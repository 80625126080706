import React, { useEffect, useState } from 'react';
import { SnapshotTeacher } from 'types';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import { FlexRow, FlexGroup } from 'layouts';
import { getDate, renderData, display } from 'util/objectUtility';
import { formatPhone } from 'util/universalcontact.utility';
import {
  createRadioOptionsForBool,
  convertBoolToRadioValue,
} from 'util/formHelpers';
import { isNil, some } from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from 'components/Common/FormElements/Button';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Input from 'components/Common/FormElements/Input';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Radio from 'components/Common/FormElements/Radio';
import Toggle from 'components/Common/FormElements/Toggle';

type PersonalInformationComponentProps = {
  teacher: SnapshotTeacher;
  onChange: Function;
  v: ValidationObject<SnapshotTeacher>;
  close: Function;
};

export const TeacherPersonalInformation: React.FC<PersonalInformationComponentProps> = (
  props
) => {
  const { teacher, onChange, v, close } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const history = useHistory();
  const render = display(teacher);

  const getCredentialType = () => {
    if (teacher) {
      const { credentialType } = teacher;
      return isNil(credentialType) ? null : !!teacher.credentialType;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!teacher.isTeachingPostsecondary) {
      const hasValue = some([
        teacher.s_number.length > 0,
        teacher.renewalReq.length > 0,
      ]);
      hasValue &&
        onChange({
          isTeachingPostsecondary: true,
        });
    }
  }, [teacher]); // eslint-disable-line

  const EditContactButton = () => {
    return (
      <React.Fragment>
        <FlexGroup style={{ alignSelf: 'flex-end' }}>
          <p className="form__label">&nbsp;</p>
          <Button
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              close();
              setTimeout(() => {
                history.push(`/contacts/${teacher.teacherContactId}`);
              }, 500);
            }}
            className="button--outline"
            style={{ height: '5rem' }}
            text="Edit Contact Information"
          />
          <p style={{ marginTop: '.2rem', textAlign: 'right' }}>
            Only users with Universal Contacts Poweruser permissions can edit
            Universal Contacts
          </p>
        </FlexGroup>
      </React.Fragment>
    );
  };

  return teacher ? (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">Personal Information</h2>
      </FlexRow>
      <FlexRow>
        <Input
          className="input-display"
          disabled
          name="firstName"
          onChange={onChange}
          value={render('firstName')}
        />
        <Input
          className="input-display"
          disabled
          name="middleName"
          onChange={onChange}
          value={render('middleName')}
        />
        <Input
          className="input-display"
          disabled
          name="lastName"
          onChange={onChange}
          value={render('lastName')}
        />
        <DatePicker
          errorMessage={v.getError('dob')}
          label="Date of Birth"
          name="dob"
          onBlur={() => v.validate('dob', teacher.dob, teacher)}
          onChange={onChange}
          valid={v.getFieldValid('dob')}
          value={teacher.dob}
        />
      </FlexRow>
      <FlexRow>
        <Input
          className="input-display"
          disabled
          name="credentialNumber"
          onChange={onChange}
          value={render('credentialNumber')}
        />
        <Input
          className="input-display"
          disabled
          label="Credential Expiration Date"
          name="credentialExp"
          onChange={onChange}
          value={renderData(getDate(teacher.credentialExp))}
        />
        <Input
          className="input-display"
          disabled
          label="Work Phone"
          name="phone"
          onChange={onChange}
          value={formatPhone(render('phone'))}
        />
        <Input
          className="input-display"
          disabled
          label="Work Email"
          name="email"
          value={render('email')}
        />
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <Input
            className="input-display"
            disabled
            label="Credential Areas"
            name="credentialArea"
            value={teacher.credentialArea}
          />
        </FlexGroup>
      </FlexRow>
      {teacher.isTeachingPostsecondary ? (
        <React.Fragment>
          <FlexRow>
            <Toggle
              border
              description="Postsecondary"
              label="Teaching Postsecondary"
              name="isTeachingPostsecondary"
              onChange={onChange}
              value={teacher.isTeachingPostsecondary}
            />
            <FlexGroup>
              <div className="u-flex">
                <Radio
                  errorMessage={v.getError('credentialType')}
                  name="credentialType"
                  label="Type"
                  onBlur={() =>
                    v.validate(
                      'credentialType',
                      teacher.credentialType,
                      teacher
                    )
                  }
                  onChange={onChange}
                  options={createRadioOptionsForBool({
                    optionTrue: 'Initial',
                    optionFalse: 'Professional',
                  })}
                  valid={v.getFieldValid('credentialType')}
                  value={convertBoolToRadioValue(getCredentialType())}
                />
                <FlexGroup>
                  <LabelWithIcon
                    htmlFor="s_number"
                    iconName="info"
                    label="S-number or Identifier"
                    tooltip="List S# or identifier for the teacher."
                  />
                  <Input
                    errorMessage={v.getError('s_number')}
                    name="s_number"
                    noLabel
                    onBlur={() =>
                      v.validate('s_number', teacher.s_number, teacher)
                    }
                    onChange={onChange}
                    valid={v.getFieldValid('s_number')}
                    value={teacher.s_number}
                  />
                </FlexGroup>
              </div>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="renewalReq"
                iconName="info"
                label="Renewal Requirement (150 characters max)"
                tooltip="List the Renewal Requirements for the teacher’s credential."
              />
              <Input
                errorMessage={v.getError('renewalReq')}
                name="renewalReq"
                noLabel
                onBlur={() =>
                  v.validate('renewalReq', teacher.renewalReq, teacher)
                }
                onChange={onChange}
                valid={v.getFieldValid('renewalReq')}
                value={teacher.renewalReq}
              />
            </FlexGroup>
            <EditContactButton />
          </FlexRow>
        </React.Fragment>
      ) : (
        <FlexRow>
          <Toggle
            border
            description="Postsecondary"
            label="Teaching Postsecondary"
            name="isTeachingPostsecondary"
            onChange={onChange}
            value={teacher.isTeachingPostsecondary}
          />
          <EditContactButton />
        </FlexRow>
      )}
    </React.Fragment>
  ) : null;
};
