import React, { useState, useEffect } from 'react';
import { compose, renderData, prop } from 'util/objectUtility';
import {
  getUserEmail,
  getContactFullName,
  getUserPhone,
  extractTeacherInfo,
  getCredentialString,
} from 'util/universalcontact.utility';
import { concat, clone, map, filter, some, find, every } from 'lodash';
import { upsert } from 'util/arrayUtility';
import {
  ProgramContactDeep,
  UniversalContact,
  ProgramContactType,
  emptyProgramContact,
  emptyProgramContactDeep,
  ProgramStep,
} from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { ProgramAdvisoryChairForm } from './AdvisoryChairForm.component';
import { ProgramContactValidation } from 'validations/ProgramContact.validations';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import InstructorAdditionalContacts from 'components/Program/Contacts/InstructorAdditionalContacts';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';
import TeacherCredentials from 'components/UniversalContacts/TeacherCredentials';

type ProgramContactsProps = {
  isFetching: boolean;
  loadProgramContacts: Function;
  nextProgramStep: ProgramStep;
  programContactCache: ProgramContactDeep[];
  programContactTypes: ProgramContactType[];
  programLevel: number;
  readOnly: boolean;
  saveProgramContacts: Function;
  setCurrentNavAnchor: Function;
};

export const ContactsEditComponent: React.FC<ProgramContactsProps> = (
  props
) => {
  const {
    isFetching,
    loadProgramContacts,
    nextProgramStep,
    programContactCache,
    programContactTypes,
    programLevel,
    readOnly,
    saveProgramContacts,
    setCurrentNavAnchor,
  } = props;

  const isPostSecondary = programLevel === 2;
  const { programInstanceId: pid }: any = useParams();
  const programInstanceId = Number(pid);

  const [programContacts, setProgramContacts] = useState<ProgramContactDeep[]>(
    clone(programContactCache)
  );

  // set validations and extract functions/state from hook
  const validations = ProgramContactValidation();
  const { isValid, validateAll } = validations;

  const hasATeacher = () => {
    const contacts = map(
      filter(programContacts, (item) => {
        return item.programContactTypeId === 5;
      }),
      (contact) => {
        return contact.contact.isTeacher;
      }
    );
    return some(contacts);
  };

  const handleSave = () => {
    const validateObject = getAdvisoryChair();
    if (validateAll(validateObject) && hasATeacher()) {
      return saveProgramContacts(programInstanceId, programContacts);
    }
    if (!hasATeacher()) {
      return Promise.reject(
        'You did not include a teacher, you will not be able to submit this program until at least one teacher is added to the contact list.'
      );
    }
    if (!validateAll(validateObject)) {
      return Promise.reject(
        'Validations did not pass, please complete the form and look for validation errors.'
      );
    }
    return Promise.reject('Something went wrong. Please file a bug report.');
  };

  const excludedContactList = (): UniversalContact[] => {
    return map(
      programContacts,
      (contact: ProgramContactDeep): UniversalContact => {
        return contact.contact;
      }
    );
  };

  const removeFromContacts = (contactProp: ProgramContactDeep) => {
    const newProgramContacts = filter(
      programContacts,
      (item: ProgramContactDeep) => item.contactId !== contactProp.contactId
    );
    setProgramContacts([...newProgramContacts]);
  };

  const addToContacts = (contact: UniversalContact) => {
    if (contact.universalContactId === -1) {
      return;
    }

    const newProgramContact: ProgramContactDeep = {
      ...emptyProgramContact(),
      programInstanceId: programInstanceId,
      contactId: contact.universalContactId,
      createdDate: new Date(Date.now()),
      contact: contact,
      programContactTypeId: 5,
    };
    const value = concat(programContacts, [newProgramContact]);
    setProgramContacts(value);
  };

  const getAdvisoryChair = () => {
    const chair = find(programContacts, (item) => {
      return item.programContactTypeId === 4;
    });
    return chair
      ? chair
      : {
          ...emptyProgramContactDeep(),
          programContactTypeId: 4,
          programInstanceId: programInstanceId,
          createdDate: new Date(Date.now()),
        };
  };

  const handleAdvisioryChairChange = (chair: ProgramContactDeep) => {
    setProgramContacts(upsert(programContacts, chair, 'contactId'));
  };

  const FullName = compose(renderData, getContactFullName, prop('contact'));
  const Title = compose(renderData, prop('title'), prop('contact'));
  const Email = compose(renderData, getUserEmail, prop('contact'));
  const Phone = compose(renderData, getUserPhone, prop('contact'));
  const Credentials = compose(
    renderData,
    prop('credentialId'),
    prop('contact')
  );
  const renderFullName = (row: ProgramContactDeep) => {
    return (
      <React.Fragment>
        <span
          className="u-flex u-color-blue u-bold"
          style={{ alignItems: 'center' }}
        >
          {row.contact.isTeacher && (
            <TeacherCredentials teacherInfo={extractTeacherInfo(row.contact)} />
          )}
          {FullName(row)}
        </span>
      </React.Fragment>
    );
  };

  const renderEmail = (row: ProgramContactDeep) => {
    const email = Email(row);
    return email === EMPTY_PLACEHOLDER ? (
      <React.Fragment>{email}</React.Fragment>
    ) : (
      <a href={`mailto:${email}`}>{email}</a>
    );
  };

  const renderCredentials = (row: ProgramContactDeep) => {
    return (
      <React.Fragment>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cool.randasolutions.com/Public/Search"
        >
          {getCredentialString(extractTeacherInfo(row.contact))}
        </a>
      </React.Fragment>
    );
  };

  const renderViewLink = (row: ProgramContactDeep) => (
    <IconButton
      target="_blank"
      name="launchOpen"
      tooltip="View contact in new browser tab"
      asLink={true}
      to={`/contacts/${row.contactId}`}
    />
  );

  const renderTrashCan = (row: ProgramContactDeep) => (
    <ConfirmationDialog
      tooltip="Delete"
      header="Confirm Remove Contact"
      onConfirm={() => removeFromContacts(row)}
    >
      <h3>Are you sure you wish to remove {FullName(row)}?</h3>
    </ConfirmationDialog>
  );

  const CONTACT_COLUMNS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: renderFullName,
      sortable: true,
      selector: FullName,
    },
    {
      name: 'Title',
      cell: Title,
      sortable: true,
      selector: Title,
    },
    {
      name: 'Email',
      cell: renderEmail,
      sortable: true,
      selector: Email,
    },
    {
      name: 'Phone',
      cell: Phone,
      sortable: true,
      selector: Phone,
    },
    {
      name: 'Credentials',
      cell: renderCredentials,
      sortable: true,
      selector: Credentials,
    },
    {
      name: 'View',
      cell: renderViewLink,
      center: true,
      width: '8rem',
    },
    {
      name: 'Remove',
      cell: renderTrashCan,
      center: true,
      width: '8rem',
    },
  ];

  const CONTACT_HEADERS_WITHOUT_DELETE = [
    ...CONTACT_COLUMNS.slice(0, CONTACT_COLUMNS.length - 1),
  ];

  const doNotRender = (typeName: string) => {
    return some([
      isPostSecondary && typeName === 'district',
      !isPostSecondary && typeName === 'department',
    ]);
  };

  const getHeaders = (typeName: string) => {
    return typeName === 'other'
      ? CONTACT_COLUMNS
      : CONTACT_HEADERS_WITHOUT_DELETE;
  };

  const getData = (typeName: string, typeId: number) => {
    return filter(programContacts, (contact: ProgramContactDeep) => {
      const isOther = every([
        typeName === 'other',
        contact.programContactTypeId === 255,
      ]);
      return some([isOther, contact.programContactTypeId === typeId]);
    });
  };

  // modifies the instructions based on the type
  const generateContactDOM = (typeName: string) => {
    switch (typeName) {
      case 'school': {
        const title = isPostSecondary
          ? 'Institution Contacts'
          : 'School Contacts';
        return (
          <Instruction title={title}>
            <p>
              Please review the {title} provided. Use the link in the 'View'
              column to see the universal contact in more detail.
            </p>
          </Instruction>
        );
      }

      case 'district': {
        const title = 'District Contacts';
        return (
          <Instruction title={title}>
            <p>
              Please review the {title} provided. Use the link in the 'View'
              column to see the universal contact in more detail.
            </p>
          </Instruction>
        );
      }

      case 'department': {
        const title = 'Department Contacts';
        return (
          <Instruction title={title}>
            <p>
              Please review the {title} provided. Use the link in the 'View'
              column to see the universal contact in more detail.
            </p>
          </Instruction>
        );
      }

      case 'advisory chair': {
        return (
          <ProgramAdvisoryChairForm
            chair={getAdvisoryChair()}
            onChange={handleAdvisioryChairChange}
            validations={validations}
          />
        );
      }

      case 'other': {
        return (
          <InstructorAdditionalContacts
            addToContacts={addToContacts}
            excludedContactList={excludedContactList}
          />
        );
      }

      default: {
        return `${typeName} Contacts`;
      }
    }
  };

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramContacts(programInstanceId);
    }
  }, [loadProgramContacts, programInstanceId]);

  useEffect(() => {
    if (
      programContactCache &&
      programContactCache.length > 0 &&
      programContacts.length === 0
    ) {
      setProgramContacts(programContactCache);
    }
  }, [
    setProgramContacts,
    programContactCache,
    programContactCache.length,
    programContacts.length,
  ]);

  useEffect(() => {
    setCurrentNavAnchor('contacts');
  }, [setCurrentNavAnchor]);

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading contact data" />
      <ScrollToTop />
      <SecureWrap component="programs" isLocked={readOnly}>
        <Paper>
          <h2 className="form__section-title">Contact Information</h2>
        </Paper>
        {programLevel !== 255 && (
          <React.Fragment>
            {map(programContactTypes, (contactType: ProgramContactType) => {
              const {
                programContactTypeId,
                programContactTypeName,
              } = contactType;

              const typeName = programContactTypeName.toLowerCase();

              if (doNotRender(typeName)) {
                return null;
              }

              return (
                <Paper key={programContactTypeName}>
                  {generateContactDOM(typeName)}
                  {typeName !== 'advisory chair' && (
                    <Table
                      columns={getHeaders(typeName)}
                      data={getData(typeName, programContactTypeId)}
                    />
                  )}
                </Paper>
              );
            })}
            <Paper>
              <SaveButtons
                cancelUrl={`/programs/program-steps/${programInstanceId}`}
                saveData={handleSave}
                saveUrl={nextProgramStep.nav.url}
                saveText="Save and Continue"
                disabled={!isValid}
              />
            </Paper>
          </React.Fragment>
        )}
      </SecureWrap>
    </React.Fragment>
  );
};
