import { ActiveTeachersComponent } from './ActiveTeachers.component';
import { connect } from 'react-redux';
import { deactivateSnapshotTeacher } from 'redux/datacollection/activeteachers';
import {
  getAdvancedFilteredRecords_ActiveTeachersData,
  getIsDataFetching_ActiveTeachersData,
  getIsDataFetching_UniversalTeachers,
} from 'redux/datacollection/_selectors';
import { loadActiveTeachers } from 'redux/datacollection/activeteachers';
import { some } from 'lodash';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any) => {
  const loggedInUser = getLoggedinUser(state);
  const teachers = getAdvancedFilteredRecords_ActiveTeachersData(state);
  const isFetching = some([
    getIsDataFetching_ActiveTeachersData(state),
    getIsDataFetching_UniversalTeachers(state),
  ]);

  return {
    loggedInUser,
    teacherData: teachers,
    isFetching,
  };
};

const dispatchProps = {
  loadActiveTeachers,
  deactivateSnapshotTeacher,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ActiveTeachersComponent);
