/*Grants Keys */
export const GRANTS_KEY = 'GRANTS';
export const GRANTS_CONSORTIUMS_KEY = 'CONSORTIUMS';
export const GRANTS_ITP_CONSORTIUMS_KEY = 'ITP_CONSORTIUMS';
export const GRANTS_FISCAL_AGENTS_KEY = 'FISCAL_AGENTS';
export const GRANTS_FISCAL_YEAR_KEY = 'GRANT_FISCAL_YEAR';
export const GRANTS_TWO_YEAR_KEY = 'TWO_YEAR';
export const GRANTS_ANNUAL_SPENDING_PLAN_KEY = 'ANNUAL_SPENDING_PLAN';
export const GRANTS_NEEDS_ASSESSMENT_KEY = 'NEEDS_ASSESSMENT';
export const GRANTS_CAREER_INFO_KEY = 'CAREER_INFO';
export const GRANTS_QUALITY_KEY = 'QUALITY';
export const GRANTS_INSTRUCTORS_KEY = 'INSTRUCTORS';
export const GRANTS_PARTNERSHIPS_KEY = 'PARTNERSHIPS';
export const GRANTS_WORK_BASED_LEARNING_KEY = 'WORK_BASED_LEARNING';
export const GRANTS_EQUITY_KEY = 'EQUITY';
export const GRANTS_ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT_KEY =
  'ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT';
export const GRANTS_ADDRESSABLE_ELEMENTS_CAREER_INFO_KEY =
  'ADDRESSABLE_ELEMENTS_CAREER_INFO';
export const GRANTS_ADDRESSABLE_ELEMENTS_QUALITY_KEY =
  'ADDRESSABLE_ELEMENTS_QUALITY';
export const GRANTS_ADDRESSABLE_ELEMENTS_INSTRUCTORS_KEY =
  'ADDRESSABLE_ELEMENTS_INSTRUCTORS';
export const GRANTS_ADDRESSABLE_ELEMENTS_PARTNERSHIPS_KEY =
  'ADDRESSABLE_ELEMENTS_PARTNERSHIPS';
export const GRANTS_ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING_KEY =
  'ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING';
export const GRANTS_ADDRESSABLE_ELEMENTS_EQUITY_KEY =
  'ADDRESSABLE_ELEMENTS_EQUITY';
export const GRANTS_PARTICIPANT_KEY = 'GRANTS_PARTICIPANT';
export const GRANTS_STRATEGY_SELECT_ITEMS_KEY = 'GRANTS_STRATEGY_SELECT_ITEMS';
export const GRANTS_EXPENSE_PROGRAMS_KEY = 'GRANTS_EXPENSE_PROGRAMS';
export const GRANTS_BASIC_GRANT_SIGNATURE_KEY = 'BASIC_GRANT_SIGNATURE';
export const GRANTS_ASSURANCES_KEY = 'GRANTS_ASSURANCES';
export const GRANTS_ANNUAL_REFLECTION_KEY = 'GRANTS_ANNUAL_REFLECTION';
export const GRANTS_AGREEMENTS_SIGNATURES = 'GRANTS_AGREEMENTS_SIGNATURES';
export const GRANTS_CIVIL_RIGHTS_SIGNATURES = 'GRANTS_CIVIL_RIGHTS_SIGNATURES';
export const GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES =
  'GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES';
export const GRANTS_EMAIL_TEMPLATE_KEY = 'GRANTS_EMAIL_TEMPLATE';
export const GRANTS_EMAIL_TEMPLATE_SUMMARIES_KEY =
  'GRANTS_EMAIL_SUMMARIES_TEMPLATES';
export const GRANTS_VOUCHERS_KEY = 'GRANTS_VOUCHERS';

export const GRANTS_REIMBURSEMENT_SUMMARY_KEY = 'GRANTS_REIMBURSEMENT_SUMMARY';
export const GRANTS_ACTUAL_REIMBURSEMENT_KEY = 'GRANTS_ACTUAL_REIMBURSEMENT';

export const GRANTS_IMPROVEMENT_PLAN_KEY = 'GRANTS_IMPROVEMENT_PLAN';

/* Program Keys */
export const PROGRAMS_KEY = 'PROGRAMS';
export const PROGRAM_ARTICULATIONS_KEY = 'ARTICULATIONS';
export const PROGRAM_ASSURANCE_QUESTIONS_KEY = 'ASSURANCE_QUESTIONS';
export const PROGRAM_AWARD_TYPES_KEY = 'AWARD_TYPES';
export const PROGRAM_CAREER_PATHWAYS_KEY = 'PROGRAM_CAREER_PATHWAYS';
export const PROGRAM_CONTACTS_KEY = 'CONTACTS';
export const PROGRAM_COURSE_TYPES_KEY = 'COURSE_TYPES';
export const PROGRAM_GENERAL_KEY = 'GENERAL';
export const PROGRAM_POS_KEY = 'POS';
export const PROGRAM_TEMPLATE_KEY = 'TEMPLATE';

/* Common Keys */
export const COMMON_KEY = 'COMMON';
export const COMMON_CAREER_CLUSTERS_KEY = 'CAREER_CLUSTERS';
export const COMMON_CAREER_PATHWAYS_KEY = 'CAREER_PATHWAYS';
export const COMMON_CIPCODE_KEY = 'CIPCODES';
export const COMMON_COURSES_KEY = 'COURSES';
export const COMMON_CTSO_KEY = 'CTSO';
export const COMMON_DEPARTMENTS_KEY = 'DEPARTMENTS';
export const COMMON_DISTRICTS_KEY = 'DISTRICTS';
export const COMMON_INSTITUTES_KEY = 'INSTITUTES';
export const COMMON_PROGRAM_AREA_KEY = 'PROGRAM_AREAS';
export const COMMON_PROGRAM_CONTACT_TYPES_KEY = 'PROGRAM_CONTACT_TYPES';
export const COMMON_PROGRAM_LENGTHS_KEY = 'PROGRAM_LENGTHS';
export const COMMON_PROGRAM_LEVELS_KEY = 'PROGRAM_LEVELS';
export const COMMON_PROGRAM_STATES_KEY = 'PROGRAM_STATES';
export const COMMON_PROGRAM_STEPS_KEY = 'PROGRAM_STEPS';
export const COMMON_SCHOOLS_KEY = 'SCHOOLS';
export const COMMON_EXPENSE_CATEGORY_KEY = 'EXPENSE_CATEGORY';
export const COMMON_TRAVELER_TYPE_KEY = 'TRAVELER_TYPE';
export const COMMON_ADMIN_COMMENTS_KEY = 'COMMON_ADMIN_COMMENTS';
export const COMMON_FERPA_KEY = 'FERPA_AGREEMENT';
export const COMMON_FISCAL_YEAR_KEY = 'FISCAL_YEAR';
export const COMMON_CTA_FISCAL_YEAR_KEY = 'CTA_FISCAL_YEAR';

/* Authorization Keys */
export const PERMISSIONS_KEY = 'PERMISSIONS';
export const USER_PERMISSIONS_KEY = 'USER_PERMISSIONS';
export const PERMISSIONS_ROLES_KEY = 'PERMISSIONS_ROLES';
export const PERMISSIONS_TOPICS_KEY = 'PERMISSIONS_TOPICS';
export const PERMISSIONS_COMPONENTS_KEY = 'PERMISSIONS_COMPONENTS';
export const PERMISSIONS_TYPES_KEY = 'PERMISSIONS_TYPES';
export const PERMISSIONS_ROLE_COMPONENTS_KEY = 'PERMISSIONS_ROLE_COMPONENTS';
export const PERMISSIONS_CONTACTS_ROLES = 'PERMISSIONS_CONTACTS_ROLES';

/* Universal Contacts Keys */
export const UNIVERSAL_CONTACTS_KEY = 'UNIVERSAL_CONTACTS';
export const UNIVERSAL_CONTACTS_CONTACTS = 'CONTACTS';
export const UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY = 'CONTACT_TYPES';

export const USER_KEY = 'USER';

// Data Collection keys
export const DATA_COLLECTION_KEY = 'DATA_COLLECTION';
export const DATA_COLLECTION_STUDENT_KEY = 'STUDENTS';
export const DATA_COLLECTION_TEACHER_KEY = 'TEACHERS';
export const DATA_COLLECTION_SUBMISSION_KEY = 'DATA_COLLECTION_SUBMISSIONS';
export const DATA_COLLECTION_STUDENT_ENROLLMENT_KEY = 'STUDENT_ENROLLMENT';
export const DATA_COLLECTION_UNIVERSAL_TEACHERS_KEY = 'UNIVERSAL_TEACHERS';
export const DATA_COLLECTION_PROGRAM_SNAPSHOT_STUDENT_KEY =
  'PROGRAM_SNAPSHOT_STUDENTS';

export const APPLICATION_KEY = 'APPLICATION_STATE';

/* Reports Key */
export const REPORTING_KEY = 'REPORTING';
export const REPORTS_KEY = 'REPORTS';

/* Workflow Keys */
export const WORKFLOW_KEY = 'WORKFLOW';
export const WORKFLOW_PROGRAMS_KEY = 'WORKFLOW_PROGRAMS';
export const WORKFLOW_ASSURANCES_KEY = 'WORKFLOW_ASSURANCES';
export const WORKFLOW_TWO_YEAR_KEY = 'WORKFLOW_TWO_YEAR';
export const WORKFLOW_FOUR_YEAR_KEY = 'WORKFLOW_FOUR_YEAR';
export const WORKFLOW_ANNUAL_SPENDING_PLAN_KEY =
  'WORKFLOW_ANNUAL_SPENDING_PLAN';
export const WORKFLOW_ASURANCE_SIGNATURE_KEY = 'WORKFLOW_ASURANCE_SIGNATURE';
export const WORKFLOW_ANNUAL_REFLECTION_KEY = 'WORKFLOW_ANNUAL_REFLECTION';
export const WORKFLOW_AWARD_PACKET_KEY = 'WORKFLOW_AWARD_PACKET';
export const WORKFLOW_VOUCHER_KEY = 'WORKFLOW_VOUCHER';
export const WORKFLOW_IMPROVEMENT_PLAN_KEY = 'WORKFLOW_IMPROVEMENT_PLAN';
export const WORKFLOW_CTA_KEY = 'WORKFLOW_CTA';

/* Changelog Keys */
export const CHANGELOG_KEY = 'CHANGELOG';
export const CHANGELOG_PROGRAMS_KEY = 'CHANGELOG_PROGRAMS';
export const CHANGELOG_FOUR_YEAR_KEY = 'CHANGELOG_FOUR_YEAR';
export const CHANGELOG_ANNUAL_REFLECTION_KEY = 'CHANGELOG_ANNUAL_REFLECTION';
export const CHANGELOG_IMPROVEMENT_PLAN_KEY = 'CHANGELOG_IMPROVEMENT_PLAN';
export const CHANGELOG_ANNUAL_SPENDING_KEY = 'CHANGELOG_ANNUAL_SPENDING';
export const CHANGELOG_VOUCHER_ONE_KEY = 'CHANGELOG_VOUCHER_ONE';
export const CHANGELOG_VOUCHER_TWO_KEY = 'CHANGELOG_VOUCHER_TWO';
export const CHANGELOG_VOUCHER_THREE_KEY = 'CHANGELOG_VOUCHER_THREE';
export const CHANGELOG_FINAL_VOUCHER_KEY = 'CHANGELOG_FINAL_VOUCHER';

/* Cache Keys */
export const CACHE_KEY = 'CACHE';
export const PROGRAM_AWARD_CACHE_KEY = 'PROGRAM_AWARD';
export const PROGRAM_COURSE_CREDIT_CACHE_KEY = 'PROGRAM_COURSE_CREDIT';
export const PROGRAM_CAREER_PATHWAY_COURSE_CACHE_KEY =
  'PROGRAM_CAREER_PATHWAY_COURSE';

/* Errors Keys */
export const ERRORS_KEY = 'ERROR';

/* Filter Keys */
export const FILTERS_KEY = 'FILTERS';
export const FILTERS_KEY_PROGRAMS = 'FILTERS_PROGRAMS';
export const FILTERS_KEY_PERKINS_GRANT = 'FILTERS_PERKINS_GRANT';
export const FILTERS_KEY_UNIVERSALCONTACTS = 'FILTERS_UNIVERSALCONTACTS';
export const FILTERS_KEY_CONSORTIA = 'FILTERS_CONSORTIA';
export const FILTERS_KEY_GRANT_PARTICIPANT = 'FILTERS_GRANT_PARTICIPANT';
export const FILTERS_KEY_EMAIL_RECIPIENT = 'FILTERS_EMAIL_RECIPIENT';
export const FILTERS_KEY_SUBMISSION_MANAGEMENT =
  'FILTERS_SUBMISSION_MANAGEMENT';
export const FILTERS_KEY_SNAPSHOT_STUDENT = 'FILTERS_SNAPSHOT_STUDENT';
export const FILTERS_KEY_CTA = 'FILTERS_CTA';
export const FILTERS_KEY_REPORTING = 'FILTERS_REPORTING';

/* Nav Keys */
export const NAV_KEY = 'NAV';
export const NAV_GRANT_OVERVIEW_KEY = 'NAV_GRANT_OVERVIEW';
export const NAV_GRANT_FISCAL_YEAR_KEY = 'NAV_GRANT_FISCAL_YEAR';
export const NAV_GRANT_FOUR_YEAR_KEY = 'NAV_GRANT_FOUR_YEAR';
export const NAV_GRANT_TWO_YEAR_KEY = 'NAV_GRANT_TWO_YEAR';
export const NAV_GRANT_ANNUAL_SPENDING_PLAN_KEY =
  'NAV_GRANT_ANNUAL_SPENDING_PLAN';
export const NAV_GRANT_IMPROVEMENT_PLAN_KEY = 'NAV_GRANT_IMPROVEMENT_PLAN';
export const NAV_GRANT_ASSURANCE_SIGNATURE_KEY =
  'NAV_GRANT_ASSURANCE_SIGNATURE';
export const NAV_GRANT_ANNUAL_REFLECTION_KEY = 'NAV_GRANT_ANNUAL_REFLECTION';
export const NAV_GRANT_AWARD_PACKET_KEY = 'NAV_GRANT_AWARD_PACKET';
export const NAV_GRANT_VOUCHER_OVERVIEW_KEY = 'NAV_GRANT_VOUCHER_OVERVIEW';
export const NAV_GRANT_VOUCHER_DETAIL_KEY = 'NAV_GRANT_VOUCHER_DETAIL';
export const NAV_GRANT_VOUCHER_STATUS_KEY = 'NAV_GRANT_VOUCHER_STATUS';

/* Nav Keys */
export const CTA_KEY = 'CTA';
export const CTA_ADMIN_KEY = 'CTA_ADMIN';
export const CTA_ADMIN_BOOK_SUPPLY_KEY = 'CTA_ADMIN_BOOK_SUPPLY';
export const CTA_BOOKS_SCHOOL_KEY = 'CTA_BOOKS_SCHOOL';

export const CTA_CONTRACTING_KEY = 'CTA_CONTRACTING';
export const CTA_CONTRACTING_HOSTS_KEY = 'CTA_CONTRACTING_HOSTS';
export const CTA_CONTRACTING_AVAILABLE_HOSTS_KEY =
  'CTA_CONTRACTING_AVAILABLE_HOSTS';

export const CTA_COURSE_CONTRACTING_KEY = 'CTA_COURSE_CONTRACTING';
export const CTA_COURSE_HOSTING_KEY = 'CTA_COURSE_HOSTING';
export const CTA_COURSE_INSTRUCTIONAL_KEY = 'CTA_COURSE_INSTRUCTIONAL';

export const CTA_DASHBOARD_KEY = 'CTA_DASHBOARD';
export const CTA_DIRECTOR_KEY = 'CTA_DIRECTOR_KEY';
export const CTA_DISTRICT_SALARY_KEY = 'CTA_DISTRICT_SALARY';
export const CTA_EQUIPMENT_KEY = 'CTA_EQUIPMENT';
export const CTA_GENERAL_KEY = 'CTA_GENERAL';

export const CTA_HOSTING_KEY = 'CTA_HOSTING';
export const CTA_HOSTING_CONTRACTS_KEY = 'CTA_HOSTING_CONTRACTS';
export const CTA_HOSTING_AVAILABLE_CONTRACTS_KEY =
  'CTA_HOSTING_AVAILABLE_CONTRACTS';

export const CTA_INSTRUCTOR_KEY = 'CTA_INSTRUCTORS';
export const CTA_INSTRUCTOR_CTE_COURSE_KEY = 'CTA_INSTRUCTORS_CTE_COURSE';
export const CTA_INSTRUCTOR_NON_CTE_COURSE_KEY =
  'CTA_INSTRUCTORS_NON_CTE_COURSE';
export const CTA_INSTRUCTOR_SALARY_KEY = 'CTA_INSTRUCTORS_SALARY';

export const CTA_REVIEW_OPTIMIZE_KEY = 'CTA_REVIEW_OPTIMIZE';
export const CTA_SCHOOL_TERMS_KEY = 'CTA_SCHOOL_TERMS';
export const CTA_SIGNATURE_KEY = 'CTA_SIGNATURE';
export const CTA_SPECIALIST_KEY = 'CTA_SPECIALIST';
export const CTA_SUPPORT_PERSONNEL_KEY = 'CTA_SUPPORT_PERSONNEL';
export const CTA_SUPPORT_PROGRAM_AFFILIATION_KEY =
  'CTA_SUPPORT_PROGRAM_AFFILIATION';

const Keys = {
  APPLICATION_KEY,
  CACHE_KEY,
  CHANGELOG_KEY,
  COMMON_KEY,
  CTA_KEY,
  DATA_COLLECTION_KEY,
  ERRORS_KEY,
  GRANTS_KEY,
  NAV_KEY,
  PERMISSIONS_KEY,
  PROGRAMS_KEY,
  REPORTING_KEY,
  UNIVERSAL_CONTACTS_KEY,
  USER_KEY,
  WORKFLOW_KEY,
  FILTERS_KEY,
};

export default Keys;
