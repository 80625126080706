import {
  toJSON,
  catchError,
  getFormattedUrl,
  postWithAuthOptions,
  getWithAuthOptions,
} from 'services';
import { Workflow, WorkflowSubmission } from 'types';
import { CTAOptimizedSubmission } from 'types/cta/CTAOptimizedSubmission.types';

const BASE_URL = ['api', 'ctaApprovals'];

export const readCtaOptimizedSubmission = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'submission', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ctaApprovals', err, 'readCtaOptimizedSubmission');
    });
};

export const createCtaOptimizedSubmission = async (
  ctaId: number,
  payload: CTAOptimizedSubmission
) => {
  return fetch(getFormattedUrl([...BASE_URL, 'submission', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(payload),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ctaApprovals', err, 'updateCtaOptimizedSubmission');
    });
};

export const createCtaWorkflowUpdate = async (
  ctaId: number,
  submission: WorkflowSubmission
): Promise<Workflow> => {
  const url = getFormattedUrl([...BASE_URL, ctaId, submission.optionId]);

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ comment: submission.comment }),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const readCtaWorkflow = async (ctaId: number) => {
  const url = getFormattedUrl([...BASE_URL, ctaId]);

  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('readCtaWorkflow', err);
    });
};
