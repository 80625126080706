import { useValidation } from 'de-formed-validations';
import {
  IntentToParticipateMember,
  IntentToParticipateFiscalAgent,
} from 'types';
import { compose, prop } from 'util/objectUtility';
import { every } from 'lodash';
import { fmap } from 'util/arrayUtility';

export const IntentToParticipateFiscalAgentValidations = () => {
  const validateMembers = compose(
    every,
    fmap(prop('hasResponded'))
  );

  return useValidation<IntentToParticipateFiscalAgent>({
    members: [
      {
        errorMessage: 'All members must respond before submitting.',
        validation: (members: IntentToParticipateMember[], _) => {
          return members.length > 0 && validateMembers(members);
        },
      },
    ],
  });
};
