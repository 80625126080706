import { PROGRAMS_KEY, PROGRAM_GENERAL_KEY } from 'redux/keys';
import { defaultTo, find, includes } from 'lodash';
import { getActiveProgramId } from 'redux/application/selectors';
import { ItemFilter, Program, emptyProgram } from 'types';
import ApplicationState from 'types/applicationstate.types';
import {
  getIsDataFetching,
  getFilteredRecords,
  getRecords,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { getProgramFilters } from 'redux/filters/programfilter.selectors';
import { EDITABLE_PROGRAM_STATES } from 'constants/programs';

const PROGRAM_INSTANCE_PATH = [PROGRAMS_KEY, PROGRAM_GENERAL_KEY];

export const getIsDataFetching_ProgramInstance = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_INSTANCE_PATH);
};

export const getProgram = (
  state: ApplicationState,
  programInstanceId: number
): Program => {
  const program = defaultTo(
    find(getRecords_ProgramInstance(state), (value: Program) => {
      return value.programInstanceId === programInstanceId;
    }),
    emptyProgram()
  );
  return program;
};

export const getProgramCccsCollege = (
  state: ApplicationState,
  programInstanceId: number
) => {
  const program = getProgram(state, programInstanceId);
  return program.isCccsCollege;
};

export const getIsProgramReadOnly = (
  state: ApplicationState,
  programInstanceId: number
) => {
  const program = getProgram(state, programInstanceId);
  return !includes(EDITABLE_PROGRAM_STATES, program.programStateId);
};

export const getProgramIsLoaded = (
  state: ApplicationState,
  programInstanceId: number
): boolean => {
  const value = find(getRecords_ProgramInstance(state), (value: Program) => {
    return value.programInstanceId === programInstanceId;
  });
  return value ? true : false;
};

export const getRecords_ProgramInstance = (state: unknown): Program[] => {
  return getRecords(state, PROGRAM_INSTANCE_PATH);
};

export const getAdvancedFilteredRecords_Programs = (state: any): Program[] => {
  const filters = getProgramFilters(state);
  const records = getAdvancedFilteredRecords<Program>(
    state,
    PROGRAM_INSTANCE_PATH,
    filters
  );
  return records;
};

export const getFilteredRecords_Programs = (
  state: any,
  itemFilter: ItemFilter
): Program[] => {
  const records = getFilteredRecords<Program>(
    state,
    PROGRAM_INSTANCE_PATH,
    itemFilter
  );
  return records;
};

export const getActiveProgramTitle = (state: any): string => {
  const value = getProgram(state, getActiveProgramId(state));
  return value.programName;
};

export const getActiveProgramLevel = (state: any): number => {
  const value = getProgram(state, getActiveProgramId(state));
  return value.programLevelId;
};
