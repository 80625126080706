import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';
import {
  AzureUploadParameters,
  ProgramArticulation,
  UniversalContact,
  emptyArticulation,
  emptySchool,
} from 'types';
import { ArticulationValidations } from 'validations/Articulation.validations';
import { toast } from 'react-toastify';
import { getReduxFilter } from 'util/filterUtility';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import ContactSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/ContactSelectBox';
import Input from 'components/Common/FormElements/Input';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import MiniContactForm from 'components/UniversalContacts/MiniContactForm';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';

type ArticulationComponentProps = {
  articulation: ProgramArticulation | undefined;
  loadProgramArticulations: Function;
  programLevel: number;
  saveArticulation: Function;
  saveBusiness: Function;
};

export const ArticulationComponent: React.FC<ArticulationComponentProps> = (
  props
) => {
  const {
    articulation,
    loadProgramArticulations,
    programLevel,
    saveArticulation,
    saveBusiness,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const validations = ArticulationValidations();
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateCustom,
    validateIfTrue,
    validationErrors,
  } = validations;

  const [articulationState, setArticulationState] = useState<
    ProgramArticulation
  >({
    ...emptyArticulation(),
  });

  const [isOpen, setOpen] = useState<boolean>(false);
  const [createNewBusiness, setCreateNewBusiness] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');

  useEffect(() => {
    if (articulation && articulationState.programInstanceId < 0) {
      setArticulationState({ ...articulation });
    }
  }, [articulation, articulationState.programInstanceId]);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramArticulations(programInstanceId);
    }
  }, [loadProgramArticulations, programInstanceId]);

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const onFormChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, articulationState);
    setArticulationState({
      ...articulationState,
      ...data,
    });
  };

  const businessNameChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, articulationState);
    setBusinessName(value);
  };

  const saveNewBusiness = async () => {
    if (getFieldValid('businessName')) {
      const newSchool = {
        ...emptySchool(),
        schoolName: businessName,
        isBusiness: true,
      };
      return saveBusiness(newSchool)
        .then((schoolId: number) => {
          validate('schoolId', schoolId);
          setBusinessName('');
          setCreateNewBusiness(false);
          setArticulationState({
            ...articulationState,
            schoolId: schoolId,
          });
        })
        .catch(() => {
          const msg =
            'Something went wrong saving the business. Please try again.';
          toast(<ToastNotification status="error" message={msg} />);
        });
    }
  };

  const setContact = (contact: UniversalContact) => {
    validateIfTrue('contactId', contact.universalContactId, articulationState);
    setArticulationState({
      ...articulationState,
      contactId: contact.universalContactId,
    });
  };

  const onContactSaved = (user: UniversalContact) => {
    validateIfTrue('contactId', user.universalContactId, articulationState);
    setArticulationState({
      ...articulationState,
      contactId: user.universalContactId,
    });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|articulations`,
  };

  const handleFileUpload = (filePath: string) => {
    validateIfTrue('filePath', filePath, articulationState);
    setArticulationState({
      ...articulationState,
      filePath,
    });
  };

  const handleFileClose = () => {
    validate('filePath', '', articulationState);
    setArticulationState({
      ...articulationState,
      filePath: '',
    });
  };

  const handleSave = async (): Promise<any> => {
    if (validateAll(articulationState)) {
      return saveArticulation({ ...articulationState, programInstanceId });
    }
    return Promise.reject(
      'Not all validations passed. Please complete the form and check for errors.'
    );
  };

  return (
    <React.Fragment>
      <Paper>
        <div className="articulation-component">
          {programLevel === 2 ? (
            <React.Fragment>
              {createNewBusiness ? (
                <React.Fragment>
                  <FlexRow>
                    <Input
                      errorMessage={getError('businessName')}
                      label="Create New Business"
                      name="businessName"
                      value={businessName}
                      onChange={businessNameChange}
                      placeholder="Enter Business Name..."
                      onBlur={() => {
                        validateCustom([
                          { key: 'businessName', value: businessName },
                        ]);
                      }}
                      valid={getFieldValid('businessName')}
                    />
                  </FlexRow>
                  <FlexRow>
                    <Button
                      style={{ color: '#007d89' }}
                      text="- Return to Search"
                      className="button--flat u-reduce-margin-top"
                      onClick={() => setCreateNewBusiness(false)}
                    />
                    <div style={{ flexGrow: 1 }} />
                    <button
                      onClick={saveNewBusiness}
                      className={
                        businessName.length > 0
                          ? 'button--filled'
                          : 'button--outline'
                      }
                      style={{ width: '30rem' }}
                      disabled={!getFieldValid('businessName')}
                    >
                      Create Business
                    </button>
                  </FlexRow>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FlexRow>
                    <FlexGroup
                      onBlur={() => {
                        validate(
                          'schoolId',
                          articulationState.schoolId,
                          articulationState
                        );
                      }}
                    >
                      <InstituteSelectBox
                        includeBusinesses={true}
                        label="Institution or Business Partner"
                        selection={articulationState.schoolId}
                        onChange={onFormChange}
                      />
                      {getError('schoolId') && (
                        <p className="u-color-red">{getError('schoolId')}</p>
                      )}
                    </FlexGroup>
                  </FlexRow>
                  <FlexRow>
                    <Button
                      style={{ color: '#007d89' }}
                      text="+ Add New Business"
                      className="button--flat u-reduce-margin-top"
                      onClick={() => setCreateNewBusiness(true)}
                    />
                  </FlexRow>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <FlexRow>
              <FlexGroup
                onBlur={() => {
                  validate(
                    'schoolId',
                    articulationState.schoolId,
                    articulationState
                  );
                }}
              >
                <SchoolSelectBox
                  filter={getReduxFilter(
                    'programLevels[0].programLevelId',
                    [1],
                    'and'
                  )}
                  label="School"
                  selection={articulationState.schoolId}
                  onChange={onFormChange}
                />
                {getError('schoolId') && (
                  <p className="u-color-red">{getError('schoolId')}</p>
                )}
              </FlexGroup>
            </FlexRow>
          )}
          <FlexRow>
            <FlexGroup
              onBlur={() => {
                validate(
                  'contactId',
                  articulationState.contactId,
                  articulationState
                );
              }}
            >
              <ContactSelectBox
                selectedContact={articulationState.contactId}
                onChange={setContact}
              />
              {getError('contactId') && (
                <p className="u-color-red">{getError('contactId')}</p>
              )}
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <div className="u-flex-grow-1" />
            <MiniContactForm contactSaved={onContactSaved} />
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={getError('year')}
              name="year"
              label="Year of Agreement"
              onBlur={() => validate('year', articulationState.year)}
              onChange={onFormChange}
              valid={getFieldValid('year')}
              value={articulationState.year}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup
              onBlur={() => {
                validate('schoolId', articulationState.schoolId);
              }}
            >
              <Toggle
                name="isCurrent"
                label="It is recommended that Articulation Agreements be reviewed on an annual basis."
                description="This articulation is still current."
                value={articulationState.isCurrent}
                onChange={onFormChange}
              />
              {getError('isCurrent') && (
                <p className="u-color-red">{getError('isCurrent')}</p>
              )}
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Articulation agreement</label>
              {articulationState.filePath ? (
                <AzureDownloader
                  onClose={handleFileClose}
                  uploadParams={uploadParams}
                  filepath={articulationState.filePath}
                />
              ) : (
                <Button
                  text="+ Upload an articulation agreement"
                  className="form__btn--add u-flex-self-start"
                  onClick={toggleModal}
                />
              )}
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup
              onBlur={() => {
                validate('filePath', articulationState.filePath);
              }}
            >
              <AzureUploader
                onClose={toggleModal}
                open={isOpen}
                uploadParams={uploadParams}
                onConfirm={handleFileUpload}
              />
              {getError('filePath') && (
                <p className="u-color-red">{getError('filePath')}</p>
              )}
            </FlexGroup>
          </FlexRow>
        </div>
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/programs/articulations/${programInstanceId}`}
          disabled={!isValid}
          saveData={handleSave}
          saveText="Save Articulation"
          saveUrl={`/programs/articulations/${programInstanceId}`}
          validationErrors={validationErrors}
        />
      </Paper>
    </React.Fragment>
  );
};
