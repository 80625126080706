import { NavigationTitle } from './NavigationTitle.component';
import { connect } from 'react-redux';
import { getNavTrayClosed } from 'redux/application/selectors';
import { setNavTrayClosed } from 'redux/application';

const mapStateToProps = (state: any, ownProps: any) => {
  const navTrayClosed = getNavTrayClosed(state);
  return {
    navTrayClosed,
  };
};

const dispatchProps = {
  setNavTrayClosed,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(NavigationTitle);
