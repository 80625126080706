import { connect } from 'react-redux';
import {
  getProgramOfStudyById,
  getIsDataFetching_ProgramOfStudy,
  getProgram,
} from 'redux/programs/_selectors';
import {
  loadProgramOfStudyData,
  saveProgramOfStudyData,
} from 'redux/programs/programofstudy';
import { loadSingleProgramInstance } from 'redux/programs/programinstance';
import { ProgramOfStudyComponent } from './ProgramOfStudyAddEdit.component';
import { insertPrograms } from 'redux/programs/programinstance';
import {
  getRecords_Schools,
  getRecords_Departments,
} from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const posId = ownProps.match.params.programOfStudyId;
  const programId = ownProps.match.params.programInstanceId;
  const programOfStudy = getProgramOfStudyById(state, Number(posId));
  const isFetching = getIsDataFetching_ProgramOfStudy(state);
  const program = getProgram(state, Number(programId));
  const schools = getRecords_Schools(state);
  const departments = getRecords_Departments(state);

  return {
    program,
    programOfStudy,
    isFetching,
    schools,
    departments,
  };
};

const dispatchToProps = {
  loadProgramOfStudyData,
  loadSingleProgramInstance,
  insertPrograms,
  saveProgramOfStudyData,
};

export const ProgramOfStudy = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramOfStudyComponent);
