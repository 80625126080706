export interface AwardAmounts {
  personnel: number;
  travel: number;
  equipment: number;
  other: number;
}

export const emptyAwardAmounts = (): AwardAmounts => {
  return {
    personnel: 0,
    travel: 0,
    equipment: 0,
    other: 0,
  };
};
