import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDebounce } from 'hooks';
import { getFilteredData } from 'util/filterUtility';
import { TableColumnProps } from '../Table/Table.component';
import Icon from '../Icons';
import Label from '../FormElements/Label';

type SearchBarProps = {
  headers: TableColumnProps[]; //Should be the same as dataTable columns
  data: any[];
  id?: string;
  isFetching?: boolean;
  setFilteredData: Function;
  label?: string;
  shouldReset?: boolean;
  searchValue?: string;
};

export const SearchBarFilter: React.FC<SearchBarProps> = (props) => {
  const {
    data,
    headers,
    id,
    isFetching,
    label,
    setFilteredData,
    shouldReset,
    searchValue,
  } = props;

  const [internalHeaders, setInternalHeaders] = useState<any[]>([...headers]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 750);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
  };

  const getClassName = () => {
    return isFetching
      ? 'search-bar-filter__input u-sec-ro'
      : 'search-bar-filter__input';
  };

  useEffect(() => {
    if (internalHeaders.length < 1) {
      setInternalHeaders(headers);
    }
  }, [headers, internalHeaders, setInternalHeaders]);

  useEffect(() => {
    if (searchValue) {
      setSearchTerm(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    const filteredData = getFilteredData(
      data,
      internalHeaders,
      debouncedSearchTerm
    );
    setFilteredData(filteredData);
  }, [internalHeaders, setFilteredData, debouncedSearchTerm, data]);

  useEffect(() => {
    shouldReset && setSearchTerm('');
  }, [shouldReset, data]);

  return (
    <div className="search-bar-filter">
      <Label
        className="search-bar-filter__label"
        htmlFor={id ? id : 'search-bar'}
        name={label ? label : 'Search'}
      />
      <input
        id={id ? id : 'search-bar'}
        onChange={handleChange}
        type="search"
        className={getClassName()}
        value={searchTerm}
      />
      <Icon name="search" className="search-bar-filter__icon" />
    </div>
  );
};
