import React from 'react';
import { useParams } from 'react-router';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import ASPWorkflow from 'components/Grants/Applications/AnnualSpendingPlan/ASPWorkflow';

export const GrantSpendingPlanApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <ASPWorkflow grantFiscalYearId={grantFiscalYearId} />
      </GrantsLayout>
    </BaseLayout>
  );
};
