export const NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS =
  '[NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS]';

export const SET_NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS = `${NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS} SET`;
export const SET_NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_LOADING = `${NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS} LOADING`;

export const setNeedsAssessmentAddressableElements = (payload: any) => {
  return {
    type: SET_NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_NeedsAssessmentAddressableElements = (
  payload: boolean
) => {
  return {
    type: SET_NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
