import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { map, find } from 'lodash';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { FlexGroup } from 'layouts';
import { isApplicaionIdValid } from 'util/global';
import {
  CourseCreditProgram,
  CourseCreditRequired,
  CourseCreditGeneral,
  CourseCreditAward,
  ProgramAward,
  CourseCreditElective,
  Course,
  CourseType,
  emptyCourse,
} from 'types';
import CreditInput from 'components/Common/FormElements/CreditInput';
import CreditsBar from 'components/Program/CourseCredits/CreditsBar';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import { compose, prop } from 'util/objectUtility';
import { CourseCreditCustom } from 'types/program/CourseCreditCustom.types';
import { readCustomCourses } from 'services/programs/coursecredits.services';

type CourseCreditsSummaryComponentProps = {
  approvedCourses: Course[];
  courseCredits: CourseCreditProgram;
  courses: Course[];
  courseTypes: CourseType[];
  loadProgramCourseCredits: Function;
  setCurrentNavAnchor: Function;
  totalCredits: number[];
  isCccsCollege: boolean;
};

export const CourseCreditsSuperSummaryComponent: React.FC<CourseCreditsSummaryComponentProps> = (
  props
) => {
  const {
    approvedCourses,
    courseCredits,
    courseTypes,
    courses,
    loadProgramCourseCredits,
    setCurrentNavAnchor,
    totalCredits,
    isCccsCollege,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);
  const [customCourses, setCustomCourses] = useState<CourseCreditCustom[]>([]);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      readCustomCourses(programInstanceId).then((value) => {
        setCustomCourses(value);
      });
    }
  }, [programInstanceId]);

  useEffect(() => {
    if (!isApplicaionIdValid(programInstanceId)) return;
    loadProgramCourseCredits(programInstanceId);
  }, [loadProgramCourseCredits, programInstanceId]);

  const getCourse = (courseId: number) => {
    const courseInfo = find(approvedCourses, (course: Course) => {
      return course.courseId === courseId;
    });
    if (courseInfo) {
      return courseInfo;
    } else {
      return emptyCourse();
    }
  };
  const getCourseType = (courseTypeId: string): string => {
    const courseType = find(courseTypes, (type: CourseType) => {
      return type.courseTypeId === courseTypeId;
    });
    if (courseType) {
      return courseType.courseTypeName;
    }
    return '';
  };

  const getCourseDeets = (courseId: number) => {
    return find(courses, { courseId });
  };

  const getCourseInfo = compose(
    prop('courseDescription'),
    getCourseDeets,
    prop('courseId')
  );

  return (
    <Paper>
      <SummaryScroll componentStep="coursecredits" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('coursecredits')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      {courseCredits ? (
        <React.Fragment>
          <div className="award-legend">
            <h2 className="award-legend__title">Award Legend</h2>
            <div className="award-legend__items">
              {map(
                courseCredits.programAwards,
                (award: ProgramAward, index: number) => {
                  return (
                    <div key={index} className="award-legend__item">
                      <p className="award-legend__number">{index + 1}</p>
                      {award.certOrDegreeTitle}
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <CreditsBar
            creditTotals={totalCredits}
            courseCredits={courseCredits}
          />
          <h2 className="u-section-title-text--credits">Required Courses</h2>
          <div className="course-credits__info--labels">
            <div className="course-credits__input-container">
              <label className="course-credits__input-container__course-container">
                Course
              </label>
              <label className="course-credits__input-container__type-container">
                Type
              </label>
              <div className="course-credits__input-container__contacts-credits-container">
                <label className="course-credits__input-container__contact-container">
                  Contact
                </label>
                <label className="course-credits__input-container__credits-container">
                  Credits
                </label>
              </div>
            </div>
          </div>
          <FlexGroup>
            {map(
              courseCredits.requiredCourses,
              (course: CourseCreditRequired, index: number) => {
                const courseInfo = getCourse(course.courseId);
                return (
                  <>
                    <FlexGroup>
                      <div
                        key={index}
                        className="course-credits__input-row course-credits__input-row--summary"
                      >
                        <div className="course-credits__container">
                          <label className="course-credits__course-name">
                            {courseInfo.courseName}
                          </label>
                        </div>
                        <div className="course-credits__info">
                          <div className="course-credits__input-container">
                            <div className="course-credits__input-container__course-container">
                              <p>
                                {courseInfo.prefix}-{courseInfo.courseNumber}
                              </p>
                            </div>
                            <div className="course-credits__input-container__type-container">
                              <p>{getCourseType(course.courseTypeId)}</p>
                            </div>
                            <div className="course-credits__input-container__contact-credits-container">
                              <div className="course-credits__input-container__contact-container">
                                <CreditInput
                                  value={course.contactHours}
                                  disabled={true}
                                  className="course-credits__input course-credits__input--summary"
                                />
                              </div>
                            </div>
                            <div className="course-credits__input-container__credits-container">
                              <CreditInput
                                value={course.defaultCredits}
                                disabled={true}
                                className="course-credits__input course-credits__input--summary"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="course-credits__credits">
                          <div className="form__group">
                            <div className="course-credits__input-row">
                              {map(
                                course.awards,
                                (award: CourseCreditAward, index: number) => {
                                  return (
                                    <CreditInput
                                      key={index}
                                      value={award.credits}
                                      disabled={true}
                                      className="course-credits__input course-credits__input--summary"
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>{getCourseInfo(course)}</p>
                    </FlexGroup>
                    <br />
                    <hr />
                    <br />
                  </>
                );
              }
            )}
          </FlexGroup>

          {!isCccsCollege && (
            <React.Fragment>
              <h2 className="u-section-title-text--credits">
                Custom CTE Courses
              </h2>
              <div className="course-credits__info--labels">
                <div className="course-credits__input-container">
                  <label className="course-credits__input-container__course-container">
                    Course
                  </label>
                  <label className="course-credits__input-container__type-container">
                    Type
                  </label>
                  <div className="course-credits__input-container__contacts-credits-container">
                    <label className="course-credits__input-container__contact-container">
                      Contact
                    </label>
                  </div>
                </div>
              </div>
              <FlexGroup>
                {map(
                  customCourses,
                  (courseInfo: CourseCreditCustom, index: number) => {
                    return (
                      <div
                        key={index}
                        className="course-credits__input-row course-credits__input-row--summary"
                      >
                        <div className="course-credits__container">
                          <label className="course-credits__course-name">
                            {courseInfo.courseName}
                          </label>
                        </div>
                        <div className="course-credits__info">
                          <div className="course-credits__input-container">
                            <div className="course-credits__input-container__course-container">
                              <p>
                                {courseInfo.coursePrefix}-
                                {courseInfo.courseNumber}
                              </p>
                            </div>
                            <div className="course-credits__input-container__type-container">
                              <p>{getCourseType(courseInfo.courseTypeId)}</p>
                            </div>
                            <div className="course-credits__input-container__contact-credits-container">
                              <div className="course-credits__input-container__contact-container">
                                <CreditInput
                                  value={courseInfo.contactHours}
                                  disabled={true}
                                  className="course-credits__input course-credits__input--summary"
                                />
                              </div>
                            </div>
                            <div className="course-credits__input-container__credits-container"></div>
                          </div>
                        </div>
                        <div className="course-credits__credits">
                          <div className="form__group">
                            <div className="course-credits__input-row">
                              {map(
                                courseInfo.awards,
                                (award: CourseCreditAward, index: number) => {
                                  return (
                                    <CreditInput
                                      key={index}
                                      value={award.credits}
                                      disabled={true}
                                      className="course-credits__input course-credits__input--summary"
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </FlexGroup>
            </React.Fragment>
          )}

          <h2 className="u-section-title-text--credits">
            General Education Courses
          </h2>
          {map(
            courseCredits.generalEducationCourses,
            (general: CourseCreditGeneral, index: number) => {
              return (
                <div
                  key={index}
                  className="course-credits__input-row course-credits__input-row--summary"
                >
                  <div className="course-credits__container">
                    <label className="course-credits__course-name">
                      {general.title}
                    </label>
                  </div>
                  <div className="course-credits__info">
                    <div className="course-credits__input-row">
                      <FlexGroup>
                        <pre>
                          <p className="course-credits__pre">
                            {general.courses}
                          </p>
                        </pre>
                      </FlexGroup>
                    </div>
                  </div>
                  <div className="course-credits__credits">
                    <FlexGroup>
                      <div className="course-credits__input-row course-credits__input-row--top">
                        {map(
                          general.awards,
                          (award: CourseCreditAward, index: number) => {
                            return (
                              <div
                                key={index}
                                className="course-credits__credit-input"
                              >
                                <CreditInput
                                  value={award.credits}
                                  disabled={true}
                                  className="course-credits__input course-credits__input--summary"
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </FlexGroup>
                  </div>
                </div>
              );
            }
          )}
          <h2 className="u-section-title-text--credits">Elective Courses</h2>
          {map(
            courseCredits.electiveCourses,
            (elective: CourseCreditElective, index: number) => {
              return (
                <div
                  key={index}
                  className="course-credits__input-row course-credits__input-row--summary"
                >
                  <div className="course-credits__container">
                    <label className="course-credits__course-name">
                      {elective.certOrDegreeTitle}
                    </label>
                  </div>
                  <div className="course-credits__info">
                    <div className="course-credits__input-row">
                      <FlexGroup>
                        <p>{elective.courses}</p>
                      </FlexGroup>
                    </div>
                  </div>
                  <div className="course-credits__credits">
                    <FlexGroup>
                      <div className="course-credits__input-row">
                        {map(
                          courseCredits.programAwards,
                          (award: ProgramAward, index: number) => {
                            return elective.programAwardId !==
                              award.programAwardId ? (
                              <div key={index}>
                                <CreditInput
                                  value={0}
                                  disabled={true}
                                  className="course-credits__input course-credits__input--summary u-hide-input"
                                />
                              </div>
                            ) : (
                              <div key={index}>
                                <CreditInput
                                  value={elective.credits}
                                  disabled={true}
                                  className="course-credits__input course-credits__input--summary"
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </FlexGroup>
                  </div>
                </div>
              );
            }
          )}
        </React.Fragment>
      ) : null}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('coursecredits')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
