import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  deleteWithAuthOptions,
  catchError,
} from 'services';
import { ProgramOfStudy } from 'types';

const POS_ENDPOINT = 'api/ProgramOfStudy/';
const GET_POS_ENDPOINT = 'api/ProgramOfStudy/ByProgramInstanceId/';

export const readProgramOfStudys = async (
  programInstanceId: number
): Promise<ProgramOfStudy[]> => {
  const url = `${ENVIRONMENT.API_URL}${GET_POS_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError<ProgramOfStudy>('ProgramOfStudy', err);
    });
};

export const createProgramOfStudy = async (
  programInstanceId: number,
  pos: ProgramOfStudy
): Promise<ProgramOfStudy> => {
  const url = `${ENVIRONMENT.API_URL}${POS_ENDPOINT}${programInstanceId}`;
  const programOfStudy = {
    ...pos,
    secondarySchoolId: -1,
    postsecondarySchoolId: -1,
    secondarySchool: null,
    postSecondarySchool: null,
    secondaryProgram: null,
    postSecondaryProgram: null,
    postSecondaryDepartment: null,
    associateDegrees: [],
    certificates: [],
    postSecondaryCourses: [],
    generalEducationCourses: [],
  };
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(programOfStudy),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<ProgramOfStudy>('ProgramOfStudy', err);
    });
};

export const deleteProgramOfStudy = async (
  programInstanceId: number,
  programOfStudyId: number
): Promise<any> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${POS_ENDPOINT}${programInstanceId}/${programOfStudyId}`;
  return fetch(url, {
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<unknown>('ProgramOfStudy', err);
    });
};

export const approveProgramOfStudy = async (
  programInstanceId: number,
  programOfStudyId: number
): Promise<any> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${POS_ENDPOINT}approve/${programInstanceId}/${programOfStudyId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<unknown>('ProgramOfStudy', err);
    });
};

export const declineProgramOfStudy = async (
  programInstanceId: number,
  programOfStudyId: number
): Promise<any> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${POS_ENDPOINT}deny/${programInstanceId}/${programOfStudyId}`;
  return fetch(url, {
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<unknown>('ProgramOfStudy', err);
    });
};
