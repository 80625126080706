import { connect } from 'react-redux';
import { PermissionRole } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { getRecords_PermissionRoles } from 'redux/permissions/permissionroles.selectors';
import { EditRoleDetailsComponent } from './EditRoleDetails.component';
import {
  loadPermissionRoles,
  savePermissionRole,
} from 'redux/permissions/permissionroles';

export const mapStateToProps = (state: ApplicationState) => {
  const roles: PermissionRole[] = getRecords_PermissionRoles(state);

  return {
    roles,
  };
};

const dispatchProps = {
  savePermissionRole,
  loadPermissionRoles,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(EditRoleDetailsComponent);
