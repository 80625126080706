import { CTAFiscalYearProgramSupport } from '.';
import { emptyCTAFiscalYearProgramSupport } from './CTAFiscalYearProgramSupport.types';
import { CTASupportProgramAffiliation } from './CTASupportProgramAffiliation.types';

export interface CTASupportPersonnel {
  actualSalaryEarned: number;
  annualContractBaseSalary: number;
  ctaFiscalYearProgramSupport: CTAFiscalYearProgramSupport[];
  ctaSupportPersonnelId: number;
  ctaSupportProgramAffiliation: CTASupportProgramAffiliation[];
  isActive?: boolean;
  personnelName: string;
  salary: number;
  subsCteAbsenses: number;
  subsSickAnnual: number;
  totalPercent: number;
}

export const emptyCTASupportPersonnel = (): CTASupportPersonnel => {
  return {
    actualSalaryEarned: 0,
    annualContractBaseSalary: 0,
    ctaFiscalYearProgramSupport: [emptyCTAFiscalYearProgramSupport()],
    ctaSupportPersonnelId: 0,
    ctaSupportProgramAffiliation: [],
    isActive: true,
    personnelName: '',
    salary: 0,
    subsCteAbsenses: 0,
    subsSickAnnual: 0,
    totalPercent: 0,
  };
};
