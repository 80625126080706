import React from 'react';
import { TeacherInfo } from 'types/universalcontact/TeacherInfo.types';
import Icon from 'components/Common/Icons';
import { getCredentialString } from 'util/universalcontact.utility';

type TeacherCredentialsProps = {
  teacherInfo: TeacherInfo;
};

export const TeacherCredentials: React.FC<TeacherCredentialsProps> = (
  props
) => {
  const { teacherInfo } = props;

  return (
    <Icon
      tooltip={getCredentialString(teacherInfo)}
      title={`Teacher Indicator`}
      name="lnrGraduationHat"
      className="u-icon-base-size u-icon-blue u-cursor-help u-margin-right"
    />
  );
};
