import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useModal, Modal } from 'react-morphing-modal';
import { Dialog } from '@rmwc/dialog';
import { map } from 'lodash';
import {
  always,
  firstMatch,
  identity,
  mergeObjects,
  safeGet,
} from 'util/objectUtility';
import { isEqual } from 'util/formHelpers';
import { FlexGroup, FlexRow } from 'layouts';
import {
  readGrantSchedule,
  updateGrantSchedule,
  uploadGrantScheduleExcelFile,
} from 'services/grants/grantschedule.services';
import { toast } from 'react-toastify';
import { loadGrantFiscalYearNav } from 'redux/nav/fiscalyearnav';
import {
  GrantSchedule,
  emptyGrantSchedule,
  AzureUploadParameters,
} from 'types';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Icon from 'components/Common/Icons';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import StringResources, { stringFormat } from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import Tooltip from 'components/Common/Tooltip';
import WarningAlert from 'components/Common/WarningAlert';

type GrantScheduleProps = {
  anchor: string;
  title: string;
  options: {
    toggleOpen: boolean;
    fileUpload: string | boolean;
    voucher: boolean;
  };
};

export const GrantScheduleComponent: React.FC<GrantScheduleProps> = (props) => {
  const { anchor, title, options } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { fiscalYear: fy }: any = useParams();
  const fiscalYear = Number(fy);
  const { modalProps, open, close } = useModal();

  // --[ local state ]---------------------------------------------------------
  const modalRef = useRef(null);
  const [schedule, setSchedule] = useState<GrantSchedule>(emptyGrantSchedule());
  const [isOpen, setOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [saveWithFileModal, setSaveWithFileModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isFileUploadDirty, setIsFileUploadDirty] = useState<boolean>(false);
  const [isFileValid, setIsFileValid] = useState<boolean>(true);

  // --[ component logic ]-----------------------------------------------------
  const get = safeGet(schedule);
  const toggleModal = () => setOpen(!isOpen);

  const onChange = (data: Partial<GrantSchedule>) => {
    setSchedule(mergeObjects<GrantSchedule>(schedule, data));
    setIsDirty(true);
  };

  const handleConfirmOverride = (): void => {
    setModalOpen(false);
    const data = {
      isOpen: !get('status'),
      isOverride: !get('isOverride'),
    };
    setSchedule(mergeObjects<GrantSchedule>(schedule, data));
    setIsDirty(true);
  };

  const handleFileUpload = (filePath: string = '') => {
    if (filePath === '') {
      setIsFileUploadDirty(false);
      setIsFileValid(true);
    } else {
      setIsFileUploadDirty(true);
    }
    const data = { filePath };
    onChange(data);
  };

  // disable file editing if file has already been uploaded and saved
  const disableFileUpload = (
    isFileUploadDirty: boolean,
    schedule: GrantSchedule
  ) => {
    return !isFileUploadDirty && !!schedule.filePath.length;
  };

  // generate AzureUploadParameters for grantschedule file uploader
  const uploadParams = (
    fiscalYear: number,
    anchor: string
  ): AzureUploadParameters => ({
    container: 'grants',
    folder: `${fiscalYear}|grant-schedule|${anchor}`,
  });

  // --[ services ]-----------------------------------------------------
  const uploadExcelFile = async (file: File) => {
    setIsLoading(true);
    return uploadGrantScheduleExcelFile(file, fiscalYear, anchor)
      .then((errors: string[]) => {
        if (errors.length > 0) {
          setIsFileValid(false);
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.FileSaveFailed}
            />
          );
          setErrors(errors);
          open(modalRef);
        } else {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.FileSaved}
            />
          );
          setIsFileValid(true);
        }
      })
      .catch(() => {
        setIsFileValid(false);
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileSaveFailed}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  const loadGrantSchedule = async () => {
    setIsLoading(true);
    return readGrantSchedule(anchor, fiscalYear)
      .then((data: GrantSchedule) => {
        if (data.isOpen === null) {
          data.isOpen = false;
        }
        if (data.isOverride === null) {
          data.isOverride = false;
        }
        setSchedule(mergeObjects<GrantSchedule>(schedule, data));
        dispatch(loadGrantFiscalYearNav(fiscalYear));
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={stringFormat(StringResources.Errors.LoadNamedDataFailed, [
              title,
            ])}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  const saveSchedule = () => {
    updateGrantSchedule(anchor, fiscalYear, schedule)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.FileSaved}
          />
        );
      })
      .then(() => setIsDirty(false))
      .then(() => loadGrantSchedule())
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={stringFormat(StringResources.Errors.LoadNamedDataFailed, [
              title,
            ])}
          />
        );
      });
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    loadGrantSchedule();
  }, [fiscalYear, anchor]); //eslint-disable-line

  // --[ display logic ]-------------------------------------------------------
  // if isOverride then return isOpen, otherwise return status
  // this logic is very delicate, highly recommended not to touch this function
  const getStatus = () => {
    return get('isOverride') ? get('isOpen') : get('status');
  };

  // getUploadName :: string -> string
  const getUploadName = firstMatch([
    [isEqual('Four-Year Plan'), always('Needs Assessment')],
    [isEqual('Final Award Packet'), always('Final Award Amount')],
    [always(true), identity],
  ]);

  // silly hack to fix edge case where the name of the component doesn't match the navigation name
  const getTitle = (title: string) => {
    return title === 'Spending Plans'
      ? 'Annual Spending Plan, Annual Reflection, and Assurances'
      : title;
  };

  // generates filled button if grant schedule is dirty
  const getButtonClass = (isDirty: boolean) => {
    return isDirty
      ? 'button--filled grant-schedule__save'
      : 'button--outline grant-schedule__save';
  };

  // when override active, disable the ability to change dates
  const getDatePickerClass = () => {
    return get('isOverride')
      ? 'grant-schedule__option grant-schedule__date u-sec-ro'
      : 'grant-schedule__option grant-schedule__date';
  };

  const getStatusIconClass = () => {
    return getStatus()
      ? 'grant-schedule__icon grant-schedule__icon--open'
      : 'grant-schedule__icon grant-schedule__icon--closed';
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Loading isActive={isLoading} />
        {/* Fileupload Modal */}
        <Dialog
          open={saveWithFileModal}
          onClose={() => setSaveWithFileModal(false)}
        >
          <div className="dialog">
            <h2 className="dialog__title">
              Danger: This action cannot be undone!
            </h2>
            <div className="dialog__children">
              <p className="u-error">
                You are about to save a file upload. Once a file upload has been
                saved, it can only be removed by manually removing it from the
                database. Are you sure you wish to save?
              </p>
            </div>
            <div className="dialog__btn-container">
              <Button
                onClick={() => setSaveWithFileModal(false)}
                className="form__btn--cancel"
                text="Cancel"
              />
              <Button
                onClick={() => {
                  saveSchedule();
                  setSaveWithFileModal(false);
                }}
                className="confirm-button form__btn--submit"
                text="I understand the risk"
              />
            </div>
          </div>
        </Dialog>
        {/* Override Modal */}
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="dialog">
            <h2 className="dialog__title">Are you sure?</h2>
            <div className="dialog__children">
              <p>
                Would you like to override the session and{' '}
                {getStatus() ? 'close it down' : 'open it up'}?
              </p>
              <p className="u-error">
                Caution: overriding the open and close dates will populate
                throughout the site. Grantees will not be notified of this
                change.
              </p>
            </div>
            <div className="dialog__btn-container">
              <Button
                onClick={() => setModalOpen(false)}
                className="form__btn--cancel"
                text="Cancel"
              />
              <Button
                onClick={handleConfirmOverride}
                className="confirm-button form__btn--submit"
                text={getStatus() ? 'Close it down' : 'Open it up'}
              />
            </div>
          </div>
        </Dialog>
        <div id={anchor} className="grant-schedule" ref={modalRef}>
          <FlexRow>
            <h2 id={getTitle(title)} className="u-section-title-text">
              {getTitle(title)}
            </h2>
          </FlexRow>
          <div className="grant-schedule__row">
            <div className="grant-schedule__options">
              {options.toggleOpen && (
                <React.Fragment>
                  <div className="grant-schedule__option grant-schedule__status">
                    <p className="form__label">Status</p>
                    <div className="grant-schedule__icon-group">
                      <Icon
                        title={`${getTitle(title)} Status ${
                          getStatus() ? 'Open' : 'Closed'
                        }`}
                        name="circleFilled"
                        className={getStatusIconClass()}
                      />
                      <p className="grant-schedule__status-text">
                        {getStatus() ? 'Open' : 'Closed'}
                      </p>
                    </div>
                  </div>
                  <div className={getDatePickerClass()}>
                    <DatePicker
                      name="startDate"
                      label="Open Date"
                      onChange={onChange}
                      value={get('startDate')}
                    />
                  </div>
                  <div className={getDatePickerClass()}>
                    <DatePicker
                      name="endDate"
                      label="Closing Date"
                      onChange={onChange}
                      value={get('endDate')}
                    />
                  </div>
                  <div className="grant-schedule__option grant-schedule__override">
                    <Toggle
                      name="isOverride"
                      label="Override"
                      value={get('isOverride')}
                      onChange={() => setModalOpen(true)}
                      description={get('isOverride') ? 'On' : 'Off'}
                      ariaLabel={`${getTitle(title)} Override`}
                    />
                  </div>
                </React.Fragment>
              )}
              {options.fileUpload && (
                <div className="grant-schedule__option grant-schedule__upload">
                  <FlexGroup>
                    {disableFileUpload(isFileUploadDirty, schedule) ? (
                      <div className="u-sec-ro">
                        <p className="form__label">{getUploadName(title)}</p>
                      </div>
                    ) : (
                      <p className="form__label">{getUploadName(title)}</p>
                    )}
                    {get('filePath') ? (
                      <AzureDownloader
                        customTruncate={20}
                        editable={
                          !disableFileUpload(isFileUploadDirty, schedule)
                        }
                        onClose={() => handleFileUpload('')}
                        uploadParams={uploadParams(fiscalYear, anchor)}
                        filepath={get('filePath')}
                      />
                    ) : (
                      <Button
                        text="+ Upload File"
                        className="form__btn--add u-flex-self-start grant-schedule__upload-btn"
                        onClick={toggleModal}
                      />
                    )}
                    <AzureUploader
                      onClose={toggleModal}
                      open={isOpen}
                      uploadParams={uploadParams(fiscalYear, anchor)}
                      onConfirm={handleFileUpload}
                      uploadFileToDB={uploadExcelFile}
                    />
                  </FlexGroup>
                </div>
              )}
            </div>
            {options.fileUpload && !!get('filePath')?.length ? (
              <Button
                className={getButtonClass(isDirty)}
                disabled={!isFileValid}
                onClick={() => setSaveWithFileModal(true)}
                text="save"
              />
            ) : (
              <Button
                className={getButtonClass(isDirty)}
                disabled={!isFileValid}
                onClick={saveSchedule}
                text="save"
              />
            )}
          </div>
          {options.voucher && (
            <FlexRow>
              <div className="grant-schedule__option grant-schedule__voucher">
                <FlexGroup>
                  <div className="grant-schedule__info-label">
                    <h2 className="u-section-title-text u-margin-bottom-medium">
                      Voucher
                    </h2>
                    <Tooltip
                      indicator={
                        <Icon
                          name="info"
                          className="grant-schedule__label-icon"
                        />
                      }
                      tooltip="Vouchers are opened automatically after the user has completed their award packet and it has been approved. The close date stipulates the last date when the final voucher can be submitted by a grantee."
                    />
                  </div>
                  <DatePicker
                    name="voucherClose"
                    onChange={onChange}
                    value={get('voucherClose')}
                  />
                </FlexGroup>
              </div>
            </FlexRow>
          )}
        </div>
        <FlexRow>
          <FlexGroup>
            {isDirty && (
              <div className="grant-schedule__error">
                <WarningAlert>You have unsaved changes.</WarningAlert>
              </div>
            )}
            {!isFileValid && (
              <div className="grant-schedule__error">
                <WarningAlert>
                  The file you tried to upload is invalid.
                </WarningAlert>
              </div>
            )}
          </FlexGroup>
        </FlexRow>
      </React.Fragment>
      {/* File Upload Errors */}
      <Modal {...modalProps}>
        <Paper>
          <h2>File Upload Encountered Errors</h2>
          <div>
            <p>The following errors were encountered during file upload:</p>
            {map(errors, (error: string) => {
              return <p className="u-error">Error: {error}</p>;
            })}
          </div>
          <div className="form__btn-container">
            <Button
              onClick={close}
              className="form__btn--cancel"
              text="Close Error Window"
            />
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
