import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { PermissionRole } from 'types';

const PERMISSION_UC_ROLES_ENDPOINT = 'api/PermissionRoles/byucid/';
const ENDPOINT = `${ENVIRONMENT.API_URL}${PERMISSION_UC_ROLES_ENDPOINT}`;

export const readContactPermissionRoles = async (
  universalContactId: number
) => {
  return fetch(`${ENDPOINT}${universalContactId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};

export const createContactPermissionRoles = async (
  universalContactId: number,
  roles: PermissionRole[]
) => {
  return fetch(`${ENDPOINT}${universalContactId}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(roles),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};
