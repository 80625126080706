import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getAnnualSpendingPlan,
  getIsDataFetching_AnnualSpendingPlan,
} from 'redux/grants/_selectors';
import { AnnualSpendingPlanSummaryComponent } from './AnnualSpendingPlanSummary.component';
import { AnnualSpendingPlanEditComponent } from './AnnualSpendingPlanEdit.component';
import { loadStrategySelectItem } from 'redux/grants/strategyselectitem';
import { getLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any) => {
  const annualSpendingPlan = getAnnualSpendingPlan(state);
  const isLoading = getIsDataFetching_AnnualSpendingPlan(state);

  const isLocked = getLockedStatus(state, 'spending-plans');

  return {
    annualSpendingPlan,
    isLoading,
    isLocked,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  loadStrategySelectItem,
};

export const AnnualSpendingPlanEdit = connect(
  mapStateToProps,
  dispatchProps
)(AnnualSpendingPlanEditComponent);

export const AnnualSpendingPlanSummary = connect(
  mapStateToProps,
  dispatchProps
)(AnnualSpendingPlanSummaryComponent);
