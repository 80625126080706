import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { readCIPCodes } from 'services/common/cipcodes.service';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CipCode } from 'types';

const initialState = baseInitialState;

export const loadCIPCodes = createActionThunk('loadCIPCodes', () => {
  return readCIPCodes();
}); // end

export default handleActions<BaseStateObject, CipCode[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<CipCode[]>(loadCIPCodes),
  },
  initialState
);
