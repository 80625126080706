import { COMMON_KEY, COMMON_DISTRICTS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { District, ItemFilter } from 'types';
import { find } from 'lodash';

export const DISTRICTS_PATH = [COMMON_KEY, COMMON_DISTRICTS_KEY];

export const getIsDataFetching_Districts = (state: any): boolean => {
  return getIsDataFetching(state, DISTRICTS_PATH);
};

export const getRecords_Districts = (state: any): District[] => {
  return getRecords<District>(state, DISTRICTS_PATH);
};

export const shouldRefreshReduxState_Districts = (state: any): boolean => {
  return shouldRefreshReduxState<District>(state, DISTRICTS_PATH);
};

export const getLastUpdated_Districts = (state: any): number => {
  return getLastUpdated(state, DISTRICTS_PATH);
};

export const getFilteredRecords_Districts = (
  state: any,
  filter: ItemFilter
): District[] => {
  return getFilteredRecords<District>(state, DISTRICTS_PATH, filter);
};

export const getDistrictById = (state: any, districtId: number) => {
  return find(getRecords_Districts(state), { districtId });
};
