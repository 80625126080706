import { CTA_KEY, CTA_DIRECTOR_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';

export const CTA_DIRECTOR_PATH = [CTA_KEY, CTA_DIRECTOR_KEY];

export const getIsDataFetching_CTADirector = (state: any): boolean => {
  return getIsDataFetching(state, CTA_DIRECTOR_PATH);
};

export const getRecords_CTADirector = (state: unknown): CTAAdministrator[] => {
  return getRecords(state, CTA_DIRECTOR_PATH);
};
