import React, { useEffect, useState } from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import {
  createRadioOptionsForBool,
  createRadioToStringOnChange,
  convertStringToRadioValue,
  convertBoolToRadioValue,
  convertRadioToBoolValue,
} from 'util/formHelpers';
import { RadioOptionSchema, SnapshotStudent } from 'types';
import {
  SECONDARY_GRADE_OPTIONS as gradeOptions,
  SECONDARY_GRADUATE_OPTIONS as graduateOptions,
  SECONDARY_CERT_OPTIONS as certOptions,
  SECONDARY_WBL_TYPE_OPTIONS as wblTypeOptions,
  SECONDARY_REGISTERED_APPRENTICESHIP_OPTIONS as registeredApprenticeshipOptions,
} from 'constants/radioOptionSchema.const';
import { sortBy } from 'lodash';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import Input from 'components/Common/FormElements/Input';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Radio from 'components/Common/FormElements/Radio';
import Toggle from 'components/Common/FormElements/Toggle';

export const AcademicInformationS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v, enrollment } = props;

  const handleBooleanChange = (data: Partial<SnapshotStudent>) => {
    const updated = convertRadioToBoolValue<SnapshotStudent>(data);
    onChange(updated.data, updated.name, updated.val);
  };

  const [homeSchoolToggle, setHomeSchoolToggle] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<string>(enrollment.cdeId);

  useEffect(() => {
    if (schoolId !== enrollment.cdeId) {
      setSchoolId(enrollment.cdeId);
    }
  }, [enrollment.cdeId]); // eslint-disable-line

  useEffect(() => {
    if (!!schoolId && student && student.homeCde_ID === schoolId) {
      setHomeSchoolToggle(true);
    } else {
      setHomeSchoolToggle(false);
    }
  }, [student.homeCde_ID, schoolId]); // eslint-disable-line

  const convertGradeToRadioValue = convertStringToRadioValue(gradeOptions);
  const handleGradesChange = createRadioToStringOnChange(
    onChange,
    gradeOptions
  );

  const mapRadioOptions = (data: RadioOptionSchema) => {
    return sortBy(Object.values(data), 'label');
  };

  return student ? (
    <React.Fragment>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexGroup style={{ width: '25%' }}>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="participant"
                iconName="info"
                label="Participant"
                tooltip="A participant is a secondary student who has earned credit for one (1) or more courses within a CTE program, where course is defined as one Carnegie Unit of credit. For example, a student who completed one year-long course or two semester-long courses would be marked as a participant."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('participant')}
                name="participant"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('participant')}
                value={convertBoolToRadioValue(student.participant)}
                onBlur={() =>
                  v.validate('participant', student.participant, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="concentrator"
                iconName="info"
                label="Concentrator"
                tooltip="A secondary student who has completed two (2) or more Carnegie Units within a CTE program as defined in the program approval documentation. Typically, one Carnegie Unit is the equivalent of one year-long course, so in most cases a student will be a concentrator after completing two year-long courses within the program. We recognize that different schools may operate courses by semester, trimester, hexter, etc. and award differing credit amounts, so calculate Concentrator to the equivalent of two Carnegie Units or the equivalent of 240 contact hours."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('concentrator')}
                name="concentrator"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('concentrator')}
                value={convertBoolToRadioValue(student.concentrator)}
                onBlur={() =>
                  v.validate('concentrator', student.concentrator, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="completer"
                iconName="info"
                label="Completer"
                tooltip="A secondary student who has reached the point this reporting year of completing all or 100% of the requirements of at least one pathway sequence as defined in your program approval."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('completer')}
                name="completer"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('completer')}
                value={convertBoolToRadioValue(student.completer)}
                onBlur={() =>
                  v.validate('completer', student.completer, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <Radio
              border={true}
              errorMessage={v.getError('wbl')}
              label="Work Based Learning (WBL)"
              name="wbl"
              onChange={handleBooleanChange}
              options={createRadioOptionsForBool()}
              valid={v.getFieldValid('wbl')}
              value={convertBoolToRadioValue(student.wbl)}
              onBlur={() => v.validate('wbl', student.wbl, student)}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="registeredApprenticeship"
                iconName="info"
                label="Registered Apprenticeship"
                tooltip="Only apprenticeships that are registered through the Department of Labor should be reported as a 'Yes'."
                place="left"
              />
              <Radio //implement "unselect" option for registeredApprenticeship
                border={true}
                horizontal={false}
                name="registeredApprenticeship"
                onChange={onChange}
                options={registeredApprenticeshipOptions}
                value={
                  student.wbl === null
                    ? null
                    : student.wbl
                    ? student.registeredApprenticeship
                    : 1
                } //implement the validation rule between WBL and apprenticeship
                noLabel
                errorMessage={v.getError('registeredApprenticeship')}
                //valid={v.getFieldValid('registeredApprenticeship')}   --optional for 23-24 fiscal year
                onBlur={() =>
                  v.validate(
                    'registeredApprenticeship',
                    student.registeredApprenticeship,
                    student
                  )
                }
              />
            </FlexGroup>
          </FlexRow>
        </FlexGroup>

        <FlexGroup style={{ width: '50%' }}>
          <FlexRow>
            <Radio
              border={true}
              errorMessage={v.getError('grade')}
              horizontal={false}
              label="CDE Grade"
              name="grade"
              onChange={handleGradesChange}
              options={mapRadioOptions(gradeOptions)}
              valid={v.getFieldValid('grade')}
              value={convertGradeToRadioValue(student.grade)}
              onBlur={() => v.validate('grade', student.grade, student)}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="wblType"
                iconName="info"
                label="WBL Type"
                tooltip="Pick the type that best applies for this program. If WBL is 'no', the type should be 'no experience'."
                place="left"
              />
              <Radio
                border={true}
                horizontal={false}
                name="wblType"
                onChange={onChange}
                options={wblTypeOptions.filter(
                  (option) => !(option.id === 0 && student.wbl)
                )} //implement the validation rule between WBL and WBL Type
                value={
                  student.wbl === null
                    ? null
                    : student.wbl
                    ? student.wblType
                    : 0
                } //implement the validation rule between WBL and WBL Type
                noLabel
                errorMessage={v.getError('wblType')}
                //valid={v.getFieldValid('wblType')}   --optional for 23-24 fiscal year
                onBlur={() => v.validate('wblType', student.wblType, student)}
              />
            </FlexGroup>
          </FlexRow>
        </FlexGroup>

        <FlexGroup style={{ width: '25%' }}>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="instructorId"
                iconName="info"
                label="Instructor ID. (optional)"
                tooltip="First 3 characters of instructor’s last name.  Example: Mr. Smith becomes SMI."
                place="right"
              />
              <Input
                name="instructorId"
                noLabel
                onChange={onChange}
                value={student.instructorId}
                errorMessage={v.getError('instructorId')}
                valid={v.getFieldValid('instructorId')}
                onBlur={() =>
                  v.validate('instructorId', student.instructorId, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <Toggle
              border={true}
              label="Home School ID (optional)"
              name="toggle"
              description="Use Current School"
              onChange={() => {
                setHomeSchoolToggle(!homeSchoolToggle);
                onChange({ homeCde_ID: schoolId }, 'homeCde_ID', schoolId);
              }}
              value={homeSchoolToggle}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="homeCde_ID"
                iconName="info"
                label="Home School ID"
                tooltip="The 4-digit CDE school code for the student’s school of primary academic enrollment (not primary CTE program enrollment)."
                place="right"
              />
              <Input
                name="homeCde_ID"
                value={student.homeCde_ID}
                disabled={homeSchoolToggle}
                onChange={onChange}
                noLabel
                valid={v.getFieldValid('homeCde_ID')}
                errorMessage={v.getError('homeCde_ID')}
                onBlur={() =>
                  v.validate('homeCde_ID', student.homeCde_ID, student)
                }
              />
            </FlexGroup>
          </FlexRow>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="secondaryCert"
            iconName="info"
            label="Secondary Certification"
            tooltip="Students may have earned more than one credential during the academic year for this reporting period. Report as pertains to this period and program for this learner."
            place="left"
          />
          <Radio
            border={true}
            horizontal={false}
            name="secondaryCert"
            onChange={onChange}
            options={certOptions}
            value={student.secondaryCert}
            noLabel
            errorMessage={v.getError('secondaryCert')}
            //valid={v.getFieldValid('secondaryCert')}   --optional for 23-24 fiscal year
            onBlur={() =>
              v.validate('secondaryCert', student.secondaryCert, student)
            }
          />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <Radio
            border={true}
            horizontal={false}
            name="highSchoolGraduate"
            onChange={onChange}
            options={graduateOptions}
            value={student.highSchoolGraduate}
            label="High School Graduate"
            errorMessage={v.getError('highSchoolGraduate')}
            valid={v.getFieldValid('highSchoolGraduate')}
            onBlur={() =>
              v.validate(
                'highSchoolGraduate',
                student.highSchoolGraduate,
                student
              )
            }
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
