import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import {
  SET_ANNUAL_SPENDING_PLAN,
  SET_ANNUAL_SPENDING_PLAN_LOADING,
  UPDATE_SPENDING_CATEGORIES,
} from './annualspendingplan.actions';
import { AnnualSpending, SpendingCategory } from 'types';
import { upsertInPlace } from 'util/arrayUtility';

const initState = baseInitialState;

export const annualspendingplanReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_ANNUAL_SPENDING_PLAN:
      return actionUpdateRecords(state, action.payload);

    case UPDATE_SPENDING_CATEGORIES:
      const { records } = state;
      const newRecords = updateSpendingCategories(records, action.payload);
      return actionUpdateRecords(state, newRecords);

    case SET_ANNUAL_SPENDING_PLAN_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

const updateSpendingCategories = (
  records: AnnualSpending[],
  payload: SpendingCategory
) => {
  const [old] = records;
  const newRecords = {
    ...old,
    spendingCategories: upsertInPlace(
      old.spendingCategories,
      payload,
      'spendingCategoryId'
    ),
  };
  return newRecords;
};
