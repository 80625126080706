import { connect } from 'react-redux';
import { DualListBox } from './DualListBox.component';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(DualListBox);
