import React, { useEffect } from 'react';
import { prop, safeGet } from 'util/objectUtility';
import { FlexRow } from 'layouts';
import { CtaInstructorProgram } from 'types';
import { InstructorProgramValidation } from 'validations/CTA/InstructorProgram.validations';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';
import Tooltip from 'components/Common/Tooltip';

interface CteProgramAffiliationProps {
  index: number;
  onChange: Function;
  program: CtaInstructorProgram;
  submitFailed: boolean;
}
export const CteProgramAffiliation: React.FC<CteProgramAffiliationProps> = ({
  index,
  onChange,
  program,
  submitFailed,
}) => {
  // --[ dependencies ]--------------------------------------------------------
  const {
    getError,
    getFieldValid,
    validate,
    validateAll,
    validateIfTrue,
  } = InstructorProgramValidation();

  // --[ component logic ]-----------------------------------------------------
  const handleChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, program);
    onChange(program)(data);
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    submitFailed && validateAll(program);
  }, [submitFailed]); // eslint-disable-line

  // --[ display logic ]-------------------------------------------------------
  const substituteToolTip = (index: number) =>
    index === 0 ? (
      <Tooltip
        indicator={<Icon name="info" className="spending-category__tooltip" />}
        tooltip="Enter the number of whole or partial days of CTE-related absences for which a substitute was compensated (e.g., travel to a CTSO or CTE class field trip). Ensure documentation supports that these absences were CTE related."
      />
    ) : undefined;

  const stipendToolTip = (index: number) =>
    index === 0 ? (
      <Tooltip
        indicator={<Icon name="info" className="spending-category__tooltip" />}
        tooltip="Enter the amount of any stipends or extra duty pay for program specific CTE-related work performed outside of the instructor's contract (e.g., CTSO advisor, work-based learning supervision, district CTE liaison, summer work) for the reporting period (7/1 to 6/30). Only work supported by an Extra Duty Pay contract that stipulates the scope of the work, the amount to be paid for the additional CTE-related duties, and the timing of when the tasks should be completed is allowable. Do not report fringe benefits associated with these stipends as 30% will be automatically calculated."
      />
    ) : undefined;

  const get = safeGet(program);

  const getInputProps = (item: any) => {
    return {
      errorMessage: getError(item),
      name: item,
      onBlur: () => validate(item, get(item)),
      value: get(item),
      valid: getFieldValid(item),
    };
  };

  return (
    <fieldset>
      <legend>{`${prop('programName', program)}/${prop(
        'schoolName',
        program
      )}`}</legend>
      <FlexRow>
        <Input
          {...getInputProps('subSickDays')}
          label="Number of CTE Sub Days"
          onChange={handleChange}
          tooltip={substituteToolTip(index)}
          type="number"
        />
        <Input
          {...getInputProps('cteDutyStipend')}
          label="Extra CTE Duty Stipend"
          onChange={handleChange}
          tooltip={stipendToolTip(index)}
          type="number"
          errorMessage={getError('cteDutyStipend')}
          onBlur={() => {
            validate('cteDutyStipend', get('cteDutyStipend'));
          }}
        />
        <div style={{ width: '100%' }} />
      </FlexRow>
    </fieldset>
  );
};
