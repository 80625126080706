import React, { useEffect } from 'react';

/*
  Budget Hydration
  This component is used to refresh / hydrate data for common data types in the background
*/

type DataHydratorProps = {
  shouldRefreshProgramSteps: boolean;
  loadProgramSteps: Function;
  shouldRefreshProgramArea: boolean;
  loadProgramAreas: Function;
  shouldRefreshCipCodes: boolean;
  loadCIPCodes: Function;
  shouldRefreshCareerClusters: boolean;
  loadCareerClusters: Function;
  shouldRefreshProgramLengths: boolean;
  loadProgramLengths: Function;
  shouldRefreshProgramLevels: boolean;
  loadProgramLevels: Function;
  shouldRefreshCourses: boolean;
  loadCourses: Function;
  shouldRefreshAwardTypes: boolean;
  loadAwardTypes: Function;
  shouldRefreshCourseTypes: boolean;
  loadCourseTypes: Function;
  shouldRefreshCtsoCodes: boolean;
  loadCtsoCodes: Function;
  shouldRefreshProgramContactTypes: boolean;
  loadProgramContactTypes: Function;
  shouldRefreshProgramStates: boolean;
  loadProgramStates: Function;
};

export const ProgramDataHydrator: React.FC<DataHydratorProps> = (props) => {
  const { shouldRefreshProgramArea, loadProgramAreas } = props;
  useEffect(() => {
    shouldRefreshProgramArea && loadProgramAreas();
  }, [shouldRefreshProgramArea, loadProgramAreas]);

  const { shouldRefreshCipCodes, loadCIPCodes } = props;
  useEffect(() => {
    shouldRefreshCipCodes && loadCIPCodes();
  }, [shouldRefreshCipCodes, loadCIPCodes]);

  const { shouldRefreshCareerClusters, loadCareerClusters } = props;
  useEffect(() => {
    shouldRefreshCareerClusters && loadCareerClusters();
  }, [shouldRefreshCareerClusters, loadCareerClusters]);

  const { shouldRefreshProgramLengths, loadProgramLengths } = props;
  useEffect(() => {
    shouldRefreshProgramLengths && loadProgramLengths();
  }, [shouldRefreshProgramLengths, loadProgramLengths]);

  const { shouldRefreshProgramStates, loadProgramStates } = props;
  useEffect(() => {
    shouldRefreshProgramStates && loadProgramStates();
  }, [shouldRefreshProgramStates, loadProgramStates]);

  const { shouldRefreshProgramSteps, loadProgramSteps } = props;
  useEffect(() => {
    shouldRefreshProgramSteps && loadProgramSteps();
  }, [shouldRefreshProgramSteps, loadProgramSteps]);

  const { shouldRefreshProgramLevels, loadProgramLevels } = props;
  useEffect(() => {
    shouldRefreshProgramLevels && loadProgramLevels();
  }, [shouldRefreshProgramLevels, loadProgramLevels]);

  const { shouldRefreshCourses, loadCourses } = props;
  useEffect(() => {
    shouldRefreshCourses && loadCourses();
  }, [shouldRefreshCourses, loadCourses]);

  const { shouldRefreshAwardTypes, loadAwardTypes } = props;
  useEffect(() => {
    shouldRefreshAwardTypes && loadAwardTypes();
  }, [shouldRefreshAwardTypes, loadAwardTypes]);

  const { shouldRefreshCourseTypes, loadCourseTypes } = props;
  useEffect(() => {
    shouldRefreshCourseTypes && loadCourseTypes();
  }, [shouldRefreshCourseTypes, loadCourseTypes]);

  const { shouldRefreshCtsoCodes, loadCtsoCodes } = props;
  useEffect(() => {
    shouldRefreshCtsoCodes && loadCtsoCodes();
  }, [shouldRefreshCtsoCodes, loadCtsoCodes]);

  const { shouldRefreshProgramContactTypes, loadProgramContactTypes } = props;
  useEffect(() => {
    shouldRefreshProgramContactTypes && loadProgramContactTypes();
  }, [shouldRefreshProgramContactTypes, loadProgramContactTypes]);

  return <div className="hydration" />;
};
