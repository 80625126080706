import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { SnapshotStudent } from 'types';
import { upsertInPlace } from 'util/arrayUtility';
import { filter, isEmpty } from 'lodash';
import {
  readProgramSnapshotStudents,
  readProgramFollowupSnapshotStudents,
} from 'services/datacollection';

const initialState = baseInitialState;

export const loadProgramFollowupSnapshotStudent = createActionThunk(
  'loadProgramFollowupSnapshotStudent',
  (programId: number) => {
    return readProgramFollowupSnapshotStudents(programId);
  }
);

export const loadProgramSnapshotStudent = createActionThunk(
  'loadProgramSnapshotStudent',
  (programId: number) => {
    return readProgramSnapshotStudents(programId);
  }
);

export const insertSnapshotStudent = createAction(
  'insertSnapshotStudent',
  (student: SnapshotStudent) => {
    return student;
  }
);

export const removeSnapshotStudent = createAction(
  'removeSnapshotStudent',
  (studentId: String) => {
    return studentId;
  }
);

export default handleActions<
  BaseStateObject,
  SnapshotStudent | SnapshotStudent[]
>(
  {
    ...baseLoadActionBuilder<SnapshotStudent | SnapshotStudent[]>(
      loadProgramSnapshotStudent
    ),

    ...baseLoadActionBuilder<SnapshotStudent | SnapshotStudent[]>(
      loadProgramFollowupSnapshotStudent
    ),

    insertSnapshotStudent: (state: any, { payload }: any) => {
      if (!isEmpty(payload)) {
        const student = payload as SnapshotStudent;
        const students: SnapshotStudent[] = state.records;
        const newdents = upsertInPlace<SnapshotStudent>(
          students,
          student,
          'studentId'
        );
        return actionUpdateRecords(state, newdents);
      }
      return actionUpdateRecords(state, state.records);
    },

    removeSnapshotStudent: (state, { payload }) => {
      const studentId = (payload as unknown) as String;
      const students: SnapshotStudent[] = state.records;
      const newdents = filter(students, (item: SnapshotStudent) => {
        return item.studentId !== studentId;
      });
      return actionUpdateRecords(state, newdents);
    },
  },
  initialState
);
