import React from 'react';
import { defaultTo, map, find } from 'lodash';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_BOTTOM_OFFSET,
  WINDOW_TOP_OFFSET,
} from 'constants/application.constants';
import {
  CipCode,
  ProgramLength,
  Program,
  CareerCluster,
  emptyCareerCluster,
  ProgramArea,
  emptyProgramArea,
} from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';

type GeneralSummaryProps = {
  cipCode: CipCode;
  careerClusters: CareerCluster[];
  isProgramAndCacheEqual: boolean;
  program: Program;
  programAreas: ProgramArea[];
  programLengths: ProgramLength[];
  saveProgramAction: Function;
  setCurrentNavAnchor: Function;
};

export const GeneralSummaryComponent: React.FC<GeneralSummaryProps> = (
  props
) => {
  const {
    cipCode,
    programLengths,
    careerClusters,
    program,
    setCurrentNavAnchor,
    programAreas,
  } = props;

  //customize the options to format the converted date, solve the expiration date one day off issue
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatedDate = new Date(
    Date.parse(props.program.expirationDate.toString())
  ).toLocaleDateString('en-US', options);

  const getSelectedTitle = (): string => {
    const value: CareerCluster = defaultTo(
      find(careerClusters, (cc) => {
        return cc && cc.careerClusterId === program.careerClusterId;
      }),
      emptyCareerCluster()
    );
    return value.careerClusterTitle;
  };

  const getSelectedProgramArea = (): string => {
    const value: ProgramArea = defaultTo(
      find(programAreas, (pa) => {
        return pa && pa.careerSectorId === program.careerSectorId;
      }),
      emptyProgramArea()
    );
    return value.careerSectorTitle;
  };

  const getLenghtText = (): string => {
    const value: ProgramLength = defaultTo(
      find(programLengths, (len) => {
        return len.programLengthId === program.programLengthId;
      }),
      {
        programLengthId: -1,
        programLengthName: '',
        createdDate: new Date(Date.now()),
        modifiedDate: new Date(Date.now()),
      }
    );
    return value.programLengthName;
  };

  return (
    <Paper>
      <SummaryScroll componentStep="general" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('general')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <div className="form__row form__row--third">
        <FlexGroup>
          <label className="form__label">CIP Code</label>
          <p>{program.cipId.trim()}</p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">CIP Trad Gender</label>
          <p>
            {cipCode.genderTradition === 'N' || cipCode.genderTradition === 'n'
              ? ''
              : cipCode.genderTradition}
          </p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">Prog Instance ID</label>
          <p>{program.programInstanceId}</p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">Program Base ID</label>
          <p>{program.programBaseId}</p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">Status</label>
          <p>{program.workflowStatus}</p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">State</label>
          <p>{program.programStateName}</p>
        </FlexGroup>

        <FlexGroup>
          <label className="form__label">Expiration Date</label>
          <p>{formatedDate}</p>
        </FlexGroup>
      </div>

      <FlexRow>
        <FlexGroup>
          <label className="form__label">Program Name</label>
          <p>{program.programName}</p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <label className="form__label">Program Description</label>
          <p>{program.programDescription}</p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <label className="form__label">Career Cluster</label>
          <p>{getSelectedTitle()}</p>
        </FlexGroup>
        <FlexGroup>
          <label className="form__label">Length</label>
          <p>{`${program.terms} ${getLenghtText()}`}</p>
        </FlexGroup>
        <FlexGroup>
          <label className="form__label">Program Area</label>
          <p>{getSelectedProgramArea()}</p>
        </FlexGroup>
      </FlexRow>
      {program.programLevelId !== 2 && (
        <FlexRow>
          <FlexGroup>
            <label className="form__label">CTSO(s)</label>
            <p>
              {map(program.ctsO_IDs, (org: string, index: number) => {
                return index < program.ctsO_IDs.length - 1 ? (
                  <span key={index}> {org} | </span>
                ) : (
                  <span key={index}> {org} </span>
                );
              })}
            </p>
          </FlexGroup>
        </FlexRow>
      )}
      {program.programLevelId === 2 && (
        <React.Fragment>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Program Requirements</label>
              <p>{program.programRequirement}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Industry Certifications</label>
              <p>{program.industryCertifications}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">
                Bachelor's Degree(s) Program Aligns To
              </label>
              <p>{program.bachelorDegrees}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Advanced Degrees</label>
              <p>{program.advancedDegrees}</p>
            </FlexGroup>
          </FlexRow>
        </React.Fragment>
      )}
      {(program.programLevelId === 1 || program.programLevelId === 2) && (
        <React.Fragment>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Employment Demand</label>
              <p>{program.employmentDemandInformation}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Occupational Information</label>
              <p>{program.occupationInfoProgramCompletion}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Work Based Learning</label>
              <p>{program.workBasedLearning}</p>
            </FlexGroup>
          </FlexRow>
        </React.Fragment>
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('general')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
