import { connect } from 'react-redux';
import { getRecords_ProgramStates } from 'redux/common/_selectors';
import { ProgramStateMultiSelectBoxComponent } from './ProgramStateMultiSelectBox.component';
import { getRecords_ProgramFilterState } from 'redux/filters/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const states = getRecords_ProgramStates(state);
  const selectedStates = getRecords_ProgramFilterState(state).programStateId;
  return {
    states,
    selectedStates,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramStateMultiSelectBoxComponent);
