import { each } from 'lodash';

const Resources = {
  Success: {
    Deleted: 'The record was successfully deleted',
    DataSaved: 'Your changes were saved successfully.',
    EmailScheduled: 'The emails have been scheduled to be sent.',
    FileSaved: 'Your file was saved successfully.',
  },
  Errors: {
    DeleteFailed: 'The record was unable to be deleted',
    LoadNamedDataFailed: 'There was an error loading the {0} data.',
    LoadDataFailed: 'There was an error loading the data.',
    DataSaveFailed:
      'There was an error saving your changes, please try again later.',
    FileSaveFailed:
      'There was an error saving the file, please try again later.',
    FileDownloadFailed:
      'There was an error downloading the file, please try again later.',
    InvalidResponse: 'The server returned an invalid response',
    ValidationFailed:
      'All validations did not pass. Please check the page for errors.',
  },
};

export default Resources;

export const stringFormat = (value: string, params: string[]) => {
  each(params, (item: string, index: number) => {
    value = value.replace(new RegExp(`\\{${index}\\}`, 'g'), item);
  });
  return value;
};
