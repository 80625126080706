import { connect } from 'react-redux';
import { loadCTAReviewOptimize } from 'redux/cta/ctareviewoptimize';
import {
  getRecords_CTAContracting,
  getRecords_CTAHosting,
  getRecords_CTAReviewOptimize,
  getRecords_CTASignatures,
} from 'redux/cta/_selectors';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { CTAWorkflowComponent } from './Workflow.component';
import { loadWorkFlow, submitCtaForUpdate } from 'redux/generalworkflow/workflow.actions';
import { loadCTASignatures } from 'redux/cta/ctasignature';
import { loadCTAContracting } from 'redux/cta/contracting/ctacontracting';
import { loadCTAHosting } from 'redux/cta/hosting/ctahosting';

const ENTITY = 'cta';

const mapStateToProps = (state: any) => {
  const ctaContracting = getRecords_CTAContracting(state);
  const ctaHosting = getRecords_CTAHosting(state);
  const reviewOptimize = getRecords_CTAReviewOptimize(state);
  const signatures = getRecords_CTASignatures(state);
  const workflow = getGeneralWorkflow(ENTITY, state);
  const isFetching = getIsDataFetching_GeneralWorkflow(ENTITY, state);
  const loggedInUser = getLoggedinUser(state);

  return {
    signatures,
    reviewOptimize,
    workflow,
    isFetching,
    loggedInUser,
    ctaContracting,
    ctaHosting,
  };
};

const dispatchProps = {
  loadCTAContracting,
  loadCTAHosting,
  submitCtaForUpdate,
  loadCTAReviewOptimize,
  loadCTASignatures,
  loadWorkFlow,
};

export default connect(mapStateToProps, dispatchProps)(CTAWorkflowComponent);
