import { connect } from 'react-redux';
import { CTAAdministrationComponent } from './CTAAdministration.component';
import {
  loadCTAAdminInformation,
  saveCTAAllocation,
  loadCTAAllocation,
  saveApprovalEmail,
  runCalculations,
} from 'redux/cta/ctaadmin';
import { getRecords_CTAAdminInformation } from 'redux/cta/_selectors';
import { getCurrentCTAFiscalYear } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const adminRecords = getRecords_CTAAdminInformation(state);
  const currentCTAYear = getCurrentCTAFiscalYear(state);

  return {
    adminRecords,
    currentCTAYear,
  };
};

const dispatchProps = {
  loadCTAAdminInformation,
  loadCTAAllocation,
  saveCTAAllocation,
  saveApprovalEmail,
  runCalculations,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTAAdministrationComponent);
