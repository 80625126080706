import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { ExpenseProgram } from 'types';
import {
  BaseStateObject,
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { readExpenseProgram } from 'services/grants/grantprogramexpenses.services';

const initialState = baseInitialState;

export const loadExpenseProgram = createActionThunk(
  'loadExpenseProgram',
  (grantFiscalYearId: number) => {
    return readExpenseProgram(grantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, ExpenseProgram[]>(
  {
    [loadExpenseProgram.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadExpenseProgram.SUCCEEDED]: (state, { payload }) => {
      return actionUpdateRecords(state, payload);
    },
    [loadExpenseProgram.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
