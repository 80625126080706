import {
  ProgramContact,
  UniversalContact,
  emptyProgramContact,
  emptyUniversalContact,
} from 'types';

export interface ProgramContactDeep extends ProgramContact {
  contact: UniversalContact;
}

export const emptyProgramContactDeep = (): ProgramContactDeep => {
  return {
    ...emptyProgramContact(),
    contact: emptyUniversalContact(),
  };
};
