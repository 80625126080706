import React, { useEffect, useState } from 'react';
import {
  AzureUploadParameters,
  QuestionGroup,
  emptyQuestionGroup,
} from 'types';
import { useParams } from 'react-router';
import { upsertInPlace } from 'util/arrayUtility';
import { updateGrantFormQuestions } from 'services/grants/grantformquestions.services';
import { FlexGroup } from 'layouts';
import Paper from 'components/Common/Paper';
import ParentQuestionGroup from 'components/Common/Questions';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';

type TwoYearProps = {
  isLocked: boolean;
  setCurrentNavAnchor: Function;
  twoYearQuestions: QuestionGroup[];
  loadTwoYear: Function;
};

export const TwoYearEditComponent: React.FC<TwoYearProps> = (props) => {
  const {
    isLocked,
    loadTwoYear,
    setCurrentNavAnchor,
    twoYearQuestions,
  } = props;
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const [questionState, setQuestionState] = useState<QuestionGroup[]>([
    emptyQuestionGroup(),
  ]);

  useEffect(() => {
    setCurrentNavAnchor('two-year-plan');
    loadTwoYear(grantFiscalYearId);
  }, [setCurrentNavAnchor, loadTwoYear, grantFiscalYearId]);

  useEffect(() => {
    setQuestionState(twoYearQuestions);
  }, [twoYearQuestions]);

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|two-year-elements`,
  };

  const handleChange = (
    data: QuestionGroup,
    key: keyof QuestionGroup,
    value: any
  ) => {
    const updated = upsertInPlace<QuestionGroup>(
      questionState,
      data,
      'questionGroupId'
    );
    setQuestionState(updated);
  };

  const saveQuestions = async (): Promise<any> => {
    return updateGrantFormQuestions(questionState);
  };

  return (
    <SecureWrap isLocked={isLocked} component={['GrantsBasic']}>
      <ScrollToTop />
      <Paper>
        <FlexGroup>
          <h2>Instructions</h2>
          <p>
            All applicants for a Perkins grant must create a Two-Year Strategic
            Plan that documents the steps that will be taken to strengthen local
            CTE programming. This action plan must be updated every two years
            and, where necessary, updated to reflect changes in regional needs.
            The following sections ask you to detail the action steps you will
            take to strengthen your institutional and regional CTE programming.
            Follow the prompts provided in each section to complete the
            open-response text boxes.
          </p>
        </FlexGroup>
      </Paper>
      <Paper>
        <ParentQuestionGroup
          uploadParams={uploadParams}
          onChange={handleChange}
          questionGroup={questionState}
          title="Questions About Performance Data"
        />
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          saveData={saveQuestions}
          saveText="Save and continue"
          saveUrl={`/grants/applications/two-year-plan/workflow-approval/${grantFiscalYearId}`}
        />
      </Paper>
    </SecureWrap>
  );
};
