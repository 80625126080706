import { toUpper } from 'lodash';
import React, { CSSProperties } from 'react';
import { ReportItem } from 'types';

type ReportingHeaderProps = {
  report: ReportItem;
  bold?: boolean;
};

export const ReportingHeaderComponent: React.FC<ReportingHeaderProps> = ({
  report,
  bold = false,
}) => {
  const headerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 3fr',
    width: '100%',
    fontWeight: bold ? 600 : 'normal',
  };

  return (
    <div style={headerStyle}>
      <p>{report.name}</p>
      <p>{toUpper(report.module)}</p>
      <p>{report.description}</p>
    </div>
  );
};
