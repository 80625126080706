import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ApplicationsDashboard from 'components/Grants/ApplicationsDashboard';

export const ApplicationsDashboardPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ApplicationsDashboard {...props} />
    </BaseLayout>
  );
};
