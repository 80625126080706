import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import ReportingDashboard from 'pages/Reports/Dashboard';
import ReportPage from 'pages/Reports/ReportPage';
import { PublicReportsReportPage } from 'pages/PublicReports/PublicReportsReportPage';
import { PublicReportsDashboard } from 'pages/PublicReports/PublicReportsDashboard';

const reportsRoutes = [
  <Route
    exact
    path="/cccs-reports"
    component={authentication.required(ReportingDashboard)}
  />,
  <Route
    exact
    path="/cccs-reports/:reportName"
    component={authentication.required(ReportPage)}
  />,
  <Route exact path="/public-reporting" component={PublicReportsDashboard} />,
  <Route
    exact
    path="/public-reporting/:reportName"
    component={PublicReportsReportPage}
  />,
];

export default reportsRoutes;
