import React, { useEffect } from 'react';
import { AzureUploadParameters, ProgramArticulationDeep, Program } from 'types';
import {
  getContactFullName,
  getUserEmail,
} from 'util/universalcontact.utility';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { last } from 'lodash';
import { downloadFileFromAzure } from 'services/azure/azure.services';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose, renderData, prop } from 'util/objectUtility';
import { FlexRow, FlexGroup } from 'layouts';
import DatatableLoading from 'components/Common/DatatableLoading';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import Table from 'components/Common/Table';
import Truncate from 'react-truncate';

type ArticulationCollaborationProps = {
  articulations: ProgramArticulationDeep[];
  isFetching: boolean;
  loadProgramArticulations: Function;
  program: Program;
  setCurrentNavAnchor: Function;
};

export const ArticulationSummaryComponent: React.FC<
  ArticulationCollaborationProps
> = (props) => {
  const {
    articulations,
    isFetching,
    loadProgramArticulations,
    program,
    setCurrentNavAnchor,
  } = props;

  const { programInstanceId: thepid }: any = useParams();
  const programInstanceId = Number(thepid);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramArticulations(programInstanceId);
    }
  }, [programInstanceId, loadProgramArticulations]);

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|articulations`,
  };

  const handleFileDownload = (row: ProgramArticulationDeep) => {
    downloadFileFromAzure(uploadParams, row.filePath);
  };

  const getFileDownloadLink = (row: ProgramArticulationDeep) => {
    return (
      <React.Fragment>
        {row.filePath ? (
          <div
            aria-label="Download Agreement Document"
            className="u-pseudo-link"
            onClick={() => handleFileDownload(row)}
            onKeyDown={(e: React.KeyboardEvent) => {
              e.keyCode === 13 && handleFileDownload(row);
            }}
            tabIndex={0}
          >
            <Truncate>{last(row.filePath.split('|'))}</Truncate>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const Institution = compose(
    renderData,
    prop('schoolName'),
    prop('school')
  );

  const ContactName = compose(
    renderData,
    getContactFullName,
    prop('contact')
  );
  const ContactTitle = compose(
    renderData,
    prop('title'),
    prop('contact')
  );
  const ContactEmail = compose(
    renderData,
    getUserEmail,
    prop('contact')
  );
  const YearOfAgreement = compose(
    renderData,
    prop('year')
  );
  const FileDownload = compose(
    renderData,
    getFileDownloadLink
  );

  const ARTICULATION_COLLABORATION_HEADERS: TableColumnProps[] = [
    {
      name: 'Institution',
      cell: Institution,
      sortable: true,
      selector: Institution,
    },
    {
      name: 'Contact Name',
      cell: ContactName,
      sortable: true,
      selector: ContactName,
    },
    {
      name: 'Title',
      cell: ContactTitle,
    },
    {
      name: 'Email',
      cell: ContactEmail,
    },
    {
      name: 'Agreement Document',
      cell: FileDownload,
    },
    {
      name: 'Year',
      cell: YearOfAgreement,
      sortable: true,
      selector: YearOfAgreement,
      width: '10rem',
    },
  ];

  return (
    <Paper>
      <SummaryScroll componentStep="articulations" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('articulations')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <Table
            noDataComponent={
              <DatatableLoading
                messageBefore={'Loading Articulations'}
                loading={isFetching}
              />
            }
            columns={ARTICULATION_COLLABORATION_HEADERS}
            data={articulations}
          />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Collaborations</p>
          {program.collaboration}
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('articulations')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
