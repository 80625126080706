import { Question } from './Question.types';

export interface QuestionGroup {
  description?: string;
  questionGroupId: number;
  questions: Question[];
  title: string;
}

export const emptyQuestionGroup = (): QuestionGroup => {
  return {
    description: '',
    questionGroupId: -1,
    questions: [],
    title: '',
  };
};
