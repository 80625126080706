import { connect } from 'react-redux';
import { CommonDataHydrator } from './CommonDataHydrator';
import { getShouldRefreshContactTypes } from 'redux/universalcontacts/contacttypes.selector';
import { shouldRefreshReduxState_UniversalContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { shouldRefreshReduxState_PermissionRoles } from 'redux/permissions/permissionroles.selectors';
import {
  shouldRefreshReduxState_Departments,
  shouldRefreshReduxState_Institutes,
  shouldRefreshReduxState_Districts,
  shouldRefreshReduxState_Schools,
  shouldRefreshReduxState_ExpenseCategory,
  shouldRefreshReduxState_TravelerType,
  shouldRefreshReduxState_FerpaAgreement,
  shouldRefreshReduxState_FiscalYear,
} from 'redux/common/_selectors';
import { loadDepartments } from 'redux/common/departments';
import { loadDistricts } from 'redux/common/districts';
import { loadSchools } from 'redux/common/schools';
import { loadUniversalContacts } from 'redux/universalcontacts/universalcontacts';
import { loadPermissionRoles } from 'redux/permissions/permissionroles';
import { loadContactTypes } from 'redux/universalcontacts/contacttypes';
import { loadInstitutes } from 'redux/common/intitutes';
import { loadExpenseCategories } from 'redux/common/expensecategory';
import { loadFerpaAgreementData } from 'redux/common/ferpaagreement';
import { loadTravelerTypes } from 'redux/common/travelertype';
import { loadFiscalYears } from 'redux/common/fiscalyear';

export const mapStateToProps = (state: any) => {
  const shouldRefreshContactTypes = getShouldRefreshContactTypes(state);
  const shouldRefreshUniversalContacts = shouldRefreshReduxState_UniversalContact(
    state
  );
  const shouldRefreshPermissionRoles = shouldRefreshReduxState_PermissionRoles(
    state
  );
  const shouldRefreshDepartments = shouldRefreshReduxState_Departments(state);
  const shouldRefreshDistricts = shouldRefreshReduxState_Districts(state);
  const shouldRefreshInstitutes = shouldRefreshReduxState_Institutes(state);
  const shouldRefreshSchools = shouldRefreshReduxState_Schools(state);
  const shouldRefreshExpenseCategories = shouldRefreshReduxState_ExpenseCategory(
    state
  );
  const shouldRefreshFerpaAgreement = shouldRefreshReduxState_FerpaAgreement(
    state
  );
  const shouldRefreshTravelerTypes = shouldRefreshReduxState_TravelerType(
    state
  );

  const shouldRefreshFiscalYears = shouldRefreshReduxState_FiscalYear(state);

  return {
    shouldRefreshSchools,
    shouldRefreshDepartments,
    shouldRefreshDistricts,
    shouldRefreshInstitutes,
    shouldRefreshContactTypes,
    shouldRefreshPermissionRoles,
    shouldRefreshUniversalContacts,
    shouldRefreshExpenseCategories,
    shouldRefreshFerpaAgreement,
    shouldRefreshTravelerTypes,
    shouldRefreshFiscalYears,
  };
};

const dispatchProps = {
  loadFiscalYears,
  loadContactTypes,
  loadPermissionRoles,
  loadUniversalContacts,
  loadDepartments,
  loadDistricts,
  loadSchools,
  loadInstitutes,
  loadExpenseCategories,
  loadFerpaAgreementData,
  loadTravelerTypes,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CommonDataHydrator);
