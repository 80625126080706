import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { SnapshotStudentSelectBoxComponent } from './SnapshotStudentSelectBox.component';
import { SnapshotStudent } from 'types';
import { getRecords_SnapshotStudent } from 'redux/datacollection/_selectors';
import { loadSnapshotStudent } from 'redux/datacollection/studentdata';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const students: SnapshotStudent[] = getRecords_SnapshotStudent(state);
  return {
    students,
  };
};

const dispatchProps = {
  loadSnapshotStudent,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SnapshotStudentSelectBoxComponent);
