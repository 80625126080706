import { FILTERS_KEY, FILTERS_KEY_SNAPSHOT_STUDENT } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ReduxFilter, SnapshotStudentFilterState } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const SNAPSHOT_STUDENT_FILTER_PATH = [
  FILTERS_KEY,
  FILTERS_KEY_SNAPSHOT_STUDENT,
];

export const getRecords_SnapshotStudentFilterState = (
  state: any
): SnapshotStudentFilterState => {
  return get(state, SNAPSHOT_STUDENT_FILTER_PATH);
};

export const getSnapshotStudentFilters = (state: any) => {
  const filterValues: KeyValueType<
    number
  > = (getRecords_SnapshotStudentFilterState(state) as unknown) as KeyValueType<
    number
  >;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (item): ReduxFilter<SnapshotStudentFilterState> => {
      return {
        filter: {
          property: item === 'cipCode' ? 'schoolId' : item,
          values: filterValues[item] !== undefined ? filterValues[item] : '',
        },
        operator: 'and',
      };
    }
  );

  return filters;
};
