import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { SnapshotStudent, StudentEnrollment } from 'types';
import { readEnrollmentStudents } from 'services/datacollection/excelmanagement.services';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import download from 'js-file-download';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type EnrollmentTrendProps = {
  enrollment: StudentEnrollment;
  students: SnapshotStudent[];
};

export const EnrollmentTrend: React.FC<EnrollmentTrendProps> = (props) => {
  const { enrollment, students } = props;

  const downloadEnrollment = () => {
    readEnrollmentStudents(enrollment.programLevelId, enrollment.programBaseId)
      .then((blob: unknown) => {
        download(
          blob as Blob,
          `${enrollment.programBaseId}_${
            enrollment.programName
          }_enrollment.xlsx`
        );
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  return (
    <React.Fragment>
      <Paper>
        <Instruction title="Viewing Program Enrollment and Trends">
          <p>
            This section allows you to view summary information for this program
            and five-year trend data. This data will open in a new tab on this
            page. Click on the “Download Program Enrollment to Excel” button to
            obtain a copy of student enrollment data previously uploaded
            relating to this program.
          </p>
        </Instruction>

        <FlexRow>
          <FlexRow>
            <FlexGroup>
              <Button
                className="form__btn--cancel"
                onClick={() =>
                  window.open(
                    `/programs/program-steps/${enrollment.programInstanceId}`,
                    '_blank'
                  )
                }
                text={'View Progam Summary'}
              />
            </FlexGroup>
            <FlexGroup>
              <Button
                disabled={true}
                className="form__btn--cancel"
                onClick={() => {}}
                text={'View 5-year Trends'}
              />
            </FlexGroup>
          </FlexRow>
          <div style={{ marginLeft: '1.8rem' }} />
          <FlexGroup>
            <Button
              disabled={students.length === 0}
              style={{ height: '6rem' }}
              className="form__btn--cancel"
              onClick={downloadEnrollment}
              text="Download Program Enrollment to Excel"
            />
          </FlexGroup>
        </FlexRow>
      </Paper>
    </React.Fragment>
  );
};
