import { connect } from 'react-redux';
import { FollowupStatusComponent } from './FollowupStatus.component';
import { insertFollowupComplete } from 'redux/datacollection/studentenrollment';

const dispatchToProps = {
  insertFollowupComplete,
};

export default connect(
  null,
  dispatchToProps
)(FollowupStatusComponent);
