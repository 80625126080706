import {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
  saveRenewalRequest,
  saveRevisionRequest,
  saveRevisionApproval,
} from 'redux/programs/programinstance';
import { isApplicaionIdValid } from 'util/global';
import { Program } from 'types';
import { resetProgramAwardCache } from 'redux/caches/programaward';
import { resetProgramCourseCreditsCache } from 'redux/caches/coursecredit';
import { resetProgramPathwayCache } from 'redux/caches/programpathway';
import { EDITABLE_PROGRAM_STATES } from 'constants/programs';
import { includes } from 'lodash';

export const programInstanceMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case loadSingleProgramInstance.SUCCEEDED:
      const program = action.payload as Program;
      const editable = includes(
        EDITABLE_PROGRAM_STATES,
        program.programStateId
      );
      if (!editable) {
        dispatch(resetProgramAwardCache());
        dispatch(resetProgramCourseCreditsCache());
        dispatch(resetProgramPathwayCache());
      }
      break;
    case saveRenewalRequest.SUCCEEDED:
    case saveRevisionApproval.SUCCEEDED: {
      const { newProgramId, oldProgramId } = action.payload;
      const newID = Number(newProgramId);
      const oldID = Number(oldProgramId);
      if (isApplicaionIdValid(newID)) {
        dispatch(loadSingleProgramInstance(newID));
      }
      if (isApplicaionIdValid(oldID)) {
        dispatch(loadSingleProgramInstance(oldID));
      }
      break;
    }
    case saveRevisionRequest.SUCCEEDED:
    case saveProgramInstanceAction.SUCCEEDED: {
      const programInstanceId = Number(action.payload);
      if (isApplicaionIdValid(programInstanceId)) {
        dispatch(loadSingleProgramInstance(programInstanceId));
      }
      break;
    }
  }
};
