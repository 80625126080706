import { SupportingDocuments } from './SupportingDocuments.component';
import { connect } from 'react-redux';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any) => {
  const loggedInUser = getLoggedinUser(state);
  return {
    loggedInUser,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(SupportingDocuments);
