import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_FOUR_YEAR_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_FOUR_YEAR = `${CHANGELOG_FOUR_YEAR_KEY} RESET UPDATE`;
export const SET_CHANGELOG_FOUR_YEAR = `${CHANGELOG_FOUR_YEAR_KEY} SET`;
export const SET_CHANGELOG_FOUR_YEAR_LOADING = `${CHANGELOG_FOUR_YEAR_KEY} LOADING`;

const initState = baseInitialState;

export const changelogFourYearReducer = (state = initState, action: any) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_FOUR_YEAR:
      return setShouldUpdate(state);

    case SET_CHANGELOG_FOUR_YEAR:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_FOUR_YEAR_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogFourYear = (payload: any) => {
  return {
    type: SET_CHANGELOG_FOUR_YEAR,
    payload,
  };
};

export const setIsLoading_ChangelogFourYear = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_FOUR_YEAR_LOADING,
    payload,
  };
};
