import React from 'react';

type BadgeProps = {
  label: string | number | JSX.Element;
  show?: boolean;
};

export const Badge: React.FC<BadgeProps> = (props) => {
  const { label, show = true } = props;
  return (
    <div aria-hidden={true} className="badge__anchor">
      {show && (
        <div className="badge">
          <p className="badge__label">{label}</p>
        </div>
      )}
      {props.children}
    </div>
  );
};
