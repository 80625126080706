import React from 'react';
import { FlexRow } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { createRadioOptionsForBool } from 'util/formHelpers';
import Radio from 'components/Common/FormElements/Radio';

export const DemographicInformationBase: React.FC<
  StudentRecordComponentProps
> = (props) => {
  const { student, onChange, v } = props;

  const mapSexToOptions = (sex: string | null) => {
    if (!sex) return null;
    if (sex.toLowerCase()[0] === 'm') {
      return 0;
    }
    return 1;
  };

  const handleSexChange = (data: { sex: number }) => {
    const val = data.sex ? 'f' : 'm';
    const sexProp = { sex: val };
    onChange(sexProp, 'sex', val);
  };

  return student ? (
    <React.Fragment>
      <FlexRow style={{ width: '25%' }}>
        <Radio
          border={true}
          errorMessage={v.getError('sex')}
          label="CDE Gender"
          name="sex"
          onBlur={() => v.validate('sex', student.sex, student)}
          onChange={handleSexChange}
          options={createRadioOptionsForBool({
            optionTrue: 'Female',
            optionFalse: 'Male',
          })}
          valid={v.getFieldValid('sex')}
          value={mapSexToOptions(student.sex)}
        />
      </FlexRow>
    </React.Fragment>
  ) : null;
};
