import Button from 'components/Common/FormElements/Button';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import download from 'js-file-download';
import { FlexRow } from 'layouts';
import { ReportItem, SelectedData } from 'types';
import { ReportingParametersComponent } from '../ReportingParameters';
import {
  readExcelReport,
  readPublicExcelReport,
} from 'services/reporting/report.service';
import { some } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';

type ReportingItemProps = {
  report: ReportItem;
  loadSingleWebReport: Function;
  loadPublicWebReport: Function;
  isLoggedin: boolean;
};

export const ReportingItemComponent: React.FC<ReportingItemProps> = ({
  report,
  loadSingleWebReport,
  loadPublicWebReport,
  isLoggedin,
}) => {
  const [valueState, setValueState] = useState<SelectedData[]>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const updateValueState = (_: any, name: any, value: any) => {
    const sdata: SelectedData = {
      name,
      value,
    };
    setValueState(upsertInPlace(valueState, sdata, 'name'));
  };

  const generateReport = () => {
    if (isLoggedin) {
      loadSingleWebReport(report.name, { ...report, selectedData: valueState })
        .then(() => {
          var reportWindow = window.open(
            `/cccs-reports/${report.name}`,
            '_blank'
          );
          if (reportWindow) {
            reportWindow.focus();
          }
        })
        .catch((err: any) => console.error(err));
    } else {
      loadPublicWebReport(report.name, { ...report, selectedData: valueState })
        .then(() => {
          var reportWindow = window.open(
            `/public-reporting/${report.name}`,
            '_blank'
          );
          if (reportWindow) {
            reportWindow.focus();
          }
        })
        .catch((err: any) => console.error(err));
    }
  };

  const downloadReport = () => {
    if (isLoggedin) {
      setLocalLoading(true);
      readExcelReport(report.name, { ...report, selectedData: valueState })
        .then((blob: unknown) => {
          download(blob as Blob, `${report.name}.xlsx`);
        })
        .catch((err: any) => console.error(err))
        .finally(() => {
          setLocalLoading(false);
        });
    } else {
      setLocalLoading(true);
      readPublicExcelReport(report.name, {
        ...report,
        selectedData: valueState,
      })
        .then((blob: unknown) => {
          download(blob as Blob, `${report.name}.xlsx`);
        })
        .catch((err: any) => console.error(err))
        .finally(() => {
          setLocalLoading(false);
        });
    }
  };

  useEffect(() => {
    setValueState(report.selectedData);
  }, [report]);

  return (
    <Paper>
      <Loading isActive={localLoading} />
      <ReportingParametersComponent
        report={report}
        onChange={updateValueState}
        valueState={valueState}
      />
      <FlexRow>
        <div className="u-flex-grow-1" />
        <div className="form__btn-container">
          {some([
            report.reportType === 'both',
            report.reportType === 'web',
          ]) && (
            <Button
              text={'View on Web'}
              onClick={generateReport}
              className="button--short-outline"
            />
          )}
          {some([
            report.reportType === 'both',
            report.reportType === 'excel',
          ]) && (
            <Button
              text={'Export to Excel'}
              onClick={downloadReport}
              className="button--short"
            />
          )}
        </div>
      </FlexRow>
    </Paper>
  );
};
