import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

const Root: React.FC = (props: any) => {
  const { children } = props;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>{children}</Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default Root;
