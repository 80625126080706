import {
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
  getFormattedUrl,
  deleteWithAuthOptions,
} from 'services';
import { APIErrorEntity, SnapshotStudent } from 'types';

const STUDENT_ENDPOINT = getFormattedUrl(['api', 'SnapshotStudents']);
const STUDENT_ALL_ENDPOINT = getFormattedUrl([
  'api',
  'SnapshotStudents',
  'all',
]);

export const readSnapshotStudents = async (): Promise<SnapshotStudent[]> => {
  return fetch(STUDENT_ALL_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotStudents', err, 'readSnapshotStudents');
    });
};

export const readProgramSnapshotStudents = async (
  programId: number
): Promise<SnapshotStudent[]> => {
  return fetch(
    getFormattedUrl(['api', 'SnapshotStudents', String(programId)]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotStudents', err, 'readSnapshotStudents');
    });
};

export const readProgramFollowupSnapshotStudents = async (
  programId: number
): Promise<SnapshotStudent[]> => {
  return fetch(
    getFormattedUrl(['api', 'SnapshotStudents', 'followup', programId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotStudents', err, 'readSnapshotStudents');
    });
};

export const createSnapshotStudent = async (
  student: SnapshotStudent
): Promise<APIErrorEntity> => {
  return fetch(STUDENT_ENDPOINT, {
    ...postWithAuthOptions(),
    body: JSON.stringify(student),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotStudents', err, 'createSnapshotStudent');
    });
};

export const deleteSnapshotStudent = async (
  student: SnapshotStudent
): Promise<String> => {
  return fetch(
    getFormattedUrl([
      'api',
      'SnapshotStudents',
      String(student.snapshotId),
      student.studentId,
      String(student.programId),
    ]),
    {
      ...deleteWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotStudents', err, 'deleteSnapshotStudent');
    });
};
