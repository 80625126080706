import { COMMON_KEY, COMMON_EXPENSE_CATEGORY_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { ExpenseCategory } from 'types/grant/ExpenseCategory.types';

export const EXPENSE_CATEGORY_PATH = [COMMON_KEY, COMMON_EXPENSE_CATEGORY_KEY];

export const getIsDataFetching_ExpenseCategory = (state: any): boolean => {
  return getIsDataFetching(state, EXPENSE_CATEGORY_PATH);
};

export const getLastUpdated_ExpenseCategory = (state: any): number => {
  return getLastUpdated(state, EXPENSE_CATEGORY_PATH);
};

export const getRecords_ExpenseCategory = (state: any): ExpenseCategory[] => {
  return getRecords<ExpenseCategory>(state, EXPENSE_CATEGORY_PATH);
};

export const shouldRefreshReduxState_ExpenseCategory = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ExpenseCategory>(state, EXPENSE_CATEGORY_PATH);
};
