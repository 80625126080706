import { ProgramsAdvancedCreditOptions } from './Options.component';
import { connect } from 'react-redux';

export const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramsAdvancedCreditOptions);
