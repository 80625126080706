import React from 'react';
import { useParams } from 'react-router';
import { FlexGroup } from 'layouts';
import {
  CourseCreditProgram,
  CourseCreditRequired,
  CourseCreditAward,
  ProgramAward,
  Course,
} from 'types';
import { createTempId } from 'util/formHelpers';
import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';
import RequiredCourseCreditsInputRow from 'components/Program/CourseCredits/RequiredCourses/RequiredInputRow';
import { map, filter } from 'lodash';

type RequiredCoursesProps = {
  courseCredits: CourseCreditProgram;
  updateProgramCourseCreditsCache: Function;
};

export const RequiredCourses: React.FC<RequiredCoursesProps> = (props) => {
  const { courseCredits, updateProgramCourseCreditsCache } = props;
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const generateEmptyCourseAwards = () => {
    return map(courseCredits.programAwards, (award: ProgramAward) => {
      const { programAwardId } = award;
      return { programAwardId, credits: 0 };
    });
  };

  const addNewRequiredCourse = () => {
    const newCourse = {
      courseId: createTempId(courseCredits.requiredCourses, 'courseId'),
      contactHours: 0,
      courseTypeId: 0,
      defaultCredits: 0,
      awards: generateEmptyCourseAwards(),
    };
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        requiredCourses: [...courseCredits.requiredCourses, newCourse],
      },
    });
  };

  const deleteRequiredCourse = (courseId: number) => {
    const courses = courseCredits.requiredCourses;
    const requiredCourses = filter(courses, (course: CourseCreditRequired) => {
      return course.courseId !== courseId;
    });
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        requiredCourses,
      },
    });
  };

  const handleCourseSelect = (data: Course, index: number) => {
    if (!data) return;
    const selectedCourse = {
      courseId: data.courseId,
      contactHours: data.contactHours,
      defaultCredits: data.defaultCredits,
    };
    const requiredCourses = map(
      courseCredits.requiredCourses,
      (course: CourseCreditRequired, idx: number) => {
        return index === idx ? { ...course, ...selectedCourse } : course;
      }
    );
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        requiredCourses,
      },
    });
  };

  const handleCourseTypeSelect = (courseTypeId: number, index: number) => {
    const requiredCourses = map(
      courseCredits.requiredCourses,
      (course: CourseCreditRequired, idx: number) => {
        return index === idx ? { ...course, courseTypeId } : course;
      }
    );
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        requiredCourses,
      },
    });
  };

  const handleRequiredCreditChange = (
    data: CourseCreditAward,
    index: number
  ) => {
    const records = { ...courseCredits };
    const updatedAwards = map(
      records.requiredCourses[index].awards,
      (award: CourseCreditAward) => {
        return award.programAwardId === data.programAwardId ? data : award;
      }
    );
    records.requiredCourses[index].awards = updatedAwards;
    updateProgramCourseCreditsCache({
      programInstanceId,
      records,
    });
  };

  const handleContactHoursChange = (data: any, index: number) => {
    const records = { ...courseCredits };
    records.requiredCourses[index] = {
      ...records.requiredCourses[index],
      ...data,
    };
    updateProgramCourseCreditsCache({
      programInstanceId,
      records,
    });
  };

  return (
    <React.Fragment>
      <Instruction title="Required Courses">
        <p>
          Search for a course below, select a type, and then provide the number
          of credits that the course applies toward each award. The fields
          "Contact" and "Credits" are shown as a reference only.
        </p>
      </Instruction>
      <FlexGroup>
        {map(
          courseCredits.requiredCourses,
          (course: CourseCreditRequired, index: number) => {
            return (
              <RequiredCourseCreditsInputRow
                key={index}
                index={index}
                requiredCourse={course}
                onCreditChange={handleRequiredCreditChange}
                onContactHoursChange={handleContactHoursChange}
                onCourseSelect={handleCourseSelect}
                onCourseTypeSelect={handleCourseTypeSelect}
                deleteRequiredCourse={deleteRequiredCourse}
              />
            );
          }
        )}
      </FlexGroup>
      <Button
        text="+ Add new Course"
        className="form__btn--add"
        onClick={addNewRequiredCourse}
      />
    </React.Fragment>
  );
};
