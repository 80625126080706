import { get, find, defaultTo } from 'lodash';
import { CACHE_KEY, PROGRAM_CAREER_PATHWAY_COURSE_CACHE_KEY } from 'redux/keys';
import { PathwayCourse, emptyPathwayCourse } from 'types';
import { getIsDataFetching } from 'redux/utility/_exports';

const paths: string[] = [CACHE_KEY, PROGRAM_CAREER_PATHWAY_COURSE_CACHE_KEY];

export const getAreProgramPathwaysFetching = (state: any) => {
  return getIsDataFetching(state, paths);
};

export const getProgramPathwayCache = (state: any) => {
  return get(state, paths, null);
};

export const getProgramPathways = (state: any, programInstanceId: number) => {
  const cache = getProgramPathwayCache(state);
  const records = cache[programInstanceId];
  return records ? records.records : null;
};

export const getProgramPathwayCourse = (
  state: any,
  programInstanceId: number,
  courseId: number,
  programCareerPathwayId: number
): PathwayCourse => {
  const cache = getProgramPathways(state, programInstanceId);
  if (!cache) return emptyPathwayCourse();
  const pathway = defaultTo(
    find(cache, { programCareerPathwayId }),
    emptyPathwayCourse
  );
  const result = defaultTo(
    find(pathway.courses, { courseId }),
    emptyPathwayCourse()
  );
  return result;
};

export const isProgramPathwayCacheDirty = (
  state: any,
  programInstanceId: number
) => {
  const cache = getProgramPathwayCache(state);
  if (!cache) return false;
  const records = cache[programInstanceId];
  return records ? records.isDirty : false;
};
