import React, { useEffect, useState } from 'react';
import { PROGRAM_STATE } from 'constants/programState.enum';
import { Link, useHistory } from 'react-router-dom';
import { Program, School } from 'types';
import {
  getShortProgramLevelName,
  getSchoolName,
} from 'util/programInstance.utility';
import { clone } from 'lodash';
import { FlexRow, FlexGroup } from 'layouts';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import {
  compose,
  renderData,
  prop,
  getDate,
  getDateYrFormat,
  getExpirationDate,
} from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { useDispatch } from 'react-redux';
import {
  saveRenewalRequest,
  saveRevisionApproval,
  saveRevisionRequest,
} from 'redux/programs/programinstance';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import Badge from 'components/Common/Badge';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ProgramFilter from 'components/Program/ProgramFilter';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';

type ProgramDashboardPageProps = {
  filters: number;
  isFetching: boolean;
  loadProgramInstanceData: Function;
  schools: School[];
  setCurrentNavAnchor: Function;
  tableData: Program[];
};

export const ProgramDashboardPage: React.FC<ProgramDashboardPageProps> = (
  props
) => {
  const {
    filters,
    isFetching,
    loadProgramInstanceData,
    schools,
    setCurrentNavAnchor,
    tableData,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([...tableData]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);

  const handleClick = (row: Program) => {
    setCurrentNavAnchor('top');
    history.push(`/programs/program-steps/${row.programInstanceId}`);
  };

  const AdministrativeOptions = (row: Program) => {
    const dispatchRenewalRequest = compose(dispatch, saveRenewalRequest);
    const dispatchRevisionRequest = compose(dispatch, saveRevisionRequest);
    const dispatchRevisionApproval = compose(dispatch, saveRevisionApproval);

    const handleConfimRenewalClick = (row: Program) => {
      dispatchRenewalRequest(row).then((data: any) => {
        const { newProgramId } = data;
        const newID = Number(newProgramId);
        history.push(`/programs/program-steps/${newID}`);
      });
    };

    switch (row.programStateId) {
      case PROGRAM_STATE.OpenForRenewal:
        return (
          <SecureWrap component="programscreate" requireEdit={true}>
            <ConfirmationDialog
              tooltip="Renew Program"
              header="Confirm Program Renewal"
              onConfirm={() => handleConfimRenewalClick(row)}
              activateButton={
                <IconButton name="contentCopy" tooltip="Renew Program" />
              }
            >
              <h3>
                {`Are you sure you wish to renew program: ${row.programInstanceId}?`}
              </h3>
            </ConfirmationDialog>
          </SecureWrap>
        );
      case PROGRAM_STATE.OpenForRevision:
        return (
          <SecureWrap component="programscreate" requireEdit={true}>
            <ConfirmationDialog
              tooltip="Request Revision"
              header="Confirm Program Revision"
              onConfirm={() => dispatchRevisionRequest(row)} // sets the status to "request for revision"
              activateButton={
                <IconButton name="contentCopy" tooltip="Request Revision" />
              }
            >
              <h3>
                {`Are you sure you wish to revise the program: ${row.programInstanceId}?`}
              </h3>
            </ConfirmationDialog>
          </SecureWrap>
        );
      case PROGRAM_STATE.RequestForRevision:
        return (
          <SecureWrap component="programs" requireAdmin={true}>
            <ConfirmationDialog
              tooltip="Approve Revision Request"
              header="Approve Program Revision"
              onConfirm={() => dispatchRevisionApproval(row)}
              activateButton={
                <IconButton
                  className="u-icon-green"
                  name="checkMark"
                  tooltip="Approve Revision Request"
                />
              }
            >
              <h3>
                {`Are you sure you wish to approve this program for revision: ${row.programInstanceId}?`}
              </h3>
              <p>This will create a copy of the current program.</p>
            </ConfirmationDialog>
          </SecureWrap>
        );
      default:
        return EMPTY_PLACEHOLDER;
    }
  };

  const renderProgramBaseId = compose(renderData, prop('programBaseId'));
  const renderProgramInstanceId = compose(
    renderData,
    prop('programInstanceId')
  );
  const renderProgramName = compose(renderData, prop('programName'));
  const renderCIP = compose(renderData, prop('cipId'));
  const renderProgramLevel = compose(renderData, getShortProgramLevelName);
  const renderSchoolName = compose(renderData, getSchoolName(schools));
  const renderProgramStateName = compose(renderData, prop('programStateName'));
  const renderWorkflowStatus = compose(renderData, prop('workflowStatus'));
  const renderModifiedDate = compose(renderData, getDate, prop('modifiedDate'));
  const renderExpirationDate = compose(
    renderData,
    getExpirationDate,
    prop('expirationDate')
  );
  const ExpirationDateSelector = compose(
    renderData,
    getDateYrFormat,
    prop('expirationDate')
  );
  const ModifiedDateSelector = compose(
    renderData,
    getDateYrFormat,
    prop('modifiedDate')
  );

  const PROGRAMS_DASHBOARD_HEADERS: TableColumnProps[] = [
    {
      name: 'Base ID',
      cell: renderProgramBaseId,
      sortable: true,
      selector: 'programBaseId',
      width: '7%',
    },
    {
      name: 'Instance',
      cell: renderProgramInstanceId,
      sortable: true,
      selector: 'programInstanceId',
      width: '7%',
    },
    {
      name: 'Program Name',
      cell: renderProgramName,
      sortable: true,
      selector: 'programName',
      width: '20%',
      style: {
        flexGrow: 2,
      },
    },
    {
      name: 'CIP',
      cell: renderCIP,
      sortable: true,
      selector: 'renderCIP',
      width: '7%',
    },
    {
      name: 'Level',
      cell: renderProgramLevel,
      sortable: true,
      selector: renderProgramLevel,
      width: '5%',
    },
    {
      name: 'School | Institution',
      cell: renderSchoolName,
      sortable: true,
      selector: renderSchoolName,
      width: '20%',
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'State',
      cell: renderProgramStateName,
      sortable: true,
      selector: renderProgramStateName,
      width: '10%',
    },
    {
      name: 'Status',
      cell: renderWorkflowStatus,
      sortable: true,
      selector: renderWorkflowStatus,
      width: '10%',
    },
    {
      name: 'Last Modified',
      cell: renderModifiedDate,
      sortable: true,
      selector: ModifiedDateSelector,
      style: {
        minWidth: '12rem',
        textAlign: 'right',
      },
    },
    {
      name: 'Expiration',
      cell: renderExpirationDate,
      selector: ExpirationDateSelector,
      sortable: true,
      style: {
        minWidth: '12rem',
        textAlign: 'right',
      },
    },
    {
      name: 'Options',
      cell: AdministrativeOptions,
      center: true,
      ignoreRowClick: true,
      width: '7%',
    },
  ];

  useEffect(() => {
    loadProgramInstanceData([]);
  }, [loadProgramInstanceData]);

  useEffect(() => {
    setFilteredData(clone(tableData));
  }, [tableData]);

  return (
    <BaseLayout>
      <SecureWrap
        component={['programs', 'programfinance', 'programbanner']}
        isPage={true}
        allowReadOnly={true}
      >
        <Loading isActive={isFetching} messageBefore="Loading programs..." />
        <ScrollToTop />
        <Paper>
          <Instruction
            title="Programs"
            actionButton={
              <SecureWrap component="programscreate" requireEdit={true}>
                <Button
                  text="Create a New Program"
                  onClick={() =>
                    history.push('/programs/create-program/create')
                  }
                  className="button--filled"
                />
              </SecureWrap>
            }
          >
            <p>
              This page enables you to review the status of your program
              application. Select a row to edit your application details. Use
              "Search for Programs" to find a program you want to review or
              edit. The table is searchable with the start of the text in any
              column. Columns with a down or up-arrow button can sort the table
              in ascending or descending order by that column.
            </p>
            <br />
            <p>
              To see trends and detailed program information, navigate to the{' '}
              <Link to="/cccs-reports">Reporting Dashboard</Link> and filter by
              'Programs.' From there, you can set unique filter parameters to
              run your preferred report.
            </p>
          </Instruction>
        </Paper>
        <FlexRow>
          <FlexGroup>
            <Paper className="u-add-padding-bottom">
              <FlexRow>
                <SearchBarFilter
                  data={tableData}
                  headers={PROGRAMS_DASHBOARD_HEADERS}
                  isFetching={isFetching}
                  label="Search for Programs"
                  setFilteredData={setFilteredData}
                />
                <div className="search-bar-filter__button">
                  <Badge label={filters} show={!!filters}>
                    <IconButton
                      name="tune"
                      tooltip="Show Filters"
                      onClick={() => {
                        setFiltersOpen(!filterOpen);
                      }}
                    />
                    <p className="search-bar-filter__label">Filter</p>
                  </Badge>
                </div>
              </FlexRow>
              <Table
                columns={PROGRAMS_DASHBOARD_HEADERS}
                data={filteredData}
                onRowClick={handleClick}
              />
            </Paper>
          </FlexGroup>
          {filterOpen && (
            <div className="u-filters">
              <ProgramFilter />
            </div>
          )}
        </FlexRow>
      </SecureWrap>
    </BaseLayout>
  );
};
