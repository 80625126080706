import { VoucherSpendingCategoryEdit } from './SpendingCategoryEdit.component';
import { connect } from 'react-redux';
import { getVoucherSpendingCategories } from 'redux/grants/_selectors';
import { loadSingleVoucherByType, saveVoucher } from 'redux/grants/vouchers';
import { getVoucherLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any, ownProps: any) => {
  const { voucherType } = ownProps;
  const categories = getVoucherSpendingCategories(state, voucherType);
  const isLocked = getVoucherLockedStatus(state, voucherType);

  return {
    categories,
    isLocked,
  };
};

const dispatchProps = {
  loadSingleVoucherByType,
  saveVoucher,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(VoucherSpendingCategoryEdit);
