import React from 'react';
import { setCurrentNavAnchor } from 'redux/application';
import { connect } from 'react-redux';
import { getRecords_GrantOverview } from 'redux/nav/_selectors';
import { isStatusLocked } from 'util/navHelpers';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { saveWorkFlowUpdate } from 'redux/generalworkflow/workflow.actions';
import { includes } from 'lodash';
import { LOCAL_PLAN_WORKFLOW } from 'constants/grantsWorkFlowStatus.enum';
import { canEditGrantsWorkflow } from 'util/workflowUtility';
import { ASPWorkflowComponent } from './AnnualSpendingPlanWorkflow.component';
import { getAnnualSpendingPlan } from 'redux/grants/_selectors';
import OpenWorkflowForRevision from 'components/Common/GeneralWorkflow/OpenWorkflowForRevision';

const ENTITY = 'spending-plans';

const mapStateToProps = (state: any, ownProps: any) => {
  const { grantFiscalYearId: applicationId } = ownProps;
  const steps = getRecords_GrantOverview(state).steps;
  const isReadOnly = isStatusLocked(ENTITY, steps);
  const workflow = getGeneralWorkflow(ENTITY, state);
  const user = getLoggedinUser(state);
  const isFetching = getIsDataFetching_GeneralWorkflow(ENTITY, state);

  const spendingPlan = getAnnualSpendingPlan(state);

  const disableSubmit = includes(
    [LOCAL_PLAN_WORKFLOW.APPROVED, LOCAL_PLAN_WORKFLOW.CONDITIONALLY_APROVED],
    workflow.workflowStatusId
  );

  const optionalAction = () => {
    if (workflow.workflowSteps.length < 1) {
      return null;
    }
    const currentStep = workflow.workflowSteps[0];

    if (!canEditGrantsWorkflow(currentStep, user)) {
      return null;
    }

    return (
      <React.Fragment>
        {disableSubmit && (
          <div className="u-flex">
            <div className="u-flex-grow-1" />
            <div>
              <OpenWorkflowForRevision
                workflowStep={currentStep}
                applicationId={applicationId}
                submitApplicationUpdate={submitApplicationUpdate}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return {
    applicationId,
    disableSubmit,
    isFetching,
    isReadOnly,
    optionalAction,
    spendingPlan,
    user,
    workflow,
  };
};

const submitApplicationUpdate = saveWorkFlowUpdate(ENTITY);

const dispatchProps = {
  setCurrentNavAnchor,
  submitApplicationUpdate,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ASPWorkflowComponent);
