export interface PathwayCourse {
  programCareerPathwayId: number;
  courseId: number;
  courseName: string;
  preferredTitle: string;
  courseLevel: number;
  contactHours: number;
  courseDescription: string;
  articulationSchoolId: number;
  articulationContactId: number;
  articulationFilePath: string;
  concurrentSchoolId: number;
  concurrentCourse: string;
  academicCredit: string;
  optIn: boolean;
  isMiddleSchool?: boolean;
  isAdvancedCreditOption: boolean;
  isApprovedForArticulationAgreement: boolean;
  isConcurrentEnrollment: boolean;
  isAcademicCredit: boolean;
  isCTE: boolean;
  isCustomCourse: boolean;
}

export const emptyPathwayCourse = (): PathwayCourse => {
  return {
    programCareerPathwayId: -1,
    courseLevel: 255,
    courseId: -1,
    courseName: '',
    preferredTitle: '',
    contactHours: 0,
    courseDescription: '',
    articulationSchoolId: -1,
    articulationContactId: -1,
    articulationFilePath: '',
    concurrentSchoolId: -1,
    concurrentCourse: '',
    academicCredit: '',
    optIn: false,
    isCustomCourse: false,
    isAdvancedCreditOption: false,
    isApprovedForArticulationAgreement: false,
    isConcurrentEnrollment: false,
    isAcademicCredit: false,
    isCTE: false,
  };
};
