import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
  deleteWithAuthOptions,
} from 'services';
import { CTASupportPersonnel } from 'types';

const BASE_URL = ['api', 'ctasupportpersonnel'];

export const readCTASupportPersonnel = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Support Personnel',
        err,
        'readCTASupportPersonnel'
      );
    });
};
export const readCTASupportProgramAffiliation = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'programAffiliation', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Support Personnel',
        err,
        'readCTASupportPersonnel'
      );
    });
};

export const updateCTASupportPersonnel = async (
  ctaId: number,
  supportPersonnel: CTASupportPersonnel
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(supportPersonnel),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Support Personnel',
        err,
        'updateCTASupportPersonnel'
      );
    });
};

export const deleteCTASupportPersonnel = async (
  ctaId: number,
  supportPersonnel: CTASupportPersonnel
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    body: JSON.stringify(supportPersonnel),
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Support Personnel',
        err,
        'deleteCTASupportPersonnel'
      );
    });
};
