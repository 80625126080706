import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { AssuranceSignatures } from './Signatures.component';
import {
  getRecords_BasicGrantSignatures,
  getIsDataFetching_BasicGrantSignatures,
} from 'redux/grants/basicgrantsignature.selectors';
import {
  loadBasicGrantSignatures,
  saveBasicGrantSignatures,
} from 'redux/grants/basicgrantsignature';
import { getLockedStatus } from 'util/applicationStateUtility';

export const mapStateToProps = (state: any, ownProps: any) => {
  const signatures = getRecords_BasicGrantSignatures(state);
  const isLoading = getIsDataFetching_BasicGrantSignatures(state);

  const isLocked = getLockedStatus(state, 'assurance-signature');

  return {
    isLoading,
    isLocked,
    signatures,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  saveBasicGrantSignatures,
  loadBasicGrantSignatures,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(AssuranceSignatures);
