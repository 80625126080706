import { COMMON_KEY, COMMON_CTSO_KEY } from 'redux/keys';
import {
  shouldRefreshReduxState,
  getRecords,
} from 'redux/utility/baseSelectors';
import { CTSO_Type } from 'types';

export const CTSOS_PATH = [COMMON_KEY, COMMON_CTSO_KEY];

export const getRecords_CtsoCodes = (state: any): CTSO_Type[] => {
  return getRecords(state, CTSOS_PATH);
};

export const shouldRefreshReduxState_CtsoCodes = (state: any): boolean => {
  return shouldRefreshReduxState<CTSO_Type>(state, CTSOS_PATH, 300);
};
