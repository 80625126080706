import { connect } from 'react-redux';
import { SuperSummaryPage } from './SuperSummary.page';
import { setProgramNav } from 'redux/application/index';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );

  return {
    programInstanceId,
  };
};

// Redux functions to export below - connect
const dispatchToProps = {
  setProgramNav,
};

// Connect my components to redux, and returns connected wrapper component "SuperSummaryPage"
export default connect(
  mapStateToProps,
  dispatchToProps
)(SuperSummaryPage);
