import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import { Program } from 'types';
import {
  readPrograms,
  updateProgram,
  createProgram,
  readProgram,
  readProgramsBySchoolId,
} from 'services/programs/program.services';
import {
  actionFetching,
  actionUpdateRecords,
  baseLoadActionBuilder,
  actionRecordFailure,
} from 'redux/utility/baseActionHandler';
import { upsert } from 'util/arrayUtility';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import {
  sendRenewalRequest,
  sendRevisionRequest,
  approveRevisionRequest,
} from 'services/programs/programapprovals.service';

const initialState = baseInitialState;

export const loadSingleProgramInstance = createActionThunk(
  'loadSingleProgramInstance',
  (id: number) => {
    return readProgram(id);
  }
);

export const loadProgramInstanceData = createActionThunk(
  'loadProgramInstanceData',
  () => {
    return readPrograms();
  }
);

export const loadProgramInstanceBySchoolId = createActionThunk(
  'loadProgramInstanceBySchoolId',
  (schoolId: number) => {
    return readProgramsBySchoolId(schoolId);
  }
);

export const saveProgramInstanceAction = createActionThunk(
  'saveProgramInstanceAction',
  async (program: Program) => {
    if (!program.programInstanceId || program.programInstanceId === -1) {
      return createProgram(program);
    } else {
      return updateProgram(program).then(() => {
        return program.programInstanceId;
      });
    }
  }
);

export const insertPrograms = createAction('insertPrograms');

export const saveRenewalRequest = createActionThunk(
  'saveRenewalRequest',
  async (payload: Program) => {
    return sendRenewalRequest(payload.programInstanceId).then(
      (response: any) => {
        return {
          ...response,
          oldProgramId: payload.programInstanceId,
        };
      }
    );
  }
);

export const saveRevisionRequest = createActionThunk(
  'saveRevisionRequest',
  async (payload: Program) => {
    return sendRevisionRequest(payload.programInstanceId).then(() => {
      return payload.programInstanceId;
    });
  }
);

export const saveRevisionApproval = createActionThunk(
  'saveRevisionApproval',
  async (payload: Program) => {
    return approveRevisionRequest(payload.programInstanceId).then(
      (response: any) => {
        return {
          ...response,
          oldProgramId: payload.programInstanceId,
        };
      }
    );
  }
);

export default handleActions<BaseStateObject, Program | Program[]>(
  {
    insertPrograms: (state, { payload }) => {
      const records = upsert<Program>(
        state.records,
        payload,
        'programInstanceId'
      );
      return actionUpdateRecords(state, records);
    },

    ...baseLoadActionBuilder<Program | Program[]>(loadProgramInstanceData),

    [loadSingleProgramInstance.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadSingleProgramInstance.SUCCEEDED]: (state, action) => {
      const records = upsert<Program>(
        state.records,
        action.payload,
        'programInstanceId'
      );
      return actionUpdateRecords(state, records);
    },
    [loadSingleProgramInstance.FAILED]: (state) => {
      return actionRecordFailure(state);
    } /* End action */,
    [loadSingleProgramInstance.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    [loadProgramInstanceBySchoolId.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadProgramInstanceBySchoolId.SUCCEEDED]: (state, { payload }) => {
      const records = upsert<Program>(
        state.records,
        payload,
        'programInstanceId'
      );
      return actionUpdateRecords(state, records);
    },
    [loadProgramInstanceBySchoolId.FAILED]: (state) => {
      return actionRecordFailure(state);
    } /* End action */,
    [loadProgramInstanceBySchoolId.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    [saveProgramInstanceAction.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveProgramInstanceAction.ENDED]: (state) => {
      return actionFetching(state, false);
    },
    [saveRenewalRequest.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveRenewalRequest.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
