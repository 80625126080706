import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAHosting, CTACourse } from 'types';
import {
  readCTAHostingContract,
  updateCTAHostingContract,
  deleteCTAHostingContract,
} from 'services/cta/ctahosting.services';

const initialState = baseInitialState;

export const loadCTAHostingContract = createActionThunk(
  'loadCTAHostingContract',
  (ctaId: number, ctaContractingId: number) => {
    return readCTAHostingContract(ctaId, ctaContractingId);
  }
);

export const saveCTAHostingContract = createActionThunk(
  'saveCTAHostingContract',
  (ctaId: number, contractingId: number, courses: CTACourse[]) => {
    return updateCTAHostingContract(ctaId, contractingId, courses);
  }
);

export const removeCTAHostingContract = createActionThunk(
  'removeCTAHosting',
  ({ contractingId, ctaId }: CTAHosting) => {
    return deleteCTAHostingContract(ctaId, contractingId);
  }
);

export default handleActions<BaseStateObject, CTAHosting[]>(
  {
    ...baseLoadActionBuilder<CTAHosting[]>(loadCTAHostingContract),
    ...baseSaveActionBuilder(saveCTAHostingContract),
  },
  initialState
);
