import { FILTERS_KEY, FILTERS_KEY_PROGRAMS } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ProgramFilterState, ReduxFilter } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';
import { getArray } from 'util/arrayUtility';

export const PROGRAM_FILTER_PATH = [FILTERS_KEY, FILTERS_KEY_PROGRAMS];

export const getRecords_ProgramFilterState = (
  state: any
): ProgramFilterState => {
  return get(state, PROGRAM_FILTER_PATH);
};

export const getProgramFilters = (state: any) => {
  const filterValues: KeyValueType<number> = (getRecords_ProgramFilterState(
    state
  ) as unknown) as KeyValueType<number>;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (item): ReduxFilter<ProgramFilterState> => {
      if (item === 'excludeArchived') {
        return {
          filter: {
            property: 'programStateId',
            values: [7],
            exclude: true,
          },
          operator: 'and',
        };
      }
      if (item === 'excludeInactive') {
        return {
          filter: {
            property: 'programStateId',
            values: [6],
            exclude: true,
          },
          operator: 'and',
        };
      }

      return {
        filter: {
          property: item === 'instituteId' ? 'schoolId' : item,
          values:
            filterValues[item] !== undefined
              ? getArray(filterValues[item])
              : '',
        },
        operator: 'and',
      };
    }
  );
  return [...filters];
};
