import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import StudentFollowupComponent from 'components/DataCollection/StudentFollowup';

export const StudentFollowupPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <StudentFollowupComponent />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
