import { connect } from 'react-redux';
import {
  SCHOOLS_PATH,
  getIsDataFetching_Schools,
} from 'redux/common/_selectors';
import { School, OptionMap } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getAdvancedFilteredRecords } from 'redux/utility/baseSelectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const { filter, label, onChange, records: baseRecords, selection } = ownProps;
  const isLoading = getIsDataFetching_Schools(state);

  const records = getAdvancedFilteredRecords(
    state,
    SCHOOLS_PATH,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<School> = {
    key: 'schoolId',
    value: 'schoolId',
    label: 'schoolName',
  };

  return {
    isLoading,
    label: label ? label : 'School',
    name: 'schoolId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
