import { connect } from 'react-redux';
import { OptionMap, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { ExpenseCategory } from 'types/grant/ExpenseCategory.types';
import {
  EXPENSE_CATEGORY_PATH,
  getRecords_ExpenseCategory,
} from 'redux/common/_selectors';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    name,
    onChange,
    records: baseRecords,
  } = ownProps;

  const records = getRecordsUtility<ExpenseCategory>(
    state,
    EXPENSE_CATEGORY_PATH,
    getRecords_ExpenseCategory,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<ExpenseCategory> = {
    key: 'expenseCategoryId',
    label: 'expenseCategoryName',
    value: 'expenseCategoryId',
  };

  return {
    label: label ? label : 'Financial Category',
    name: name ? name : 'expenseCategoryId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
