import { useValidation } from 'de-formed-validations';
import { Expense } from 'types';

export const EquipmentExpenseValidations = () => {
  return useValidation<Expense>({
    units: [
      {
        errorMessage: 'Number of Units are required',
        validation: (value: number, state: Expense) => {
          if (state.financialCategoryId !== 4) return true;
          return value > 0;
        },
      },
    ],
    unitCost: [
      {
        errorMessage:
          'The Unit Cost of Equipment must be $5,000 or greater. If less than $5,000, change the Financial Category to "Other."',
        validation: (_, state: Expense) => {
          if (state.financialCategoryId !== 4) return true;
          return state.unitCost >= 5000;
        },
      },
    ],
  });
};
