import { PROGRAMS_KEY, PROGRAM_POS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getLastUpdated,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';
import { find, defaultTo } from 'lodash';
import { ProgramOfStudy, emptyProgramOfStudy } from 'types';

const POS_PATH = [PROGRAMS_KEY, PROGRAM_POS_KEY];

export const getIsDataFetching_ProgramOfStudy = (state: any): boolean => {
  return getIsDataFetching(state, POS_PATH);
};

export const getLastUpdated_ProgramOfStudy = (state: any): number => {
  return getLastUpdated(state, POS_PATH);
};

export const getRecords_ProgramOfStudy = (state: any): ProgramOfStudy[] => {
  return getRecords<ProgramOfStudy>(state, POS_PATH);
};

export const shouldRefreshReduxState_ProgramOfStudy = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramOfStudy>(state, POS_PATH);
};

export const getProgramOfStudyById = (
  state: any,
  programOfStudyId: number
): ProgramOfStudy => {
  const data = getRecords_ProgramOfStudy(state);
  const selectedPos = find(data, (pos) => {
    return Number(pos.programOfStudyId) === Number(programOfStudyId);
  });

  return defaultTo(selectedPos, emptyProgramOfStudy());
};
