import { connect } from 'react-redux';
import { getNavTrayClosed } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { GrantsLayout } from './GrantsLayout';
import { getGrantFiscalYear } from 'redux/grants/_selectors';
import { loadSingleGrantFiscalYear } from 'redux/grants/grantfiscalyear';

export const mapStateToProps = (state: any, ownProps: any) => {
  const grantFiscalYearId: number = Number(
    ownProps.match.params.grantFiscalYearId
  );
  const navTrayClosed = getNavTrayClosed(state);
  const grant = getGrantFiscalYear(state, grantFiscalYearId);

  return {
    grant,
    navTrayClosed,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
  loadSingleGrantFiscalYear,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(GrantsLayout);
