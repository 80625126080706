import { CTA_KEY, CTA_REVIEW_OPTIMIZE_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import {
  CTAReviewOptimizeOverview,
  emptyCTAReviewOptimizeOverview,
} from 'types';
import { compose } from 'util/objectUtility';
import { defaultTo, head } from 'ramda';

export const CTA_REVIEW_OPTIMIZE_PATH = [CTA_KEY, CTA_REVIEW_OPTIMIZE_KEY];

export const getIsDataFetching_CTAReviewOptimize = (state: any): boolean => {
  return getIsDataFetching(state, CTA_REVIEW_OPTIMIZE_PATH);
};

export const getRecords_CTAReviewOptimize = (
  state: unknown
): CTAReviewOptimizeOverview => {
  return compose(
    defaultTo(emptyCTAReviewOptimizeOverview()),
    head
  )(getRecords(state, CTA_REVIEW_OPTIMIZE_PATH));
};
