import React, { useLayoutEffect, useRef } from 'react';
import { scrollToAnchor } from 'util/windowScroll';

type SummaryScrollProps = {
  componentStep: string;
  currentNavAnchor: string;
  setCurrentNavAnchor: Function;
};

export const SummaryScroll: React.FC<SummaryScrollProps> = (props) => {
  const { componentStep, currentNavAnchor, setCurrentNavAnchor } = props;

  const mounted = useRef(false);
  useLayoutEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  }, []);

  useLayoutEffect(() => {
    if (mounted.current && currentNavAnchor === componentStep) {
      scrollToAnchor(currentNavAnchor);
    }
    if (mounted.current && currentNavAnchor === 'top') {
      window.scroll(0, 0);
      setCurrentNavAnchor('general');
    }
  }, [mounted]); // eslint-disable-line

  return null;
};
