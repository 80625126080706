import { CTA_KEY, CTA_BOOKS_SCHOOL_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTABooksSchool } from 'types';

export const CTA_BOOKS_SCHOOL_PATH = [CTA_KEY, CTA_BOOKS_SCHOOL_KEY];

export const getIsDataFetching_CTABooksSchool = (state: any): boolean => {
  return getIsDataFetching(state, CTA_BOOKS_SCHOOL_PATH);
};

export const getRecords_CTABooksSchool = (state: unknown): CTABooksSchool[] => {
  return getRecords(state, CTA_BOOKS_SCHOOL_PATH);
};
