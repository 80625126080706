import {
  saveContact,
  loadSingleUniversalContact,
} from 'redux/universalcontacts/universalcontacts';

import { updateLoggedinUser } from 'redux/user';
import { getLoggedinUser } from 'redux/user/selectors';

export const universalContactMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState, dispatch } = store;

  next(action);

  switch (action.type) {
    case saveContact.SUCCEEDED: {
      const { universalContactId } = getLoggedinUser(getState());
      if (Number(action.payload) === Number(universalContactId)) {
        dispatch(updateLoggedinUser(universalContactId));
      }
      dispatch(loadSingleUniversalContact(Number(action.payload)));
      break;
    }
  }
};
