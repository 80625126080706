import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCTAAdminInformation,
  updateCTAAllocation,
  createSubmissionOpenEmail,
  createCalculations,
  createApprovalEmail,
  readCTAAllocation,
} from 'services/cta';
import {
  CTAAdminInformation,
  CTAAllocation,
} from 'types/cta/CTAAdminInformation.types';

const initialState = baseInitialState;

export const loadCTAAdminInformation = createActionThunk(
  'loadCTAAdminInformation',
  () => {
    return readCTAAdminInformation();
  }
);
export const saveSubmissionOpenEmail = createActionThunk(
  'sendSubmissionOpenEmail',
  (year: number) => {
    return createSubmissionOpenEmail(year);
  }
);
export const saveCTAAllocation = createActionThunk(
  'saveCTAAllocation',
  (allocation: CTAAllocation) => {
    return updateCTAAllocation(allocation);
  }
);

export const loadCTAAllocation = createActionThunk(
  'loadCTAAllocation',
  (year: number) => {
    return readCTAAllocation(year);
  }
);

export const runCalculations = createActionThunk(
  'runCalculations',
  (year: number) => {
    return createCalculations(year);
  }
);

export const saveApprovalEmail = createActionThunk(
  'saveApprovalEmail',
  (year: number) => {
    return createApprovalEmail(year);
  }
);

export default handleActions<BaseStateObject, CTAAdminInformation[]>(
  {
    ...baseLoadActionBuilder<CTAAdminInformation[]>(loadCTAAdminInformation),

    ...baseSaveActionBuilder(saveSubmissionOpenEmail),

    ...baseSaveActionBuilder(saveCTAAllocation),

    ...baseSaveActionBuilder(loadCTAAllocation),
  },
  initialState
);
