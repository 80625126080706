import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import LandingPageCard from 'components/Common/LandingPageCard';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export const DataCollectionPortal: React.FC = () => {
  return (
    <BaseLayout>
      <ScrollToTop />
      <div className="landing-page">
        <h1 className="landing-page__title">Data Collection Portal</h1>
        <div className="landing-page__cards">
          <SecureWrap component={['DCStudentRecords']} allowReadOnly={true}>
            <LandingPageCard
              title="Student"
              iconType="localLibrary"
              buttons={[
                <Button
                  asLink={true}
                  className="landing-page-card__btn"
                  text="Enrollment"
                  to="/data-collection/student-enrollment"
                />,
                <Button
                  asLink={true}
                  className="landing-page-card__btn"
                  text="Follow-up"
                  to="/data-collection/student-followup"
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap component={['DCTeacherRecords']} allowReadOnly={true}>
            <LandingPageCard
              title="Active Teachers"
              iconType="lnrGraduationHat"
              buttons={[
                <div />,
                <Button
                  aria-label="View Active Teachers"
                  asLink={true}
                  className="landing-page-card__btn"
                  text="View"
                  to="/data-collection/active-teacher"
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap component="DCAdmin">
            <LandingPageCard
              title="Admin"
              iconType="inbox"
              buttons={[
                <Button
                  aria-label="View Submission Management"
                  asLink={true}
                  className="landing-page-card__btn"
                  text="Management"
                  to="/data-collection/submission-management"
                />,
                <Button
                  aria-label="Data Rollover"
                  asLink={true}
                  className="landing-page-card__btn"
                  text="Rollover"
                  to="/data-collection/rollover"
                />,
              ]}
            />
          </SecureWrap>
        </div>
      </div>
    </BaseLayout>
  );
};
