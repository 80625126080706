import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { MultiyearDeficiency } from 'components/Grants/Applications/ImprovementPlan/ImprovementPlanConnect';

export const MultiyearDeficiencyPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <MultiyearDeficiency />
      </GrantsLayout>
    </BaseLayout>
  );
};
