import { FILTERS_KEY, FILTERS_KEY_REPORTING } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ReportingFilterState, ReduxFilter } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const FILTERS_REPORTING_PATH = [FILTERS_KEY, FILTERS_KEY_REPORTING];

export const getRecords_ReportingFilterState = (
  state: any
): ReportingFilterState => {
  return get(state, FILTERS_REPORTING_PATH, { module: [] });
};

export const getReportingFilters = (
  state: any
): ReduxFilter<ReportingFilterState>[] => {
  const filterValues: KeyValueType<unknown> = (getRecords_ReportingFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters = getFiltersWithValues(filterValues);
  const filters = map(
    activeFilters,
    (item): ReduxFilter<ReportingFilterState> => {
      return {
        filter: {
          property: item,
          values: filterValues[item] !== undefined ? filterValues[item] : '',
        },
        operator: 'and',
      };
    }
  );
  return filters;
};
