export interface PermissionRoleComponent {
  permissionRoleId: number;
  permissionComponentId: number;
  permissionTypeId: number;
}

export const emptyPermissionRoleComponent = () => {
  return {
    permissionRoleId: -1,
    permissionComponentId: -1,
    permissionTypeId: -1,
  };
};
