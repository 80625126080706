import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { AwardAddEditComponent } from 'components/Program/Award/Award.component';
import { getRecords_AwardTypes } from 'redux/programs/_selectors';
import {
  loadProgramAwards,
  setProgramAwardCache,
  addProgramAwardToCache,
  updateProgramAwardCache,
} from 'redux/caches/programaward';
import { getProgramAward, getProgramAwards } from 'redux/caches/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programAwardId = ownProps.match.params.programAwardId;
  const programInstanceId = getActiveProgramId(state);
  const awardTypes = getRecords_AwardTypes(state);
  const award = getProgramAward(state, programInstanceId, programAwardId);
  const programAwards = getProgramAwards(state, programInstanceId);

  return {
    programInstanceId,
    award,
    awardTypes,
    programAwards,
  };
};

const dispatchToProps = {
  loadProgramAwards,
  addProgramAwardToCache,
  setProgramAwardCache,
  updateProgramAwardCache,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(AwardAddEditComponent);
