import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import ProgramFollowup from 'components/DataCollection/StudentFollowup/ProgramFollowUp';

export const ProgramFollowupPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <ProgramFollowup {...props} />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
