import { COMMON_KEY, COMMON_SCHOOLS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { find } from 'lodash';
import { School, ItemFilter } from 'types';
import { getInstituteById } from 'redux/common/institutes.selectors';

export const SCHOOLS_PATH = [COMMON_KEY, COMMON_SCHOOLS_KEY];

export const getIsDataFetching_Schools = (state: any): boolean => {
  return getIsDataFetching(state, SCHOOLS_PATH);
};

export const getLastUpdated_Schools = (state: any): number => {
  return getLastUpdated(state, SCHOOLS_PATH);
};

export const getRecords_Schools = (state: any): School[] => {
  return getRecords<School>(state, SCHOOLS_PATH);
};

export const shouldRefreshReduxState_Schools = (state: any): boolean => {
  return shouldRefreshReduxState<School>(state, SCHOOLS_PATH);
};

export const getFilteredRecords_Schools = (
  state: any,
  filter: ItemFilter
): School[] => {
  return getFilteredRecords<School>(state, SCHOOLS_PATH, filter);
};

export const getSchoolById = (state: any, schoolId: number) => {
  return find(getRecords_Schools(state), { schoolId });
};

export const getSchoolOrInstituteById = (state: any, schoolId: number) => {
  const secondary = getSchoolById(state, schoolId);
  const postsecondary = getInstituteById(state, schoolId);
  return secondary ? secondary : postsecondary;
};
