import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FlexGroup } from 'layouts';
import { CourseCreditProgram, ProgramAward } from 'types';
import { createTempId } from 'util/formHelpers';
import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';
import { filter, map } from 'lodash';
import CustomInputRow from './CustomInputRow';
import { CourseCreditCustom } from 'types/program/CourseCreditCustom.types';
import { upsertInPlace } from 'util/arrayUtility';
import { isApplicaionIdValid } from 'util/global';
import {
  readCustomCourses,
  updateCustomCourses,
} from 'services/programs/coursecredits.services';
import SaveButtons from 'components/Common/SaveButtons';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';
import Loading from 'components/Common/Loading';
import { CustomCourseValidations } from 'validations/Program/CustomCourse.validations';

type CustomCourseProps = {
  courseCredits: CourseCreditProgram;
};

export const CustomCourseComponent: React.FC<CustomCourseProps> = (props) => {
  const { courseCredits } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [courses, setCourses] = useState<CourseCreditCustom[]>([]);
  const { programInstanceId }: any = useParams();

  const { validate, validationErrors } = CustomCourseValidations();

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      setLoading(true);
      readCustomCourses(programInstanceId)
        .then((value) => {
          setCourses(value);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [programInstanceId]);

  const generateEmptyCourseAwards = () => {
    return map(courseCredits.programAwards, (award: ProgramAward) => {
      const { programAwardId } = award;
      return { programAwardId, credits: 0 };
    });
  };

  const updateCourses = (course: CourseCreditCustom) => {
    const value = upsertInPlace(courses, course, 'courseId');
    setCourses(value);
  };

  const deleteCourse = (value: CourseCreditCustom) => {
    const items = filter(courses, (item) => {
      return Number(item.courseId) !== Number(value.courseId);
    });
    setCourses(items);
  };

  const addCourse = () => {
    const newCourse: CourseCreditCustom = {
      courseId: createTempId(courses, 'courseId'),
      contactHours: 0,
      courseTypeId: '',
      defaultCredits: 0,
      awards: generateEmptyCourseAwards(),
      courseName: '',
      coursePrefix: '',
      courseNumber: '',
    };
    setCourses([...courses, newCourse]);
  };

  const saveCourses = () => {
    if (!validate('allCoursesValid', courses)) {
      return;
    }

    if (isApplicaionIdValid(programInstanceId)) {
      setLoading(true);
      updateCustomCourses(programInstanceId, courses)
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
          if (isApplicaionIdValid(programInstanceId)) {
            setLoading(true);
            readCustomCourses(programInstanceId)
              .then((value) => {
                setCourses(value);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Loading isActive={loading} />
      <Instruction title="Custom CTE Courses">
        <p>
          If you are not using a State standard CCNS course, please add this
          course here. Custom CTE Courses must be saved immediately. Please
          press 'Save custom courses' when you are finished adding them. If you
          are editing other course you must still press save at the bottom of
          the page.
        </p>
      </Instruction>
      <FlexGroup>
        {map(courses, (course: CourseCreditCustom, index: number) => {
          return (
            <CustomInputRow
              key={index}
              index={index}
              onChange={updateCourses}
              course={course}
              onDelete={deleteCourse}
            />
          );
        })}
      </FlexGroup>
      <Button
        text="+ Add new Course"
        className="form__btn--add"
        onClick={addCourse}
      />
      <SaveButtons
        alternateAction={saveCourses}
        hideCancel={true}
        saveText="Save custom courses"
        validationErrors={validationErrors}
      />
    </React.Fragment>
  );
};
