import { IntentToParticipateMember } from 'types';

export interface IntentToParticipateFiscalAgent {
  guid: string;
  consortiumId: number;
  consortiumName: string;
  fiscalAgentName: string;
  fiscalAgentUniversalContactId: number;
  fiscalYear: number;
  consortiumActive: boolean;
  hasResponded: boolean;
  members: IntentToParticipateMember[];
}

export const emptyIntentToParticipateFiscalAgent = (): IntentToParticipateFiscalAgent => {
  return {
    guid: '',
    consortiumId: 0,
    consortiumName: '',
    fiscalAgentName: '',
    fiscalAgentUniversalContactId: 0,
    fiscalYear: 0,
    consortiumActive: true,
    hasResponded: false,
    members: [],
  };
};
