import { useValidation } from 'de-formed-validations';
import { Program } from 'types';
import { isStringValid } from 'util/validation.utility';
import { every, some } from 'lodash';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { compose, prop } from 'util/objectUtility';
import { isEqual } from 'util/formHelpers';

const isMiddleSchoolProgram = compose(
  isEqual(PROGRAM_LEVEL_ENUM['Middle School']),
  prop('programLevelId')
);
const isSecondaryProgram = compose(
  isEqual(PROGRAM_LEVEL_ENUM.Secondary),
  prop('programLevelId')
);
const isPostsecondaryProgram = compose(
  isEqual(PROGRAM_LEVEL_ENUM.Postsecondary),
  prop('programLevelId')
);

export const ProgramGeneralValidations = () => {
  return useValidation<Program>({
    cipId: [
      {
        errorMessage: 'CIP code is required.',
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    programDescription: [
      {
        errorMessage: 'Program description is required.',
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    terms: [
      {
        errorMessage: 'Unit of length is required.',
        validation: (val: number) => {
          return val > 0;
        },
      },
      {
        errorMessage:
          'Program length and unit of length must be at least 2 years when combined.',
        validation: (_, program: Program) => {
          if (isSecondaryProgram(program)) {
            var bool = every([
              program.programLengthId > 0,
              program.programLengthId < 7,
              program.terms > 0,
            ]);
            switch (program.programLengthId) {
              case 1: //Years
                return bool && program.terms >= 2;
              case 2: //Semesters
                return bool && program.terms >= 4;
              case 3: //Weeks
                return bool && program.terms >= 68;
              case 4: //Trimester
                return bool && program.terms >= 6;
              case 5: //Quarters
                return bool && program.terms >= 8;
              case 6: //Hextares
                return bool && program.terms >= 12;
              default:
                return bool;
            }
          }
          return true;
        },
      },
    ],
    programLengthId: [
      {
        errorMessage: 'Program length is required.',
        validation: (val: number) => {
          return val > 0 && val !== 255;
        },
      },
      {
        errorMessage:
          'Program length and unit of length must be at least 2 years when combined.',
        validation: (_, program: Program) => {
          if (isSecondaryProgram(program)) {
            var bool = every([
              program.programLengthId > 0,
              program.programLengthId < 7,
              program.terms > 0,
            ]);
            switch (program.programLengthId) {
              case 1: //Years
                return bool && program.terms >= 2;
              case 2: //Semesters
                return bool && program.terms >= 4;
              case 3: //Weeks
                return bool && program.terms >= 68;
              case 4: //Trimester
                return bool && program.terms >= 6;
              case 5: //Quarters
                return bool && program.terms >= 8;
              case 6: //Hextares
                return bool && program.terms >= 12;
              default:
                return bool;
            }
          }
          return true;
        },
      },
    ],
    // only secondary validations
    ctsO_IDs: [
      {
        errorMessage: 'CTSO Organization is required.',
        validation: (val: string[], program: Program) => {
          return isSecondaryProgram(program) ? val.length > 0 : true;
        },
      },
    ],

    // postsecondary validations
    programRequirement: [
      {
        errorMessage: 'Prerequisites are required.',
        validation: (val: string, program: Program) => {
          return isPostsecondaryProgram(program) ? isStringValid(val) : true;
        },
      },
    ],
    industryCertifications: [
      {
        errorMessage: 'Industry certifications are required.',
        validation: (val: string, program: Program) => {
          return isPostsecondaryProgram(program) ? isStringValid(val) : true;
        },
      },
    ],
    bachelorDegrees: [
      {
        errorMessage: "Bachelor's degree information is required.",
        validation: (val: string, program: Program) => {
          return isPostsecondaryProgram(program) ? isStringValid(val) : true;
        },
      },
    ],
    advancedDegrees: [
      {
        errorMessage: 'Advanced degree information is required.',
        validation: (val: string, program: Program) => {
          return isPostsecondaryProgram(program) ? isStringValid(val) : true;
        },
      },
    ],

    // Occupational information validations
    employmentDemandInformation: [
      {
        errorMessage: 'Employment demand information is required.',
        validation: (val: string, program: Program) => {
          return some([
            isPostsecondaryProgram(program),
            isSecondaryProgram(program),
          ])
            ? isStringValid(val)
            : true;
        },
      },
    ],
    occupationInfoProgramCompletion: [
      {
        errorMessage: 'Occupation information is required.',
        validation: (val: string, program: Program) => {
          return some([
            isPostsecondaryProgram(program),
            isSecondaryProgram(program),
          ])
            ? isStringValid(val)
            : true;
        },
      },
    ],
    onetReviewed: [
      {
        errorMessage:
          'Click on the toggle to verify O*Net has been reviewed before saving.',
        validation: (val: boolean, program: Program) => {
          return isMiddleSchoolProgram(program) ? true : val;
        },
      },
    ],
    workBasedLearning: [
      {
        errorMessage: 'Work based learning is required.',
        validation: (val: string, program: Program) => {
          return some([
            isPostsecondaryProgram(program),
            isSecondaryProgram(program),
          ])
            ? isStringValid(val)
            : true;
        },
      },
    ],
  });
};
