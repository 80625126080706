import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTADistrictSalary, emptyCTADistrictSalary } from 'types';
import { FlexGroup, FlexRow } from 'layouts';
import { connect, useSelector } from 'react-redux';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { getRecords_CTADistrictSalary } from 'redux/cta/ctadistrictsalary.selectors';
import {
  loadCTADistrictSalary,
  saveCTADistrictSalary,
} from 'redux/cta/ctadistrictsalary';
import { safeGet } from 'util/objectUtility';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

interface DistrictSalaryProps {
  districtSalary: CTADistrictSalary;
  loadCTADistrictSalary: Function;
  saveCTADistrictSalary: Function;
  district: string;
  year: number;
}
export const DistrictSalary: React.FC<DistrictSalaryProps> = ({
  loadCTADistrictSalary: loadSalary,
  saveCTADistrictSalary: saveSalary,
  districtSalary,
  district,
  year,
}) => {
  // -- dependencies --
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local states --
  const [open, setOpen] = useState<boolean>(false);
  const [
    districtSalaryState,
    setDistrictSalaryState,
  ] = useState<CTADistrictSalary>(emptyCTADistrictSalary());

  // -- component logic --
  const handleFileUpload = (str: string) => {
    setDistrictSalaryState({ filePath: str });
  };
  const handleSave = () => {
    saveSalary(ctaId, districtSalaryState).then(() => {
      toast(
        <ToastNotification
          status="success"
          message={StringResources.Success.FileSaved}
        />
      );
    });
  };

  // -- lifecyle --
  useEffect(() => {
    loadSalary(ctaId).catch((err: any) => console.error(err));
  }, [loadCTADistrictSalary, ctaId]); // eslint-disable-line

  useEffect(() => {
    setDistrictSalaryState(districtSalary);
  }, [districtSalary]);

  // -- display logic --
  const get = safeGet(districtSalaryState);

  return (
    <SecureWrap component={['ctageneral']} isLocked={readOnly}>
      <Instruction title="District Salary Documentation for Supporting Personnel">
        <p>
          When you have finished entering data please combine your documentation
          into a single electronic file (PDF or XLS) and upload it using the
          ‘Upload Document’ field.
        </p>
        <p>
          <span className="u-color-red">Note</span>: If you upload a second
          document, it will replace the previous one.
        </p>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Upload Document</p>
          {get('filePath') ? (
            <AzureDownloader
              onClose={() => handleFileUpload('')}
              uploadParams={{
                container: 'cta',
                folder: `${year}|${district}|district-salary`,
              }}
              filepath={get('filePath')}
            />
          ) : (
            <SecureWrap component={['ctageneral']} isLocked={readOnly}>
              <Button
                text="+ Upload File"
                className="form__btn--add u-flex-self-start grant-schedule__upload-btn"
                onClick={() => setOpen(true)}
              />
            </SecureWrap>
          )}
          <AzureUploader
            onClose={() => setOpen(false)}
            open={open}
            uploadParams={{
              container: 'cta',
              folder: `${year}|${district}|district-salary`,
            }}
            onConfirm={handleFileUpload}
          />
        </FlexGroup>
        <div style={{ flexGrow: 1 }} />
        <SecureWrap component={['ctageneral']} isLocked={readOnly}>
          <Button
            onClick={handleSave}
            style={{ alignSelf: 'flex-end', height: '5.6rem' }}
            className="button--filled"
            text="Save"
          />
        </SecureWrap>
      </FlexRow>
    </SecureWrap>
  );
};

const mapStateToProps = (state: any) => {
  const districtSalary = getRecords_CTADistrictSalary(state);
  return {
    districtSalary,
  };
};

const dispatchProps = {
  loadCTADistrictSalary,
  saveCTADistrictSalary,
};

export default connect(mapStateToProps, dispatchProps)(DistrictSalary);
