import { useValidation } from 'de-formed-validations';
import { CertificationOfExemptionSignature } from 'types';
import { SignatureWithAssertionValidations } from './SignatureWithAssertions.validations';

// type alias
type S = CertificationOfExemptionSignature;

// define validation schema for validation hook
type ValidationState = {
  canSave: S;
  canSubmit: S;
};

export const CertificationOfExemptionItemValidations = () => {
  const { validate } = SignatureWithAssertionValidations();

  return useValidation<ValidationState>({
    canSave: [
      {
        errorMessage: 'Please fill in all fields before saving.',
        validation: (signature: S, _) => {
          return signature.institutionDoesNotMeetThreshold
            ? validate('canSave', signature)
            : true;
        },
      },
    ],
    canSubmit: [
      {
        errorMessage:
          'Not all signature fields have been provided or the single audit report is missing.',
        validation: (signature: S, _) => {
          const filePath = signature.auditReportFilePath;
          return signature.institutionDoesNotMeetThreshold
            ? validate('canSubmit', signature)
            : filePath && filePath.length > 0;
        },
      },
    ],
  });
};
