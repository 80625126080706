import { connect } from 'react-redux';
import { OptionMap, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';
import { TravelerType } from 'types/grant/TravelerType.types';
import {
  TRAVELER_TYPE_PATH,
  getRecords_TravelerType,
} from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    name,
    onChange,
    records: baseRecords,
  } = ownProps;

  const records = getRecordsUtility<TravelerType>(
    state,
    TRAVELER_TYPE_PATH,
    getRecords_TravelerType,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<TravelerType> = {
    key: 'expenseTravelerTypeId',
    label: 'expenseTravelerTypeName',
    value: 'expenseTravelerTypeId',
  };

  return {
    label: label ? label : 'Traveler Type',
    name: name ? name : 'expenseTravelerTypeId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
