import { PropertyDifferences } from 'types';

export interface HistoryData {
  name: string;
  dateOfChanges: Date | null;
  changeCount: number;
  changeType: string;
  propertyDifferences: PropertyDifferences[];
  childDifferences: HistoryData[];
}

export const emptyHistoryData = (): HistoryData => {
  return {
    name: '',
    changeCount: 0,
    changeType: '',
    dateOfChanges: null,
    propertyDifferences: [],
    childDifferences: [],
  };
};
