import { SelectBoxComponent } from './SelectBox.component';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { dropdownMapper } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: any) => {
  const { options, optionMap, selection, records } = ownProps;

  const values = options ? options : dropdownMapper(records, optionMap);
  const selectedItem = find(values, { value: selection });

  return {
    ...ownProps,
    options: values,
    selectedItem,
  };
};
const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(SelectBoxComponent);
