/**
 * Utility/helper methods for Universal Contacts
 * @module Utilities > ProgramInstanceUtility
 * @author AmplifyCP
 */

import { Program, School, Department } from 'types';
import { find, curry } from 'lodash';
import { PROGRAM_LEVELS } from 'constants/programLevels.const';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';

export const getProgramLevelName = (program: Program) => {
  return getProgramLevelNameById(program.programLevelId);
};

export const getProgramLevelNameById = (id: number) => {
  const value = find(PROGRAM_LEVELS, (level) => {
    return level.id === id;
  });
  return value ? value.name : EMPTY_PLACEHOLDER;
};

export const getShortProgramLevelName = (program: Program) => {
  return getShortProgramLevelNameById(program.programLevelId);
};

export const getShortProgramLevelNameById = (id: number) => {
  const value = find(PROGRAM_LEVELS, (level) => {
    return level.id === id;
  });
  switch (value ? value.name : '') {
    case 'Postsecondary':
      return 'P';

    case 'Secondary':
      return 'S';

    case 'Middle School':
      return 'M';

    default:
      return EMPTY_PLACEHOLDER;
  }
};

export const getSchoolName = curry((schools: School[], program: Program) => {
  const value = find(schools, (item) => {
    return item.schoolId === program.schoolId;
  });
  return value ? value.schoolName : EMPTY_PLACEHOLDER;
});

export const getDepartmentName = (
  departments: Department[],
  program: Program
) => {
  const value = find(departments, (item) => {
    return item.schoolDepartmentId === program.schoolDepartmentId;
  });
  return value ? value.departmentName : EMPTY_PLACEHOLDER;
};
