/**
 * Enum for SpendingCategories.
 * @readonly
 * @enum {number}
 */
export enum SPENDING_CATEGORY {
  IndirectAdministrativeCosts = 7,
  DirectAdministrativeCosts = 8,
  NeedsAssessmentCosts = 9,
  ImprovementPlanProject = 10,
}
