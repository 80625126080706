import React, { useRef, useEffect, useState } from 'react';
import { readGrantRecipientDocumentData } from 'services/grants/awardsignature.services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatMoney } from 'util/moneyHelpers';
import Icon from 'components/Common/Icons';
import Loading from 'components/Common/Loading';
import ReactToPrint from 'react-to-print';
import ToastNotification from 'components/Common/Toast';

export type GrantRecipientAgreementDocumentData = {
  federalGrantId: string;
  firstInstallmentAmount: number;
  consortiumName: string;
  grantFiscalYearStartDate: Date;
  grantFiscalYearEndDate: Date;
  grantReserveFundAmount: number;
  grantBasicFundAmount: number;
  totalGrantAmount: number;
  agreementDate: Date;
};

export const GrantRecipientAgreementDocumentComponent = () => {
  const printArea = useRef<HTMLDivElement>(null);

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    documentData,
    setDocumentData,
  ] = useState<GrantRecipientAgreementDocumentData>({
    federalGrantId: 'INVALID GRANT ID',
    firstInstallmentAmount: NaN,
    consortiumName: 'INVALID RECIEPIENT',
    grantFiscalYearStartDate: new Date('1/1/1970'),
    grantFiscalYearEndDate: new Date('1/1/1970'),
    grantReserveFundAmount: NaN,
    grantBasicFundAmount: NaN,
    totalGrantAmount: NaN,
    agreementDate: new Date('1/1/1970'),
  });

  useEffect(() => {
    readGrantRecipientDocumentData(grantFiscalYearId)
      .then((result) => {
        setDocumentData({
          ...result,
        });
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Could not load grant data, please refresh the page."
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setDocumentData, grantFiscalYearId]);

  const {
    federalGrantId,
    firstInstallmentAmount,
    consortiumName,
    grantFiscalYearStartDate,
    grantFiscalYearEndDate,
    grantReserveFundAmount,
    grantBasicFundAmount,
    totalGrantAmount,
    agreementDate,
  } = documentData;

  const insertDate = (value: Date) => {
    return insertValue(new Date(value).getFullYear());
  };
  const insertValue = (value: string | number) => {
    return <strong>{`${value}`}</strong>;
  };

  return (
    <React.Fragment>
      <Loading isActive={isLoading} />
      <div className="printable-padding" ref={printArea}>
        <div className="u-flex">
          <span className="u-flex-grow-1" />
          <span>
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="no-print u-pseudo-link">
                    <Icon
                      title="Print"
                      name="print"
                      className="history__print__icon"
                    />
                  </div>
                );
              }}
              content={() => {
                const parea = printArea as React.MutableRefObject<HTMLInputElement>;
                return parea.current;
              }}
            />
          </span>
        </div>
        <div
          className="printable u-flex-vertical"
          style={{ alignItems: 'center' }}
        >
          <p>
            <strong>Grant Recipient Agreement</strong>
          </p>
        </div>
        <div className="printable u-flex-vertical">
          <p>
            This Agreement dated{' '}
            {agreementDate
              ? new Date(agreementDate).toLocaleDateString(undefined, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : 'NOT SUBMITTED'}{' '}
            is entered into between the State of Colorado by the State Board for
            Community Colleges and Occupational Education ("State Board") and{' '}
            {insertValue(consortiumName)} ("Recipient").
          </p>
          <div
            className="printable u-flex-vertical"
            style={{ alignItems: 'center' }}
          >
            <p>Recitals</p>
          </div>
          <div>
            <ol style={{ marginLeft: '4rem' }}>
              <li>
                The State Board administers the Strengthening Career and
                Technical Education for the Twenty-First Century Act, ("Perkins"
                or "the Act") P.L. 115-224, within the guidelines of the
                Education Department General Administrative Regulations (EDGAR)
                and the Colorado Community College System (CCCS) CTE
                Administrators’ Handbook. The Perkins CFDA Number is 84.048A.
                The federal grant award number is {insertValue(federalGrantId)}.
              </li>
              <li>
                The State Board is distributing available funds to eligible
                recipients under the Act through a Request for Proposal (RFP)
                process, basic grant formulas established by the Act, and basic
                grant and other application processes established by the State
                Board.
              </li>
              <li>
                {insertValue(consortiumName)} is an eligible recipient and has
                been approved for receipt of one or more of such funds.
              </li>
              <li>
                These funds can be used for costs incurred between the later of
                July 1, {insertDate(grantFiscalYearStartDate)} or the date of
                your local plan submittal and June 30,{' '}
                {insertDate(grantFiscalYearEndDate)}.
              </li>
              <li>
                This Agreement and its accompanying Addenda contain the entire
                understanding of the Parties with respect to the subject matter
                hereof and supersedes all prior agreements, oral or written, and
                all other communications between the Parties relating to such
                subject matter. This Agreement may be executed in one or more
                counterparts (facsimile transmission or otherwise), each
                counterpart shall be deemed an original and all of which shall
                constitute but one Agreement. This Agreement may not be amended
                or modified except by mutual written agreement by both parties.
              </li>
              <li>
                The programs/projects identified in this Agreement represent
                programs/projects described in the approval documents on file
                with CCCS. Signing this Agreement will certify the Recipient has
                met the following criteria:
              </li>
              <ol>
                <li>
                  All related costs submitted for reimbursement for this fiscal
                  year (FY ending June 30, {insertDate(grantFiscalYearEndDate)})
                  are compliant with this Grant Recipient Agreement.
                </li>
                <li>
                  Career and Technical Education instructors of funded programs
                  have and maintain credentialing appropriate to their funded
                  program.
                </li>
              </ol>
            </ol>
          </div>
          <div>
            <ol type="I" style={{ marginLeft: '4rem' }}>
              <li>
                Grant documents include CCCS Request for Proposal(s) (RFP's) (if
                applicable), the local application, vouchers, concept papers or
                other applications for the grants listed below:
                <span>
                  <p>
                    Total Fiscal Year {insertDate(grantFiscalYearEndDate)} Award
                    Amounts:
                  </p>
                  <ul>
                    <li>
                      3.1 Secondary Reserved{' '}
                      {insertValue(`$${formatMoney(grantReserveFundAmount)}`)}
                    </li>
                    <li>
                      3.1 Secondary Non-Reserved /Basic{' '}
                      {insertValue(`$${formatMoney(grantBasicFundAmount)}`)}
                    </li>
                  </ul>
                  NOTE: The first installment total amount of{' '}
                  {insertValue(`$${formatMoney(firstInstallmentAmount)}`)} is
                  the spending limit for Quarter 1 (Date of Local Plan submittal
                  or July 1, {insertDate(grantFiscalYearStartDate)}, whichever
                  is later, through September 30,{' '}
                  {insertDate(grantFiscalYearStartDate)}.)
                </span>
              </li>
              <li>
                State Board shall pay Recipient an amount not to exceed{' '}
                {insertValue(`$${formatMoney(totalGrantAmount)}`)} for
                performing required services in accordance with the grant
                documents. The following pages specify important components of
                this grant.
              </li>
              <li>
                Allowability
                <ol type="a">
                  <li>
                    Recipient's expenditures submitted to CCCS for reimbursement
                    must be for appropriate purposes and in accordance with
                    provisions of grant documents. Recipient is required to
                    maintain adequate records to provide full disclosure of
                    grant expenditures. Such records include purchase orders,
                    invoices, payroll records, time and effort records in
                    support of payroll (monthly timesheets recommended), bid
                    solicitation, contracts and checks issued. These source
                    documents must demonstrate appropriate signatures and
                    systems of strong internal controls as required by the
                    Uniform Administrative Requirements, Cost Principles, and
                    Audit Requirements for Federal Awards at 2 C.F.R. Part 200.{' '}
                  </li>
                  <li>
                    Perkins funding for CTSO activities is restricted to
                    leadership activities, not competitive events.
                  </li>
                  <li>
                    Equipment must be tagged and tracked and disposition
                    requires CCCS approval.
                  </li>
                  <li>
                    Recipient must include the following documentation when
                    submitting requests and transaction details for
                    reimbursement of Perkins expenditures to CCCS: Type of
                    Expenditure Documents/Information Required from Recipients
                    Salaries and/or Wages Copy of Payroll Register, or
                    timesheets documenting time worked, documenting personnel
                    paid from the grant unless transaction details lists payees
                    by name. Note that timesheets or time and effort reports are
                    still required to be completed and may still be requested,
                    even if a Payroll Register with name detail is provided.
                    Indirect Costs Certification of current fiscal year’s
                    negotiated indirect rate Other For each transaction of
                    $5,000 or more, copies of invoices are required, even if
                    individual items are less. Equipment For purchases of
                    Equipment (any single unit with a useful life of more than
                    one year and an acquisition cost of greater than or equal to
                    $5,000), an equipment detailed invoice is required.
                  </li>
                  <li>
                    CCCS reserves the right to request additional documentation
                    if deemed necessary.{' '}
                  </li>
                  <li>
                    For expenditures that are funded by Perkins, Recipient
                    cannot claim reimbursement for those same expenditures from
                    other sources of funding.
                  </li>
                </ol>
              </li>
              <li>
                Recipient agrees to perform any additional project assurances
                noted below
                <ol type="a">
                  <li>
                    All Perkins federal fund budget change requests must be
                    received by CCCS no later than April 1 of the current fiscal
                    year ({insertDate(grantFiscalYearEndDate)}).
                  </li>
                  <li>
                    All Perkins Recipients must abide by the Federal Assurances
                    as provided with Perkins grant guidelines.
                  </li>
                  <li>
                    All Perkins Recipients must report accurate enrollment and
                    follow-up data by the data reporting deadlines.
                  </li>
                  <li>
                    All Federal grant requirements documented in the Uniform
                    Administrative Requirements, Cost Principles, and Audit
                    Requirements for Federal Awards as codified at 2 C.F.R. Part
                    200 and in Education Department General Administrative
                    Regulations (EDGAR) will apply.
                  </li>
                </ol>
              </li>
              <li>
                Termination - State Board may terminate part or all of this
                Agreement at any time if it believes Recipient is not performing
                services in an appropriate manner, or if Recipient has failed to
                remedy deficiencies after a 30-day written notice from State
                Board staff. Recipient will be entitled to be paid for services
                performed prior to the date of termination if they were
                allowable to the grant.
              </li>
              <li>
                Copyright and Distribution
                <ol type="a">
                  <li>
                    All of the items prepared for or submitted to the State
                    Board by Recipient under this Agreement shall belong
                    exclusively to the State Board and shall be deemed to be
                    works made for hire. To the extent that any of the
                    deliverable items may not, by operation of law, be works
                    made for hire, Recipient hereby assigns to the State Board
                    the ownership of copyright in the deliverable items and the
                    State Board shall have the right to obtain and hold in its
                    own name copyrights, registrations and similar protection
                    which may be available in the deliverable items. Recipient
                    agrees to give the State Board or its designees all
                    assistance reasonably required to perfect such rights.
                  </li>
                  <li>
                    To the extent that any pre-existing materials are contained
                    in the items, Recipient grants to State an irrevocable,
                    non-exclusive, worldwide, royalty-free license to (1) Use,
                    execute, reproduce, display, perform, distribute (internally
                    or externally) copies of, and prepare derivative works based
                    upon, such pre-existing materials and derivative works
                    thereof; and (2) Authorize others to do any, some or all of
                    the foregoing.
                  </li>
                  <li>
                    No license or right is granted to Recipient either expressly
                    or by implication, estoppel or otherwise to publish,
                    reproduce, prepare derivative works based upon, distribute
                    copies of, publicly display, or perform, any of such items,
                    except pre-existing materials of Recipient, either during or
                    after the term of this Agreement.{' '}
                  </li>
                </ol>
              </li>
              <li>
                Certification for Contracts, Grants, Loans, and Cooperative
                Agreements
                <ol type="a">
                  <li>
                    No federal appropriated funds have been paid or will be
                    paid, by or on behalf of the undersigned, to any person for
                    influencing or attempting to influence an officer or
                    employee of any agency, a Member of Congress, an officer or
                    employee of Congress, or an employee of a member of Congress
                    in connection with the awarding of any federal contract, the
                    making of any federal grant, the making of any federal loan,
                    the entering into of any cooperative agreement, and the
                    extension, continuation, renewal, amendment, or modification
                    of any federal contract, grant, loan, or cooperative
                    agreement.
                  </li>
                  <li>
                    If any funds other than federal appropriated funds have been
                    paid or will be paid to any person for influencing or
                    attempting to influence an officer or employee of an agency,
                    a member of Congress, an officer or employee of Congress, or
                    an employee of a member of Congress in connection with this
                    federal contract, grant, loan, or cooperative agreement, the
                    undersigned shall complete and submit Standard Form-LLL,
                    "Disclosure Form to Report Lobbying," in accordance with its
                    instructions.
                  </li>
                  <li>
                    The undersigned shall require that the language of this
                    certification be included in the award documents for all
                    sub-awards at all tiers (including subcontracts, sub-grants,
                    and contracts under grants, loans, and cooperative
                    agreements) and that all sub-recipients shall certify and
                    disclose accordingly. This certification is a material
                    representation of fact upon which reliance was placed when
                    this transaction was made or entered into. Submission of
                    this certification is a prerequisite for making or entering
                    into this transaction imposed by section 1352, Title 31,
                    U.S. Code. Any person who fails to file the required
                    certification shall be subject to a civil penalty of not
                    less than $10,000 and not more than $100,000 for each such
                    failure.
                  </li>
                </ol>
              </li>
              <li>
                Audit Compliance: The Recipient of federal financial assistance
                hereby agrees to the following audit requirements in accordance
                with the Single Audit Act
                <ol type="a">
                  <li>
                    to permit, as a condition of receiving federal funds under
                    this agreement, an independent auditor of the State Board or
                    local government to have access to the Recipient records and
                    financial statements as necessary to comply with the Single
                    Audit Act.
                  </li>
                  <li>
                    to arrange for an annual financial and compliance audit in
                    accordance with the Uniform Grant Guidance at 2 C.F.R. Part
                    200 if the Recipient expended over $750,000 of federal
                    financial assistance during a given fiscal year. The
                    $750,000 includes federal funds received directly from a
                    federal agency or indirectly through a state or local
                    government agency.
                  </li>
                </ol>
              </li>
              <li>
                This certification is required by the regulations implementing
                Executive Order 12549, Debarment and Suspension, 2 CFR Part 180,
                Subpart C, Responsibilities of Participants Regarding
                Transactions Doing Business with Other Persons. Copies of the
                regulations may be obtained by contacting the person to whom
                this proposal is submitted.
                <ol type="a">
                  <li>
                    The prospective lower tier participant certifies, by
                    submission of this proposal, that neither it nor its
                    principals are presently debarred, suspended, proposed for
                    debarment, declared ineligible, or voluntarily excluded from
                    participation in this transaction by any Federal department
                    or agency.
                  </li>
                  <li>
                    Where the prospective lower tier participant is unable to
                    certify to any of the statements in this certification, such
                    prospective participant shall attach an explanation to this
                    proposal.
                  </li>
                </ol>
              </li>
              <li>
                Records Management: The Recipient will maintain all fiscal
                records and related grant documentation for six years following
                the close of the fiscal year.
              </li>
              <li>
                Subcontracting: The Recipient shall not subcontract the
                performance of any part of its duties under this Grant except in
                accordance with the terms of this Grant or with the prior
                written consent of CCCS approving the Recipient.
              </li>
              <li className="printable">
                Equipment
                <p>
                  Equipment purchases are only for use in approved Career and
                  Technical Education programs. Equipment (any single unit with
                  a useful life of more than one year and an acquisition cost of
                  greater than or equal to $5,000) must be accounted for through
                  an established property management system. The information to
                  be maintained should at least include a description (including
                  serial number or other identification number), source, federal
                  grant award number (included on page 1 of this agreement), who
                  holds title, acquisition date and cost, percentage of Federal
                  participation in the cost, location, condition, and any
                  ultimate disposition data including, the date of disposal and
                  sales price or method used to determine current fair market
                  value.
                </p>
                <p>
                  Note: Prior written approval for Equipment must be obtained
                  from CCCS before purchasing or disposing of equipment.
                </p>
              </li>
              <li>
                Severability: To the extent this agreement may be executed and
                performance of the obligations of the parties may be
                accomplished within the intent of the contract, the terms of
                this contract are severable. Should any term or provision hereof
                be declared invalid or become inoperative for any reason, such
                invalidity or failure shall not affect the validity of any other
                term or provision hereof.
              </li>
              <li>
                Entire Understanding: This agreement is intended as the complete
                integration of all understandings between the parties. No prior
                or contemporaneous addition, deletion, or other amendment hereto
                shall have any force or affect whatsoever, unless embodied
                herein in writing. No subsequent novation, renewal, addition,
                deletion, or other amendment hereto shall have any force or
                effect unless embodied in a writing executed and approved
                pursuant to the Colorado State Fiscal Rules.
              </li>
              <li>
                Survival of Certain Contract Terms: Notwithstanding anything
                herein to the contrary, all terms and conditions of this
                contract, including but not limited to its exhibits and
                attachments, which may require continued performance,
                compliance, or effect beyond the termination date of the
                contract, shall survive such termination date and shall be
                enforceable by the State in the event of the Contractor’s
                failure to perform or comply as required.
              </li>
              <li>
                Modification and Amendment
                <ol type="a">
                  <li>
                    This agreement is subject to such modifications as may be
                    required by changes in Federal or Colorado State law, or
                    their implementing regulations. Any such required
                    modification automatically shall be incorporated into and be
                    part of this contract on the effective date of such change,
                    as if fully set forth herein.
                  </li>
                  <li>
                    Except as specifically provided in this contract, no
                    modification of this contract shall be effective unless
                    agreed to in writing by both parties in an Amendment to this
                    agreement, properly executed and approved in accordance with
                    Colorado State law and State Fiscal Rules.
                  </li>
                </ol>
              </li>
              <li>
                Venue: Venue for any action related to performance of this
                agreement shall be in the City and County of Denver, Colorado.
              </li>
              <li>
                CONTROLLER'S APPROVAL. CRS §24-30-202 (1).: This Contract shall
                not be valid until it has been approved by the Colorado State
                Controller or designee.
              </li>
              <li>
                FUND AVAILABILITY. CRS §24-30-202(5.5).: Financial obligations
                of the State payable after the current fiscal year are
                contingent upon funds for that purpose being appropriated,
                budgeted, and otherwise made available.
              </li>
              <li>
                GOVERNMENTAL IMMUNITY: No term or condition of this Contract
                shall be construed or interpreted as a waiver, express or
                implied, of any of the immunities, rights, benefits,
                protections, or other provisions, of the Colorado Governmental
                Immunity Act, CRS §24-10-101 et seq., or the Federal Tort Claims
                Act, 28 U.S.C. §§1346(b) and 2671 et seq., as applicable now or
                hereafter amended.
              </li>
              <li>
                INDEPENDENT CONTRACTOR: Contractor shall perform its duties
                hereunder as an independent contractor and not as an employee.
                Neither Contractor nor any agent or employee of Contractor shall
                be deemed to be an agent or employee of the State. Contractor
                and its employees and agents are not entitled to unemployment
                insurance or workers compensation benefits through the State and
                the State shall not pay for or otherwise provide such coverage
                for Contractor or any of its agents or employees. Unemployment
                insurance benefits will be available to Contractor and its
                employees and agents only if such coverage is made available by
                Contractor or a third party. Contractor shall pay when due all
                applicable employment taxes and income taxes and local head
                taxes incurred pursuant to this Contract. Contractor shall not
                have authorization, express or implied, to bind the State to any
                agreement, liability or understanding, except as expressly set
                forth herein. Contractor shall (a) provide and keep in force
                workers' compensation and unemployment compensation insurance in
                the amounts required by law, (b) provide proof thereof when
                requested by the State, and (c) be solely responsible for its
                acts and those of its employees and agents.
              </li>
              <li>
                COMPLIANCE WITH LAW: Contractor shall strictly comply with all
                applicable federal and State laws, rules, and regulations in
                effect or hereafter established, including, without limitation,
                laws applicable to discrimination and unfair employment
                practices.
              </li>
              <li>
                CHOICE OF LAW. Colorado law, and rules and regulations issued
                pursuant thereto, shall be applied in the interpretation,
                execution, and enforcement of this Contract. Any provision
                included or incorporated herein by reference which conflicts
                with said laws, rules, and regulations shall be null and void.
                Any provision incorporated herein by reference which purports to
                negate this or any other Special Provision in whole or in part
                shall not be valid or enforceable or available in any action at
                law, whether by way of complaint, defense, or otherwise. Any
                provision rendered null and void by the operation of this
                provision shall not invalidate the remainder of this Contract,
                to the extent capable of execution.
              </li>
              <li>
                BINDING ARBITRATION PROHIBITED: The State of Colorado does not
                agree to binding arbitration by any extra-judicial body or
                person. Any provision to the contrary in this Contract or
                incorporated herein by reference shall be null and void.
              </li>
              <li>
                SOFTWARE PIRACY PROHIBITION. Governor's Executive Order D 002
                00: State or other public funds payable under this Contract
                shall not be used for the acquisition, operation, or maintenance
                of computer software in violation of federal copyright laws or
                applicable licensing restrictions. Contractor hereby certifies
                and warrants that, during the term of this Contract and any
                extensions, Contractor has and shall maintain in place
                appropriate systems and controls to prevent such improper use of
                public funds. If the State determines that Contractor is in
                violation of this provision, the State may exercise any remedy
                available at law or in equity or under this Contract, including,
                without limitation, immediate termination of this Contract and
                any remedy consistent with federal copyright laws or applicable
                licensing restrictions.
              </li>
              <li>
                EMPLOYEE FINANCIAL INTEREST/CONFLICT OF INTEREST. CRS
                §§24-18-201 and 24-50-507. The signatories aver that to their
                knowledge, no employee of the State has any personal or
                beneficial interest whatsoever in the service or property
                described in this Contract. Contractor has no interest and shall
                not acquire any interest, direct or indirect, that would
                conflict in any manner or degree with the performance of
                Contractor’s services and Contractor shall not employ any person
                having such known interests.
              </li>
              <li>
                VENDOR OFFSET. CRS §§24-30-202 (1) and 24-30-202.4: [Not
                applicable to intergovernmental agreements] Subject to CRS
                §24-30-202.4 (3.5), the State Controller may withhold payment
                under the State’s vendor offset intercept system for debts owed
                to State agencies for: (a) unpaid child support debts or child
                support arrearages; (b) unpaid balances of tax, accrued
                interest, or other charges specified in CRS §39-21-101, et seq.;
                (c) unpaid loans due to the Student Loan Division of the
                Department of Higher Education; (d) amounts required to be paid
                to the Unemployment Compensation Fund; and (e) other unpaid
                debts owing to the State as a result of final agency
                determination or judicial action.
              </li>
              <li>
                PUBLIC CONTRACTS FOR SERVICES. CRS §8-17.5-101.: [Not applicable
                to agreements relating to the offer, issuance, or sale of
                securities, investment advisory services or fund management
                services, sponsored projects, intergovernmental agreements, or
                information technology services or products and services]
                Contractor certifies, warrants, and agrees that it does not
                knowingly employ or contract with an illegal alien who will
                perform work under this Contract and will confirm the employment
                eligibility of all employees who are newly hired for employment
                in the United States to perform work under this Contract,
                through participation in the E-Verify Program or the State
                program established pursuant to CRS §8-17.5-102(5)(c),
                Contractor shall not knowingly employ or contract with an
                illegal alien to perform work under this Contract or enter into
                a contract with a Subcontractor that fails to certify to
                Contractor that the Subcontractor shall not knowingly employ or
                contract with an illegal alien to perform work under this
                Contract. Contractor (a) shall not use E-Verify Program or State
                program procedures to undertake pre-employment screening of job
                applicants while this Contract is being performed, (b) shall
                notify the Subcontractor and the contracting State agency within
                three days if Contractor has actual knowledge that a
                Subcontractor is employing or contracting with an illegal alien
                for work under this Contract, (c) shall terminate the
                subcontract if a Subcontractor does not stop employing or
                contracting with the illegal alien within three days of
                receiving the notice, and (d) shall comply with reasonable
                requests made in the course of an investigation, undertaken
                pursuant to CRS §8-17.5-102(5), by the Colorado Department of
                Labor and Employment. If Contractor participates in the State
                program, Contractor shall deliver to the contracting State
                agency, Institution of Higher Education or political
                subdivision, a written, notarized affirmation, affirming that
                Contractor has examined the legal work status of such employee,
                and shall comply with all of the other requirements of the State
                program. If Contractor fails to comply with any requirement of
                this provision or CRS §8-17.5-101 et seq., the contracting State
                agency, institution of higher education or political subdivision
                may terminate this Contract for breach and, if so terminated,
                Contractor shall be liable for damages.
              </li>
              <li>
                PUBLIC CONTRACTS WITH NATURAL PERSONS. CRS §24-76.5-101:
                Contractor, if a natural person eighteen (18) years of age or
                older, hereby swears and affirms under penalty of perjury that
                he or she (a) is a citizen or otherwise lawfully present in the
                United States pursuant to federal law, (b) shall comply with the
                provisions of CRS §24-76.5-101 et seq., and (c) has produced one
                form of identification required by CRS §24-76.5-103 prior to the
                effective date of this Contract.
              </li>
            </ol>
          </div>
          <p>
            This agreement must be signed and submitted with the rest of the
            Award Packet documents before the January Voucher will be posted to
            your CTE in Colorado account. (Remember: The January Voucher request
            for reimbursement of funds must be submitted by January 15,{' '}
            {insertDate(grantFiscalYearEndDate)}.) Additionally, for Recipients
            that are not CCCS system colleges or their consortia, this Agreement
            will only be considered as complete upon receipt of the Recipient’s
            most recently completed, within the last 2 years, Single Audit
            Report. If the Recipient is not required to arrange for an annual
            financial and compliance audit, a signed copy of the Certification
            of Exemption From Single Audit Reports form must be provided
            instead. And finally, the signed copy of the Civil Rights Compliance
            Form must also be included. Signed pages of the Grant Recipient
            Agreement must be digitally signed in the CTE in Colorado site or
            must be originals in hard copy format
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
