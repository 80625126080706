import React from 'react';
import WorkflowDiff from 'components/Common/GeneralWorkflow/WorkflowDiff';
import BaseLayout from 'layouts/BaseLayout';
import ChangeLogLayout from 'layouts/ChangeLogLayout';

export const WorflowDiffPage: React.FC<any> = (props) => {
  return (
    <BaseLayout>
      <ChangeLogLayout {...props}>
        <WorkflowDiff />
      </ChangeLogLayout>
    </BaseLayout>
  );
};
