import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  putWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { ProgramLevel } from 'types';

const PROGRAM_LEVELS_ENDPOINT = 'api/ProgramLevels/';

export const createProgramLevel = async (programLevel: ProgramLevel) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_LEVELS_ENDPOINT}`;

  let value: Omit<ProgramLevel, 'programLevelId'> = {
    programLevelName: programLevel.programLevelName,
  };

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(value),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramLevels', err);
    });
}; // end

export const readProgramLevels = async () => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_LEVELS_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramLevels', err);
    });
}; // end

export const updateProgramLevel = async (programLevel: ProgramLevel) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_LEVELS_ENDPOINT}${
    programLevel.programLevelId
  }`;
  return fetch(url, {
    ...putWithAuthOptions(),
    body: JSON.stringify(programLevel),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramLevels', err);
    });
}; // end
