import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import Paper from 'components/Common/Paper';
import CTAAdministrationComponent from 'components/CTA/CTAAdministration';
import Instruction from 'components/Common/Instruction';

export const CTAAdminPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <section className="program__body">
        <Paper>
          <div className="program__header u-flex">
            <div className="u-flex-grow-1">
              <h2 className="program__title">CTA Administrator Management</h2>
            </div>
          </div>
          <Instruction title="" hideTitle>
            <p>
              This page will help you to open report submission in the Per-Pupil
              Operating Revenue report (PPOR) section, enter CTA financials, and
              run the CTA Reimbursement Calculation to determine the CTA
              distribution amounts for participating districts. Note that the
              CTA reporting section of the site is based on the year in which
              costs are incurred (i.e., the last academic/fiscal year), which
              would be the year prior to the current state fiscal year in which
              reimbursements will be made. Click the ‘Return to CTA Portal’ if
              you do not wish to work on the Administrator Management page. You
              may review costs incurred in prior fiscal years by clicking on the
              buttons below. Once you are ready to proceed, follow the
              instructions listed in the sections below.
            </p>
          </Instruction>
        </Paper>
        <div className="program__content">
          <CTAAdministrationComponent />
        </div>
      </section>
    </BaseLayout>
  );
};
