import { connect } from 'react-redux';
import { StudentEnrollmentComponent } from './StudentEnrollment.component';
import { loadStudentEnrollmentData } from 'redux/datacollection/studentenrollment';
import {
  getIsDataFetching_StudentEnrollment,
  getAdvancedFilteredRecords_StudentEnrollment,
} from 'redux/datacollection/studentenrollment.selectors';
import {
  getRecords_ProgramSnapshotStudent,
  getIsDataFetching_ProgramSnapshotStudent,
} from 'redux/datacollection/programsnapshotstudent.selectors';
import { some } from 'lodash';
import { loadProgramSnapshotStudent } from 'redux/datacollection/programsnapshotstudent';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any) => {
  const loggedInUser = getLoggedinUser(state);
  const enrollmentDataFetching = getIsDataFetching_StudentEnrollment(state);
  const enrollmentData = getAdvancedFilteredRecords_StudentEnrollment(state);
  const students = getRecords_ProgramSnapshotStudent(state);
  const studentsFetching = getIsDataFetching_ProgramSnapshotStudent(state);

  const isFetching = some([enrollmentDataFetching, studentsFetching]);

  return {
    enrollmentData,
    isFetching,
    loggedInUser,
    students,
  };
};

const dispatchToProps = {
  loadProgramSnapshotStudent,
  loadStudentEnrollmentData,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(StudentEnrollmentComponent);
