import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { getAccessToken, currentUser } from 'util/authorization';
import { UniversalContact } from 'types';
import {
  getContactFullName,
  getUserInitials,
} from 'util/universalcontact.utility';
import { FlexGroup } from 'layouts';
import { CircularProgress } from '@rmwc/circular-progress';
import Button from 'components/Common/FormElements/Button';
import ResetPassword from 'components/Common/Authorization/ResetPassword';

type LoginButtonProps = {
  isAuthenticated: boolean;
  isFetching: boolean;
  loadUser: Function;
  logUserOut: Function;
  user: UniversalContact;
  shouldRefreshPermissions: boolean;
  loadUserPermissions: Function;
};

export const AccountMenuComponent: React.FC<LoginButtonProps> = (props) => {
  const {
    isAuthenticated,
    isFetching,
    loadUser,
    logUserOut,
    user,
    shouldRefreshPermissions,
    loadUserPermissions,
  } = props;
  const [navOpen, setNavOpen] = useState(false);
  const [navFocused, setNavFocused] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement>(null);

  const handleClick = (ev: MouseEvent) => {
    let target = ev.target as HTMLElement;

    if (menu.current !== null && menu.current.contains(target)) {
      return;
    } else {
      setNavOpen(false);
    }
  };

  const getButtonClass = () => {
    return navFocused
      ? 'navigation__button navigation__button--focused'
      : 'navigation__button';
  };

  const history = useHistory();
  const navigateToAccount = () => {
    history.push(`/contacts/${user.universalContactId}`);
  };

  useEffect(() => {
    if (shouldRefreshPermissions) {
      loadUserPermissions();
    }
  }, [loadUserPermissions, shouldRefreshPermissions]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const token = getAccessToken();
    const userid = currentUser().aadB2cId;
    if (token && !isAuthenticated) {
      loadUser(userid);
    }
  }, [isAuthenticated, loadUser]);

  return (
    <div>
      <ResetPassword visible={false} />
      <div className="navigation" ref={menu}>
        <div className="navigation__menu-btn">
          <input
            checked={navOpen}
            className="navigation__checkbox"
            id="account-toggle"
            onChange={() => {
              setNavOpen(!navOpen);
            }}
            onFocus={() => setNavFocused(true)}
            onBlur={() => setNavFocused(false)}
            onKeyDown={(e: React.KeyboardEvent) =>
              e.keyCode === 13 && setNavOpen(!navOpen)
            }
            type="checkbox"
          />
          <label htmlFor="account-toggle" className={getButtonClass()}>
            <span className="navigation__button--text">
              {isFetching ? (
                <CircularProgress
                  style={{ margin: '1rem 1rem 0 0' }}
                  size={56}
                />
              ) : (
                <React.Fragment>{getUserInitials(user)}</React.Fragment>
              )}
            </span>
          </label>
        </div>
        <nav id="account-menu">
          <div className={`account-menu ${navOpen && 'u-show'}`}>
            <div className="account-menu__row">
              <div className="account-menu__avatar">
                <span className="account-menu__avatar--initials">
                  {getUserInitials(user)}
                </span>
              </div>
              <div className="account-menu__group">
                <h2 className="account-menu__user">
                  {getContactFullName(user)}
                </h2>
                <span className="account-menu__email">{user.loginEmail}</span>
              </div>
            </div>
            <div className="account-menu__row">
              <FlexGroup>
                <Button
                  onClick={navigateToAccount}
                  text="Edit Account Info"
                  className="account-menu__button--flat u-margin-top"
                />
                <ResetPassword />
              </FlexGroup>
            </div>
            <FlexGroup>
              <Button
                text="Logout"
                className="account-menu__button"
                onClick={() => {
                  logUserOut();
                }}
              />
            </FlexGroup>
          </div>
        </nav>
        <p
          onClick={() => {
            document.getElementById('account-toggle')!.focus();
            setNavOpen(!navOpen);
          }}
          className="navigation__menu-title"
          aria-hidden
        >
          Account
        </p>
      </div>
    </div>
  );
};
