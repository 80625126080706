import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, SubmissionManagementFilterState } from 'types';

const initialState: SubmissionManagementFilterState = {
  incompleteFollowUp: undefined,
  incompleteTeachers: undefined,
  incompleteEnrollment: undefined,
  district: undefined,
  institute: undefined,
};

export const setSubmissionManagementFilterValue = createAction(
  'setSubmissionManagementFilterValue'
);
export const resetSubmissionManagementFilterValue = createAction(
  'resetSubmissionManagementFilterValue'
);
export const clearSubmissionManagementFilters = createAction(
  'clearSubmissionManagementFilters'
);

export default handleActions<
  SubmissionManagementFilterState,
  KeyValueType<unknown> | string
>(
  {
    setSubmissionManagementFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetSubmissionManagementFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearSubmissionManagementFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
