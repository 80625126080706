import { useValidation } from 'de-formed-validations';
import { SnapshotStudent } from 'types';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import {
  isEqual,
  validatePhoneNumber,
  validateEmail,
  validateSocialSecurityNumber,
} from 'util/formHelpers';
import { compose, prop, isTruthy } from 'util/objectUtility';
import { some, trim } from 'lodash';
import { isStringValid } from 'util/validation.utility';

// type ValidationState = {
//   // -------------------
//   // personal
//   // -------------------
//   firstName: boolean;
//   lastName: boolean;
//   dob: boolean;
//   sasId: boolean;

//   // secondary or postsecondary
//   phone: boolean;
//   email: boolean;

//   // postsecondary
//   alternateId: boolean;
//   ssn: boolean;

//   // -------------------
//   // demographic
//   // -------------------
//   sex: boolean;

//   // secondary or postsecondary
//   hispanic: boolean;
//   limitedEnglish: boolean;
//   disadvantaged: boolean;

//   // secondary
//   iep: boolean;
//   active504: boolean;
//   migrantStudent: boolean;

//   // postsecondary
//   singleParent: boolean;
//   psDisability: boolean;
//   outOfWorkforceIndividual: boolean;

//   // -------------------
//   // academic
//   // -------------------
//   homeCde_ID: boolean;
//   grade: boolean;

//   // secondary or postsecondary
//   participant: boolean;
//   concentrator: boolean;
//   completer: boolean;

//   // secondary
//   race: boolean;

//   highSchoolGraduate: boolean;
//   wbl: boolean;

//   // postsecondary
//   fullTimeCte: boolean;
// };

const isMiddleSchool = compose(
  isEqual(PROGRAM_LEVEL_ENUM['Middle School']),
  prop('programLevelId')
);
const isSecondary = compose(
  isEqual(PROGRAM_LEVEL_ENUM.Secondary),
  prop('programLevelId')
);
const isPostsecondary = compose(
  isEqual(PROGRAM_LEVEL_ENUM.Postsecondary),
  prop('programLevelId')
);

export const SnapshotStudentValidations = () => {
  return useValidation<SnapshotStudent>({
    firstName: [
      {
        errorMessage: 'First Name is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    lastName: [
      {
        errorMessage: 'Last Name is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    dob: [
      {
        errorMessage: 'Age needs to be between 8 to 80 years old.',
        validation: (val: Date, _) => {
          const currentDate = new Date();
          const inputDate = new Date(val);
          const monthsDiff =
            currentDate.getMonth() -
            inputDate.getMonth() +
            12 * (currentDate.getFullYear() - inputDate.getFullYear());
          return (
            Math.floor(monthsDiff / 12) >= 8 &&
            Math.floor(monthsDiff / 12) <= 80
          );
        },
      },
    ],
    sasId: [
      {
        errorMessage: 'SAS ID must be 10 digit.',
        validation: (val: string, state: SnapshotStudent) => {
          return isSecondary(state)
            ? isStringValid(val) && val.trim().length === 10
            : isPostsecondary(state)
            ? !val || (isStringValid(val) && val.trim().length === 10)
            : true;
        },
      },
    ],
    phone: [
      {
        errorMessage: 'Must be valid phone number.',
        validation: (val: string, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val === '' || validatePhoneNumber(val)
            : true;
        },
      },
    ],
    email: [
      {
        errorMessage: 'Must be valid email.',
        validation: (val: string, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val === '' || validateEmail(val)
            : true;
        },
      },
    ],
    alternateId: [
      {
        errorMessage: '9 digit Student ID is required.',
        validation: (val: string, state: SnapshotStudent) => {
          return isPostsecondary(state)
            ? isStringValid(val) && val.trim().length === 9
            : true;
        },
      },
    ],
    ssn: [
      {
        errorMessage: 'SSN must be a valid number',
        validation: (val: string, state: SnapshotStudent) => {
          return isPostsecondary(state)
            ? val === '' || validateSocialSecurityNumber(val)
            : true;
        },
      },
    ],

    sex: [
      {
        errorMessage: 'Gender is required.',
        validation: (val: string | null, _) => {
          return isTruthy(val);
        },
      },
    ],
    hispanic: [
      {
        errorMessage: 'CTE Ethnicity is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    limitedEnglish: [
      {
        errorMessage: 'English Proficiency is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    disadvantaged: [
      {
        errorMessage: 'Economically Disadvantaged is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    iep: [
      {
        errorMessage: 'IEP is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) ? val !== null : true;
        },
      },
    ],
    active504: [
      {
        errorMessage: 'Active 504 is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) ? val !== null : true;
        },
      },
    ],
    migrantStudent: [
      {
        errorMessage: 'Migrant Student is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) ? val !== null : true;
        },
      },
    ],
    singleParent: [
      {
        errorMessage: 'Single Parent is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isPostsecondary(state) ? val !== null : true;
        },
      },
    ],
    psDisability: [
      {
        errorMessage: 'PS Disability is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isPostsecondary(state) ? val !== null : true;
        },
      },
    ],
    outOfWorkforceIndividual: [
      {
        errorMessage: 'Out of Workforce is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isPostsecondary(state) ? val !== null : true;
        },
      },
    ],
    homeCde_ID: [
      {
        errorMessage: 'Home School ID is required.',
        validation: (val: string | number | null, state: SnapshotStudent) => {
          return isMiddleSchool(state) || isSecondary(state)
            ? isTruthy(val)
            : true;
        },
      },
    ],
    grade: [
      {
        errorMessage: 'Grade is required.',
        validation: (val: string | number | null, _) => {
          return isTruthy(val);
        },
      },
    ],
    participant: [
      {
        errorMessage: 'Participant is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    concentrator: [
      {
        errorMessage: 'Concentrator is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    completer: [
      {
        errorMessage: 'Completer is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) || isPostsecondary(state)
            ? val !== null
            : true;
        },
      },
    ],
    wbl: [
      {
        errorMessage: 'WBL is required.',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isSecondary(state) ? val !== null : true;
        },
      },
    ],
    highSchoolGraduate: [
      {
        errorMessage: 'High School Graduate info is required.',
        validation: (val: number | null, state: SnapshotStudent) => {
          return isSecondary(state) ? val !== null : true;
        },
      },
    ],
    fullTimeCte: [
      {
        errorMessage: 'Full Time CTE is required',
        validation: (val: boolean | null, state: SnapshotStudent) => {
          return isPostsecondary(state) ? val !== null : true;
        },
      },
    ],
    race: [
      {
        errorMessage: 'Race is required',
        validation: (_, state: SnapshotStudent) => {
          return isSecondary(state)
            ? some([
                state.pacificIslander,
                state.asian,
                state.black,
                state.white,
                state.americanIndian,
              ])
            : true;
        },
      },
    ],
    instructorId: [
      {
        errorMessage: 'Must be a 3 character Instructor ID.',
        validation: (val: string, state: SnapshotStudent) => {
          return isSecondary(state) ? !val || trim(val).length === 3 : true;
        },
      },
    ],
  });
};
