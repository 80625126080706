import { PERMISSIONS_KEY, PERMISSIONS_CONTACTS_ROLES } from 'redux/keys';
import { getRecords } from 'redux/utility/_exports';
import { PermissionRole } from 'types';

export const PERMISSIONS_CONTACTS_ROLES_PATH: string[] = [
  PERMISSIONS_KEY,
  PERMISSIONS_CONTACTS_ROLES,
];

export const getRecords_ContactPermissionRoles = (
  state: any
): PermissionRole[] => {
  return getRecords<PermissionRole>(state, PERMISSIONS_CONTACTS_ROLES_PATH);
};
