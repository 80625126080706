import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { readProgramContactTypes } from 'services/common';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { ProgramContactType } from 'types';

const initialState = baseInitialState;

export const loadProgramContactTypes = createActionThunk(
  'loadProgramContactTypes',
  () => {
    return readProgramContactTypes();
  }
); // end

export default handleActions<BaseStateObject, ProgramContactType[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramContactType[]>(loadProgramContactTypes),
  },
  initialState
);
