import SelectBox from 'components/Common/FormElements/SelectBox';
import { connect } from 'react-redux';
import {
  getRecords_PermissionTypes,
  PERMISSION_TYPES_PATH,
} from 'redux/permissions/permissiontype.selectors';
import { OptionMap, PermissionType, SelectWrapper } from 'types';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { filter, onChange, selection, records: baseRecords } = ownProps;

  const records = getRecordsUtility<PermissionType>(
    state,
    PERMISSION_TYPES_PATH,
    getRecords_PermissionTypes,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<PermissionType> = {
    key: 'permissionTypeId',
    value: 'permissionTypeId',
    label: 'permissionTypeName',
  };

  return {
    label: '',
    name: 'permissionTypeId',
    onChange,
    optionMap,
    records,
    selection,
    noLabel: true,
  };
};

export default connect(mapStateToProps)(SelectBox);
