import React from 'react';
import ReactSelect from 'react-select';
import { DropdownItem, FiscalYear, HandleSelect } from 'types';
import { SelectBoxProps } from 'components/Common/FormElements/SelectBox/SelectBox.component';

interface FiscalYearSelectBoxProps extends SelectBoxProps {
  fiscalYear: FiscalYear;
}

export const FiscalYearSelectBox: React.FC<FiscalYearSelectBoxProps> = (
  props
) => {
  const {
    disabled,
    errorMessage,
    onChange,
    options,
    selectedItem,
    valid,
  } = props;

  const handleSelection: HandleSelect = (item: any) => {
    if (disabled || !onChange || !item) return;
    const data: { [key: string]: FiscalYear } = {};
    const value = (item as DropdownItem).value;
    data['fiscalYear'] = value;
    onChange && onChange(data, 'fiscalYear', value);
  };

  return (
    <div className="form__group">
      <label htmlFor="fiscalYear" className="form__label">
        Fiscal Year
      </label>
      <ReactSelect
        className={`react-select-container ${
          valid ? 'react-select__valid' : 'react-select__invalid'
        }`}
        classNamePrefix="react-select"
        name="fiscalYear"
        onChange={handleSelection}
        options={options}
        value={selectedItem}
      />
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
