import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, putWithAuthOptions, catchError } from 'services';

const PROGRAM_APPROVALS_ENDPOINT = 'api/ProgramApprovals/';
const REQUEST_RENEWAL_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}request-renewal/`;

const REQUEST_REVISION_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}request-revision/`;
const APPROVE_REVISION_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}approve-revision/`;

export const sendRenewalRequest = async (programInstanceId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${REQUEST_RENEWAL_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...putWithAuthOptions() })
    .then(toJSON)
    .then((data: any) => {
      return {
        newProgramId: Number(data),
      };
    })
    .catch((err) => {
      return catchError('ProgramApprovals', err);
    });
}; // end

export const sendRevisionRequest = async (programInstanceId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${REQUEST_REVISION_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...putWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramApprovals', err);
    });
}; // end

export const approveRevisionRequest = async (programInstanceId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${APPROVE_REVISION_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...putWithAuthOptions() })
    .then(toJSON)
    .then((data: any) => {
      return {
        newProgramId: Number(data),
      };
    })
    .catch((err) => {
      return catchError('ProgramApprovals', err);
    });
}; // end
