import { connect } from 'react-redux';
import {
  getProgramContactsDeep,
  getIsDataFetching_ProgramContacts,
} from 'redux/programs/contacts.selectors';
import {
  loadProgramContacts,
  saveProgramContacts,
} from 'redux/programs/contacts';
import { ContactsSummaryComponent } from './ContactsSummary.component';
import { ContactsEditComponent } from './ContactsEdit.component';
import { getActiveProgramId } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getActiveProgramLevel,
  getIsProgramReadOnly,
} from 'redux/programs/_selectors';
import {
  getRecords_ProgramContactTypes,
  getNextStep,
} from 'redux/common/_selectors';
import { some } from 'lodash';
import { getIsDataFetching_UniversalContact } from 'redux/universalcontacts/universalcontacts.selectors';

export const mapStateToProps = (state: any) => {
  const programInstanceId = getActiveProgramId(state);
  const programContactCache = getProgramContactsDeep(state, programInstanceId);
  const programLevel = getActiveProgramLevel(state);
  const isFetching = some([
    getIsDataFetching_ProgramContacts(state),
    getIsDataFetching_UniversalContact(state),
  ]);
  const programContactTypes = getRecords_ProgramContactTypes(state);
  const nextProgramStep = getNextStep(state, 'contacts');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    isFetching,
    nextProgramStep,
    programContactCache,
    programContactTypes,
    programLevel,
    readOnly,
  };
};

const dispatchToProps = {
  loadProgramContacts,
  saveProgramContacts,
  setCurrentNavAnchor,
};

export const ContactsSummary = connect(
  mapStateToProps,
  dispatchToProps
)(ContactsSummaryComponent);

export const ContactsEdit = connect(
  mapStateToProps,
  dispatchToProps
)(ContactsEditComponent);
