import React from 'react';
import Button from '../FormElements/Button';
import { FlexGroup, FlexRow } from 'layouts';
import { DynamicFormProps } from 'types';
import Icon from '../Icons';

export const DynamicForm: React.FC<DynamicFormProps> = ({
  addButtonText,
  addForm,
  form,
  items,
  onChange,
  removeForm,
  resetValidation,
  submitFailed,
}) => {
  return (
    <>
      <FlexGroup>
        {items.map((data: any, index: number) => {
          const changeWithIndex = onChange(index);
          return (
            <FlexRow key={index}>
              {React.createElement(form, {
                data,
                onChange: changeWithIndex,
                resetValidation,
                submitFailed,
              })}
              <div style={{ position: 'relative' }}>
                <div
                  className="form__delete-btn"
                  onClick={() => removeForm(index)}
                >
                  <Icon
                    name="crossCircle"
                    title="Remove"
                    className="form__delete-btn--svg"
                  />
                </div>
              </div>
            </FlexRow>
          );
        })}
      </FlexGroup>
      <FlexRow>
        <Button
          className="form__btn--add u-reduce-margin-top"
          onClick={addForm}
          text={addButtonText ? addButtonText : '+ Add'}
        />
      </FlexRow>
    </>
  );
};
