import { connect } from 'react-redux';
import { loadSingleWebReport, loadPublicWebReport } from 'redux/reporting/reports';
import { getLoggedinUser } from 'redux/user/selectors';
import { isApplicaionIdValid } from 'util/global';
import { ReportingItemComponent } from './ReportingItem.component';

export const mapStateToProps = (state: any, ownState: any) => {
  const loggedInUser = getLoggedinUser(state);
  const isLoggedin = isApplicaionIdValid(loggedInUser.universalContactId);
  return { isLoggedin };
};

const dispatchProps = {
  loadSingleWebReport,
  loadPublicWebReport,
};

export default connect(mapStateToProps, dispatchProps)(ReportingItemComponent);
