import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readImprovementPlan,
  updateImprovementPlan,
} from 'services/grants/improvementPlan.services';
import { ImprovementPlan } from 'types';

const initialState = baseInitialState;

export const loadImprovementPlan = createActionThunk(
  'loadImprovementPlan',
  (grantFiscalYearId: number) => {
    return readImprovementPlan(grantFiscalYearId);
  }
);

export const saveImprovementPlan = createActionThunk(
  'saveImprovementPlan',
  (grantFiscalYearId: number, plan: ImprovementPlan) => {
    return updateImprovementPlan(grantFiscalYearId, plan);
  }
);

export default handleActions<
  BaseStateObject,
  ImprovementPlan | ImprovementPlan[]
>(
  {
    ...baseLoadActionBuilder<ImprovementPlan | ImprovementPlan[]>(
      loadImprovementPlan
    ),
    ...baseSaveActionBuilder<ImprovementPlan | ImprovementPlan[]>(
      saveImprovementPlan
    ),
  },
  initialState
);
