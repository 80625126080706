import { ParticipationDashboardFilters } from './Filters.component';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { prop, len as length } from 'util/objectUtility';
import { getRecords_GrantFiscalYear } from 'redux/grants/_selectors';
import { GrantFiscalYear } from 'types';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import {
  clearPerkinsGrantFilters,
  setPerkinsGrantFilterValue,
} from 'redux/filters/perkinsgrantsfilter';
import { getRecords_PerkinsGrantFilterState } from 'redux/filters/_selectors';

const mapStateProps = (state: any) => {
  const allApplications = getRecords_GrantFiscalYear(state);
  const filterState = getRecords_PerkinsGrantFilterState(state);

  const isPostSecondary = (grantFiscalYear: GrantFiscalYear) => {
    return grantFiscalYear.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary;
  };
  const isSecondary = (grantFiscalYear: GrantFiscalYear) => {
    return grantFiscalYear.programLevelId === PROGRAM_LEVEL_ENUM.Secondary;
  };

  const needsReview = prop('needsReview');
  const needsFinanceReview = prop('needsFinanceReview');

  const filterLengths = {
    needsReview: length(filter(allApplications, needsReview)),
    needsFinanceReview: length(filter(allApplications, needsFinanceReview)),
    postsecondary: length(filter(allApplications, isPostSecondary)),
    secondary: length(filter(allApplications, isSecondary)),
  };

  return {
    filterLengths,
    filterState,
  };
};

const dispatchProps = {
  clearPerkinsGrantFilters,
  setPerkinsGrantFilterValue,
};

export default connect(
  mapStateProps,
  dispatchProps
)(ParticipationDashboardFilters);
