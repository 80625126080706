export interface AdminComments {
  templateId: number;
  adminComment: string;
}

export const emptyAdminComment = () => {
  return {
    templateId: -1,
    adminComment: '',
  };
};
