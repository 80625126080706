import { connect } from 'react-redux';
import {
  getRecords_ProgramLengths,
  PROGRAM_LENGTHS_PATH,
} from 'redux/common/_selectors';
import { SelectWrapper, OptionMap, ProgramLength } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    onChange,
    records: baseRecords,
    onBlur,
  } = ownProps;

  const records = getRecordsUtility<ProgramLength>(
    state,
    PROGRAM_LENGTHS_PATH,
    getRecords_ProgramLengths,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<ProgramLength> = {
    key: 'programLengthId',
    label: 'programLengthName',
    value: 'programLengthId',
  };

  return {
    ...ownProps,
    onBlur,
    label: label ? label : 'Unit of Length',
    name: 'programLengthId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
