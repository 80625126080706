import React, { useState, useEffect } from 'react';
import {
  SnapshotStudent,
  emptySnapshotStudent,
  APIErrorEntity,
  APIError,
  StudentEnrollment,
} from 'types';
import { getFullName } from 'util/datacollection.utility';
import { mergeObjects } from 'util/objectUtility';
import { SnapshotStudentValidations } from 'validations/DataCollection/SnapshotStudent.validations';
import { toast } from 'react-toastify';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import { map, remove, startCase, isNil } from 'lodash';
import AcademicInformation from './AcademicInformation';
import DemographicInformation from './DemographicInformation';
import Instruction from 'components/Common/Instruction';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import PersonalInformation from './PersonalInformation';
import ProgramInformation from './ProgramInformation';
import SaveButtons from 'components/Common/SaveButtons';
import ToastNotification from 'components/Common/Toast';

export type StudentRecordProps = {
  enrollment: StudentEnrollment;
  student: SnapshotStudent;
};

export type StudentRecordComponentProps = StudentRecordProps & {
  v: ValidationObject<any>;
  onChange: Function;
  ssnLocked?: boolean;
  alternateIdLocked?: boolean;
};

type ModalRecordProps = StudentRecordProps & {
  close: Function;
  reduxFunc: Function;
  saveSnapshotStudent(student: SnapshotStudent): Promise<APIErrorEntity>;
};

export const EditSnapshotStudent: React.FC<ModalRecordProps> = (props) => {
  const { enrollment, student, close, saveSnapshotStudent } = props;
  const v = SnapshotStudentValidations();
  const [studentState, setStudentState] = useState<SnapshotStudent>(emptySnapshotStudent());
  const [apiErrors, setApiErrors] = useState<APIError[]>([]);
  const [ssnLocked, setSsnLocked] = useState<boolean>(false);
  const [alternateIdLocked, setAlternateIdLocked] = useState<boolean>(false);

  const onChange = (
    data: Partial<SnapshotStudent>,
    name: keyof SnapshotStudent,
    val: string | boolean | number | null | undefined
  ) => {
    const races = ['americanIndian', 'pacificIslander', 'black', 'white', 'americanIndian'];
    const updated = mergeObjects<SnapshotStudent>(studentState, data);
    setStudentState(updated);
    if (races.includes(name)) {
      v.validateIfTrue('race', null, updated);
    } else {
      v.validateIfTrue(name as any, val, updated);
    }
    // If the field with an API error is updated remove the error
    if (apiErrors.length > 0) {
      remove(apiErrors, (val) => {
        return val.field === name;
      });
    }
  };

  const handleSave = () => {
    const valid = v.validateAll(studentState);

    if (valid) {
      return saveSnapshotStudent(studentState).then((data: APIErrorEntity) => {
        if (data.errors.length > 0) {
          setApiErrors(data.errors);
          toast(<ToastNotification status="error" message="Save Student Failed" />);
        } else {
          setApiErrors([]);
          close();
          toast(<ToastNotification status="success" message="Student data saved." />);
        }
      });
    }
    toast(
      <ToastNotification
        status="error"
        message="Not all validations passed. Please check the form for errors."
      />
    );
  };

  // Combines UI validation errors and API validaion errors
  const getErrors = () => {
    return [
      ...v.validationErrors,
      ...map(apiErrors, (val: APIError) => {
        return `${startCase(val.field)}: ${val.error}`;
      }),
    ];
  };

  useEffect(() => {
    setApiErrors([]);
    v.resetValidationState();
    if (student) {
      setStudentState(student);
      !isNil(student.alternateId) ? setAlternateIdLocked(true) : setAlternateIdLocked(false);
      !isNil(student.ssn) ? setSsnLocked(true) : setSsnLocked(false);
    }
  }, [student]); // eslint-disable-line

  return (
    <React.Fragment>
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Student Record: ${getFullName(studentState)}`}
              isPageHeader={true}
            >
              <p>
                Enter information for the student you wish to add to this program. Note that
                fields will automatically populate for students found within the database.
                Please verify that these data are accurate before proceeding. If a match is not
                made, you will need to manually enter this information. Input boxes highlighted
                in blue will pre-populate and are not editable. When you have entered all
                information click on the ‘Save’ button to return to the Student Enrollment
                overview.{' '}
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <PersonalInformation
          ssnLocked={ssnLocked}
          alternateIdLocked={alternateIdLocked}
          enrollment={enrollment}
          student={studentState}
          onChange={onChange}
          v={v}
        />
      </Paper>
      <Paper>
        <ProgramInformation
          enrollment={enrollment}
          student={studentState}
          onChange={onChange}
          v={v}
        />
      </Paper>
      <Paper>
        <DemographicInformation
          enrollment={enrollment}
          student={studentState}
          onChange={onChange}
          v={v}
        />
      </Paper>
      <Paper>
        <AcademicInformation
          enrollment={enrollment}
          student={studentState}
          onChange={onChange}
          v={v}
        />
      </Paper>
      <Paper>
        <SaveButtons
          alternateAction={handleSave}
          disabled={getErrors().length > 0}
          onCancel={close}
          validationErrors={getErrors()}
        />
      </Paper>
    </React.Fragment>
  );
};
