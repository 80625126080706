import { CustomCourseComponent } from './CustomCourse.component';
import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { updateProgramCourseCreditsCache } from 'redux/caches/coursecredit';
import { getProgramCourseCredits } from 'redux/caches/_selectors';

const mapStateToProps = (state: any, ownprops: any) => {
  const programInstanceId = getActiveProgramId(state);
  const courseCredits = getProgramCourseCredits(state, programInstanceId);

  return {
    courseCredits,
  };
};

const dispatchToProps = {
  updateProgramCourseCreditsCache,
};

export default connect(mapStateToProps, dispatchToProps)(CustomCourseComponent);
