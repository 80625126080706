import React, { useEffect } from 'react';
import { isApplicaionIdValid } from 'util/global';
import { useParams } from 'react-router-dom';
import { FlexRow, FlexGroup } from 'layouts';
import { ProgramOfStudy, Program, UniversalContact, ProgramStep } from 'types';
import {
  getInstitutionOrSchoolName,
  getPartnerProgramName,
} from 'util/ProgramOfStudy.utility';
import { some, every } from 'lodash';
import { contactHasRoles, isSuperAdmin } from 'util/permissionsUtility';
import { canContactEditProgram } from 'util/universalcontact.utility';
import { ApprovePOS } from './ApprovalActions/ApprovePOS';
import { DenyPOS } from './ApprovalActions/DenyPos';
import { RemovePOS } from './ApprovalActions/RemovePos';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SaveButtons from 'components/Common/SaveButtons';
import Table from 'components/Common/Table';

type ProgramOfStudyEditProps = {
  isFetching: boolean;
  loadProgramOfStudyData: Function;
  nextProgramStep: ProgramStep;
  program: Program;
  programsOfStudy: ProgramOfStudy[];
  readOnly: boolean;
  removeProgramOfStudy: Function;
  saveProgramOfStudyApproval: Function;
  saveProgramOfStudyDenial: Function;
  setCurrentNavAnchor: Function;
  user: UniversalContact;
};

export const ProgramOfStudyEditComponent: React.FC<ProgramOfStudyEditProps> = (
  props
) => {
  const {
    isFetching,
    loadProgramOfStudyData,
    nextProgramStep,
    program,
    programsOfStudy,
    readOnly,
    removeProgramOfStudy,
    saveProgramOfStudyApproval,
    saveProgramOfStudyDenial,
    setCurrentNavAnchor,
    user,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramOfStudyData(programInstanceId);
    }
  }, [loadProgramOfStudyData, programInstanceId]);

  useEffect(() => {
    setCurrentNavAnchor('programofstudy');
  }, [setCurrentNavAnchor]);

  const getApprovalOptions = (row: ProgramOfStudy) => {
    const requesterProgram =
      row.ownerContactId === row.postSecondaryProgram.requestorContactId
        ? row.postSecondaryProgram
        : row.secondaryProgram;

    const isOwner =
      requesterProgram.programInstanceId === program.programInstanceId;

    const canEditThisProgram = some([
      isSuperAdmin(user),
      canContactEditProgram(user, program),
    ]);

    const hasPermissions = some([
      Number(user.universalContactId) === row.ownerContactId,
      contactHasRoles(user, [
        'primaryprogrammanager',
        'limitedprogrammanager',
        'admin',
      ]),
    ]);

    const canApprove = every([
      !isOwner,
      every([canEditThisProgram, hasPermissions]),
    ]);

    const canRemove = every([canEditThisProgram, hasPermissions]);

    return (
      <span className="pos__option">
        <ApprovePOS
          statusId={row.programOfStudyStatusId}
          isApprover={canApprove}
          onApprove={() => {
            saveProgramOfStudyApproval(
              program.programInstanceId,
              row.programOfStudyId
            );
          }}
        />
        <DenyPOS
          statusId={row.programOfStudyStatusId}
          isApprover={canApprove}
          onDeny={() => {
            saveProgramOfStudyDenial(
              program.programInstanceId,
              row.programOfStudyId
            );
          }}
        />
        <RemovePOS
          canRemove={canRemove}
          onRemove={() => {
            removeProgramOfStudy(
              program.programInstanceId,
              row.programOfStudyId
            );
          }}
        />
      </span>
    );
  };

  const getApprovalStatusText = (row: ProgramOfStudy) => {
    switch (row.programOfStudyStatusId) {
      case 1:
        return 'Approved';

      case 2:
        return 'Denied';

      case 3:
        return 'Pending';

      default:
        return 'Needs Approval';
    }
  };

  const POS_HEADERS: TableColumnProps[] = [
    {
      name: 'Institution | School',
      cell: (row: ProgramOfStudy) => getInstitutionOrSchoolName(row, program),
      sortable: true,
      selector: (row: ProgramOfStudy) =>
        getInstitutionOrSchoolName(row, program),
    },
    {
      name: 'Program',
      cell: (row: ProgramOfStudy) => getPartnerProgramName(row, program),
      sortable: true,
      selector: (row: ProgramOfStudy) => getPartnerProgramName(row, program),
    },
    {
      name: 'Status',
      cell: getApprovalStatusText,
      sortable: true,
      selector: getApprovalStatusText,
      width: '10%',
    },
    {
      width: '22rem',
      name: 'Approve | Deny | Remove',
      cell: getApprovalOptions,
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading
        isActive={isFetching}
        messageBefore="Loading Program of Study Data..."
      />
      <SecureWrap component="programs">
        <Paper>
          <Instruction title="Program of Study">
            <p>
              Click on the ‘Add a Program of Study’ then select the name of the
              district / school or institution / department. Type the first few
              letters of the program you are partnering with and click on the
              appropriate option.
            </p>
          </Instruction>
          <FlexRow>
            <h2>Program of Study Partnerships</h2>
          </FlexRow>

          <SecureWrap
            component="ProgramsProgramOfStudy"
            requireEdit={true}
            isLocked={readOnly}
          >
            <FlexRow>
              <Button
                text="+ Add a Program of Study"
                className="form__btn--add"
                asLink={true}
                to={`/programs/programofstudy/${programInstanceId}/create`}
              />
            </FlexRow>
          </SecureWrap>
          <FlexRow>
            <FlexGroup>
              <Table columns={POS_HEADERS} data={programsOfStudy} />
            </FlexGroup>
          </FlexRow>
        </Paper>
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            saveData={() => {
              return Promise.resolve();
            }}
            saveUrl={nextProgramStep.nav.url}
            saveText="Save and Continue"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
