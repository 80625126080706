import { connect } from 'react-redux';
import { ProgramDataHydrator } from './ProgramDataHydrator';
import {
  shouldRefreshReduxState_CareerCluster,
  shouldRefreshReduxState_CipCode,
  shouldRefreshReduxState_CtsoCodes,
  shouldRefreshReduxState_ProgramContactTypes,
  shouldRefreshReduxState_ProgramLengths,
  shouldRefreshReduxState_ProgramLevels,
  shouldRefreshReduxState_ProgramSteps,
  shouldRefreshReduxState_ProgramArea,
  shouldRefreshReduxState_ProgramStates,
} from 'redux/common/_selectors';
import { shouldRefreshReduxState_AwardTypes } from 'redux/programs/_selectors';
import { shouldRefreshReduxState_Courses } from 'redux/common/courses.selectors';
import { shouldRefreshReduxState_CourseTypes } from 'redux/programs/coursetypes.selectors';
import { loadAwardTypes } from 'redux/programs/awardtypes';
import { loadCourseTypes } from 'redux/programs/coursetypes';
import { loadProgramAreas } from 'redux/common/programareas';
import { loadCareerClusters } from 'redux/common/careerclusters';
import { loadCIPCodes } from 'redux/common/cipcodes';
import { loadCourses } from 'redux/common/courses';
import { loadCtsoCodes } from 'redux/common/ctsos';
import { loadProgramContactTypes } from 'redux/common/programcontacttypes';
import { loadProgramLengths } from 'redux/common/programlengths';
import { loadProgramLevels } from 'redux/common/programlevels';
import { loadProgramSteps } from 'redux/common/programsteps';
import { loadProgramStates } from 'redux/common/programstates';

export const mapStateToProps = (state: any) => {
  const shouldRefreshProgramSteps = shouldRefreshReduxState_ProgramSteps(state);
  const shouldRefreshProgramArea = shouldRefreshReduxState_ProgramArea(state);
  const shouldRefreshAwardTypes = shouldRefreshReduxState_AwardTypes(state);
  const shouldRefreshCareerClusters = shouldRefreshReduxState_CareerCluster(
    state
  );
  const shouldRefreshCipCodes = shouldRefreshReduxState_CipCode(state);
  const shouldRefreshCourses = shouldRefreshReduxState_Courses(state);
  const shouldRefreshCourseTypes = shouldRefreshReduxState_CourseTypes(state);
  const shouldRefreshCtsoCodes = shouldRefreshReduxState_CtsoCodes(state);
  const shouldRefreshProgramContactTypes = shouldRefreshReduxState_ProgramContactTypes(
    state
  );
  const shouldRefreshProgramLengths = shouldRefreshReduxState_ProgramLengths(
    state
  );
  const shouldRefreshProgramLevels = shouldRefreshReduxState_ProgramLevels(
    state
  );
  const shouldRefreshProgramStates = shouldRefreshReduxState_ProgramStates(
    state
  );
  return {
    shouldRefreshAwardTypes,
    shouldRefreshCareerClusters,
    shouldRefreshCipCodes,
    shouldRefreshCourses,
    shouldRefreshCourseTypes,
    shouldRefreshCtsoCodes,
    shouldRefreshProgramArea,
    shouldRefreshProgramContactTypes,
    shouldRefreshProgramLengths,
    shouldRefreshProgramLevels,
    shouldRefreshProgramStates,
    shouldRefreshProgramSteps,
  };
};

const dispatchProps = {
  loadAwardTypes,
  loadCareerClusters,
  loadCIPCodes,
  loadCourses,
  loadCourseTypes,
  loadCtsoCodes,
  loadProgramContactTypes,
  loadProgramLengths,
  loadProgramStates,
  loadProgramSteps,
  loadProgramLevels,
  loadProgramAreas,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProgramDataHydrator);
