import { ProgramStepSideNav } from 'components/Program/ProgramStepSideNav/ProgramStepSideNav.component';
import { connect } from 'react-redux';
import { getProgramNav } from 'redux/application/selectors';
import { getNavTrayClosed } from 'redux/application/selectors';
import { setNavTrayClosed } from 'redux/application';
import { getProgramSteps } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const { pathname } = ownProps;
  const programNav = getProgramNav(state);
  const navData = {
    title: pathname.includes('program-steps')
      ? 'Program Summary'
      : 'Edit Program',
    scrollNav: pathname.includes('program-steps'),
    steps: getProgramSteps(state),
  };
  const navTrayClosed = getNavTrayClosed(state);

  return {
    navTrayClosed,
    programNav,
    navData,
  };
};

const dispatchToProps = {
  setNavTrayClosed,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramStepSideNav);
