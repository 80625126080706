import { combineReducers } from 'redux';
import {
  PROGRAM_AWARD_CACHE_KEY,
  PROGRAM_COURSE_CREDIT_CACHE_KEY,
  PROGRAM_CAREER_PATHWAY_COURSE_CACHE_KEY,
} from 'redux/keys';
import programaward from 'redux/caches/programaward';
import coursecredit from 'redux/caches/coursecredit';
import programcareerpathwaycourse from 'redux/caches/programpathway';

export default combineReducers({
  [PROGRAM_AWARD_CACHE_KEY]: programaward,
  [PROGRAM_COURSE_CREDIT_CACHE_KEY]: coursecredit,
  [PROGRAM_CAREER_PATHWAY_COURSE_CACHE_KEY]: programcareerpathwaycourse,
});
