import { useValidation } from 'de-formed-validations';
import { StateType } from 'components/Common/Authorization/ResetPassword/ResetPassword.component';
import { isStringValid } from 'util/validation.utility';

export const ResetPasswordValidations = () => {
  return useValidation<StateType>({
    newPassword: [
      {
        errorMessage: 'The password must be between 8 and 64 characters.',
        validation: (val: string, _) => {
          return val.trim().length >= 8 && val.trim().length < 64;
        },
      },
      {
        errorMessage:
          'The password must have at least 3 of the following:\n' +
          ' a lowercase letter,\n' +
          ' an uppercase letter,\n' +
          ' a digit,\n' +
          ' a symbol',
        validation: (val: string, _) => {
          let requirments = 0;
          const lower = new RegExp('(?=.*[a-z])');
          const upper = new RegExp('(?=.*[A-Z])');
          const number = new RegExp('(?=.*[0-9])');
          const special = /(?=.*[@#$%^&;*\-_+=[\]{}|\\:',?/`~"();! ])/;
          if (isStringValid(val)) {
            if (lower.test(val.trim())) requirments++;
            if (upper.test(val.trim())) requirments++;
            if (number.test(val.trim())) requirments++;
            if (special.test(val.trim())) requirments++;
          }
          return requirments >= 3;
        },
      },
    ],
    confirmNewPassword: [
      {
        errorMessage:
          'The password entry fields do not match. Please enter the same password in both fields and try again.',
        validation: (val: string, state: StateType) => {
          return state.newPassword === val;
        },
      },
    ],
  });
};
