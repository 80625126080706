import { CTA_KEY, CTA_COURSE_HOSTING_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { defaultTo, find } from 'lodash';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTACourse, emptyCTACourse } from 'types';

export const CTA_COURSE_HOSTING_PATH = [CTA_KEY, CTA_COURSE_HOSTING_KEY];

export const getIsDataFetching_CTACourseHosting = (state: any): boolean => {
  return getIsDataFetching(state, CTA_COURSE_HOSTING_PATH);
};

export const getRecords_CTACourseHosting = (state: unknown): CTACourse[] => {
  return getRecords(state, CTA_COURSE_HOSTING_PATH);
};

export const getAdvancedFilteredRecords_CTACourseHosting = (
  state: any
): CTACourse[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTACourse>(
    state,
    CTA_COURSE_HOSTING_PATH,
    filters
  );
  return records;
};

export const getSingleRecord_CTACourseHosting = (
  state: unknown,
  ctaId: number
): CTACourse => {
  const records = getRecords_CTACourseHosting(state);

  return defaultTo(
    find(records, (item: CTACourse) => {
      return item.ctaId === Number(ctaId);
    }),
    emptyCTACourse()
  );
};
