import { QuestionValue, emptyQuestionValue } from './QuestionValue.types';

export interface Question {
  childQuestions: Question[];
  dataLength: number;
  dataType: string;
  fileUploadRequired: boolean;
  isQuestionOptional?: boolean;
  linkName: string;
  link: string;
  questionId: number;
  questionValue: QuestionValue;
  text: string;
}

export const emptyQuestion = (): Question => {
  return {
    childQuestions: [],
    dataLength: 8675309,
    dataType: 'textbox',
    fileUploadRequired: false,
    isQuestionOptional: false,
    linkName: '',
    link: '',
    questionId: 0,
    questionValue: emptyQuestionValue(),
    text: '',
  };
};
