export interface BaseStateObject {
  records: any[];
  lastUpdate: number;
  lastFailure: number;
  isFetching: boolean;
}
export const baseInitialState: BaseStateObject = {
  records: [],
  lastUpdate: 0,
  lastFailure: 0,
  isFetching: false,
};
