import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAContracting, CTACourse } from 'types';
import {
  updateCTAContractingHost,
  readCTAContractingHost,
} from 'services/cta/ctacontracting.services';

const initialState = baseInitialState;

export const loadCTAContractingHost = createActionThunk(
  'loadCTAContractingHost',
  (ctaId: number, ctaContractingId: number) => {
    return readCTAContractingHost(ctaId, ctaContractingId);
  }
);

export const saveCTAContractingHost = createActionThunk(
  'saveCTAContractingHost',
  (ctaId: number, ctaContractingId: number, ctaCourses: CTACourse[]) => {
    return updateCTAContractingHost(ctaId, ctaContractingId, ctaCourses);
  }
);

export default handleActions<BaseStateObject, CTAContracting[]>(
  {
    ...baseLoadActionBuilder<CTAContracting[]>(loadCTAContractingHost),
    ...baseSaveActionBuilder(saveCTAContractingHost),
  },
  initialState
);
