import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';

type FilterProps = {
  clearFilterValue: Function;
  filterLengths: any;
  setFilterValue: Function;
};

export const ParticipationDashboardFilters: React.FC<FilterProps> = (props) => {
  const { clearFilterValue, filterLengths, setFilterValue } = props;

  const [activeFilter, setActiveFilter] = useState<string>('');

  /**
   * @prop hotButtonName The name of the hot button
   * @prop filters Object containing filters to put in redux
   * @return void
   */
  const updateFilterValue = (hotButtonName: string, filters: any) => {
    clearFilterValue();
    return activeFilter === hotButtonName
      ? setActiveFilter('')
      : (() => {
          setActiveFilter(hotButtonName);
          setFilterValue(filters);
        })();
  };

  useEffect(() => {
    clearFilterValue();
  }, [clearFilterValue]);

  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'incompleteEnrollment'}
          iconName="clipboard"
          number={filterLengths.incompleteEnrollment}
          onClick={() => {
            updateFilterValue('incompleteEnrollment', {
              incompleteEnrollment: true,
            });
          }}
          text="Incomplete Enrollment"
        />
        <FilterHotButton
          active={activeFilter === 'district'}
          iconName="bus"
          number={filterLengths.district}
          onClick={() => {
            updateFilterValue('district', {
              district: true,
            });
          }}
          text="District"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'incompleteFollowUp'}
          iconName="clipboard"
          number={filterLengths.incompleteFollowUp}
          onClick={() => {
            updateFilterValue('incompleteFollowUp', {
              incompleteFollowUp: true,
            });
          }}
          text="Incomplete Follow-up"
        />
        <FilterHotButton
          active={activeFilter === 'institute'}
          iconName="accountBalance"
          number={filterLengths.institute}
          onClick={() => {
            updateFilterValue('institute', {
              institute: true,
            });
          }}
          text="Institution"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'incompleteTeachers'}
          iconName="clipboard"
          number={filterLengths.incompleteTeachers}
          onClick={() => {
            updateFilterValue('incompleteTeachers', {
              incompleteTeachers: true,
            });
          }}
          text="Incomplete Teachers"
        />
      </FlexGroup>
    </FlexRow>
  );
};
