import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { SignatureWithAssertion } from 'types';
import { SignatureWithAssertionValidations } from 'validations/AwardPacket/SignatureWithAssertions.validations';
import { mergeObjects } from 'util/objectUtility';
import Checkbox from 'components/Common/FormElements/Checkbox';
import Signature from 'components/Common/Signature';
import WarningAlert from '../WarningAlert';

type Props = {
  onChange: Function;
  signature: SignatureWithAssertion;
  assertionString: string;
  validState?: boolean;
};

export const SignatureWithAssertionComponent: React.FC<Props> = (props) => {
  const { assertionString, validState = true, onChange, signature } = props;

  const {
    getError,
    getFieldValid,
    validate,
  } = SignatureWithAssertionValidations();

  const [forceValidation, setForceValidation] = useState<boolean>(false);

  const handleCheckBox = (data: Partial<SignatureWithAssertion>) => {
    const updatedSignature = mergeObjects<SignatureWithAssertion>(
      signature,
      data
    );
    updateValidations(signature);
    onChange(updatedSignature);
  };

  const updateValidations = (signature: SignatureWithAssertion) => {
    const valid = validate('canSave', signature);
    setForceValidation(!valid);
  };

  useEffect(() => {
    updateValidations(signature);
  }, [signature]); // eslint-disable-line

  return (
    <React.Fragment>
      <FlexGroup>
        <FlexRow>
          <div className="u-margin-top">
            <Checkbox
              label={assertionString}
              name="wasReadAndAgreedTo"
              onChange={handleCheckBox}
              value={signature.wasReadAndAgreedTo}
            />
          </div>
        </FlexRow>
        <Signature
          signature={signature}
          onChange={onChange}
          forceValidation={forceValidation}
        />
        {(!getFieldValid('canSave') || !validState) && (
          <FlexRow>
            <WarningAlert iconPosition="left">
              {getError('canSave')}
            </WarningAlert>
          </FlexRow>
        )}
      </FlexGroup>
    </React.Fragment>
  );
};
