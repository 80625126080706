import React, { useState, useEffect, useRef } from 'react';
import { map } from 'lodash';
import { useHistory } from 'react-router';
import { FlexRow, FlexGroup, ButtonContainer } from 'layouts';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import ToastNotification from 'components/Common/Toast';
import WarningAlert from '../WarningAlert';
import { insertClass } from 'util/formHelpers';
import ConfirmationDialog from '../ConfirmationDialog';
import { doNothing } from 'util/global';

type SaveButtonsProps = {
  alternateAction?: Function;
  cancelText?: string;
  cancelUrl?: string;
  disabled?: boolean;
  errorMessage?: string;
  hideCancel?: boolean;
  onCancel?: Function;
  saveData?: () => Promise<any>;
  saveText?: string;
  saveUrl?: string;
  validationErrors?: string[];
  testId?: string;
  confirmation?: {
    tooltip: string;
    header: string;
    text: string;
  };
};

export const SaveButtons: React.FC<SaveButtonsProps> = (props) => {
  const {
    alternateAction,
    cancelText = 'Cancel',
    cancelUrl,
    disabled,
    errorMessage = 'Please complete the form and check for potential validation errors',
    hideCancel = false,
    onCancel,
    saveText = 'Save',
    saveData,
    saveUrl,
    validationErrors = [],
    testId = 'test',
    confirmation,
  } = props;

  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const mountedRef = useRef(true);

  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };

  const history = useHistory();
  const navigateTo = (url: string) => history.push(url);

  const handleSave = async () => {
    if (saveData) {
      setHasRequested(true);
      return saveData()
        .then(() => saveUrl && navigateTo(saveUrl))
        .catch((err: any) => toast(<ToastNotification status="error" message={err} />))
        .finally(() => mountedRef.current && setHasRequested(false));
    }
    if (alternateAction) {
      toggleHasRequested();
      return alternateAction();
    }
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <ButtonContainer>
      {disabled && validationErrors.length === 0 && (
        <FlexRow>
          <span className="u-margin-bottom-large u-error">
            <WarningAlert>{errorMessage}</WarningAlert>
          </span>
        </FlexRow>
      )}
      {validationErrors.length > 0 && (
        <div className="u-margin-bottom-large">
          {map(validationErrors, (error, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <WarningAlert>{error}</WarningAlert>
              </div>
            );
          })}
        </div>
      )}
      <FlexRow>
        <FlexGroup>
          {!hideCancel && (
            <div className="form__group u-sec-ro--override">
              <Button
                asLink={false}
                text={cancelText}
                onClick={() => {
                  onCancel && onCancel();
                  cancelUrl && navigateTo(cancelUrl);
                }}
                className={`form__btn--cancel${insertClass(`t-${testId}-cancel`)}`}
              />
            </div>
          )}
        </FlexGroup>
        <FlexGroup>
          {confirmation ? (
            <ConfirmationDialog
              disabled={disabled}
              tooltip={confirmation.tooltip}
              header={confirmation.header}
              onConfirm={handleSave}
              activateButton={
                <Button
                  style={{ width: '100%' }}
                  asLink={false}
                  text={saveText}
                  onClick={doNothing}
                  disabled={disabled || hasRequested}
                  className={`form__btn--submit${insertClass(`t-${testId}-save`)}`}
                />
              }
            >
              <p>{confirmation.text}</p>
            </ConfirmationDialog>
          ) : (
            <Button
              asLink={false}
              text={saveText}
              onClick={handleSave}
              disabled={disabled || hasRequested}
              className={`form__btn--submit${insertClass(`t-${testId}-save`)}`}
            />
          )}
        </FlexGroup>
      </FlexRow>
    </ButtonContainer>
  );
};
