import { baseInitialState, actionUpdateRecords } from 'redux/utility/_exports';
import { SET_ADMIN_COMMENTS } from '../adminComments/adminComments.actions';
import { upsertInPlace } from 'util/arrayUtility';

const initState = baseInitialState;

export const adminCommentsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_ADMIN_COMMENTS:
      const newRecords = upsertInPlace(
        state.records,
        action.payload,
        'templateId'
      );
      return actionUpdateRecords(state, newRecords);

    default:
      return state;
  }
};
