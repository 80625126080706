import { combineReducers } from 'redux';
import {
  WORKFLOW_ASSURANCES_KEY,
  WORKFLOW_TWO_YEAR_KEY,
  WORKFLOW_FOUR_YEAR_KEY,
  WORKFLOW_ANNUAL_SPENDING_PLAN_KEY,
  WORKFLOW_ASURANCE_SIGNATURE_KEY,
  WORKFLOW_ANNUAL_REFLECTION_KEY,
  WORKFLOW_AWARD_PACKET_KEY,
  WORKFLOW_VOUCHER_KEY,
  WORKFLOW_IMPROVEMENT_PLAN_KEY,
  WORKFLOW_PROGRAMS_KEY,
  WORKFLOW_CTA_KEY,
} from 'redux/keys';
import workflowassurances from './workflowassurances';
import { twoYearWorkflowReducer } from 'redux/generalworkflow/twoyearworkflow.reducer';
import { fourYearWorkflowReducer } from 'redux/generalworkflow/fouryearworkflow.reducer';
import { annualSpendingPlanWorkflowReducer } from 'redux/generalworkflow/annualspendingplan.reducer';
import { assuranceSignatureWorkflowReducer } from 'redux/generalworkflow/assurancesignatureworkflow.reducer';
import { annualReflectionWorkflowReducer } from 'redux/generalworkflow/annualreflection.reducer';
import { awardPacketReducer } from 'redux/generalworkflow/awardpacket.reducer';
import { voucherWorkflowReducer } from 'redux/generalworkflow/voucherworkflow.reducer';
import { improvementPlanWorkflowReducer } from 'redux/generalworkflow/improvementplanworkflow.reducer';
import { programWorkflowReducer } from 'redux/generalworkflow/programworkflow.reducer';
import { ctaWorkflowReducer } from 'redux/generalworkflow/ctaworkflow.reducer';

export default combineReducers({
  [WORKFLOW_PROGRAMS_KEY]: programWorkflowReducer,
  [WORKFLOW_ASSURANCES_KEY]: workflowassurances,
  [WORKFLOW_TWO_YEAR_KEY]: twoYearWorkflowReducer,
  [WORKFLOW_FOUR_YEAR_KEY]: fourYearWorkflowReducer,
  [WORKFLOW_ANNUAL_SPENDING_PLAN_KEY]: annualSpendingPlanWorkflowReducer,
  [WORKFLOW_ASURANCE_SIGNATURE_KEY]: assuranceSignatureWorkflowReducer,
  [WORKFLOW_ANNUAL_REFLECTION_KEY]: annualReflectionWorkflowReducer,
  [WORKFLOW_AWARD_PACKET_KEY]: awardPacketReducer,
  [WORKFLOW_VOUCHER_KEY]: voucherWorkflowReducer,
  [WORKFLOW_IMPROVEMENT_PLAN_KEY]: improvementPlanWorkflowReducer,
  [WORKFLOW_CTA_KEY]: ctaWorkflowReducer,
});
