import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { SideNavData } from 'types/ui/SideNavData.types';
import { readGrantVoucherOverviewNav } from 'services/nav/grantvoucher.services';

const initialState = baseInitialState;

export const loadGrantVoucherOverviewNav = createActionThunk(
  'loadGrantVoucherOverviewNav',
  () => {
    return readGrantVoucherOverviewNav();
  }
);

export default handleActions<BaseStateObject, SideNavData>(
  {
    ...baseLoadActionBuilder<SideNavData>(loadGrantVoucherOverviewNav),
  },
  initialState
);
