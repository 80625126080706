import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  actionUpdateRecords,
  baseLoadActionBuilder,
} from 'redux/utility/_exports';
import {
  readGrantParticipants,
  updateGrantParticipants,
} from 'services/grants/participationManagement';
import { GrantParticipationMember } from 'types/grant/GrantParticipationMember';
import { upsertInPlace } from 'util/arrayUtility';
import { map } from 'lodash';
import { randomString } from 'util/global';

const initialState = baseInitialState;

export const loadGrantParticipants = createActionThunk(
  'loadGrantParticipants',
  async (grantFiscalYearId: number) => {
    return readGrantParticipants(grantFiscalYearId).then((results) => {
      return map(results, (item) => {
        return {
          ...item,
          key: randomString(),
        };
      });
    });
  }
);

export const saveGrantParticipants = createActionThunk(
  'saveGrantParticipants',
  (participants: GrantParticipationMember[]) => {
    return updateGrantParticipants(participants);
  }
);

export const upsertGrantParticipants = createAction('upsertGrantParticipants');

export default handleActions<BaseStateObject, GrantParticipationMember[]>(
  {
    upsertGrantParticipants: (state, { payload }) => {
      const records = upsertInPlace(state.records, payload, 'key');
      return actionUpdateRecords(state, records);
    },

    ...baseLoadActionBuilder<GrantParticipationMember[]>(loadGrantParticipants),
  },
  initialState
);
