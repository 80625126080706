import {
  saveSnapshotTeacher,
  deactivateSnapshotTeacher,
  insertActiveTeacherComplete,
  loadActiveTeachers,
} from 'redux/datacollection/activeteachers';
import {
  insertSnapshotTeacher,
  removeSnapshotTeacher,
} from 'redux/datacollection/activeteachers';
import { loadUniversalTeachers } from 'redux/datacollection/universalteachers';

export const snapshotTeacherMiddleware = (store: any) => (
  next: Function
) => async (action: any) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveSnapshotTeacher.SUCCEEDED: {
      const teacher = action.payload;
      dispatch(insertSnapshotTeacher(teacher));
      dispatch(loadUniversalTeachers());
      break;
    }

    case deactivateSnapshotTeacher.SUCCEEDED: {
      const teacher = action.payload;
      dispatch(removeSnapshotTeacher(teacher));
      break;
    }

    // when active teachers status is saved reload the active teachers data
    case insertActiveTeacherComplete.SUCCEEDED: {
      dispatch(loadActiveTeachers());
      break;
    }
  }
};
