import { connect } from 'react-redux';
import {
  getSchoolOrInstituteById,
  getDistrictById,
} from 'redux/common/_selectors';
import { setActiveProgramInstanceId, setProgramNav } from 'redux/application';
import { loadSingleProgramInstance } from 'redux/programs/programinstance';
import { getProgramIsLoaded, getProgram } from 'redux/programs/_selectors';
import { getNavTrayClosed } from 'redux/application/selectors';
import { ProgramLayout } from './ProgramLayout';

export const mapStateToProps = (state: any, ownProps: any) => {
  const readOnly = String(ownProps.location.pathname).includes(
    'create-program'
  );
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const entityId: number = Number(ownProps.match.params.entityId);
  const id = programInstanceId ? programInstanceId : entityId;
  const programIsLoaded = getProgramIsLoaded(state, id);
  const program = getProgram(state, id);
  const school = getSchoolOrInstituteById(state, program.schoolId);
  const district =
    school && school.districtId > 0
      ? getDistrictById(state, school.districtId)
      : undefined;
  const navTrayClosed = getNavTrayClosed(state);

  return {
    readOnly,
    programIsLoaded,
    program,
    school,
    district,
    navTrayClosed,
  };
};

const dispatchToProps = {
  loadSingleProgramInstance,
  setActiveProgramInstanceId,
  setProgramNav,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramLayout);
