import { StickyCreditsBar } from './StickyCreditsBar.component';
import { getWorkflowTrayClosed } from 'redux/application/selectors';
import { connect } from 'react-redux';

const mapStateToProps = (state: any, ownProps: any) => {
  const workflowTrayClosed = getWorkflowTrayClosed(state);
  return {
    workflowTrayClosed,
  };
};

const dispatchProps = {};
export default connect(
  mapStateToProps,
  dispatchProps
)(StickyCreditsBar);
