import { useValidation } from 'de-formed-validations';
import { CTAFiscalYearProgramSupport } from 'types';
import { __, both, gt, gte, lte } from 'ramda';

export const CTAFiscalYearProgramSupportValidation = () => {
  return useValidation<CTAFiscalYearProgramSupport>({
    ctePercent: [
      {
        errorMessage: 'CTE % cannot exceed 100.',
        validation: both(gte(__, 0), lte(__, 100)),
      },
    ],
    ctaProgramId: [
      {
        errorMessage: 'School / Program is required.',
        validation: gt(__, 0),
      },
    ],
  });
};
