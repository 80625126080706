import React from 'react';
import { Deficiency, FiscalYear } from 'types';
import { compose, renderData, prop, getFullYear } from 'util/objectUtility';

type TableOutlineTopProps = {
  deficiency: Deficiency;
};

export const TableOutlineTop: React.FC<TableOutlineTopProps> = (props) => {
  const { deficiency } = props;

  const getFormattedFiscalYear = (year: FiscalYear) => {
    const start = compose(
      renderData,
      getFullYear,
      prop('startDate')
    );
    const end = compose(
      renderData,
      getFullYear,
      prop('endDate')
    );
    return `${start(year)} - ${end(year)}`;
  };

  return (
    <React.Fragment>
      <table className="table-summary" style={{ minWidth: '32rem' }}>
        <tbody>
          <tr>
            <th className="deficiency__header-big" colSpan={3}>
              Academic Year
            </th>
          </tr>
          <tr>
            <td>
              {`${getFormattedFiscalYear(
                deficiency.currentYearTarget.year
              )} State performance target`}
            </td>
          </tr>
          <tr>
            <td>
              {`${getFormattedFiscalYear(
                deficiency.previousYearTarget.year
              )} State performance target`}
            </td>
          </tr>
          <tr>
            <td>
              {`${getFormattedFiscalYear(deficiency.previousYearTarget.year)}
                State Actual level`}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
