import { CTA_KEY, CTA_HOSTING_CONTRACTS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTACourse } from 'types';

export const CTA_HOSTING_CONTRACTS_PATH = [CTA_KEY, CTA_HOSTING_CONTRACTS_KEY];

export const getIsDataFetching_CTAHostingContract = (state: any): boolean => {
  return getIsDataFetching(state, CTA_HOSTING_CONTRACTS_PATH);
};

export const getRecords_CTAHostingContract = (state: unknown): CTACourse[] => {
  return getRecords(state, CTA_HOSTING_CONTRACTS_PATH);
};
