import ApplicationState from 'types/applicationstate.types';
import { ContactType } from 'types';
import {
  UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY,
  UNIVERSAL_CONTACTS_KEY,
} from 'redux/keys';
import { get } from 'lodash';

const updatePeriodElapsed = (state: any): boolean => {
  const lastUpdate = get(
    state,
    [
      UNIVERSAL_CONTACTS_KEY,
      UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY,
      'lastUpdate',
    ],
    0
  );
  var timeDiff = Date.now() - lastUpdate;
  return Math.round((timeDiff /= 1000)) > 43200;
};

export const getContactTypes = (state: ApplicationState): ContactType[] => {
  return get(
    state,
    [UNIVERSAL_CONTACTS_KEY, UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY, 'records'],
    []
  );
};

export const getShouldRefreshContactTypes = (state: any): boolean => {
  const value: ContactType[] = get(
    state,
    [UNIVERSAL_CONTACTS_KEY, UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY, 'records'],
    []
  );
  return value.length === 0 || updatePeriodElapsed(state);
};
