import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { ProgramAwardEdit } from 'components/Program/Awards/AwardsConnect';

type AwardsPageProps = {
  programInstanceId: number;
};

export const AwardsPage: React.FC<AwardsPageProps> = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramAwardEdit />
      </ProgramLayout>
    </BaseLayout>
  );
};
