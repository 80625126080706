import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  AzureUploadParameters,
  QuestionGroup,
  emptyQuestionGroup,
} from 'types';
import { upsertInPlace } from 'util/arrayUtility';
import { toast } from 'react-toastify';
import { QuestionValidation } from 'validations/Question.validations';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import ParentQuestionGroup from 'components/Common/Questions';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

type GrantAssurancesProps = {
  isLoading: boolean;
  isLocked: boolean;
  loadBasicGrantAssurances: Function;
  questions: QuestionGroup[];
  saveBasicGrantAssurances: Function;
  setCurrentNavAnchor: Function;
};

export const GrantAssurances: React.FC<GrantAssurancesProps> = (props) => {
  const {
    isLoading,
    isLocked,
    loadBasicGrantAssurances,
    questions,
    saveBasicGrantAssurances,
    setCurrentNavAnchor,
  } = props;

  const { validate, validationErrors } = QuestionValidation();

  const { grantFiscalYearId: gId }: any = useParams();
  const grantFiscalYearId = Number(gId);

  const [questionState, setQuestionState] = useState<QuestionGroup[]>([
    emptyQuestionGroup(),
  ]);

  const handleChange = (data: QuestionGroup) => {
    const updated = upsertInPlace<QuestionGroup>(
      questionState,
      data,
      'questionGroupId'
    );
    setQuestionState(updated);
  };

  const handleSave = async (): Promise<any> => {
    validate('allQuestionsAnswered', questionState);
    return saveQuestions();
  };

  const saveQuestions = () => {
    return saveBasicGrantAssurances(questionState)
      .then(() =>
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        )
      )
      .catch(() =>
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        )
      );
  };

  useEffect(() => {
    setQuestionState([...questions]);
  }, [questions]);

  useEffect(() => {
    setCurrentNavAnchor('assurances');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    loadBasicGrantAssurances(grantFiscalYearId);
  }, [loadBasicGrantAssurances, grantFiscalYearId]);

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|basic-grant-assurances`,
  };

  return (
    <SecureWrap isLocked={isLocked} component={['GrantsAssurances']}>
      <ScrollToTop />
      <Paper>
        <Loading isActive={isLoading} messageBefore="Loading Assurances" />
        <Instruction title="Assurances">
          <p>
            The assurances below outline basic expectations of the eligible
            entity and checking the box for each assurance constitutes an
            initial commitment by the entity at the time of submission of the
            local application to meet the requirements set forth. Signatures by
            the Perkins contact, CTE Director, and appropriate fiscal personnel
            signify as such on the next screen. The award packet documentation
            required during the grant period is a reiteration of these and
            additional compliance related requirements resulting from the
            acceptance of these federal grant funds.
          </p>
        </Instruction>
        <ParentQuestionGroup
          questionGroup={questionState}
          title={''}
          uploadParams={uploadParams}
          onChange={handleChange}
        />
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          cancelText="Return to Grant Overview"
          saveData={handleSave}
          saveText="Save Assurances"
          validationErrors={validationErrors}
        />
      </Paper>
    </SecureWrap>
  );
};
