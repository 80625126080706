import { PERMISSIONS_COMPONENTS_KEY, PERMISSIONS_KEY } from 'redux/keys';
import { getRecords } from 'redux/utility/baseSelectors';
import { PermissionComponent } from 'types';

export const PERMISSION_COMPONENT_PATH = [
  PERMISSIONS_KEY,
  PERMISSIONS_COMPONENTS_KEY,
];

export const getRecords_PermissionComponent = (
  state: any
): PermissionComponent[] => {
  return getRecords<PermissionComponent>(state, PERMISSION_COMPONENT_PATH);
};
