import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import LandingPageCard from 'components/Common/LandingPageCard';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export const CTAPortalPage: React.FC = () => {
  return (
    <BaseLayout>
      <ScrollToTop />
      <div className="landing-page">
        <h1 className="landing-page__title">Career and Technical Act Portal</h1>
        <div className="landing-page__cards">
          <SecureWrap component={['CTAAdmin']} allowReadOnly={true}>
            <LandingPageCard
              title="Admin"
              iconType="calendar"
              buttons={[
                <div />,
                <Button
                  asLink={true}
                  className="landing-page-card__btn t-admin-view"
                  text="View"
                  to="/cta/admin"
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap component={['CTAGeneral']} allowReadOnly={true}>
            <LandingPageCard
              title="CTA Reporting"
              iconType="mrMustache"
              buttons={[
                <div />,
                <Button
                  aria-label="District Reporting"
                  asLink={true}
                  className="landing-page-card__btn t-cta-dr-view"
                  text="District Reporting"
                  to="/cta/district-reporting"
                />,
              ]}
            />
          </SecureWrap>
        </div>
      </div>
    </BaseLayout>
  );
};
