import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
  getFormattedUrl,
} from 'services';
import { CourseCreditProgram } from 'types';
import { CourseCreditCustom } from 'types/program/CourseCreditCustom.types';

const ENDPOINT = 'api/ProgramAwards/CourseAwardDTO/';

// TODO: add JSDoc
export const createProgramCoursesCredits = async (
  courseCredits: CourseCreditProgram,
  programInstanceId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(courseCredits),
  })
    .then(toJSON)
    .then((data: any) => {
      return {
        ...data,
        programInstanceId,
      };
    })
    .catch((err) => {
      return catchError('CourseAwardDTO', err);
    });
};

// TODO: add JSDoc
export const readProgramCoursesCredits = async (programInstanceId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((records: CourseCreditProgram) => {
      return {
        records,
        programInstanceId,
      };
    })
    .catch((err) => {
      return catchError('CourseAwardDTO', err);
    });
};

export const readCustomCourses = async (
  programInstanceId: number
): Promise<CourseCreditCustom[]> => {
  const url = getFormattedUrl([
    'api',
    'ProgramAwards',
    'CustomCourse',
    programInstanceId,
  ]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwards', err, 'readCustomCourses');
    });
};

export const updateCustomCourses = async (
  programInstanceId: number,
  courses: CourseCreditCustom[]
) => {
  const url = getFormattedUrl([
    'api',
    'ProgramAwards',
    'CustomCourse',
    programInstanceId,
  ]);
  return fetch(url, { ...postWithAuthOptions(), body: JSON.stringify(courses) })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwards', err, 'updateCustomCourses');
    });
};
