import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const ENDPOINT = 'api/GrantNav/fiscalyear/';

export const readGrantFiscalYearNav = async (year: number) => {
  return await fetch(`${ENVIRONMENT.API_URL}${ENDPOINT}${year}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantFiscalYearNav', err);
    });
};
