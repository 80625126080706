import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { TeacherSelectBoxComponent } from './TeacherSelectBox.component';
import { UniversalContact } from 'types';
import { getRecords_UniversalTeachers } from 'redux/datacollection/universalteachers.selectors';
import { loadUniversalTeachers } from 'redux/datacollection/universalteachers';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const contacts: UniversalContact[] = getRecords_UniversalTeachers(state);
  return {
    contacts,
  };
};

const dispatchProps = {
  loadUniversalTeachers,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(TeacherSelectBoxComponent);
