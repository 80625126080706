export interface CTAHostItem {
  ctaId: number;
  districtInstitutionName: string;
}

export const emptyCTAHostItem = (): CTAHostItem => {
  return {
    ctaId: 0,
    districtInstitutionName: '',
  };
};
