import {
  CTA_KEY,
  CTA_INSTRUCTOR_KEY,
  CTA_INSTRUCTOR_SALARY_KEY,
  CTA_INSTRUCTOR_NON_CTE_COURSE_KEY,
  CTA_INSTRUCTOR_CTE_COURSE_KEY,
} from 'redux/keys';

export const CTA_INSTRUCTORS_PATH = [CTA_KEY, CTA_INSTRUCTOR_KEY];
export const CTA_INSTRUCTOR_SALARY_PATH = [CTA_KEY, CTA_INSTRUCTOR_SALARY_KEY];
export const CTA_NON_CTE_COURSE_PATH = [
  CTA_KEY,
  CTA_INSTRUCTOR_NON_CTE_COURSE_KEY,
];
export const CTA_CTE_COURSE_PATH = [CTA_KEY, CTA_INSTRUCTOR_CTE_COURSE_KEY];
