import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import {
  CertificationOfExemptionSignature,
  Signature,
  SignatureWithAssertion,
} from 'types';
import { GrantRecipientAgreementDocumentData } from 'components/Grants/Applications/AwardPacket/Documents/GrantRecipientAgreementDocument/GrantRecipientAgreementDocument.component';

const BASE_AWARD_SIGNATURES_PATH = 'api/AwardSignatures/';

const GRANT_AGREEMENTS_PATH = `${
  ENVIRONMENT.API_URL
}${BASE_AWARD_SIGNATURES_PATH}GrantAgreement/`;

const CIVIL_RIGHTS_PATH = `${
  ENVIRONMENT.API_URL
}${BASE_AWARD_SIGNATURES_PATH}CivilRights/`;

const CERTIFICATE_OF_EXEMPTION_PATH = `${
  ENVIRONMENT.API_URL
}${BASE_AWARD_SIGNATURES_PATH}CertificateOfExemption/`;

const GRANT_AGREEMENT_DOCUMENT_DATA = `${
  ENVIRONMENT.API_URL
}${BASE_AWARD_SIGNATURES_PATH}DocumentData/`;

// DocumentData
export const readGrantRecipientDocumentData = async (
  grantFiscalYearId: number
): Promise<GrantRecipientAgreementDocumentData> => {
  return fetch(`${GRANT_AGREEMENT_DOCUMENT_DATA}${grantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantRecipientData', err);
    });
};

// GRANT AGREEMENTS
export const readGrantAgreementSignatures = async (
  grantFiscalYearId: number
): Promise<Signature[]> => {
  return fetch(`${GRANT_AGREEMENTS_PATH}${grantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantAgreementSignatures', err);
    });
};

export const updateGrantAgreementSignatures = async (
  grantFiscalYearId: number,
  signatures: Signature[]
) => {
  return fetch(`${GRANT_AGREEMENTS_PATH}${grantFiscalYearId}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(signatures),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantAgreementSignatures', err);
    });
};

// CIVIL RIGHTS
export const readCivilRightSignatures = async (
  grantFiscalYearId: number
): Promise<SignatureWithAssertion[]> => {
  return fetch(`${CIVIL_RIGHTS_PATH}${grantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CivilRightsSignatures', err);
    });
};

export const updateCivilRightSignatures = async (
  grantFiscalYearId: number,
  signatures: SignatureWithAssertion[]
) => {
  return fetch(`${CIVIL_RIGHTS_PATH}${grantFiscalYearId}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(signatures),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CivilRightsSignatures', err);
    });
};

// CERTIFICATE OF EXEMPTION
export const readCertificateOfExemptionSignatures = async (
  grantFiscalYearId: number
): Promise<CertificationOfExemptionSignature[]> => {
  return fetch(`${CERTIFICATE_OF_EXEMPTION_PATH}${grantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CertificateOfExemption', err);
    });
};

export const updateCertificateOfExemptionSignatures = async (
  grantFiscalYearId: number,
  signatures: CertificationOfExemptionSignature[]
) => {
  return fetch(`${CERTIFICATE_OF_EXEMPTION_PATH}${grantFiscalYearId}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(signatures),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CertificateOfExemption', err);
    });
};
