import React, { useState } from 'react';
import {
  WorkflowSubmission,
  WorkflowAction,
  WorkflowStep,
} from 'types/workflow';
import { map } from 'lodash';
import { FlexRow } from 'layouts';
import { OptionMap } from 'types';
import Button from 'components/Common/FormElements/Button';
import Select from 'components/Common/FormElements/SelectBox';
import Textarea from 'components/Common/FormElements/Textarea';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

export type ApprovalProps = {
  workflowStep: WorkflowStep;
  programInstanceId: number;
  submitProgramForUpdate: Function;
};

export const Approval: React.FC<ApprovalProps> = (props) => {
  const { workflowStep, programInstanceId, submitProgramForUpdate } = props;
  const [submission, setSubmission] = useState<WorkflowSubmission>({
    optionId: -1,
    comment: '',
  });

  const onSubmit = () => {
    submitProgramForUpdate(programInstanceId, submission).catch(() => {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.DataSaveFailed}
        />
      );
    });
  };

  const onFormChange = (data: any) => {
    setSubmission({
      ...submission,
      ...data,
    });
  };

  const optionMap: OptionMap<WorkflowAction> = {
    key: 'stepActionId',
    label: 'label',
    value: 'stepActionId',
  };

  return (
    <React.Fragment>
      <FlexRow>
        <span>Currently assigned To: &nbsp;</span>
        {map(workflowStep.approvers, (item, index) => {
          return (
            <React.Fragment key={index}>
              <a key={index} href={`mailto:${item.email}`}>
                {item.name}
              </a>
              {index < workflowStep.approvers.length - 1 && (
                <span>&nbsp;|&nbsp;</span>
              )}
            </React.Fragment>
          );
        })}
      </FlexRow>
      <FlexRow>
        <Select
          records={workflowStep.workflowActions}
          optionMap={optionMap}
          label="Select an option"
          name="optionId"
          onChange={onFormChange}
          selection={submission.optionId}
        />
      </FlexRow>
      <FlexRow>
        <Textarea
          label="Add a comment (optional)"
          name="comment"
          onChange={onFormChange}
          value={submission.comment}
        />
      </FlexRow>
      <div className="form__btn-container u-flex">
        <div className="u-flex-grow-1" />
        <Button
          to={`/programs/program-steps/${programInstanceId}`}
          asLink={true}
          onClick={onSubmit}
          text="Submit"
          className="form__btn--submit"
        />
      </div>
    </React.Fragment>
  );
};
