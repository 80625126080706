import { some, map, includes } from 'lodash';
import { UniversalContact } from 'types';
import { getArray } from './arrayUtility';

/**
 * Determines if the user is a super admin or not
 * @param contact the contact to test
 */
export const isSuperAdmin = (contact: UniversalContact): boolean => {
  return contactHasRoles(contact, 'admin');
};

/**
 * Determines if the user has the specified roles or not
 * @param contact the contact to test
 * @param roles the roles to test for
 */
export const contactHasRoles = (
  contact: UniversalContact,
  roles: string | string[]
): boolean => {
  return some(
    map(contact.permissionRoles, (item) => {
      return includes(getArray(roles), item.permissionRoleName);
    })
  );
};

export const userHasPerkinsRole = (contact: UniversalContact) => {
  const perkinsDistrict = some(
    map(contact.districts, (item) => {
      return item.isPerkinPrimary;
    })
  );

  const perkinsSchool = some(
    map(contact.institutes, (item) => {
      return item.isPerkinPrimary;
    })
  );
  return some([perkinsDistrict, perkinsSchool]);
};
