import React from 'react';
import { CourseCreditProgram, CourseCreditElective } from 'types';
import ElectiveCourseCreditsInputRow from 'components/Program/CourseCredits/ElectiveCourses/ElectiveInputRow';
import Instruction from 'components/Common/Instruction';
import { useParams } from 'react-router';
import { map } from 'lodash';

type ElectiveCoursesProps = {
  courseCredits: CourseCreditProgram;
  updateProgramCourseCreditsCache: Function;
};

export const ElectiveCourses: React.FC<ElectiveCoursesProps> = (props) => {
  const { courseCredits, updateProgramCourseCreditsCache } = props;
  const { electiveCourses, programAwards } = courseCredits;
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const handleElectiveCourseChange = (data: CourseCreditElective) => {
    const electiveCourses = map(
      courseCredits.electiveCourses,
      (elective: CourseCreditElective) => {
        return data.programAwardId === elective.programAwardId
          ? data
          : elective;
      }
    );
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        electiveCourses,
      },
    });
  };

  return (
    <React.Fragment>
      <Instruction title="Elective Courses">
        <p>
          If applicable, provide a list of courses that can be taken as an
          elective and the number of credits that each list can count toward the
          associated award.
        </p>
      </Instruction>
      {map(electiveCourses, (elective: CourseCreditElective, index: number) => {
        return (
          <ElectiveCourseCreditsInputRow
            key={index}
            electiveCourse={elective}
            programAwards={programAwards}
            onChange={handleElectiveCourseChange}
          />
        );
      })}
    </React.Fragment>
  );
};
