import React, { useState } from 'react';
import { CollapsibleList } from '@rmwc/list';
import { Icon } from 'components/Common/Icons/Icon.component';
import { BACKGROUND_RED_GRADIENT } from 'constants/css.const';
import { insertClass } from 'util/formHelpers';
import { isString } from 'lodash';

type CTACollapsableProps = {
  locked?: boolean;
  header: string | JSX.Element;
  children: any;
  name: string;
  onChange?: Function;
};

export const CTACollapsableLayout: React.FC<CTACollapsableProps> = (props) => {
  const { header, children, locked = false, name, onChange } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const updateOpenState = (value: boolean) => {
    setIsOpen(value);
    onChange && onChange(value);
  };

  return (
    <div className={`cta-collapsable t-${name}`}>
      <CollapsibleList
        onOpen={() => updateOpenState(true)}
        onClose={() => updateOpenState(false)}
        handle={
          <div
            className={`${locked &&
              insertClass('u-sec-ro')} program-step__header`}
            style={
              locked
                ? {
                    border: '2px solid red',
                    background: BACKGROUND_RED_GRADIENT,
                  }
                : undefined
            }
          >
            {isString(header) ? (
              <h2 className="u-flex-grow-1 program-step__title">{header}</h2>
            ) : (
              header
            )}
            <Icon
              style={{ width: '2.8rem' }}
              tooltip="Expand"
              className={
                locked
                  ? 'u-icon-red'
                  : isOpen
                  ? 'u-icon-blue u-R90'
                  : 'u-icon-blue'
              }
              name={locked ? 'lock' : 'chevronRight'}
            />
          </div>
        }
      >
        <div className="cta-reporting">{children}</div>
      </CollapsibleList>
    </div>
  );
};
