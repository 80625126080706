import React, { useState } from 'react';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { COLOR_HIGH_LIGHT } from 'constants/css.const';

type CreditInputProps = {
  className?: string;
  disabled?: boolean;
  id?: string;
  index?: number;
  name?: string;
  onChange?: Function;
  value: string | number;
  valid?: boolean;
};

// This should be moved to course credits or programs, this isn't
// generically reusable
export const SquareBoxInput: React.FC<CreditInputProps> = (props) => {
  const {
    className,
    disabled,
    id,
    index,
    name,
    onChange,
    valid = true,
    value,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const handleChange = (e: any) => {
    if (disabled) return;
    const { value, name } = e.target;
    const newNumber = `${value}0`;
    if (isNaN(Number(newNumber))) return;
    const data = {
      [name]: value ? value : 0,
    };
    onChange && onChange(data, index);
  };

  /* using == intentionally */
  const getValue = () => {
    if (isFocus && value == '0') return '0'; // eslint-disable-line
    if (!isFocus && value == '0') return EMPTY_PLACEHOLDER; //eslint-disable-line
    return value;
  };

  const getClassName = () => {
    const bob = className
      ? className
      : 'course-credits__input course-credits__input--disabled';
    return valid ? bob : `${bob} form__input--error`;
  };

  const style = {
    backgroundColor: COLOR_HIGH_LIGHT,
    borderColor: COLOR_HIGH_LIGHT,
  };

  return (
    <input
      autoComplete="off"
      className={getClassName()}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={() => setIsFocus(false)}
      onChange={handleChange}
      onFocus={() => setIsFocus(true)}
      style={disabled ? style : {}}
      title={name}
      value={getValue()}
    />
  );
};
