import { connect } from 'react-redux';
import {
  loadCTAAdminInformation,
  saveSubmissionOpenEmail,
} from 'redux/cta/ctaadmin';
import { PerPupilOperatingRevenueComponent } from './PerPupilOperatingRevenue.component';

const dispatchProps = {
  loadCTAAdminInformation,
  saveSubmissionOpenEmail,
};
export default connect(
  null,
  dispatchProps
)(PerPupilOperatingRevenueComponent);
