export const CAREER_INFO_ADDRESSABLE_ELEMENTS =
  '[CAREER_INFO_ADDRESSABLE_ELEMENTS]';

export const SET_CAREER_INFO_ADDRESSABLE_ELEMENTS = `${CAREER_INFO_ADDRESSABLE_ELEMENTS} SET`;
export const SET_CAREER_INFO_ADDRESSABLE_ELEMENTS_LOADING = `${CAREER_INFO_ADDRESSABLE_ELEMENTS} LOADING`;

export const setCareerInfoAddressableElements = (payload: any) => {
  return {
    type: SET_CAREER_INFO_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_CareerInfoAddressableElements = (
  payload: boolean
) => {
  return {
    type: SET_CAREER_INFO_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
