import { connect } from 'react-redux';
import { EditProgramOwnerComponent } from './EditProgramOwner.component';
import { getLoggedinUser } from 'redux/user/selectors';
import {
  loadSingleProgramInstance,
  saveProgramInstanceAction,
} from 'redux/programs/programinstance';

export const mapStateToProps = (state: any, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
  };
};

const dispatchToProps = {
  loadSingleProgramInstance,
  saveProgramInstanceAction,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(EditProgramOwnerComponent);
