import React, { useEffect } from 'react';
import { Voucher, WorkflowReduxEntities } from 'types';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';
import { VouchersSubmitValidations } from 'validations/Vouchers/VouchersSubmit.validations';
import { saveWorkFlowUpdate } from 'redux/generalworkflow/workflow.actions';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

type Props = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadReimbursementRequestSummaryData: Function;
  loadSingleVoucherByType: Function;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  submitApplicationUpdate: Function;
  user: any;
  voucher: Voucher;
  voucherType: string;
  workflow: any;
};

export const VoucherWorkflowComponent: React.FC<Props> = (props) => {
  const { loadSingleVoucherByType, voucher, voucherType } = props;

  const { validateCustom, validationErrors } = VouchersSubmitValidations();

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const dispatch = useDispatch();
  const submitApplicationUpdate = compose(
    dispatch,
    saveWorkFlowUpdate(voucherType as keyof WorkflowReduxEntities)
  );

  const wrap = {
    ...props,
    validationErrors,
    submitApplicationUpdate,
  };

  const validateVoucherType = () => {
    switch (voucherType) {
      case 'voucher-one':
        return [
          { key: 'adminCosts', value: voucher },
          { key: 'canSubmitVoucherOne', value: voucher },
          { key: 'certifyAllExpenditures', value: voucher },
          { key: 'indirectAdminCost', value: voucher },
          { key: 'isVoucherSigned', value: voucher },
          { key: 'isVoucherSubmitDateValid', value: voucher },
          { key: 'totalExpenses', value: voucher },
          { key: 'improvementPlan', value: voucher },
        ];
      case 'voucher-two':
      case 'voucher-three':
        return [
          { key: 'adminCosts', value: voucher },
          { key: 'canSubmitVouchersTwoOrThree', value: voucher },
          { key: 'certifyAllExpenditures', value: voucher },
          { key: 'indirectAdminCost', value: voucher },
          { key: 'isVoucherSigned', value: voucher },
          { key: 'isVoucherSubmitDateValid', value: voucher },
          { key: 'totalExpenses', value: voucher },
          { key: 'improvementPlan', value: voucher },
        ];
      default:
        return [
          { key: 'adminCosts', value: voucher },
          { key: 'certifyAllExpenditures', value: voucher },
          { key: 'indirectAdminCost', value: voucher },
          { key: 'isVoucherSigned', value: voucher },
          { key: 'isVoucherSubmitDateValid', value: voucher },
          { key: 'totalExpenses', value: voucher },
          { key: 'improvementPlan', value: voucher },
        ];
    }
  };

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadSingleVoucherByType(grantFiscalYearId, voucherType);
    }
  }, [grantFiscalYearId, voucherType]); // eslint-disable-line

  useEffect(() => {
    if (voucher) {
      validateCustom(validateVoucherType());
    }
  }, [grantFiscalYearId, voucher]); // eslint-disable-line

  return (
    <React.Fragment>
      <WorkflowSummaryComponent {...wrap} />
    </React.Fragment>
  );
};
