import React from 'react';
import { useParams } from 'react-router';
import { SideNavData } from 'types/ui/SideNavData.types';
import Button from 'components/Common/FormElements/Button';
import SideNav from 'components/Common/SideNav';

type ProgramStepSideNavProps = {
  navData: SideNavData;
  navTrayClosed: boolean;
  programNav: string;
  setNavTrayClosed: Function;
};

export const ProgramStepSideNav: React.FC<ProgramStepSideNavProps> = (
  props
) => {
  const { navData } = props;
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const actionButton = () => {
    return navData.scrollNav ? (
      undefined
    ) : (
      <div className="side-nav__btn-container">
        <Button
          text="Go to Summary"
          asLink={true}
          to={`/programs/program-steps/${programInstanceId}`}
          className="side-nav__action-btn"
        />
      </div>
    );
  };

  return (
    <SideNav
      actionButton={actionButton()}
      navagationParameters={[programInstanceId]}
      iconName={navData.scrollNav ? 'documents' : 'pencil'}
      navData={navData}
      titleBarColor={!navData.scrollNav ? 'orange' : ''}
    />
  );
};
