import React from 'react';

type PaperProps = {
  children: any;
  className?: string;
  flat?: boolean;
};

export const Paper: React.FC<PaperProps> = (props) => {
  const { children, className, flat = false } = props;
  const cn = `${flat ? `paper__flat ` : ''}paper ${
    className ? ` ${className}` : ''
  }`;
  return (
    <section className={cn}>
      <div className="paper__body">{children}</div>
    </section>
  );
};
