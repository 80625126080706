import { COMMON_KEY, COMMON_CAREER_PATHWAYS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { CareerPathway, ItemFilter } from 'types';

export const CAREER_PATHWAYS_PATH: string[] = [
  COMMON_KEY,
  COMMON_CAREER_PATHWAYS_KEY,
];

export const getIsDataFetching_CareerPathways = (state: any): boolean => {
  return getIsDataFetching(state, CAREER_PATHWAYS_PATH);
};

export const getLastUpdated_CareerPathways = (state: any): number => {
  return getLastUpdated(state, CAREER_PATHWAYS_PATH);
};

export const getRecords_CareerPathways = (state: any): CareerPathway[] => {
  return getRecords<CareerPathway>(state, CAREER_PATHWAYS_PATH);
};

export const shouldRefreshReduxState_CareerPathways = (state: any): boolean => {
  return shouldRefreshReduxState<CareerPathway>(state, CAREER_PATHWAYS_PATH);
};

export const getFilteredRecords_CareerPathways = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<CareerPathway>(
    state,
    CAREER_PATHWAYS_PATH,
    itemFilter
  );
};
