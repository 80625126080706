import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { FlexRow, FlexGroup } from 'layouts';
import {
  WINDOW_BOTTOM_OFFSET,
  WINDOW_TOP_OFFSET,
} from 'constants/application.constants';
import { QuestionGroup } from 'types';
import { useDispatch } from 'react-redux';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import { FourYearPlanValidations } from 'validations/FourYearPlan.validations';

type FourYearSummarySummaryProps = {
  isFetching: boolean;
  loadQuestions: Function;
  questions: { [key: string]: QuestionGroup[] };
  setCurrentNavAnchor: Function;
};

export const FourYearSummarySummaryComponent: React.FC<
  FourYearSummarySummaryProps
> = (props) => {
  const { loadQuestions, questions, setCurrentNavAnchor } = props;
  const {
    careerInfoQuestions,
    equityQuestions,
    instructorsQuestions,
    needsAssessmentQuestions,
    partnershipsQuestions,
    qualityQuestions,
    workBasedLearningQuestions,
  } = questions;

  const dispatch = useDispatch();

  const { grantFiscalYearId: cId }: any = useParams();
  const grantFiscalYearId = Number(cId);

  const { getFieldValid, validateCustom } = FourYearPlanValidations();

  useEffect(() => {
    loadQuestions(dispatch, grantFiscalYearId);
  }, [grantFiscalYearId]); // eslint-disable-line

  /* eslint-disable */
  useEffect(() => {
    validateCustom([
      { key: 'needsAssessment', value: needsAssessmentQuestions },
      { key: 'careerInfo', value: careerInfoQuestions },
      { key: 'quality', value: qualityQuestions },
      { key: 'instructors', value: instructorsQuestions },
      { key: 'partnerships', value: partnershipsQuestions },
      { key: 'workBasedLearning', value: workBasedLearningQuestions },
      { key: 'equity', value: equityQuestions },
    ]);
  }, [
    grantFiscalYearId,
    needsAssessmentQuestions,
    careerInfoQuestions,
    qualityQuestions,
    instructorsQuestions,
    partnershipsQuestions,
    workBasedLearningQuestions,
    equityQuestions,
  ]);
  /* eslint-enable */

  return (
    <Paper className="u-read-only">
      <SummaryScroll componentStep="four-year-plan" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('four-year-plan')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Needs Assessment Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('needsAssessment') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Career Info Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('careerInfo') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Quality Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('quality') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Instructors Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('instructors') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Partnerships Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('partnerships') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Work-Based Learning Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('workBasedLearning') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Equity Questions</p>
          <p>
            Collection Status:{' '}
            {getFieldValid('equity') ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('four-year-plan')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
