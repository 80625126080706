import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { PathwayCourseEdit } from 'components/Program/PathwayCourses/PathwayCourseConnect';

export const PathwayCourseStep: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <PathwayCourseEdit {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};
