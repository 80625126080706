import { connect } from 'react-redux';
import { GrantsPortal } from 'pages/Grants/GrantsPortalPage/GrantsPortal.page';
import { getCurrentFiscalYear } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const currentFiscalYear = getCurrentFiscalYear(state);

  return {
    currentFiscalYear,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(GrantsPortal);
