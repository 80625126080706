import React, { useEffect } from 'react';
import { Workflow, WorkflowStep } from 'types/workflow';
import { UniversalContact } from 'types';
import { map, filter, every } from 'lodash';
import { canEditGrantsWorkflow } from 'util/workflowUtility';
import { isSuperAdmin } from 'util/permissionsUtility';
import CreateWorkflowAction from './WorkflowAction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import WorkflowCard from './WorkflowCard';

export type WorkflowApprovalProps = {
  applicationId: number;
  disableSubmit?: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  optionalAction?: Function;
  setCurrentNavAnchor: Function;
  submitApplicationUpdate: Function;
  user: UniversalContact;
  validationErrors?: string[];
  workflow: Workflow;
};

export const WorkflowSummaryComponent: React.FC<WorkflowApprovalProps> = (
  props
) => {
  const {
    applicationId,
    disableSubmit = false,
    isFetching,
    isReadOnly,
    optionalAction,
    setCurrentNavAnchor,
    submitApplicationUpdate,
    user,
    validationErrors,
    workflow,
  } = props;

  const currentWorkflowSteps = (): WorkflowStep[] => {
    return filter(workflow.workflowSteps, (item) => {
      return item.isCurrent;
    });
  };

  useEffect(() => {
    setCurrentNavAnchor('workflow-approval');
  }, [setCurrentNavAnchor, workflow, isFetching]);

  const isReallyReadOnly = every([isReadOnly, !isSuperAdmin(user)]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} />
      <div className="program-step__header">
        <h2 className="program-step__title">Submission / Approval</h2>
      </div>
      <Paper>
        <div className={isReallyReadOnly ? 'u-sec-ro' : ''}>
          {map(currentWorkflowSteps(), (item, index) => {
            return !disableSubmit && canEditGrantsWorkflow(item, user) ? (
              <CreateWorkflowAction
                key={index}
                applicationId={applicationId}
                submitApplicationUpdate={submitApplicationUpdate}
                validationErrors={validationErrors}
                workflowStep={item}
              />
            ) : (
              <WorkflowCard
                key={index}
                flat={true}
                isFirst={true}
                isOdd={false}
                workflowItem={item}
                isSummary={false}
              />
            );
          })}
          {optionalAction && !isReadOnly ? optionalAction() : null}
        </div>
      </Paper>
    </React.Fragment>
  );
};
