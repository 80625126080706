import { ENVIRONMENT } from 'constants/application.config';
import { getWithAuthOptions, toJSON, catchError } from 'services/index';

const CAREER_PATHWAYS_ENDPOINT =
  'api/careerpathways/validForProgramInstanceId/';

/**
 * Returns a list of Career Pathways that are valid for the programInstanceId
 * @param ProgramInstanceId the program instance id we want to get selectins for.
 */
export const readCareerPathwaysByProgramInstanceId = async (
  ProgramInstanceId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${CAREER_PATHWAYS_ENDPOINT}${ProgramInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('careerpathways', err);
    });
};
