import { GRANTS_CONSORTIUMS_KEY, GRANTS_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { defaultTo, find, every } from 'lodash';
import { Consortium, emptyConsortium } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { getConsortiumFilters } from 'redux/filters/_selectors';
import { getCurrentFiscalYear } from 'redux/common/_selectors';

export const CONSORTIUM_PATH = [GRANTS_KEY, GRANTS_CONSORTIUMS_KEY];

export const getIsDataFetching_Consortia = (state: any): boolean => {
  return getIsDataFetching(state, CONSORTIUM_PATH);
};

export const getRecords_Consortia = (state: unknown): Consortium[] => {
  return getRecords(state, CONSORTIUM_PATH);
};

export const shouldRefreshReduxState_Consortia = (state: any): boolean => {
  return shouldRefreshReduxState<Consortium>(state, CONSORTIUM_PATH);
};

export const getConsortium = (
  state: ApplicationState,
  consortiumId: number
): Consortium => {
  const allConsortia = getRecords_Consortia(state);
  const consortium = defaultTo(
    find(allConsortia, (value: Consortium) => {
      return value.consortiumId === consortiumId;
    }),
    emptyConsortium()
  );
  return consortium;
};

export const getConsortiumByFiscalYear = (
  state: ApplicationState,
  consortiumId: number,
  fiscalyear: number
): Consortium => {
  const fiscalYear = getCurrentFiscalYear(state);
  const allConsortia = getRecords_Consortia(state);
  const consortium = defaultTo(
    find(allConsortia, (value: Consortium) => {
      return every([
        value.consortiumId === consortiumId,
        value.fiscalYear.year === fiscalyear,
      ]);
    }),
    { ...emptyConsortium(), fiscalYear }
  );
  return consortium;
};

export const getAdvancedFilteredRecords_Consortia = (
  state: any
): Consortium[] => {
  const filters = getConsortiumFilters(state);
  const records = getAdvancedFilteredRecords<Consortium>(
    state,
    CONSORTIUM_PATH,
    filters
  );
  return records;
};

export const getConsortiumIsLoaded = (
  state: ApplicationState,
  consortiumId: number
): boolean => {
  const value = find(getRecords_Consortia(state), (value: Consortium) => {
    return value && value.consortiumId === consortiumId;
  });
  return value ? true : false;
};
