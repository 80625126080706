import {
  setIsLoading_NeedsAssessmentAddressableElements,
  setNeedsAssessmentAddressableElements,
} from 'redux/grants/fouryearaddressableelements/needsassessment.actions';
import { readAddressableElements } from 'services/grants/addressableElements.services';
import {
  setIsLoading_EquityAddressableElements,
  setEquityAddressableElements,
} from 'redux/grants/fouryearaddressableelements/equity.actions';
import {
  setIsLoading_WorkBasedLearningAddressableElements,
  setWorkBasedLearningAddressableElements,
} from 'redux/grants/fouryearaddressableelements/workbasedlearning.actions';
import {
  setIsLoading_PartnershipsAddressableElements,
  setPartnershipsAddressableElements,
} from 'redux/grants/fouryearaddressableelements/partnerships.actions';
import {
  setIsLoading_InstructorsAddressableElements,
  setInstructorsAddressableElements,
} from 'redux/grants/fouryearaddressableelements/instructors.actions';
import {
  setIsLoading_QualityAddressableElements,
  setQualityAddressableElements,
} from 'redux/grants/fouryearaddressableelements/quality.actions';
import {
  setIsLoading_CareerInfoAddressableElements,
  setCareerInfoAddressableElements,
} from 'redux/grants/fouryearaddressableelements/careerinfo.actions';
import { FETCH_FOUR_YEAR_ADDRESSABLE_ELEMENTS } from 'redux/grants/fouryearaddressableelements/fouryear.actions';

export const grantFourYearAddressableElementsMiddleware = ({
  dispatch,
}: any) => (next: Function) => (action: any) => {
  next(action);

  if (action.type === FETCH_FOUR_YEAR_ADDRESSABLE_ELEMENTS) {
    const { entityType, entityId } = action.payload;

    switch (entityType) {
      case 'needs-assessment':
        dispatch(setIsLoading_NeedsAssessmentAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) =>
            dispatch(setNeedsAssessmentAddressableElements(res))
          )
          .finally(() =>
            dispatch(setIsLoading_NeedsAssessmentAddressableElements(false))
          );

      case 'career-info':
        dispatch(setIsLoading_CareerInfoAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) => dispatch(setCareerInfoAddressableElements(res)))
          .finally(() =>
            dispatch(setIsLoading_CareerInfoAddressableElements(false))
          );

      case 'quality':
        dispatch(setIsLoading_QualityAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) => dispatch(setQualityAddressableElements(res)))
          .finally(() =>
            dispatch(setIsLoading_QualityAddressableElements(false))
          );

      case 'instructors':
        dispatch(setIsLoading_InstructorsAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) => dispatch(setInstructorsAddressableElements(res)))
          .finally(() =>
            dispatch(setIsLoading_InstructorsAddressableElements(false))
          );

      case 'partnerships':
        dispatch(setIsLoading_PartnershipsAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) => dispatch(setPartnershipsAddressableElements(res)))
          .finally(() =>
            dispatch(setIsLoading_PartnershipsAddressableElements(false))
          );

      case 'work-based-learning':
        dispatch(setIsLoading_WorkBasedLearningAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) =>
            dispatch(setWorkBasedLearningAddressableElements(res))
          )
          .finally(() =>
            dispatch(setIsLoading_WorkBasedLearningAddressableElements(false))
          );

      case 'equity':
        dispatch(setIsLoading_EquityAddressableElements(true));
        return readAddressableElements(entityType, entityId)
          .then((res: any) => dispatch(setEquityAddressableElements(res)))
          .finally(() =>
            dispatch(setIsLoading_EquityAddressableElements(false))
          );
    }
  }
};
