import { connect } from 'react-redux';
import { AdministrativeComponent } from './Administrative.component';
import {
  loadCTASpecialists,
  saveCTASpecialists,
  removeCTASpecialists,
} from 'redux/cta/ctaspecialist';
import { loadCTADirectors, saveCTADirectors, removeCTADirectors } from 'redux/cta/ctadirector';

import { loadCTASupplies, saveCTASupplies } from 'redux/cta/ctaadminbooksupply';

import {
  getRecords_CTASpecialist,
  getRecords_CTADirector,
  getFirstRecord_AdminBooksAndSupplies,
  getRecords_CTASchool,
} from 'redux/cta/_selectors';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { loadCTASchools } from 'redux/cta/ctageneral';

const mapStateToProps = (state: any) => {
  const specialists = getRecords_CTASpecialist(state);
  const directors = getRecords_CTADirector(state);
  const booksSupplies = getFirstRecord_AdminBooksAndSupplies(state);
  const ctaSchools = getRecords_CTASchool(state);
  return {
    ctaSchools,
    booksSupplies,
    specialists,
    directors,
  };
};

const dispatchProps = {
  getContact,
  loadCTASpecialists,
  loadCTADirectors,
  saveCTASpecialists,
  saveCTADirectors,
  removeCTASpecialists,
  removeCTADirectors,
  loadCTASupplies,
  saveCTASupplies,
  loadCTASchools,
};

export default connect(mapStateToProps, dispatchProps)(AdministrativeComponent);
