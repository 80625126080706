import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  updateInstructorSalary,
  readInstructorSalary,
} from 'services/cta/ctainstructionalcosts.services';
import { CtaInstructorSalary } from 'types/cta/InstructionalCosts/CtaInstructorSalary.types';

const initialState = baseInitialState;

export const loadInstructorSalary = createActionThunk(
  'loadInstructorSalary',
  (ctaInstructorId: number) => {
    return readInstructorSalary(ctaInstructorId);
  }
);
export const saveInstructorSalary = createActionThunk(
  'saveInstructorSalary',
  (ctaId: number, instructorSalary: CtaInstructorSalary) => {
    return updateInstructorSalary(ctaId, instructorSalary);
  }
);

export default handleActions<BaseStateObject, CtaInstructorSalary>(
  {
    ...baseLoadActionBuilder<CtaInstructorSalary>(loadInstructorSalary),
    ...baseSaveActionBuilder(saveInstructorSalary),
  },
  initialState
);
