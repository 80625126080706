import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readVouchers,
  createUpdateVoucher,
  readVoucherByType,
} from 'services/grants/vouchers.services';
import { Voucher } from 'types';

const initialState = baseInitialState;

export const loadVouchers = createActionThunk(
  'loadVouchers',
  (grantFiscalYearId: number) => {
    return readVouchers(grantFiscalYearId);
  }
);

export const loadSingleVoucherByType = createActionThunk(
  'loadSingleVoucherByType',
  (grantFiscalYearId: number, voucherType: string) => {
    return readVoucherByType(grantFiscalYearId, voucherType);
  }
);

export const saveVoucher = createActionThunk(
  'saveVoucher',
  (grantFiscalYearId: number, voucher: Voucher) => {
    return createUpdateVoucher(grantFiscalYearId, voucher);
  }
);
export default handleActions<BaseStateObject, Voucher | Voucher[]>(
  {
    ...baseLoadActionBuilder<Voucher | Voucher[]>(loadVouchers),
    ...baseLoadActionBuilder<Voucher | Voucher[]>(loadSingleVoucherByType),
    ...baseSaveActionBuilder<Voucher | Voucher[]>(saveVoucher),
  },
  initialState
);
