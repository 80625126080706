import React from 'react';
import {
  FETCH_ANNUAL_SPENDING_PLAN,
  setIsLoading_AnnualSpendingPlan,
  setAnnualSpendingPlan,
  NORMALIZE_ANNUAL_SPENDING_PLAN,
  normalize_AnnualSpendingPlan,
  SAVE_ANNUAL_SPENDING_PLAN,
  loadAnnualSpendingPlan,
} from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import {
  readAnnualSpendingPlan,
  createAnnualSpendingPlan,
} from 'services/grants/annualSpendingPlan.services';
import { emptyAnnualSpending } from 'types';
import { toast } from 'react-toastify';
import { normalizeSpendingCategories } from 'util/annualSpendingUtility';
import ToastNotification from 'components/Common/Toast';

export const grantAnnualSpendingPlanMiddleware = ({ dispatch }: any) => (
  next: Function
) => (action: any) => {
  next(action);

  if (action.type === NORMALIZE_ANNUAL_SPENDING_PLAN) {
    const { payload } = action;
    const [annualSpendingPlan] = payload;
    const plan = annualSpendingPlan
      ? annualSpendingPlan
      : emptyAnnualSpending();
    const updatedPayload = normalizeSpendingCategories(plan);
    next(setAnnualSpendingPlan(updatedPayload));
  }

  if (action.type === FETCH_ANNUAL_SPENDING_PLAN) {
    const grantFiscalYearId = action.payload;

    dispatch(setIsLoading_AnnualSpendingPlan(true));
    return readAnnualSpendingPlan(grantFiscalYearId)
      .then((res: any) => dispatch(normalize_AnnualSpendingPlan(res)))
      .finally(() => dispatch(setIsLoading_AnnualSpendingPlan(false)));
  }

  if (action.type === SAVE_ANNUAL_SPENDING_PLAN) {
    dispatch(setIsLoading_AnnualSpendingPlan(true));
    const { grantFiscalYearId } = action.payload;
    return createAnnualSpendingPlan(action.payload)
      .then(() => dispatch(loadAnnualSpendingPlan(grantFiscalYearId)))
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message="Annual Spending Plan saved successfully"
          />
        );
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Annual Spending Plan was unable to save."
          />
        );
      });
  }
};
