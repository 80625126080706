import { GRANTS_KEY, GRANTS_CIVIL_RIGHTS_SIGNATURES } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';
import { DistrictSchoolSignature } from 'types';

export const GRANT_CIVIL_RIGHTS_SIGNATURES_PATH = [
  GRANTS_KEY,
  GRANTS_CIVIL_RIGHTS_SIGNATURES,
];

export const getIsDataFetching_CivilRightsSignatures = (
  state: unknown
): boolean => {
  return getIsDataFetching(state, GRANT_CIVIL_RIGHTS_SIGNATURES_PATH);
};

export const getRecords_CivilRightsSignatures = (
  state: unknown
): DistrictSchoolSignature[] => {
  return getRecords(state, GRANT_CIVIL_RIGHTS_SIGNATURES_PATH);
};

export const shouldRefreshReduxState_CivilRightsSignatures = (
  state: unknown
): boolean => {
  return shouldRefreshReduxState<DistrictSchoolSignature[]>(
    state,
    GRANT_CIVIL_RIGHTS_SIGNATURES_PATH
  );
};
