import CteInstructorReview from 'components/CTA/DistrictReporting/Instructional/CteInstructorReview';
import CteSalaryInformation from 'components/CTA/DistrictReporting/Instructional/CteSalaryInformation';
import CteSchedule from 'components/CTA/DistrictReporting/Instructional/CteSchedule';
import DirtyDataWarning from 'components/Common/DirtyDataWarning';
import Loading from 'components/Common/Loading';
import NonCteSchedule from 'components/CTA/DistrictReporting/Instructional/NonCteSchedule';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect, useRef } from 'react';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import {
  CTA_INSTRUCTORS_PATH,
  CTA_INSTRUCTOR_SALARY_PATH,
  CTA_NON_CTE_COURSE_PATH,
  CTA_CTE_COURSE_PATH,
} from 'redux/cta/_paths';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { getIsDataFetching } from 'redux/utility/_exports';
import { getIsDataFetching_CtaInstructorCourse } from 'redux/cta/_selectors';
import { isApplicaionIdValid } from 'util/global';
import { readCTADistrictName } from 'services/cta/ctageneral.services';
import { readInstructorName } from 'services/cta/ctainstructionalcosts.services';
import { some } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const EditCTAInstructor = () => {
  // -- dependencies --
  const { ctaId: cId, ctaInstructorId: ciId }: any = useParams();
  const ctaId = Number(cId);
  const ctaInstructorId = Number(ciId);
  const history = useHistory();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- localstate --
  const isMounted = useRef(true);
  const [districtName, setDistrictName] = useState<string>(EMPTY_PLACEHOLDER);
  const [instructorName, setInstructorName] = useState<string>(
    EMPTY_PLACEHOLDER
  );
  const [dirtyData, setDirtyData] = useState<boolean>(false);
  const [dirtyWarning, setDirtyWarning] = useState<boolean>(false);
  const [forceChildSave, setForceChildSave] = useState<boolean>(false);
  const isLoading = some([
    useSelector((state) => getIsDataFetching(state, CTA_INSTRUCTORS_PATH)),
    useSelector((state) =>
      getIsDataFetching(state, CTA_INSTRUCTOR_SALARY_PATH)
    ),
    useSelector((state) => getIsDataFetching(state, CTA_NON_CTE_COURSE_PATH)),
    useSelector((state) => getIsDataFetching(state, CTA_CTE_COURSE_PATH)),
    useSelector(getIsDataFetching_CtaInstructorCourse),
  ]);

  // -- component logic --
  const navigateBack = () => {
    history.push(`/cta/district-reporting/${ctaId}`);
  };

  const handleGoBack = () => {
    if (dirtyData) {
      setDirtyWarning(true);
    } else {
      navigateBack();
    }
  };

  const handleSave = () => {
    setForceChildSave(true);
    setTimeout(() => {
      setForceChildSave(false);
    }, 5000);
  };

  // -- lifecycle --
  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      readCTADistrictName(ctaId)
        .then((name: string) => isMounted.current && setDistrictName(name))
        .catch((err: any) => console.error(err));

      readInstructorName(ctaInstructorId)
        .then((name: string) => isMounted.current && setInstructorName(name))
        .catch((err: any) => console.error(err));
    }
  }, [ctaId, ctaInstructorId]);

  return (
    <React.Fragment>
      <SecureWrap component={['ctageneral']} isLocked={readOnly}>
        <ScrollToTop />
        <Loading isActive={isLoading} messageBefore="Loading..." />
        <DirtyDataWarning
          open={dirtyWarning}
          onClose={() => setDirtyWarning(false)}
          onCancel={navigateBack}
          onConfirm={() => {
            handleSave();
            setDirtyWarning(false);
          }}
        />
        <Paper>
          <div className="program__header u-flex">
            <div className="u-flex-grow-1">
              <h2 className="program__title">Instructor: {instructorName}</h2>
              <p className="program__subtitle">{districtName}</p>
            </div>
          </div>
        </Paper>
        <CteSchedule forceSave={forceChildSave} setDirtyData={setDirtyData} />
        <NonCteSchedule
          forceSave={forceChildSave}
          setDirtyData={setDirtyData}
        />
        <CteSalaryInformation
          forceSave={forceChildSave}
          setDirtyData={setDirtyData}
        />
        <CteInstructorReview
          forceSave={forceChildSave}
          setDirtyData={setDirtyData}
        />
      </SecureWrap>
      <Paper>
        <SaveButtons
          alternateAction={handleGoBack}
          hideCancel
          saveText="Return to Overview"
        />
      </Paper>
    </React.Fragment>
  );
};
