import { connect } from 'react-redux';
import { SignaturesComponent } from './Signatures.component';
import { loadCTASignatures, saveCTASignatures } from 'redux/cta/ctasignature';
import { getRecords_CTASignatures } from 'redux/cta/_selectors';

const mapStateToProps = (state: any) => {
  const ctaSignatures = getRecords_CTASignatures(state);
  return {
    ctaSignatures,
  };
};

const dispatchProps = {
  loadCTASignatures,
  saveCTASignatures,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SignaturesComponent);
