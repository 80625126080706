import { connect } from 'react-redux';
import { ProgramFilterComponent } from './ProgramFilterComponent';
import {
  setProgramFilterValue,
  resetProgramFilterValue,
  clearProgramFilters,
} from 'redux/filters/programfilter';
import { getRecords_ProgramFilterState } from 'redux/filters/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const filter = getRecords_ProgramFilterState(state);
  return { filter };
};

const dispatchToProps = {
  setProgramFilterValue,
  resetProgramFilterValue,
  clearProgramFilters,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramFilterComponent);
