import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
} from 'services';
import { FiscalYear } from 'types';

const BASE_URL = ['api', 'ctafiscalyear'];

export const readCTAFiscalYears = async (): Promise<FiscalYear[]> => {
  return fetch(getFormattedUrl(BASE_URL), { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Fiscalyear', err, 'readCurrentCTAFiscalYear');
    });
};
