import { connect } from 'react-redux';
import { getRecords_CtsoCodes } from 'redux/common/_selectors';
import { CTSOMultiSelectBox } from './CTSOMultiSelectBox.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  const organizations = getRecords_CtsoCodes(state);
  return {
    organizations,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(CTSOMultiSelectBox);
