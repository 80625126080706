import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { QuestionGroup } from 'types';
import { useParams } from 'react-router';
import { FlexRow, FlexGroup } from 'layouts';
import { QuestionValidation } from 'validations/Question.validations';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';

type Props = {
  annualReflection: QuestionGroup[];
  setCurrentNavAnchor: Function;
  loadAnnualReflection: Function;
};

export const AnnualReflectionSummaryComponent: React.FC<Props> = (props) => {
  const { annualReflection, loadAnnualReflection, setCurrentNavAnchor } = props;

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const { validate, isValid } = QuestionValidation();

  useEffect(() => {
    loadAnnualReflection(grantFiscalYearId);
  }, [loadAnnualReflection, grantFiscalYearId]);

  useEffect(() => {
    validate('allQuestionsAnswered', annualReflection);
  }, [annualReflection]); //eslint-disable-line

  return (
    <Paper>
      <SummaryScroll componentStep="annual-reflection" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('annual-reflection')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <h4>Annual Reflection Questions</h4>
          <p>
            Collection Status:{' '}
            {isValid ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('annual-reflection')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
