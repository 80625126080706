import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { SideNavData } from 'types/ui/SideNavData.types';
import { readGrantAssuranceSignatureNav } from 'services/nav/grantassurancesignature.services';

const initialState = baseInitialState;

export const loadGrantAssuranceSignatureNav = createActionThunk(
  'loadGrantAssuranceSignatureNav',
  (GrantFiscalYearId: number) => {
    return readGrantAssuranceSignatureNav(GrantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, SideNavData>(
  {
    ...baseLoadActionBuilder<SideNavData>(loadGrantAssuranceSignatureNav),
  },
  initialState
);
