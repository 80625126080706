import { GRANTS_ANNUAL_SPENDING_PLAN_KEY, GRANTS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { first, defaultTo } from 'lodash';
import { AnnualSpending, emptyAnnualSpending } from 'types';
import { getRemainingFunds } from 'util/annualSpendingUtility';

export const ANNUAL_SPENDING_PLAN_PATH = [
  GRANTS_KEY,
  GRANTS_ANNUAL_SPENDING_PLAN_KEY,
];

export const getIsDataFetching_AnnualSpendingPlan = (state: any): boolean => {
  return getIsDataFetching(state, ANNUAL_SPENDING_PLAN_PATH);
};

export const getRecords_AnnunalSpendingPlan = (
  state: unknown
): AnnualSpending[] => {
  return getRecords(state, ANNUAL_SPENDING_PLAN_PATH);
};

export const getAnnualSpendingPlan = (state: any): AnnualSpending => {
  const records = getRecords_AnnunalSpendingPlan(state);
  return defaultTo(first(records), emptyAnnualSpending());
};

export const getFundsRemaining = (state: any): number => {
  const records = getAnnualSpendingPlan(state);
  return getRemainingFunds(records);
};
