import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { Expense } from 'types';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import { safeGet } from 'util/objectUtility';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Input from 'components/Common/FormElements/Input';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';
import TravelerTypeSelectBox from 'components/Common/SelectBoxes/TravelerTypeSelectBox';

type TravelExpenseProps = {
  onChange: Function;
  expense: Expense;
  validations: ValidationObject<any>;
};

export const TravelExpense: React.FC<TravelExpenseProps> = (props) => {
  const { onChange, expense, validations } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { getError, getFieldValid, validate } = validations;

  // --[ component logic ]-----------------------------------------------------
  const get = safeGet(expense);

  return (
    <FlexGroup>
      <FlexRow>
        <Toggle
          noLabel={true}
          name="travelIsOutOfState"
          description="This is out of state travel"
          value={get('travelIsOutOfState')}
          onChange={onChange}
        />
      </FlexRow>

      {get('travelIsOutOfState') && (
        <React.Fragment>
          <FlexRow>
            <Toggle
              noLabel={true}
              name="travelerNoValidation"
              description="I cannot provide additional travel details now"
              value={get('travelerNoValidation')}
              onChange={onChange}
            />
          </FlexRow>

          <FlexRow>
            <DatePicker
              errorMessage={getError('travelStartDate')}
              label="Start Date"
              name="travelStartDate"
              onBlur={() =>
                validate('travelStartDate', get('travelStartDate'), expense)
              }
              onChange={onChange}
              valid={getFieldValid('travelStartDate')}
              value={get('travelStartDate')}
            />
            <DatePicker
              errorMessage={getError('travelEndDate')}
              label="End Date"
              name="travelEndDate"
              onBlur={() =>
                validate('travelEndDate', get('travelEndDate'), expense)
              }
              onChange={onChange}
              valid={getFieldValid('travelEndDate')}
              value={get('travelEndDate')}
            />
            <Input
              errorMessage={getError('travelerDestination')}
              label="Destination"
              name="travelerDestination"
              onBlur={() =>
                validate(
                  'travelerDestination',
                  get('travelerDestination'),
                  expense
                )
              }
              onChange={onChange}
              valid={getFieldValid('travelerDestination')}
              value={get('travelerDestination')}
            />
          </FlexRow>

          <FlexRow>
            <Input
              errorMessage={getError('travelerName')}
              label="Traveler Name"
              name="travelerName"
              onBlur={() =>
                validate('travelerName', get('travelerName'), expense)
              }
              onChange={onChange}
              valid={getFieldValid('travelerName')}
              value={get('travelerName')}
            />
            <FlexGroup
              onBlur={() =>
                validate('travelerTypeId', get('travelerTypeId'), expense)
              }
            >
              <TravelerTypeSelectBox
                errorMessage={getError('travelerTypeId')}
                name="travelerTypeId"
                onChange={(data) => onChange(data)}
                selection={get('travelerTypeId')}
                valid={getFieldValid('travelerTypeId')}
              />
            </FlexGroup>
            <Input
              errorMessage={getError('eventName')}
              label="Event Name / Website"
              name="eventName"
              onBlur={() => validate('eventName', get('eventName'), expense)}
              onChange={onChange}
              valid={getFieldValid('eventName')}
              value={get('eventName')}
            />
          </FlexRow>

          <FlexRow>
            <Textarea
              maxLength={4000}
              errorMessage={getError('travelerImproveProgramDesc')}
              label="How will this travel improve the CTE Program(s) selected?"
              name="travelerImproveProgramDesc"
              onBlur={() =>
                validate(
                  'travelerImproveProgramDesc',
                  get('travelerImproveProgramDesc'),
                  expense
                )
              }
              onChange={onChange}
              valid={getFieldValid('travelerImproveProgramDesc')}
              value={get('travelerImproveProgramDesc')}
            />
          </FlexRow>
        </React.Fragment>
      )}
    </FlexGroup>
  );
};
