import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  putWithAuthOptions,
  postWithAuthOptions,
  deleteWithAuthOptions,
  catchError,
} from 'services';
import { AwardType } from 'types';

const AWARD_TYPE_ENDPOINT = 'api/ProgramAwardTypes';
const URL = `${ENVIRONMENT.API_URL}${AWARD_TYPE_ENDPOINT}`;

export const createAwardType = async (awardType: AwardType) => {
  let newAward: Omit<AwardType, 'awardTypeId'> = {
    awardName: awardType.awardName,
  };
  return fetch(URL, {
    ...postWithAuthOptions(),
    body: JSON.stringify(newAward),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwardTypes', err);
    });
};

export const readAwardTypes = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwardTypes', err);
    });
};

export const updateAwardType = async (awardType: AwardType) => {
  const url = `${URL}${awardType.awardTypeId}`;
  return fetch(url, {
    ...putWithAuthOptions(),
    body: JSON.stringify(awardType),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwardTypes', err);
    });
};

export const deleteAwardType = async (awardTypeId: number) => {
  const url = `${URL}${awardTypeId}`;
  return fetch(url, { ...deleteWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAwardTypes', err);
    });
};
