import Button from 'components/Common/FormElements/Button';
import CTASchoolTermsSelectBox from 'components/Common/SelectBoxes/CTASchoolTermsSelectBox';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useRef, useState, useEffect } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTASchool, emptyCTASchool } from 'types/cta/CTASchool.types';
import { CTASchoolValidation } from 'validations/CTA/CTASchool.validations';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { FlexRow } from 'layouts';
import { Modal, useModal } from 'react-morphing-modal';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import {
  renderData,
  compose,
  cMergeObjects,
  safeGet,
} from 'util/objectUtility';
import { round } from 'util/mathHelpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

type TermsAndCreditsModalProps = {
  ctaSchool: CTASchool;
  saveCTASchool: Function;
};

export const TermsAndCreditsModal: React.FC<TermsAndCreditsModalProps> = (
  props
) => {
  const { ctaSchool, saveCTASchool } = props;

  // -- dependencies --
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const { modalProps, open, close } = useModal();
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
  } = CTASchoolValidation();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local state --
  const modalRef = useRef<HTMLDivElement>(null);
  const [schoolState, setSchoolState] = useState<CTASchool>(emptyCTASchool());

  // -- component logic --
  const validateSchool = (school: CTASchool) => {
    validateAll(school);
    return school;
  };
  const updateSchoolState = compose(
    setSchoolState,
    validateSchool,
    cMergeObjects(schoolState)
  );

  const saveTerms = () => {
    const canSave = validateAll(schoolState);
    if (canSave) {
      saveCTASchool(ctaId, schoolState)
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        })
        .finally(close());
    }
  };

  // -- lifecycle --
  useEffect(() => {
    setSchoolState(ctaSchool);
  }, [ctaSchool]);

  // -- display logic --
  const get = safeGet(schoolState);

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <IconButton
          name="pencil"
          tooltip="Edit"
          onClick={() => open(modalRef)}
        />
      </div>
      <Modal {...modalProps}>
        <div className="u-flex-center" style={{ width: '100%' }}>
          <Paper>
            <SecureWrap component={['ctageneral']} isLocked={readOnly}>
              <FlexRow>
                <Instruction
                  title={`Terms and credits per year for ${renderData(
                    get('schoolName')
                  )}`}
                >
                  <p>
                    Review the ‘Terms per Year’ and ‘Credits per Year.’ If edits
                    are necessary, use the dropdown menu and/or input the
                    correct number of credits in the indicated boxes. Click the
                    ‘Save’ button when you have completed this section.
                  </p>
                </Instruction>
              </FlexRow>

              <FlexRow style={{ justifyContent: 'space-between' }}>
                <div style={{ width: '50%', marginRight: '1.6rem' }}>
                  <FlexRow>
                    <CTASchoolTermsSelectBox
                      name="terms"
                      onChange={updateSchoolState}
                      selection={get('terms')}
                    />
                  </FlexRow>
                  <FlexRow>
                    <Input
                      errorMessage={getError('credits')}
                      label="Credits per Year"
                      name="credits"
                      onBlur={() => validate('credits', get('credits'))}
                      onChange={updateSchoolState}
                      step={0.1}
                      type="number"
                      valid={getFieldValid('credits')}
                      value={get('credits')}
                    />
                  </FlexRow>
                  <FlexRow>
                    <Input
                      disabled={true}
                      label="Calculated Contact Hours"
                      name="credits"
                      onChange={updateSchoolState}
                      value={
                        120 / schoolState.credits === Infinity
                          ? EMPTY_PLACEHOLDER
                          : round(2, 120 / schoolState.credits)
                      }
                    />
                  </FlexRow>
                </div>
                <div style={{ width: '50%', marginLeft: '1.6rem' }}>
                  <h4>Check for Accuracy</h4>
                  <span>
                    If the ‘Calculated Contact Hours Per Course’ is incorrect,
                    check that the number of terms and/or number of credits that
                    a student receives for a year-long course are correct.
                  </span>
                  <FlexRow />
                  <span>
                    *Note that the number of contact hours may not match what is
                    in program approval.
                  </span>
                </div>
              </FlexRow>

              <FlexRow>
                <div className="u-flex-grow-1" />
                <Button
                  asLink={false}
                  className="form__btn--cancel t-cancel"
                  onClick={close}
                  text="Cancel"
                />

                <ConfirmationDialog
                  disabled={!isValid}
                  tooltip="Change Terms and Credits"
                  header="Confirm Changes"
                  onConfirm={saveTerms}
                  activateButton={
                    <Button
                      asLink={false}
                      className="form__btn--submit t-save"
                      disabled={!isValid}
                      onClick={() => {}}
                      text="Save"
                    />
                  }
                >
                  <p>
                    Changing the Terms or Credits will reset any credit counts
                    you have made in your CTE and Non-CTE Schedules.
                  </p>
                </ConfirmationDialog>
              </FlexRow>
            </SecureWrap>
          </Paper>
        </div>
      </Modal>
    </React.Fragment>
  );
};
