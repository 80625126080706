import React, { useState, useEffect } from 'react';
import { compose, renderData, prop } from 'util/objectUtility';
import { ProgramArticulationDeep, Program, ProgramStep } from 'types';
import { getContactFullName } from 'util/universalcontact.utility';
import { isApplicaionIdValid } from 'util/global';
import { useParams, useHistory } from 'react-router-dom';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SaveButtons from 'components/Common/SaveButtons';
import Textarea from 'components/Common/FormElements/Textarea';
import Table from 'components/Common/Table';

type ArticulationCollaborationProps = {
  articulations: ProgramArticulationDeep[];
  loadProgramArticulations: Function;
  loadSingleProgramInstance: Function;
  nextProgramStep: ProgramStep;
  program: Program;
  readOnly: boolean;
  removeArticulation: Function;
  saveProgramInstanceAction: Function;
  setCurrentNavAnchor: Function;
};

export const ArticulationEditComponent: React.FC<
  ArticulationCollaborationProps
> = (props) => {
  const {
    articulations,
    loadProgramArticulations,
    loadSingleProgramInstance,
    nextProgramStep,
    program,
    readOnly,
    removeArticulation,
    saveProgramInstanceAction,
    setCurrentNavAnchor,
  } = props;

  const history = useHistory();
  const [programState, setProgramState] = useState<Program>({
    ...program,
  });

  const { programInstanceId: thepid }: any = useParams();
  const programInstanceId = Number(thepid);

  const onChange = (data: any) => {
    setProgramState({
      ...programState,
      collaboration: data.collaboration,
    });
  };

  const deleteArticulation = (id: number) => {
    removeArticulation(id);
  };

  const onSaveProgram = async (): Promise<any> => {
    return saveProgramInstanceAction({ ...programState });
  };

  const renderEdit = (row: ProgramArticulationDeep) => (
    <React.Fragment>
      <IconButton
        name="pencil"
        tooltip="Edit Articulation"
        onClick={() =>
          history.push(
            `/programs/articulations/${programInstanceId}/${
              row.programArticulationId
            }`
          )
        }
      />
    </React.Fragment>
  );
  const renderDelete = (row: ProgramArticulationDeep) => (
    <React.Fragment>
      <ConfirmationDialog
        header="Confirm Delete"
        tooltip="Delete Articulation"
        onConfirm={() => deleteArticulation(row.programArticulationId)}
      >
        <b>
          Are you sure you wish to delete the articulaion
          {row.school ? ` for ${row.school.schoolName}?` : '?'}
        </b>
      </ConfirmationDialog>
    </React.Fragment>
  );

  const renderPartnerCell = compose(
    renderData,
    prop('schoolName'),
    prop('school')
  );

  const renderYearOfAgreement = compose(
    renderData,
    prop('year')
  );

  const renderContactTitle = compose(
    renderData,
    prop('title')
  );

  const renderContactName = compose(
    renderData,
    getContactFullName,
    prop('contact')
  );

  const ARTICULATION_COLLABORATION_HEADERS: TableColumnProps[] = [
    {
      name: 'Partner',
      cell: renderPartnerCell,
      selector: 'school.schoolName',
      sortable: true,
    },
    {
      name: 'Contact Name',
      sortable: true,
      cell: renderContactName,
    },
    {
      name: 'Contact Title',
      selector: 'title',
      sortable: true,
      cell: renderContactTitle,
    },
    {
      name: 'Year of Agreement',
      cell: renderYearOfAgreement,
      selector: 'year',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: renderEdit,
      center: true,
      width: '10rem',
    },
    {
      name: 'Delete',
      cell: renderDelete,
      center: true,
      width: '10rem',
    },
  ];

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadSingleProgramInstance(programInstanceId);
      loadProgramArticulations(programInstanceId);
    }
  }, [loadSingleProgramInstance, programInstanceId, loadProgramArticulations]);

  useEffect(() => {
    setCurrentNavAnchor('articulations');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    if (isApplicaionIdValid(program.programInstanceId)) {
      setProgramState({ ...program });
    }
  }, [program]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <SecureWrap component="programs" isLocked={readOnly}>
        <Paper>
          <Instruction title="Articulations Information">
            <p>
              If applicable, list the institutions with which this program
              articulates. If you do not have an articulation agreement but
              offer your students the opportunity for concurrent enrollment in
              CTE courses enter the collaborating institution and contact
              information below and use the current year as the agreement date.
            </p>
          </Instruction>
          <Button
            text="+ Add Articulation"
            className="form__btn--add"
            onClick={() =>
              history.push(
                `/programs/articulations/${programInstanceId}/create`
              )
            }
          />
          <div className="form__group">
            <Table
              columns={ARTICULATION_COLLABORATION_HEADERS}
              data={articulations}
            />
          </div>
          <br />
        </Paper>

        <Paper>
          <h2 className="form__section-title">Collaborations</h2>
          <p />
          <div className="form__row">
            <Textarea
              name="collaboration"
              label="If applicable, list and describe formal consortium agreements with this program and/or evidence of shared resources."
              onChange={onChange}
              value={programState.collaboration}
            />
          </div>
        </Paper>
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            saveData={onSaveProgram}
            saveUrl={nextProgramStep.nav.url}
            saveText="Save and Continue"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
