import { basetype } from 'types';

// what does a course DTO look like?
export interface Course extends basetype {
  courseId: number;
  programLevelID: number;
  courseName: string;
  courseNumber: number;
  courseLevel: number;
  prefix: string;
  contactHours: number;
  courseDescription: string;
  advancedCreditOptions: string;
  defaultCredits: number;
  isCte: boolean;
  isApproved: boolean;
  isActive: boolean;
  modifiedBy: number;
  courseSchool: any[]; // CourseSchool[];
  ctaCourse: any; // CtaCourse;
  programAwardChronicleCourse: any[]; // ProgramAwardChronicleCourse[];
  programCareerPathwaysGroupChronicleCourse: any[]; // ProgramCareerPathwayGroupChronicleCourse[];
}

export const emptyCourse = (): Course => {
  return {
    courseId: -1,
    programLevelID: -1,
    courseName: '',
    courseNumber: 0,
    courseLevel: 0,
    prefix: '',
    contactHours: 0,
    courseDescription: '',
    advancedCreditOptions: '',
    defaultCredits: 0,
    isCte: true,
    isApproved: true,
    modifiedBy: -1,
    isActive: true,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    courseSchool: [],
    ctaCourse: {
      ctacourseId: -1,
      courseId: -1,
      term: 0,
      credit: 0,
      enrollment: 0,
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
    programAwardChronicleCourse: [],
    programCareerPathwaysGroupChronicleCourse: [],
  };
};
