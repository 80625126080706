import { connect } from 'react-redux';
import { ReviewAndOptimizeComponent } from './ReviewAndOptimize.component';
import { loadCTAReviewOptimize } from 'redux/cta/ctareviewoptimize';
import { getRecords_CTAReviewOptimize } from 'redux/cta/_selectors';

const mapStateToProps = (state: any) => {
  const reviewOptimize = getRecords_CTAReviewOptimize(state);
  return {
    reviewOptimize,
  };
};

const dispatchProps = {
  loadCTAReviewOptimize,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ReviewAndOptimizeComponent);
