import React, { useEffect, useState } from 'react';
import { CTABooksSchool } from 'types/cta/CTABooksSchool.types';
import {
  TableColumnProps,
  ConditionalRowStyle,
} from 'components/Common/Table/Table.component';
import {
  prop,
  compose,
  renderData,
  concatLengthWithItems,
  isTruthy,
  not,
  safeGet,
} from 'util/objectUtility';
import { gt as lt } from 'ramda';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { BooksSuppliesModal } from './BooksSuppliesModal';
import { useParams } from 'react-router';
import { sumNumbers } from 'util/mathHelpers';
import { fmap } from 'util/arrayUtility';
import { CTADistrictRecord } from 'types';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Instruction from 'components/Common/Instruction';
import Table from 'components/Common/Table';
import Paper from 'components/Common/Paper';

type BooksAndSuppliesProps = {
  ctaRecord: CTADistrictRecord;
  booksSchools: CTABooksSchool[];
  loadCTABooksSchool: Function;
  locked: boolean;
};

export const BooksAndSuppliesComponent: React.FC<BooksAndSuppliesProps> = (props) => {
  const { ctaRecord, booksSchools = [], loadCTABooksSchool, locked } = props;
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // -- dependencies --
  const { ctaId: cId }: any = useParams();
  const ctaId = Number(cId);

  // -- component logic --
  const totalCost = compose(sumNumbers, fmap(prop('totalCost')))(booksSchools);

  // -- lifecyle --
  useEffect(() => {
    if (wasOpened) {
      loadCTABooksSchool(ctaId);
    }
  }, [wasOpened, loadCTABooksSchool, ctaId]);

  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  // -- display logic --
  const get = safeGet(ctaRecord);
  const hide = compose(lt(0), prop('ctaSchoolId'));
  const BOOKS_SUPPLIES_COLUMNS: TableColumnProps[] = [
    {
      cell: compose(renderData, prop('schoolName')),
      name: 'School',
    },
    {
      cell: (row: CTABooksSchool) => {
        return prop('programs', row)
          ? concatLengthWithItems('programs', 'programName')(row)
          : null;
      },
      name: 'Programs',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: compose(insertDollarSign, formatMoney, prop('totalCost')),
      name: 'Total Cost',
      style: {
        width: '20rem',
        textAlign: 'right',
      },
    },
    {
      cell: (row: CTABooksSchool) => {
        return hide(row) ? null : (
          <BooksSuppliesModal
            ctaBooksSchool={row}
            district={get('schoolDistrictName')}
            year={get('year')}
          />
        );
      },
      name: 'Edit',
      center: true,
      width: '20rem',
    },
  ];

  const conditionalStyles: ConditionalRowStyle[] = [
    {
      when: compose(not, isTruthy, prop('programs')),
      style: {
        backgroundColor: 'transparent',
      },
    },
  ];

  const totalRow: CTABooksSchool = {
    document: '',
    ctaSchoolId: -1,
    programs: null as any,
    schoolName: 'Total',
    totalCost,
  };

  const tableData = [...booksSchools, totalRow];

  return (
    <CTACollapsableLayout
      name="cta-books"
      locked={locked}
      header="Books & Supplies"
      onChange={updateOpenedState}
    >
      <Paper className="t-books-supplies">
        <Instruction title="" hideTitle>
          <p>
            Books and Supplies costs must be entered for each school and program reported.
            Click the Edit icon next to a school’s name and follow the prompts in the pop-up
            window. If you do not see a program in this table, have the UC Power User or person
            with student record permission enter student enrollment data for the program in the
            Data Collection module.
          </p>
          <p>
            <span className="u-color-red">Note</span>: Entries with an ellipses (…) indicates
            that items were cutoff and the number in parenthesis (#) indicates the total number
            of items.
          </p>
        </Instruction>
        <Table
          data={tableData}
          columns={BOOKS_SUPPLIES_COLUMNS}
          conditionalRowStyles={conditionalStyles}
        />
      </Paper>
    </CTACollapsableLayout>
  );
};
