import { GRANTS_KEY, GRANTS_REIMBURSEMENT_SUMMARY_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  shouldRefreshReduxState,
  getFirstRecord,
} from 'redux/utility/baseSelectors';
import { ReimbursementSummary, emptyReimbursementSummary } from 'types';

export const REIMBURSEMENT_SUMMARY_PATH = [
  GRANTS_KEY,
  GRANTS_REIMBURSEMENT_SUMMARY_KEY,
];

export const getIsDataFetching_ReimbursementSummary = (state: any): boolean => {
  return getIsDataFetching(state, REIMBURSEMENT_SUMMARY_PATH);
};

export const getFirstRecord_ReimbursementSummary = (
  state: unknown
): ReimbursementSummary => {
  return getFirstRecord(
    state,
    REIMBURSEMENT_SUMMARY_PATH,
    emptyReimbursementSummary()
  );
};

export const shouldRefreshReduxState_ReimbursementSummary = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ReimbursementSummary>(
    state,
    REIMBURSEMENT_SUMMARY_PATH
  );
};
