import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from 'services';
import { Consortium, FiscalAgent, ConsortiumMember } from 'types';

const CONSORTIUM_ENDPOINT = 'api/Consortia/';
const FISCAL_AGENT_ENDPOINT = `${CONSORTIUM_ENDPOINT}FiscalAgentsAll`;
const MEMBER_ENDPOINT = `${CONSORTIUM_ENDPOINT}Available/`;

export const createNewConsortiums = async (grandFiscalYear: number) => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}CreateConsortiums/${grandFiscalYear}`;
  return fetch(url, {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'createNewConsortiums');
    });
};

export const createConsortium = async (consortium: Consortium) => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}`;
  consortium.createdDate = new Date(Date.now());
  consortium.modifiedDate = new Date(Date.now());
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(consortium),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'createConsortium');
    });
};

export const readConsortia = async (): Promise<Consortium[]> => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'readConsortia');
    });
};

export const readAllConsortia = async (): Promise<Consortium[]> => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}all`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'readAllConsortia');
    });
};

export const readSingleConsortium = async (
  grantFiscalYearId: number
): Promise<Consortium> => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'readSingleConsortium');
    });
};

export const readFiscalAgents = async (): Promise<FiscalAgent[]> => {
  const url = `${ENVIRONMENT.API_URL}${FISCAL_AGENT_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'readFiscalAgents');
    });
};

export const readConsortiaMembers = async (
  fiscalYear: number
): Promise<ConsortiumMember[]> => {
  const url = `${ENVIRONMENT.API_URL}${MEMBER_ENDPOINT}${fiscalYear}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Consortium Services', err, 'readConsortiaMembers');
    });
};

export const readConsortiaByFiscalYearId = async (
  fiscalYearId: number
): Promise<ConsortiumMember[]> => {
  const url = `${ENVIRONMENT.API_URL}${CONSORTIUM_ENDPOINT}all/${fiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'Consortium Services',
        err,
        'readConsortiaByFiscalYearId'
      );
    });
};
