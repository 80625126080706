import {
  removeArticulation,
  saveArticulation,
  loadProgramArticulations,
} from 'redux/programs/articulations';

export const programArticulationMiddleware = (store: any) => (
  next: Function
) => (action: any) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case removeArticulation.SUCCEEDED:
    case saveArticulation.SUCCEEDED: {
      dispatch(loadProgramArticulations(action.payload));
      break;
    }
  }
};
