import React, { useState, useEffect, CSSProperties } from 'react';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
  RenderSuggestionsContainerParams,
  RenderSuggestionParams,
} from 'react-autosuggest';
import { map, find } from 'lodash';
import { FlexGroup } from 'layouts';
import { renderPart, getParts, getSuggestions } from 'util/autoSuggest.utility';
import { prop } from 'util/objectUtility';
import {
  CTASupportProgramAffiliation,
  emptyCTASupportProgramAffiliation,
} from 'types';
import MaxResults from '../MaxResults';

type CTASchoolAndProgramSelectBoxProps = {
  ctaProgramId: number;
  label?: string;
  maxResults?: number;
  noLabel?: boolean;
  onChange: Function;
  programAffiliations: CTASupportProgramAffiliation[];
};

export const CTASchoolAndProgramSelectBox: React.FC<CTASchoolAndProgramSelectBoxProps> = (
  props
) => {
  const {
    ctaProgramId,
    programAffiliations,
    noLabel,
    onChange,
    label,
    maxResults = 20,
  } = props;

  // -- dependencies --

  // -- local states --
  const [resultText, setResultText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<
    CTASupportProgramAffiliation[]
  >([]);

  // -- component logic --
  const defaultResult: CTASupportProgramAffiliation = {
    ...emptyCTASupportProgramAffiliation(),
    schoolName: 'No results',
  };

  const executeSearch = (value: string): CTASupportProgramAffiliation[] => {
    return getSuggestions(
      programAffiliations,
      value,
      [prop('schoolName'), prop('programName')],
      maxResults,
      defaultResult
    );
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    const { value } = request;
    if (value === resultText) {
      setSearchResults(executeSearch(value));
    }
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'left':
      case 'right':
      case 'down':
      case 'up':
        break;
      default:
        setResultText(newValue);
        setSearchResults(executeSearch(newValue));
        break;
    }
  };

  const getSuggestionValue = (suggestion: CTASupportProgramAffiliation) => {
    return `${prop('schoolName', suggestion)} / ${prop(
      'programName',
      suggestion
    )}`;
  };

  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  const onSuggestionSelected = (
    _: React.FormEvent<any>,
    data: SuggestionSelectedEventData<CTASupportProgramAffiliation>
  ) => {
    const { suggestion } = data;
    onSuggestionsClearRequested();
    setResultText('');
    onChange && onChange({ ctaProgramId: suggestion.ctaProgramId });
  };

  // -- lifecyle --
  useEffect(() => {
    const selected = find(
      programAffiliations,
      (item: CTASupportProgramAffiliation) => {
        return item.ctaProgramId === ctaProgramId;
      }
    );
    if (selected) {
      setResultText(getSuggestionValue(selected));
    } else {
      setResultText('');
    }
  }, [ctaProgramId, programAffiliations]);

  // -- display logic --
  const inputProps = {
    value: resultText,
    onChange: handleChange,
    id: 'ctaProgramId',
  };

  const boxStyle: CSSProperties = {
    width: '50%',
  };

  const renderSuggestionsContainer = (
    suggestionParams: RenderSuggestionsContainerParams
  ) => {
    const { containerProps, children } = suggestionParams;
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>School Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>Program Name</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={programAffiliations.length}
          resultCount={searchResults.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: CTASupportProgramAffiliation,
    { query }: RenderSuggestionParams
  ) => {
    const schoolParts = getParts(prop('schoolName', suggestion), query);
    const programParts = getParts(prop('programName', suggestion), query);

    return (
      <>
        <div className="u-flex">
          <div style={boxStyle}>{map(schoolParts, renderPart)}</div>
          <div style={boxStyle}>{map(programParts, renderPart)}</div>
        </div>
      </>
    );
  };

  return (
    <FlexGroup>
      {!noLabel && (
        <p className="form__label">{label ? label : 'Name of the Contact'}</p>
      )}
      <Autosuggest
        aria-label="CTA School And Program SelectBox"
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={true}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={searchResults}
      />
    </FlexGroup>
  );
};
