import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import { Program } from 'types';
import ProgramLayout from 'layouts/ProgramLayout';

type ProgramAdvancedCreditOptionsProps = {
  programInstanceId: number;
  program: Program;
  isProgramDataFetching: boolean;
  getSingleProgram: Function;
};

export const ProgramAdvancedCreditOptions: React.FC<
  ProgramAdvancedCreditOptionsProps
> = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        {/* TODO: Need to wire up the data for this page */}
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramAdvancedCreditOptions;
