import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_VOUCHER_ONE_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_ONE = `${CHANGELOG_VOUCHER_ONE_KEY} RESET UPDATE`;
export const SET_CHANGELOG_VOUCHER_ONE = `${CHANGELOG_VOUCHER_ONE_KEY} SET`;
export const SET_CHANGELOG_VOUCHER_ONE_LOADING = `${CHANGELOG_VOUCHER_ONE_KEY} LOADING`;

const initState = baseInitialState;

export const changelogVoucherOneReducer = (state = initState, action: any) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_ONE:
      return setShouldUpdate(state);

    case SET_CHANGELOG_VOUCHER_ONE:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_VOUCHER_ONE_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogVoucherOne = (payload: any) => {
  return {
    type: SET_CHANGELOG_VOUCHER_ONE,
    payload,
  };
};

export const setIsLoading_ChangelogVoucherOne = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_VOUCHER_ONE_LOADING,
    payload,
  };
};
