import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { FlexRow, FlexGroup } from 'layouts';
import { GrantFiscalYear, UniversalContact } from 'types';
import { useDispatch } from 'react-redux';
import { loadSingleConsortium } from 'redux/grants/consortium';
import { isApplicaionIdValid } from 'util/global';
import { loadSingleGrantFiscalYear } from 'redux/grants/grantfiscalyear';
import { useParams } from 'react-router-dom';
import { compose, renderData, prop } from 'util/objectUtility';
import {
  getContactFullName,
  getUserEmail,
  getUserPhone,
} from 'util/universalcontact.utility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { getArrayOrEmpty } from 'util/arrayUtility';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import Paper from 'components/Common/Paper';
import Table from 'components/Common/Table';

type GeneralSummaryProps = {
  grantFiscalYear: GrantFiscalYear;
  entityName: string | undefined;
  setCurrentNavAnchor: Function;
  selectedPrimaryUCContact: UniversalContact;
};

export const GeneralSummary: React.FC<GeneralSummaryProps> = (props) => {
  const {
    grantFiscalYear,
    entityName = '',
    setCurrentNavAnchor,
    selectedPrimaryUCContact,
  } = props;

  const { grantFiscalYearId: gfyId }: any = useParams();
  const grantFiscalYearId = Number(gfyId);

  const dispatch = useDispatch();

  const FullName = compose(renderData, getContactFullName);

  const Title = compose(renderData, prop('title'));

  const Email = compose(renderData, getUserEmail);

  const Phone = compose(renderData, getUserPhone);

  const renderNavIcon = (row: UniversalContact) => {
    return (
      <React.Fragment>
        <IconButton
          name="launchOpen"
          tooltip="View Grant Application Details"
          asLink={true}
          to={`/contacts/${row.universalContactId}`}
        />
      </React.Fragment>
    );
  };

  const UC_COLUMNS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: FullName,
      sortable: true,
      selector: FullName,
    },
    {
      name: 'Title',
      cell: Title,
      sortable: true,
      selector: Title,
    },
    {
      name: 'Email',
      cell: Email,
      sortable: true,
      selector: Email,
    },
    {
      name: 'Phone',
      cell: Phone,
      sortable: true,
      selector: Phone,
    },
    {
      name: 'View',
      cell: renderNavIcon,
      center: true,
    },
  ];

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYear.grantFiscalYearId)) {
      dispatch(loadSingleConsortium(grantFiscalYear.grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYear]);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadSingleGrantFiscalYear(grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYearId]);

  return (
    <Paper>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('general')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <Input
          disabled={true}
          className="input-display"
          name="entityName"
          label="District / Institution Name"
          value={renderData(entityName)}
        />
        <Input
          disabled={true}
          className="input-display"
          name="grantType"
          label="Type of Grant"
          value="Basic"
        />
        <Input
          disabled={true}
          className="input-display"
          name="grantFiscalYear.year"
          label="Fiscal Year"
          value={renderData(grantFiscalYear.year)}
        />
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <h3>Primary Contact</h3>
          <Table columns={UC_COLUMNS} data={[selectedPrimaryUCContact]} />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <h3>Finance Contacts</h3>
          <Table
            columns={UC_COLUMNS}
            data={getArrayOrEmpty(grantFiscalYear.financeReviewers)}
          />
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('general')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
