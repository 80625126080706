import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ConsortiumViewComponent from 'components/Grants/ConsortiumManagement/View';

type ConsortiumViewPageProps = {
  consortiumId: number;
};

export const ConsortiumViewPage: React.FC<ConsortiumViewPageProps> = (
  props
) => {
  return (
    <BaseLayout>
      <ConsortiumViewComponent {...props} />
    </BaseLayout>
  );
};
