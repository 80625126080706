import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { Signature } from 'types';

const ENDPOINT = 'api/GrantSignatures/BasicGrantSignatures/';

export const readBasicGrantSignatures = async (grantFiscalYearId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('BasicGrantSignatures', err);
    });
};

export const updateBasicGrantSignatures = async (
  grantFiscalYearId: number,
  signatures: Signature[]
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(signatures),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('BasicGrantSignatures', err);
    });
};
