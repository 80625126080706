import { get, map } from 'lodash';
import { PROGRAM_COURSE_CREDIT_CACHE_KEY, CACHE_KEY } from 'redux/keys';
import {
  CourseCreditRequired,
  CourseCreditAward,
  CourseCreditElective,
  CourseCreditGeneral,
  CourseCreditCustomForTotal,
} from 'types';
import { getIsDataFetching } from 'redux/utility/_exports';

const paths: string[] = [CACHE_KEY, PROGRAM_COURSE_CREDIT_CACHE_KEY];

export const getIsDataFetching_CourseCredits = (state: any) => {
  return getIsDataFetching(state, paths);
};

export const getProgramCourseCreditCache = (state: any) => {
  return get(state, paths, null);
};

export const getProgramCourseCredits = (
  state: any,
  programInstanceId: number
) => {
  const cache = getProgramCourseCreditCache(state);
  const records = cache[programInstanceId];
  return records ? records.records : null;
};

export const isProgramCourseCreditsDirty = (
  state: any,
  programInstanceId: number
) => {
  const cache = getProgramCourseCreditCache(state);
  if (!cache) return false;
  const records = cache[programInstanceId];
  return records ? records.isDirty : false;
};

export const calculateProgramCourseCredits = (
  state: any,
  programInstanceId: number
): number[] => {
  const courseCredits = getProgramCourseCredits(state, programInstanceId);
  if (!courseCredits) return [];
  const required = map(
    courseCredits.requiredCourses,
    (course: CourseCreditRequired) => {
      return map(course.awards, (award: CourseCreditAward) => {
        return Number(award.credits);
      });
    }
  );
  const general = map(
    courseCredits.generalEducationCourses,
    (course: CourseCreditGeneral) => {
      return map(course.awards, (award: CourseCreditAward) => {
        return Number(award.credits);
      });
    }
  );
  const electives = map(
    courseCredits.electiveCourses,
    (course: CourseCreditElective) => {
      return Number(course.credits);
    }
  );
  const customCourses = map(
    courseCredits.customCourses,
    (course: CourseCreditCustomForTotal) => {
      return map(course.awards, (award: CourseCreditAward) => {
        return Number(award.credits);
      });
    }
  );

  const results = [...required, ...general, ...customCourses];
  let sums = new Array(courseCredits.programAwards.length).fill(0);
  let awardIdx = 0;
  while (awardIdx < sums.length) {
    for (let result of results) {
      if (isNaN(Number(result[awardIdx]))) continue;
      sums[awardIdx] += Number(result[awardIdx]);
    }
    sums[awardIdx] += Number(electives[awardIdx]);
    awardIdx++;
  }
  return sums;
};
