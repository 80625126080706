import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import ProgramOfStudyOverview from 'components/Program/ProgramOfStudyOverview';

export const ProgramOfStudyOverviewPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramOfStudyOverview {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramOfStudyOverviewPage;
