import { connect } from 'react-redux';
import { CteInstructorReview } from './CteInstructorReview.component';
import {
  loadSingleInstructor,
  saveInstructor,
} from 'redux/cta/InstructionalCosts/ctainstructors';

const dispatchProps = {
  loadSingleInstructor,
  saveInstructor,
};

export default connect(
  null,
  dispatchProps
)(CteInstructorReview);
