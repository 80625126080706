import { useValidation } from 'de-formed-validations';
import { SignatureInput } from 'types';
import { isStringValid } from 'util/validation.utility';

export const SignatureComponentValidations = () => {
  return useValidation<SignatureInput>({
    signedName: [
      {
        errorMessage: 'You must provide your name.',
        validation: (value: string, signature: SignatureInput) => {
          return signature.isDirty ? isStringValid(value) : true;
        },
      },
    ],
    signedTitle: [
      {
        errorMessage: 'You must provide your title.',
        validation: (value: string, signature: SignatureInput) => {
          return signature.isDirty ? isStringValid(value) : true;
        },
      },
    ],
  });
};
