import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { CourseCreditsEdit } from 'components/Program/CourseCredits/CourseCreditsConnect';

type ProgramCoursesCreditsStepProps = {
  programInstanceId: number;
  isProgramDataFetching: boolean;
};

export const ProgramCoursesCreditsStep: React.FC<
  ProgramCoursesCreditsStepProps
> = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <CourseCreditsEdit />
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramCoursesCreditsStep;
