import React from 'react';
import { CTAFilterState } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';

type CTAHotButtonsProps = {
  filterState: CTAFilterState;
  filterLengths: {
    needsReviewCount: number;
    notStartedCount: number;
  };
  setCTAFilterValue: Function;
  clearCTAFilters: Function;
};

export const CTAHotButtonsComponent: React.FC<CTAHotButtonsProps> = (props) => {
  const { setCTAFilterValue, clearCTAFilters, filterState, filterLengths } = props;
  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          active={filterState.needsReview !== undefined}
          iconName="clipboard"
          number={filterLengths.needsReviewCount}
          onClick={() => {
            clearCTAFilters();
            setCTAFilterValue({
              needsReview: filterState.needsReview ? undefined : true,
            });
          }}
          text={`Needs Review`}
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={filterState.notStarted !== undefined}
          iconName="inbox"
          number={filterLengths.notStartedCount}
          onClick={() => {
            clearCTAFilters();
            setCTAFilterValue({
              notStarted: filterState.notStarted ? undefined : true,
            });
          }}
          text={`Not Started`}
        />
      </FlexGroup>
    </FlexRow>
  );
};
