import { USER_KEY } from 'redux/keys';
import { defaultTo, get, uniq, each, first } from 'lodash';
import {
  School,
  UniversalContact,
  emptyUniversalContact,
  District,
} from 'types';
import { getIsDataFetching } from 'redux/utility/_exports';

export const getIsUserFetching = (state: any): boolean => {
  return getIsDataFetching(state, [USER_KEY]);
}; // End

export const getLoggedinUser = (state: any): UniversalContact => {
  const users = get(state, [USER_KEY, 'records'], []);
  return defaultTo(first(users), {
    ...emptyUniversalContact(),
  });
};

export const isUserAuthenticated = (state: any): boolean => {
  return get(state, [USER_KEY, 'isUserAuthenticated'], false);
};

export const getIsUserNew = (state: any): boolean => {
  const user = getLoggedinUser(state);
  const isNewUser = user.isNew ? user.isNew : false;
  return isNewUser;
};

export const getUsersSchools = (state: any): School[] => {
  const user = getLoggedinUser(state);
  const udistricts = user.districts;
  const uschools = user.schools;

  let schools: School[] = [];
  each(udistricts, (item: District) => {
    each(item.schools, (sc: School) => {
      schools.push(sc);
    });
  });

  each(uschools, (item: School) => {
    schools.push(item);
  });
  return uniq(schools);
};
