import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAContracting } from 'types';
import {
  readCTAContractingHosts,
  deleteCTAContractingHost,
} from 'services/cta/ctacontracting.services';

const initialState = baseInitialState;

export const loadCTAContracting = createActionThunk(
  'loadCTAContracting',
  (ctaId: number) => {
    return readCTAContractingHosts(ctaId);
  }
);

export const removeCTAContracting = createActionThunk(
  'removeCTAContracting',
  ({ hostingId, ctaId }: CTAContracting) => {
    return deleteCTAContractingHost(ctaId, hostingId);
  }
);

export default handleActions<BaseStateObject, CTAContracting[]>(
  {
    ...baseLoadActionBuilder<CTAContracting[]>(loadCTAContracting),
  },
  initialState
);
