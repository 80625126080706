import React from 'react';
import { SecondaryInformation } from './SecondaryInformation';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';
import { SecondaryCareerPathways } from './SecondaryCareerPathways';
import { PosInfoSection } from '../PosInfoSection';
import { map, filter, includes } from 'lodash';

const SecondaryInformationComponent: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy, ctsoCodes } = props;
  const { secondaryProgram: program } = programOfStudy;

  const filterCtsoCodes = (ids: string[]): string => {
    const ctsos = filter(ctsoCodes, (code) => {
      return includes(ids, code.ctsoId);
    });
    return ctsos && ctsos.length > 0
      ? map(ctsos, (code) => {
          return code.ctsoDescription;
        }).join(',  ')
      : '';
  };

  return (
    <React.Fragment>
      <SecondaryInformation {...props} />
      <SecondaryCareerPathways {...props} />

      <PosInfoSection
        title="Work-based Learning Offered Through Secondary Program:"
        text={program.workBasedLearning}
      />

      <PosInfoSection
        title={'CTSO Organization(s):'}
        text={filterCtsoCodes(program.ctsO_IDs)}
      />
    </React.Fragment>
  );
};

export default SecondaryInformationComponent;
