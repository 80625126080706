import { connect } from 'react-redux';
import { AdminLayout } from './AdminLayout.layout';
import { getLoadingState } from 'redux/application/selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const loadingState = getLoadingState(state);
  return {
    ...ownProps,
    loadingState,
  };
};

export default connect(
  mapStateToProps,
  null
)(AdminLayout);
