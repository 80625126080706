import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramOfStudys,
  createProgramOfStudy,
  deleteProgramOfStudy,
  approveProgramOfStudy,
  declineProgramOfStudy,
} from 'services/programs/programofstudy.services';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { ProgramOfStudy } from 'types';

const initialState = baseInitialState;

export const saveProgramOfStudyApproval = createActionThunk(
  'saveProgramOfStudyApproval',
  (programInstanceId: number, programOfStudyId: number) => {
    return approveProgramOfStudy(programInstanceId, programOfStudyId);
  }
);

export const saveProgramOfStudyDenial = createActionThunk(
  'saveProgramOfStudyDenial',
  (programInstanceId: number, programOfStudyId: number) => {
    return declineProgramOfStudy(programInstanceId, programOfStudyId);
  }
);

export const loadProgramOfStudyData = createActionThunk(
  'loadProgramOfStudyData',
  (programInstanceId: number) => {
    return readProgramOfStudys(programInstanceId);
  }
);

export const saveProgramOfStudyData = createActionThunk(
  'saveProgramOfStudyData',
  (programInstanceId: number, pos: ProgramOfStudy) => {
    return createProgramOfStudy(programInstanceId, pos);
  }
);

export const removeProgramOfStudy = createActionThunk(
  'removeProgramOfStudy',
  (programInstanceId: number, programOfStudyId: number) => {
    return deleteProgramOfStudy(programInstanceId, programOfStudyId);
  }
); // end getProgramData

export default handleActions<BaseStateObject, ProgramOfStudy[]>(
  {
    [removeProgramOfStudy.START]: (state) => {
      return actionFetching(state, true);
    },
    [removeProgramOfStudy.ENDED]: (state) => {
      return actionFetching(state, true);
    },

    ...baseLoadActionBuilder<ProgramOfStudy[]>(loadProgramOfStudyData),

    [saveProgramOfStudyApproval.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveProgramOfStudyApproval.ENDED]: (state) => {
      return actionFetching(state, true);
    },

    [saveProgramOfStudyDenial.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveProgramOfStudyDenial.ENDED]: (state) => {
      return actionFetching(state, true);
    },

    [saveProgramOfStudyData.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveProgramOfStudyData.ENDED]: (state) => {
      return actionFetching(state, true);
    },
  },
  initialState
);
