export interface CTAAdminInformation {
  year: number;
  submissionsOpen: boolean;
  reportsComplete: boolean;
  calculationComplete: boolean;
  recipientCount: number;
  recipientReimbursementCount: number;
  ctaAllocation: CTAAllocation;
}

export const emptyCTAAdminInformation = () => {
  return {
    submissionsOpen: false,
    year: 1970,
    reportsComplete: false,
    calculationComplete: false,
    recipientCount: 0,
    recipientReimbursementCount: 0,
    ctaAllocation: emptyCTAAllocation(),
  };
};

export interface CTAAllocation {
  year: number;
  stateAllocation: number;
  carryOver: number;
}

export const emptyCTAAllocation = (): CTAAllocation => {
  return {
    year: 1970,
    stateAllocation: 0,
    carryOver: 0,
  };
};
