import { CTA_KEY, CTA_EQUIPMENT_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTAEquipment } from 'types/cta/CTAEquipment.types';

export const CTA_EQUIPMENT_PATH = [CTA_KEY, CTA_EQUIPMENT_KEY];

export const getIsDataFetching_CTAEquipment = (state: any): boolean => {
  return getIsDataFetching(state, CTA_EQUIPMENT_PATH);
};

export const getRecords_CTAEquipment = (state: unknown): CTAEquipment[] => {
  return getRecords(state, CTA_EQUIPMENT_PATH);
};
