import React, { useRef } from 'react';
import ReactToggle from 'react-toggle';
import Label from 'components/Common/FormElements/Label';
import { insertClass } from 'util/formHelpers';
import { isDisabled } from 'util/authorization';

type ToggleProps = {
  border?: boolean;
  centerWithRow?: boolean;
  description?: string;
  disabled?: boolean;
  inTable?: boolean;
  label?: string;
  name: string;
  noLabel?: boolean;
  onChange: Function;
  value: boolean;
  className?: string;
  ariaLabel?: string;
};

export const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    border = false,
    centerWithRow = false,
    description,
    disabled,
    inTable,
    label,
    name,
    noLabel,
    onChange,
    value,
    className,
    ariaLabel,
  } = props;

  const handleChange = () => {
    if (isDisabled(disabled, ref)) return;
    const data: any = {};
    data[name] = !value;
    onChange(data, name, !value);
  };

  const ref = useRef(null);

  const styles = inTable ? { height: '2.8rem' } : {};

  const getClass = () => {
    const base = centerWithRow
      ? 'form__group form__toggle-container form__toggle--center'
      : 'form__group form__toggle-container';
    return `${base} ${insertClass(className)}`;
  };

  return (
    <div className={getClass()} style={styles}>
      {!noLabel && (
        <div>
          <p className="form__label">{label}</p>
        </div>
      )}
      <div className={`u-flex form__toggle ${border && 'u-border'}`}>
        <ReactToggle
          // aria-labelledby={name}
          aria-label={ariaLabel}
          aria-checked={value}
          id={ariaLabel ? ariaLabel : name}
          checked={value}
          disabled={isDisabled(disabled, ref)}
          onChange={handleChange}
          icons={false}
        />
        {description && (
          <Label
            name={name}
            htmlFor={ariaLabel ? ariaLabel : name}
            label={description}
            className="u-margin-left--medium form__clear-weight"
          />
        )}
      </div>
    </div>
  );
};
