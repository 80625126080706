import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { PermissionRoleComponent } from 'types';

const PERMISSION_ROLE_COMPONENT_ENDPOINT = 'api/PermissionRoleComponents/';
const URL = `${ENVIRONMENT.API_URL}${PERMISSION_ROLE_COMPONENT_ENDPOINT}`;

export const readPermissionRoleComponents = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoleComponents', err);
    });
}; // end

export const createPermissionRoleComponent = async (
  roleId: number,
  prolecomponent: PermissionRoleComponent[]
) => {
  return fetch(`${URL}/${roleId}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(prolecomponent),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoleComponents', err);
    });
}; // end
