import React, { useRef } from 'react';
import { isDisabled } from 'util/authorization';
import Icon from 'components/Common/Icons';

type CheckBoxProps = {
  disabled?: boolean;
  name: string;
  label: string;
  onChange: Function;
  value: boolean | null;
};

export const Checkbox: React.FC<CheckBoxProps> = (props) => {
  const { onChange, value, name, label, disabled } = props;
  const ref = useRef(null);

  const handleClick = () => {
    if (isDisabled(disabled, ref)) return;
    let data: {
      [key: string]: unknown;
    } = {};
    const outputValue = !value;
    data[name] = outputValue;
    onChange && onChange(data, name, outputValue);
  };

  return (
    <div
      role="checkbox"
      aria-checked={!!value}
      tabIndex={0}
      className="form__checkbox-container"
      onClick={handleClick}
      onKeyPress={(e: React.KeyboardEvent) => {
        e.preventDefault();
        (e.key === 'Enter' || e.key === ' ') && handleClick();
      }}
      style={{
        padding: '0.25rem .5rem',
      }}
    >
      {value ? (
        <div className="u-flex">
          <Icon name="circleChecked" className="form__checkbox" />
          <p
            style={{
              marginLeft: '1rem',
              userSelect: 'none',
            }}
          >
            {label}
          </p>
        </div>
      ) : (
        <div className="u-flex">
          <Icon name="circleOpen" className="form__checkbox" />
          <p
            style={{
              marginLeft: '1rem',
              userSelect: 'none',
            }}
          >
            {label}
          </p>
        </div>
      )}
    </div>
  );
};
