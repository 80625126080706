import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, ReportingFilterState } from 'types';

const initialState: ReportingFilterState = {
  cccsFavorite: undefined,
  financeFavorite: undefined,
  dcReview: undefined,
  ctaReview: undefined,
  mostUsed: undefined,
  module: [],
};

export const setReportingFilterValue = createAction('setReportingFilterValue');
export const resetReportingFilterValue = createAction(
  'resetReportingFilterValue'
);
export const clearReportingFilters = createAction('clearReportingFilters');

export default handleActions<
  ReportingFilterState,
  KeyValueType<number> | string
>(
  {
    setReportingFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetReportingFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: undefined,
      };
    },
    clearReportingFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
