import { connect } from 'react-redux';
import {
  getRecords_CareerCluster,
  CAREER_CLUSTERS_PATH,
} from 'redux/common/_selectors';
import { OptionMap, CareerCluster, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, filter, label, onChange, records: baseRecords } = ownProps;

  const records = getRecordsUtility<CareerCluster>(
    state,
    CAREER_CLUSTERS_PATH,
    getRecords_CareerCluster,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<CareerCluster> = {
    key: 'careerClusterId',
    label: 'careerClusterTitle',
    value: 'careerClusterId',
  };

  return {
    label: label ? label : 'Career Cluster',
    name: 'careerClusterId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
