/**
 * This file contains functions that help in calculating values for the annualSpendingPlan
 * @module Utilities > AnnualSpendingUtility
 */
import {
  SpendingCategory,
  AnnualSpending,
  Expense,
  VoucherSpendingCategory,
  defaultNeedsAssessmentCosts,
  defaultIndirectAdministrativeCosts,
  defaultDirectAdministrativeCosts,
} from 'types';
import { sumNumbers, subtract, add } from './mathHelpers';
import { map, some, find, curry } from 'lodash';
import {
  both,
  converge,
  firstMatch,
  isFalse,
  isTruthy,
  prop,
} from './objectUtility';
import { compose } from 'redux';
import { fmap } from './arrayUtility';
import { allParamsTrue } from './validation.utility';
import { isEqual } from './formHelpers';
import { multiply, defaultTo } from 'ramda';
import { SPENDING_CATEGORY } from 'enums/spendingCategory.enum';

/**
 * Function used soley for typing
 * @ignore
 */
const isSpendingCategoryType = (value: SpendingCategory): boolean => {
  return true;
};

/**
 * Returns true if the the spending category is for IndirectAdministrativeCosts
 * @function
 * @param { SpendingCategory } value SpendingCategory object to test
 */
export const isIndirectAdminCosts: typeof isSpendingCategoryType = compose(
  isEqual(SPENDING_CATEGORY.IndirectAdministrativeCosts),
  prop('categoryId')
);

/**
 * Returns true if the the spending category is for DirectAdministrativeCosts
 * @function
 * @param { SpendingCategory } value SpendingCategory object to test
 */
export const isDirectAdminCosts: typeof isSpendingCategoryType = compose(
  isEqual(SPENDING_CATEGORY.DirectAdministrativeCosts),
  prop('categoryId')
);

/**
 * Returns true if the the spending category is for NeedsAssessmentCosts
 * @function
 * @param { SpendingCategory } value SpendingCategory object to test
 */
export const isNeedsAssessment: typeof isSpendingCategoryType = compose(
  isEqual(SPENDING_CATEGORY.NeedsAssessmentCosts),
  prop('categoryId')
);

/**
 * Returns true if the the spending category is for ImprovementPlanCosts
 * @function
 * @param { SpendingCategory } value SpendingCategory object to test
 */
export const isImprovementPlan: typeof isSpendingCategoryType = compose(
  isEqual(SPENDING_CATEGORY.ImprovementPlanProject),
  prop('categoryId')
);

/**
 * calculates all allocatedFunds for a spending plan
 * @function
 * @param {SpendingCategory[]} categories the SpendingCategories to calculate against
 * @returns allocated funds as a number
 */
export const getAllocatedFunds = (categories: SpendingCategory[]): number => {
  return compose(
    sumNumbers,
    fmap(prop('allocatedFunds'))
  )(categories) as number;
};

/**
 * calculates total expenses for a spending plan
 * @function
 * @param {SpendingCategory[]} categories the SpendingCategories to calculate against
 * @returns total expenses as a number
 */
export const getTotalExpenses = (categories: SpendingCategory[]): number => {
  return sumNumbers(
    map(categories, (category: SpendingCategory) => {
      if (category.isFundPool && !category.isPartnershipLead) {
        return 0;
      }
      return sumNumbers(
        map(category.expenses, (expense: Expense) => {
          if (expense.isBackupExpense) return 0;
          return expense.budgetedAmount;
        })
      );
    })
  );
};

/**
 * calculates all equipment expenses for a spending plan
 * @function
 * @param {SpendingCategory[]} categories the SpendingCategories to calculate against
 * @returns all equipment expenses as a number
 */
export const getEquipmentExpenses = (
  categories: SpendingCategory[]
): number => {
  return sumNumbers(
    map(categories, (category: SpendingCategory) => {
      return sumNumbers(
        map(category.expenses, (expense: Expense) => {
          // ignore expense if it belongs to a fund pooled partner
          if (category.isFundPool && !category.isPartnershipLead) return 0;
          // ignore expense if it is a backup expense
          if (expense.isBackupExpense) return 0;
          // only return financialCategoryId that is equipment expense
          return expense.financialCategoryId === 4 ? expense.budgetedAmount : 0;
        })
      );
    })
  );
};

/**
 * calculates the remaining allocated funds for a category.
 * @function
 * @param {SpendingCategory} category the SpendingCategory to calculate
 * @returns remaining funds as a number
 */
export const getAllocatedFundsRemaining = (category: SpendingCategory) => {
  const { partnershipInstituteContribution } = category;
  const totalAvailable = category.allocatedFunds;
  const totalExpenses = getCategoryExpenses(category) * -1;
  const total = sumNumbers([
    totalAvailable,
    partnershipInstituteContribution,
    totalExpenses,
  ]);
  return total;
};

/**
 * calculates the remaining allocated funds for a category.
 * @function
 * @param {SpendingCategory} category the SpendingCategory to calculate
 * @returns the expenses as a number
 */
export const getCategoryExpenses = (spendingCategory: SpendingCategory) => {
  return sumNumbers(
    map(spendingCategory.expenses, (expense: Expense) => {
      if (expense.isBackupExpense) return 0;
      return expense.budgetedAmount;
    })
  );
};

/**
 *
 * @param {SpendingCategory[]} categories the SpendingCategories to calculate against
 * @returns an array of allocatedFunds values
 */
export const mapFunds = (categories: SpendingCategory[]): number[] => {
  return map(categories, prop('allocatedFunds'));
};

/**
 * @param records array of AnnualSpendingCategory
 * @returns an array of allocatedFunds values
 */
export const calcFunds = compose(sumNumbers, mapFunds);

export const getSpendingCategory = curry(
  (
    categoryId: number,
    categories: SpendingCategory[] | VoucherSpendingCategory[]
  ) => {
    if (!categories) return [];
    return categories.filter(
      (item: SpendingCategory | VoucherSpendingCategory) => {
        return item.categoryId === categoryId;
      }
    );
  }
);

export const handleMoneyInput = (value: any) => {
  const funds = ((value as unknown) as string).replace(',', '');
  return Number(funds);
};

export const isAdminCategory = (category: SpendingCategory) => {
  if (!category) return false;
  return some([
    category.categoryId === 7,
    category.categoryId === 8,
    category.categoryId === 9,
  ]);
};

export const isAdminExpense = (strategyId: number) => {
  return strategyId < 0;
};

// isFundPoolLead :: SpendingCategory -> bool
export const isFundPoolLead = converge(allParamsTrue, [
  prop('isFundPool'),
  prop('isPartnershipLead'),
]);

// isNotFundPoolLead :: SpendingCategory -> bool
export const isNotFundPoolLead = converge(allParamsTrue, [
  prop('isFundPool'),
  compose(isFalse, prop('isPartnershipLead')),
]);

// sumTotalFundPool :: SpendingCategory -> number
export const sumTotalFundPool = converge(add, [
  prop('partnershipInstituteContribution'),
  prop('allocatedFunds'),
]);

// calcRemainingFundPool :: SpendingCategory -> number
export const calcRemainingFundPool = converge(subtract, [
  sumTotalFundPool,
  getCategoryExpenses,
]);

// getCategoryFundsRemaining :: SpendingCategory -> number
export const getSpendingCategoryFundsRemaining = firstMatch([
  [isFundPoolLead, calcRemainingFundPool],
  [isNotFundPoolLead, () => 0],
  [() => true, getAllocatedFundsRemaining],
]);

// ------------------------------------
//      SpendingPlan Helpers
// ------------------------------------

export const getMinImprovementAllocation = (spendingPlan: AnnualSpending) => {
  const total = add(
    spendingPlan.basicGrantFunds,
    spendingPlan.reserveGrantFunds
  );
  return Math.ceil(total * 0.2);
};

export const checkForAdminCategories = (spendingPlan: AnnualSpending) => {
  let adminCats = {
    needsAssessment: false,
    indirect: false,
    direct: false,
  };
  const { spendingCategories: categories } = spendingPlan;
  return categories.reduce((acc: any, item: SpendingCategory) => {
    if (item.categoryId === 7) {
      acc.indirect = true;
    }
    if (item.categoryId === 8) {
      acc.direct = true;
    }
    if (item.categoryId === 9) {
      acc.needsAssessment = true;
    }
    return acc;
  }, adminCats);
};

export const normalizeSpendingCategories = (
  spendingPlan: AnnualSpending
): AnnualSpending => {
  const adminCats = checkForAdminCategories(spendingPlan);
  let spendingCategories = [...spendingPlan.spendingCategories];

  if (!adminCats.needsAssessment) {
    spendingCategories = [
      ...spendingCategories,
      defaultNeedsAssessmentCosts(spendingPlan.annualSpendingPlanId),
    ];
  }
  if (!adminCats.indirect) {
    spendingCategories = [
      ...spendingCategories,
      defaultIndirectAdministrativeCosts(spendingPlan.annualSpendingPlanId),
    ];
  }
  if (!adminCats.direct) {
    spendingCategories = [
      ...spendingCategories,
      defaultDirectAdministrativeCosts(spendingPlan.annualSpendingPlanId),
    ];
  }

  return {
    ...spendingPlan,
    spendingCategories,
  };
};

/**
 * calculates difference between totalAvailable and totalAllocated
 * @returns available remaining funds as a number
 */
export const getRemainingFunds = (spendingPlan: AnnualSpending) => {
  const totalAllocated = getAllocatedFunds(spendingPlan.spendingCategories);
  const totalAvailable = sumNumbers([
    spendingPlan.basicGrantFunds,
    spendingPlan.reserveGrantFunds,
  ]);
  return subtract(totalAvailable, totalAllocated);
};

/**
 * Available Direct Funds = (Initial Award Estimate*5%) - Allocated Indirect Funds
 * @returns available direct funds as a number
 */
export const calculateAvailableDirectFunds = (plan: AnnualSpending) => {
  const [cat] = getSpendingCategory(7, plan.spendingCategories)!;
  return subtract(
    add(plan.basicGrantFunds, plan.reserveGrantFunds) * 0.05,
    cat.allocatedFunds
  );
};

/**
 * Available Indirect Funds = (Initial Award Estimate - Total Equipment Expense) * Negotiated Indirect Rate
 * @returns available direct funds as a number
 */
export const calculateAvailableIndirectFunds = (plan: AnnualSpending) => {
  const total = add(plan.basicGrantFunds, plan.reserveGrantFunds);
  const diff = subtract(total, getEquipmentExpenses(plan.spendingCategories));
  return diff * plan.indirectRate;
};

// ------------------------------------
//           Expense Helpers
// ------------------------------------
export const getOffsetAmount = (
  expenseState: Expense,
  spendingCategory: SpendingCategory
): number => {
  const { expenseId } = expenseState;
  const currentExpense = find(spendingCategory.expenses, { expenseId });

  // this calculates the amount that has changed since the previous save
  const getAmountDiff = (expenseState: Expense) => {
    const offset = subtract(
      expenseState.budgetedAmount,
      currentExpense?.budgetedAmount
    );
    return offset * -1;
  };

  // existingBackupExpense :: Expense -> bool
  const existingBackupExpense = both(
    () => isTruthy(currentExpense),
    prop('isBackupExpense')
  );

  // existingPrimaryExpense :: () -> bool
  const existingPrimaryExpense = () => isTruthy(currentExpense);

  // newBackupExpense :: expenseState -> bool
  const newBackupExpense = prop('isBackupExpense');

  // newPrimaryExpense :: () -> true
  // acts as the default case in calculateOffset
  const newPrimaryExpense = () => true;

  // getBudgetedAmount :: expenseState -> number
  const getBudgetedAmount = prop('budgetedAmount');

  // invertBudgetedAmount :: expenseState -> number
  const invertBudgetedAmount = compose(
    multiply(-1),
    defaultTo(0),
    getBudgetedAmount
  );

  // calculateOffset :: expenseState -> number
  const calculateOffset = firstMatch([
    [existingBackupExpense, getBudgetedAmount],
    [existingPrimaryExpense, getAmountDiff],
    [newBackupExpense, () => 0],
    [newPrimaryExpense, invertBudgetedAmount],
  ]);

  return calculateOffset(expenseState);
};
