import { curry, isNil, reduce } from 'lodash';
import { prop } from './objectUtility';

export const add = curry((n1: any, n2: any) => {
  const result = (n1 * 100 + n2 * 100) / 100;
  return Number(result.toFixed(2));
});

export const subtract = curry((n1: any, n2: any) => {
  const result = (n1 * 100 - n2 * 100) / 100;
  return Number(result.toFixed(2));
});

export const sumNumbers = (numbers: number[]): number => {
  return reduce(numbers, add, 0);
};

export const addTotals = curry(
  (property: string, prev: number, currentItem: any) => {
    return add(prev, prop(property, currentItem));
  }
);

export const sumCollection = curry(
  (reducerFunction: (prev: any, curr: any) => number, collection: any[]) => {
    return reduce(collection, reducerFunction, 0);
  }
);

export const getPercent = (item: number, places: number = 2) => {
  return `${(item * 100).toFixed(places)}%`;
};

export const round = curry((places: number, item: number) => {
  return isNil(item) || typeof item === 'string' ? item : item.toFixed(places);
});
