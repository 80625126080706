import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from '..';
import { ProgramBase, Program } from 'types';

const PROGRAM_TEMPLATE_ENDPOINT = 'api/ProgramBases/';
const PROGRAM_CLONE_ENDPOINT = `${PROGRAM_TEMPLATE_ENDPOINT}clone/`;

export const createProgramBase = async (program: ProgramBase) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_TEMPLATE_ENDPOINT}`;
  program.createdDate = new Date(Date.now());
  program.modifiedDate = new Date(Date.now());
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(program),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramBases', err);
    });
}; // end function

export const readProgramBases = async () => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_TEMPLATE_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramBases', err);
    });
}; // end fetchPrograms

export const readProgramBase = async (id: number) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_TEMPLATE_ENDPOINT}${id}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramBases', err);
    });
}; // end fetchPrograms

export const cloneProgram = async (program: Program) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_CLONE_ENDPOINT}${
    program.programInstanceId
  }`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(program),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramBases', err);
    });
}; // end cloneProgram
