import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState, useRef } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SignatureWithAssertionComponent from 'components/Common/SignatureWithAssertion';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { FlexRow } from 'layouts';
import { SignatureWithAssertion } from 'types';
import { SignatureWithAssertionValidations } from 'validations/AwardPacket/SignatureWithAssertions.validations';
import { every } from 'lodash';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { safeGet, prop, mergeObjects } from 'util/objectUtility';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

type SignaturesProps = {
  ctaSignatures: SignatureWithAssertion[];
  loadCTASignatures: Function;
  locked: boolean;
  saveCTASignatures: Function;
};

export const SignaturesComponent: React.FC<SignaturesProps> = (props) => {
  const { ctaSignatures, loadCTASignatures, locked, saveCTASignatures } = props;

  // --[ dependencies ]--------------------------------------------------------
  const mountedRef = useRef(true);
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const { validate } = SignatureWithAssertionValidations();
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [dirtyData, setDirtyData] = useState<any>();
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [signatureState, setSignatureState] = useState<
    SignatureWithAssertion[]
  >([]);
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const handleChange = (signature: SignatureWithAssertion) => (
    data: SignatureWithAssertion
  ) => {
    setDirtyData(true);
    const signatureId = prop('signatureId', data);
    const updated = signatureState.map((sig: SignatureWithAssertion) => {
      return sig.signatureId === signatureId
        ? mergeObjects(signature, data)
        : sig;
    });
    setSignatureState(updated);
  };
  const validateSignatures = () => {
    return every([
      ...signatureState.map((sig: SignatureWithAssertion) => {
        return validate('canSave', sig);
      }),
    ]);
  };
  const saveChanges = () => {
    toggleHasRequested();
    const canSave = validateSignatures();
    if (canSave) {
      saveCTASignatures(ctaId, signatureState)
        .then(() => setDirtyData(false))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTASignatures(ctaId).catch((err: any) => console.error(err));
    }
  }, [wasOpened, loadCTASignatures, ctaId]);

  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  useEffect(() => {
    setSignatureState(ctaSignatures);
  }, [ctaSignatures]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // --[ display logic ]-------------------------------------------------------
  const assertionString =
    'I certify that I have reviewed this document and related supporting documentation. All costs reported herein are allowed under the CTA Act, all costs are supported by adequate documentation, and these costs have not been reimbursed by any other funding source.';

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-signatures"
        locked={locked}
        header="Signatures"
        onChange={updateOpenedState}
      >
        <SecureWrap component={['ctageneral']} isLocked={readOnly}>
          <Paper>
            <Instruction title="" hideTitle>
              <p>
                Sign below with your name, title, and date in the appropriate
                signature line for your role. Click on the ‘Save’ button to
                proceed to the next section.
              </p>
              <p>
                <span className="u-color-red">Note</span>: The signatures will
                be cleared any time the CCCS CTA Manager sends the report back
                requesting information from the district or charter school. This
                means that both signature lines will need to be re-signed by the
                appropriate individuals prior to submitting the changes.
              </p>
            </Instruction>
            {signatureState.map((signature: SignatureWithAssertion) => {
              const getSig = safeGet(signature);
              return (
                <SignatureWithAssertionComponent
                  assertionString={assertionString}
                  key={getSig('signatureId')}
                  onChange={handleChange(signature)}
                  signature={signature}
                />
              );
            })}
            <FlexRow>
              <div className="u-flex-grow-1" />
              <Button
                className={dirtyData ? 'button--filled' : 'button--outline'}
                disabled={hasRequested}
                onClick={saveChanges}
                style={{
                  alignSelf: 'flex-end',
                  height: '5.6rem',
                  width: '28rem',
                }}
                text="Save Signatures"
              />
            </FlexRow>
          </Paper>
        </SecureWrap>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
