import React, { useEffect } from 'react';
import { useOnChange } from 'hooks';
import { useParams } from 'react-router';
import { Voucher, emptyVoucher } from 'types';
import { toast } from 'react-toastify';
import { every, map } from 'lodash';
import { SignatureComponentValidations } from 'validations/SignatureComponent.validations';
import { VouchersSubmitValidations } from 'validations/Vouchers/VouchersSubmit.validations';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ReimbursementRequestSummary from '../ReimbursementRequestSummary';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import SupportingDocuments from './SupportingDocuments';
import ToastNotification from 'components/Common/Toast';
import TotalActualReimbursementTables from '../TotalActualReimbursementTables';
import VoucherSpendingCategoryEdit from './SpendingCategoryEdit';
import VoucherAuthSignatureComponent from './AuthSignature';
import StringResources from 'StringResources';

type VoucherDetailComponentProps = {
  isLoading: boolean;
  isLocked: boolean;
  loadGrantVoucherDetailNav: Function;
  loadSingleVoucherByType: Function;
  saveVoucher: Function;
  setCurrentNavAnchor: Function;
  voucherId: number;
  voucher: Voucher;
};

export const VoucherDetailComponent: React.FC<VoucherDetailComponentProps> = (
  props
) => {
  const {
    isLoading,
    isLocked,
    loadGrantVoucherDetailNav,
    loadSingleVoucherByType,
    saveVoucher,
    setCurrentNavAnchor,
    voucher,
    voucherId,
  } = props;

  const { grantFiscalYearId: gfy, voucherType }: any = useParams();
  const grantFiscalYearId = Number(gfy);

  const [voucherState, setVoucherState, onChange] = useOnChange<Voucher>(
    emptyVoucher()
  );

  const v = VouchersSubmitValidations();
  const {
    validateCustom: validateAllVouchers,
    validationErrors: voucherErrors,
  } = v;
  const validateVoucherType = () => {
    switch (voucherType) {
      case 'voucher-one':
        return [
          { key: 'adminCosts', value: voucherState },
          { key: 'canSubmitVoucherOne', value: voucherState },
          { key: 'certifyAllExpenditures', value: voucherState },
          { key: 'indirectAdminCost', value: voucherState },
          { key: 'isVoucherSigned', value: voucherState },
          { key: 'isVoucherSubmitDateValid', value: voucherState },
          { key: 'totalExpenses', value: voucherState },
          { key: 'improvementPlan', value: voucherState },
        ];
      case 'voucher-two':
      case 'voucher-three':
        return [
          { key: 'adminCosts', value: voucherState },
          { key: 'canSubmitVouchersTwoOrThree', value: voucherState },
          { key: 'certifyAllExpenditures', value: voucherState },
          { key: 'indirectAdminCost', value: voucherState },
          { key: 'isVoucherSigned', value: voucherState },
          { key: 'isVoucherSubmitDateValid', value: voucherState },
          { key: 'totalExpenses', value: voucherState },
          { key: 'improvementPlan', value: voucherState },
        ];
      default:
        return [
          { key: 'adminCosts', value: voucherState },
          { key: 'certifyAllExpenditures', value: voucherState },
          { key: 'indirectAdminCost', value: voucherState },
          { key: 'isVoucherSigned', value: voucherState },
          { key: 'isVoucherSubmitDateValid', value: voucherState },
          { key: 'totalExpenses', value: voucherState },
          { key: 'improvementPlan', value: voucherState },
        ];
    }
  };

  const {
    validateAll: validateAllSignatures,
    validationErrors: signatureErrors,
  } = SignatureComponentValidations();

  const validateSignatures = (voucher: Voucher) => {
    const signatures = voucher.authSignatures;
    return every(
      map(signatures, (signature) => {
        return validateAllSignatures(signature);
      })
    );
  };

  const handleSave = () => {
    saveVoucher(grantFiscalYearId, voucherState).then(() => {
      loadSingleVoucherByType(grantFiscalYearId, voucherType).then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      });
    });
  };

  useEffect(() => {
    validateSignatures(voucherState);
  }, [voucherState]); // eslint-disable-line

  useEffect(() => {
    if (voucherType && voucherState) {
      validateAllVouchers(validateVoucherType() as any);
    }
  }, [grantFiscalYearId, voucherState, voucherType]); // eslint-disable-line

  useEffect(() => {
    setVoucherState(voucher);
  }, [voucher, setVoucherState, voucherId]);

  useEffect(() => {
    setCurrentNavAnchor('voucher-detail');
    loadSingleVoucherByType(grantFiscalYearId, voucherType);
    loadGrantVoucherDetailNav(grantFiscalYearId, voucherType);
  }, [
    setCurrentNavAnchor,
    loadSingleVoucherByType,
    grantFiscalYearId,
    voucherId,
    voucherType,
    loadGrantVoucherDetailNav,
  ]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isLoading} messageBefore="Loading Voucher..." />
      <Paper>
        <Instruction title={voucherState.voucherName}>
          <p>
            There are three steps to complete before you can submit the expenses
            you incurred for your project plan(s), your improvement plan (if
            applicable) and administrative costs on this voucher. First you’ll
            record your expenses. Second, you’ll provide supporting documents
            and finally you will certify that your expenses and documentation
            are valid. Voucher requests cannot exceed the total grant award. We
            recommend you record direct administrative cost expenses after you
            have recorded expenses for all the other categories.
          </p>
        </Instruction>
      </Paper>
      <Paper>
        <ReimbursementRequestSummary grantFiscalYearId={grantFiscalYearId} />
      </Paper>
      <Paper>
        <VoucherSpendingCategoryEdit
          onChange={onChange}
          voucher={voucherState}
          voucherType={voucherType}
        />
      </Paper>
      <Paper>
        <SecureWrap isLocked={isLocked} component={['GrantsVouchers']}>
          <SupportingDocuments onChange={onChange} voucher={voucherState} />
        </SecureWrap>
      </Paper>
      <Paper>
        <TotalActualReimbursementTables grantFiscalYearId={grantFiscalYearId} />
      </Paper>
      <Paper>
        <SecureWrap
          isLocked={isLocked}
          component={['GrantsVouchers', 'GrantsVouchersSignee']}
        >
          <VoucherAuthSignatureComponent
            onChange={onChange}
            voucher={voucherState}
            v={v}
          />
        </SecureWrap>
      </Paper>
      <SecureWrap
        isLocked={isLocked}
        component={['GrantsVouchers', 'GrantsVouchersSignee']}
      >
        <Paper>
          <SaveButtons
            alternateAction={handleSave}
            cancelUrl={`/grants/applications/vouchers/${grantFiscalYearId}`}
            cancelText="return to vouchers"
            saveText="Save Voucher"
            validationErrors={[...signatureErrors, ...voucherErrors]}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
