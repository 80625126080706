import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTACourse } from 'types';
import { readCTAContractingCourse } from 'services/cta/ctacourse.services';

const initialState = baseInitialState;

export const loadCTAContractingCourse = createActionThunk(
  'loadCTAContractingCourse',
  (ctaId: number, ctaHostingId: number) => {
    return readCTAContractingCourse(ctaId, ctaHostingId);
  }
);

export default handleActions<BaseStateObject, CTACourse[]>(
  {
    ...baseLoadActionBuilder<CTACourse[]>(loadCTAContractingCourse),
  },
  initialState
);
