import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FlexGroup, FlexRow } from 'layouts';
import {
  CTASupportPersonnel,
  emptyCTASupportPersonnel,
  emptyCTAFiscalYearProgramSupport,
} from 'types';
import { Modal, useModal } from 'react-morphing-modal';
import { safeGet, mergeObjects } from 'util/objectUtility';
import { SchoolProgramAffiliationForm } from './SchoolProgramAffiliation.form';
import { isEqual } from 'util/formHelpers';
import { useDispatch } from 'react-redux';
import { saveCTASupportPersonnel } from 'redux/cta/ctasupportpersonnel';
import { toast } from 'react-toastify';
import { SupportingPersonnelValidation } from 'validations/CTA/SupportingPersonnel.validations';
import { Link } from 'react-router-dom';
import Button from 'components/Common/FormElements/Button';
import DynamicForm from 'components/Common/DynamicForm';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import InputToolTip from 'components/Common/FormElements/InputToolTip';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type SupportingPersonnelModalProps = {
  supportPersonnel: CTASupportPersonnel;
  isNew?: boolean;
};

export const SupportingPersonnelModal: React.FC<SupportingPersonnelModalProps> = (props) => {
  const { supportPersonnel, isNew = false } = props;

  // -- dependencies --
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const dispatch = useDispatch();
  const { modalProps, open, close } = useModal();
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = SupportingPersonnelValidation();

  // --[ local states ]--------------------------------------------------------
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);
  const [supportPersonnelState, setSupportPersonnelState] = useState<CTASupportPersonnel>(
    emptyCTASupportPersonnel()
  );

  // --[ component logic ]-----------------------------------------------------
  const get = safeGet(supportPersonnelState);

  const onChange = (
    data: Partial<CTASupportPersonnel>,
    key: keyof CTASupportPersonnel,
    value: any
  ) => {
    const updated = mergeObjects(supportPersonnelState, data);
    validateIfTrue(key, value);
    setSupportPersonnelState(updated);
  };

  const handleCTASupportProgramAffiliationChange = (index: number) => (
    edited: CTASupportPersonnel
  ) => {
    const ctaFiscalYearProgramSupport = get('ctaFiscalYearProgramSupport').map(
      (item: CTASupportPersonnel, idx: number) => {
        return isEqual(index, idx) ? edited : item;
      }
    );
    const updated = mergeObjects(supportPersonnelState, {
      ctaFiscalYearProgramSupport,
    });
    validateIfTrue('ctaFiscalYearProgramSupport', ctaFiscalYearProgramSupport);
    setSupportPersonnelState(updated);
  };

  const addSchoolProgramAffiliation = () => {
    const ctaFiscalYearProgramSupport = [
      ...get('ctaFiscalYearProgramSupport'),
      emptyCTAFiscalYearProgramSupport(),
    ];
    const updated = mergeObjects(supportPersonnelState, {
      ctaFiscalYearProgramSupport,
    });
    setSupportPersonnelState(updated);
  };

  const deleteSchoolProgramAffiliation = (index: number) => {
    const ctaFiscalYearProgramSupport = get('ctaFiscalYearProgramSupport').filter(
      (_: any, idx: number) => {
        return index !== idx;
      }
    );
    const updated = mergeObjects(supportPersonnelState, {
      ctaFiscalYearProgramSupport,
    });
    setSupportPersonnelState(updated);
  };

  const handleSave = () => {
    const canSave = validateAll(supportPersonnelState);
    if (canSave) {
      dispatch(saveCTASupportPersonnel(ctaId, supportPersonnelState))
        .then(() => {
          toast(
            <ToastNotification status="success" message={StringResources.Success.DataSaved} />
          );
        })
        .then(() => setSubmitFailed(false))
        .then(() => close())
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        });
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification status="error" message={StringResources.Errors.ValidationFailed} />
      );
    }
  };

  // --[ lifecyle ]------------------------------------------------------------
  useEffect(() => {
    setSupportPersonnelState(supportPersonnel);
  }, [supportPersonnel]);

  // --[ display logic ]-------------------------------------------------------

  return (
    <React.Fragment>
      <div ref={modalRef}>
        {isNew ? (
          <Button
            text="+ Add Supporting Personnel"
            className="form__btn--add u-flex-self-start"
            onClick={() => open(modalRef)}
          />
        ) : (
          <IconButton name="pencil" tooltip="Edit" onClick={() => open(modalRef)} />
        )}
      </div>
      <Modal {...modalProps}>
        <div className="u-flex-center" style={{ width: '100%' }}>
          <Paper className="t-supporting-detail">
            <FlexRow>
              <h2 className="u-flex-grow-1">Supporting Personnel Costs</h2>
            </FlexRow>
            <FlexRow>
              <Instruction title="Personnel Details">
                <p>
                  Enter the information requested for the individual you would like to add,
                  including school and program affiliation below.
                </p>
              </Instruction>
            </FlexRow>
            <FlexRow>
              <Input
                errorMessage={getError('personnelName')}
                name="personnelName"
                onBlur={() => validate('personnelName', get('personnelName'))}
                onChange={onChange}
                valid={getFieldValid('personnelName')}
                value={get('personnelName')}
              />
              <Input
                errorMessage={getError('annualContractBaseSalary')}
                name="annualContractBaseSalary"
                onBlur={() =>
                  validate('annualContractBaseSalary', get('annualContractBaseSalary'))
                }
                onChange={onChange}
                tooltip={
                  <InputToolTip
                    tooltip="Enter the employee’s annual base salary (regular contract salary) for the reporting period (7/1 to 6/30). 
                  Do not include benefits, stipends, or extra duty pay. If district pay is based on an hourly rate, 
                  convert that rate to an annual basis according to your district's internal policies."
                  />
                }
                type="number"
                valid={getFieldValid('annualContractBaseSalary')}
                value={get('annualContractBaseSalary')}
              />
              {/* 100 < salary < 1000000 */}
              <Input
                errorMessage={getError('actualSalaryEarned')}
                name="actualSalaryEarned"
                onBlur={() => validate('actualSalaryEarned', get('actualSalaryEarned'))}
                onChange={onChange}
                tooltip={
                  <InputToolTip
                    place="right"
                    tooltip="Enter the employee’s actual base salary earned during the reporting period (7/1-6/30). 
                      Note that this should include the total amount earned during the period regardless of when it is paid. 
                      Do not include benefits, stipends, or extra duty pay."
                  />
                }
                type="number"
                valid={getFieldValid('actualSalaryEarned')}
                value={get('actualSalaryEarned')}
              />
            </FlexRow>
            <FlexRow>
              <FlexGroup>
                {/* char limit 3 */}
                <Input
                  errorMessage={getError('subsSickAnnual')}
                  label="Annual Sub Sick Days"
                  name="subsSickAnnual"
                  onBlur={() => validate('subsSickAnnual', get('subsSickAnnual'))}
                  onChange={onChange}
                  tooltip={
                    <InputToolTip
                      tooltip="Enter the number of whole or partial days of a non-CTE related absence for which a 
                      substitute was compensated. Note that CTE-related absences will be reported in the next field."
                    />
                  }
                  type="number"
                  valid={getFieldValid('subsSickAnnual')}
                  value={get('subsSickAnnual')}
                />
              </FlexGroup>
              <FlexGroup>
                {/* char limit 3 */}
                <Input
                  errorMessage={getError('subsCteAbsenses')}
                  label="Sub Days for CTE Absences"
                  name="subsCteAbsenses"
                  onBlur={() => validate('subsCteAbsenses', get('subsCteAbsenses'))}
                  onChange={onChange}
                  tooltip={
                    <InputToolTip
                      place="right"
                      tooltip="Enter the number of whole or partial days of CTE-related absences for which a substitute was compensated 
                      (e.g., travel to a CTSO or CTE class field trip). Ensure documentation supports that the absence was CTE related."
                    />
                  }
                  type="number"
                  valid={getFieldValid('subsCteAbsenses')}
                  value={get('subsCteAbsenses')}
                />
              </FlexGroup>
            </FlexRow>
            <FlexRow>
              <Instruction title="School and Program Affiliations">
                <p>
                  Enter the information requested for the individual noted above. Use the
                  drop-down menu and select the appropriate school and program affiliation.
                  Next enter the percentage of time the individual provided CTE support at the
                  school and add any brief explanatory notes in the ‘CTE Memo’ field. Click on
                  ‘Add an Affiliation’ if you need to enter an additional school for this
                  employee. Save your response to return to the ‘Supporting Personnel Costs’
                  section.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: If a program needs to be added to
                  the dropdown, have the person with the student record permission enter
                  student enrollment information in the{' '}
                  <Link rel="noreferrer" target="_blank" to="/data-collections">
                    Data Collection
                  </Link>{' '}
                  module. You will not be able to select a school and program affiliation until
                  the day after the program was reported on in Data Collections, allowing time
                  for the database to update.
                </p>
              </Instruction>
            </FlexRow>
            <FlexRow>
              <FlexGroup>
                <DynamicForm
                  addButtonText="+ Add an Affiliation"
                  addForm={addSchoolProgramAffiliation}
                  form={SchoolProgramAffiliationForm}
                  items={get('ctaFiscalYearProgramSupport')}
                  onChange={handleCTASupportProgramAffiliationChange}
                  removeForm={deleteSchoolProgramAffiliation}
                  submitFailed={submitFailed}
                />
              </FlexGroup>
            </FlexRow>
            <SaveButtons
              alternateAction={handleSave}
              disabled={!isValid}
              onCancel={() => {
                setSupportPersonnelState(emptyCTASupportPersonnel());
                close();
              }}
              validationErrors={validationErrors}
            />
          </Paper>
        </div>
      </Modal>
    </React.Fragment>
  );
};
