import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const CAREER_CLUSTER_ENDPOINT = 'api/CareerClusters/';

export const readCareerClusters = async () => {
  const url = `${ENVIRONMENT.API_URL}${CAREER_CLUSTER_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CareerClusters', err);
    });
}; // end fetchCIPCodes
