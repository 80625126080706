import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  getFormattedUrl,
} from 'services';
import { Workflow, WorkflowSubmission, AdminOption } from 'types';

const BASE_PROGRAM_APPROVALS = ['api', 'ProgramApprovals'];
const PROGRAM_APPROVALS_ENDPOINT = 'api/ProgramApprovals/';
const GET_APPROVALS_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}GetUIWorkflow/`;
const SUBMIT_APPROVAL_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}programapprovalsubmit/`;
const POST_APPROVAL_ENDPOINT = `${PROGRAM_APPROVALS_ENDPOINT}postprogramapproval/`;

/**
 * reads admin options
 * @param programInstanceId program instanceid to return steps for
 */
export const readProgramApprovalSteps = async (
  programInstanceId: number
): Promise<Workflow> => {
  const url = getFormattedUrl([
    ...BASE_PROGRAM_APPROVALS,
    'GetProgramApprovalSteps',
    programInstanceId,
  ]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *
 */
export const readProgramApprovals = async (
  programInstanceId: number
): Promise<Workflow> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${GET_APPROVALS_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *
 */
export const readWorkflowChangeLog = async (
  entityType: string,
  entityId: number
) => {
  const url = getFormattedUrl(['api', 'changelog', entityType, entityId]);
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *
 */
export const createWorkflowApproval = async (
  programInstanceId: number,
  comment: string
): Promise<Workflow> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${SUBMIT_APPROVAL_ENDPOINT}${programInstanceId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ comment }),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *
 */
export const createWorkflowUpdate = async (
  programInstanceId: number,
  submission: WorkflowSubmission
): Promise<Workflow> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${POST_APPROVAL_ENDPOINT}${programInstanceId}/${submission.optionId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ comment: submission.comment }),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 *"PostAdminApproval/{programInstanceId}
 */
export const createProgramAdminWorkflowUpdate = async (
  programInstanceId: number,
  submission: AdminOption
): Promise<Workflow> => {
  const url = getFormattedUrl([
    ...BASE_PROGRAM_APPROVALS,
    'PostAdminApproval',
    programInstanceId,
  ]);
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(submission),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};
