import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { FlexGroup } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import { loadChangelog } from 'redux/generalworkflow/changelog/_reducer';
import {
  getRecords_Changelog,
  getIsDataFetching_Changelog,
} from 'redux/generalworkflow/changelog/_selectors';
import { display, getDate, compose, prop, either } from 'util/objectUtility';
import ChangeHistory from '../ChangeHistory';
import Icon from 'components/Common/Icons';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';

export const WorkflowDiffComponent: React.FC = () => {
  const { entityType, entityId: id }: any = useParams();
  const entityId = Number(id);

  const [data] = useSelector(getRecords_Changelog(entityType));
  const isFetching = useSelector(getIsDataFetching_Changelog(entityType));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadChangelog(entityType, entityId));
  }, [entityType, entityId]); //eslint-disable-line

  const date = compose(
    either(getDate, () => null),
    prop('dateOfChanges')
  );

  const render = display(data);

  return (
    <Paper>
      <ScrollToTop />
      <Loading
        isActive={isFetching}
        messageBefore="Loading Change History. This will take a few minutes."
      />
      <div className="history__header">
        <h2 className="u-section-title">Change History</h2>
      </div>
      {data ? (
        <React.Fragment>
          <div className="history">
            <FlexGroup>
              <p>{date(data)}</p>
              <p>
                Number of Changes:&nbsp;
                <span className="">{render('changeCount')}</span>
              </p>
            </FlexGroup>
            <div className="history__instructions">
              <p className="history__instruction-item">
                <Icon
                  name="plus"
                  className="history__change-icon--instruction"
                />
                represents additions made.
              </p>
              <p className="history__instruction-item">
                <Icon
                  name="minus"
                  className="history__change-icon--instruction"
                />
                represents removals made.
              </p>
            </div>
          </div>
          {data && <ChangeHistory historyData={data} />}
        </React.Fragment>
      ) : (
        <p>No changes have been made</p>
      )}
    </Paper>
  );
};
