import { useValidation } from 'de-formed-validations';
import { get } from 'lodash';

export const CTAWorkflowSubmissionValidations = () => {
  return useValidation({
    selectedAmount: [
      {
        errorMessage: 'You must select a submission amount',
        validation: (value: boolean | null) => {
          const optimized = get(value, 'optimized', null);
          return optimized === true || optimized === false;
        },
      },
    ],
  });
};
