import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readCTAFiscalYears } from 'services/cta/ctafiscalyear.services';
import { FiscalYear } from 'types';

const initialState = baseInitialState;

export const loadCTAFiscalYears = createActionThunk(
  'loadCTAFiscalYears',
  () => {
    return readCTAFiscalYears();
  }
);

export default handleActions<BaseStateObject, FiscalYear[]>(
  {
    ...baseLoadActionBuilder<FiscalYear[]>(loadCTAFiscalYears),
  },
  initialState
);
