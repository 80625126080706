import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, CTAFilterState } from 'types';

const initialState: CTAFilterState = {
  year: undefined,
  archived: undefined,
  needsReview: undefined,
  notStarted: undefined,
  status: undefined,
  firstSubmission: undefined,
};

export const setCTAFilterValue = createAction('setCTAFilterValue');
export const resetCTAFilterValue = createAction('resetCTAFilterValue');
export const clearCTAFilters = createAction('clearCTAFilters');

export default handleActions<CTAFilterState, KeyValueType<number> | string>(
  {
    setCTAFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetCTAFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: undefined,
      };
    },
    clearCTAFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
