import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { SnapshotStudentFilterState, UniversalContact, School } from 'types';
import { getActiveUserSchools } from 'util/universalcontact.utility';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import { isSuperAdmin } from 'util/permissionsUtility';

type StudentEnrollmentFilterProps = {
  loggedInUser: UniversalContact;
  adminschools: School[];
  filter: SnapshotStudentFilterState;
  setSnapshotStudentValue: Function;
  clearSnapshotStudentFilters: Function;
  resetSnapshotStudentValue: Function;
};

export const StudentEnrollmentFilterComponent: React.FC<
  StudentEnrollmentFilterProps
> = (props) => {
  const {
    loggedInUser,
    adminschools,
    filter,
    setSnapshotStudentValue,
    resetSnapshotStudentValue,
  } = props;

  const updateFilter = (
    data: { [key: string]: unknown },
    name: string,
    value: number
  ) => {
    if (value) {
      setSnapshotStudentValue(data);
    } else {
      resetSnapshotStudentValue(name);
    }
  };

  return (
    <FlexGroup>
      <FlexRow>
        <SchoolSelectBox
          records={
            isSuperAdmin(loggedInUser)
              ? adminschools
              : getActiveUserSchools(loggedInUser)
          }
          clearable={true}
          shouldReset={true}
          onChange={(data: any, name: any, value: any) => {
            updateFilter(data, name, value);
          }}
          selection={filter.schoolId > 0 ? filter.schoolId : undefined}
        />
        <FlexGroup />
        <FlexGroup />
      </FlexRow>
    </FlexGroup>
  );
};
