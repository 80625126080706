import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FlexRow, FlexGroup } from 'layouts';
import { find } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { mergeObjects } from 'util/objectUtility';
import { PathwayCourse, ProgramPathway, emptyPathwayCourse } from 'types';
import { createTempId } from 'util/formHelpers';
import { PathwayCourseValidation } from 'validations/PathwayCourse.validation';
import { CollapsibleList } from '@rmwc/list';
import CourseSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CourseSelectBox';
import CourseLevelSelectBox from 'components/Common/SelectBoxes/CourseLevelSelectBox';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ProgramsAdvancedCreditOptions from 'components/Program/AdvancedCreditOptions';
import ScrollToTop from 'components/Common/ScrollToTop';
import SaveButtons from 'components/Common/SaveButtons';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';

type SecondaryCourseEditProps = {
  course: PathwayCourse;
  loadProgramPathway: Function;
  programCareerPathwayId: number;
  programPathways: ProgramPathway[];
  updateProgramPathwayCache: Function;
};

export const SecondaryCourseEdit: React.FC<SecondaryCourseEditProps> = (
  props
) => {
  const {
    course,
    loadProgramPathway,
    programPathways,
    updateProgramPathwayCache,
  } = props;

  const validations = PathwayCourseValidation();
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = validations;

  const {
    programInstanceId: pId,
    programCareerPathwayId: pcId,
    courseId: cId,
  }: any = useParams();

  const programInstanceId = Number(pId);
  const programCareerPathwayId = Number(pcId);
  const courseId = Number(cId);

  const [courseState, setCourseState] = useState<PathwayCourse>({
    ...emptyPathwayCourse(),
  });

  const [isNewCourse, setIsNewCourse] = useState(false);

  useEffect(() => {
    setCourseState(course);
  }, [setCourseState, course]);

  useEffect(() => {
    courseId === 0 ? setIsNewCourse(true) : setIsNewCourse(false);
  }, [courseId, setIsNewCourse]);

  useEffect(() => {
    loadProgramPathway(programInstanceId);
  }, [loadProgramPathway, programInstanceId]);

  const onFormChange = (data: Partial<PathwayCourse>, key: any, value: any) => {
    const newState = mergeObjects<PathwayCourse>(courseState, data);
    if (key === 'isAcademicCredit' && !value) {
      validate('academicCredit', newState.academicCredit, newState);
    }
    validateIfTrue(key, value, newState);
    setCourseState(newState);
  };

  const handleSave = async (): Promise<any> => {
    // turn isAdvancedCreditOption toggle off if empty
    if (
      !courseState.isApprovedForArticulationAgreement &&
      !courseState.isConcurrentEnrollment
    ) {
      courseState.isAdvancedCreditOption = false;
    }

    // run validations
    if (!validateAll(courseState)) {
      return Promise.reject(
        'Not all validations passed, please complete the form and check for errors.'
      );
    }
    return saveCourse(courseState);
  };

  const saveCourse = (courseData: PathwayCourse) => {
    const currentPath = find(programPathways, { programCareerPathwayId });
    if (!currentPath) return;
    courseData.programCareerPathwayId = programCareerPathwayId;
    courseData.optIn = true;
    if (isNewCourse) {
      courseData.courseId = createTempId(currentPath.courses, 'courseId');
    }
    currentPath.courses = upsertInPlace(
      currentPath.courses,
      courseData,
      'courseId'
    );
    const updatedCache = upsertInPlace(
      programPathways,
      currentPath,
      'programCareerPathwayId'
    );
    updateProgramPathwayCache(updatedCache);
    return Promise.resolve();
  };

  const instructionTitle = () => {
    let title: string;
    if (course.isMiddleSchool) {
      title = isNewCourse
        ? 'Create a New Middle School Course'
        : 'Edit Middle School Course';
    } else {
      title = isNewCourse
        ? 'Create a New Secondary Course'
        : 'Edit Secondary Course';
    }
    return title;
  };

  const buildCourseLevelDOM = () => {
    if (course.isMiddleSchool) return null;
    if (isNewCourse) {
      return (
        <FlexGroup
          onBlur={() => {
            validate('courseLevel', courseState.courseLevel, courseState);
          }}
        >
          <CourseLevelSelectBox
            name="courseLevel"
            selection={courseState.courseLevel}
            onChange={onFormChange}
            valid={getFieldValid('courseLevel')}
          />
          <div className="u-color-red">
            {getError('courseLevel') && getError('courseLevel')}
          </div>
        </FlexGroup>
      );
    }
    return (
      <Input
        name="courseLevelId"
        label="Course Level"
        disabled={true}
        value={courseState.courseLevel}
        onChange={onFormChange}
      />
    );
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <Paper>
        <Instruction title={instructionTitle()}>
          {isNewCourse && !course.isMiddleSchool ? (
            <React.Fragment>
              <p className="u-margin-bottom-tiny">
                To create a new course you can search for a pre-existing course,
                create one from scratch, or edit a pre-existing course for this
                career pathway.
              </p>
              <p className="u-margin-bottom-tiny">
                If this course has advanced credit options and/or offers
                academic credit, please provide the necessary additional
                information.
              </p>
            </React.Fragment>
          ) : (
            <p>Please make any necessary changes for your course below.</p>
          )}
          <p>
            Course contact hours and description are both required. Once
            complete, click "Save Course" to return to the career pathway and
            courses overview. Please contact this program area’s Program
            Director if you are interested in adding a non-standard course to a
            pathway.
          </p>
        </Instruction>
        {isNewCourse && !course.isMiddleSchool && (
          <React.Fragment>
            <FlexGroup>
              <label className="form__label">
                Search for a Pre-Existing Course
              </label>
              <FlexRow>
                <CourseSelectBox onChange={onFormChange} />
              </FlexRow>
            </FlexGroup>
          </React.Fragment>
        )}
        <FlexRow>
          <Input
            name="courseName"
            label="Course Title"
            disabled={!isNewCourse}
            value={courseState.courseName}
            onChange={onFormChange}
            errorMessage={getError('courseName')}
            onBlur={() => {
              validate('courseName', courseState.courseName, courseState);
            }}
            valid={getFieldValid('courseName')}
          />
          {buildCourseLevelDOM()}
          <Input
            name="contactHours"
            value={courseState.contactHours}
            onChange={onFormChange}
            errorMessage={getError('contactHours')}
            onBlur={() => {
              validate('contactHours', courseState.contactHours, courseState);
            }}
            valid={getFieldValid('contactHours')}
            type="number"
            min={0}
            max={500000000}
          />
        </FlexRow>
        {!isNewCourse && (
          <FlexRow>
            <Input
              name="preferredTitle"
              label="Preferred Title For Local Use (Optional, 50 characters max.)"
              value={courseState.preferredTitle}
              onChange={onFormChange}
            />
          </FlexRow>
        )}
        <FlexRow>
          <Textarea
            name="courseDescription"
            value={courseState.courseDescription}
            onChange={onFormChange}
            errorMessage={getError('courseDescription')}
            onBlur={() => {
              validate(
                'courseDescription',
                courseState.courseDescription,
                courseState
              );
            }}
            valid={getFieldValid('courseDescription')}
          />
        </FlexRow>
      </Paper>

      {!course.isMiddleSchool && (
        <React.Fragment>
          <ProgramsAdvancedCreditOptions
            course={courseState}
            onChange={onFormChange}
            validations={validations}
          />
          <Paper>
            <FlexRow>
              <h2>Academic Credit</h2>
            </FlexRow>
            <FlexRow>
              <Toggle
                name="isAcademicCredit"
                noLabel={true}
                value={courseState.isAcademicCredit}
                description="Students completing this course earn Academic Credit (optional)"
                onChange={onFormChange}
              />
            </FlexRow>
            <CollapsibleList
              handle={<div />}
              open={courseState.isAcademicCredit}
            >
              <FlexRow>
                <Textarea
                  name="academicCredit"
                  label="Describe this Course’s Academic Credit"
                  value={courseState.academicCredit}
                  onChange={onFormChange}
                  onBlur={() => {
                    validate(
                      'academicCredit',
                      courseState.academicCredit,
                      courseState
                    );
                  }}
                  errorMessage={getError('academicCredit')}
                  valid={getFieldValid('academicCredit')}
                />
              </FlexRow>
            </CollapsibleList>
          </Paper>
        </React.Fragment>
      )}
      <Paper>
        <SaveButtons
          cancelUrl={`/programs/courses/${programInstanceId}`}
          disabled={!isValid}
          errorMessage="Please check all validations."
          saveData={handleSave}
          saveText="Save Course"
          saveUrl={`/programs/courses/${programInstanceId}`}
          validationErrors={validationErrors}
        />
      </Paper>
    </React.Fragment>
  );
};
