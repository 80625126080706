import { connect } from 'react-redux';
import {
  getIsDataFetching_Institutes,
  getInstitutesWithBusinesses,
  INSTITUTES_PATH,
  getRecords_Institutes,
} from 'redux/common/_selectors';
import { Institute, OptionMap, InstituteSelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: InstituteSelectWrapper) => {
  const {
    filter,
    selection,
    label,
    name,
    onChange,
    includeBusinesses,
    records: baseRecords,
  } = ownProps;

  const isLoading = getIsDataFetching_Institutes(state);

  const withInstitutes = getInstitutesWithBusinesses(state, filter);
  const institutes = getRecordsUtility<Institute>(
    state,
    INSTITUTES_PATH,
    getRecords_Institutes,
    filter,
    baseRecords
  );

  const records = includeBusinesses ? withInstitutes : institutes;

  const optionMap: OptionMap<Institute> = {
    key: 'schoolId',
    value: 'schoolId',
    label: 'schoolName',
  };

  return {
    isLoading,
    label: label ? label : 'Institution',
    name: name ? name : 'schoolId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
