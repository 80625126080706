import React, { useEffect, useState } from 'react';
import { CourseCreditAward } from 'types';
import { FlexGroup } from 'layouts';
import { map, get } from 'lodash';
import Button from 'components/Common/FormElements/Button';
import CourseTypeSelectBox from 'components/Common/SelectBoxes/CourseTypeSelectBox';
import CreditInput from 'components/Common/FormElements/CreditInput';
import Input from 'components/Common/FormElements/Input';
import { CourseCreditCustom } from 'types/program/CourseCreditCustom.types';

type CustomInputRowProps = {
  course: CourseCreditCustom;
  onChange: Function;
  onDelete: Function;
};

export const CustomInputRowComponent: React.FC<CustomInputRowProps> = (
  props
) => {
  const { course, onChange, onDelete } = props;
  const [courseState, setCourseState] = useState<CourseCreditCustom>(course);

  useEffect(() => {
    setCourseState(course);
  }, [course, setCourseState]);

  const updateCourse = (item: Partial<CourseCreditCustom>) => {
    const newcourse = { ...courseState, ...item };
    onChange(newcourse);
  };

  const updateCredits = (
    item: Partial<CourseCreditAward>,
    programAwardId: number
  ) => {
    const awards = map(
      courseState.awards,
      (award): CourseCreditAward => {
        return Number(award.programAwardId) === Number(programAwardId)
          ? { ...award, ...item }
          : award;
      }
    );
    updateCourse({ awards });
  };
  return (
    <div style={{ marginBottom: '1.8rem' }}>
      <div className="u-flex">
        <Input
          name="courseName"
          value={get(courseState, 'courseName', '')}
          onChange={updateCourse}
        />
        <Input
          name="coursePrefix"
          value={get(courseState, 'coursePrefix', '')}
          onChange={updateCourse}
        />
        <Input
          name="courseNumber"
          value={get(courseState, 'courseNumber', '')}
          onChange={updateCourse}
        />
      </div>
      <div className="u-flex">
        <div className="course-credits__info">
          <div className="course-credits__input-container">
            <div
              className="course-credits__input-container__type-container"
              style={{ width: '100%' }}
            >
              <CourseTypeSelectBox
                selection={get(courseState, 'courseTypeId', -1)}
                onChange={updateCourse}
              />
            </div>
            <div className="course-credits__input-container__contact-credits-container">
              <div className="course-credits__input-container__contact-container">
                <Input
                  label="Contact"
                  name="contactHours"
                  value={get(courseState, 'contactHours', '')}
                  onChange={updateCourse}
                  className="course-credits__input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="course-credits__info" style={{ width: 'auto' }}>
          <div className="course-credits__input-container">
            <FlexGroup>
              <p className="form__label course-credits__input-container-label">
                Apply Credits
              </p>
              <div className="u-flex">
                {map(
                  get(courseState, 'awards', []),
                  (award: CourseCreditAward, index: number) => {
                    return (
                      <CreditInput
                        key={index}
                        name="credits"
                        programAwardId={award.programAwardId}
                        value={award.credits}
                        onChange={(item: Partial<CourseCreditAward>) => {
                          return updateCredits(item, award.programAwardId);
                        }}
                        className="course-credits__input"
                      />
                    );
                  }
                )}
              </div>
              <Button
                style={{
                  marginTop: '1rem',
                  cursor: 'pointer',
                  height: '2.5rem',
                  fontSize: '1.6rem',
                  border: 'none',
                  color: '#007d89',
                  backgroundColor: 'transparent',
                  alignSelf: 'flex-end',
                }}
                text="Delete course"
                className="no-print"
                onClick={() => onDelete(course)}
              />
            </FlexGroup>
          </div>
        </div>
      </div>
    </div>
  );
};
