import { GRANTS_EMAIL_TEMPLATE_SUMMARIES_KEY, GRANTS_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { find } from 'lodash';
import { EmailTemplateBase } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  // getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
// import { getEmailTemplateFilters } from 'redux/filters/_selectors';

export const EMAIL_TEMPLATES_SUMMARIES_PATH = [
  GRANTS_KEY,
  GRANTS_EMAIL_TEMPLATE_SUMMARIES_KEY,
];

export const getIsDataFetching_EmailTemplateSummaries = (
  state: any
): boolean => {
  return getIsDataFetching(state, EMAIL_TEMPLATES_SUMMARIES_PATH);
};

export const getRecords_EmailTemplateSummaries = (
  state: unknown
): EmailTemplateBase[] => {
  return getRecords(state, EMAIL_TEMPLATES_SUMMARIES_PATH);
};

export const shouldRefreshReduxState_EmailTemplateSummaries = (
  state: any
): boolean => {
  return shouldRefreshReduxState<EmailTemplateBase>(
    state,
    EMAIL_TEMPLATES_SUMMARIES_PATH
  );
};

// export const getAdvancedFilteredRecords_EmailTemplate = (
//   state: any
// ): EmailTemplateBase[] => {
//   const filters = getEmailTemplateFilters(state);
//   const records = getAdvancedFilteredRecords<EmailTemplateBase>(
//     state,
//     EMAIL_TEMPLATES_PATH,
//     filters
//   );
//   return records;
// };

export const getEmailTemplateIsLoaded = (
  state: ApplicationState,
  EmailTemplateId: number
): boolean => {
  const value = find(
    getRecords_EmailTemplateSummaries(state),
    (value: EmailTemplateBase) => {
      return value && value.templateId === EmailTemplateId;
    }
  );
  return value ? true : false;
};
