export const EQUITY = '[EQUITY]';

export const SET_EQUITY = `${EQUITY} SET`;
export const SET_EQUITY_LOADING = `${EQUITY} LOADING`;

export const setEquity = (payload: any) => {
  return {
    type: SET_EQUITY,
    payload,
  };
};

export const setIsLoading_Equity = (payload: boolean) => {
  return {
    type: SET_EQUITY_LOADING,
    payload,
  };
};
