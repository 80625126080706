import { connect } from 'react-redux';
import { District, SelectWrapper, OptionMap } from 'types';
import {
  DISTRICTS_PATH,
  getRecords_Districts,
  getIsDataFetching_Districts,
} from 'redux/common/_selectors';
import { getRecordsUtility } from 'util/formHelpers';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, label, filter, onChange, records: baseRecords } = ownProps;

  const isLoading = getIsDataFetching_Districts(state);
  const records = getRecordsUtility<District>(
    state,
    DISTRICTS_PATH,
    getRecords_Districts,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<District> = {
    key: 'districtId',
    value: 'districtId',
    label: 'districtName',
  };

  return {
    isLoading,
    label: label ? label : 'District',
    name: 'districtId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
