import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTASchool, SignatureWithAssertion } from 'types';
import {
  readCTASignatures,
  updateCTASignature,
} from 'services/cta/ctasignature.services';

const initialState = baseInitialState;

export const loadCTASignatures = createActionThunk(
  'loadCTASignatures',
  (ctaId: number) => {
    return readCTASignatures(ctaId);
  }
);

export const saveCTASignatures = createActionThunk(
  'saveCTASignatures',
  (ctaId: number, signatures: SignatureWithAssertion[]) => {
    return updateCTASignature(ctaId, signatures);
  }
);

export default handleActions<BaseStateObject, CTASchool[]>(
  {
    ...baseLoadActionBuilder<CTASchool[]>(loadCTASignatures),
    ...baseSaveActionBuilder(saveCTASignatures),
  },
  initialState
);
