import { combineReducers } from 'redux';
import {
  NAV_GRANT_OVERVIEW_KEY,
  NAV_GRANT_FISCAL_YEAR_KEY,
  NAV_GRANT_FOUR_YEAR_KEY,
  NAV_GRANT_TWO_YEAR_KEY,
  NAV_GRANT_ANNUAL_SPENDING_PLAN_KEY,
  NAV_GRANT_ASSURANCE_SIGNATURE_KEY,
  NAV_GRANT_ANNUAL_REFLECTION_KEY,
  NAV_GRANT_AWARD_PACKET_KEY,
  NAV_GRANT_VOUCHER_OVERVIEW_KEY,
  NAV_GRANT_VOUCHER_DETAIL_KEY,
  NAV_GRANT_VOUCHER_STATUS_KEY,
  NAV_GRANT_IMPROVEMENT_PLAN_KEY,
} from 'redux/keys';
import grantoverview from './grantoverviewnav';
import fiscalyear from './fiscalyearnav';
import fouryear from './fouryearnav';
import twoyear from './twoyearnav';
import annualspendingplan from './annualspendingplannav';
import assurancesignature from './assurancesignaturenav';
import annualreflectionnav from './annualreflectionnav';
import awardpacketnav from './awardpacketnav';
import voucheroverview from './voucheroverview';
import voucherdetail from './voucherdetail';
import voucheroverviewstatus from './voucheroverviewstatus';
import improvementplannav from './improvementplannav';

export default combineReducers({
  [NAV_GRANT_OVERVIEW_KEY]: grantoverview,
  [NAV_GRANT_FISCAL_YEAR_KEY]: fiscalyear,
  [NAV_GRANT_FOUR_YEAR_KEY]: fouryear,
  [NAV_GRANT_TWO_YEAR_KEY]: twoyear,
  [NAV_GRANT_ANNUAL_SPENDING_PLAN_KEY]: annualspendingplan,
  [NAV_GRANT_IMPROVEMENT_PLAN_KEY]: improvementplannav,
  [NAV_GRANT_ASSURANCE_SIGNATURE_KEY]: assurancesignature,
  [NAV_GRANT_ANNUAL_REFLECTION_KEY]: annualreflectionnav,
  [NAV_GRANT_AWARD_PACKET_KEY]: awardpacketnav,
  [NAV_GRANT_VOUCHER_OVERVIEW_KEY]: voucheroverview,
  [NAV_GRANT_VOUCHER_DETAIL_KEY]: voucherdetail,
  [NAV_GRANT_VOUCHER_STATUS_KEY]: voucheroverviewstatus,
});
