import { ProjectPlanAllocation } from './ProjectPlanAllocation.component';
import { getRecords_StrategySelectItems } from 'redux/grants/_selectors';
import { connect } from 'react-redux';
import { SpendingCategory } from 'types';
import { filter } from 'lodash';

const mapStateToProps = (state: any, ownProps: any) => {
  const strategies = getRecords_StrategySelectItems(state);
  const { spendingCategories: cats } = ownProps.annualSpendingPlan;
  const spendingCategories = filter(cats, (cat: SpendingCategory) => {
    return [1, 2, 3, 4, 5, 6, 10].includes(cat.categoryId);
  });

  return {
    spendingCategories,
    strategies,
  };
};

export default connect(
  mapStateToProps,
  {}
)(ProjectPlanAllocation);
