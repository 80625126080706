export enum PROGRAM_STATE {
  New = 1,
  Pending = 2,
  InformationNeeded = 3,
  Active = 4,
  ConditionallyActive = 5,
  Inactive = 6,
  Archive = 7,
  Expired = 8,
  RequestForRevision = 9,
  OpenForRevision = 10,
  Revision = 11,
  OpenForRenewal = 12,
  Renewal = 14,
  Active_FinancialAidPending = 15,
}
