import { COMMON_KEY, COMMON_INSTITUTES_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { find } from 'lodash';
import { Institute, ItemFilter } from 'types';

export const INSTITUTES_PATH = [COMMON_KEY, COMMON_INSTITUTES_KEY];

export const getIsDataFetching_Institutes = (state: any): boolean => {
  return getIsDataFetching(state, INSTITUTES_PATH);
};

export const getRecords_Institutes = (state: any): Institute[] => {
  return getRecords<Institute>(state, INSTITUTES_PATH);
};

export const shouldRefreshReduxState_Institutes = (state: any): boolean => {
  return shouldRefreshReduxState<Institute>(state, INSTITUTES_PATH);
};

export const getLastUpdated_Institutes = (state: any): number => {
  return getLastUpdated(state, INSTITUTES_PATH);
};

export const getInstitutesWithBusinesses = (
  state: any,
  filter?: ItemFilter
): Institute[] => {
  return getFilteredRecords<Institute>(
    state,
    INSTITUTES_PATH,
    filter
      ? filter
      : {
          exclude: true,
          property: 'excludeme',
          values: 'excludeme',
        }
  );
};

export const getFilteredRecords_Institutes = (
  state: any,
  filter?: ItemFilter
): Institute[] => {
  const collection = getFilteredRecords<Institute>(state, INSTITUTES_PATH, {
    exclude: true,
    property: 'isBusiness',
    values: true,
  });
  return getFilteredRecords<Institute>(
    state,
    INSTITUTES_PATH,
    filter
      ? filter
      : {
          exclude: true,
          property: 'excludeme',
          values: 'excludeme',
        },
    collection
  );
};

export const getInstituteById = (state: any, schoolId: number) => {
  return find(getRecords_Institutes(state), { schoolId });
};
