/**
 * This file contains functions to help in processing validations.
 * @module Utilities > ValidationUtility
 * @author AmplifyCP
 */

import { compose, isTruthy } from './objectUtility';
import { isMoney, removeCommas } from 'util/moneyHelpers';
import { lt as gt, gt as lt } from 'ramda';

/**
 * @function
 * @returns { boolean } bool indicating if the string is valid or not
 * @param { string } str The string to test
 */
export const isStringValid = (str: string): boolean => {
  return !!str && str.trim().length > 0;
};

/**
 * Checks if the array istruthy for all elements
 * @param { boolean[] } args boolean array
 * @returns { boolean } indication if every item in the array is true.
 * @todo the args.reduce code in the second part of the terinary should be replaced with every(args), don't re-invent the wheel.
 * @function
 */
export const allParamsTrue = (...args: boolean[]) => {
  return args.length === 0
    ? false
    : args.reduce((prev: boolean, curr: boolean) => {
        return prev ? isTruthy(curr) : prev;
      }, true);
};

/**
 * @todo this does not belong here
 * This is a ValidationProps object, but it's not exposed by the library
 */
export const annualContractBaseSalary = [
  {
    errorMessage: 'Must be a valid money representation.',
    validation: isMoney,
  },
  {
    errorMessage: 'Must be less than $1 million.',
    validation: compose(lt(1000000), removeCommas),
  },
  {
    errorMessage: 'Must be more than $100.',
    validation: compose(gt(100), removeCommas),
  },
];

/**
 * @todo this does not belong here
 * This is a ValidationProps object, but it's not exposed by the library
 */
export const actualSalaryEarned = [
  {
    errorMessage: 'Must be a valid money representation.',
    validation: isMoney,
  },
  {
    errorMessage: 'Must be less than $1 million.',
    validation: compose(lt(1000000), removeCommas),
  },
  {
    errorMessage: 'Must be more than $100.',
    validation: compose(gt(100), removeCommas),
  },
];
