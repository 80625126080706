import { connect } from 'react-redux';
import { CTEInstructorCourseSelectBox } from './CTEInstructorCourseSelectBox.component';
import ApplicationState from 'types/applicationstate.types';
import { getRecords } from 'redux/utility/_exports';
import { CTA_COURSE_INSTRUCTIONAL_PATH } from 'redux/cta/_selectors';
import { CtaInstructorCourse } from 'types';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const courses: CtaInstructorCourse[] = getRecords(
    state,
    CTA_COURSE_INSTRUCTIONAL_PATH
  );
  return {
    courses,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTEInstructorCourseSelectBox);
