import { useValidation } from 'de-formed-validations';
import { ProgramContact } from 'types';
import { validateEmail } from 'util/formHelpers';
import { isStringValid } from 'util/validation.utility';

export const ProgramContactValidation = () => {
  return useValidation<ProgramContact>({
    advisorFirstName: [
      {
        errorMessage: `Advisor's first name is required.`,
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    advisorLastName: [
      {
        errorMessage: `Advisor's last name is required.`,
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    advisorTitle: [
      {
        errorMessage: `Advisor's title is required.`,
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    advisorOrganization: [
      {
        errorMessage: `Advisor's organization is required.`,
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
    ],
    advisorEmail: [
      {
        errorMessage: `Advisor's email is required.`,
        validation: (val: string) => {
          return isStringValid(val);
        },
      },
      {
        errorMessage: `Advisor's email must be valid.`,
        validation: (val: string) => {
          return validateEmail(val);
        },
      },
    ],
  });
};
