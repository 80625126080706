import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
} from 'redux/utility/_exports';
import { UniversalContact } from 'types';
import { readUniversalTeachers } from 'services/universalcontacts/universalcontact.services';

const initialState = baseInitialState;

export const loadUniversalTeachers = createActionThunk(
  'loadUniversalTeachers',
  () => {
    return readUniversalTeachers();
  }
);

export default handleActions<BaseStateObject, UniversalContact[]>(
  {
    ...baseLoadActionBuilder<UniversalContact[]>(loadUniversalTeachers),
  },
  initialState
);
