import { connect } from 'react-redux';
import { ContractingComponent } from './Contracting.component';
import {
  loadCTAContracting,
  removeCTAContracting,
} from 'redux/cta/contracting/ctacontracting';
import { getRecords_CTAContracting } from 'redux/cta/_selectors';

const mapStateToProps = (state: any) => {
  const ctaContracting = getRecords_CTAContracting(state);
  return {
    ctaContracting,
  };
};

const dispatchProps = {
  loadCTAContracting,
  removeCTAContracting,
};

export default connect(mapStateToProps, dispatchProps)(ContractingComponent);
