import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readFiscalAgents } from 'services/grants/consortium.services';
import { FiscalAgent } from 'types';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
} from 'redux/utility/_exports';

const initialState = baseInitialState;

export const loadFiscalAgents = createActionThunk('loadFiscalAgents', () => {
  return readFiscalAgents();
});

export default handleActions<BaseStateObject, FiscalAgent[]>(
  {
    ...baseLoadActionBuilder<FiscalAgent[]>(loadFiscalAgents),
  },
  initialState
);
