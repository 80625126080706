import {
  removeCTAEquipment,
  saveCTAEquipment,
  loadCTAEquipment,
} from 'redux/cta/ctaequipment';

export const ctaEquipmentMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case removeCTAEquipment.SUCCEEDED:
    case saveCTAEquipment.SUCCEEDED: {
      dispatch(loadCTAEquipment(action.payload));
      break;
    }
  }
};
