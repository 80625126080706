import { FiscalYear } from 'types';
import { emptyFiscalYear } from '../FiscalYear.types';

export interface PerformanceTarget {
  year: FiscalYear;
  percent: number;
}

export const emptyPerformanceTarget = () => {
  return {
    year: emptyFiscalYear(),
    percent: -1,
  };
};
