import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { ProjectSummary } from './ProjectSummary.component';

const mapStateToProps = (state: any) => {
  return {};
};

const dispatchProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProjectSummary);
