import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ReportsDashboard from 'components/Reports/ReportsDashboard';

export const ReportingDashboard = () => {
  return (
    <BaseLayout>
      <ReportsDashboard />
    </BaseLayout>
  );
};
