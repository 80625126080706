import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCertificateOfExemptionSignatures,
  updateCertificateOfExemptionSignatures,
} from 'services/grants/awardsignature.services';
import { CertificationOfExemptionSignature } from 'types/grant/CertificationOfExemptionSignature.types';

const initialState = baseInitialState;

export const loadCertificateOfExemptionSignatures = createActionThunk(
  'loadCertificateOfExemptionSignatures',
  (grantFiscalYearId: number) => {
    return readCertificateOfExemptionSignatures(grantFiscalYearId);
  }
);

export const saveCertificateOfExemptionSignatures = createActionThunk(
  'saveCertificateOfExemptionSignatures',
  async (
    grantFiscalYearId: number,
    payload: CertificationOfExemptionSignature[]
  ) => {
    return updateCertificateOfExemptionSignatures(grantFiscalYearId, payload);
  }
);

export default handleActions<
  BaseStateObject,
  CertificationOfExemptionSignature | CertificationOfExemptionSignature[]
>(
  {
    ...baseLoadActionBuilder<
      CertificationOfExemptionSignature | CertificationOfExemptionSignature[]
    >(loadCertificateOfExemptionSignatures),
    ...baseSaveActionBuilder<
      CertificationOfExemptionSignature | CertificationOfExemptionSignature[]
    >(saveCertificateOfExemptionSignatures),
  },
  initialState
);
