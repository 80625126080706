import { connect } from 'react-redux';
import { loadActualReimbursementRequestData } from 'redux/grants/actualreimbursementrequest';
import { getFirstRecord_ActualReimbursement } from 'redux/grants/_selectors';
import { TotalActualReimbursementTables } from './TotalActualReimbursementTables.component';

const mapStateToProps = (state: any) => {
  const reimbursementSummary = getFirstRecord_ActualReimbursement(state);

  return {
    reimbursementSummary,
  };
};

const dispatchProps = {
  loadActualReimbursementRequestData,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(TotalActualReimbursementTables);
