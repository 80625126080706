import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';
import { Signature } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { useDispatch } from 'react-redux';
import { isApplicaionIdValid } from 'util/global';
import { loadGrantAgreementSignatures } from 'redux/grants/awardsignatures/grantagreementsignatures';
import CommonSignatureComponent from 'components/Common/Signature';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';

type Props = {
  onChange: Function;
  signatures: Signature[];
};

export const ReadAndSignGrantRecipientAgreementComponent: React.FC<Props> = (
  props
) => {
  const { onChange, signatures } = props;
  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadGrantAgreementSignatures(grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYearId]);

  const [signaturesState, setSignaturesState] = useState<Signature[]>([]);

  const handleChange = (signature: Signature, index: number) => {
    const newState = signaturesState;
    newState[index] = signature;
    setSignaturesState([...newState]);
    onChange([...newState]);
  };

  useEffect(() => {
    if (signaturesState.length <= 0) {
      setSignaturesState([...signatures]);
    }
  }, [signatures]); // eslint-disable-line

  return (
    <Paper>
      <Instruction title="Read and Sign Grant Recipient Agreement">
        <p>Review and sign the Grant Recipient Agreement</p>
        <p>
          If participating as a Consortium, only the Fiscal Agent is required to
          complete the Grant Recipient Agreement.
        </p>
      </Instruction>

      <FlexRow>
        <FlexGroup>
          <div className="u-sec-ro--override">
            <h4 className="u-margin-bottom-medium">
              Grant Recipient Agreement
            </h4>
            <a
              href={`/grants/applications/award-packet/documents/grant-recipient-agreement/${grantFiscalYearId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div style={{ display: 'inline-block' }}>
                <span className="u-flex">
                  <Icon
                    name="documents"
                    className="u-icon-base-size u-icon-blue u-cursor-help"
                  />
                  <span style={{ marginLeft: '.5rem' }}>
                    View Grant Recipient Agreement
                  </span>
                </span>
              </div>
            </a>
          </div>
        </FlexGroup>
      </FlexRow>

      {map(signaturesState, (signature: Signature, index: number) => {
        return (
          <CommonSignatureComponent
            onChange={(signature: Signature) => {
              handleChange(signature, index);
            }}
            signature={signature}
            key={index}
          />
        );
      })}
    </Paper>
  );
};
