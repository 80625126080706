import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseInitialState,
  BaseStateObject,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/_exports';
import { readInstitutes } from 'services/common/districtinstituteschool.services';
import { Institute } from 'types';

const initialState = baseInitialState;

export const loadInstitutes = createActionThunk('loadInstitutes', () => {
  return readInstitutes();
}); // end

export default handleActions<BaseStateObject, Institute[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<Institute[]>(loadInstitutes),
  },
  initialState
);
