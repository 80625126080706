import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { renderData, prop, getDate, compose } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import {
  StudentEnrollment,
  StudentEnrollmentData,
  UniversalContact,
} from 'types';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { COLOR_LIGHT_RED, COLOR_LIGHT_GREEN } from 'constants/css.const';
import { FlexRow } from 'layouts';
import { some } from 'lodash';
import { isSuperAdmin } from 'util/permissionsUtility';
import EnrollmentUpload from './EnrollmentUpload';
import EnrollmentStatus from './EnrollmentStatus';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import Table from 'components/Common/Table';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StudentEnrollmentFilter from '../StudentEnrollmentFilter';

type StudentEnrollmentProps = {
  isFetching: boolean;
  enrollmentData: StudentEnrollmentData;
  loadStudentEnrollmentData: Function;
  loggedInUser: UniversalContact;
};

export const StudentEnrollmentComponent: React.FC<StudentEnrollmentProps> = (
  props
) => {
  const {
    enrollmentData,
    isFetching,
    loadStudentEnrollmentData,
    loggedInUser,
  } = props;
  const { enrollments } = enrollmentData;

  const [filteredData, setFilteredData] = useState<StudentEnrollment[]>([]);
  const history = useHistory();

  const handleRowClick = (row: StudentEnrollment) => {
    if (row.isOpen) {
      return history.push(
        `/data-collection/student-enrollment/${row.programBaseId}`
      );
    }
  };

  const getDisplayData = (name: keyof StudentEnrollment) => (
    row: StudentEnrollment
  ) => {
    return renderData(prop(name, row));
  };

  const getEPCData = (name: keyof StudentEnrollment) => (
    row: StudentEnrollment
  ) => {
    const value =
      row.programLevelId === PROGRAM_LEVEL_ENUM['Middle School']
        ? 'N/A'
        : prop(name, row);
    return renderData(value);
  };

  const STUDENT_ENROLLMENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Enrollments',
      cell: getDisplayData('enrollments'),
      selector: prop('enrollments'),
      sortable: true,
    },
    {
      name: 'Participants',
      cell: getEPCData('participants'),
      selector: prop('participants'),
      sortable: true,
    },
    {
      name: 'Concentrators',
      cell: getEPCData('concentrators'),
      selector: prop('concentrators'),
      sortable: true,
    },
    {
      name: 'Completers',
      cell: getEPCData('completers'),
      selector: prop('completers'),
      sortable: true,
    },
    {
      name: 'School Name',
      cell: getDisplayData('schoolName'),
      selector: compose(prop('schoolName')),
      sortable: true,
      style: {
        width: '20%',
        flexGrow: 1,
      },
    },
    {
      name: 'CDE ID',
      cell: getDisplayData('cdeId'),
      selector: prop('cdeId'),
      sortable: true,
    },
    {
      name: 'Program',
      cell: getDisplayData('programName'),
      selector: prop('programName'),
      sortable: true,
      style: {
        width: '20%',
        flexGrow: 2,
      },
    },
    {
      name: 'CIP',
      cell: getDisplayData('cipCode'),
      selector: prop('cipCode'),
      sortable: true,
    },
    {
      name: 'Base ID',
      cell: getDisplayData('programBaseId'),
      selector: prop('programBaseId'),
      sortable: true,
    },
    {
      name: 'Program Instance ID',
      cell: getDisplayData('programInstanceId'),
      selector: prop('programInstanceId'),
      sortable: true,
    },
    {
      name: 'Program State',
      cell: getDisplayData('programState'),
      selector: prop('programState'),
      sortable: true,
      style: {
        width: '10%',
        flexGrow: 1,
      },
    },
    {
      name: 'Expiration',
      cell: compose(getDate, prop('expiration')),
      selector: prop('expiration'),
      sortable: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: StudentEnrollment) => {
        return row.isComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_GREEN,
      },
    },
    {
      when: (row: StudentEnrollment) => {
        return !row.isOpen && !row.isComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_RED,
      },
    },
  ];

  useEffect(() => {
    setFilteredData(enrollments);
  }, [enrollments]);

  useEffect(() => {
    loadStudentEnrollmentData();
  }, [loadStudentEnrollmentData]);

  return (
    <SecureWrap
      component={['DCStudentRecords']}
      allowReadOnly={true}
      isPage={true}
    >
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Student Enrollment Academic Year: ${
                enrollmentData.schoolYear - 1
              } - ${enrollmentData.schoolYear}`}
              isPageHeader={true}
            >
              <p>
                You are required to report student enrollment data for the
                indicated academic year, which opens on May 1st for all
                education levels and closes on July 31st for Districts and
                August 31st for Postsecondary Institutions. Use this page to
                upload new student enrollment data or to view and update data on
                existing ones. Once all enrollment data is entered, users with
                approval permission may certify that their data is complete for
                their site.
              </p>
              <br />
              <p>
                Once all enrollment data is entered, the person who has the
                "Local - DC Submission Approver" permission should access the{' '}
                <Link to="/cccs-reports">Reporting Dashboard</Link> and filter
                by "DC - Review" to double check all data that has been entered.
                Then users with approval permission may certify that their data
                is complete for their site.
              </p>
            </Instruction>
          }
        />
      </Paper>
      {!isSuperAdmin(loggedInUser) && (
        <SecureWrap
          isLocked={some([enrollmentData.complete, enrollmentData.locked])}
          component={['DCCertification']}
        >
          <Paper>
            <EnrollmentStatus enrollment={enrollmentData} />
          </Paper>
        </SecureWrap>
      )}
      <SecureWrap
        isLocked={some([enrollmentData.complete, enrollmentData.locked])}
        component={['DCStudentRecords']}
      >
        <EnrollmentUpload enrollmentData={enrollmentData} />
      </SecureWrap>
      <Paper>
        <Instruction title="View & Edit Program Enrollment">
          <p>
            Listed below are details on the active and conditionally-active
            programs offered within your site(s) during the current academic
            year, as entered in the Program Approvals module. Each row
            corresponds to a specific program offered within a site. Rows
            highlighted in green show completed records and rows highlighted in
            red show records that have been closed. Use the search bar to search
            within any column of the table. Click on a program's row to view
            program summary and trend data or to update the information
            provided, including adding students to the enrollment information.
          </p>
        </Instruction>
        <FlexRow>
          <SearchBarFilter
            shouldReset={true}
            data={enrollments}
            isFetching={isFetching}
            label="Search Student Enrollment"
            setFilteredData={setFilteredData}
            headers={STUDENT_ENROLLMENT_HEADERS}
          />
        </FlexRow>
        <StudentEnrollmentFilter />
        <Table
          conditionalRowStyles={conditionalRowStyles}
          columns={STUDENT_ENROLLMENT_HEADERS}
          data={filteredData}
          onRowClick={handleRowClick}
        />
      </Paper>
    </SecureWrap>
  );
};
