import React from 'react';
import { FlexRow } from 'layouts';
import Icon from 'components/Common/Icons';

type ToastNotificationProps = {
  message?: string;
  children?: JSX.Element | JSX.Element[];
  status: 'error' | 'warning' | 'success';
};

export const ToastNotification: React.FC<ToastNotificationProps> = (props) => {
  const getIcon = () => {
    switch (props.status) {
      case 'error':
        return <Icon name="warningAlert" className="toast__icon" />;
      case 'warning':
        return <Icon name="info" className="toast__icon" />;
      case 'success':
        return (
          <Icon
            name="circleChecked"
            className="toast__icon toast__icon--success"
          />
        );
      default:
        return <Icon name="questionMark" className="toast__icon" />;
    }
  };

  const buildDOM = () => {
    if (props.children) {
      return (
        <React.Fragment>
          <div className="u-flex toast__container">
            {getIcon()}
            <div className="toast__text">{props.children}</div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <React.Fragment>
            <div className="u-flex toast__container">
              {getIcon()}
              {props.message ? (
                <FlexRow>
                  <div className="toast__text">
                    <p>{props.message}</p>
                  </div>
                </FlexRow>
              ) : (
                <React.Fragment>
                  <div className="toast__text">
                    <FlexRow>
                      <p>An error occurred.</p>
                    </FlexRow>
                    <FlexRow>
                      <p>
                        If the problem persists, please contact your site
                        administrator.
                      </p>
                    </FlexRow>
                  </div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  return buildDOM();
};
