import { useValidation } from 'de-formed-validations';
import { isEmpty } from 'lodash';
import { DistrictSchoolSignature } from 'types';
import { SignatureWithAssertionValidations } from '../SignatureWithAssertions.validations';

// type alias
type S = DistrictSchoolSignature;

// define validation schema for validation hook
type ValidationState = {
  canSave: S[];
  canSubmit: S[];
};

export const CivilRightsAssertionsListValidations = () => {
  const { validate } = SignatureWithAssertionValidations();

  return useValidation<ValidationState>({
    canSave: [
      {
        errorMessage: 'All signature fields must be provided.',
        validation: (signatures: S[], _) => {
          if (isEmpty(signatures)) return true;
          return signatures.reduce((prev: boolean, curr: S) => {
            if (!prev) return prev;
            return curr.required ? validate('canSave', curr) : true;
          }, true);
        },
      },
    ],
    canSubmit: [
      {
        errorMessage:
          'Not all required civil rights assurance signatures have been provided.',
        validation: (signatures: S[], _) => {
          if (isEmpty(signatures)) return true;
          return signatures.reduce((prev: boolean, curr: S) => {
            if (!prev) return prev;
            return curr.required ? validate('canSubmit', curr) : true;
          }, true);
        },
      },
    ],
  });
};
