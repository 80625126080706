import { ENVIRONMENT, isProd, isDev, isUAT } from './application.config';

export interface AuthConfig {
  instance: string;
  tenant: string;
  signInPolicy: string;
  resetPolicy: string;
  applicationId: string;
  cacheLocation: string;
  scopes: string[];
  redirectUri: string;
  postLogoutRedirectUri: string;
  storeAuthStateInCookie: boolean;
}

const BASE_AUTH_CONFIG: AuthConfig = {
  storeAuthStateInCookie: true,
  instance: 'https://CCCSB2C.b2clogin.com/tfp/',
  tenant: 'CCCSB2C.onmicrosoft.com',
  signInPolicy: 'B2C_1_signin_only_mfa',
  resetPolicy: 'B2C_1_passwordreset',
  applicationId: 'b3badb91-d01a-4429-807f-225f920fd847',
  cacheLocation: 'localStorage',
  scopes: ['https://CCCSB2C.onmicrosoft.com/cccs-api/user_impersonation'],
  redirectUri: ENVIRONMENT.REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
};

export const AUTH_CONFIG = (): AuthConfig => {
  let config = BASE_AUTH_CONFIG;

  if (isProd()) {
    config = {
      ...BASE_AUTH_CONFIG,
      scopes: [
        'https://CCCSB2C.onmicrosoft.com/cccs-api-prod/user_impersonation',
      ],
      applicationId: '35501e94-bffd-4a23-b297-bc62959be5e4',
    };
  }
  if (isDev()) {
    config = {
      ...BASE_AUTH_CONFIG,
      scopes: [
        'https://CCCSB2C.onmicrosoft.com/cccs-api-dev/user_impersonation',
      ],
      applicationId: 'c7532cd0-d3e8-4f3d-bbfd-6dd981fa9245',
    };
  }
  if (isUAT()) {
    config = {
      ...BASE_AUTH_CONFIG,
      scopes: [
        'https://CCCSB2C.onmicrosoft.com/cccs-api-uat/user_impersonation',
      ],
      applicationId: '1c21028f-083d-43d5-925d-860d918b9efc',
    };
  }
  return config;
};
