import { useValidation } from 'de-formed-validations';
import { isEmpty } from 'lodash';
import { CertificationOfExemptionSignature } from 'types';
import { CertificationOfExemptionItemValidations } from '../CertificationOfExemptionItem.validations';

// type alias
type S = CertificationOfExemptionSignature;

// define validation schema for validation hook
type ValidationState = {
  canSave: S[];
  canSubmit: S[];
};

export const CertificationOfExemptionValidationsList = () => {
  const { validate } = CertificationOfExemptionItemValidations();

  return useValidation<ValidationState>({
    canSave: [
      {
        errorMessage:
          'All Certification of Exemption signature fields must be provided.',
        validation: (signatures: S[], _) => {
          if (isEmpty(signatures)) return true;
          return signatures.reduce((prev: boolean, curr: S) => {
            return prev ? validate('canSave', curr) : prev;
          }, true);
        },
      },
    ],
    canSubmit: [
      {
        errorMessage:
          'Not all required Certification of Exemption signature fields have been provided or the single audit report is missing.',
        validation: (signatures: S[], _) => {
          if (isEmpty(signatures)) return true;
          return signatures.reduce((prev: boolean, curr: S) => {
            return prev ? validate('canSubmit', curr) : prev;
          }, true);
        },
      },
    ],
  });
};
