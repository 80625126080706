import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionRole } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readContactPermissionRoles,
  createContactPermissionRoles,
} from 'services/permissions/contactpermissionrole.service';

const initialState = baseInitialState;

// TODO: Evaluate if we need this in the future.
export const loadContactPermissionRoles = createActionThunk(
  'loadContactPermissionRoles',
  (universalContactId: number): Promise<PermissionRole[]> => {
    return readContactPermissionRoles(universalContactId);
  }
);

export const saveContactPermissionRoles = createActionThunk(
  'saveContactPermissionRoles',
  (universalContactId: number, roles: PermissionRole[]) => {
    return createContactPermissionRoles(universalContactId, roles);
  }
);

export default handleActions<BaseStateObject, PermissionRole[]>(
  {
    ...baseLoadActionBuilder<PermissionRole[]>(loadContactPermissionRoles),

    [saveContactPermissionRoles.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveContactPermissionRoles.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
