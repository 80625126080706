import React, { useState, useEffect, CSSProperties } from 'react';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
  RenderSuggestionsContainerParams,
  RenderSuggestionParams,
} from 'react-autosuggest';
import { SnapshotStudent, emptySnapshotStudent } from 'types';
import { map, find } from 'lodash';
import { FlexGroup } from 'layouts';
import { getFullName } from 'util/datacollection.utility';
import { renderData, prop } from 'util/objectUtility';
import MaxResults from '../MaxResults';
import { getSuggestions, getParts, renderPart } from 'util/autoSuggest.utility';

type SnapshotStudentSelectBoxProps = {
  student?: SnapshotStudent[];
  students: SnapshotStudent[];
  onChange: Function;
  loadSnapshotStudent: Function;
  maxResults?: number;
};

export const SnapshotStudentSelectBoxComponent: React.FC<SnapshotStudentSelectBoxProps> = (
  props
) => {
  const { students, onChange, loadSnapshotStudent, maxResults = 8 } = props;
  const [resultText, setResultText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SnapshotStudent[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<SnapshotStudent>();

  const boxStyle: CSSProperties = {
    width: '30%',
  };

  useEffect(() => {
    if (selectedStudent) {
      const value = find(students, (item) => {
        return item.studentId === selectedStudent.studentId;
      });
      setResultText(value ? `${value.firstName} ${value.lastName}` : '');
    }
  }, [setResultText, students, selectedStudent]);

  useEffect(() => {
    loadSnapshotStudent();
  }, [loadSnapshotStudent]);

  const defaultResult = {
    ...emptySnapshotStudent(),
    firstName: 'No results',
    sasId: '',
    alternateId: '',
  };

  const executeSearch = (value: string): SnapshotStudent[] => {
    setSelectedStudent(undefined);
    return getSuggestions(
      students,
      value,
      [getFullName, prop('sasId'), prop('alternateId')],
      maxResults,
      defaultResult
    );
  };

  const handleSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams) => {
    const { value } = request;
    if (value === resultText) {
      setSearchResults(executeSearch(value));
    }
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>Full Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>SASID</strong>
          </span>
          <span style={boxStyle}>
            <strong>Alternate ID</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={students.length}
          resultCount={searchResults.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: SnapshotStudent,
    { query }: RenderSuggestionParams
  ) => {
    const nameParts = getParts(getFullName(suggestion), query);
    const idParts = getParts(renderData(suggestion.sasId), query);
    const altIdParts = getParts(renderData(suggestion.alternateId), query);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div style={boxStyle}>{map(nameParts, renderPart)}</div>
          <div style={boxStyle}>{map(idParts, renderPart)}</div>
          <div style={boxStyle}>{map(altIdParts, renderPart)}</div>
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'down':
      case 'up':
        break;
      default:
        setResultText(newValue);
        setSearchResults(executeSearch(newValue));
        break;
    }
  };

  const inputProps = {
    value: resultText,
    onChange: handleChange,
    id: 'studentSelectBox',
  };

  const getSuggestionValue = (suggestion: SnapshotStudent) => {
    return getFullName(suggestion);
  };

  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<SnapshotStudent>
  ) => {
    const { suggestion } = data;
    onSuggestionsClearRequested();
    setResultText('');
    onChange && onChange(suggestion);
  };

  return (
    <React.Fragment>
      <span id="hackathon" />
      <FlexGroup>
        <Autosuggest
          focusInputOnSuggestionClick={false}
          alwaysRenderSuggestions={true}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          suggestions={searchResults}
        />
      </FlexGroup>
    </React.Fragment>
  );
};
