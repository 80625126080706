import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import DataCollectionPortal from 'pages/DataCollection/Portal';
import DataCollectionProgramFollowupPage from 'pages/DataCollection/ProgramFollowup';
import DataCollectionSubmissionManagementPage from 'pages/DataCollection/SubmissionManagement';
import DataCollectionStudentEnrollmentPage from 'pages/DataCollection/StudentEnrollment';
import DataCollectionStudentFollowupPage from 'pages/DataCollection/StudentFollowup';
import ProgramEnrollment from 'pages/DataCollection/ProgramEnrollment';
import ActiveTeachersPage from 'pages/DataCollection/ActiveTeachers';
import RolloverPage from 'pages/DataCollection/RolloverPage';

const dataCollectionRoutes = [
  <Route
    exact
    path="/data-collection"
    component={authentication.required(DataCollectionPortal)}
  />,

  // ------------------------------------------- //
  //          Admin
  // ------------------------------------------- //

  <Route
    exact
    path="/data-collection/submission-management"
    component={authentication.required(DataCollectionSubmissionManagementPage)}
  />,
  <Route
    exact
    path="/data-collection/rollover"
    component={authentication.required(RolloverPage)}
  />,

  // ------------------------------------------- //
  //          Enrollment
  // ------------------------------------------- //
  <Route
    exact
    path="/data-collection/student-enrollment"
    component={authentication.required(DataCollectionStudentEnrollmentPage)}
  />,
  <Route
    exact
    path="/data-collection/student-enrollment/:programId"
    component={authentication.required(ProgramEnrollment)}
  />,

  // ------------------------------------------- //
  //          FollowUp
  // ------------------------------------------- //
  <Route
    exact
    path="/data-collection/student-followup"
    component={authentication.required(DataCollectionStudentFollowupPage)}
  />,
  <Route
    exact
    path="/data-collection/student-followup/:programId"
    component={authentication.required(DataCollectionProgramFollowupPage)}
  />,

  // ------------------------------------------- //
  //          Teachers
  // ------------------------------------------- //
  <Route
    exact
    path="/data-collection/active-teacher"
    component={authentication.required(ActiveTeachersPage)}
  />,
];

export default dataCollectionRoutes;
