import React from 'react';
import { Deficiency } from 'types';
import { map, every } from 'lodash';
import { insertClass } from 'util/formHelpers';
import { renderData } from 'util/objectUtility';
import { getPercent } from 'util/mathHelpers';

type DeficiencyTableProps = {
  deficiency: Deficiency;
};

export const DeficiencyTable: React.FC<DeficiencyTableProps> = (props) => {
  const { deficiency } = props;

  return (
    <React.Fragment>
      <table className="table-summary deficiency__table">
        <tbody className="deficiency__table-body">
          <tr>
            <th className="deficiency__header-big" colSpan={3} scope="row">
              {renderData(`${deficiency.metricCode} ${deficiency.metricName}`)}
            </th>
          </tr>
          <tr>
            <th
              aria-label="numerator"
              scope="col"
              className="deficiency__header"
            >
              N
            </th>
            <th
              aria-label="denominator"
              scope="col"
              className="deficiency__header"
            >
              D
            </th>
            <th
              aria-label="percentage"
              scope="col"
              className="deficiency__header"
            >
              %
            </th>
          </tr>
          {map(deficiency.performanceData, (perfdata, index: number) => {
            const zeroes = every([
              perfdata.denominator === 0,
              perfdata.numerator === 0,
              perfdata.percentage === 0,
            ]);

            const consortiumAllPercentage =
              deficiency.performanceData[0].percentage;

            const isNonTraditionalMetric = ['3P1', '4S1'].includes(
              deficiency.metricCode
            );

            const error = every([
              index === 0
                ? perfdata.percentage <
                  deficiency.previousYearTarget.percent * 0.9
                : perfdata.denominator >= 30 &&
                  (perfdata.percentage < consortiumAllPercentage * 0.7 ||
                    perfdata.percentage > consortiumAllPercentage * 1.3) &&
                  // ignore Non-traditional data in the Non-Traditional Metric type because it will always be 100%
                  !(
                    isNonTraditionalMetric &&
                    perfdata.aggType === 'Non-Traditional' &&
                    perfdata.percentage === 1
                  ),
              !zeroes,
            ]);

            return (
              <tr
                key={index}
                aria-label={perfdata.aggType}
                className={insertClass(error ? 'deficiency__table-error' : '')}
              >
                <td>{perfdata.numerator}</td>
                <td>{perfdata.denominator}</td>
                <td>{getPercent(perfdata.percentage)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};
