import { connect } from 'react-redux';
import {
  getRecords_EmailTemplateSummaries,
  getIsDataFetching_EmailTemplateSummaries,
} from 'redux/grants/_selectors';
import { EmailsDashboardComponent } from './Dashboard.component';
import { loadEmailTemplateSummaries } from 'redux/grants/emailtemplatesummaries';
import { getCurrentFiscalYear } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const emailTemplates = getRecords_EmailTemplateSummaries(state);
  const isFetching = getIsDataFetching_EmailTemplateSummaries(state);
  const currentFiscalYear = getCurrentFiscalYear(state);
  const grantFiscalYear = Number(ownProps.match.params.grantFiscalYear);

  return {
    currentFiscalYear,
    grantFiscalYear,
    emailTemplates,
    isFetching,
  };
};

const dispatchProps = {
  loadEmailTemplateSummaries,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(EmailsDashboardComponent);
