import { connect } from 'react-redux';
import { SelectWrapperFilter, OptionMap } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapperFilter) => {
  const optionMap: OptionMap<any> = {
    key: 'questionId',
    value: 'questionId',
    label: 'text',
  };

  return {
    optionMap,
  };
};

export default connect(mapStateToProps)(SelectBox);
