import { GRANTS_KEY, GRANTS_ANNUAL_REFLECTION_KEY } from 'redux/keys';
import { QuestionGroup } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';

export const ANNUAL_REFLECTION_PATH = [
  GRANTS_KEY,
  GRANTS_ANNUAL_REFLECTION_KEY,
];

export const getIsDataFetching_AnnualReflection = (state: any): boolean => {
  return getIsDataFetching(state, ANNUAL_REFLECTION_PATH);
};

export const getRecords_AnnualReflection = (
  state: unknown
): QuestionGroup[] => {
  return getRecords(state, ANNUAL_REFLECTION_PATH);
};

export const shouldRefreshReduxState_AnnualReflection = (
  state: any
): boolean => {
  return shouldRefreshReduxState<QuestionGroup>(state, ANNUAL_REFLECTION_PATH);
};
