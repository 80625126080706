import React from 'react';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import IconButton from 'components/Common/IconButton';

type RemovePOSProps = {
  canRemove: boolean;
  onRemove: Function;
};

export const RemovePOS: React.FC<RemovePOSProps> = (props) => {
  const { canRemove, onRemove } = props;

  return canRemove ? (
    <span className="pos__remove">
      <IconButton
        name="trashCan"
        tooltip="Remove Program of Study"
        onClick={onRemove}
      />
    </span>
  ) : (
    <span className="pos__approve">{EMPTY_PLACEHOLDER}</span>
  );
};
