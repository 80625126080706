import React from 'react';
import { CourseCreditProgram, ProgramAward } from 'types';
import { map } from 'lodash';
import Tooltip from 'components/Common/Tooltip';

type CreditsBarProps = {
  courseCredits: CourseCreditProgram;
  creditTotals: number[];
};

export const CreditsBar: React.FC<CreditsBarProps> = (props) => {
  const { creditTotals, courseCredits } = props;
  const { programAwards } = courseCredits;

  return (
    <div className="course-credits__credit-bar-wrapper no-print">
      <h2 className="credit-bar__title">Total Credits</h2>

      <div className="course-credits__info">
        <div className="credit-bar__input-row">
          <p className="course-credits__labels">Award Number</p>
        </div>
        <div className="credit-bar__input-row">
          <p className="course-credits__labels">Required Credits</p>
        </div>
        <div className="credit-bar__input-row">
          <p className="course-credits__labels">Current Total</p>
        </div>
      </div>

      <div className="course-credits__credits">
        <div className="credit-bar__input-row">
          {programAwards &&
            map(programAwards, (award: ProgramAward, index: number) => {
              return (
                <Tooltip
                  key={index}
                  indicator={
                    <h4
                      aria-label={award.certOrDegreeTitle}
                      className="credit-bar__box credit-bar__box--award-number"
                    >
                      {index + 1}
                    </h4>
                  }
                  tooltip={award.certOrDegreeTitle}
                />
              );
            })}
        </div>
        <div className="credit-bar__input-row">
          {map(programAwards, (award: ProgramAward, index: number) => {
            return (
              <p key={index} className="credit-bar__box">
                {award.credits}
              </p>
            );
          })}
        </div>
        <div className="credit-bar__input-row">
          {map(creditTotals, (total: number, index: number) => {
            return (
              <p
                key={index}
                className={
                  total === programAwards[index].credits
                    ? 'credit-bar__box'
                    : total < programAwards[index].credits
                    ? 'credit-bar__box credit-bar__box--current'
                    : 'credit-bar__box u-error'
                }
              >
                {total}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
