import React from 'react';
import { Deficiency, FiscalYear, PerformanceData } from 'types';
import { map } from 'lodash';
import { compose, renderData, prop, getFullYear } from 'util/objectUtility';

type TableOutlineProps = {
  deficiency: Deficiency;
};

export const TableOutline: React.FC<TableOutlineProps> = (props) => {
  const { deficiency } = props;

  const getFormattedFiscalYear = (year: FiscalYear) => {
    const start = compose(
      renderData,
      getFullYear,
      prop('startDate')
    );
    const end = compose(
      renderData,
      getFullYear,
      prop('endDate')
    );
    return `${start(year)} - ${end(year)}`;
  };

  return (
    <React.Fragment>
      <table className="table-summary" style={{ minWidth: '32rem' }}>
        <tbody>
          <tr>
            <th className="deficiency__header-big" colSpan={3}>
              Deficiency Code / Name
            </th>
          </tr>
          <tr>
            <th className="deficiency__header">Population</th>
          </tr>
          {map(
            deficiency.performanceData,
            (item: PerformanceData, index: number) => {
              return (
                <tr key={index} aria-label={item.aggType}>
                  <td>
                    {item.aggType.toLowerCase() === 'all' ? (
                      <strong>
                        {getFormattedFiscalYear(
                          deficiency.previousYearTarget.year
                        )}
                        &nbsp; Institution Actual level
                      </strong>
                    ) : (
                      item.aggType
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};
