import React, { useRef } from 'react';
import { Modal, useModal } from 'react-morphing-modal';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';
import { AdminCostComponent } from './AdminCostComponent';
import IconButton from 'components/Common/IconButton';
import { CTASchool } from 'types';

type AdminCostModalProps = {
  admin: CTAAdministrator;
  saveCTAAdministrator: Function;
  hidden?: boolean;
  trigger?: JSX.Element;
  districtId: number;
  ctaSchools: CTASchool[];
  adminSchools: CTASchool[];
};

export const AdminCostModal: React.FC<AdminCostModalProps> = (props) => {
  const { admin, saveCTAAdministrator, districtId, ctaSchools, adminSchools } = props;

  // -- dependencies --
  const { modalProps, open, close } = useModal();

  // -- local state --
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <IconButton name="pencil" tooltip="Edit" onClick={() => open(modalRef)} />
      </div>
      <Modal {...modalProps}>
        <div className="u-flex-center" style={{ width: '100%' }}>
          <AdminCostComponent
            adminSchools={adminSchools}
            ctaSchools={ctaSchools}
            close={close}
            admin={admin}
            districtId={districtId}
            onSave={saveCTAAdministrator}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
