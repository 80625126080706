import { basetype } from 'types/base.types';

export interface ProgramContact extends basetype {
  advisorFirstName: string;
  advisorLastName: string;
  advisorEmail: string;
  advisorTitle: string;
  advisorOrganization: string;
  contactId: number;
  isActive?: boolean;
  modifiedBy: number;
  programContactTypeId: number;
  programInstanceId: number;
}

export const emptyProgramContact = (): ProgramContact => {
  return {
    programInstanceId: -1,
    contactId: -1,
    programContactTypeId: 255,
    advisorFirstName: '',
    advisorLastName: '',
    advisorEmail: '',
    advisorTitle: '',
    advisorOrganization: '',
    modifiedBy: -1,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  };
};

export interface ProgramContactType extends basetype {
  programContactTypeId: number;
  programContactTypeName: string;
  isActive: boolean;
}
