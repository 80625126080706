import { GRANTS_KEY, GRANTS_FISCAL_YEAR_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { GrantFiscalYear, emptyGrantFiscalYear } from 'types';
import { find, defaultTo } from 'lodash';
import { getPerkinsGrantFilters } from 'redux/filters/_selectors';

export const GRANT_FISCAL_YEAR_PATH = [GRANTS_KEY, GRANTS_FISCAL_YEAR_KEY];

export const getIsDataFetching_GrantFiscalYear = (state: any): boolean => {
  return getIsDataFetching(state, GRANT_FISCAL_YEAR_PATH);
};

export const shouldRefreshReduxState_GrantFiscalYear = (
  state: any
): boolean => {
  return shouldRefreshReduxState<GrantFiscalYear>(
    state,
    GRANT_FISCAL_YEAR_PATH
  );
};

export const getRecords_GrantFiscalYear = (
  state: unknown
): GrantFiscalYear[] => {
  return getRecords(state, GRANT_FISCAL_YEAR_PATH);
};

export const getGrantFiscalYear = (state: any, grantFiscalYearId: number) => {
  const records = getRecords_GrantFiscalYear(state);
  const result = find(records, { grantFiscalYearId });
  return defaultTo(result, emptyGrantFiscalYear());
};

export const getAdvancedFilteredRecords_GrantFiscalYear = (
  state: any
): GrantFiscalYear[] => {
  const filters = getPerkinsGrantFilters(state);
  const records = getAdvancedFilteredRecords<GrantFiscalYear>(
    state,
    GRANT_FISCAL_YEAR_PATH,
    filters
  );
  return records;
};

// For my favorite buttercup
export const getFiscalYearFromGFY = (state: any, grantFiscalYearId: number) => {
  const fy = getGrantFiscalYear(state, grantFiscalYearId);
  return fy ? fy.year : 1970;
};
