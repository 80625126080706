import { ProgramLevel, PerkinsBase } from 'types';

export interface School extends PerkinsBase {
  ctaFlag: boolean;
  cdeId: string;
  districtId: number;
  schoolId: number;
  schoolName: string;
  programLevels?: ProgramLevel[];
  isPrimary: boolean;
  isPerkinPrimary: boolean;
  isCTASupervisor: boolean;
  isCharterSchool: boolean;
  isBusiness: boolean;
}

export const emptySchool = (): School => {
  return {
    ctaFlag: false,
    cdeId: '',
    districtId: -1,
    schoolId: -1,
    schoolName: '',
    isPrimary: false,
    isPerkinPrimary: false,
    isCTASupervisor: false,
    isCharterSchool: false,
    isBusiness: false,
    perkinsInstitutionCode: '',
    perkinsVendorCode: '',
    dunsNumber: '',
  };
};
