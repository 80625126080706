import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Voucher } from 'types';
import {
  renderData,
  compose,
  prop,
  getDate,
  isNull,
  isFalse,
  len as length,
} from 'util/objectUtility';
import { getVoucherEntityType } from 'util/voucherUtility';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { useSelector, useDispatch } from 'react-redux';
import { getRecords_GrantFiscalYear } from 'redux/grants/_selectors';
import {
  convertBoolToRadioValue,
  createRadioOptionsForBool,
  isEqual,
  convertRadioToBoolValue,
} from 'util/formHelpers';
import { loadSingleGrantFiscalYear } from 'redux/grants/grantfiscalyear';
import { FlexRow } from 'layouts';
import { find, some, every } from 'lodash';
import { fmap } from 'util/arrayUtility';
import { createVoucherThreeQuestion } from 'services/grants/vouchers.services';
import { toast } from 'react-toastify';
import { loadVouchers } from 'redux/grants/vouchers';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Table from 'components/Common/Table';
import Radio from 'components/Common/FormElements/Radio';
import ToastNotification from 'components/Common/Toast';

type VoucherListComponentProps = {
  vouchers: Voucher[];
  isSummary?: boolean;
};

export const VoucherListComponent: React.FC<VoucherListComponentProps> = (
  props
) => {
  const { vouchers, isSummary = false } = props;

  const { grantFiscalYearId: fy }: any = useParams();
  const grantFiscalYearId = Number(fy);

  const [showRadios, setShowRadios] = useState<boolean>(false);
  const [submitVoucherThree, setSubmitVoucherThree] = useState<boolean | null>(
    null
  );

  const handleRadioChange = (
    data: Partial<{ submitVoucherThree: boolean }>
  ) => {
    const { val } = convertRadioToBoolValue(data);
    setSubmitVoucherThree(val);
  };

  const reduxData = useSelector(getRecords_GrantFiscalYear);
  const gfy = find(reduxData, { grantFiscalYearId });

  const history = useHistory();
  const navigate = (row: Voucher) => {
    const voucherType = getVoucherEntityType(row.voucherName);
    history.push(
      `/grants/applications/${voucherType}/voucher-detail/${grantFiscalYearId}`
    );
  };

  const saveSubmitVoucherThree = () => {
    if (!isNull(submitVoucherThree)) {
      createVoucherThreeQuestion(grantFiscalYearId, submitVoucherThree!)
        .then(() => {
          toast(<ToastNotification status="success" message="Success" />);
        })
        .then(() => {
          dispatch(loadSingleGrantFiscalYear(grantFiscalYearId));
          dispatch(loadVouchers(grantFiscalYearId));
        });
    }
  };

  const allClosed = compose(
    every,
    fmap(
      compose(
        isFalse,
        prop('isOpen')
      )
    )
  );

  const voucherName = compose(
    renderData,
    prop('voucherName')
  );

  const submissionDate = compose(
    getDate,
    prop('submissionDate')
  );

  const getVoucherDueDate = (voucher: Voucher) => {
    return voucher.voucherTypeId === 4 ? voucher.voucherClose : voucher.dueDate;
  };
  const dueDate = compose(
    getDate,
    getVoucherDueDate
  );

  const reimbursementRequested = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('reimbursementRequested')
  );

  const renderNavIcon = (row: Voucher) => {
    return (
      <React.Fragment>
        {row.isOpen ? (
          <IconButton
            name="pencil"
            tooltip="Edit Voucher"
            asLink={false}
            onClick={() => navigate(row)}
          />
        ) : (
          <IconButton
            name="lock"
            tooltip="Voucher is not open for editing"
            asLink={false}
            onClick={() => navigate(row)}
          />
        )}
      </React.Fragment>
    );
  };

  const VOUCHER_HEADERS = (): TableColumnProps[] => {
    const baseHeaders = [
      {
        name: 'Voucher #',
        cell: voucherName,
        selector: voucherName,
      },
      {
        name: 'Submission Date',
        cell: submissionDate,
        selector: submissionDate,
      },
      {
        name: 'Due Date',
        cell: dueDate,
        selector: dueDate,
      },
      {
        name: 'Reimbursement Requested',
        cell: reimbursementRequested,
        selector: reimbursementRequested,
      },
    ];
    if (isSummary) {
      return baseHeaders;
    }
    return [
      ...baseHeaders,
      {
        name: 'Edit',
        cell: renderNavIcon,
        selector: renderNavIcon,
        style: {
          width: '8%',
        },
      },
    ];
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSingleGrantFiscalYear(grantFiscalYearId));
  }, [grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    const submitVoucherThree = prop('submitVoucherThree', gfy);
    const voucherTwoApproved = prop('voucherTwoApproved', gfy);
    if (
      gfy !== undefined &&
      some([
        isEqual(true, isSummary),
        isEqual(true, submitVoucherThree),
        isEqual(false, submitVoucherThree),
        isEqual(false, voucherTwoApproved),
        isEqual(0, length(vouchers)),
      ])
    ) {
      setShowRadios(false);
    }
    if (
      gfy !== undefined &&
      every([
        isEqual(false, isSummary),
        allClosed(vouchers),
        isNull(submitVoucherThree),
        voucherTwoApproved,
      ])
    ) {
      setShowRadios(true);
    }
  }, [gfy, vouchers, isSummary]); //eslint-disable-line

  const getWarningText = () => {
    return submitVoucherThree ? 'accept' : 'decline';
  };

  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">Voucher List</h2>
      </FlexRow>
      {showRadios && (
        <React.Fragment>
          <FlexRow>
            <Radio
              label="Are you submitting the optional Voucher 3?"
              name="submitVoucherThree"
              onChange={handleRadioChange}
              options={createRadioOptionsForBool({
                optionTrue: 'Yes, I will submit voucher 3.',
                optionFalse: 'No, I will not submit voucher 3.',
              })}
              value={convertBoolToRadioValue(submitVoucherThree)}
            />
            <div className="u-flex-grow-1" />
            <div style={{ alignSelf: 'flex-end' }}>
              <ConfirmationDialog
                disabled={isNull(submitVoucherThree)}
                tooltip="Save voucher 3 intention"
                header="Save Voucher 3 Intention?"
                onConfirm={saveSubmitVoucherThree}
                activateButton={
                  <Button
                    disabled={isNull(submitVoucherThree)}
                    style={{ height: '5.4rem' }}
                    text="Save"
                    className="button--filled"
                  />
                }
              >
                <div style={{ whiteSpace: 'normal' }}>
                  <p>
                    Are you sure you want to <strong>{getWarningText()}</strong>{' '}
                    to open voucher 3? Voucher 3 is an optional voucher;
                    however, once you <strong>{getWarningText()}</strong>, the
                    decision is final and cannot be changed!
                  </p>
                </div>
              </ConfirmationDialog>
            </div>
          </FlexRow>
          <FlexRow>
            <p className="u-error">
              You must select an option before editing Voucher 3 or Final
              Voucher.
            </p>
          </FlexRow>
        </React.Fragment>
      )}
      <Table columns={VOUCHER_HEADERS()} data={vouchers} />
    </React.Fragment>
  );
};
