import { createAction, handleActions } from 'redux-actions';

const initialState = {
  currentNavAnchor: 'general',
  showDisabledUsers: false,
  programInstanceId: -1,
  programNav: 'general',
  navTrayClosed: false,
  workflowTrayClosed: false,
};

export const setShowDisabledUsers = createAction('setShowDisabledUsers');
export const setActiveProgramInstanceId = createAction(
  'setActiveProgramInstanceId'
);
export const setProgramNav = createAction('setProgramNav');
export const setNavTrayClosed = createAction('setNavTrayClosed');
export const setWorkflowTrayClosed = createAction('setWorkflowTrayClosed');
export const setCurrentNavAnchor = createAction('setCurrentNavAnchor');

export default handleActions(
  {
    setShowDisabledUsers: (state: any, { payload }) => {
      return { ...state, showDisabledUsers: payload };
    },

    setActiveProgramInstanceId: (state, { payload }) => {
      return { ...state, programInstanceId: payload };
    },

    setProgramNav: (state, { payload }) => {
      return { ...state, programNav: payload };
    },

    setNavTrayClosed: (state, { payload }) => {
      return { ...state, navTrayClosed: payload };
    },

    setWorkflowTrayClosed: (state, { payload }) => {
      return { ...state, workflowTrayClosed: payload };
    },
    setCurrentNavAnchor: (state, { payload }) => {
      return { ...state, currentNavAnchor: payload };
    },
  },
  initialState
);
