import React, { useState, useEffect, useRef } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import { map, some } from 'lodash';
import { getSecureComponentsForNavigation } from 'util/global';
import { fetchMenuItems } from 'constants/links';
import { HamburgerMenuItem } from './HamburgerMenuItem';
import { HamburgerMenuDetails } from 'types';

export const HamburgerMenuComponent: React.FC = () => {
  const links = fetchMenuItems();

  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [navFocused, setNavFocused] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement>(null);

  const handleClick = (ev: MouseEvent) => {
    let target = ev.target as HTMLElement;

    if (menu.current !== null && menu.current.contains(target)) {
      return;
    } else {
      setNavOpen(false);
    }
  };

  const getHamburgerClass = () => {
    return navFocused
      ? 'navigation__button navigation__button--focused'
      : 'navigation__button';
  };

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      setNavOpen(!navOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div className="navigation" ref={menu}>
      <div className="navigation__menu-btn">
        <input
          className="navigation__checkbox"
          checked={navOpen}
          id="navi-toggle"
          onKeyDown={handleEnter}
          onChange={() => setNavOpen(!navOpen)}
          onFocus={() => setNavFocused(true)}
          onBlur={() => setNavFocused(false)}
          type="checkbox"
        />
        <label
          aria-label="navigation menu"
          htmlFor="navi-toggle"
          className={getHamburgerClass()}
        >
          Menu
          <span className="navigation__icon">&nbsp;</span>
        </label>
      </div>
      <div className="navigation__menu--hover">
        <nav
          id="nav-menu"
          className={`navigation__menu ${navOpen && 'u-show'}`}
        >
          <h2 className="navigation__title">Menu</h2>
          {map(links, (link: HamburgerMenuDetails, index: number) => {
            const components = getSecureComponentsForNavigation(link.component);
            return link.component ? (
              <SecureWrap
                allowReadOnly={some([
                  link.component === 'programs',
                  link.component === 'ctageneral',
                  link.component === 'datacollection',
                ])}
                key={index}
                component={components}
              >
                <HamburgerMenuItem key={index} {...link} />
              </SecureWrap>
            ) : (
              <HamburgerMenuItem key={index} {...link} />
            );
          })}
        </nav>
        <p
          onClick={() => {
            document.getElementById('account-toggle')!.focus();
            setNavOpen(!navOpen);
          }}
          className="navigation__menu-title"
          aria-hidden
        >
          Menu
        </p>
      </div>
    </div>
  );
};
