import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionRoleComponent } from 'types';
import {
  createPermissionRoleComponent,
  readPermissionRoleComponents,
} from 'services/permissions/permissionrolecomponent.services';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const removePermissionRoleComponents = createActionThunk(
  'removePermissionRoleComponents',
  () => {
    return readPermissionRoleComponents();
  }
); // end

export const loadPermissionRoleComponents = createActionThunk(
  'loadPermissionRoleComponents',
  () => {
    return readPermissionRoleComponents();
  }
); // end

export const savePermissionRoleComponent = createActionThunk(
  'savePermissionRoleComponent',
  (roleId: number, payload: PermissionRoleComponent[]) => {
    return createPermissionRoleComponent(roleId, payload);
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, PermissionRoleComponent[]>(
  {
    ...baseLoadActionBuilder<PermissionRoleComponent[]>(
      loadPermissionRoleComponents
    ),

    [savePermissionRoleComponent.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [savePermissionRoleComponent.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
