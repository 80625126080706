import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  actionFetching,
  actionUpdateRecords,
  actionRecordFailure,
} from 'redux/utility/baseActionHandler';
import { BaseStateObject, baseInitialState } from 'redux/utility/baseInitialState';
import { CTADistrictRecord } from 'types';
import {
  readCTADistrictRecords,
  readCTASingleDistrictRecord,
  updateCTADistrictRecord,
} from 'services/cta/dashboardrecord.services';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadCTADistrictRecords = createActionThunk(
  'loadCTADistrictRecords',
  async (year: number) => {
    return readCTADistrictRecords(year);
  }
);
export const loadCTASingleDistrictRecord = createActionThunk(
  'loadCTASingleDistrictRecord',
  async (id: number) => {
    return readCTASingleDistrictRecord(id);
  }
);

export const saveCTAAssurances = createActionThunk(
  'saveCTAAssurances',
  async (record: CTADistrictRecord) => {
    return updateCTADistrictRecord(record);
  }
);

export default handleActions<BaseStateObject, CTADistrictRecord[]>(
  {
    ...baseLoadActionBuilder<CTADistrictRecord[]>(loadCTADistrictRecords),
    ...baseLoadActionBuilder<CTADistrictRecord[]>(saveCTAAssurances),

    [loadCTASingleDistrictRecord.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadCTASingleDistrictRecord.SUCCEEDED]: (state, action) => {
      const records = upsert<CTADistrictRecord>(state.records, action.payload, 'ctaId');
      return actionUpdateRecords(state, records);
    },
    [loadCTASingleDistrictRecord.FAILED]: (state) => {
      return actionRecordFailure(state);
    },
    [loadCTASingleDistrictRecord.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
