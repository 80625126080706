import React from 'react';
import { UCPersonalInfo, UniversalContact } from 'types';
import { FlexRow } from 'layouts';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import Input from 'components/Common/FormElements/Input';

type PersonalInfoProps = {
  personalInfo: UCPersonalInfo;
  onChange: Function;
  validations: ValidationObject<UniversalContact>;
};

export const PersonalInfo: React.FC<PersonalInfoProps> = (props) => {
  const { onChange, personalInfo, validations } = props;
  const { getError, getFieldValid, validate } = validations;

  return (
    <div className="form__section">
      <FlexRow>
        <h3 className="u-section-title-text">Personal Information</h3>
      </FlexRow>
      <FlexRow>
        <Input
          errorMessage={getError('firstName')}
          name="firstName"
          value={personalInfo.firstName}
          onChange={onChange}
          onBlur={() => validate('firstName', personalInfo.firstName)}
          valid={getFieldValid('firstName')}
        />
        <Input
          name="middleName"
          value={personalInfo.middleName}
          onChange={onChange}
        />
        <Input
          errorMessage={getError('lastName')}
          name="lastName"
          onBlur={() => {
            validate('lastName', personalInfo.lastName);
          }}
          onChange={onChange}
          valid={getFieldValid('lastName')}
          value={personalInfo.lastName}
        />
      </FlexRow>
      <FlexRow>
        <Input
          errorMessage={getError('title')}
          name="title"
          value={personalInfo.title}
          onChange={onChange}
          onBlur={() => validate('title', personalInfo.title)}
          valid={getFieldValid('title')}
        />
      </FlexRow>
    </div>
  );
};
