import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { reacCTASchoolTerms } from 'services/common/ctaschoolterms.services';
import { CTASchoolTerm } from 'types/cta/CTASchoolTerm.types';

const initialState = baseInitialState;

export const loadCTASchoolTerms = createActionThunk(
  'loadCTASchoolTerms',
  () => {
    return reacCTASchoolTerms();
  }
);

export default handleActions<BaseStateObject, CTASchoolTerm[]>(
  {
    ...baseLoadActionBuilder<CTASchoolTerm[]>(loadCTASchoolTerms),
  },
  initialState
);
