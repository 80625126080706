import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { StrategySelectItem } from 'types';
import {
  BaseStateObject,
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { readStrategySelectItem } from 'services/grants/addressableElements.services';

const initialState = baseInitialState;

export const loadStrategySelectItem = createActionThunk(
  'loadStrategySelectItem',
  (grantFiscalYearId: number) => {
    return readStrategySelectItem(grantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, StrategySelectItem[]>(
  {
    [loadStrategySelectItem.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadStrategySelectItem.SUCCEEDED]: (state, { payload }) => {
      return actionUpdateRecords(state, payload);
    },
    [loadStrategySelectItem.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
