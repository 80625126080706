import React, { useEffect, useState } from 'react';
import { Dialog } from '@rmwc/dialog';
import { SpendingCategoryValidations } from 'validations/AnnualSpendingPlan/SpendingCategory.validations';
import { defaultTo, map, every, find } from 'lodash';
import { emptySpendingCategory, SpendingCategory, AnnualSpending } from 'types';
import { firstMatch, prop } from 'util/objectUtility';
import {
  isAdminCategory,
  isDirectAdminCosts,
  isImprovementPlan,
  isNeedsAssessment,
} from 'util/annualSpendingUtility';
import { saveAnnualSpendingPlan } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { getAnnualSpendingPlan } from 'redux/grants/_selectors';
import Button from 'components/Common/FormElements/Button';
import CreateProjectExpenses from './CreateProjectExpenses';
import FundPoolingEditComponent from './FundPooling/';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ProjectSummary from './ProjectSummary';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type ProjectPlanProps = {
  isLoading: boolean;
  setCurrentNavAnchor: Function;
  isLocked: boolean;
};

export const ProjectSpendingPlan: React.FC<ProjectPlanProps> = (props) => {
  const { isLoading, setCurrentNavAnchor, isLocked } = props;

  // --[ dependencies ]--------------------------------------------------------
  const {
    isValid,
    validateAll,
    validationErrors,
  } = SpendingCategoryValidations();
  const dispatch = useDispatch();
  const history = useHistory();
  const { grantFiscalYearId: gId }: any = useParams();
  const grantFiscalYearId = Number(gId);
  const { strategyId: sId }: any = useParams();
  const strategyId = Number(sId);

  // --[ component logic ]-----------------------------------------------------
  const spendingPlan: AnnualSpending = useSelector(getAnnualSpendingPlan);
  const spendingCategory: SpendingCategory = defaultTo(
    find(spendingPlan.spendingCategories, { strategyId }),
    emptySpendingCategory()
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleSave = () => {
    if (validateAll(spendingCategory)) {
      dispatch(saveAnnualSpendingPlan(spendingPlan));
    }
  };

  const backUrl = `/grants/applications/spending-plans/${grantFiscalYearId}`;
  const handleCancelButton = () => {
    const ids = map(spendingCategory.expenses, prop('expenseId'));
    const valid = every(map(ids, (number: number) => number > 0));
    if (valid) {
      return history.push(backUrl);
    }
    setModalOpen(true);
  };

  const handleConfirm = () => {
    handleSave();
    setModalOpen(false);
  };

  const handleNoSave = () => {
    return history.push(backUrl);
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setCurrentNavAnchor('spending-plans');
  }, [setCurrentNavAnchor]);

  // --[ render logic ]--------------------------------------------------------
  const renderDirectAdminInstructions = () => (
    <Instruction title="Direct Administrative Costs">
      <p>
        Allocating Grant Funds toward Direct Administrative Costs is optional.
        The Formula is: Direct Costs = (Initial Award Estimate * 5%) - Allocated
        Indirect Costs. The Direct Funds Remaining will change as your Indirect
        Costs change.
      </p>
    </Instruction>
  );
  const renderNeedsAssessmentInstructions = () => (
    <Instruction title="Needs Assessment Costs">
      <p>
        While optional, if your institution chooses to allocate funds towards
        conducting the Needs Assessment, you are required to provide detailed
        expenses here.
      </p>
    </Instruction>
  );
  const renderImprovementPlanInstructions = () => (
    <Instruction title="Improvement Plan Project">
      <p>
        You are required to allocate 30% of your Institution’s Total Grant Award
        toward an Improvement Plan. An Improvement Plan is required for your
        institution because your school scored below State Metrics by 10% or
        more.
      </p>
      <br />
      <p>
        Create expenses to allocate all project funds. In order to submit your
        Annual Spending Plan, the remaining funds balance has to be zero. At
        least one expense is required. Once the Funds Reamining for this project
        is zero, we recommend creating several "Backup Expenses" in case more
        funds become available for your institution.
      </p>
    </Instruction>
  );
  const renderDefaultInstructions = () => (
    <Instruction title="Project Plan">
      <p>
        Create Expenses to allocate all Project Funds. In order to submit your
        Annual Spending Plan, the Remaining Funds Balance has to be zero. At
        least one expense is required. Once the Funds Remaining for this project
        is zero, we recommend creating several Backup Expenses in case more
        funds become available for your institution.
      </p>
    </Instruction>
  );

  // renderInstructions :: SpendingCategory -> JSX
  const renderInstructions = firstMatch([
    [isDirectAdminCosts, renderDirectAdminInstructions],
    [isNeedsAssessment, renderNeedsAssessmentInstructions],
    [isImprovementPlan, renderImprovementPlanInstructions],
    [() => true, renderDefaultInstructions],
  ]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading
        isActive={isLoading}
        messageBefore="Loading Annual Spending Plan Data..."
      />
      <Paper>{renderInstructions(spendingCategory)}</Paper>
      {!isAdminCategory(spendingCategory) && (
        <React.Fragment>
          <Paper>
            <Instruction title="Project Summary">
              {isImprovementPlan(spendingCategory) ? (
                <React.Fragment>
                  <p>
                    Please provide a description of the project to illustrate
                    how your funds will address its needs.
                  </p>
                  <br />
                  <p>Improvement Plan projects should also include:</p>
                  <ul>
                    <li>Reason project is a priority</li>
                    <li>Explanation of planned activities</li>
                    <li>Desired outcomes</li>
                    <li>
                      Links to performance and special populations where
                      appropriate
                    </li>
                    <li>
                      How it relates to institution’s overall goals (CTE or
                      otherwise)
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                <p>
                  Please provide a description of the project to illustrate how
                  your funds will address its needs.
                </p>
              )}
            </Instruction>
            <SecureWrap
              isLocked={isLocked}
              component={['grantsannualspending']}
            >
              <ProjectSummary spendingCategory={spendingCategory} />
            </SecureWrap>
          </Paper>
          {!isImprovementPlan(spendingCategory) && (
            <Paper>
              <SecureWrap
                isLocked={isLocked}
                component={['grantsannualspending']}
              >
                <FundPoolingEditComponent spendingCategory={spendingCategory} />
              </SecureWrap>
            </Paper>
          )}
        </React.Fragment>
      )}
      {(!spendingCategory.isFundPool || spendingCategory.isPartnershipLead) && (
        <Paper>
          <h2>Create Expenses</h2>

          <CreateProjectExpenses
            isLocked={isLocked}
            spendingCategory={spendingCategory}
          />
        </Paper>
      )}
      <Paper>
        <SaveButtons
          cancelText="Return to Annual Spending Plan"
          disabled={!isValid}
          onCancel={handleCancelButton}
          saveText="Save Expenses"
          alternateAction={handleSave}
          validationErrors={validationErrors}
        />
      </Paper>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="dialog">
          <h2 className="dialog__title">
            Would you like to save your expenses?
          </h2>
          <div className="dialog__children">
            <p className="u-error">
              Caution: You have created new expenses which have not been saved.
              Would you like to save them now?
            </p>
          </div>
          <div className="dialog__btn-container">
            <Button
              onClick={handleNoSave}
              className="dialog__btn--cancel"
              text="Do not save"
            />
            <Button
              onClick={handleConfirm}
              className="confirm-button dialog__btn--submit"
              text="Yes, save changes"
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
