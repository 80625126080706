import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
  deleteWithAuthOptions,
} from 'services';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';
import { AdminBooksAndSupplies } from 'types';

const SERVICE_NAME = 'CTA Administrative Costs';

const BASE = ['api', 'CtaAdminCosts'];
const BASE_DIRECTOR = [...BASE, 'director'];
const BASE_SPECIALIST = [...BASE, 'specialist'];
const BASE_SUPPLIES = [...BASE, 'supplies'];

export const readCTASupplies = async (
  ctaId: number
): Promise<AdminBooksAndSupplies[]> => {
  return fetch(getFormattedUrl([...BASE_SUPPLIES, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'readCTASpecialists');
    });
};

export const updateCTASupplies = async (
  ctaId: number,
  item: AdminBooksAndSupplies
) => {
  return fetch(getFormattedUrl([...BASE_SUPPLIES, ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(item),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'updateCTASpecialists');
    });
};

export const readCTASpecialists = async (
  ctaId: number
): Promise<CTAAdministrator[]> => {
  return fetch(getFormattedUrl([...BASE_SPECIALIST, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'readCTASpecialists');
    });
};

export const updateCTASpecialists = async (item: CTAAdministrator) => {
  return fetch(getFormattedUrl(BASE_SPECIALIST), {
    ...postWithAuthOptions(),
    body: JSON.stringify(item),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'updateCTASpecialists');
    });
};

export const deleteCTASpecialists = async (item: CTAAdministrator) => {
  return fetch(getFormattedUrl(BASE_SPECIALIST), {
    ...deleteWithAuthOptions(),
    body: JSON.stringify(item),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'deleteCTASpecialists');
    });
};

export const readCTADirectors = async (
  ctaId: number
): Promise<CTAAdministrator[]> => {
  return fetch(getFormattedUrl([...BASE_DIRECTOR, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'readCTADirectors');
    });
};

export const updateCTADirectors = async (item: CTAAdministrator) => {
  return fetch(getFormattedUrl(BASE_DIRECTOR), {
    ...postWithAuthOptions(),
    body: JSON.stringify(item),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'updateCTADirectors');
    });
};

export const deleteCTADirectors = async (item: CTAAdministrator) => {
  return fetch(getFormattedUrl(BASE_DIRECTOR), {
    ...deleteWithAuthOptions(),
    body: JSON.stringify(item),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(SERVICE_NAME, err, 'deleteCTASpecialists');
    });
};
