import React, { useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { every, some } from 'lodash';
import { ProgramStep, Program, School, District } from 'types';
import IconButton from 'components/Common/IconButton';
import Button from 'components/Common/FormElements/Button';
import Paper from 'components/Common/Paper';
import ProgramStepSideNav from 'components/Program/ProgramStepSideNav';
import ReactToPrint from 'react-to-print';
import Skeleton from 'components/Common/Skeleton';
import WorkflowTray from 'components/Common/GeneralWorkflow/WorkflowTray';

type ProgramLayoutProps = {
  children: any;
  district?: District;
  loadSingleProgramInstance: Function;
  programIsLoaded: boolean;
  programLevel: string;
  programSteps: ProgramStep[];
  program: Program;
  school?: School;
  setActiveProgramInstanceId: Function;
  setProgramNav: Function;
};

export const ProgramLayout: React.FC<ProgramLayoutProps> = (props) => {
  const {
    children,
    loadSingleProgramInstance,
    program,
    school,
    district,
    setActiveProgramInstanceId,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);
  const history = useHistory();
  const { pathname } = useLocation();

  /* ensures the program is loaded */
  useEffect(() => {
    const value = isApplicaionIdValid(programInstanceId);
    if (value) {
      setActiveProgramInstanceId(programInstanceId);
      loadSingleProgramInstance(programInstanceId);
    }
  }, [
    loadSingleProgramInstance,
    setActiveProgramInstanceId,
    programInstanceId,
  ]);

  const urlIncludes = (url: string, str: string) => {
    return url.toLocaleLowerCase().includes(str);
  };

  const getCurrentStep = (): string => pathname.split('/')[2];
  const hideHeader = () => {
    return some([
      getCurrentStep() === 'create-program',
      getCurrentStep() === 'pos-overview',
    ]);
  };

  const isCreateProgram = () => {
    return getCurrentStep() === 'create-program';
  };

  const renderWorkflowTray = () => {
    return every([
      !isCreateProgram(),
      !urlIncludes(pathname, 'approval'),
      !urlIncludes(pathname, 'super-summary'),
    ]);
  };

  const renderSideNav = () => {
    return every([
      !isCreateProgram(),
      !urlIncludes(pathname, 'change-history'),
      !urlIncludes(pathname, 'super-summary'),
    ]);
  };
  const printArea = useRef<HTMLDivElement>(null);
  return (
    <div id="general" className="program">
      {renderWorkflowTray() ? (
        <WorkflowTray entityType="programs" entityId={programInstanceId} />
      ) : (
        <div />
      )}
      <section className="program__body" ref={printArea}>
        {hideHeader() ? null : (
          <Paper>
            <div className="program__header u-flex">
              <div className="u-flex-grow-1">
                <h2 className="program__title">
                  {program.programName ? program.programName : <Skeleton />}
                </h2>
                {school && school.schoolName !== 'UNKNOWN' ? (
                  <h3 className="program__subtitle">
                    {school.schoolName}
                    {district &&
                      district.districtName !== 'UNKNOWN' &&
                      ` | ${district.districtName}`}
                  </h3>
                ) : null}
              </div>

              {!urlIncludes(pathname, 'super-summary') ? (
                <div>
                  <Button
                    text="View Printable Summary"
                    onClick={() =>
                      history.push(
                        `/programs/program-steps/super-summary/${pId}`
                      )
                    }
                    className="button--filled"
                  />
                </div>
              ) : (
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div className="no-print u-pseudo-link">
                        <IconButton
                          tooltip="Print"
                          name="print"
                          className="history__print__icon"
                        />
                      </div>
                    );
                  }}
                  content={() => {
                    const parea = printArea as React.MutableRefObject<
                      HTMLInputElement
                    >;
                    return parea.current;
                  }}
                />
              )}
            </div>
          </Paper>
        )}
        <div className="program__content">{children}</div>
      </section>
      {renderSideNav() ? <ProgramStepSideNav pathname={pathname} /> : <div />}
    </div>
  );
};
