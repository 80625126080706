import { useEffect } from 'react';

type ScrollToTopProps = {
  smooth?: boolean;
};

export const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const { smooth } = props;

  useEffect(() => {
    if (!smooth) {
      window.scrollTo(0, 0);
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [smooth]);

  return null;
};
