import React from 'react';
import { ProgramOfStudy, Program } from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { defaultTo } from 'lodash';
import { compose, prop } from './objectUtility';

// getInstitutionOrSchoolName :: ProgramOfStudy Program -> string
export const getInstitutionOrSchoolName = (
  item: ProgramOfStudy,
  program: Program
) => {
  const { programLevelId } = program;

  if (Number(programLevelId) === PROGRAM_LEVEL_ENUM.Secondary) {
    let schoolName = item.postsecondarySchool
      ? item.postsecondarySchool.schoolName
      : EMPTY_PLACEHOLDER;

    let departmentName = item.postsecondaryDepartment
      ? item.postsecondaryDepartment.departmentName
      : EMPTY_PLACEHOLDER;

    if (departmentName === 'UNKNOWN') {
      departmentName = '';
    }
    return `${schoolName}${departmentName ? `: ${departmentName}` : ''}`;
  } else {
    return item.secondaryProgram
      ? defaultTo(
          compose(
            prop('schoolName'),
            prop('secondarySchool')
          )(item),
          EMPTY_PLACEHOLDER
        )
      : EMPTY_PLACEHOLDER;
  }
};

// getPartnerProgramName :: ProgramOfStudy Program -> string
export const getPartnerProgramName = (
  item: ProgramOfStudy,
  program: Program
) => {
  const { programLevelId } = program;
  if (Number(programLevelId) === PROGRAM_LEVEL_ENUM.Secondary) {
    return item.postSecondaryProgram ? (
      <a
        href={`/programs/program-steps/${
          item.postSecondaryProgram.programInstanceId
        }`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.postSecondaryProgram.programName}
      </a>
    ) : (
      EMPTY_PLACEHOLDER
    );
  } else {
    return item.secondaryProgram ? (
      <a
        href={`/programs/program-steps/${
          item.secondaryProgram.programInstanceId
        }`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.secondaryProgram.programName}
      </a>
    ) : (
      EMPTY_PLACEHOLDER
    );
  }
};
