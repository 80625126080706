import ContactRoutes from 'routes/contacts.routes';
import CtaRoutes from 'routes/cta.routes';
import DataCollection from 'routes/dataCollection.routes';
import DevToolRoutes from 'routes/devTools.routes';
import GrantRoutes from 'routes/grants.routes';
import HomeRoute from 'routes/home.routes';
import ProgramRoutes from 'routes/programs.routes';
import ChangeLogRoutes from 'routes/changelog.routes';
import RepotingRoutes from 'routes/reports.routes';
import AdminRoutes from 'routes/admin.routes';

const routes = [
  ContactRoutes,
  CtaRoutes,
  DataCollection,
  DevToolRoutes,
  GrantRoutes,
  HomeRoute,
  ProgramRoutes,
  ChangeLogRoutes,
  RepotingRoutes,
  AdminRoutes,
];

export default routes;
