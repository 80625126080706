import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import CreateProgram from 'components/Program/CreateProgram/CreateProgram';
import { UniversalContact, Institute } from 'types';

type ProgramCreateStepProps = {
  method: string;
  user: UniversalContact;
  institutes: Institute[];
};

export const ProgramCreateStep: React.FC<ProgramCreateStepProps> = (props) => {
  const { method, user, institutes } = props;

  const createComponent = () => {
    switch (method) {
      case 'create':
        return <CreateProgram user={user} institutes={institutes} />;
      case 'clone':
      default:
        return null;
    }
  };

  return (
    <BaseLayout>
      <ProgramLayout {...props}>{createComponent()}</ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramCreateStep;
