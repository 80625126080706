import React from 'react';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';

export const StateStrategicGoals: React.FC = () => {
  return (
    <div className="goals">
      <div className="goals__row">
        <p className="goals__goal">Goal 1</p>
        <p className="goals__description">
          <span className="goals__description--title">
            Career Advisement & Development
          </span>
          {` ${EMPTY_PLACEHOLDER}`} Ensure each Colorado Learner has access to
          ongoing career advisement and development.
        </p>
      </div>
      <div className="goals__row">
        <p className="goals__goal goals__goal--orange">Goal 2</p>
        <p className="goals__description">
          <span className="goals__description--title">
            Instructor Recruitment & Retention
          </span>
          {` ${EMPTY_PLACEHOLDER}`} Develop and ensure a viable pipeline of CTE
          instructors to fill the educator positions at the middle school,
          secondary, and postsecondary levels.
        </p>
      </div>
      <div className="goals__row">
        <p className="goals__goal goals__goal--green">Goal 3</p>
        <p className="goals__description">
          <span className="goals__description--title">Partnerships</span>
          {` ${EMPTY_PLACEHOLDER}`} Prepare Colorado's future workforce through
          transformational partnerships.
        </p>
      </div>
      <div className="goals__row">
        <p className="goals__goal goals__goal--dark-grey">Goal 4</p>
        <p className="goals__description">
          <span className="goals__description--title">Quality Programs</span>
          {` ${EMPTY_PLACEHOLDER}`} Elevate the quality of CTE programs to
          support each learner's skill development and meet industry needs.
        </p>
      </div>
      <div className="goals__row">
        <p className="goals__goal goals__goal--light-blue">Goal 5</p>
        <p className="goals__description">
          <span className="goals__description--title">Work-Based Learning</span>
          {` ${EMPTY_PLACEHOLDER}`} Ensure access to meaningful work-based
          learning opportunities for each CTE learner.
        </p>
      </div>
      <div className="goals__elements">
        <p className="goals__goal goals__goal--elements">
          Foundational Elements
        </p>
        <div className="goals__element-items">
          <div className="goals__element-group">
            <p className="goals__element-title">Equity & Access</p>
            <p className="goals__element-description">
              Create opportunities for each learner to access quality CTE
              programs and distribution of CTE programs throughout the state.
            </p>
          </div>
          <div className="goals__element-group">
            <p className="goals__element-title">Inclusivity</p>
            <p className="goals__element-description">
              Provide each learner with the opportunity to participate in a CTE
              program and decrease the gaps of the CTE participation for target
              populations, non-traditional occupations, and geographic areas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
