import { QuestionGroup } from 'types';
import { curry } from 'ramda';

export const GRANT_FOUR_YEAR = '[GRANT_FOUR_YEAR]';

export const FETCH_FOUR_YEAR = `${GRANT_FOUR_YEAR} FETCH`;
export const SAVE_FOUR_YEAR = `${GRANT_FOUR_YEAR} SAVE`;

export const loadFourYearQuestions = curry(
  (entityType: string, entityId: number) => {
    return {
      type: FETCH_FOUR_YEAR,
      payload: {
        entityType,
        entityId,
      },
    };
  }
);

export const saveFourYearQuestions = curry(
  (entityType: string, entityId: number, questions: QuestionGroup[]) => {
    return {
      type: SAVE_FOUR_YEAR,
      payload: {
        entityType,
        entityId,
        questions,
      },
    };
  }
);
