import { useValidation } from 'de-formed-validations';
import { CTASupportPersonnel } from 'types';
import { length, lt as gt, gte as lte, all } from 'ramda';
import { compose, isTruthy, prop } from 'util/objectUtility';
import { CTAFiscalYearProgramSupportValidation } from './CTAFiscalYearProgramSupport.validations';
import { fmap, isUniqueObjectList } from 'util/arrayUtility';
import { sumNumbers } from 'util/mathHelpers';
import { actualSalaryEarned, annualContractBaseSalary } from 'util/validation.utility';

export const SupportingPersonnelValidation = () => {
  const { validateAll: validateProgram } = CTAFiscalYearProgramSupportValidation();
  return useValidation<CTASupportPersonnel>({
    annualContractBaseSalary,
    actualSalaryEarned,
    personnelName: [
      {
        errorMessage: 'Personnel Name is required.',
        validation: compose(gt(0), length),
      },
    ],
    subsSickAnnual: [
      {
        errorMessage: 'Cannot exceed 89 days.',
        validation: lte(89),
      },
    ],
    subsCteAbsenses: [
      {
        errorMessage: 'Cannot exceed 89 days.',
        validation: lte(89),
      },
    ],
    ctaFiscalYearProgramSupport: [
      {
        errorMessage: 'Not all program affiliations are valid.',
        validation: compose(all(isTruthy), fmap(validateProgram)),
      },
      {
        errorMessage: 'School / Program cannot contain duplicates.',
        validation: isUniqueObjectList('ctaProgramId'),
      },
      {
        errorMessage: 'Combined CTE % cannot exceed 100%.',
        validation: compose(lte(100), sumNumbers, fmap(prop('ctePercent'))),
      },
    ],
  });
};
