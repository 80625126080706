import React, { useState, useEffect } from 'react';
import { clone, remove } from 'lodash';
import { useParams } from 'react-router-dom';
import { upsertInPlace } from 'util/arrayUtility';
import { createTempId } from 'util/formHelpers';
import {
  ProgramPathway,
  emptyProgramPathway,
  CareerPathway,
  ProgramStep,
} from 'types';
import { isApplicaionIdValid } from 'util/global';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose, renderData, prop } from 'util/objectUtility';
import CareerPathwaySelectBox from 'components/Common/SelectBoxes/CareerPathwaySelectBox';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';

type CareerPathwayEditProps = {
  isFetching: boolean;
  loadCareerPathways: Function;
  loadProgramCareerPathways: Function;
  nextProgramStep: ProgramStep;
  programCareerPathways: ProgramPathway[];
  readOnly: boolean;
  saveProgramCareerPathways: Function;
  setCurrentNavAnchor: Function;
};

export const CareerPathwayEditComponent: React.FC<CareerPathwayEditProps> = (
  props
) => {
  const {
    isFetching,
    loadCareerPathways,
    loadProgramCareerPathways,
    nextProgramStep,
    programCareerPathways,
    readOnly,
    saveProgramCareerPathways,
    setCurrentNavAnchor,
  } = props;

  const [pathwayState, setPathwayState] = useState<ProgramPathway[]>(
    clone(programCareerPathways)
  );
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    setPathwayState(clone(programCareerPathways));
  }, [programCareerPathways]);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadCareerPathways(programInstanceId);
      loadProgramCareerPathways(programInstanceId);
    }
  }, [programInstanceId, loadProgramCareerPathways, loadCareerPathways]);

  useEffect(() => {
    setCurrentNavAnchor('pathways');
  }, [setCurrentNavAnchor]);

  const emitFullCareerPathway = (data: CareerPathway) => {
    const { careerPathwayId, careerPathwayName, programLevelId } = data;
    const programCareerPathwayId = createTempId<ProgramPathway>(
      pathwayState,
      'programCareerPathwayId'
    );
    const isMiddleSchool = programLevelId === 5;

    const newPathway: ProgramPathway = {
      ...emptyProgramPathway(),
      careerPathwayId,
      careerPathwayName,
      isMiddleSchool,
      programCareerPathwayId,
      programInstanceId,
    };
    setPathwayState(upsertInPlace(pathwayState, newPathway, 'careerPathwayId'));
  };

  const onPathwayDelete = (pathwayId: number) => {
    const updatedPathways = remove(pathwayState, (pathway) => {
      return pathway.careerPathwayId !== pathwayId;
    });
    setPathwayState(updatedPathways);
  };

  const handleSave = () => {
    return saveProgramCareerPathways(pathwayState, programInstanceId);
  };

  const CareerPathwayName = compose(
    renderData,
    prop('careerPathwayName')
  );

  const renderTrashCan = (row: ProgramPathway) => (
    <ConfirmationDialog
      tooltip="Delete"
      header="Confirm Delete"
      onConfirm={() => onPathwayDelete(row.careerPathwayId)}
    >
      <h3>Are you sure you wish to delete {row.careerPathwayName}?</h3>
    </ConfirmationDialog>
  );

  const headers: TableColumnProps[] = [
    {
      name: 'Career Pathway',
      cell: CareerPathwayName,
      sortable: true,
      selector: CareerPathwayName,
    },
    {
      name: 'Delete',
      cell: renderTrashCan,
      center: true,
      width: '80px',
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading
        isActive={isFetching}
        messageBefore="Loading Career Pathways..."
      />
      <SecureWrap component="programs" isLocked={readOnly}>
        <Paper>
          <Instruction title="Career Pathways Information">
            <p>
              Type the first few letters of the career pathway name you wish to
              select and click on the appropriate option.
            </p>
          </Instruction>
          <FlexRow>
            <CareerPathwaySelectBox
              isLoading={isFetching}
              getFullObject={emitFullCareerPathway}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <Table columns={headers} data={pathwayState} />
            </FlexGroup>
          </FlexRow>
        </Paper>
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            saveData={handleSave}
            saveUrl={nextProgramStep.nav.url}
            saveText="Save and Continue"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
