import React from 'react';
import { Question } from 'types';

type QuestionLinkProps = {
  question: Question;
};

export const QuestionLink: React.FC<QuestionLinkProps> = ({ question }) => {
  const { link, linkName } = question;

  return link && linkName ? (
    <div className="u-flex" style={{ marginBottom: '1.6rem' }}>
      <a
        style={{ fontSize: '1.8rem', fontWeight: 650 }}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkName}
      </a>
      <div className="u-flex-grow-1" />
    </div>
  ) : link ? (
    <div className="assurance-question__child">
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    </div>
  ) : null;
};
