import { FiscalYear } from 'types/grant/FiscalYear.types';

export interface GrantSchedule extends FiscalYear {
  grantScheduleId: number;
  applicationName: string;
  filePath: string;
  fiscalYear: number;
  isOpen: boolean;
  isOverride: boolean;
  status: boolean;
  voucherClose: Date | undefined;
}

export const emptyGrantSchedule = (): GrantSchedule => {
  return {
    year: 1970,
    applicationName: '',
    endDate: undefined,
    filePath: '',
    fiscalYear: -1,
    grantScheduleId: -1,
    isOpen: false,
    isOverride: false,
    startDate: undefined,
    status: false,
    voucherClose: undefined,
    isCurrent: false,
  };
};

// --[ Grant Schedule UI Types ]-----------------------------------------------
export type GrantScheduleAvailableOption = {
  toggleOpen: boolean;
  fileUpload: string | boolean;
  voucher: boolean;
};

export const defaultGrantScheduleOption = () => {
  return {
    toggleOpen: true,
    fileUpload: true,
    voucher: true,
  };
};

export type GrantScheduleOptions = {
  'intent-to-participate': GrantScheduleAvailableOption;
  'initial-award-estimate': GrantScheduleAvailableOption;
  'four-year-plan': GrantScheduleAvailableOption;
  'two-year-plan': GrantScheduleAvailableOption;
  'spending-plans': GrantScheduleAvailableOption;
  'final-award-packet': GrantScheduleAvailableOption;
  'improvement-plan': GrantScheduleAvailableOption;
  default: GrantScheduleAvailableOption;
};

export const getGrantScheduleOptions = (
  stepName: keyof GrantScheduleOptions
): GrantScheduleAvailableOption => {
  const options: GrantScheduleOptions = {
    'intent-to-participate': {
      toggleOpen: true,
      fileUpload: false,
      voucher: false,
    },
    'initial-award-estimate': {
      toggleOpen: false,
      fileUpload: 'Awards',
      voucher: false,
    },
    'four-year-plan': {
      toggleOpen: true,
      fileUpload: 'Needs Assessment',
      voucher: false,
    },
    'two-year-plan': {
      toggleOpen: true,
      fileUpload: false,
      voucher: false,
    },
    'spending-plans': {
      toggleOpen: true,
      fileUpload: false,
      voucher: true,
    },
    'final-award-packet': {
      toggleOpen: true,
      fileUpload: 'Final Awards',
      voucher: false,
    },
    'improvement-plan': {
      toggleOpen: true,
      fileUpload: false,
      voucher: false,
    },
    default: {
      ...defaultGrantScheduleOption(),
    },
  };
  return options[stepName] ?? options['default'];
};
