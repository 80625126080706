import React, { useState, useEffect, useRef } from 'react';
import { clone } from 'lodash';
import { updateProgramAssurance } from 'services/programs/programassurance.service';
import { catchError } from 'services';
import { FlexRow, FlexGroup } from 'layouts';
import { AzureUploadParameters, QuestionGroup } from 'types';
import { QuestionsGroupLayout } from 'components/Common/Questions/QuestionsGroupLayout.component';
import { allQuestionsAnswered } from 'util/formHelpers';
import Button from 'components/Common/FormElements/Button';
import Textarea from 'components/Common/FormElements/Textarea';

export type WorkflowApprovalProps = {
  programInstanceId: number;
  submitProgramForApproval: Function;
  assurances: QuestionGroup;
};

export const SubmissionComponent: React.FC<WorkflowApprovalProps> = (props) => {
  const { programInstanceId, submitProgramForApproval, assurances } = props;

  const [canSave, setCanSave] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const mountedRef = useRef(true);

  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };

  const handleSubmit = () => {
    toggleHasRequested();
    updateProgramAssurance([assuranceState], programInstanceId)
      .then(() => {
        submitProgramForApproval(programInstanceId, comment).catch(
          (err: any) => {
            return catchError('submitProgramForApproval', err);
          }
        );
      })
      .catch((err: any) => {
        return catchError('updateProgramAssurance', err);
      });
  };

  const [assuranceState, setAssuranceState] = useState<QuestionGroup>(
    clone(assurances)
  );

  useEffect(() => {
    setAssuranceState(clone(assurances));
  }, [assurances]);

  useEffect(() => {
    if (assuranceState) {
      setCanSave(allQuestionsAnswered([assuranceState]));
    }
  }, [assuranceState, allQuestionsAnswered]); // eslint-disable-line

  const updateGroup = async (group: QuestionGroup) => {
    setAssuranceState(group);
  };

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|submission-assurances`,
  };

  return (
    <React.Fragment>
      <FlexRow>
        <FlexGroup>
          <h3>Instructions</h3>
          <p>
            Please verify all assurances. You must check all boxes to submit the
            program.
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <QuestionsGroupLayout
            uploadParams={uploadParams}
            flat={true}
            onChange={updateGroup}
            group={assuranceState}
          />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <Textarea
          name="comment"
          value={comment}
          onChange={(data: any) => {
            setComment(data.comment);
          }}
          label="Additional comments, if applicable"
        />
      </FlexRow>
      <div className="form__btn-container u-flex">
        <div className="u-flex-grow-1" />
        <Button
          disabled={!canSave || hasRequested}
          onClick={handleSubmit}
          text="Submit"
          className="form__btn--submit"
        />
      </div>
    </React.Fragment>
  );
};
