import React from 'react';
import { ProgramPathway, PathwayCourse } from 'types';
import { FlexGroup } from 'layouts';
import { filter, map, reduce } from 'lodash';
import WarningAlert from 'components/Common/WarningAlert';

type CourseSummaryTotalProps = {
  programPathway: ProgramPathway;
};

export const CourseSummaryTotal: React.FC<CourseSummaryTotalProps> = (
  props
) => {
  const { programPathway } = props;
  const { courses } = programPathway;

  const getValue = (course: PathwayCourse) => Number(course.contactHours);
  const sum = (a: number, b: number) => Number(a) + Number(b);

  const levelHours = (num: number) => {
    const filtered = filter(courses, (c: PathwayCourse) => {
      return c.courseLevel === num && c.optIn;
    });
    return map(filtered, (c: PathwayCourse) => {
      return getValue(c);
    });
  };
  const sumLevel = (num: number) => reduce(levelHours(num), sum, 0);

  const totals = [sumLevel(1), sumLevel(2), sumLevel(3), sumLevel(4)];
  const diffLevels = filter(totals, (num: number) => num > 0).length;
  const total = reduce(totals, sum, 0);

  /* In total, each pathway must have >=240 contact hrs */
  /* Each Pathway should have no more than 2000 contact hrs */
  const validateTotal = () => {
    return total < 240 || total > 2000
      ? 'Each pathway must have a minimum of 240 contact hours.'
      : null;
  };

  /* For each level that is offered, contact hours should be >=60 contact hrs */
  const validateLevelHours = () => {
    let error = false;
    let numErrors = 0;
    for (const t of totals) {
      if (t < 60) {
        error = true;
        ++numErrors; //TODO: this is very crude but works
      }
    }
    return error && numErrors > 2
      ? 'Course levels must have a minimum of 60 contact hours.'
      : null;
  };

  /* A pathway must have courses at >=2 levels */
  const validateMinLevels = () => {
    return diffLevels < 2
      ? 'Pathways must have minimum of 2 course levels.'
      : null;
  };

  /* Each opted-in course must have Contact Hours & a Course Level added */
  const validateHasHours = () => {
    let error = false;
    for (let num = 1; num < 5; num++) {
      for (const t of levelHours(num)) {
        if (t === 0) {
          error = true;
        }
      }
    }
    return error ? 'All courses must have contact hours.' : null;
  };

  const validate = () => {
    return [
      validateMinLevels(),
      validateLevelHours(),
      validateHasHours(),
      validateTotal(),
    ];
  };

  return (
    <div className="course-summary">
      <FlexGroup>
        <div className="course-summary__row">
          <div className="course-summary__row">
            <p className="course-summary__label">Total Contact Hours:</p>
            <p className="course-summary__data">{total}</p>
          </div>
          {map(totals, (total: number, index: number) => {
            return (
              <div key={index} className="course-summary__row">
                <p className="course-summary__label">Level {index + 1}:</p>
                <p className="course-summary__data">{totals[index]}</p>
              </div>
            );
          })}
        </div>
      </FlexGroup>
      {map(validate(), (error: string | null, index: number) => {
        return error ? (
          <div key={index} className="course-summary__row">
            <WarningAlert iconPosition="left">{error}</WarningAlert>
          </div>
        ) : null;
      })}
    </div>
  );
};
