import { connect } from 'react-redux';
import { GrantsDataHydrator } from './GrantsDataHydrator';
import { shouldRefreshReduxState_Consortia } from 'redux/grants/_selectors';
import { loadConsortia } from 'redux/grants/consortium';
import {
  shouldRefreshReduxState_GrantFourYearNav,
  shouldRefreshReduxState_GrantOverview,
  shouldRefreshReduxState_GrantFiscalYearNav,
  shouldRefreshReduxState_GrantTwoYearNav,
  shouldRefreshReduxState_GrantSpendingPlanNav,
  shouldRefreshReduxState_GrantAssuranceSignatureNav,
  shouldRefreshReduxState_GrantAwardPacketNav,
  shouldRefreshReduxState_GrantVoucherDetailNav,
  shouldRefreshReduxState_GrantImprovementPlanNav,
} from 'redux/nav/_selectors';
import { loadGrantFiscalYearNav } from 'redux/nav/fiscalyearnav';
import { loadGrantFourYearNav } from 'redux/nav/fouryearnav';
import { loadGrantOverviewNav } from 'redux/nav/grantoverviewnav';
import { loadGrantTwoYearNav } from 'redux/nav/twoyearnav';
import { loadGrantSpendingPlanNav } from 'redux/nav/annualspendingplannav';
import { loadGrantAssuranceSignatureNav } from 'redux/nav/assurancesignaturenav';
import { shouldRefreshReduxState_GrantAnnualReflectionNav } from 'redux/nav/annualreflectionnav.selectors';
import { loadGrantAnnualReflectionNav } from 'redux/nav/annualreflectionnav';
import { loadGrantImprovementPlanNav } from 'redux/nav/improvementplannav';
import { loadGrantAwardPacketNav } from 'redux/nav/awardpacketnav';
import { shouldRefreshReduxState_GrantVoucherOverviewNav } from 'redux/nav/voucheroverview.selectors';
import { loadGrantVoucherOverviewNav } from 'redux/nav/voucheroverview';
import { loadGrantVoucherDetailNav } from 'redux/nav/voucherdetail';

export const mapStateToProps = (state: any) => {
  const shouldRefreshVoucherDetailNav = shouldRefreshReduxState_GrantVoucherDetailNav(
    state
  );
  const shouldRefreshVoucherOverviewNav = shouldRefreshReduxState_GrantVoucherOverviewNav(
    state
  );
  const shouldRefreshConsortia = shouldRefreshReduxState_Consortia(state);
  const shouldRefreshFourYearNav = shouldRefreshReduxState_GrantFourYearNav(
    state
  );
  const shouldRefreshTwoYearNav = shouldRefreshReduxState_GrantTwoYearNav(
    state
  );
  const shouldRefreshFiscalYearNav = shouldRefreshReduxState_GrantFiscalYearNav(
    state
  );
  const shouldRefreshGrantOverview = shouldRefreshReduxState_GrantOverview(
    state
  );
  const shouldRefreshSpendingPlanNav = shouldRefreshReduxState_GrantSpendingPlanNav(
    state
  );
  const shouldRefreshImprovementPlanNav = shouldRefreshReduxState_GrantImprovementPlanNav(
    state
  );
  const shouldRefreshAssuranceSignatureNav = shouldRefreshReduxState_GrantAssuranceSignatureNav(
    state
  );
  const shouldRefreshAnnualReflectionNav = shouldRefreshReduxState_GrantAnnualReflectionNav(
    state
  );
  const shouldRefreshAwardPacketNav = shouldRefreshReduxState_GrantAwardPacketNav(
    state
  );
  return {
    shouldRefreshConsortia,
    shouldRefreshFiscalYearNav,
    shouldRefreshFourYearNav,
    shouldRefreshGrantOverview,
    shouldRefreshSpendingPlanNav,
    shouldRefreshTwoYearNav,
    shouldRefreshAssuranceSignatureNav,
    shouldRefreshAnnualReflectionNav,
    shouldRefreshImprovementPlanNav,
    shouldRefreshAwardPacketNav,
    shouldRefreshVoucherOverviewNav,
    shouldRefreshVoucherDetailNav,
  };
};

const dispatchProps = {
  loadConsortia,
  loadGrantFiscalYearNav,
  loadGrantFourYearNav,
  loadGrantOverviewNav,
  loadGrantSpendingPlanNav,
  loadGrantTwoYearNav,
  loadGrantAssuranceSignatureNav,
  loadGrantAnnualReflectionNav,
  loadGrantImprovementPlanNav,
  loadGrantAwardPacketNav,
  loadGrantVoucherOverviewNav,
  loadGrantVoucherDetailNav,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(GrantsDataHydrator);
