/**
 * This module contains functions that help when working with arrays.
 * © 2020 CCCS & AmplifyCP
 * @module Utilities > ArrayUtility
 */
import {
  concat,
  curry,
  defaultTo,
  find,
  findIndex,
  first,
  get,
  map,
  reduce,
  uniqBy,
} from 'lodash';
import { filter, isArray } from 'lodash';
import { truncateString } from './global';
import { KeyValueType } from 'types';
import { compose, prop } from './objectUtility';
import { equals } from 'ramda';

export { map as fmap } from 'ramda';

/**
 * Returns the input value if it is an array, otherwise returns an empty array.
 * Useful to ensure you are working with an array when expecting an array
 * @function
 * @param { unknown } value The array to test
 * @return { unknown[] } the input array if it is an array, otherwise an empty array.
 */
export const getArrayOrEmpty = (value: unknown) => {
  return isArray(value) ? value : [];
};

/**
 * @function
 * @param { T[] } array the array we are adding to
 * @param { T[] | T } payload the item(s) we want to insert or update in the array.
 * @param { 'keyof T' } selector the property we want to compare.
 * @returns { T[] } a new array containing the original items and the new items.
 */
export const upsert = <T>(
  array: T[],
  payload: T[] | T,
  selector: keyof T
): T[] => {
  const newArray = isArray(array) ? array : [];
  const values = concat(payload, newArray);
  return uniqBy(values, selector);
};

/**
 * This method takes an item and inserts it into an array, if the iotem already exists in the array it will insert it in the same index postion,
 * otherwise it will add the item to the end of the array
 * @function
 * @param { T[] } array the array we are inserting to
 * @param { 'keyof T' } selector the property we want to compare.
 * @param { T } payload the item we want to insert or update in the array.
 * @returns { T[] } The new array containing the original items and the updated / inserted item.
 */
export const upsertInPlace = <T>(
  array: T[],
  payload: T,
  predicate: keyof T
): T[] => {
  const payloadPredicateValue = get(payload, [predicate], -1);
  const index = findIndex(array, [predicate, payloadPredicateValue]);

  return index === -1
    ? [...array, { ...payload }]
    : map(array, (item: any) => {
        return item[predicate] === payload[predicate]
          ? { ...payload }
          : { ...item };
      });
};

/**
 * @function
 * @param { T[] } array an array of objects to be converted to a hashtable
 * @param { 'keyof T' } selector the property that will serve as the hash lookup.
 * @returns a hashtable containing all objects in original array
 */
export const hashArray = <T>(array: T[], selector: keyof T) => {
  return reduce(
    array,
    (acc: any, item: T) => {
      acc[item[selector]] = item;
      return acc;
    },
    {}
  );
};

/**
 * @function
 * @param { T[] } originalCollection an array of objects to be preserved through merge
 * @param { T[] } collectionToMerge an array of objects to merge into originalCollection
 * @param { 'keyof T' } selector the key of the object you wish to compare
 * @param { KeyValueType } normalizeData optional object to manipulate collectionToMerge objects with
 * @returns { T[] } the merged array
 */
export const reMap = <T>(
  originalCollection: T[],
  collectionToMerge: T[],
  selector: keyof T,
  normalizeData?: KeyValueType<unknown>
) => {
  const hashMap = hashArray<T>(originalCollection, selector);
  return map(collectionToMerge, (item: T) => {
    const arr1Item = hashMap[item[selector]];
    return arr1Item ? arr1Item : { ...item, ...normalizeData };
  });
};

/**
 * @function
 * @param { T } object object to convert to array
 * @returns { unknown[] } the converted array
 */
export const objectToArray = <T>(object: T): (keyof T)[] => {
  return Object.keys(object) as (keyof T)[];
};

/**
 * Takes an item or array of items and retuns them as an array.
 * @function
 * @param { T | T[] }  item the thing to convert to an array if it isn't
 * @returns { T[] } the array or item as an array
 */
export const getArray = <T>(value: T | T[]): T[] => {
  return isArray(value) ? value : [value];
};

/**
 * Finds an item in an array given a partial of the item
 * @function
 * @param { Partial<T> } object The object yto look for
 * @param { T[] } collection collection of T to get stuff from
 * @returns { T } the item in the collection that matches the partial input
 */
export const findObject = curry(<T>(object: Partial<T>, collection: T[]) => {
  return find(collection, object);
});

/**
 * @function
 *  ffilter :: (a -> bool) -> [a] -> [a]
 */
export const ffilter = curry((f: Function, xs: any[]) => filter(xs, f));

/**
 * returns the first item from an array, if the array is empty returns a default item
 * @function
 * @param { T[] } items the array of items to get the first item from
 * @param { T } defaultItem the item to return if the array is empty
 * @returns { T } The first item from the array or the empty item.
 */
export const firstOrDefault = <T>(items: T[], defaultItem: T) => {
  return defaultTo(first(items), defaultItem);
};

/**
 * turns an array into a string, and truncates it to 40 characters
 * @function
 * @param { unknown[] } items the array
 * @returns { string } 40 character string that represents the items in the array
 */
export const stringifyArray = (items: unknown[]): string => {
  const stringArray = items.toString();
  const withSpaces = stringArray.replace(',', ', ');
  return truncateString(withSpaces, 40);
};

/**
 * takes a property and a list of objects and returns true if that property
 * value exists once in the array and false otherwise.
 */
export const isUniqueObjectList = curry((property: string, list: any[]) => {
  const reduced = list.reduce((bool: boolean, item: any) => {
    const val = item[property];
    const things = list.filter(compose(equals(val), prop(property)));
    const numberItems = things.length;
    return bool ? numberItems === 1 : bool;
  }, true);
  return list.length ? reduced : true;
});
