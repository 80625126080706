import React from 'react';
import Toggle from 'components/Common/FormElements/Toggle';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';

type CTASupervisorToggleProps = {
  onChange: Function;
  schoolOrDistrictId: number;
  isCTASupervisor: boolean;
};

export const CTASupervisorToggle: React.FC<CTASupervisorToggleProps> = (
  props
) => {
  const { onChange, schoolOrDistrictId, isCTASupervisor } = props;

  return (
    <React.Fragment>
      <ConfirmationDialog
        tName="t-cta-confirm-remove"
        tooltip="Set CTA Supervisor Contact"
        header={'Set CTA Supervisor?'}
        onConfirm={() => {
          onChange(schoolOrDistrictId);
        }}
        activateButton={
          <Toggle
            name="isCTASupervisor"
            value={isCTASupervisor}
            onChange={() => {}}
            noLabel={true}
          />
        }
      >
        <div style={{ whiteSpace: 'normal' }}>
          <p>
            Setting a CTA Supervisor contact will replace the CTA Supervisor
            setting on any other user for the District | Institute. Are you sure
            you wish to continue?
          </p>
        </div>
      </ConfirmationDialog>
    </React.Fragment>
  );
};
