import { AssuranceSignatureSummaryComponent } from './AssuranceSignatureSummary.component';
import { connect } from 'react-redux';
import { getRecords_BasicGrantSignatures } from 'redux/grants/basicgrantsignature.selectors';
import { getRecords_GrantAssurances } from 'redux/grants/_selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { loadBasicGrantAssurances } from 'redux/grants/assurances';
import { loadBasicGrantSignatures } from 'redux/grants/basicgrantsignature';

const mapStateToProps = (state: any) => {
  const assurances = getRecords_GrantAssurances(state);
  const signatures = getRecords_BasicGrantSignatures(state);

  return {
    assurances,
    signatures,
  };
};

const dispatchProps = {
  loadBasicGrantAssurances,
  loadBasicGrantSignatures,
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(AssuranceSignatureSummaryComponent);
