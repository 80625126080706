import React from 'react';
import { ComponentStep, NavAnchor, SideNavData } from 'types';
import { useHistory } from 'react-router';
import { scrollToAnchor } from 'util/windowScroll';
import Skeleton from 'components/Common/Skeleton';
import SidenavItem from './SideNavItem';

type NavListProps = {
  navagationParameters: number[] | string[];
  navData: SideNavData;
  subNav?: boolean;
};

export const NavList: React.FC<NavListProps> = (props) => {
  const { navagationParameters, navData, subNav = false } = props;
  const history = useHistory();

  const navigateOnClick = (nav: NavAnchor) => {
    const url = `${nav.url}${navagationParameters.join('/')}`;
    history.push(url);
  };

  return (
    <ul className="side-nav__anchors">
      {navData.steps &&
        navData.steps.map((step: ComponentStep, index: number) => {
          return step.children ? (
            <React.Fragment key={index}>
              <SidenavItem
                nav={step.nav}
                onClick={() => null}
                parent={true}
                hasLockedIcon={navData.hasLockedIcon}
              />
              <NavList
                navagationParameters={navagationParameters}
                navData={navData}
                subNav={true}
              />
            </React.Fragment>
          ) : (
            <SidenavItem
              key={index}
              nav={step.nav}
              onClick={
                navData.scrollNav
                  ? () => scrollToAnchor(step.nav.anchor)
                  : () => navigateOnClick(step.nav)
              }
              subNav={subNav}
              hasLockedIcon={navData.hasLockedIcon}
            />
          );
        })}
      {!navData.steps && (
        <React.Fragment>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </React.Fragment>
      )}
    </ul>
  );
};
