import React from 'react';
import { UCContactInfo, UniversalContact } from 'types';
import { FlexRow } from 'layouts';
import { DynamicEmailForm } from './DynamicEmail.component';
import { DynamicPhoneForm } from './DynamicPhone.component';
import { formatPhone } from 'util/universalcontact.utility';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import Input from 'components/Common/FormElements/Input';

type ContactInfoProps = {
  checkIfEmailExists: Function;
  contactInfo: UCContactInfo;
  duplicateEmail: string;
  emailExists: UniversalContact | undefined;
  onChange: Function;
  validations: ValidationObject<UniversalContact>;
};

export const ContactInfo: React.FC<ContactInfoProps> = (props) => {
  const {
    checkIfEmailExists,
    contactInfo,
    duplicateEmail,
    emailExists,
    onChange,
    validations,
  } = props;

  const { getError, getFieldValid, validate } = validations;

  return (
    <div className="form__section">
      <FlexRow>
        <h3 className="u-section-title-text">Contact Information</h3>
      </FlexRow>
      <FlexRow>
        <DynamicEmailForm
          checkIfEmailExists={checkIfEmailExists}
          duplicateEmail={duplicateEmail}
          emailExists={emailExists}
          emails={contactInfo.universalContactEmail}
          onChange={onChange}
          validations={validations}
        />
        <DynamicPhoneForm
          phones={contactInfo.universalContactPhone}
          onChange={onChange}
          validations={validations}
        />
        <Input
          errorMessage={getError('fax')}
          name="fax"
          onBlur={() => validate('fax', contactInfo.fax)}
          onChange={onChange}
          valid={getFieldValid('fax')}
          value={formatPhone(contactInfo.fax)}
        />
      </FlexRow>
    </div>
  );
};
