import React from 'react';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { escapeRegExp, deburr, some, map, filter } from 'lodash';

export const getParts = (selection: string, query: string) => {
  return AutosuggestHighlightParse(
    selection,
    AutosuggestHighlightMatch(selection, query)
  );
};

export const renderPart = (
  part: { text: string; highlight: boolean },
  index: number
) => {
  const className = part.highlight
    ? 'react-autosuggest__suggestion--match'
    : undefined;

  const mypart = { ...part, text: part.text ? part.text : '' };

  return (
    <span className={className} key={index}>
      {mypart.text.startsWith(' ') ? (
        <React.Fragment>&nbsp;{mypart.text}</React.Fragment>
      ) : (
        mypart.text
      )}
    </span>
  );
};

export const getSuggestions = <T, S>( // eslint-disable-line
  values: T[],
  searchValue: string,
  testValues: Function[],
  maxResults: number,
  defaultResult: T
): T[] => {
  const cleansearch = escapeRegExp(deburr(searchValue).toLowerCase());
  if (cleansearch) {
    const regex = new RegExp(`^${cleansearch}`, 'i');
    const output = filter(values, (item) => {
      return some(
        map(testValues, (tester) => {
          return regex.test(tester(item));
        })
      );
    });
    return output.length > 0 ? output.slice(0, maxResults) : [defaultResult];
  }
  return values.slice(0, maxResults);
};
