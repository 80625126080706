import Checkbox from 'components/Common/FormElements/Checkbox';
import DatePicker from 'components/Common/FormElements/DatePicker';
import DualListBox from 'components/Common/FormElements/DualListBox';
import Input from 'components/Common/FormElements/Input';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { ReportParametersSelectBoxComponent } from 'components/Common/SelectBoxes/AutoSuggestBoxes/ReportParametersSelectBox/ReportParametersSelectBox.component';
import { FlexRow } from 'layouts';
import { find, get, isEmpty, map, startCase } from 'lodash';
import React from 'react';
import { DropdownItem, ReportParameter, SelectedData } from 'types';

type ReportParamProps = {
  parameter: ReportParameter;
  selectedData?: SelectedData;
  onChange: Function;
};

export const ReportParamComponent: React.FC<ReportParamProps> = (props) => {
  const { parameter, selectedData, onChange } = props;

  // onMultiChange :: DropdownItem -> void
  const onMultiChange = (selected: DropdownItem[]) => {
    const parts = map(selected, (item) => {
      return item.value;
    });
    const value = parts.join(',');
    onChange(null, parameter.name, value);
  };

  // onStartDateChange :: DropdownItem -> void
  const onSchoolProgramChange = (selected: DropdownItem) => {
    onChange(null, 'ProgramID', selected.value);
  };

  // onStartDateChange :: (a, string, Date) -> void
  const onStartDateChange = (_: any, name: string, value: Date) => {
    const parts = getDateParts();
    if (parts.length >= 1) {
      parts[0] = (value as Date).toISOString();
      return onChange(null, name, parts.join('|'));
    }
    onChange(null, name, (value as Date).toISOString());
  };

  // onEndDateChange :: (a, string, Date) -> void
  const onEndDateChange = (_: any, name: string, value: Date) => {
    const parts = getDateParts();
    if (parts.length === 2) {
      parts[1] = (value as Date).toISOString();
      return onChange(null, name, parts.join('|'));
    }
    parts.push((value as Date).toISOString());
    onChange(null, name, parts.join('|'));
  };

  const getDateParts = () => {
    if (selectedData && !isEmpty(selectedData)) {
      return selectedData.value.split('|');
    }
    return [];
  };

  const getMultiData = () => {
    if (selectedData) {
      if (selectedData.value !== '') {
        return selectedData.value.split(',');
      }
    }
    return [];
  };

  const getDate = () => {
    return selectedData ? new Date(Date.parse(selectedData.value)) : undefined;
  };

  const onDateChange = (data: any, name: any, value: any) => {
    onChange(data, name, new Date(value).toDateString());
  };

  const getStartDate = () => {
    const parts = getDateParts();
    return parts.length > 0 ? new Date(Date.parse(parts[0])) : undefined;
  };

  const getEndDate = () => {
    const parts = getDateParts();
    return parts.length > 1 ? new Date(Date.parse(parts[1])) : undefined;
  };

  const getMultiSelected = () => {
    const values = getMultiData();
    const items = map(values, (item) => {
      return find(parameter.options, (option) => {
        return option.value === item;
      });
    });
    return items;
  };

  const generateParameter = () => {
    switch (parameter.type) {
      case 'text':
        return (
          <Input
            name={parameter.name}
            onChange={onChange}
            value={get(selectedData, ['value'], '')}
          />
        );

      case 'date':
        return (
          <DatePicker
            name={parameter.name}
            label={startCase(parameter.name)}
            onChange={onDateChange}
            value={getDate()}
          />
        );

      case 'daterange':
        return (
          <FlexRow>
            <DatePicker
              name={parameter.name}
              label={`${startCase(parameter.name)} Start`}
              value={getStartDate()}
              onChange={onStartDateChange}
            />
            <DatePicker
              disabled={getDateParts().length < 1}
              name={parameter.name}
              label={`${startCase(parameter.name)} End`}
              value={getEndDate()}
              onChange={onEndDateChange}
            />
          </FlexRow>
        );

      case 'bool':
        return (
          <div className="u-flex-vertical" style={{ height: '10.6rem' }}>
            <p className="form__label">&nbsp;</p>
            <Checkbox
              name={parameter.name}
              value={Boolean(get(selectedData, ['value'], false))}
              onChange={onChange}
              label={startCase(parameter.name)}
            />
          </div>
        );
      case 'select':
        if (parameter.multiSelect) {
          return (
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <DualListBox
                height={175}
                data={[...parameter.options]}
                dataLabel={startCase(parameter.name)}
                selectedData={getMultiSelected()}
                selectedDataLabel={`Selected`}
                selector="value"
                onChange={onMultiChange}
                displayName={'label'}
              />
            </div>
          );
        } else if (parameter.autoSuggest) {
          // TODO: this only works for SchoolProgram, the name value is hardcoded
          return (
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <ReportParametersSelectBoxComponent
                dropdownItems={[...parameter.options]}
                onChange={onSchoolProgramChange}
              />
            </div>
          );
        }
        return (
          <div className="not-static">
            <SelectBox
              options={parameter.options}
              label={startCase(parameter.name)}
              name={parameter.name}
              onChange={onChange}
              selection={get(selectedData, ['value'], false)}
            />
          </div>
        );

      default:
        return (
          <React.Fragment>
            <div className="u-color-red">{parameter.name}</div>
          </React.Fragment>
        );
    }
  };

  return <React.Fragment>{generateParameter()}</React.Fragment>;
};
