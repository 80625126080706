import React from 'react';
import Toggle from 'components/Common/FormElements/Toggle';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';

type PerkinsContactToggleProps = {
  onChange: Function;
  schoolOrDistrictId: number;
  isPerkinPrimary: boolean;
};

export const PerkinsContactToggle: React.FC<PerkinsContactToggleProps> = (
  props
) => {
  const { onChange, schoolOrDistrictId, isPerkinPrimary } = props;

  return (
    <React.Fragment>
      <ConfirmationDialog
        tooltip="Set Perkins Contact"
        header={'Set Perkins Contact?'}
        onConfirm={() => {
          onChange(schoolOrDistrictId);
        }}
        activateButton={
          <Toggle
            name="isPerkinPrimary"
            value={isPerkinPrimary}
            onChange={() => {}}
            noLabel={true}
          />
        }
      >
        <div style={{ whiteSpace: 'normal' }}>
          <p>
            Setting a Perkins Primary contact will replace the Perkins Primary
            setting on any other user for the District | Institute. Are you sure
            you wish to continue?
          </p>
        </div>
      </ConfirmationDialog>
    </React.Fragment>
  );
};
