import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
} from 'services';
import { SignatureWithAssertion } from 'types';

const BASE_URL = ['api', 'ctasignature'];

export const readCTASignatures = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Signatures', err, 'readCTASignatures');
    });
};

export const updateCTASignature = async (
  ctaId: number,
  ctaSignatures: SignatureWithAssertion[]
): Promise<number> => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(ctaSignatures),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contract',
        err,
        'updateCTAHostingContract'
      );
    });
};
