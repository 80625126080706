import React from 'react';
import { useParams } from 'react-router';
import FourYearWorkflow from 'components/Grants/Applications/FourYear/Workflow';
import GrantsLayout from 'layouts/GrantsLayout';
import BaseLayout from 'layouts/BaseLayout';

export const GrantFourYearApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <FourYearWorkflow grantFiscalYearId={grantFiscalYearId} />
      </GrantsLayout>
    </BaseLayout>
  );
};
