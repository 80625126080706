import React from 'react';
import { FlexRow } from 'layouts';
import Cop from 'assets/403.svg';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';

const NoSubmissionAccess: React.FC = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Paper>
        <div className="u-flex" style={{ alignItems: 'center' }}>
          <img
            style={{
              filter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',
              width: 175,
              alignSelf: 'center',
            }}
            src={Cop}
            alt="403"
          />
          <div>
            <p style={{ fontSize: '2.4rem' }}>Access Denied</p>
            <FlexRow>
              <ul style={{ marginLeft: '2rem' }}>
                <li>
                  You may have either already submitted your intent to
                  participate
                </li>
                <li>
                  You might not have access to modify or submit this intent to
                  participate
                </li>
              </ul>
            </FlexRow>
            <p>
              If you believe you have received this message in error, please
              contact the CCCS Administrator at{' '}
              <a href="mailto:cte@cccs.edu">cte@cccs.edu</a>.
            </p>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default NoSubmissionAccess;
