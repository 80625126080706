import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { SET_CAREER_INFO, SET_CAREER_INFO_LOADING } from './careerinfo.actions';

const initState = baseInitialState;

export const careerInfoReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_CAREER_INFO:
      return actionUpdateRecords(state, action.payload);

    case SET_CAREER_INFO_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
