import React from 'react';
import { FlexRow } from 'layouts';
import { PERMISSION_ROLE_PATH } from 'redux/permissions/permissionroles.selectors';
import { ItemFilter, PermissionRole, UniversalContact } from 'types';
import { flatMap } from 'lodash';
import DataFilter from 'components/Common/DataFilter';
import DualListBox from 'components/Common/FormElements/DualListBox';
import { isSuperAdmin } from 'util/permissionsUtility';

type AddRemovePermissionsProps = {
  contactRoles: PermissionRole[];
  loggedInUser: UniversalContact;
  handlePermissionChange: Function;
};

export const AddRemovePermissions: React.FC<AddRemovePermissionsProps> = (
  props
) => {
  const { loggedInUser, handlePermissionChange, contactRoles } = props;

  const getAssignableRoleFilter = () => {
    if (isSuperAdmin(loggedInUser)) {
      return { exclude: true, property: 'd', values: ['1'] };
    }

    return {
      strict: true,
      property: 'permissionRoleName',
      values: flatMap(loggedInUser.permissionRoles, (item) => {
        switch (item.permissionRoleName) {
          case 'universalcontactpoweruser':
            return [
              'primaryprogrammanager',
              'limitedprogrammanager',
              'programreadonly',
              'universalcontacts',
              'grantssupport',
              'standaloneprimarycontact',
              'consortiumfiscalagentcontact',
              'signee',
              'consortiummember',
              'localfinancerep',
              'dcstudentrecord',
              'dcteacherrecord',
              'dcsubmissionapprover',
              'ctapreparer',
              'ctareadonly',
            ];
          case 'ctamanager':
            return ['ctapreparer', 'ctareadonly'];
          case 'primaryprogrammanager':
            return ['limitedprogrammanager', 'programreadonly'];
          default:
            return [];
        }
      }),
    };
  };

  return (
    <React.Fragment>
      {getAssignableRoleFilter().values.length > 0 && (
        <div className="form__section">
          <FlexRow>
            <h3 className="u-section-title-text">Assign Permissions</h3>
          </FlexRow>
          <DataFilter
            path={PERMISSION_ROLE_PATH}
            itemFilter={getAssignableRoleFilter()}
          >
            <DualListBox
              dataLabel="Available Permissions"
              displayName="permissionRoleLabelName"
              height={200}
              onChange={handlePermissionChange}
              selectedData={contactRoles}
              selectedDataLabel="Current Permissions"
              selector="permissionRoleId"
              search={true}
            />
          </DataFilter>
        </div>
      )}
    </React.Fragment>
  );
};
