import { SnapshotTeacher, StudentEnrollment } from 'types';
import { prop } from './objectUtility';
import { some } from 'lodash';

export const getFullName = (item: unknown) => {
  return `${prop('firstName', item)} ${prop('lastName', item)}`;
};

export const isEnrollmentLocked = (enrollment: StudentEnrollment) => {
  return some([enrollment.isComplete, !enrollment.isOpen]);
};

export const isTeachersLocked = (teacher: SnapshotTeacher) => {
  return some([teacher.isComplete, !teacher.isOpen]);
};
