import React from 'react';

type MaxResultsProps = {
  maxResults: number;
  resultCount: number;
  totalCount: number;
};

export const MaxResults: React.FC<MaxResultsProps> = (props) => {
  const { maxResults, resultCount, totalCount } = props;

  return (
    <React.Fragment>
      {totalCount > maxResults && (
        <div
          className="react-autosuggest__suggestion"
          style={{ backgroundColor: '#e8f6f7' }}
        >
          <strong>
            <div className="u-flex">
              <p>{`Showing ${resultCount} out of ${totalCount}`}</p>
              <p className="u-flex-grow-1" />
              {resultCount >= maxResults && (
                <p>Start typing to see more options...</p>
              )}
            </div>
          </strong>
        </div>
      )}
    </React.Fragment>
  );
};
