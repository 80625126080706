import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import FourYearGroup from 'components/Grants/Applications/FourYear/FourYearGroup';
import { useParams } from 'react-router';

export const FourYearGroupPage: React.FC = (props) => {
  const { step }: any = useParams();
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <FourYearGroup step={step} />
      </GrantsLayout>
    </BaseLayout>
  );
};
