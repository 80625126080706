import { connect } from 'react-redux';
import {
  getIsDataFetching_Consortia,
  getAdvancedFilteredRecords_Consortia,
} from 'redux/grants/_selectors';
import { ConsortiumManagementDashboard } from './Dashboard.component';
import { getConsortiumFilters } from 'redux/filters/_selectors';
import { remove } from 'lodash';
import { getCurrentFiscalYear } from 'redux/common/_selectors';

const mapStateToProps = (state: any) => {
  const currentFiscalYear = getCurrentFiscalYear(state);
  const consortia = getAdvancedFilteredRecords_Consortia(state);
  const isFetching = getIsDataFetching_Consortia(state);
  const filters = remove(getConsortiumFilters(state), (item) => {
    return item.filter.property !== 'standalone';
  }).length;

  return {
    currentFiscalYear,
    filters,
    isFetching,
    consortia,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(ConsortiumManagementDashboard);
