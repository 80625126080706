import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import reducers from './reducers';
import thunk from 'redux-thunk';
import {
  GRANTS_PARTICIPANT_KEY,
  COMMON_KEY,
  UNIVERSAL_CONTACTS_KEY,
  APPLICATION_KEY,
  CACHE_KEY,
  FILTERS_KEY,
  CHANGELOG_KEY,
  REPORTING_KEY,
} from './keys';

// import DevTools from 'components/DevTools';

import { courseCreditsMiddleware } from 'redux/middleware/programs/coursecredits.middleware';
import { permissionsMiddleware } from 'redux/middleware/common/permissions.middleware';
import { programArticulationMiddleware } from 'redux/middleware/programs/programarticulation.middleware';
import { programAwardsMiddleware } from 'redux/middleware/programs/programawards.middleware';
import { programInstanceMiddleware } from 'redux/middleware/programs/programinstance.middleware';
import { programOfStudyMiddleware } from 'redux/middleware/programs/programofstudy.middleware';
import { programPathwaysMiddleware } from 'redux/middleware/programs/programpathways.middleware';
import { userMiddleware } from 'redux/middleware/common/user.middleware';
import { universalContactMiddleware } from 'redux/middleware/universalcontacts/universalcontacts.middleware';
import { programAssuranceMiddleware } from 'redux/middleware/programs/programassurance.middleware';
import { programContactsMiddleware } from 'redux/middleware/programs/programcontacts.middleware';
import { programLengthMiddleware } from 'redux/middleware/programs/programlength.middleware';
import { errorMiddleware } from 'redux/middleware/common/error.middleware';
import { consortiaMiddleware } from './middleware/grants/consortia.middleware';
import { schoolMiddleWare } from './middleware/common/school.middleware';
import { changleLogMiddleware } from 'redux/middleware/changelog.middleware';
import { workflowMiddleware } from './middleware/workflow.middleware';
import { grantFourYearQuestionsMiddleware } from './middleware/grants/grantfouryearquestions.middleware';
import { grantFourYearAddressableElementsMiddleware } from './middleware/grants/grantfouryearaddressableelements.middleware';
import { grantParticipantsMiddleware } from './middleware/grants/grantparticipants.middleware';
import { grantAnnualSpendingPlanMiddleware } from './middleware/grants/grantannualspendingplan.middleware';
import { awardPacketMiddleware } from './middleware/grants/awardpacket.middleware';
import { submissionManagementMiddleware } from './middleware/datacollection/submissionManagement.middleware';
import { ferpaAgreementMiddleware } from './middleware/common/ferpaAgreement.middleware';
import { snapshotStudentMiddleware } from './middleware/datacollection/snapshotStudent.middleware';
import { snapshotTeacherMiddleware } from './middleware/datacollection/snapshotTeacher.middleware';
import { persistMiddleware } from './middleware/common/persist.middleware';
import ctaMiddleware from './middleware/cta/_ctamiddleware';
import { adminMiddleware } from './middleware/common/admin.middleware';

const persistConfig = {
  key: 'cccsroot',
  storage: localForage,
  whitelist: [
    GRANTS_PARTICIPANT_KEY,
    COMMON_KEY,
    UNIVERSAL_CONTACTS_KEY,
    APPLICATION_KEY,
    CACHE_KEY,
    FILTERS_KEY,
    CHANGELOG_KEY,
    REPORTING_KEY,
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const featureMiddleware = [
  ...ctaMiddleware,
  snapshotStudentMiddleware,
  snapshotTeacherMiddleware,
  errorMiddleware,
  awardPacketMiddleware,
  consortiaMiddleware,
  courseCreditsMiddleware,
  permissionsMiddleware,
  programArticulationMiddleware,
  programAssuranceMiddleware,
  programAwardsMiddleware,
  programContactsMiddleware,
  programInstanceMiddleware,
  programLengthMiddleware,
  programOfStudyMiddleware,
  programPathwaysMiddleware,
  universalContactMiddleware,
  userMiddleware,
  schoolMiddleWare,
  changleLogMiddleware,
  workflowMiddleware,
  grantFourYearQuestionsMiddleware,
  grantFourYearAddressableElementsMiddleware,
  grantParticipantsMiddleware,
  grantAnnualSpendingPlanMiddleware,
  submissionManagementMiddleware,
  ferpaAgreementMiddleware,
  persistMiddleware,
  adminMiddleware,
];

const applyCustomMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV === 'production') {
    return compose(applyMiddleware(...middleware, ...featureMiddleware, thunk));
  } else {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return composeEnhancers(
      applyMiddleware(...middleware, ...featureMiddleware, thunk)
    );
  }
};

const createStoreWithMiddleware = (...middleware: any) => {
  return compose(applyCustomMiddleware(middleware))(createStore);
};

export const store = createStoreWithMiddleware(thunk)(persistedReducer);

export const persistor = persistStore(store);
