import { connect } from 'react-redux';
import { EquipmentComponent } from './Equipment.component';
import { getRecords_CTAEquipment } from 'redux/cta/_selectors';
import {
  loadCTAEquipment,
  saveCTAEquipment,
  removeCTAEquipment,
} from 'redux/cta/ctaequipment';

const mapStateToProps = (state: any) => {
  const equipment = getRecords_CTAEquipment(state);

  return {
    equipment,
  };
};

const dispatchProps = {
  loadCTAEquipment,
  saveCTAEquipment,
  removeCTAEquipment,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(EquipmentComponent);
