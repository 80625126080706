import { setCurrentNavAnchor } from 'redux/application';
import { connect } from 'react-redux';
import { getRecords_GrantOverview } from 'redux/nav/_selectors';
import { isStatusLocked } from 'util/navHelpers';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { includes, some, every } from 'lodash';
import { LOCAL_PLAN_WORKFLOW } from 'constants/grantsWorkFlowStatus.enum';

import { VoucherWorkflowComponent } from './VoucherWorkflow.component';
import {
  getSingleVoucherByType,
  getIsDataFetching_Vouchers,
  getIsDataFetching_ReimbursementSummary,
} from 'redux/grants/_selectors';
import { loadSingleVoucherByType } from 'redux/grants/vouchers';
import { getVoucherLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any, ownProps: any) => {
  const { grantFiscalYearId: applicationId, voucherType = '' } = ownProps;

  const ENTITY = voucherType as string;
  const VOUCHER_ENTITY = 'vouchers';

  const voucher = getSingleVoucherByType(state, voucherType);
  const steps = getRecords_GrantOverview(state).steps;
  const isReadOnly = every([
    isStatusLocked(VOUCHER_ENTITY, steps),
    getVoucherLockedStatus(state, voucherType),
  ]);
  const workflow = getGeneralWorkflow(ENTITY, state);
  const user = getLoggedinUser(state);

  const isFetching = some([
    getIsDataFetching_GeneralWorkflow(ENTITY, state),
    getIsDataFetching_ReimbursementSummary(state),
    getIsDataFetching_Vouchers(state),
  ]);

  const disableSubmit = includes(
    [LOCAL_PLAN_WORKFLOW.APPROVED, LOCAL_PLAN_WORKFLOW.CONDITIONALLY_APROVED],
    workflow.workflowStatusId
  );

  return {
    applicationId,
    disableSubmit,
    isFetching,
    isReadOnly,
    user,
    voucher,
    voucherType,
    workflow,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  loadSingleVoucherByType,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(VoucherWorkflowComponent);
