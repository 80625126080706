import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import AdvancedCreditOptions from 'pages/ProgramSteps/AdvancedCreditOptions';
import Articulation from 'pages/ProgramSteps/Articulation';
import Articulations from 'pages/ProgramSteps/Articulations';
import AssurancesStep from 'pages/ProgramSteps/Assurances';
import Award from 'pages/ProgramSteps/Award';
import AwardStep from 'pages/ProgramSteps/Awards';
import CareerPathway from 'pages/ProgramSteps/CareerPathway';
import ContactsStep from 'pages/ProgramSteps/Contacts';
import CoursesCreditsStep from 'pages/ProgramSteps/CoursesCredits';
import Create from 'pages/ProgramSteps/Create';
import Dashboard from 'pages/ProgramSteps/Dashboard';
import General from 'pages/ProgramSteps/General';
import PathwayCoursesStep from 'pages/ProgramSteps/PathwayCourses';
import ProgramOfStudyStep from 'pages/ProgramSteps/ProgramsOfStudy';
import ProgramOfStudy from 'pages/ProgramSteps/ProgramOfStudy';
import ProgramOfStudyOverviewPage from 'pages/ProgramSteps/ProgramOfStudyOverview';
import SecondaryCourseEditPage from 'pages/ProgramSteps/SecondaryCourseEdit';
import SummaryPage from 'pages/ProgramSteps/Summary';
import WorkflowPage from 'pages/Workflow';
import SuperSummaryPage from 'pages/ProgramSteps/SuperSummary';

const programsRoutes = [
  <Route
    exact
    path="/programs"
    component={authentication.required(Dashboard)}
  />,
  <Route
    exact
    path="/programs/advancedcredit/:programInstanceId"
    component={authentication.required(AdvancedCreditOptions)}
  />,
  <Route
    exact
    path="/programs/approval/:programInstanceId"
    component={authentication.required(WorkflowPage)}
  />,
  <Route
    exact
    path="/programs/articulations/:programInstanceId"
    component={authentication.required(Articulations)}
  />,
  <Route
    exact
    path="/programs/articulations/:programInstanceId/:articulationId"
    component={authentication.required(Articulation)}
  />,
  <Route
    exact
    path="/programs/assurances/:programInstanceId"
    component={authentication.required(AssurancesStep)}
  />,
  <Route
    exact
    path="/programs/awards/:programInstanceId"
    component={authentication.required(AwardStep)}
  />,
  <Route
    exact
    path="/programs/awards/:programInstanceId/:programAwardId"
    component={authentication.required(Award)}
  />,
  <Route
    exact
    path="/programs/contacts/:programInstanceId"
    component={authentication.required(ContactsStep)}
  />,
  <Route
    exact
    path="/programs/courses/:programInstanceId"
    component={authentication.required(PathwayCoursesStep)}
  />,
  <Route
    exact
    path="/programs/courses/:programInstanceId/:programCareerPathwayId/:courseId"
    component={authentication.required(SecondaryCourseEditPage)}
  />,
  <Route
    exact
    path="/programs/coursecredits/:programInstanceId"
    component={authentication.required(CoursesCreditsStep)}
  />,
  <Route
    exact
    path="/programs/create-program/:method"
    component={authentication.required(Create)}
  />,
  <Route
    exact
    path="/programs/general/:programInstanceId"
    component={authentication.required(General)}
  />,
  <Route
    exact
    path="/programs/pathways/:programInstanceId"
    component={authentication.required(CareerPathway)}
  />,
  <Route
    exact
    path="/programs/program-steps/:programInstanceId"
    component={authentication.required(SummaryPage)}
  />,
  <Route
    exact
    path="/programs/programofstudy/:programInstanceId"
    component={authentication.required(ProgramOfStudyStep)}
  />,
  <Route
    exact
    path="/programs/programofstudy/:programInstanceId/:programOfStudyId"
    component={authentication.required(ProgramOfStudy)}
  />,
  <Route
    exact
    path="/programs/pos-overview/:programInstanceId/:programOfStudyId"
    component={authentication.required(ProgramOfStudyOverviewPage)}
  />,
  <Route
    exact
    path="/programs/program-steps/super-summary/:programInstanceId"
    component={authentication.required(SuperSummaryPage)}
  />,
];

export default programsRoutes;
