import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readBasicGrantSignatures,
  updateBasicGrantSignatures,
} from 'services/grants/basicGrantSignatures.services';
import { Signature } from 'types';

const initialState = baseInitialState;

export const loadBasicGrantSignatures = createActionThunk(
  'loadBasicGrantSignatures',
  (grantFiscalYearId: number) => {
    return readBasicGrantSignatures(grantFiscalYearId);
  }
);

export const saveBasicGrantSignatures = createActionThunk(
  'saveBasicGrantSignatures',
  async (grantFiscalYearId: number, payload: Signature[]) => {
    return updateBasicGrantSignatures(grantFiscalYearId, payload);
  }
);

export const insertConsortiums = createAction('insertConsortiums');

export default handleActions<BaseStateObject, Signature | Signature[]>(
  {
    ...baseLoadActionBuilder<Signature | Signature[]>(loadBasicGrantSignatures),
    ...baseSaveActionBuilder<Signature | Signature[]>(saveBasicGrantSignatures),
  },
  initialState
);
