import { PROGRAMS_KEY, PROGRAM_AWARD_TYPES_KEY } from 'redux/keys';
import { AwardType } from 'types';
import {
  shouldRefreshReduxState,
  getRecords,
} from 'redux/utility/baseSelectors';

export const AWARD_TYPE_PATH = [PROGRAMS_KEY, PROGRAM_AWARD_TYPES_KEY];

export const getRecords_AwardTypes = (state: any): AwardType[] => {
  return getRecords(state, AWARD_TYPE_PATH);
};

export const shouldRefreshReduxState_AwardTypes = (state: any): boolean => {
  return shouldRefreshReduxState<AwardType>(state, AWARD_TYPE_PATH, 300);
};
