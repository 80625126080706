import { connect } from 'react-redux';
import { ResetPassword } from './ResetPassword.component';
import {
  getLoggedinUser,
  getIsUserNew,
  getIsUserFetching,
} from 'redux/user/selectors';
import { updateContactPassword } from 'redux/user';

export const mapStateToProps = (state: any, ownProps: any) => {
  const user = getLoggedinUser(state);
  const isFetching = getIsUserFetching(state);
  const isNewUser = getIsUserNew(state);
  const { visible } = ownProps;

  return {
    user,
    isFetching,
    visible,
    isNewUser,
  };
};

const dispatchToProps = {
  updateContactPassword,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ResetPassword);
