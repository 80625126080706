import { COMMON_KEY, CTA_SCHOOL_TERMS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { CTASchoolTerm } from 'types/cta/CTASchoolTerm.types';

export const CTA_SCHOOL_TERM_PATH = [COMMON_KEY, CTA_SCHOOL_TERMS_KEY];

export const getIsDataFetching_CTASchoolTerm = (state: any): boolean => {
  return getIsDataFetching(state, CTA_SCHOOL_TERM_PATH);
};

export const getLastUpdated_CTASchoolTerm = (state: any): number => {
  return getLastUpdated(state, CTA_SCHOOL_TERM_PATH);
};

export const getRecords_CTASchoolTerm = (state: any): CTASchoolTerm[] => {
  return getRecords<CTASchoolTerm>(state, CTA_SCHOOL_TERM_PATH);
};

export const shouldRefreshReduxState_CTASchoolTerm = (state: any): boolean => {
  return shouldRefreshReduxState<CTASchoolTerm>(state, CTA_SCHOOL_TERM_PATH);
};
