import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';
import { ProgramState } from 'types';

const PROGRAM_STATES_ENDPOINT = 'api/ProgramInstances/states';

export const readProgramStates = async (): Promise<ProgramState[]> => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_STATES_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramStates', err);
    });
}; // end
