import { CTA_KEY, CTA_HOSTING_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { defaultTo, find } from 'lodash';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTAHosting, emptyCTAHosting } from 'types';

export const CTA_HOSTING_PATH = [CTA_KEY, CTA_HOSTING_KEY];

export const getIsDataFetching_CTAHosting = (state: any): boolean => {
  return getIsDataFetching(state, CTA_HOSTING_PATH);
};

export const getRecords_CTAHosting = (state: unknown): CTAHosting[] => {
  return getRecords(state, CTA_HOSTING_PATH);
};

export const getAdvancedFilteredRecords_CTAHosting = (
  state: any
): CTAHosting[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTAHosting>(
    state,
    CTA_HOSTING_PATH,
    filters
  );
  return records;
};

export const getSingleRecord_CTAHosting = (
  state: unknown,
  contractingId: number
): CTAHosting => {
  const records = getRecords_CTAHosting(state);

  return defaultTo(
    find(records, (item: CTAHosting) => {
      return item.contractingId === Number(contractingId);
    }),
    emptyCTAHosting()
  );
};
