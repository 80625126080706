import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { some } from 'lodash';
import { GrantFiscalYear, WorkflowReduxEntities } from 'types';
import { isApplicaionIdValid, doNothing } from 'util/global';
import { prop, compose } from 'util/objectUtility';
import { GRANT_LAYOUT_HEADER_TITLES } from 'constants/grantLayoutTitles.const';
import GrantsSideNav from 'components/Grants/GrantsSideNav';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import Skeleton from 'components/Common/Skeleton';
import WorkflowTray from 'components/Common/GeneralWorkflow/WorkflowTray';

type GrantsLayoutProps = {
  children: JSX.Element;
  grant: GrantFiscalYear;
  loadSingleGrantFiscalYear: Function;
};

export const GrantsLayout: React.FC<GrantsLayoutProps> = (props) => {
  const { children, grant, loadSingleGrantFiscalYear } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { pathname } = useLocation();
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  // --[ local state ]---------------------------------------------------------
  const entityType = pathname.split('/')[3];

  // --[ component logic ]-----------------------------------------------------
  const generateTitle = compose(
    // startCase, ---startCase will parse out the special characters
    prop(entityType)
  );

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (isApplicaionIdValid(id)) {
      loadSingleGrantFiscalYear(id).catch(doNothing);
    }
  }, [id, loadSingleGrantFiscalYear]);

  // --[ display logic ]-------------------------------------------------------
  const getTitle = () => {
    return entityType in GRANT_LAYOUT_HEADER_TITLES
      ? generateTitle(GRANT_LAYOUT_HEADER_TITLES)
      : entityType;
    // : startCase(entityType); ---startCase will parse out the special characters
  };
  const hideHeader = (pathname: string) => {
    return some([pathname.includes('create-grant')]);
  };
  const renderWorkflowTray = () => {
    return !(pathname.includes('grant-steps') || pathname.includes('vouchers'));
  };

  return (
    <div className="program">
      {renderWorkflowTray() ? (
        <WorkflowTray
          entityId={grantFiscalYearId}
          entityType={entityType as keyof WorkflowReduxEntities}
        />
      ) : (
        <div />
      )}
      <section className="program__body">
        {hideHeader(pathname) ? null : (
          <Paper>
            <PageHeading
              title={getTitle()}
              subTitle={grant.name ? grant.name : <Skeleton />}
            />
          </Paper>
        )}
        <div className="program__content">{children}</div>
      </section>
      <GrantsSideNav pathname={pathname} />
    </div>
  );
};
