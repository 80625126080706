import download from 'js-file-download';
import { ENVIRONMENT } from 'constants/application.config';
import { postWithAuthOptions, toFileBlob } from '..';
import { AzureUploadParameters } from 'types';
const AZUREUPLOAD_ENDPOINT = 'api/AzureStorage/upload/';
const AZUREDOWNLOAD_ENDPOINT = 'api/AzureStorage/download/';

export const uploadFileToAzure = async (
  container: string,
  path: string,
  file: File
) => {
  return await fetch(
    `${ENVIRONMENT.API_URL}${AZUREUPLOAD_ENDPOINT}${container}/${path}`,
    {
      ...postWithAuthOptions(),
      body: file,
    }
  ).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
}; // end upload

// TODO: get actual Year GMT
export const downloadFileFromAzure = async (
  uploadParams: AzureUploadParameters,
  filename: string
) => {
  return await fetch(
    `${ENVIRONMENT.API_URL}${AZUREDOWNLOAD_ENDPOINT}${
      uploadParams.container
    }/${filename}`,
    {
      ...postWithAuthOptions(),
    }
  )
    .then(toFileBlob)
    .then((blob) => {
      download(blob, filename);
    });
}; // end upload
