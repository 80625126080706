import {
  saveCTASupportPersonnel,
  loadCTASupportPersonnel,
  deactivateCTASupportPersonnel,
} from 'redux/cta/ctasupportpersonnel';

export const ctaSupportingPersonnelMiddleware = (store: any) => (
  next: Function
) => (action: any) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveCTASupportPersonnel.SUCCEEDED: {
      dispatch(loadCTASupportPersonnel(action.payload));
      break;
    }
    case deactivateCTASupportPersonnel.SUCCEEDED: {
      dispatch(loadCTASupportPersonnel(action.payload));
      break;
    }
  }
};
