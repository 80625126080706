import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkflowActionProps } from '../WorkflowAction/WorkflowAction.component';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Button from 'components/Common/FormElements/Button';

export const OpenWorkflowForRevision: React.FC<WorkflowActionProps> = (
  props
) => {
  const { workflowStep, applicationId, submitApplicationUpdate } = props;
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <ConfirmationDialog
        tooltip="Reopen Workflow"
        activateButton={
          <Button text="Activate " className="button--filled button--full" />
        }
        header={'Reopen Workflow'}
        onConfirm={() => {
          const thing = submitApplicationUpdate(applicationId, {
            optionId: workflowStep.workflowActions[0].stepActionId,
          });
          dispatch(thing);
        }}
      >
        <h3>Are you sure you want activate this for revision?</h3>
      </ConfirmationDialog>
    </React.Fragment>
  );
};
