import * as R from 'ramda';
import Button from 'components/Common/FormElements/Button';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import ScrollToTop from 'components/Common/ScrollToTop';
import download from 'js-file-download';
import { FlexGroup, FlexRow } from 'layouts';
import { GraphReport } from './GraphReport';
import { ReportItem, SelectedData } from 'types';
import { ReportTable } from './ReportTable.component';
import { ReportingParametersComponent } from '../ReportsDashboard/ReportingParameters';
import { compose, prop } from 'util/objectUtility';
import {
  readExcelReport,
  readPublicExcelReport,
} from 'services/reporting/report.service';
import { some } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';

type ReportDetailProps = {
  isLoggedin: boolean;
  isFetching: boolean;
  report: ReportItem;
  loadSingleWebReport: Function;
  loadPublicWebReport: Function;
};

export const ReportDetailComponent: React.FC<ReportDetailProps> = ({
  isFetching,
  report,
  loadSingleWebReport,
  loadPublicWebReport,
  isLoggedin,
}) => {
  const [valueState, setValueState] = useState<SelectedData[]>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const updateValueState = (data: any, name: any, value: any) => {
    const sdata: SelectedData = {
      name,
      value,
    };
    setValueState(upsertInPlace(valueState, sdata, 'name'));
  };

  useEffect(() => {
    setValueState(report.selectedData);
  }, [report]);

  const generateReport = () => {
    if (isLoggedin) {
      loadSingleWebReport(report.name, { ...report, selectedData: valueState })
        .then()
        .catch((err: any) => console.error(err));
    } else {
      loadPublicWebReport(report.name, { ...report, selectedData: valueState })
        .then()
        .catch((err: any) => console.error(err));
    }
  };

  const downloadReport = () => {
    if (isLoggedin) {
      setLocalLoading(true);
      readExcelReport(report.name, { ...report, selectedData: valueState })
        .then((blob: unknown) => {
          download(blob as Blob, `${report.name}.xlsx`);
        })
        .catch((err: any) => console.error(err))
        .finally(() => {
          setLocalLoading(false);
        });
    } else {
      setLocalLoading(true);
      readPublicExcelReport(report.name, {
        ...report,
        selectedData: valueState,
      })
        .then((blob: unknown) => {
          download(blob as Blob, `${report.name}.xlsx`);
        })
        .catch((err: any) => console.error(err))
        .finally(() => {
          setLocalLoading(false);
        });
    }
  };

  // hasData :: string -> ReportItem -> boolean
  const hasData = (rowsProperty: string) =>
    compose(R.gt(R.__, 0), R.length, R.defaultTo([]), prop(rowsProperty));

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading
        isActive={some([isFetching, localLoading])}
        messageBefore="Loading..."
      />

      <Paper>
        <h2>{report.name}</h2>
        <h3>
          <p>{report.description}</p>
        </h3>
      </Paper>

      <Paper>
        <FlexRow>
          <h2>Update / Refresh Report</h2>
        </FlexRow>
        <ReportingParametersComponent
          report={report}
          onChange={updateValueState}
          valueState={valueState}
        />
        <FlexRow>
          <div className="u-flex-grow-1" />
          <div>
            {some([
              report.reportType === 'both',
              report.reportType === 'web',
            ]) && (
              <Button
                text={'Refresh Data'}
                onClick={generateReport}
                className="button--short-outline"
              />
            )}
            {some([
              report.reportType === 'both',
              report.reportType === 'excel',
            ]) && (
              <Button
                text={'Export to Excel'}
                onClick={downloadReport}
                className="button--short"
              />
            )}
          </div>
        </FlexRow>
      </Paper>
      <ReportTable data={report.rows} reportLink={report.reportLink} />
      {hasData('rows2')(report) && (
        <ReportTable data={report.rows2} reportLink={report.reportLink} />
      )}
      {hasData('rows3')(report) && (
        <ReportTable data={report.rows3} reportLink={report.reportLink} />
      )}
      {report.graphData && (
        <FlexRow>
          <FlexGroup>
            <GraphReport
              data={report.extendedGraphData}
              graphData={report.graphData}
            />
          </FlexGroup>
        </FlexRow>
      )}
    </React.Fragment>
  );
};
