import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { SET_EQUITY, SET_EQUITY_LOADING } from './equity.actions';

const initState = baseInitialState;

export const equityReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_EQUITY:
      return actionUpdateRecords(state, action.payload);

    case SET_EQUITY_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
