import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { startCase, filter } from 'lodash';
import { mergeObjects, prop, len as length } from 'util/objectUtility';
import { sendEmails } from 'services/grants/emailManagement.services';
import { setAdminComments } from 'redux/common/adminComments/adminComments.actions';
import { EmailTemplate, AdminComments, emptyAdminComment } from 'types';
import { FlexRow } from 'layouts';
import { Dialog } from '@rmwc/dialog';
import Button from 'components/Common/FormElements/Button';
import FancyLabel from 'components/Common/FormElements/FancyLabel';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import RenderHTML from 'components/Common/RenderHTML';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Textarea from 'components/Common/FormElements/Textarea';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

type ManageTemplateProps = {
  adminComments: AdminComments;
  emailTemplate: EmailTemplate;
  isLoading: boolean;
  loadSingleEmailTemplate: Function;
};

export const ManageTemplate: React.FC<ManageTemplateProps> = (props) => {
  const {
    adminComments,
    emailTemplate,
    isLoading,
    loadSingleEmailTemplate,
  } = props;
  const { templateName, sendGridText } = emailTemplate;
  const dispatch = useDispatch();

  const { templateId: id, fiscalYear: fy }: any = useParams();
  const templateId = Number(id);
  const fiscalYear = Number(fy);
  const history = useHistory();

  const navigateToRecipients = () => {
    history.push(
      `/grants/email-management/recipients/${fiscalYear}/${templateId}`
    );
  };
  const [state, setState] = useState<AdminComments>(emptyAdminComment());
  const [updating, setUpdating] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const debouncedAdminComment = useDebounce(state, 1000);

  const onChange = (data: Partial<AdminComments>) => {
    setUpdating(true);
    const updatedComment = mergeObjects<AdminComments>(adminComments, {
      ...data,
      templateId,
    });
    setState(updatedComment);
  };

  const handleSendEmail = async () => {
    setSending(true);
    return sendEmails(fiscalYear, templateId, adminComments.adminComment)
      .then(() => {
        setSending(false);
        setModalOpen(false);
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.EmailScheduled}
          />
        );
      })
      .catch(() => {
        setSending(false);
        setModalOpen(false);
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      });
  };

  const marginBottom = { marginBottom: '2rem' };
  const included = prop('include');
  const includedRecipients = length(filter(emailTemplate.recipients, included));

  useEffect(() => {
    loadSingleEmailTemplate(fiscalYear, templateId);
  }, [fiscalYear, loadSingleEmailTemplate, templateId]);

  useEffect(() => {
    if (!updating) {
      setState(adminComments);
    }
  }, [updating, adminComments, setState]);

  useEffect(() => {
    if (updating) {
      dispatch(setAdminComments(state));
      setUpdating(false);
    }
  }, [debouncedAdminComment]); // eslint-disable-line

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isLoading} messageBefore="Loading Email Template..." />
      <Loading isActive={sending} messageBefore="Sending Emails..." />

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="dialog">
          <h2 className="dialog__title">Send Email to Recipients?</h2>
          <div className="dialog__children">
            <FlexRow>
              <p>
                There are
                <span className="u-color-orange-dark u-bold">
                  {` ${includedRecipients} `}
                </span>
                recipients selected to receive this email, would you like to
                send it?
              </p>
            </FlexRow>
            <p>
              Note: to edit recipients, click "Cancel", then click "View
              Recipients" to navigate to recipient management to include or
              exclude recipients.
            </p>
          </div>
          <div className="dialog__btn-container">
            <Button
              onClick={() => setModalOpen(false)}
              className="form__btn--cancel"
              text="Cancel"
            />
            <Button
              onClick={handleSendEmail}
              className="confirm-button form__btn--submit"
              text="Confirm and Send Email"
            />
          </div>
        </div>
      </Dialog>
      <Paper>
        <h1 className="program__title" style={marginBottom}>
          Manage Email Template: {startCase(templateName)} {fiscalYear}
        </h1>
        <FlexRow>
          <p>
            The “Template Preview” below is read only and illustrates what
            recipients will see upon sending the email. Click the “View
            Recipients” button to edit who will receive this email. You may
            append comments to the email form using the “Admin Comments” field.
          </p>
        </FlexRow>
      </Paper>
      <Paper>
        <h2 className="u-section-title-text" style={marginBottom}>
          Template Preview
        </h2>
        <RenderHTML
          label="Preview of the email for this template (stored in SendGrid)"
          htmlString={sendGridText}
        />
      </Paper>
      <Paper>
        <h2 className="u-section-title-text" style={marginBottom}>
          Recipients
        </h2>
        <FlexRow>
          <p>You may edit who will receive this pre-populated template.</p>
        </FlexRow>
        <SecureWrap allowReadOnly={true} component="GrantsEmailManagement">
          <FlexRow style={{ justifyContent: 'flex-end', height: '5rem' }}>
            <Button
              style={{ width: '30%' }}
              className="button--outline"
              text="view recipients"
              onClick={navigateToRecipients}
            />
          </FlexRow>
        </SecureWrap>
      </Paper>
      <SecureWrap component="GrantsEmailManagement">
        <Paper>
          <h2 className="u-section-title-text" style={marginBottom}>
            Admin Comments
          </h2>
          <FlexRow>
            <Textarea
              name="adminComment"
              label={
                <FancyLabel
                  bool={updating}
                  classNameTrue="u-icon-base-size u-icon-orange u-spin-animation"
                  classNameFalse="u-icon-base-size u-icon-green"
                  label="Add any comments that should be included in sending this template"
                  iconNameFalse="checkMark"
                  iconNameTrue="loopSync"
                />
              }
              onChange={onChange}
              value={state.adminComment}
            />
          </FlexRow>
        </Paper>
        <Paper>
          <SaveButtons
            cancelText="Return to Dashboard"
            cancelUrl={`/grants/email-management/${fiscalYear}`}
            saveText="Send Email"
            alternateAction={() => setModalOpen(true)}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
