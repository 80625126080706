import { connect } from 'react-redux';
import { ReimbursementRequestSummaryComponent } from './ReimbursementRequestSummary.component';
import { getFirstRecord_ReimbursementSummary } from 'redux/grants/_selectors';
import { loadReimbursementRequestSummaryData } from 'redux/grants/reimbursementrequestsummary';

const mapStateToProps = (state: any) => {
  const reimbursementSummary = getFirstRecord_ReimbursementSummary(state);

  return {
    reimbursementSummary,
  };
};

const dispatchProps = {
  loadReimbursementRequestSummaryData,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ReimbursementRequestSummaryComponent);
