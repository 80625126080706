import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionRole } from 'types';
import {
  readPermissionRoles,
  createPermissionRole,
  updatePermissionRole,
  deletePermissionRole,
} from 'services/permissions/permissionroles.services';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadPermissionRoles = createActionThunk(
  'loadPermissionRoles',
  () => {
    return readPermissionRoles();
  }
); // end

export const removePermissionRole = createActionThunk(
  'removePermissionRole',
  (payload: PermissionRole) => {
    return deletePermissionRole(payload);
  }
); // end

export const savePermissionRole = createActionThunk(
  'savePermissionRole',
  async (payload: PermissionRole) => {
    let creating = !payload.permissionRoleId || payload.permissionRoleId === -1;
    if (creating) {
      return createPermissionRole(payload);
    } else {
      return updatePermissionRole(payload).then(() => {
        return payload.permissionRoleId;
      });
    }
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, PermissionRole[]>(
  {
    ...baseLoadActionBuilder<PermissionRole[]>(loadPermissionRoles),

    [savePermissionRole.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [savePermissionRole.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
