import { FiscalYearSideNav } from 'components/Grants/FiscalYearManagement/FiscalYearSideNav/FiscalYearSideNav.component';
import { connect } from 'react-redux';
import {
  getCurrentNavAnchor,
  getNavTrayClosed,
} from 'redux/application/selectors';
import { setNavTrayClosed } from 'redux/application';
import { getRecords_GrantFiscalYearNav } from 'redux/nav/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const currentNavAchor = getCurrentNavAnchor(state);
  const navTrayClosed = getNavTrayClosed(state);
  const navData = getRecords_GrantFiscalYearNav(state);

  return {
    currentNavAchor,
    navTrayClosed,
    navData,
  };
};

const dispathProps = {
  setNavTrayClosed,
};

export default connect(
  mapStateToProps,
  dispathProps
)(FiscalYearSideNav);
