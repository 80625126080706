import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramArticulations,
  deleteArticulation,
  createUpdateArticulation,
} from 'services/programs/articulations.services';
import { ProgramArticulation } from 'types';
import {
  BaseStateObject,
  baseLoadActionBuilder,
  baseInitialState,
} from 'redux/utility/_exports';

const initialState = baseInitialState;

export const removeArticulation = createActionThunk(
  'removeArticulation',
  (articulationId: number) => {
    return deleteArticulation(articulationId);
  }
);

export const loadProgramArticulations = createActionThunk(
  'loadProgramArticulations',
  (programInstanceId: number) => {
    return readProgramArticulations(programInstanceId);
  }
);

export const saveArticulation = createActionThunk(
  'saveArticulation',
  (payload: ProgramArticulation) => {
    if (payload.programArticulationId >= 1) {
      return createUpdateArticulation(payload);
    }
    const articulation: Omit<ProgramArticulation, 'programArticulationId'> = {
      programInstanceId: payload.programInstanceId,
      partnerProgramInstanceId: payload.partnerProgramInstanceId,
      contactId: payload.contactId,
      schoolId: payload.schoolId,
      type: payload.type,
      year: payload.year,
      filePath: payload.filePath,
      modifiedBy: payload.modifiedBy,
      hasHighAdvisoryCommittee: payload.hasHighAdvisoryCommittee,
      isCurrent: payload.isCurrent,
    };
    return createUpdateArticulation(articulation);
  }
);

export default handleActions<BaseStateObject, ProgramArticulation>(
  {
    ...baseLoadActionBuilder<ProgramArticulation>(loadProgramArticulations),

    [saveArticulation.START]: (state, { payload }) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [saveArticulation.SUCCEEDED]: (state, { payload }) => {
      return {
        ...state,
        lastUpdate: Date.now(),
        isFetching: false,
      };
    },
    [saveArticulation.FAILED]: (state, { payload }) => {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },

    [removeArticulation.START]: (state, { payload }) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    [removeArticulation.SUCCEEDED]: (state, { payload }) => {
      return {
        ...state,
        lastUpdate: Date.now(),
        isFetching: false,
      };
    },
    [removeArticulation.FAILED]: (state, { payload }) => {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  initialState
);
