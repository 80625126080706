import { NAV_KEY, NAV_GRANT_FOUR_YEAR_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { SideNavData, emptySideNavData } from 'types/ui/SideNavData.types';
import { defaultTo } from 'lodash';
import { ComponentStep } from 'types/ui/ComponentStep.types';
import { findIndex } from 'lodash';

export const NAV_GRANT_FOUR_YEAR_PATH: string[] = [
  NAV_KEY,
  NAV_GRANT_FOUR_YEAR_KEY,
];

export const getIsDataFetching_GrantFourYearNav = (state: any): boolean => {
  return getIsDataFetching(state, NAV_GRANT_FOUR_YEAR_PATH);
};

export const getLastUpdated_GrantFourYearNav = (state: any): number => {
  return getLastUpdated(state, NAV_GRANT_FOUR_YEAR_PATH);
};

export const shouldRefreshReduxState_GrantFourYearNav = (
  state: any
): boolean => {
  return shouldRefreshReduxState<SideNavData>(
    state,
    NAV_GRANT_FOUR_YEAR_PATH,
    300
  );
};

export const getRecords_GrantFourYearNav = (state: any) => {
  const records = getRecords<SideNavData>(state, NAV_GRANT_FOUR_YEAR_PATH);
  const [data] = records;
  return defaultTo(data, emptySideNavData());
};

export const getNextFourYearStepUrl = (state: any, anchor: string): string => {
  const navData = getRecords_GrantFourYearNav(state);
  const currentStepIndex = findIndex(navData.steps, (step: ComponentStep) => {
    return step.nav.anchor === anchor;
  });
  if (currentStepIndex > -1) {
    if (currentStepIndex < navData.steps.length) {
      return navData.steps[currentStepIndex + 1].nav.url;
    }
  }
  return '';
};
