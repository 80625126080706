import { CTA_KEY, CTA_DASHBOARD_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { CTADistrictRecord, emptyCTADistrictRecord } from 'types';
import { defaultTo, find, curry } from 'lodash';
import { getCTADashboardFilters } from 'redux/filters/_selectors';

export const CTA_DASHBOARD_PATH = [CTA_KEY, CTA_DASHBOARD_KEY];

export const getIsDataFetching_CTADistrictRecord = (state: any): boolean => {
  return getIsDataFetching(state, CTA_DASHBOARD_PATH);
};

export const getRecords_CTADistrictRecord = (
  state: unknown
): CTADistrictRecord[] => {
  return getRecords(state, CTA_DASHBOARD_PATH);
};

export const getAdvancedFilteredRecords_CTADistrictRecord = (
  state: any
): CTADistrictRecord[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTADistrictRecord>(
    state,
    CTA_DASHBOARD_PATH,
    filters
  );
  return records;
};

export const getSingleRecord_CTADistrictRecord = (
  state: unknown,
  ctaId: number
): CTADistrictRecord => {
  const records = getRecords_CTADistrictRecord(state);

  return defaultTo(
    find(records, (item: CTADistrictRecord) => {
      return item.ctaId === Number(ctaId);
    }),
    emptyCTADistrictRecord()
  );
};

export const getCTADistrictRecord = curry(
  (ctaId: number, state: unknown): CTADistrictRecord => {
    const records = getRecords_CTADistrictRecord(state);

    return defaultTo(
      find(records, (item: CTADistrictRecord) => {
        return item.ctaId === Number(ctaId);
      }),
      emptyCTADistrictRecord()
    );
  }
);
