import { connect } from 'react-redux';
import { ProgramEnrollmentComponent } from './ProgramEnrollment.component';
import { loadStudentEnrollmentData } from 'redux/datacollection/studentenrollment';
import {
  getIsDataFetching_StudentEnrollment,
  getFirstRecord_StudentEnrollment,
} from 'redux/datacollection/studentenrollment.selectors';
import { emptyStudentEnrollment } from 'types';
import { firstOrDefault } from 'util/arrayUtility';
import { filter, some } from 'lodash';
import { deactivateSnapshotStudent } from 'redux/datacollection/studentdata';
import {
  insertSnapshotStudent,
  loadProgramSnapshotStudent,
} from 'redux/datacollection/programsnapshotstudent';
import {
  getRecords_ProgramSnapshotStudent,
  getIsDataFetching_ProgramSnapshotStudent,
} from 'redux/datacollection/programsnapshotstudent.selectors';
import {
  getProgram,
  getIsDataFetching_ProgramInstance,
} from 'redux/programs/_selectors';
import { loadSingleProgramInstance } from 'redux/programs/programinstance';

const mapStateToProps = (state: any, ownProps: any) => {
  const programId = Number(ownProps.match.params.programId);

  const enrollmentdata = getFirstRecord_StudentEnrollment(state);

  const schoolYear = enrollmentdata.schoolYear;
  const students = getRecords_ProgramSnapshotStudent(state);

  const enrollment = firstOrDefault(
    filter(enrollmentdata.enrollments, (item) => {
      return item.programBaseId === programId;
    }),
    emptyStudentEnrollment()
  );
  const schoolId = getProgram(state, enrollment.programInstanceId).schoolId;
  const isFetching = some([
    getIsDataFetching_StudentEnrollment(state),
    getIsDataFetching_ProgramSnapshotStudent(state),
    getIsDataFetching_ProgramInstance(state),
  ]);

  return {
    schoolId,
    isFetching,
    enrollment,
    schoolYear,
    students,
  };
};

const dispatchToProps = {
  deactivateSnapshotStudent,
  loadProgramSnapshotStudent,
  loadStudentEnrollmentData,
  insertSnapshotStudent,
  loadSingleProgramInstance,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramEnrollmentComponent);
