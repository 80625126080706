import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { useParams } from 'react-router-dom';
import { ContactsEdit } from 'components/Program/Contacts/ContactsConnect';

export const ProgramContactsStep: React.FC = (props) => {
  const { programInstanceId }: any = useParams();

  return (
    <BaseLayout>
      <ProgramLayout {...props} programInstanceId={programInstanceId}>
        <ContactsEdit />
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramContactsStep;
