import { NAV_KEY, NAV_GRANT_ANNUAL_SPENDING_PLAN_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { SideNavData, emptySideNavData } from 'types/ui/SideNavData.types';
import { defaultTo } from 'lodash';

export const NAV_GRANT_ANNUAL_SPENDING_PLAN_PATH: string[] = [
  NAV_KEY,
  NAV_GRANT_ANNUAL_SPENDING_PLAN_KEY,
];

export const getIsDataFetching_GrantSpendingPlanNav = (state: any): boolean => {
  return getIsDataFetching(state, NAV_GRANT_ANNUAL_SPENDING_PLAN_PATH);
};

export const getLastUpdated_GrantSpendingPlanNav = (state: any): number => {
  return getLastUpdated(state, NAV_GRANT_ANNUAL_SPENDING_PLAN_PATH);
};

export const shouldRefreshReduxState_GrantSpendingPlanNav = (
  state: any
): boolean => {
  return shouldRefreshReduxState<SideNavData>(
    state,
    NAV_GRANT_ANNUAL_SPENDING_PLAN_PATH,
    300
  );
};

export const getRecords_GrantSpendingPlanNav = (state: any) => {
  const records = getRecords<SideNavData>(
    state,
    NAV_GRANT_ANNUAL_SPENDING_PLAN_PATH
  );
  const [data] = records;
  return defaultTo(data, emptySideNavData());
};
