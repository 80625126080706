import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { some, every, isEmpty } from 'lodash';
import { isEqual } from 'util/formHelpers';
import { compose, renderData, prop } from 'util/objectUtility';
import { FlexRow } from 'layouts';
import {
  readFiscalAgentParticipation,
  updateFiscalAgentParticipation,
} from 'services/grants/intentToParticipate.services';
import {
  IntentToParticipateFiscalAgent,
  emptyIntentToParticipateFiscalAgent,
  IntentToParticipateMember,
  GrantSchedule,
} from 'types';
import { ConsortiumOverview } from '../ConsortiumOverview';
import {
  getContactFullName,
  getUserEmail,
} from 'util/universalcontact.utility';
import { IntentToParticipateFiscalAgentValidations } from 'validations/IntentToParticipate/IntentToParticipateFiscalAgent.validations';
import { loadConsortia } from 'redux/grants/consortium';
import { useDispatch } from 'react-redux';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ToastNotification from 'components/Common/Toast';
import SaveButtons from 'components/Common/SaveButtons';
import Icon from 'components/Common/Icons';
import NoSubmissionAccess from '../NoSubmissionAccess';
import Table from 'components/Common/Table';
import Instruction from 'components/Common/Instruction';
import StringResources from 'StringResources';
import { readGrantSchedule } from 'services/grants/grantschedule.services';
import { isApplicaionYearValid } from 'util/global';

export const FiscalAgentManagementComponent: React.FC = () => {
  const { secureGUID }: any = useParams();
  const [
    fiscalAgentData,
    setFiscalAgentData,
  ] = useState<IntentToParticipateFiscalAgent>(
    emptyIntentToParticipateFiscalAgent()
  );
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fiscalYear, setFiscalYear] = useState<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsActive(true);
    if (secureGUID) {
      readFiscalAgentParticipation(secureGUID)
        .then((response) => {
          setFiscalAgentData(response);
          setFiscalYear(response.fiscalYear);
        })
        .catch((err) => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.LoadDataFailed}
            />
          );
        })
        .finally(() => {
          setIsActive(false);
        });
    }
  }, [secureGUID]);

  useEffect(() => {
    validateAll(fiscalAgentData);
  }, [fiscalAgentData]); //eslint-disable-line

  useEffect(() => {
    if (isApplicaionYearValid(fiscalYear)) {
      setIsLoading(true);
      readGrantSchedule('intent-to-participate', fiscalYear)
        .then((data: GrantSchedule) => {
          setIsOpen(data.isOpen);
        })
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.LoadDataFailed}
            />
          );
          setIsOpen(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [fiscalYear]);

  const {
    isValid,
    validateAll,
    validationErrors,
  } = IntentToParticipateFiscalAgentValidations();

  const navigateToParticipationManagement = () => {
    const { fiscalYear, consortiumId } = fiscalAgentData;
    if (fiscalYear > 0) {
      const t = window.open(
        `/grants/consortium/add-edit/${consortiumId}/${fiscalYear}`,
        '_blank'
      );
      t && t.focus();
    } else {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.InvalidResponse}
        />
      );
    }
  };
  const history = useHistory();
  const handleSave = async () => {
    setIsActive(true);
    updateFiscalAgentParticipation(secureGUID)
      .then(() => {
        dispatch(loadConsortia());
        history.push(`/grants/participation-success`);
      })
      .catch(() => {
        setIsActive(false);
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      });
  };

  const renderHasResponded = (row: IntentToParticipateMember) => {
    return (
      <React.Fragment>
        <Icon
          name={
            row.hasResponded && row.hasResponded ? 'circleFilled' : 'circleOpen'
          }
          className={
            'consortium-view__status-icon consortium-view__status-icon--active'
          }
        />
      </React.Fragment>
    );
  };

  const renderInConsortium = (row: IntentToParticipateMember) => {
    return (
      <React.Fragment>
        <Icon
          name={
            every([
              row.isActive,
              row.consortiumId === fiscalAgentData.consortiumId,
            ])
              ? 'circleFilled'
              : 'circleOpen'
          }
          className={
            'consortium-view__status-icon consortium-view__status-icon--active'
          }
        />
      </React.Fragment>
    );
  };

  const renderParticipating = (row: IntentToParticipateMember) => {
    return (
      <React.Fragment>
        <Icon
          name={
            row.isParticipating && row.isParticipating
              ? 'circleFilled'
              : 'circleOpen'
          }
          className={
            'consortium-view__status-icon consortium-view__status-icon--active'
          }
        />
      </React.Fragment>
    );
  };

  const Member = compose(renderData, prop('memberName'));

  const PrimaryContactName = compose(
    getContactFullName,
    prop('perkinsContact')
  );

  const ContactEmail = compose(
    renderData,
    getUserEmail,
    prop('perkinsContact')
  );

  const isInConsortium = compose(
    isEqual(fiscalAgentData.consortiumId),
    prop('consortiumId')
  );

  const MEMBER_HEADERS: TableColumnProps[] = [
    {
      name: 'Member',
      cell: Member,
      sortable: true,
      selector: 'memberName',
    },
    {
      name: 'Response Submitted',
      cell: renderHasResponded,
      sortable: true,
      selector: 'hasResponded',
    },
    {
      name: 'In this Consortium',
      cell: renderInConsortium,
      sortable: true,
      selector: isInConsortium,
    },
    {
      name: 'Participating',
      cell: renderParticipating,
      sortable: true,
      selector: renderParticipating,
    },
    {
      name: 'Primary Contact Name',
      cell: PrimaryContactName,
      sortable: true,
      selector: PrimaryContactName,
    },
    {
      name: 'Contact Email',
      cell: ContactEmail,
      sortable: true,
      selector: ContactEmail,
    },
  ];

  const readOnly = () => {
    return some([
      fiscalAgentData.hasResponded,
      isEmpty(fiscalAgentData.fiscalAgentName.trim()),
    ]);
  };

  return (
    <React.Fragment>
      <Loading isActive={some([isLoading, isActive])} />
      <SecureWrap
        requireEdit={true}
        isLocked={!isOpen}
        component={'owneronly'}
        owner={readOnly() ? 0 : fiscalAgentData.fiscalAgentUniversalContactId}
        isPage={false}
        noAccessComponent={<NoSubmissionAccess />}
      >
        <Paper>
          <Instruction title="Perkins Intent to Participate">
            <p>
              Verify which members to include in your consortium and that all
              members have submitted their Intent to Participate. If you need to
              add or remove sites, use “Update Members”. You cannot submit this
              form until all consortium members have submitted a response.
            </p>
            <br />
            <p>
              You will receive an email confirmation upon successful submission.
            </p>
            <br />
            <p className="u-error">
              Failure to submit this form before the deadline will disqualify
              your consortium from the next allocation formula calculation. Your
              deadline, set by the CCCS Perkins Plan Manager, is in your Intent
              to Participate notification sent via email.
            </p>
          </Instruction>
        </Paper>
        <Paper>
          <FlexRow>
            <h2 className="u-section-title-text">Consortium Overview</h2>
          </FlexRow>
          <ConsortiumOverview fiscalAgentData={fiscalAgentData} />
        </Paper>
        <Paper>
          <h2 className="u-section-title-text">
            Verify all members have submitted their Intent to Participate
          </h2>
          <Table columns={MEMBER_HEADERS} data={fiscalAgentData.members} />
        </Paper>
        <Paper>
          <SaveButtons
            alternateAction={handleSave}
            cancelText="update members"
            onCancel={navigateToParticipationManagement}
            disabled={some([!isValid, !isOpen])}
            saveText="submit intent to participate"
            validationErrors={
              !isOpen
                ? ['The intent to participate window is closed.']
                : validationErrors
            }
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
