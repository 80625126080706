import React, { useState } from 'react';
import { FlexRow } from 'layouts';
import { Dialog } from '@rmwc/dialog';
import { useDispatch } from 'react-redux';
import Button from 'components/Common/FormElements/Button';
import Icon from 'components/Common/Icons';

type RefreshDataProps = {};

export const RefreshData: React.FC<RefreshDataProps> = () => {
  const [open, setOpen] = useState(false);
  const [navFocused, setNavFocused] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch({ type: 'REFRESH_DATA' });
  };

  const getButtonClass = () => {
    return navFocused
      ? 'navigation__button navigation__button--focused'
      : 'navigation__button';
  };

  return (
    <React.Fragment>
      <div className="navigation">
        <div className="navigation__menu-btn">
          <input
            className="navigation__checkbox"
            id="refresh-button"
            onBlur={() => setNavFocused(false)}
            onChange={() => setOpen(true)}
            onFocus={() => setNavFocused(true)}
            onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 && setOpen(true)}
            type="checkbox"
          />
          <label
            aria-label="refresh site data"
            className={getButtonClass()}
            htmlFor="refresh-button"
          >
            Refresh
            <span className="navigation__button--text">
              <Icon name="loopSync" className="navigation__refresh-icon" />
            </span>
          </label>
        </div>
        <p aria-hidden className="navigation__menu-title" onClick={() => setOpen(true)}>
          Refresh
        </p>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className="dialog">
          <h2 className="dialog__title">Refresh Site Data</h2>
          <FlexRow>
            <p>Do you wish to continue and refresh site data?</p>
          </FlexRow>
          <FlexRow>
            <p className="u-bold u-color-red">
              Once the process is completed, use your browser's 'page reload' button to reload
              your current page.
            </p>
          </FlexRow>
          <FlexRow>
            <p className="u-error">Warning: Unsaved changes will be lost.</p>
          </FlexRow>
          <div className="dialog__btn-container">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              className="form__btn--cancel"
              text="abort"
            />
            <Button
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
              className="form__btn--cancel"
              text="continue"
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
