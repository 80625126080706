import React from 'react';
import {
  saveSnapshotStudent,
  deactivateSnapshotStudent,
  loadSnapshotStudent,
} from 'redux/datacollection/studentdata';
import {
  insertSnapshotStudent,
  removeSnapshotStudent,
} from 'redux/datacollection/programsnapshotstudent';
import {
  insertEnrollmentComplete,
  insertFollowupComplete,
  loadStudentEnrollmentData,
  loadStudentFollowUpData,
} from 'redux/datacollection/studentenrollment';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';

export const snapshotStudentMiddleware = (store: any) => (
  next: Function
) => async (action: any) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveSnapshotStudent.SUCCEEDED: {
      const ErrorEntity = action.payload;
      if (ErrorEntity.entity !== null) {
        dispatch(insertSnapshotStudent(ErrorEntity.entity));
        dispatch(loadSnapshotStudent());
      }
      break;
    }

    case deactivateSnapshotStudent.SUCCEEDED: {
      const student = action.payload;
      dispatch(removeSnapshotStudent(student));
      toast(
        <ToastNotification
          status="success"
          message="Student successfully removed."
        />
      );
      break;
    }

    // when Student enrollment status is saved reload the emrollment data
    case insertEnrollmentComplete.SUCCEEDED: {
      dispatch(loadStudentEnrollmentData());
      break;
    }

    // when Student follow-up status is saved reload the follow-up data
    case insertFollowupComplete.SUCCEEDED: {
      dispatch(loadStudentFollowUpData());
      break;
    }
  }
};
