import { useValidation } from 'de-formed-validations';
import { CTAProgram } from 'types';
import { gt as lt } from 'ramda';

export const CTAProgramValidation = () => {
  return useValidation<CTAProgram>({
    costs: [
      {
        errorMessage: 'Costs must be less than $1 million.',
        validation: lt(1000000),
      },
    ],
  });
};
