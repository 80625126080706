import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { ReportItem, ReportingFilterState } from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { get, map } from 'lodash';
import { loadPublicReportList, loadReportList } from 'redux/reporting/reports';
import { randomString } from 'util/global';
import { renderData, prop } from 'util/objectUtility';
import { useDispatch } from 'react-redux';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import FilterIconButton from 'components/Common/Filters/FilterIconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ReportingHeader from './ReportingHeader';
import ReportingItem from './ReportingItem';
import ReportsDashboardFilters, { RailFilters } from './Filters';
import ScrollToTop from 'components/Common/ScrollToTop';
import SearchBarFilter from 'components/Common/SearchBarFilter';

type ReportsDashboardProps = {
  isLoggedin: boolean;
  isFetching: boolean;
  reports: ReportItem[];
  filterState: ReportingFilterState;
};

export const ReportsDashboardComponent: React.FC<ReportsDashboardProps> = ({
  isFetching,
  reports,
  isLoggedin,
  filterState,
}) => {
  const headersReport: ReportItem = {
    cccsFavorite: false,
    ctaReview: false,
    dcReview: false,
    description: 'Description',
    extendedGraphData: [],
    financeFavorite: false,
    module: 'Module',
    mostUsed: false,
    name: 'Report',
    parameters: [],
    reportType: 'both',
    rows: [],
    rows2: [],
    rows3: [],
    selectedData: [],
  };

  // -- local state --
  const [filteredData, setFilteredData] = useState<ReportItem[]>([]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const getDisplayData = (name: keyof ReportItem) => (row: ReportItem) => {
    return renderData(prop(name, row));
  };

  // -- lifecycle --
  useEffect(() => {
    if (isLoggedin) {
      dispatch(loadReportList());
    } else {
      dispatch(loadPublicReportList());
    }
  }, [isLoggedin, dispatch]);

  // -- display logic --
  const REPORTS_DASHBOARD_HEADERS: TableColumnProps[] = [
    {
      name: 'Report',
      cell: getDisplayData('name'),
    },
    {
      name: 'Module',
      cell: getDisplayData('module'),
    },
    {
      name: 'Description',
      cell: getDisplayData('description'),
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <Paper>
        <Instruction title="Reporting Dashboard">
          {isLoggedin ? (
            <React.Fragment>
              <p>
                This page enables you to generate reports summarizing your data.
                Enter text in the Search bar to find a specific report or click
                the green filter button to sort reports by module. Use the
                buttons below the search bar to access pre-populated reports.
                These include those ‘Most Used’ by local users or ‘CCCS
                Favorites.’ The “DC Review” displays reports required for review
                from the Data Collections module and “CTA Review” ones required
                to review from the ‘Review and Optimize’ section of the CTA
                module.
              </p>
              <p>
                <span className="u-color-red">Note</span>: reports are limited
                to those for which you have data access permissions defined in
                the Universal Contacts module.
              </p>
              <br />
              <p>
                The highlighted summary section arrays the report name, module,
                and a brief description. Click on “Open All” to display detailed
                report filters or click on a specific one to expand and run
                tailored requests. The "View on Web" button will open the report
                in a new page that allows you to sort by column and print.
                Alternatively, click the "Export to Excel" button to download an
                editable file and create your own print parameters.
              </p>
              <p>
                <span className="u-color-red">Note</span>: If no filter is
                selected, most reports will still run with default parameters.
              </p>
            </React.Fragment>
          ) : (
            <p>The following are the publically availablke CCCS Reports</p>
          )}
        </Instruction>
      </Paper>
      <FlexRow>
        <FlexGroup>
          <Paper>
            <FlexRow>
              <SearchBarFilter
                data={reports}
                headers={REPORTS_DASHBOARD_HEADERS}
                isFetching={isFetching}
                label="Search For Report"
                setFilteredData={setFilteredData}
                shouldReset={true}
              />
              {isLoggedin && (
                <FilterIconButton
                  count={get(filterState, 'module', []).length}
                  onClick={() => {
                    setFiltersOpen(!filterOpen);
                  }}
                />
              )}
            </FlexRow>
            {isLoggedin && <ReportsDashboardFilters />}
            <FlexRow
              style={{ padding: '2.4rem 0 0 2.4rem', boxSizing: 'border-box' }}
            >
              <ReportingHeader bold={true} report={headersReport} />
              <div style={{ width: '4.8rem' }} />
            </FlexRow>
            <FlexRow>
              <FlexGroup>
                {map(filteredData, (report: ReportItem) => {
                  return (
                    <CTACollapsableLayout
                      name={report.name}
                      key={randomString()}
                      header={<ReportingHeader report={report} />}
                    >
                      <ReportingItem report={report} />
                    </CTACollapsableLayout>
                  );
                })}
              </FlexGroup>
            </FlexRow>
          </Paper>
        </FlexGroup>
        {filterOpen && <RailFilters />}
      </FlexRow>
    </React.Fragment>
  );
};
