import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import CTAPortal from 'pages/CTA/CTAPortalPage';
import CTAAdmin from 'pages/CTA/CTAAdminPage';
import CTADashboard from 'pages/CTA/CTADashboard';
import DistrictPage from 'pages/CTA/DistrictPage';
import CTAContractPage from 'pages/CTA/CTAContractPage';
import CTAHostingPage from 'pages/CTA/CTAHostingPage';
import CTAInstructorPage from 'pages/CTA/CTAInstructorPage';

const ctaRoutes = [
  <Route exact path="/cta" component={authentication.required(CTAPortal)} />,
  <Route
    exact
    path="/cta/admin"
    component={authentication.required(CTAAdmin)}
  />,
  <Route
    exact
    path="/cta/district-reporting"
    component={authentication.required(CTADashboard)}
  />,
  <Route
    exact
    path="/cta/district-reporting/:ctaId"
    component={authentication.required(DistrictPage)}
  />,
  <Route
    exact
    path="/cta/district-reporting/contracting/:ctaId/:ctaHostingId"
    component={authentication.required(CTAContractPage)}
  />,
  <Route
    exact
    path="/cta/district-reporting/hosting/:ctaId/:ctaContractingId"
    component={authentication.required(CTAHostingPage)}
  />,
  <Route
    path="/cta/district-reporting/instructor/:ctaId/:ctaInstructorId"
    component={authentication.required(CTAInstructorPage)}
  />,
  <Route
    exact
    path="/cta/reports"
    component={authentication.required(CTAPortal)}
  />,
];

export default ctaRoutes;
