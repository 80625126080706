import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { ImprovementPlan } from 'types';
import { useParams } from 'react-router';
import { SingleDeficiencySubmitValidations } from 'validations/ImprovementPlan/SingleDeficiencySubmit.validations';
import { BasicGrantSignatureValidations } from 'validations/BasicGrantSignature.validations';
import { QuestionValidation } from 'validations/Question.validations';

type Props = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadImprovementPlan: Function;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  improvementPlan: ImprovementPlan;
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
};

export const ImprovementPlanWorkflowComponent: React.FC<Props> = (props) => {
  const { loadImprovementPlan, improvementPlan } = props;

  const { grantFiscalYearId: cId }: any = useParams();
  const grantFiscalYearId = Number(cId);

  const { validate, validationErrors } = SingleDeficiencySubmitValidations();
  const {
    validate: sigVal,
    validationErrors: sigErrors,
  } = BasicGrantSignatureValidations();
  const {
    validate: qvalidation,
    validationErrors: qerrors,
  } = QuestionValidation();

  const wrap = {
    ...props,
    validationErrors: [...validationErrors, ...sigErrors, ...qerrors],
  };

  useEffect(() => {
    loadImprovementPlan(grantFiscalYearId);
  }, [loadImprovementPlan, grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    if (improvementPlan) {
      validate('areQuestionsAnswered', improvementPlan);
      if (improvementPlan.hasMultiYearDeficiencies) {
        sigVal('hasAllRequiredSignatures', [
          improvementPlan.multiYearSignature,
        ]);
        qvalidation('allQuestionsAnswered', improvementPlan.multiYearQuestions);
      }
    }
  }, [improvementPlan, grantFiscalYearId]); //eslint-disable-line

  return <WorkflowSummaryComponent {...wrap} />;
};
