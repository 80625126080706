import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import Skeleton from 'components/Common/Skeleton';

type InstructionProps = {
  actionButton?: JSX.Element;
  isPageHeader?: boolean;
  title: string;
  hideTitle?: boolean;
};

export const Instruction: React.FC<InstructionProps> = (props) => {
  const {
    actionButton,
    isPageHeader,
    title,
    children,
    hideTitle = false,
  } = props;

  const className = () => {
    return isPageHeader ? 'program__title' : 'u-section-title-text';
  };
  return (
    <div className="instruction">
      {hideTitle ? null : title ? (
        <FlexRow>
          <h2 className={className()}>{title}</h2>
          {actionButton && (
            <React.Fragment>
              <div className="u-flex-grow-1" />
              {actionButton}
            </React.Fragment>
          )}
        </FlexRow>
      ) : (
        <Skeleton />
      )}
      <FlexRow>
        <FlexGroup>
          <h3>Instructions</h3>
          <div>{children}</div>
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
