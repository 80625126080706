import { PROGRAMS_KEY, PROGRAM_CONTACTS_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { ProgramContact, ProgramContactDeep } from 'types';
import { getRecords, getIsDataFetching } from 'redux/utility/_exports';
import { map, filter } from 'lodash';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';

export const CONTACTS_PATH = [PROGRAMS_KEY, PROGRAM_CONTACTS_KEY];

export const getRecords_ProgramContacts = (
  state: ApplicationState
): ProgramContactDeep[] => {
  return getRecords<ProgramContactDeep>(state, CONTACTS_PATH);
};

export const getProgramContacts = (
  state: ApplicationState,
  programInstanceId: number
): ProgramContact[] => {
  return filter(getRecords_ProgramContacts(state), (value: ProgramContact) => {
    return value.programInstanceId === programInstanceId;
  });
};

// TODO: is there a reason we are pulling the contact data from the API rather than loading it from redux?
// It's causing data to be mismatched.
export const getProgramContactsDeep = (
  state: ApplicationState,
  programInstanceId: number
): ProgramContactDeep[] => {
  const pcontacts = filter(
    getRecords_ProgramContacts(state),
    (value: ProgramContactDeep) => {
      return value.programInstanceId === programInstanceId;
    }
  );
  return map(pcontacts, (item) => {
    const contact = getContact(state, item.contactId);
    return {
      ...item,
      contact: contact.universalContactId > 0 ? contact : item.contact,
      // i am pulling the contact from the contact cache if it exists
      // otherwise I am just using what was returned by the API with the program contact.
    };
  });
};

export const getIsDataFetching_ProgramContacts = (state: any): boolean => {
  return getIsDataFetching(state, CONTACTS_PATH);
};
