import { connect } from 'react-redux';
import { PerformanceDataComponent } from './PerformanceData.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(PerformanceDataComponent);
