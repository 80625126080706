import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { WorkflowEdit } from 'components/Program/ProgramWorkflow/WorkflowConnect';

type WorkflowPageProps = {
  programInstanceId: number;
};

export const WorkflowPage: React.FC<WorkflowPageProps> = (props) => {
  const { programInstanceId } = props;
  return (
    <BaseLayout>
      <ProgramLayout {...props} programInstanceId={programInstanceId}>
        <WorkflowEdit />
      </ProgramLayout>
    </BaseLayout>
  );
};
