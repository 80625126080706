import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
} from 'services';

const BASE_URL = ['api', 'ctareviewoptimize'];

export const readCTAReviewOptimize = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Review Optimize', err, 'readCTAReviewOptimize');
    });
};
