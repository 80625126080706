import React, { useRef, useState, useEffect } from 'react';
import { isDisabled } from 'util/authorization';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { insertClass } from 'util/formHelpers';
import Icon from 'components/Common/Icons';

type IconButtonProps = {
  asLink?: boolean;
  className?: string;
  disabled?: boolean;
  modalClick?: Function;
  name: string;
  onClick?: Function;
  target?: string;
  text?: string;
  textAfter?: boolean;
  textClassName?: string;
  to?: string;
  tooltip: string;
};

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const {
    asLink,
    className,
    disabled = false,
    modalClick,
    name,
    onClick,
    target,
    text,
    textAfter = false,
    textClassName,
    to,
    tooltip,
  } = props;

  const [computedDisable, setComputedDisable] = useState<boolean>(false);
  const btnRef = useRef(null);

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, btnRef);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    modalClick && modalClick(btnRef);
    onClick && onClick();
  };

  const butt = () => (
    <React.Fragment>
      {!textAfter && text && (
        <p className={`icon-button__text ${insertClass(textClassName)}`}>
          {text}
        </p>
      )}
      <Icon
        name={name}
        className={`icon-button__icon ${insertClass(className)}`}
      />
      {textAfter && text && (
        <p className={`icon-button__text ${insertClass(textClassName)}`}>
          {text}
        </p>
      )}
    </React.Fragment>
  );

  const getLink = () => {
    return computedDisable ? (
      <div className="icon-button no-print">{butt()}</div>
    ) : (
      <React.Fragment>
        {to && (
          <Link
            aria-label={tooltip ? tooltip : startCase(name)}
            target={target}
            to={to}
          >
            <div className="icon-button no-print">{butt()}</div>
          </Link>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {asLink && to ? (
        getLink()
      ) : (
        <button
          title={tooltip ? tooltip : startCase(name)}
          className="icon-button no-print"
          disabled={computedDisable}
          onClick={handleClick}
          ref={btnRef}
        >
          {butt()}
        </button>
      )}
    </React.Fragment>
  );
};
