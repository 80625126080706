export interface SnapshotStudent {
  aas: number | null;
  academicFlag: boolean;
  active504: boolean | null;
  agsCte: number | null;
  agsAcademic: number | null;
  alternateId: string | null;
  americanIndian: boolean | null;
  apprenticeship: number | null;
  area: string;
  asian: boolean | null;
  black: boolean | null;
  cdeId: string;
  certExamCompleted: number | null;
  certExamPassed: number | null;
  cipAcademic: string;
  cipCode: string;
  coloradoResident: boolean;
  comment: string;
  completer: boolean | null;
  concentrator: boolean | null;
  contEducation: number | null;
  degreeLevel: number | null;
  dg_org_cip_id: string;
  disadvantaged: boolean | null;
  dob: Date;
  educationPlan: string;
  email: string;
  enrollmentDate: Date;
  ethnicity: string | null; // e.g. '06'
  firstName: string | null;
  followupDate: Date;
  foster: boolean;
  fullTimeCte: boolean | null;
  grade: string | null;
  highSchoolGraduate: number | null;
  hispanic: boolean | null;
  homeCde_ID: string;
  homeless: boolean;
  iep: boolean | null;
  instructorId: string;
  lastName: string | null;
  limitedEnglish: boolean | null;
  mathAssessment: number | null;
  middleName: string;
  migrantStudent: boolean | null;
  militaryStatus: string | null;
  militaryServiceId: number | null;
  newRecord: boolean | null;
  oneYearCteCertificate: number | null;
  outOfWorkforceIndividual: boolean | null;
  pacificIslander: boolean | null;
  parentInMilitary: boolean;
  participant: boolean | null;
  phone: string;
  placement: number | null;
  programId: number;
  programLevelId: number;
  programName: string;
  psDisability: boolean | null;
  readAssessment: number | null;
  registeredApprenticeship: number | null;
  sasId: string | null;
  schoolId: number;
  schoolYear: number;
  sciAssessment: number | null;
  secondaryCert: number | null;
  serviceProgram: number | null;
  sex: string;
  singleParent: boolean | null;
  sisFlag: boolean;
  snapshotId: number; // PK
  source: string;
  studentFollowup: boolean | null;
  studentId: string;
  ssn: string | null;
  teacherId: number;
  twoYearCteCertificate: number | null;
  vocCreditHours: number;
  wbl: boolean | null;
  wblType: number | null;
  white: boolean | null;
}

export const emptySnapshotStudent = (): SnapshotStudent => {
  return {
    snapshotId: -1,
    studentId: '',
    schoolId: -1,
    teacherId: -1,
    newRecord: true,
    studentFollowup: null,
    sasId: '',
    alternateId: '',
    ssn: '',
    programLevelId: -1,
    firstName: '',
    lastName: '',
    middleName: '',
    dob: new Date(Date.now()),
    area: '',
    phone: '',
    email: '',
    sex: '',
    cdeId: '',
    cipCode: '',
    iep: null,
    instructorId: '',
    active504: null,
    disadvantaged: null,
    grade: '',
    migrantStudent: null,
    concentrator: null,
    completer: null,
    highSchoolGraduate: null,
    wbl: null,
    wblType: null,
    registeredApprenticeship: null,
    secondaryCert: null,
    limitedEnglish: null,
    psDisability: null,
    outOfWorkforceIndividual: null,
    singleParent: null,
    homeless: false,
    parentInMilitary: false,
    coloradoResident: false,
    militaryStatus: '',
    militaryServiceId: null,
    educationPlan: '',
    cipAcademic: '',
    fullTimeCte: null,
    participant: null,
    oneYearCteCertificate: 0,
    twoYearCteCertificate: 0,
    aas: 0,
    agsCte: 0,
    agsAcademic: 0,
    apprenticeship: null,
    contEducation: null,
    serviceProgram: null,
    certExamCompleted: null,
    certExamPassed: null,
    degreeLevel: null,
    mathAssessment: null,
    readAssessment: null,
    sciAssessment: null,
    placement: null,
    homeCde_ID: '',
    foster: false,
    academicFlag: false,
    sisFlag: false,
    vocCreditHours: -1,
    dg_org_cip_id: '',
    source: '',
    comment: '',
    enrollmentDate: new Date(Date.now()),
    followupDate: new Date(Date.now()),
    programId: -1,
    programName: '',
    schoolYear: -1,
    americanIndian: null,
    asian: null,
    black: null,
    white: null,
    pacificIslander: null,
    hispanic: null,
    ethnicity: '', // e.g. '06'
  };
};
