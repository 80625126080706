import { getFilteredRecords } from 'redux/utility/baseSelectors';
import { connect } from 'react-redux';
import { DataFilter } from './DataFilter.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { path, itemFilter } = ownProps;
  const data = getFilteredRecords(state, path, itemFilter);
  return {
    data,
  };
};

export default connect(
  mapStateToProps,
  null
)(DataFilter);
