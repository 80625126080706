import { DATA_COLLECTION_KEY, DATA_COLLECTION_TEACHER_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { getIsDataFetching, getFirstRecord } from 'redux/utility/baseSelectors';
import { ActiveTeachersData, emptyActiveTeacher } from 'types';
import { getRecords_SnapshotStudentFilterState } from 'redux/filters/_selectors';
import { filter } from 'lodash';
import { isApplicaionIdValid } from 'util/global';

export const DATA_COLLECTION_TEACHER_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_TEACHER_KEY,
];

export const getIsDataFetching_ActiveTeachersData = (
  state: ApplicationState
): boolean => {
  return getIsDataFetching(state, DATA_COLLECTION_TEACHER_PATH);
};

export const getFirstRecord_ActiveTeachersData = (
  state: ApplicationState
): ActiveTeachersData => {
  return getFirstRecord<ActiveTeachersData>(
    state,
    DATA_COLLECTION_TEACHER_PATH,
    emptyActiveTeacher()
  );
};

export const getAdvancedFilteredRecords_ActiveTeachersData = (
  state: any
): ActiveTeachersData => {
  const filterData = getRecords_SnapshotStudentFilterState(state);
  const sed = getFirstRecord_ActiveTeachersData(state);

  const records = filter(sed.teachers, (item) => {
    return isApplicaionIdValid(filterData.schoolId)
      ? filterData.schoolId === item.schoolId
      : true;
  });
  return { ...sed, teachers: records };
};
