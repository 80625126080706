import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import EditSnapshotStudent from '../EditSnapshotStudent';
import EnrollmentTrend from './EnrollmentTrend';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState, useRef } from 'react';
import ScrollToTop from 'components/Common/ScrollToTop';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SnapshotStudentSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/SnapshotStudentSelectBox';
import Table from 'components/Common/Table';
import { COLOR_LIGHT_RED } from 'constants/css.const';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { FlexRow, FlexGroup } from 'layouts';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import {
  StudentEnrollment,
  SnapshotStudent,
  emptySnapshotStudent,
} from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { find, some } from 'lodash';
import { getFullName, isEnrollmentLocked } from 'util/datacollection.utility';
import { isApplicaionIdValid } from 'util/global';
import { renderData, prop, compose } from 'util/objectUtility';
import { useModal, Modal } from 'react-morphing-modal';
import { useParams } from 'react-router';

type ProgramEnrollmentProps = {
  deactivateSnapshotStudent: Function;
  enrollment: StudentEnrollment;
  insertSnapshotStudent: Function;
  isFetching: boolean;
  loadProgramSnapshotStudent: Function;
  loadStudentEnrollmentData: Function;
  loadSingleProgramInstance: Function;
  schoolYear: number;
  students: SnapshotStudent[];
  schoolId: number;
};

export const ProgramEnrollmentComponent: React.FC<ProgramEnrollmentProps> = (
  props
) => {
  const {
    deactivateSnapshotStudent,
    enrollment,
    isFetching,
    loadProgramSnapshotStudent,
    loadStudentEnrollmentData,
    loadSingleProgramInstance,
    schoolYear,
    students,
    schoolId,
  } = props;
  const [filteredData, setFilteredData] = useState<SnapshotStudent[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { modalProps, open, close } = useModal({
    onOpen: () => {
      const modalbody = document.getElementsByClassName('RMM__body')[0];
      modalbody.scrollTo(0, 0);
    },
  });
  const [selectedStudent, setSelectedStudent] = useState<
    SnapshotStudent | undefined
  >();

  const { programId: pId }: any = useParams();
  const programId = Number(pId);

  const triggerRef = useRef(null);

  const getDisplayData = (name: keyof SnapshotStudent) => {
    return compose(renderData, prop(name));
  };

  const renderBoolData = (name: keyof SnapshotStudent) => {
    return compose(renderCircle, prop(name));
  };

  const addStudent = (student: SnapshotStudent) => {
    const exists = find(students, (item) => {
      return item.studentId === student.studentId;
    });
    if (exists) {
      setSearchValue(`${student.firstName} ${student.lastName}`);
    }
    setSelectedStudent({
      ...student,
      programId: enrollment.programBaseId,
      schoolId,
      programLevelId: enrollment.programLevelId,
      programName: enrollment.programName,
      cipCode: String(enrollment.cipCode),
      cdeId: enrollment.cdeId,
      schoolYear,
    });
  };

  const getLocked = (row: SnapshotStudent) => {
    return some([
      row.schoolYear !== enrollment.schoolYear,
      isEnrollmentLocked(enrollment),
    ]);
  };

  const renderEditIcon = (row: SnapshotStudent) => {
    return (
      <IconButton
        tooltip="Edit"
        name="pencil"
        onClick={() => {
          setSelectedStudent(row);
          open(triggerRef);
        }}
      />
    );
  };

  const renderDeleteIcon = (row: SnapshotStudent) => {
    return (
      <SecureWrap component={['DCStudentRecords']} isLocked={getLocked(row)}>
        <ConfirmationDialog
          tooltip="Delete Student Enrollment"
          header={'Confirm Delete?'}
          onConfirm={() => {
            deactivateSnapshotStudent(row).catch((err: any) =>
              console.error(err)
            );
          }}
          activateButton={<IconButton tooltip="Delete" name="trashCan" />}
        >
          <p>
            Are you sure you want to delete this student? Once deleted, data on
            this student will need to be re-added using the Excel upload or in
            the “Add Individual Student” field.
          </p>
        </ConfirmationDialog>
      </SecureWrap>
    );
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        {enrollment.programLevelId === PROGRAM_LEVEL_ENUM['Middle School'] ? (
          <React.Fragment>{EMPTY_PLACEHOLDER}</React.Fragment>
        ) : (
          <Icon
            name={bool ? 'circleFilled' : 'circleOpen'}
            className="consortium-view__status-icon u-icon-green"
          />
        )}
      </React.Fragment>
    );
  };

  const STUDENT_ENROLLMENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: getFullName,
      selector: getFullName,
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'SASID',
      cell: getDisplayData('sasId'),
      sortable: true,
      selector: prop('sasId'),
    },
    {
      name: 'Participant',
      cell: renderBoolData('participant'),
      sortable: true,
      selector: prop('participant'),
    },
    {
      name: 'Concentrator',
      cell: renderBoolData('concentrator'),
      sortable: true,
      selector: prop('concentrator'),
    },
    {
      name: 'Completer',
      cell: renderBoolData('completer'),
      sortable: true,
      selector: prop('completer'),
    },
    {
      name: 'Year',
      cell: getDisplayData('schoolYear'),
      sortable: true,
      selector: prop('schoolYear'),
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '5%',
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: SnapshotStudent) => {
        return row.schoolYear !== enrollment.schoolYear;
      },
      style: {
        backgroundColor: COLOR_LIGHT_RED,
      },
    },
  ];

  useEffect(() => {
    if (isApplicaionIdValid(enrollment.programInstanceId)) {
      loadSingleProgramInstance(enrollment.programInstanceId);
    }
  }, [loadSingleProgramInstance, enrollment]);

  useEffect(() => {
    loadProgramSnapshotStudent(programId);
  }, [loadProgramSnapshotStudent, programId]);

  useEffect(() => {
    loadStudentEnrollmentData().catch((err: any) => console.error(err));
  }, [loadStudentEnrollmentData]);

  useEffect(() => {
    setFilteredData(students);
  }, [students]);

  useEffect(() => {
    if (selectedStudent && selectedStudent.programId > 0) {
      open(triggerRef);
    }
  }, [selectedStudent]); //eslint-disable-line

  return (
    <SecureWrap
      component={['DCStudentRecords']}
      allowReadOnly={true}
      isPage={true}
    >
      <Loading isActive={isFetching} />
      <ScrollToTop />

      <Paper>
        <FlexRow>
          <PageHeading
            title={`Student Enrollment: ${renderData(enrollment.programName)}`}
            subTitle={renderData(enrollment.schoolName)}
          />
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <h3>Instructions</h3>
            <p>
              Use this page to review program enrollment and trends in your site
              and add or make edits to the student record data entered in the
              system
            </p>
          </FlexGroup>
        </FlexRow>
      </Paper>
      <EnrollmentTrend enrollment={enrollment} students={students} />
      <SecureWrap
        component={['DCStudentRecords']}
        isLocked={isEnrollmentLocked(enrollment)}
      >
        <Paper>
          <Instruction title="Add an Individual Student">
            <p>
              Enter a student’s name or SASID in the search bar to access
              information previously entered on an existing student and add them
              to this program. You may also click on the "New Student" button to
              add information on a new student that does not exist in the
              database
            </p>
          </Instruction>
          <FlexRow>
            <SnapshotStudentSelectBox
              selectedContact={undefined}
              onChange={addStudent}
            />
          </FlexRow>
          <FlexRow>
            <div className="u-flex-grow-1" />
            <button
              ref={triggerRef}
              onClick={() => {
                addStudent(emptySnapshotStudent());
              }}
              className="button--outline"
            >
              New Student
            </button>
          </FlexRow>
        </Paper>
      </SecureWrap>
      <Paper>
        <FlexRow />
        <Instruction
          title={`Student Records: ${renderData(enrollment.programName)}`}
        >
          <p>
            Enter a student’s name or SASID in the search bar to review
            information previously entered on an existing student. Click on the
            delete icon to remove a student from the database and the edit icon
            to update the information provided. Data provided on this page
            correspond to the last two academic years. Note that the
            participant, concentrator and completer fields will be blank for
            middle school students.
          </p>
        </Instruction>

        <SearchBarFilter
          data={students}
          isFetching={isFetching}
          label="Search Student Records"
          setFilteredData={setFilteredData}
          headers={STUDENT_ENROLLMENT_HEADERS}
          searchValue={searchValue}
        />
        <Table
          columns={STUDENT_ENROLLMENT_HEADERS}
          data={filteredData}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Paper>
      <Modal {...modalProps}>
        <SecureWrap
          component={['DCStudentRecords']}
          isLocked={some([
            isEnrollmentLocked(enrollment),
            selectedStudent &&
              selectedStudent.schoolYear !== enrollment.schoolYear,
          ])}
        >
          <EditSnapshotStudent
            close={close}
            enrollment={enrollment}
            student={selectedStudent}
          />
        </SecureWrap>
      </Modal>
    </SecureWrap>
  );
};
