import {
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_PROGRAM_SNAPSHOT_STUDENT_KEY,
} from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { SnapshotStudent } from 'types';
import { getSubmissionManagementFilters } from 'redux/filters/_selectors';

export const PROGRAM_SNAPSHOT_STUDENT_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_PROGRAM_SNAPSHOT_STUDENT_KEY,
];

export const getIsDataFetching_ProgramSnapshotStudent = (
  state: any
): boolean => {
  return getIsDataFetching(state, PROGRAM_SNAPSHOT_STUDENT_PATH);
};

export const shouldRefreshReduxState_ProgramSnapshotStudent = (
  state: any
): boolean => {
  return shouldRefreshReduxState<SnapshotStudent>(
    state,
    PROGRAM_SNAPSHOT_STUDENT_PATH
  );
};

export const getRecords_ProgramSnapshotStudent = (
  state: unknown
): SnapshotStudent[] => {
  return getRecords(state, PROGRAM_SNAPSHOT_STUDENT_PATH);
};

export const getAdvancedFilteredRecords_ProgramSnapshotStudent = (
  state: any
): SnapshotStudent[] => {
  const filters = getSubmissionManagementFilters(state);
  const records = getAdvancedFilteredRecords<SnapshotStudent>(
    state,
    PROGRAM_SNAPSHOT_STUDENT_PATH,
    filters
  );
  return records;
};
