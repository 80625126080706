import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCTABooksSchool,
  updateCTABooksSchool,
} from 'services/cta/booksSchool.services';
import { CTABooksSchool } from 'types/cta/CTABooksSchool.types';

const initialState = baseInitialState;

export const loadCTABooksSchool = createActionThunk(
  'loadCTABooksSchool',
  (id: number) => {
    return readCTABooksSchool(id);
  }
);

export const saveCTABooksSchool = createActionThunk(
  'saveCTAGeneralAccuracy',
  (booksSchool: CTABooksSchool) => {
    return updateCTABooksSchool(booksSchool);
  }
);

export default handleActions<BaseStateObject, CTABooksSchool[]>(
  {
    ...baseLoadActionBuilder<CTABooksSchool[]>(loadCTABooksSchool),
    ...baseSaveActionBuilder(saveCTABooksSchool),
  },
  initialState
);
