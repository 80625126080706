import { connect } from 'react-redux';
import { FiscalYearSelectBox } from 'components/Common/SelectBoxes/FiscalYearSelectBox/FiscalYearSelectBox.component';
import { find, map } from 'lodash';
import { getRecords_FiscalYear } from 'redux/common/_selectors';
import { DropdownItem } from 'types';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { selection } = ownProps;

  const years = getRecords_FiscalYear(state);

  const options = map(
    years,
    (item): DropdownItem => {
      return {
        key: item.year!,
        label: `${item.year! - 1} - ${item.year}`,
        value: item.year,
      };
    }
  );

  const selectedItem = find(options, (option) => {
    return Number(option.value) === Number(selection);
  });
  return {
    options,
    selectedItem,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(FiscalYearSelectBox);
