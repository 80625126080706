import React from 'react';
import IconButton from 'components/Common/IconButton';

type CollapseButtonProps = {
  buttonText?: string;
  trayClosed: boolean;
  setClosed: Function;
  origin: string;
};

export const CollapseButton: React.FC<CollapseButtonProps> = (props) => {
  const { buttonText, origin, setClosed, trayClosed } = props;

  const isLeftOrigin = origin === 'left';
  const collapseClass = isLeftOrigin
    ? 'collapse-button__collapse collapse-button__collapse--left'
    : 'collapse-button__collapse';

  return (
    <div className="collapse-button__container">
      {trayClosed ? (
        <div className="collapse-button__expand u-flip">
          <IconButton
            tooltip="Expand"
            className={isLeftOrigin ? 'u-icon-blue u-flip' : 'u-icon-blue'}
            onClick={() => {
              setClosed(false);
            }}
            name="chevronsRight"
          />
        </div>
      ) : (
        <div className={collapseClass}>
          <IconButton
            tooltip="Collapse"
            textAfter={isLeftOrigin}
            text={buttonText ? buttonText : 'collapse'}
            className={isLeftOrigin ? 'u-icon-blue u-flip' : 'u-icon-blue'}
            onClick={() => {
              setClosed(true);
            }}
            name="chevronsRight"
          />
        </div>
      )}
    </div>
  );
};
