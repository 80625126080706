export const PARTNERSHIPS_ADDRESSABLE_ELEMENTS =
  '[PARTNERSHIPS_ADDRESSABLE_ELEMENTS]';

export const SET_PARTNERSHIPS_ADDRESSABLE_ELEMENTS = `${PARTNERSHIPS_ADDRESSABLE_ELEMENTS} SET`;
export const SET_PARTNERSHIPS_ADDRESSABLE_ELEMENTS_LOADING = `${PARTNERSHIPS_ADDRESSABLE_ELEMENTS} LOADING`;

export const setPartnershipsAddressableElements = (payload: any) => {
  return {
    type: SET_PARTNERSHIPS_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_PartnershipsAddressableElements = (
  payload: boolean
) => {
  return {
    type: SET_PARTNERSHIPS_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
