import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, SnapshotStudentFilterState } from 'types';

const initialState = {
  schoolId: -1,
};

export const setSnapshotStudentValue = createAction('setSnapshotStudentValue');
export const resetSnapshotStudentValue = createAction(
  'resetSnapshotStudentValue'
);

export default handleActions<
  SnapshotStudentFilterState,
  KeyValueType<number> | string
>(
  {
    setSnapshotStudentValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetSnapshotStudentValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
  },
  initialState
);
