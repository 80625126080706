import { DevPrintIcons } from 'components/DeveloperTools/IconsPage';
import { Route } from 'react-router-dom';
import { DevFormElements } from 'components/DeveloperTools/FormElements.page';
import authentication from 'components/Common/Authorization/AzureB2C';

const devToolsRoutes = [
  <Route exact path="/devTools/icons" component={DevPrintIcons} />,
  <Route
    exact
    path="/devTools/form-elements"
    component={authentication.required(DevFormElements)}
  />,
];

export default devToolsRoutes;
