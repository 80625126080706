import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
  actionFetching,
  actionUpdateRecords,
  actionRecordFailure,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readInstructors,
  updateSupportingSalary,
  readSingleInstructor,
  updateInstructor,
} from 'services/cta/ctainstructionalcosts.services';
import { CtaInstructor } from 'types/cta/InstructionalCosts/CtaInstructor.types';
import { SupportingSalaryDocuments } from 'types/cta/InstructionalCosts/SupportingSalaryDocuments.types';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadInstructors = createActionThunk(
  'loadInstructors',
  (ctaId: number) => {
    return readInstructors(ctaId);
  }
);

export const loadSingleInstructor = createActionThunk(
  'loadSingleInstructor',
  (ctaInstructorId: number) => {
    return readSingleInstructor(ctaInstructorId);
  }
);

export const saveInstructor = createActionThunk(
  'saveInstructor',
  (ctaId: number, instructor: CtaInstructor) => {
    return updateInstructor(ctaId, instructor);
  }
);

export const saveSupportingSalary = createActionThunk(
  'saveSupportingSalary',
  (ctaId: number, item: SupportingSalaryDocuments) => {
    return updateSupportingSalary(ctaId, item);
  }
);

export default handleActions<BaseStateObject, CtaInstructor[]>(
  {
    [loadSingleInstructor.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadSingleInstructor.SUCCEEDED]: (state, action) => {
      const records = upsert<CtaInstructor>(
        state.records,
        action.payload,
        'ctaInstructorId'
      );
      return actionUpdateRecords(state, records);
    },
    [loadSingleInstructor.FAILED]: (state) => {
      return actionRecordFailure(state);
    } /* End action */,
    [loadSingleInstructor.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    ...baseLoadActionBuilder<CtaInstructor[]>(loadInstructors),
    ...baseSaveActionBuilder(saveSupportingSalary),
  },
  initialState
);
