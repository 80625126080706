import React, { useEffect } from 'react';
import { map } from 'lodash';
import { ComponentStep } from 'types/ui/ComponentStep.types';
import { GrantTwoYearSummary } from 'components/Grants/Applications/TwoYear/TwoYearConnect';
import { AnnualSpendingPlanSummary } from '../AnnualSpendingPlan/AnnualSpendingPlanConnect';
import { FourYearSummary } from 'components/Grants/Applications/FourYear/FourYearSummary';
import { useParams } from 'react-router';
import { AnnualReflectionSummary } from '../AnnualReflection/AnnualReflectionConnect';
import { AwardPacketSummary } from '../AwardPacket/AwardPacketConnect';
import { VouchersSummary } from '../Vouchers/Vouchers.connect';
import AssuranceSignatureSummary from '../AssuranceSignature/AssuranceSignatureSummary';
import CurrentStatus from 'components/Grants/Applications/CurrentStatus';
import GeneralSummary from '../General';
import GrantSteps from 'components/Grants/Applications/OverviewSteps';
import Loading from 'components/Common/Loading';
import ImprovementOverview from '../ImprovementPlan/ImprovementOverview';

type OverviewStepsSelectionsProps = {
  currentNavAchor: string;
  setCurrentNavAnchor: Function;
  steps: ComponentStep[];
  stepsLoading: boolean;
};

const GrantOverviewComponent: React.FC<any> = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  switch (props.component) {
    case 'general':
      return <GeneralSummary grantFiscalYearId={grantFiscalYearId} />;
    case 'current-status':
      return <CurrentStatus />;
    case 'four-year-plan':
      return <FourYearSummary />;
    case 'two-year-plan':
      return <GrantTwoYearSummary />;
    case 'spending-plans':
      return <AnnualSpendingPlanSummary />;
    case 'assurance-signature':
      return <AssuranceSignatureSummary />;
    case 'annual-reflection':
      return <AnnualReflectionSummary />;
    case 'award-packet':
      return <AwardPacketSummary />;
    case 'vouchers':
      return <VouchersSummary />;
    case 'improvement-plan':
      return <ImprovementOverview />;
    default:
      return null;
  }
};

export const OverviewStepsSelections: React.FC<OverviewStepsSelectionsProps> = (
  props
) => {
  const { currentNavAchor, setCurrentNavAnchor, steps } = props;

  useEffect(() => {
    if (steps.length) {
      const sideNavAnchors = map(
        steps,
        (step: ComponentStep) => step.nav.anchor
      );
      if (!sideNavAnchors.includes(currentNavAchor)) {
        setCurrentNavAnchor('general');
      }
    }
  }, [setCurrentNavAnchor, steps, currentNavAchor]);

  return (
    <React.Fragment>
      <Loading
        isActive={props.stepsLoading}
        messageBefore="Loading Grant Overview..."
      />
      <div className="program-steps-selection">
        {map(steps, (step: ComponentStep) => {
          if (step.children) {
            const childSteps = step.children;
            return map(childSteps, (childStep: ComponentStep) => {
              return (
                <GrantSteps
                  key={childStep.nav.anchor}
                  stepInfo={childStep}
                  component={
                    <GrantOverviewComponent component={childStep.nav.anchor} />
                  }
                />
              );
            });
          } else {
            return (
              <GrantSteps
                key={step.nav.anchor}
                stepInfo={step}
                component={
                  <GrantOverviewComponent component={step.nav.anchor} />
                }
              />
            );
          }
        })}
      </div>
    </React.Fragment>
  );
};
