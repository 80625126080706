import { connect } from 'react-redux';
import { OptionMap, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';

type CourseLevel = {
  courseLevelId: number;
  courseLevelName: string;
};

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, label, onChange } = ownProps;

  // TODO: Should make this an enum
  const records = [
    { courseLevelId: 1, courseLevelName: '1' },
    { courseLevelId: 2, courseLevelName: '2' },
    { courseLevelId: 3, courseLevelName: '3' },
    { courseLevelId: 4, courseLevelName: '4' },
  ];

  const optionMap: OptionMap<CourseLevel> = {
    key: 'courseLevelId',
    label: 'courseLevelName',
    value: 'courseLevelId',
  };

  const selectCourseLevel = () => {
    return selection === 255 ? undefined : selection;
  };

  return {
    label: label ? label : 'Course Level',
    name: 'courseLevel',
    onChange,
    optionMap,
    records,
    selection: selectCourseLevel(),
  };
};

export default connect(mapStateToProps)(SelectBox);
