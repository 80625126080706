import React from 'react';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { FlexRow } from 'layouts';
import { ProgramInformationBase } from './ProgramInformationBase';
import { ProgramInformationPS } from './ProgramInformationPS';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { prop } from 'util/objectUtility';

const ProgramInformation: React.FC<StudentRecordComponentProps> = (props) => {
  const { student } = props;

  const programLevel = prop('programLevelId', student);
  const isPostsecondary = programLevel === PROGRAM_LEVEL_ENUM.Postsecondary;

  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">Program Information</h2>
      </FlexRow>
      <ProgramInformationBase {...props} />
      {isPostsecondary && <ProgramInformationPS {...props} />}
    </React.Fragment>
  );
};

export default ProgramInformation;
