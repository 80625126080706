import { SpendingCategoryComponent } from './SpendingCategory.component';
import { getAnnualSpendingPlan } from 'redux/grants/_selectors';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const spendingPlan = getAnnualSpendingPlan(state);
  return {
    spendingPlan,
  };
};

export default connect(mapStateToProps)(SpendingCategoryComponent);
