import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
  deleteWithAuthOptions,
} from 'services';
import { CTAEquipment } from 'types/cta/CTAEquipment.types';

const controller = 'CTA Equipment';
const BASE_URL = ['api', 'CtaEquipment'];

export const readCTAEquipment = async (
  ctaId: number
): Promise<CTAEquipment[]> => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(controller, err, 'readCTAEquipment');
    });
};

export const updateCTAEquipment = async (
  ctaId: number,
  payload: CTAEquipment
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(payload),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(controller, err, 'updateCTAEquipment');
    });
};

export const deleteCTAEquipment = async (
  ctaId: number,
  payload: CTAEquipment
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...deleteWithAuthOptions(),
    body: JSON.stringify(payload),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(controller, err, 'deleteCTAEquipment');
    });
};
