import React from 'react';
import CreditInput from 'components/Common/FormElements/CreditInput';
import Textarea from 'components/Common/FormElements/Textarea';
import { CourseCreditElective, ProgramAward } from 'types';
import { map } from 'lodash';
import { randomString } from 'util/global';

type ElectiveCourseCreditsInputRowProps = {
  electiveCourse: CourseCreditElective;
  programAwards: ProgramAward[];
  onChange: Function;
};

export const ElectiveCourseCreditsInputRow: React.FC<
  ElectiveCourseCreditsInputRowProps
> = (props) => {
  const { electiveCourse, programAwards, onChange } = props;
  const {
    programAwardId,
    certOrDegreeTitle,
    courses,
    credits,
  } = electiveCourse;

  const handleChange = (data: any) => {
    onChange({
      ...electiveCourse,
      ...data,
    });
  };

  return (
    <React.Fragment>
      <div className="course-credits__education-row">
        <div className="course-credits__info">
          <Textarea
            id={randomString()}
            label={certOrDegreeTitle}
            name="courses"
            value={courses}
            onChange={handleChange}
          />
        </div>
        <div className="course-credits__input-row">
          {map(programAwards, (award: ProgramAward, index: number) => {
            return programAwardId !== award.programAwardId ? (
              <div key={index}>
                <CreditInput
                  disabled={true}
                  name=""
                  value={0}
                  onChange={() => null}
                  className="course-credits__input u-hide-input"
                />
              </div>
            ) : (
              <div key={index}>
                <CreditInput
                  programAwardId={award.programAwardId}
                  name=""
                  value={credits}
                  onChange={handleChange}
                  className="course-credits__input"
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};
