import { basetype } from 'types/base.types';

export interface CareerCluster extends basetype {
  careerClusterId: number;
  careerClusterTitle: string;
  careerSectorId: number;
}

export const emptyCareerCluster = (): CareerCluster => {
  return {
    careerClusterId: -1,
    careerClusterTitle: '',
    careerSectorId: -1,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  };
};
