import { Department } from 'types';

// TODO: convert to function
export const emptyDepartment: Department = {
  departmentId: -1,
  departmentName: '',
  schoolId: -1,
  schoolDepartmentId: -1,
  isPrimary: false,
};
