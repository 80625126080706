import React from 'react';
import { AzureUploadParameters, QuestionGroup, Question } from 'types';
import { upsertInPlace } from 'util/arrayUtility';
import { map } from 'lodash';
import { QuestionComponent } from 'components/Common/Questions/Question';
import { FlexRow } from 'layouts';

type QuestionsGroupLayoutProps = {
  group: QuestionGroup;
  onChange: Function;
  flat?: boolean;
  uploadParams: AzureUploadParameters;
};

export const QuestionsGroupLayout: React.FC<QuestionsGroupLayoutProps> = (
  props
) => {
  const { group, onChange, uploadParams } = props;

  const updateQuestionGroup = (question: Question) => {
    const questions = upsertInPlace<Question>(
      group.questions,
      question,
      'questionId'
    );
    const newGroup = {
      ...group,
      questions,
    };
    onChange(newGroup);
  };

  const generateQuestions = () => {
    if (group.questions && group.questions.length > 0) {
      return map(group.questions, (question: Question, index: number) => {
        return (
          <QuestionComponent
            key={index}
            question={question}
            onChange={updateQuestionGroup}
            uploadParams={uploadParams}
          />
        );
      });
    } else {
      return (
        <FlexRow>
          <p>There was no data was returned, please try again.</p>
        </FlexRow>
      );
    }
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: '2.8rem', marginBottom: '1.2rem' }}>
        <h3 className="u-color-blue">{group.title}</h3>
      </div>
      {generateQuestions()}
    </React.Fragment>
  );
};
