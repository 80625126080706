import { getGeneralWorkflow } from 'redux/generalworkflow/workflow.selectors';
import { emptyWorkflowStep, ComponentStep } from 'types';
import { head } from 'ramda';
import { isEqual } from 'util/formHelpers';
import { compose, prop, either } from './objectUtility';
import { every } from 'lodash';
import { WorkflowStatus } from 'types/grant/WorkflowStatus.types';

// isStepOpen :: ComponentStep -> boolean
export const isStepOpen = compose(
  isEqual('Open'),
  prop('status')
);

// isNavStepLocked :: ComponentStep -> boolean
export const isNavStepLocked = compose(
  isEqual('locked'),
  prop('status'),
  prop('nav')
);

// isVoucherStep :: ComponentStep -> boolean
export const isVoucherStep = compose(
  isEqual('vouchers'),
  prop('anchor'),
  prop('nav')
);

// getCurrentWorkflowStep :: string -> WorkflowStep
export const getCurrentWorkflowStep = (entityName: string) =>
  either(
    compose(
      head,
      prop('workflowSteps'),
      getGeneralWorkflow(entityName)
    ),
    () => emptyWorkflowStep()
  );

// updateLockedStatus :: WorkflowStatus ComponentStep -> WorkflowStatus
export const updateLockedStatus = (
  status: WorkflowStatus,
  step: ComponentStep
) => {
  return every([isStepOpen(status), isNavStepLocked(step)])
    ? { ...status, status: 'Locked' }
    : status;
};
