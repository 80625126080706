import React from 'react';
import { FlexRow } from 'layouts';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';

const SubmissionSuccess: React.FC = () => {
  return (
    <React.Fragment>
      <Paper>
        <div className="u-flex" style={{ alignItems: 'center' }}>
          <Icon
            name="checkMark"
            className=""
            style={{ width: 175, fill: 'green' }}
          />
          <div>
            <p style={{ fontSize: '2.4rem' }}>Congratulations</p>
            <FlexRow>
              <p>
                You have successfully submitted your intent to participate, you
                may now close this window.
              </p>
            </FlexRow>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default SubmissionSuccess;
