import { PERMISSIONS_KEY, PERMISSIONS_ROLE_COMPONENTS_KEY } from 'redux/keys';
import { PermissionRoleComponent } from 'types';
import { filter } from 'lodash';
import { getRecords } from 'redux/utility/baseSelectors';

export const ROLE_COMPONENT_PATH = [
  PERMISSIONS_KEY,
  PERMISSIONS_ROLE_COMPONENTS_KEY,
];

export const getRecords_PermissionRoleComponent = (
  state: any
): PermissionRoleComponent[] => {
  return getRecords<PermissionRoleComponent>(state, ROLE_COMPONENT_PATH);
};

export const getRoleComponentsByRoleId = (state: any, roleId: number) => {
  return filter(
    getRecords_PermissionRoleComponent(state),
    (rc: PermissionRoleComponent) => {
      return rc.permissionRoleId === roleId;
    }
  );
};
