import { AwardAmounts, emptyAwardAmounts } from 'types';

export interface ActualReimbursementAmounts {
  budgetedCosts: AwardAmounts;
  fiscalYearCosts: AwardAmounts;
}

export const emptyActualReimbursementAmounts = (): ActualReimbursementAmounts => {
  return {
    budgetedCosts: emptyAwardAmounts(),
    fiscalYearCosts: emptyAwardAmounts(),
  };
};
