import { AnnualSpending, SpendingCategory } from 'types';

export const ANNUAL_SPENDING_PLAN = '[ANNUAL_SPENDING_PLAN]';

export const SET_ANNUAL_SPENDING_PLAN = `${ANNUAL_SPENDING_PLAN} SET`;
export const UPDATE_SPENDING_CATEGORIES = `${ANNUAL_SPENDING_PLAN} UPDATE CATEGORIES`;
export const SET_ANNUAL_SPENDING_PLAN_LOADING = `${ANNUAL_SPENDING_PLAN} SET LOADING`;
export const FETCH_ANNUAL_SPENDING_PLAN = `${ANNUAL_SPENDING_PLAN} FETCH`;
export const SAVE_ANNUAL_SPENDING_PLAN = `${ANNUAL_SPENDING_PLAN} SAVE`;
export const NORMALIZE_ANNUAL_SPENDING_PLAN = `${ANNUAL_SPENDING_PLAN} NORMALIZE`;

export const loadAnnualSpendingPlan = (
  payload: number,
  debug: any = 'default'
) => {
  return {
    type: FETCH_ANNUAL_SPENDING_PLAN,
    payload,
    debug,
  };
};

export const saveAnnualSpendingPlan = (
  payload: AnnualSpending,
  debug: any = 'default'
) => {
  return {
    type: SAVE_ANNUAL_SPENDING_PLAN,
    payload,
    debug,
  };
};

export const setAnnualSpendingPlan = (
  payload: AnnualSpending,
  debug: any = 'default'
) => {
  return {
    type: SET_ANNUAL_SPENDING_PLAN,
    payload,
    debug,
  };
};

export const updateSpendingCategories = (
  payload: SpendingCategory[],
  debug: any = 'default'
) => {
  return {
    type: UPDATE_SPENDING_CATEGORIES,
    payload,
    debug,
  };
};

export const setIsLoading_AnnualSpendingPlan = (
  payload: boolean,
  debug: any = 'default'
) => {
  return {
    type: SET_ANNUAL_SPENDING_PLAN_LOADING,
    payload,
    debug,
  };
};

export const normalize_AnnualSpendingPlan = (
  payload: AnnualSpending[],
  debug: any = 'default'
) => {
  return {
    type: NORMALIZE_ANNUAL_SPENDING_PLAN,
    payload,
    debug,
  };
};
