import React from 'react';
import Paper from 'components/Common/Paper';
import { FlexRow } from 'layouts';
import { BorderedTitle } from 'components/Common/BorderedTitle';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';

export const SecondaryInformation: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy } = props;
  const { secondaryProgram: program, secondarySchool: school } = programOfStudy;

  return (
    <Paper>
      <FlexRow>
        <h2 className="program__title">Secondary Information</h2>
      </FlexRow>
      <BorderedTitle
        primaryText={program.programName}
        secondaryText={school.schoolName}
      />
      <FlexRow>
        <div style={{ width: '15.0rem' }}>
          <label className="form__label">Program Id</label>
          <p>{program.programInstanceId}</p>
        </div>
        <div style={{ width: '15.0rem' }}>
          <label className="form__label">CIP Code</label>
          <p>{program.cipId}</p>
        </div>

        <div className="form__group u-flex-grow-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cde.state.co.us/postsecondary/graduationguidelines"
          >
            State Graduation Guidelines
          </a>
        </div>
      </FlexRow>
      <FlexRow>
        <div className="form__group u-flex-grow-1">
          <h3 className="form__label">Program Description</h3>
          <p>{program.programDescription}</p>
        </div>
      </FlexRow>
    </Paper>
  );
};
