import { handleActions, createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import {
  readAllUsers,
  createUser,
  readDBUser,
} from 'services/universalcontacts';
import { UniversalContact } from 'types';
import {
  actionFetching,
  actionUpdateRecords,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const saveContact = createActionThunk(
  'saveContact',
  async (user: UniversalContact) => {
    return createUser(user).then((value: number) => {
      return value;
    });
  }
); // end addUpdateUser

export const loadUniversalContacts = createActionThunk(
  'loadUniversalContacts',
  () => {
    return readAllUsers();
  }
); // end getUniversalContacts

export const loadSingleUniversalContact = createActionThunk(
  'loadSingleUniversalContact',
  async (userId: number) => {
    return readDBUser(userId);
  }
); // end loadSingleUniversalContact

export const insertUser = createAction('insertUser');

export default handleActions<
  BaseStateObject,
  UniversalContact | UniversalContact[]
>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    insertUser: (state, { payload }) => {
      const records = upsert<UniversalContact>(
        state.records,
        payload,
        'universalContactId'
      );
      return actionUpdateRecords(state, records);
    } /* End action */,

    ...baseLoadActionBuilder<UniversalContact | UniversalContact[]>(
      loadUniversalContacts
    ),

    [loadSingleUniversalContact.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [loadSingleUniversalContact.SUCCEEDED]: (state, { payload }) => {
      const records = upsert<UniversalContact>(
        state.records,
        payload,
        'universalContactId'
      );
      return actionUpdateRecords(state, records);
    } /* End action */,
    [loadSingleUniversalContact.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,

    [saveContact.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [saveContact.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
