import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import FiscalYearLayout from 'layouts/FiscalYearLayout';
import FiscalYearDashboard from 'components/Grants/FiscalYearManagement/Dashboard';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export const FiscalYearManagementPage: React.FC = () => {
  return (
    <BaseLayout>
      <SecureWrap component="grantsfiscalyear" isPage={true}>
        <FiscalYearLayout>
          <FiscalYearDashboard />
        </FiscalYearLayout>
      </SecureWrap>
    </BaseLayout>
  );
};
