import React from 'react';
import Paper from 'components/Common/Paper';
import { FlexRow, FlexGroup } from 'layouts';
import { ProgramOfStudyOverviewProps } from '../ProgramOfStudyOverview.component';
import {
  getContactFullName,
  getUserEmail,
  getUserPhone,
} from 'util/universalcontact.utility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose, renderData, prop } from 'util/objectUtility';
import Table from 'components/Common/Table';

export const PosReview: React.FC<ProgramOfStudyOverviewProps> = (props) => {
  const { secondaryUser, postsecondaryUser } = props;

  const Name = compose(
    renderData,
    getContactFullName
  );
  const Title = compose(
    renderData,
    prop('title')
  );
  const Email = compose(
    renderData,
    getUserEmail
  );
  const Phone = compose(
    renderData,
    getUserPhone
  );

  const HEADERS: TableColumnProps[] = [
    {
      name: 'Primary Contact Name',
      cell: Name,
    },
    {
      name: 'Title',
      cell: Title,
    },
    {
      name: 'Email',
      cell: Email,
    },
    {
      name: 'Phone',
      cell: Phone,
    },
  ];

  return (
    <Paper>
      <FlexRow>
        <h2 className="program__title">District / College Review</h2>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <h2>Secondary Partner Contact Information</h2>
          <Table data={[secondaryUser]} columns={HEADERS} />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <h2>Postecondary Partner Contact Information</h2>
          <Table data={[postsecondaryUser]} columns={HEADERS} />
        </FlexGroup>
      </FlexRow>
    </Paper>
  );
};
