import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import DistrictSalary from './DistrictSalary.component';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import {
  CTASupportPersonnel,
  emptyCTASupportPersonnel,
  CTADistrictRecord,
} from 'types';
import { FlexGroup } from 'layouts';
import { SupportingPersonnelModal } from './SupportingPersonnelModal';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { fmap } from 'util/arrayUtility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { insertDollarSign, formatMoney } from 'util/moneyHelpers';
import {
  prop,
  renderData,
  concatLengthWithItems,
  compose,
  concatEnd,
  safeGet,
  ifElse,
} from 'util/objectUtility';
import { round, sumNumbers } from 'util/mathHelpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

type SupportingPersonnelProps = {
  locked: boolean;
  supportingPersonnel: CTASupportPersonnel[];
  loadCTASupportPersonnel: Function;
  deactivateCTASupportPersonnel: Function;
  ctaRecord: CTADistrictRecord;
};

export const SupportingPersonnelComponent: React.FC<SupportingPersonnelProps> = (
  props
) => {
  const {
    ctaRecord,
    supportingPersonnel,
    loadCTASupportPersonnel,
    deactivateCTASupportPersonnel,
    locked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  // get :: ctaRecord -> string -> ctaRecord[string]
  const get = safeGet(ctaRecord);

  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTASupportPersonnel(ctaId).catch((err: any) => console.error(err));
    }
  }, [wasOpened, loadCTASupportPersonnel, ctaId]);

  // --[ display logic ]-------------------------------------------------------
  const renderEditIcon = (row: CTASupportPersonnel) => {
    return row.personnelName === 'Total' ? null : (
      <SupportingPersonnelModal supportPersonnel={row} />
    );
  };

  const renderDeleteIcon = (row: CTASupportPersonnel) => {
    return row.personnelName === 'Total' ? null : (
      <ConfirmationDialog
        tooltip="Delete Support Personnel"
        header="Confirm Delete?"
        onConfirm={() => {
          deactivateCTASupportPersonnel(ctaId, row)
            .then(() => {
              toast(
                <ToastNotification
                  status="success"
                  message={StringResources.Success.Deleted}
                />
              );
            })
            .catch(() => {
              toast(
                <ToastNotification
                  status="error"
                  message={StringResources.Errors.DeleteFailed}
                />
              );
            });
        }}
        activateButton={<IconButton tooltip="Delete" name="trashCan" />}
      >
        <p>
          Are you sure you want to delete this Supporting Personnel? Once
          deleted, data on this Supporting Personnel will need to be re-added
          using the “Add Supporting Personnel” button.
        </p>
      </ConfirmationDialog>
    );
  };

  const createTotalRow = (): CTASupportPersonnel => {
    return {
      ...emptyCTASupportPersonnel(),
      personnelName: 'Total',
      ctaSupportProgramAffiliation: null as any,
      totalPercent: null as any,
      salary: compose(sumNumbers, fmap(prop('salary')))(supportingPersonnel),
    };
  };

  const CTA_SUPPORTTING_PERSONNEL_HEADERS: TableColumnProps[] = [
    {
      name: 'Employee Name',
      cell: compose(renderData, prop('personnelName')),
      selector: prop('personnelName'),
      sortable: true,
    },
    {
      cell: (row: CTASupportPersonnel) => {
        return prop('ctaSupportProgramAffiliation', row)
          ? concatLengthWithItems(
              'ctaSupportProgramAffiliation',
              'schoolName'
            )(row)
          : null;
      },
      name: 'Schools',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: (row: CTASupportPersonnel) => {
        return prop('ctaSupportProgramAffiliation', row)
          ? concatLengthWithItems(
              'ctaSupportProgramAffiliation',
              'programName'
            )(row)
          : null;
      },
      name: 'Programs',
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Combined CTE%',
      cell: compose(
        ifElse(concatEnd('%'), () => null),
        round(2),
        prop('totalPercent')
      ),
      selector: prop('totalPercent'),
      sortable: true,
      width: '15rem',
    },
    {
      name: 'Salary',
      cell: compose(insertDollarSign, formatMoney, prop('salary')),
      selector: prop('salary'),
      sortable: true,
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '5%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: CTASupportPersonnel) => row.personnelName === 'Total',
      style: {
        backgroundColor: 'transparent',
        fontWeight: 'bold',
      },
    },
  ];

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-supporting"
        locked={locked}
        header="Supporting Personnel Costs"
        onChange={updateOpenedState}
      >
        {wasOpened && (
          <React.Fragment>
            <Paper className="t-supporting">
              <Instruction title="Supporting Personnel">
                <p>
                  Enter information for all supporting personnel in your
                  district, which include paraprofessionals and interpreters.
                  Click on ‘Add Supporting Personnel’ and follow the prompts in
                  the pop-up window. Click on the Edit icon to make changes to
                  the record and Delete icon if you wish to remove this person.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: Entries with an
                  ellipses (…) indicates that items were cutoff and the number
                  in parenthesis (#) indicates the total number of items.
                </p>
              </Instruction>
              <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                <FlexGroup>
                  <SupportingPersonnelModal
                    supportPersonnel={emptyCTASupportPersonnel()}
                    isNew={true}
                  />
                </FlexGroup>
              </SecureWrap>
              <FlexGroup>
                <span className="t-sp-data">
                  <Table
                    columns={CTA_SUPPORTTING_PERSONNEL_HEADERS}
                    data={supportingPersonnel}
                  />
                </span>
                <span className="t-sp-total">
                  <Table
                    columns={CTA_SUPPORTTING_PERSONNEL_HEADERS}
                    conditionalRowStyles={conditionalRowStyles}
                    data={[createTotalRow()]}
                    displayColumnLabels={false}
                  />
                </span>
              </FlexGroup>
            </Paper>
            <Paper className="t-district-salary">
              <DistrictSalary
                district={get('schoolDistrictName')}
                year={get('year')}
              />
            </Paper>
          </React.Fragment>
        )}
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
