import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IntentToParticipateMember,
  emptyIntentToParticipateMember,
  AzureUploadParameters,
  Consortium,
  UniversalContact,
} from 'types';
import {
  readMemberParticipation,
  updateMemberParticipation,
} from 'services/grants/intentToParticipate.services';
import { map, isEmpty, some } from 'lodash';
import { MemberParticipationSummary } from '../MemberParticipationSummary';
import { FlexRow, FlexGroup } from 'layouts';
import {
  PARTICIPATION_TYPE,
  PARTICIPATION_TYPE_ENUM,
} from 'constants/participation.enum';
import { SaveButtons } from 'components/Common/SaveButtons/SaveButtons.component';
import { toast } from 'react-toastify';
import { IntentToParticipateMemberValidations } from 'validations/IntentToParticipate/IntentToParticipateMember.validations';
import { participationChanged } from 'util/intentToParticipate.utility';
import { mergeObjects } from 'util/objectUtility';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import ConsortiumSelectBox from 'components/Common/SelectBoxes/ConsortiumSelectBox';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import NoSubmissionAccess from '../NoSubmissionAccess';
import Paper from 'components/Common/Paper';
import Radio from 'components/Common/FormElements/Radio';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SelectBox from 'components/Common/FormElements/SelectBox';
import StringResources from 'StringResources';
import Textarea from 'components/Common/FormElements/Textarea';
import ToastNotification from 'components/Common/Toast';
import { isApplicaionYearValid } from 'util/global';
import { contactHasRoles } from 'util/permissionsUtility';

type MemberIntentComponentProps = {
  isLoading: boolean;
  consoria: Consortium[];
  loadAllConsortia: Function;
  fiscalYear: number;
  loggedInUser: UniversalContact;
};

export const MemberIntentComponent: React.FC<MemberIntentComponentProps> = (
  props
) => {
  const {
    isLoading,
    consoria,
    loadAllConsortia,
    fiscalYear,
    loggedInUser,
  } = props;
  const { secureGUID }: any = useParams();
  const [member, setMember] = useState<IntentToParticipateMember>(
    emptyIntentToParticipateMember()
  );
  const [open, setOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = IntentToParticipateMemberValidations();

  useEffect(() => {
    if (isApplicaionYearValid(fiscalYear)) {
      setIsActive(true);
      if (typeof member.isOpen === 'boolean') {
        setIsOpen(member.isOpen);
      } else {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.LoadDataFailed}
          />
        );
      }
      setIsActive(false);
    }
  }, [fiscalYear, member]);

  const handleChange = (
    data: Partial<IntentToParticipateMember>,
    key: 'changeExplanation' | 'consortiumId' | 'exemptionReason',
    value: string | number
  ) => {
    validateIfTrue(key, value, member);
    const updated = mergeObjects<IntentToParticipateMember>(member, data);
    setMember(updated);
  };

  const options = () => {
    return map(PARTICIPATION_TYPE, (item: any, index: number) => {
      return {
        id: index++,
        label: item,
      };
    });
  };

  const sanitizedData = () => {
    const changeExplanation = participationChanged(member)
      ? member.changeExplanation
      : '';
    const result = mergeObjects<IntentToParticipateMember>(member, {
      changeExplanation,
    });
    return result;
  };

  const loadIntent = () => {
    if (secureGUID) {
      readMemberParticipation(secureGUID)
        .then((response: IntentToParticipateMember) => {
          setMember({
            ...response,
            previousConsortiumId: response.consortiumId,
            newParticipationStatus: response.currentParticipationStatus,
          });
        })
        .catch(() =>
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.LoadDataFailed}
            />
          )
        )
        .finally(() => {
          setIsActive(false);
        });
    }
  };

  const history = useHistory();
  const saveIntent = () => {
    if (!validateAll(member)) {
      return toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
    setIsActive(true);
    if (secureGUID) {
      updateMemberParticipation(secureGUID, sanitizedData())
        .then(() => {
          history.replace('/grants/participation-success');
        })
        .catch(() => {
          setIsActive(false);
          loadIntent(); //?
          toast(
            <ToastNotification
              status="error"
              message={`${StringResources.Errors.DataSaveFailed}. The form has been restored to a clean state.`}
            />
          );
        });
    }
  };
  const handleMemberParticipationStatus = (status: number) => {
    switch (status) {
      case PARTICIPATION_TYPE_ENUM.STANDALONE:
        const standalone = {
          ...member,
          isStandalone: true,
          isExempt: false,
          newParticipationStatus: Number(status),
          consortiumId: member.previousConsortiumId,
        };
        setMember(standalone);
        validateAll(standalone);
        break;

      case PARTICIPATION_TYPE_ENUM.CONSORTIUM:
        const consortium = {
          ...member,
          isStandalone: false,
          isExempt: false,
          newParticipationStatus: Number(status),
        };
        setMember(consortium);
        validateAll(consortium);
        break;

      case PARTICIPATION_TYPE_ENUM.STANDALONE_EXEMPT:
        const standaloneExempt = {
          ...member,
          isStandalone: true,
          isExempt: true,
          newParticipationStatus: Number(status),
        };
        setMember(standaloneExempt);
        validateAll(standaloneExempt);
        break;

      case PARTICIPATION_TYPE_ENUM.NOT_PARTICIPATING:
        const noParticipating = {
          ...member,
          isStandalone: true,
          isExempt: false,
          newParticipationStatus: Number(status),
          isParticipating: false,
        };
        setMember(noParticipating);
        validateAll(noParticipating);
        break;

      default:
        break;
    }
  };

  const handleFileUpload = (item: string) => {
    validate('exemptionLetterFilePath', item, member);
    setMember({ ...member, exemptionLetterFilePath: item });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `intent-to-participate|${member.guid}`,
  };

  const readOnly = () => {
    return some([member.hasResponded, isEmpty(member.memberName.trim())]);
  };

  useEffect(() => {
    loadIntent();
  }, [secureGUID]); // eslint-disable-line

  useEffect(() => {
    loadAllConsortia();
  }, [loadAllConsortia]);

  const getDisabled = (): boolean => {
    if (!isValid) return true;
    if (contactHasRoles(loggedInUser, 'admin')) {
      return false;
    }
    return !isOpen;
  };

  return (
    <React.Fragment>
      <Loading isActive={some([isLoading, isActive])} />
      <SecureWrap
        requireEdit={true}
        isLocked={!isOpen}
        component={'owneronly'}
        owner={readOnly() ? 0 : member.perkinsContact.universalContactId}
        isPage={false}
        noAccessComponent={<NoSubmissionAccess />}
      >
        <Paper>
          <Instruction title="Perkins Intent to Participate">
            <p>
              This page provides options to declare your intent to participate.
              If you select a participation status or consortium that is
              different than what was chosen for the previous fiscal year, you
              will be required to explain the change. When completed, use
              'Submit Intent To Participate'.
            </p>
          </Instruction>
        </Paper>

        <Paper>
          <FlexRow>
            <Input
              disabled={true}
              className="input-display"
              name="entityName"
              label="Your Institution | District"
              value={member.memberName}
            />
          </FlexRow>

          <FlexRow>
            <Radio
              label="Select how your institution will be participating"
              name="participation"
              onChange={(item: { participation: PARTICIPATION_TYPE_ENUM }) => {
                handleMemberParticipationStatus(item.participation);
              }}
              options={options()}
              value={member.newParticipationStatus}
            />
          </FlexRow>
          {member.newParticipationStatus ===
            PARTICIPATION_TYPE_ENUM.CONSORTIUM && (
            <FlexRow
              onBlur={() => {
                validate('consortiumId', member.consortiumId, member);
              }}
            >
              <ConsortiumSelectBox
                records={consoria}
                filter={{
                  property: 'standalone',
                  values: [false],
                }}
                valid={getFieldValid('consortiumId')}
                label="Select Your Consortium"
                name="consortiumId"
                onChange={handleChange}
                placeholder="Select..."
                selection={member.consortiumId}
              />
            </FlexRow>
          )}
          {member.newParticipationStatus ===
            PARTICIPATION_TYPE_ENUM.STANDALONE_EXEMPT && (
            <React.Fragment>
              <FlexRow
                onBlur={() => {
                  validate('exemptionReason', member.exemptionReason, member);
                }}
              >
                <SelectBox
                  clearable={true}
                  valid={getFieldValid('exemptionReason')}
                  errorMessage={getError('exemptionReason')}
                  name="exemptionReason"
                  label="Select Reason for Standalone Exempt Status"
                  onChange={handleChange}
                  options={[
                    {
                      key: 1,
                      value: 1,
                      label: 'No consortium in region',
                    },
                    {
                      key: 2,
                      value: 2,
                      label:
                        'Distance and / or Terrain limit access to substitutes',
                    },
                    {
                      key: 3,
                      value: 3,
                      label: 'Participation as Exempt is a grant condition ',
                    },
                  ]}
                />
              </FlexRow>
              <FlexRow>
                <FlexGroup>
                  <p className="form__label">Letter From The Superintendent</p>
                  {isEmpty(member.exemptionLetterFilePath) ? (
                    <div
                      onBlur={() => {
                        validate(
                          'exemptionLetterFilePath',
                          member.exemptionLetterFilePath,
                          member
                        );
                      }}
                    >
                      <Button
                        text="+ Upload Document"
                        className="form__btn--add u-flex-self-start"
                        onClick={() => setOpen(true)}
                      />
                      <AzureUploader
                        onClose={() => setOpen(false)}
                        open={open}
                        uploadParams={uploadParams}
                        onConfirm={handleFileUpload}
                      />
                      {!getFieldValid('exemptionLetterFilePath') && (
                        <p className="u-color-red">
                          {getError('exemptionLetterFilePath')}
                        </p>
                      )}
                    </div>
                  ) : (
                    <AzureDownloader
                      onClose={() => {
                        handleFileUpload('');
                      }}
                      uploadParams={uploadParams}
                      filepath={
                        member.exemptionLetterFilePath
                          ? member.exemptionLetterFilePath
                          : ''
                      }
                    />
                  )}
                </FlexGroup>
              </FlexRow>
            </React.Fragment>
          )}
          <MemberParticipationSummary member={member} consoria={consoria} />
          {participationChanged(member) && (
            <Textarea
              valid={getFieldValid('changeExplanation')}
              label="Status Change Explanation (2000 characters or less)"
              name="changeExplanation"
              onChange={handleChange}
              value={member.changeExplanation}
              errorMessage={getError('changeExplanation')}
              onBlur={() => {
                validate('changeExplanation', member.changeExplanation, member);
              }}
            />
          )}
        </Paper>
        <Paper>
          <SaveButtons
            disabled={getDisabled()}
            saveText="Submit Intent to Participate"
            alternateAction={saveIntent}
            cancelUrl={'/'}
            validationErrors={
              !isOpen
                ? ['The intent to participate window is closed.']
                : validationErrors
            }
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
