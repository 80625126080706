import Administrative from './Administrative';
import BooksAndSupplies from './BooksAndSupplies';
import CTAAssurances from './CTAAssurances';
import CTAGeneral from './CTAGeneral';
import CTASignatures from './CTASignatures';
import CTAWorkflow from './CTAWorkflow';
import Contracting from './Contracting';
import Equipment from './Equipment';
import Hosting from './Hosting';
import Instructional from './Instructional';
import Loading from 'components/Common/Loading';
import React, { useEffect, useState, useRef } from 'react';
import ReviewAndOptimize from './ReviewAndOptimize';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SupportingPersonnel from './SupportingPersonnel';
import {
  CTADistrictRecord,
  CTASchool,
  CTAGeneralAccuracy,
  CTAAdminInformation,
} from 'types/cta';
import { doNothing, isApplicaionIdValid } from 'util/global';
import { map, every } from 'lodash';
import { useParams } from 'react-router-dom';

type DistrictReportingProps = {
  adminRecords: CTAAdminInformation;
  ctaRecord: CTADistrictRecord;
  ctaSchools: CTASchool[];
  isFetching: boolean;
  loadCTAAdminInformation: Function;
  loadCTAGeneralAccuracy: Function;
  loadCTASchools: Function;
  readOnly: boolean;
};

export const DistrictReportingComponent: React.FC<DistrictReportingProps> = (
  props
) => {
  const {
    adminRecords,
    ctaRecord,
    ctaSchools,
    isFetching,
    loadCTAAdminInformation,
    loadCTAGeneralAccuracy,
    loadCTASchools,
    readOnly,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);

  // --[ local state ]---------------------------------------------------------
  const [termsCorrect, setTermsCorrect] = useState<boolean>(false);
  const [reportingLocked, setReportingLocked] = useState<boolean>(true);
  const isMounted = useRef(true);

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      loadCTASchools(ctaId).catch(doNothing);
      loadCTAAdminInformation().catch(doNothing);
    }
  }, [loadCTASchools, ctaId, loadCTAAdminInformation]);

  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      loadCTAGeneralAccuracy(ctaId)
        .then(({ enabled }: CTAGeneralAccuracy) => {
          isMounted.current && setTermsCorrect(enabled);
        })
        .catch((err: any) => console.error(err));
      const valid = map(ctaSchools, (item: CTASchool) => {
        return every([item.credits > 0, item.terms > 0]);
      });
      isMounted.current && setReportingLocked(!every([...valid, termsCorrect]));
    }
  }, [ctaSchools, termsCorrect, ctaId, loadCTAGeneralAccuracy]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // --[ render logic ]--------------------------------------------------------

  return (
    <SecureWrap component="ctageneral" allowReadOnly={true} isLocked={readOnly}>
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <CTAGeneral ctaRecord={ctaRecord} enabled={termsCorrect} />
      <Instructional locked={reportingLocked} ctaRecord={ctaRecord} />
      <SupportingPersonnel locked={reportingLocked} ctaRecord={ctaRecord} />
      <Administrative locked={reportingLocked} ctaRecord={ctaRecord} />
      <BooksAndSupplies locked={reportingLocked} ctaRecord={ctaRecord} />
      <Equipment locked={reportingLocked} ctaRecord={ctaRecord} />
      <Contracting locked={reportingLocked} />
      <Hosting locked={reportingLocked} ctaRecord={ctaRecord} />
      <ReviewAndOptimize locked={reportingLocked} />
      <CTAAssurances ctaRecord={ctaRecord} locked={reportingLocked} />
      <CTASignatures locked={reportingLocked} />
      <CTAWorkflow
        ctaRecord={ctaRecord}
        locked={reportingLocked || !adminRecords.submissionsOpen}
      />
    </SecureWrap>
  );
};
