import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import AssuranceSignatureWorkflow from 'components/Grants/Applications/AssuranceSignature/Workflow';
import { useParams } from 'react-router';

export const GrantAssuranceSignatureApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AssuranceSignatureWorkflow grantFiscalYearId={grantFiscalYearId} />
      </GrantsLayout>
    </BaseLayout>
  );
};
