import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { Signature } from 'types';
import { useParams } from 'react-router-dom';
import { BasicGrantSignatureValidations } from 'validations/BasicGrantSignature.validations';
import { toast } from 'react-toastify';
import CommonSignatureComponent from 'components/Common/Signature';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ToastNotification from 'components/Common/Toast';
import Loading from 'components/Common/Loading';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';

type AssuranceSignaturesProps = {
  isLocked: boolean;
  isLoading: boolean;
  loadBasicGrantSignatures: Function;
  saveBasicGrantSignatures: Function;
  setCurrentNavAnchor: Function;
  signatures: Signature[];
};

export const AssuranceSignatures: React.FC<AssuranceSignaturesProps> = (
  props
) => {
  const {
    isLoading,
    isLocked,
    loadBasicGrantSignatures,
    saveBasicGrantSignatures,
    setCurrentNavAnchor,
    signatures,
  } = props;

  const { validate, validationErrors } = BasicGrantSignatureValidations();

  const [signaturesState, setSignaturesState] = useState<Signature[]>([]);
  const [canSave, setCanSave] = useState<boolean>(true);
  const { grantFiscalYearId }: any = useParams();

  const onChange = (signature: Signature, index: number) => {
    const newState = signaturesState;
    newState[index] = signature;
    setSignaturesState([...newState]);
  };

  const handleSave = (): Promise<any> => {
    if (validate('hasAllRequiredFields', signaturesState)) {
      return saveSignatures();
    }
    return Promise.reject('Not all validations passed. Please check the form.');
  };

  const saveSignatures = () => {
    return saveBasicGrantSignatures(grantFiscalYearId, signaturesState)
      .then(() =>
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        )
      )
      .catch(() =>
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        )
      );
  };

  useEffect(() => {
    loadBasicGrantSignatures(grantFiscalYearId);
  }, [loadBasicGrantSignatures, grantFiscalYearId]);

  useEffect(() => {
    setCurrentNavAnchor('signatures');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    setSignaturesState([...signatures]);
  }, [signatures]);

  useEffect(() => {
    setCanSave(!!validate('hasAllRequiredFields', signaturesState));
  }, [signaturesState]); // eslint-disable-line

  return (
    <SecureWrap isLocked={isLocked} component="GrantsSignee">
      <Paper>
        <Loading isActive={isLoading} messageBefore="Loading Signatures..." />
        <Instruction title="Signatures">
          <p>
            Sign below with your name, title, and date in the appropriate
            signature line for your role, then save. Use the “Other” signature
            line if you are a stakeholder who served on an institution planning
            team for the Perkins application and are knowledgeable of the
            application.
          </p>
        </Instruction>
        {map(signaturesState, (signature: Signature, index: number) => {
          return (
            <CommonSignatureComponent
              onChange={(signature: Signature) => {
                onChange(signature, index);
              }}
              signature={signature}
              key={index}
            />
          );
        })}
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          cancelText="Return to Grant Overview"
          disabled={!canSave}
          saveData={handleSave}
          saveText="Save Signatures"
          validationErrors={validationErrors}
        />
      </Paper>
    </SecureWrap>
  );
};
