import { GRANTS_KEY, GRANTS_BASIC_GRANT_SIGNATURE_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';
import { Signature } from 'types';

export const BASIC_GRANT_SIGNATURE_PATH = [
  GRANTS_KEY,
  GRANTS_BASIC_GRANT_SIGNATURE_KEY,
];

export const getIsDataFetching_BasicGrantSignatures = (state: any): boolean => {
  return getIsDataFetching(state, BASIC_GRANT_SIGNATURE_PATH);
};

export const getRecords_BasicGrantSignatures = (
  state: unknown
): Signature[] => {
  return getRecords(state, BASIC_GRANT_SIGNATURE_PATH);
};

export const shouldRefreshReduxState_BasicGrantSignatures = (
  state: any
): boolean => {
  return shouldRefreshReduxState<Signature>(state, BASIC_GRANT_SIGNATURE_PATH);
};
