export interface CTAEquipmentCTEProgram {
  ctaEquipmentId: number;
  ctaProgramId: number;
  programName: string;
  schoolName: string;
  cost: number;
}

export const emptyCTAEquipmentCTEProgram = (): CTAEquipmentCTEProgram => {
  return {
    ctaEquipmentId: -1,
    ctaProgramId: -1,
    programName: '',
    schoolName: '',
    cost: 0,
  };
};
