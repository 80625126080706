import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { QuestionGroup } from 'types';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readGrantAssuranceQuestions,
  updateGrantFormQuestions,
} from 'services/grants/grantformquestions.services';

const initialState = baseInitialState;

export const loadBasicGrantAssurances = createActionThunk(
  'loadBasicGrantAssurances',
  (grantFiscalYearId: number) => {
    return readGrantAssuranceQuestions(grantFiscalYearId);
  }
);

export const saveBasicGrantAssurances = createActionThunk(
  'saveBasicGrantAssurances',
  async (payload: QuestionGroup[]) => {
    return updateGrantFormQuestions(payload);
  }
);

export default handleActions<BaseStateObject, QuestionGroup | QuestionGroup[]>(
  {
    ...baseLoadActionBuilder<QuestionGroup | QuestionGroup[]>(
      loadBasicGrantAssurances
    ),
    ...baseSaveActionBuilder<QuestionGroup | QuestionGroup[]>(
      saveBasicGrantAssurances
    ),
  },
  initialState
);
