import React from 'react';
import { HistoryData, PropertyDifferences } from 'types';
import { every, map, isEmpty } from 'lodash';
import { compose, isNull, prop } from 'util/objectUtility';
import Differences from '../Differences';

type ChangeHistoryProps = {
  historyData: HistoryData;
};

const newValueisNull = compose(
  isNull,
  prop('newValue')
);

const evalDiffs = (propertyDifferences: PropertyDifferences[]) => {
  return every(propertyDifferences, newValueisNull) ? (
    <p className="u-error">Deleted</p>
  ) : (
    map(propertyDifferences, (diff: PropertyDifferences, index: number) => {
      return <Differences key={index} propDiff={diff} />;
    })
  );
};

const renderChangeHistory = (hist: HistoryData, index: number) => {
  return (
    <React.Fragment key={index}>
      <h3 className="history__children-title">{hist.name}</h3>
      <div className="history__children">
        <ChangeHistory historyData={hist} />
      </div>
    </React.Fragment>
  );
};

export const ChangeHistory: React.FC<ChangeHistoryProps> = (props) => {
  const { historyData } = props;
  const { propertyDifferences, childDifferences } = historyData;
  return (
    <React.Fragment>
      {!isEmpty(childDifferences) && map(childDifferences, renderChangeHistory)}
      {!isEmpty(propertyDifferences) && evalDiffs(propertyDifferences)}
    </React.Fragment>
  );
};
