export const PARTNERSHIPS = '[PARTNERSHIPS]';

export const SET_PARTNERSHIPS = `${PARTNERSHIPS} SET`;
export const SET_PARTNERSHIPS_LOADING = `${PARTNERSHIPS} LOADING`;

export const setPartnerships = (payload: any) => {
  return {
    type: SET_PARTNERSHIPS,
    payload,
  };
};

export const setIsLoading_Partnerships = (payload: boolean) => {
  return {
    type: SET_PARTNERSHIPS_LOADING,
    payload,
  };
};
