import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readCareerPathwaysByProgramInstanceId } from 'services/common/careerpathways.services';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { ProgramPathway } from 'types';

const initialState = baseInitialState;

export const loadCareerPathways = createActionThunk(
  'loadCareerPathways',
  (programInstanceId: number) => {
    return readCareerPathwaysByProgramInstanceId(programInstanceId);
  }
);

export default handleActions<
  BaseStateObject,
  ProgramPathway | ProgramPathway[]
>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramPathway | ProgramPathway[]>(
      loadCareerPathways
    ),
  },
  initialState
);
