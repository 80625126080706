import { connect } from 'react-redux';
import { AwardPage } from './Award.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const awardId: number = Number(ownProps.match.params.articulationId);

  return {
    programInstanceId,
    awardId,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(AwardPage);
