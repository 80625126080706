import React from 'react';
import ReactSelect from 'react-select';
import { CTSO_Type, OptionMap, DropdownItem, HandleSelect } from 'types';
import { dropdownMapper } from 'util/formHelpers';
import { SelectBoxProps } from 'components/Common/FormElements/SelectBox/SelectBox.component';
import { map, filter } from 'lodash';

interface CTSOMultiSelectBoxProps extends SelectBoxProps {
  ctsO_IDs: string[];
  isMulti?: boolean;
  organizations: CTSO_Type[];
}

export const CTSOMultiSelectBox: React.FC<CTSOMultiSelectBoxProps> = (
  props
) => {
  const {
    errorMessage,
    organizations,
    ctsO_IDs,
    onBlur,
    onChange,
    disabled,
    valid = true,
  } = props;

  const optionMap: OptionMap<CTSO_Type> = {
    key: 'ctsoId',
    label: 'ctsoId',
    value: 'ctsoId',
  };

  const options = dropdownMapper(organizations, optionMap);

  const selectOrgs = () => {
    return filter(options, (item: DropdownItem) => {
      return ctsO_IDs.includes(item.value);
    });
  };

  const handleSelection: HandleSelect = (items: any) => {
    if (disabled || !onChange) {
      return;
    }
    const data: { [key: string]: unknown } = {};

    if (!items) {
      data['ctsO_IDs'] = [];
      onChange(data, 'ctsO_IDs', []);
      return;
    }

    const value = map(items as DropdownItem[], (item: DropdownItem) => {
      return item.value;
    });
    data['ctsO_IDs'] = value;
    onChange(data, 'ctsO_IDs', value);
  };

  return (
    <div className="form__group">
      <label htmlFor="ctsO_IDs" className="form__label">
        CTSO(s)
      </label>
      <ReactSelect
        className={`react-select-container ${
          valid ? 'react-select__valid' : 'react-select__invalid'
        }`}
        classNamePrefix="react-select"
        fluid
        isMulti={true}
        isDisabled={disabled}
        name="ctsO_IDs"
        onBlur={onBlur && onBlur()}
        onChange={handleSelection}
        options={options}
        search
        valid={valid}
        value={selectOrgs()}
      />
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
