import { combineReducers } from 'redux';
import {
  GRANTS_ANNUAL_SPENDING_PLAN_KEY,
  GRANTS_CONSORTIUMS_KEY,
  GRANTS_ITP_CONSORTIUMS_KEY,
  GRANTS_FISCAL_AGENTS_KEY,
  GRANTS_FISCAL_YEAR_KEY,
  GRANTS_TWO_YEAR_KEY,
  GRANTS_WORK_BASED_LEARNING_KEY,
  GRANTS_NEEDS_ASSESSMENT_KEY,
  GRANTS_PARTNERSHIPS_KEY,
  GRANTS_CAREER_INFO_KEY,
  GRANTS_INSTRUCTORS_KEY,
  GRANTS_EQUITY_KEY,
  GRANTS_QUALITY_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_CAREER_INFO_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_QUALITY_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_INSTRUCTORS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_PARTNERSHIPS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_EQUITY_KEY,
  GRANTS_PARTICIPANT_KEY,
  GRANTS_STRATEGY_SELECT_ITEMS_KEY,
  GRANTS_EXPENSE_PROGRAMS_KEY,
  GRANTS_BASIC_GRANT_SIGNATURE_KEY,
  GRANTS_ASSURANCES_KEY,
  GRANTS_ANNUAL_REFLECTION_KEY,
  GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES,
  GRANTS_CIVIL_RIGHTS_SIGNATURES,
  GRANTS_AGREEMENTS_SIGNATURES,
  GRANTS_EMAIL_TEMPLATE_KEY,
  GRANTS_EMAIL_TEMPLATE_SUMMARIES_KEY,
  GRANTS_VOUCHERS_KEY,
  GRANTS_REIMBURSEMENT_SUMMARY_KEY,
  GRANTS_ACTUAL_REIMBURSEMENT_KEY,
  GRANTS_IMPROVEMENT_PLAN_KEY,
} from 'redux/keys';
import { needsAssessmentReducer } from './grantformquestions/fouryearquestions/subtypes/needsassessment.reducer';
import { careerInfoReducer } from './grantformquestions/fouryearquestions/subtypes/careerinfo.reducer';
import { qualityReducer } from './grantformquestions/fouryearquestions/subtypes/quality.reducer';
import { instructorsReducer } from './grantformquestions/fouryearquestions/subtypes/instructors.reducer';
import { partnershipsReducer } from './grantformquestions/fouryearquestions/subtypes/partnerships.reducer';
import { workBasedLearningReducer } from './grantformquestions/fouryearquestions/subtypes/workbasedlearning.reducer';
import { equityReducer } from './grantformquestions/fouryearquestions/subtypes/equity.reducer';
import { needsAssessmentAddressableElementsReducer } from './fouryearaddressableelements/needsassessment.reducer';
import { careerInfoAddressableElementsReducer } from './fouryearaddressableelements/careerinfo.reducer';
import { qualityAddressableElementsReducer } from './fouryearaddressableelements/quality.reducer';
import { instructorsAddressableElementsReducer } from './fouryearaddressableelements/instructors.reducer';
import { partnershipsAddressableElementsReducer } from './fouryearaddressableelements/partnerships.reducer';
import { workBasedLearningAddressableElementsReducer } from './fouryearaddressableelements/workbasedlearning.reducer';
import { equityAddressableElementsReducer } from './fouryearaddressableelements/equity.reducer';
import { annualspendingplanReducer } from './annualspendingplan/annualspendingplan.reducer';
import consortium from 'redux/grants/consortium';
import itpconsortium from 'redux/grants/itpconsortium';
import fiscalagents from 'redux/grants/fiscalagents';
import grantexpenseprogram from './grantexpenseprogram';
import grantfiscalyear from './grantfiscalyear';
import grantParticipants from './grantparticipant';
import strategyselectitem from './strategyselectitem';
import twoyear from './twoyear';
import basicgrantsignatures from './basicgrantsignature';
import assurances from './assurances';
import annualreflection from './annualreflection';
import certificateofexemptionsignatures from './awardsignatures/certificateofexemptionsignatures';
import civilrightsignatures from './awardsignatures/civilrightsignatures';
import grantagreementsignatures from './awardsignatures/grantagreementsignatures';
import emailtemplates from './emailtemplates';
import emailtemplatesummaries from './emailtemplatesummaries';
import vouchers from './vouchers';
import reimbursementrequestsummary from './reimbursementrequestsummary';
import actualreimbursementrequest from './actualreimbursementrequest';
import improvementplan from './improvementplan';

export default combineReducers({
  [GRANTS_ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT_KEY]: needsAssessmentAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_CAREER_INFO_KEY]: careerInfoAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_QUALITY_KEY]: qualityAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_INSTRUCTORS_KEY]: instructorsAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_PARTNERSHIPS_KEY]: partnershipsAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING_KEY]: workBasedLearningAddressableElementsReducer,
  [GRANTS_ADDRESSABLE_ELEMENTS_EQUITY_KEY]: equityAddressableElementsReducer,
  [GRANTS_ANNUAL_SPENDING_PLAN_KEY]: annualspendingplanReducer,
  [GRANTS_CAREER_INFO_KEY]: careerInfoReducer,
  [GRANTS_CONSORTIUMS_KEY]: consortium,
  [GRANTS_ITP_CONSORTIUMS_KEY]: itpconsortium,
  [GRANTS_EQUITY_KEY]: equityReducer,
  [GRANTS_FISCAL_AGENTS_KEY]: fiscalagents,
  [GRANTS_FISCAL_YEAR_KEY]: grantfiscalyear,
  [GRANTS_INSTRUCTORS_KEY]: instructorsReducer,
  [GRANTS_NEEDS_ASSESSMENT_KEY]: needsAssessmentReducer,
  [GRANTS_PARTICIPANT_KEY]: grantParticipants,
  [GRANTS_PARTNERSHIPS_KEY]: partnershipsReducer,
  [GRANTS_QUALITY_KEY]: qualityReducer,
  [GRANTS_STRATEGY_SELECT_ITEMS_KEY]: strategyselectitem,
  [GRANTS_TWO_YEAR_KEY]: twoyear,
  [GRANTS_WORK_BASED_LEARNING_KEY]: workBasedLearningReducer,
  [GRANTS_EXPENSE_PROGRAMS_KEY]: grantexpenseprogram,
  [GRANTS_BASIC_GRANT_SIGNATURE_KEY]: basicgrantsignatures,
  [GRANTS_ASSURANCES_KEY]: assurances,
  [GRANTS_ANNUAL_REFLECTION_KEY]: annualreflection,
  [GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES]: certificateofexemptionsignatures,
  [GRANTS_CIVIL_RIGHTS_SIGNATURES]: civilrightsignatures,
  [GRANTS_AGREEMENTS_SIGNATURES]: grantagreementsignatures,
  [GRANTS_EMAIL_TEMPLATE_KEY]: emailtemplates,
  [GRANTS_EMAIL_TEMPLATE_SUMMARIES_KEY]: emailtemplatesummaries,
  [GRANTS_VOUCHERS_KEY]: vouchers,
  [GRANTS_REIMBURSEMENT_SUMMARY_KEY]: reimbursementrequestsummary,
  [GRANTS_ACTUAL_REIMBURSEMENT_KEY]: actualreimbursementrequest,
  [GRANTS_IMPROVEMENT_PLAN_KEY]: improvementplan,
});
