import React, { useState, useRef } from 'react';
import IconButton from 'components/Common/IconButton';
import Button from 'components/Common/FormElements/Button';
import { Dialog } from '@rmwc/dialog';
import '@material/dialog/dist/mdc.dialog.css';
import { insertClass } from 'util/formHelpers';

type ConfirmationDialogProps = {
  header: string;
  onConfirm: Function;
  activateButton?: JSX.Element | string;
  icon?: string;
  tooltip: string;
  cancelText?: string;
  confirmText?: string;
  disabled?: boolean;
  tName?: string;
};

/**
 *  Example: for activateButton, should be a function that takes a onClick
 *  as a param and returns a button or any other html element.
 *
 *  activateButton={
 *   <Button text="Renew" className="button--filled" />
 *  }
 *
 *  tooltip is a required prop so if an activateButton is not provided, the DOM
 *  can properly label the icon that will activate the dialog
 */
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props
) => {
  const {
    header,
    children,
    icon = 'trashCan',
    onConfirm,
    tooltip,
    activateButton = <IconButton tooltip={tooltip} name={icon} />,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    disabled,
    tName = 't-dialog-confirm',
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const btnRef = useRef(null);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className="dialog">
          <h2 className="dialog__title">{header}</h2>
          <div className="dialog__children">{children}</div>
          <div className="dialog__btn-container">
            <Button
              onClick={() => setOpen(false)}
              className="form__btn--cancel"
              text={cancelText}
            />
            <Button
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
              className={`confirm-button form__btn--submit ${insertClass(
                tName
              )}`}
              text={confirmText}
            />
          </div>
        </div>
      </Dialog>
      <span
        ref={btnRef}
        onClick={(e) => {
          if (!disabled) {
            handleClick();
          }
        }}
      >
        {activateButton}
      </span>
    </React.Fragment>
  );
};
