import React from 'react';
import { isNumber } from 'util';
import Icon from 'components/Common/Icons';

type HotButtonProps = {
  text: string;
  number?: number;
  iconName: string;
  active?: boolean;
  onClick?: Function;
};

export const FilterHotButton: React.FC<HotButtonProps> = (props) => {
  const { active = false, iconName = 'tune', number, onClick, text } = props;

  const containerClass = active
    ? 'hot-button hot-button--active'
    : 'hot-button';
  return (
    <button className={containerClass} onClick={() => onClick && onClick()}>
      <div className="hot-button__row">
        <div className="hot-button__icon-parent">
          <Icon className="hot-button__icon" name={iconName} />
        </div>
        {isNumber(number) && <p className="hot-button__num">{number}</p>}
      </div>
      <p className="hot-button__text">{text}</p>
    </button>
  );
};
