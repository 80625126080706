import { connect } from 'react-redux';
import { Department, OptionMap, SelectWrapper } from 'types';
import {
  getRecords_Departments,
  DEPARTMENTS_PATH,
  getIsDataFetching_Departments,
} from 'redux/common/_selectors';
import { getRecordsUtility } from 'util/formHelpers';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, filter, label, onChange, records: baseRecords } = ownProps;

  const isLoading = getIsDataFetching_Departments(state);
  const records = getRecordsUtility<Department>(
    state,
    DEPARTMENTS_PATH,
    getRecords_Departments,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<Department> = {
    key: 'schoolDepartmentId',
    value: 'schoolDepartmentId',
    label: 'departmentName',
  };

  return {
    isLoading,
    label: label ? label : 'Department',
    name: 'schoolDepartmentId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
