import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect } from 'react';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import { CTADistrictRecord } from 'types';
import { CtaInstructor } from 'types/cta/InstructionalCosts/CtaInstructor.types';
import { FlexRow, FlexGroup } from 'layouts';
import {
  SupportingSalaryDocuments,
  emptySupportingSalaryDocuments,
} from 'types/cta/InstructionalCosts/SupportingSalaryDocuments.types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { defaultTo } from 'ramda';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import {
  renderData,
  prop,
  concatLengthWithItems,
  compose,
  safeGet,
  concatEnd,
} from 'util/objectUtility';
import { round } from 'util/mathHelpers';
import { toString } from 'util/global';
import { toast } from 'react-toastify';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

type InstructionalProps = {
  locked: boolean;
  instructors: CtaInstructor[];
  ctaRecord: CTADistrictRecord;
  saveSupportingSalary: Function;
  loadInstructors: Function;
};

export const InstructionalComponent: React.FC<InstructionalProps> = (props) => {
  const {
    instructors,
    ctaRecord,
    saveSupportingSalary,
    loadInstructors,
    locked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const history = useHistory();
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [isSalaryOpen, setIsSalaryOpen] = useState<boolean>(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);
  const [instructorsState, setInstructorsState] = useState<CtaInstructor[]>(
    instructors
  );
  const [
    documentsState,
    setDocumentsState,
  ] = useState<SupportingSalaryDocuments>(emptySupportingSalaryDocuments());
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  // get :: documentsState -> string -> documentsState[string]
  const get = safeGet(documentsState);

  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };
  const getCTA = safeGet(ctaRecord);
  const uploadParams = () => ({
    container: 'cta',
    folder: `${getCTA('year')}|${getCTA(
      'schoolDistrictName'
    )}|SupportingSalaryDocuments`,
  });

  const uploadFile = (item: string, schedule: boolean) => {
    if (schedule) {
      setDocumentsState({
        ...documentsState,
        districtScheduleInfoFilePath: item,
      });
    } else {
      setDocumentsState({
        ...documentsState,
        districtSalaryInfoFilePath: item,
      });
    }
  };

  const saveDocs = () => {
    saveSupportingSalary(ctaId, documentsState)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch((err: any) => console.error(err));
  };

  // --[ lifecycle ]-----------------------------------------------------------
  /* Set the local document state from the district record */
  useEffect(() => {
    const {
      districtSalaryInfoFilePath,
      districtScheduleInfoFilePath,
    } = ctaRecord;
    setDocumentsState({
      districtSalaryInfoFilePath,
      districtScheduleInfoFilePath,
    });
  }, [ctaRecord]);

  useEffect(() => {
    if (wasOpened) {
      loadInstructors(ctaId);
    }
  }, [wasOpened, loadInstructors, ctaId]);

  useEffect(() => {
    setInstructorsState(instructors);
  }, [instructors]);

  // --[ display logic ]-------------------------------------------------------
  // getDisplayData :: string -> CtaInstructor -> string
  const getDisplayData = (name: keyof CtaInstructor) => (
    row: CtaInstructor
  ) => {
    return renderData(prop(name, row));
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderEditIcon = (row: CtaInstructor) => {
    return (
      <IconButton
        name="pencil"
        tooltip="Edit"
        onClick={() => {
          history.push(
            `/cta/district-reporting/instructor/${ctaId}/${row.ctaInstructorId}`
          );
        }}
      />
    );
  };

  const BASE_HEADERS: TableColumnProps[] = [
    {
      name: 'Instructor Name',
      cell: getDisplayData('instructorName'),
      sortable: true,
      selector: prop('instructorName'),
      width: '12%',
    },
    {
      name: 'Credential Id',
      cell: getDisplayData('credentialNumber'),
      sortable: true,
      selector: prop('credentialNumber'),
      width: '7%',
    },
    {
      name: 'Schools',
      cell: (row: CtaInstructor) => {
        return prop('schools', row)
          ? concatLengthWithItems('schools', 'schoolName')(row)
          : null;
      },
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Programs',
      cell: (row: CtaInstructor) => {
        return prop('programs', row)
          ? concatLengthWithItems('programs', 'programName')(row)
          : null;
      },
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'CTE Count',
      cell: getDisplayData('cteCount'),
      sortable: true,
      selector: prop('instructorName'),
      width: '10rem',
    },
    {
      name: 'CTE %',
      cell: compose(concatEnd('%'), toString, round(2), prop('ctePercent')),
      sortable: true,
      selector: prop('ctePercent'),
      width: '8rem',
    },
    {
      name: '# Classes',
      cell: getDisplayData('classCount'),
      sortable: true,
      selector: prop('classCount'),
      width: '10rem',
    },
    {
      name: 'Salary',
      cell: compose(
        insertDollarSign,
        formatMoney,
        defaultTo(0),
        prop('salary')
      ),
      sortable: true,
      selector: prop('salary'),
    },
    {
      name: 'Status',
      cell: compose(renderCircle, prop('status')),
      sortable: true,
      selector: prop('status'),
      width: '8rem',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-instructional"
        locked={locked}
        header="Instructional Costs"
        onChange={updateOpenedState}
      >
        {wasOpened && (
          <React.Fragment>
            <Paper>
              <FlexRow>
                <Instruction title="Supporting Salary Documentation">
                  <p>
                    Click on the ‘Upload Documents’ text to enter your
                    supporting ‘District Salary Information’ and ‘District
                    Schedule Information’ files.
                  </p>
                  <p>
                    <span className="u-color-red">Note</span>: You may only
                    upload one file per year.
                  </p>
                </Instruction>
              </FlexRow>

              <div className="cta-grid-row">
                <p className="form__label">District Salary Information</p>
                <p className="form__label">District Schedule Information</p>
                <div />
                <div />
              </div>
              <div className="cta-grid-row">
                <div
                  className="salary-azure"
                  style={{
                    height: '5.4rem',
                  }}
                >
                  {get('districtSalaryInfoFilePath') ? (
                    <AzureDownloader
                      onClose={() => {
                        uploadFile('', false);
                      }}
                      uploadParams={uploadParams()}
                      filepath={get('districtSalaryInfoFilePath')}
                    />
                  ) : (
                    <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                      <Button
                        text="+ Upload Documentation"
                        className="form__btn--add"
                        onClick={() => setIsSalaryOpen(true)}
                      />
                    </SecureWrap>
                  )}

                  <AzureUploader
                    onClose={() => setIsSalaryOpen(false)}
                    open={isSalaryOpen}
                    uploadParams={uploadParams()}
                    onConfirm={(file: string) => {
                      uploadFile(file, false);
                    }}
                  />
                </div>

                <div
                  className="schedule-azure"
                  style={{
                    height: '5.4rem',
                  }}
                >
                  {get('districtScheduleInfoFilePath') ? (
                    <AzureDownloader
                      onClose={() => {
                        uploadFile('', true);
                      }}
                      uploadParams={uploadParams()}
                      filepath={get('districtScheduleInfoFilePath')}
                    />
                  ) : (
                    <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                      <Button
                        text="+ Upload Documentation"
                        className="form__btn--add"
                        onClick={() => setIsScheduleOpen(true)}
                      />
                    </SecureWrap>
                  )}

                  <AzureUploader
                    onClose={() => setIsScheduleOpen(false)}
                    open={isScheduleOpen}
                    uploadParams={uploadParams()}
                    onConfirm={(file: string) => {
                      uploadFile(file, true);
                    }}
                  />
                </div>
                <div />
                <div
                  className="u-flex"
                  style={{
                    height: '5.4rem',
                    alignSelf: 'flex-end',
                    justifyContent: 'flex-end',
                  }}
                >
                  <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                    <Button
                      text="Save"
                      className="button--filled"
                      onClick={saveDocs}
                    />
                  </SecureWrap>
                </div>
              </div>
            </Paper>
            <Paper>
              <FlexRow>
                <Instruction title="Instructors">
                  <p>
                    Enter text in the ‘Search’ bar to find a specific teacher or
                    click on the filter button to sort by indicated fields in
                    the dropdown menu. Review the information and, if changes
                    are needed, click on the Edit icon. If a teacher needs to be
                    added, have your district's poweruser or person with the
                    teacher record permission enter information on the
                    instructor in the Data Collection module. You will not be
                    able to edit a teacher’s instructional costs until the day
                    after they were reported on in{' '}
                    <Link
                      rel="noreferrer"
                      target="_blank"
                      to="/data-collection"
                    >
                      Data Collections
                    </Link>
                    , allowing time for the database to update.
                  </p>
                  <br />
                  <p>
                    ‘Status’ indicator is solid teal. If no data are reported
                    for an individual instructor you will need to edit the
                    record to indicate the information is correct: In the review
                    section, toggle the indicator that the instructor record is
                    complete, ‘Save’ your response, and click the ‘Return to
                    Summary’ button.
                  </p>
                  <br />
                  <p>
                    <span className="u-color-red">Note</span>: Entries with an
                    ellipses (…) indicate that the item was cutoff and entries
                    with a plus (+) indicate additional information is contained
                    in the record.
                  </p>
                </Instruction>
              </FlexRow>
              <FlexRow>
                <SearchBarFilter
                  data={instructors}
                  headers={BASE_HEADERS}
                  label="Search"
                  setFilteredData={setInstructorsState}
                />
              </FlexRow>
              <FlexRow>
                <FlexGroup>
                  <Table columns={BASE_HEADERS} data={instructorsState} />
                </FlexGroup>
              </FlexRow>
            </Paper>
          </React.Fragment>
        )}
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
