import { connect } from 'react-redux';
import { OptionMap, SelectWrapper, StrategySelectItem } from 'types';
import {
  GRANTS_STRATEGY_SELECT_ITEMS_PATH,
  getIsDataFetching_StrategySelectItems,
  getRecords_StrategySelectItems,
} from 'redux/grants/_selectors';
import { getRecordsUtility } from 'util/formHelpers';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    onChange,
    records: baseRecords,
    name,
  } = ownProps;

  const isLoading = getIsDataFetching_StrategySelectItems(state);
  const records = getRecordsUtility<StrategySelectItem>(
    state,
    GRANTS_STRATEGY_SELECT_ITEMS_PATH,
    getRecords_StrategySelectItems,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<StrategySelectItem> = {
    key: 'strategyId',
    value: 'strategyId',
    label: 'strategy',
  };

  return {
    isLoading,
    label: label ? label : 'Select a Strategy to Add a Project',
    name: name ? name : 'strategyId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
