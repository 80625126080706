import { connect } from 'react-redux';
import { loadSingleUniversalContact } from 'redux/universalcontacts/universalcontacts';
import { MiniContactForm } from './MiniContactForm.component';

export const mapStateToProps = () => {};

const dispatchToProps = {
  loadSingleUniversalContact,
};

export default connect(
  null,
  dispatchToProps
)(MiniContactForm);
