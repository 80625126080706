import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import { EmailTemplate } from 'types';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
  actionUpdateRecords,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  postRecipients,
  readSingleEmailTemplate,
} from 'services/grants/emailManagement.services';
import { upsertInPlace } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadSingleEmailTemplate = createActionThunk(
  'loadSingleEmailTemplate',
  (fiscalYear: number, templateId: number) => {
    return readSingleEmailTemplate(fiscalYear, templateId);
  }
);

export const saveEmailRecipients = createActionThunk(
  'saveEmailRecipients',
  async (fiscalYear: number, payload: EmailTemplate) => {
    return postRecipients(fiscalYear, payload);
  }
);

export const upsertEmailTemplate = createAction('upsertEmailTemplate');

export default handleActions<BaseStateObject, EmailTemplate | EmailTemplate[]>(
  {
    upsertEmailTemplate: (state, { payload }: any) => {
      const records = upsertInPlace(state.records, payload, 'templateId');
      return actionUpdateRecords(state, records);
    },
    ...baseLoadActionBuilder<EmailTemplate | EmailTemplate[]>(
      loadSingleEmailTemplate
    ),
    ...baseSaveActionBuilder<EmailTemplate | EmailTemplate[]>(
      saveEmailRecipients
    ),
  },
  initialState
);
