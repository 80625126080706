import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const OVERVIEW = 'api/GrantNav/voucher-overview/';
const DETAIL = 'api/GrantNav/voucher-detail/';
const STATUS = 'api/GrantNav/VoucherOverview/';

export const readGrantVoucherOverviewNav = async () => {
  return fetch(`${ENVIRONMENT.API_URL}${OVERVIEW}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantVoucherOverviewNav', err);
    });
};

export const readGrantVoucherDetailNav = async (
  grantFiscalYearId: number,
  voucherType: string
) => {
  return fetch(
    `${ENVIRONMENT.API_URL}${DETAIL}${grantFiscalYearId}/${voucherType}`,
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantVoucherDetailNav', err);
    });
};

export const readGrantVoucherWorkflowStatus = async (
  GrantFiscalYearId: number
) => {
  return fetch(`${ENVIRONMENT.API_URL}${STATUS}${GrantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantVoucherOverviewStatus', err);
    });
};
