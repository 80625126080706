import React, { useEffect, useState } from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import { MIDDLE_SCHOOL_GRADE_OPTIONS as gradeOptions } from 'constants/radioOptionSchema.const';
import {
  convertStringToRadioValue,
  createRadioToStringOnChange,
} from 'util/formHelpers';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import Input from 'components/Common/FormElements/Input';
import Radio from 'components/Common/FormElements/Radio';
import Toggle from 'components/Common/FormElements/Toggle';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';

export const AcademicInformationM: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v, enrollment } = props;

  const [homeSchoolToggle, setHomeSchoolToggle] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<string>(enrollment.cdeId);
  const convertGradeToRadioValue = convertStringToRadioValue(gradeOptions);
  const handleGradesChange = createRadioToStringOnChange(
    onChange,
    gradeOptions
  );

  useEffect(() => {
    if (schoolId !== enrollment.cdeId) {
      setSchoolId(enrollment.cdeId);
    }
  }, [enrollment.cdeId]); // eslint-disable-line

  useEffect(() => {
    if (!!schoolId && (student && student.homeCde_ID === schoolId)) {
      setHomeSchoolToggle(true);
    } else {
      setHomeSchoolToggle(false);
    }
  }, [student.homeCde_ID, schoolId]); // eslint-disable-line

  return student ? (
    <React.Fragment>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexGroup style={{ width: '48%' }}>
          <Radio
            border={true}
            horizontal={false}
            label="CDE Grade"
            name="grade"
            onChange={handleGradesChange}
            options={Object.values(gradeOptions)}
            value={convertGradeToRadioValue(student.grade)}
            valid={v.getFieldValid('grade')}
            errorMessage={v.getError('grade')}
            onBlur={() => v.validate('grade', student.grade, student)}
          />
        </FlexGroup>

        <FlexGroup style={{ width: '49%' }}>
          <FlexRow>
            <Toggle
              border={true}
              label="Home School ID (optional)"
              name="toggle"
              description="Use Current School"
              onChange={() => {
                setHomeSchoolToggle(!homeSchoolToggle);
                onChange({ homeCde_ID: schoolId }, 'homeCde_ID', schoolId);
              }}
              value={homeSchoolToggle}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="homeCde_ID"
                iconName="info"
                label="Home School ID"
                tooltip="The 4-digit CDE school code for the student’s school of primary academic enrollment (not primary CTE program enrollment)."
                place="right"
              />
              <Input
                name="homeCde_ID"
                value={student.homeCde_ID}
                disabled={homeSchoolToggle}
                onChange={onChange}
                noLabel
                valid={v.getFieldValid('homeCde_ID')}
                errorMessage={v.getError('homeCde_ID')}
                onBlur={() =>
                  v.validate('homeCde_ID', student.homeCde_ID, student)
                }
              />
            </FlexGroup>
          </FlexRow>
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
