import { ParticipationDashboardFilters } from './Filters.component';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { prop, len as length, isFalse, compose } from 'util/objectUtility';
import { getEmailTemplate } from 'redux/grants/_selectors';
import {
  clearEmailRecipientFilters,
  setEmailRecipientFilterValue,
} from 'redux/filters/emailrecipientfilter';
import { EmailRecipient } from 'types';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';

const mapStateProps = (state: any) => {
  const { recipients } = getEmailTemplate(state);

  const emailSent = prop('emailWasSent');
  const emailNotSent = compose(
    isFalse,
    prop('emailWasSent')
  );
  const isSecondary = (recipient: EmailRecipient) => {
    return recipient.instituteLevel === PROGRAM_LEVEL_ENUM.Secondary;
  };
  const isPostsecondary = (recipient: EmailRecipient) => {
    return recipient.instituteLevel === PROGRAM_LEVEL_ENUM.Postsecondary;
  };

  const included = prop('include');
  const notIncluded = compose(
    isFalse,
    prop('include')
  );

  const filterLengths = {
    all: length(recipients),
    standaloneParticipant: length(
      filter(recipients, (item) => {
        return item.consortiumName === null;
      })
    ),
    emailSent: length(filter(recipients, emailSent)),
    emailNotSent: length(filter(recipients, emailNotSent)),
    consortiumParticipant: length(
      filter(recipients, (item) => {
        return item.consortiumName !== null;
      })
    ),
    secondary: length(filter(recipients, isSecondary)),
    postsecondary: length(filter(recipients, isPostsecondary)),
    included: length(filter(recipients, included)),
    notIncluded: length(filter(recipients, notIncluded)),
  };

  return {
    filterLengths,
  };
};

const dispatchProps = {
  clearEmailRecipientFilters,
  setEmailRecipientFilterValue,
};

export default connect(
  mapStateProps,
  dispatchProps
)(ParticipationDashboardFilters);
