import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramCareerPathways,
  createProgramCareerPathways,
} from 'services/programs/programcareerpathways.services';
import { ProgramPathway } from 'types';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';

export const loadProgramCareerPathways = createActionThunk(
  'loadProgramCareerPathways',
  (programInstanceId: number) => {
    return readProgramCareerPathways(programInstanceId);
  }
);

export const saveProgramCareerPathways = createActionThunk(
  'saveProgramCareerPathways',
  (payload: ProgramPathway[], programInstanceId: number) => {
    return createProgramCareerPathways(payload, programInstanceId);
  }
);

export default handleActions<BaseStateObject, ProgramPathway[]>(
  {
    ...baseLoadActionBuilder<ProgramPathway[]>(loadProgramCareerPathways),

    [saveProgramCareerPathways.START]: (state, { payload }) => {
      return actionFetching(state, true);
    },
    [saveProgramCareerPathways.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    },
  },
  baseInitialState
);
