import React, { useEffect } from 'react';
import { QuestionGroup, Signature } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { BasicGrantSignatureValidations } from 'validations/BasicGrantSignature.validations';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { Waypoint } from 'react-waypoint';
import { useParams } from 'react-router';
import { QuestionValidation } from 'validations/Question.validations';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';

type AssuranceSignatureSummaryProps = {
  assurances: QuestionGroup[];
  loadBasicGrantAssurances: Function;
  loadBasicGrantSignatures: Function;
  signatures: Signature[];
  setCurrentNavAnchor: Function;
};

export const AssuranceSignatureSummaryComponent: React.FC<
  AssuranceSignatureSummaryProps
> = (props) => {
  const {
    assurances,
    loadBasicGrantAssurances,
    loadBasicGrantSignatures,
    signatures,
    setCurrentNavAnchor,
  } = props;

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const {
    validate: validateAssurances,
    isValid: areAssurancesComplete,
  } = QuestionValidation();

  const {
    validate: validateSignatures,
    isValid: areSignaturesComplete,
  } = BasicGrantSignatureValidations();

  useEffect(() => {
    loadBasicGrantAssurances(grantFiscalYearId);
    loadBasicGrantSignatures(grantFiscalYearId);
  }, [grantFiscalYearId, loadBasicGrantSignatures, loadBasicGrantAssurances]);

  useEffect(() => {
    validateAssurances('allQuestionsAnswered', assurances);
  }, [assurances]); //eslint-disable-line

  useEffect(() => {
    validateSignatures('hasAllRequiredSignatures', signatures);
  }, [signatures]); //eslint-disable-line

  return (
    <Paper>
      <SummaryScroll componentStep="assurance-signature" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('assurance-signature')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Assurances</p>
          <p>
            Collection Status:{' '}
            {areAssurancesComplete ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Signatures</p>
          <p>
            Collection Status:{' '}
            {areSignaturesComplete ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('assurance-signature')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
