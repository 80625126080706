import React from 'react';
import { Question } from 'types';
import { FlexRow } from 'layouts';
import { randomString } from 'util/global';
import Textarea from 'components/Common/FormElements/Textarea';

type QuestionTextProps = {
  question: Question;
  onChange: Function;
};

export const QuestionText: React.FC<QuestionTextProps> = (props) => {
  const { question, onChange } = props;

  const handleChange = (
    data: Partial<Question>,
    name: keyof Question,
    value: string
  ) => {
    const questionValue = {
      ...question.questionValue,
      value,
    };
    const newState = {
      ...question,
      questionValue,
    };
    onChange(newState);
  };

  return (
    <FlexRow>
      <Textarea
        name="questionValue.value"
        id={randomString()}
        label={question.text}
        value={question.questionValue.value}
        onChange={handleChange}
      />
    </FlexRow>
  );
};
