/**
 * this is really a careersector!
 */
export interface ProgramArea {
  careerSectorId: number;
  careerSectorTitle: string;
}

export const emptyProgramArea = (): ProgramArea => {
  return {
    careerSectorId: -1,
    careerSectorTitle: '',
  };
};
