import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { CourseSelectBoxComponent } from './CourseSelectBox.component';
import { getCourses, COURSES_PATH } from 'redux/common/_selectors';
import { Course } from 'types';
import { getRecordsUtility } from 'util/formHelpers';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const { filter, records } = ownProps;

  const courses = getRecordsUtility<Course>(
    state,
    COURSES_PATH,
    getCourses,
    filter,
    records
  );

  return {
    courses,
  };
};

export default connect(mapStateToProps)(CourseSelectBoxComponent);
