import React, { useEffect, useState } from 'react';
import { clone, map, isEmpty, defaultTo, last } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { useParams } from 'react-router-dom';
import {
  AzureUploadParameters,
  QuestionGroup,
  emptyQuestionGroup,
} from 'types';
import { QuestionsGroupLayout } from 'components/Common/Questions/QuestionsGroupLayout.component';
import { allQuestionsAnswered } from 'util/formHelpers';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import { getIsProgramReadOnly } from 'redux/programs/_selectors';
import { useSelector } from 'react-redux';

type AssurancesFormProps = {
  isFetching: boolean;
  loadProgramAssuranceQuestions: Function;
  questions: QuestionGroup[];
  saveProgramAssuranceQuestions: Function;
  setCurrentNavAnchor: Function;
};

const AssurancesForm: React.FC<AssurancesFormProps> = (props) => {
  const {
    isFetching,
    loadProgramAssuranceQuestions,
    questions,
    saveProgramAssuranceQuestions,
    setCurrentNavAnchor,
  } = props;

  const [groups, setGroups] = useState<QuestionGroup[]>(clone(questions));

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    loadProgramAssuranceQuestions(programInstanceId);
  }, [loadProgramAssuranceQuestions, programInstanceId]);

  useEffect(() => {
    if (questions) {
      setGroups(clone(questions));
    }
  }, [questions]);

  useEffect(() => {
    setCurrentNavAnchor('assurances');
  }, [setCurrentNavAnchor]);

  const updateGroup = async (group: QuestionGroup) => {
    const newGroups = upsertInPlace<QuestionGroup>(
      groups,
      group,
      'questionGroupId'
    );
    await setGroups(newGroups);
  };

  const [canSave, setCanSave] = useState<boolean>(false);

  /*
    This use effect validates that the end user has checked all checkboxes 
    and uploaded all documents.
   */
  useEffect(() => {
    const theGroup: QuestionGroup = defaultTo(
      last(groups),
      emptyQuestionGroup()
    );
    const theValue = defaultTo(last(theGroup.questions), {
      questionValue: { value: '' },
    });

    const override = theValue.questionValue.value;
    if (!isEmpty(override)) {
      setCanSave(true);
      return;
    }

    setCanSave(allQuestionsAnswered(groups));
  }, [groups, questions]);

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|assurances`,
  };

  const readOnly = useSelector((state) => {
    return getIsProgramReadOnly(state, programInstanceId);
  });

  return (
    <React.Fragment>
      <ScrollToTop />
      <SecureWrap isLocked={readOnly} component="programs">
        <Loading isActive={isFetching} />
        <Paper>
          <Instruction title="Program Assurances Information">
            <p>
              In order to proceed to program approval, please verify all
              assurances by checking each respective box and uploading
              supporting documentation when prompted. If you are unable to
              verify an assurance or provide supporting documentation at this
              time, please provide an explanation in the comment box below.
            </p>
          </Instruction>
        </Paper>
        <Paper>
          {map(groups, (group: QuestionGroup) => {
            return (
              <QuestionsGroupLayout
                key={`${group.questionGroupId}`}
                group={group}
                onChange={updateGroup}
                uploadParams={uploadParams}
              />
            );
          })}
        </Paper>
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            disabled={!canSave}
            errorMessage="If any assurances are left unchecked or supporting documentation is not provided, please provide an explanation."
            saveData={() => {
              return saveProgramAssuranceQuestions(groups, programInstanceId);
            }}
            saveText="Save and Continue"
            saveUrl={`/programs/program-steps/${programInstanceId}`}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};

export default AssurancesForm;
