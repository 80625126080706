export interface ReimbursementRequestTotal {
  finalAwardAmount: number;
  fundPooledAmount: number;
  voucher1: number;
  voucher2: number;
  voucher3: number;
  finalVoucher: number;
}

export const emptyReimbursementRequestTotal = (): ReimbursementRequestTotal => {
  return {
    finalAwardAmount: 0,
    fundPooledAmount: 0,
    voucher1: 0,
    voucher2: 0,
    voucher3: 0,
    finalVoucher: 0,
  };
};
