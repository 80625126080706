import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

import {
  readCTADirectors,
  updateCTADirectors,
  deleteCTADirectors,
} from 'services/cta/administrativecosts.services';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';

const initialState = baseInitialState;

export const loadCTADirectors = createActionThunk(
  'loadCTADirectors',
  (ctaId: number) => {
    return readCTADirectors(ctaId);
  }
);

export const saveCTADirectors = createActionThunk(
  'saveCTADirectors',
  (item: CTAAdministrator) => {
    return updateCTADirectors(item);
  }
);

export const removeCTADirectors = createActionThunk(
  'removeCTADirectors',
  (item: CTAAdministrator) => {
    return deleteCTADirectors(item);
  }
);

export default handleActions<BaseStateObject, CTAAdministrator[]>(
  {
    ...baseLoadActionBuilder<CTAAdministrator[]>(loadCTADirectors),

    ...baseSaveActionBuilder(saveCTADirectors),

    ...baseSaveActionBuilder(removeCTADirectors),
  },
  initialState
);
