import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Button from 'components/Common/FormElements/Button';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import PermissionComponentsSelectBox from 'components/Common/SelectBoxes/PermissionComponentsSelectBox';
import PermissionRoleTypeSelectBox from 'components/Common/SelectBoxes/PermissionRoleTypeSelectBox';
import Table from 'components/Common/Table';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import ToastNotification from 'components/Common/Toast';
import { FlexGroup, FlexRow } from 'layouts';
import { concat, defaultTo, filter, find, map, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StringResources from 'StringResources';
import {
  emptyPermissionComponent,
  emptyPermissionRoleComponent,
  PermissionComponent,
  PermissionRoleComponent,
  PermissionType,
} from 'types';
import { upsertInPlace } from 'util/arrayUtility';

type EditRoleComponentsProps = {
  roleComponents: PermissionRoleComponent[];
  components: PermissionComponent[];
  loadPermissionRoleComponents: Function;
  loadPermissionComponents: Function;
  loadPermissionTypes: Function;
  savePermissionRoleComponent: Function;
};

export const EditRoleComponentsComponent: React.FC<EditRoleComponentsProps> = (
  props
) => {
  const {
    roleComponents,
    components,
    loadPermissionRoleComponents,
    loadPermissionComponents,
    loadPermissionTypes,
    savePermissionRoleComponent,
  } = props;

  const [roleComponentsState, setRoleComponentsState] = useState<
    PermissionRoleComponent[]
  >([]);

  const { roleId }: any = useParams();

  useEffect(() => {
    loadPermissionTypes();
  }, [loadPermissionTypes]);

  useEffect(() => {
    loadPermissionComponents();
  }, [loadPermissionComponents]);

  useEffect(() => {
    loadPermissionRoleComponents();
  }, [loadPermissionRoleComponents]);

  useEffect(() => {
    const selroles = filter(roleComponents, (item: PermissionRoleComponent) => {
      return Number(item.permissionRoleId) === Number(roleId);
    });
    setRoleComponentsState(selroles);
  }, [roleComponents, roleId]);

  /**
   * This function finds a PermissionRoleComponent in the local state and returns it (or an empty one if it doesn't exist in the collection).
   * @param value permissionComponentId of the PermissionRoleComponent we want to get
   */
  const getRoleComponent = (value: number) => {
    return defaultTo(
      find(roleComponents, (item) => {
        return Number(item.permissionComponentId) === Number(value);
      }),
      emptyPermissionRoleComponent()
    );
  };

  const addComponent = (value: Partial<PermissionRoleComponent>) => {
    const component = getRoleComponent(value.permissionComponentId!);
    const exists = map(roleComponentsState, (rcs) => {
      return rcs.permissionComponentId === component.permissionComponentId;
    });

    const emptyOrExists = some([value.permissionComponentId === -1, ...exists]);
    if (emptyOrExists) {
      return;
    }

    const newRoleComponent = {
      ...emptyPermissionRoleComponent(),
      ...value,
      permissionRoleId: roleId,
    };
    setRoleComponentsState(concat(roleComponentsState, [newRoleComponent]));
  };

  const changeType = (
    prc: PermissionRoleComponent,
    change: Partial<PermissionType>
  ) => {
    const newprc: PermissionRoleComponent = {
      ...prc,
      permissionTypeId: change.permissionTypeId!,
    };
    const newarray = upsertInPlace(
      roleComponentsState,
      newprc,
      'permissionComponentId'
    );
    setRoleComponentsState(newarray);
  };

  const handleSave = () => {
    savePermissionRoleComponent(roleId, roleComponentsState).then(() => {
      toast(
        <ToastNotification
          status="success"
          message={StringResources.Success.DataSaved}
        />
      );
    });
  };

  const renderDeleteIcon = (item: PermissionRoleComponent) => {
    return (
      <React.Fragment>
        {!disabled && (
          <ConfirmationDialog
            disabled={disabled}
            tooltip="Delete Component"
            header="Confirm Delete?"
            onConfirm={() => {
              const newValue = filter(roleComponentsState, (rcv) => {
                return (
                  Number(rcv.permissionComponentId) !==
                  Number(item.permissionComponentId)
                );
              });
              setRoleComponentsState(newValue);
            }}
            activateButton={<IconButton tooltip="Delete" name="trashCan" />}
          >
            <p>Are you sure you want to delete this Component permission?.</p>
          </ConfirmationDialog>
        )}
      </React.Fragment>
    );
  };

  const disabled = Number(roleId) === 1;

  const HEADERS: TableColumnProps[] = [
    {
      name: 'Component Name',
      cell: (item) => {
        const name = defaultTo(
          find(components, (com) => {
            return (
              Number(com.permissionComponentId) ===
              Number(item.permissionComponentId)
            );
          }),
          emptyPermissionComponent()
        ).permissionComponentLabelName;
        return name;
      },
      width: '48%',
    },
    {
      name: 'Permission Type',
      cell: (item: PermissionRoleComponent) => {
        return (
          <PermissionRoleTypeSelectBox
            selection={item.permissionTypeId}
            disabled={disabled}
            onChange={(change) => {
              changeType(item, change);
            }}
          />
        );
      },
      width: '48%',
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '6rem',
    },
  ];

  return (
    <Paper>
      <FlexRow>
        <Instruction title="Role Components">
          <p>
            You can add or remove components to roles using the tool below. You
            may also change the level of access.
          </p>
          <br />
          <p>
            NOTE: Changes to the super admin role are not permitted, they are
            automatically given full control access to all new components
          </p>
        </Instruction>
      </FlexRow>
      {!disabled && (
        <FlexRow>
          <PermissionComponentsSelectBox
            onChange={addComponent}
            shouldReset={true}
          />
        </FlexRow>
      )}
      <FlexRow>
        <FlexGroup>
          <Table columns={HEADERS} data={roleComponentsState} />
        </FlexGroup>
      </FlexRow>

      <FlexRow>
        <div className="u-flex-grow-1" />
        <Button
          asLink={false}
          text={'Save Role Components'}
          onClick={handleSave}
          className={`form__btn--submit`}
        />
      </FlexRow>
    </Paper>
  );
};
