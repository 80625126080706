import { ExpenseEditComponent } from './ExpenseEdit.component';
import { connect } from 'react-redux';
import { loadExpenseProgram } from 'redux/grants/grantexpenseprogram';
import { getRecords_ExpenseProgram } from 'redux/grants/_selectors';

const mapStateToProps = (state: any) => {
  const expensePrograms = getRecords_ExpenseProgram(state);

  return {
    expensePrograms,
  };
};

const dispatchProps = {
  loadExpenseProgram,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ExpenseEditComponent);
