import { connect } from 'react-redux';
import { GeneralComponent } from './General.component';
import {
  loadCTASchools,
  saveCTAGeneralAccuracy,
  saveCTASchool,
} from 'redux/cta/ctageneral';
import { getRecords_CTASchool } from 'redux/cta/ctageneral.selectors';
import { getRecords_CTASchoolTerm } from 'redux/common/_selectors';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';

const mapStateToProps = (state: any) => {
  const ctaSchools = getRecords_CTASchool(state);
  const ctaSchoolTerms = getRecords_CTASchoolTerm(state);
  const readOnly = getIsCTAReadOnly(state);

  return {
    ctaSchools,
    ctaSchoolTerms,
    isFetching: false,
    readOnly,
  };
};

const dispatchProps = {
  saveCTAGeneralAccuracy,
  saveCTASchool,
  loadCTASchools,
};

export default connect(mapStateToProps, dispatchProps)(GeneralComponent);
