import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import { GeneralEdit } from 'components/Program/ProgramGeneral/ProgramGeneralConnect';

type GeneralComponentProps = {
  programInstanceId: number;
  isProgramDataFetching: boolean;
};

export const GeneralComponent: React.FC<GeneralComponentProps> = (props) => {
  const { programInstanceId } = props;

  return (
    <BaseLayout>
      <ProgramLayout {...props} programInstanceId={programInstanceId}>
        <SecureWrap isPage={true} component="programs">
          <GeneralEdit />
        </SecureWrap>
      </ProgramLayout>
    </BaseLayout>
  );
};
