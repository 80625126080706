const ONET = {
  API_URL:
    'https://services.onetcenter.org/ws/online/crosswalks/education?client=colorado_cte&keyword=',
  USERNAME: 'colorado_cte',
  PASSWORD: '6467nmy',
};

const LOCAL_DEV_CONFIG = {
  API_URL: 'http://localhost:53611/',
  REDIRECT_URI: 'http://localhost:3000',
  ONET,
};

const DEV_CONFIG = {
  API_URL: 'https://cccs-cte-api-v2-dev.azurewebsites.net/',
  REDIRECT_URI: 'https://cccs-cte-ui-dev.azurewebsites.net',
  ONET,
};

const STAGE_CONFIG = {
  API_URL: 'https://cccs-cte-api-v2-stage.azurewebsites.net/',
  REDIRECT_URI: 'https://cccs-cte-ui-staging.azurewebsites.net',
  ONET,
};

const PRODUCTION_CONFIG = {
  API_URL: 'https://cccs-cte-api-v2.azurewebsites.net/',
  REDIRECT_URI: 'https://cccs-cte-ui.azurewebsites.net',
  ONET,
};

const UAT_CONFIG = {
  API_URL: 'https://cccs-cte-api-v2-uat.azurewebsites.net/',
  REDIRECT_URI: 'https://cccs-cte-ui-uat.azurewebsites.net',
  ONET,
};

export const isUAT = () => {
  return window.location.hostname.includes('-ui-uat');
};

export const isDev = () => {
  return window.location.hostname.includes('-ui-dev');
};

const isStage = () => {
  return window.location.hostname.includes('-staging');
};

export const isProd = () => {
  return !isStage() && process.env.NODE_ENV === 'production';
};

const getEnvironment = () => {
  if (isStage()) {
    return STAGE_CONFIG;
  } else if (isDev()) {
    return DEV_CONFIG;
  } else if (isUAT()) {
    return UAT_CONFIG;
  } else if (process.env.NODE_ENV === 'production') {
    return PRODUCTION_CONFIG;
  }
  return LOCAL_DEV_CONFIG;
};

export const ENVIRONMENT = getEnvironment();
