import React from 'react';
import { useParams } from 'react-router';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import AnnualReflectionWorkflow from 'components/Grants/Applications/AnnualReflection/Workflow';

export const GrantAnnualReflectionApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AnnualReflectionWorkflow grantFiscalYearId={grantFiscalYearId} />
      </GrantsLayout>
    </BaseLayout>
  );
};
