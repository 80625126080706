export const INSTRUCTORS_ADDRESSABLE_ELEMENTS =
  '[INSTRUCTORS_ADDRESSABLE_ELEMENTS]';

export const SET_INSTRUCTORS_ADDRESSABLE_ELEMENTS = `${INSTRUCTORS_ADDRESSABLE_ELEMENTS} SET`;
export const SET_INSTRUCTORS_ADDRESSABLE_ELEMENTS_LOADING = `${INSTRUCTORS_ADDRESSABLE_ELEMENTS} LOADING`;

export const setInstructorsAddressableElements = (payload: any) => {
  return {
    type: SET_INSTRUCTORS_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_InstructorsAddressableElements = (
  payload: boolean
) => {
  return {
    type: SET_INSTRUCTORS_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
