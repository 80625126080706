import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { useParams } from 'react-router';
import { BasicGrantSignatureValidations } from 'validations/BasicGrantSignature.validations';
import { QuestionGroup, Signature } from 'types';
import { QuestionValidation } from 'validations/Question.validations';

type AssuranceSignatureWorkflowProps = {
  applicationId: number;
  assurances: QuestionGroup[];
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadBasicGrantAssurances: Function;
  loadBasicGrantSignatures: Function;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  signatures: Signature[];
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
};

export const AssuranceSignatureWorkflow: React.FC<
  AssuranceSignatureWorkflowProps
> = (props) => {
  const {
    assurances,
    loadBasicGrantAssurances,
    loadBasicGrantSignatures,
    signatures,
  } = props;

  const {
    validate: validateAssurance,
    validationErrors: assuranceValidationErrors,
  } = QuestionValidation();

  const {
    validate: validateSignature,
    validationErrors: signatureValidationErrors,
  } = BasicGrantSignatureValidations();

  const validationErrors = [
    ...assuranceValidationErrors,
    ...signatureValidationErrors,
  ];

  const wrap = {
    ...props,
    validationErrors,
  };

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  useEffect(() => {
    loadBasicGrantAssurances(grantFiscalYearId);
    loadBasicGrantSignatures(grantFiscalYearId);
  }, [loadBasicGrantSignatures, loadBasicGrantAssurances, grantFiscalYearId]);

  useEffect(() => {
    validateAssurance('allQuestionsAnswered', assurances);
    validateSignature('hasAllRequiredSignatures', signatures);
  }, [assurances, signatures, grantFiscalYearId]); //eslint-disable-line

  return <WorkflowSummaryComponent {...wrap} />;
};
