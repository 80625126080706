import { FILTERS_KEY, FILTERS_KEY_GRANT_PARTICIPANT } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, GrantParticipantFilterState, ReduxFilter } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const GRANT_PARTICIPANT_FILTER_PATH = [
  FILTERS_KEY,
  FILTERS_KEY_GRANT_PARTICIPANT,
];

export const getRecords_GrantParticipantFilterState = (
  state: any
): GrantParticipantFilterState => {
  return get(state, GRANT_PARTICIPANT_FILTER_PATH);
};

export const getGrantParticipantFilters = (state: any) => {
  const filterValues: KeyValueType<
    unknown
  > = (getRecords_GrantParticipantFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (item): ReduxFilter<GrantParticipantFilterState> => {
      return {
        filter: {
          property: item,
          values: filterValues[item] !== undefined ? filterValues[item] : '',
        },
        operator: 'and',
      };
    }
  );

  return filters;
};
