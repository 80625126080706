import { connect } from 'react-redux';
import {
  getContact,
  getFilteredUniversalContacts,
  getFilteredRecords_UniversalContact,
  getRecords_UniversalContact,
  UNIVERSAL_CONTACTS_PATH,
} from 'redux/universalcontacts/universalcontacts.selectors';
import { loadUniversalContacts } from 'redux/universalcontacts/universalcontacts';
import ApplicationState from 'types/applicationstate.types';
import { ContactSelectBox } from './ContactSelectBox.component';
import { UniversalContact } from 'types';
import { shouldRefreshReduxState } from 'redux/utility/_exports';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const contact: UniversalContact = getContact(state, ownProps.selectedContact);
  const { filter } = ownProps;

  const unfilteredContacts = getRecords_UniversalContact(state);

  const contacts: UniversalContact[] = filter
    ? getFilteredRecords_UniversalContact(state, filter)
    : getFilteredUniversalContacts(state, ownProps.excludeList);
  const shouldReload = shouldRefreshReduxState(state, UNIVERSAL_CONTACTS_PATH);

  return {
    contact,
    contacts,
    unfilteredContacts,
    shouldReload,
  };
};

const dispatchProps = {
  loadUniversalContacts,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ContactSelectBox);
