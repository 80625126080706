import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import {
  convertRadioToBoolValue,
  convertBoolToRadioValue,
  createRadioOptionsForBool,
} from 'util/formHelpers';
import { SnapshotStudent } from 'types';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Radio from 'components/Common/FormElements/Radio';

export const DemographicInformationS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v } = props;

  const handleBooleanChange = (data: Partial<SnapshotStudent>) => {
    const updated = convertRadioToBoolValue<SnapshotStudent>(data);
    onChange(updated.data, updated.name, updated.val);
  };

  return student ? (
    <React.Fragment>
      <FlexRow>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="iep"
            iconName="info"
            label="IEP"
            tooltip="Student has an active IEP."
          />
          <Radio
            border={true}
            errorMessage={v.getError('iep')}
            name="iep"
            noLabel={true}
            onBlur={() => v.validate('iep', student.iep, student)}
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('iep')}
            value={convertBoolToRadioValue(student.iep)}
          />
        </FlexGroup>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="active504"
            iconName="info"
            label="Active 504"
            tooltip="Student as an active 504"
          />
          <Radio
            border={true}
            errorMessage={v.getError('active504')}
            name="active504"
            noLabel={true}
            onBlur={() => v.validate('active504', student.active504, student)}
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('active504')}
            value={convertBoolToRadioValue(student.active504)}
          />
        </FlexGroup>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="migrantStudent"
            iconName="info"
            label="Migrant Student"
            tooltip="Student was reported to CDE as a migrant student."
            place="right"
          />
          <Radio
            border={true}
            errorMessage={v.getError('migrantStudent')}
            name="migrantStudent"
            noLabel={true}
            onBlur={() =>
              v.validate('migrantStudent', student.migrantStudent, student)
            }
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('migrantStudent')}
            value={convertBoolToRadioValue(student.migrantStudent)}
          />
        </FlexGroup>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="disadvantaged"
            iconName="info"
            label="Economically Disadvantaged"
            tooltip="This field is used to determine if a student is a Pell recipient during the academic year."
            place="right"
          />
          <Radio
            border={true}
            errorMessage={v.getError('disadvantaged')}
            name="disadvantaged"
            noLabel={true}
            onBlur={() =>
              v.validate('disadvantaged', student.disadvantaged, student)
            }
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('disadvantaged')}
            value={convertBoolToRadioValue(student.disadvantaged)}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
