import { isNil, isNumber, every, curry } from 'lodash';

export const isToday = (value: Date) => {
  const today = new Date(Date.now());
  return every([
    value.getDate() === today.getDate(),
    value.getMonth() === today.getMonth(),
    value.getFullYear() === today.getFullYear(),
  ]);
};

export const createDate = (
  future: boolean = true,
  dateNow: Date = new Date(Date.now())
) => {
  const year = dateNow.getFullYear();
  const month = dateNow.getMonth();
  const day = dateNow.getDay();
  const newYear = future ? year + 1 : year - 1;
  const date = new Date(`${newYear}/${month + 1}/${day}`);
  return date;
};

/**
 * Generates a random string.  Neato aye?
 */
export const randomString = () => {
  return Math.random().toString(36).substring(7);
};

/**
 * Determines if a specified amount of time has passed
 * @param lastUpdate last time the data was updated
 * @param waitTime how long should we wait
 */
export const hasUpdatePeriodElapsed = (
  lastUpdate: number,
  waitTime: number | undefined = 43200
): boolean => {
  var timeDiff = Date.now() - lastUpdate;
  return Math.round((timeDiff /= 1000)) > waitTime;
};

/**
 * Checks if the specified id is a number and is greator than 0.
 * @param id the id to check (programInstanceId, universalContactId, etc...)
 */
export const isApplicaionIdValid = (
  id: number | string | undefined
): boolean => {
  if (!id) {
    return false;
  }
  return isNumber(Number(id)) && Number(id) > 0;
};

export const isApplicaionYearValid = (
  id: number | string | undefined
): boolean => {
  return isApplicaionIdValid(id) ? Number(id) > 2000 : false;
};

/**
 * function returns the set of permissions needed to secure and allow access to navigation components.
 * @param value the security value to check
 */
export const getSecureComponentsForNavigation = (value?: string): string[] => {
  if (value) {
    switch (value.toLowerCase()) {
      case 'programs':
        return ['programs', 'programfinance', 'programbanner'];
      case 'datacollection':
        return ['dcdashboard'];
      case 'universalcontacts':
        return ['any'];
      case 'reports':
        return ['any'];
      default:
        return [value.toLowerCase()];
    }
  }
  return [];
};

/**
 *  Converts a string with pixel measurement into a parsed number. By default
 *  this function will grab the fontSize of the document body.
 *  @param size string optional pixel size e.g. "42px"
 *  @returns the current computed fontsize for manual REM calculations
 */
export const pixelSize = (size = getComputedStyle(document.body).fontSize) => {
  return size ? parseFloat(size.split('px')[0]) : 1;
};

export const stillInFiscalYearApplicationWindow = (
  date = new Date(Date.now())
) => {
  return date.getMonth() < 6;
};

/**
 *  @param date Date optional param primarilly for testing
 *  @returns 4 digit year that represents the next fiscal year.
 */
export const getNextFiscalYearApplicationEndYear = (
  date = new Date(Date.now())
) => {
  const inWindow = stillInFiscalYearApplicationWindow(date);
  const fullYearNow = date.getFullYear();
  return inWindow ? fullYearNow + 1 : fullYearNow + 2;
};

export const truncateString = (str: string, numChar: number) => {
  if (!str) return '';
  if (str.length < numChar) return str;
  return `${str.substring(0, numChar)} ...`;
};

export const titleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 *  join :: String -> [String] -> String
 */
export const join = curry((str: string, xs: string[]) => xs.join(str));

/**
 *  concat :: String -> String -> String
 */
export const concat = curry((a: string, b: string) => a.concat(b));

/**
 *  toString :: a -> String
 */
export const toString = (val: any) => String(val);

export const rEither = curry((func1: Function, func2: Function, a: any) => {
  return !isNil(func1(a)) ? func1(a) : func2(a);
});

export const doNothing = (..._: any) => {
  return null;
};
