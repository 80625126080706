import React from 'react';
import { useParams } from 'react-router';
import GrantsLayout from 'layouts/GrantsLayout';
import VoucherWorkflow from 'components/Grants/Applications/Vouchers/Workflow';
import BaseLayout from 'layouts/BaseLayout';

export const GrantVoucherApprovalPage: React.FC = (props) => {
  const {
    grantFiscalYearId: id,
    voucherId: vid,
    voucherType,
  }: any = useParams();
  const grantFiscalYearId = Number(id);
  const voucherId = Number(vid);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <VoucherWorkflow
          grantFiscalYearId={grantFiscalYearId}
          voucherId={voucherId}
          voucherType={voucherType}
          {...props}
        />
      </GrantsLayout>
    </BaseLayout>
  );
};
