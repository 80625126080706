import {
  toJSON,
  postWithAuthOptions,
  catchError,
  toFileBlob,
  getFormattedUrl,
} from 'services';

const DATA_COLLECTION_BASE = ['api', 'DataCollection'];
const TEACHER_TEMPLATE_BASE = [...DATA_COLLECTION_BASE, 'TeacherDownload'];
const ENROLLMENT_TEMPLATE_BASE = [...DATA_COLLECTION_BASE, 'StudentDownload'];
const TEACHER_UPLOAD_BASE = [...DATA_COLLECTION_BASE, 'TeacherUpload'];
const ENROLLMENT_UPLOAD_BASE = [...DATA_COLLECTION_BASE, 'Student', 'false'];
const ENROLLMENT_FOLLOWUP_BASE = [...DATA_COLLECTION_BASE, 'Student', 'true'];

export const readEnrollmentTemplate = async (
  programLevelId: number,
  programInstanceId: number
) => {
  return fetch(
    getFormattedUrl([
      ...ENROLLMENT_TEMPLATE_BASE,
      String(programLevelId),
      String(programInstanceId),
      true /* isTemplate */,
      false /* isFollowup */,
    ]),
    {
      ...postWithAuthOptions(),
    }
  )
    .then(toFileBlob)
    .catch((err) => {
      return catchError('excelmanagement', err, 'readEnrollmentTemplate');
    });
}; // end upload

export const readEnrollmentStudents = async (
  programLevelId: number,
  programInstanceId: number
) => {
  return fetch(
    getFormattedUrl([
      ...ENROLLMENT_TEMPLATE_BASE,
      String(programLevelId),
      String(programInstanceId),
      false /* isTemplate */,
      false /* isFollowup */,
    ]),
    {
      ...postWithAuthOptions(),
    }
  )
    .then(toFileBlob)
    .catch((err) => {
      return catchError('excelmanagement', err, 'readEnrollmentStudents');
    });
}; // end upload

export const readFollowupTemplate = async (
  programLevelId: number,
  programInstanceId: number
) => {
  return fetch(
    getFormattedUrl([
      ...ENROLLMENT_TEMPLATE_BASE,
      String(programLevelId),
      String(programInstanceId),
      false /* isTemplate */,
      true /* isFollowup */,
    ]),
    {
      ...postWithAuthOptions(),
    }
  )
    .then(toFileBlob)
    .catch((err) => {
      return catchError('excelmanagement', err, 'readEnrollmentTemplate');
    });
}; // end upload

export const readTeacherTemplate = async (getdata: boolean) => {
  const url = getFormattedUrl([...TEACHER_TEMPLATE_BASE, String(getdata)]);
  return fetch(url, {
    ...postWithAuthOptions(),
  })
    .then(toFileBlob)
    .then()
    .catch((err) => {
      return catchError('excelmanagement', err, 'readTeacherTemplate');
    });
}; // end upload

export const createEnrollmentRecords = async (file: File) => {
  return fetch(getFormattedUrl(ENROLLMENT_UPLOAD_BASE), {
    ...postWithAuthOptions(),
    body: file,
  })
    .then(toJSON)
    .then()
    .catch((err) => {
      return catchError('excelmanagement', err, 'createEnrollmentRecords');
    });
};
export const createFollowUpRecords = async (file: File) => {
  return fetch(getFormattedUrl(ENROLLMENT_FOLLOWUP_BASE), {
    ...postWithAuthOptions(),
    body: file,
  })
    .then(toJSON)
    .then()
    .catch((err) => {
      return catchError('excelmanagement', err, 'createEnrollmentRecords');
    });
};

export const createTeacherRecords = async (file: File) => {
  return fetch(getFormattedUrl(TEACHER_UPLOAD_BASE), {
    ...postWithAuthOptions(),
    body: file,
  })
    .then(toJSON)
    .then()
    .catch((err) => {
      return catchError('excelmanagement', err, 'createTeacherRecords');
    });
}; // end upload
