import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { startCase, curry, map, unionBy, every, includes } from 'lodash';
import { FlexRow } from 'layouts';
import { EmailTemplate, EmailRecipient, UniversalContact } from 'types';
import { compose, renderData, prop, mergeObjects } from 'util/objectUtility';
import {
  getUserEmail,
  getContactFullName,
} from 'util/universalcontact.utility';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { PARTICIPATION_TYPE_ENUM } from 'constants/participation.enum';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { COLOR_LIGHT_ORANGE } from 'constants/css.const';
import Filters from 'components/Grants/EmailManagement/ManageRecipients/Filters';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import { doNothing } from 'util/global';

type RecipientsProps = {
  emailTemplate: EmailTemplate;
  isLoading: boolean;
  loadSingleEmailTemplate: Function;
  recipients: EmailRecipient[];
  saveEmailRecipients: Function;
  upsertEmailTemplate: Function;
};

export const ManageEmailRecipients: React.FC<RecipientsProps> = (props) => {
  const {
    emailTemplate,
    isLoading,
    loadSingleEmailTemplate,
    recipients,
    saveEmailRecipients,
    upsertEmailTemplate,
  } = props;
  const { recipients: allRecipients, templateName } = emailTemplate;

  // -- dependencies --
  const { templateId: id, fiscalYear: fy } = useParams<any>();
  const templateId = Number(id);
  const fiscalYear = Number(fy);

  // -- local state --
  const [filteredData, setFilteredData] = useState<EmailRecipient[]>([]);
  const [toggleAll, setToggleAll] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // -- component logic --
  const navigateUC = (contact: UniversalContact) => {
    const t = window.open(`/contacts/${contact.universalContactId}`, '_blank');
    t && t.focus();
  };

  const handleDoomToggle = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
    const currentToggleState = toggleAll;
    setToggleAll(!currentToggleState);
    const updated = map(filteredData, (recipient: EmailRecipient) => {
      return { ...recipient, include: !currentToggleState };
    });
    const newRecords = unionBy(updated, allRecipients, 'compoundId');
    const template = mergeObjects<EmailTemplate>(emailTemplate, {
      recipients: newRecords,
    });
    upsertEmailTemplate(template);
  };

  const handleChange = curry(
    ({ compoundId }: EmailRecipient, data: Partial<EmailRecipient>) => {
      if (!isDirty) {
        setIsDirty(true);
      }
      const recipients = map(allRecipients, (recipient: EmailRecipient) => {
        return recipient.compoundId === compoundId
          ? mergeObjects<EmailRecipient>(recipient, data)
          : recipient;
      });
      const template = mergeObjects<EmailTemplate>(emailTemplate, {
        recipients,
      });
      upsertEmailTemplate(template);
    }
  );

  const handleSave = () => {
    return saveEmailRecipients(fiscalYear, emailTemplate)
      .then(() => {
        setIsDirty(false);
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch(doNothing);
  };
  // -- lifecycle --
  useEffect(() => {
    loadSingleEmailTemplate(fiscalYear, templateId);
  }, [fiscalYear, loadSingleEmailTemplate, templateId]);

  useEffect(() => {
    if (recipients.length) {
      setToggleAll(
        every(map(recipients, ({ include }: EmailRecipient) => include))
      );
    } else {
      setToggleAll(false);
    }
  }, [recipients]);

  // -- display logic --
  const renderToggle = (row: EmailRecipient) => {
    return (
      <SecureWrap component="GrantsEmailManagement">
        <Toggle
          name="include"
          onChange={handleChange(row)}
          value={row.include}
          noLabel={true}
        />
      </SecureWrap>
    );
  };

  const renderCircle = (row: EmailRecipient) => {
    return (
      <React.Fragment>
        <Icon
          name={row.emailWasSent ? 'circleFilled' : 'circleOpen'}
          className={
            'consortium-view__status-icon consortium-view__status-icon--active'
          }
        />
      </React.Fragment>
    );
  };

  const renderEditIcon = (row: EmailRecipient) => {
    return (
      <React.Fragment>
        <IconButton
          name="launchOpen"
          tooltip="Go to universal contact"
          asLink={false}
          onClick={() => navigateUC(row.contact)}
        />
      </React.Fragment>
    );
  };

  const renderDirtyMessage = () => {
    return isDirty ? ['You have unsaved changes'] : [];
  };

  /*
   *   getInitial :: string | undefined -> string | undefined
   */
  const getInitial = (ele: string | undefined) => (ele ? ele[0] : undefined);

  /*
   *   getInstituteLevel :: number -> string
   */
  const getInstituteLevel = (id: number) => {
    switch (id) {
      case PROGRAM_LEVEL_ENUM.Secondary:
        return 'Secondary';
      case PROGRAM_LEVEL_ENUM.Postsecondary:
        return 'Postsecondary';
      case PROGRAM_LEVEL_ENUM['Middle School']:
        return 'Middle School';
    }
  };
  /*
   *   InstitutionName :: EmailRecipient -> string
   */
  const InstitutionName = compose(renderData, prop('instituteName'));
  /*
   *   Level :: EmailRecipient -> string
   */
  const Level = compose(getInitial, getInstituteLevel, prop('instituteLevel'));
  /*
   *   Name :: EmailRecipient -> string
   */
  const Name = compose(getContactFullName, prop('contact'));
  /*
   *   Email :: EmailRecipient -> string
   */
  const Email = compose(renderData, getUserEmail, prop('contact'));
  /*
   *   Type :: EmailRecipient -> string
   */
  const Type = compose(renderData, prop('instituteType'));
  /*
   *   Consortium :: EmailRecipient -> string
   */
  const Consortium = compose(renderData, prop('consortiumName'));

  const RECIPIENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Include',
      cell: renderToggle,
      selector: prop('include'),
      sortable: true,
      width: '8%',
    },
    {
      name: 'Institution Name',
      cell: InstitutionName,
      selector: prop('instituteName'),
      sortable: true,
      /* width: '20%', */
    },
    {
      name: 'Level',
      cell: compose(renderData, Level),
      selector: Level,
      sortable: true,
      width: '5%',
    },
    {
      name: 'Sent',
      cell: renderCircle,
      selector: prop('emailWasSent'),
      sortable: true,
      width: '5%',
    },
    {
      name: 'Name',
      cell: compose(renderData, Name),
      selector: Name,
      sortable: true,
    },
    {
      name: 'Email',
      cell: Email,
      selector: Email,
      sortable: true,
    },
    {
      name: 'Type',
      cell: Type,
      selector: Type,
      sortable: true,
      width: '10%',
    },
    {
      name: 'Consortium',
      cell: Consortium,
      selector: Consortium,
      sortable: true,
      width: '20%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      center: true,
      ignoreRowClick: true,
      sortable: false,
      width: '8%',
    },
  ];

  const marginBottom = { marginBottom: '2rem' };

  return (
    <React.Fragment>
      <Loading isActive={isLoading} messageBefore="Loading Recipients..." />
      <ScrollToTop />
      <Paper>
        <h2 className="program__title" style={marginBottom}>
          Manage Recipients: {startCase(templateName)} {fiscalYear}
        </h2>
        <Instruction title="" hideTitle>
          <p>
            Use this page to select the recipients of this email. Use the
            “toggle" to select an individual site or use the “Select all that
            are visible” to choose all on the page. Use the “Search” bar to find
            a specific participant or click on a pre-populated filter to see a
            listing of participants. Check whether a site has previously
            received this email using the “Sent” column; know that if so, emails
            can still be resent. Click on the “Edit” icon to access the
            Universal Contact profile page to review or make changes to the
            email’s recipient. When you are done remember to click the “Save
            Changes” to return back to the manage email page to send.
          </p>
          <br />
          <p>
            <span className="u-color-red">Note</span>: Highlighted rows indicate
            that an eligible recipient did not participate in the prior fiscal
            year.
          </p>
        </Instruction>
      </Paper>
      <Paper>
        <FlexRow>
          <SearchBarFilter
            data={recipients}
            headers={RECIPIENT_HEADERS}
            isFetching={isLoading}
            label="Search for Recipients"
            setFilteredData={setFilteredData}
          />
        </FlexRow>
        <Filters />
        <SecureWrap requireEdit={true} component="GrantsEmailManagement">
          <FlexRow>
            <div className="form__toggle--outline">
              <Toggle
                name="toggleAll"
                label="Include/Exclude All Currently Filtered Participants"
                description={
                  toggleAll
                    ? 'Toggling this will exclude all filtered participants'
                    : 'Toggling this will include all filtered participants'
                }
                value={toggleAll}
                onChange={handleDoomToggle}
              />
            </div>
          </FlexRow>
        </SecureWrap>
      </Paper>
      <SecureWrap component="GrantsEmailManagement">
        <Paper>
          <Table
            columns={RECIPIENT_HEADERS}
            data={filteredData}
            conditionalRowStyles={[
              {
                when: (row: EmailRecipient) => {
                  const badids = [3, 4, 5, 6, 7, 8, 9, 10];
                  const show = every([
                    row.previousParticipationStatus ===
                      PARTICIPATION_TYPE_ENUM.NOT_PARTICIPATING,
                    !includes(badids, templateId),
                  ]);

                  return show;
                },
                style: {
                  backgroundColor: COLOR_LIGHT_ORANGE,
                },
              },
            ]}
          />
        </Paper>
        <Paper>
          <SaveButtons
            cancelText="return to template"
            cancelUrl={`/grants/email-management/email-template/${fiscalYear}/${templateId}`}
            saveText="save changes"
            saveData={handleSave}
            validationErrors={renderDirtyMessage()}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
