import { CTA_KEY, CTA_SUPPORT_PROGRAM_AFFILIATION_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTASupportProgramAffiliation } from 'types';

export const CTA_SUPPORT_PROGRAM_AFFILIATION_PATH = [
  CTA_KEY,
  CTA_SUPPORT_PROGRAM_AFFILIATION_KEY,
];

export const getIsDataFetching_CTASupportProgramAffiliation = (
  state: any
): boolean => {
  return getIsDataFetching(state, CTA_SUPPORT_PROGRAM_AFFILIATION_PATH);
};

export const getRecords_CTASupportProgramAffiliation = (
  state: unknown
): CTASupportProgramAffiliation[] => {
  return getRecords(state, CTA_SUPPORT_PROGRAM_AFFILIATION_PATH);
};
