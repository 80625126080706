import React, { useRef, useState, useEffect } from 'react';
import {
  SnapshotTeacher,
  ActiveTeachersData,
  emptySnapshotTeacher,
  UniversalContact,
} from 'types';
import { FlexGroup, FlexRow } from 'layouts';
import { getFullName, isTeachersLocked } from 'util/datacollection.utility';
import { renderData, compose, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { getShortProgramLevelNameById } from 'util/programInstance.utility';
import { useModal, Modal } from 'react-morphing-modal';
import { every, some } from 'lodash';
import { getUserEmail, getUserPhone } from 'util/universalcontact.utility';
import { readTeacherTemplate } from 'services/datacollection';
import { toast } from 'react-toastify';
import { COLOR_LIGHT_RED, COLOR_LIGHT_GREEN } from 'constants/css.const';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { isSuperAdmin } from 'util/permissionsUtility';
import ActiveTeacherStatus from './ActiveTeacherStatus';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import download from 'js-file-download';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SelectedTeacherRecord from './EditSnapshotTeacher';
import ScrollToTop from 'components/Common/ScrollToTop';
import StringResources from 'StringResources';
import StudentEnrollmentFilter from '../StudentEnrollmentFilter';
import Table from 'components/Common/Table';
import TeacherSelectBoxComponent from 'components/Common/SelectBoxes/AutoSuggestBoxes/TeacherSelectBox';
import TeacherUpload from './TeacherUpload';
import ToastNotification from 'components/Common/Toast';

interface ActiveTeachersProps {
  deactivateSnapshotTeacher: Function;
  isFetching: boolean;
  loadActiveTeachers: Function;
  loggedInUser: UniversalContact;
  teacherData: ActiveTeachersData;
}

export const ActiveTeachersComponent: React.FC<ActiveTeachersProps> = (props) => {
  const {
    deactivateSnapshotTeacher,
    isFetching,
    loadActiveTeachers,
    loggedInUser,
    teacherData,
  } = props;

  const [selectedTeacher, setSelectedTeacher] = useState<SnapshotTeacher>(
    emptySnapshotTeacher()
  );
  const [filteredData, setFilteredData] = useState<SnapshotTeacher[]>([]);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { modalProps, open, close } = useModal({
    onOpen: () => {
      const modalbody = document.getElementsByClassName('RMM__body')[0];
      modalbody.scrollTo(0, 0);
    },
  });

  const getLocked = (row: SnapshotTeacher) => {
    return some([isTeachersLocked(row)]);
  };

  const downloadTeachers = () => {
    readTeacherTemplate(false)
      .then((blob: unknown) => {
        download(blob as Blob, `${teacherData.schoolYear}_active_teacher_data.xlsx`);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  const renderEditIcon = (row: SnapshotTeacher) => {
    return (
      <SecureWrap
        component={['DCTeacherRecords']}
        isLocked={getLocked(row)}
        requireEdit={true}
        noAccessComponent={<React.Fragment>{EMPTY_PLACEHOLDER}</React.Fragment>}
      >
        <IconButton
          tooltip="Edit"
          name="pencil"
          onClick={() => {
            setSelectedTeacher(row);
            open(triggerRef);
          }}
        />
      </SecureWrap>
    );
  };

  const renderDeleteIcon = (row: SnapshotTeacher) => {
    return (
      <SecureWrap
        component={['DCTeacherRecords']}
        isLocked={getLocked(row)}
        requireEdit={true}
        noAccessComponent={<React.Fragment>{EMPTY_PLACEHOLDER}</React.Fragment>}
      >
        <ConfirmationDialog
          tooltip="Delete Teacher Affiliation"
          header="Confirm Delete?"
          onConfirm={() => {
            deactivateSnapshotTeacher(row)
              .then(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Success.Deleted}
                  />
                );
              })
              .catch(() => {
                toast(
                  <ToastNotification
                    status="error"
                    message={StringResources.Errors.DeleteFailed}
                  />
                );
              });
          }}
          activateButton={<IconButton tooltip="Delete" name="trashCan" />}
        >
          <p>
            Are you sure you want to delete this Teacher? Once deleted, data on this teacher
            will need to be re-added using the “Add Program Affiliation” field.
          </p>
        </ConfirmationDialog>
      </SecureWrap>
    );
  };

  const display = (name: keyof SnapshotTeacher) => (row: SnapshotTeacher) => {
    return renderData(prop(name, row));
  };

  const addTeacher = (teacher: UniversalContact) => {
    setSelectedTeacher({
      ...emptySnapshotTeacher(),
      teacherContactId: teacher.universalContactId,
      firstName: teacher.firstName,
      middleName: teacher.middleName,
      lastName: teacher.lastName,
      email: getUserEmail(teacher),
      phone: getUserPhone(teacher),
      credentialNumber: teacher.credentialId,
      credentialExp: teacher.credentialExpirationDate!,
      credentialArea: teacher.credentialArea,
      schoolId: -1,
      dob: teacher.dob!,
    });
  };

  const Level = compose(renderData, getShortProgramLevelNameById, prop('programLevelId'));

  const TEACHER_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: getFullName,
      sortable: true,
      selector: getFullName,
      style: {
        width: '15%',
        flexGrow: 1,
      },
    },
    {
      name: 'CIP',
      cell: display('cipCode'),
      sortable: true,
      selector: prop('cipCode'),
      width: '7%',
    },
    {
      name: 'Level',
      cell: Level,
      sortable: true,
      selector: Level,
      width: '7%',
    },
    {
      name: 'Program Name',
      cell: display('programName'),
      sortable: true,
      selector: prop('programName'),
      style: {
        width: '20%',
        flexGrow: 3,
      },
    },
    {
      name: 'School Name',
      cell: display('programSchoolName'),
      sortable: true,
      selector: prop('programSchoolName'),
      style: {
        width: '20%',
        flexGrow: 2,
      },
    },
    {
      name: 'CDE ID',
      cell: display('cdeId'),
      sortable: true,
      selector: prop('cdeId'),
      width: '10%',
    },
    {
      name: 'Program Base ID',
      cell: display('programBaseId'),
      sortable: true,
      selector: prop('programBaseId'),
      width: '7%',
    },
    {
      name: 'Program Instance ID',
      cell: display('programInstanceId'),
      sortable: true,
      selector: prop('programInstanceId'),
      width: '7%',
    },
    {
      name: 'State',
      cell: display('programState'),
      sortable: true,
      selector: prop('programState'),
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      ignoreRowClick: true,
      width: '5rem',
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      ignoreRowClick: true,
      width: '6rem',
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: SnapshotTeacher) => {
        return row.isComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_GREEN,
      },
    },
    {
      when: (row: SnapshotTeacher) => {
        return !row.isOpen && !row.isComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_RED,
      },
    },
  ];

  useEffect(() => {
    loadActiveTeachers();
  }, [loadActiveTeachers]);

  useEffect(() => {
    if (prop('teachers', teacherData)) {
      setFilteredData(teacherData.teachers);
    }
  }, [teacherData]);

  useEffect(() => {
    if (every([selectedTeacher, selectedTeacher.teacherContactId > 0])) {
      open(triggerRef);
    }
  }, [selectedTeacher]); // eslint-disable-line

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <ScrollToTop />
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Active Teachers Academic Year: ${teacherData.schoolYear - 1} - ${
                teacherData.schoolYear
              }`}
              isPageHeader={true}
            >
              <p className="u-margin-bottom">
                You are required to report information on CTE instructors who will be actively
                teaching during the period September 15th through September 1st of the given
                academic year. Follow the prompts below to identify instructors on whom you
                must report and to upload information on their status.
              </p>
            </Instruction>
          }
        />
      </Paper>
      {!isSuperAdmin(loggedInUser) && (
        <SecureWrap
          component={['DCCertification']}
          isLocked={some([teacherData.complete, teacherData.locked])}
        >
          <ActiveTeacherStatus complete={teacherData.complete} />
        </SecureWrap>
      )}
      <TeacherUpload />

      <Paper>
        <Instruction title="Viewing Active Teachers">
          <p>
            Use this ‘Active Teacher Contact List’ button to review information on active
            teachers and their programs in which they teach currently in the system and the
            ‘Download Active Teachers to Excel’ button to download an Excel file listing all
            currently active teachers.
          </p>
        </Instruction>

        <FlexRow>
          <FlexGroup>
            <Button
              className="form__btn--cancel"
              disabled={true}
              text="Active Teacher Contact List"
            />
          </FlexGroup>
          <FlexGroup>
            <Button
              className="form__btn--cancel"
              onClick={() => {
                downloadTeachers();
              }}
              text={'Download active teachers to Excel'}
            />
          </FlexGroup>
        </FlexRow>
      </Paper>

      <Paper>
        <Instruction title="Add a New Program Affiliation">
          <p className="u-margin-bottom">
            If you do not see the correct instructor-program combination in the Active Teachers
            / Faculty list below, you can affiliate an instructor with a program here. To do
            so, use the search bar to find the name of the instructor for whom you would like
            to create an affiliation. Click on their name and follow the instructions on the
            pop-up screen to make and save your affiliation.
          </p>
          <p>
            If you cannot find the instructor using the search bar you will need to create a
            new record in the Universal Contacts. To do so, click on the ‘New Contact’ button
            and complete and save the information requested.
          </p>
        </Instruction>
        <SecureWrap
          isLocked={some([teacherData.complete, teacherData.locked])}
          component="DCTeacherRecords"
        >
          <FlexRow>
            <TeacherSelectBoxComponent onChange={addTeacher} />
          </FlexRow>
        </SecureWrap>
        <FlexRow>
          <div className="u-flex-grow-1" />
          <button
            ref={triggerRef}
            onClick={() => window.open(`/contacts/create-new-user`, '_blank')}
            className="button--outline"
          >
            New Contact
          </button>
        </FlexRow>
      </Paper>

      <Paper>
        <Instruction title="View & Edit Active Teachers">
          <p>
            Enter an instructor’s name in the search bar to review information previously
            entered about them. Note that instructors affiliated with multiple programs will
            appear in multiple rows. Rows highlighted in green show completed records and rows
            highlighted in red show records that have been closed. You can use the ‘School’
            dropdown to refine results. Click on the edit icon to update and save the
            information currently on file. Click on the trash icon to remove an instructor
            listing for the current year (note that this will not affect the information
            contained in the Universal Contacts database).
          </p>
        </Instruction>
        <FlexRow>
          <SearchBarFilter
            data={teacherData.teachers}
            isFetching={isFetching}
            label="Find teacher"
            setFilteredData={setFilteredData}
            headers={TEACHER_HEADERS}
            shouldReset={true}
          />
        </FlexRow>
        <StudentEnrollmentFilter />
        <Table
          columns={TEACHER_HEADERS}
          data={filteredData}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Paper>
      <Modal {...modalProps}>
        <SelectedTeacherRecord
          close={close}
          selectedTeacher={selectedTeacher}
          teacherData={teacherData}
        />
      </Modal>
    </React.Fragment>
  );
};
