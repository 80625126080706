import { PathwaySummaryComponent } from 'components/Program/PathwayCourses/PathwaySummary/PathwaySummary.component';

import { connect } from 'react-redux';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(PathwaySummaryComponent);
