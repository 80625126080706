export interface ProgramArticulation {
  programArticulationId: number;
  programInstanceId: number;
  partnerProgramInstanceId: number;
  contactId: number;
  schoolId: number;
  type: string;
  year: string;
  filePath: string;
  modifiedBy: number;
  hasHighAdvisoryCommittee: boolean;
  isCurrent: boolean;
}

export const emptyArticulation = (): ProgramArticulation => {
  return {
    programArticulationId: -1,
    programInstanceId: -1,
    partnerProgramInstanceId: -1,
    schoolId: -1,
    filePath: '',
    hasHighAdvisoryCommittee: false,
    isCurrent: false,
    type: '',
    year: '',
    contactId: -1,
    modifiedBy: -1,
  };
};
