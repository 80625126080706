import {
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_STUDENT_ENROLLMENT_KEY,
} from 'redux/keys';
import {
  getIsDataFetching,
  shouldRefreshReduxState,
  getLastUpdated,
  getFirstRecord,
} from 'redux/utility/baseSelectors';
import { StudentEnrollmentData, emptyStudentEnrollmentData } from 'types';
import { getRecords_SnapshotStudentFilterState } from 'redux/filters/_selectors';
import { filter } from 'lodash';
import { isApplicaionIdValid } from 'util/global';

export const DATA_COLLECTION_ENROLLMENT_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_STUDENT_ENROLLMENT_KEY,
];

export const getIsDataFetching_StudentEnrollment = (state: any): boolean => {
  return getIsDataFetching(state, DATA_COLLECTION_ENROLLMENT_PATH);
};

export const getLastUpdated_StudentEnrollment = (state: any): number => {
  return getLastUpdated(state, DATA_COLLECTION_ENROLLMENT_PATH);
};

export const shouldRefreshReduxState_StudentEnrollment = (
  state: any
): boolean => {
  return shouldRefreshReduxState<StudentEnrollmentData>(
    state,
    DATA_COLLECTION_ENROLLMENT_PATH,
    300
  );
};

export const getFirstRecord_StudentEnrollment = (state: any) => {
  return getFirstRecord<StudentEnrollmentData>(
    state,
    DATA_COLLECTION_ENROLLMENT_PATH,
    emptyStudentEnrollmentData()
  );
};

export const getAdvancedFilteredRecords_StudentEnrollment = (
  state: any
): StudentEnrollmentData => {
  const filterData = getRecords_SnapshotStudentFilterState(state);
  const sed = getFirstRecord_StudentEnrollment(state);

  const records = filter(sed.enrollments, (item) => {
    return isApplicaionIdValid(filterData.schoolId)
      ? filterData.schoolId === item.schoolId
      : true;
  });
  return { ...sed, enrollments: records };
};
