import { VoucherDetailComponent } from './VoucherDetail.component';
import { connect } from 'react-redux';
import {
  getIsDataFetching_Vouchers,
  getSingleVoucherByType,
} from 'redux/grants/_selectors';
import { loadSingleVoucherByType, saveVoucher } from 'redux/grants/vouchers';
import { getVoucherLockedStatus } from 'util/applicationStateUtility';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getIsDataFetching_ActualReimbursement,
  getIsDataFetching_ReimbursementSummary,
} from 'redux/grants/_selectors';
import { some } from 'lodash';
import { loadGrantVoucherDetailNav } from 'redux/nav/voucherdetail';

const mapStateToProps = (state: any, ownProps: any) => {
  const { voucherType } = ownProps;
  const voucher = getSingleVoucherByType(state, voucherType);

  const isLocked = getVoucherLockedStatus(state, voucherType);

  const isLoading = some([
    getIsDataFetching_Vouchers(state),
    getIsDataFetching_ActualReimbursement(state),
    getIsDataFetching_ReimbursementSummary(state),
  ]);

  return {
    isLoading,
    isLocked,
    voucher,
  };
};

const dispatchProps = {
  loadGrantVoucherDetailNav,
  loadSingleVoucherByType,
  saveVoucher,
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(VoucherDetailComponent);
