import React from 'react';
import { Link } from 'react-router-dom';
import { WorkflowStep, WorkflowUser } from 'types/workflow';
import { map } from 'lodash';
import { FlexGroup } from 'layouts';
import { renderData } from 'util/objectUtility';
import Icon from 'components/Common/Icons';

export type WorkflowCardProps = {
  programInstanceId?: number;
  workflowItem: WorkflowStep;
  isSummary: boolean;
  isOdd?: boolean;
  isFirst?: boolean;
  flat?: boolean;
};

export const WorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  const {
    programInstanceId,
    workflowItem,
    isSummary,
    isFirst,
    flat = false,
  } = props;
  const flatStyle = `${flat ? 'workflow-card--flat' : ''}`;
  const getCardStyle = () => {
    if (isFirst) {
      return flat
        ? `workflow-card workflow-card--blue ${flatStyle}`
        : `workflow-card workflow-card--blue`;
    }
    return flat ? `workflow-card ${flatStyle}` : 'workflow-card';
  };

  return (
    <React.Fragment>
      {workflowItem && (
        <div className={getCardStyle()}>
          {isSummary && (
            <Link
              to={`/programs/program-steps/${programInstanceId}`}
              className="workflow-card__cta"
            >
              <p className="workflow-card__view-history">
                View Workflow History
              </p>
              <Icon name="clockHistory" className="workflow-card__icon" />
            </Link>
          )}
          <h2 className="workflow-card__title">{workflowItem.title}</h2>
          <div className="workflow-card__info">
            <div className="workflow-card__label-group workflow-card__label-group--date">
              <label className="workflow-card__label">Date</label>
              <p className="workflow-card__value">
                {new Date(
                  Date.parse(workflowItem.date.toString())
                ).toLocaleDateString()}
              </p>
            </div>

            {isFirst ? (
              <div className="workflow-card__label-group">
                <label className="workflow-card__label">Assigned To</label>
                <div className="workflow-card__value">
                  <FlexGroup>
                    {map(
                      workflowItem.approvers,
                      (user: WorkflowUser, index: number) => {
                        return user.name.trim().length > 0 ? (
                          <p key={index}>{renderData(user.name)}</p>
                        ) : (
                          <a key={index} href={`mailto:${user.email}`}>
                            {renderData(user.name)}
                          </a>
                        );
                      }
                    )}
                  </FlexGroup>
                </div>
              </div>
            ) : (
              <div className="workflow-card__label-group">
                <label className="workflow-card__label">Modified By</label>
                <p className="workflow-card__value">
                  {workflowItem.modifiedBy.name.trim().length > 0 ? (
                    <a href={`mailto:${workflowItem.modifiedBy.email}`}>
                      {renderData(workflowItem.modifiedBy.name)}
                    </a>
                  ) : (
                    <React.Fragment>
                      {renderData(workflowItem.modifiedBy.name)}
                    </React.Fragment>
                  )}
                </p>
              </div>
            )}
          </div>
          {workflowItem.comment && (
            <div className="workflow-card__label-group--comments">
              <label className="workflow-card__label">
                {`${workflowItem.modifiedBy.name} `}Comments
              </label>
              <p className="workflow-card__comments">{workflowItem.comment}</p>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
