import { connect } from 'react-redux';
import {
  CteScheduleComponent,
  CteScheduleProps,
} from './CteSchedule.component';
import {
  loadCTECourses,
  saveCTECourses,
} from 'redux/cta/InstructionalCosts/ctactecourses';
import { getRecords } from 'redux/utility/_exports';
import { CTA_CTE_COURSE_PATH } from 'redux/cta/_paths';
import { CtaInstructorCourse } from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';
import { getRecords_CTASchool } from 'redux/cta/_selectors';

const mapStateToProps = (state: any, ownProps: Partial<CteScheduleProps>) => {
  const courses = getRecords<CtaInstructorCourse>(state, CTA_CTE_COURSE_PATH);
  const schools = getRecords_CTASchool(state);
  return {
    courses,
    schools,
  };
};

const dispatchProps = {
  loadCTECourses,
  saveCTECourses,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CteScheduleComponent);
