import React from 'react';
import { HamburgerMenuItemDetail } from 'types';
import { Link } from 'react-router-dom';
import Icon from 'components/Common/Icons';

export const HamburgerMenuItem: React.FC<HamburgerMenuItemDetail> = (props) => {
  const { details, name, onChange } = props;
  return (
    <Link
      key={name}
      to={details.link}
      onClick={() => {
        onChange && onChange();
      }}
      className={`menu-item__row t-${name}`}
    >
      <Icon
        name={details.icon}
        className={'menu-item__icon'}
        style={{ zIndex: 1010 }}
      />
      <span className="menu-item__title">{details.title}</span>
    </Link>
  );
};
