import React from 'react';
import Button from '../FormElements/Button';
import { Dialog } from '@rmwc/dialog';

interface DirtyDataWarningProps {
  open: boolean;
  onCancel: Function;
  onClose: Function;
  onConfirm: Function;
}
export const DirtyDataWarning: React.FC<DirtyDataWarningProps> = ({
  open,
  onCancel,
  onClose,
  onConfirm,
}) => {
  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        <div className="dialog">
          <h2 className="dialog__title">Discard Changes?</h2>
          <div className="dialog__children">
            <p className="u-error">
              You have unsaved changes, would you like to discard these changes
              or save them?
            </p>
          </div>
          <div className="dialog__btn-container">
            <Button
              onClick={() => onCancel()}
              className="form__btn--cancel"
              text="Discard changes"
            />
            <Button
              onClick={() => onConfirm()}
              className="confirm-button form__btn--submit"
              text="Save changes"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
