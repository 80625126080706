import React, { useEffect } from 'react';

/*
  Budget Hydration
  This component is used to refresh / hydrate data for grant data types in the background
*/

type DataHydratorProps = {
  shouldRefreshUniversalTeachers: boolean;
  loadUniversalTeachers: Function;
};

export const DataCollectionHydrator: React.FC<DataHydratorProps> = (
  props: any
) => {
  /* Universal Teachers */
  const { shouldRefreshUniversalTeachers, loadUniversalTeachers } = props;
  useEffect(() => {
    shouldRefreshUniversalTeachers && loadUniversalTeachers();
  }, [shouldRefreshUniversalTeachers, loadUniversalTeachers]);

  return <div className="hydration" />;
};
