import React from 'react';
import { prop } from 'util/objectUtility';
import { FlexRow } from 'layouts';
import { GraphData } from 'types';
import { map, startCase } from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import { CHART_COLOR } from 'constants/css.const';
import Paper from 'components/Common/Paper';

type GraphReportProps = {
  data: any;
  graphData: GraphData;
};

export const GraphReport: React.FC<GraphReportProps> = ({
  data,
  graphData,
}) => {
  const graphValues = () => {
    return data.map((data: any) => {
      return [...graphData.schema, graphData.xAxis].reduce((acc, curr) => {
        return {
          ...acc,
          [curr]:
            curr === graphData.xAxis
              ? prop(curr, data)
              : Number((Number(prop(curr, data)) * 100).toFixed(2)),
          //[curr]:prop(curr, data),
        };
      }, {});
    });
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#007d8990',
            padding: '1rem',
            borderRadius: '4px',
          }}
        >
          <p>
            <strong>{`${startCase(graphData.xAxis)}: ${label}`}</strong>
          </p>
          {map(payload, (item, index) => {
            return (
              <p
                key={index}
                className="label"
              >{`${item.dataKey} : ${item.value} %`}</p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Paper>
        <div style={{ padding: '1rem' }} />
        <FlexRow>
          <LineChart width={1280} height={720} data={graphValues()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={graphData.xAxis} height={64}>
              <Label position="insideBottom">
                {startCase(graphData.xAxis)}
              </Label>
            </XAxis>
            <YAxis
              label={{
                value: `${graphData.yAxis.label} %`,
                angle: -90,
                position: 'insideLeft',
              }}
              type="number"
              domain={[0, graphData.yAxis.max * 100]}
            />
            <Tooltip
              contentStyle={{ fontSize: '2rem', fontWeight: 600 }}
              content={<CustomTooltip />}
            />
            <Legend
              verticalAlign="top"
              height={32}
              wrapperStyle={{ fontSize: '2rem', fontWeight: 600 }}
            />
            {map(graphData.schema, (item, index: number) => {
              const val = `TYPE_${index % 10}`;
              return (
                <Line
                  type="monotone"
                  key={index}
                  dataKey={item}
                  //@ts-ignoreline
                  stroke={CHART_COLOR[val]}
                />
              );
            })}
          </LineChart>
        </FlexRow>
      </Paper>
    </>
  );
};
