import {
  GRANTS_KEY,
  GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES,
} from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';
import { CertificationOfExemptionSignature } from 'types';

export const GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES_PATH = [
  GRANTS_KEY,
  GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES,
];

export const getIsDataFetching_CertificationOfExemptionSignatures = (
  state: unknown
): boolean => {
  return getIsDataFetching(
    state,
    GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES_PATH
  );
};

export const getRecords_CertificationOfExemptionSignatures = (
  state: unknown
): CertificationOfExemptionSignature[] => {
  return getRecords(state, GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES_PATH);
};

export const shouldRefreshReduxState_CertificationOfExemptionSignatures = (
  state: unknown
): boolean => {
  return shouldRefreshReduxState<CertificationOfExemptionSignature[]>(
    state,
    GRANTS_CERTIFICATE_OF_EXEMPTION_SIGNATURES_PATH
  );
};
