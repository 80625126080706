import React from 'react';
import { formatMoney, splitMoney } from 'util/moneyHelpers';
import { SpendingCategory } from 'types';

type FundsRemainingProps = {
  funds: number;
  label: string;
  offset?: number;
  spendingCategory?: SpendingCategory;
};

export const FundsRemaining: React.FC<FundsRemainingProps> = (props) => {
  const { label, funds } = props;

  const getValueStyle = () => {
    return funds >= 0.0
      ? 'funds-remaining__amount'
      : 'funds-remaining__amount funds-remaining__amount--negative';
  };

  const getFunds = (funds: number) => {
    const formatted = formatMoney(funds);
    return splitMoney(formatted);
  };

  return (
    <React.Fragment>
      <div className="funds-remaining">
        <p className="funds-remaining__label">{label}</p>
        <div className={getValueStyle()}>
          <p className="funds-remaining__dollars">{getFunds(funds).dollars}</p>
          <p className="funds-remaining__cents">{getFunds(funds).cents}</p>
        </div>
      </div>
    </React.Fragment>
  );
};
