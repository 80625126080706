export interface FiscalAgent {
  districtOrInstituteId: number;
  duns: string;
  fiscalAgentId: string;
  institutionCode: string;
  name: string;
  primaryContactId: number;
  type: string;
  vendorCode: string;
}

export const emptyFiscalAgent = (): FiscalAgent => {
  return {
    name: '',
    districtOrInstituteId: -1,
    duns: '',
    fiscalAgentId: '',
    institutionCode: '',
    primaryContactId: -1,
    type: '',
    vendorCode: '',
  };
};
