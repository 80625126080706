import { IntentToParticipateMember } from 'types';
import { isEqual, some } from 'lodash';

export const participationChanged = (member: IntentToParticipateMember) => {
  return some([
    participationStatusChanged(member),
    member.consortiumId !== member.previousConsortiumId,
  ]);
};

export const participationStatusChanged = (
  member: IntentToParticipateMember
) => {
  return !isEqual(
    member.previousParticipationStatus,
    member.newParticipationStatus
  );
};
