import { connect } from 'react-redux';
import { DataCollectionHydrator } from './DataCollectionHydrator';
import { shouldRefreshReduxState_UniversalTeachers } from 'redux/datacollection/universalteachers.selectors';
import { loadUniversalTeachers } from 'redux/datacollection/universalteachers';

export const mapStateToProps = (state: any) => {
  const shouldRefreshUniversalTeachers = shouldRefreshReduxState_UniversalTeachers(
    state
  );
  return {
    shouldRefreshUniversalTeachers,
  };
};

const dispatchProps = {
  loadUniversalTeachers,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(DataCollectionHydrator);
