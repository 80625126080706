import { connect } from 'react-redux';
import { StudentEnrollmentFilterComponent } from './StudentEnrollmentFilter.component';
import {
  setSnapshotStudentValue,
  resetSnapshotStudentValue,
} from 'redux/filters/snapshotstudentfilter';
import { getRecords_SnapshotStudentFilterState } from 'redux/filters/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import {
  getRecords_Schools,
  getRecords_Institutes,
} from 'redux/common/_selectors';
import { unionBy } from 'lodash';

export const mapStateToProps = (state: any, ownProps: any) => {
  const adminschools = unionBy(
    getRecords_Schools(state),
    getRecords_Institutes(state),
    'schoolId'
  );
  const filter = getRecords_SnapshotStudentFilterState(state);

  const loggedInUser = getLoggedinUser(state);
  return { filter, loggedInUser, adminschools };
};

const dispatchToProps = {
  setSnapshotStudentValue,
  resetSnapshotStudentValue,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(StudentEnrollmentFilterComponent);
