import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { PerkinsGrantFilterState } from 'types';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import Button from 'components/Common/FormElements/Button';
import Paper from 'components/Common/Paper';
import ToggleButton from 'components/Common/FormElements/ToggleButton';

type GrantApplicationFilterComponentProps = {
  filter: PerkinsGrantFilterState;
  setPerkinsGrantFilterValue: Function;
  resetPerkinsGrantFilterValue: Function;
  clearPerkinsGrantFilters: Function;
};

export const GrantApplicationFilterComponent: React.FC<
  GrantApplicationFilterComponentProps
> = (props) => {
  const {
    filter,
    setPerkinsGrantFilterValue,
    clearPerkinsGrantFilters,
  } = props;

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Postsecondary"
          value={filter.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary}
          onChange={() => {
            setPerkinsGrantFilterValue({
              programLevelId:
                filter.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary
                  ? undefined
                  : PROGRAM_LEVEL_ENUM.Postsecondary,
            });
          }}
        />
        <ToggleButton
          text="Secondary"
          value={filter.programLevelId === PROGRAM_LEVEL_ENUM.Secondary}
          onChange={() => {
            setPerkinsGrantFilterValue({
              programLevelId:
                filter.programLevelId === PROGRAM_LEVEL_ENUM.Secondary
                  ? undefined
                  : PROGRAM_LEVEL_ENUM.Secondary,
            });
          }}
        />
      </div>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Active"
          value={filter.status === true}
          onChange={() => {
            setPerkinsGrantFilterValue({
              status: filter.status ? undefined : true,
            });
          }}
        />
        <ToggleButton
          text="Inactive"
          value={filter.status === false}
          onChange={() => {
            setPerkinsGrantFilterValue({
              status: filter.status === false ? undefined : false,
            });
          }}
        />
      </div>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Needs Review"
          value={filter.needsReview === true}
          onChange={() => {
            setPerkinsGrantFilterValue({
              needsReview: filter.needsReview ? undefined : true,
            });
          }}
        />
      </div>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Substantially Submitted"
          value={filter.substantiallySubmitted === true}
          onChange={() => {
            setPerkinsGrantFilterValue({
              substantiallySubmitted: filter.substantiallySubmitted
                ? undefined
                : true,
            });
          }}
        />
      </div>
      <FlexGroup>
        <Button
          onClick={clearPerkinsGrantFilters}
          className="button--outline"
          text="Reset Filters"
        />
      </FlexGroup>
    </Paper>
  );
};
