import { connect } from 'react-redux';
import {
  getRecords_FiscalAgents,
  FISCAL_AGENTS_PATH,
} from 'redux/grants/fiscalagents.selectors';
import { OptionMap, FiscalAgent, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { filter, selection, label, onChange, records: baseRecords } = ownProps;

  const records = getRecordsUtility<FiscalAgent>(
    state,
    FISCAL_AGENTS_PATH,
    getRecords_FiscalAgents,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<FiscalAgent> = {
    key: 'fiscalAgentId',
    label: 'name',
    value: 'fiscalAgentId',
  };

  return {
    label: label ? label : 'Fiscal Agent',
    name: 'fiscalAgentId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
