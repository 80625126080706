import 'react-morphing-modal/dist/ReactMorphingModal.min.css';
import Button from 'components/Common/FormElements/Button';
import EditParticipantComponent from './EditParticipant';
import Filters from './Filters';
import HotButton from 'components/Common/HotButton';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useEffect, useRef, useState } from 'react';
import ScrollToTop from 'components/Common/ScrollToTop';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import { ButtonContainer, FlexRow, FlexGroup } from 'layouts';
import { COLOR_LIGHT_ORANGE } from 'constants/css.const';
import { Dialog } from '@rmwc/dialog';
import { GrantParticipationMember, FiscalYear, UniversalContact } from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose } from 'redux';
import { createGrants } from 'services/grants/creategrants.services';
import { getNextFiscalYearApplicationEndYear } from 'util/global';
import { prop, renderData } from 'util/objectUtility';
import { some } from 'lodash';
import { toast } from 'react-toastify';
import { upsertGrantParticipants } from 'redux/grants/grantparticipant';
import { useDispatch } from 'react-redux';
import { useModal, Modal } from 'react-morphing-modal';
import { useParams, useHistory } from 'react-router';
import { isSuperAdmin } from 'util/permissionsUtility';

type ParticipationDashboardProps = {
  isFetching?: boolean;
  loadGrantParticipants: Function;
  participants: GrantParticipationMember[];
  currentStatus: boolean;
  currentFiscalYear: FiscalYear;
  loggedInUser: UniversalContact;
};

export const ParticipationDashboardComponent: React.FC<ParticipationDashboardProps> = (
  props
) => {
  const {
    isFetching = false,
    loadGrantParticipants,
    participants,
    currentStatus,
    currentFiscalYear,
    loggedInUser,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { modalProps, open, close } = useModal();
  const { fiscalYear: fy } = useParams<any>();
  const fiscalYear = Number(fy);
  const { year: currentFy } = currentFiscalYear;
  const dispatch = useDispatch();
  const history = useHistory();

  // --[ local state ]---------------------------------------------------------
  const mountedRef = useRef(true);
  const [filteredData, setFilteredData] = useState<GrantParticipationMember[]>(
    []
  );
  const [participantKey, setParticipantKey] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  const navigateToPortal = () => history.push('/grants');
  const isActive = (year: number) => fiscalYear === year;
  const navigate = (year: number) => {
    history.push(`/grants/participation/${year}`);
  };
  const toggleParticipating = (item: GrantParticipationMember) => {
    dispatch(
      upsertGrantParticipants({
        ...item,
        isParticipating: !item.isParticipating,
      })
    );
  };
  const handleClick = ({ key = '' }: GrantParticipationMember) => {
    setParticipantKey(key);
  };
  const handleSave = () => {
    setIsLoading(true);
    return createGrants(Number(fiscalYear))
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message="Successfully created grants."
          />
        );
      })
      .catch((err) => {
        toast(<ToastNotification status="error" message={err} />);
      })
      .finally(() => mountedRef.current && setOpenDialog(false))
      .finally(() => mountedRef.current && setIsLoading(false));
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    loadGrantParticipants(fiscalYear).catch((err: any) => console.error(err));
  }, [loadGrantParticipants, fiscalYear]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // --[ display logic ]-------------------------------------------------------
  const renderEditIcon = (row: GrantParticipationMember) => {
    return (
      <React.Fragment>
        <IconButton
          name="pencil"
          tooltip="Edit Consortium Participant Details"
          asLink={false}
          modalClick={open}
          onClick={() => handleClick(row)}
        />
      </React.Fragment>
    );
  };

  const renderGuidIcon = (row: GrantParticipationMember) => {
    return (
      <SecureWrap component="grantsparticipants" requireAdmin>
        {row.guid && (
          <IconButton
            name="pencil"
            tooltip="Update Consortium Intent to Participate"
            asLink={true}
            to={`/grants/participation/${
              ParticipationType(row) === 'Fiscal Agent'
                ? 'fiscalagent'
                : 'member'
            }/${prop('guid', row)}`}
          />
        )}
      </SecureWrap>
    );
  };

  const renderToggle = (row: GrantParticipationMember) => {
    return (
      <SecureWrap component="grantsparticipants">
        <Toggle
          name={'isParticipating'}
          onChange={() => {
            toggleParticipating(row);
          }}
          value={row.isParticipating ? row.isParticipating : false}
          noLabel={true}
        />
      </SecureWrap>
    );
  };

  const renderCircle = (row: GrantParticipationMember) => {
    return (
      <React.Fragment>
        <Icon
          name={row.isExempt ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const getInitial = (ele: string | undefined) => (ele ? ele[0] : undefined);

  const Name = compose(renderData, prop('name'));
  const Level = compose(renderData, getInitial, prop('level'));
  const Type = compose(renderData, prop('type'));
  const ParticipationType = (row: GrantParticipationMember) => {
    return row.isStandalone
      ? 'Standalone'
      : row.isFiscalAgent
      ? 'Fiscal Agent'
      : 'Consortium';
  };
  const Consortium = compose(renderData, prop('consortiumName'));

  const PARTICIPATION_HEADERS: TableColumnProps[] = [
    {
      name: 'Participating',
      cell: renderToggle,
      selector: prop('isParticipating'),
      sortable: true,
      width: '8%',
    },
    {
      name: 'Name',
      cell: Name,
      selector: Name,
      sortable: true,
      width: '20%',
    },
    {
      name: 'Level',
      cell: Level,
      selector: Level,
      sortable: true,
      center: true,
      width: '10%',
    },
    {
      name: 'Type',
      cell: Type,
      selector: Type,
      sortable: true,
      width: '10%',
    },
    {
      name: 'Participation Type',
      cell: ParticipationType,
      selector: ParticipationType,
      sortable: true,
    },
    {
      name: 'Exempt',
      cell: renderCircle,
      selector: prop('isExempt'),
      sortable: true,
      center: true,
      width: '10%',
    },
    {
      name: 'Consortium',
      cell: Consortium,
      selector: Consortium,
      sortable: true,
      width: '20%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      center: true,
      ignoreRowClick: true,
      width: '8%',
    },
  ];

  const getHeaders = () => {
    return [
      ...PARTICIPATION_HEADERS,
      ...(isSuperAdmin(loggedInUser)
        ? [
            {
              name: 'Modify Intent',
              cell: renderGuidIcon,
              center: true,
              ignoreRowClick: true,
              width: '8%',
            },
          ]
        : []),
    ];
  };

  const conditionalRowStyles = [
    {
      when: (row: GrantParticipationMember) => row.participationChange === true,
      style: {
        backgroundColor: COLOR_LIGHT_ORANGE,
      },
    },
  ];

  return (
    <React.Fragment>
      <Loading
        isActive={some([isFetching, isLoading])}
        messageBefore="Loading Participants"
      />
      <ScrollToTop />
      <Paper>
        <Instruction title="Participation Management">
          <p className="u-margin-bottom-medium">
            This page enables you to view responses received from the Intent to
            Participate process. Use 'Search For Participants' or the filters to
            find a specific participant or group of participants. You may review
            and edit participation as needed using the Participating toggle. Use
            the toggle in the 'Participating' column to update a participant’s
            status. Toggling a participant’s status on the dashboard will save
            changes automatically. You can also update participation, exemption
            status, and add comments for each participant by using the 'Edit'
            column on each row. Changes made in the edit screen will not
            auto-save; use 'Save and Close' to save your changes. Any rows
            highlighted in orange indicate a change in participation status from
            the previous fiscal year.
          </p>
          <p className="u-margin-bottom-medium">
            Once responses have been reviewed, use 'Create Grants' to associate
            grant applications to those listed as participating for
            federally-funded CTE programming in the current fiscal year. This is
            a one-time process and cannot be executed more than once per fiscal
            year.
          </p>
          <p className="u-error u-margin-bottom-medium">
            Caution: Updating participation after grants have been created is
            possible. However, deactivating a participant after creation of the
            Grant will only hide a participant’s applications, but users will
            maintain access to the Basic Grants Application Dashboard.
          </p>
        </Instruction>
      </Paper>
      <Paper>
        <FlexRow>
          <h2 className="u-section-title-text">Select Fiscal Year</h2>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <HotButton
              iconName="envelope"
              text={`${currentFy - 2} - ${currentFy - 1}`}
              active={isActive(currentFy - 1)}
              onClick={() => navigate(currentFy - 1)}
            />
          </FlexGroup>
          <FlexGroup>
            <HotButton
              iconName="envelope"
              text={`${currentFy - 1} - ${currentFy}`}
              active={isActive(currentFy)}
              onClick={() => navigate(currentFy)}
            />
          </FlexGroup>
          <FlexGroup>
            <HotButton
              iconName="envelope"
              text={`${currentFy} - ${currentFy + 1}`}
              active={isActive(currentFy + 1)}
              onClick={() => navigate(currentFy + 1)}
            />
          </FlexGroup>
        </FlexRow>
      </Paper>
      <Paper>
        <FlexRow>
          <SearchBarFilter
            data={participants}
            headers={getHeaders()}
            isFetching={isFetching}
            label="Search for Participants"
            setFilteredData={setFilteredData}
          />
        </FlexRow>
        <Filters />
        <Table
          conditionalRowStyles={conditionalRowStyles}
          data={filteredData}
          columns={getHeaders()}
          tableRowKey={'key'}
        />
      </Paper>
      <Paper>
        <ButtonContainer>
          <FlexRow>
            <FlexGroup>
              <Button
                asLink={false}
                text="Return to grants portal"
                onClick={navigateToPortal}
                className="form__btn--cancel"
              />
            </FlexGroup>
            <FlexGroup>
              <Button
                disabled={currentStatus}
                asLink={false}
                text={
                  currentStatus
                    ? 'Grants have already been created'
                    : `Create Grant for ${getNextFiscalYearApplicationEndYear()}`
                }
                onClick={() => setOpenDialog(true)}
                className="form__btn--submit"
              />
            </FlexGroup>
          </FlexRow>
        </ButtonContainer>
      </Paper>
      <Modal {...modalProps}>
        <SecureWrap component="grantsparticipants">
          <EditParticipantComponent
            close={close}
            participantKey={participantKey}
          />
        </SecureWrap>
      </Modal>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <div className="dialog">
          <h2 className="dialog__title">
            Create Grants for {getNextFiscalYearApplicationEndYear()}
          </h2>
          <div className="dialog__children">
            <p>
              Would you like to create all grants for{' '}
              {getNextFiscalYearApplicationEndYear()}?
            </p>
            <p className="u-error">
              Caution: this action cannot be undone. Once created, all
              participants will have access to their Grant Application Overview
              dashboard.
            </p>
          </div>
          <div className="dialog__btn-container">
            <Button
              onClick={() => setOpenDialog(false)}
              className="dialog__btn--cancel"
              text="Cancel"
            />
            <SecureWrap component={'grantsparticipants'}>
              <Button
                onClick={handleSave}
                className="confirm-button dialog__btn--submit"
                text="Confirm and create grants"
              />
            </SecureWrap>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
