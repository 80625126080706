import { useValidation } from 'de-formed-validations';
import { CTACourse, CTACourseTerm } from 'types';
import { prop, compose } from 'util/objectUtility';
import { some, every } from 'lodash';
import { fmap } from 'util/arrayUtility';
import { length, gt as lt, lt as gt } from 'ramda';

type CTACourseValidationType = CTACourse & {
  isCte?: boolean;
  isHosting?: boolean;
  isInstructional?: boolean;
};

const hasCountData = compose(some, fmap(compose(gt(0), prop('count'))));
const hasCreditData = compose(some, fmap(compose(gt(0), prop('credits'))));
//commented code goes with property "terms" below, to remove limit for count textbox
//const countLessThan51 = compose(every, fmap(compose(lt(51), prop('count'))));
const creditsLessThan100 = compose(
  every,
  fmap(compose(lt(100), prop('credits')))
);
/* 
  There is a floating point error in JavaScript that causes 1.1*100 to equal 110.00000000000001
  There might be other places this happens, so to fix I set the value to a fixed 2 decimal string,
  then cast it back to a number.  Not pretty, but it's effective.
*/
const creditsTwoDecimals = compose(
  every,
  fmap(
    compose(
      (val: number) => Number((Number(val) * 100).toFixed(2)) % 1 === 0,
      prop('credits')
    )
  )
);
const costsHasTwoDecimals = compose(
  every,
  fmap(
    compose(
      (val: number) => Number((Number(val) * 100).toFixed(2)) % 1 === 0,
      prop('cost')
    )
  )
);
const costLessThan100000 = compose(
  every,
  fmap(compose(lt(100001), prop('cost')))
);

export const oneCTATermHasData = (
  terms: CTACourseTerm[],
  { isInstructional, isCte }: CTACourseValidationType
) => {
  if (isInstructional) {
    return isCte ? hasCountData(terms) : hasCreditData(terms);
  }
  return some(
    terms.map((term: CTACourseTerm) => {
      return every([term.credits > 0, term.count > 0, term.cost > 0]);
    })
  );
};

export const CTACourseValidation = () => {
  return useValidation<CTACourseValidationType>({
    ctaSchoolId: [
      {
        errorMessage: 'School is required.',
        validation: gt(0),
      },
    ],
    coCourseName: [
      {
        errorMessage: 'Course Name is required.',
        validation: compose(gt(0), length),
      },
      {
        errorMessage: 'Course Name must be less than 250 characters.',
        validation: compose(lt(250), length),
      },
    ],
    terms: [
      /*
        Commenting out the limit (50) placed on "count" textbox, for hosting 
        and contracting. Leaving code here in case we need it later.
        Also commented a one liner at the top of the file
      {
        errorMessage: 'Term Count cannot exceed 50.',
        validation: countLessThan51,
      },
      */
      {
        errorMessage: 'Term Credits must be less than 100.',
        validation: creditsLessThan100,
      },
      {
        errorMessage: 'Credits cannot have more than two decimal places.',
        validation: creditsTwoDecimals,
      },
      {
        errorMessage: 'Costs can never exceed $100,000.',
        validation: (
          terms: CTACourseTerm[],
          { isInstructional }: CTACourseValidationType
        ) => {
          return isInstructional ? true : costLessThan100000(terms);
        },
      },
      {
        errorMessage: 'Cost cannot have more than two decimal places.',
        validation: (
          terms: CTACourseTerm[],
          { isInstructional }: CTACourseValidationType
        ) => {
          return isInstructional ? true : costLessThan100000(terms);
        },
      },
      {
        errorMessage: 'Cost cannot have more than two decimal places.',
        validation: (
          terms: CTACourseTerm[],
          { isInstructional }: CTACourseValidationType
        ) => {
          return isInstructional ? true : costsHasTwoDecimals(terms);
        },
      },
      {
        errorMessage: 'At least one term needs to have data.',
        validation: (
          terms: CTACourseTerm[],
          course: CTACourseValidationType
        ) => {
          return oneCTATermHasData(terms, course);
        },
      },
      {
        errorMessage: 'Terms cannot be partially completed',
        validation: (
          terms: CTACourseTerm[],
          { isInstructional, isHosting }: CTACourseValidationType
        ) => {
          if (isInstructional) {
            return true;
          }
          if (isHosting) {
            return every(
              terms.map((term: CTACourseTerm) => {
                /*
                 * case 1: count = 1, cost = 1 -> return true === true -> valid
                 * case 2: count = 0, cost = 0 -> return true === true -> valid
                 * case 3: count = 0, cost = 1 -> return true === false -> invalid
                 */
                const someData = some([term.count > 0, term.cost > 0]);
                const everyData = every([term.count > 0, term.cost > 0]);
                return someData === everyData;
              })
            );
          }
          return every(
            terms.map((term: CTACourseTerm) => {
              const someData = some([
                term.credits > 0,
                term.count > 0,
                term.cost > 0,
              ]);
              const everyData = every([
                term.credits > 0,
                term.count > 0,
                term.cost > 0,
              ]);
              return someData === everyData;
            })
          );
        },
      },
    ],
  });
};
