import React, { useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { UniversalContact, Signature } from 'types';
import {
  getContactFullName,
  getUserEmail,
} from 'util/universalcontact.utility';
import { mergeObjects, renderData, compose, getDate } from 'util/objectUtility';
import { SignatureComponentValidations } from 'validations/SignatureComponent.validations';
import { isEmpty, isNil } from 'lodash';
import { isEmptySignature } from 'util/formHelpers';
import Icon from '../Icons';
import Input from 'components/Common/FormElements/Input';
import Tooltip from '../Tooltip';
import WarningAlert from '../WarningAlert';

type SignatureProps = {
  loadSingleUniversalContact: Function;
  onChange?: Function;
  signature: Signature;
  user?: UniversalContact;
  forceValidation?: boolean;
};

export const CommonSignatureComponent: React.FC<SignatureProps> = (props) => {
  const {
    signature,
    loadSingleUniversalContact,
    user,
    onChange,
    forceValidation,
  } = props;

  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
  } = SignatureComponentValidations();

  const dirtyIsValid = () => {
    return signature.isDirty ? !isEmptySignature(signature) : true;
  };

  const updateSignature = (data: Partial<Signature>) => {
    const updatedData = mergeObjects<Signature>(signature, data);
    const isDirty = !isEmptySignature(updatedData);
    const signedDate = isDirty ? new Date(Date.now()) : undefined;
    const payload = mergeObjects<Signature>(updatedData, {
      isDirty,
      signedDate,
    });
    validateAll(payload);
    onChange && onChange(payload);
  };

  const SignedDate = compose(
    renderData,
    getDate
  );

  useEffect(() => {
    if (
      user && // user exist
      user.universalContactId < 0 && // user is not an empty contact
      !isNil(signature.universalContactId) && // the universalContactId in not null
      signature.universalContactId > -1 // the universalContactId greater that 0
    ) {
      loadSingleUniversalContact(signature.universalContactId);
    }
  }, [loadSingleUniversalContact, signature.universalContactId]); // eslint-disable-line

  useEffect(() => {
    validateAll(mergeObjects(signature, { isDirty: forceValidation }));
  }, [forceValidation]); // eslint-disable-line

  return (
    <div className={isValid ? 'signature' : 'signature signature--error'}>
      <FlexGroup>
        <div className="signature__signatory-info">
          {!isEmpty(signature.signatory) && (
            <h3 className="signature__signatory">{signature.signatory}</h3>
          )}
          {signature.info && (
            <Tooltip
              indicator={<Icon className="signature__icon" name="info" />}
              tooltip={signature.info}
            />
          )}
        </div>
        <FlexRow>
          <FlexGroup>
            <div className="u-flex u-margin-bottom-tiny">
              <Input
                errorMessage={getError('signedName')}
                label="Typed Signature"
                name="signedName"
                onBlur={() =>
                  validate('signedName', signature.signedName, signature)
                }
                onChange={updateSignature}
                valid={getFieldValid('signedName')}
                value={signature.signedName}
              />
              <Input
                errorMessage={getError('signedTitle')}
                label="Official Title"
                name="signedTitle"
                onBlur={() =>
                  validate('signedTitle', signature.signedTitle, signature)
                }
                onChange={updateSignature}
                valid={getFieldValid('signedTitle')}
                value={signature.signedTitle}
              />
              <Input
                className="input-display"
                disabled={true}
                label="Signed Date"
                name="signedDate"
                value={SignedDate(signature.signedDate)}
              />
            </div>
            {user && user.universalContactId > 0 && (
              <p
                style={{ color: '#6A7677' }}
                className="u-form-label"
              >{`Signatory: ${getContactFullName(user)} | ${
                user.title
              } | ${getUserEmail(user)}`}</p>
            )}
          </FlexGroup>
        </FlexRow>
      </FlexGroup>
      {!dirtyIsValid() && (
        <WarningAlert>Please fill in all fields before saving.</WarningAlert>
      )}
    </div>
  );
};
