import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCivilRightSignatures,
  updateCivilRightSignatures,
} from 'services/grants/awardsignature.services';
import { SignatureWithAssertion } from 'types';

const initialState = baseInitialState;

export const loadCivilRightSignatures = createActionThunk(
  'loadCivilRightSignatures',
  (grantFiscalYearId: number) => {
    return readCivilRightSignatures(grantFiscalYearId);
  }
);

export const saveCivilRightSignatures = createActionThunk(
  'saveCivilRightSignatures',
  async (grantFiscalYearId: number, payload: SignatureWithAssertion[]) => {
    return updateCivilRightSignatures(grantFiscalYearId, payload);
  }
);

export default handleActions<
  BaseStateObject,
  SignatureWithAssertion | SignatureWithAssertion[]
>(
  {
    ...baseLoadActionBuilder<SignatureWithAssertion | SignatureWithAssertion[]>(
      loadCivilRightSignatures
    ),
    ...baseSaveActionBuilder<SignatureWithAssertion | SignatureWithAssertion[]>(
      saveCivilRightSignatures
    ),
  },
  initialState
);
