import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { DataCollectionSubmission } from 'types';

export const DATA_COLLECTION_SUBMISSION_ENDPOINT = 'api/submissionManagement/';

export const readDataCollectionSubmission = async () => {
  const url = `${ENVIRONMENT.API_URL}${DATA_COLLECTION_SUBMISSION_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('DataCollectionSubmission', err);
    });
};

export const updateDataCollectionSubmission = async (
  participants: DataCollectionSubmission[]
) => {
  const url = `${ENVIRONMENT.API_URL}${DATA_COLLECTION_SUBMISSION_ENDPOINT}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(participants),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('DataCollectionSubmission', err);
    });
};
