import { OverviewStepsSelections } from 'components/Grants/Applications/OverviewStepsSelections/OverviewStepsSelections.component';
import { connect } from 'react-redux';
import {
  getRecords_GrantOverview,
  getIsDataFetching_GrantOverview,
  getIsDataFetching_GrantTwoYearNav,
  getIsDataFetching_GrantFourYearNav,
  getIsDataFetching_GrantSpendingPlanNav,
  getIsDataFetching_GrantAssuranceSignatureNav,
} from 'redux/nav/_selectors';
import { some } from 'lodash';
import {
  getIsDataFetching_GrantAssurances,
  getIsDataFetching_AnnualReflection,
  getIsDataFetching_AnnualSpendingPlan,
  getIsDataFetching_GrantFiscalYear,
} from 'redux/grants/_selectors';
import { getIsDataFetching_GrantAnnualReflectionNav } from 'redux/nav/annualreflectionnav.selectors';
import { getIsDataFetching_BasicGrantSignatures } from 'redux/grants/basicgrantsignature.selectors';
import { getIsDataFetching_TwoYear } from 'redux/grants/twoyear.selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { getCurrentNavAnchor } from 'redux/application/selectors';

const mapStateToProps = (state: any) => {
  const steps = getRecords_GrantOverview(state).steps;
  const currentNavAchor = getCurrentNavAnchor(state);

  const stepsLoading = some([
    getIsDataFetching_GrantOverview(state),
    getIsDataFetching_GrantTwoYearNav(state),
    getIsDataFetching_GrantFourYearNav(state),
    getIsDataFetching_GrantAnnualReflectionNav(state),
    getIsDataFetching_GrantSpendingPlanNav(state),
    getIsDataFetching_GrantAssuranceSignatureNav(state),
    getIsDataFetching_GrantFiscalYear(state),
    getIsDataFetching_GrantAssurances(state),
    getIsDataFetching_BasicGrantSignatures(state),
    getIsDataFetching_AnnualReflection(state),
    getIsDataFetching_AnnualSpendingPlan(state),
    getIsDataFetching_TwoYear(state),
  ]);

  return {
    currentNavAchor,
    steps,
    stepsLoading,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(OverviewStepsSelections);
