import {
  GRANTS_KEY,
  GRANTS_NEEDS_ASSESSMENT_KEY,
  GRANTS_CAREER_INFO_KEY,
  GRANTS_QUALITY_KEY,
  GRANTS_INSTRUCTORS_KEY,
  GRANTS_PARTNERSHIPS_KEY,
  GRANTS_WORK_BASED_LEARNING_KEY,
  GRANTS_EQUITY_KEY,
} from 'redux/keys';
import { getRecords, getIsDataFetching } from 'redux/utility/_exports';
import { QuestionGroup } from 'types';
import { every } from 'lodash';

export const NEEDS_ASSESSMENT_PATH = [GRANTS_KEY, GRANTS_NEEDS_ASSESSMENT_KEY];
export const CAREER_INFO_PATH = [GRANTS_KEY, GRANTS_CAREER_INFO_KEY];
export const QUALITY_PATH = [GRANTS_KEY, GRANTS_QUALITY_KEY];
export const INSTRUCTORS_PATH = [GRANTS_KEY, GRANTS_INSTRUCTORS_KEY];
export const PARTNERSHIPS_PATH = [GRANTS_KEY, GRANTS_PARTNERSHIPS_KEY];
export const WORK_BASED_LEARNING_PATH = [
  GRANTS_KEY,
  GRANTS_WORK_BASED_LEARNING_KEY,
];
export const EQUITY_PATH = [GRANTS_KEY, GRANTS_EQUITY_KEY];

export const getRecords_FourYearPlan = (
  state: any,
  entityType: string = 'all'
) => {
  switch (entityType) {
    case 'needs-assessment':
      return getRecords<QuestionGroup>(state, NEEDS_ASSESSMENT_PATH);

    case 'career-info':
      return getRecords<QuestionGroup>(state, CAREER_INFO_PATH);

    case 'quality':
      return getRecords<QuestionGroup>(state, QUALITY_PATH);

    case 'instructors':
      return getRecords<QuestionGroup>(state, INSTRUCTORS_PATH);

    case 'partnerships':
      return getRecords<QuestionGroup>(state, PARTNERSHIPS_PATH);

    case 'work-based-learning':
      return getRecords<QuestionGroup>(state, WORK_BASED_LEARNING_PATH);

    case 'equity':
      return getRecords<QuestionGroup>(state, EQUITY_PATH);

    case 'all':
      return [
        ...getRecords<QuestionGroup>(state, NEEDS_ASSESSMENT_PATH),
        ...getRecords<QuestionGroup>(state, CAREER_INFO_PATH),
        ...getRecords<QuestionGroup>(state, QUALITY_PATH),
        ...getRecords<QuestionGroup>(state, INSTRUCTORS_PATH),
        ...getRecords<QuestionGroup>(state, PARTNERSHIPS_PATH),
        ...getRecords<QuestionGroup>(state, WORK_BASED_LEARNING_PATH),
        ...getRecords<QuestionGroup>(state, EQUITY_PATH),
      ];

    default:
      throw new Error('entityType could not be matched');
  }
};

export const getIsDataFetching_FourYearPlan = (
  state: any,
  entityType: string = 'all'
) => {
  switch (entityType) {
    case 'needs-assessment':
      return getIsDataFetching(state, NEEDS_ASSESSMENT_PATH);

    case 'career-info':
      return getIsDataFetching(state, CAREER_INFO_PATH);

    case 'quality':
      return getIsDataFetching(state, QUALITY_PATH);

    case 'instructors':
      return getIsDataFetching(state, INSTRUCTORS_PATH);

    case 'partnerships':
      return getIsDataFetching(state, PARTNERSHIPS_PATH);

    case 'work-based-learning':
      return getIsDataFetching(state, WORK_BASED_LEARNING_PATH);

    case 'equity':
      return getIsDataFetching(state, EQUITY_PATH);

    case 'all':
      return every([
        getIsDataFetching(state, NEEDS_ASSESSMENT_PATH),
        getIsDataFetching(state, CAREER_INFO_PATH),
        getIsDataFetching(state, QUALITY_PATH),
        getIsDataFetching(state, INSTRUCTORS_PATH),
        getIsDataFetching(state, PARTNERSHIPS_PATH),
        getIsDataFetching(state, WORK_BASED_LEARNING_PATH),
        getIsDataFetching(state, EQUITY_PATH),
      ]);

    default:
      throw new Error('entityType could not be matched');
  }
};
