import React from 'react';

type Props = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
};

export const PageHeading: React.FC<Props> = (props) => {
  const { title, subTitle } = props;

  const getSubTitle = () => {
    return typeof subTitle === 'string' ? (
      <h3 className="program__subtitle">{subTitle}</h3>
    ) : (
      subTitle
    );
  };

  return (
    <div className="program__header">
      {typeof title === 'string' ? (
        <h2 className="program__title">{title}</h2>
      ) : (
        title
      )}
      {subTitle && getSubTitle()}
    </div>
  );
};
