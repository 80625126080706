import React, { useState, useEffect, CSSProperties } from 'react';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
  RenderSuggestionsContainerParams,
  RenderSuggestionParams,
} from 'react-autosuggest';
import { AdminOption, emptyAdminOption } from 'types';
import { isNil, map, find } from 'lodash';
import { FlexGroup } from 'layouts';
import { renderPart, getParts, getSuggestions } from 'util/autoSuggest.utility';
import MaxResults from '../MaxResults';
import { prop, renderData } from 'util/objectUtility';

type ProgramWorkflowStatusProps = {
  options: AdminOption[];
  noLabel?: boolean;
  onChange: Function;
  label?: string;
  maxResults: number;
  selection: Partial<AdminOption>;
};

export const ProgramWorkflowStatusComponent: React.FC<ProgramWorkflowStatusProps> = (
  props
) => {
  const {
    options,
    noLabel,
    onChange,
    label,
    maxResults = 8,
    selection,
  } = props;

  const [resultText, setResultText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<AdminOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<AdminOption>();

  const boxStyle: CSSProperties = {
    width: '30%',
  };

  const getDisplayValue = (value: AdminOption) => {
    const status = prop('workflowStatus', value)
      ? ` | ${prop('workflowStatus', value)}`
      : '';
    const state = prop('programState', value)
      ? ` | ${prop('programState', value)}`
      : '';
    return `${renderData(prop('stepName', value))}${status}${state}`;
  };

  useEffect(() => {
    if (isNil(selection.stepOptionId)) {
      setResultText('');
    }
  }, [selection]);

  useEffect(() => {
    if (selectedOption) {
      const value = find(options, (item) => {
        return item.stepOptionId === selectedOption.stepOptionId;
      });
      setResultText(value ? getDisplayValue(value) : '');
    }
  }, [setResultText, options, selectedOption]);

  const defaultResult: AdminOption = {
    ...emptyAdminOption(),
    workflowStatus: 'No results',
  };

  const executeSearch = (value: string): AdminOption[] => {
    setSelectedOption(undefined);
    return getSuggestions(
      options,
      value,
      [prop('stepName'), prop('workflowStatus'), prop('programState')],
      maxResults,
      defaultResult
    );
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    const { value } = request;
    if (value === resultText) {
      setSearchResults(executeSearch(value));
    }
  };

  const renderSuggestionsContainer = (
    suggestionParams: RenderSuggestionsContainerParams
  ) => {
    const { containerProps, children } = suggestionParams;
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>Step Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>Workflow Status</strong>
          </span>
          <span style={boxStyle}>
            <strong>Program State</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={options.length}
          resultCount={searchResults.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: AdminOption,
    { query }: RenderSuggestionParams
  ) => {
    const stepNameParts = getParts(suggestion.stepName, query);
    const statusParts = getParts(suggestion.workflowStatus, query);
    const stateParts = getParts(suggestion.programState, query);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div style={boxStyle}>{map(stepNameParts, renderPart)}</div>
          <div style={boxStyle}>{map(statusParts, renderPart)}</div>
          <div style={boxStyle}>{map(stateParts, renderPart)}</div>
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'down':
      case 'up':
        break;
      default:
        setResultText(newValue);
        setSearchResults(executeSearch(newValue));
        break;
    }
  };

  const inputProps = {
    value: resultText,
    onChange: handleChange,
    id: 'stepOptionId',
  };

  const getSuggestionValue = (suggestion: AdminOption) => {
    return getDisplayValue(suggestion);
  };

  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<AdminOption>
  ) => {
    const { suggestion } = data;
    onSuggestionsClearRequested();
    onChange && onChange(suggestion);
  };

  return (
    <FlexGroup>
      {!noLabel && (
        <label htmlFor="contactSelectBox" className="form__label">
          {label ? label : 'Name of the Contact'}
        </label>
      )}
      <Autosuggest
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={true}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={searchResults}
      />
    </FlexGroup>
  );
};
