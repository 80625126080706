import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { AwardPacketEditComponent } from './AwardPacketEdit.component';
import { AwardPacketSummaryComponent } from './AwardPacketSummary.component';
import { getLockedStatus } from 'util/applicationStateUtility';
import {
  getRecords_CivilRightsSignatures,
  getIsDataFetching_CivilRightsSignatures,
} from 'redux/grants/awardsignatures/civilrightsignatures.selectors';
import {
  getRecords_CertificationOfExemptionSignatures,
  getIsDataFetching_CertificationOfExemptionSignatures,
} from 'redux/grants/awardsignatures/certificateofexemptionsignatures.selectors';
import {
  getRecords_GrantAgreementSignatures,
  getIsDataFetching_GrantAgreementSignatures,
} from 'redux/grants/awardsignatures/grantagreementsignatures.selectors';
import { some } from 'lodash';
import { saveCertificateOfExemptionSignatures } from 'redux/grants/awardsignatures/certificateofexemptionsignatures';
import { saveCivilRightSignatures } from 'redux/grants/awardsignatures/civilrightsignatures';
import { saveGrantAgreementSignatures } from 'redux/grants/awardsignatures/grantagreementsignatures';

const mapStateToProps = (state: any) => {
  const civilLoading = getIsDataFetching_CivilRightsSignatures(state);
  const certLoading = getIsDataFetching_CertificationOfExemptionSignatures(
    state
  );
  const grantAgreementLoading = getIsDataFetching_GrantAgreementSignatures(
    state
  );

  const isLoading = some([civilLoading, certLoading, grantAgreementLoading]);

  const isLocked = getLockedStatus(state, 'award-packet');
  const civilRightsSignatures = getRecords_CivilRightsSignatures(state);
  const certificationOfExemptionSignatures = getRecords_CertificationOfExemptionSignatures(
    state
  );
  const grantAgreementSignatures = getRecords_GrantAgreementSignatures(state);

  return {
    isLoading,
    isLocked,
    civilRightsSignatures,
    certificationOfExemptionSignatures,
    grantAgreementSignatures,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  saveCertificateOfExemptionSignatures,
  saveCivilRightSignatures,
  saveGrantAgreementSignatures,
};

export const AwardPacketEdit = connect(
  mapStateToProps,
  dispatchProps
)(AwardPacketEditComponent);

export const AwardPacketSummary = connect(
  mapStateToProps,
  dispatchProps
)(AwardPacketSummaryComponent);
