import { connect } from 'react-redux';
import { StudentFollowupComponent } from './StudentFollowup.component';
import { loadStudentFollowUpData } from 'redux/datacollection/studentenrollment';
import {
  getAdvancedFilteredRecords_StudentEnrollment,
  getIsDataFetching_StudentEnrollment,
} from 'redux/datacollection/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);
  const followUpData = getAdvancedFilteredRecords_StudentEnrollment(state);
  const isFetching = getIsDataFetching_StudentEnrollment(state);

  return {
    followUpData,
    isFetching,
    loggedInUser,
  };
};

const dispatchProps = {
  loadStudentFollowUpData,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(StudentFollowupComponent);
