import React from 'react';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import IconButton from 'components/Common/IconButton';
import { PROGRAM_OF_STUDY_STATUS_ID } from 'constants/programOfStudy.const';
import { every } from 'lodash';

type DenyPOSProps = {
  statusId: number;
  isApprover: boolean;
  onDeny: Function;
};

export const DenyPOS: React.FC<DenyPOSProps> = (props) => {
  const { statusId, isApprover, onDeny } = props;

  return every([
    isApprover,
    statusId === PROGRAM_OF_STUDY_STATUS_ID.Pending,
  ]) ? (
    <span className="pos__deny">
      <IconButton
        name="cross"
        tooltip="Deny Program of Study"
        onClick={onDeny}
      />
    </span>
  ) : (
    <span className="pos__deny">{EMPTY_PLACEHOLDER}</span>
  );
};
