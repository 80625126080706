import React, { useState, useEffect } from 'react';
import { compose, renderData, prop } from 'util/objectUtility';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import { UniversalContact } from 'types';
import {
  getUserEmail,
  getUserPhone,
  getContactTypeDisplay,
  getContactFullName,
  extractTeacherInfo,
  isPowerUser,
} from 'util/universalcontact.utility';
import { useHistory } from 'react-router-dom';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Badge from 'components/Common/Badge';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import IconButton from 'components/Common/IconButton';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import TeacherCredentials from 'components/UniversalContacts/TeacherCredentials';
import UniversalContactFilter from 'components/UniversalContacts/UniversalContactFilter';
import Instruction from 'components/Common/Instruction';
import Table from 'components/Common/Table';

type UniversalContactsDashboardProps = {
  filters: number;
  contacts: UniversalContact[];
  loadUniversalContacts: Function;
  isFetching: boolean;
  loggedInUser: UniversalContact;
};

export const UniversalContactsDashboard: React.FC<UniversalContactsDashboardProps> = (
  props
) => {
  const { contacts, filters, isFetching, loadUniversalContacts } = props;
  const [searchFilter, setSearchFilter] = useState([...contacts]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);

  const history = useHistory();
  const handleClick = (row: UniversalContact) => {
    history.push(`/contacts/${row.universalContactId}`);
  };

  useEffect(() => {
    history.block(!isFetching);
  }, [isFetching]); // eslint-disable-line

  const Name = compose(renderData, getContactFullName);

  const DisplayName = (row: UniversalContact) => {
    return (
      <span
        className="u-flex u-color-blue u-bold"
        style={{ alignItems: 'center' }}
      >
        {row.isTeacher && (
          <TeacherCredentials teacherInfo={extractTeacherInfo(row)} />
        )}
        {Name(row)}
      </span>
    );
  };

  const DisplayEmail = (row: UniversalContact) => {
    return (
      <a
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        href={`mailto:${getUserEmail(row)}`}
      >
        {getUserEmail(row)}
      </a>
    );
  };

  const renderTitle = compose(renderData, prop('title'));

  const renderContactType = compose(renderData, getContactTypeDisplay);

  const renderPhone = compose(renderData, getUserPhone);

  const renderPowerUser = compose(renderData, isPowerUser);

  const renderRequiredLogin = (contact: UniversalContact) => {
    return contact.isAadB2c ? 'Yes' : 'No';
  };

  useEffect(() => {
    loadUniversalContacts();
  }, [loadUniversalContacts]);

  const UNIVERSAL_CONTACT_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: DisplayName,
      sortable: true,
      selector: Name,
    },
    {
      name: 'Title',
      cell: renderTitle,
      sortable: true,
      selector: renderTitle,
      width: '20%',
    },
    {
      name: 'Contact Type',
      cell: renderContactType,
      sortable: true,
      selector: renderContactType,
    },
    {
      name: 'Power User',
      cell: renderPowerUser,
      sortable: true,
      selector: isPowerUser,
      width: '10%',
    },
    {
      name: 'Phone',
      cell: renderPhone,
      sortable: true,
      selector: renderPhone,
    },
    {
      name: 'Email',
      cell: DisplayEmail,
      sortable: true,
      selector: getUserEmail,
    },
    {
      name: 'Login',
      cell: renderRequiredLogin,
      sortable: true,
      selector: renderRequiredLogin,
      width: '10%',
    },
  ];

  return (
    <BaseLayout>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Loading contacts..." />
      <Paper>
        <Instruction
          actionButton={
            <SecureWrap component="universalcontacts" requireEdit={true}>
              <Button
                onClick={() => history.push('/contacts/create-new-user')}
                text="Create New Contact"
                className="button--filled"
              />
            </SecureWrap>
          }
          title="Universal Contacts"
        >
          <p>
            This page enables you to search, create, and manage universal
            contacts. Select a row to view or edit a contact. Use "Search for
            Universal Contacts" to find a contact you want to view or edit. The
            table is searchable with the start of the text in any column.
            Columns with a down or up-arrow button can sort the table in
            ascending or descending order by that column. A graduation cap
            indicates the contact is a teacher. Users with enabled permission
            can create a new contact by using "Create New Contact".
            <b>
              {' '}
              Do NOT create a new contact just because an individual's email or
              credential information has changed within your district or
              college. If you experienced an error in creating a contact, please
              use the "submit issue" link in the footer of the page so that we
              may assist you.
            </b>
            <br></br>
            <br></br>Each entity is permitted one "Power User", so if you do not
            see anyone with that designation for your institution, please use
            the "submit issue" button at the bottom of the screen to submit an
            issue ticket. Note that permissions are managed locally by each
            institution's Power User. If the column labeled "login" indicates
            "yes", then that individual has permissions to login to the Gateway
            as assigned by the institution. Using the filter icon next to the
            search bar below, this page can be filtered by "Active" contacts. If
            the active filter is applied, you will see only the individuals
            associated with the district/college that are currently active in
            the system, including their login credentials and associated
            permissions. Inactive users will continue to have a record in the
            Gateway, but would not be able to login.
            <b>
              {' '}
              We strongly encourage Power Users to regularly review contacts,
              logins, and permissions for their institution to ensure data
              privacy and security is maintained.
            </b>
          </p>
        </Instruction>
      </Paper>
      <FlexRow>
        <FlexGroup>
          <Paper className="u-add-padding-bottom">
            <FlexRow>
              <SearchBarFilter
                data={contacts}
                headers={UNIVERSAL_CONTACT_HEADERS}
                isFetching={isFetching}
                label="Search for Universal Contacts"
                setFilteredData={setSearchFilter}
              />
              <div className="search-bar-filter__button">
                <Badge label={filters} show={!!filters}>
                  <IconButton
                    name="tune"
                    tooltip="Show Filters"
                    onClick={() => {
                      setFiltersOpen(!filterOpen);
                    }}
                  />
                </Badge>
              </div>
            </FlexRow>
            <Table
              columns={UNIVERSAL_CONTACT_HEADERS}
              data={searchFilter}
              onRowClick={handleClick}
            />
          </Paper>
        </FlexGroup>
        {filterOpen && (
          <div className="u-filters">
            <UniversalContactFilter />
          </div>
        )}
      </FlexRow>
    </BaseLayout>
  );
};
