import { GRANTS_KEY, GRANTS_TWO_YEAR_KEY } from 'redux/keys';
import { QuestionGroup } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';

export const TWO_YEAR_PATH = [GRANTS_KEY, GRANTS_TWO_YEAR_KEY];

export const getIsDataFetching_TwoYear = (state: any): boolean => {
  return getIsDataFetching(state, TWO_YEAR_PATH);
};

export const getRecords_TwoYear = (state: unknown): QuestionGroup[] => {
  return getRecords(state, TWO_YEAR_PATH);
};

export const shouldRefreshReduxState_TwoYear = (state: any): boolean => {
  return shouldRefreshReduxState<QuestionGroup>(state, TWO_YEAR_PATH);
};
