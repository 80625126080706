import { handleCareerPathwayCoursesCache } from 'redux/utility/_exports';
import { loadProgramPathway } from 'redux/caches/programpathway';

export const programPathwaysMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState, dispatch } = store;

  next(action);

  switch (action.type) {
    case loadProgramPathway.SUCCEEDED: {
      if (!action.payload.records) {
        throw new Error(
          'Service did not return records array. Cache services must return { records: [], programInstanceId: number }'
        );
      }
      const payload = Array.isArray(action.payload.records)
        ? action.payload
        : { ...action.payload, records: [] };
      handleCareerPathwayCoursesCache(dispatch, getState(), payload);
      break;
    }
  }
};
