import { QuestionGroup, Signature, PerformanceData } from 'types';
import { Deficiency } from './Deficiency.types';
import { emptyQuestionGroup, emptySignature } from 'types/common';
import { emptyPerformanceData } from './PerformanceData.types';

export interface ImprovementPlan {
  hasMultiYearDeficiencies: boolean;
  multiYearQuestions: QuestionGroup;
  multiYearSignature: Signature;
  deficiencies: Deficiency[];
  totals: PerformanceData;
  initialGrantFund: number;
  minimumSpending: number; // 20% of total grant award
}

export const emptyImprovementPlan = (): ImprovementPlan => {
  return {
    hasMultiYearDeficiencies: false,
    multiYearQuestions: emptyQuestionGroup(),
    multiYearSignature: emptySignature(),
    deficiencies: [],
    totals: emptyPerformanceData(),
    initialGrantFund: 0,
    minimumSpending: 0,
  };
};
