import React from 'react';
import { IconTable } from 'constants/icons.const';
import { map } from 'lodash';
import Icon from 'components/Common/Icons';

export const DevPrintIcons: React.FC<any> = (props) => {
  const allIcons = Object.keys(IconTable);
  return (
    <React.Fragment>
      <h1>Icon Library</h1>
      <h3>This page is just a list of our currently used icons</h3>
      <br />
      {map(allIcons, (icon: string, index: number) => {
        return (
          <div style={{ margin: '2rem', display: 'inline-block' }}>
            <Icon name={icon} className="landing-page-card__icon" />
            <p style={{ textAlign: 'center' }}>{icon}</p>
          </div>
        );
      })}
    </React.Fragment>
  );
};
