import React, { useState, useEffect } from 'react';
import { filter, map } from 'lodash';
import { PhoneNumber, UniversalContact } from 'types';
import { FlexGroup, FlexRow } from 'layouts';
import { validatePhoneNumber } from 'util/formHelpers';
import { formatPhone } from 'util/universalcontact.utility';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import Button from 'components/Common/FormElements/Button';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';

type DynamicPhoneProps = {
  phones: PhoneNumber[];
  onChange: Function;
  dynamic?: boolean;
  validations: ValidationObject<UniversalContact>;
};

export const DynamicPhoneForm: React.FC<DynamicPhoneProps> = (props) => {
  const { dynamic = true, phones, onChange, validations } = props;

  const { getError, getFieldValid, validate } = validations;

  const updatePhone = (phone: any, index: number) => {
    validatePhoneIfTrue({ ...phones[index], ...phone }, index);
    const universalContactPhone = map(phones, (storedPhone, idx) => {
      return index === idx ? { ...storedPhone, ...phone } : storedPhone;
    });
    onChange(
      { universalContactPhone },
      'universalContactPhone',
      universalContactPhone
    );
  };

  const deletePhone = (index: number) => {
    const universalContactPhone = filter(phones, (stored, idx) => {
      return index !== idx;
    });
    onChange(
      { universalContactPhone },
      'universalContactPhone',
      universalContactPhone
    );
  };

  const validateAllPhones = () => {
    validate('universalContactPhone', phones);
    return map(phones, (phone: PhoneNumber) => {
      return validatePhoneNumber(phone.number);
    });
  };

  const [phoneValid, setPhoneValid] = useState<boolean[]>([]);

  const validatePhoneIfTrue = (phone: PhoneNumber, index: number) => {
    if (validatePhoneNumber(phone.number)) {
      const update = map(phoneValid, (val, idx) => {
        return index === idx ? true : val;
      });
      setPhoneValid([...update]);
    }
  };

  useEffect(() => {
    if (!getFieldValid('universalContactPhone')) {
      setPhoneValid(validateAllPhones());
    }
  }, [setPhoneValid, getFieldValid('universalContactPhone')]); // eslint-disable-line

  return (
    <FlexGroup>
      {map(phones, (phone: PhoneNumber, index: number) => {
        return (
          <FlexRow key={index}>
            <FlexGroup>
              <Input
                id="label"
                name="label"
                value={phone.label}
                label="Phone Type"
                onChange={(data: any) => {
                  updatePhone(data, index);
                }}
              />
            </FlexGroup>
            <FlexGroup>
              <Input
                errorMessage={getError('universalContactPhone')}
                id="number"
                name="number"
                value={formatPhone(phone.number)}
                label="Phone Number"
                onChange={(data: any) => {
                  updatePhone(data, index);
                }}
                valid={phoneValid[index]}
                onBlur={() => {
                  setPhoneValid(validateAllPhones());
                }}
              />
              {index !== 0 && (
                <div
                  className="form__delete-btn"
                  onClick={() => deletePhone(index)}
                >
                  <Icon
                    name="crossCircle"
                    title="Remove"
                    className="form__delete-btn--svg"
                  />
                </div>
              )}
            </FlexGroup>
          </FlexRow>
        );
      })}
      {dynamic && (
        <FlexRow>
          <Button
            text="+ Add another phone"
            className="form__btn--add u-reduce-margin-top"
            onClick={() =>
              updatePhone({ label: 'Phone', number: '' }, phones.length++)
            }
          />
        </FlexRow>
      )}
    </FlexGroup>
  );
};
