import { FILTERS_KEY, FILTERS_KEY_SUBMISSION_MANAGEMENT } from 'redux/keys';
import { get, map } from 'lodash';
import {
  KeyValueType,
  SubmissionManagementFilterState,
  ReduxFilter,
} from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const SUBMISSION_MANAGEMENT_FILTER_PATH = [
  FILTERS_KEY,
  FILTERS_KEY_SUBMISSION_MANAGEMENT,
];

export const getRecords_SubmissionManagementFilterState = (
  state: any
): SubmissionManagementFilterState => {
  return get(state, SUBMISSION_MANAGEMENT_FILTER_PATH);
};

export const getSubmissionManagementFilters = (
  state: any
): ReduxFilter<SubmissionManagementFilterState>[] => {
  const filterValues: KeyValueType<
    unknown
  > = (getRecords_SubmissionManagementFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (filterName: string): ReduxFilter<SubmissionManagementFilterState> => {
      switch (filterName) {
        case 'incompleteTeachers':
          return {
            filter: {
              property: 'teacher.completed',
              values:
                filterValues.incompleteTeachers !== undefined ? false : '',
            },
            operator: 'and',
          };
        case 'incompleteEnrollment':
          return {
            filter: {
              property: 'enrollment.completed',
              values:
                filterValues.incompleteEnrollment !== undefined ? false : '',
            },
            operator: 'and',
          };

        case 'incompleteFollowUp':
          return {
            filter: {
              property: 'followUp.completed',
              values:
                filterValues.incompleteFollowUp !== undefined ? false : '',
            },
            operator: 'and',
          };

        case 'institute':
          return {
            filter: {
              property: 'level',
              values: filterValues.institute !== undefined ? 'I' : '',
            },
            operator: 'and',
          };

        case 'district':
          return {
            filter: {
              property: 'level',
              values: filterValues.district !== undefined ? 'D' : '',
            },
            operator: 'and',
          };

        default:
          return {
            filter: {
              property: filterName,
              values:
                filterValues[filterName] !== undefined
                  ? filterValues[filterName]
                  : '',
            },
            operator: 'and',
          };
      }
    }
  );

  return filters;
};
