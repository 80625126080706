import React from 'react';
import { FlexRow } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { SnapshotStudent } from 'types';
import {
  convertRadioToBoolValue,
  convertBoolToRadioValue,
  createRadioOptionsForBool,
} from 'util/formHelpers';
import CheckboxGroupComponent from 'components/Common/FormElements/CheckboxGroup';
import Radio from 'components/Common/FormElements/Radio';

export const DemographicInformationSPS: React.FC<
  StudentRecordComponentProps
> = (props) => {
  const { student, onChange, v } = props;

  const handleBooleanChange = (data: Partial<SnapshotStudent>) => {
    const updated = convertRadioToBoolValue<SnapshotStudent>(data);
    onChange(updated.data, updated.name, updated.val);
  };

  const raceOptions = (student: SnapshotStudent) => {
    return {
      americanIndian: {
        value: student.americanIndian,
        label: 'American Indian or Alaska Native',
      },
      asian: {
        value: student.asian,
      },
      black: {
        value: student.black,
        label: 'Black or African American',
      },
      pacificIslander: {
        value: student.pacificIslander,
        label: 'Native Hawaiian or Other Pacific Islander',
      },
      white: {
        value: student.white,
      },
    };
  };

  return student ? (
    <React.Fragment>
      <FlexRow>
        <CheckboxGroupComponent
          border={true}
          errorMessage={v.getError('race')}
          groupTitle="CDE Race"
          onBlur={() => v.validate('race', null, student)}
          onChange={onChange}
          options={raceOptions(student)}
          valid={v.getFieldValid('race')}
        />
      </FlexRow>
      <FlexRow>
        <Radio
          border={true}
          errorMessage={v.getError('hispanic')}
          label="CDE Ethnicity"
          name="hispanic"
          onBlur={() => v.validate('hispanic', student.hispanic, student)}
          onChange={handleBooleanChange}
          options={createRadioOptionsForBool({
            optionTrue: 'Hispanic',
            optionFalse: 'Non-Hispanic',
          })}
          valid={v.getFieldValid('hispanic')}
          value={convertBoolToRadioValue(student.hispanic)}
        />
        <Radio
          border={true}
          errorMessage={v.getError('limitedEnglish')}
          label="English Language Learner"
          name="limitedEnglish"
          onBlur={() =>
            v.validate('limitedEnglish', student.limitedEnglish, student)
          }
          onChange={onChange}
          options={createRadioOptionsForBool({
            optionTrue: 'Proficient in English or Unknown',
            optionFalse: 'Limited English Proficiency',
          })}
          valid={v.getFieldValid('limitedEnglish')}
          value={convertBoolToRadioValue(student.limitedEnglish)}
        />
      </FlexRow>
    </React.Fragment>
  ) : null;
};
