import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { useParams } from 'react-router-dom';
import VoucherList from './VoucherList';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';

type VoucherSummaryProps = {
  isLoading: boolean;
  isLocked: boolean;
  loadVouchers: Function;
  setCurrentNavAnchor: Function;
};

export const VouchersSummaryComponent: React.FC<VoucherSummaryProps> = (
  props
) => {
  const { loadVouchers, setCurrentNavAnchor } = props;

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  useEffect(() => {
    loadVouchers(grantFiscalYearId);
  }, [loadVouchers, grantFiscalYearId]);

  return (
    <Paper>
      <SummaryScroll componentStep="vouchers" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('vouchers')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <VoucherList isSummary={true} />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('vouchers')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
