import React from 'react';
import ReportDetail from 'components/Reports/ReportDetail';
import { PublicLayout } from 'layouts/PublicLayout';

export const PublicReportsReportPage = (props: any) => {
  return (
    <PublicLayout>
      <ReportDetail {...props} />
    </PublicLayout>
  );
};
