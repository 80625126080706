import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from 'services';
import { ProgramPathway } from 'types';

const ENDPOINT = 'api/ProgramPathwayCourses/';

export const createProgramPathway = async (
  programPathway: ProgramPathway[],
  programInstanceId: number
) => {
  const url = ENVIRONMENT.API_URL + ENDPOINT + programInstanceId;

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(programPathway),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramPathwayCourses', err);
    });
};

export const readProgramPathway = async (programInstanceId: number) => {
  const url = ENVIRONMENT.API_URL + ENDPOINT + programInstanceId;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((records: ProgramPathway[]) => {
      return {
        records,
        programInstanceId,
      };
    })
    .catch((err) => {
      return catchError('ProgramPathwayCourses', err);
    });
};
