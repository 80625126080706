import { VoucherFundsAvailable } from './VoucherFundsAvailable.component';
import { connect } from 'react-redux';
import { isAdminCategory } from 'util/annualSpendingUtility';
import {
  getOtherSourcesAvailable,
  getTotalVoucherRemaining,
  getCategoryFundsRemainingFromExpenses,
} from 'util/voucherUtility';
import { getSingleVoucherByType } from 'redux/grants/_selectors';
import { subtract } from 'util/mathHelpers';

const mapStateToProps = (state: any, ownProps: any) => {
  const { spendingCategory, voucherType } = ownProps;

  // Display information
  const title = isAdminCategory(spendingCategory)
    ? 'Available Voucher Funds for Administrative Costs'
    : 'Available Voucher Funds for Project Plan';

  // get voucher data
  const voucher = getSingleVoucherByType(state, voucherType);

  // funds remaining for the current spending category
  const fundsRemaining =
    spendingCategory.categoryId !== 7
      ? getCategoryFundsRemainingFromExpenses(spendingCategory)
      : subtract(
          getTotalVoucherRemaining(voucher),
          spendingCategory.totalAmountForCurrentRequest
        );

  // funds available from other sources
  const otherSourcesAvailable = getOtherSourcesAvailable(
    voucher,
    spendingCategory
  );

  return {
    fundsRemaining,
    otherSourcesAvailable,
    title,
  };
};

export default connect(mapStateToProps)(VoucherFundsAvailable);
