import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CTADistrictRecord } from 'types';
import { isApplicaionIdValid, doNothing } from 'util/global';
import { safeGet } from 'util/objectUtility';
import Paper from 'components/Common/Paper';
import WorkflowTray from 'components/Common/GeneralWorkflow/WorkflowTray';

type CTADistrictLayoutProps = {
  children: JSX.Element;
  ctaRecord: CTADistrictRecord;
  loadCTASingleDistrictRecord: Function;
};

export const CTADistrictLayout: React.FC<CTADistrictLayoutProps> = (props) => {
  const { children, ctaRecord, loadCTASingleDistrictRecord } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: id }: any = useParams();
  const ctaId = Number(id);

  // --[ component logic ]-----------------------------------------------------
  const get = safeGet(ctaRecord);

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (isApplicaionIdValid(id)) {
      loadCTASingleDistrictRecord(id).catch(doNothing);
    }
  }, [id, loadCTASingleDistrictRecord]);

  return (
    <div className="program">
      <WorkflowTray entityId={ctaId} entityType="cta" />
      <section className="program__body">
        <Paper>
          <h2 className="program__title">
            CTA Report: {get('schoolDistrictName')}
          </h2>
          <h3>
            Costs Incurred {get('year') - 1} - {get('year')}
          </h3>
        </Paper>
        <div className="program__content">{children}</div>
      </section>
    </div>
  );
};
