export interface QuestionValue {
  parentObjectId: number;
  questionFilePath: string[];
  questionId: number;
  value: string;
  valueId: number;
}

export const emptyQuestionValue = (): QuestionValue => {
  return {
    parentObjectId: 0,
    questionFilePath: [],
    questionId: 1,
    value: '',
    valueId: 1,
  };
};
