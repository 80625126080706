import React from 'react';
import Input from 'components/Common/FormElements/Input';
import { UCStreetAddress } from 'types';
import { FlexRow } from 'layouts';

type AddressInfoProps = {
  onChange: Function;
  streetAddress: UCStreetAddress;
};

export const AddressInfo: React.FC<AddressInfoProps> = (props) => {
  const { onChange, streetAddress } = props;

  return (
    <div className="form__section">
      <FlexRow>
        <h3 className="u-section-title-text">Address Information</h3>
      </FlexRow>
      <FlexRow>
        <Input
          name="street1"
          value={streetAddress.street1}
          onChange={onChange}
        />
        <Input
          name="street2"
          value={streetAddress.street2}
          onChange={onChange}
        />
      </FlexRow>
      <FlexRow>
        <Input name="city" value={streetAddress.city} onChange={onChange} />
        <Input name="state" value={streetAddress.state} onChange={onChange} />
        <Input
          name="zipCode"
          value={streetAddress.zipCode}
          onChange={onChange}
        />
      </FlexRow>
    </div>
  );
};
