import React, { useEffect, useState } from 'react';
import { FlexRow } from 'layouts';
import {
  executeRollover,
  executeTeacherRollover,
  getRolloverStatus,
  getRolloverTeacherStatus,
} from 'services/datacollection/rollover.services';
import { AdminRollover } from 'types';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Instruction from 'components/Common/Instruction';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ToastNotification from 'components/Common/Toast';
import { useHistory } from 'react-router';
import Loading from 'components/Common/Loading';

export const AdminRolloverComponent: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canExecute, setCanExecute] = useState<boolean>(false); //The Data Viz Rollover

  const [teacherDisabled, setTeacherDisabled] = useState<boolean>(true); //Teacher Rollover
  const history = useHistory();

  useEffect(() => {
    getRolloverStatus()
      .then((data: AdminRollover) => {
        setCanExecute(data.canExecute);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Load Rollover Status Failed."
          />
        );
      });
  }, []);

  const handleRollover = () => {
    executeRollover()
      .then(() => {
        setCanExecute(false);
        toast(
          <ToastNotification
            status="success"
            message="Data Viz Rollover Completed."
          />
        );
      })
      .catch((err: any) => {
        toast(
          <ToastNotification
            status="error"
            message="Data Viz Rollover Failed."
          />
        );
      });
  };

  useEffect(() => {
    getRolloverTeacherStatus()
      .then((data: AdminRollover) => {
        setTeacherDisabled(data.teacherDisabled);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Load Rollover Teacher Status Failed."
          />
        );
      });
  }, []);

  const handleTeacherRollover = () => {
    setIsLoading(true);
    setTeacherDisabled(true);

    executeTeacherRollover()
      .then(() => {
        history.push('/data-collection');
      })
      .catch((err: any) => {
        toast(
          <ToastNotification
            status="error"
            message="Teacher Rollover Failed."
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <SecureWrap component={['DCAdmin']}>
      <Loading isActive={isLoading} />
      <ScrollToTop />
      <Paper>
        <PageHeading
          title={
            <Instruction isPageHeader={true} title="Data Viz Rollover">
              <p className="u-margin-bottom">
                The Data Viz rollover includes pushing the data from student
                enrollment from the current academic year, and student follow up
                from the previous year to all 5 year trends and performance
                metrics areas within Program Approvals, Grants, and CTA. Click
                “Run Rollover” to initiate the rollover. Typically this will
                occur between January and March depending upon the date the data
                is approved by the Federal Government.
              </p>
              <p className="u-margin-bottom">
                Teacher rollover should happen once a year in September. Before
                using the rollover button, you need to switch the open/close
                teachers toggle, under Admin management (submission management)
                to close.
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <FlexRow>
          <h2>Data Viz Rollover</h2>
        </FlexRow>
        <FlexRow>
          <div className="u-flex-grow-1" />

          <ConfirmationDialog
            disabled={!canExecute}
            confirmText="Rollover"
            cancelText="No, Don't Rollover"
            tooltip="roll over data vis"
            header="Are you sure you want to rollover?"
            onConfirm={handleRollover}
            activateButton={
              <Button
                disabled={!canExecute}
                className="confirm-button form__btn--submit"
                text="Run Rollover"
              />
            }
          >
            <div style={{ whiteSpace: 'normal' }}>
              <p>
                The Data Viz Rollover can only occur once per year. In order to
                rollover the data, you need federal approval of the current
                year’s student enrollment data and the previous year’s student
                follow-up data. Please ensure you have federal approval prior to
                initiating the rollover.
              </p>
            </div>
          </ConfirmationDialog>
        </FlexRow>
        <FlexRow>
          <h2>Teacher Rollover</h2>
        </FlexRow>
        <FlexRow>
          <div className="u-flex-grow-1" />

          <ConfirmationDialog
            disabled={teacherDisabled}
            confirmText="Run TeacherRollover"
            cancelText="No, Don't Rollover"
            tooltip="roll over Teacher data"
            header="Are you sure you want to rollover?"
            onConfirm={handleTeacherRollover}
            activateButton={
              <Button
                disabled={teacherDisabled}
                className="confirm-button form__btn--submit"
                text="Run Teacher Rollover"
              />
            }
          >
            <div style={{ whiteSpace: 'normal' }}>
              <p>Teacher Rollover should happen only once a year.</p>
            </div>
          </ConfirmationDialog>
        </FlexRow>
      </Paper>
    </SecureWrap>
  );
};
