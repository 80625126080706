import { PathwayCourse } from 'types/program';

export interface ProgramPathway {
  programCareerPathwayId: number;
  programInstanceId: number;
  careerPathwayId: number;
  careerPathwayName: string;
  credits: number;
  definitionCompleter: string;
  courses: PathwayCourse[];
  isMiddleSchool: boolean;
}

export const emptyProgramPathway = (): ProgramPathway => {
  return {
    programCareerPathwayId: -1,
    careerPathwayId: -1,
    careerPathwayName: '',
    programInstanceId: -1,
    courses: [],
    definitionCompleter: '',
    credits: 0,
    isMiddleSchool: false,
  };
};
