import React from 'react';
import { Deficiency } from 'types';
import { renderData } from 'util/objectUtility';
import { getPercent } from 'util/mathHelpers';

type DeficiencyTableTopProps = {
  deficiency: Deficiency;
};

export const DeficiencyTableTop: React.FC<DeficiencyTableTopProps> = (
  props
) => {
  const { deficiency } = props;

  return (
    <React.Fragment>
      <table className="table-summary deficiency__table">
        <tbody className="deficiency__table-body">
          <tr>
            <th className="deficiency__header-big" colSpan={3} scope="row">
              {renderData(`${deficiency.metricCode} ${deficiency.metricName}`)}
            </th>
          </tr>
          <tr>
            <td colSpan={3}>
              {renderData(getPercent(deficiency.currentYearTarget.percent))}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              {renderData(getPercent(deficiency.previousYearTarget.percent))}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              {renderData(getPercent(deficiency.stateActualLevel.percent))}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
