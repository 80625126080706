import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import {
  convertRadioToBoolValue,
  convertBoolToRadioValue,
  createRadioOptionsForBool,
} from 'util/formHelpers';
import { SnapshotStudent } from 'types';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Radio from 'components/Common/FormElements/Radio';

export const DemographicInformationPS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v } = props;

  const handleBooleanChange = (data: Partial<SnapshotStudent>) => {
    const updated = convertRadioToBoolValue<SnapshotStudent>(data);
    onChange(updated.data, updated.name, updated.val);
  };

  return student ? (
    <React.Fragment>
      <FlexRow>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="psDisability"
            iconName="info"
            label="PS Disability"
            tooltip="This field is used to determine if a student has an ADA handicapping condition."
          />
          <Radio
            border={true}
            errorMessage={v.getError('psDisability')}
            name="psDisability"
            noLabel={true}
            onBlur={() =>
              v.validate('psDisability', student.psDisability, student)
            }
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('psDisability')}
            value={convertBoolToRadioValue(student.psDisability)}
          />
        </FlexGroup>

        <FlexGroup>
          <LabelWithIcon
            htmlFor="outOfWorkforceIndividual"
            iconName="info"
            label="Out of Workforce Individual"
            tooltip="Out of Workforce is defined in the Postsecondary Guidance Documentation on the Student Enrollment Page."
          />
          <Radio
            border={true}
            errorMessage={v.getError('outOfWorkforceIndividual')}
            name="outOfWorkforceIndividual"
            noLabel={true}
            onBlur={() =>
              v.validate(
                'outOfWorkforceIndividual',
                student.outOfWorkforceIndividual,
                student
              )
            }
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('outOfWorkforceIndividual')}
            value={convertBoolToRadioValue(student.outOfWorkforceIndividual)}
          />
        </FlexGroup>
        <Radio
          border={true}
          errorMessage={v.getError('singleParent')}
          label="Single Parent"
          name="singleParent"
          onBlur={() =>
            v.validate('singleParent', student.singleParent, student)
          }
          onChange={handleBooleanChange}
          options={createRadioOptionsForBool()}
          valid={v.getFieldValid('singleParent')}
          value={convertBoolToRadioValue(student.singleParent)}
        />
        <FlexGroup>
          <LabelWithIcon
            htmlFor="disadvantaged"
            iconName="info"
            label="Economically Disadvantaged"
            tooltip="This field is used to determine if a student is a Pell recipient during the academic year."
            place="right"
          />
          <Radio
            border={true}
            errorMessage={v.getError('disadvantaged')}
            name="disadvantaged"
            noLabel={true}
            onBlur={() =>
              v.validate('disadvantaged', student.disadvantaged, student)
            }
            onChange={handleBooleanChange}
            options={createRadioOptionsForBool()}
            valid={v.getFieldValid('disadvantaged')}
            value={convertBoolToRadioValue(student.disadvantaged)}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
