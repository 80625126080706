import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
  getFormattedUrl,
} from 'services';
import { Voucher, ReimbursementSummary, VoucherSpendingCategory } from 'types';
import { ActualReimbursementAmounts } from 'types';

const VOUCHERS = ['api', 'vouchers'];
const CATEGORY = [...VOUCHERS, 'category'];
const VOUCHER_BY_TYPE = [...VOUCHERS, 'byType'];
const REIMBURSEMENT = [...VOUCHERS, 'reimbursement'];
const ACTUAL_REIMBURSEMENT = [...VOUCHERS, 'actualreimbursement'];
const VOUCHER_THREE = [...VOUCHERS, 'submitVoucherThree'];

export const readVouchers = async (
  grantFiscalYearId: number
): Promise<Voucher[]> => {
  const url = getFormattedUrl([...VOUCHERS, grantFiscalYearId]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Vouchers', err);
    });
};

export const readVoucher = async (
  grantFiscalYearId: number,
  voucherId: number
): Promise<Voucher[]> => {
  const url = getFormattedUrl([...VOUCHERS, grantFiscalYearId, voucherId]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Vouchers', err);
    });
};

export const readVoucherByType = async (
  grantFiscalYearId: number,
  voucherType: string
): Promise<Voucher[]> => {
  const url = getFormattedUrl([
    ...VOUCHER_BY_TYPE,
    grantFiscalYearId,
    voucherType,
  ]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Vouchers', err);
    });
};

export const createVoucherThreeQuestion = async (
  grantFiscalYearId: number,
  submitVoucherThree: boolean
): Promise<number> => {
  const url = getFormattedUrl([...VOUCHER_THREE, grantFiscalYearId]);
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ submitVoucherThree }),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createUpdateVoucher = async (
  grantFiscalYearId: number,
  voucher: Voucher
): Promise<number> => {
  const { voucherId } = voucher;
  const url = getFormattedUrl([...VOUCHERS, grantFiscalYearId, voucherId]);
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(voucher),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const readReimbursementRequestSummaryData = async (
  grantFiscalYearId: number
): Promise<ReimbursementSummary> => {
  const url = getFormattedUrl([...REIMBURSEMENT, grantFiscalYearId]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Vouchers', err);
    });
};

export const readActualReimbursementRequestData = async (
  grantFiscalYearId: number
): Promise<ActualReimbursementAmounts> => {
  const url = getFormattedUrl([...ACTUAL_REIMBURSEMENT, grantFiscalYearId]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('Vouchers', err);
    });
};

export const createVoucherCategoryUpdate = async (
  grantFiscalYearId: number,
  voucherId: number,
  spendingCategory: VoucherSpendingCategory
) => {
  const url = getFormattedUrl([...CATEGORY, grantFiscalYearId, voucherId]);
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(spendingCategory),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};
