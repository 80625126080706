import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import {
  ImprovementPlan,
  Deficiency,
  AzureUploadParameters,
  Question,
} from 'types';
import { map } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { QuestionComponent } from 'components/Common/Questions/Question';
import { mergeObjects } from 'util/objectUtility';
import { isApplicaionIdValid } from 'util/global';
import { useParams } from 'react-router-dom';
import { ImprovementPlanProps } from './ImprovementPlanConnect';
import { toast } from 'react-toastify';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import PerformanceData from './PerformanceData';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

export const ImprovementDeficienciesComponent: React.FC<ImprovementPlanProps> = (
  props
) => {
  const {
    isLoading,
    improvementPlan,
    loadImprovementPlan,
    saveImprovementPlan,
    setCurrentNavAnchor,
    isLocked,
  } = props;
  const [
    improvementPlanState,
    setImprovementPlanState,
  ] = useState<ImprovementPlan>(improvementPlan);

  useEffect(() => {
    setCurrentNavAnchor('improvement-plan');
  }, [setCurrentNavAnchor]);

  const handleChange = (question: Question, item: Deficiency) => {
    const newItem = mergeObjects<Deficiency>(item, { question });

    const deficiencies = upsertInPlace(
      improvementPlanState.deficiencies,
      newItem,
      'metricCode'
    );
    const updatedState = mergeObjects<ImprovementPlan>(improvementPlanState, {
      deficiencies,
    });
    setImprovementPlanState(updatedState);
  };

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadImprovementPlan(grantFiscalYearId);
    }
  }, [loadImprovementPlan, grantFiscalYearId]);

  useEffect(() => {
    if (improvementPlan) {
      setImprovementPlanState(improvementPlan);
    }
  }, [improvementPlan]);

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: 'unused',
  };

  const handleSave = () => {
    return saveImprovementPlan(grantFiscalYearId, improvementPlanState).then(
      () => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      }
    );
  };

  return (
    <React.Fragment>
      <Loading isActive={isLoading} />
      <SecureWrap isLocked={isLocked} component="GrantsImprovementPlan">
        <ScrollToTop />
        <Paper>
          <Instruction title="Deficiencies This Year">
            <p>
              Sites falling below 90% of state targets or have performance gaps
              of 30% or more in student demographics must create an improvement
              plan for each affected area. The data below details which areas
              need an improvement plan. Use “Show All Data” to see detailed info
              on all "Perkins V" indicators.
            </p>
          </Instruction>
        </Paper>
        <Paper>
          <FlexRow>
            {!isLoading && (
              <PerformanceData improvementPlan={improvementPlanState} />
            )}
          </FlexRow>
          <FlexRow>
            <Instruction title="Question About Performance Deficiencies">
              <p>
                Describe the strategies that will be used to address these
                deficiencies or gaps in performance. If some progress has been
                made in performance since the data set available, please provide
                that information and include it in this reflection.
              </p>
            </Instruction>
          </FlexRow>
          {map(
            improvementPlanState.deficiencies,
            (item: Deficiency, index: Number) => {
              return (
                <FlexRow key={index}>
                  <FlexGroup>
                    <QuestionComponent
                      question={item.question}
                      onChange={(question: Question) => {
                        handleChange(question, item);
                      }}
                      uploadParams={uploadParams}
                    />
                  </FlexGroup>
                </FlexRow>
              );
            }
          )}
        </Paper>
        <Paper>
          <SaveButtons
            hideCancel
            saveData={handleSave}
            saveText="Save Improvement Plan"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
