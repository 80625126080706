import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { VouchersEditComponent } from './VouchersEdit.component';
import { VouchersSummaryComponent } from './VouchersSummary.component';
import {
  getIsDataFetching_Vouchers,
  getIsDataFetching_ActualReimbursement,
  getIsDataFetching_ReimbursementSummary,
} from 'redux/grants/_selectors';
import { loadVouchers } from 'redux/grants/vouchers';
import { some } from 'lodash';

export const mapStateToProps = (state: any, ownProps: any) => {
  const isLoading = some([
    getIsDataFetching_Vouchers(state),
    getIsDataFetching_ActualReimbursement(state),
    getIsDataFetching_ReimbursementSummary(state),
  ]);

  return {
    isLoading,
  };
};

const dispatchToProps = {
  loadVouchers,
  setCurrentNavAnchor,
};

export const VouchersEdit = connect(
  mapStateToProps,
  dispatchToProps
)(VouchersEditComponent);

export const VouchersSummary = connect(
  mapStateToProps,
  dispatchToProps
)(VouchersSummaryComponent);
