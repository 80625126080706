import {
  savePermissionRoleComponent,
  loadPermissionRoleComponents,
} from 'redux/permissions/permissionrolecomponent';
import {
  savePermissionComponent,
  loadPermissionComponents,
} from 'redux/permissions/permissioncomponents';
import {
  savePermissionTopic,
  loadPermissionTopics,
} from 'redux/permissions/permissiontopics';
import {
  savePermissionRole,
  loadPermissionRoles,
} from 'redux/permissions/permissionroles';

export const permissionsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case savePermissionRoleComponent.SUCCEEDED: {
      dispatch(loadPermissionRoleComponents());
      break;
    }
    case savePermissionComponent.SUCCEEDED: {
      dispatch(loadPermissionComponents());
      break;
    }
    case savePermissionTopic.SUCCEEDED: {
      dispatch(loadPermissionTopics());
      break;
    }
    case savePermissionRole.SUCCEEDED: {
      dispatch(loadPermissionRoles());
      break;
    }
  }
};
