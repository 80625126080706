import { connect } from 'react-redux';
import { ArticulationsPage } from './Articulations.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programId: number = Number(ownProps.match.params.programId);

  return {
    programId,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ArticulationsPage);
