import { COMMON_KEY, COMMON_PROGRAM_STATES_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { ProgramState, ItemFilter } from 'types';

export const PROGRAM_STATE_PATH = [COMMON_KEY, COMMON_PROGRAM_STATES_KEY];

export const getIsDataFetching_ProgramStates = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_STATE_PATH);
};

export const getRecords_ProgramStates = (state: any): ProgramState[] => {
  return getRecords<ProgramState>(state, PROGRAM_STATE_PATH);
};

export const shouldRefreshReduxState_ProgramStates = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramState>(state, PROGRAM_STATE_PATH);
};

export const getLastUpdated_ProgramStates = (state: any): number => {
  return getLastUpdated(state, PROGRAM_STATE_PATH);
};

export const getFilteredRecords_ProgramStates = (
  state: any,
  filter: ItemFilter
): ProgramState[] => {
  return getFilteredRecords<ProgramState>(state, PROGRAM_STATE_PATH, filter);
};
