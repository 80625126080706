import {
  basetype,
  ConsortiumMember,
  FiscalAgent,
  FiscalYear,
  emptyFiscalAgent,
  emptyFiscalYear,
} from 'types';

export interface Consortium extends basetype {
  grantFiscalYearId: number;
  comment: string;
  consortiumId: number;
  consortiumName: string;
  editable: boolean;
  fiscalAgent: FiscalAgent;
  fiscalYear: FiscalYear;
  standalone: boolean;
  status: boolean;
  members: ConsortiumMember[];
}

export const emptyConsortium = (): Consortium => {
  return {
    grantFiscalYearId: 0,
    comment: '',
    consortiumId: -1,
    consortiumName: '',
    editable: false,
    fiscalAgent: emptyFiscalAgent(),
    fiscalYear: emptyFiscalYear(),
    status: true,
    standalone: false,
    members: [],
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
  };
};
