export const GRANT_FOUR_YEAR_ADDRESSABLE_ELEMENTS =
  '[GRANT_FOUR_YEAR_ADDRESSABLE_ELEMENTS]';

export const FETCH_FOUR_YEAR_ADDRESSABLE_ELEMENTS = `${GRANT_FOUR_YEAR_ADDRESSABLE_ELEMENTS} FETCH`;
export const SAVE_FOUR_YEAR_ADDRESSABLE_ELEMENTS = `${GRANT_FOUR_YEAR_ADDRESSABLE_ELEMENTS} SAVE`;

export const loadFourYearAddressableElements = (entityType: string) => (
  entityId: number
) => {
  return {
    type: FETCH_FOUR_YEAR_ADDRESSABLE_ELEMENTS,
    payload: {
      entityType,
      entityId,
    },
  };
};
