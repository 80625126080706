import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCTASpecialists,
  updateCTASpecialists,
  deleteCTASpecialists,
} from 'services/cta/administrativecosts.services';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';

const initialState = baseInitialState;

export const loadCTASpecialists = createActionThunk(
  'loadCTASpecialists',
  (ctaId: number) => {
    return readCTASpecialists(ctaId);
  }
);
export const saveCTASpecialists = createActionThunk(
  'saveCTASpecialists',
  (item: CTAAdministrator) => {
    return updateCTASpecialists(item);
  }
);

export const removeCTASpecialists = createActionThunk(
  'removeCTASpecialists',
  (item: CTAAdministrator) => {
    return deleteCTASpecialists(item);
  }
);

export default handleActions<BaseStateObject, CTAAdministrator[]>(
  {
    ...baseLoadActionBuilder<CTAAdministrator[]>(loadCTASpecialists),

    ...baseSaveActionBuilder(saveCTASpecialists),
    ...baseSaveActionBuilder(removeCTASpecialists),
  },
  initialState
);
