import React from 'react';
import PermissionRoleManagement from 'components/Admin/PermissionRoleManagement';
import AdminLayout from 'layouts/AdminLayout';

export const PermissionRolesPage: React.FC = () => {
  return (
    <AdminLayout>
      <PermissionRoleManagement />
    </AdminLayout>
  );
};
