import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import AdminRollover from 'components/DataCollection/AdminRollover';
import { DataCollectionLayout } from 'layouts/DataCollectionLayout/DataCollection.layout';

export const RolloverPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <AdminRollover />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
