import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { ImprovementOverview } from './ImprovementOverview.component';
import { getFirstRecord_ImprovementPlan } from 'redux/grants/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const plan = getFirstRecord_ImprovementPlan(state);
  return {
    plan,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ImprovementOverview);
