import { createActionThunk } from 'redux-thunk-actions';
import { createAction, handleActions } from 'redux-actions';
import {
  readProgramPathway,
  createProgramPathway,
} from 'services/programs/careerpathwaycourse.service';
import { ProgramPathway } from 'types';
import { actionFetching } from 'redux/utility/_exports';

const initialState = {};

export const loadProgramPathway = createActionThunk(
  'loadProgramPathway',
  (programInstanceId: number) => {
    return readProgramPathway(programInstanceId);
  }
);

export const saveProgramPathway = createActionThunk(
  'saveProgramPathway',
  (payload: ProgramPathway[], programInstanceId: number) => {
    return createProgramPathway(payload, programInstanceId);
  }
);

export const setProgramPathwayCache = createAction('setProgramPathwayCache');
export const addProgramCourseCreditsToCache = createAction(
  'addProgramCourseCreditsToCache'
);
export const updateProgramPathwayCache = createAction(
  'updateProgramPathwayCache'
);
export const cleanProgramPathwayCache = createAction(
  'cleanProgramPathwayCache'
);
export const resetProgramPathwayCache = createAction(
  'resetProgramPathwayCache'
);

export default handleActions(
  {
    [loadProgramPathway.START]: (state) => {
      return actionFetching(state, true);
    },

    [loadProgramPathway.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    },

    setProgramPathwayCache: (state: any, { payload }: any) => {
      const { programInstanceId, records } = payload;
      return {
        ...state,
        [programInstanceId]: {
          records,
          isDirty: false,
        },
      };
    },

    addProgramPathwayToCache: (state: any, { payload }: any) => {
      const { programInstanceId } = payload;
      const cache = state[programInstanceId];
      return {
        ...state,
        [programInstanceId]: {
          records: [...cache.records, ...payload],
          isDirty: true,
        },
      };
    },

    updateProgramPathwayCache: (state: any, { payload }: any) => {
      const { programInstanceId, records } = payload;
      return {
        ...state,
        [programInstanceId]: {
          records,
          isDirty: true,
        },
      };
    },

    cleanProgramPathwayCache: (state: any, { payload }: any) => {
      const { programInstanceId } = payload;
      return {
        ...state,
        [programInstanceId]: {
          ...state[programInstanceId],
          isDirty: false,
        },
      };
    },

    resetProgramPathwayCache: (state: any, { payload }: any) => {
      delete state[payload];
      return { ...state };
    },
  },
  initialState
);
