import { useValidation } from 'de-formed-validations';
import { ProgramArticulation } from 'types';
import { isStringValid } from 'util/validation.utility';

export const ArticulationValidations = () => {
  return useValidation<ProgramArticulation>({
    schoolId: [
      {
        errorMessage: 'Institution or Business Partner is required.',
        validation: (val: number, _) => {
          return val > 0;
        },
      },
    ],
    businessName: [
      {
        errorMessage: 'Business name is required.',
        validation: (val: string, state: ProgramArticulation) => {
          if (state.schoolId > 0) {
            return true;
          }
          return val === undefined || isStringValid(val);
        },
      },
    ],
    contactId: [
      {
        errorMessage: 'Contact must be provided.',
        validation: (val: number, _) => {
          return val > 0;
        },
      },
    ],
    year: [
      {
        errorMessage: 'Must be a 4-digit year.',
        validation: (val: string, _) => {
          return val.trim().length === 4 && !isNaN(Number(val));
        },
      },
    ],
    isCurrent: [
      {
        errorMessage: 'Articulation must be current.',
        validation: (val: boolean, _) => {
          return val;
        },
      },
    ],
    filePath: [
      {
        errorMessage: 'Articulation agreement is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
  });
};
