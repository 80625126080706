export interface AdminBooksAndSupplies {
  booksAndSuppliesTotalCost: number;
  dctsTotalCost: number;
  dcts: boolean;
  supportingDoc: string;
}

export const emptyAdminBooksAndSupplies = () => {
  return {
    booksAndSuppliesTotalCost: 0,
    dctsTotalCost: 0,
    dcts: false,
    supportingDoc: '',
  };
};
