import { useState, useEffect } from 'react';
import { map } from 'lodash';
import { objectToArray } from 'util/arrayUtility';

interface ValidationArray<T> {
  key: keyof T;
  value: unknown;
}

interface ErrorMessages {
  [key: string]: string;
}

interface ValidationFunction {
  (val: any, state: any): boolean | string | number;
}

interface ValidationProps {
  errorMessage: string;
  validation: ValidationFunction;
}

export interface ValidationState {
  [key: string]: boolean;
}

interface ValidationSchema {
  [key: string]: ValidationProps;
}

export interface ValidationData {
  errorMessages: ErrorMessages;
  isValid: boolean;
  reset: Function;
  validate: Function;
  validateAll: Function;
  validateIfTrue: Function;
  validateIfFalse: Function;
  validationState: ValidationState;
  validateMultiple: Function;
}

/**
 * A hook that can be used to generate an object containing functions and
 * properties pertaining to the validation state provided.
 * @param validationSchema an object containing all the properties you want to validate
 * @returns isValid True if all properties in validation state are true
 * @returns validationState True/false state of all keys
 * @returns validate Function
 * @returns validateIfTrue Function
 * @returns validateIfFalse Function
 * @returns validateAll Function
 * @returns errorMessages Object of all error messages organized by key
 */
export const useValidation = <T, S>(validationSchema: ValidationSchema) => {
  // Error State Boolean
  const [isValid, setIsValid] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  // Build Error Messages Property
  const errorMessages: ErrorMessages = objectToArray(validationSchema).reduce(
    (acc: any, item: string | number) => {
      acc[item] = validationSchema[item as string].errorMessage;
      return acc;
    },
    {}
  );

  // Build Validation State Object
  const createValidationsState = (): T => {
    return objectToArray(validationSchema).reduce(
      (acc: any, item: string | number) => {
        acc[item] = true;
        return acc;
      },
      {}
    );
  };

  const [validationState, setValidationState] = useState<T>(
    createValidationsState()
  );

  /**
   * executes a reset function to reset to a new validaion state
   */
  const reset = () => {
    setValidationState(createValidationsState());
  };

  // Build Validation Logic Object
  const vFunc = () => {
    return objectToArray(validationSchema).reduce(
      (acc: any, key: string | number) => {
        acc[key] = validationSchema[key].validation;
        return acc;
      },
      []
    );
  };
  const allValidators = vFunc();

  // helper function to determine if all validations are true.
  const allValid = (whiteChocolate: T = validationState) => {
    function checkTrue(bool: boolean) {
      return bool && true;
    }
    setValidationErrors([]);
    const valid = objectToArray(whiteChocolate).reduce(
      (acc: any, key: keyof T) => {
        acc = [...acc, whiteChocolate[key]];
        return acc;
      },
      []
    );
    return valid.every(checkTrue);
  };

  // heler function to update array of error messages
  const generateValidationErrors = (whiteChocolate: T = validationState) => {
    return objectToArray(whiteChocolate).reduce((acc: any, key: keyof T) => {
      if (!whiteChocolate[key]) {
        acc = [...acc, errorMessages[key as any]];
      }
      return acc;
    }, []);
  };

  /**
   * executes a validation function on a value and updates isValid state
   * @param key string the name of the property being validated
   * @param value any the value to be tested for validation
   * @return true/false validation
   */
  const validate = (key: keyof T, value: unknown, state?: S) => {
    if (key in allValidators) {
      const updatedValidations: T = { ...validationState };
      const valid = allValidators[key](value, state);
      updatedValidations[key as keyof T] = valid;
      setValidationState(updatedValidations);
      return valid;
    }
  };

  /**
   * updates isValid state if validation succeeds
   * @param key string the name of the property being validated
   * @param value any the value to be tested for validation
   * @return void
   */
  const validateIfTrue = (key: keyof T, value: unknown, state?: S) => {
    if (key in allValidators) {
      if (allValidators[key](value, state)) {
        const updatedValidations: any = { ...validationState };
        updatedValidations[key] = true;
        setValidationState(updatedValidations);
      }
    }
  };

  /**
   * updates isValid state if validation fails
   * @param key string the name of the property being validated
   * @param value any the value to be tested for validation
   * @return void
   */
  const validateIfFalse = (key: keyof T, value: unknown, state?: S) => {
    if (key in validate) {
      if (!allValidators[key](value, state)) {
        const updatedValidations: any = { ...validationState };
        updatedValidations[key] = false;
        setValidationState(updatedValidations);
      }
    }
  };

  /**
   * Runs all validations against an object with all values and updates/returns
   * isValid state.
   * @param state any an object that contains all values to be validated
   * @return boolean isValid state
   */
  const validateAll = (state: S): boolean => {
    const local: any = {};
    const updatedValidations = objectToArray(validationState).reduce(
      (acc: any, key: any) => {
        acc[key] = allValidators[key](state[key as keyof S], state);
        local[key] = allValidators[key](state[key as keyof S], state);
        return acc;
      },
      {}
    );
    setValidationState({ ...updatedValidations });
    return allValid(local);
  };

  /**
   * updates isValid state if validation succeeds
   * @param key[] string the name of the property being validated
   * @param value any the value to be tested for validation
   * @return void
   */
  const validateMultiple = (items: ValidationArray<T>[], state?: S) => {
    let whiteChocolate: any = { ...validationState };
    map(items, (item) => {
      if (item.key in allValidators) {
        whiteChocolate[item.key] = allValidators[item.key](item.value, state);
      }
    });
    setValidationState(whiteChocolate);
  };

  // whenever validationState changes, update
  // TODO: @prescott this is nasty
  useEffect(() => {
    setIsValid(allValid());
    setValidationErrors(generateValidationErrors(validationState));
  }, [setValidationErrors, setIsValid, validationState]); //eslint-disable-line

  return {
    errorMessages,
    isValid,
    reset,
    validate,
    validateAll,
    validateIfTrue,
    validateIfFalse,
    validationErrors,
    validationState,
    validateMultiple,
  };
};
