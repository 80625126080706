import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { EmailTemplate } from 'types';

const CONTROLLER = 'api/emailmanagement/';
const SUMMARY = `${CONTROLLER}summary/`;
const RECIPIENTS = `${CONTROLLER}recipients/`;
const SEND_EMAIL = `${CONTROLLER}send/`;

export const readEmailTemplates = async (fiscalYear: number) => {
  const url = `${ENVIRONMENT.API_URL}${SUMMARY}${fiscalYear}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('EmailManagement', err);
    });
};

export const readSingleEmailTemplate = async (
  fiscalYear: number,
  templateId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${SUMMARY}${fiscalYear}/${templateId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('EmailManagement', err);
    });
};

export const postRecipients = async (
  fiscalYear: number,
  emailTemplate: EmailTemplate
) => {
  const { templateId } = emailTemplate;
  const url = `${ENVIRONMENT.API_URL}${RECIPIENTS}${fiscalYear}/${templateId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(emailTemplate),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('EmailManagement', err);
    });
};

export const sendEmails = async (
  fiscalYear: number,
  templateId: number,
  comment: string
) => {
  const url = `${ENVIRONMENT.API_URL}${SEND_EMAIL}${fiscalYear}/${templateId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ comment }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('EmailManagement', err);
    });
};
