import { combineReducers } from 'redux';
import {
  PERMISSIONS_ROLES_KEY,
  PERMISSIONS_TOPICS_KEY,
  PERMISSIONS_COMPONENTS_KEY,
  PERMISSIONS_TYPES_KEY,
  PERMISSIONS_ROLE_COMPONENTS_KEY,
  PERMISSIONS_CONTACTS_ROLES,
  USER_PERMISSIONS_KEY,
} from 'redux/keys';
import contactpermissionroles from './contactpermissionroles';
import permissionroles from './permissionroles';
import permissiontopics from './permissiontopics';
import permissioncomponents from './permissioncomponents';
import permissiontypes from './permissiontypes';
import permissionrolecomponents from './permissionrolecomponent';
import userpermissions from './userpermissions';

export default combineReducers({
  [USER_PERMISSIONS_KEY]: userpermissions,
  [PERMISSIONS_COMPONENTS_KEY]: permissioncomponents,
  [PERMISSIONS_CONTACTS_ROLES]: contactpermissionroles,
  [PERMISSIONS_ROLE_COMPONENTS_KEY]: permissionrolecomponents,
  [PERMISSIONS_ROLES_KEY]: permissionroles,
  [PERMISSIONS_TOPICS_KEY]: permissiontopics,
  [PERMISSIONS_TYPES_KEY]: permissiontypes,
});
