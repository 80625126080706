import { GRANTS_KEY, GRANTS_EXPENSE_PROGRAMS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { ExpenseProgram } from 'types';

export const EXPENSE_PROGRAMS_PATH = [GRANTS_KEY, GRANTS_EXPENSE_PROGRAMS_KEY];

export const getIsDataFetching_ExpenseProgram = (state: any): boolean => {
  return getIsDataFetching(state, EXPENSE_PROGRAMS_PATH);
};

export const getRecords_ExpenseProgram = (state: unknown): ExpenseProgram[] => {
  return getRecords(state, EXPENSE_PROGRAMS_PATH);
};
