import {
  UNIVERSAL_CONTACTS_KEY,
  UNIVERSAL_CONTACTS_CONTACTS,
} from 'redux/keys';
import { curry, some, defaultTo, filter, find } from 'lodash';
import {
  getIsDataFetching,
  getLastUpdated,
  shouldRefreshReduxState,
  getActiveRecords,
  getFilteredRecords,
  getRecords,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import ApplicationState from 'types/applicationstate.types';
import { ItemFilter, UniversalContact, emptyUniversalContact } from 'types';
import { getUniversalContactFilters } from 'redux/filters/universalcontactfilter.selectors';

export const UNIVERSAL_CONTACTS_PATH = [
  UNIVERSAL_CONTACTS_KEY,
  UNIVERSAL_CONTACTS_CONTACTS,
];

export const getIsDataFetching_UniversalContact = (state: any): boolean => {
  return getIsDataFetching(state, UNIVERSAL_CONTACTS_PATH);
};

export const getLastUpdated_UniversalContact = (state: any): number => {
  return getLastUpdated(state, UNIVERSAL_CONTACTS_PATH);
};

export const getRecords_UniversalContact = (state: any): UniversalContact[] => {
  return getRecords<UniversalContact>(state, UNIVERSAL_CONTACTS_PATH);
};

export const shouldRefreshReduxState_UniversalContact = (
  state: any
): boolean => {
  return shouldRefreshReduxState<UniversalContact>(
    state,
    UNIVERSAL_CONTACTS_PATH
  );
};

export const getFilteredRecords_UniversalContact = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<UniversalContact>(
    state,
    UNIVERSAL_CONTACTS_PATH,
    itemFilter
  );
};

// TODO: JSDocs
export const getFilteredUniversalContacts = (
  state: any,
  exclude: UniversalContact[]
): UniversalContact[] => {
  return filter(
    getActiveRecords<UniversalContact>(state, UNIVERSAL_CONTACTS_PATH),
    (contact) => {
      return !some(exclude, (toexclude: UniversalContact) => {
        return toexclude.universalContactId === contact.universalContactId;
      });
    }
  );
};

export const getAdvancedFilteredRecords_UniversalContacts = (
  state: any
): UniversalContact[] => {
  const filters = getUniversalContactFilters(state);
  const records = getAdvancedFilteredRecords<UniversalContact>(
    state,
    UNIVERSAL_CONTACTS_PATH,
    filters
  );
  return records;
};

export const cGetContact = curry((userId: number, state: ApplicationState) => {
  return getContact(state, userId);
});

// TODO: JSDocs
export const getContact = (
  state: ApplicationState,
  userid: number
): UniversalContact => {
  const allContacts = getRecords_UniversalContact(state);
  const user = defaultTo(
    find(allContacts, (value: UniversalContact) => {
      return value.universalContactId === userid;
    }),
    {
      ...emptyUniversalContact(),
      credentialExpirationDate: undefined,
    }
  );
  if (user.universalContactEmail && user.universalContactEmail.length <= 0) {
    user.universalContactEmail.push({ email: '' });
  }
  if (user.universalContactEmail && user.universalContactPhone.length <= 0) {
    user.universalContactPhone.push({ label: 'Phone', number: '' });
  }
  return user;
};
