import React, { useEffect, useState } from 'react';
import {
  CTAAdminInformation,
  CTAAllocation,
  emptyCTAAdminInformation,
  emptyCTAAllocation,
  FiscalYear,
} from 'types';
import { find, defaultTo, some } from 'lodash';
import { toast } from 'react-toastify';
import { readGeneralReport } from 'services/cta';
import { doNothing } from 'util/global';
import { FlexGroup, FlexRow } from 'layouts';
import Button from 'components/Common/FormElements/Button';
import download from 'js-file-download';
import HotYear from 'components/Common/HotYear';
import Instruction from 'components/Common/Instruction';
import Input from 'components/Common/FormElements/Input';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import PerPupilOperatingRevenueComponent from './PerPupilOperatingRevenue';
import ToastNotification from 'components/Common/Toast';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';

export type CTAAdministrationProps = {
  adminRecords: CTAAdminInformation[];
  loadCTAAdminInformation: Function;
  loadCTAAllocation: Function;
  saveCTAAllocation: Function;
  saveApprovalEmail: Function;
  runCalculations: Function;
  currentCTAYear: FiscalYear;
};

export const CTAAdministrationComponent: React.FC<CTAAdministrationProps> = (props) => {
  const {
    loadCTAAdminInformation,
    loadCTAAllocation,
    adminRecords,
    currentCTAYear,
    saveCTAAllocation,
    saveApprovalEmail,
    runCalculations,
  } = props;

  // --[ local state ]---------------------------------------------------------
  const maxFunds = 1000000000;
  const [currentYear, setCurrentYear] = useState<number>(-1);
  const [activeYear, setActiveYear] = useState<number>(-1);
  const [allocation, setAllocation] = useState<CTAAllocation>(emptyCTAAllocation());

  // --[ component logic ]-----------------------------------------------------
  const currentAdminRecord = defaultTo(
    find(adminRecords, (item: CTAAdminInformation) => {
      return item.year === currentYear;
    }),
    emptyCTAAdminInformation()
  );

  const updateAllocation = (item: Partial<CTAAllocation>) => {
    setAllocation({ ...allocation, ...item });
  };

  const downloadReport = () => {
    readGeneralReport(activeYear)
      .then((blob: unknown) => {
        download(blob as Blob, `${activeYear}_general_report.xlsx`);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  const saveFinancials = () => {
    saveCTAAllocation(allocation)
      .then(() => {
        toast(
          <ToastNotification status="success" message={StringResources.Success.DataSaved} />
        );
      })
      .catch(doNothing);
  };

  // --[ lifecycle logic ]-----------------------------------------------------
  useEffect(() => {
    loadCTAAdminInformation().catch(doNothing);
  }, [loadCTAAdminInformation]);

  useEffect(() => {
    loadCTAAllocation(activeYear)
      .then((resp: CTAAllocation) => {
        setAllocation(resp);
      })
      .catch(doNothing);
  }, [activeYear, loadCTAAllocation]);

  useEffect(() => {
    setCurrentYear(currentCTAYear.year);
    setActiveYear(currentCTAYear.year);
  }, [currentCTAYear]);

  // --[ display logic ]-------------------------------------------------------
  const maxExceeded = (): boolean => {
    return some([allocation.carryOver > maxFunds, allocation.stateAllocation > maxFunds]);
  };

  const getDisabled = (): boolean => {
    return some([maxExceeded(), allocation.stateAllocation === 0]);
  };

  const isCurrentYear = () => {
    return activeYear === currentCTAYear.year;
  };

  return (
    <SecureWrap component={['ctaadmin']}>
      <div className="u-flex">
        <Loading isActive={false} />
        <div className="u-flex-grow-1">
          <Paper>
            <HotYear
              title="Select Reporting Year"
              year={currentYear}
              activeYear={activeYear}
              prefix="Cost Incurred"
              handleClick={setActiveYear}
            />
          </Paper>
          {isCurrentYear() && (
            <Paper>
              <PerPupilOperatingRevenueComponent adminRecord={currentAdminRecord} />
            </Paper>
          )}
          <Paper>
            <Instruction title="Financials">
              <p>
                Enter the state CTA allocation for the current year and any prior year
                carryover funding. Click on the ‘Save’ button once these amounts are finalized.
                Upon saving, these amounts will be visible to all districts and charter schools
                in their reporting area.
              </p>
            </Instruction>

            <FlexRow>
              <Input
                disabled={!isCurrentYear()}
                max={maxFunds}
                min={0}
                step={1}
                type="number"
                value={allocation.stateAllocation}
                name="stateAllocation"
                onChange={updateAllocation}
              />
              <Input
                disabled={!isCurrentYear()}
                max={maxFunds}
                min={0}
                step={1}
                type="number"
                value={allocation.carryOver}
                name="carryOver"
                onChange={updateAllocation}
              />
              <FlexGroup />
              {isCurrentYear() && (
                <Button
                  disabled={getDisabled()}
                  style={{ alignSelf: 'flex-end' }}
                  onClick={saveFinancials}
                  text="Save"
                  className="button--outline form__btn--submit t-save-financials"
                />
              )}
            </FlexRow>
            {maxExceeded() && (
              <p className="u-color-red" style={{ marginTop: '.4rem' }}>
                Neither State or Carry Over funds can exceed $1,000,000,000.00
              </p>
            )}
          </Paper>

          {isCurrentYear() && (
            <Paper>
              <Instruction title="Run CTA Reimbursement Calculation">
                <p>
                  The ’Run Calculation‘ button will not be operational until all district
                  reports are coded as ‘No Report’ or ‘Approved.’ Once this occurs, the button
                  will turn teal allowing you to run the calculation. Click the ‘Download
                  General Report’ button to view the results. If desired, you may run the
                  calculation multiple times. When you have finalized the CTA Reimbursement and
                  the appropriate CCCS staff have internally documented the review, click on
                  the ‘Send Email to Approved Districts’ button to alert districts that they
                  may now login to check their reimbursement amounts. Do not click to send
                  email until you have confirmed the CTA reimbursement calculation is final.
                  Sending the email locks the amounts in the site and the amounts are also
                  included in the email to districts.
                </p>
                <br />
                <p>
                  Note that after completing the CTA cycle for the year (sending the CTA
                  reimbursement email to approved districts at the bottom of this form), the
                  rollover process for the site will occur at midnight on April 1st in
                  preparation for the next reporting year. If you have questions or need
                  assistance, please contact the CTE Director of Accountability.
                </p>
              </Instruction>
              <div className="cta-grid-row u-margin-bottom--large">
                <Button
                  disabled={some([
                    !currentAdminRecord.reportsComplete,
                    !currentAdminRecord.submissionsOpen,
                  ])}
                  onClick={() => {
                    runCalculations(currentYear)
                      .then(() => {
                        loadCTAAdminInformation().then(() => {
                          toast(
                            <ToastNotification
                              status="success"
                              message={StringResources.Success.DataSaved}
                            />
                          );
                        });
                      })
                      .catch(doNothing);
                  }}
                  text="Run Calculation"
                  className="button--outline cta-grid-button"
                />
                <Button
                  onClick={downloadReport}
                  text="Download General Report"
                  className="button--outline cta-grid-button"
                />

                <ConfirmationDialog
                  tooltip="Send Email to Approved Districts"
                  header="Send Email to Approved Districts?"
                  onConfirm={() => {
                    saveApprovalEmail(currentYear)
                      .then(() => {
                        loadCTAAdminInformation();
                      })
                      .catch(doNothing);
                  }}
                  activateButton={
                    <Button
                      disabled={some([
                        !currentAdminRecord.calculationComplete,
                        !currentAdminRecord.submissionsOpen,
                      ])}
                      onClick={() => doNothing}
                      text="Send Email to Approved Districts"
                      className="button--outline cta-grid-button"
                    />
                  }
                >
                  <p>
                    Sending this email is final and will email all approved districts to login
                    and check their reimbursement amounts.
                  </p>
                </ConfirmationDialog>
              </div>
            </Paper>
          )}
        </div>
        {/*this div is for the side menu */}
        <div />
      </div>
    </SecureWrap>
  );
};
