import { useValidation } from 'de-formed-validations';
import { CTASchool } from 'types';
import { gt as lt, lt as gt } from 'ramda';

export const CTASchoolValidation = () => {
  return useValidation<CTASchool>({
    credits: [
      {
        errorMessage: 'Credits must be less than 100.',
        validation: lt(100),
      },
      {
        errorMessage: 'Credits must be greater than 0.',
        validation: gt(0),
      },
      {
        errorMessage: 'Credits cannot have more than one decimal place.',
        validation: (val: number) => (val * 10) % 1 === 0,
      },
    ],
  });
};
