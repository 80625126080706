import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React from 'react';
import { NewRoleModal } from './NewRoleModal';

export const CreateNewRoleComponent: React.FC = () => {
  return (
    <Paper>
      <Instruction title="Application Roles" actionButton={<NewRoleModal />}>
        <p>This page enables you to create | edit application roles.</p>
      </Instruction>
    </Paper>
  );
};
