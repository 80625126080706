import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import MemberIntentComponent from 'components/Grants/IntentToParticipate/MemberIntent';

export const MemberResponsePage: React.FC = (props) => {
  return (
    <BaseLayout>
      <MemberIntentComponent />
    </BaseLayout>
  );
};
