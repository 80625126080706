import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAHosting } from 'types';
import { readCTAHostingContracts } from 'services/cta/ctahosting.services';

const initialState = baseInitialState;

export const loadCTAHosting = createActionThunk(
  'loadCTAHosting',
  (ctaId: number) => {
    return readCTAHostingContracts(ctaId);
  }
);

export default handleActions<BaseStateObject, CTAHosting[]>(
  {
    ...baseLoadActionBuilder<CTAHosting[]>(loadCTAHosting),
  },
  initialState
);
