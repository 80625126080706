import React, { useState } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { every } from 'lodash';
import { UniversalContact } from 'types';
import { isContactPowerRole } from 'util/universalcontact.utility';
import Toggle from 'components/Common/FormElements/Toggle';
import {
  deactivateUser,
  reactivateUser,
} from 'services/universalcontacts/universalcontact.services';
import ToastNotification from 'components/Common/Toast';
import { toast } from 'react-toastify';
import StringResources from 'StringResources';

type UserEnableToggleProps = {
  contact: UniversalContact;
  loggedInUser: UniversalContact;
  onChange: Function;
};

export const UserEnableToggle: React.FC<UserEnableToggleProps> = (props) => {
  const { contact, onChange, loggedInUser } = props;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleActiveUserToggle = (data: any, key: any, value: any) => {
    setIsSaving(true);
    if (value) {
      reactivateUser(contact.universalContactId)
        .then(() => {
          onChange(data, key, value);
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      deactivateUser(contact.universalContactId)
        .then(() => {
          onChange(data, key, value);
        })
        .catch((err: any) =>
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          )
        )
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const isToggleReadOnly = !every([
    isContactPowerRole(loggedInUser),
    loggedInUser.universalContactId !== contact.universalContactId,
  ]);

  return (
    <React.Fragment>
      {isContactPowerRole(loggedInUser) && (
        <FlexGroup>
          <FlexRow>
            <Toggle
              description="This is an active user account."
              name="isActive"
              value={contact.isActive}
              onChange={handleActiveUserToggle}
              noLabel={true}
              disabled={isToggleReadOnly || isSaving}
            />
          </FlexRow>
        </FlexGroup>
      )}
    </React.Fragment>
  );
};
