import { COMMON_KEY, COMMON_PROGRAM_AREA_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { ProgramArea, ItemFilter } from 'types';

export const PROGRAM_AREA_PATH = [COMMON_KEY, COMMON_PROGRAM_AREA_KEY];

export const getIsDataFetching_ProgramArea = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_AREA_PATH);
};

export const getRecords_ProgramArea = (state: any): ProgramArea[] => {
  return getRecords<ProgramArea>(state, PROGRAM_AREA_PATH);
};

export const shouldRefreshReduxState_ProgramArea = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramArea>(state, PROGRAM_AREA_PATH);
};

export const getLastUpdated_ProgramArea = (state: any): number => {
  return getLastUpdated(state, PROGRAM_AREA_PATH);
};

export const getFilteredRecords_ProgramArea = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<ProgramArea>(state, PROGRAM_AREA_PATH, itemFilter);
};
