import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import { CTADistrictRecord, CTAEquipment, emptyCtaEquipment } from 'types';
import { EquipmentCostModal } from './EquipmentCostModal';
import { FlexGroup, FlexRow } from 'layouts';
import {
  TableColumnProps,
  ConditionalRowStyle,
} from 'components/Common/Table/Table.component';
import {
  compose,
  concatLengthWithItems,
  isTruthy,
  not,
  prop,
  renderData,
  safeGet,
} from 'util/objectUtility';
import { fmap } from 'util/arrayUtility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { gt as lt } from 'ramda';
import { insertDollarSign, formatMoney } from 'util/moneyHelpers';
import { sumNumbers } from 'util/mathHelpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

type EquipmentProps = {
  locked: boolean;
  ctaRecord: CTADistrictRecord;
  equipment: CTAEquipment[];
  removeCTAEquipment: Function;
  loadCTAEquipment: Function;
  saveCTAEquipment: Function;
};

export const EquipmentComponent: React.FC<EquipmentProps> = (props) => {
  const {
    equipment,
    loadCTAEquipment,
    removeCTAEquipment,
    locked,
    ctaRecord,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------

  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTAEquipment(ctaId).catch((err: any) => console.error(err));
    }
  }, [wasOpened, loadCTAEquipment, ctaId]);

  // --[ display logic ]-------------------------------------------------------
  // get :: CTAEquipment -> string -> CTAEquipment[string]
  const get = safeGet(ctaRecord);

  // getDisplayData :: string -> CTAEquipment -> string
  const getDisplayData = (name: keyof CTAEquipment) => (row: CTAEquipment) => {
    return renderData(prop(name, row));
  };

  // hide :: CTAEquipment -> bool
  const hide = compose(lt(0), prop('ctaEquipmentId'));

  const renderEditIcon = (row: CTAEquipment) => {
    return hide(row) ? null : (
      <EquipmentCostModal
        equipment={row}
        isNew={false}
        ctaId={ctaId}
        district={get('schoolDistrictName')}
        year={get('year')}
      />
    );
  };

  const renderDeleteIcon = (row: CTAEquipment) => {
    return hide(row) ? null : (
      <ConfirmationDialog
        tooltip="Delete Equipment Cost"
        header="Confirm Delete?"
        onConfirm={() => {
          removeCTAEquipment(ctaId, row)
            .then(() => {
              toast(
                <ToastNotification
                  status="success"
                  message={StringResources.Success.Deleted}
                />
              );
            })
            .catch(() => {
              toast(
                <ToastNotification
                  status="error"
                  message={StringResources.Errors.DeleteFailed}
                />
              );
            });
        }}
        activateButton={<IconButton tooltip="Delete" name="trashCan" />}
      >
        <p>
          Are you sure you want to delete this Equipment Cost? Once deleted,
          data on this equipment cost will need to be re-added using the “Add
          Equipment” button.
        </p>
      </ConfirmationDialog>
    );
  };

  const CTA_EQUIPMENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Equipment Name',
      cell: getDisplayData('itemName'),
      selector: getDisplayData('itemName'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Program',
      cell: (row: CTAEquipment) => {
        return prop('ctaEquipmentCTEPrograms', row)
          ? concatLengthWithItems('ctaEquipmentCTEPrograms', 'programName')(row)
          : null;
      },
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'School',
      cell: (row: CTAEquipment) => {
        return prop('ctaEquipmentCTEPrograms', row)
          ? concatLengthWithItems('ctaEquipmentCTEPrograms', 'schoolName')(row)
          : null;
      },
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Total Cost',
      cell: compose(insertDollarSign, formatMoney, prop('totalCost')),
      style: {
        width: '20rem',
        textAlign: 'right',
      },
      sortable: true,
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '7%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '7%',
    },
  ];

  const conditionalStyles: ConditionalRowStyle[] = [
    {
      when: compose(not, isTruthy, prop('ctaEquipmentCTEPrograms')),
      style: {
        backgroundColor: 'transparent',
      },
    },
  ];

  const totalTable: TableColumnProps[] = [
    {
      name: '',
      cell: getDisplayData('itemName'),
      selector: getDisplayData('itemName'),
      sortable: false,
      width: '13%',
    },
    {
      name: '',
      cell: () => '',
      sortable: false,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: '',
      cell: () => '',
      sortable: false,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: '',
      cell: compose(insertDollarSign, formatMoney, prop('totalCost')),
      style: {
        width: '20rem',
        textAlign: 'right',
      },
      sortable: false,
    },
    {
      name: '',
      cell: () => '',
      width: '7%',
    },
    {
      name: '',
      cell: () => '',
      width: '7%',
    },
  ];

  const totalCost: number = compose(
    sumNumbers,
    fmap(prop('totalCost'))
  )(equipment);

  const totalRow: CTAEquipment = {
    ...emptyCtaEquipment(),
    itemName: 'Total',
    totalCost,
  };

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-equipment"
        locked={locked}
        header="Equipment Costs"
        onChange={updateOpenedState}
      >
        <Paper className="t-equipment-costs">
          <Instruction title="Equipment Costs" hideTitle={true}>
            <p>
              Equipment expenditures (units of property with an acquisition cost
              of $5,000 or more per unit) reported must be assigned to each
              applicable school and program. Click ‘Add Equipment’ and follow
              the prompts in the pop-up window. Do not include book and supplies
              costs entered above. Click the Edit icon to edit equipment
              details. You may delete the record by clicking on the trash icon.
            </p>
            <p>
              <span className="u-color-red">Note</span>: Entries with an
              ellipses (…) indicates that items were cutoff and the number in
              parenthesis (#) indicates the total number of items.
            </p>
          </Instruction>
          <SecureWrap
            component={['ctageneral']}
            requireEdit={true}
            isLocked={readOnly}
          >
            <FlexRow>
              <EquipmentCostModal
                equipment={emptyCtaEquipment()}
                isNew={true}
                ctaId={ctaId}
                year={get('year')}
                district={get('schoolDistrictName')}
              />
            </FlexRow>
          </SecureWrap>
          <FlexGroup>
            <Table columns={CTA_EQUIPMENT_HEADERS} data={equipment} />
          </FlexGroup>
          {equipment && equipment.length > 0 && (
            <FlexGroup>
              <Table
                columns={totalTable}
                data={[totalRow]}
                conditionalRowStyles={conditionalStyles}
              />
            </FlexGroup>
          )}
        </Paper>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
