import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTASupportPersonnel } from 'types';
import {
  deleteCTASupportPersonnel,
  readCTASupportPersonnel,
  updateCTASupportPersonnel,
} from 'services/cta/ctasupportpersonnel.services';

const initialState = baseInitialState;

export const loadCTASupportPersonnel = createActionThunk(
  'loadCTASupportPersonnel',
  (ctaId: number) => {
    return readCTASupportPersonnel(ctaId);
  }
);

export const saveCTASupportPersonnel = createActionThunk(
  'saveCTASupportPersonnel',
  (ctaId: number, supportPersonnel: CTASupportPersonnel) => {
    return updateCTASupportPersonnel(ctaId, supportPersonnel);
  }
);

export const deactivateCTASupportPersonnel = createActionThunk(
  'deactivateCTASupportPersonnel',
  (ctaId: number, supportPersonnel: CTASupportPersonnel) => {
    return deleteCTASupportPersonnel(ctaId, supportPersonnel);
  }
);

export default handleActions<BaseStateObject, CTASupportPersonnel[]>(
  {
    ...baseLoadActionBuilder<CTASupportPersonnel[]>(loadCTASupportPersonnel),
    ...baseSaveActionBuilder<CTASupportPersonnel>(saveCTASupportPersonnel),
  },
  initialState
);
