import { connect } from 'react-redux';
import { loadUser, logUserOut } from 'redux/user';
import { AccountMenuComponent } from './AccountMenu.component';
import {
  getLoggedinUser,
  isUserAuthenticated,
  getIsUserFetching,
} from 'redux/user/selectors';
import { shouldRefreshReduxState_UserPermissions } from 'redux/permissions/userpermissions.selectors';
import { loadUserPermissions } from 'redux/permissions/userpermissions';

export const mapStateToProps = (state: any) => {
  const shouldRefreshPermissions = shouldRefreshReduxState_UserPermissions(
    state
  );
  const isAuthenticated = isUserAuthenticated(state);
  const user = getLoggedinUser(state);
  const isFetching = getIsUserFetching(state);
  return {
    isAuthenticated,
    shouldRefreshPermissions,
    user,
    isFetching,
  };
};

const dispatchToProps = {
  loadUser,
  logUserOut,
  loadUserPermissions,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(AccountMenuComponent);
