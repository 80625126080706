import React, { useEffect } from 'react';

/*
  Budget Hydration
  This component is used to refresh / hydrate data for common data types in the background
*/

type DataHydratorProps = {
  shouldRefreshContactTypes: boolean;
  loadContactTypes: Function;

  shouldRefreshUniversalContacts: boolean;
  loadUniversalContacts: Function;

  shouldRefreshPermissionRoles: boolean;
  loadPermissionRoles: Function;

  shouldRefreshSchools: boolean;
  loadSchools: Function;

  shouldRefreshInstitutes: boolean;
  loadInstitutes: Function;

  shouldRefreshDepartments: boolean;
  loadDepartments: Function;

  shouldRefreshDistricts: boolean;
  loadDistricts: Function;

  shouldRefreshExpenseCategories: boolean;
  loadExpenseCategories: Function;

  shouldRefreshFerpaAgreement: boolean;
  loadFerpaAgreementData: Function;

  shouldRefreshTravelerTypes: boolean;
  loadTravelerTypes: Function;

  shouldRefreshFiscalYears: boolean;
  loadFiscalYears: Function;
};

export const CommonDataHydrator: React.FC<DataHydratorProps> = (props) => {
  // -- FiscalYears ------------------------------------------------------------
  const { shouldRefreshFiscalYears, loadFiscalYears } = props;
  useEffect(() => {
    shouldRefreshFiscalYears && loadFiscalYears();
  }, [shouldRefreshFiscalYears, loadFiscalYears]);

  // -- Districts ------------------------------------------------------------
  const { shouldRefreshDistricts, loadDistricts } = props;
  useEffect(() => {
    shouldRefreshDistricts && loadDistricts();
  }, [shouldRefreshDistricts, loadDistricts]);

  // -- Departments -----------------------------------------------------------
  const { shouldRefreshDepartments, loadDepartments } = props;
  useEffect(() => {
    shouldRefreshDepartments && loadDepartments();
  }, [shouldRefreshDepartments, loadDepartments]);

  // -- Institutes ------------------------------------------------------------
  const { shouldRefreshInstitutes, loadInstitutes } = props;
  useEffect(() => {
    shouldRefreshInstitutes && loadInstitutes();
  }, [shouldRefreshInstitutes, loadInstitutes]);

  // -- Schools ---------------------------------------------------------------
  const { shouldRefreshSchools, loadSchools } = props;
  useEffect(() => {
    shouldRefreshSchools && loadSchools();
  }, [shouldRefreshSchools, loadSchools]);

  // -- ContactTypes ----------------------------------------------------------
  const { shouldRefreshContactTypes, loadContactTypes } = props;
  useEffect(() => {
    shouldRefreshContactTypes && loadContactTypes();
  }, [shouldRefreshContactTypes, loadContactTypes]);

  // -- UniversalContacts -----------------------------------------------------
  const { shouldRefreshUniversalContacts, loadUniversalContacts } = props;
  useEffect(() => {
    shouldRefreshUniversalContacts && loadUniversalContacts();
  }, [shouldRefreshUniversalContacts, loadUniversalContacts]);

  // -- PermissionRoles -------------------------------------------------------
  const { shouldRefreshPermissionRoles, loadPermissionRoles } = props;
  useEffect(() => {
    shouldRefreshPermissionRoles && loadPermissionRoles();
  }, [shouldRefreshPermissionRoles, loadPermissionRoles]);

  // -- ExpenseCategories -----------------------------------------------------
  const { shouldRefreshExpenseCategories, loadExpenseCategories } = props;
  useEffect(() => {
    shouldRefreshExpenseCategories && loadExpenseCategories();
  }, [shouldRefreshExpenseCategories, loadExpenseCategories]);

  // -- FERPA Agreement -----------------------------------------------------
  const { shouldRefreshFerpaAgreement, loadFerpaAgreementData } = props;
  useEffect(() => {
    shouldRefreshFerpaAgreement && loadFerpaAgreementData();
  }, [shouldRefreshFerpaAgreement, loadFerpaAgreementData]);

  // -- TravelerTypes ---------------------------------------------------------
  const { shouldRefreshTravelerTypes, loadTravelerTypes } = props;
  useEffect(() => {
    shouldRefreshTravelerTypes && loadTravelerTypes();
  }, [shouldRefreshTravelerTypes, loadTravelerTypes]);

  return <div className="hydration" />;
};
