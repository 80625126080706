import { connect } from 'react-redux';
import {
  getCurrentNavAnchor,
  getNavTrayClosed,
} from 'redux/application/selectors';
import { SideNavItem } from './SideNavItem.component';

const mapStateToProps = (state: any, ownProps: any) => {
  const currentNavAnchor = getCurrentNavAnchor(state);
  const navTrayClosed = getNavTrayClosed(state);

  return {
    currentNavAnchor,
    navTrayClosed,
  };
};

export default connect(mapStateToProps)(SideNavItem);
