import { CTA_KEY, CTA_ADMIN_BOOK_SUPPLY_KEY } from 'redux/keys';
import { getIsDataFetching, getFirstRecord } from 'redux/utility/baseSelectors';
import { AdminBooksAndSupplies, emptyAdminBooksAndSupplies } from 'types';

export const CTA_ADMIN_BOOK_SUPPLY_PATH = [CTA_KEY, CTA_ADMIN_BOOK_SUPPLY_KEY];

export const getIsDataFetching_AdminBooksAndSupplies = (
  state: any
): boolean => {
  return getIsDataFetching(state, CTA_ADMIN_BOOK_SUPPLY_PATH);
};

export const getFirstRecord_AdminBooksAndSupplies = (
  state: unknown
): AdminBooksAndSupplies => {
  return getFirstRecord(
    state,
    CTA_ADMIN_BOOK_SUPPLY_PATH,
    emptyAdminBooksAndSupplies()
  );
};
