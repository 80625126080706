import React, { useState, useEffect } from 'react';
import { FlexRow } from 'layouts';
import { useParams } from 'react-router-dom';
import {
  readFederalGrantId,
  updateFederalGrantId,
} from 'services/grants/grantschedule.services';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import Input from 'components/Common/FormElements/Input';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

export const FederalGrantId: React.FC = () => {
  // --[ dependencies ]--------------------------------------------------------
  const { fiscalYear }: any = useParams();

  // --[ local state ]---------------------------------------------------------
  const [federalGrantIdState, setFederalGrantIdState] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  const handleChange = (item: { federalGrantId: string }) => {
    setIsDirty(true);
    setFederalGrantIdState(item.federalGrantId);
  };

  const handleSave = () => {
    if (!isDirty) {
      return;
    }
    setLoading(true);
    updateFederalGrantId(fiscalYear, federalGrantIdState)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
        setIsDirty(false);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      })
      .finally(() => setLoading(false));
  };
  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    readFederalGrantId(fiscalYear)
      .then((response) => {
        setFederalGrantIdState(response);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Unable to load the Federal Grant Id.  Try again later."
          />
        );
      });
  }, [fiscalYear]);

  // --[ display logic ]-------------------------------------------------------
  const getButtonClass = () => {
    return isDirty
      ? 'button--filled grant-schedule__save'
      : 'button--outline grant-schedule__save';
  };

  return (
    <React.Fragment>
      <Loading isActive={loading} />
      <Paper>
        <FlexRow style={{ alignItems: 'flex-end' }}>
          <Input
            value={federalGrantIdState}
            name="federalGrantId"
            onChange={handleChange}
          />
          <Button
            onClick={handleSave}
            text="save"
            className={getButtonClass()}
          />
        </FlexRow>
      </Paper>
    </React.Fragment>
  );
};
