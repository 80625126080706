export const QUALITY_ADDRESSABLE_ELEMENTS = '[QUALITY_ADDRESSABLE_ELEMENTS]';

export const SET_QUALITY_ADDRESSABLE_ELEMENTS = `${QUALITY_ADDRESSABLE_ELEMENTS} SET`;
export const SET_QUALITY_ADDRESSABLE_ELEMENTS_LOADING = `${QUALITY_ADDRESSABLE_ELEMENTS} LOADING`;

export const setQualityAddressableElements = (payload: any) => {
  return {
    type: SET_QUALITY_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_QualityAddressableElements = (payload: boolean) => {
  return {
    type: SET_QUALITY_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
