import { CTA_KEY, CTA_SIGNATURE_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { SignatureWithAssertion } from 'types';

export const CTA_SIGNATURE_PATH = [CTA_KEY, CTA_SIGNATURE_KEY];

export const getIsDataFetching_CTASignatures = (state: any): boolean => {
  return getIsDataFetching(state, CTA_SIGNATURE_PATH);
};

export const getRecords_CTASignatures = (
  state: unknown
): SignatureWithAssertion[] => {
  return getRecords(state, CTA_SIGNATURE_PATH);
};
