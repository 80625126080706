export interface PerformanceData {
  aggType: string;
  numerator: number;
  denominator: number;
  percentage: number;
}

export const emptyPerformanceData = () => {
  return {
    aggType: '',
    numerator: -1,
    denominator: -1,
    percentage: -1,
  };
};
