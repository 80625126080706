import { WorkflowSubmission, WorkflowReduxEntities } from 'types';
import { curry } from 'lodash';
import { WORKFLOW_KEY } from 'redux/keys';

export const FETCH_WORKFLOW = `${WORKFLOW_KEY} FETCH`;
export const SAVE_WORKFLOW_APPROVAL = `${WORKFLOW_KEY} SAVE APPROVAL`;
export const SAVE_WORKFLOW_UPDATE = `${WORKFLOW_KEY} SAVE UPDATE`;
export const SAVE_PROGRAM_APPROVAL = `${WORKFLOW_KEY} SAVE PROGRAM APPROVAL`;
export const SAVE_PROGRAM_UPDATE = `${WORKFLOW_KEY} SAVE PROGRAM UPDATE`;
export const SAVE_CTA_UPDATE = `${WORKFLOW_KEY} SAVE CTA UPDATE`;

export const loadWorkFlow = curry(
  (entityType: keyof WorkflowReduxEntities, entityId: number) => {
    return {
      type: FETCH_WORKFLOW,
      payload: {
        entityType,
        entityId,
      },
    };
  }
);

export const saveWorkFlowUpdate = (entityType: keyof WorkflowReduxEntities) => (
  entityId: number,
  submission: WorkflowSubmission
) => {
  return {
    type: SAVE_WORKFLOW_UPDATE,
    payload: {
      entityType,
      entityId,
      submission,
    },
  };
};

export const submitProgramForApproval = (
  programInstanceId: number,
  comment: string
) => {
  return {
    type: SAVE_PROGRAM_APPROVAL,
    payload: {
      programInstanceId,
      comment,
    },
  };
};

export const submitProgramForUpdate = (
  programInstanceId: number,
  submission: WorkflowSubmission
) => {
  return {
    type: SAVE_PROGRAM_UPDATE,
    payload: {
      programInstanceId,
      submission,
    },
  };
};

export const submitCtaForUpdate = (
  ctaId: number,
  submission: WorkflowSubmission
) => {
  return {
    type: SAVE_CTA_UPDATE,
    payload: {
      ctaId,
      submission,
    },
  };
};
