import { connect } from 'react-redux';
import { EditCategoryExpensesComponent } from './EditCategoryExpenses.component';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(EditCategoryExpensesComponent);
