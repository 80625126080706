import { FundsRemaining } from './FundsRemaining.component';
import { connect } from 'react-redux';
import { getAnnualSpendingPlan } from 'redux/grants/_selectors';
import { getRemainingFunds } from 'util/annualSpendingUtility';

const mapStateToProps = (state: any) => {
  const label = 'Total Funds Remaining';
  const annualSpendingPlan = getAnnualSpendingPlan(state);
  const funds = getRemainingFunds(annualSpendingPlan);

  return {
    label,
    funds,
  };
};

export default connect(mapStateToProps, {})(FundsRemaining);
