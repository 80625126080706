import { useValidation } from 'de-formed-validations';
import { CtaInstructorProgram } from 'types';
import { gt as lt } from 'ramda';

export const InstructorProgramValidation = () => {
  return useValidation<CtaInstructorProgram>({
    cteDutyStipend: [
      {
        errorMessage: 'Must be more than $100.',
        validation: (item: any) => {
          if (Number(item) === 0) {
            return true;
          }
          return item > 100;
        },
      },
      {
        errorMessage: 'CTE Duty Stipend must be less than $100,000.',
        validation: lt(100000),
      },
    ],
    subSickDays: [
      {
        errorMessage: 'CTE Sub days can not exceed 89.',
        validation: lt(90),
      },
    ],
  });
};
