import React, { useState } from 'react';
import { AzureUploadParameters, Question, QuestionValue } from 'types';
import { FlexRow } from 'layouts';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';

type QuestionFilesProps = {
  question: Question;
  onChange: Function;
  uploadParams: AzureUploadParameters;
};

export const QuestionFiles: React.FC<QuestionFilesProps> = (props) => {
  const { question, onChange, uploadParams } = props;
  const [isOpen, setOpen] = useState<boolean>(false);

  const filepath = () => {
    return question.questionValue.questionFilePath &&
      question.questionValue.questionFilePath.length > 0
      ? question.questionValue.questionFilePath[0]
      : '';
  };

  const updateFilePath = (file: string) => {
    const questionValue: QuestionValue = {
      ...question.questionValue,
      questionFilePath: [file],
    };
    const newState = {
      ...question,
      questionValue,
    };
    onChange(newState);
  };

  return (
    <FlexRow>
      <div>
        {filepath() ? (
          <AzureDownloader
            onClose={() => updateFilePath('')}
            uploadParams={uploadParams}
            filepath={filepath()}
          />
        ) : (
          <div className="no-show no-print">
            <Button
              text="+ Upload Documentation"
              className="form__btn--add u-flex-self-start"
              onClick={() => setOpen(true)}
            />
          </div>
        )}
      </div>
      <AzureUploader
        onClose={() => setOpen(false)}
        open={isOpen}
        uploadParams={uploadParams}
        onConfirm={updateFilePath}
      />
    </FlexRow>
  );
};
