import React, { useState, useEffect } from 'react';
import {
  StudentEnrollmentData,
  StudentEnrollment,
  UniversalContact,
} from 'types';
import { FlexRow } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { prop, renderData, compose, getDate } from 'util/objectUtility';
import { useHistory } from 'react-router';
import { COLOR_LIGHT_GREEN, COLOR_LIGHT_RED } from 'constants/css.const';
import { some } from 'lodash';
import { isSuperAdmin } from 'util/permissionsUtility';
import FollowupStatus from './FollowupStatus';
import FollowupUpload from './FollowupUpload';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StudentEnrollmentFilter from '../StudentEnrollmentFilter';
import Table from 'components/Common/Table';

type StudentFollowupComponentProps = {
  isFetching: boolean;
  followUpData: StudentEnrollmentData;
  loadStudentFollowUpData: Function;
  loadProgramSnapshotStudent: Function;
  loggedInUser: UniversalContact;
};

export const StudentFollowupComponent: React.FC<
  StudentFollowupComponentProps
> = (props) => {
  const {
    followUpData,
    isFetching,
    loadStudentFollowUpData,
    loggedInUser,
  } = props;
  const { enrollments } = followUpData;

  const [filteredData, setFilteredData] = useState<StudentEnrollment[]>([]);
  const history = useHistory();

  const handleRowClick = (row: StudentEnrollment) => {
    if (row.isFollowUpOpen) {
      return history.push(
        `/data-collection/student-followup/${row.programBaseId}`
      );
    }
  };

  const getDisplayData = (name: keyof StudentEnrollment) => (
    row: StudentEnrollment
  ) => {
    return renderData(prop(name, row));
  };

  const STUDENT_ENROLLMENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Incomplete',
      cell: compose(
        renderData,
        prop('followUpIncompletes')
      ),
      selector: prop('followUpIncompletes'),
      sortable: true,
    },
    {
      name: 'Complete',
      cell: compose(
        renderData,
        prop('followUpsComplete')
      ),
      selector: prop('followUpsComplete'),
      sortable: true,
    },
    {
      name: 'School Name',
      cell: getDisplayData('schoolName'),
      selector: compose(prop('schoolName')),
      sortable: true,
      style: {
        width: '20%',
        flexGrow: 1,
      },
    },
    {
      name: 'CDE ID',
      cell: getDisplayData('cdeId'),
      selector: prop('cdeId'),
      sortable: true,
    },
    {
      name: 'Program',
      cell: getDisplayData('programName'),
      selector: prop('programName'),
      sortable: true,
      style: {
        width: '20%',
        flexGrow: 2,
      },
    },
    {
      name: 'CIP',
      cell: getDisplayData('cipCode'),
      selector: prop('cipCode'),
      sortable: true,
    },
    {
      name: 'Base ID',
      cell: getDisplayData('programBaseId'),
      selector: prop('programBaseId'),
      sortable: true,
    },
    {
      name: 'Program Instance ID',
      cell: getDisplayData('programInstanceId'),
      selector: prop('programInstanceId'),
      sortable: true,
    },
    {
      name: 'Program State',
      cell: getDisplayData('programState'),
      selector: prop('programState'),
      sortable: true,
      style: {
        width: '10%',
        flexGrow: 1,
      },
    },
    {
      name: 'Expiration',
      cell: compose(
        getDate,
        prop('expiration')
      ),
      selector: prop('expiration'),
      sortable: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: StudentEnrollment) => {
        return row.isFollowUpComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_GREEN,
      },
    },
    {
      when: (row: StudentEnrollment) => {
        return !row.isFollowUpOpen && !row.isFollowUpComplete;
      },
      style: {
        backgroundColor: COLOR_LIGHT_RED,
      },
    },
  ];

  useEffect(() => {
    setFilteredData(enrollments);
  }, [enrollments]);

  useEffect(() => {
    loadStudentFollowUpData();
  }, [loadStudentFollowUpData]);

  return (
    <React.Fragment>
      <SecureWrap
        component={['DCStudentRecords', 'DCCertification']}
        allowReadOnly={true}
        isPage={true}
      >
        <Loading isActive={isFetching} messageBefore="Loading..." />
        <Paper>
          <PageHeading
            title={
              <Instruction
                title={`Student Follow-up from Academic Year: ${followUpData.schoolYear -
                  1} - ${followUpData.schoolYear}`}
                isPageHeader={true}
              >
                <p>
                  You are required to report follow-up data for the period
                  corresponding between December 15 and March 31st for students
                  flagged as CTE concentrators at the secondary level and CTE
                  completers at the postsecondary level who completed their
                  programming in the prior academic year. Follow the prompts
                  below to identify students on whom you must report and to
                  upload information on their status.
                </p>
              </Instruction>
            }
          />
        </Paper>
        {!isSuperAdmin(loggedInUser) && (
          <SecureWrap
            component={['DCCertification']}
            isLocked={some([
              followUpData.followUpComplete,
              followUpData.followUpLocked,
            ])}
          >
            <Paper>
              <FollowupStatus followUpData={followUpData} />
            </Paper>
          </SecureWrap>
        )}
        <Paper>
          <FollowupUpload followUpData={followUpData} />
        </Paper>
        <Paper>
          <Instruction title="View & Edit Program Placement">
            <p>
              Listed below are details on the active and conditionally-active
              programs offered within your site(s) during the current academic
              year, as entered in the Program Approvals module. Each row
              corresponds to a specific program offered within a site. Rows
              highlighted in green show completed records and rows highlighted
              in red show records that have been closed. Use the search bar to
              search within any column of the table. Click on a program's row to
              view the program summary and trend data or to update follow-up
              information.
            </p>
          </Instruction>
          <FlexRow>
            <SearchBarFilter
              shouldReset={true}
              data={enrollments}
              isFetching={isFetching}
              label="Search Program Follow-up"
              setFilteredData={setFilteredData}
              headers={STUDENT_ENROLLMENT_HEADERS}
            />
          </FlexRow>
          <StudentEnrollmentFilter />
          <Table
            conditionalRowStyles={conditionalRowStyles}
            columns={STUDENT_ENROLLMENT_HEADERS}
            data={filteredData}
            onRowClick={handleRowClick}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
