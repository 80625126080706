import React from 'react';
import { useParams } from 'react-router';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import AwardPacketWorkflow from 'components/Grants/Applications/AwardPacket/Workflow';

export const GrantAwardPacketApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AwardPacketWorkflow grantFiscalYearId={grantFiscalYearId} {...props} />
      </GrantsLayout>
    </BaseLayout>
  );
};
