import { combineReducers } from 'redux';
import {
  DATA_COLLECTION_SUBMISSION_KEY,
  DATA_COLLECTION_STUDENT_KEY,
  DATA_COLLECTION_STUDENT_ENROLLMENT_KEY,
  DATA_COLLECTION_TEACHER_KEY,
  DATA_COLLECTION_UNIVERSAL_TEACHERS_KEY,
  DATA_COLLECTION_PROGRAM_SNAPSHOT_STUDENT_KEY,
} from 'redux/keys';
import submissiondata from './datacollectionsubmission';
import studentdata from './studentdata';
import studentenrollment from './studentenrollment';
import universalteachers from './universalteachers';
import activeteachers from './activeteachers';
import programsnapshotstudent from './programsnapshotstudent';

export default combineReducers({
  [DATA_COLLECTION_SUBMISSION_KEY]: submissiondata,
  [DATA_COLLECTION_STUDENT_ENROLLMENT_KEY]: studentenrollment,
  [DATA_COLLECTION_STUDENT_KEY]: studentdata,
  [DATA_COLLECTION_UNIVERSAL_TEACHERS_KEY]: universalteachers,
  [DATA_COLLECTION_TEACHER_KEY]: activeteachers,
  [DATA_COLLECTION_PROGRAM_SNAPSHOT_STUDENT_KEY]: programsnapshotstudent,
});
