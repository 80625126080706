import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { Workflow, WorkflowSubmission } from 'types';

const ENDPOINT = 'api/GrantApprovals/';

/**
 *
 */
export const createGrantWorkflowUpdate = async (
  grantType: string,
  grantFiscalYearId: number,
  submission: WorkflowSubmission
): Promise<Workflow> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ENDPOINT}${grantType}/${grantFiscalYearId}/${submission.optionId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ comment: submission.comment }),
  })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const readGrantWorkflow = async (
  grantType: string,
  grantFiscalYearId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ENDPOINT}${grantType}/${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ReadGrantWorkflow', err);
    });
};
