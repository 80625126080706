import React, { useState } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { Link } from 'react-router-dom';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { defaultTo, every, first, last, map, startCase } from 'lodash';
import { renderData, prop, compose } from 'util/objectUtility';
import { stringFormat } from 'StringResources';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import Table from 'components/Common/Table';

interface ReportTableProps {
  data: any;
  reportLink: any;
}
export const ReportTable: React.FC<ReportTableProps> = ({
  data,
  reportLink,
}) => {
  // --[ local state ]---------------------------------------------------------
  const [filteredData, setFilteredData] = useState<unknown[]>([]);

  // --[ component logic ]-----------------------------------------------------
  const value: any = defaultTo(first(data), {});
  const keys = Object.keys(value);

  const getLink = (row: any) => {
    const parameters = map(reportLink.values, (item) => {
      return row[item];
    });
    const url = stringFormat(reportLink.url, parameters);
    return (
      <Link rel="noreferrer" target="_blank" to={url}>
        {last(parameters)}
      </Link>
    );
  };

  // cellProperty :: (string, number) => fn
  const cellProperty = (item: string, index: number) =>
    every([reportLink, index === keys.length - 1])
      ? getLink
      : compose(renderData, prop(item));

  // columnHeader :: (string, number) -> TableColumnProps
  const columnHeader = (item: string, index: number): TableColumnProps => {
    return {
      cell: cellProperty(item, index),
      selector: prop(item),
      name: compose(renderData, startCase)(item),
      sortable: true,
      style: { alignSelf: 'start' },
    };
  };

  const headers: TableColumnProps[] = keys.map(columnHeader);

  return (
    <>
      <Paper>
        <FlexRow>
          <SearchBarFilter
            data={data}
            headers={headers}
            isFetching={false}
            label="Search"
            setFilteredData={setFilteredData}
          />
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <Table data={filteredData} columns={headers} />
          </FlexGroup>
        </FlexRow>
      </Paper>
    </>
  );
};
