import React, { useState, useEffect } from 'react';
import { FlexRow } from 'layouts';
import { APIError, APIErrorEntity, SnapshotStudent } from 'types';
import { getFullName } from 'util/datacollection.utility';
import { display, mergeObjects } from 'util/objectUtility';
import {
  placementvalues,
  contEducationvalues,
  militaryServicevalues,
  apprenticeshipvalues,
  serviceProgramvalues,
  certExamPassedvalues,
  certExamCompletedvalues,
} from 'constants/snapshotStudent.const';
import { StudentFollowupValidations } from 'validations/DataCollection/Followup.validations';
import { toast } from 'react-toastify';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import Select from 'components/Common/FormElements/SelectBox';
import ToastNotification from 'components/Common/Toast';
import { map, startCase } from 'lodash';

type FollowupDetailsProps = {
  student: SnapshotStudent;
  saveSnapshotStudent: Function;
  close: Function;
};

export const FollowupDetailsComponent: React.FC<FollowupDetailsProps> = (props) => {
  const { close, student, saveSnapshotStudent } = props;
  const [studentState, setStudentState] = useState<SnapshotStudent>({
    ...student,
  });
  const [apiErrors, setApiErrors] = useState<APIError[]>([]);

  const render = display<SnapshotStudent>(studentState);
  const {
    getError,
    getFieldValid,
    validateAll,
    validateIfTrue,
    validationErrors,
    resetValidationState,
  } = StudentFollowupValidations();

  const handleChange = (
    data: Partial<SnapshotStudent>,
    name: keyof SnapshotStudent,
    val: number | null
  ) => {
    const updated = mergeObjects<SnapshotStudent>(studentState, data);
    validateIfTrue(name as any, val, updated);
    setStudentState(updated);
    // If any field is updated, clear the errors
    if (apiErrors.length > 0) {
      setApiErrors([]);
    }
  };

  const handleSave = () => {
    const completedStudent = {
      ...studentState,
      studentFollowup: true,
    };
    return validateAll(completedStudent)
      ? saveSnapshotStudent(completedStudent)
          .then((data: APIErrorEntity) => {
            if (data.errors.length > 0) {
              setApiErrors(data.errors);
              toast(<ToastNotification status="error" message="Save Student Failed" />);
            } else {
              setApiErrors([]);
              close();
              toast(<ToastNotification status="success" message="Student data saved." />);
            }
          })
          .catch(() => {
            Promise.resolve(); // avoid unhandled catch
          })
      : Promise.reject('Not all validations passed. Please check the form for errors.');
  };

  // Combines UI validation errors and API validaion errors
  const getErrors = () => {
    return [
      ...validationErrors,
      ...map(apiErrors, (val: APIError) => {
        return `${startCase(val.field)}: ${val.error}`;
      }),
    ];
  };

  useEffect(() => {
    setApiErrors([]);
    resetValidationState();
    setStudentState(student);
  }, [student]); // eslint-disable-line

  return (
    <React.Fragment>
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Student Record: ${getFullName(studentState)}`}
              isPageHeader={true}
            >
              <p>
                Make edits to the identified fields. Click on the ‘Save’ button to record your
                responses or ‘Cancel’ to make no changes and return to the program Student
                follow-up summary page.
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <h2 className="u-section-title-text">Student Information</h2>
        <FlexRow>
          <Input
            disabled={true}
            className="input-display"
            name=""
            label="Student Id"
            value={render('studentId')}
          />
          <Select
            errorMessage={getError('placement')}
            label="Placement Status"
            name="placement"
            onChange={handleChange}
            options={placementvalues}
            selection={studentState.placement}
            shouldReset={true}
            valid={getFieldValid('placement')}
          />
        </FlexRow>
        <FlexRow>
          <Select
            errorMessage={getError('contEducation')}
            label="Continuing Education"
            name="contEducation"
            onChange={handleChange}
            options={contEducationvalues}
            selection={studentState.contEducation}
            shouldReset={true}
            valid={getFieldValid('contEducation')}
          />
          <Select
            errorMessage={getError('militaryServiceId')}
            label="Military Service"
            name="militaryServiceId"
            onChange={handleChange}
            options={militaryServicevalues}
            selection={studentState.militaryServiceId}
            shouldReset={true}
            valid={getFieldValid('militaryServiceId')}
          />
        </FlexRow>
        <FlexRow>
          <Select
            errorMessage={getError('apprenticeship')}
            label="Apprenticeship"
            name="apprenticeship"
            onChange={handleChange}
            options={apprenticeshipvalues}
            selection={studentState.apprenticeship}
            shouldReset={true}
            valid={getFieldValid('apprenticeship')}
          />
          <Select
            errorMessage={getError('serviceProgram')}
            label="Service Program"
            name="serviceProgram"
            onChange={handleChange}
            options={serviceProgramvalues}
            selection={studentState.serviceProgram}
            shouldReset={true}
            valid={getFieldValid('serviceProgram')}
          />
        </FlexRow>
        <FlexRow>
          <Select
            errorMessage={getError('certExamCompleted')}
            label="Certification Exam Complete"
            name="certExamCompleted"
            onChange={handleChange}
            options={certExamCompletedvalues}
            selection={studentState.certExamCompleted}
            shouldReset={true}
            valid={getFieldValid('certExamCompleted')}
          />
          <Select
            errorMessage={getError('certExamPassed')}
            label="Certification Exam Passed"
            name="certExamPassed"
            onChange={handleChange}
            options={certExamPassedvalues}
            selection={studentState.certExamPassed}
            shouldReset={true}
            valid={getFieldValid('certExamPassed')}
          />
        </FlexRow>
      </Paper>
      <Paper>
        <SaveButtons
          disabled={getErrors().length > 0}
          onCancel={close}
          saveData={handleSave}
          validationErrors={getErrors()}
        />
      </Paper>
    </React.Fragment>
  );
};
