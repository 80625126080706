import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import CertificationOfExemptionDocument from 'components/Grants/Applications/AwardPacket/Documents/CertificationOfExemptionDocument';
import Paper from 'components/Common/Paper';

const CertificationOfExemptionPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <Paper>
        <CertificationOfExemptionDocument />
      </Paper>
    </BaseLayout>
  );
};

export default CertificationOfExemptionPage;
