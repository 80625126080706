import {
  loadProgramAwards,
  saveProgramAwardCache,
  resetProgramAwardCache,
} from 'redux/caches/programaward';
import { handleProgramAwardCache } from 'redux/utility/_exports';

export const programAwardsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState } = store;
  next(action);

  switch (action.type) {
    case loadProgramAwards.SUCCEEDED: {
      handleProgramAwardCache(next, getState(), action.payload);
      break;
    }

    case saveProgramAwardCache.SUCCEEDED: {
      const { programInstanceId } = action.payload;
      next(resetProgramAwardCache(programInstanceId));
      break;
    }
  }
};
