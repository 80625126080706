import { useValidation } from 'de-formed-validations';
import { IntentToParticipateMember } from 'types';
import { every } from 'lodash';
import {
  participationChanged,
  participationStatusChanged,
} from 'util/intentToParticipate.utility';
import { isStringValid } from 'util/validation.utility';

export const IntentToParticipateMemberValidations = () => {
  return useValidation<IntentToParticipateMember>({
    consortiumId: [
      {
        errorMessage: 'Please select a consortium.',
        validation: (value: number, member: IntentToParticipateMember) => {
          return every([!member.isStandalone, participationChanged(member)])
            ? value > 0
            : true;
        },
      },
    ],
    changeExplanation: [
      {
        errorMessage: 'Please provide an explanation for the change.',
        validation: (value: string, member: IntentToParticipateMember) => {
          return participationChanged(member)
            ? isStringValid(value) && value.trim().length <= 2000
            : true;
        },
      },
    ],
    exemptionReason: [
      {
        errorMessage: 'Please select a reason for Standalone Exempt Status.',
        validation: (value: number, member: IntentToParticipateMember) => {
          return every([participationStatusChanged(member), member.isExempt])
            ? value > 0
            : true;
        },
      },
    ],
    exemptionLetterFilePath: [
      {
        errorMessage:
          'You must include a copy of a letter from the superintendent for Standalone Exempt.',
        validation: (value: string, member: IntentToParticipateMember) => {
          return every([participationStatusChanged(member), member.isExempt])
            ? value.trim().length > 0
            : true;
        },
      },
    ],
  });
};
