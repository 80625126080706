import run from 'lodash/fp';
import StringResources from 'StringResources';
import { getAccessToken } from '../util/authorization';
import { every, flow } from 'lodash';
import { ENVIRONMENT } from 'constants/application.config';

/**
 * This function inserts the base environment URL and concatenates the paths together into a
 * formatted web url.
 * @param paths the paths to merge together
 */
export const getFormattedUrl = (paths: unknown[]): string => {
  return flow([
    run.concat([ENVIRONMENT.API_URL]),
    run.map(RemoveWhack),
    run.join('/'),
  ])(paths);
};

/**
 * Removes whacks from a string even if it's not a string
 * @param whackString the string to check for a whack
 */
export const RemoveWhack = (whackString: string) => {
  const item = String(whackString);
  return item.endsWith('/') ? item.slice(0, item.length - 1) : item;
};

/**
 * Standard RequestInit headers for GET
 */
export const getOptions: RequestInit = {
  method: 'GET',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

/**
 * Standard RequestInit headers for POST
 */
export const postOptions: RequestInit = {
  ...getOptions,
  method: 'POST',
};

/**
 * Returns the standard RequestInit headers for GET with an authentication Token
 */
export const getWithAuthOptions = (): RequestInit => {
  return {
    ...getOptions,
    headers: {
      ...getOptions.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
};

/**
 * Returns the standard RequestInit headers for GET with an authentication Token
 */
export const getDataWithAuthOptions = (): RequestInit => {
  return {
    ...getOptions,
    headers: {
      ...getOptions.headers,
      Authorization: `Bearer ${getAccessToken()}`,
      Accept: 'application/octet-stream',
    },
  };
};

/**
 * Returns the standard RequestInit headers for POST with an authentication Token
 */
export const postWithAuthOptions = (): RequestInit => {
  return {
    ...getWithAuthOptions(),
    method: 'POST',
  };
};

/**
 * Returns the standard RequestInit headers for PUT with an authentication Token
 */
export const putWithAuthOptions = (): RequestInit => {
  return {
    ...getWithAuthOptions(),
    method: 'PUT',
  };
};

/**
 * Returns the standard RequestInit headers for DELETE with an authentication Token
 */
export const deleteWithAuthOptions = (): RequestInit => {
  return {
    ...getWithAuthOptions(),
    method: 'DELETE',
  };
};

/**
 * Processes the response from the server
 * @param response
 */
export const toJSON = async (response: Response) => {
  return invalidStatus(response)
    ? Promise.reject(response)
    : response
        .text()
        .then((text) => (text.length ? JSON.parse(text) : []))
        .catch((error) => {
          return error;
        });
};

/**
 * Processes errors, logs them to the console.
 * TODO: Replace with telemetry.
 * @param service Name of the service where the error happened.
 * @param response The servers response.
 * @param method Optional name of the method being called.
 */
export const catchError = async <T>(
  service: string,
  response: Response,
  method?: string
): Promise<T> => {
  console.log('response: ', response);
  const text = await Promise.resolve(
    response instanceof Response
      ? response.text()
      : StringResources.Errors.InvalidResponse
  );
  const serviceText = `${service} ${method ? `(${method})` : ''}: `;
  console.log({ [serviceText]: text });
  return Promise.reject(serviceText);
};

const invalidStatus = (response: Response) => {
  return every([
    response.status < 200 || response.status >= 300,
    response.status !== 304,
  ]);
};

export const toFileBlob = (response: Response) => {
  return invalidStatus(response) ? Promise.reject(response) : response.blob();
};
