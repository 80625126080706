import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Checkbox from 'components/Common/FormElements/Checkbox';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import { CTADistrictRecord } from 'types';
import { FlexGroup, FlexRow } from 'layouts';
import { compose, prop } from 'util/objectUtility';
import { defaultTo } from 'ramda';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { useSelector } from 'react-redux';

type AssurancesProps = {
  locked: boolean;
  ctaRecord: CTADistrictRecord;
  saveCTAAssurances: Function;
};

export const AssurancesComponent: React.FC<AssurancesProps> = (props) => {
  const { locked, ctaRecord, saveCTAAssurances } = props;
  const [ctaState, setCtaState] = useState<CTADistrictRecord>(ctaRecord);
  const readOnly = useSelector(getIsCTAReadOnly);

  useEffect(() => {
    setCtaState(ctaRecord);
  }, [ctaRecord]);

  const updateState = (item: Partial<CTADistrictRecord>) => {
    setCtaState({ ...ctaState, ...item });
  };

  const handleSave = () => {
    saveCTAAssurances(ctaState);
  };

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-assurances"
        locked={locked}
        header="Assurances"
      >
        <SecureWrap component={['ctageneral']} isLocked={readOnly}>
          <Paper>
            <Instruction title="asd" hideTitle={true}>
              Read the text, fill in the fields, and check off the assurances.
              This is required prior to report submission.
            </Instruction>
            <FlexRow>
              <div>
                <p>
                  The following language applies to all pages of this report:
                </p>
                <p>
                  (A) Colorado State Board for Cummunity Colleges and
                  Occupational Education - (Herein after known as the State)
                </p>
                <p>
                  (B) Institution (Herin after known as the Grantee):{' '}
                  {ctaState.schoolDistrictName}
                </p>
              </div>
            </FlexRow>
            <FlexRow>
              <Input
                name="districtMailingAddress"
                value={ctaState.districtMailingAddress}
                onChange={updateState}
              />
              <Input
                name="contactPhoneNumber"
                value={compose(
                  defaultTo(''),
                  prop('contactPhoneNumber')
                )(ctaState)}
                onChange={updateState}
              />
            </FlexRow>
            <FlexRow>
              <FlexGroup>
                <FlexRow>
                  <div>
                    <Checkbox
                      name="assuranceProgramRepresentsDescription"
                      label={
                        'The CTE programs(including any also supported with other Federal funds such as those supporting special education programs) identified on this form represent programs/projetcs described on the approval documents on file with CCCS.'
                      }
                      value={ctaState.assuranceProgramRepresentsDescription}
                      onChange={updateState}
                    />
                    <div className="assurance-question__child">
                      Note: It is the district's obligation to retain all
                      records associated with the costs reported, including
                      copies of the signature page according to CTA record
                      retention requirements. Signing below will certify the
                      Grantee has met the following criteria:
                    </div>
                  </div>
                </FlexRow>
                <div className="assurance-question__child">
                  <FlexRow>
                    <Checkbox
                      name="assuranceCostsMeetRequirements"
                      label={`1. All programs and related costs included in your districts FY ${ctaState.year} CTA report, meet CTA program requirements and will be found compliant upon an audit.`}
                      value={ctaState.assuranceCostsMeetRequirements}
                      onChange={updateState}
                    />
                  </FlexRow>
                </div>
                <div className="assurance-question__child">
                  <FlexRow>
                    <Checkbox
                      name="assuranceAllProgramActive"
                      label={`2. All programs in your districts FY ${ctaState.year} CTA report are currently active with program approval (i.e. your school district is not requestiong funds on closed programs).`}
                      value={ctaState.assuranceAllProgramActive}
                      onChange={updateState}
                    />
                  </FlexRow>
                </div>
                <div className="assurance-question__child">
                  <FlexRow>
                    <Checkbox
                      name="assuranceInstructorsCredentialed"
                      label={`All instructors included in your school district's FY ${ctaState.year} CTA final have and maintain current credentialing in the program they are being claimed for.`}
                      value={ctaState.assuranceInstructorsCredentialed}
                      onChange={updateState}
                    />
                  </FlexRow>
                </div>
              </FlexGroup>
            </FlexRow>
            <FlexRow>
              <div className="u-flex-grow-1" />
              <Button
                asLink={false}
                text="Save"
                onClick={handleSave}
                className={`form__btn--submit t-save-assurances`}
              />
            </FlexRow>
          </Paper>
        </SecureWrap>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
