import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, ProgramFilterState } from 'types';

const initialState = {
  programLevelId: -1,
  instituteId: -1,
  schoolId: -1,
  districtId: -1,
  careerClusterId: -1,
  careerSectorId: -1,
  programStateId: [],
  currentWorkflowStatusId: -1,
  excludeArchived: true,
  excludeInactive: true,
};

export const setProgramFilterValue = createAction('setProgramFilterValue');
export const resetProgramFilterValue = createAction('resetProgramFilterValue');
export const clearProgramFilters = createAction('clearProgramFilters');

export default handleActions<ProgramFilterState, KeyValueType<number> | string>(
  {
    setProgramFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetProgramFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearProgramFilters: (_state, action) => {
      return initialState;
    },
  },
  initialState
);
