import { COMMON_KEY, COMMON_TRAVELER_TYPE_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { TravelerType } from 'types';

export const TRAVELER_TYPE_PATH = [COMMON_KEY, COMMON_TRAVELER_TYPE_KEY];

export const getIsDataFetching_TravelerType = (state: any): boolean => {
  return getIsDataFetching(state, TRAVELER_TYPE_PATH);
};

export const getLastUpdated_TravelerType = (state: any): number => {
  return getLastUpdated(state, TRAVELER_TYPE_PATH);
};

export const getRecords_TravelerType = (state: any): TravelerType[] => {
  return getRecords<TravelerType>(state, TRAVELER_TYPE_PATH);
};

export const shouldRefreshReduxState_TravelerType = (state: any): boolean => {
  return shouldRefreshReduxState<TravelerType>(state, TRAVELER_TYPE_PATH);
};
