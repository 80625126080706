import React from 'react';
import { useParams } from 'react-router';
import GrantsLayout from 'layouts/GrantsLayout';
import BaseLayout from 'layouts/BaseLayout';
import ImprovementPlanWorkflow from 'components/Grants/Applications/ImprovementPlan/Workflow';

export const ImprovementApproval: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <ImprovementPlanWorkflow
          grantFiscalYearId={grantFiscalYearId}
          {...props}
        />
      </GrantsLayout>
    </BaseLayout>
  );
};
