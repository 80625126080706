import { RefreshData } from './RefreshData.component';
import { connect } from 'react-redux';
import { resetProgramAwardCache } from 'redux/caches/programaward';
import { resetProgramCourseCreditsCache } from 'redux/caches/coursecredit';
import { resetProgramPathwayCache } from 'redux/caches/programpathway';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchProps = {
  resetProgramPathwayCache,
  resetProgramAwardCache,
  resetProgramCourseCreditsCache,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(RefreshData);
