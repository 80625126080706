import React from 'react';
import { Voucher, Signature } from 'types';
import { map, curry } from 'lodash';
import { FlexRow, FlexGroup } from 'layouts';
import { display } from 'util/objectUtility';
import { useSelector } from 'react-redux';
import { getFirstRecord_ReimbursementSummary } from 'redux/grants/_selectors';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import CommonSignatureComponent from 'components/Common/Signature';
import Instruction from 'components/Common/Instruction';
import Checkbox from 'components/Common/FormElements/Checkbox';

type VoucherAuthSigProps = {
  onChange: Function;
  v: ValidationObject<any>;
  voucher: Voucher;
};

export const VoucherAuthSignatureComponent: React.FC<VoucherAuthSigProps> = (
  props
) => {
  const { onChange, v, voucher } = props;

  const reimbursementRquest = useSelector(getFirstRecord_ReimbursementSummary);

  const handleSignature = curry((index: number, signature: Signature) => {
    const { authSignatures } = voucher;
    authSignatures[index] = signature;
    onChange({ authSignatures });
  });

  const render = display(reimbursementRquest);

  return (
    <React.Fragment>
      <Instruction title="Step 3: Authorization and Signature">
        <p>
          Read the statement below, agree by selecting the checkbox, and enter
          the requested information for Preparer and Reviewer signatures. Use
          “Save Voucher” when completed.
        </p>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <Checkbox
            value={voucher.certifyAllExpenditures}
            name="certifyAllExpenditures"
            onChange={onChange}
            label="I certify that all expenditures reported are for appropriate purposes and in accordance with the provisions of our CCCS approved Perkins Local Plan, EDGAR, applicable OMB Circulars and the Grant Recipient Award Documents."
          />
          {!v.getFieldValid('certifyAllExpenditures') && (
            <p className="u-error">{v.getError('certifyAllExpenditures')}</p>
          )}
        </FlexGroup>
      </FlexRow>
      {voucher.voucherName === 'Final Voucher' && (
        <React.Fragment>
          <FlexRow>
            <FlexGroup>
              <p>
                The following language applies to all pages of this document:
              </p>
              <ol>
                <li style={{ listStylePosition: 'inside' }}>
                  Colorado State Board for Community Colleges and Occupational
                  Education (Herinafter known as the Grantor)
                </li>
                <li style={{ listStylePosition: 'inside' }}>
                  Institution/District Code (Herinafter known as the Grantee):{' '}
                  {render('institutionCode')}
                </li>
                <li style={{ listStylePosition: 'inside' }}>
                  Institution/District Name: {render('institutionName')}
                </li>
              </ol>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <Checkbox
                value={voucher.granteeIndemnify}
                name="granteeIndemnify"
                onChange={onChange}
                label="To the extent authroized by law the Grantee shall indemnify, save and hold harmless the Grantor, it's employees and agents, against any and all claimes, damages, liability and court awards including costs, expenses, and attorney fees incurred as a result of any action or omission by the grantee, or it's employees, agents, subcontrators, or assignees in connection with the performance of this grant."
              />
              {!v.getFieldValid('certifyAllExpenditures') &&
                !voucher.granteeIndemnify && (
                  <p className="u-error">
                    {v.getError('certifyAllExpenditures')}
                  </p>
                )}
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <Checkbox
                value={voucher.granteeMetCriteria}
                name="granteeMetCriteria"
                onChange={onChange}
                label="The programs/projects identified on this form represent programs/projects described on the approval documents on file with CCCS. Signing below will certify the grantee has met the following criteria: 1. All related costs submitted for reimbursement for this fiscal year are compliant with the applicable Grant Recipient Agreement. 2. Career and Technical Education instructors of funded programs have and maintain credentialing appropriate to their funded program."
              />
              {!v.getFieldValid('certifyAllExpenditures') &&
                !voucher.granteeMetCriteria && (
                  <p className="u-error">
                    {v.getError('certifyAllExpenditures')}
                  </p>
                )}
            </FlexGroup>
          </FlexRow>
        </React.Fragment>
      )}
      <FlexRow>
        <p>
          By signing this report, I certify to the best of my knowledge and
          belief that the report is true, complete, and accurate, and the
          expenditures, disbursements and cash receipts are for the purposes and
          objectives set forth in the terms and conditions of the Federal award.
          I am aware that any false, fictitious, or fraudulent information, or
          the omission of any material fact, may subject me to criminal, civil
          or administrative penalties for fraud, false statements, false claims
          or otherwise. (U.S. Code Title 18, Section 1001 and Title 31, Sections
          3729-3730 and 3801-3812).
        </p>
      </FlexRow>
      {map(voucher.authSignatures, (signature: Signature, index: number) => {
        return (
          <CommonSignatureComponent
            key={index}
            signature={signature}
            onChange={handleSignature(index)}
          />
        );
      })}
    </React.Fragment>
  );
};
