import { EmailRecipient } from 'types';
import { getNextFiscalYearApplicationEndYear } from 'util/global';

export interface EmailTemplateBase {
  templateId: number;
  templateName: string;
  fiscalYear: number;
  emailWasSent: boolean;
}

export interface EmailTemplate extends EmailTemplateBase {
  sendGridText: string;
  recipients: EmailRecipient[];
}

export const emptyEmailTemplate = () => {
  return {
    templateId: -1,
    templateName: 'template name',
    fiscalYear: getNextFiscalYearApplicationEndYear(),
    emailWasSent: false,
    departments: [],
    sendGridText: '',
    recipients: [],
  };
};
