import { AdminComments } from 'types';

export const ADMIN_COMMENTS = '[ADMIN_COMMENTS]';

export const SET_ADMIN_COMMENTS = `${ADMIN_COMMENTS} SET`;
export const FETCH_ADMIN_COMMENTS = `${ADMIN_COMMENTS} FETCH`;

export const setAdminComments = (
  payload: AdminComments,
  debug: any = 'default'
) => {
  return {
    type: SET_ADMIN_COMMENTS,
    payload,
    debug,
  };
};
