import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { isApplicaionIdValid } from 'util/global';
import { useParams } from 'react-router-dom';
import { FlexRow, FlexGroup } from 'layouts';
import { ProgramOfStudy, Program } from 'types';
import {
  getPartnerProgramName,
  getInstitutionOrSchoolName,
} from 'util/ProgramOfStudy.utility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import IconButton from 'components/Common/IconButton';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import Table from 'components/Common/Table';
import DatatableLoading from 'components/Common/DatatableLoading';

type ProgramOfStudySummaryProps = {
  isFetching: boolean;
  loadProgramOfStudyData: Function;
  program: Program;
  programsOfStudy: ProgramOfStudy[];
  setCurrentNavAnchor: Function;
};

export const ProgramOfStudySummaryComponent: React.FC<
  ProgramOfStudySummaryProps
> = (props) => {
  const {
    isFetching,
    loadProgramOfStudyData,
    program,
    programsOfStudy,
    setCurrentNavAnchor,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramOfStudyData(programInstanceId);
    }
  }, [loadProgramOfStudyData, programInstanceId]);

  const getApprovalStatusText = (row: ProgramOfStudy) => {
    return row.programOfStudyStatusId === 1 ? 'Approved' : 'Needs Approval';
  };

  const ProgramName = () => {
    return program.programName;
  };

  const POS_HEADERS: TableColumnProps[] = [
    {
      style: { width: '25%' },
      name: 'This Program',
      cell: ProgramName,
      sortable: true,
      selector: ProgramName,
    },
    {
      name: 'Partner Program',
      style: { width: '25%' },
      cell: (row: ProgramOfStudy) => getPartnerProgramName(row, program),
      sortable: true,
      selector: (row: ProgramOfStudy) => getPartnerProgramName(row, program),
    },
    {
      name: 'Institution | School',
      style: { width: '25%' },
      cell: (row: ProgramOfStudy) => getInstitutionOrSchoolName(row, program),
      sortable: true,
      selector: (row: ProgramOfStudy) => {
        return getInstitutionOrSchoolName(row, program);
      },
    },
    {
      style: { width: '20%' },
      name: 'Status',
      cell: getApprovalStatusText,
      sortable: true,
      selector: getApprovalStatusText,
    },
    {
      name: 'View',
      style: { width: '5%' },
      cell: (row: ProgramOfStudy) => (
        <IconButton
          name="documents"
          tooltip="View program of study overview"
          asLink={true}
          to={`/programs/pos-overview/${programInstanceId}/${
            row.programOfStudyId
          }`}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <SummaryScroll componentStep="programofstudy" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('programofstudy')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <Paper>
        {isFetching ? (
          <DatatableLoading loading={true} />
        ) : (
          <FlexRow>
            <FlexGroup>
              <Table columns={POS_HEADERS} data={programsOfStudy} />
            </FlexGroup>
          </FlexRow>
        )}
      </Paper>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('programofstudy')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </React.Fragment>
  );
};
