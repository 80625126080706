import React from 'react';
import { every } from 'lodash';
import { PropertyDifferences } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { isFalse, compose } from 'util/objectUtility';
import { isNil } from 'ramda';
import { isStringValid } from 'util/validation.utility';
import { titleCase, toString } from 'util/global';
import Icon from 'components/Common/Icons';

type DifferencesProps = {
  propDiff: PropertyDifferences;
};

export const Differences: React.FC<DifferencesProps> = (props) => {
  const { propDiff } = props;
  const { name, oldValue, newValue } = propDiff;

  const validString = compose(
    isStringValid,
    toString
  );

  const shouldShow = (val: any) => {
    return every([isFalse(isNil(val)), validString(val)]);
  };

  return (
    <div className="history__diff">
      <h3 className="history__prop-name">{titleCase(name)}</h3>
      <FlexRow>
        <FlexGroup>
          {shouldShow(oldValue) ? (
            <div className="history__old">
              <Icon name="minus" className="history__change-icon" />
              <p className="history__value">{oldValue}</p>
            </div>
          ) : null}
        </FlexGroup>
        <FlexGroup>
          {shouldShow(newValue) ? (
            <div className="history__new">
              <Icon name="plus" className="history__change-icon" />
              <p className="history__value">{newValue}</p>
            </div>
          ) : null}
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
