import React from 'react';
import Tooltip from 'components/Common/Tooltip';

type ONetAttributionProps = {
  showLogo?: boolean;
};

export const ONetAttribution: React.FC<ONetAttributionProps> = (props) => {
  const { showLogo = true } = props;
  return (
    <div style={{ display: 'flex', margin: '1rem 0', alignItems: 'center' }}>
      <span style={{ textAlign: 'center' }}>
        <Tooltip
          indicator={
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://services.onetcenter.org/"
            >
              {showLogo && (
                <img
                  className="u-onet-logo"
                  src="https://www.onetcenter.org/image/link/onet-in-it.svg"
                  style={{ width: '130px', height: '60px', border: 'none' }}
                  alt="O*NET in-it"
                />
              )}
            </a>
          }
          tooltip="This site incorporates information from O*NET Web Services. Click to learn more."
        />
      </span>
      <p>
        This site incorporates information from{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://services.onetcenter.org/"
        >
          O*NET Web Services
        </a>{' '}
        by the U.S. Department of Labor, Employment and Training Administration
        (USDOL/ETA). O*NET&reg; is a trademark of USDOL/ETA.
      </p>
    </div>
  );
};
