import {
  toJSON,
  catchError,
  postWithAuthOptions,
  getFormattedUrl,
  getWithAuthOptions,
} from 'services';

export const ROLLOVER_ENDPOINT = getFormattedUrl(['api', 'Rollover']);
export const ROLLOVER_TEACHER_ENDPOINT = getFormattedUrl([
  'api',
  'Rollover',
  'Teachers',
]);

export const getRolloverStatus = async () => {
  return fetch(ROLLOVER_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('rollover.services', err, 'getRolloverStatus');
    });
};

export const executeRollover = async () => {
  return fetch(ROLLOVER_ENDPOINT, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('rollover.services', err, 'executeRollover');
    });
};

export const getRolloverTeacherStatus = async () => {
  return fetch(ROLLOVER_TEACHER_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('rollover.services', err, 'getRolloverTeacherStatus');
    });
};

export const executeTeacherRollover = async () => {
  return fetch(ROLLOVER_TEACHER_ENDPOINT, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('rollover.services', err, 'executeTeacherRollover');
    });
};
