import { useValidation } from 'de-formed-validations';
import { VoucherSpendingCategory } from 'types';

export const VoucherSpendingCategoryValidations = () => {
  return useValidation<VoucherSpendingCategory>({
    totalAmountForCurrentRequest: [
      {
        errorMessage:
          'You have exceeded the maximum funds available for indirect costs.',
        validation: (value: number, category: VoucherSpendingCategory) => {
          return category.limit ? value <= category.limit : true;
        },
      },
    ],
  });
};
