import React, { useState } from 'react';
import { useParams } from 'react-router';
import { FlexRow, FlexGroup } from 'layouts';
import {
  Voucher,
  AzureUploadParameters,
  VoucherFile,
  UniversalContact,
} from 'types';
import { map, isEmpty, size } from 'lodash';
import { getArrayOrEmpty } from 'util/arrayUtility';
import { isSuperAdmin } from 'util/permissionsUtility';
import { prop, safeGet } from 'util/objectUtility';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';

type SupportingDocumentsProps = {
  onChange: Function;
  voucher: Voucher;
  loggedInUser: UniversalContact;
};

export const SupportingDocuments: React.FC<SupportingDocumentsProps> = (
  props
) => {
  const { onChange, voucher, loggedInUser } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  // --[ local state ]---------------------------------------------------------
  const [upload1, setUpload1] = useState<boolean>(false);
  const [upload2, setUpload2] = useState<boolean>(false);
  const [upload3, setUpload3] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  const toggleUpload1 = () => setUpload1(!upload1);
  const toggleUpload2 = () => setUpload2(!upload2);
  const toggleUpload3 = () => setUpload3(!upload3);
  const FOLDER_NAMES = {
    GENERAL_LEDGER: 'GeneralLedger',
    PERSONNEL_PAYROLL: 'PersonnelPayroll',
    ADDITIONAL_DOCS: 'AdditionalDocs',
  };

  // get :: voucher -> string -> voucher[string]
  const get = safeGet(voucher);

  const uploadParams = (
    folderName: string,
    version: number
  ): AzureUploadParameters => ({
    container: 'grants',
    folder: `${grantFiscalYearId}|voucher|${get('voucherId')}|${folderName}`,
    version,
  });

  const handleUpload = (filePath: string = '', propName: string) => {
    if (isEmpty(filePath)) {
      return;
    }
    const data: VoucherFile = { filePath, grantVoucherFilePathId: 0 };

    const files = (prop(propName, voucher) as unknown) as VoucherFile[];

    const newFiles: VoucherFile[] = [...getArrayOrEmpty(files), ...[data]];
    switch (propName) {
      case 'generalLedgerFilePath':
        onChange({ ...voucher, generalLedgerFilePath: newFiles });
        break;

      case 'personnelPayrollFilePath':
        onChange({ ...voucher, personnelPayrollFilePath: newFiles });
        break;

      case 'additionalDocsFilePath':
        onChange({ ...voucher, additionalDocsFilePath: newFiles });
        break;

      default:
        break;
    }
  };

  const removeFile = (index: number, propName: string) => {
    const newFiles = (prop(propName, voucher) as unknown) as VoucherFile[];
    newFiles.splice(index, 1);

    switch (propName) {
      case 'generalLedgerFilePath':
        onChange({ ...voucher, generalLedgerFilePath: newFiles });
        break;

      case 'personnelPayrollFilePath':
        onChange({ ...voucher, personnelPayrollFilePath: newFiles });
        break;

      case 'additionalDocsFilePath':
        onChange({ ...voucher, additionalDocsFilePath: newFiles });
        break;

      default:
        break;
    }
  };

  // --[ display logic ]-------------------------------------------------------
  return (
    <React.Fragment>
      <Instruction title="Step 2: Upload Supporting Documents">
        <p>
          You may only upload one file per "Upload Document". If you have more
          than one ledger, payroll register, or supporting documents to submit,
          combine them into a single document per category before uploading.
          Documents accepted are PDF, XLSX, or CSV. Before you save, ensure the
          documents uploaded are correct. Once saved, you cannot delete these
          documents.
        </p>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">General Ledger</p>
          {map(get('generalLedgerFilePath'), (item, index: number) => {
            return (
              <div style={{ marginBottom: '.5rem' }} key={index}>
                <AzureDownloader
                  onClose={() => {
                    removeFile(index, 'generalLedgerFilePath');
                  }}
                  uploadParams={uploadParams(
                    FOLDER_NAMES.GENERAL_LEDGER,
                    size(get('generalLedgerFilePath'))
                  )}
                  filepath={item.filePath}
                  editable={isSuperAdmin(loggedInUser)}
                  allowReadOnlyDownloads
                />
              </div>
            );
          })}
          <Button
            className="form__btn--add grant-schedule__upload-btn"
            onClick={toggleUpload1}
            style={{ textAlign: 'left' }}
            text="+ Upload Document"
          />
          <AzureUploader
            onClose={toggleUpload1}
            open={upload1}
            uploadParams={uploadParams(
              FOLDER_NAMES.GENERAL_LEDGER,
              size(get('generalLedgerFilePath'))
            )}
            onConfirm={(file) => handleUpload(file, 'generalLedgerFilePath')}
          />
        </FlexGroup>
        <FlexGroup>
          <p className="form__label">Personnel Payroll Registers</p>
          {map(get('personnelPayrollFilePath'), (item, index: number) => {
            return (
              <div style={{ marginBottom: '.5rem' }} key={index}>
                <AzureDownloader
                  onClose={() => {
                    removeFile(index, 'personnelPayrollFilePath');
                  }}
                  uploadParams={uploadParams(
                    FOLDER_NAMES.PERSONNEL_PAYROLL,
                    size(get('personnelPayrollFilePath'))
                  )}
                  filepath={item.filePath}
                  editable={isSuperAdmin(loggedInUser)}
                  allowReadOnlyDownloads
                />
              </div>
            );
          })}
          <Button
            className="form__btn--add grant-schedule__upload-btn"
            onClick={toggleUpload2}
            style={{ textAlign: 'left' }}
            text="+ Upload Document"
          />
          <AzureUploader
            onClose={toggleUpload2}
            open={upload2}
            uploadParams={uploadParams(
              FOLDER_NAMES.PERSONNEL_PAYROLL,
              size(get('personnelPayrollFilePath'))
            )}
            onConfirm={(file) => handleUpload(file, 'personnelPayrollFilePath')}
          />
        </FlexGroup>
        <FlexGroup>
          <p className="form__label">Additional Supporting Documents</p>
          {map(get('additionalDocsFilePath'), (item, index: number) => {
            return (
              <div style={{ marginBottom: '.5rem' }} key={index}>
                <AzureDownloader
                  onClose={() => {
                    removeFile(index, 'additionalDocsFilePath');
                  }}
                  uploadParams={uploadParams(
                    FOLDER_NAMES.ADDITIONAL_DOCS,
                    size(get('additionalDocsFilePath'))
                  )}
                  filepath={item.filePath}
                  editable={isSuperAdmin(loggedInUser)}
                  allowReadOnlyDownloads
                />
              </div>
            );
          })}
          <Button
            className="form__btn--add grant-schedule__upload-btn"
            onClick={toggleUpload3}
            style={{ textAlign: 'left' }}
            text="+ Upload Document"
          />
          <AzureUploader
            onClose={toggleUpload3}
            open={upload3}
            uploadParams={uploadParams(
              FOLDER_NAMES.ADDITIONAL_DOCS,
              size(get('additionalDocsFilePath'))
            )}
            onConfirm={(file) => handleUpload(file, 'additionalDocsFilePath')}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  );
};
