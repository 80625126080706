import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { ProgramsOfStudyEdit } from 'components/Program/ProgramsOfStudy/ProgramsOfStudyConnect';

export const ProgramOfStudyStep: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramsOfStudyEdit {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramOfStudyStep;
