import React from 'react';
import { Question } from 'types';
import { FlexRow } from 'layouts';
import { randomString } from 'util/global';
import QuestionSelectBox from 'components/Common/SelectBoxes/QuestionSelectBox';

type QuestionDropdownProps = {
  question: Question;
  onChange: Function;
};

export const QuestionDropdown: React.FC<QuestionDropdownProps> = (props) => {
  const { question, onChange } = props;

  const handleChange = (data: any, key: any, value: any) => {
    const questionValue = {
      ...question.questionValue,
      value,
    };
    const newState = {
      ...question,
      questionValue,
    };
    onChange(newState);
  };

  return (
    <FlexRow>
      <QuestionSelectBox
        filter={[]}
        inputId={randomString()}
        label={question.text}
        name="This name is irrelevant - you can name it anything"
        records={question.childQuestions}
        placeholder="Choose a value"
        selection={Number(question.questionValue.value)}
        onChange={handleChange}
      />
    </FlexRow>
  );
};
