import { WorkflowUser, WorkflowAction } from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';

export interface WorkflowStep {
  date: Date;
  title: string;
  comment: string;
  isCurrent: boolean;
  adminComment: string;
  modifiedBy: WorkflowUser;
  approvers: WorkflowUser[];
  workflowActions: WorkflowAction[];
}

export const emptyWorkflowStep = (): WorkflowStep => {
  return {
    date: new Date(Date.now()),
    title: EMPTY_PLACEHOLDER,
    comment: EMPTY_PLACEHOLDER,
    isCurrent: true,
    adminComment: EMPTY_PLACEHOLDER,
    modifiedBy: {
      universalContactId: -1,
      name: EMPTY_PLACEHOLDER,
      email: EMPTY_PLACEHOLDER,
      usertype: EMPTY_PLACEHOLDER,
    },
    approvers: [
      {
        universalContactId: -1,
        name: EMPTY_PLACEHOLDER,
        email: EMPTY_PLACEHOLDER,
        usertype: EMPTY_PLACEHOLDER,
      },
    ],
    workflowActions: [],
  };
};
