import React, { useEffect, useState } from 'react';
import { every } from 'lodash';
import { useParams } from 'react-router';
import { Expense, ExpenseProgram, ItemFilter, SpendingCategory } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { buildOnChange, createTempId, isEqual } from 'util/formHelpers';
import { updateSpendingCategories } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { useDispatch } from 'react-redux';
import { compose, mergeObjects, prop, safeGet } from 'util/objectUtility';
import { upsertInPlace } from 'util/arrayUtility';
import { EquipmentExpense } from '../ExpenseTypeSubs/EquipmentExpense.component';
import { TravelExpense } from '../ExpenseTypeSubs/TravelExpense.component';
import { truncateString, titleCase } from 'util/global';
import { BaseExpenseValidations } from 'validations/AnnualSpendingPlan/BaseExpense.validations';
import { TravelExpenseValidations } from 'validations/AnnualSpendingPlan/TravelExpense.validations';
import { EquipmentExpenseValidations } from 'validations/AnnualSpendingPlan/EquipmentExpense.validations';
import { getOffsetAmount, isAdminCategory } from 'util/annualSpendingUtility';
import { showEverythingFilter } from 'util/filterUtility';
import AllocatedFundsRemaing from '../../FundsRemaining/AllocatedFundsRemaing';
import DualListBox from 'components/Common/FormElements/DualListBox';
import ExpenseCategorySelectBox from 'components/Common/SelectBoxes/ExpenseCategorySelectBox';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';

type ExpenseEditProps = {
  close: Function;
  expense: Expense;
  expensePrograms: ExpenseProgram[];
  loadExpenseProgram: Function;
  spendingCategory: SpendingCategory;
  isLocked: boolean;
};

export const ExpenseEditComponent: React.FC<ExpenseEditProps> = (props) => {
  const {
    close,
    expense,
    expensePrograms,
    loadExpenseProgram,
    spendingCategory,
    isLocked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = BaseExpenseValidations();

  const travelValidations = TravelExpenseValidations();
  const {
    isValid: travelIsValid,
    validateAll: travelValidateAll,
    validationErrors: travelValidationErrors,
  } = travelValidations;

  const equipmentValidations = EquipmentExpenseValidations();
  const {
    isValid: equipmentIsValid,
    validateAll: equipmentValidateAll,
    validationErrors: equipmentValidationErrors,
  } = equipmentValidations;

  const dispatch = useDispatch();
  const updateCategory = buildOnChange(
    updateSpendingCategories,
    dispatch,
    spendingCategory
  );

  // --[ local state ]---------------------------------------------------------
  const [expenseState, setExpenseState] = useState<Expense>({ ...expense });
  const [filter, setFilter] = useState<ItemFilter>(showEverythingFilter());

  // --[ component logic ]-----------------------------------------------------
  const isAdminCat: boolean = isAdminCategory(spendingCategory);

  // isTravelExpense :: ExpenseState -> bool
  const isTravelExpense = compose(isEqual(3), prop('financialCategoryId'));
  // isEquipmentExpense :: ExpenseState -> bool
  const isEquipmentExpense = compose(isEqual(4), prop('financialCategoryId'));

  // get :: expenseState -> string -> expenseState[string]
  const get = safeGet(expenseState);

  const handleFormChange = (data: Partial<Expense>, key: any, value: any) => {
    const newState = mergeObjects<Expense>(expenseState, data);
    if (key === 'isNewProgram') {
      validate('expensePrograms', value, newState);
    }
    if (key === 'financialCategoryId') {
      if (value !== 3) {
        travelValidateAll(newState);
      }
      if (value !== 4) {
        equipmentValidateAll(newState);
      }
    }
    if (key === 'travelStartDate' || key === 'travelEndDate') {
      travelValidateAll(newState, ['travelStartDate', 'travelEndDate']);
    }
    validateIfTrue(key, value, newState);
    setExpenseState(newState);
  };

  const handleExpenseProgram = (expensePrograms: ExpenseProgram[]) => {
    handleFormChange({ expensePrograms }, 'expensePrograms', expensePrograms);
  };

  // validateAllExpenses :: () -> bool
  const validateAllExpenses = () => {
    return every([
      validateAll(expenseState),
      travelValidateAll(expenseState),
      equipmentValidateAll(expenseState),
    ]);
  };

  const handleSave = () => {
    if (validateAllExpenses()) {
      const expenseId =
        get('expenseId') === -1
          ? createTempId(spendingCategory.expenses, 'expenseId')
          : get('expenseId');

      const newExpense: Expense = {
        ...expenseState,
        expenseId,
      };
      const newExpenses = upsertInPlace(
        spendingCategory.expenses,
        newExpense,
        'expenseId'
      );
      const payload: SpendingCategory = {
        ...spendingCategory,
        expenses: newExpenses,
      };
      updateCategory(payload);
      close();
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setExpenseState({ ...expense });
  }, [setExpenseState, expense]);

  useEffect(() => {
    loadExpenseProgram(grantFiscalYearId);
  }, [loadExpenseProgram, grantFiscalYearId]);

  useEffect(() => {
    if (isAdminCat) {
      setFilter({
        exclude: true,
        property: 'expenseCategoryId',
        values: 4,
      });
    } else {
      setFilter(showEverythingFilter());
    }
  }, [isAdminCat]);

  // --[ display logic ]-------------------------------------------------------
  // showCTEPrograms :: () -> bool
  const showCTEPrograms = () => {
    return !isAdminCategory(spendingCategory);
  };

  return (
    <React.Fragment>
      <SecureWrap isLocked={isLocked} component={['grantsannualspending']}>
        <Paper>
          <Instruction title="Add an Expense">
            <p>
              We recommend rounding to the nearest dollar. For more help filling
              out this Expense, visit Colorado Perkins Implementation
              Strategies, Performance Metrics report, or a list of Required uses
              of funds.
            </p>
            <br />
            <p>Travel Expenses:</p>
            <ul>
              <li>
                Out of State travel occurring in the Summer or Fall, should
                include all details upon submission.
              </li>
              <li>
                Please refer to Appendix B in the Administrators’ Handbook for
                current Perkins Travel Policy.
              </li>
            </ul>
            <br />
            <p>Equipment Expenses: </p>
            <ul>
              <li>
                In order to qualify as Equipment, Federal law requires the Total
                Cost of Equipment to be $5000 or greater.
              </li>
            </ul>
          </Instruction>
        </Paper>
        <Paper>
          <h2>Project Funds Remaining</h2>
          <p>Allocate all funds toward expenses</p>
          <AllocatedFundsRemaing
            offset={getOffsetAmount(expenseState, spendingCategory)}
            spendingCategory={spendingCategory}
          />
        </Paper>

        <Paper>
          <FlexRow>
            <h2>
              {get('expenseId') < 0 && 'New '}Expense{' '}
              {get('expenseId') > 0 && get('expenseId')}
            </h2>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <Toggle
                name={'isBackupExpense'}
                value={get('isBackupExpense')}
                onChange={handleFormChange}
                noLabel
                description="This is a Backup Expense"
              />
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <Input
                name="expenseName"
                value={get('expenseName')}
                onChange={handleFormChange}
                errorMessage={getError('expenseName')}
                onBlur={() => validate('expenseName', get('expenseName'))}
                valid={getFieldValid('expenseName')}
              />
            </FlexGroup>

            <FlexGroup
              onBlur={() =>
                validate('financialCategoryId', get('financialCategoryId'))
              }
            >
              <ExpenseCategorySelectBox
                filter={filter}
                name="financialCategoryId"
                label="Select a Financial Category"
                selection={get('financialCategoryId')}
                onChange={handleFormChange}
                errorMessage={getError('financialCategoryId')}
                valid={getFieldValid('financialCategoryId')}
              />
            </FlexGroup>

            <FlexGroup>
              <Input
                errorMessage={getError('budgetedAmount')}
                min={0}
                max={50000000}
                name="budgetedAmount"
                onBlur={() => validate('budgetedAmount', get('budgetedAmount'))}
                onChange={handleFormChange}
                type="number"
                value={get('budgetedAmount')}
                valid={getFieldValid('budgetedAmount')}
              />
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <Textarea
                maxLength={4000}
                onChange={handleFormChange}
                label="Expense Description"
                name="description"
                value={get('description')}
                errorMessage={getError('description')}
                onBlur={() => validate('description', get('description'))}
                valid={getFieldValid('description')}
              />
            </FlexGroup>
          </FlexRow>

          {isTravelExpense(expenseState) && (
            <FlexRow>
              <TravelExpense
                expense={expenseState}
                onChange={handleFormChange}
                validations={travelValidations}
              />
            </FlexRow>
          )}

          {isEquipmentExpense(expenseState) && (
            <FlexRow>
              <EquipmentExpense
                expense={expenseState}
                onChange={handleFormChange}
                validations={equipmentValidations}
              />
            </FlexRow>
          )}

          {showCTEPrograms() && (
            <React.Fragment>
              <FlexRow>
                <FlexGroup>
                  <Toggle
                    name={'isNewProgram'}
                    value={get('isNewProgram')}
                    onChange={handleFormChange}
                    noLabel
                    description="This expense is for a new program"
                  />
                </FlexGroup>
              </FlexRow>
              <div className={get('isNewProgram') ? 'u-sec-ro' : ''}>
                <FlexRow>
                  <FlexGroup>
                    <DualListBox
                      data={[...expensePrograms]}
                      dataLabel="Select CTE Program(s) this expense relates to: "
                      displayName={(program: ExpenseProgram) => (
                        <div className="expense-program">
                          <p
                            title={titleCase(program.programName)}
                            className="expense-program__program"
                          >
                            {truncateString(titleCase(program.programName), 15)}
                          </p>
                          <p
                            title={titleCase(program.schoolName)}
                            className="expense-program__school"
                          >
                            {truncateString(titleCase(program.schoolName), 15)}
                          </p>
                          <p className="expense-program__expiration">
                            {new Date(program.expiration).toLocaleDateString()}
                          </p>
                        </div>
                      )}
                      headers={() => (
                        <div className="expense-program">
                          <span className="expense-program__program--header">
                            Program
                          </span>
                          <span className="expense-program__school--header">
                            School Name
                          </span>
                          <span className="expense-program__expiration--header">
                            Expiration
                          </span>
                        </div>
                      )}
                      height={200}
                      selectedData={get('expensePrograms')}
                      selectedDataLabel="Selected Program(s):"
                      selector="programId"
                      sortBySelectors={['programName', 'schoolName']}
                      onChange={handleExpenseProgram}
                    />
                    {!getFieldValid('expensePrograms') && (
                      <p className="u-color-red">
                        {getError('expensePrograms')}
                      </p>
                    )}
                  </FlexGroup>
                </FlexRow>
              </div>
            </React.Fragment>
          )}
          {isEquipmentExpense(expenseState) && (
            <FlexRow>
              <span className="expense-warning">
                <Icon name="megaphone" className="expense-warning__icon" />
                <div className="expense-warning__text">
                  <p>
                    Adding an Equipment Expense will change the allowable amount
                    for Indirect Administrative Costs.
                  </p>
                  <p>
                    You will need to revisit funds allocated toward
                    Administrative expenses.
                  </p>
                </div>
                <Icon
                  name="megaphone"
                  className="expense-warning__icon expense-warning__icon--flip"
                />
              </span>
            </FlexRow>
          )}
        </Paper>
        <Paper>
          <SaveButtons
            alternateAction={handleSave}
            disabled={!isValid && !equipmentIsValid && !travelIsValid}
            onCancel={close}
            saveText="Save Expense"
            validationErrors={[
              ...validationErrors,
              ...travelValidationErrors,
              ...equipmentValidationErrors,
            ]}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
