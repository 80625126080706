import { setProgramAwardCache } from 'redux/caches/programaward';
import { setProgramCourseCreditsCache } from 'redux/caches/coursecredit';
import { setProgramPathwayCache } from 'redux/caches/programpathway';
import { CourseCreditProgram, ProgramPathway, ProgramAward } from 'types';
import {
  getProgramAwards,
  getProgramCourseCredits,
  getProgramPathways,
} from 'redux/caches/_selectors';
import {
  buildProgramPathwaysCache,
  buildCourseCreditCache,
} from 'redux/utility/cacheHelpers';

/**
 * Set ProgramAward data from either HTTP request payload or cache
 *@param dispatch the dispatcher to set data
 *@param state the cache state
 *@param payload the HTTP request payload
 *@return dispatch with array of records to set in cache
 */
export const handleProgramAwardCache = (
  dispatch: Function,
  state: any,
  payload: { records: ProgramAward[]; programInstanceId: number }
) => {
  const { programInstanceId, records: data } = payload;
  const cache = getProgramAwards(state, programInstanceId);
  const records = cache ? cache : data;
  return dispatch(setProgramAwardCache({ programInstanceId, records }));
};

/**
 * Set CourseCreditProgram data from either HTTP request payload or cache
 *@param dispatch the dispatcher to set data
 *@param state the cache state
 *@param payload the HTTP request payload
 *@return dispatch with array of records to set in cache
 */
export const handleProgramCourseCreditCache = (
  dispatch: Function,
  state: any,
  payload: { records: CourseCreditProgram; programInstanceId: number }
) => {
  const { programInstanceId, records: data } = payload;
  const cachedRecords = getProgramCourseCredits(state, programInstanceId);
  const records = buildCourseCreditCache(cachedRecords, data);
  return dispatch(setProgramCourseCreditsCache({ programInstanceId, records }));
};

/**
 * Set PathwayPathway data from either HTTP request payload or cache
 *@param dispatch the dispatcher to set data
 *@param state the cache state
 *@param payload the HTTP request payload
 *@return dispatch with array of records to set in cache
 */
export const handleCareerPathwayCoursesCache = (
  dispatch: Function,
  state: any,
  payload: { records: ProgramPathway[]; programInstanceId: number }
) => {
  const { programInstanceId, records: data } = payload;
  const cachedRecords: ProgramPathway[] | null = getProgramPathways(
    state,
    programInstanceId
  );
  const records = buildProgramPathwaysCache(cachedRecords, data);
  return dispatch(setProgramPathwayCache({ programInstanceId, records }));
};
