import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
} from 'services';

const BASE_URL = ['api', 'ctacourse'];

export const readCTAContractingCourse = async (
  ctaId: number,
  ctaHostingId: number
) => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'contracting', ctaId, ctaHostingId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Contracting Course',
        err,
        'readCTAContractingCourse'
      );
    });
};

export const readCTAHostingCourse = async (
  ctaId: number,
  ctaContractingId: number
) => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'hosting', ctaId, ctaContractingId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Hosting Course', err, 'readCTAHostingCourse');
    });
};

export const readCTAInstructionalCourse = async (
  ctaId: number,
  ctaInstructorId: number
) => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'instructional', ctaId, ctaInstructorId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Instructional Course',
        err,
        'readCTAInstructionalCourse'
      );
    });
};
