import React from 'react';
import { EditCTAInstructor } from 'components/CTA/DistrictReporting/Instructional/EditCTAInstructor.component';
import BaseLayout from 'layouts/BaseLayout';

export const CTAInstructorPage = () => {
  return (
    <BaseLayout>
      <section className="program__body">
        <EditCTAInstructor />
      </section>
    </BaseLayout>
  );
};
