import React, { useState, useEffect } from 'react';
import { UniversalContact, AdminOption, WorkflowSubmission } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import Textarea from 'components/Common/FormElements/Textarea';
import Button from 'components/Common/FormElements/Button';
import { contactHasRoles } from 'util/permissionsUtility';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';
import { useParams } from 'react-router-dom';
import ProgramWorkflowStatus from 'components/Common/SelectBoxes/AutoSuggestBoxes/ProgramWorkflowStatus';
import { createProgramAdminWorkflowUpdate } from 'services/workflow/programworkflow.service';
import Loading from 'components/Common/Loading';

type AdminStateControlProps = {
  loggedInUser: UniversalContact;
  validRoles: string[];
  optionsEndpoint: Function;
  submitProgramForUpdate: Function;
};

export const AdminStateControlComponent: React.FC<AdminStateControlProps> = (
  props
) => {
  const { programInstanceId }: any = useParams();

  const [adminSubmission, setAdminSubmission] = useState<Partial<AdminOption>>({
    comment: '',
  });

  const [options, setOptions] = useState<AdminOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    loggedInUser,
    validRoles,
    optionsEndpoint,
    submitProgramForUpdate,
  } = props;

  const onSubmit = () => {
    const submission = adminSubmission as AdminOption;
    setAdminSubmission({
      comment: '',
    });

    if (submission.stepName.toLowerCase() === 'admin comment') {
      setLoading(true);
      createProgramAdminWorkflowUpdate(programInstanceId, submission)
        .then(() => window.location.reload(false))
        .catch((err: any) => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const wfsub: WorkflowSubmission = {
        optionId: submission.stepOptionId,
        comment: submission.comment,
      };
      submitProgramForUpdate(programInstanceId, wfsub);
    }
  };

  const onCommentChange = (comment: Partial<AdminOption>) => {
    setAdminSubmission({ ...adminSubmission, ...comment });
  };

  const onChange = (item: AdminOption) => {
    setAdminSubmission({ ...item, comment: adminSubmission!.comment });
  };

  useEffect(() => {
    optionsEndpoint(programInstanceId)
      .then((items: AdminOption[]) => {
        setOptions(items);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.LoadDataFailed}
          />
        );
      });
  }, [optionsEndpoint, programInstanceId]);

  const getDisabled = () => {
    return !adminSubmission.stepName;
  };

  return (
    <React.Fragment>
      <Loading isActive={loading} />
      {contactHasRoles(loggedInUser, validRoles) && (
        <FlexRow>
          <FlexGroup>
            <ProgramWorkflowStatus
              maxResults={30}
              options={options}
              label="Select an option for this program to override workflow"
              onChange={onChange}
              selection={adminSubmission}
            />
            <Textarea
              maxLength={4000}
              label="CCCS Admin Comments"
              name="comment"
              onChange={onCommentChange}
              value={adminSubmission.comment!}
            />
            <div className="u-flex">
              <div className="u-flex-grow-1" />
              <Button
                disabled={getDisabled()}
                onClick={onSubmit}
                text="Submit Admin"
                className="form__btn--submit"
              />
            </div>
          </FlexGroup>
        </FlexRow>
      )}
    </React.Fragment>
  );
};
