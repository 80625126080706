import { connect } from 'react-redux';
import { getRecords_CTASupportPersonnel } from 'redux/cta/ctasupportpersonnel.selectors';
import { SupportingPersonnelComponent } from './SupportingPersonnel.component';
import {
  loadCTASupportPersonnel,
  deactivateCTASupportPersonnel,
} from 'redux/cta/ctasupportpersonnel';

const mapStateToProps = (state: any) => {
  const supportingPersonnel = getRecords_CTASupportPersonnel(state);
  return {
    supportingPersonnel,
  };
};

const dispatchProps = {
  loadCTASupportPersonnel,
  deactivateCTASupportPersonnel,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SupportingPersonnelComponent);
