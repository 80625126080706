import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from 'services';
import { ProgramArticulation } from 'types';

const ARTICULATIONS_ENDPOINT = 'api/ProgramArticulations/';

export const readProgramArticulations = async (programInstanceId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ARTICULATIONS_ENDPOINT}byProgramInstanceId/${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramArticulations', err);
    });
}; // end

export const createUpdateArticulation = async (
  articulation:
    | ProgramArticulation
    | Omit<ProgramArticulation, 'programArticulationId'>
) => {
  const url = `${ENVIRONMENT.API_URL}${ARTICULATIONS_ENDPOINT}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(articulation),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramArticulations', err);
    });
}; // end

export const deleteArticulation = async (articulationId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ARTICULATIONS_ENDPOINT}deactivate/${articulationId}`;
  return fetch(url, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramArticulations', err);
    });
}; // end
