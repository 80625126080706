import { useValidation } from 'de-formed-validations';
import { SignatureWithAssertion } from 'types';
import { SignatureComponentValidations } from 'validations/SignatureComponent.validations';
import { mergeObjects } from 'util/objectUtility';
import { isEmptySignature } from 'util/formHelpers';
import { every, some } from 'lodash';

type S = SignatureWithAssertion;

type ValidationState = {
  canSubmit: S;
  canSave: S;
};

export const SignatureWithAssertionValidations = () => {
  const { validateAll } = SignatureComponentValidations();

  return useValidation<ValidationState>({
    canSave: [
      {
        errorMessage:
          'Please indicate your agreement and fill all fields before saving.',
        validation: (signature: S, _) => {
          const isDirty = some([
            signature.wasReadAndAgreedTo,
            signature.isDirty,
            !isEmptySignature(signature),
          ]);
          const dirtySig = mergeObjects<S>(signature, { isDirty });
          return isDirty
            ? validateAll(dirtySig) && dirtySig.wasReadAndAgreedTo
            : true;
        },
      },
    ],
    canSubmit: [
      {
        errorMessage:
          'Not all required signatures have been provided. Please provide all before submitting for approval.',
        validation: (signature: S, _) => {
          return signature.required
            ? every([
                signature.wasReadAndAgreedTo,
                !isEmptySignature(signature),
                validateAll(signature),
              ])
            : true;
        },
      },
    ],
  });
};
