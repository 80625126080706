import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Department,
  District,
  Email,
  Institute,
  School,
  PermissionRole,
  UniversalContact,
} from 'types';
import {
  extractAddress,
  extractPersonal,
  extractContactInfo,
  canEditUser,
  getContactFullName,
  isContactPowerRole,
  isNotCurrentUser,
} from 'util/universalcontact.utility';
import { FlexRow, FlexGroup } from 'layouts';
import { PersonalInfo } from '../ContactForms/Personal.component';
import { AddressInfo } from '../ContactForms/AddressInfo.component';
import { ContactInfo } from '../ContactForms/ContactInfo.component';
import { TeacherInformation } from '../ContactForms/TeacherInformation.component';
import { ContactNotes } from '../ContactForms/ContactNotes.component';
import { DistrictSchoolInfo } from 'components/UniversalContacts/ContactForms/DistrictSchoolInfo.component';
import { InstituteDepartmentInfo } from 'components/UniversalContacts/ContactForms/InstituteDepartmentInfo.component';
import { AddRemovePermissions } from 'components/UniversalContacts/ContactForms/AddRemovePermissions.component';
import { validateEmail, validatePhoneNumber } from 'util/formHelpers';
import { LoginInformation } from 'components/UniversalContacts/ContactForms/LoginInformation.component';
import { UniversalContactValidation } from 'validations/UniversalContact.validations';
import { some, map, isNil } from 'lodash';
import { isApplicaionIdValid } from 'util/global';
import { getContactByEmail } from 'services/universalcontacts/universalcontact.services';
import { Dialog } from '@rmwc/dialog';
import { toast } from 'react-toastify';
import { UserEnableToggle } from '../ContactForms/UserEnableToggle.component';
import Button from 'components/Common/FormElements/Button';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Skeleton from 'components/Common/Skeleton';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type ContactFormProps = {
  contact: UniversalContact;
  contactRoles: PermissionRole[];
  departments: Department[];
  districts: District[];
  institutes: Institute[];
  loadPermissionRoles: Function;
  loadSingleUniversalContact: Function;
  loggedInUser: UniversalContact;
  saveContact: Function;
  saveContactPermissionRoles: Function;
  schools: School[];
  isFetching: boolean;
};

export const UniversalContactAddEdit: React.FC<ContactFormProps> = (props) => {
  const {
    contact,
    departments,
    districts,
    institutes,
    loadPermissionRoles,
    loadSingleUniversalContact,
    loggedInUser,
    saveContact,
    schools,
    isFetching,
  } = props;

  const history = useHistory();
  const validations = UniversalContactValidation();
  const { validateAll, validateIfTrue } = validations;

  const { userId: uId }: any = useParams();
  const userId = Number(uId);

  const isNewContact = isNaN(userId);

  const [
    universalContactState,
    setUniversalContactState,
  ] = useState<UniversalContact>({ ...contact });

  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [groupModalOpen, setGroupModalOpen] = useState<boolean>(false);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<UniversalContact>();
  const [credentialExists, setCredentialExists] = useState<UniversalContact>();
  const [duplicateEmail, setDuplicateEmail] = useState<string>('');
  const [duplicateCredential, setDuplicateCredential] = useState<string>('');

  const checkHasGroupAssociation = () => {
    const empty = some([
      universalContactState.schools.length,
      universalContactState.institutes.length,
      universalContactState.districts.length,
      universalContactState.departments.length,
    ]);
    return empty;
  };

  const checkIfEmailExists = async (email: string) => {
    if (validateEmail(email.trim())) {
      const user = await getContactByEmail(email.trim());
      if (isNotCurrentUser(user, userId)) {
        setEmailExists(user);
        setDuplicateEmail(email);
      }
    }
  };

  const handleFormChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, universalContactState);
    if (key === 'loginEmail') {
      validations.validate(
        'universalContactEmail',
        universalContactState.universalContactEmail,
        universalContactState
      );
    }
    setUniversalContactState({
      ...universalContactState,
      ...data,
    });
  };

  const getHeaderName = () => {
    return universalContactState.firstName
      ? `${universalContactState.firstName} ${universalContactState.lastName}`
      : 'Universal Contact';
  };

  const handlePermissionChange = (data: PermissionRole[]) => {
    setUniversalContactState({
      ...universalContactState,
      permissionRoles: data,
    });
  };

  const validateOneEmail = () => {
    const {
      universalContactEmail,
      isAadB2c,
      loginEmail,
    } = universalContactState;

    if (isAadB2c) {
      return loginEmail.length > 0 && validateEmail(loginEmail);
    }
    return (
      universalContactEmail.length > 0 &&
      validateEmail(universalContactEmail[0].email)
    );
  };
  const validateOnePhone = () => {
    const { universalContactPhone } = universalContactState;

    return (
      universalContactPhone.length > 0 &&
      validatePhoneNumber(universalContactPhone[0].number)
    );
  };

  const renderErrors = () => {
    let errors = [...validations.validationErrors];
    if (duplicateEmail.length > 0) {
      errors = [
        ...errors,
        `Email ${duplicateEmail} is already in use by another user.`,
      ];
    }
    if (
      duplicateCredential &&
      duplicateCredential.length > 0 &&
      universalContactState.credentialOverride === false
    ) {
      errors = [
        ...errors,
        `Credential number ${duplicateCredential} is already in use by another user. Please contact a site administrator for assistance.`,
      ];
    }
    return errors;
  };

  const handleSave = () => {
    return checkHasGroupAssociation()
      ? saveUniversalContact()
      : setGroupModalOpen(true);
  };

  const confirmSave = () => {
    setGroupModalOpen(false);
    saveUniversalContact();
  };

  const saveUniversalContact = () => {
    const valid = validateAll(universalContactState);
    if (!validateOneEmail()) {
      return toast(
        <ToastNotification
          status="error"
          message="At least one email is required."
        />
      );
    }
    if (!validateOnePhone()) {
      return toast(
        <ToastNotification
          status="error"
          message="At least one phone number is required."
        />
      );
    }
    if (valid) {
      return saveContact(universalContactState)
        .then(() => history.push('/contacts'))
        .catch(() =>
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          )
        );
    }
    return toast(
      <ToastNotification
        status="error"
        message={StringResources.Errors.ValidationFailed}
      />
    );
  };

  const isNewOrEditable = () => {
    return some([
      canEditUser(loggedInUser, contact),
      !isApplicaionIdValid(userId),
    ]);
  };

  const navigateToUser = () => {
    if (emailExists) {
      history.push(`/contacts/${emailExists.universalContactId}`);
      setDuplicateEmail('');
      setEmailExists(undefined);
      setEmailModalOpen(false);
    }
  };

  const handleCloseEmailWarning = () => {
    if (universalContactState.loginEmail === duplicateEmail) {
      setUniversalContactState({
        ...universalContactState,
        loginEmail: '',
        isAadB2c: false,
      });
    } else {
      const { universalContactEmail } = universalContactState;
      const updatedEmails = map(universalContactEmail, (email: Email) => {
        return email.email === duplicateEmail ? { email: '' } : email;
      });
      setUniversalContactState({
        ...universalContactState,
        universalContactEmail: updatedEmails,
      });
    }
    setDuplicateEmail('');
    setEmailExists(undefined);
    setEmailModalOpen(false);
  };

  useEffect(() => {
    setEmailModalOpen(isNotCurrentUser(emailExists, userId));
  }, [emailExists, loggedInUser, setEmailModalOpen, userId]);

  useEffect(() => {
    if (!isFetching && isNil(universalContactState.programLevelIds)) {
      // if null the full contact isn't loaded
      setNeedsRefresh(!needsRefresh); // triggers state change
    }
    setUniversalContactState({ ...contact });
  }, [contact, isFetching]); // eslint-disable-line

  useEffect(() => {
    if (isApplicaionIdValid(userId)) {
      loadSingleUniversalContact(userId);
      loadPermissionRoles();
    }
  }, [loadSingleUniversalContact, loadPermissionRoles, userId, needsRefresh]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} />
      <SecureWrap
        isLocked={!isNewOrEditable()}
        isPage={true}
        component="universalcontacts"
        owner={isNewContact ? loggedInUser.universalContactId : userId}
      >
        <Dialog open={groupModalOpen} onClose={() => setGroupModalOpen(false)}>
          <div className="dialog">
            <h2 className="dialog__title">Contact Has No Group Associations</h2>
            <div className="dialog__children">
              <p className="u-error">
                Warning: the contact you are trying to create has no
                associations with any districts, schools, institutes, or
                departments.
              </p>
              <p>
                If this is intentional, you may continue; however, be advised
                that depending on your permissions, you might not be able to see
                or edit this contact if they are not in a group you share.
              </p>
            </div>
            <div className="dialog__btn-container">
              <Button
                onClick={() => setGroupModalOpen(false)}
                className="form__btn--cancel"
                text="Do not save"
              />
              <Button
                onClick={confirmSave}
                className="confirm-button form__btn--submit"
                text="Save Anyways"
              />
            </div>
          </div>
        </Dialog>
        <Dialog open={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
          <div className="dialog">
            <h2 className="dialog__title">Duplicate Email Detected</h2>
            <div className="dialog__children">
              <p className="u-error">
                The email you have entered is already in use.
              </p>
              {emailExists && (
                <p>
                  The email you have provided is already in use by
                  {` ${getContactFullName(emailExists as UniversalContact)}`}.
                  Would you like to navigate to their profile instead?
                </p>
              )}
            </div>
            <div className="dialog__btn-container">
              <Button
                onClick={handleCloseEmailWarning}
                className="form__btn--cancel"
                text="Cancel"
              />
              <Button
                onClick={navigateToUser}
                className="confirm-button form__btn--submit"
                text="Go to existing user"
              />
            </div>
          </div>
        </Dialog>
        <Paper>
          <div className="program__header">
            <h1 className="program__title">
              {isFetching ? <Skeleton /> : getHeaderName()}
            </h1>
            <h3>Create or Edit a Universal Contact</h3>
          </div>
        </Paper>
        <Paper>
          <FlexRow>
            <FlexGroup>
              <FlexRow>
                <h3 className="u-section-title-text">Login Credentials</h3>
              </FlexRow>
              <LoginInformation
                checkIfEmailExists={checkIfEmailExists}
                contact={universalContactState}
                emailExists={emailExists}
                onChange={handleFormChange}
              />
            </FlexGroup>
            {isContactPowerRole(loggedInUser) && (
              <FlexGroup>
                <FlexRow>
                  <h3 className="u-section-title-text">Active</h3>
                </FlexRow>
                <UserEnableToggle
                  contact={universalContactState}
                  loggedInUser={loggedInUser}
                  onChange={handleFormChange}
                />
              </FlexGroup>
            )}
            <FlexGroup />
          </FlexRow>

          <FlexGroup>
            <FlexRow>
              <h3 className="u-section-title-text">Credentials</h3>
            </FlexRow>
            <TeacherInformation
              contact={universalContactState}
              credentialExists={credentialExists}
              duplicateCredential={duplicateCredential}
              onChange={handleFormChange}
              setCredentialExists={setCredentialExists}
              setDuplicateCredential={setDuplicateCredential}
            />
          </FlexGroup>

          <PersonalInfo
            onChange={handleFormChange}
            personalInfo={extractPersonal(universalContactState)}
            validations={validations}
          />

          <AddressInfo
            onChange={handleFormChange}
            streetAddress={extractAddress(universalContactState)}
          />

          <ContactInfo
            checkIfEmailExists={checkIfEmailExists}
            contactInfo={extractContactInfo(universalContactState)}
            duplicateEmail={duplicateEmail}
            emailExists={emailExists}
            onChange={handleFormChange}
            validations={validations}
          />

          <DistrictSchoolInfo
            contactDistricts={universalContactState.districts}
            contactSchools={universalContactState.schools}
            districts={districts}
            onChange={handleFormChange}
            schools={schools}
            loggedInUser={loggedInUser}
          />

          <InstituteDepartmentInfo
            contactDepartments={universalContactState.departments}
            contactInstitutes={universalContactState.institutes}
            departments={departments}
            institutes={institutes}
            onChange={handleFormChange}
            loggedInUser={loggedInUser}
          />

          <AddRemovePermissions
            contactRoles={universalContactState.permissionRoles}
            loggedInUser={loggedInUser}
            handlePermissionChange={handlePermissionChange}
          />

          <ContactNotes
            onChange={handleFormChange}
            state={universalContactState.notes}
          />

          <SaveButtons
            testId="contacts"
            cancelUrl="/contacts"
            disabled={renderErrors().length > 0}
            alternateAction={handleSave}
            saveText={isNewContact ? 'Save New Contact' : 'Save Contact'}
            validationErrors={renderErrors()}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
