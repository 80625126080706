import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { CTASupportProgramAffiliation } from 'types';
import { CTASchoolAndProgramSelectBox } from './CTASchoolAndProgramSelectBox.component';
import { getRecords_CTASupportProgramAffiliation } from 'redux/cta/ctasupportprogramaffiliation.selectors';

export const mapStateToProps = (state: ApplicationState) => {
  const programAffiliations: CTASupportProgramAffiliation[] = getRecords_CTASupportProgramAffiliation(
    state
  );
  return {
    programAffiliations,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTASchoolAndProgramSelectBox);
