import {
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
  getFormattedUrl,
  deleteWithAuthOptions,
} from 'services';
import { SnapshotTeacher, ActiveTeachersData, School } from 'types';

const SNAPSHOT_TEACHERS_ENDPOINT = getFormattedUrl(['api', 'SnapshotTeachers']);
const ACTIVE_TEACHER_SCHOOLS_ENDPOINT = getFormattedUrl([
  'api',
  'SnapshotTeachers',
  'activeschools',
]);
const ACTIVE_TEACHERS_ENDPOINT = getFormattedUrl([
  'api',
  'SnapshotTeachers',
  'activeteachers',
]);

export const setTeacherReportingComplete = async () => {
  return fetch(getFormattedUrl(['api', 'SnapshotTeachers', 'complete']), {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotTeacher', err, 'setTeacherReportingComplete');
    });
};

export const readActiveTeacherSchools = async (): Promise<School[]> => {
  return fetch(ACTIVE_TEACHER_SCHOOLS_ENDPOINT, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotTeachers', err, 'readActiveTeachers');
    });
};

export const readActiveTeachers = async (): Promise<ActiveTeachersData> => {
  return fetch(ACTIVE_TEACHERS_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotTeachers', err, 'readActiveTeachers');
    });
};

export const createSnapshotTeacher = async (
  student: SnapshotTeacher
): Promise<SnapshotTeacher> => {
  return fetch(SNAPSHOT_TEACHERS_ENDPOINT, {
    ...postWithAuthOptions(),
    body: JSON.stringify(student),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotTeachers', err, 'createSnapshotTeacher');
    });
};

export const deleteSnapshotTeacher = async (
  teacher: SnapshotTeacher
): Promise<SnapshotTeacher> => {
  const controller = 'SnapshotTeachers';
  return fetch(
    getFormattedUrl(['api', controller, String(teacher.snapshotTeacherId)]),
    {
      ...deleteWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('SnapshotTeachers', err, 'deleteSnapshotTeacher');
    });
};
