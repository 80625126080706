/**
 * This file contains functions that help in calculating workflow and voucher application state for grants
 * @module ApplicationStateUtility
 * @author AmplifyCP
 */
import { isPerkinsAdminRole } from './universalcontact.utility';
import { some, includes, find } from 'lodash';
import { isStatusLocked } from './navHelpers';
import { LOCAL_PLAN_WORKFLOW } from 'constants/grantsWorkFlowStatus.enum';
import { getLoggedinUser } from 'redux/user/selectors';
import { getGeneralWorkflow } from 'redux/generalworkflow/workflow.selectors';
import { getRecords_GrantOverview } from 'redux/nav/_selectors';
import { getSingleVoucherByType } from 'redux/grants/_selectors';

/**
 * Returns if the grant application step is locked or not
 * @param state The Redux application state
 * @param stepName the grants step to check
 */
export const getLockedStatus = (state: any, stepName: string): boolean => {
  const loggedInUser = getLoggedinUser(state);
  const workflow = getGeneralWorkflow(stepName, state);
  const steps = getRecords_GrantOverview(state).steps;
  const locked = isStatusLocked(stepName, steps);
  const statusId = workflow ? workflow.workflowStatusId : 0;
  const lockedState = includes(
    [
      LOCAL_PLAN_WORKFLOW.APPROVED,
      LOCAL_PLAN_WORKFLOW.CONDITIONALLY_APROVED,
      LOCAL_PLAN_WORKFLOW.REVIEW_IN_PROGRESS,
    ],
    statusId
  );
  const isLocked = isPerkinsAdminRole(loggedInUser)
    ? false
    : some([locked, lockedState]);

  return isLocked;
};

/**
 * Returns if the a voucher is locked or not
 * @param state The Redux application state.
 * @param stepName the voucher to check.
 */
export const getVoucherLockedStatus = (
  state: any,
  stepName: string
): boolean => {
  const loggedInUser = getLoggedinUser(state);
  const voucher = getSingleVoucherByType(state, stepName);
  const workflow = getGeneralWorkflow(stepName, state);
  const locked = !voucher.isOpen;
  const statusId = workflow ? workflow.workflowStatusId : 0;
  const lockedState = includes(
    [
      LOCAL_PLAN_WORKFLOW.FISCAL_INFORMATION_NEEDED,
      LOCAL_PLAN_WORKFLOW.APPROVED,
      LOCAL_PLAN_WORKFLOW.CONDITIONALLY_APROVED,
      LOCAL_PLAN_WORKFLOW.REVIEW_IN_PROGRESS,
      LOCAL_PLAN_WORKFLOW.RECOMMENDED_FOR_APPROVAL,
    ],
    statusId
  );
  if (locked) {
    return isPerkinsAdminRole(loggedInUser) ? false : true;
  }
  if (lockedState) {
    return isPerkinsAdminRole(loggedInUser) ? false : true;
  }
  return false;
};

export const getLockedIcon = (state: any, stepName: string): boolean => {
  if (
    find(
      ['voucher-one', 'voucher-two', 'voucher-three', 'final-voucher'],
      (step) => {
        return step === stepName;
      }
    )
  ) {
    return getVoucherLockedStatus(state, stepName);
  }
  return getLockedStatus(state, stepName);
};
