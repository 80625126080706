import React from 'react';

/**
 * @prop { string | JSX.Element } indicator
 * @prop { string | JSX.Element } tooltip
 * @prop { 'right' | 'left' | null } place?
 * @prop { 'above' | 'below' | null } position?
 */
export type TooltipComponentProps = {
  indicator: string | JSX.Element;
  tooltip: string | JSX.Element;
  place?: 'right' | 'left' | null;
  position?: 'above' | 'below' | null;
};

/**
 * @component
 * @prop { string | JSX.Element } indicator
 * @prop { string | JSX.Element } tooltip
 * @prop { 'right' | 'left' | null } place?
 * @prop { 'above' | 'below' | null } position?
 */
export const Tooltip: React.FC<TooltipComponentProps> = (props) => {
  const { indicator, place, position, tooltip } = props;

  const getPosition = () => {
    switch (position) {
      case 'below':
        return 'tooltiptext--bottom';

      default:
        return 'tooltiptext--top';
    }
  };
  const getPlace = () => {
    switch (place) {
      case 'right':
        return 'tooltiptext tooltiptext--right';

      default:
        return 'tooltiptext tooltiptext--left';
    }
  };

  const tooltipClassName = () => {
    const positionPlace = `${getPosition()} ${getPlace()}`;
    if (typeof tooltip === 'string') {
      return tooltip.length > 70
        ? `${positionPlace} tooltiptext--long`
        : `${positionPlace} tooltiptext--short`;
    }
    return `${positionPlace} tooltiptext--long`;
  };

  return (
    <div className="tooltip" aria-hidden="true">
      {indicator}
      <span className={tooltipClassName()}>{tooltip}</span>
    </div>
  );
};
