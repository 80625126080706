export const PARTICIPATION_TYPE: any = {
  1: 'Standalone',
  2: 'Consortium',
  3: 'Standalone Exempt',
  4: 'Not Participating',
};
export enum PARTICIPATION_TYPE_ENUM {
  STANDALONE = 1,
  CONSORTIUM = 2,
  STANDALONE_EXEMPT = 3,
  NOT_PARTICIPATING = 4,
}
