import React from 'react';
import { saveGrantParticipants } from 'redux/grants/grantparticipant';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

export const grantParticipantsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  next(action);

  switch (action.type) {
    case 'upsertGrantParticipants':
      next(saveGrantParticipants([action.payload]));
      break;

    case saveGrantParticipants.SUCCEEDED:
      toast(
        <ToastNotification
          status="success"
          message={StringResources.Success.DataSaved}
        />
      );
      break;

    case saveGrantParticipants.FAILED:
      toast(
        <ToastNotification
          status="error"
          message={`${
            StringResources.Errors.DataSaveFailed
          }, please refresh the page and try again.`}
        />
      );
      break;
  }
};
