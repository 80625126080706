import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';
import { GrantFiscalYear } from 'types';

const ENDPOINT = 'api/GrantFiscalYearDashboard/';

export const readGrantFiscalYear = async (): Promise<GrantFiscalYear[]> => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantFiscalYearDashboard', err, 'readGrantFiscalYear');
    });
};

export const readSingleGrantFiscalYear = async (
  grantFiscalYearId: number
): Promise<GrantFiscalYear> => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'GrantFiscalYearDashboard',
        err,
        'readSingleGrantFiscalYear'
      );
    });
};
