export interface StudentEnrollmentData {
  complete: boolean;
  followUpComplete: boolean;
  followUpLocked: boolean;
  locked: boolean;
  schoolYear: number;
  enrollments: StudentEnrollment[];
}

export const emptyStudentEnrollmentData = () => {
  return {
    complete: false,
    followUpComplete: false,
    locked: true,
    followUpLocked: false,
    schoolYear: 1970,
    enrollments: [],
  };
};

export interface StudentEnrollment {
  isOpen: boolean;
  isComplete: boolean;
  isFollowUpOpen: boolean;
  isFollowUpComplete: boolean;
  enrollments?: number;
  participants?: number;
  concentrators?: number;
  completers: number;
  programBaseId: number;
  programLevelId: number;
  cdeId: string;
  cipCode: number;
  programInstanceId: number;
  programState: string;
  expiration: Date;
  followUpIncompletes: number;
  followUpsComplete: number;
  programName: string;
  schoolId: number;
  schoolName: string;
  schoolYear: number;
  perkinsInstitutionCode: string;
}

export const emptyStudentEnrollment = (): StudentEnrollment => {
  return {
    isOpen: false,
    isComplete: false,
    isFollowUpOpen: false,
    isFollowUpComplete: false,
    completers: -1,
    programBaseId: -1,
    programLevelId: -1,
    cdeId: '',
    cipCode: -1,
    perkinsInstitutionCode: '',
    programInstanceId: -1,
    programState: '',
    expiration: new Date(),
    followUpIncompletes: -1,
    followUpsComplete: -1,
    programName: '',
    schoolId: -1,
    schoolName: '',
    schoolYear: -1,
  };
};
