import { saveConsortia, loadConsortia } from 'redux/grants/consortium';

/* lets monitor this not 100% happy with using an async / await.
   Also maybe thing of a better way to type our thunk actions
*/
export const consortiaMiddleware = (store: any) => (next: Function) => async (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveConsortia.SUCCEEDED: {
      dispatch(await loadConsortia());
      break;
    }
  }
};
