import { ProgramStepsSelections } from 'components/Program/ProgramStepsSelections/ProgramStepsSelections.component';
import { connect } from 'react-redux';
import { getIsProgramReadOnly } from 'redux/programs/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { programInstanceId } = ownProps;
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    readOnly,
  };
};

export default connect(
  mapStateToProps,
  null
)(ProgramStepsSelections);
