import { connect } from 'react-redux';
import { getIsDataFetching_UniversalContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { PermissionRole } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { getLoggedinUser } from 'redux/user/selectors';
import { getRecords_PermissionRoles } from 'redux/permissions/permissionroles.selectors';
import { loadPermissionRoles } from 'redux/permissions/permissionroles';
import { CreateNewRoleComponent } from './CreateNewRole.component';

export const mapStateToProps = (state: ApplicationState) => {
  const roles: PermissionRole[] = getRecords_PermissionRoles(state);
  const isFetching: boolean = getIsDataFetching_UniversalContact(state);
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
    roles,
    isFetching,
  };
};

const dispatchProps = { loadPermissionRoles };

export default connect(
  mapStateToProps,
  dispatchProps
)(CreateNewRoleComponent);
