import {
  FETCH_CHANGELOG,
  resetShouldUpdate,
  RESET_LAST_MODIFIED_CHANGELOG,
} from 'redux/generalworkflow/changelog/_reducer';
import {
  setIsLoading_ChangelogPrograms,
  setChangelogPrograms,
  RESET_LAST_MODIFIED_CHANGELOG_PROGRAMS,
} from 'redux/generalworkflow/changelog/subtypes/programs.reducer';
import { readWorkflowChangeLog } from 'services/workflow/programworkflow.service';
import {
  submitProgramForApproval,
  submitProgramForUpdate,
  SAVE_WORKFLOW_UPDATE,
} from 'redux/generalworkflow/workflow.actions';
import { compose, prop } from 'util/objectUtility';
import {
  setIsLoading_ChangelogFourYear,
  setChangelogFourYear,
  RESET_LAST_MODIFIED_CHANGELOG_FOUR_YEAR,
} from 'redux/generalworkflow/changelog/subtypes/fouryear.reducer';
import {
  setIsLoading_ChangelogAnnualReflection,
  setChangelogAnnualReflection,
  RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_REFLECTION,
} from 'redux/generalworkflow/changelog/subtypes/annualreflection.reducer';
import {
  setIsLoading_ChangelogImprovementPlan,
  setChangelogImprovementPlan,
  RESET_LAST_MODIFIED_CHANGELOG_IMPROVEMENT_PLAN,
} from 'redux/generalworkflow/changelog/subtypes/improvementplan.reducer';
import {
  setIsLoading_ChangelogAnnualSpending,
  setChangelogAnnualSpending,
  RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_SPENDING,
} from 'redux/generalworkflow/changelog/subtypes/annualspending.reducer';
import { getRecords_Changelog } from 'redux/generalworkflow/changelog/_selectors';
import { gt } from 'ramda';
import { WorkflowReduxEntities } from 'types';
import { isEqual } from 'util/formHelpers';
import {
  setIsLoading_ChangelogVoucherOne,
  setChangelogVoucherOne,
} from 'redux/generalworkflow/changelog/subtypes/voucherOne.reducer';
import {
  setIsLoading_ChangelogVoucherTwo,
  setChangelogVoucherTwo,
} from 'redux/generalworkflow/changelog/subtypes/voucherTwo.reducer';
import {
  setIsLoading_ChangelogVoucherThree,
  setChangelogVoucherThree,
} from 'redux/generalworkflow/changelog/subtypes/voucherThree.reducer';
import {
  setIsLoading_ChangelogFinalVoucher,
  setChangelogFinalVoucher,
} from 'redux/generalworkflow/changelog/subtypes/finalVoucher.reducer';

export const changleLogMiddleware = (store: any) => (next: Function) => async (
  action: any
) => {
  const { dispatch, getState } = store;

  next(action);

  if (action.type === RESET_LAST_MODIFIED_CHANGELOG) {
    const { entityType } = action.payload;

    const funcs: any = {
      programs: RESET_LAST_MODIFIED_CHANGELOG_PROGRAMS,
      'annual-reflection': RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_REFLECTION,
      'four-year-plan': RESET_LAST_MODIFIED_CHANGELOG_FOUR_YEAR,
      'improvement-plan': RESET_LAST_MODIFIED_CHANGELOG_IMPROVEMENT_PLAN,
      'spending-plans': RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_SPENDING,
    };
    return funcs[entityType] && dispatch(resetShouldUpdate(funcs[entityType]));
  }

  if (action.type === FETCH_CHANGELOG) {
    const { entityType, entityId } = action.payload;
    const state = getState();
    const IDsMatch = compose(
      isEqual(entityId),
      prop('entityId'),
      getRecords_Changelog(entityType)
    );
    const shouldUpdate = compose(
      gt(1000 * 60 * 60 * 24), // ms, s, min, hours
      prop('lastModified'),
      getRecords_Changelog(entityType)
    );

    const programs = () => {
      dispatch(setIsLoading_ChangelogPrograms(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogPrograms(res)))
        .finally(() => dispatch(setIsLoading_ChangelogPrograms(false)));
    };
    const fourYear = () => {
      dispatch(setIsLoading_ChangelogFourYear(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogFourYear(res)))
        .finally(() => dispatch(setIsLoading_ChangelogFourYear(false)));
    };
    const annualReflection = () => {
      dispatch(setIsLoading_ChangelogAnnualReflection(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogAnnualReflection(res)))
        .finally(() => dispatch(setIsLoading_ChangelogAnnualReflection(false)));
    };
    const improvementplan = () => {
      dispatch(setIsLoading_ChangelogImprovementPlan(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogImprovementPlan(res)))
        .finally(() => dispatch(setIsLoading_ChangelogImprovementPlan(false)));
    };
    const spendingPlans = () => {
      dispatch(setIsLoading_ChangelogAnnualSpending(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogAnnualSpending(res)))
        .finally(() => dispatch(setIsLoading_ChangelogAnnualSpending(false)));
    };
    const voucherOne = () => {
      dispatch(setIsLoading_ChangelogVoucherOne(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogVoucherOne(res)))
        .finally(() => dispatch(setIsLoading_ChangelogVoucherOne(false)));
    };
    const voucherTwo = () => {
      dispatch(setIsLoading_ChangelogVoucherTwo(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogVoucherTwo(res)))
        .finally(() => dispatch(setIsLoading_ChangelogVoucherTwo(false)));
    };
    const voucherThree = () => {
      dispatch(setIsLoading_ChangelogVoucherThree(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogVoucherThree(res)))
        .finally(() => dispatch(setIsLoading_ChangelogVoucherThree(false)));
    };
    const finalVoucher = () => {
      dispatch(setIsLoading_ChangelogFinalVoucher(true));
      return readWorkflowChangeLog(entityType, entityId)
        .then((res: any) => dispatch(setChangelogFinalVoucher(res)))
        .finally(() => dispatch(setIsLoading_ChangelogFinalVoucher(false)));
    };

    const funcs: Partial<WorkflowReduxEntities> = {
      programs: programs,
      'four-year-plan': fourYear,
      'annual-reflection': annualReflection,
      'improvement-plan': improvementplan,
      'spending-plans': spendingPlans,
      'voucher-one': voucherOne,
      'voucher-two': voucherTwo,
      'voucher-three': voucherThree,
      'final-voucher': finalVoucher,
    };

    if (funcs[entityType as keyof WorkflowReduxEntities]) {
      return IDsMatch(state)
        ? shouldUpdate(state) &&
            funcs[entityType as keyof WorkflowReduxEntities]()
        : funcs[entityType as keyof WorkflowReduxEntities]();
    }
  }

  switch (action.type) {
    case submitProgramForApproval:
    case submitProgramForUpdate: {
      dispatch(resetShouldUpdate('programs'));
      break;
    }
    case SAVE_WORKFLOW_UPDATE: {
      const funcs: Partial<WorkflowReduxEntities> = {
        'four-year-plan': RESET_LAST_MODIFIED_CHANGELOG_FOUR_YEAR,
        'annual-reflection': RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_REFLECTION,
        'improvement-plan': RESET_LAST_MODIFIED_CHANGELOG_IMPROVEMENT_PLAN,
        'spending-plans': RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_SPENDING,
      };
      const entity = compose(
        prop('entityType'),
        prop('payload')
      );
      const resetLastModified = compose(
        dispatch,
        resetShouldUpdate
      );
      resetLastModified(funcs[entity(action) as keyof WorkflowReduxEntities]);
      break;
    }
  }
};
