import React from 'react';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import Loading from 'components/Common/Loading';
import EditRoleDetails from './EditRoleDetails';
import EditRoleComponents from './EditRoleComponents';

type RoleDetailsProps = {
  isFetching: boolean;
};

export const RoleDetailsComponent: React.FC<RoleDetailsProps> = (props) => {
  const { isFetching } = props;

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <EditRoleDetails />
      <EditRoleComponents />
    </React.Fragment>
  );
};
