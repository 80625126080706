import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAReviewOptimizeOverview } from 'types';
import { readCTAReviewOptimize } from 'services/cta/ctareviewoptimize.services';

const initialState = baseInitialState;

export const loadCTAReviewOptimize = createActionThunk(
  'loadCTAReviewOptimize',
  (ctaId: number) => {
    return readCTAReviewOptimize(ctaId);
  }
);

export default handleActions<BaseStateObject, CTAReviewOptimizeOverview>(
  {
    ...baseLoadActionBuilder<CTAReviewOptimizeOverview>(loadCTAReviewOptimize),
  },
  initialState
);
