import { connect } from 'react-redux';
import {
  getRecords_CourseTypes,
  COURSE_TYPES_PATHS,
} from 'redux/programs/_selectors';
import { OptionMap, CourseType, SelectWrapper } from 'types';
import { getRecordsUtility } from 'util/formHelpers';
import { randomString } from 'util/global';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    inputId,
    selection,
    filter,
    label,
    onChange,
    records: baseRecords,
  } = ownProps;

  const records = getRecordsUtility<CourseType>(
    state,
    COURSE_TYPES_PATHS,
    getRecords_CourseTypes,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<CourseType> = {
    key: 'courseTypeId',
    label: 'courseTypeName',
    value: 'courseTypeId',
  };

  return {
    inputId: inputId ? inputId : randomString(),
    label: label ? label : 'Course Type',
    name: 'courseTypeId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
