import React, { useEffect } from 'react';
import { Workflow, WorkflowStep } from 'types';
import { map } from 'lodash';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';
import { WorkflowCard } from '../WorkflowCard.component';

type WorkflowStepsProps = {
  setCurrentNavAnchor: Function;
  workflow: Workflow;
};

export const WorkflowSteps: React.FC<WorkflowStepsProps> = (props) => {
  const { setCurrentNavAnchor, workflow } = props;

  useEffect(() => {
    setCurrentNavAnchor('approval');
  }, [setCurrentNavAnchor]);

  return (
    <React.Fragment>
      <Paper>
        <div className="workflow__steps">
          <h3 className="u-section-title-text">Workflow History</h3>
          <Icon
            className="workflow__icon workflow__icon--steps"
            name="clockHistory"
            title="Workflow History"
          />
        </div>
        <p>
          Review the complete workflow approval history for the selected
          program.
        </p>
      </Paper>
      {map(workflow.workflowSteps, (item: WorkflowStep, index: number) => {
        return (
          <WorkflowCard
            isFirst={index === 0}
            isSummary={false}
            key={index}
            workflowItem={item}
          />
        );
      })}
      {!workflow.workflowSteps.length && (
        <Paper>
          <p>This program has not been submited for approval yet.</p>
        </Paper>
      )}
    </React.Fragment>
  );
};
