import { useValidation } from 'de-formed-validations';
import { QuestionGroup } from 'types';
import { allQuestionsAnswered } from 'util/formHelpers';

type ValidationState = {
  allQuestionsAnswered: boolean;
};

export const QuestionValidation = () => {
  return useValidation<ValidationState>({
    allQuestionsAnswered: [
      {
        errorMessage: 'Not all required questions have been answered.',
        validation: (questions: QuestionGroup[]) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
  });
};
