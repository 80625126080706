import { COMMON_KEY, COMMON_FERPA_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getFirstRecord,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { FerpaAgreement, emptyFerpaAgreement } from 'types';

export const COMMON_FERPA_PATH: string[] = [COMMON_KEY, COMMON_FERPA_KEY];

export const getIsDataFetching_FerpaAgreement = (state: any): boolean => {
  return getIsDataFetching(state, COMMON_FERPA_PATH);
};

export const getLastUpdated_FerpaAgreement = (state: any): number => {
  return getLastUpdated(state, COMMON_FERPA_PATH);
};

export const shouldRefreshReduxState_FerpaAgreement = (state: any): boolean => {
  return shouldRefreshReduxState<FerpaAgreement>(state, COMMON_FERPA_PATH);
};

export const getFirstRecord_FerpaAgreement = (state: any) => {
  return getFirstRecord<FerpaAgreement>(
    state,
    COMMON_FERPA_PATH,
    emptyFerpaAgreement()
  );
};
