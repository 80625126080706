import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import {
  DistrictSchoolSignature,
  CertificationOfExemptionSignature,
} from 'types';
import { every } from 'lodash';
import { CivilRightsAssertionsListValidations } from 'validations/AwardPacket/ListValidations/CivilRightsAssertionsList.validations';
import { CertificationOfExemptionValidationsList } from 'validations/AwardPacket/ListValidations/CertificationOfExemptionList.validations';
import { GrantRecipientSignaturesValidations } from 'validations/AwardPacket/ListValidations/GrantRecipientSignatures.validations';
import CertificationOfExemption from './CertificationOfExemption';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ReadAndSignGrantRecipientAgreement from './ReadAndSignGrantRecipientAgreement';
import ReadAndSignCivilRightsAssurance from './ReadAndSignCivilRightsAssurance';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

type Props = {
  isLoading: boolean;
  isLocked: boolean;
  setCurrentNavAnchor: Function;
  civilRightsSignatures: DistrictSchoolSignature[];
  certificationOfExemptionSignatures: CertificationOfExemptionSignature[];
  grantAgreementSignatures: DistrictSchoolSignature[];
  saveCertificateOfExemptionSignatures: Function;
  saveCivilRightSignatures: Function;
  saveGrantAgreementSignatures: Function;
};

export const AwardPacketEditComponent: React.FC<Props> = (props) => {
  const {
    isLoading,
    isLocked,
    setCurrentNavAnchor,
    civilRightsSignatures,
    certificationOfExemptionSignatures,
    grantAgreementSignatures,
    saveCertificateOfExemptionSignatures,
    saveCivilRightSignatures,
    saveGrantAgreementSignatures,
  } = props;

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const [civilRightsSignaturesState, setCivilRightsSignaturesState] = useState<
    DistrictSchoolSignature[]
  >([]);
  const [
    certificationOfExemptionState,
    setCertificationOfExemptionState,
  ] = useState<CertificationOfExemptionSignature[]>([]);
  const [agreementSignaturesState, setAgreementSignaturesState] = useState<
    DistrictSchoolSignature[]
  >([]);

  const [canSave, setCanSave] = useState<boolean>(true);

  const {
    validate: validateGrantRecipient,
    validationErrors: recipientErrors,
  } = GrantRecipientSignaturesValidations();
  const {
    validate: validateCivilRights,
    validationErrors: civilRightsErrors,
  } = CivilRightsAssertionsListValidations();
  const {
    validate: validateCertificationOfExemption,
    validationErrors: exepmtionErrors,
  } = CertificationOfExemptionValidationsList();

  const handleSave = () => {
    const newCanSave = every([
      validateGrantRecipient('canSave', agreementSignaturesState),
      validateCivilRights('canSave', civilRightsSignaturesState),
      validateCertificationOfExemption(
        'canSave',
        certificationOfExemptionState
      ),
    ]);
    if (newCanSave) {
      return saveSignatures();
    }
    return Promise.reject('Not all validations passed. Please check the form.');
  };

  const saveSignatures = async () => {
    return Promise.all([
      await saveCertificateOfExemptionSignatures(
        grantFiscalYearId,
        certificationOfExemptionState
      ),
      await saveCivilRightSignatures(
        grantFiscalYearId,
        civilRightsSignaturesState
      ),
      await saveGrantAgreementSignatures(
        grantFiscalYearId,
        agreementSignaturesState
      ),
    ])
      .then(() =>
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        )
      )
      .catch(() =>
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        )
      );
  };

  useEffect(() => {
    setCivilRightsSignaturesState([...civilRightsSignatures]);
  }, [civilRightsSignatures]);

  useEffect(() => {
    setAgreementSignaturesState([...grantAgreementSignatures]);
  }, [grantAgreementSignatures]);

  useEffect(() => {
    setCertificationOfExemptionState([...certificationOfExemptionSignatures]);
  }, [certificationOfExemptionSignatures]);

  /* eslint-disable */
  useEffect(() => {
    const newCanSave = every([
      validateGrantRecipient('canSave', agreementSignaturesState),
      validateCivilRights('canSave', civilRightsSignaturesState),
      validateCertificationOfExemption(
        'canSave',
        certificationOfExemptionState
      ),
    ]);
    setCanSave(newCanSave);
  }, [
    certificationOfExemptionState,
    civilRightsSignaturesState,
    agreementSignaturesState,
  ]);
  /* eslint-enable */

  useEffect(() => {
    setCurrentNavAnchor('award-packet');
  }, [setCurrentNavAnchor]);

  return (
    <React.Fragment>
      <Loading
        isActive={isLoading}
        messageBefore="Loading Award Signatures..."
      />
      <SecureWrap isLocked={isLocked} component="GrantsAwardPacket">
        <Paper>
          <ScrollToTop />
          <Instruction title="Instructions">
            Please complete all sections of the Award Packet to confirm your
            eligibility and recieve your Grant Funds. To submit, all sections of
            the Award Packet must be completed by the appropriate signee.
          </Instruction>
        </Paper>
        <ReadAndSignGrantRecipientAgreement
          onChange={(signatures: DistrictSchoolSignature[]) => {
            setAgreementSignaturesState([...signatures]);
          }}
          signatures={agreementSignaturesState}
        />
        <CertificationOfExemption
          signatures={certificationOfExemptionState}
          onChange={(signatures: CertificationOfExemptionSignature[]) => {
            setCertificationOfExemptionState(signatures);
          }}
        />
        <ReadAndSignCivilRightsAssurance
          signatures={civilRightsSignaturesState}
          onChange={(signatures: DistrictSchoolSignature[]) => {
            setCivilRightsSignaturesState([...signatures]);
          }}
        />
        <Paper>
          <SaveButtons
            cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
            saveText="Save Award Packet"
            disabled={!canSave}
            saveData={handleSave}
            validationErrors={[
              ...recipientErrors,
              ...civilRightsErrors,
              ...exepmtionErrors,
            ]}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
