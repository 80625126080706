import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { GrantParticipationMember } from 'types';

export const PARTICIPATION_ENDPOINT = 'api/ParticipationManagements/';

export const readGrantParticipants = async (
  grantFiscalYear: number
): Promise<GrantParticipationMember[]> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${PARTICIPATION_ENDPOINT}${grantFiscalYear}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('AddressableElements', err);
    });
};

export const updateGrantParticipants = async (
  participants: GrantParticipationMember[]
) => {
  const url = `${ENVIRONMENT.API_URL}${PARTICIPATION_ENDPOINT}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(participants),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('AddressableElements', err);
    });
};
