export type CTASupportProgramAffiliation = {
  ctaProgramId: number;
  schoolName: string;
  programName: string;
};

export const emptyCTASupportProgramAffiliation = (): CTASupportProgramAffiliation => {
  return {
    ctaProgramId: -1,
    programName: '',
    schoolName: '',
  };
};
