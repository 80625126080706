import {
  toJSON,
  catchError,
  getFormattedUrl,
  postWithAuthOptions,
} from 'services';

const PPOR_BASE = ['api', 'ppor'];
const OPEN_SUBMISSION = getFormattedUrl([...PPOR_BASE, 'OpenSubmission']);

export const createOpenSubmissions = async () => {
  return fetch(OPEN_SUBMISSION, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA-PPOR', err, 'createOpenSubmissions');
    });
};

export const readPPORRecipients = async () => {
  return fetch(OPEN_SUBMISSION, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA-PPOR', err, 'createOpenSubmissions');
    });
};
