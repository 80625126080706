import decodeJWT from 'jwt-decode';
import authentication from 'components/Common/Authorization/AzureB2C';
import { MutableRefObject } from 'react';
import { some } from 'lodash';

export const getAccessToken = () => {
  return authentication.getAccessToken();
};

export const isUserAuthenticated = () => {
  return authentication.getAccessToken() ? true : false;
};

export const logoutUser = () => {
  authentication.signOut();
};

export const currentUser = () => {
  try {
    const decoded = decodeJWT<any>(getAccessToken());
    const aadB2cId = decoded.oid ? decoded.oid : decoded.sub ? decoded.sub : '';
    return {
      aadB2cId,
    };
  } catch (error) {
    return {
      aadB2cId: '',
    };
  }
};

export const isDisabled = (
  disabled: boolean | undefined,
  elementRef: MutableRefObject<any>
): boolean => {
  return some([disabled, secureDisabled(elementRef)]);
};

export const secureDisabled = (elementRef: MutableRefObject<any>): boolean => {
  if (elementRef && elementRef.current) {
    const value = window
      .getComputedStyle(elementRef.current, null)
      .getPropertyValue('animation-name');
    return value === 'is-secure';
  }
  return false;
};
