import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  deleteWithAuthOptions,
  catchError,
} from 'services';
import { ProgramContact } from 'types';

const CONTACT_ENDPOINT = 'api/ProgramContacts/';
const PROGRAM_INSTANCE_ID = 'byProgramInstanceId/';

export const readProgramContacts = async (programInstanceId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${CONTACT_ENDPOINT}${PROGRAM_INSTANCE_ID}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramContacts', err);
    });
}; // end function

export const updateProgramContacts = async (
  programInstanceId: number,
  payload: ProgramContact[]
) => {
  /* prettier-ignore */
  const url = `${ENVIRONMENT.API_URL}${CONTACT_ENDPOINT}${programInstanceId}`;
  // payload.modifiedDate = new Date(Date.now()); //TODO should be handled in api
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(payload),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramContacts', err);
    });
}; // end function

export const deleteProgramContacts = async (programContactId: number) => {
  const url = `${ENVIRONMENT.API_URL}${CONTACT_ENDPOINT}${programContactId}`;
  return fetch(url, { ...deleteWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramContacts', err);
    });
}; // end function
