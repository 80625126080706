import { useValidation } from 'de-formed-validations';
import { CTAAdministrator } from 'types';
import { lt as gt, gt as lt } from 'ramda';

export const CTAAdministratorValidations = () => {
  return useValidation<CTAAdministrator>({
    annualContractBaseSalary: [
      {
        errorMessage: 'Must be less than $1 million.',
        validation: lt(1000000),
      },
      {
        errorMessage: 'Must be more than $100.',
        validation: gt(100),
      },
    ],
    actualSalaryEarned: [
      {
        errorMessage: 'Must be less than $1 million.',
        validation: lt(1000000),
      },
      {
        errorMessage: 'Must be more than $100.',
        validation: gt(100),
      },
    ],

    contractExtraPay: [
      {
        errorMessage: 'Value must be less than or equal to $100,000',
        validation: lt(100001),
      },
    ],

    ctePercent: [
      {
        errorMessage: 'Value cannot be greater than 100%',
        validation: lt(101),
      },
    ],
  });
};
