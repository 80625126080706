import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { CreateProjectExpenses } from './CreateProjectExpenses.component';
import { loadAnnualSpendingPlan } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { getRecords_ExpenseCategory } from 'redux/common/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any) => {
  const expenseCategories = getRecords_ExpenseCategory(state);
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
    expenseCategories,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  loadAnnualSpendingPlan,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CreateProjectExpenses);
