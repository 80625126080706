import React, { useRef } from 'react';
import {
  HandleChangeFunction,
  DropdownItem,
  OptionMap,
  HandleSelect,
} from 'types';
import ReactSelect from 'react-select';
import { some } from 'lodash';
import { isDisabled } from 'util/authorization';
import { insertClass } from 'util/formHelpers';
import Label from 'components/Common/FormElements/Label';

export type SelectBoxProps = {
  clearable?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  inputId?: string;
  isLoading?: boolean;
  isMulti?: boolean;
  filter?: any;
  label?: string;
  name: string;
  noLabel?: boolean;
  onBlur?: Function;
  onChange: HandleChangeFunction;
  optionMap: OptionMap<any>;
  options: DropdownItem[] | undefined;
  placeholder?: string; // TODO: remove this
  records: any[];
  selectedItem: DropdownItem | DropdownItem[] | undefined;
  valid?: boolean;
  shouldReset?: boolean;
};

export const SelectBoxComponent = (props: SelectBoxProps) => {
  const {
    disabled,
    errorMessage,
    inputId,
    isLoading,
    isMulti,
    label,
    name,
    noLabel,
    onBlur,
    onChange,
    options,
    selectedItem,
    valid = true,
    shouldReset = false,
    clearable = false,
  } = props;

  const ref = useRef(null);

  const handleSelection: HandleSelect = (item: any) => {
    const data: { [key: string]: unknown } = {};
    if (!item && clearable) {
      data[name] = undefined;
      onChange && onChange(data, name, undefined);
    }
    const ifDisabled = some([disabled, !onChange, !item]);

    if (isDisabled(ifDisabled, ref)) return;
    const value = (item as DropdownItem).value;
    data[name] = value;
    onChange && onChange(data, name, value);
  };

  return (
    <div className="form__group">
      {!noLabel && (
        <Label name={name} label={label} htmlFor={inputId ? inputId : name} />
      )}
      <ReactSelect
        aria-label={label ? label : name}
        className={`react-select-container ${insertClass(`t-${name}`)} ${
          valid ? 'react-select__valid' : 'react-select__invalid'
        }`}
        classNamePrefix="react-select"
        fluid
        isClearable={clearable}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti}
        inputId={inputId ? inputId : name}
        name={name}
        label={label}
        onBlur={onBlur && onBlur()}
        onChange={handleSelection}
        options={options}
        placeholder=""
        search
        value={selectedItem ? selectedItem : shouldReset ? null : undefined}
      />
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
