export const INSTRUCTORS = '[INSTRUCTORS]';

export const SET_INSTRUCTORS = `${INSTRUCTORS} SET`;
export const SET_INSTRUCTORS_LOADING = `${INSTRUCTORS} LOADING`;

export const setInstructors = (payload: any) => {
  return {
    type: SET_INSTRUCTORS,
    payload,
  };
};

export const setIsLoading_Instructors = (payload: boolean) => {
  return {
    type: SET_INSTRUCTORS_LOADING,
    payload,
  };
};
