import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';

type FilterProps = {
  clearGrantParticipantFilters: Function;
  filterLengths: any;
  setGrantParticipantFilterValue: Function;
};

export const ParticipationDashboardFilters: React.FC<FilterProps> = (props) => {
  const {
    clearGrantParticipantFilters,
    filterLengths,
    setGrantParticipantFilterValue,
  } = props;

  const [activeFilter, setActiveFilter] = useState<string>('');

  useEffect(() => {
    clearGrantParticipantFilters();
  }, [clearGrantParticipantFilters]);

  const setFilterValue = (filterData: any) => {
    clearGrantParticipantFilters();
    const { filters, name } = filterData;
    if (activeFilter !== name) {
      setActiveFilter(name);
      setGrantParticipantFilterValue(filters);
    } else {
      setActiveFilter('');
    }
  };

  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'isStandalone'}
          iconName="person"
          number={filterLengths.standaloneParticipant}
          onClick={() =>
            setFilterValue({
              name: 'isStandalone',
              filters: {
                isStandalone: true,
                isParticipating: true,
              },
            })
          }
          text="Standalone Participant"
        />
        <FilterHotButton
          active={activeFilter === 'isConsortium'}
          text="Consortium Participant"
          number={filterLengths.consortiumParticipant}
          onClick={() =>
            setFilterValue({
              name: 'isConsortium',
              filters: {
                isStandalone: false,
                isParticipating: true,
              },
            })
          }
          iconName="group"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'notified'}
          iconName="outbox"
          number={filterLengths.notified}
          onClick={() =>
            setFilterValue({
              name: 'notified',
              filters: {
                notified: true,
              },
            })
          }
          text="Notified"
        />
        <FilterHotButton
          active={activeFilter === 'responded'}
          iconName="inbox"
          number={filterLengths.responses}
          onClick={() => {
            setFilterValue({
              name: 'responded',
              filters: {
                responded: true,
              },
            });
          }}
          text="Responses"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'noResponses'}
          iconName="cross"
          number={filterLengths.noResponses}
          onClick={() =>
            setFilterValue({
              name: 'noResponses',
              filters: { responded: false },
            })
          }
          text="No Responses"
        />
        <FilterHotButton
          active={activeFilter === 'notParticipating'}
          iconName="walk"
          number={filterLengths.notParticipating}
          onClick={() =>
            setFilterValue({
              name: 'notParticipating',
              filters: { isParticipating: false },
            })
          }
          text="Not Participating"
        />
      </FlexGroup>
    </FlexRow>
  );
};
