import { connect } from 'react-redux';
import {
  getIsDataFetching_UniversalContact,
  getAdvancedFilteredRecords_UniversalContacts,
} from 'redux/universalcontacts/universalcontacts.selectors';
import { UniversalContact } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { UniversalContactsDashboard } from './Dashboard.page';
import { loadUniversalContacts } from 'redux/universalcontacts/universalcontacts';
import { getLoggedinUser } from 'redux/user/selectors';
import { getUniversalContactFilters } from 'redux/filters/universalcontactfilter.selectors';

export const mapStateToProps = (state: ApplicationState) => {
  const contacts: UniversalContact[] = getAdvancedFilteredRecords_UniversalContacts(
    state
  );
  const isFetching: boolean = getIsDataFetching_UniversalContact(state);
  const loggedInUser = getLoggedinUser(state);
  const filters = getUniversalContactFilters(state).length;

  return {
    filters,
    loggedInUser,
    contacts,
    isFetching,
  };
};

const dispatchProps = { loadUniversalContacts };

export default connect(
  mapStateToProps,
  dispatchProps
)(UniversalContactsDashboard);
