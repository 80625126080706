import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const ENDPOINT = 'api/GrantNav/improvementplan/';

export const readGrantImprovementPlanNav = async (
  grantFiscalYearId: number
) => {
  return fetch(`${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantImprovementPlanNav', err);
    });
};
