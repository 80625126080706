import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTASchool, CTAGeneralAccuracy } from 'types';
import {
  readCTASchools,
  readCTAGeneralAccuracy,
  updateCTAGeneralAccuracy,
  updateCTASchool,
} from 'services/cta/ctageneral.services';

const initialState = baseInitialState;

export const loadCTASchools = createActionThunk(
  'loadCTASchools',
  (ctaId: number) => {
    return readCTASchools(ctaId);
  }
);

export const saveCTASchool = createActionThunk(
  'saveCTASchool',
  (ctaId: number, school: CTASchool) => {
    return updateCTASchool(ctaId, school);
  }
);

export const loadCTAGeneralAccuracy = createActionThunk(
  'loadCTAGeneralAccuracy',
  (ctaId: number) => {
    return readCTAGeneralAccuracy(ctaId);
  }
);

export const saveCTAGeneralAccuracy = createActionThunk(
  'saveCTAGeneralAccuracy',
  (ctaId: number, terms: CTAGeneralAccuracy) => {
    return updateCTAGeneralAccuracy(ctaId, terms);
  }
);

export default handleActions<BaseStateObject, CTASchool[]>(
  {
    ...baseLoadActionBuilder<CTASchool[]>(loadCTASchools),
    ...baseSaveActionBuilder(loadCTAGeneralAccuracy),
    ...baseSaveActionBuilder(saveCTASchool),
  },
  initialState
);
