import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CtaInstructorCourse } from 'types';
import { readCTAInstructionalCourse } from 'services/cta/ctacourse.services';

const initialState = baseInitialState;

export const loadCTAInstructionalCourse = createActionThunk(
  'loadCTAInstructionalCourse',
  (ctaId: number, ctaInstructorId: number) => {
    return readCTAInstructionalCourse(ctaId, ctaInstructorId);
  }
);

export default handleActions<BaseStateObject, CtaInstructorCourse[]>(
  {
    ...baseLoadActionBuilder<CtaInstructorCourse[]>(loadCTAInstructionalCourse),
  },
  initialState
);
