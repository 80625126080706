import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { ProgramOfStudy } from 'components/Program/ProgramOfStudy';

export const ProgramOfStudyPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramOfStudy {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};

export default ProgramOfStudyPage;
