import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { map, startCase } from 'lodash';
import { WorkflowStep, WorkflowReduxEntities } from 'types';
import { loadWorkFlow } from 'redux/generalworkflow/workflow.actions';
import { getWorkflowTrayClosed } from 'redux/application/selectors';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import { compose } from 'util/objectUtility';
import { setWorkflowTrayClosed } from 'redux/application';
import Button from 'components/Common/FormElements/Button';
import CollapseButton from 'components/Common/CollapseButton';
import DatatableLoading from 'components/Common/DatatableLoading';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Paper from 'components/Common/Paper';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import WorkflowCard from '../WorkflowCard';

type WorkflowTrayProps = {
  entityType: keyof WorkflowReduxEntities;
  entityId: number;
};

export const WorkflowTray: React.FC<WorkflowTrayProps> = (props) => {
  const { entityType, entityId } = props;
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const workflowTrayClosed = useSelector(getWorkflowTrayClosed);
  const workflow = useSelector(getGeneralWorkflow(entityType));
  const isFetching = useSelector(getIsDataFetching_GeneralWorkflow(entityType));

  const toggleTray = compose(
    dispatch,
    setWorkflowTrayClosed
  );

  useEffect(() => {
    dispatch(loadWorkFlow(entityType, entityId));
  }, [dispatch, entityId, entityType]);

  return (
    <div
      className={
        workflowTrayClosed
          ? 'program__workflow--collapsed'
          : 'program__workflow--expanded'
      }
    >
      <div
        className={
          workflowTrayClosed
            ? 'workflow__container workflow__container--collapsed'
            : 'workflow__container workflow__container--expanded'
        }
      >
        <div
          className={
            workflowTrayClosed
              ? 'workflow__menu workflow__menu--collapsed'
              : 'workflow__menu workflow__menu--expanded'
          }
        >
          {!workflowTrayClosed ? (
            <React.Fragment>
              <div
                onClick={() => toggleTray(!workflowTrayClosed)}
                onKeyPress={(e: React.KeyboardEvent) => {
                  (e.key === 'Enter' || e.key === ' ') &&
                    toggleTray(!workflowTrayClosed);
                }}
                className="workflow__header"
              >
                <div className="workflow__header--container">
                  <Icon
                    className="workflow__icon"
                    name="clockHistory"
                    title="Workflow History"
                  />
                  <h2 className="workflow__title">Workflow History</h2>
                </div>
              </div>
              {entityType !== 'cta' && (
                <div className="workflow__changelog">
                  {pathname.includes('change-history') ? (
                    <Button
                      onClick={() => history.goBack()}
                      text={`Return to ${startCase(entityType)}`}
                      className="button--outline"
                    />
                  ) : (
                    <SecureWrap component="programs" requireAdmin={true}>
                      <Button
                        onClick={() => {
                          history.push(
                            `/change-history/${entityType}/${entityId}`
                          );
                        }}
                        text="View Changelog"
                        className="button--outline"
                      />
                    </SecureWrap>
                  )}
                </div>
              )}
              {!workflowTrayClosed &&
                map(
                  workflow.workflowSteps,
                  (item: WorkflowStep, index: number) => {
                    return (
                      <WorkflowCard
                        isFirst={index === 0}
                        isSummary={false}
                        key={index}
                        workflowItem={item}
                      />
                    );
                  }
                )}
              {!workflowTrayClosed && !workflow.workflowSteps.length && (
                <Paper>
                  {isFetching ? (
                    <DatatableLoading
                      loading={true}
                      messageBefore="Loading Workflow...."
                    />
                  ) : (
                    <p>
                      This application has not been submited for approval yet.
                    </p>
                  )}
                </Paper>
              )}
              <div className="workflow__button">
                <CollapseButton
                  buttonText="Collapse Workflow History"
                  origin="left"
                  setClosed={toggleTray}
                  trayClosed={workflowTrayClosed}
                />
              </div>
            </React.Fragment>
          ) : (
            <IconButton
              className="workflow__icon"
              name="clockHistory"
              onClick={() => toggleTray(!workflowTrayClosed)}
              tooltip="Workflow History"
            />
          )}
        </div>
      </div>
    </div>
  );
};
