import { connect } from 'react-redux';
import { ReportingHeaderComponent } from './ReportingHeader.component';

export const mapStateToProps = (state: any) => {
  return {};
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(ReportingHeaderComponent);
