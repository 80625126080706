import { GRANTS_KEY, GRANTS_STRATEGY_SELECT_ITEMS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { StrategySelectItem } from 'types';

export const GRANTS_STRATEGY_SELECT_ITEMS_PATH = [
  GRANTS_KEY,
  GRANTS_STRATEGY_SELECT_ITEMS_KEY,
];

export const getIsDataFetching_StrategySelectItems = (state: any): boolean => {
  return getIsDataFetching(state, GRANTS_STRATEGY_SELECT_ITEMS_PATH);
};

export const getRecords_StrategySelectItems = (
  state: unknown
): StrategySelectItem[] => {
  return getRecords(state, GRANTS_STRATEGY_SELECT_ITEMS_PATH);
};
