import React, { useState, useRef, useEffect } from 'react';
import { CTAEquipment, emptyCtaEquipment, emptyCTAEquipmentCTEProgram } from 'types';
import { defaultTo } from 'ramda';
import { EquipmentSchoolProgramAffiliationForm } from './EquipmentSchoolProgramAffiliation.form';
import { FlexGroup, FlexRow } from 'layouts';
import { fmap } from 'util/arrayUtility';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { isEqual } from 'util/formHelpers';
import { Modal, useModal } from 'react-morphing-modal';
import { safeGet, mergeObjects, compose, prop } from 'util/objectUtility';
import { saveCTAEquipment } from 'redux/cta/ctaequipment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { sumNumbers, round } from 'util/mathHelpers';
import { CTAEquipmentValidation } from 'validations/CTA/CTAEquipment.validations';
import { Link } from 'react-router-dom';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import DatePicker from 'components/Common/FormElements/DatePicker';
import DynamicForm from 'components/Common/DynamicForm';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import StringResources from 'StringResources';
import Textarea from 'components/Common/FormElements/Textarea';
import ToastNotification from 'components/Common/Toast';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import { every } from 'lodash';

type EquipmentCostModalProps = {
  equipment: CTAEquipment;
  isNew?: boolean;
  ctaId: number;
  year: number;
  district: string;
};

export const EquipmentCostModal: React.FC<EquipmentCostModalProps> = (props) => {
  const { equipment, isNew = false, ctaId, year, district } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { modalProps, open, close } = useModal();
  const {
    getError,
    isValid,
    resetValidationState,
    validate,
    validateAll,
    validationErrors,
  } = CTAEquipmentValidation();

  // --[ local state ]---------------------------------------------------------
  const modalRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [equipmentState, setEquipmentState] = useState<CTAEquipment>(emptyCtaEquipment());

  // --[ component logic ]-----------------------------------------------------
  // get :: string -> equipmentState[string]
  const get = safeGet(equipmentState);

  // calculateCosts :: equipmentState -> number
  const calculateCosts = compose(
    sumNumbers,
    fmap(prop('cost')),
    defaultTo([emptyCTAEquipmentCTEProgram()]),
    prop('ctaEquipmentCTEPrograms')
  );

  // onChange :: Partical<CTAEquipment> -> void
  const onChange = (data: Partial<CTAEquipment>) => {
    const updated = mergeObjects(equipmentState, data);
    setEquipmentState(updated);
  };

  // handleCTAEquipmentProgramAffiliationChange :: number -> void
  const handleCTAEquipmentProgramAffiliationChange = (index: number) => (
    edited: CTAEquipment
  ) => {
    const ctaEquipmentCTEPrograms = get('ctaEquipmentCTEPrograms').map(
      (item: CTAEquipment, idx: number) => {
        return isEqual(index, idx) ? edited : item;
      }
    );
    const updated = mergeObjects(equipmentState, {
      ctaEquipmentCTEPrograms,
    });
    setEquipmentState({
      ...updated,
      totalCost: calculateCosts(updated),
    });
  };

  // addEquipmentProgramAffiliation :: empty -> void
  const addEquipmentProgramAffiliation = () => {
    const ctaEquipmentCTEPrograms = [
      ...get('ctaEquipmentCTEPrograms'),
      emptyCTAEquipmentCTEProgram(),
    ];
    const updated = mergeObjects(equipmentState, {
      ctaEquipmentCTEPrograms,
    });
    setEquipmentState(updated);
  };

  // deleteSchoolProgramAffiliation :: number -> void
  const deleteSchoolProgramAffiliation = (index: number) => {
    const ctaEquipmentCTEPrograms = get('ctaEquipmentCTEPrograms').filter(
      (_: any, idx: number) => {
        return index !== idx;
      }
    );
    const updated = mergeObjects(equipmentState, {
      ctaEquipmentCTEPrograms,
    });
    setEquipmentState(updated);
  };

  // handleFileUpload :: string | empty -> void
  const handleFileUpload = (fileUpload: string = '') => {
    setEquipmentState(mergeObjects(equipmentState, { fileUpload }));
  };

  // handleClose :: empty -> void
  const handleClose = () => {
    resetValidationState();
    setEquipmentState(emptyCtaEquipment());
    close();
  };

  // handleSave :: empty -> void
  const handleSave = () => {
    const canSave = validateAll(equipmentState);
    if (canSave) {
      dispatch(saveCTAEquipment(ctaId, equipmentState))
        .then(() => {
          toast(
            <ToastNotification status="success" message={StringResources.Success.DataSaved} />
          );
        })
        .then(() => setEquipmentState(emptyCtaEquipment()))
        .then(() => resetValidationState())
        .then(() => close())
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        });
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setEquipmentState(equipment);
  }, [equipment]);

  useEffect(() => {
    resetValidationState();
  }, [equipmentState]); // eslint-disable-line

  // --[ render logic ]--------------------------------------------------------
  // getCosts :: equipmentState -> string
  const getCosts = compose(insertDollarSign, formatMoney, calculateCosts);

  // getCostsPerUnit :: equipmentState -> string
  const getCostsPerUnit = compose(
    insertDollarSign,
    formatMoney,
    round(2),
    (costs: number) => {
      const units = get('units');
      return every([units, units > 0]) ? costs / get('units') : 0;
    },
    calculateCosts
  );

  return (
    <React.Fragment>
      <div ref={modalRef}>
        {isNew ? (
          <Button
            text="+ Add Equipment"
            className="form__btn--add u-flex-self-start"
            onClick={() => open(modalRef)}
          />
        ) : (
          <IconButton name="pencil" tooltip="Edit" onClick={() => open(modalRef)} />
        )}
      </div>
      <Modal {...modalProps}>
        <SecureWrap component={['ctageneral']}>
          <div className="u-flex-center" style={{ width: '100%' }}>
            <Paper className="t-equipment-details">
              <FlexRow>
                <h2 className="u-flex-grow-1">Add Equipment</h2>
              </FlexRow>
              <FlexRow>
                <Instruction title="Equipment Details">
                  <p>
                    Enter details for this equipment, including school and program affiliation
                    below. When you have finished entering this data please combine your
                    documentation into a single electronic file (PDF or XLS) and upload it
                    using the ‘Upload Document’ field.
                  </p>
                  <p>
                    <span className="u-color-red">Note</span>: If you upload a second document,
                    it will replace the previous one. Click on the ‘Save’ button to return to
                    the ‘Equipment Costs’ section.
                  </p>
                </Instruction>
              </FlexRow>
              <FlexGroup>
                <FlexRow>
                  <Input name="itemName" onChange={onChange} value={get('itemName')} />
                  <Input name="fundSource" onChange={onChange} value={get('fundSource')} />
                  <Input
                    name="units"
                    onChange={onChange}
                    value={get('units')}
                    errorMessage={getError('units')}
                    onBlur={() => {
                      validate('units', get('units'));
                    }}
                  />
                </FlexRow>
                <FlexRow>
                  <DatePicker
                    name="invoiceDate"
                    label="Invoice Date"
                    onChange={onChange}
                    value={get('invoiceDate')}
                  />
                  <Input
                    name="invoiceNumber"
                    onChange={onChange}
                    value={get('invoiceNumber')}
                  />
                  <FlexGroup>
                    <p className="form__label">Upload Invoice</p>
                    {get('fileUpload') ? (
                      <AzureDownloader
                        onClose={() => handleFileUpload('')}
                        uploadParams={{
                          container: 'cta',
                          folder: `${year}|${district}|equipment-costs`,
                        }}
                        filepath={get('fileUpload')}
                      />
                    ) : (
                      <Button
                        text="+ Upload File"
                        className="form__btn--add u-flex-self-start grant-schedule__upload-btn"
                        onClick={() => setOpen(true)}
                      />
                    )}
                    <AzureUploader
                      onClose={() => setOpen(false)}
                      open={isOpen}
                      uploadParams={{
                        container: 'cta',
                        folder: `${year}|${district}|equipment-costs`,
                      }}
                      onConfirm={handleFileUpload}
                    />
                  </FlexGroup>
                </FlexRow>
                <FlexRow>
                  <FlexGroup>
                    <LabelWithIcon
                      htmlFor="location"
                      iconName="info"
                      label="Location of equipment (character limit 100)"
                      tooltip="Provide a description of the location of the equipment purchased. 
                      If units are located in multiple locations, please list each site and classroom number 
                      (if applicable), separating each entry with a comma."
                    />
                    <Textarea
                      maxLength={100}
                      onChange={onChange}
                      name="location"
                      value={get('location')}
                      noLabel={true}
                    />
                  </FlexGroup>
                </FlexRow>
              </FlexGroup>
              <FlexGroup>
                <FlexRow>
                  <Instruction title="School and Program Affiliations">
                    <p>
                      Use the dropdown box to select all school and program affiliations for
                      the item listed above and the cost applied to each. For example, if a
                      minibus was purchased and used at two schools for three CTE programs, you
                      would determine the appropriate split the cost across each school and
                      program. Use the ‘Add a School/Program’ to add additional sites.
                    </p>
                    <p>
                      <span className="u-color-red">Note</span>: If a program needs to be added
                      to the dropdown, have the person with the student record permission enter
                      student enrollment information in the{' '}
                      <Link rel="noreferrer" target="_blank" to="/data-collection">
                        Data Collection
                      </Link>{' '}
                      module. You will not be able to select a school and program affiliation
                      until the day after the program was reported on in Data Collections,
                      allowing time for the database to update.
                    </p>
                  </Instruction>
                </FlexRow>
                <DynamicForm
                  addButtonText="+ Add an Affiliation"
                  addForm={addEquipmentProgramAffiliation}
                  form={EquipmentSchoolProgramAffiliationForm}
                  items={get('ctaEquipmentCTEPrograms')}
                  onChange={handleCTAEquipmentProgramAffiliationChange}
                  removeForm={deleteSchoolProgramAffiliation}
                />
                {equipmentState.ctaEquipmentCTEPrograms.length > 0 && (
                  <>
                    <FlexRow>
                      <div className="u-flex-grow-1" />
                      <div style={{ display: 'flex', paddingRight: '5.3rem' }}>
                        <Input
                          disabled={true}
                          name="Total Cost per Unit"
                          value={getCostsPerUnit(equipmentState)}
                        />
                        <Input
                          disabled={true}
                          name="Total Cost"
                          value={getCosts(equipmentState)}
                        />
                      </div>
                    </FlexRow>
                  </>
                )}
              </FlexGroup>
              <SaveButtons
                alternateAction={handleSave}
                disabled={!isValid}
                onCancel={handleClose}
                validationErrors={validationErrors}
              />
            </Paper>
          </div>
        </SecureWrap>
      </Modal>
    </React.Fragment>
  );
};
