import { COMMON_KEY, COMMON_CAREER_CLUSTERS_KEY } from 'redux/keys';
import { CareerCluster } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';

export const CAREER_CLUSTERS_PATH = [COMMON_KEY, COMMON_CAREER_CLUSTERS_KEY];

export const getIsDataFetching_CareerCluster = (state: any): boolean => {
  return getIsDataFetching(state, CAREER_CLUSTERS_PATH);
};

export const getLastUpdated_CareerCluster = (state: any): number => {
  return getLastUpdated(state, CAREER_CLUSTERS_PATH);
};

export const getRecords_CareerCluster = (state: any): CareerCluster[] => {
  return getRecords<CareerCluster>(state, CAREER_CLUSTERS_PATH);
};

export const shouldRefreshReduxState_CareerCluster = (state: any): boolean => {
  return shouldRefreshReduxState<CareerCluster>(state, CAREER_CLUSTERS_PATH);
};
