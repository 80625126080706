import React from 'react';
import AdminLayout from 'layouts/AdminLayout';
import RoleDetails from 'components/Admin/RoleDetails';

export const RoleDetailsPage: React.FC = () => {
  return (
    <AdminLayout>
      <RoleDetails />
    </AdminLayout>
  );
};
