import { CTA_KEY, CTA_COURSE_CONTRACTING_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { defaultTo, find } from 'lodash';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTACourse, emptyCTACourse } from 'types';

export const CTA_COURSE_CONTRACTING_PATH = [
  CTA_KEY,
  CTA_COURSE_CONTRACTING_KEY,
];

export const getIsDataFetching_CTACourseContracting = (state: any): boolean => {
  return getIsDataFetching(state, CTA_COURSE_CONTRACTING_PATH);
};

export const getRecords_CTACourseContracting = (
  state: unknown
): CTACourse[] => {
  return getRecords(state, CTA_COURSE_CONTRACTING_PATH);
};

export const getAdvancedFilteredRecords_CTACourseContracting = (
  state: any
): CTACourse[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTACourse>(
    state,
    CTA_COURSE_CONTRACTING_PATH,
    filters
  );
  return records;
};

export const getSingleRecord_CTACourseContracting = (
  state: unknown,
  ctaId: number
): CTACourse => {
  const records = getRecords_CTACourseContracting(state);

  return defaultTo(
    find(records, (item: CTACourse) => {
      return item.ctaId === Number(ctaId);
    }),
    emptyCTACourse()
  );
};
