import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  readCTASupplies,
  updateCTASupplies,
} from 'services/cta/administrativecosts.services';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';
import { AdminBooksAndSupplies } from 'types';

const initialState = baseInitialState;

export const loadCTASupplies = createActionThunk(
  'loadCTASupplies',
  (ctaId: number) => {
    return readCTASupplies(ctaId);
  }
);
export const saveCTASupplies = createActionThunk(
  'saveCTASupplies',
  (ctaId: number, item: AdminBooksAndSupplies) => {
    return updateCTASupplies(ctaId, item);
  }
);

export default handleActions<BaseStateObject, CTAAdministrator[]>(
  {
    ...baseLoadActionBuilder<CTAAdministrator[]>(loadCTASupplies),
    ...baseSaveActionBuilder(saveCTASupplies),
  },
  initialState
);
