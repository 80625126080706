import React, { useState, useEffect } from 'react';
import { SnapshotTeacher, ActiveTeachersData } from 'types';
import { mergeObjects, prop, compose, converge } from 'util/objectUtility';
import { getFullName } from 'util/datacollection.utility';
import { SnapshotTeacherValidations } from 'validations/DataCollection/SnapshotTeacher.validations';
import { toast } from 'react-toastify';
import { some } from 'lodash';
import Instruction from 'components/Common/Instruction';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import ProgramInformation from './ProgramInformation';
import SaveButtons from 'components/Common/SaveButtons';
import TeacherPersonalInformation from './PersonalInformation';
import ToastNotification from 'components/Common/Toast';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type SelectedTeacherComponentProps = {
  close: Function;
  saveSnapshotTeacher: Function;
  selectedTeacher: SnapshotTeacher;
  teacherData: ActiveTeachersData;
};

export const EditSnapshotTeacher: React.FC<SelectedTeacherComponentProps> = (
  props
) => {
  const { close, saveSnapshotTeacher, selectedTeacher, teacherData } = props;
  const [dupe, setDupe] = useState<boolean>(false);
  const [teacherState, setTeacherState] = useState<SnapshotTeacher>(
    selectedTeacher
  );

  const v = SnapshotTeacherValidations();

  const onChange = (
    data: Partial<SnapshotTeacher>,
    name: keyof SnapshotTeacher,
    val: any
  ) => {
    let updated;
    if (name === 'isTeachingPostsecondary' && !val) {
      // if isTeachingPostsecondary is toggled false, clear validations
      const clearData: Partial<SnapshotTeacher> = {
        ...data,
        schoolId: -1,
        s_number: '',
        renewalReq: '',
        credentialType: null,
      };
      updated = mergeObjects<SnapshotTeacher>(teacherState, clearData);
      // remove any validation errors
      v.validateCustom([
        { key: 's_number', value: clearData.s_number, state: updated },
        { key: 'renewalReq', value: clearData.renewalReq, state: updated },
        {
          key: 'credentialType',
          value: clearData.credentialType,
          state: updated,
        },
      ]);
      updated = mergeObjects<SnapshotTeacher>(teacherState, clearData);
    } else {
      updated = mergeObjects<SnapshotTeacher>(teacherState, data);
      if (Object.keys(v.validationState).includes(name)) {
        v.validateIfTrue(name as any, val, updated);
      }
    }
    setTeacherState(updated);
  };

  const handleSave = () => {
    const valid = v.validateAll(teacherState);
    return valid
      ? saveSnapshotTeacher(teacherState)
          .then(() => {
            toast(
              <ToastNotification status="success" message="Teacher Saved." />
            );
          })
          .catch(() => {
            Promise.resolve(); // avoid unhandled catch
          })
          .finally(() => close())
      : toast(
          <ToastNotification
            status="error"
            message="Not all validations passed. Please check the form for errors and try again."
          />
        );
  };

  const getErrors = () => {
    return dupe
      ? [...v.validationErrors, 'Current teacher record already exists']
      : v.validationErrors;
  };

  useEffect(() => {
    setTeacherState(selectedTeacher);
    v.resetValidationState();
  }, [selectedTeacher]); // eslint-disable-line

  return (
    <SecureWrap
      component={['DCTeacherRecords', 'DCCertification']}
      isLocked={some([teacherData.complete, teacherData.locked])}
    >
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Teacher Record: ${getFullName(teacherState)}`}
              isPageHeader={true}
            >
              <p>
                Enter the program affiliation for this instructor in the
                ‘Program Information’ section below and click on the ‘Save’
                button to return to the previous screen. Input boxes highlighted
                in blue will pre-populate and are not editable.
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <TeacherPersonalInformation
          onChange={onChange}
          teacher={teacherState}
          v={v}
          close={close}
        />
      </Paper>
      <Paper>
        <ProgramInformation onChange={onChange} teacher={teacherState} v={v} />
      </Paper>
      <Paper>
        <SaveButtons
          alternateAction={handleSave}
          disabled={!v.isValid}
          onCancel={close}
          validationErrors={getErrors()}
        />
      </Paper>
    </SecureWrap>
  );
};
