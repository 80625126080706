import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import { Consortium } from 'types';
import {
  createConsortium,
  readConsortia,
  readSingleConsortium,
} from 'services/grants/consortium.services';
import {
  actionFetching,
  baseLoadActionBuilder,
  actionUpdateRecords,
  actionRecordFailure,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadConsortia = createActionThunk('loadConsortia', () => {
  return readConsortia();
});

export const loadSingleConsortium = createActionThunk(
  'loadSingleConsortium',
  (consortiumId: number) => {
    return readSingleConsortium(consortiumId);
  }
);

export const saveConsortia = createActionThunk(
  'saveConsortia',
  async (payload: Consortium) => {
    return createConsortium(payload);
  }
);

export const insertConsortiums = createAction('insertConsortiums');

export default handleActions<BaseStateObject, Consortium | Consortium[]>(
  {
    ...baseLoadActionBuilder<Consortium | Consortium[]>(loadConsortia),

    [loadSingleConsortium.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadSingleConsortium.SUCCEEDED]: (state, action) => {
      const records = upsert<Consortium>(
        state.records,
        action.payload,
        'consortiumId'
      );
      return actionUpdateRecords(state, records);
    },
    [loadSingleConsortium.FAILED]: (state) => {
      return actionRecordFailure(state);
    } /* End action */,
    [loadSingleConsortium.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    [saveConsortia.START]: (state) => {
      return actionFetching(state, true);
    },
    [saveConsortia.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
