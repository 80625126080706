import { hasUpdatePeriodElapsed } from 'util/global';
import {
  get,
  concat,
  filter,
  isArray,
  includes,
  map,
  isString,
  some,
  uniq,
} from 'lodash';
import { ItemFilter, ReduxFilter } from 'types';
import { firstOrDefault } from 'util/arrayUtility';

/**
 * Checks the last time this redux state was updated, and then returns a value indicating is
 * the specified waitTime has elapsed.
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 * @param waitTime How long to wait in seconds before refreshing redux state
 */
export const shouldRefreshReduxState = <T>(
  state: any,
  paths: string[],
  waitTime: number | undefined = 43200
): boolean => {
  // Not sure how it happened, but isFetching was true for several thinngs even though they were not fetching
  // This extra check should allow us to bypass the isFetching if it somehow gets in a bad state.
  if (
    getIsDataFetching(state, paths) &&
    hasUpdatePeriodElapsed(getLastUpdated(state, paths), waitTime + 120)
  ) {
    return true;
  }

  // If we had a failure in the last 1 minutes, don't refresh redux state
  // Updating this to one minute as we have been seeing some empty dropdowns
  // and 5 minutes is a long time!
  if (!hasUpdatePeriodElapsed(getLastFailedCall(state, paths), 60)) {
    return false;
  }

  const value: T[] = getRecords<T>(state, paths);
  return (
    (value.length === 0 &&
      hasUpdatePeriodElapsed(getLastUpdated(state, paths), 60)) ||
    hasUpdatePeriodElapsed(getLastUpdated(state, paths), waitTime)
  );
};

/**
 * Returns all records at the given state path of Type <T>
 *
 * @param state Redux state object
 * @param paths string array that can be used to find the appropriate sub-are in the state object.
 */
export const getRecords = <T>(state: any, paths: string[]): T[] => {
  const fullpath = concat(paths, ['records']);
  return get(state, fullpath, []);
};

/**
 * Returns the first record at the given state path of Type <T>
 *
 * @param state Redux state object
 * @param paths string array that can be used to find the appropriate sub-are in the state object.
 * @param defaultItem the item to return if the array is empty.
 */
export const getFirstRecord = <T>(
  state: any,
  paths: string[],
  defaultItem: T
): T => {
  return firstOrDefault<T>(getRecords(state, paths), defaultItem);
};

/**
 * Takes an arrayCompare item filter, executes it against the provided records and returns the results
 *
 * @param itemFilter The ItemFilter to use
 * @param records The records to execute the ItemFilter against
 */
const executeArrayFilter = <T>(filters: ItemFilter, records: T[]): T[] => {
  const propValue = isArray(filters.values) ? filters.values : [filters.values];

  return filter(records, (item) => {
    const testItems: any[any] = get(item, filters.property, undefined);
    return includes(testItems, propValue[0]);
  });
};

/**
 * Takes an arrayCompare item filter, executes it against the provided records and returns the results
 *
 * @param itemFilter The ItemFilter to use
 * @param records The records to execute the ItemFilter against
 */
const executeStandardFilter = <T>(filters: ItemFilter, records: T[]): T[] => {
  const propValue = isArray(filters.values) ? filters.values : [filters.values];
  return filter(records, (item: T) => {
    const testItem = get(item, filters.property, undefined);

    let results;
    if (isString(testItem)) {
      results = some(
        map(propValue, (val) => {
          if (filters.strict) {
            return RegExp(`^${val}$`, 'i').test(testItem);
          } else {
            return RegExp(val, 'i').test(testItem);
          }
        })
      );
    } else {
      results = includes(propValue, testItem);
    }
    return filters.exclude ? !results : results;
  });
};

/**
 * Takes an item filter with a comparator function, executes it against the provided records and returns the results
 *
 * @param itemFilter The ItemFilter to use
 * @param records The records to execute the ItemFilter against
 */
const executeCompareFilter = <T>(filters: ItemFilter, records: T[]): T[] => {
  const propValue = isArray(filters.values) ? filters.values : [filters.values];

  return filter(records, (item) => {
    const testItem = get(item, filters.property, undefined);
    const results = some(
      map(propValue, (val) => {
        return filters.comparator!(testItem, val);
      })
    );
    return results;
  });
};

/**
 * Takes an item filter, executes it against the provided records and returns the results
 *
 * @param itemFilter The ItemFilter to use
 * @param records The records to execute the ItemFilter against
 */
const executeFilter = <T>(filters: ItemFilter, records: T[]): T[] => {
  if (filters.arrayCompare) {
    return executeArrayFilter<T>(filters, records);
  }
  if (filters.comparator) {
    return executeCompareFilter<T>(filters, records);
  }
  return executeStandardFilter<T>(filters, records);
};

/**
 * Filters records and then returns the results
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 * @param filters Set of redux filters to use against the data
 */
export const getAdvancedFilteredRecords = <T>(
  state: any,
  path: string[],
  filters: ReduxFilter<T>[],
  records?: T[]
): T[] => {
  const values = records ? records : getRecords<T>(state, path);
  let output: any[] = [...values];

  map(filters, (itemFilter: ReduxFilter<unknown>) => {
    switch (itemFilter.operator) {
      case 'and':
        output = executeFilter<T>(itemFilter.filter, output);
        break;

      case 'or':
        output = [...output, ...executeFilter<T>(itemFilter.filter, values)];
        break;

      default:
        break;
    }
  });

  return uniq(output);
};

/**
 * Filters records and then returns the results
 *
 * @param state Redux state object
 * @param path String array that can be used to find the appropriate sub-are in the state object.
 * @param itemFilter The ItemFilter to use
 * @param collection The records to filter, if no records are specified it will select the default records from state
 */
export const getFilteredRecords = <T>(
  state: any,
  path: string[],
  itemFilter: ItemFilter,
  collection?: T[]
): T[] => {
  const records = collection ? collection : getRecords<T>(state, path);
  return executeFilter<T>(itemFilter, records);
};

/**
 * Returns only active records from the state
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 */
export const getActiveRecords = <T>(state: any, paths: string[]): T[] => {
  return filter(getRecords<T>(state, paths), (value: any) => {
    return value.isActive === true;
  });
};

/**
 * Returns a number indication the last time the records were updated.
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 */
export const getLastUpdated = (state: any, paths: string[]): number => {
  const fullpath = concat(paths, ['lastUpdate']);
  return get(state, fullpath, 0);
};

/**
 * Returns a number indicating the last time this api call failed.
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 */
export const getLastFailedCall = (state: any, paths: string[]): number => {
  const fullpath = concat(paths, ['lastFailure']);
  return get(state, fullpath, 0);
};

/**
 * Returns a boolean indicating if the redux is currently loading these records from the API
 *
 * @param state Redux state object
 * @param paths String array that can be used to find the appropriate sub-are in the state object.
 */
export const getIsDataFetching = (state: any, paths: string[]): boolean => {
  const fullpath = concat(paths, ['isFetching']);
  return get(state, fullpath, false);
};
