import { connect } from 'react-redux';
import { WorkflowEditComponent } from './WorkflowEdit.component';
import { WorkflowSummaryComponent } from './WorkflowSummary.component';
import ApplicationState from 'types/applicationstate.types';
import { getActiveProgramId } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { getLoggedinUser } from 'redux/user/selectors';
import { getActiveProgramTitle } from 'redux/programs/_selectors';
import { getWorkflowAssurances } from 'redux/workflow/workflowassurances.selectors';
import { loadWorkflowAssurances } from 'redux/workflow/workflowassurances';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import {
  loadWorkFlow,
  submitProgramForApproval,
  submitProgramForUpdate,
} from 'redux/generalworkflow/workflow.actions';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const programTitle = getActiveProgramTitle(state);
  const isFetching = getIsDataFetching_GeneralWorkflow('programs', state);
  const workflow = getGeneralWorkflow('programs', state);
  const user = getLoggedinUser(state);
  const assurances = getWorkflowAssurances(state);

  return {
    assurances,
    user,
    programTitle,
    programInstanceId,
    workflow,
    isFetching,
  };
};

const dispatchToProps = {
  loadWorkFlow,
  loadWorkflowAssurances,
  setCurrentNavAnchor,
  submitProgramForApproval,
  submitProgramForUpdate,
};

export const WorkflowEdit = connect(
  mapStateToProps,
  dispatchToProps
)(WorkflowEditComponent);

export const WorkflowSummary = connect(
  mapStateToProps,
  dispatchToProps
)(WorkflowSummaryComponent);
