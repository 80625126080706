import { connect } from 'react-redux';
import { CTAHydrator } from './CTAHydrator';
import {
  shouldRefreshReduxState_CTAFiscalYear,
  shouldRefreshReduxState_CTASchoolTerm,
} from 'redux/common/_selectors';
import { loadCTAFiscalYears } from 'redux/common/ctafiscalyear';
import { loadCTASchoolTerms } from 'redux/common/ctaschoolterms';
import { loadCTASupportProgramAffiliation } from 'redux/cta/ctasupportprogramaffiliation';

export const mapStateToProps = (state: any) => {
  const shouldRefreshCTAFiscalYears = shouldRefreshReduxState_CTAFiscalYear(
    state
  );
  const shouldRefreshCTASchoolTerms = shouldRefreshReduxState_CTASchoolTerm(
    state
  );

  return {
    shouldRefreshCTAFiscalYears,
    shouldRefreshCTASchoolTerms,
  };
};

const dispatchProps = {
  loadCTAFiscalYears,
  loadCTASchoolTerms,
  loadCTASupportProgramAffiliation,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTAHydrator);
