import {
  saveProgramAssuranceQuestions,
  loadProgramAssuranceQuestions,
} from 'redux/programs/assurancequestions';

export const programAssuranceMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case saveProgramAssuranceQuestions.SUCCEEDED: {
      dispatch(loadProgramAssuranceQuestions(action.payload));
      break;
    }
  }
};
