import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Root from 'Root';
import routes from 'routes';
import FourOhFourPage from 'pages/FourOhFour';
import { AccessibilityStatementPage } from 'pages/AccessibilityStatement/AccessibilityStatement.page';
import authentication from 'components/Common/Authorization/AzureB2C';

class App extends Component {
  render() {
    return (
      <Root>
        {routes}
        <Route
          exact
          path="/accessibility"
          component={authentication.required(AccessibilityStatementPage)}
        />
        ,{/* this must always be the last route. It is the 404 page */}
        <Route component={FourOhFourPage} />
      </Root>
    );
  }
}

export default App;
