import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import ProjectSpendingPlan from 'components/Grants/Applications/AnnualSpendingPlan/ProjectSpendingPlan';

export const GrantProjectPlanPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <ProjectSpendingPlan />
      </GrantsLayout>
    </BaseLayout>
  );
};
