import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { GrantTwoYearEdit } from 'components/Grants/Applications/TwoYear/TwoYearConnect';

export const GrantTwoYearStrategicPlanPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <GrantTwoYearEdit />
      </GrantsLayout>
    </BaseLayout>
  );
};
