import { PROGRAMS_KEY, PROGRAM_CAREER_PATHWAYS_KEY } from 'redux/keys';
import { ItemFilter, ProgramPathway } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';

export const PROGRAM_CAREER_PATHWAYS_PATH: string[] = [
  PROGRAMS_KEY,
  PROGRAM_CAREER_PATHWAYS_KEY,
];

export const getIsDataFetching_ProgramCareerPathways = (
  state: any
): boolean => {
  return getIsDataFetching(state, PROGRAM_CAREER_PATHWAYS_PATH);
};

export const getLastUpdated_ProgramCareerPathways = (state: any): number => {
  return getLastUpdated(state, PROGRAM_CAREER_PATHWAYS_PATH);
};

export const getRecords_ProgramCareerPathways = (
  state: any
): ProgramPathway[] => {
  return getRecords<ProgramPathway>(state, PROGRAM_CAREER_PATHWAYS_PATH);
};

export const shouldRefreshReduxState_ProgramCareerPathways = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ProgramPathway>(
    state,
    PROGRAM_CAREER_PATHWAYS_PATH
  );
};

export const getFilteredRecords_ProgramCareerPathways = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<ProgramPathway>(
    state,
    PROGRAM_CAREER_PATHWAYS_PATH,
    itemFilter
  );
};
