import { VoucherExpense, SpendingCategory } from 'types';
import { emptySpendingCategory } from '../AnnualSpendingPlan/SpendingCategory.types';

export interface VoucherSpendingCategory extends SpendingCategory {
  expenses: VoucherExpense[];
  limit: number | null;
  reimbursementRequest: number;
  totalAmountForCurrentRequest: number;
}

export const emptyVoucherSpendingCategory = (): VoucherSpendingCategory => {
  return {
    ...emptySpendingCategory(),
    expenses: [],
    limit: null,
    reimbursementRequest: 0,
    totalAmountForCurrentRequest: 0,
  };
};
