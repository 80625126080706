import { find } from 'lodash';
import { COMMON_KEY, COMMON_PROGRAM_LEVELS_KEY } from 'redux/keys';
import { ProgramLevel } from 'types';
import ApplicationState from 'types/applicationstate.types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';

export const PROGRAM_LEVELS_PATH = [COMMON_KEY, COMMON_PROGRAM_LEVELS_KEY];

export const getIsDataFetching_ProgramLevels = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_LEVELS_PATH);
};

export const getRecords_ProgramLevels = (state: any): ProgramLevel[] => {
  return getRecords<ProgramLevel>(state, PROGRAM_LEVELS_PATH);
};

export const shouldRefreshReduxState_ProgramLevels = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramLevel>(state, PROGRAM_LEVELS_PATH);
};

export const getLastUpdated_ProgramLevels = (state: any): number => {
  return getLastUpdated(state, PROGRAM_LEVELS_PATH);
};

export const getProgramLevel = (
  state: ApplicationState,
  programLevelId: number
): ProgramLevel | undefined => {
  return find(getRecords_ProgramLevels(state), { programLevelId });
};
