import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readCTASupportProgramAffiliation } from 'services/cta/ctasupportpersonnel.services';
import { CTASupportProgramAffiliation } from 'types/cta/CTASupportProgramAffiliation.types';

const initialState = baseInitialState;

export const loadCTASupportProgramAffiliation = createActionThunk(
  'loadCTASupportProgramAffiliation',
  (ctaId: number) => {
    return readCTASupportProgramAffiliation(ctaId);
  }
);

export default handleActions<BaseStateObject, CTASupportProgramAffiliation[]>(
  {
    ...baseLoadActionBuilder<CTASupportProgramAffiliation[]>(
      loadCTASupportProgramAffiliation
    ),
  },
  initialState
);
