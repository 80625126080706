import React from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import BaseLayout from 'layouts/BaseLayout';

export const AdminLayout: React.FC = (props: any) => {
  return (
    <BaseLayout>
      <SecureWrap component={'adminonly'} isPage={true}>
        {props.children}
      </SecureWrap>
    </BaseLayout>
  );
};
