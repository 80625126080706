import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import ProgramEnrollment from 'components/DataCollection/StudentEnrollment/ProgramEnrollment';

export const ProgramEnrollmentPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <ProgramEnrollment {...props} />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
