import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import CourseCreditsEditComponent from 'components/Program/CourseCredits/CourseCreditsEdit';
import CourseCreditsSummaryComponent from 'components/Program/CourseCredits/CourseCreditsSummary';
import { loadProgramAwards } from 'redux/caches/programaward';
import { getCourses, getNextStep } from 'redux/common/_selectors';
import {
  getRecords_CourseTypes,
  getIsProgramReadOnly,
  getProgramCccsCollege,
} from 'redux/programs/_selectors';
import {
  getProgramCourseCredits,
  calculateProgramCourseCredits,
} from 'redux/caches/_selectors';
import {
  loadProgramCourseCredits,
  saveProgramCourseCreditCache,
  resetProgramCourseCreditsCache,
} from 'redux/caches/coursecredit';
import { CourseCreditsSuperSummaryComponent } from './CourseCreditsSuperSummary.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const approvedCourses = getCourses(state);
  const courseCredits = getProgramCourseCredits(state, programInstanceId);
  const courseTypes = getRecords_CourseTypes(state);
  const totalCredits = calculateProgramCourseCredits(state, programInstanceId);
  const nextProgramStep = getNextStep(state, 'coursecredits');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);
  const courses = getCourses(state);
  const isCccsCollege = getProgramCccsCollege(state, programInstanceId);

  return {
    approvedCourses,
    courseCredits,
    courseTypes,
    courses,
    nextProgramStep,
    readOnly,
    totalCredits,
    isCccsCollege,
  };
};

const dispatchToProps = {
  loadProgramAwards,
  loadProgramCourseCredits,
  resetProgramCourseCreditsCache,
  saveProgramCourseCreditCache,
  setCurrentNavAnchor,
};

export const CourseCreditsEdit = connect(
  mapStateToProps,
  dispatchToProps
)(CourseCreditsEditComponent);

export const CourseCreditsSummary = connect(
  mapStateToProps,
  dispatchToProps
)(CourseCreditsSummaryComponent);

export const CourseCreditsSuperSummary = connect(
  mapStateToProps,
  dispatchToProps
)(CourseCreditsSuperSummaryComponent);
