import { first, last } from 'lodash';

export const isMoney = (str: string) => {
  if (typeof str === 'string') {
    return /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/.test(str);
  }
  return typeof str === 'number' ? true : false;
};

export const removeCommas = (str: string) => {
  if (typeof str === 'string') {
    return str.replace(/,/g, '');
  }
  return str;
};

/**
 * @param amount number or string of money quantity
 * @param decimals boolean
 * @returns returns a formatted money string with/without decimals
 */
export const formatMoney = (
  amount: number | string,
  decimals: boolean = true
): string => {
  if (typeof amount === 'string') {
    amount = amount.replace(/,/g, '');
    amount = parseFloat(amount);
  }
  const positive = amount >= 0;
  const str = Math.abs(amount)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

  const result = `${positive ? '' : '-'}${str}`;
  return decimals ? result : result.split('.')[0];
};

/**
 * splits a formatted money string into an object with dollars and cents
 * @param moneyAmount string
 * @returns returns an object with dollars and cents amounts
 */
export const splitMoney = (moneyAmount: string) => {
  if (!moneyAmount.includes('.')) {
    throw new Error(
      'Error: function splitMoney requires a formatted money string'
    );
  }
  const splitAmounts = moneyAmount.split('.');
  const dollars = first(splitAmounts) ? first(splitAmounts) : '0';
  const cents = last(splitAmounts) ? last(splitAmounts) : '00';

  // This check will never happen but TS thinks there is still a path
  if (!dollars || !cents) {
    throw new Error('Error: mythical unicorn found in splitMoney');
  }
  return {
    dollars,
    cents,
  };
};

export const insertDollarSign = (num: string | number) => `$${num}`;
