import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  catchError,
  deleteWithAuthOptions,
} from 'services';
import { UniversalContact } from 'types';
import { map, defaults } from 'lodash';
import { emptyUniversalContact } from 'types';
import { sanitizeContactForAPI } from 'util/universalcontact.utility';

const CONTACTS_ENDPOINT = 'api/users/';
const TEACHERS_ENDPOINT = `${CONTACTS_ENDPOINT}Teachers/`;
const BY_EMAIL = `${CONTACTS_ENDPOINT}GetContactByEmail/`;
const BY_CREDENTIAL = `${CONTACTS_ENDPOINT}GetContactByCredential/`;
const B2C_CONTACTS_ENDPOINT = `${CONTACTS_ENDPOINT}GetUserByB2CID/`;
const BY_SCHOOL_ID = `${CONTACTS_ENDPOINT}bySchoolId/`;

/**
 * Retrieves a specific user from the API using their aadB2cId
 * @param aadB2cId the aadB2cId we want to look up
 */
export const readUser = async (aadB2cId: string): Promise<UniversalContact> => {
  return fetch(`${ENVIRONMENT.API_URL}${B2C_CONTACTS_ENDPOINT}${aadB2cId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Get User By B2CID', err, 'readUser');
    });
};

/**
 * Retrieves a specific user from the API using their universalContactId
 * @param universalContactId the universalContactId of the user we want to retrieve
 */
export const readDBUser = async (
  universalContactId: number
): Promise<UniversalContact> => {
  return fetch(
    `${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}${universalContactId}`,
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('Read Database User', err, 'readDBUser');
    });
};

/**
 * Retrieves all users from the database
 */
export const readAllUsers = async (): Promise<UniversalContact[]> => {
  return fetch(`${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .then((contacts: UniversalContact[]) => {
      return map(contacts, (user: UniversalContact) => {
        let output = defaults({}, user, emptyUniversalContact());
        output.universalContactPhone = user.universalContactPhone
          ? user.universalContactPhone
          : emptyUniversalContact().universalContactPhone;
        return output;
      });
    })
    .catch((err) => {
      return catchError('Read All Users', err, 'readAllUsers');
    });
};

/**
 * Retrieves all users from the database
 */
export const readUniversalTeachers = async (): Promise<UniversalContact[]> => {
  return fetch(`${ENVIRONMENT.API_URL}${TEACHERS_ENDPOINT}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'universalcontact.services',
        err,
        'readUniversalTeachers'
      );
    });
};

/**
 * Creates or updates a user
 * @param contact the UniversalContact object representing the contact we are creating
 */
export const createUser = async (
  contact: UniversalContact
): Promise<number> => {
  const newContact: UniversalContact = sanitizeContactForAPI(contact);

  return fetch(`${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(newContact),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Create User', err, 'createUser');
    });
};

/**
 * Updates a contacts password
 * @param contact the contact who's password we are updating
 * @param password the new password
 */
export const updateUserPassword = async (
  contact: UniversalContact,
  password: string
) => {
  const userId: String = contact.aadB2cId;
  const data = { objectId: userId, password };

  return fetch(`${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}updateUserPassword`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(data),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Update User Password', err, 'updateUserPassword');
    });
};

/**
 * get all users associated with a schoolId
 * @param schoolId the Id of the school we are looking for users associated with
 * @returns array of UniversalContacts
 */
export const readUsersBySchool = async (
  schoolId: number
): Promise<UniversalContact[]> => {
  return fetch(`${ENVIRONMENT.API_URL}${BY_SCHOOL_ID}${schoolId}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Read Users By School', err, 'readUsersBySchool');
    });
};

/**
 * get user by email
 * @param email string address
 * @returns universal contact or no content
 */
export const getContactByEmail = async (email: string) => {
  return fetch(`${ENVIRONMENT.API_URL}${BY_EMAIL}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ email }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Get Contact By Email', err, 'getContactByEmail');
    });
};

/**
 * get user by email
 * @param email string address
 * @returns universal contact or no content
 */
export const getContactByCredential = async (credential: string) => {
  return fetch(`${ENVIRONMENT.API_URL}${BY_CREDENTIAL}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ credential }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'Get Contact By Credential',
        err,
        'getContactByCredential'
      );
    });
};

/**
 * Reactivate the user with the associated universalContactId
 * @param universalContactId the Id of the user we are looking to Reactivate
 * @returns the UniversalContact
 */
export const reactivateUser = async (
  universalContactId: number
): Promise<UniversalContact> => {
  return fetch(
    `${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}reactivate/${universalContactId}`,
    {
      ...postWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('Reactivate User', err, 'reactivateUser');
    });
};

/**
 * Deactivate the user with the associated universalContactId
 * @param universalContactId the Id of the user we are looking to deactivate
 * @returns the UniversalContact
 */
export const deactivateUser = async (
  universalContactId: number
): Promise<UniversalContact> => {
  return fetch(
    `${ENVIRONMENT.API_URL}${CONTACTS_ENDPOINT}${universalContactId}`,
    {
      ...deleteWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('Deactivate User', err, 'deactivateUser');
    });
};
