import {
  saveProgramContacts,
  loadProgramContacts,
} from 'redux/programs/contacts';
import { isApplicaionIdValid } from 'util/global';
import { getActiveProgramId } from 'redux/application/selectors';

export const programContactsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState, dispatch } = store;
  next(action);

  switch (action.type) {
    case saveProgramContacts.ENDED: {
      const programInstanceId = getActiveProgramId(getState());
      if (isApplicaionIdValid(programInstanceId)) {
        dispatch(loadProgramContacts(programInstanceId));
      }
      break;
    }
  }
};
