import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Icon from 'components/Common/Icons';

export const CivilRightsAssuranceDocumentComponent = () => {
  const printArea = useRef<HTMLDivElement>(null);
  return (
    <div className="printable-padding" ref={printArea}>
      <div className="u-flex">
        <span>
          <ReactToPrint
            trigger={() => {
              return (
                <div className="no-print u-pseudo-link">
                  <Icon
                    title="Print"
                    name="print"
                    className="history__print__icon"
                  />
                </div>
              );
            }}
            content={() => {
              const parea = printArea as React.MutableRefObject<
                HTMLInputElement
              >;
              return parea.current;
            }}
          />
        </span>
        <span className="u-flex-grow-1" />
        <span>OMB Approval No. 1870-0503</span>
      </div>
      <div
        className="printable u-flex-vertical"
        style={{ alignItems: 'center' }}
      >
        <p>United States Department of Education, Office for Civil Rights </p>
        <p>
          <strong>ASSURANCE OF COMPLIANCE – CIVIL RIGHTS CERTIFICATE</strong>
        </p>
        <p style={{ maxWidth: 708 }}>
          TITLE VI OF THE CIVIL RIGHTS ACT OF 1964, TITLE IX OF THE EDUCATION
          AMENDMENTS OF 1972, SECTION 504 OF THE REHABILITATION ACT OF 1973, THE
          AGE DISCRIMINATION ACT OF 1975, AND THE BOY SCOUTS OF AMERICA EQUAL
          ACCESS ACT OF 2001
        </p>
        <p>
          The applicant provides this assurance for the purpose of obtaining
          Federal grants, loans, contracts (except contracts of insurance or
          guaranty), property, discounts, funds made available through the U.S.
          Department of Education, or other Federal financial assistance from
          the Department. This assurance applies to all Federal financial
          assistance from or funds made available through the Department,
          including any that the applicant may seek in the future.
        </p>
        <div>
          The applicant assures that it will comply with:
          <ol style={{ marginLeft: '4rem' }}>
            <li>
              Title VI of the Civil Rights Act of 1964, as amended, 42 U.S.C.
              2000d et seq., which prohibits discrimination on the basis of
              race, color, or national origin in any program or activity
              receiving Federal financial assistance.
            </li>
            <li>
              Title IX of the Education Amendments of 1972, as amended, 20
              U.S.C. 1681 et seq., which prohibits discrimination on the basis
              of sex in any education program or activity receiving Federal
              financial assistance.
            </li>
            <li>
              Section 504 of the Rehabilitation Act of 1973, as amended, 29
              U.S.C. 794, which prohibits discrimination on the basis of
              disability in any program or activity receiving Federal financial
              assistance.
            </li>
            <li>
              The Age Discrimination Act of 1975, as amended, 42 U.S.C. 6101 et
              seq., which prohibits discrimination on the basis of age in any
              program or activity receiving Federal financial assistance.
            </li>
            <li>
              If applicable, the Boy Scouts of America Equal Access Act of 2001,
              20 U.S.C. 7905, which requires equala ccess for the Boy Scouts of
              America and other designated youth groups to meet at public
              schools. This law applies to any public elementary school, public
              secondary school, local educational agency, or State educational
              agency that has a designated open forum or limited public forum
              and that receives funds made available through the Department.
            </li>
            <li>
              All regulations, guidelines, and standards issued by the
              Department under any of these statutes.
            </li>
          </ol>
        </div>
        <p>
          The applicant understands that it must comply with items 1-6 in order
          to continue receiving Federal financial assistance from the
          Department. The applicant also understands that this Assurance is
          binding on the applicant, its successors, transferees, and assignees
          at any time during which federal financial assistance is provided. The
          applicant will ensure that all contractors, subcontractors,
          subgrantees, or others with whom it arranges to provide services or
          benefits are not discriminating in violation of items 1-6. Otherwise,
          the financial assistance can be terminated and the applicant can be
          declared ineligible to receive further assistance. The applicant also
          understands that the Department may seek a court order requiring
          compliance with items 1-6 or seek other appropriate judicial relief.
          By signing this form, the applicant is agreeing to the above
          provisions.
        </p>
        <div className="u-flex-vertical page-before" />
        <div
          className="u-flex-vertical avoid-page-break"
          style={{
            alignItems: 'center',
            border: '1px solid #000',
            padding: '1.6rem',
          }}
        >
          <p>Paperwork Burden Statement</p>
          <p>
            According to the Paperwork Reduction Act of 1995, no persons are
            required to respond to a collection of information unless such
            collection displays a valid OMB control number. Public reporting
            burden for this collection of information is estimated to average 20
            minutes per response, including time for reviewing instructions,
            searching existing data sources, gathering and maintaining the data
            needed, and completing and reviewing the collection of information.
            The obligation to respond to this collection is required to obtain
            or retain benefit (42 U.S.C. 2000d et seq.; 20 U.S.C. 1681 et seq.;
            29 U.S.C. 794; 42 U.S.C. 6101 et seq.; 20 U.S.C. 7905). Send
            comments regarding the burden estimate or any other aspect of this
            collection of information, including suggestions for reducing this
            burden, to the U.S. Department of Education, 400 Maryland Ave., SW,
            Washington, DC 20202-4536 or email ICDocketMgr@ed.gov and reference
            the OMB Control Number 1870-0503. Note: Please do not return the
            completed Assurance of Compliance – Civil Rights Certificate to this
            address.
          </p>
        </div>
      </div>
    </div>
  );
};
