import React, { useEffect } from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { compose, prop, renderData } from 'util/objectUtility';
import {
  ExpenseCategory,
  VoucherSpendingCategory,
  emptyVoucherExpense,
} from 'types';
import { useParams } from 'react-router';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import Input from 'components/Common/FormElements/Input';
import Tooltip from 'components/Common/Tooltip';
import Table from 'components/Common/Table';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type RecordActualAmountsProps = {
  expenseCategories: ExpenseCategory[];
  loadExpenseCategories: Function;
  onChange: Function;
  spendingCategory: VoucherSpendingCategory;
  isLocked: boolean;
};

export const RecordActualIndirectComponent: React.FC<
  RecordActualAmountsProps
> = (props) => {
  const { loadExpenseCategories, onChange, spendingCategory, isLocked } = props;

  const { voucherId: vid }: any = useParams();
  const voucherId = Number(vid);

  const Name = compose(
    renderData,
    prop('expenseName')
  );

  const Limit = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('limit')
  );

  const Reimbursement = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('reimbursementRequest')
  );

  const ActualAmountName = (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      }}
    >
      <p style={{ marginBottom: '0' }} className="form__label">
        Current Voucher Request
      </p>
      <Tooltip
        indicator={<Icon name="info" className="custom-table__icon" />}
        tooltip="You can ask for reimbursement over the amount initially allocated in your Annual Spending Plan. However, the total voucher request cannot exceed the 'Maximum Indirect Funds Available' and is subject to approval."
        place="right"
      />
    </div>
  );
  const renderActualAmount = (row: VoucherSpendingCategory) => {
    return (
      <React.Fragment>
        <SecureWrap
          isLocked={isLocked}
          component={['GrantsVouchers', 'GrantsReimbursementOverride']}
        >
          <Input
            className="form__input u-align-right"
            key={row.categoryId}
            min={0}
            name="totalAmountForCurrentRequest"
            noLabel={true}
            onChange={onChange}
            type="number"
            value={row.totalAmountForCurrentRequest}
          />
        </SecureWrap>
      </React.Fragment>
    );
  };

  const generateRowData = () => {
    return [
      {
        ...emptyVoucherExpense(),
        ...spendingCategory,
        expenseName: 'Indirect Administrative Costs',
      },
    ];
  };

  const EXPENSE_COLUMNS: TableColumnProps[] = [
    {
      name: 'Expense Name',
      cell: Name,
      style: {
        width: '25%',
      },
    },
    {
      name: 'Maximum Indirect Funds Available',
      cell: Limit,
      style: {
        textAlign: 'right',
        width: '25%',
      },
    },
    {
      name: 'Reimbursement Requested To Date',
      cell: Reimbursement,
      style: {
        textAlign: 'right',
        width: '25%',
      },
    },
    {
      name: 'Actual Amount',
      label: ActualAmountName,
      cell: renderActualAmount,
      style: {
        width: '25%',
        marginLeft: '2rem',
        textAlign: 'right',
      },
    },
  ];

  useEffect(() => {
    loadExpenseCategories();
  }, [loadExpenseCategories, voucherId]);

  return (
    <div style={{ position: 'relative' }}>
      <Instruction title="Record Actual Amounts">
        <p>
          Enter the total amount you are requesting for Indirect Administrative
          Costs in the “Current Voucher Request” input. "Maximum Indirect Funds
          Available" indicates the updated maximum amount you can request based
          on the formula: (Total Grant Award - Equipment Expenses) * Indirect
          Rate. This means if you add or remove equipment expenses in a project
          plan, the maximum indirect funds that are available will change.
        </p>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <Table
            columns={EXPENSE_COLUMNS}
            data={generateRowData()}
            useRowExpander
            tableRowKey="expenseId"
          />
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
