import CTAAvailableDistrictsSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTAAvailableDistrictsSelectBox';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Tooltip from 'components/Common/Tooltip';
import { COLOR_RED } from 'constants/css.const';
import { CTAContracting } from 'types';
import { FlexRow } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { all, defaultTo, length, lt as gt, identity } from 'ramda';
import { fmap } from 'util/arrayUtility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { getRecords_CTAContractingAvailableHosts } from 'redux/cta/_selectors';
import { loadCTAContractingAvailableHosts } from 'redux/cta/contracting/ctacontractingavailablehosts';
import { oneCTATermHasData } from 'validations/CTA/CTACourse.validations';
import {
  prop,
  compose,
  renderData,
  concatLengthWithItems,
  either,
  isTruthy,
  not,
  converge,
} from 'util/objectUtility';
import { round } from 'util/mathHelpers';
import { some } from 'lodash';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';

type ContractingProps = {
  ctaContracting: CTAContracting[];
  loadCTAContracting: Function;
  locked: boolean;
  removeCTAContracting: Function;
};

export const ContractingComponent: React.FC<ContractingProps> = (props) => {
  const {
    ctaContracting,
    loadCTAContracting,
    locked,
    removeCTAContracting,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId }: any = useParams();
  const ctaId = Number(cId);
  const history = useHistory();
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  // updateOpenedState :: boolean -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };
  // invalidCourses :: ctaContracting -> boolean
  const invalidCourses = compose(
    not,
    all(isTruthy),
    fmap(converge(oneCTATermHasData, [prop('terms'), identity])),
    defaultTo([]),
    prop('courses')
  );
  // invalidCourses :: ctaContractingp[] -> boolean
  const anyCoursesInvalid = compose(some, fmap(invalidCourses));
  // handleDelete :: ctaContractingp -> void
  const handleDelete = async (row: CTAContracting) => {
    return removeCTAContracting(row)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.Deleted}
          />
        );
      })
      .catch((err: any) => console.error(err));
  };

  // handleAddDistrict :: number -> void
  const handleAddDistrict = (id: number) => {
    return history.push(`/cta/district-reporting/contracting/${ctaId}/${id}`);
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTAContracting(ctaId);
    }
  }, [wasOpened, loadCTAContracting, ctaId]);

  // --[ display logic ]-------------------------------------------------------
  const renderEditIcon = (row: CTAContracting) => {
    return (
      <IconButton
        tooltip="Edit"
        name="pencil"
        asLink={true}
        to={`/cta/district-reporting/contracting/${ctaId}/${prop(
          'hostingId',
          row
        )}`}
      />
    );
  };
  const renderDeleteIcon = (row: CTAContracting) => {
    return (
      <ConfirmationDialog
        tooltip="Delete Hosting Entry"
        header="Confirm Delete?"
        onConfirm={async () => {
          await handleDelete(row);
        }}
        activateButton={<IconButton tooltip="Delete" name="trashCan" />}
      >
        <p>
          Are you sure you want to delete this Hosting entry? Once deleted, data
          on this contact will need to be re-added using the “Add New Hosting
          District/Institution" field.
        </p>
      </ConfirmationDialog>
    );
  };

  const CONTRACTING_COLUMNS: TableColumnProps[] = [
    {
      cell: compose(renderData, prop('hostingDistrictInstitution')),
      name: 'Hosting District/Institution',
    },
    {
      cell: (row: CTAContracting) => {
        return prop('programs', row)
          ? concatLengthWithItems('programs', 'programName')(row)
          : null;
      },
      name: 'Programs',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: either(compose(round(2), prop('fte')), () => renderData('')),
      label: () => {
        return (
          <div className="u-flex">
            <span style={{ marginRight: '1rem' }}>FTE</span>
            <Tooltip
              place="right"
              position="below"
              tooltip={
                'The FTE numbers shown below are rounded to 2 decimal places for display purposes and are not the complete FTE values used for calculations. Use the reports module to see full FTE by CIP.'
              }
              indicator={<Icon name="questionMark" className="u-menu-icon" />}
            />
          </div>
        );
      },
      name: 'FTE',
      style: {
        width: '7rem',
      },
    },
    {
      cell: either(prop('count'), () => renderData('')),
      name: 'Count',
      width: '7rem',
    },
    {
      cell: either(prop('cost'), () => renderData('')),
      name: 'Cost',
    },
    {
      cell: renderDeleteIcon,
      name: 'Delete',
      center: true,
      width: '7rem',
    },
    {
      cell: renderEditIcon,
      name: 'Edit',
      center: true,
      width: '7rem',
    },
  ];

  // hasCourses :: ctaContracting -> bool
  const hasCourses = compose(gt(0), length, prop('courses'));

  const conditionalRowStyles = [
    {
      when: (row: CTAContracting) => {
        return hasCourses(row) ? invalidCourses(row) : true;
      },
      style: {
        borderColor: COLOR_RED,
      },
    },
  ];

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-contracting"
        locked={locked}
        header="Contracting Costs"
        onChange={updateOpenedState}
      >
        <Paper className="t-contracting">
          <Instruction title="" hideTitle>
            <p>
              Click in the ‘Add New Hosting District/Institution’ box and select
              the hosting site that your students attended. Click the Edit icon
              next to the site name and follow the prompts in the pop-up window.
              You may delete the record by clicking on the trash icon. Enter
              additional hosting sites as needed.
            </p>
            <p>
              <span className="u-color-red">Note</span>: Only active CTE
              programs for which student enrollment data have been reported will
              populate in this list. If the program you contracted with does not
              appear, encourage the host entity to complete their data reporting
              so it will become available. Entries with an ellipses (…)
              indicates that items were cutoff and the number in parenthesis (#)
              indicates the total number of items.
            </p>
          </Instruction>
          <SecureWrap
            component={['ctageneral']}
            requireEdit={true}
            isLocked={readOnly}
          >
            <FlexRow>
              <CTAAvailableDistrictsSelectBox
                label="Add New Hosting District/Institution"
                onChange={handleAddDistrict}
                selector={getRecords_CTAContractingAvailableHosts}
                dispatcher={loadCTAContractingAvailableHosts}
              />
            </FlexRow>
          </SecureWrap>
          <Table
            columns={CONTRACTING_COLUMNS}
            conditionalRowStyles={conditionalRowStyles}
            data={ctaContracting}
          />
          {anyCoursesInvalid(ctaContracting) && (
            <p className="u-color-red">
              Not all required course data has been filled out. Edit each
              District/Institution bordered in red to fill out required data.
            </p>
          )}
        </Paper>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
