import {
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_UNIVERSAL_TEACHERS_KEY,
} from 'redux/keys';
import {
  getIsDataFetching,
  shouldRefreshReduxState,
  getLastUpdated,
  getRecords,
} from 'redux/utility/baseSelectors';
import { UniversalContact } from 'types';

export const DATA_COLLECTION_UNIVERSAL_TEACHERS_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_UNIVERSAL_TEACHERS_KEY,
];

export const getRecords_UniversalTeachers = (
  state: any
): UniversalContact[] => {
  return getRecords<UniversalContact>(
    state,
    DATA_COLLECTION_UNIVERSAL_TEACHERS_PATH
  );
};

export const getIsDataFetching_UniversalTeachers = (state: any): boolean => {
  return getIsDataFetching(state, DATA_COLLECTION_UNIVERSAL_TEACHERS_PATH);
};

export const getLastUpdated_UniversalTeachers = (state: any): number => {
  return getLastUpdated(state, DATA_COLLECTION_UNIVERSAL_TEACHERS_PATH);
};

export const shouldRefreshReduxState_UniversalTeachers = (
  state: any
): boolean => {
  return shouldRefreshReduxState<UniversalContact>(
    state,
    DATA_COLLECTION_UNIVERSAL_TEACHERS_PATH,
    300
  );
};
