import { FlexRow } from 'layouts';
import { find, map } from 'lodash';
import React from 'react';
import { ReportItem, SelectedData } from 'types';
import { ReportParamComponent } from '../ReportParam';

type ReportingParametersProps = {
  report: ReportItem;
  onChange: Function;
  valueState: SelectedData[];
};

export const ReportingParametersComponent: React.FC<
  ReportingParametersProps
> = ({ report, onChange, valueState }) => {
  return (
    <FlexRow>
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(50rem, 1fr))',
          gridGap: '1.6rem',
        }}
      >
        {report.parameters.length > 0 ? (
          map(report.parameters, (parameter, index) => {
            return (
              <ReportParamComponent
                key={index}
                onChange={onChange}
                parameter={parameter}
                selectedData={find(valueState, (item) => {
                  return item.name === parameter.name;
                })}
              />
            );
          })
        ) : (
          <p>This report has no options</p>
        )}
      </div>
    </FlexRow>
  );
};
