import React, { useState, useEffect, CSSProperties } from 'react';
import { UniversalContact, emptyUniversalContact } from 'types';
import { map, find } from 'lodash';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
  RenderSuggestionParams,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import {
  getContactFullName,
  getUserEmail,
  extractTeacherInfo,
} from 'util/universalcontact.utility';
import { FlexGroup } from 'layouts';
import { renderPart, getParts, getSuggestions } from 'util/autoSuggest.utility';
import MaxResults from '../MaxResults';
import TeacherCredentials from 'components/UniversalContacts/TeacherCredentials';

type ContactSelectBoxProps = {
  allowFilterDeactivation?: boolean;
  contact?: UniversalContact;
  contacts: UniversalContact[];
  noLabel?: boolean;
  onChange: Function;
  label?: string;
  loadUniversalContacts: Function;
  maxResults?: number;
  shouldReload: boolean;
};

export const ContactSelectBox: React.FC<ContactSelectBoxProps> = (props) => {
  const {
    contact,
    contacts,
    noLabel,
    onChange,
    label,
    loadUniversalContacts,
    maxResults = 8,
    shouldReload,
  } = props;

  const [searchResults, setSearchResults] = useState<UniversalContact[]>([]);
  const [resultText, setResultText] = useState('');
  const [selectedContact, setSelectedContact] = useState<UniversalContact>();

  const boxStyle: CSSProperties = {
    width: '45%',
  };

  /* set the selected contact  */
  useEffect(() => {
    if (selectedContact) {
      const value = find(contacts, (item) => {
        return item.universalContactId === selectedContact.universalContactId;
      });
      setResultText(value ? getContactFullName(value) : '');
    }
  }, [setResultText, contacts, selectedContact]);

  /* set the selected contact  */
  useEffect(() => {
    if (contact) {
      setSelectedContact({ ...contact });
    }
  }, [contact]);

  useEffect(() => {
    if (loadUniversalContacts && shouldReload) {
      loadUniversalContacts();
    }
  }, [loadUniversalContacts, shouldReload]);

  const defaultResult: UniversalContact = {
    ...emptyUniversalContact(),
    firstName: 'No results',
    loginEmail: '',
    universalContactEmail: [],
    schools: [],
    institutes: [],
  };

  const executeSearch = (value: string): UniversalContact[] => {
    setSelectedContact(undefined);
    return getSuggestions(
      contacts,
      value,
      [getContactFullName, getUserEmail],
      maxResults,
      defaultResult
    );
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    const { value } = request;
    if (value === resultText) {
      setSearchResults(executeSearch(value));
    }
  };

  const renderSuggestionsContainer = (
    suggestionParams: RenderSuggestionsContainerParams
  ) => {
    const { containerProps, children } = suggestionParams;
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>Full Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>Email Address</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={contacts.length}
          resultCount={searchResults.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: UniversalContact,
    { query }: RenderSuggestionParams
  ) => {
    const nameParts = getParts(getContactFullName(suggestion), query);
    const mailParts = getParts(getUserEmail(suggestion), query);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div style={{ ...boxStyle, display: 'flex', alignItems: 'center' }}>
            {suggestion.isTeacher && (
              <TeacherCredentials
                teacherInfo={extractTeacherInfo(suggestion)}
              />
            )}
            {map(nameParts, renderPart)}
          </div>
          <div style={boxStyle}>{map(mailParts, renderPart)}</div>
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'down':
      case 'up':
      case 'left':
      case 'right':
        break;
      default:
        setResultText(newValue);
        setSearchResults(executeSearch(newValue));
        break;
    }
  };

  const inputProps = {
    value: resultText,
    onChange: handleChange,
    id: 'contactSelectBox',
  };

  const getSuggestionValue = (suggestion: UniversalContact) => {
    return getContactFullName(suggestion);
  };

  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<UniversalContact>
  ) => {
    const { suggestion } = data;
    onSuggestionsClearRequested();
    setResultText('');
    onChange && onChange(suggestion);
  };

  return (
    <FlexGroup>
      {!noLabel && (
        <label htmlFor="contactSelectBox" className="form__label">
          {label ? label : 'Name of the Contact'}
        </label>
      )}
      <Autosuggest
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={true}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={searchResults}
      />
    </FlexGroup>
  );
};
