import { useValidation } from 'de-formed-validations';
import { every, isEmpty } from 'lodash';
import { CTADistrictRecord } from 'types';

export const CTAAssuranceValidations = () => {
  return useValidation<CTADistrictRecord>({
    canSubmit: [
      {
        errorMessage:
          'Not all assurance information has been provided. Please provide all before submitting for approval.',
        validation: (item: CTADistrictRecord, _) => {
          return every([
            item.assuranceProgramRepresentsDescription,
            item.assuranceCostsMeetRequirements,
            item.assuranceAllProgramActive,
            item.assuranceInstructorsCredentialed,
            !isEmpty(item.contactPhoneNumber),
            !isEmpty(item.districtMailingAddress),
          ]);
        },
      },
      {
        errorMessage: 'Reported cost must be greator than $0.00.',
        validation: (item: CTADistrictRecord, _) => {
          //TODO : retore this to item.reportedCosts > 0 when we decide how to handle reporting
          return true;
        },
      },
    ],
  });
};

// contactPhoneNumber: string;
