import React, { useEffect } from 'react';
import { find, map, filter } from 'lodash';
import { FlexGroup, FlexRow } from 'layouts';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import {
  compose,
  prop,
  renderData,
  mergeObjects,
  converge,
} from 'util/objectUtility';
import {
  ExpenseCategory,
  VoucherExpense,
  VoucherSpendingCategory,
} from 'types';
import { useParams } from 'react-router';
import { sumCollection, addTotals, subtract, add } from 'util/mathHelpers';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import EquipmentDetailsComponent from './EquipmentDetails';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Input from 'components/Common/FormElements/Input';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';
import Tooltip from 'components/Common/Tooltip';

type RecordActualAmountsProps = {
  expenseCategories: ExpenseCategory[];
  loadExpenseCategories: Function;
  onChange: Function;
  spendingCategory: VoucherSpendingCategory;
  isLocked: boolean;
};

export const RecordActualAmountsComponent: React.FC<RecordActualAmountsProps> = (
  props
) => {
  const {
    expenseCategories,
    loadExpenseCategories,
    onChange,
    spendingCategory,
    isLocked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { voucherId: vid, grantFiscalYearId: gfy } = useParams<any>();
  const voucherId = Number(vid);
  const grantFiscalYearId = Number(gfy);

  // --[ component logic ]-----------------------------------------------------
  const handleChange = ({ expenseId }: VoucherExpense) => (
    data: Partial<VoucherExpense>
  ) => {
    const expenses = map(
      spendingCategory.expenses,
      (expense: VoucherExpense) => {
        return expense.expenseId === expenseId
          ? mergeObjects<VoucherExpense>(expense, data)
          : expense;
      }
    );
    onChange({ expenses });
  };

  const navigateToExpense = () => {
    const { strategyId } = spendingCategory;
    const t = window.open(
      `/grants/applications/spending-plans/${grantFiscalYearId}/${strategyId}`,
      '_blank'
    );
    t && t.focus();
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    loadExpenseCategories();
  }, [loadExpenseCategories, voucherId]);

  // --[ display logic ]-------------------------------------------------------
  // getRemainingAmount :: spendingCategory -> number
  // budgetedAmount - (reimbursementRequestedToDate + amountForCurrentVoucher)
  const getRemainingAmount = converge(subtract, [
    prop('budgetedAmount'),
    converge(add, [
      prop('reimbursementRequestedToDate'),
      prop('amountForCurrentVoucher'),
    ]),
  ]);

  // renderTotal :: spendingCategory -> number
  const renderTotal = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    sumCollection,
    addTotals('amountForCurrentVoucher'),
    prop('expenses')
  );

  const getPrimaryExpenses = () =>
    filter(spendingCategory.expenses, { isBackupExpense: false });

  const getBackupExpenses = () =>
    filter(spendingCategory.expenses, { isBackupExpense: true });

  const getFinancialCategoryName = (id: number) => {
    return find(expenseCategories, (cat: ExpenseCategory) => {
      return cat.expenseCategoryId === id;
    });
  };

  const FinancialCategoryName = (
    <div className="u-flex">
      <p>Financial Category</p>
      <Tooltip
        indicator={<Icon name="info" className="custom-table__icon" />}
        tooltip="Reimbursement for travel and equipment expenses have to be requested in full on one voucher. Reimbursement for other and personnel expenses can be requested over multiple vouchers."
      />
    </div>
  );

  const ActualAmountName = (
    <div className="u-flex">
      <p>Actual Amount for Current Voucher Request</p>
      <Tooltip
        indicator={<Icon name="info" className="custom-table__icon" />}
        tooltip="You can ask for reimbursement over the amount remaining. However, the total voucher request cannot exceed the total grant funds available to your institution and is subject to approval."
        place="right"
      />
    </div>
  );

  // TODO: add helpers to define financialCategoryId
  // TODO: firstMatch replace
  const isDisabled = (row: VoucherExpense) => {
    return row.financialCategoryId === 4 || row.financialCategoryId === 3
      ? row.reimbursementRequestedToDate > 0
      : false;
  };

  const renderActualAmount = (row: VoucherExpense) => {
    return (
      <SecureWrap
        isLocked={isLocked}
        component={['GrantsVouchers', 'GrantsReimbursementOverride']}
      >
        <Input
          className="form__input u-align-right"
          disabled={isDisabled(row)}
          key={row.expenseId}
          min={0}
          name="amountForCurrentVoucher"
          noLabel={true}
          onChange={handleChange(row)}
          type="number"
          value={row.amountForCurrentVoucher}
        />
      </SecureWrap>
    );
  };

  const ExpenseIcon = () => {
    return (
      <React.Fragment>
        <IconButton
          className={'u-sec-ro--override'}
          name="launchOpen"
          tooltip="View Details & Edit"
          asLink={false}
          onClick={navigateToExpense}
        />
      </React.Fragment>
    );
  };

  const EXPENSE_COLUMNS: TableColumnProps[] = [
    {
      name: 'ID#',
      cell: compose(renderData, prop('expenseId')),
      style: {
        width: '5%',
      },
    },
    {
      name: 'Expense Name',
      cell: compose(renderData, prop('expenseName')),
      style: {
        width: '30%',
      },
    },
    {
      name: 'Financial Category',
      label: FinancialCategoryName,
      cell: compose(
        renderData,
        prop('expenseCategoryName'),
        getFinancialCategoryName,
        prop('financialCategoryId')
      ),
      style: {
        width: '10%',
      },
    },
    {
      name: 'Reimbursement Requested To Date',
      cell: compose(
        renderData,
        insertDollarSign,
        formatMoney,
        prop('reimbursementRequestedToDate')
      ),
      style: {
        width: '15%',
        textAlign: 'right',
      },
    },
    {
      name: 'Amount Remaining for Reimbursement',
      cell: compose(
        renderData,
        insertDollarSign,
        formatMoney,
        getRemainingAmount
      ),
      style: {
        width: '15%',
        textAlign: 'right',
      },
    },
    {
      name: 'Actual Amount',
      label: ActualAmountName,
      cell: renderActualAmount,
      style: {
        width: '20%',
        marginLeft: '2rem',
        textAlign: 'right',
      },
    },
    {
      name: 'View Detail',
      cell: ExpenseIcon,
      center: true,
      style: {
        width: '5%',
      },
    },
  ];

  const expandableRow = (row: VoucherExpense) => {
    return row.financialCategoryId === 4 ? (
      <EquipmentDetailsComponent
        onChange={handleChange(row)}
        row={row}
        disabled={isDisabled(row)}
      />
    ) : null;
  };

  return (
    <div style={{ position: 'relative' }}>
      <Instruction title="Record Actual Amounts">
        <p>
          Enter amounts to request for primary and backup expenses during this
          voucher period. Use “Actual Amount For Current Voucher Request” to
          enter the total you are requesting. The total you have requested will
          show in “Total Actual Amounts” at the top of this section. Request
          primary expenses first, then back up expenses. Use a comma or new line
          to separate equipment expenses with many locations, serial numbers, or
          invoice numbers. You must request full reimbursement for travel and
          equipment expenses on one voucher. You can request other and personnel
          expenses over many vouchers.
        </p>
      </Instruction>
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          textAlign: 'right',
          width: '20rem',
        }}
      >
        <FlexGroup>
          <p className="form__label">Total Actual Amounts</p>
          <p className="input-display">{renderTotal(spendingCategory)}</p>
        </FlexGroup>
      </div>
      <FlexRow>
        <FlexGroup>
          <h2>Primary Expenses</h2>
          <Table
            columns={EXPENSE_COLUMNS}
            data={getPrimaryExpenses()}
            expandableRow={expandableRow}
            useRowExpander
            tableRowKey="expenseId"
          />
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <h2>Backup Expenses</h2>
          <Table
            columns={EXPENSE_COLUMNS}
            data={getBackupExpenses()}
            expandableRow={expandableRow}
            useRowExpander
            tableRowKey="expenseId"
          />
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
