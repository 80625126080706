import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DistrictReporting from 'components/CTA/DistrictReporting';
import CTADistrictLayout from 'layouts/CTADistrict';

export const DistrictPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <CTADistrictLayout {...props}>
        <DistrictReporting {...props} />
      </CTADistrictLayout>
    </BaseLayout>
  );
};
