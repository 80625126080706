import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import Articulation from 'components/Program/Articulation';

export const ArticulationPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <Articulation {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};
