import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readReimbursementRequestSummaryData } from 'services/grants/vouchers.services';
import { ReimbursementSummary } from 'types';

const initialState = baseInitialState;

export const loadReimbursementRequestSummaryData = createActionThunk(
  'loadReimbursementRequestSummaryData',
  (fiscalYearId: number) => {
    return readReimbursementRequestSummaryData(fiscalYearId);
  }
);

export default handleActions<
  BaseStateObject,
  ReimbursementSummary | ReimbursementSummary[]
>(
  {
    ...baseLoadActionBuilder<ReimbursementSummary | ReimbursementSummary[]>(
      loadReimbursementRequestSummaryData
    ),
  },
  initialState
);
