import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/_exports';
import { Department } from 'types';
import { readDepartments } from 'services/common/districtinstituteschool.services';

const initialState = baseInitialState;

export const loadDepartments = createActionThunk('loadDepartments', () => {
  return readDepartments();
}); // end

export default handleActions<BaseStateObject, Department[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<Department[]>(loadDepartments),
  },
  initialState
);
