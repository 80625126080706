export const WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS =
  '[WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS]';

export const SET_WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS = `${WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS} SET`;
export const SET_WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_LOADING = `${WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS} LOADING`;

export const setWorkBasedLearningAddressableElements = (payload: any) => {
  return {
    type: SET_WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_WorkBasedLearningAddressableElements = (
  payload: boolean
) => {
  return {
    type: SET_WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
