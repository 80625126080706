export const COLOR_BLUE = '#007d89';
export const COLOR_RED = '#bb3131';
export const COLOR_LIGHT_RED = '#FFE5E5';
export const COLOR_LIGHT_GREEN = '#ECF6E5';
export const COLOR_LIGHT_ORANGE = 'rgba(242, 109, 32, .2)';
export const COLOR_HIGH_LIGHT = '#e8f6f7';
export const BACKGROUND_RED_GRADIENT =
  'linear-gradient(165deg, rgba(255, 229, 229, 1) 0%, rgba(255, 229, 229, .75) 100%)';

export enum CHART_COLOR {
  TYPE_1 = '#a97d2e',
  TYPE_2 = '#c20000',
  TYPE_3 = '#c9510c',
  TYPE_4 = '#53832f',
  TYPE_5 = '#13838e',
  TYPE_6 = '#1d5d90',
  TYPE_7 = '#e60078',
  TYPE_8 = '#2f0089',
  TYPE_9 = '#4e5758',
}
