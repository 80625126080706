import React from 'react';
import { ProgramContactDeep, ProgramContact } from 'types';
import { mergeObjects } from 'util/objectUtility';
import { FlexRow } from 'layouts';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';

type ProgramAdvisoryChairFormProps = {
  chair: ProgramContactDeep;
  onChange: Function;
  validations: ValidationObject<ProgramContact>;
};

export const ProgramAdvisoryChairForm: React.FC<
  ProgramAdvisoryChairFormProps
> = (props) => {
  const { chair, onChange, validations } = props;

  const { getError, getFieldValid, validate, validateIfTrue } = validations;

  const handleFormChange = (
    data: Partial<ProgramContactDeep>,
    key: keyof ProgramContactDeep,
    value: any
  ) => {
    validateIfTrue(key as any, value);
    onChange(mergeObjects<ProgramContactDeep>(chair, data));
  };

  return (
    <div className="form__section">
      <Instruction title="Program Advisory Chair">
        <p>
          All fields are required. The Program Advisory Chair listed must be a
          representative from the business or industry.
        </p>
      </Instruction>
      <FlexRow>
        <Input
          name="advisorFirstName"
          label="First Name"
          value={chair.advisorFirstName}
          onChange={handleFormChange}
          errorMessage={getError('advisorFirstName')}
          onBlur={() => {
            validate('advisorFirstName', chair.advisorFirstName);
          }}
          valid={getFieldValid('advisorFirstName')}
        />
        <Input
          name="advisorLastName"
          label="Last Name"
          value={chair.advisorLastName}
          onChange={handleFormChange}
          errorMessage={getError('advisorLastName')}
          onBlur={() => {
            validate('advisorLastName', chair.advisorLastName);
          }}
          valid={getFieldValid('advisorLastName')}
        />
      </FlexRow>
      <FlexRow>
        <Input
          name="advisorTitle"
          label="Title"
          value={chair.advisorTitle}
          onChange={handleFormChange}
          errorMessage={getError('advisorTitle')}
          onBlur={() => {
            validate('advisorTitle', chair.advisorTitle);
          }}
          valid={getFieldValid('advisorTitle')}
        />
        <Input
          name="advisorOrganization"
          label="Organization"
          value={chair.advisorOrganization}
          onChange={handleFormChange}
          errorMessage={getError('advisorOrganization')}
          onBlur={() => {
            validate('advisorOrganization', chair.advisorOrganization);
          }}
          valid={getFieldValid('advisorOrganization')}
        />
      </FlexRow>
      <FlexRow>
        <Input
          name="advisorEmail"
          label="Email"
          value={chair.advisorEmail}
          onChange={handleFormChange}
          errorMessage={getError('advisorEmail')}
          onBlur={() => {
            validate('advisorEmail', chair.advisorEmail);
          }}
          valid={getFieldValid('advisorEmail')}
        />
        <div className="form__group" />
      </FlexRow>
    </div>
  );
};
