import { basetype } from 'types';

export interface Program extends basetype {
  advancedDegrees: string;
  awardCoursesString: string;
  awardsString: string;
  bachelorDegrees: string;
  careerClusterId: number;
  cipId: string;
  careerSectorId: number;
  cipJustification: string;
  collaboration: string;
  ctsO_IDs: string[];
  currentWorkflowStatusId: number;
  districtId: number;
  dueDate: Date;
  employmentDemandInformation: string;
  endDate: Date;
  expirationDate: Date;
  industryCertifications: string;
  isActive: boolean;
  modifiedBy: number;
  occupationInfoProgramCompletion: string;
  occupationInfo2YearCert: string;
  occupationInfoAdvanceDegree: string;
  onetReviewed: boolean;
  programBaseId: number;
  pathwayCoursesString: string;
  pathwaysString: string;
  programDescription: string;
  programInstanceId: number;
  programLengthId: number;
  programLevelId: number;
  programName: string;
  programRequirement: string;
  programStateId: number;
  programStateName: string;
  requestorContactId: number;
  schoolId: number;
  schoolDepartmentId: number;
  startDate: Date;
  terms: number;
  workBasedLearning: string;
  workflowStatus: string;
  isCccsCollege: boolean;
}

export const emptyProgram = (): Program => {
  return {
    advancedDegrees: '',
    awardCoursesString: '',
    awardsString: '',
    bachelorDegrees: '',
    careerClusterId: -1,
    cipId: '',
    cipJustification: '',
    collaboration: '',
    createdDate: new Date(Date.now()),
    ctsO_IDs: [],
    currentWorkflowStatusId: -1,
    districtId: -1,
    dueDate: new Date(Date.now()),
    employmentDemandInformation: '',
    endDate: new Date(Date.now()),
    expirationDate: new Date(Date.now()),
    industryCertifications: '',
    isActive: true,
    modifiedBy: -1,
    modifiedDate: new Date(Date.now()),
    onetReviewed: false,
    occupationInfoProgramCompletion: '',
    occupationInfo2YearCert: '',
    occupationInfoAdvanceDegree: '',
    pathwayCoursesString: '',
    pathwaysString: '',
    careerSectorId: 0,
    programBaseId: -1,
    programDescription: '',
    programInstanceId: -1,
    programLevelId: 255,
    programLengthId: 255,
    programName: '',
    programRequirement: '',
    programStateId: -1,
    programStateName: '',
    schoolId: -1,
    schoolDepartmentId: -1,
    startDate: new Date(Date.now()),
    requestorContactId: -1,
    terms: 0,
    workBasedLearning: '',
    workflowStatus: '',
    isCccsCollege: false,
  };
};
