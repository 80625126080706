import React from 'react';
import {
  readGrantWorkflow,
  createGrantWorkflowUpdate,
} from 'services/grants/grantworkflow.services';
import {
  setFourYearWorkflow,
  setFourYearWorkflowLoading,
} from 'redux/generalworkflow/fouryearworkflow.reducer';
import {
  setTwoYearWorkflow,
  setTwoYearWorkflowLoading,
} from 'redux/generalworkflow/twoyearworkflow.reducer';
import {
  FETCH_WORKFLOW,
  SAVE_WORKFLOW_UPDATE,
  loadWorkFlow,
  SAVE_PROGRAM_APPROVAL,
  SAVE_PROGRAM_UPDATE,
  SAVE_CTA_UPDATE,
} from 'redux/generalworkflow/workflow.actions';
import { toast } from 'react-toastify';
import { catchError } from 'services';
import ToastNotification from 'components/Common/Toast';
import {
  setAnnualSpendingPlanWorkflowLoading,
  setAnnualSpendingPlanWorkflow,
} from 'redux/generalworkflow/annualspendingplan.reducer';
import {
  setAssuranceSignatureWorkflow,
  setAssuranceSignatureWorkflowLoading,
} from 'redux/generalworkflow/assurancesignatureworkflow.reducer';
import {
  setAnnualReflectionWorkflowLoading,
  setAnnualReflectionWorkflow,
} from 'redux/generalworkflow/annualreflection.reducer';
import {
  setAwardPacketWorkflowLoading,
  setAwardPacketWorkflow,
} from 'redux/generalworkflow/awardpacket.reducer';
import {
  setVoucherWorkflowLoading,
  setVoucherWorkflow,
} from 'redux/generalworkflow/voucherworkflow.reducer';
import {
  setImprovementPlanWorkflowLoading,
  setImprovementPlanWorkflow,
} from 'redux/generalworkflow/improvementplanworkflow.reducer';
import {
  setProgramWorkflow,
  setProgramWorkflowLoading,
} from 'redux/generalworkflow/programworkflow.reducer';
import { WorkflowReduxAction, WorkflowReduxEntities } from 'types';
import {
  createWorkflowApproval,
  readProgramApprovals,
  createWorkflowUpdate,
} from 'services/workflow/programworkflow.service';
import {
  setctaWorkflow,
  setctaWorkflowLoading,
} from 'redux/generalworkflow/ctaworkflow.reducer';
import {
  createCtaWorkflowUpdate,
  readCtaWorkflow,
} from 'services/cta/ctaApprovals.services';

export const workflowMiddleware = (store: any) => (next: Function) => (
  action: WorkflowReduxAction
) => {
  const { dispatch } = store;
  next(action);

  if (action.type === FETCH_WORKFLOW) {
    const { entityType, entityId } = action.payload;
    const fourYearPlan = () => {
      dispatch(setFourYearWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setFourYearWorkflow(res)))
        .finally(() => dispatch(setFourYearWorkflowLoading(false)));
    };
    const twoYearPlan = () => {
      dispatch(setTwoYearWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setTwoYearWorkflow(res)))
        .finally(() => dispatch(setTwoYearWorkflowLoading(false)));
    };
    const spendingPlans = () => {
      dispatch(setAnnualSpendingPlanWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setAnnualSpendingPlanWorkflow(res)))
        .finally(() => dispatch(setAnnualSpendingPlanWorkflowLoading(false)));
    };
    const assuranceSignature = () => {
      dispatch(setAssuranceSignatureWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setAssuranceSignatureWorkflow(res)))
        .finally(() => dispatch(setAssuranceSignatureWorkflowLoading(false)));
    };
    const annualReflection = () => {
      dispatch(setAnnualReflectionWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setAnnualReflectionWorkflow(res)))
        .finally(() => dispatch(setAnnualReflectionWorkflowLoading(false)));
    };
    const improvementPlan = () => {
      dispatch(setImprovementPlanWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setImprovementPlanWorkflow(res)))
        .finally(() => dispatch(setImprovementPlanWorkflowLoading(false)));
    };
    const awardPacket = () => {
      dispatch(setAwardPacketWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setAwardPacketWorkflow(res)))
        .finally(() => dispatch(setAwardPacketWorkflowLoading(false)));
    };
    const voucher = () => {
      dispatch(setVoucherWorkflowLoading(true));
      return readGrantWorkflow(entityType, entityId)
        .then((res: any) => dispatch(setVoucherWorkflow(res)))
        .finally(() => dispatch(setVoucherWorkflowLoading(false)));
    };
    const programs = () => {
      dispatch(setProgramWorkflowLoading(true));
      return readProgramApprovals(entityId)
        .then((res: any) => dispatch(setProgramWorkflow(res)))
        .finally(() => dispatch(setProgramWorkflowLoading(false)));
    };

    const cta = () => {
      dispatch(setctaWorkflowLoading(true));
      return readCtaWorkflow(entityId)
        .then((res: any) => dispatch(setctaWorkflow(res)))
        .finally(() => dispatch(setctaWorkflowLoading(false)));
    };

    const options: WorkflowReduxEntities = {
      cta: cta,
      programs: programs,
      'four-year-plan': fourYearPlan,
      'two-year-plan': twoYearPlan,
      'spending-plans': spendingPlans,
      'assurance-signature': assuranceSignature,
      'award-packet': awardPacket,
      'improvement-plan': improvementPlan,
      'annual-reflection': annualReflection,
      'voucher-one': voucher,
      'voucher-two': voucher,
      'voucher-three': voucher,
      'final-voucher': voucher,
    };

    return options[entityType] && options[entityType]();
  }

  if (action.type === SAVE_PROGRAM_APPROVAL) {
    const { programInstanceId, comment } = action.payload as any;
    dispatch(setProgramWorkflowLoading(true));
    createWorkflowApproval(programInstanceId, comment)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message="Program submitted successfully"
          />
        );
      })
      .then(() => dispatch(loadWorkFlow('programs', programInstanceId)))
      .catch((err: any) => catchError('saveProgramApproval', err))
      .finally(() => {
        dispatch(setProgramWorkflowLoading(false));
      });
  }

  if (action.type === SAVE_PROGRAM_UPDATE) {
    const { programInstanceId, submission } = action.payload as any;
    dispatch(setProgramWorkflowLoading(true));
    createWorkflowUpdate(programInstanceId, submission)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message="Program workflow updated successfully"
          />
        );
      })
      .then(() => dispatch(loadWorkFlow('programs', programInstanceId)))
      .catch((err: any) => catchError('saveProgramUpdate', err))
      .finally(() => {
        dispatch(setProgramWorkflowLoading(false));
      });
  }

  if (action.type === SAVE_CTA_UPDATE) {
    const { ctaId, submission } = action.payload as any;
    dispatch(setctaWorkflowLoading(true));
    createCtaWorkflowUpdate(ctaId, submission)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message="CTA Report workflow updated successfully"
          />
        );
      })
      .then(() => dispatch(loadWorkFlow('cta', ctaId)))
      .catch((err: any) => catchError('createCtaWorkflowUpdate', err))
      .finally(() => {
        dispatch(setctaWorkflowLoading(false));
      });
  }

  if (action.type === SAVE_WORKFLOW_UPDATE) {
    const { entityType, entityId, submission } = action.payload;
    const options: any = {
      'four-year-plan': setFourYearWorkflowLoading,
      'two-year-plan': setTwoYearWorkflowLoading,
      'spending-plans': setAnnualSpendingPlanWorkflowLoading,
      'assurance-signature': setAssuranceSignatureWorkflowLoading,
      'award-packet': setAwardPacketWorkflowLoading,
      'improvement-plan': setImprovementPlanWorkflowLoading,
      'annual-reflection': setAnnualReflectionWorkflowLoading,
      'voucher-one': setVoucherWorkflowLoading,
      'voucher-two': setVoucherWorkflowLoading,
      'voucher-three': setVoucherWorkflowLoading,
      'final-voucher': setVoucherWorkflowLoading,
    };
    if (options[entityType]) {
      dispatch(options[entityType as any](true));
      createGrantWorkflowUpdate(entityType, entityId, submission)
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message="Application updated successfully"
            />
          );
        })
        .catch((err: any) => catchError('submitApplicationUpdate', err))
        .finally(() => {
          dispatch(options[entityType as any](false));
          dispatch(loadWorkFlow(entityType, entityId));
        });
    }
  }
};
