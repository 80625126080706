import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
  toFileBlob,
} from 'services';
import { ReportItem, SelectedData } from 'types';

const BASE_URL = ['api', 'reporting'];

export const readReportList = async (): Promise<ReportItem[]> => {
  return fetch(getFormattedUrl([...BASE_URL, 'reportlist']), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Reporting', err, 'readReportList');
    });
};

export const readWebReport = async (name: string, report: ReportItem): Promise<ReportItem> => {
  return fetch(getFormattedUrl([...BASE_URL, 'dataview', name]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(report),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Reporting', err, 'readWebReport');
    });
};

export const readPublicReportList = async (): Promise<ReportItem[]> => {
  return fetch(getFormattedUrl([...BASE_URL, 'publicreportlist']), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Reporting', err, 'readPublicReportList');
    });
};

export const readExcelReport = async (name: string, params: ReportItem) => {
  return fetch(getFormattedUrl([...BASE_URL, 'exceldownload', name]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(params),
  })
    .then(toFileBlob)
    .catch((err) => {
      return catchError('Reporting', err, 'readExcelReport');
    });
};

export const readPublicExcelReport = async (name: string, params: ReportItem) => {
  return fetch(getFormattedUrl([...BASE_URL, 'publicexceldownload', name]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(params),
  })
    .then(toFileBlob)
    .catch((err) => {
      return catchError('Reporting', err, 'readExcelReport');
    });
};

export const readPublicWebReport = async (
  name: string,
  params: SelectedData[]
): Promise<ReportItem> => {
  return fetch(getFormattedUrl([...BASE_URL, 'publicdataview', name]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(params),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Reporting', err, 'readPublicWebReport');
    });
};
