import { PROGRAMS_KEY, PROGRAM_ASSURANCE_QUESTIONS_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { map } from 'lodash';

import {
  getIsDataFetching,
  getRecords,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { QuestionGroup } from 'types';

/* Base selectors */

export const ASSURANCE_QUESTIONS_PATH: string[] = [
  PROGRAMS_KEY,
  PROGRAM_ASSURANCE_QUESTIONS_KEY,
];

export const getIsDataFetching_ProgramAssuranceGroups = (
  state: any
): boolean => {
  return getIsDataFetching(state, ASSURANCE_QUESTIONS_PATH);
};

export const getLastUpdated_ProgramAssuranceGroups = (state: any): number => {
  return getLastUpdated(state, ASSURANCE_QUESTIONS_PATH);
};

export const getRecords_ProgramAssuranceGroups = (
  state: any
): QuestionGroup[] => {
  return getRecords<QuestionGroup>(state, ASSURANCE_QUESTIONS_PATH);
};

/* End Base selectors */

export const getAssuranceQuestionGroups = (state: ApplicationState): any[] => {
  const serviceGroups = getRecords_ProgramAssuranceGroups(state);

  return map(serviceGroups, (group) => {
    return map(group, (question) => {
      return undefined;
    });
  });
};
