import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { FlexRow, FlexGroup } from 'layouts';
import { useDispatch } from 'react-redux';
import { loadImprovementPlan } from 'redux/grants/improvementplan';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { SingleDeficiencySubmitValidations } from 'validations/ImprovementPlan/SingleDeficiencySubmit.validations';
import { BasicGrantSignatureValidations } from 'validations/BasicGrantSignature.validations';
import { every } from 'lodash';
import { ImprovementPlan } from 'types';
import { QuestionValidation } from 'validations/Question.validations';
import Paper from 'components/Common/Paper';

type ImprovementOverviewProps = {
  setCurrentNavAnchor: Function;
  plan: ImprovementPlan;
};

export const ImprovementOverview: React.FC<ImprovementOverviewProps> = (
  props
) => {
  const { setCurrentNavAnchor, plan } = props;
  const { grantFiscalYearId: gfid }: any = useParams();
  const grantFiscalYearId = Number(gfid);

  const [singleValid, setSingleValid] = useState<boolean>(false);
  const [multiValid, setMultiValid] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { validate } = SingleDeficiencySubmitValidations();
  const { validate: sigVal } = BasicGrantSignatureValidations();
  const { validate: qvalidation } = QuestionValidation();

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadImprovementPlan(grantFiscalYearId));
    }
  }, [grantFiscalYearId, dispatch]);

  useEffect(() => {
    if (plan.hasMultiYearDeficiencies) {
      setMultiValid(
        every([
          sigVal('hasAllRequiredFields', [plan.multiYearSignature]),
          qvalidation('allQuestionsAnswered', plan.multiYearQuestions),
        ])
      );
    }
    setSingleValid(validate('areQuestionsAnswered', plan));
  }, [plan]); // eslint-disable-line

  return (
    <Paper>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('improvement-plan')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <h4>Improvement Plan Questions</h4>
          <p>
            Collection Status:{' '}
            {singleValid ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>

      {plan.hasMultiYearDeficiencies && (
        <FlexRow>
          <FlexGroup>
            <h4>Improvement Plan Year over Year Questions and Signature</h4>
            <p>
              Collection Status:{' '}
              {multiValid ? (
                <span className="u-color-green">Complete</span>
              ) : (
                <span className="u-color-orange-dark">In Progress</span>
              )}
            </p>
          </FlexGroup>
        </FlexRow>
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('improvement-plan')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
