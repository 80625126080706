import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import ContactFormPage from 'pages/UniversalContacts/ContactFormPage';
import Dashboard from 'pages/UniversalContacts/Dashboard';

const contactRoutes = [
  <Route
    exact
    path="/contacts"
    component={authentication.required(Dashboard)}
  />,
  <Route
    exact
    path="/contacts/:userId"
    component={authentication.required(ContactFormPage)}
  />,
];

export default contactRoutes;
