import { useValidation } from 'de-formed-validations';
import { AdminBooksAndSupplies } from 'types';

export const AdminBooksSupplyValidations = () => {
  return useValidation<AdminBooksAndSupplies>({
    booksAndSuppliesTotalCost: [
      {
        errorMessage: 'Value must be less than or equal to $100,000',
        validation: (val: number, _) => {
          return val <= 100000;
        },
      },
    ],

    dctsTotalCost: [
      {
        errorMessage: 'Value must be less than or equal to $100,000',
        validation: (val: number, _) => {
          return val <= 100000;
        },
      },
    ],
  });
};
