import React from 'react';
import { every, some } from 'lodash';
import { SpendingCategory, AnnualSpending } from 'types';
import { useParams, Link } from 'react-router-dom';
import { truncateString } from 'util/global';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { useDispatch } from 'react-redux';
import { saveAnnualSpendingPlan } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import {
  getAllocatedFundsRemaining,
  isAdminCategory,
  calculateAvailableIndirectFunds,
  calculateAvailableDirectFunds,
  getMinImprovementAllocation,
  getSpendingCategoryFundsRemaining,
  isIndirectAdminCosts,
  isDirectAdminCosts,
  isImprovementPlan,
} from 'util/annualSpendingUtility';
import { isEqual } from 'util/formHelpers';
import { compose, safeGet } from 'util/objectUtility';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Input from 'components/Common/FormElements/Input';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export type SpendingCategoryProps = {
  disabled?: boolean;
  index?: number;
  isDirty?: boolean;
  isLocked: boolean;
  onChange: Function;
  onDelete?: Function;
  showLinks?: boolean;
  spendingCategory: SpendingCategory;
  spendingPlan: AnnualSpending;
  tooltip?: JSX.Element;
  validationStatus?: boolean;
};

export const SpendingCategoryComponent: React.FC<SpendingCategoryProps> = (
  props
) => {
  const {
    disabled = false,
    index,
    isDirty = false,
    isLocked,
    onChange,
    onDelete,
    showLinks = true,
    spendingCategory,
    spendingPlan,
    tooltip,
    validationStatus = null,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { grantFiscalYearId: id }: any = useParams<any>();
  const grantFiscalYearId = Number(id);

  // --[ component logic ]-----------------------------------------------------
  // get :: string -> SpendingCategory[string]
  const get = safeGet(spendingCategory);

  // saveData :: () -> void
  const saveData = () => {
    isDirty && dispatch(saveAnnualSpendingPlan(spendingPlan));
  };

  // isValid :: () -> boolean
  const isValid = () => {
    return validationStatus !== null
      ? validationStatus
      : getAllocatedFundsRemaining(spendingCategory) >= 0;
  };

  // --[ variables ]-----------------------------------------------------------
  // isHeader determines if the spending category row should have labels
  const isHeader: boolean = every([index !== undefined && isEqual(0, index)]);
  // to supplies the nav link locations to the strategies
  const to: string = `/grants/applications/spending-plans/${grantFiscalYearId}/${get(
    'strategyId'
  )}`;

  // --[ render logic ]--------------------------------------------------------
  // fundsRemaining :: SpendingCategory -> string
  const fundsRemaining: Function = compose(
    insertDollarSign,
    formatMoney,
    getSpendingCategoryFundsRemaining
  );

  // fundsRemainingClass :: () -> string
  const fundsRemainingClass = () => {
    return isValid()
      ? 'input-display u-align-right'
      : 'input-display u-color-red u-align-right';
  };

  // descriptionClass :: () -> string
  const descriptionClass = () => {
    return isAdminCategory(spendingCategory)
      ? 'spending-category__description spending-category__description--admin'
      : 'spending-category__description';
  };

  return (
    <div className="spending-category__container">
      {isHeader && (
        <div className="spending-category">
          <div className="spending-category__header-spacer">&nbsp;</div>
          <div className="spending-category__fields">
            {isIndirectAdminCosts(spendingCategory) && (
              <p className="spending-category__field spending-category__field--header spending-category__field--indirect">
                Indirect Rate
              </p>
            )}
            {some([
              isIndirectAdminCosts(spendingCategory),
              isDirectAdminCosts(spendingCategory),
            ]) && (
              <p className="spending-category__field spending-category__field--header">
                Available Funds
              </p>
            )}
            {isImprovementPlan(spendingCategory) && (
              <p className="spending-category__field spending-category__field--header">
                Minimum Allocation
              </p>
            )}
            {!isIndirectAdminCosts(spendingCategory) ? (
              <p className="spending-category__field spending-category__field--header">
                Funds Remaining
              </p>
            ) : (
              <p className="spending-category__field spending-category__field--header" />
            )}
            <p className="spending-category__field spending-category__field--header">
              Funds Allocated
            </p>
          </div>
        </div>
      )}

      <div className="spending-category">
        <div className="spending-category__name">
          {every([showLinks, !isIndirectAdminCosts(spendingCategory)]) ? (
            <Link to={to}>
              <div className={descriptionClass()} onClick={saveData}>
                <p>{truncateString(get('strategy'), 120)}</p>
                {tooltip && tooltip}
              </div>
            </Link>
          ) : (
            <div className={descriptionClass()}>
              <p>{truncateString(get('strategy'), 120)}</p>
              {tooltip && tooltip}
            </div>
          )}
        </div>
        <div className="spending-category__fields">
          <SecureWrap isLocked={isLocked} component={['grantsannualspending']}>
            {every([
              !isLocked,
              onDelete,
              !isImprovementPlan(spendingCategory),
            ]) && (
              <div className="spending-category__delete">
                <ConfirmationDialog
                  tooltip="Delete"
                  header="Delete Records"
                  onConfirm={() => onDelete!(get('strategyId'))}
                >
                  <p>Are you sure you wish to delete this item?</p>
                  <p className="u-error">
                    Caution: this action will also delete all expenses
                    associated with the group.
                  </p>
                </ConfirmationDialog>
              </div>
            )}
          </SecureWrap>
          {isAdminCategory(spendingCategory) && (
            <React.Fragment>
              {isIndirectAdminCosts(spendingCategory) && (
                <div className="spending-category__field spending-category__field--indirect">
                  <p className="input-display u-align-right">
                    {(spendingPlan.indirectRate * 100).toFixed(2)}%
                  </p>
                </div>
              )}
              {isIndirectAdminCosts(spendingCategory) && (
                <div className="spending-category__field">
                  <p className="input-display u-align-right">
                    $
                    {formatMoney(calculateAvailableIndirectFunds(spendingPlan))}
                  </p>
                </div>
              )}
              {isDirectAdminCosts(spendingCategory) && (
                <div className="spending-category__field">
                  <p className="input-display u-align-right">
                    $
                    {formatMoney(
                      calculateAvailableDirectFunds(spendingPlan),
                      false
                    )}
                  </p>
                </div>
              )}
            </React.Fragment>
          )}
          {isImprovementPlan(spendingCategory) && (
            <div className="spending-category__field">
              <p className="input-display u-align-right">
                ${formatMoney(getMinImprovementAllocation(spendingPlan))}
              </p>
            </div>
          )}
          {isIndirectAdminCosts(spendingCategory) ? (
            <div className="spending-category__field" />
          ) : (
            <div className="spending-category__field">
              <p className={fundsRemainingClass()}>
                {fundsRemaining(spendingCategory)}
              </p>
            </div>
          )}
          <div className="spending-category__field">
            <SecureWrap
              isLocked={isLocked}
              component={['grantsannualspending']}
            >
              {disabled ? (
                <Input
                  className="input-display u-align-right"
                  disabled={disabled}
                  name="allocatedFunds"
                  noLabel={true}
                  onChange={onChange(spendingCategory)}
                  value={`$${formatMoney(get('allocatedFunds'))}`}
                />
              ) : (
                <Input
                  className="spending-category__input"
                  name="allocatedFunds"
                  noLabel={true}
                  onChange={onChange(spendingCategory)}
                  type="number"
                  min={0}
                  max={500000000}
                  valid={isValid()}
                  value={get('allocatedFunds')}
                />
              )}
            </SecureWrap>
          </div>
        </div>
      </div>
    </div>
  );
};
