import React from 'react';
import { FlexRow } from 'layouts';
import { IntentToParticipateFiscalAgent } from 'types';
import { renderData } from 'util/objectUtility';
import Input from 'components/Common/FormElements/Input';

type ConsortiumOverviewProps = {
  fiscalAgentData: IntentToParticipateFiscalAgent;
};

export const ConsortiumOverview: React.FC<ConsortiumOverviewProps> = (
  props
) => {
  const { fiscalAgentData } = props;

  return (
    <React.Fragment>
      <FlexRow>
        <Input
          disabled={true}
          className="input-display"
          name="entityName"
          label="Name of the Consortium"
          value={renderData(fiscalAgentData.consortiumName)}
        />
        <Input
          disabled={true}
          className="input-display"
          name="entityName"
          label="Name of Fiscal Agent"
          value={renderData(fiscalAgentData.fiscalAgentName)}
        />
        <Input
          disabled={true}
          className="input-display"
          name="entityName"
          label="Status of Consortium"
          value={renderData(
            fiscalAgentData.consortiumActive ? 'Active' : 'Inactive'
          )}
        />
      </FlexRow>
    </React.Fragment>
  );
};
