import React from 'react';
import { useParams } from 'react-router';
import { SideNavData } from 'types/ui/SideNavData.types';
import SideNav from 'components/Common/SideNav';

type ProgramStepSideNavProps = {
  currentStep: string;
  navTrayClosed: boolean;
  currentNavAchor: string;
  navData: SideNavData;
  setNavTrayClosed: Function;
};

export const FiscalYearSideNav: React.FC<ProgramStepSideNavProps> = (props) => {
  const { navData } = props;

  const { grantFiscalYearId: cId }: any = useParams();
  const grantFiscalYearId = Number(cId);

  return (
    <SideNav
      actionButton={undefined}
      navagationParameters={[grantFiscalYearId]}
      iconName="documents"
      navData={navData}
      titleBarColor={navData.scrollNav ? 'blue' : 'orange'}
    />
  );
};
