import React from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import { FormType, CTAEquipment, CTAEquipmentCTEProgram } from 'types';
import { safeGet, mergeObjects } from 'util/objectUtility';
import CTASchoolAndProgramSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTASchoolAndProgramSelectBox';
import Input from 'components/Common/FormElements/Input';

export const EquipmentSchoolProgramAffiliationForm: React.FC<
  FormType<CTAEquipmentCTEProgram>
> = ({ data, onChange }) => {
  // -- component logic --
  const handleChange = (item: Partial<CTAEquipment>) => {
    const updated = mergeObjects(data, item);
    onChange(updated);
  };

  // -- display logic --
  const get = safeGet(data);
  return (
    <>
      <FlexGroup>
        <FlexRow>
          <CTASchoolAndProgramSelectBox
            label="School / Program"
            onChange={handleChange}
            ctaProgramId={get('ctaProgramId')}
          />
          <Input
            name="cost"
            onChange={handleChange}
            label="Total Cost Applied to this Program"
            value={get('cost')}
          />
          <div style={{ width: '3rem' }} />
        </FlexRow>
      </FlexGroup>
    </>
  );
};
