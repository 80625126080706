import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { GeneralSummary } from 'components/Grants/Applications/General/GeneralSummary.component';
import { getGrantFiscalYear, getConsortium } from 'redux/grants/_selectors';
import {
  getRecords_Districts,
  getRecords_Institutes,
} from 'redux/common/_selectors';
import { getCurrentNavAnchor } from 'redux/application/selectors';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { grantFiscalYearId } = ownProps;
  const grantFiscalYear = getGrantFiscalYear(state, grantFiscalYearId);
  const consortiumInfo = getConsortium(state, grantFiscalYear.consortiumId);
  const currentNavAchor = getCurrentNavAnchor(state);
  const {
    type,
    districtOrInstituteId: id,
    primaryContactId,
  } = consortiumInfo.fiscalAgent;
  let entityName;
  if (id && type) {
    if (type === 'DISTRICT') {
      const entity = getRecords_Districts(state).find(
        (d) => d.districtId === id
      );
      if (entity) {
        entityName = entity.districtName;
      }
    } else {
      const entity = getRecords_Institutes(state).find(
        (i) => i.schoolId === id
      );
      if (entity) {
        entityName = entity.schoolName;
      }
    }
  }

  const selectedPrimaryUCContact = getContact(state, primaryContactId);

  return {
    consortiumInfo,
    currentNavAchor,
    entityName,
    grantFiscalYear,
    selectedPrimaryUCContact,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(GeneralSummary);
