import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { FourYearGroup } from 'components/Grants/Applications/FourYear/FourYearGroup/FourYearGroup.component';
import { getNextFourYearStepUrl } from 'redux/nav/fouryearnav.selectors';
import { every } from 'lodash';
import {
  getRecords_FourYearPlan,
  getIsDataFetching_FourYearPlan,
  getRecords_FourYearPlanAddressableElements,
  getIsDataFetching_FourYearPlanAddressableElements,
} from 'redux/grants/_selectors';
import { loadFourYearQuestions } from 'redux/grants/grantformquestions/fouryearquestions/fouryear.actions';
import { loadFourYearAddressableElements } from 'redux/grants/fouryearaddressableelements/fouryear.actions';
import { getLockedStatus } from 'util/applicationStateUtility';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { step } = ownProps;
  const nextStepUrl = getNextFourYearStepUrl(state, step);
  const addressableElements = getRecords_FourYearPlanAddressableElements(
    state,
    step
  );
  const questions = getRecords_FourYearPlan(state, step);
  const isLoading = every([
    getIsDataFetching_FourYearPlan(state, step),
    getIsDataFetching_FourYearPlanAddressableElements(state, step),
  ]);
  const loadQuestions = loadFourYearQuestions(step);
  const loadAddressableElements = loadFourYearAddressableElements(step);

  const isLocked = getLockedStatus(state, 'four-year-plan');

  return {
    addressableElements,
    isLoading,
    isLocked,
    loadAddressableElements,
    loadQuestions,
    nextStepUrl,
    questions,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(FourYearGroup);
