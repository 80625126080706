import { CTA_KEY, CTA_COURSE_INSTRUCTIONAL_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CtaInstructorCourse } from 'types';

export const CTA_COURSE_INSTRUCTIONAL_PATH = [
  CTA_KEY,
  CTA_COURSE_INSTRUCTIONAL_KEY,
];

export const getIsDataFetching_CtaInstructorCourse = (state: any): boolean => {
  return getIsDataFetching(state, CTA_COURSE_INSTRUCTIONAL_PATH);
};

export const getRecords_CtaInstructorCourse = (
  state: unknown
): CtaInstructorCourse[] => {
  return getRecords(state, CTA_COURSE_INSTRUCTIONAL_PATH);
};
