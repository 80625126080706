import React, { useEffect } from 'react';
import { map } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { ProgramPathway, ProgramStep } from 'types';
import { useParams } from 'react-router';
import DatatableLoading from 'components/Common/DatatableLoading';
import Instruction from 'components/Common/Instruction';
import PathwayComponent from './Pathway';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SaveButtons from 'components/Common/SaveButtons';

type PathwayCourseEditComponentProps = {
  cleanProgramPathwayCache: Function;
  isFetching: boolean;
  loadProgramPathway: Function;
  nextProgramStep: ProgramStep;
  programLevelId: number;
  programPathways: ProgramPathway[];
  readOnly: boolean;
  saveProgramPathway: Function;
  setCurrentNavAnchor: Function;
  updateProgramPathwayCache: Function;
};

export const PathwayCourseEditComponent: React.FC<
  PathwayCourseEditComponentProps
> = (props) => {
  const {
    cleanProgramPathwayCache,
    isFetching,
    loadProgramPathway,
    nextProgramStep,
    programLevelId,
    programPathways,
    readOnly,
    saveProgramPathway,
    setCurrentNavAnchor,
    updateProgramPathwayCache,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    loadProgramPathway(programInstanceId);
  }, [loadProgramPathway, programInstanceId]);

  useEffect(() => {
    setCurrentNavAnchor('courses');
  }, [setCurrentNavAnchor]);

  const updatePathways = (updated: ProgramPathway) => {
    const records = upsertInPlace(programPathways, updated, 'careerPathwayId');
    updateProgramPathwayCache({
      records,
      programInstanceId,
    });
  };

  const handleSave = () => {
    cleanProgramPathwayCache({ programInstanceId });
    return saveProgramPathway(programPathways, programInstanceId);
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <SecureWrap component="programs" isLocked={readOnly}>
        <Paper>
          <Instruction
            title={
              programLevelId === 5
                ? 'Middle School Courses'
                : 'Secondary Courses'
            }
          >
            <p>
              Opt-in to all relevant courses by clicking on the checkbox in
              front of each course below. Edit courses using the pencil to
              adjust their title, contact hours, and advanced credit options. If
              a course is not shown, you can add a new course using the "Add New
              Course" button.
            </p>
          </Instruction>
        </Paper>
        {isFetching ? (
          <Paper>
            <DatatableLoading loading={true} messageBefore={'Loading Data'} />
          </Paper>
        ) : (
          map(programPathways, (pathway: ProgramPathway, index: number) => {
            return (
              <PathwayComponent
                key={index}
                programPathway={pathway}
                onChange={updatePathways}
              />
            );
          })
        )}
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            saveData={handleSave}
            saveUrl={nextProgramStep.nav.url}
            saveText="Save and Continue"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
