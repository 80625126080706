import { createActionThunk } from 'redux-thunk-actions';
import { handleActions, createAction } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionUpdateRecords,
  baseSaveActionBuilder,
} from 'redux/utility/_exports';
import { SnapshotTeacher, ActiveTeachersData } from 'types';
import { upsertInPlace } from 'util/arrayUtility';
import { filter, first } from 'lodash';
import {
  readActiveTeachers,
  createSnapshotTeacher,
  deleteSnapshotTeacher,
  setTeacherReportingComplete,
} from 'services/datacollection';

const initialState = baseInitialState;

export const insertActiveTeacherComplete = createActionThunk(
  'insertActiveTeacherComplete',
  () => {
    return setTeacherReportingComplete();
  }
);

export const loadActiveTeachers = createActionThunk(
  'loadActiveTeachers',
  () => {
    return readActiveTeachers();
  }
);

export const insertSnapshotTeacher = createAction(
  'insertSnapshotTeacher',
  (teacher: SnapshotTeacher) => {
    return teacher;
  }
);

export const removeSnapshotTeacher = createAction(
  'removeSnapshotTeacher',
  (teacher: SnapshotTeacher) => {
    return teacher;
  }
);

export const saveSnapshotTeacher = createActionThunk(
  'saveSnapshotTeacher',
  (Teacher: SnapshotTeacher) => {
    return createSnapshotTeacher(Teacher);
  }
);

export const deactivateSnapshotTeacher = createActionThunk(
  'deactivateSnapshotTeacher',
  (Teacher: SnapshotTeacher) => {
    return deleteSnapshotTeacher(Teacher);
  }
);

export default handleActions<
  BaseStateObject,
  ActiveTeachersData | ActiveTeachersData[]
>(
  {
    ...baseSaveActionBuilder<SnapshotTeacher | SnapshotTeacher[]>(
      saveSnapshotTeacher
    ),
    ...baseSaveActionBuilder<SnapshotTeacher | SnapshotTeacher[]>(
      deactivateSnapshotTeacher
    ),
    ...baseLoadActionBuilder<ActiveTeachersData>(loadActiveTeachers),

    insertSnapshotTeacher: (state, { payload }) => {
      const teacher = (payload as unknown) as SnapshotTeacher;
      const currentrecord: ActiveTeachersData = first(state.records);
      if (currentrecord) {
        const teachers = [...currentrecord.teachers];
        const newteachers = upsertInPlace<SnapshotTeacher>(
          teachers,
          teacher,
          'snapshotTeacherId'
        );
        const updatedPayload = {
          ...currentrecord,
          teachers: newteachers,
        };
        return actionUpdateRecords(state, updatedPayload);
      }
      return actionUpdateRecords(state, state.records);
    },

    removeSnapshotTeacher: (state, { payload }) => {
      const teacher = (payload as unknown) as SnapshotTeacher;
      const currentrecord: ActiveTeachersData = first(state.records);
      if (currentrecord) {
        const teachers = [...currentrecord.teachers];
        const newteachers = filter(teachers, (item: SnapshotTeacher) => {
          return item.snapshotTeacherId !== teacher.snapshotTeacherId;
        });
        const updatedPayload = {
          ...currentrecord,
          teachers: newteachers,
        };
        return actionUpdateRecords(state, updatedPayload);
      }

      return actionUpdateRecords(state, state.records);
    },
  },
  initialState
);
