import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import Label from 'components/Common/FormElements/Label';
import { isDisabled } from 'util/authorization';
import { isNumber } from 'lodash';
import { insertClass } from 'util/formHelpers';

type TextareaProps = {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  label?: JSX.Element | React.ReactElement | string;
  name: string;
  noLabel?: boolean;
  onBlur?: Function;
  onChange?: Function;
  valid?: boolean;
  value: string | number;
  additionalInfo?: React.ReactElement | string;
  maxLength?: number;
};

export const Textarea: React.FC<TextareaProps> = (props) => {
  const {
    className,
    disabled,
    errorMessage,
    id,
    label,
    name,
    noLabel,
    onBlur,
    onChange,
    valid = true,
    value,
    additionalInfo,
    maxLength,
  } = props;

  const [computedDisable, setComputedDisable] = useState<boolean>(false);

  const ref = useRef<HTMLTextAreaElement>(null);

  const myStyle: CSSProperties = {
    pointerEvents: 'auto',
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (computedDisable) return;
    const value = event.target.value;
    const data: any = {};
    data[name] = value;
    onChange && onChange(data, name, value);
  };

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, ref);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  return (
    <div className="form__group" onBlur={() => onBlur && onBlur()}>
      {!noLabel && <Label name={name} label={label} htmlFor={id ? id : name} />}
      {additionalInfo && <p>{additionalInfo}</p>}
      <textarea
        style={myStyle}
        maxLength={maxLength}
        id={id ? id : name}
        name={name}
        value={value}
        readOnly={computedDisable}
        onChange={handleChange}
        className={`form__textarea ${
          !valid && insertClass('form__input--error')
        }${insertClass(className)}`}
        ref={ref}
      />
      {isNumber(maxLength) && (
        <p className="u-color-blue">{`${maxLength} characters max`}</p>
      )}
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
