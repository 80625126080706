import React, { useState } from 'react';
import { createProgram } from 'services/programs/program.services';
import { readOccupations } from 'services/common/onet.services';
import {
  CipCode,
  UniversalContact,
  emptyProgram,
  Program,
  Institute,
} from 'types';
import { FlexRow } from 'layouts';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { map, flatMap, isNil, unionBy, filter, includes, every } from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { isTruthy } from 'util/objectUtility';
import CipCodeSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CipCodeSelectBox';
import DepartmentSelectBox from 'components/Common/SelectBoxes/DepartmentSelectBox';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Instruction from 'components/Common/Instruction';
import Input from 'components/Common/FormElements/Input';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ProgramLevelSelectBox from 'components/Common/SelectBoxes/ProgramLevelSelectBox';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import ScrollToTop from 'components/Common/ScrollToTop';
import SaveButtons from 'components/Common/SaveButtons';
import ToastNotification from 'components/Common/Toast';

type CreateProgramProps = {
  user: UniversalContact;
  institutes: Institute[];
};

const CreateProgram: React.FC<CreateProgramProps> = (props) => {
  const { user, institutes } = props;
  const [isFetching, setisFetching] = useState(false);

  const [newProgram, setNewProgram] = useState<Program>(emptyProgram());
  const history = useHistory();

  const createNewProgram = async () => {
    setisFetching(true);
    return createProgram(newProgram)
      .then((result) => history.push(`/programs/program-steps/${result}`))
      .catch(() => {
        setisFetching(false);
        toast(
          <ToastNotification
            status="error"
            message="Unable to create new program."
          />
        );
      });
  };

  const canSave = (): boolean => {
    return every([
      newProgram.schoolId !== -1,
      isTruthy(newProgram.cipId),
      isTruthy(newProgram.programName),
      newProgram.programLevelId !== 255,
    ]);
  };

  const instituteChange = (data: any): void => {
    setNewProgram({
      ...newProgram,
      ...data,
      schoolDepartmentId: null,
    });
  };
  const handleChange = (data: any): void => {
    setNewProgram({
      ...newProgram,
      ...data,
    });
  };

  const generateONetJobText = (data: any): string => {
    let jobtext = '';
    if (data.match || data.match.length !== 0) {
      const onetOccupations = data.match[0].occupations.occupation;
      jobtext = map(
        onetOccupations,
        (job: any) => `${job.code}: ${job.title}\r`
      ).join('');
    }
    return jobtext;
  };

  const loadOnetData = async (cipId: string): Promise<string> => {
    return readOccupations(cipId)
      .then((data: any) => generateONetJobText(data))
      .catch((err: any) => {
        return Promise.reject('Unable to read external O*Net data.');
      });
  };

  const handleCipCodeSelect = (cip: CipCode): void => {
    if (isNil(cip)) return;
    const programName = cip.cipCodeColoradoName
      ? cip.cipCodeColoradoName
      : cip.cipCodeName;

    loadOnetData(cip.cipId)
      .then((jobtext: string = '') => {
        setNewProgram({
          ...newProgram,
          cipId: cip.cipId,
          programName,
          occupationInfoProgramCompletion: jobtext,
          occupationInfo2YearCert: jobtext,
          occupationInfoAdvanceDegree: jobtext,
          programDescription: cip.cipCodeDescription,
        });
      })
      .catch((err: any) => {
        setNewProgram({
          ...newProgram,
          cipId: cip.cipId,
          programName,
          occupationInfoProgramCompletion: '',
          occupationInfo2YearCert: '',
          occupationInfoAdvanceDegree: '',
          programDescription: cip.cipCodeDescription,
        });
      });
  };

  const getUserInstitutes = () => {
    const ids = flatMap(
      [...user.departments, ...getUserDepartments()],
      (dept) => {
        return [dept.schoolId];
      }
    );
    return filter(institutes, (ins) => {
      return includes(ids, ins.schoolId);
    });
  };

  const getUserDepartments = () => {
    const instDepartments = flatMap(user.institutes, (inst) => {
      return inst.departments ? [...inst.departments] : [];
    });
    return unionBy(instDepartments, user.departments, 'schoolDepartmentId');
  };

  const getUserSchools = () => {
    const districtSchools = flatMap(user.districts, (district) => {
      return district.schools ? [...district.schools] : [];
    });
    const allSchools = unionBy(districtSchools, user.schools, 'schoolId');

    if (newProgram.programLevelId === PROGRAM_LEVEL_ENUM['Middle School']) {
      return filter(allSchools, (school) => {
        if (!school.programLevels) {
          return false;
        }
        return includes(
          map(school.programLevels, (pl) => {
            return pl.programLevelId;
          }),
          5
        );
      });
    }
    return filter(allSchools, (school) => {
      if (!school.programLevels) {
        return false;
      }
      return includes(
        map(school.programLevels, (pl) => {
          return pl.programLevelId;
        }),
        1
      );
    });
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Creating the program" />
      <Paper>
        <Instruction title="Create a Program">
          <ol>
            <li>Choose the desired program level offered.</li>
            <li>
              Select the school or institution/department this program is
              intended for.
            </li>
            <li>Add a CIP code.</li>
            <li>
              Once all fields are provided, continue to General Information.
            </li>
          </ol>
          <br />
          <h4>To add a CIP code: </h4>
          <p>
            Type the first few digits of the CIP code or name of the program you
            wish to enter. Select the corresponding program from the options
            provided. Position your cursor on the ? symbol to see a description.
            Select the program you wish to create from the list and verify the
            name before proceeding. CIP Codes are filtered based on program
            level offered.
          </p>
        </Instruction>
        <FlexRow>
          <ProgramLevelSelectBox
            filter={{
              property: 'programLevelId',
              values: user.programLevelIds,
            }}
            selection={newProgram.programLevelId}
            onChange={handleChange}
          />
        </FlexRow>
        {newProgram.programLevelId !== 255 && (
          <React.Fragment>
            <FlexRow>
              {newProgram.programLevelId ===
              PROGRAM_LEVEL_ENUM.Postsecondary ? (
                <React.Fragment>
                  <InstituteSelectBox
                    label="Institute"
                    name="schoolId"
                    records={getUserInstitutes()}
                    selection={newProgram.schoolId}
                    onChange={instituteChange}
                  />
                  <DepartmentSelectBox
                    shouldReset={true}
                    records={getUserDepartments()}
                    filter={{
                      property: 'schoolId',
                      values: newProgram.schoolId,
                    }}
                    selection={newProgram.schoolDepartmentId}
                    onChange={handleChange}
                  />
                </React.Fragment>
              ) : (
                <SchoolSelectBox
                  filter={[
                    {
                      filter: {
                        exclude: true,
                        property: 'nada',
                        values: 'nada',
                      },
                      operator: 'and',
                    },
                  ]}
                  label="Select Your School"
                  records={getUserSchools()}
                  selection={newProgram.schoolId}
                  onChange={handleChange}
                />
              )}
            </FlexRow>
            <FlexRow>
              <CipCodeSelectBox
                filter={{
                  property: 'programLevelId',
                  values: newProgram.programLevelId,
                }}
                onChange={handleCipCodeSelect}
              />
            </FlexRow>
            <FlexRow>
              <Input
                label="Name of the Program Request"
                name="programName"
                value={newProgram.programName}
                disabled={true}
              />
            </FlexRow>
          </React.Fragment>
        )}
      </Paper>
      <Paper>
        <SaveButtons
          alternateAction={createNewProgram}
          cancelUrl="/programs"
          disabled={!canSave()}
          saveText="Continue to General Information"
        />
      </Paper>
    </React.Fragment>
  );
};

export default CreateProgram;
