import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { AddressableElement } from 'types';

const ENDPOINT = 'api/AddressableElements/';

export const readStrategySelectItem = async (grantFiscalYearId: number) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ENDPOINT}strategyselect/${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('getStrategySelectItems', err);
    });
};

export const readAddressableElements = async (
  step: string,
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${step}/${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('AddressableElements', err);
    });
};

export const updateAddressableElements = async (
  grantFiscalYearId: number,
  addressableElements: AddressableElement[]
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(addressableElements),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('AddressableElements', err);
    });
};
