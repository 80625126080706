import { useValidation } from 'de-formed-validations';
import { SpendingCategory } from 'types';
import { isAdminCategory } from 'util/annualSpendingUtility';
import { isStringValid } from 'util/validation.utility';

export const SpendingCategoryValidations = () => {
  return useValidation<SpendingCategory>({
    partnerConsortiumId: [
      {
        errorMessage: 'Fund Pooling must select a Partnership Institution.',
        validation: (value: number, state: SpendingCategory) => {
          return !state.isFundPool ? true : value > 0;
        },
      },
    ],

    partnershipAgreementFilePath: [
      {
        errorMessage: 'Fund Pooling must upload a Partnership Agreement.',
        validation: (value: string, state: SpendingCategory) => {
          return !state.isFundPool ? true : isStringValid(value);
        },
      },
    ],

    description: [
      {
        errorMessage: 'Project Description is required.',
        validation: (value: string, state: SpendingCategory) => {
          const admin = isAdminCategory(state);
          return admin ? true : isStringValid(value);
        },
      },
    ],
  });
};
