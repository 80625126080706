import React from 'react';
import Icon from 'components/Common/Icons';

type NavigationTitleProps = {
  color: string;
  iconName: string;
  navTrayClosed: boolean;
  setNavTrayClosed: Function;
  title: string;
};

export const NavigationTitle: React.FC<NavigationTitleProps> = (props) => {
  const { color, title, iconName, navTrayClosed, setNavTrayClosed } = props;
  return (
    <div
      onClick={() => setNavTrayClosed(!navTrayClosed)}
      className={
        navTrayClosed
          ? `side-nav__header side-nav__header--${color} u-flex-center`
          : `side-nav__header side-nav__header--${color}`
      }
    >
      {navTrayClosed ? (
        <div className="u-flex-center">
          <Icon name={iconName} className="side-nav__title-icon" />
        </div>
      ) : (
        <div className="side-nav__title">
          <Icon name={iconName} className="side-nav__title-icon" />
          <h2 className="side-nav__title-text">{title}</h2>
        </div>
      )}
    </div>
  );
};
