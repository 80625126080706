import { ReportsDashboardFilters } from './Filters.component';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { prop, len as length } from 'util/objectUtility';
import { ReportingFilterState, ReportItem } from 'types';
import {
  setReportingFilterValue,
  clearReportingFilters,
} from 'redux/filters/reportingfilter';
import { getRecords } from 'redux/utility/_exports';
import { REPORTING_REPORTS_PATH } from 'redux/reporting/_paths';
import { getRecords_ReportingFilterState } from 'redux/filters/reportingfilter.selectors';
import { ReportRailFiltersComponent } from './ReportRailFilters';

const mapStateProps = (state: any) => {
  const reports: ReportItem[] = getRecords(state, REPORTING_REPORTS_PATH);
  const filterState: ReportingFilterState = getRecords_ReportingFilterState(
    state
  );

  const cccsFavoriteCount = length(filter(reports, prop('cccsFavorite')));
  const financeFavoriteCount = length(filter(reports, prop('financeFavorite')));
  const dcReviewCount = length(filter(reports, prop('dcReview')));
  const ctaReviewCount = length(filter(reports, prop('ctaReview')));
  const mostUsedCount = length(filter(reports, prop('mostUsed')));

  const filterLengths = {
    cccsFavoriteCount,
    financeFavoriteCount,
    dcReviewCount,
    ctaReviewCount,
    mostUsedCount,
  };

  return {
    filterLengths,
    filterState: filterState ? filterState : {},
  };
};

const dispatchProps = {
  clearFilters: clearReportingFilters,
  setFilterValue: setReportingFilterValue,
};

export default connect(mapStateProps, dispatchProps)(ReportsDashboardFilters);

export const RailFilters = connect(
  mapStateProps,
  dispatchProps
)(ReportRailFiltersComponent);
