import { combineReducers } from 'redux';
import ApplicationReducer from 'redux/application';
import CacheReducer from 'redux/caches/_reducer';
import ChangelogReducer from 'redux/generalworkflow/changelog/_reducer';
import CommonReducer from 'redux/common/_reducer';
import DataCollectionReducer from 'redux/datacollection/_reducer';
import filters from 'redux/filters/_reducer';
import GrantsReducer from 'redux/grants/_reducer';
import NavReducer from 'redux/nav/_reducer';
import PermissionsReducer from 'redux/permissions';
import ProgramReducer from 'redux/programs/_reducer';
import UniversalContactsReducer from 'redux/universalcontacts';
import UserReducer from 'redux/user';
import WorkflowReducer from 'redux/workflow/_reducer';
import CtaReducer from 'redux/cta/_reducer';
import KEYS from 'redux/keys';
import storage from 'redux-persist/lib/storage';
import ReportingReducer from 'redux/reporting/_reducer';

export const appReducer = combineReducers({
  [KEYS.CTA_KEY]: CtaReducer,
  [KEYS.APPLICATION_KEY]: ApplicationReducer,
  [KEYS.CACHE_KEY]: CacheReducer,
  [KEYS.CHANGELOG_KEY]: ChangelogReducer,
  [KEYS.COMMON_KEY]: CommonReducer,
  [KEYS.DATA_COLLECTION_KEY]: DataCollectionReducer,
  [KEYS.FILTERS_KEY]: filters,
  [KEYS.GRANTS_KEY]: GrantsReducer,
  [KEYS.NAV_KEY]: NavReducer,
  [KEYS.PERMISSIONS_KEY]: PermissionsReducer,
  [KEYS.PROGRAMS_KEY]: ProgramReducer,
  [KEYS.UNIVERSAL_CONTACTS_KEY]: UniversalContactsReducer,
  [KEYS.USER_KEY]: UserReducer,
  [KEYS.WORKFLOW_KEY]: WorkflowReducer,
  [KEYS.REPORTING_KEY]: ReportingReducer,
});

const keyDucer = (state: any, action: any) => {
  if (action.type === 'logUserOut') {
    storage.removeItem('persist:cccsroot');
    state = undefined;
  }
  if (action.type === 'REFRESH_DATA') {
    storage.removeItem('persist:cccsroot');
    state = {
      [KEYS.APPLICATION_KEY]: undefined,
      [KEYS.CACHE_KEY]: undefined,
      [KEYS.CHANGELOG_KEY]: undefined,
      [KEYS.COMMON_KEY]: undefined,
      [KEYS.CTA_KEY]: undefined,
      [KEYS.DATA_COLLECTION_KEY]: undefined,
      [KEYS.FILTERS_KEY]: undefined,
      [KEYS.GRANTS_KEY]: undefined,
      [KEYS.NAV_KEY]: undefined,
      [KEYS.PERMISSIONS_KEY]: undefined,
      [KEYS.PROGRAMS_KEY]: undefined,
      [KEYS.REPORTING_KEY]: undefined,
      [KEYS.UNIVERSAL_CONTACTS_KEY]: undefined,
      [KEYS.WORKFLOW_KEY]: undefined,
    };
  }
  return appReducer(state, action);
};

export default keyDucer;
