import React, { useState } from 'react';
import { startCase } from 'lodash';
import { CourseCreditAward } from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';

type CreditInputProps = {
  className?: string;
  disabled?: boolean;
  index?: number;
  name?: string;
  onChange?: Function;
  programAwardId?: number;
  value: string | number;
};

// This should be moved to course credits or programs, this isn't
// generically reusable
export const CreditInput: React.FC<CreditInputProps> = (props) => {
  const {
    className,
    disabled,
    index,
    name,
    onChange,
    programAwardId,
    value,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const handleChange = (e: any) => {
    if (!programAwardId || disabled) return;
    const value = e.target.value ? e.target.value.trim() : '';
    const newNumber = `${value}0`;
    if (isNaN(Number(newNumber))) return;
    const data: CourseCreditAward = {
      programAwardId,
      credits: value ? value : 0,
    };
    onChange && onChange(data, index);
  };

  /* using == intentionally */
  const getValue = () => {
    if (isFocus && value == '0') return '0'; // eslint-disable-line
    if (!isFocus && value == '0') return EMPTY_PLACEHOLDER; //eslint-disable-line
    return value;
  };

  const getClassName = () => {
    return className
      ? className
      : 'course-credits__input course-credits__input--disabled';
  };

  return (
    <input
      autoComplete="off"
      className={getClassName()}
      disabled={disabled}
      name={name ? startCase(name) : 'Credits'}
      onBlur={() => setIsFocus(false)}
      onChange={handleChange}
      onFocus={() => setIsFocus(true)}
      title={name ? startCase(name) : 'Enter Credits For Award'}
      value={getValue()}
    />
  );
};
