import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { map, every } from 'lodash';
import { FlexRow, FlexGroup } from 'layouts';
import { compose, prop, renderData, isTruthy } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { DataCollectionSubmission } from 'types';
import { upsertInPlace, fmap } from 'util/arrayUtility';
import { toast } from 'react-toastify';
import Loading from 'components/Common/Loading';
import Filters from './Filters';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';

type Props = {
  isFetching: boolean;
  loadDataCollectionSubmission: Function;
  saveDataCollectionSubmission: Function;
  submissions: DataCollectionSubmission[];
};

export const SubmissionManagementDataCollection: FC<Props> = (props) => {
  const {
    isFetching,
    loadDataCollectionSubmission,
    saveDataCollectionSubmission,
    submissions = [],
  } = props;

  const history = useHistory();

  const [submissionData, setSubmissionData] = useState<
    DataCollectionSubmission[]
  >([]);

  // grab the type, grab open prop, return if it is truthy
  const allToggled = (type: string) =>
    compose(
      isTruthy,
      prop('open'),
      prop(type)
    );

  // check if every toggle for a type is true / false
  const checkToggles = (type: string) => {
    return every(fmap(allToggled(type), submissionData));
  };

  const [doomToggle, setDoomToggle] = useState<{ [key: string]: boolean }>({
    enrollment: false,
    teacher: false,
    followUp: false,
  });

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleDoomToggle = (type: string) => {
    if (!isDirty) {
      setIsDirty(true);
    }
    const currentToggleState = doomToggle[type];

    setDoomToggle({
      ...doomToggle,
      [type]: !currentToggleState,
    });

    const updated = map(submissionData, (submission: any) => {
      const data = { ...submission[type], open: !currentToggleState };
      return { ...submission, [type]: data };
    });
    setSubmissionData(updated);
  };

  const handleToggle = (
    type: 'enrollment' | 'teacher' | 'followUp',
    submission: DataCollectionSubmission
  ) => (data: any) => {
    const subItem = { ...submission[type], ...data };
    const updatedItem = { ...submission, [type]: subItem };
    const updatedArray = upsertInPlace(submissionData, updatedItem, 'id');
    setSubmissionData(updatedArray);
  };

  const handleSave = () => {
    return saveDataCollectionSubmission(submissionData)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      });
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderOpenToggle = (type: 'enrollment' | 'teacher' | 'followUp') => (
    submission: DataCollectionSubmission
  ): JSX.Element => {
    const getValue = compose(
      prop('open'),
      prop(type)
    );
    return (
      <React.Fragment>
        <Toggle
          className="consortium-view__status-icon u-icon-green"
          inTable
          name="open"
          noLabel={true}
          onChange={handleToggle(type, submission)}
          value={getValue(submission)}
        />
      </React.Fragment>
    );
  };

  const renderNavLink = (type: string) => (id: number): JSX.Element => {
    const getLink = () => {
      switch (type) {
        case 'enrollment':
          return 'student-enrollment/';
        case 'teacher':
          return 'active-teacher/';
        case 'followUp':
          return 'student-followup/';
        default:
          return '';
      }
    };
    return (
      <React.Fragment>
        <IconButton
          tooltip="open"
          name="launchOpen"
          onClick={() => history.push(`/data-collection/${getLink()}`)}
        />
      </React.Fragment>
    );
  };

  const Name = compose(
    renderData,
    prop('name')
  );
  const Level = compose(
    renderData,
    prop('level')
  );
  const ItemStatus = compose(
    renderCircle,
    prop('completed')
  );
  const EnrollmentNavLink = compose(
    renderNavLink('enrollment'),
    prop('id')
  );
  const TeacherNavLink = compose(
    renderNavLink('teacher'),
    prop('id')
  );
  const FollowUpNavLink = compose(
    renderNavLink('followUp'),
    prop('id')
  );
  const Completed = (entity: keyof DataCollectionSubmission) =>
    compose(
      prop('completed'),
      prop(entity)
    );
  const Open = (entity: keyof DataCollectionSubmission) =>
    compose(
      prop('open'),
      prop(entity)
    );

  const PARTICIPANT_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: Name,
      sortable: true,
      selector: Name,
      style: {
        minWidth: '20%',
        flexGrow: 3,
      },
    },
    {
      name: 'Level',
      cell: Level,
      sortable: true,
      selector: (row: any) => [Level(row), Name(row), prop('id', row)],
    },
    {
      name: 'Enrollment Completed',
      cell: compose(
        ItemStatus,
        prop('enrollment')
      ),
      sortable: true,
      selector: (row: any) => [
        Completed('enrollment')(row),
        Name(row),
        prop('id', row),
      ],
      width: '14rem',
    },
    {
      name: 'Enrollment Open',
      cell: renderOpenToggle('enrollment'),
      sortable: true,
      selector: Open('enrollment'),
      width: '14rem',
    },
    {
      name: 'View Enrollment',
      cell: EnrollmentNavLink,
      width: '15%',
    },
    {
      name: 'Follow-up Completed',
      cell: compose(
        ItemStatus,
        prop('followUp')
      ),
      sortable: true,
      selector: (row: any) => [
        Completed('followUp')(row),
        Name(row),
        prop('id', row),
      ],
      width: '14rem',
    },
    {
      name: 'Follow-up Open',
      cell: renderOpenToggle('followUp'),
      sortable: true,
      selector: Open('followUp'),
      width: '14rem',
    },
    {
      name: 'View Follow-up',
      cell: FollowUpNavLink,
      width: '15%',
    },
    {
      name: 'Teachers Completed',
      cell: compose(
        ItemStatus,
        prop('teacher')
      ),
      sortable: true,
      selector: (row: any) => [
        Completed('teacher')(row),
        Name(row),
        prop('id', row),
      ],
      width: '14rem',
    },
    {
      name: 'Teachers Open',
      cell: renderOpenToggle('teacher'),
      sortable: true,
      selector: compose(
        prop('open'),
        prop('teacher')
      ),
      width: '14rem',
    },
    {
      name: 'View Teachers',
      cell: TeacherNavLink,
      width: '5%',
    },
  ];

  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '2rem',
  };

  // set value if all filtered toggles are true/false
  useEffect(() => {
    if (submissionData.length > 0) {
      setDoomToggle({
        enrollment: checkToggles('enrollment'),
        teacher: checkToggles('teacher'),
        followUp: checkToggles('followUp'),
      });
    }
  }, [submissionData, setDoomToggle]); // eslint-disable-line

  useEffect(() => {
    if (submissions.length > 0) {
      setSubmissionData(submissions);
    }
  }, [submissions]);

  useEffect(() => {
    loadDataCollectionSubmission();
  }, []); //eslint-disable-line

  return (
    <React.Fragment>
      <Loading
        isActive={isFetching}
        messageBefore="Loading Submission Management"
      />
      <ScrollToTop />
      <Paper>
        <PageHeading
          title={
            <Instruction title="Submission Management" isPageHeader={true}>
              <p>
                This page enables you to manage the collection of Student
                Enrollment, Student Follow-Up, and Active Teacher data for the
                current reporting year. Use the toggles to open and close
                portions of the Data Collection site for individual districts
                and institutes or all filtered entities. The view buttons will
                display a complete list of the Enrollment, Follow-Up, and Active
                Teacher data reported on the site.
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <FlexRow>
          <SearchBarFilter
            data={submissions}
            headers={PARTICIPANT_HEADERS}
            isFetching={isFetching}
            label="Search for School Submission Data"
            setFilteredData={setSubmissionData}
            shouldReset={true}
          />
        </FlexRow>
        <Filters />
        <div style={rowStyle}>
          <FlexGroup>
            <div className="form__toggle--outline">
              <Toggle
                name="toggleEnrollment"
                label="Open/Close Enrollment"
                description="Open enrollment for all filtered submissions"
                value={doomToggle.enrollment}
                onChange={() => handleDoomToggle('enrollment')}
              />
            </div>
          </FlexGroup>
          <FlexGroup>
            <div className="form__toggle--outline">
              <Toggle
                name="toggleFollowUp"
                label="Open/Close Follow-up"
                description="Open follow-up for all filtered submissions"
                value={doomToggle.followUp}
                onChange={() => handleDoomToggle('followUp')}
              />
            </div>
          </FlexGroup>
          <FlexGroup>
            <div className="form__toggle--outline">
              <Toggle
                name="toggleTeachers"
                label="Open/Close Teachers"
                description="Open teachers for all filtered submissions"
                value={doomToggle.teacher}
                onChange={() => handleDoomToggle('teacher')}
              />
            </div>
          </FlexGroup>
        </div>
        <Table columns={PARTICIPANT_HEADERS} data={submissionData} />
      </Paper>
      <Paper>
        <SaveButtons alternateAction={handleSave} />
      </Paper>
    </React.Fragment>
  );
};
