import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, EmailRecipientFilterState } from 'types';

const initialState: EmailRecipientFilterState = {
  consortiumName: undefined,
  emailWasSent: undefined,
  instituteLevel: undefined,
};

export const setEmailRecipientFilterValue = createAction(
  'setEmailRecipientFilterValue'
);
export const resetEmailRecipientFilterValue = createAction(
  'resetEmailRecipientFilterValue'
);
export const clearEmailRecipientFilters = createAction(
  'clearEmailRecipientFilters'
);

export default handleActions<
  EmailRecipientFilterState,
  KeyValueType<number> | string
>(
  {
    setEmailRecipientFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetEmailRecipientFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearEmailRecipientFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
