import React, { useEffect, useState } from 'react';
import {
  AddressableElement,
  AzureUploadParameters,
  QuestionGroup,
  emptyQuestionGroup,
} from 'types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { upsertInPlace } from 'util/arrayUtility';
// import { startCase } from 'lodash';--use customStartCase function instead
import { customStartCase } from 'util/customStartCase';
import { updateGrantFormQuestions } from 'services/grants/grantformquestions.services';
import { FourYearPlanValidations } from 'validations/FourYearPlan.validations';
import AddressableElements from 'components/Grants/Applications/FourYear/AddressableElements';
import Loading from 'components/Common/Loading';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ParentQuestionGroup from 'components/Common/Questions';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type FourYearGroupProps = {
  addressableElements: AddressableElement[];
  isLocked: boolean;
  isLoading: boolean;
  loadAddressableElements: Function;
  loadQuestions: Function;
  nextStepUrl: string;
  questions: QuestionGroup[];
  setCurrentNavAnchor: Function;
  step: string;
};

export const FourYearGroup: React.FC<FourYearGroupProps> = (props) => {
  const {
    addressableElements,
    isLoading,
    isLocked,
    loadAddressableElements,
    loadQuestions,
    questions,
    setCurrentNavAnchor,
    nextStepUrl,
  } = props;
  const dispatch = useDispatch();

  const { grantFiscalYearId: cId, step }: any = useParams();
  const grantFiscalYearId = Number(cId);

  const [questionState, setQuestionState] = useState<QuestionGroup[]>([
    emptyQuestionGroup(),
  ]);

  const { getError, getFieldValid, validate } = FourYearPlanValidations();

  useEffect(() => {
    dispatch(loadQuestions(grantFiscalYearId));
    dispatch(loadAddressableElements(grantFiscalYearId));
    window.scrollTo(0, 0);
  }, [step, grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    setQuestionState(questions);
  }, [questions]);

  useEffect(() => {
    setCurrentNavAnchor(step);
  }, [setCurrentNavAnchor, step]);

  const handleChange = (data: QuestionGroup) => {
    if (data.title === 'Identify an Element') {
      validate('elements', data);
    }
    const updated = upsertInPlace<QuestionGroup>(
      questionState,
      data,
      'questionGroupId'
    );
    setQuestionState(updated);
  };

  const saveQuestions = async (): Promise<any> => {
    if (validate('elements', questionState[0])) {
      return updateGrantFormQuestions(questionState);
    }
    return Promise.reject('Validations did not pass.');
  };

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|four-year-elements|${step}`,
  };

  const showElementDupeError = () => {
    return getFieldValid('elements') ? [] : [getError('elements')];
  };

  return (
    <SecureWrap isLocked={isLocked} component="grantsbasic">
      <Loading
        isActive={isLoading}
        messageBefore={`Loading ${customStartCase(step)}`}
      />
      <Paper>
        <Instruction title={customStartCase(step)}>
          <p>
            All applicants for a Perkins grant must create a Four-Year Strategic
            Plan that build off their regional needs assessment to address
            identified needs. The following sections ask you to detail the steps
            you will take to strengthen your institutional and regional CTE
            programming. Follow the prompts provided in each section to complete
            the open-response text boxes.
          </p>
        </Instruction>
        {addressableElements && (
          <AddressableElements addressableElements={addressableElements} />
        )}
      </Paper>

      <Paper>
        <ParentQuestionGroup
          uploadParams={uploadParams}
          questionGroup={questionState}
          title={`Questions about ${customStartCase(step)}`}
          onChange={handleChange}
        />
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          disabled={!getFieldValid('elements')}
          saveData={saveQuestions}
          saveText="Save and continue"
          saveUrl={`${nextStepUrl}${grantFiscalYearId}`} // create get next step logic
          validationErrors={showElementDupeError()}
        />
      </Paper>
    </SecureWrap>
  );
};
