import React from 'react';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import Paper from 'components/Common/Paper';
import { FlexRow, FlexGroup } from 'layouts';
import { Link } from 'react-router-dom';
import sheep from 'assets/bighorn.jpg';
import { PublicLayout } from 'layouts/PublicLayout';

const FourOhFourPage: React.FC = () => {
  return (
    <PublicLayout>
      <ScrollToTop />
      <Paper>
        <FlexRow>
          <FlexGroup>
            <div style={{ textAlign: 'center' }}>
              <img src={sheep} alt="404: Page not found" />
              <h1>404</h1>
              <h2>
                Nothing to see here. <Link to={'/'}>Go Home</Link>
              </h2>
            </div>
          </FlexGroup>
        </FlexRow>
      </Paper>
    </PublicLayout>
  );
};

export default FourOhFourPage;
