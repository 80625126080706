import React from 'react';
import { CourseCreditProgram, CourseCreditGeneral } from 'types';
import GeneralCourseCreditsInputRow from 'components/Program/CourseCredits/GeneralEducationCourses/GeneralInputRow';
import Instruction from 'components/Common/Instruction';
import { useParams } from 'react-router';
import { map } from 'lodash';

type GeneralEducationCoursesProps = {
  courseCredits: CourseCreditProgram;
  updateProgramCourseCreditsCache: Function;
};

export const GeneralEducationCourses: React.FC<GeneralEducationCoursesProps> = (
  props
) => {
  const { courseCredits, updateProgramCourseCreditsCache } = props;
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const handleGeneralCourseChange = (data: CourseCreditGeneral) => {
    const generalEducationCourses = map(
      courseCredits.generalEducationCourses,
      (general: CourseCreditGeneral) => {
        return data.title === general.title ? data : general;
      }
    );
    updateProgramCourseCreditsCache({
      programInstanceId,
      records: {
        ...courseCredits,
        generalEducationCourses,
      },
    });
  };

  return (
    <React.Fragment>
      <Instruction title="General Education Courses">
        <p>
          If applicable, provide a list of courses (organized by category) that
          earns credit for each award. (Note: Minimum of 15 credits is required
          for AAS)
        </p>
      </Instruction>
      {map(
        courseCredits.generalEducationCourses,
        (course: CourseCreditGeneral, index: number) => {
          return (
            <GeneralCourseCreditsInputRow
              key={index}
              generalCourse={course}
              onChange={handleGeneralCourseChange}
            />
          );
        }
      )}
    </React.Fragment>
  );
};
