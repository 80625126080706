import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
} from 'services';
import { CTAGeneralAccuracy, CTASchool } from 'types';

const BASE = ['api', 'ctageneral'];

// TODO -  Send just the bool and the CTEId
export const updateCTAGeneralAccuracy = async (ctaId: number, terms: CTAGeneralAccuracy) => {
  return fetch(getFormattedUrl([...BASE, 'accuracy', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(terms),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA General', err, 'readCTAGeneralAccuracy');
    });
};

export const readCTAGeneralAccuracy = async (ctaId: number): Promise<CTAGeneralAccuracy[]> => {
  return fetch(getFormattedUrl([...BASE, 'accuracy', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA General', err, 'readCTAGeneralAccuracy');
    });
};

export const readCTASchools = async (
  ctaId: number,
  isDCTS: boolean = false
): Promise<CTASchool[]> => {
  const surl = isDCTS
    ? getFormattedUrl([...BASE, 'schools', ctaId, isDCTS])
    : getFormattedUrl([...BASE, 'schools', ctaId]);
  return fetch(surl, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA General', err, 'readCTASchools');
    });
};

export const updateCTASchool = async (ctaId: number, school: CTASchool): Promise<any> => {
  return fetch(getFormattedUrl([...BASE, 'schools', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(school),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA General', err, 'updateCTASchool');
    });
};

export const readCTADistrictName = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE, 'ctadistrictname', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA General', err, 'readCTADistrictName');
    });
};
