import { connect } from 'react-redux';
import { getProgramArticulationsDeep } from 'redux/programs/_selectors';
import {
  removeArticulation,
  saveArticulation,
  loadProgramArticulations,
} from 'redux/programs/articulations';
import { ArticulationEditComponent } from './ArticulationEdit.component';
import { ArticulationSummaryComponent } from './ArticulationSummary.component';
import { getActiveProgramId } from 'redux/application/selectors';
import { getIsDataFetching_ProgramArticulations } from 'redux/programs/articulations.selectors';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getActiveProgramLevel,
  getProgram,
  getIsDataFetching_ProgramInstance,
  getIsProgramReadOnly,
} from 'redux/programs/programinstance.selectors';
import {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
} from 'redux/programs/programinstance';
import { getNextStep } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const program = getProgram(state, programInstanceId);
  const programLevel = getActiveProgramLevel(state);
  const articulations = getProgramArticulationsDeep(state, programInstanceId);
  const isArtFetching = getIsDataFetching_ProgramArticulations(state);
  const isPrgFetching = getIsDataFetching_ProgramInstance(state);
  const nextProgramStep = getNextStep(state, 'articulations');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    articulations,
    isFetching: isArtFetching || isPrgFetching,
    nextProgramStep,
    program,
    programLevel,
    readOnly,
  };
};

const dispatchToProps = {
  loadProgramArticulations,
  removeArticulation,
  saveArticulation,
  saveProgramInstanceAction,
  setCurrentNavAnchor,
  loadSingleProgramInstance,
};

export const ArticulationEdit = connect(
  mapStateToProps,
  dispatchToProps
)(ArticulationEditComponent);

export const ArticulationSummary = connect(
  mapStateToProps,
  dispatchToProps
)(ArticulationSummaryComponent);
