import { connect } from 'react-redux';
import {
  getRecords_ProgramArea,
  PROGRAM_AREA_PATH,
} from 'redux/common/_selectors';
import { ProgramArea } from 'types/common/ProgramArea.types';
import { OptionMap, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { filter, label, onChange, selection, records: baseRecords } = ownProps;

  const records = getRecordsUtility<ProgramArea>(
    state,
    PROGRAM_AREA_PATH,
    getRecords_ProgramArea,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<ProgramArea> = {
    key: 'careerSectorId',
    value: 'careerSectorId',
    label: 'careerSectorTitle',
  };

  return {
    label: label ? label : 'Program Area',
    name: 'careerSectorId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
