import React, { useRef } from 'react';
import { FlexGroup } from 'layouts';
import { randomString } from 'util/global';
import { map, startCase } from 'lodash';
import { isDisabled } from 'util/authorization';
import Icon from 'components/Common/Icons';

export type CheckboxOption = {
  value: boolean | null;
  label?: string;
};

type InternalCheckboxType = {
  name: string;
  value: boolean | null;
  label: string;
};

type CheckboxInputProps = {
  border?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  groupTitle: string;
  horizontal?: boolean;
  id?: string;
  onBlur?: Function;
  onChange?: Function;
  options: { [key: string]: CheckboxOption };
  valid?: boolean;
};

export function CheckboxGroupComponent(props: CheckboxInputProps) {
  const {
    border = false,
    disabled = false,
    errorMessage,
    groupTitle,
    horizontal = false,
    id,
    onBlur,
    onChange,
    options,
    valid = true,
  } = props;

  const createCheckboxGroupOptions = (data: {
    [key: string]: CheckboxOption;
  }): InternalCheckboxType[] => {
    const keys = Object.keys(data);
    return map(keys, (name: string) => {
      return {
        name: name,
        label: data[name].label ? data[name].label! : startCase(name),
        value: data[name].value,
      };
    });
  };

  const mappedOptions = createCheckboxGroupOptions(options);
  const randomID = randomString();
  const ref = useRef(null);

  const handleChange = (option: InternalCheckboxType) => {
    if (isDisabled(disabled, ref)) return;
    let data: {
      [key: string]: unknown;
    } = {};
    const value = !option.value;
    data[option.name] = value;
    onChange && onChange(data, option.name, value);
  };

  const getGroupClassName = () => {
    const direction = horizontal
      ? 'form__checkbox-group u-flex'
      : 'form__checkbox-group';

    return border ? `${direction} u-border` : direction;
  };

  const getItemClassName = () => {
    return horizontal
      ? 'form__checkbox-container'
      : 'form__checkbox-container form__checkbox-container--vertical';
  };

  return (
    <FlexGroup>
      <p id={id ? id : randomID} className="form__label">
        {groupTitle}
      </p>
      <div
        role="group"
        aria-labelledby={id ? id : randomID}
        className={getGroupClassName()}
      >
        {map(mappedOptions, (option: InternalCheckboxType, index: number) => {
          return (
            <div
              key={index}
              role="checkbox"
              aria-checked={!!option.value}
              tabIndex={0}
              className={getItemClassName()}
              onBlur={() => onBlur && onBlur()}
              onClick={() => handleChange(option)}
              onKeyPress={(e: React.KeyboardEvent) => {
                e.preventDefault();
                (e.key === 'Enter' || e.key === ' ') && handleChange(option);
              }}
              style={{
                padding: '0.25rem .5rem',
              }}
            >
              <Icon
                name={option.value ? 'circleChecked' : 'circleOpen'}
                className="form__checkbox"
              />
              <p
                style={{
                  marginLeft: '1rem',
                  userSelect: 'none',
                }}
              >
                {option.label}
              </p>
            </div>
          );
        })}
      </div>
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </FlexGroup>
  );
}
