import { basetype } from 'types/base.types';

export interface CipCode extends basetype {
  careerPathway: any;
  careerPathwayId: number;
  cipCodeName: string;
  cipCodeColoradoName: string;
  cipCodeDescription: string;
  cipComments: string;
  cipId: string;
  coloradoActive: number;
  genderTradition: string;
  isActive: boolean;
  programLevelId: number;
}

export const emptyCipCode = (): CipCode => {
  return {
    careerPathway: '',
    careerPathwayId: -1,
    cipCodeName: '',
    cipCodeColoradoName: '',
    cipCodeDescription: '',
    cipComments: '',
    cipId: '',
    coloradoActive: -1,
    genderTradition: '',
    isActive: true,
    programLevelId: -1,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  };
};
