import React, { useState, useEffect } from 'react';
import { UniversalContact } from 'types';
import { Dialog } from '@rmwc/dialog';
import { ResetPasswordValidations } from 'validations/ResetPassword.validations';
import Button from 'components/Common/FormElements/Button';
import DatatableLoading from 'components/Common/DatatableLoading';
import Input from 'components/Common/FormElements/Input';

type ResetPasswordProps = {
  user: UniversalContact;
  updateContactPassword: Function;
  isFetching: boolean;
  isNewUser?: boolean;
  visible?: boolean;
};

export type StateType = {
  error: string;
  newPassword: string;
  confirmNewPassword: string;
  submitted: boolean;
};

export const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const {
    user,
    updateContactPassword,
    isFetching,
    isNewUser,
    visible = true,
  } = props;
  const [open, setOpen] = useState<boolean | undefined>(false);
  const newState: StateType = {
    error: '',
    newPassword: '',
    confirmNewPassword: '',
    submitted: false,
  };
  const [state, setState] = useState(newState);

  const strongPasswordString =
    'Strong password required. Enter 8-64 characters. Combine uppercase letters, lowercase letters, numbers, and symbols.';

  useEffect(() => {
    setOpen(isNewUser);
  }, [isNewUser]);

  const validations = ResetPasswordValidations();
  const {
    getError,
    getFieldValid,
    isValid,
    validateAll,
    validateIfTrue,
    validate,
  } = validations;

  const onConfirm = () => {
    if (validateAll(state)) {
      updateContactPassword(user, state.newPassword)
        .then(() => {
          setState({ ...state, submitted: true });
        })
        .catch(() => {
          setState({ ...newState, error: 'Password reset failed. Try again.' });
        });
    }
  };

  const onCancel = () => {
    setState(newState);
    setOpen(false);
  };

  const handleFormChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, state);
    setState({ ...state, ...data });
  };

  const resetForm = () => {
    return (
      <div className="dialog">
        <h2 className="dialog__title">{'Reset Password'}</h2>
        <div className="dialog__children">
          <form>
            <div className="form__group">
              {isNewUser && <h4>{'Welcome to CCCS!'}</h4>}
              <p>{strongPasswordString}</p>
            </div>
            <div className="form__error">{state.error}</div>
            <div className="form__group">
              <Input
                errorMessage={getError('newPassword')}
                name="newPassword"
                onBlur={() => {
                  validate('newPassword', state.newPassword, state);
                }}
                onChange={handleFormChange}
                type="password"
                valid={getFieldValid('newPassword')}
                value={state.newPassword}
              />
            </div>
            <div className="form__group">
              <Input
                errorMessage={getError('confirmNewPassword')}
                name="confirmNewPassword"
                onBlur={() => validateAll(state)}
                onChange={handleFormChange}
                type="password"
                valid={getFieldValid('confirmNewPassword')}
                value={state.confirmNewPassword}
              />
            </div>
          </form>
        </div>
        <div className="dialog__btn-container">
          {!isNewUser ? (
            <Button
              onClick={onCancel}
              className="dialog__btn--cancel"
              text="Cancel"
            />
          ) : (
            <div className="u-flex-grow-1" />
          )}
          <Button
            onClick={() => {
              onConfirm();
            }}
            disabled={!isValid}
            className="dialog__btn--submit"
            text="Confirm"
          />
        </div>
      </div>
    );
  };

  const submittedMessage = () => {
    return isFetching ? (
      <DatatableLoading loading={isFetching} />
    ) : (
      <div className="dialog">
        <h2 className="dialog__title">{'Reset Password'}</h2>
        <div className="dialog__children">
          <p>{'Password has been updated.'}</p>
        </div>
        <div className="dialog__btn-container">
          <Button
            onClick={onCancel}
            className="dialog__btn--cancel"
            text="Cancel"
          />
          <Button
            onClick={() => {
              onCancel();
            }}
            className="dialog__btn--submit"
            text="OK"
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onCancel} preventOutsideDismiss={isNewUser}>
        {state.submitted ? submittedMessage() : resetForm()}
      </Dialog>
      {visible && (
        <Button
          text="Reset Password"
          className="account-menu__button--flat"
          onClick={() => setOpen(true)}
        />
      )}
    </React.Fragment>
  );
};
