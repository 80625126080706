import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import Table from 'components/Common/Table';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import ToastNotification from 'components/Common/Toast';
import { FlexRow } from 'layouts';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import StringResources from 'StringResources';
import { PermissionRole } from 'types';
import { prop } from 'util/objectUtility';
import CreateNewRole from '../CreateNewRole';

type PermissionRoleManagementProps = {
  roles: PermissionRole[];
  isFetching: boolean;
  loadPermissionRoles: Function;
  removePermissionRole: Function;
};

export const PermissionRoleManagement: React.FC<
  PermissionRoleManagementProps
> = (props) => {
  const {
    roles,
    isFetching,
    loadPermissionRoles,
    removePermissionRole,
  } = props;
  const [roleState, setRoleState] = useState<PermissionRole[]>([]);

  useEffect(() => {
    loadPermissionRoles();
  }, [loadPermissionRoles]);

  const getValue = (item: PermissionRole, name: string) => {
    return prop(name, item);
  };

  const history = useHistory();

  const renderEditIcon = (row: PermissionRole) => {
    return (
      <IconButton
        name="pencil"
        onClick={() => {
          history.push(`/admin/permission-roles/${row.permissionRoleId}`);
        }}
        tooltip="Edit"
      />
    );
  };

  const renderDeleteIcon = (row: PermissionRole) => {
    return (
      <React.Fragment>
        {Number(row.permissionRoleId) !== 1 && (
          <ConfirmationDialog
            tooltip="Delete Security Role"
            header="Confirm Delete?"
            onConfirm={() => {
              removePermissionRole(row).then(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Success.Deleted}
                  />
                );
              });
            }}
            activateButton={<IconButton tooltip="Delete" name="trashCan" />}
          >
            <p>Are you sure you want to delete this role?</p>
          </ConfirmationDialog>
        )}
      </React.Fragment>
    );
  };

  const ROLES_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: (item) => getValue(item, 'permissionRoleName'),
      selector: (item) => getValue(item, 'permissionRoleName'),
      sortable: true,
      width: '100%',
    },
    {
      name: 'Friendly Name',
      cell: (item) => getValue(item, 'permissionRoleLabelName'),
      selector: (item) => getValue(item, 'permissionRoleLabelName'),
      sortable: true,
      width: '100%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '12rem',
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '12rem',
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <CreateNewRole />
      <Paper className="u-add-padding-bottom">
        <FlexRow>
          <SearchBarFilter
            data={roles}
            headers={ROLES_HEADERS}
            isFetching={isFetching}
            label="Search Roles"
            setFilteredData={setRoleState}
          />
        </FlexRow>
        <Table columns={ROLES_HEADERS} data={roleState} />
      </Paper>
    </React.Fragment>
  );
};
