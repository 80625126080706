import { connect } from 'react-redux';
import { HostingComponent } from './Hosting.component';
import { getRecords_CTAHosting } from 'redux/cta/_selectors';
import { loadCTAHosting } from 'redux/cta/hosting/ctahosting';
import { removeCTAHostingContract } from 'redux/cta/hosting/ctahostingcontract';

const mapStateToProps = (state: any) => {
  const ctaHosting = getRecords_CTAHosting(state);
  return {
    ctaHosting,
  };
};

const dispatchProps = {
  removeCTAHostingContract,
  loadCTAHosting,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(HostingComponent);
