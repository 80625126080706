import React from 'react';
import Icon from 'components/Common/Icons';

type FancyLabelProps = {
  bool: boolean;
  classNameFalse?: string;
  classNameTrue: string;
  iconNameFalse: string;
  iconNameTrue: string;
  label: string;
  labelFalse?: string;
};

export const FancyLabel: React.FC<FancyLabelProps> = (props) => {
  const {
    bool,
    classNameFalse,
    classNameTrue,
    iconNameFalse,
    iconNameTrue,
    label,
    labelFalse,
  } = props;
  return bool ? (
    <div className="fancy-label">
      <label htmlFor="" className="form__label fancy-label__label">
        {label}
      </label>
      <Icon name={iconNameTrue} className={classNameTrue} />
    </div>
  ) : (
    <div className="fancy-label">
      <label htmlFor="" className="form__label fancy-label__label">
        {labelFalse ? labelFalse : label}
      </label>
      <Icon
        name={iconNameFalse}
        className={classNameFalse ? classNameFalse : classNameTrue}
      />
    </div>
  );
};
