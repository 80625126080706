import { SuperSummaryComponent } from './SuperSummary.component';
import { connect } from 'react-redux';
import { getProgram } from 'redux/programs/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );

  const program = getProgram(state, programInstanceId);

  return {
    program,
  };
};

export default connect(
  mapStateToProps,
  null
)(SuperSummaryComponent);
