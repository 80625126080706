import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GrantFiscalYear } from 'types';
import { getContactFullName } from 'util/universalcontact.utility';
import { compose, getDate, prop, renderData } from 'util/objectUtility';
import { getShortProgramLevelName } from 'util/programInstance.utility';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Badge from 'components/Common/Badge';
import Filters from './Filters';
import GrantApplicationFilters from './GrantApplicationFilters';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import Table from 'components/Common/Table';

type ApplicationsDashboardProps = {
  filters: number;
  grants: GrantFiscalYear[];
  isFetching: boolean;
  loadGrantFiscalYear: Function;
};

export const ApplicationsDashboardComponent: React.FC<ApplicationsDashboardProps> = (
  props
) => {
  const { filters, grants, isFetching, loadGrantFiscalYear } = props;
  const history = useHistory();

  const [filteredData, setFilteredData] = useState<GrantFiscalYear[]>([
    ...grants,
  ]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);

  const handleClick = (row: GrantFiscalYear) => {
    history.push(`/grants/applications/grant-steps/${row.grantFiscalYearId}`);
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const Name = compose(renderData, prop('name'));
  const Year = compose(renderData, prop('year'));
  const Level = compose(renderData, getShortProgramLevelName);
  const Contact = compose(renderData, getContactFullName, prop('contact'));
  const Status = (row: GrantFiscalYear) => (row.status ? 'Active' : 'Inactive');
  const SubstantiallySubmitted = compose(
    renderData,
    getDate,
    prop('substantiallySubmitted')
  );
  const NeedsReview = compose(renderCircle, prop('needsReview'));
  const NeedsFinanceReview = compose(renderCircle, prop('needsFinanceReview'));

  const GRANTS_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: Name,
      sortable: true,
      selector: Name,
      width: '20%',
    },
    {
      name: 'Fiscal Year',
      cell: Year,
      sortable: true,
      selector: Year,
      width: '10%',
    },
    {
      name: 'Level',
      cell: Level,
      sortable: true,
      selector: Level,
      width: '5%',
    },
    {
      name: 'Contact',
      cell: Contact,
      sortable: true,
      selector: Contact,
      width: '10%',
    },
    {
      name: 'Status',
      cell: Status,
      sortable: true,
      selector: Status,
      width: '8%',
    },
    {
      name: 'Substantially Submitted',
      cell: SubstantiallySubmitted,
      sortable: true,
      selector: SubstantiallySubmitted,
    },
    {
      name: 'Needs Review',
      cell: NeedsReview as any,
      sortable: true,
      selector: 'needsReview',
      center: true,
    },
    {
      name: 'Needs Finance Review',
      cell: NeedsFinanceReview as any,
      sortable: true,
      selector: 'needsFinanceReview',
      center: true,
    },
  ];

  useEffect(() => {
    setFilteredData([...grants]);
  }, [grants]);

  useEffect(() => {
    loadGrantFiscalYear();
  }, [loadGrantFiscalYear]);

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading Applications" />
      <ScrollToTop />
      <SecureWrap allowReadOnly={true} component={'grantsbasic'}>
        <Paper>
          <Instruction title="Perkins Grants Application Dashboard">
            <p>
              This page enables you to review your grant application for
              federally-funded CTE programming. Select a row to edit your
              application details. Use "Search for Applications" to find an
              application you want to review or edit. The table is searchable
              with the start of the text in any column. Columns with a down or
              up-arrow button can sort the table in ascending or descending
              order by that column.
            </p>
            <br />
            <p>
              Access the <Link to="/cccs-reports">Reporting Dashboard</Link>
              and filter by 'Grants' to see a list of helpful reports. From
              there, you can set unique filter parameters to run your preferred
              report.
            </p>
          </Instruction>
        </Paper>
        <FlexRow>
          <FlexGroup>
            <Paper>
              <FlexRow>
                <SearchBarFilter
                  data={grants}
                  headers={GRANTS_HEADERS}
                  isFetching={isFetching}
                  label="Search for Grant Applications"
                  setFilteredData={setFilteredData}
                />
                <div className="search-bar-filter__button">
                  <Badge label={filters} show={!!filters}>
                    <IconButton
                      name="tune"
                      tooltip="Show Filters"
                      onClick={() => {
                        setFiltersOpen(!filterOpen);
                      }}
                    />
                  </Badge>
                </div>
              </FlexRow>
              <Filters />
              <Table
                columns={GRANTS_HEADERS}
                data={filteredData}
                onRowClick={handleClick}
              />
            </Paper>
          </FlexGroup>
          {filterOpen && (
            <div className="u-filters">
              <GrantApplicationFilters />
            </div>
          )}
        </FlexRow>
      </SecureWrap>
    </React.Fragment>
  );
};
