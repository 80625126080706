import { connect } from 'react-redux';
import { InstructionalComponent } from './Instructional.component';
import { saveSupportingSalary } from 'redux/cta/InstructionalCosts/ctainstructors';
import { getRecords } from 'redux/utility/_exports';
import { CtaInstructor } from 'types/cta/InstructionalCosts/CtaInstructor.types';
import { CTA_INSTRUCTORS_PATH } from 'redux/cta/_paths';
import { loadInstructors } from 'redux/cta/InstructionalCosts/ctainstructors';

const mapStateToProps = (state: any) => {
  const instructors = getRecords<CtaInstructor>(state, CTA_INSTRUCTORS_PATH);

  return {
    instructors,
  };
};

const dispatchProps = {
  loadInstructors,
  saveSupportingSalary,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(InstructionalComponent);
