import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import ApplicationsDashboardPage from 'pages/Grants/ApplicationsDashboard';
import ApplicationOverviewPage from 'pages/Grants/Applications/Overview';
import ApplicationsAnnualSpendingPlanPage from 'pages/Grants/Applications/AnnualSpendingPlan/AnnualSpendingPlanEdit';
import ApplicationsAnnualSpendingProjectPlanPage from 'pages/Grants/Applications/AnnualSpendingPlan/ProjectPlan';
import ApplicationsAnnualSpendingPlanApprovalPage from 'pages/Grants/Applications/AnnualSpendingPlan/SpendingPlanApproval';
import ApplicationsAnnualReflectionPage from 'pages/Grants/Applications/AnnualReflection/AnnualReflectionEdit';
import ApplicationsAnnualReflectionApprovalPage from 'pages/Grants/Applications/AnnualReflection/AnnualReflectionApproval';
import ApplicationsAssurancesPage from 'pages/Grants/Applications/AssuranceSignature/Assurances';
import ApplicationsAssuranceSignatureApprovalPage from 'pages/Grants/Applications/AssuranceSignature/AssuranceSignatureApproval';
import ApplicationsAwardPacketPage from 'pages/Grants/Applications/AwardPacket/AwardPacketEdit';
import ApplicationsAwardPacketApprovalPage from 'pages/Grants/Applications/AwardPacket/AwardPacketApproval';
import ApplicationsFourYearGroupPage from 'pages/Grants/Applications/FourYearStrategicPlan/FourYearGroup';
import ApplicationsFourYearApprovalPage from 'pages/Grants/Applications/FourYearStrategicPlan/FourYearApproval';
import ApplicationsSignaturesPage from 'pages/Grants/Applications/AssuranceSignature/Signatures';
import ApplicationsTwoYearGroupPage from 'pages/Grants/Applications/TwoYearStrategicPlan/TwoYearGroup';
import ApplicationsTwoYearApprovalPage from 'pages/Grants/Applications/TwoYearStrategicPlan/TwoYearApproval';
import ApplicationsVoucherDetailPage from 'pages/Grants/Applications/Voucher/VoucherDetail';
import ApplicationsVoucherOverviewPage from 'pages/Grants/Applications/Voucher/VoucherOverview';
import ApplicationsVoucherApprovalPage from 'pages/Grants/Applications/Voucher/VoucherApproval';
import ConsortiumAddEditPage from 'pages/Grants/Consortium/AddEdit';
import ConsortiumDashboardPage from 'pages/Grants/ConsortiumDashboard';
import ConsortiumViewPage from 'pages/Grants/Consortium/View';
import DocumentsCertificationOfExemptionPage from 'pages/Grants/Applications/AwardPacket/Documents/CertificationOfExemptionDocument.page';
import DocumentsCivilRightsAssurancePage from 'pages/Grants/Applications/AwardPacket/Documents/CivilRightsAssuranceDocument.page';
import DocumentsGrantRecipientAgreementPage from 'pages/Grants/Applications/AwardPacket/Documents/GrantRecipientAgreementDocument.page';
import EmailManagementDashboardPage from 'pages/Grants/EmailManagement/Dashboard';
import EmailManagementManageTemplatePage from 'pages/Grants/EmailManagement/ManageTemplate';
import EmailManagementRecipientsPage from 'pages/Grants/EmailManagement/EmailRecipients';
import FiscalYearManagementPage from 'pages/Grants/FiscalYearManagement';
import GrantsPortalPage from 'pages/Grants/GrantsPortalPage';
import ParticipationDashboardPage from 'pages/Grants/ParticipantManagement';
import MemberResponsePage from 'pages/Grants/IntentToParticipate/MemberResponse';
import FiscalAgentResponsePage from 'pages/Grants/IntentToParticipate/FiscalAgentResponse';
import IntentSuccess from 'pages/Grants/IntentToParticipate/IntentSuccess';
import ImprovementPlanPage from 'pages/Grants/Applications/ImprovementPlan/ImprovementPlanPage';
import MultiyearDeficiencyPage from 'pages/Grants/Applications/ImprovementPlan/MultiyearDeficiency';
import ImprovementApproval from 'pages/Grants/Applications/ImprovementPlan/ImprovementApproval';

const grantsRoutes = [
  <Route
    exact
    path="/grants"
    component={authentication.required(GrantsPortalPage)}
  />,

  // ----------------------------------- //
  //          CONSORTIUM ROUTES
  // ----------------------------------- //
  <Route
    component={authentication.required(ConsortiumDashboardPage)}
    exact
    path="/grants/consortium"
  />,
  <Route
    component={authentication.required(FiscalYearManagementPage)}
    exact
    path="/grants/fiscal-year-management/:fiscalYear"
  />,
  <Route
    exact
    path="/grants/consortium/:consortiumId/:fiscalYear"
    component={authentication.required(ConsortiumViewPage)}
  />,
  <Route
    exact
    path="/grants/consortium/add-edit/:consortiumId/:fiscalYear"
    component={authentication.required(ConsortiumAddEditPage)}
  />,
  <Route
    exact
    path="/grants/applications"
    component={authentication.required(ApplicationsDashboardPage)}
  />,

  // ----------------------------------- //
  //          FOUR YEAR ROUTES
  // ----------------------------------- //
  <Route
    exact
    path="/grants/applications/four-year-plan/workflow-approval/:grantFiscalYearId"
    component={authentication.required(ApplicationsFourYearApprovalPage)}
  />,
  <Route
    exact
    path="/grants/applications/four-year-plan/:step/:grantFiscalYearId"
    component={authentication.required(ApplicationsFourYearGroupPage)}
  />,
  // ----------------------------------- //
  //          TWO YEAR ROUTES
  // ----------------------------------- //
  <Route
    exact
    path="/grants/applications/two-year-plan/:grantFiscalYearId"
    component={authentication.required(ApplicationsTwoYearGroupPage)}
  />,
  <Route
    exact
    path="/grants/applications/two-year-plan/workflow-approval/:grantFiscalYearId"
    component={authentication.required(ApplicationsTwoYearApprovalPage)}
  />,

  // ------------------------------------------- //
  //          ANNUAL SPENDING PLAN ROUTES
  // ------------------------------------------- //

  <Route
    exact
    path="/grants/applications/spending-plans/workflow-approval/:grantFiscalYearId"
    component={authentication.required(
      ApplicationsAnnualSpendingPlanApprovalPage
    )}
  />,
  <Route
    exact
    path="/grants/applications/spending-plans/:grantFiscalYearId/:strategyId"
    component={authentication.required(
      ApplicationsAnnualSpendingProjectPlanPage
    )}
  />,
  <Route
    exact
    path="/grants/applications/spending-plans/:grantFiscalYearId"
    component={authentication.required(ApplicationsAnnualSpendingPlanPage)}
  />,

  // ------------------------------------------- //
  //          ASSURANCES ROUTES
  // ------------------------------------------- //

  <Route
    exact
    path="/grants/applications/assurance-signature/workflow-approval/:grantFiscalYearId"
    component={authentication.required(
      ApplicationsAssuranceSignatureApprovalPage
    )}
  />,
  <Route
    exact
    path="/grants/applications/assurance-signature/assurances/:grantFiscalYearId"
    component={authentication.required(ApplicationsAssurancesPage)}
  />,
  <Route
    exact
    path="/grants/applications/assurance-signature/signatures/:grantFiscalYearId"
    component={authentication.required(ApplicationsSignaturesPage)}
  />,

  // ------------------------------------------- //
  //        PARTICIPANT MANAGEMENT ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/participation/:fiscalYear"
    component={authentication.required(ParticipationDashboardPage)}
  />,

  // ------------------------------------------- //
  //        INTENT TO PARTICIPANT ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/participation/fiscalagent/:secureGUID"
    component={authentication.required(FiscalAgentResponsePage)}
  />,
  <Route
    exact
    path="/grants/participation/member/:secureGUID"
    component={authentication.required(MemberResponsePage)}
  />,
  <Route
    exact
    path="/grants/participation-success"
    component={authentication.required(IntentSuccess)}
  />,

  // ------------------------------------------- //
  //        ANNUAL REFLECTION ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/applications/annual-reflection/workflow-approval/:grantFiscalYearId"
    component={authentication.required(
      ApplicationsAnnualReflectionApprovalPage
    )}
  />,
  <Route
    exact
    path="/grants/applications/annual-reflection/:grantFiscalYearId"
    component={authentication.required(ApplicationsAnnualReflectionPage)}
  />,

  // ------------------------------------------- //
  //        AWARD PACKET ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/applications/award-packet/workflow-approval/:grantFiscalYearId"
    component={authentication.required(ApplicationsAwardPacketApprovalPage)}
  />,
  <Route
    exact
    path="/grants/applications/award-packet/:grantFiscalYearId"
    component={authentication.required(ApplicationsAwardPacketPage)}
  />,
  <Route
    exact
    path="/grants/applications/award-packet/documents/certification-of-exemption"
    component={authentication.required(DocumentsCertificationOfExemptionPage)}
  />,
  <Route
    exact
    path="/grants/applications/award-packet/documents/civil-rights-assurance"
    component={authentication.required(DocumentsCivilRightsAssurancePage)}
  />,
  <Route
    exact
    path="/grants/applications/award-packet/documents/grant-recipient-agreement/:grantFiscalYearId"
    component={authentication.required(DocumentsGrantRecipientAgreementPage)}
  />,
  // ------------------------------------------- //
  //        EMAIL MANAGEMENT ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/email-management/:grantFiscalYear"
    component={authentication.required(EmailManagementDashboardPage)}
  />,
  <Route
    exact
    path="/grants/email-management/email-template/:fiscalYear/:templateId"
    component={authentication.required(EmailManagementManageTemplatePage)}
  />,
  <Route
    exact
    path="/grants/email-management/recipients/:fiscalYear/:templateId"
    component={authentication.required(EmailManagementRecipientsPage)}
  />,

  // ------------------------------------------- //
  //        Improvement Plan ROUTES
  // ------------------------------------------- //
  <Route
    exact
    path="/grants/applications/improvement-plan/:grantFiscalYearId"
    component={authentication.required(ImprovementPlanPage)}
  />,
  <Route
    exact
    path="/grants/applications/improvement-plan/multiyear/:grantFiscalYearId"
    component={authentication.required(MultiyearDeficiencyPage)}
  />,
  <Route
    exact
    path="/grants/applications/improvement-plan/workflow-approval/:grantFiscalYearId"
    component={authentication.required(ImprovementApproval)}
  />,

  // ------------------------------------------- //
  //        VOUCHER ROUTES
  // ------------------------------------------- //

  <Route
    exact
    path="/grants/applications/vouchers/:grantFiscalYearId"
    component={authentication.required(ApplicationsVoucherOverviewPage)}
  />,
  <Route
    exact
    path="/grants/applications/:voucherType/voucher-detail/:grantFiscalYearId"
    component={authentication.required(ApplicationsVoucherDetailPage)}
  />,
  <Route
    exact
    path="/grants/applications/:voucherType/workflow-approval/:grantFiscalYearId"
    component={authentication.required(ApplicationsVoucherApprovalPage)}
  />,

  // ------------------------------------------- //
  <Route
    exact
    path="/grants/applications/grant-steps/:grantFiscalYearId"
    component={authentication.required(ApplicationOverviewPage)}
  />,
  <Route
    exact
    path="/grants/funded-projects"
    component={authentication.required()}
  />,
];

export default grantsRoutes;
