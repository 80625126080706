import { connect, DefaultRootState } from 'react-redux';
import { getLoggedinUser } from 'redux/user/selectors';
import { ReportDetailComponent } from './ReportDetail.component';
import { ReportItem } from 'types';
import { getIsDataFetching, getRecords } from 'redux/utility/_exports';
import { REPORTING_REPORTS_PATH } from 'redux/reporting/_paths';
import { loadReportList } from 'redux/reporting/reports';
import { defaultTo, find } from 'lodash';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import {
  loadSingleWebReport,
  loadPublicWebReport,
} from 'redux/reporting/reports';
import { isApplicaionIdValid } from 'util/global';

export const mapStateToProps = (state: DefaultRootState, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);
  const isFetching = getIsDataFetching(state, REPORTING_REPORTS_PATH);
  const reports: ReportItem[] = getRecords(state, REPORTING_REPORTS_PATH);
  const reportName = ownProps.match.params.reportName;
  const isLoggedin = isApplicaionIdValid(loggedInUser.universalContactId);

  const defaultReport: ReportItem = {
    name: reportName,
    module: EMPTY_PLACEHOLDER,
    reportType: 'web',
    description: EMPTY_PLACEHOLDER,
    extendedGraphData: [],
    rows: [],
    rows2: [],
    rows3: [],
    selectedData: [],
    parameters: [],
    cccsFavorite: false,
    financeFavorite: false,
    dcReview: false,
    ctaReview: false,
    mostUsed: false,
  };

  const report = defaultTo(
    find(reports, (item) => {
      return String(item.name) === String(reportName);
    }),
    defaultReport
  );

  return {
    isLoggedin,
    isFetching,
    loggedInUser,
    report,
  };
};

const dispatchProps = {
  loadReportList,
  loadSingleWebReport,
  loadPublicWebReport,
};

export default connect(mapStateToProps, dispatchProps)(ReportDetailComponent);
