import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  putWithAuthOptions,
  catchError,
} from 'services';
import { PermissionComponent } from 'types';

const PERMISSION_TOPIC_ENDPOINT = 'api/PermissionComponents/';
const URL = `${ENVIRONMENT.API_URL}${PERMISSION_TOPIC_ENDPOINT}`;

export const readPermissionComponents = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionComponents', err);
    });
}; // end

export const createPermissionComponent = async (
  component: PermissionComponent
) => {
  return fetch(URL, {
    ...postWithAuthOptions(),
    body: JSON.stringify(component),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionComponents', err);
    });
}; // end

export const updatePermissionComponent = async (
  component: PermissionComponent
) => {
  return fetch(`${URL}${component.permissionComponentId}`, {
    ...putWithAuthOptions(),
    body: JSON.stringify(component),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionComponents', err);
    });
}; // end
