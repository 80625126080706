import { CTAEquipmentCTEProgram } from './CTAEquipmentCTEProgram.types';

export interface CTAEquipment {
  ctaEquipmentId: number;
  itemName: string;
  fundSource: string;
  units: number;
  invoiceNumber: string;
  invoiceDate: Date;
  location: string;
  fileUpload: string;
  ctaEquipmentCTEPrograms: CTAEquipmentCTEProgram[];
  totalCost: number;
}

export const emptyCtaEquipment = (): CTAEquipment => {
  return {
    ctaEquipmentId: -1,
    itemName: '',
    fundSource: '',
    units: 0,
    invoiceNumber: '',
    invoiceDate: new Date(Date.now()),
    location: '',
    fileUpload: '',
    ctaEquipmentCTEPrograms: [],
    totalCost: 0,
  };
};
