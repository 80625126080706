import { connect } from 'react-redux';
import { SecondaryCourseEdit } from './CourseEdit.component';
import {
  loadProgramPathway,
  updateProgramPathwayCache,
} from 'redux/caches/programpathway';
import {
  getProgramPathwayCourse,
  getProgramPathways,
} from 'redux/caches/_selectors';
import { getProgram } from 'redux/programs/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = Number(ownProps.match.params.programInstanceId);
  const courseId = Number(ownProps.match.params.courseId);
  const programCareerPathwayId = Number(
    ownProps.match.params.programCareerPathwayId
  );
  const programPathways = getProgramPathways(state, programInstanceId);
  const courseData = getProgramPathwayCourse(
    state,
    programInstanceId,
    courseId,
    programCareerPathwayId
  );
  const programInstance = getProgram(state, programInstanceId);
  const isMiddleSchool = programInstance.programLevelId === 5;

  const course = {
    ...courseData,
    isMiddleSchool,
  };

  return {
    course,
    programPathways,
  };
};

const dispatchToProps = {
  loadProgramPathway,
  updateProgramPathwayCache,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(SecondaryCourseEdit);
