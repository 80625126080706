import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { CipCodeSelectBoxComponent } from './CipCodeSelectBox.component';
import { CipCode } from 'types';
import {
  getRecords_CipCode,
  getFilteredRecords_CipCode,
} from 'redux/common/_selectors';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const { filter } = ownProps;
  const cipCodes: CipCode[] = filter
    ? getFilteredRecords_CipCode(state, filter)
    : getRecords_CipCode(state);

  return {
    cipCodes,
  };
};

export default connect(mapStateToProps)(CipCodeSelectBoxComponent);
