export interface FiscalYear {
  startDate?: Date;
  endDate?: Date;
  year: number;
  isCurrent: boolean;
}

export const emptyFiscalYear = (): FiscalYear => {
  return {
    startDate: undefined,
    endDate: undefined,
    year: 1970,
    isCurrent: false,
  };
};
