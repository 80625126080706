import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  readProgramsBySchoolId,
  readProgramsByDistrictId,
} from 'services/programs/program.services';
import { FlexRow, FlexGroup } from 'layouts';
import { clone, some } from 'lodash';
import { ItemFilter, Program, ProgramOfStudy, School } from 'types';
import { isApplicaionIdValid } from 'util/global';
import { PROGRAMS_KEY, PROGRAM_GENERAL_KEY } from 'redux/keys';
import { getProgramLevelName } from 'util/programInstance.utility';
import { getReduxFilterFromItemFilterArray } from 'util/filterUtility';
import { compose, renderData, prop, mergeObjects } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { toast } from 'react-toastify';
import DataFilter from 'components/Common/DataFilter';
import DistrictSelectBox from 'components/Common/SelectBoxes/DistrictSelectBox';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import ScrollToTop from 'components/Common/ScrollToTop';
import Toggle from 'components/Common/FormElements/Toggle';
import SaveButtons from 'components/Common/SaveButtons';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

type ProgramOfStudyProps = {
  program: Program;
  programOfStudy: ProgramOfStudy;
  isFetching: boolean;
  loadProgramOfStudyData: Function;
  loadSingleProgramInstance: Function;
  insertPrograms: Function;
  saveProgramOfStudyData: Function;
  schools: School[];
};

export const ProgramOfStudyComponent: React.FC<ProgramOfStudyProps> = (
  props
) => {
  const {
    insertPrograms,
    program,
    programOfStudy,
    isFetching,
    loadProgramOfStudyData,
    loadSingleProgramInstance,
    saveProgramOfStudyData,
  } = props;

  const { programInstanceId: pId } = useParams<{ programInstanceId: string }>();
  const programInstanceId = Number(pId);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramOfStudyData(programInstanceId);
      loadSingleProgramInstance(programInstanceId);
    }
  }, [loadSingleProgramInstance, loadProgramOfStudyData, programInstanceId]);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [pos, setPos] = useState<ProgramOfStudy>(clone(programOfStudy));
  const [filter, setFilter] = useState<ItemFilter>({
    property: 'nothing',
    values: -1,
  });
  const [schoolFilter, setSchoolFilter] = useState<ItemFilter>({
    property: 'nothing',
    values: -1,
  });

  useEffect(() => {
    if (programOfStudy) {
      setPos(programOfStudy);
    }
  }, [programOfStudy]);

  const handleSchoolChange = async (
    data: Partial<ProgramOfStudy>,
    key: keyof ProgramOfStudy,
    id: number
  ) => {
    const updated = mergeObjects<ProgramOfStudy>(programOfStudy, {
      secondarySchoolId: id,
    });
    setPos(updated);
    setLoadingData(true);
    readProgramsBySchoolId(id)
      .then((response) => {
        insertPrograms(response);
        // create second filter
        setFilter({
          property: 'schoolId',
          values: id,
        });
      })
      .then(() => setLoadingData(false))
      .catch(() => {
        setLoadingData(false);
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.LoadDataFailed}
          />
        );
      });
  };

  const handleDistrictChange = async (
    data: Partial<ProgramOfStudy>,
    key: keyof ProgramOfStudy,
    id: number
  ) => {
    const updated = mergeObjects<ProgramOfStudy>(programOfStudy, {
      secondarySchoolId: -1,
    });
    setPos(updated);
    setLoadingData(true);
    readProgramsByDistrictId(id)
      .then((response) => {
        insertPrograms(response);
        setSchoolFilter({
          property: 'districtId',
          values: id,
        });
      })
      .then(() => setLoadingData(false))
      .catch(() => {
        setLoadingData(false);
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.LoadDataFailed}
          />
        );
      });
  };

  const handleProgramChange = (prg: Program) => {
    if (Number(program.programLevelId) === 1) {
      setPos({
        ...pos,
        postSecondaryProgramId: prg.programInstanceId,
        secondaryProgramId: program.programInstanceId,
        ownerContactId: program.requestorContactId,
        approverContactId: prg.requestorContactId,
      });
    } else {
      setPos({
        ...pos,
        secondaryProgramId: prg.programInstanceId,
        postSecondaryProgramId: program.programInstanceId,
        ownerContactId: program.requestorContactId,
        approverContactId: prg.requestorContactId,
      });
    }
  };

  const handleSave = () => {
    return saveProgramOfStudyData(programInstanceId, pos);
  };

  const isDisabled = () => {
    return !(pos.postSecondaryProgramId > 0 && pos.secondaryProgramId > 0);
  };

  const isRowSelected = (row: Program) => {
    return some([
      row.programInstanceId === pos.postSecondaryProgramId,
      row.programInstanceId === pos.secondaryProgramId,
    ]);
  };

  const ID = compose(
    renderData,
    prop('programInstanceId')
  );
  const Name = compose(
    renderData,
    prop('programName')
  );
  const Level = compose(
    renderData,
    getProgramLevelName
  );
  const renderSelectProgram = (row: Program) => (
    <Toggle
      name="isPrimary"
      value={isRowSelected(row)}
      onChange={() => {
        isRowSelected(row)
          ? handleProgramChange({ ...row, programInstanceId: -1 })
          : handleProgramChange(row);
      }}
      noLabel={true}
    />
  );

  const PROGRAM_HEADERS: TableColumnProps[] = [
    {
      name: 'Id',
      cell: ID,
      selector: 'programInstanceId',
      sortable: true,
      width: '10%',
    },
    {
      name: 'Name',
      cell: Name,
      sortable: true,
      selector: Name,
      style: {
        minWidth: '60%',
        flexGrow: '4',
      },
    },
    {
      name: 'Level',
      cell: Level,
      sortable: true,
      selector: Level,
      width: '15%',
    },
    {
      name: 'Select Program',
      cell: renderSelectProgram,
      width: '12.8rem',
    },
  ];

  return (
    <React.Fragment>
      <Loading
        isActive={isFetching || loadingData}
        messageBefore="Loading Data"
      />
      <ScrollToTop />
      <Paper>
        <Instruction title="Program of Study">
          {Number(program.programLevelId) === 1 ? (
            <p>
              Select an institution and then select the program you are
              partnering with.
            </p>
          ) : (
            <p>
              Select a District / School combination, and then search for and
              select the program you are partnering with.
            </p>
          )}
        </Instruction>
        <FlexRow>
          <h2>Add a Program of Study Partnership</h2>
        </FlexRow>
        <FlexRow>
          {Number(program.programLevelId) === 1 ? (
            <InstituteSelectBox onChange={handleSchoolChange} />
          ) : (
            <React.Fragment>
              <DistrictSelectBox onChange={handleDistrictChange} />
              <SchoolSelectBox
                filter={getReduxFilterFromItemFilterArray([
                  schoolFilter,
                  {
                    exclude: true,
                    property: 'programLevels[0].programLevelId',
                    values: [5],
                  },
                ])}
                onChange={handleSchoolChange}
              />
            </React.Fragment>
          )}
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <h3>Select the partner program</h3>
            <DataFilter
              path={[PROGRAMS_KEY, PROGRAM_GENERAL_KEY]}
              itemFilter={filter}
            >
              <Table columns={PROGRAM_HEADERS} />
            </DataFilter>
          </FlexGroup>
        </FlexRow>
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/programs/programofstudy/${programInstanceId}`}
          disabled={isDisabled()}
          errorMessage={
            Number(program.programLevelId) !== 2
              ? 'Please select an institution to continue.'
              : 'Please select a school to continue.'
          }
          saveData={handleSave}
          saveUrl={`/programs/programofstudy/${programInstanceId}`}
          saveText="Save program of study"
        />
      </Paper>
    </React.Fragment>
  );
};
