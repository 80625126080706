import { handleActions, createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import { CourseCreditProgram } from 'types';
import {
  readProgramCoursesCredits,
  createProgramCoursesCredits,
} from 'services/programs/coursecredits.services';
import { baseInitialState } from 'redux/utility/baseInitialState';
import { actionFetching } from 'redux/utility/_exports';

const initialState = baseInitialState;

export const loadProgramCourseCredits = createActionThunk(
  'loadProgramCourseCredits',
  (programInstanceId: number) => {
    return readProgramCoursesCredits(programInstanceId);
  }
);

export const saveProgramCourseCreditCache = createActionThunk(
  'saveProgramCourseCreditCache',
  (payload: CourseCreditProgram, programInstanceId: number) => {
    return createProgramCoursesCredits(payload, programInstanceId);
  }
);

export const setProgramCourseCreditsCache = createAction(
  'setProgramCourseCreditsCache'
);
export const addProgramCourseCreditsToCache = createAction(
  'addProgramCourseCreditsToCache'
);
export const updateProgramCourseCreditsCache = createAction(
  'updateProgramCourseCreditsCache'
);
export const cleanProgramCourseCreditsCache = createAction(
  'cleanProgramCourseCreditsCache'
);
export const resetProgramCourseCreditsCache = createAction(
  'resetProgramCourseCreditsCache'
);
export const resolveCourseCreditCacheConflicts = createAction(
  'resolveCourseCreditCacheConflicts'
);

export default handleActions(
  {
    [loadProgramCourseCredits.START]: (state) => {
      return actionFetching(state, true);
    },

    [loadProgramCourseCredits.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    },

    setProgramCourseCreditsCache: (state: any, { payload }: any) => {
      const { programInstanceId, records } = payload;
      return {
        ...state,
        [programInstanceId]: {
          ...state[programInstanceId],
          records,
        },
      };
    },

    updateProgramCourseCreditsCache: (state: any, { payload }: any) => {
      const { programInstanceId, records } = payload;
      return {
        ...state,
        [programInstanceId]: {
          records: { ...records },
          isDirty: true,
        },
      };
    },

    cleanProgramCourseCreditsCache: (state: any, { payload }: any) => {
      const { programInstanceId } = payload;
      return {
        ...state,
        [programInstanceId]: {
          ...state[programInstanceId],
          isDirty: false,
        },
      };
    },

    resetProgramCourseCreditsCache: (state: any, { payload }: any) => {
      delete state[payload];
      return { ...state };
    },
  },
  initialState
);
