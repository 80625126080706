import React from 'react';
import { ImprovementPlan, emptyDeficiency, Deficiency } from 'types';
import { map, first, defaultTo } from 'lodash';
import { TableOutline } from './TableOutline';
import { DeficiencyTable } from './DeficiencyTable';
import { DeficiencyTableTop } from './DeficiencyTableTop';
import { FlexGroup, FlexRow } from 'layouts';
import { TableOutlineTop } from './TableOutlineTop';

type PerformanceDataProps = {
  improvementPlan: ImprovementPlan;
};

export const PerformanceDataComponent: React.FC<PerformanceDataProps> = (
  props
) => {
  const { improvementPlan } = props;

  const firstItem = defaultTo(
    first(improvementPlan.deficiencies),
    emptyDeficiency()
  );

  return (
    <React.Fragment>
      <FlexGroup>
        <h3>
          State Performance Target vs Actual Performance by Deficient Metric
        </h3>
        <FlexRow>
          <TableOutlineTop deficiency={firstItem} />
          {map(
            improvementPlan.deficiencies,
            (item: Deficiency, index: number) => {
              return (
                <React.Fragment key={index}>
                  <DeficiencyTableTop key={index} deficiency={item} />
                </React.Fragment>
              );
            }
          )}
        </FlexRow>

        <h3>Actual Performance of Each Population by Metric</h3>
        <div className="u-flex" style={{ width: '100%' }}>
          <TableOutline deficiency={firstItem} />
          {map(
            improvementPlan.deficiencies,
            (item: Deficiency, index: number) => {
              return (
                <React.Fragment key={index}>
                  <DeficiencyTable key={index} deficiency={item} />
                </React.Fragment>
              );
            }
          )}
        </div>
      </FlexGroup>
    </React.Fragment>
  );
};
