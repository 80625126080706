export interface PermissionComponent {
  permissionComponentId: number;
  permissionTopicId: number;
  permissionComponentName: string;
  permissionComponentLabelName: string;
}

export const emptyPermissionComponent = () => {
  return {
    permissionComponentId: -1,
    permissionTopicId: -1,
    permissionComponentName: '',
    permissionComponentLabelName: '',
  };
};
