import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import { useParams } from 'react-router-dom';
import UniversalContactAddEdit from 'components/UniversalContacts/AddEdit';

export const ContactFormPage: React.FC = (props) => {
  const { userId }: any = useParams();
  return (
    <BaseLayout>
      <UniversalContactAddEdit userId={userId} />
    </BaseLayout>
  );
};
