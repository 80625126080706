import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramLevels,
  createProgramLevel,
  updateProgramLevel,
} from 'services/common/programlevel.services';
import {
  actionFetching,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { ProgramLevel } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadProgramLevels = createActionThunk('loadProgramLevels', () => {
  return readProgramLevels();
}); // end

export const saveProgramLevel = createActionThunk(
  'saveProgramLevel',
  (payload: ProgramLevel) => {
    if (!!payload.programLevelId || payload.programLevelId === -1) {
      return createProgramLevel(payload);
    } else {
      return updateProgramLevel(payload);
    }
  }
); // end

export default handleActions<BaseStateObject, ProgramLevel[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramLevel>(loadProgramLevels),

    [saveProgramLevel.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [saveProgramLevel.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
