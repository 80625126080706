import { connect } from 'react-redux';
import { WorkflowPage } from './Workflow.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  return {
    programInstanceId,
  };
};
export default connect(mapStateToProps)(WorkflowPage);
