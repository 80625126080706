import React from 'react';

export type ToggleButtonProps = {
  disabled?: boolean;
  onChange: Function;
  text: string;
  value: boolean;
};

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { disabled, text, onChange, value } = props;

  return (
    <button
      aria-pressed={value}
      className={`${value ? 'toggle-button--selected' : 'toggle-button'}`}
      onClick={() => onChange()}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
