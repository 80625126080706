import { EditSnapshotTeacher } from './EditSnapshotTeacher';
import { connect } from 'react-redux';
import { saveSnapshotTeacher } from 'redux/datacollection/activeteachers';

const dispatchProps = {
  saveSnapshotTeacher,
};

export default connect(
  null,
  dispatchProps
)(EditSnapshotTeacher);
