import { COMMON_KEY, COMMON_DEPARTMENTS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';
import { Department, ItemFilter } from 'types';

export const DEPARTMENTS_PATH = [COMMON_KEY, COMMON_DEPARTMENTS_KEY];

export const getFilteredRecords_Departments = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<Department>(state, DEPARTMENTS_PATH, itemFilter);
};

export const getIsDataFetching_Departments = (state: any): boolean => {
  return getIsDataFetching(state, DEPARTMENTS_PATH);
};

export const getRecords_Departments = (state: any): Department[] => {
  return getRecords<Department>(state, DEPARTMENTS_PATH);
};

export const shouldRefreshReduxState_Departments = (state: any): boolean => {
  return shouldRefreshReduxState<Department>(state, DEPARTMENTS_PATH);
};

export const getLastUpdated_Departments = (state: any): number => {
  return getLastUpdated(state, DEPARTMENTS_PATH);
};
