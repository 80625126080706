import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramAssuranceQuestions,
  updateProgramAssurance,
} from 'services/programs/programassurance.service';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import { QuestionGroup } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const saveProgramAssuranceQuestions = createActionThunk(
  'saveProgramAssuranceQuestions',
  (groups: QuestionGroup[], programInstanceId: number) => {
    return updateProgramAssurance(groups, programInstanceId);
  }
); // end

export const loadProgramAssuranceQuestions = createActionThunk(
  'loadProgramAssuranceQuestions',
  (programInstanceId: number) => {
    return readProgramAssuranceQuestions(programInstanceId);
  }
); // end

export default handleActions<BaseStateObject, QuestionGroup[]>(
  {
    ...baseLoadActionBuilder<QuestionGroup[]>(loadProgramAssuranceQuestions),
  },
  initialState
);
