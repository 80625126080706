import React from 'react';
import { getSecureComponentsForNavigation } from 'util/global';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import LandingPageCard from 'components/Common/LandingPageCard';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export const LandingPage: React.FC = () => {
  return (
    <BaseLayout>
      <ScrollToTop />
      <div className="landing-page">
        <h1 className="landing-page__title">
          Learning that works for Colorado
        </h1>
        <div className="landing-page__cards">
          <SecureWrap
            allowReadOnly={true}
            component={getSecureComponentsForNavigation('programs')}
          >
            <LandingPageCard
              title="Programs"
              iconType="rocket"
              buttons={[
                <Button
                  aria-label="View Programs"
                  asLink={true}
                  className="landing-page-card__btn t-programs-view"
                  text="View"
                  to="/programs"
                />,
                <SecureWrap component="programscreate" requireEdit={true}>
                  <Button
                    aria-label="Create New Program"
                    asLink={true}
                    className="landing-page-card__btn t-programs-edit"
                    text="New"
                    to="/programs/create-program/create"
                  />
                </SecureWrap>,
              ]}
            />
          </SecureWrap>

          <SecureWrap component={getSecureComponentsForNavigation('grants')}>
            <LandingPageCard
              title="Grants"
              iconType="diamond"
              buttons={[
                <Button
                  aria-label="View Grants"
                  asLink={true}
                  className="landing-page-card__btn t-grants-view"
                  text="View"
                  to="/grants"
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap
            component={getSecureComponentsForNavigation('datacollection')}
            allowReadOnly={true}
          >
            <LandingPageCard
              title="Data Collection"
              iconType="layers"
              buttons={[
                <Button
                  aria-label="View Data Collection"
                  asLink={true}
                  className="landing-page-card__btn t-dc-view"
                  text="View"
                  to="/data-collection"
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap
            component={getSecureComponentsForNavigation('CTAGeneral')}
            allowReadOnly={true}
          >
            <LandingPageCard
              title="CTA Reporting"
              iconType="report"
              buttons={[
                <Button
                  aria-label="View CTA Reporting"
                  asLink={true}
                  className="landing-page-card__btn t-cta-view"
                  text="View"
                  to="/cta"
                />,
              ]}
            />
          </SecureWrap>

          <LandingPageCard
            title="Universal Contacts"
            iconType="users"
            buttons={[
              <Button
                aria-label="View Universal Contacts"
                asLink={true}
                className="landing-page-card__btn t-contacts-view"
                to="/contacts"
                text="View"
              />,
              <SecureWrap requireEdit={true} component="universalcontacts">
                <Button
                  aria-label="Create New User"
                  asLink={true}
                  className="landing-page-card__btn t-contacts-edit"
                  text="New"
                  to="/contacts/create-new-user"
                />
              </SecureWrap>,
            ]}
          />
          <LandingPageCard
            title="Reports"
            iconType="documents"
            buttons={[
              <Button
                aria-label="Reports"
                asLink={true}
                className="landing-page-card__btn t-cta-reports-view"
                text="View"
                to="cccs-reports"
              />,
            ]}
          />
        </div>
      </div>
    </BaseLayout>
  );
};
