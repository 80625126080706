import { connect } from 'react-redux';
import {
  getContact,
  getIsDataFetching_UniversalContact,
} from 'redux/universalcontacts/universalcontacts.selectors';
import { getContactTypes } from 'redux/universalcontacts/contacttypes.selector';
import {
  saveContact,
  loadSingleUniversalContact,
} from 'redux/universalcontacts/universalcontacts';
import {
  getRecords_Districts,
  getRecords_Schools,
  getRecords_Institutes,
  getRecords_Departments,
} from 'redux/common/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { getRecords_ContactPermissionRoles } from 'redux/permissions/contactpermissionroles.selectors';
import { loadPermissionRoles } from 'redux/permissions/permissionroles';
import { saveContactPermissionRoles } from 'redux/permissions/contactpermissionroles';
import { UniversalContactAddEdit } from './UniversalContactAddEdit.component';

export const mapStateToProps = (state: any, ownProps: any) => {
  const isFetching = getIsDataFetching_UniversalContact(state);
  const loggedInUser = getLoggedinUser(state);
  const userId = Number(ownProps.userId);
  const contact = getContact(state, userId);
  const contactTypes = getContactTypes(state);

  const districts = getRecords_Districts(state);
  const schools = getRecords_Schools(state);
  const institutes = getRecords_Institutes(state);
  const departments = getRecords_Departments(state);
  const contactRoles = getRecords_ContactPermissionRoles(state);

  return {
    contact,
    contactRoles,
    contactTypes,
    departments,
    districts,
    institutes,
    schools,
    loggedInUser,
    isFetching,
  };
};

const dispatchToProps = {
  saveContact,
  saveContactPermissionRoles,
  loadPermissionRoles,
  loadSingleUniversalContact,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(UniversalContactAddEdit);
