import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { AnnualReflectionEdit } from 'components/Grants/Applications/AnnualReflection/AnnualReflectionConnect';

export const GrantAnnualReflectionPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AnnualReflectionEdit />
      </GrantsLayout>
    </BaseLayout>
  );
};
