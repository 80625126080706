import {
  saveCTABooksSchool,
  loadCTABooksSchool,
} from 'redux/cta/ctabooksschool';

export const ctaBooksSchoolMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveCTABooksSchool.SUCCEEDED: {
      dispatch(loadCTABooksSchool(action.payload));
      break;
    }
  }
};
