import { connect } from 'react-redux';
import {
  getRecords_CTASchoolTerm,
  CTA_SCHOOL_TERM_PATH,
} from 'redux/common/_selectors';
import { OptionMap, SelectWrapper } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';
import { CTASchoolTerm } from 'types/cta/CTASchoolTerm.types';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, filter, label, onChange, records: baseRecords } = ownProps;

  const records = getRecordsUtility<CTASchoolTerm>(
    state,
    CTA_SCHOOL_TERM_PATH,
    getRecords_CTASchoolTerm,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<CTASchoolTerm> = {
    key: 'termId',
    label: 'termName',
    value: 'termId',
  };

  return {
    label: label ? label : 'Terms',
    name: 'terms',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
