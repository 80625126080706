import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  catchError,
  postWithAuthOptions,
  getWithAuthOptions,
} from 'services';
import { QuestionGroup } from 'types';

const BASE = 'api/grantformquestions/';
const UPDATE_ENDPOINT = 'api/grantformquestions/questionvalues/';
const FOUR_YEAR = `${BASE}fouryear/`;
const TWO_YEAR = `${BASE}twoyear/`;
const ASSURANCES = `${BASE}grant-assurances/`;
const ANNUAL_REFLECTION = `${BASE}annual-reflection/`;

export const readFourYearGrantFormQuestions = async (
  step: string,
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${FOUR_YEAR}${step}/${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('FourYearStrategicPlanQuestions', err);
    });
};

export const readTwoYearGrantFormQuestions = async (
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${TWO_YEAR}${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('TwoYearStrategicPlanQuestions', err);
    });
};

export const readGrantAssuranceQuestions = async (
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ASSURANCES}${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantAssuranceQuestions', err);
    });
};

export const readAnnualReflectionGrantFormQuestions = async (
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ANNUAL_REFLECTION}${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('AnnualReflectionQuestions', err);
    });
};

export const readGrantFormQuestions = async (
  entity: string,
  grantFiscalYearId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${BASE}${entity}/${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantFormQuestions', err);
    });
};

export const updateGrantFormQuestions = async (questions: QuestionGroup[]) => {
  const url = `${ENVIRONMENT.API_URL}${UPDATE_ENDPOINT}`;

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(questions),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('GrantFormQuestions', err);
    });
};
