import { connect } from 'react-redux';
import { DistrictReportingComponent } from './DistrictReporting.component';
import {
  getSingleRecord_CTADistrictRecord,
  getIsDataFetching_CTADistrictRecord,
  CTA_DASHBOARD_PATH,
} from 'redux/cta/ctadashboard.selectors';
import { some } from 'lodash';
import {
  getIsDataFetching_CTASupportPersonnel,
  getIsDataFetching_CTADirector,
  getIsDataFetching_CTASpecialist,
  getIsDataFetching_AdminBooksAndSupplies,
  getRecords_CTASchool,
  getIsDataFetching_CTACourseContracting,
  getIsDataFetching_CTAContractingAvailableHosts,
  getIsDataFetching_CTAHostingAvailableContracts,
  getIsDataFetching_CTAHosting,
  getIsDataFetching_CTAEquipment,
  getRecords_CTAAdminInformation,
} from 'redux/cta/_selectors';
import { getIsDataFetching_CTAGeneral } from 'redux/cta/_selectors';
import { getIsDataFetching_CTASupportProgramAffiliation } from 'redux/cta/ctasupportprogramaffiliation.selectors';
import { loadCTAGeneralAccuracy, loadCTASchools } from 'redux/cta/ctageneral';
import { getIsDataFetching } from 'redux/utility/_exports';
import { CTA_INSTRUCTORS_PATH } from 'redux/cta/_paths';
import { CTA_WORKFLOW_PATH } from 'redux/generalworkflow/workflow.selectors';
import { UNIVERSAL_CONTACTS_PATH } from 'redux/universalcontacts/universalcontacts.selectors';
import { loadCTAAdminInformation } from 'redux/cta/ctaadmin';
import { compose, prop } from 'util/objectUtility';
import { defaultTo, find } from 'ramda';
import {
  CTAAdminInformation,
  CTADistrictRecord,
  CTASchool,
  emptyCTAAdminInformation,
} from 'types';
import { isEqual } from 'util/formHelpers';
import { getCurrentCTAFiscalYear } from 'redux/common/_selectors';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';

const mapStateToProps = (state: any, ownProps: any) => {
  const isFetching = some([
    getIsDataFetching(state, CTA_INSTRUCTORS_PATH),
    getIsDataFetching_CTADistrictRecord(state),
    getIsDataFetching_CTASupportPersonnel(state),
    getIsDataFetching_CTAGeneral(state),
    getIsDataFetching_CTADirector(state),
    getIsDataFetching_CTASpecialist(state),
    getIsDataFetching_AdminBooksAndSupplies(state),
    getIsDataFetching_CTASupportProgramAffiliation(state),
    getIsDataFetching_CTACourseContracting(state),
    getIsDataFetching_CTAContractingAvailableHosts(state),
    getIsDataFetching_CTAHosting(state),
    getIsDataFetching_CTAHostingAvailableContracts(state),
    getIsDataFetching_CTAEquipment(state),
    getIsDataFetching(state, CTA_DASHBOARD_PATH),
    getIsDataFetching(state, CTA_WORKFLOW_PATH),
    getIsDataFetching(state, UNIVERSAL_CONTACTS_PATH),
  ]);

  const currentYear: number | undefined = prop(
    'year',
    getCurrentCTAFiscalYear(state)
  );

  // currentYearOrDefault :: CTAAdminInformation[] -> CTAAdminInformation
  const currentYearOrDefault = compose(
    defaultTo(emptyCTAAdminInformation()),
    find(compose(isEqual(currentYear), prop('year')))
  );

  const ctaId: number = ownProps.match.params.ctaId;
  const readOnly = getIsCTAReadOnly(state);
  const ctaRecord: CTADistrictRecord = getSingleRecord_CTADistrictRecord(
    state,
    ctaId
  );
  const ctaSchools: CTASchool[] = getRecords_CTASchool(state);
  const adminRecords: CTAAdminInformation = currentYearOrDefault(
    getRecords_CTAAdminInformation(state)
  );

  return {
    adminRecords,
    ctaRecord,
    ctaSchools,
    isFetching,
    readOnly,
  };
};

const dispatchProps = {
  loadCTAAdminInformation,
  loadCTAGeneralAccuracy,
  loadCTASchools,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(DistrictReportingComponent);
