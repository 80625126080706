import { CTA_KEY, CTA_CONTRACTING_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { defaultTo, find } from 'lodash';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTAContracting, emptyCTAContracting } from 'types';

export const CTA_CONTRACTING_PATH = [CTA_KEY, CTA_CONTRACTING_KEY];

export const getIsDataFetching_CTAContracting = (state: any): boolean => {
  return getIsDataFetching(state, CTA_CONTRACTING_PATH);
};

export const getRecords_CTAContracting = (state: any): CTAContracting[] => {
  return getRecords(state, CTA_CONTRACTING_PATH);
};

export const getAdvancedFilteredRecords_CTAContracting = (
  state: any
): CTAContracting[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTAContracting>(
    state,
    CTA_CONTRACTING_PATH,
    filters
  );
  return records;
};

export const getSingleRecord_CTAContracting = (
  state: unknown,
  hostingId: number
): CTAContracting => {
  const records = getRecords_CTAContracting(state);

  return defaultTo(
    find(records, (item: CTAContracting) => {
      return item.hostingId === Number(hostingId);
    }),
    emptyCTAContracting()
  );
};
