import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { FlexRow, FlexGroup } from 'layouts';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import { CivilRightsAssertionsListValidations } from 'validations/AwardPacket/ListValidations/CivilRightsAssertionsList.validations';
import { CertificationOfExemptionValidationsList } from 'validations/AwardPacket/ListValidations/CertificationOfExemptionList.validations';
import { GrantRecipientSignaturesValidations } from 'validations/AwardPacket/ListValidations/GrantRecipientSignatures.validations';
import {
  DistrictSchoolSignature,
  CertificationOfExemptionSignature,
} from 'types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { loadCertificateOfExemptionSignatures } from 'redux/grants/awardsignatures/certificateofexemptionsignatures';
import { loadCivilRightSignatures } from 'redux/grants/awardsignatures/civilrightsignatures';
import { loadGrantAgreementSignatures } from 'redux/grants/awardsignatures/grantagreementsignatures';

type Props = {
  setCurrentNavAnchor: Function;
  civilRightsSignatures: DistrictSchoolSignature[];
  certificationOfExemptionSignatures: CertificationOfExemptionSignature[];
  grantAgreementSignatures: DistrictSchoolSignature[];
};

export const AwardPacketSummaryComponent: React.FC<Props> = (props) => {
  const {
    setCurrentNavAnchor,
    civilRightsSignatures,
    certificationOfExemptionSignatures,
    grantAgreementSignatures,
  } = props;

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadCertificateOfExemptionSignatures(grantFiscalYearId));
      dispatch(loadCivilRightSignatures(grantFiscalYearId));
      dispatch(loadGrantAgreementSignatures(grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYearId]);

  const [isGrantAgreementComplete, setIsGrantAgreementComplete] = useState<
    boolean
  >(false);
  const [isCivilRightsComplete, setIsCivilRightsComplete] = useState<boolean>(
    false
  );
  const [isCertOfExemptionComplete, setIsCertOfExemptionComplete] = useState<
    boolean
  >(false);

  const [civilRightsSignaturesState, setCivilRightsSignaturesState] = useState<
    DistrictSchoolSignature[]
  >([]);
  const [
    certificationOfExemptionState,
    setCertificationOfExemptionState,
  ] = useState<CertificationOfExemptionSignature[]>([]);
  const [agreementSignaturesState, setAgreementSignaturesState] = useState<
    DistrictSchoolSignature[]
  >([]);

  useEffect(() => {
    setCivilRightsSignaturesState([...civilRightsSignatures]);
  }, [civilRightsSignatures]);

  useEffect(() => {
    setAgreementSignaturesState([...grantAgreementSignatures]);
  }, [grantAgreementSignatures]);

  useEffect(() => {
    setCertificationOfExemptionState([...certificationOfExemptionSignatures]);
  }, [certificationOfExemptionSignatures]);

  const {
    validate: validateGrantRecipient,
  } = GrantRecipientSignaturesValidations();

  const {
    validate: validateCivilRights,
  } = CivilRightsAssertionsListValidations();

  const {
    validate: validateCertificationOfExemption,
  } = CertificationOfExemptionValidationsList();

  useEffect(() => {
    setIsGrantAgreementComplete(
      validateGrantRecipient('canSubmit', agreementSignaturesState)
    );
  }, [agreementSignaturesState]); // eslint-disable-line

  useEffect(() => {
    setIsCivilRightsComplete(
      validateCivilRights('canSubmit', civilRightsSignaturesState)
    );
  }, [civilRightsSignaturesState]); // eslint-disable-line

  useEffect(() => {
    setIsCertOfExemptionComplete(
      validateCertificationOfExemption(
        'canSubmit',
        certificationOfExemptionState
      )
    );
  }, [certificationOfExemptionState]); // eslint-disable-line

  return (
    <Paper>
      <SummaryScroll componentStep="award-packet" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('award-packet')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Grant Recipient Agreement</p>
          <p>
            Signature Collection Status:{' '}
            {isGrantAgreementComplete ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">
            Certification of Exemption or Single Audit
          </p>
          <p>
            Collection Status:{' '}
            {isCertOfExemptionComplete ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Civil Rights Assurance</p>
          <p>
            Signature Collection Status:{' '}
            {isCivilRightsComplete ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('award-packet')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
