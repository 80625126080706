import { ProgramOccupationInfo } from 'types';

export const emptyProgramOccupationInfo: ProgramOccupationInfo = {
  occupationInfoId: -1,
  programId: -1,
  occupation: '',
};

/**
 * New: 1
 * Revision: 11
 * Renewal: 14
 * Information Needed: 3
 */
export const EDITABLE_PROGRAM_STATES = [1, 3, 11, 14];
