import {
  PerformanceTarget,
  PerformanceData,
  emptyPerformanceTarget,
} from 'types';
import { Question, emptyQuestion } from 'types/common';

export interface Deficiency {
  currentYearTarget: PerformanceTarget;
  previousYearTarget: PerformanceTarget;
  stateActualLevel: PerformanceTarget;
  instituteActualLevel: PerformanceTarget;
  performanceData: PerformanceData[];
  question: Question;
  metricCode: string;
  metricName: string;
}

export const emptyDeficiency = (): Deficiency => {
  return {
    currentYearTarget: emptyPerformanceTarget(),
    previousYearTarget: emptyPerformanceTarget(),
    stateActualLevel: emptyPerformanceTarget(),
    instituteActualLevel: emptyPerformanceTarget(),
    performanceData: [],
    question: emptyQuestion(),
    metricCode: '',
    metricName: '',
  };
};
