import { Expense, emptyExpense } from 'types';

export interface VoucherExpense extends Expense {
  reimbursementRequestedToDate: number;
  amountForCurrentVoucher: number;
  specificLocation: string;
  serialNumber: string;
  invoiceNumber: string;
  invoiceDate: Date | undefined;
  equipmentReceiptFilePath: string;
}

export const emptyVoucherExpense = (): VoucherExpense => {
  return {
    ...emptyExpense(),
    reimbursementRequestedToDate: 0,
    amountForCurrentVoucher: 0,
    specificLocation: '',
    serialNumber: '',
    invoiceNumber: '',
    invoiceDate: undefined,
    equipmentReceiptFilePath: '',
  };
};
