import { WorkflowStep } from 'types';
import { emptyWorkflow } from 'constants/workflow.const';

export interface Workflow {
  workflowStatusId: number;
  workflowSteps: WorkflowStep[];
}

export const emptyWorkflowObject = (): Workflow => {
  return emptyWorkflow;
};
