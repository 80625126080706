import { UniversalContact, emptyUniversalContact } from 'types';

export interface IntentToParticipateMember {
  id: number;
  guid: string;
  memberName: string;
  grantFiscalYearId: number;
  previousParticipationStatus: number;
  currentParticipationStatus: number;
  newParticipationStatus: number;
  consortiumId: number;
  previousConsortiumId: number;
  isStandalone: boolean;
  isExempt: boolean;
  exemptionReason: number;
  exemptionLetterFilePath: string;
  changeExplanation: string;
  perkinsContact: UniversalContact;
  hasResponded: boolean;
  isParticipating: boolean;
  isActive: boolean;
  isOpen: boolean;
}

export const emptyIntentToParticipateMember = (): IntentToParticipateMember => {
  return {
    id: 0, // zero for API
    guid: '',
    memberName: '',
    grantFiscalYearId: -1,
    previousParticipationStatus: -1,
    currentParticipationStatus: -1,
    newParticipationStatus: -1,
    consortiumId: -1,
    previousConsortiumId: -1,
    isStandalone: false,
    isExempt: false,
    exemptionReason: -1,
    exemptionLetterFilePath: '',
    changeExplanation: '',
    perkinsContact: emptyUniversalContact(),
    hasResponded: false,
    isParticipating: true,
    isActive: true,
    isOpen: false,
  };
};
