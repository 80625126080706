import React, { useState, useEffect, useRef } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { map, some } from 'lodash';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { Dialog } from '@rmwc/dialog';
import { useModal, Modal } from 'react-morphing-modal';
import { downloadFileFromAzure } from 'services/azure/azure.services';
import { AzureUploadParameters, StudentEnrollmentData } from 'types';
import {
  readEnrollmentTemplate,
  createEnrollmentRecords,
} from 'services/datacollection/excelmanagement.services';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import DatatableLoading from 'components/Common/DatatableLoading';
import download from 'js-file-download';
import FileDropZone from 'components/Common/FormElements/FileDropZone/FileDropZone';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type EnrollmentUploadProps = {
  programLevels: number[];
  enrollmentData: StudentEnrollmentData;
};

export const EnrollmentUploadComponent: React.FC<EnrollmentUploadProps> = (
  props
) => {
  const { enrollmentData, programLevels } = props;

  const [isOpen, setOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { modalProps, open, close } = useModal();
  const modalRef = useRef(null);
  const { complete, locked } = enrollmentData;
  const isLocked = some([complete, locked]);

  useEffect(() => {
    if (errors.length > 0) {
      open(modalRef);
    }
  }, [errors]); //eslint-disable-line

  const uploadFile = async (file: File) => {
    setErrors([]);
    setUploading(true);
    createEnrollmentRecords(file)
      .then((response: string[]) => {
        setErrors(response);
        if (response.length === 0) {
          toast(
            <ToastNotification
              status="success"
              message="Enrollment records uploaded successfully."
            />
          );
        }
      })
      .catch(() => setErrors([StringResources.Errors.InvalidResponse]))
      .finally(() => {
        setUploading(false);
        setOpen(false);
      });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'datacollection',
    folder: '',
  };

  const downloadTemplate = (programLevelId: number, filePrefix: string) => {
    readEnrollmentTemplate(programLevelId, -1)
      .then((blob: unknown) => {
        download(blob as Blob, `${filePrefix}_enrollment_template.xlsx`);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <Paper>
          <Instruction title="Upload Student Enrollment">
            <p className="u-margin-bottom">
              Use this section to enter individual student enrollment data for
              programs offered at your site. When you are ready to begin, click
              the ‘Download Template’ button corresponding to the educational
              level you wish to report. Use the file to enter student data at
              the school and program levels. Click on the ‘View Guidance’
              buttons to access documents with detailed instructions on how to
              enter your enrollment data.
            </p>

            <p className="u-margin-bottom">
              When you have completed entering your student enrollment data save
              the file to your computer. Click on the ‘Upload Student Enrollment
              File’ button and follow the instructions provided. Note that all
              uploads are validated to ensure that data are correctly formatted
              and include all required fields.
            </p>
            <ul className="u-margin-bottom">
              <li>
                If the validation process is successful, you will receive
                onscreen and an email notification that your upload is complete.
              </li>
              <li>
                If the validation process uncovers errors, you will be provided
                a detailed list of errors onscreen and in an email. You must fix
                all identified errors and resubmit your file.
              </li>
            </ul>
            <p className="u-error">
              Note: Due to a high volume of data, your upload will appear the
              following day.
            </p>
          </Instruction>

          <FlexRow>
            <FlexRow>
              <FlexGroup>
                <h3>Guidance</h3>
                {map(programLevels, (level: number) => {
                  switch (level) {
                    case PROGRAM_LEVEL_ENUM.Secondary:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadFileFromAzure(
                              uploadParams,
                              'guidance|secondary_guidance.docx'
                            ).catch(() => {
                              toast(
                                <ToastNotification
                                  status="error"
                                  message={
                                    StringResources.Errors.FileDownloadFailed
                                  }
                                />
                              );
                            });
                          }}
                          text={'View Secondary Guidance'}
                        />
                      );
                    case PROGRAM_LEVEL_ENUM.Postsecondary:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadFileFromAzure(
                              uploadParams,
                              'guidance|postsecondary_guidance.docx'
                            ).catch(() => {
                              toast(
                                <ToastNotification
                                  status="error"
                                  message={
                                    StringResources.Errors.FileDownloadFailed
                                  }
                                />
                              );
                            });
                          }}
                          text={'View Postsecondary Guidance'}
                        />
                      );
                    case PROGRAM_LEVEL_ENUM['Middle School']:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadFileFromAzure(
                              uploadParams,
                              'guidance|middle_guidance.docx'
                            ).catch(() => {
                              toast(
                                <ToastNotification
                                  status="error"
                                  message={
                                    StringResources.Errors.FileDownloadFailed
                                  }
                                />
                              );
                            });
                          }}
                          text={'View Middle School Guidance'}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </FlexGroup>
              <FlexGroup>
                <h3>Templates</h3>
                {map(programLevels, (level: number) => {
                  switch (level) {
                    case PROGRAM_LEVEL_ENUM.Secondary:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadTemplate(level, 'secondary');
                          }}
                          text={'Download Secondary Template'}
                        />
                      );
                    case PROGRAM_LEVEL_ENUM.Postsecondary:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadTemplate(level, 'postsecondary');
                          }}
                          text={'Download Postsecondary Template'}
                        />
                      );
                    case PROGRAM_LEVEL_ENUM['Middle School']:
                      return (
                        <Button
                          disabled={isLocked}
                          key={level}
                          className="form__btn--cancel u-margin-bottom"
                          onClick={() => {
                            downloadTemplate(level, 'middle');
                          }}
                          text={'Download Middle School Template'}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </FlexGroup>
            </FlexRow>
            <div style={{ marginLeft: '1.8rem' }} />

            <FlexGroup>
              <h3>Upload</h3>
              <Button
                style={{ height: '6rem' }}
                className="form__btn--add grant-schedule__upload-btn"
                onClick={() => {
                  setOpen(!isOpen);
                }}
                text="+ Upload Student Enrollment File"
              />
            </FlexGroup>
          </FlexRow>

          <Dialog
            preventOutsideDismiss
            open={isOpen}
            onClose={() => {
              setOpen(false);
            }}
          >
            <div className="azure-dialog">
              <div className="azure-dialog__title form__section-title u-flex u-flex-full-center">
                <div className="u-flex-grow-1">Upload files</div>
                {!uploading && (
                  <IconButton
                    name="cross"
                    tooltip="Exit"
                    onClick={() => setOpen(false)}
                  />
                )}
              </div>
              <div style={{ minWidth: '64rem' }}>
                {uploading ? (
                  <DatatableLoading
                    loading={uploading}
                    messageBefore="Uploading File..."
                  />
                ) : (
                  <FileDropZone
                    onUpload={(incomingFile: File) => {
                      uploadFile(incomingFile);
                    }}
                  />
                )}
              </div>
            </div>
          </Dialog>
        </Paper>
      </div>
      <Modal {...modalProps}>
        <Paper>
          <h2>File Upload Encountered Errors</h2>
          <div>
            <p>The following errors were encountered during file upload:</p>
            {map(errors, (error: string, index: number) => {
              return (
                <p key={index} className="u-error">
                  {error}
                </p>
              );
            })}
          </div>
          <div className="form__btn-container">
            <Button
              onClick={close}
              className="form__btn--cancel"
              text="Close Error Window"
            />
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
