import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import HotButton from 'components/Common/HotButton';

type HotYearProps = {
  title: string;
  prefix: string;
  handleClick: Function;
  year: number;
  activeYear: number;
};

export const HotYearComponent: React.FC<HotYearProps> = (props) => {
  const { activeYear, title, handleClick, prefix, year } = props;
  const isActive = (value: number) => {
    return activeYear === value;
  };

  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">{title}</h2>
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <HotButton
            className="t-year-three"
            iconName="pencil"
            text={`${prefix}: ${year - 3} - ${year - 2}`}
            active={isActive(year - 2)}
            onClick={() => {
              handleClick(year - 2);
            }}
          />
        </FlexGroup>
        <FlexGroup>
          <HotButton
            className="t-year-one"
            iconName="pencil"
            text={`${prefix}: ${year - 2} - ${year - 1}`}
            active={isActive(year - 1)}
            onClick={() => {
              handleClick(year - 1);
            }}
          />
        </FlexGroup>
        <FlexGroup>
          <HotButton
            className="t-year-two"
            iconName="pencil"
            text={`${prefix}: ${year - 1} - ${year}`}
            active={isActive(year)}
            onClick={() => {
              handleClick(year);
            }}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  );
};
