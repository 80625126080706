import { CtaInstructorProgram } from './CtaInstructorProgram.types';

export interface CtaInstructorSalary {
  ctaInstructorId: number;
  annualContractBaseSalary: number;
  actualSalaryEarned: number;
  numberOfSickDays: number;
  extraAdminStipend: number;
  programs: CtaInstructorProgram[];
}

export const emptyCtaInstructorSalary = () => {
  return {
    ctaInstructorId: -1,
    annualContractBaseSalary: 0,
    actualSalaryEarned: 0,
    numberOfSickDays: 0,
    extraAdminStipend: 0,
    programs: [],
  };
};
