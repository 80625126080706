import React, { useState } from 'react';
import {
  AnnualSpending,
  StrategySelectItem,
  emptySpendingCategory,
  SpendingCategory,
} from 'types';
import { FlexRow } from 'layouts';
import { getSpendingCategory, calcFunds } from 'util/annualSpendingUtility';
import { findObject, upsertInPlace } from 'util/arrayUtility';
import { createTempId, buildOnChange } from 'util/formHelpers';
import { sumNumbers } from 'util/mathHelpers';
import { formatMoney } from 'util/moneyHelpers';
import { filter, curry, map } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setAnnualSpendingPlan,
  updateSpendingCategories,
} from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import SpendingCategoryComponent from '../SpendingCategory';
import StrategySelectBox from 'components/Common/SelectBoxes/StrategySelectBox';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type ProjectPlanAllocationProps = {
  annualSpendingPlan: AnnualSpending;
  strategies: StrategySelectItem[];
  spendingCategories: SpendingCategory[];
  isLocked: boolean;
};

export const ProjectPlanAllocation: React.FC<ProjectPlanAllocationProps> = (
  props
) => {
  const {
    annualSpendingPlan,
    spendingCategories,
    strategies,
    isLocked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();

  // --[ local state ]---------------------------------------------------------
  const [isDirty, setIsDirty] = useState(false);

  // --[ component logic ]-----------------------------------------------------
  const onAnnualSpendingChange = buildOnChange(setAnnualSpendingPlan, dispatch);
  const onCategoryChange = buildOnChange(updateSpendingCategories, dispatch);

  const handleSelect = (data: Partial<StrategySelectItem>) => {
    const exists = findObject(data, annualSpendingPlan.spendingCategories);
    if (exists) return;
    const strategy = findObject(data, strategies);
    const spendingCategoryId = createTempId(
      annualSpendingPlan.spendingCategories,
      'spendingCategoryId'
    );
    const newCategory: SpendingCategory = {
      ...emptySpendingCategory(),
      ...strategy,
      annualSpendingPlanId: annualSpendingPlan.annualSpendingPlanId,
      spendingCategoryId,
    };
    const newCategories = upsertInPlace(
      annualSpendingPlan.spendingCategories,
      newCategory,
      'strategyId'
    );
    setIsDirty(true);
    return onAnnualSpendingChange(annualSpendingPlan, {
      spendingCategories: newCategories,
    });
  };

  const handleDelete = (strategyId: number) => {
    const newCategories = filter(
      annualSpendingPlan.spendingCategories,
      (item) => {
        return item.strategyId !== strategyId;
      }
    );
    setIsDirty(true);
    return onAnnualSpendingChange(annualSpendingPlan, {
      spendingCategories: newCategories,
    });
  };

  const handleChange = curry(
    (spendingCategory: SpendingCategory, data: Partial<SpendingCategory>) => {
      const { allocatedFunds } = data;
      setIsDirty(true);
      return onCategoryChange(spendingCategory, { allocatedFunds });
    }
  );

  // --[ display logic ]-------------------------------------------------------
  const careerInfoFunds = getSpendingCategory(1, spendingCategories);
  const partnershipsFunds = getSpendingCategory(2, spendingCategories);
  const qualityFunds = getSpendingCategory(3, spendingCategories);
  const equityFunds = getSpendingCategory(4, spendingCategories);
  const instructorsFunds = getSpendingCategory(5, spendingCategories);
  const workBasedLearningFunds = getSpendingCategory(6, spendingCategories);
  const improvementPlanFunds = getSpendingCategory(10, spendingCategories);

  const totalAllocations = formatMoney(
    sumNumbers([
      calcFunds(careerInfoFunds),
      calcFunds(partnershipsFunds),
      calcFunds(qualityFunds),
      calcFunds(equityFunds),
      calcFunds(instructorsFunds),
      calcFunds(workBasedLearningFunds),
    ])
  );

  return (
    <div>
      <h2 className="spending-category__header u-section-title-text">
        Allocate Project Plan Funds
      </h2>
      <div className="spending-category__summary">
        <h3 className="spending-category__title">Total Allocated Funding</h3>
        <h3 className="spending-category__title">${totalAllocations}</h3>
      </div>
      <SecureWrap isLocked={isLocked} component={['grantsannualspending']}>
        <FlexRow>
          <StrategySelectBox onChange={handleSelect} shouldReset={true} />
        </FlexRow>
      </SecureWrap>
      {improvementPlanFunds.length > 0 && (
        <div className="form__section">
          <h2>Improvement Plan</h2>
          {map(
            improvementPlanFunds,
            (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isDirty={isDirty}
                    isLocked={isLocked}
                    index={index}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
      {careerInfoFunds.length > 0 && (
        <div className="form__section">
          <h2>Career Advisement & Development</h2>
          {map(careerInfoFunds, (category: SpendingCategory, index: number) => {
            return (
              <React.Fragment key={index}>
                <SpendingCategoryComponent
                  isDirty={isDirty}
                  isLocked={isLocked}
                  index={index}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  spendingCategory={category}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}

      {partnershipsFunds.length > 0 && (
        <div className="form__section">
          <h2>Local Workforce Alignment</h2>
          {map(
            partnershipsFunds,
            (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isDirty={isDirty}
                    isLocked={isLocked}
                    index={index}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}

      {qualityFunds.length > 0 && (
        <div className="form__section">
          <h2>Quality & Student Performance</h2>
          {map(qualityFunds, (category: SpendingCategory, index: number) => {
            return (
              <React.Fragment key={index}>
                <SpendingCategoryComponent
                  isDirty={isDirty}
                  isLocked={isLocked}
                  index={index}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  spendingCategory={category}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}

      {equityFunds.length > 0 && (
        <div className="form__section">
          <h2>Progress Toward Improving Equity</h2>
          {map(equityFunds, (category: SpendingCategory, index: number) => {
            return (
              <React.Fragment key={index}>
                <SpendingCategoryComponent
                  isDirty={isDirty}
                  isLocked={isLocked}
                  index={index}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  spendingCategory={category}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}

      {instructorsFunds.length > 0 && (
        <div className="form__section">
          <h2>Recruitment & Retention of Faculty/Staff</h2>
          {map(
            instructorsFunds,
            (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isDirty={isDirty}
                    isLocked={isLocked}
                    index={index}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}

      {workBasedLearningFunds.length > 0 && (
        <div className="form__section">
          <h2>Work-based Learning</h2>
          {map(
            workBasedLearningFunds,
            (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isDirty={isDirty}
                    isLocked={isLocked}
                    index={index}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};
