import { ContactType, Email, PermissionRole, PhoneNumber } from 'types';
import { District, School, Institute, Department } from 'types/common';

export interface UniversalContact {
  aadB2cId: string;
  city: string;
  contactTypes: ContactType[];
  credentialId: string;
  credentialArea: string;
  credentialExpirationDate?: Date;
  credentialOverride: boolean;
  departments: Department[];
  districts: District[];
  dob?: Date;
  fax: string;
  firstName: string;
  institutes: Institute[];
  isAadB2c: boolean;
  isActive: boolean;
  isNew: boolean;
  isTeacher: boolean;
  isCteDirector: boolean;
  isCteSpecialist: boolean;
  isWorkBasedLearning: boolean;
  lastName: string;
  loginEmail: string;
  middleName: string;
  modifiedBy: number;
  notes: string;
  permissionRoles: PermissionRole[];
  programLevelIds: number[];
  schools: School[];
  state: string;
  street1: string;
  street2: string;
  title: string;
  universalContactId: number;
  universalContactEmail: Email[];
  universalContactPhone: PhoneNumber[];
  zipCode: string;
  instituteIds: number[];
  districtIds: number[];
}

export const emptyUniversalContact = (): UniversalContact => {
  return {
    aadB2cId: '',
    city: '',
    contactTypes: [],
    credentialId: '',
    credentialExpirationDate: undefined,
    credentialOverride: false,
    departments: [],
    districts: [],
    dob: undefined,
    fax: '',
    firstName: '',
    institutes: [],
    isAadB2c: false,
    isActive: true,
    isNew: false,
    isTeacher: false,
    credentialArea: '',
    isCteDirector: false,
    isCteSpecialist: false,
    isWorkBasedLearning: false,
    loginEmail: '',
    lastName: '',
    middleName: '',
    modifiedBy: -1,
    notes: '',
    permissionRoles: [],
    programLevelIds: [],
    schools: [],
    state: '',
    street1: '',
    street2: '',
    title: '',
    universalContactEmail: [{ email: '' }],
    universalContactId: -1,
    universalContactPhone: [{ label: 'Phone', number: '' }],
    zipCode: '',
    instituteIds: [],
    districtIds: [],
  };
};
