import React from 'react';
import { useParams } from 'react-router';
import Button from 'components/Common/FormElements/Button';
import SideNav from 'components/Common/SideNav';
import { SideNavData } from 'types/ui/SideNavData.types';
import { isApplicaionIdValid } from 'util/global';

type SideNavProps = {
  navTrayClosed: boolean;
  currentNavAchor: string;
  navData: SideNavData;
  setNavTrayClosed: Function;
};

export const GrantSideNav: React.FC<SideNavProps> = (props) => {
  const { navData } = props;

  const { grantFiscalYearId: gfyid, voucherId: vid }: any = useParams();

  const grantFiscalYearId = Number(gfyid);
  const voucherId = Number(vid);

  const getEntityIds = () => {
    // Include voucher only if it is found in the URL
    return [
      grantFiscalYearId,
      ...(isApplicaionIdValid(voucherId) ? [voucherId] : []),
    ];
  };

  const actionButton = () => {
    return navData.scrollNav ? (
      undefined
    ) : (
      <div className="side-nav__btn-container">
        <Button
          text="Go to Overview"
          asLink={true}
          to={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          className="side-nav__action-btn"
        />
      </div>
    );
  };

  return (
    <SideNav
      actionButton={actionButton()}
      navagationParameters={getEntityIds()}
      iconName={navData.scrollNav ? 'documents' : 'pencil'}
      navData={navData}
      titleBarColor={navData.scrollNav ? 'blue' : 'orange'}
    />
  );
};
