import React, { useRef, CSSProperties, useState } from 'react';
import { useModal } from 'react-morphing-modal';
import { CTAAdminInformation } from 'types';
import { safeGet } from 'util/objectUtility';
import { doNothing } from 'util/global';
import { toast } from 'react-toastify';
import { equals } from 'ramda';
import { every } from 'lodash';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Instruction from 'components/Common/Instruction';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type PerPupilOperatingRevenueProps = {
  adminRecord: CTAAdminInformation;
  loadCTAAdminInformation: Function;
  saveSubmissionOpenEmail: Function;
};

export const PerPupilOperatingRevenueComponent: React.FC<
  PerPupilOperatingRevenueProps
> = (props) => {
  const {
    adminRecord,
    loadCTAAdminInformation,
    saveSubmissionOpenEmail,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { open, close } = useModal();
  const modalRef = useRef(null);
  const mountedRef = useRef(true);

  // --[ local state ]---------------------------------------------------------
  const [hasRequested, setHasRequested] = useState(false);

  // --[ component logic ]-----------------------------------------------------
  // get :: string -> CTAAdminInformation[string]
  const get = safeGet(adminRecord);

  // toggleHasRequested :: empty -> void
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };

  // sendMail :: empty -> void
  const sendMail = () => {
    toggleHasRequested();
    saveSubmissionOpenEmail(adminRecord.year)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.EmailScheduled}
          />
        );
      })
      .then(() => {
        loadCTAAdminInformation()
          .catch(doNothing)
          .finally(close);
      })
      .catch(doNothing);
  };

  // --[ display logic ]-------------------------------------------------------
  const pstyle: CSSProperties = {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 500,
  };

  const disable: boolean = every([
    equals(true, get('submissionsOpen')),
    equals(false, hasRequested),
  ]);

  return (
    <React.Fragment>
      <Instruction title="Per Pupil Operating Revenue">
        <p>
          When the final PPOR figures are available, the data will need to be
          provided to the CCCS CTE Development team for entry into the database.
          Once those figures have been correctly entered into the database for
          each district and charter school, click on the 'Open Submission and
          Send Email' button. This will trigger an automatic email announcement
          alerting eligible sites that their PPOR amount has been calculated and
          that they may now submit their CTA reports.
        </p>
        <p>
          <span className="u-color-red">
            Do not click this button until you have confirmed the PPOR data is
            correct and has been entered into the database. The email will only
            send one time.
          </span>
        </p>
      </Instruction>
      <div className="form__row" ref={modalRef}>
        <ConfirmationDialog
          disabled={disable}
          header="Send PPOR Email to Districts?"
          tooltip="Send PPOR Emails"
          onConfirm={sendMail}
          activateButton={
            <Button
              disabled={disable}
              onClick={() => open(modalRef)}
              text="Open submission and send email"
              className="button--outline cta-grid-button"
            />
          }
        >
          <p style={pstyle}>
            Sending this email is final and will email all districts where a per
            pupil revenue amount has been set.
          </p>
          <br />
          <p style={pstyle}>
            There are{' '}
            <span style={{ fontWeight: 'bold', color: '#bb3131' }}>
              {get('recipientCount')}
            </span>{' '}
            recipients selected to recieve this email, would you like to send
            it?
          </p>
        </ConfirmationDialog>
        <div className="u-flex-grow-1" />
      </div>
    </React.Fragment>
  );
};
