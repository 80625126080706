import React from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';
import { WorkflowSummary } from 'components/Program/ProgramWorkflow/WorkflowConnect';
import { ProgramCareerPathwaySummary } from 'components/Program/CareerPathway/CareerPathwayConnect';
import { ProgramAwardSummary } from 'components/Program/Awards/AwardsConnect';
import { CourseCreditsSummary } from 'components/Program/CourseCredits/CourseCreditsConnect';
import { PathwayCourseSummary } from 'components/Program/PathwayCourses/PathwayCourseConnect';
import { ProgramsOfStudySummary } from '../ProgramsOfStudy/ProgramsOfStudyConnect';
import { ProgramStep, Workflow, Program } from 'types';
import { ArticulationSummary } from '../Articulations/ArticulationsConnect';
import { AssurancesSummary } from '../Assurances/ProgramAssurancesConnect';
import { ContactsSummary } from '../Contacts/ContactsConnect';
import { GeneralSummary } from '../ProgramGeneral/ProgramGeneralConnect';
import ProgramSteps from 'components/Program/ProgramSteps';

type ProgramStepsSelectionsProps = {
  steps: ProgramStep[];
  program: Program;
  programInstanceId: number;
  readOnly: boolean;
  workflow: Workflow;
};

const SummaryComponent: React.FC<any> = (props) => {
  const { programInstanceId: pid }: any = useParams();
  const programInstanceId = Number(pid);
  switch (props.component) {
    case 'general':
      return <GeneralSummary />;
    case 'articulations':
      return <ArticulationSummary />;
    case 'assurances':
      return <AssurancesSummary />;
    case 'contacts':
      return <ContactsSummary />;
    case 'approval':
      return <WorkflowSummary />;
    case 'pathways':
      return <ProgramCareerPathwaySummary />;
    case 'courses':
      return <PathwayCourseSummary />;
    case 'awards':
      return <ProgramAwardSummary />;
    case 'coursecredits':
      return <CourseCreditsSummary />;
    case 'programofstudy':
      return <ProgramsOfStudySummary programInstanceId={programInstanceId} />;
    default:
      return null;
  }
};

export const ProgramStepsSelections: React.FC<ProgramStepsSelectionsProps> = (
  props
) => {
  const { steps, programInstanceId, readOnly } = props;
  return (
    <div className="program-steps-selection">
      {map(steps, (step) => {
        return (
          <ProgramSteps
            readOnly={readOnly}
            key={step.stepName}
            stepInfo={step}
            programInstanceId={programInstanceId}
            component={<SummaryComponent component={step.nav.anchor} />}
          />
        );
      })}
    </div>
  );
};
