import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { SET_QUALITY, SET_QUALITY_LOADING } from './quality.actions';

const initState = baseInitialState;

export const qualityReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_QUALITY:
      return actionUpdateRecords(state, action.payload);

    case SET_QUALITY_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
