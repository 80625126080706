import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantRecipientAgreementDocument from 'components/Grants/Applications/AwardPacket/Documents/GrantRecipientAgreementDocument';
import Paper from 'components/Common/Paper';

const GrantRecipientAgreementPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <Paper>
        <GrantRecipientAgreementDocument />
      </Paper>
    </BaseLayout>
  );
};

export default GrantRecipientAgreementPage;
