import React from 'react';
import BreadcrumbComponent from 'components/Common/Breadcrumb';
import { useSelector } from 'react-redux';
import { getFirstRecord_FerpaAgreement } from 'redux/common/_selectors';
import FerpaAgreement from 'components/DataCollection/FerpaAgreement';

type GrantsLayoutProps = {
  children: any;
};

export const DataCollectionLayout: React.FC<GrantsLayoutProps> = (props) => {
  const { children } = props;

  const ferpa = useSelector(getFirstRecord_FerpaAgreement);

  return (
    <React.Fragment>
      <BreadcrumbComponent />
      <div className="no-tray-layout">
        <section className="no-tray-layout__body">
          <div className="no-tray-layout__content">
            {ferpa && ferpa.agreed ? children : <FerpaAgreement />}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
