import { connect } from 'react-redux';
import { EditSnapshotStudent } from './EditSnapshotStudent';
import {
  loadSnapshotStudent,
  saveSnapshotStudent,
} from 'redux/datacollection/studentdata';
import { getIsDataFetching_SnapshotStudent } from 'redux/datacollection/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const isFetching = getIsDataFetching_SnapshotStudent(state);
  return {
    isFetching,
  };
};

const dispatchToProps = {
  loadSnapshotStudent, // Don't think we need this, but just in case
  saveSnapshotStudent,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(EditSnapshotStudent);
