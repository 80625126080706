import { SideNav } from './SideNav.component';
import { getNavTrayClosed } from 'redux/application/selectors';
import { setNavTrayClosed } from 'redux/application';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const navTrayClosed = getNavTrayClosed(state);

  return {
    navTrayClosed,
  };
};

const dispatchProps = {
  setNavTrayClosed,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SideNav);
