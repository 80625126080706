import { HamburgerMenuDetails } from 'types';

export const fetchMenuItems = (): HamburgerMenuDetails[] => {
  return [
    {
      name: 'index',
      details: {
        title: 'Home',
        link: '/',
        icon: 'earth',
      },
    },
    {
      component: 'programs',
      name: 'program-approvals',
      details: {
        title: 'Program Approvals',
        link: '/programs',
        icon: 'rocket',
      },
    },
    {
      component: 'grants',
      name: 'grants',
      details: {
        title: 'Grants Portal',
        link: '/grants',
        icon: 'diamond',
      },
    },
    {
      component: 'datacollection',
      name: 'data-collection',
      details: {
        title: 'Data Collection',
        link: '/data-collection',
        icon: 'layers',
      },
    },
    {
      component: 'ctageneral',
      name: 'cta',
      details: {
        title: 'CTA Reporting',
        link: '/cta',
        icon: 'report',
      },
    },
    {
      component: 'universalcontacts',
      name: 'contacts',
      details: {
        title: 'Universal Contacts',
        link: '/contacts',
        icon: 'users',
      },
    },
    {
      component: 'reports',
      name: 'reports',
      details: {
        title: 'Reports',
        link: '/cccs-reports',
        icon: 'documents',
      },
    },
  ];
};
