import { GRANTS_KEY, GRANTS_ASSURANCES_KEY } from 'redux/keys';
import { QuestionGroup } from 'types';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';

export const GRANTS_ASSURANCES_PATH = [GRANTS_KEY, GRANTS_ASSURANCES_KEY];

export const getRecords_GrantAssurances = (state: any) => {
  return getRecords<QuestionGroup>(state, GRANTS_ASSURANCES_PATH);
};

export const getIsDataFetching_GrantAssurances = (state: any) => {
  return getIsDataFetching(state, GRANTS_ASSURANCES_PATH);
};
