import { COMMON_KEY, COMMON_PROGRAM_CONTACT_TYPES_KEY } from 'redux/keys';
import { ProgramContactType } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';

export const PROGRAM_CONTACT_TYPES_PATH = [
  COMMON_KEY,
  COMMON_PROGRAM_CONTACT_TYPES_KEY,
];

export const getIsDataFetching_ProgramContactTypes = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_CONTACT_TYPES_PATH);
};

export const getRecords_ProgramContactTypes = (
  state: any
): ProgramContactType[] => {
  return getRecords<ProgramContactType>(state, PROGRAM_CONTACT_TYPES_PATH);
};

export const shouldRefreshReduxState_ProgramContactTypes = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ProgramContactType>(
    state,
    PROGRAM_CONTACT_TYPES_PATH
  );
};

export const getLastUpdated_ProgramContactTypes = (state: any): number => {
  return getLastUpdated(state, PROGRAM_CONTACT_TYPES_PATH);
};
