import { connect } from 'react-redux';
import { OptionMap, SelectWrapper, CTASchool } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';
import { CTA_GENERAL_PATH, getRecords_CTASchool } from 'redux/cta/_selectors';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, filter, label, onChange, records: baseRecords } = ownProps;

  const records = getRecordsUtility<CTASchool>(
    state,
    CTA_GENERAL_PATH,
    getRecords_CTASchool,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<CTASchool> = {
    key: 'ctaSchoolId',
    label: 'schoolName',
    value: 'ctaSchoolId',
  };

  return {
    label: label ? label : 'School',
    name: 'ctaSchoolId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
