import React from 'react';
import Textarea from 'components/Common/FormElements/Textarea';
import { FlexRow } from 'layouts';

type ContactNotesProps = {
  state: string;
  onChange: Function;
};

export const ContactNotes: React.FC<ContactNotesProps> = (props) => {
  const { onChange, state } = props;

  return (
    <div className="form__section">
      <FlexRow>
        <h3 className="u-section-title-text">Additional Information</h3>
      </FlexRow>
      <FlexRow>
        <Textarea name="notes" value={state} onChange={onChange} />
      </FlexRow>
    </div>
  );
};
