import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { ProgramPathway, PathwayCourse } from 'types';
import { filter } from 'lodash';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { renderData, compose, prop } from 'util/objectUtility';
import CourseSummaryTotal from '../CourseSummaryTotals';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';
import Table from 'components/Common/Table';

type PathwayComponentProps = {
  programPathway: ProgramPathway;
};

export const PathwaySuperSummaryComponent: React.FC<PathwayComponentProps> = (
  props
) => {
  const { programPathway } = props;

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderCourseName = (row: PathwayCourse) => {
    return (
      <React.Fragment>
        {row.preferredTitle ? (
          <div className="u-flex-vertical">
            <div>{row.preferredTitle}</div>
            {row.preferredTitle && <div>{`State Name: ${row.courseName}`}</div>}
          </div>
        ) : (
          row.courseName
        )}
      </React.Fragment>
    );
  };

  const renderCustomCourse = (row: PathwayCourse) => {
    return (
      <Icon
        name={row.isCustomCourse ? 'circleFilled' : 'circleOpen'}
        className="consortium-view__status-icon u-icon-green"
      />
    );
  };

  const CourseLevel = (row: PathwayCourse) => {
    return row.courseLevel === 255
      ? renderData(undefined)
      : renderData(row.courseLevel);
  };

  const ContactHours = compose(
    renderData,
    prop('contactHours')
  );
  const CourseDescription = compose(
    renderData,
    prop('courseDescription')
  );
  const AcademicCredit = compose(
    renderCircle,
    prop('isAcademicCredit')
  );
  const AdvancedCreditOption = compose(
    renderCircle,
    prop('isAdvancedCreditOption')
  );

  const generateHeaders = (): TableColumnProps[] => {
    const baseHeaders: TableColumnProps[] = [
      {
        name: 'Course Name',
        cell: renderCourseName,
        sortable: true,
        selector: 'courseName',
        style: {
          minWidth: '20%',
        },
      },
      {
        name: 'Course Description',
        cell: CourseDescription,
        sortable: false,
        selector: 'courseDescription',
        style: {
          minWidth: '50%',
        },
      },
      {
        name: 'Custom Course',
        cell: renderCustomCourse,
        sortable: true,
        selector: prop('isCustomCourse'),
        center: true,
        width: '15%',
      },
      {
        name: 'Contact Hours',
        cell: ContactHours,
        sortable: true,
        selector: 'contactHours',
        center: true,
        width: '15%',
      },
    ];

    const highSchoolHeaders: TableColumnProps[] = [
      {
        name: 'Course Level',
        cell: CourseLevel,
        selector: CourseLevel,
        sortable: true,
        center: true,
        width: '10%',
      },
      {
        name: 'Advanced Credit',
        cell: AdvancedCreditOption,
        center: true,
        width: '10%',
      },
      {
        name: 'Academic Credit',
        cell: AcademicCredit,
        center: true,
        width: '10%',
      },
    ];

    return programPathway.isMiddleSchool
      ? [...baseHeaders]
      : [...baseHeaders, ...highSchoolHeaders];
  };

  const optInCourses = filter(programPathway.courses, { optIn: true });

  return (
    <React.Fragment>
      <Paper>
        <h2 className="u-section-title">{programPathway.careerPathwayName}</h2>
        {!programPathway.isMiddleSchool && (
          <CourseSummaryTotal programPathway={programPathway} />
        )}
        <Table
          columns={generateHeaders()}
          data={programPathway && optInCourses}
        />
        {false && (
          <FlexRow>
            <FlexGroup>
              <label htmlFor="careerClusters" className="form__label">
                Definition of Completer
              </label>
              <p>
                {programPathway.definitionCompleter
                  ? programPathway.definitionCompleter
                  : 'No Definition of Completion Provided'}
              </p>
            </FlexGroup>
          </FlexRow>
        )}
      </Paper>
    </React.Fragment>
  );
};
