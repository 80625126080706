import { connect } from 'react-redux';
import { ActiveTeacherStatusComponent } from './ActiveTeacherStatus.component';
import { insertActiveTeacherComplete } from 'redux/datacollection/activeteachers';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {
  insertActiveTeacherComplete,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ActiveTeacherStatusComponent);
