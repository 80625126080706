import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { CareerPathwaySummaryComponent } from 'components/Program/CareerPathway/CareerPathwaySummary.component';
import { CareerPathwayEditComponent } from 'components/Program/CareerPathway/CareerPathwayEdit.component';
import {
  getIsDataFetching_ProgramCareerPathways,
  getRecords_ProgramCareerPathways,
  getIsProgramReadOnly,
} from 'redux/programs/_selectors';
import {
  loadProgramCareerPathways,
  saveProgramCareerPathways,
} from 'redux/programs/programcareerpathway';
import { loadCareerPathways } from 'redux/common/careerpathways';
import {
  getRecords_CareerPathways,
  getNextStep,
} from 'redux/common/_selectors';
import { getActiveProgramId } from 'redux/application/selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const isFetching = getIsDataFetching_ProgramCareerPathways(state);
  const programCareerPathways = getRecords_ProgramCareerPathways(state);
  const careerPathways = getRecords_CareerPathways(state);
  const nextProgramStep = getNextStep(state, 'pathways');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    careerPathways,
    isFetching,
    nextProgramStep,
    programCareerPathways,
    readOnly,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
  loadProgramCareerPathways,
  saveProgramCareerPathways,
  loadCareerPathways,
};

export const ProgramCareerPathwayEdit = connect(
  mapStateToProps,
  dispatchToProps
)(CareerPathwayEditComponent);

export const ProgramCareerPathwaySummary = connect(
  mapStateToProps,
  dispatchToProps
)(CareerPathwaySummaryComponent);
