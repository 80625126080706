import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { map } from 'lodash';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { ProgramPathway } from 'types';
import { isApplicaionIdValid } from 'util/global';
import DatatableLoading from 'components/Common/DatatableLoading';
import PathwaySummaryComponent from 'components/Program/PathwayCourses/PathwaySummary';
import SummaryScroll from 'components/Common/SummaryScroll';

type CoursesProps = {
  isFetching: boolean;
  loadProgramPathway: Function;
  programPathways: ProgramPathway[];
  setCurrentNavAnchor: Function;
};

export const PathwayCourseSummaryComponent: React.FC<CoursesProps> = (
  props
) => {
  const {
    isFetching,
    loadProgramPathway,
    programPathways,
    setCurrentNavAnchor,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramPathway(programInstanceId);
    }
  }, [loadProgramPathway, programInstanceId]);

  return (
    <React.Fragment>
      <SummaryScroll componentStep="courses" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('courses')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      {isFetching ? (
        <DatatableLoading loading={true} messageBefore={'Loading Data'} />
      ) : (
        map(programPathways, (pathway: ProgramPathway, index: number) => {
          return (
            <PathwaySummaryComponent key={index} programPathway={pathway} />
          );
        })
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('courses')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </React.Fragment>
  );
};
