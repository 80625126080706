import { GRANTS_FISCAL_AGENTS_KEY, GRANTS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { FiscalAgent } from 'types';

export const FISCAL_AGENTS_PATH = [GRANTS_KEY, GRANTS_FISCAL_AGENTS_KEY];

export const getIsDataFetching_FiscalAgents = (state: any): boolean => {
  return getIsDataFetching(state, FISCAL_AGENTS_PATH);
};

export const getRecords_FiscalAgents = (state: unknown): FiscalAgent[] => {
  return getRecords(state, FISCAL_AGENTS_PATH);
};
