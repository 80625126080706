import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import AwardAddEdit from 'components/Program/Award';

type AwardPageProps = {
  programInstanceId: number;
};

export const AwardPage: React.FC<AwardPageProps> = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <AwardAddEdit {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};
