import React from 'react';
import { formatMoney } from 'util/moneyHelpers';
import { sumNumbers } from 'util/mathHelpers';
import { getSpendingCategory } from 'util/annualSpendingUtility';
import { AnnualSpending } from 'types';
import { buildOnChange } from 'util/formHelpers';
import { updateSpendingCategories } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import Tooltip from 'components/Common/Tooltip';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import SpendingCategoryComponent from '../SpendingCategory';

type AdministrativeCostsProps = {
  spendingPlan: AnnualSpending;
  validations: ValidationObject<any>;
  isLocked: boolean;
};

export const AdministrativeCosts: React.FC<AdministrativeCostsProps> = (
  props
) => {
  const { spendingPlan, validations, isLocked } = props;
  const { spendingCategories } = spendingPlan;
  const { getFieldValid } = validations;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();

  // --[ component logic ]-----------------------------------------------------
  const onChange = buildOnChange(updateSpendingCategories, dispatch);

  // --[ render logic ]--------------------------------------------------------
  const [indirectAdminCosts] = getSpendingCategory(7, spendingCategories);
  const [directAdminCosts] = getSpendingCategory(8, spendingCategories);
  const [needsAssessmentCosts] = getSpendingCategory(9, spendingCategories);

  const totalAllocations = formatMoney(
    sumNumbers([
      get(indirectAdminCosts, 'allocatedFunds'),
      get(directAdminCosts, 'allocatedFunds'),
      get(needsAssessmentCosts, 'allocatedFunds'),
    ])
  );

  return (
    <div>
      <Instruction title="Allocate Administrative Funds">
        <p>
          Administrative costs include indirect and direct costs as well as
          funds for conducting a needs assessment. The “Direct Administrative
          Costs” and “Needs Assessment Costs” link to pages where you will enter
          more detail. Indirect and direct funds are linked so altering one
          impacts the other. We recommend you complete the Needs Assessment
          section first.
        </p>
      </Instruction>
      <div className="spending-category__summary">
        <h3 className="spending-category__title">Total Allocated Funding</h3>
        <h3 className="spending-category__title">${totalAllocations}</h3>
      </div>
      <div className="u-margin-bottom-large">
        <SpendingCategoryComponent
          isLocked={isLocked}
          index={0}
          spendingCategory={needsAssessmentCosts}
          onChange={onChange}
          tooltip={
            <Tooltip
              indicator={
                <Icon name="info" className="spending-category__tooltip" />
              }
              tooltip="Your Institution may choose to allocate funds towards conducting the Needs Assessment. If you do, click the link to fill out the required Project Page."
            />
          }
        />
      </div>
      <div className="u-margin-bottom-large">
        <SpendingCategoryComponent
          isLocked={isLocked}
          index={0}
          spendingCategory={indirectAdminCosts}
          onChange={onChange}
          tooltip={
            <Tooltip
              indicator={
                <Icon name="info" className="spending-category__tooltip" />
              }
              tooltip="The Formula is: Available Indirect Funds = (Initial Award Estimate - Total Equipment Expense) * Negotiated Indirect Rate. The Indirect Funds Available will change as you create equipment expenses."
            />
          }
          validationStatus={getFieldValid('indirectAdminCosts')}
        />
      </div>
      <div className="u-margin-bottom-large">
        <SpendingCategoryComponent
          isLocked={isLocked}
          index={0}
          spendingCategory={directAdminCosts}
          onChange={onChange}
          tooltip={
            <Tooltip
              indicator={
                <Icon name="info" className="spending-category__tooltip" />
              }
              tooltip="Allocate up to the amount available toward direct costs after allocating funds toward indirect costs.  Then click the link to add expenses for those allocated funds.  The Formula is: Available Direct Funds = (Initial Award Estimate * 5%) - Allocated Indirect Funds.  The Direct Funds Available will change as your Indirect Costs change."
            />
          }
          validationStatus={getFieldValid('directAdminCosts')}
        />
      </div>
    </div>
  );
};
