import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { renderData, prop, notNull } from 'util/objectUtility';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { formatPhone } from 'util/universalcontact.utility';
import { every } from 'lodash';
import { getNonNullValue } from 'util/formHelpers';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Input from 'components/Common/FormElements/Input';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';

export const PersonalInformationBase: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v } = props;
  const programLevel = prop('programLevelId', student);
  const isSecondary = programLevel === PROGRAM_LEVEL_ENUM.Secondary;
  const isPostsecondary = programLevel === PROGRAM_LEVEL_ENUM.Postsecondary;
  const notNew = student.studentId !== '' && !student.newRecord;

  const disabled = (value: string | null) => every([notNull(value), notNew]);
  const disabledDOB = (value: string) =>
    every([notNull(value), student.studentId !== '']);

  return student ? (
    <React.Fragment>
      <FlexRow>
        <Input
          disabled={disabled(student.firstName)}
          errorMessage={v.getError('firstName')}
          name="firstName"
          onBlur={() => v.validate('firstName', student.firstName, student)}
          onChange={onChange}
          valid={v.getFieldValid('firstName')}
          value={getNonNullValue(student.firstName)}
        />
        <Input
          disabled={notNew}
          name="middleName"
          onChange={onChange}
          value={student.middleName}
        />
        <Input
          disabled={disabled(student.lastName)}
          errorMessage={v.getError('lastName')}
          name="lastName"
          onBlur={() => v.validate('lastName', student.lastName, student)}
          onChange={onChange}
          valid={v.getFieldValid('lastName')}
          value={getNonNullValue(student.lastName)}
        />
        <DatePicker
          disabled={disabledDOB(String(student.dob))}
          errorMessage={v.getError('dob')}
          label="Date of Birth"
          name="dob"
          onBlur={() => v.validate('dob', student.dob, student)}
          onChange={onChange}
          valid={v.getFieldValid('dob')}
          value={student.dob}
        />
      </FlexRow>
      <FlexRow>
        <FlexGroup>
          <div className="u-flex">
            {student.studentId !== '' ? (
              <FlexGroup>
                {isPostsecondary ? (
                  <LabelWithIcon
                    htmlFor="sasId"
                    iconName="info"
                    label="SASID (Optional)"
                    tooltip="State Assigned Student Identification Number is the 10-digit Colorado Department of Education student ID."
                  />
                ) : (
                  <LabelWithIcon
                    htmlFor="sasId"
                    iconName="info"
                    label="SASID"
                    tooltip="State Assigned Student Identification Number is the 10-digit Colorado Department of Education student ID."
                  />
                )}
                <Input
                  disabled={true}
                  className="input-display"
                  name="sasId"
                  noLabel={true}
                  value={renderData(student.sasId)}
                />
              </FlexGroup>
            ) : (
              <FlexGroup>
                <LabelWithIcon
                  htmlFor="sasId"
                  iconName="info"
                  label="SASID"
                  tooltip="State Assigned Student Identification Number is the 10-digit Colorado Department of Education student ID."
                />
                <Input
                  errorMessage={v.getError('sasId')}
                  name="sasId"
                  noLabel={true}
                  onBlur={() => v.validate('sasId', student.sasId, student)}
                  onChange={onChange}
                  valid={v.getFieldValid('sasId')}
                  value={getNonNullValue(student.sasId)}
                />
              </FlexGroup>
            )}
            {isSecondary || isPostsecondary ? (
              <Input
                errorMessage={v.getError('phone')}
                label="Phone Number (Optional)"
                name="phone"
                onBlur={() => v.validate('phone', student.phone, student)}
                onChange={onChange}
                valid={v.getFieldValid('phone')}
                value={formatPhone(student.phone)}
              />
            ) : (
              <FlexGroup />
            )}
          </div>
        </FlexGroup>
        {isSecondary || isPostsecondary ? (
          <FlexGroup>
            <Input
              errorMessage={v.getError('email')}
              label="Email (Optional)"
              name="email"
              onBlur={() => v.validate('email', student.email, student)}
              onChange={onChange}
              valid={v.getFieldValid('email')}
              value={student.email}
            />
          </FlexGroup>
        ) : (
          <FlexGroup />
        )}
      </FlexRow>
    </React.Fragment>
  ) : null;
};
