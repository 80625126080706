import {
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_CAREER_INFO_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_QUALITY_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_INSTRUCTORS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_PARTNERSHIPS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_EQUITY_KEY,
} from 'redux/keys';
import { getRecords, getIsDataFetching } from 'redux/utility/_exports';
import { AddressableElement } from 'types';
import { every } from 'lodash';

export const NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_NEEDS_ASSESSMENT_KEY,
];
export const CAREER_INFO_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_CAREER_INFO_KEY,
];
export const QUALITY_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_QUALITY_KEY,
];
export const INSTRUCTORS_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_INSTRUCTORS_KEY,
];
export const PARTNERSHIPS_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_PARTNERSHIPS_KEY,
];
export const WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_WORK_BASED_LEARNING_KEY,
];
export const EQUITY_ADDRESSABLE_ELEMENTS_PATH = [
  GRANTS_KEY,
  GRANTS_ADDRESSABLE_ELEMENTS_EQUITY_KEY,
];

export const getRecords_FourYearPlanAddressableElements = (
  state: any,
  entityType: string = 'all'
) => {
  switch (entityType) {
    case 'needs-assessment':
      return getRecords<AddressableElement>(
        state,
        NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'career-info':
      return getRecords<AddressableElement>(
        state,
        CAREER_INFO_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'quality':
      return getRecords<AddressableElement>(
        state,
        QUALITY_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'instructors':
      return getRecords<AddressableElement>(
        state,
        INSTRUCTORS_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'partnerships':
      return getRecords<AddressableElement>(
        state,
        PARTNERSHIPS_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'work-based-learning':
      return getRecords<AddressableElement>(
        state,
        WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'equity':
      return getRecords<AddressableElement>(
        state,
        EQUITY_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'all':
      return [
        ...getRecords<AddressableElement>(
          state,
          NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          CAREER_INFO_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          QUALITY_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          INSTRUCTORS_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          PARTNERSHIPS_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_PATH
        ),
        ...getRecords<AddressableElement>(
          state,
          EQUITY_ADDRESSABLE_ELEMENTS_PATH
        ),
      ];

    default:
      throw new Error('entityType could not be matched');
  }
};

export const getIsDataFetching_FourYearPlanAddressableElements = (
  state: any,
  entityType: string = 'all'
) => {
  switch (entityType) {
    case 'needs-assessment':
      return getIsDataFetching(
        state,
        NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'career-info':
      return getIsDataFetching(state, CAREER_INFO_ADDRESSABLE_ELEMENTS_PATH);

    case 'quality':
      return getIsDataFetching(state, QUALITY_ADDRESSABLE_ELEMENTS_PATH);

    case 'instructors':
      return getIsDataFetching(state, INSTRUCTORS_ADDRESSABLE_ELEMENTS_PATH);

    case 'partnerships':
      return getIsDataFetching(state, PARTNERSHIPS_ADDRESSABLE_ELEMENTS_PATH);

    case 'work-based-learning':
      return getIsDataFetching(
        state,
        WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_PATH
      );

    case 'equity':
      return getIsDataFetching(state, EQUITY_ADDRESSABLE_ELEMENTS_PATH);

    case 'all':
      return every([
        getIsDataFetching(state, NEEDS_ASSESSMENT_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, CAREER_INFO_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, QUALITY_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, INSTRUCTORS_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, PARTNERSHIPS_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, WORK_BASED_LEARNING_ADDRESSABLE_ELEMENTS_PATH),
        getIsDataFetching(state, EQUITY_ADDRESSABLE_ELEMENTS_PATH),
      ]);

    default:
      throw new Error('entityType could not be matched');
  }
};
