import React from 'react';
import Button from 'components/Common/FormElements/Button';
import LandingPageCard from 'components/Common/LandingPageCard';
import ScrollToTop from 'components/Common/ScrollToTop';
import AdminLayout from 'layouts/AdminLayout';

export const AdminPortalPage: React.FC = () => {
  return (
    <AdminLayout>
      <ScrollToTop />
      <div className="landing-page">
        <h1 className="landing-page__title">Admin Portal</h1>
        <div className="landing-page__cards">
          <LandingPageCard
            title="Permission Role Management"
            iconType="calendar"
            buttons={[
              <div />,
              <Button
                asLink={true}
                className="landing-page-card__btn t-admin-view"
                text="View / Edit"
                to="/admin/permission-roles"
              />,
            ]}
          />
        </div>
      </div>
    </AdminLayout>
  );
};
