import { useValidation } from 'de-formed-validations';
import { Voucher, VoucherSpendingCategory, Signature } from 'types';
import { every, map, isEmpty, find } from 'lodash';
import { VoucherSpendingCategoryValidations } from './VoucherCategory.validations';
import { compose, prop, converge } from 'util/objectUtility';
import { add, subtract, sumNumbers } from 'util/mathHelpers';
import { fmap } from 'util/arrayUtility';
import {
  getTotalAwardAmount,
  getVoucherCurrentRequests,
  getCategoryFundsRemaining,
  getTotalVoucherRemaining,
  getCategoryAmountForCurrentVoucher,
  getCategoryReimbursementRequestsToDate,
} from 'util/voucherUtility';

// define validation schema for validation hook
type ValidationState = {
  adminCosts: boolean;
  isVoucherSigned: boolean;
  totalExpenses: boolean;
  isVoucherSubmitDateValid: boolean;
  canSubmitVoucherOne: boolean;
  canSubmitVouchersTwoOrThree: boolean;
  canSubmitFinalVoucher: boolean;
  improvementPlan: boolean;
};

const calcRemainingTotal = compose(
  sumNumbers,
  fmap(getCategoryFundsRemaining),
  prop('categories')
);

const getImprovementPlanTotal = converge(add, [
  getCategoryAmountForCurrentVoucher,
  getCategoryReimbursementRequestsToDate,
]);

const validateImprovementPlan = (
  improvementPlan: VoucherSpendingCategory,
  voucher: Voucher
) => {
  const totalAward = getTotalAwardAmount(voucher);
  const totalRemaining = getTotalVoucherRemaining(voucher);
  const minimumImprovement = totalAward * 0.25;
  const improvementPlanSpent = getImprovementPlanTotal(improvementPlan);

  return improvementPlanSpent < minimumImprovement
    ? totalRemaining >= subtract(minimumImprovement, improvementPlanSpent)
    : true;
};

export const VouchersSubmitValidations = () => {
  const { getError, validate } = VoucherSpendingCategoryValidations();

  return useValidation<ValidationState>({
    adminCosts: [
      {
        errorMessage:
          'Direct and Indirect Admin Costs cannot exceed 5% of your grant award.',
        validation: (voucher: Voucher) => {
          const direct = find(voucher.categories, { categoryId: 8 });
          const indirect = find(voucher.categories, { categoryId: 7 });

          const directIndirect = sumNumbers([
            direct ? getCategoryReimbursementRequestsToDate(direct) : 0,
            direct ? getCategoryAmountForCurrentVoucher(direct) : 0,
            indirect ? getCategoryReimbursementRequestsToDate(indirect) : 0,
            indirect ? getCategoryAmountForCurrentVoucher(indirect!) : 0,
          ]);
          return directIndirect <= voucher.adminCostLimit;
        },
      },
    ],
    canSubmitVoucherOne: [
      {
        errorMessage:
          'Voucher 1 cannot exceed 30% of your Final Award. Request less in order to submit.',
        validation: (voucher: Voucher) => {
          return getVoucherCurrentRequests(voucher) < voucher.cannotExceed;
        },
      },
    ],
    canSubmitVouchersTwoOrThree: [
      {
        errorMessage:
          'You cannot request $0 for this Voucher. Please record actual amounts to increase reimbursement request.',
        validation: (voucher: Voucher) => {
          return getVoucherCurrentRequests(voucher) > 0;
        },
      },
    ],
    certifyAllExpenditures: [
      {
        errorMessage: 'All expenditures must be certified before submitting.',
        validation: (voucher: Voucher) => {
          return voucher.voucherName === 'Final Voucher'
            ? every([
                voucher.certifyAllExpenditures,
                voucher.granteeIndemnify,
                voucher.granteeMetCriteria,
              ])
            : voucher.certifyAllExpenditures;
        },
      },
    ],
    indirectAdminCost: [
      {
        errorMessage: getError('totalAmountForCurrentRequest'),
        validation: (voucher: Voucher) => {
          const indirect = find(voucher.categories, { categoryId: 7 });
          const isValid = (indirect: VoucherSpendingCategory) => {
            return validate(
              'totalAmountForCurrentRequest',
              indirect.totalAmountForCurrentRequest,
              indirect
            );
          };
          return indirect ? isValid(indirect) : true;
        },
      },
    ],
    isVoucherSigned: [
      {
        errorMessage: 'Not all required signatures have been provided.',
        validation: (voucher: Voucher) => {
          return every(
            map(voucher.authSignatures, (signature: Signature) => {
              return every([
                !isEmpty(signature.signedName.trim()),
                !isEmpty(signature.signedTitle.trim()),
              ]);
            })
          );
        },
      },
    ],
    isVoucherSubmitDateValid: [
      {
        errorMessage: 'Vouchers cannot be submitted past the voucher due date',
        validation: ({ voucherClose, submissionDate }: Voucher) => {
          const now = new Date(Date.now()).setHours(0, 0, 0, 0);
          const dueDate = new Date(voucherClose).setHours(0, 0, 0, 0);
          return submissionDate ? true : now <= dueDate;
        },
      },
    ],
    totalExpenses: [
      {
        errorMessage: 'Your total expenses exceeeds the amount available.',
        validation: (voucher: Voucher) => {
          return calcRemainingTotal(voucher) >= 0;
        },
      },
    ],
    improvementPlan: [
      {
        errorMessage:
          'At least 30% of your expenses should be applied toward your improvement plan.',
        validation: (voucher: Voucher) => {
          const improvementPlan = find(voucher.categories, { categoryId: 10 });
          return improvementPlan
            ? validateImprovementPlan(improvementPlan, voucher)
            : true;
        },
      },
    ],
  });
};
