export interface SupportingSalaryDocuments {
  districtSalaryInfoFilePath: string;
  districtScheduleInfoFilePath: string;
}

export const emptySupportingSalaryDocuments = () => {
  return {
    districtSalaryInfoFilePath: '',
    districtScheduleInfoFilePath: '',
  };
};
