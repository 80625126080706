import { connect } from 'react-redux';
import { CTACourseSelectBox } from './CTACourseSelectBox.component';
import ApplicationState from 'types/applicationstate.types';

export const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const { selector } = ownProps;
  const courses = selector(state);
  return {
    courses,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTACourseSelectBox);
