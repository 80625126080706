import { ParticipationDashboardFilters } from './Filters.component';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { prop, len as length, compose, isFalse } from 'util/objectUtility';
import { getRecords_DataCollectionSubmission } from 'redux/datacollection/_selectors';
import { isEqual } from 'util/formHelpers';
import {
  setSubmissionManagementFilterValue,
  clearSubmissionManagementFilters,
} from 'redux/filters/submissionmanagementfilters';

const mapStateProps = (state: any) => {
  const allSubmissions = getRecords_DataCollectionSubmission(state);

  const isDistrict = compose(
    isEqual('D'),
    prop('level')
  );
  const isInstitute = compose(
    isEqual('I'),
    prop('level')
  );

  const enrollment = compose(
    isFalse,
    prop('completed'),
    prop('enrollment')
  );
  const followUp = compose(
    isFalse,
    prop('completed'),
    prop('followUp')
  );
  const teachers = compose(
    isFalse,
    prop('completed'),
    prop('teacher')
  );

  const filterLengths = {
    incompleteFollowUp: length(filter(allSubmissions, followUp)),
    incompleteEnrollment: length(filter(allSubmissions, enrollment)),
    incompleteTeachers: length(filter(allSubmissions, teachers)),
    district: length(filter(allSubmissions, isDistrict)),
    institute: length(filter(allSubmissions, isInstitute)),
  };

  return {
    filterLengths,
  };
};

const clearFilterValue = clearSubmissionManagementFilters;
const setFilterValue = setSubmissionManagementFilterValue;

const dispatchProps = {
  clearFilterValue,
  setFilterValue,
};

export default connect(
  mapStateProps,
  dispatchProps
)(ParticipationDashboardFilters);
