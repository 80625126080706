import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { QuestionGroup } from 'types';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readAnnualReflectionGrantFormQuestions } from 'services/grants/grantformquestions.services';

const initialState = baseInitialState;

export const loadAnnualReflection = createActionThunk(
  'loadAnnualReflection',
  (grantFiscalYearId: number) => {
    return readAnnualReflectionGrantFormQuestions(grantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, QuestionGroup | QuestionGroup[]>(
  {
    ...baseLoadActionBuilder<QuestionGroup | QuestionGroup[]>(
      loadAnnualReflection
    ),
  },
  initialState
);
