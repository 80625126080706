import React, { useEffect, useState } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_BOTTOM_OFFSET,
  WINDOW_TOP_OFFSET,
} from 'constants/application.constants';
import { ComponentStep } from 'types/ui/ComponentStep.types';
import { map } from 'lodash';
import { useParams, useHistory } from 'react-router';
import { FiscalYear, getGrantScheduleOptions } from 'types';
//import { Link } from 'react-router-dom';
import FederalGrantId from '../FederalGrantId';
import GrantScheduleComponent from 'components/Grants/FiscalYearManagement/GrantSchedule';
import HotButton from 'components/Common/HotButton';
import Paper from 'components/Common/Paper';
import { Link } from 'react-router-dom';
import Button from 'components/Common/FormElements/Button';
import { createNewConsortiums } from 'services/grants/consortium.services';
import Loading from 'components/Common/Loading';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type FiscalYearStepSelectionProps = {
  steps: ComponentStep[];
  setCurrentNavAnchor: Function;
  currentFiscalYear: FiscalYear;
};

export const FiscalYearStepSelection: React.FC<FiscalYearStepSelectionProps> = (
  props
) => {
  const { steps, setCurrentNavAnchor, currentFiscalYear } = props;
  // --[ dependencies ]--------------------------------------------------------
  const { year: currentFy } = currentFiscalYear;
  const history = useHistory();
  const { fiscalYear: fy } = useParams<{ fiscalYear: any }>();
  const fiscalYear = Number(fy);

  const isActive = (year: number) => fiscalYear === year;

  const navigate = (year: any) => {
    history.push(`/grants/fiscal-year-management/${year}`);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setCurrentNavAnchor('intent-to-participate');
  }, [setCurrentNavAnchor, fiscalYear]);

  // --[ display logic ]-------------------------------------------------------
  return (
    <React.Fragment>
      <Loading isActive={isLoading} />
      <Paper>
        <div className="program__header">
          <FlexRow>
            <h2 className="program__title">Fiscal Year Management</h2>
          </FlexRow>
          <FlexRow>
            <p>
              This page enables you to manage the status of grantee
              participation in federally-funded CTE programming. Users can track
              fiscal year open and close dates on all plan types and vouchers
              and upload supporting documentation. The override button
              (available on most date ranges) allows users to stipulate open and
              close dates for the current fiscal year and to make retroactive
              changes, where necessary. Users may also upload or view historical
              data files documenting grantee needs assessment and awards. Users
              are also able to access the{' '}
              <Link to="/cccs-reports">Reporting Dashboard</Link> to run
              relevant reports.
            </p>
          </FlexRow>
        </div>
      </Paper>
      <Paper>
        <FlexRow>
          <h2 className="u-section-title-text">Select Fiscal Year</h2>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <HotButton
              iconName="pencil"
              text={`${currentFy - 2} - ${currentFy - 1}`}
              active={isActive(currentFy - 1)}
              onClick={() => navigate(currentFy - 1)}
            />
          </FlexGroup>
          <FlexGroup>
            <HotButton
              iconName="pencil"
              text={`${currentFy - 1} - ${currentFy}`}
              active={isActive(currentFy)}
              onClick={() => navigate(currentFy)}
            />
          </FlexGroup>
          <FlexGroup>
            <HotButton
              iconName="pencil"
              text={`${currentFy} - ${currentFy + 1}`}
              active={isActive(currentFy + 1)}
              onClick={() => navigate(currentFy + 1)}
            />
          </FlexGroup>
        </FlexRow>
      </Paper>
      <div className="program-steps-selection">
        <SecureWrap component="grants" requireAdmin={true}>
          <Paper>
            <FlexRow>
              <h2 id={'clone-grants'} className="u-section-title-text">
                {`Clone ${fiscalYear - 1} Grant Data`}
              </h2>
            </FlexRow>
            <div className="grant-schedule__row">
              <div className="grant-schedule__options">
                {`Clicking this button will copy the standalone and consortium data to Fiscal Year ${fiscalYear}`}
              </div>
              <Button
                aria-label="Clone Grant Data"
                className="button--outline grant-schedule__save"
                text="Clone Data"
                onClick={() => {
                  setIsLoading(true);
                  createNewConsortiums(fiscalYear)
                    .then(() => {
                      toast(
                        <ToastNotification
                          status="success"
                          message={'Grant data was successfully cloned'}
                        />
                      );
                    })
                    .catch(() => {
                      toast(
                        <ToastNotification
                          status="error"
                          message={'Something went wrong'}
                        />
                      );
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              />
            </div>
          </Paper>
        </SecureWrap>
        {map(steps, (step: ComponentStep, index: number) => {
          return (
            <React.Fragment key={index}>
              <Paper>
                <Waypoint
                  onEnter={() => setCurrentNavAnchor(step.nav.anchor)}
                  topOffset={WINDOW_TOP_OFFSET}
                  bottomOffset={WINDOW_BOTTOM_OFFSET}
                />
                <GrantScheduleComponent
                  key={step.nav.anchor}
                  anchor={step.nav.anchor}
                  title={step.nav.displayName}
                  options={getGrantScheduleOptions(step.nav.anchor as any)}
                />
                <Waypoint
                  onEnter={() => setCurrentNavAnchor(step.nav.anchor)}
                  topOffset={WINDOW_TOP_OFFSET}
                  bottomOffset={WINDOW_BOTTOM_OFFSET}
                />
              </Paper>
            </React.Fragment>
          );
        })}
        <FederalGrantId />
      </div>
    </React.Fragment>
  );
};
