import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import SuperSummaryComponent from 'components/Program/SuperSummary';

export const SuperSummaryPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <SuperSummaryComponent {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};
