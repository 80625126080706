import { ComponentStep } from './ComponentStep.types';

export interface SideNavData {
  title: string;
  scrollNav: boolean;
  steps: ComponentStep[];
  hasLockedIcon?: boolean;
}

export const emptySideNavData = () => {
  return {
    title: '',
    scrollNav: true,
    steps: [],
  };
};
