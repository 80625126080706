import { connect } from 'react-redux';
import {
  getIsDataFetching_ProgramInstance,
  getAdvancedFilteredRecords_Programs,
} from 'redux/programs/_selectors';
import {
  loadProgramInstanceData,
  saveProgramInstanceAction,
  saveRenewalRequest,
  saveRevisionRequest,
  saveRevisionApproval,
} from 'redux/programs/programinstance';
import ApplicationState from 'types/applicationstate.types';
import { ProgramDashboardPage } from './ProgramDashboard.page';
import {
  getRecords_Schools,
  getRecords_Institutes,
  getRecords_Departments,
} from 'redux/common/_selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { getProgramFilters } from 'redux/filters/programfilter.selectors';

export const mapStateToProps = (state: ApplicationState) => {
  const departments = getRecords_Departments(state);
  const schools = [
    ...getRecords_Schools(state),
    ...getRecords_Institutes(state),
  ];
  const tableData = getAdvancedFilteredRecords_Programs(state);
  const isFetching = getIsDataFetching_ProgramInstance(state);
  const filters = getProgramFilters(state).length;

  return {
    filters,
    isFetching,
    tableData,
    schools,
    departments,
  };
};

const dispatchProps = {
  loadProgramInstanceData,
  saveProgramInstanceAction,
  saveRenewalRequest,
  saveRevisionRequest,
  saveRevisionApproval,
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProgramDashboardPage);
