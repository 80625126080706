import { connect } from 'react-redux';
import {
  getSchoolOrInstituteById,
  getDistrictById,
} from 'redux/common/_selectors';
import { setProgramNav } from 'redux/application';
import { getProgram } from 'redux/programs/_selectors';
import { ChangeLogLayout } from './ChangeLog.layout';
import { prop, compose, converge, either } from 'util/objectUtility';
import { loadSingleProgramInstance } from 'redux/programs/programinstance';
import { concat } from 'ramda';
import { startCase } from 'lodash';
import { getGrantFiscalYear } from 'redux/grants/_selectors';
import { GRANT_LAYOUT_HEADER_TITLES } from 'constants/grantLayoutTitles.const';
import { loadSingleGrantFiscalYear } from 'redux/grants/grantfiscalyear';

const grantTitle = (entityType: string) => {
  const getTitle = compose(
    startCase,
    prop(entityType)
  );

  const title = getTitle(GRANT_LAYOUT_HEADER_TITLES);
  return title ? title : startCase(entityType);
};

const programInfo = (state: any, entityId: number) => {
  /* const programIsLoaded = getProgramIsLoaded(state, entityId); */
  const program = getProgram(state, entityId);
  const school = getSchoolOrInstituteById(state, program.schoolId);
  const district =
    school && school.districtId > 0
      ? getDistrictById(state, school.districtId)
      : undefined;

  const schoolName = compose(
    either(prop('schoolName'), () => ''),
    prop('school')
  );
  const districtName = compose(
    concat(' | '),
    either(prop('districtName'), () => ''),
    prop('district')
  );
  const getSubtitle = converge(concat, [schoolName, districtName]);

  const title = prop('programName', program);
  const subTitle = district
    ? getSubtitle({ district, school })
    : schoolName({ school });

  const loader = loadSingleProgramInstance;
  return {
    title,
    subTitle,
    loader,
  };
};

const grantInfo = (state: any, entityId: number, entityType: string) => {
  const grant = getGrantFiscalYear(state, entityId);
  const title = grantTitle(entityType);
  return {
    title,
    subTitle: prop('name', grant),
    loader: loadSingleGrantFiscalYear,
  };
};

const empty = () => ({ title: '', subTitle: '', loader: null });

const options: any = {
  programs: programInfo,
  'spending-plans': grantInfo,
  'four-year-plan': grantInfo,
  'two-year-plan': grantInfo,
  'annual-reflection': grantInfo,
  'assurance-signature': grantInfo,
  'award-packet': grantInfo,
  'improvement-plan': grantInfo,
  'voucher-one': grantInfo,
  'voucher-two': grantInfo,
  'voucher-three': grantInfo,
  'final-voucher': grantInfo,
  default: empty,
};

export const mapStateToProps = (state: any, ownProps: any) => {
  const entityType: string = ownProps.match.params.entityType;
  const entityId: number = Number(ownProps.match.params.entityId);
  const getData = compose(
    (func: Function) => func(state, entityId, entityType),
    either(prop(entityType), prop('default'))
  );
  return getData(options);
};

const dispatchToProps = {
  setProgramNav,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ChangeLogLayout);
