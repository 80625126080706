import { resetIsLoading } from 'redux/utility/_exports';

export const persistMiddleware = (store: any) => (next: Function) => async (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case 'persist/REHYDRATE':
      dispatch(resetIsLoading());
      break;
  }
};
