import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_VOUCHER_TWO_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_TWO = `${CHANGELOG_VOUCHER_TWO_KEY} RESET UPDATE`;
export const SET_CHANGELOG_VOUCHER_TWO = `${CHANGELOG_VOUCHER_TWO_KEY} SET`;
export const SET_CHANGELOG_VOUCHER_TWO_LOADING = `${CHANGELOG_VOUCHER_TWO_KEY} LOADING`;

const initState = baseInitialState;

export const changelogVoucherTwoReducer = (state = initState, action: any) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_TWO:
      return setShouldUpdate(state);

    case SET_CHANGELOG_VOUCHER_TWO:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_VOUCHER_TWO_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogVoucherTwo = (payload: any) => {
  return {
    type: SET_CHANGELOG_VOUCHER_TWO,
    payload,
  };
};

export const setIsLoading_ChangelogVoucherTwo = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_VOUCHER_TWO_LOADING,
    payload,
  };
};
