import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { GrantSchedule } from 'types';

const ENDPOINT = 'api/FiscalYearManagement/';
const AWARD_ESTIMATE = 'api/FiscalYearManagement/ExcelUpload/';

export const updateFederalGrantId = async (
  fiscalYear: number,
  federalGrantId: string
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}FederalGrant/${fiscalYear}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ federalGrantId }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'grantfiscalyear.services',
        err,
        'updateFederalGrantId'
      );
    });
};

export const readFederalGrantId = async (
  fiscalYear: number
): Promise<string> => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}FederalGrant/${fiscalYear}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('grantfiscalyear.services', err, 'readFederalGrantId');
    });
};

export const uploadGrantScheduleExcelFile = async (
  files: File,
  fiscalYear: number,
  anchor: string
) => {
  return fetch(
    `${ENVIRONMENT.API_URL}${AWARD_ESTIMATE}${fiscalYear}/${anchor}`,
    {
      ...postWithAuthOptions(),
      body: files,
    }
  )
    .then(toJSON)
    .then()
    .catch((err) => {
      return catchError('ExcelUpload', err);
    });
}; // end upload

export const updateGrantSchedule = async (
  name: string,
  year: number,
  grantSchedule: GrantSchedule
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${name}/${year}`;

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(grantSchedule),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('FiscalYearManagement', err);
    });
};

export const readGrantSchedule = async (name: string, year: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${name}/${year}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('FiscalYearManagement', err);
    });
};
