import { GRANTS_KEY, GRANTS_ITP_CONSORTIUMS_KEY } from 'redux/keys';
import { Consortium } from 'types';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';

export const ITP_CONSORTIUM_PATH = [GRANTS_KEY, GRANTS_ITP_CONSORTIUMS_KEY];

export const getIsDataFetching_ITPConsortia = (state: any): boolean => {
  return getIsDataFetching(state, ITP_CONSORTIUM_PATH);
};

export const getRecords_ITPConsortia = (state: unknown): Consortium[] => {
  return getRecords(state, ITP_CONSORTIUM_PATH);
};
