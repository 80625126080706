import React from 'react';
import { startCase } from 'lodash';
import { insertClass } from 'util/formHelpers';

type LabelProps = {
  htmlFor: string;
  label?: string | React.ReactElement | JSX.Element;
  name: string;
  className?: string;
};

export const Label: React.FC<LabelProps> = (props) => {
  const { htmlFor, label, name, className } = props;
  return (
    <label
      htmlFor={htmlFor ? htmlFor : name}
      className={`form__label ${insertClass(className)}`}
    >
      {label ? label : startCase(name)}
    </label>
  );
};
