import { FourYearSummarySummaryComponent } from './FourYearSummary.component';
import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getIsDataFetching_FourYearPlan,
  getRecords_FourYearPlan,
} from 'redux/grants/_selectors';
import { loadFourYearQuestions } from 'redux/grants/grantformquestions/fouryearquestions/fouryear.actions';

export const mapStateToProps = (state: any, ownProps: any) => {
  const isFetching = getIsDataFetching_FourYearPlan(state);

  // Question Data
  const questions = {
    needsAssessment: getRecords_FourYearPlan(state, 'needs-assessment'),
    careerInfoQuestions: getRecords_FourYearPlan(state, 'career-info'),
    qualityQuestions: getRecords_FourYearPlan(state, 'quality'),
    instructorsQuestions: getRecords_FourYearPlan(state, 'instructors'),
    partnershipsQuestions: getRecords_FourYearPlan(state, 'partnerships'),
    workBasedLearningQuestions: getRecords_FourYearPlan(
      state,
      'work-based-learning'
    ),
    equityQuestions: getRecords_FourYearPlan(state, 'equity'),
  };

  const loadQuestions = (dispatch: Function, entityId: number) => {
    dispatch(loadFourYearQuestions('needs-assessment')(entityId));
    dispatch(loadFourYearQuestions('career-info')(entityId));
    dispatch(loadFourYearQuestions('quality')(entityId));
    dispatch(loadFourYearQuestions('instructors')(entityId));
    dispatch(loadFourYearQuestions('partnerships')(entityId));
    dispatch(loadFourYearQuestions('work-based-learning')(entityId));
    dispatch(loadFourYearQuestions('equity')(entityId));
  };

  return {
    isFetching,
    loadQuestions,
    questions,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
};

export const FourYearSummary = connect(
  mapStateToProps,
  dispatchToProps
)(FourYearSummarySummaryComponent);
