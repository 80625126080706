import React, { useState, useEffect, useCallback } from 'react';
import { FlexGroup } from 'layouts';
import {
  sortBy,
  get,
  isFunction,
  remove,
  includes,
  map,
  uniq,
  every,
  isEqual,
  isEmpty,
  some,
} from 'lodash';
import Icon from 'components/Common/Icons';
import { contactHasRoles } from 'util/permissionsUtility';
import { UniversalContact } from 'types';
import Input from '../Input';

type DualListBoxProps = {
  data?: Readonly<unknown[]>;
  dataLabel: string;
  headers?: Function;
  height?: number;
  selectedData: unknown[];
  selectedDataLabel: string;
  displayName: Function | string;
  selector: string;
  onChange?: Function;
  sortBySelectors?: string[] | string;
  disable?: {
    selector: string;
    value: string | boolean | number;
  };
  disableOverrideRoles?: string[];
  loggedInUser: UniversalContact;
  search?: boolean;
};

/**
 * @prop {array}    data              - Data array you want to pass between the list-boxes
 * @prop {string}   dataLabel         - Label to display over the first listbox
 * @prop {array}    selectedData      - The data that has been selected, this will be removed from the data.
 * @prop {string}   selectedDataLabel - Label to display over the second listbox
 * @prop {string}   displayName       - Function or string for the display name
 * @prop {string}   selector          - The property name that the items will key off of
 * @prop {function} onChange          - A callback that will be called when the selected items have changed.
 * @prop {string[]} [sortBySelectors] - The iteratees to sort by, specified individually or in arrays.
 */
export const DualListBox: React.FC<DualListBoxProps> = (props) => {
  const {
    data = [],
    dataLabel,
    disable,
    headers,
    height,
    selectedData,
    selectedDataLabel,
    selector,
    displayName,
    onChange,
    sortBySelectors,
    disableOverrideRoles = [],
    loggedInUser,
    search = false,
  } = props;

  const [selectedItem, setSelectedItem] = useState<unknown>();
  const [primaryFocus, setPrimaryFocus] = useState<boolean>(true);

  const [allItems, setAllItems] = useState<unknown[]>([]);
  const [clickCounter, setClickCounter] = useState<{
    target: unknown;
    counter: number;
  }>({
    target: null,
    counter: 0,
  });

  const sort = (list: unknown[]) => {
    const sorter = sortBySelectors
      ? sortBySelectors
      : isFunction(displayName)
      ? selector
      : displayName;
    return sortBy(list, sorter);
  };
  const sortList = useCallback(sort, []); // eslint-disable-line

  const addItem = () => {
    if (!selectedItem || !onChange) return;
    const newSelectedItems = uniq([...selectedData, selectedItem]);
    const newAllItems = remove(allItems, (item: unknown) => {
      return get(item, selector) !== get(selectedItem, selector);
    });
    setAllItems(sortList(newAllItems));
    setSelectedItem(undefined);
    onChange && onChange(newSelectedItems);
  };

  const removeItem = () => {
    if (!selectedItem || !onChange) return;
    const newAllItems = uniq([...allItems, selectedItem]);
    setAllItems(sortList(newAllItems));
    const newSelectedItems = remove([...selectedData], (item: unknown) => {
      return get(item, selector) !== get(selectedItem, selector);
    });
    setSelectedItem(undefined);
    onChange && onChange(newSelectedItems);
  };

  let timer: any;
  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setClickCounter({
        target: undefined,
        counter: 0,
      });
    }, 500);
  };

  const handleDoubleclick = (target: unknown) => {
    resetTimer();
    if (!clickCounter.target || clickCounter.target === target) {
      if (!clickCounter.counter) {
        setClickCounter({
          target,
          counter: ++clickCounter.counter,
        });
      } else {
        clearTimeout(timer);
        primaryFocus ? addItem() : removeItem();
        setClickCounter({
          target: undefined,
          counter: 0,
        });
      }
    }
  };

  const applyHeight = () => {
    return height ? { height: height } : {};
  };

  const getCanSelect = (item: unknown): boolean => {
    if (contactHasRoles(loggedInUser, disableOverrideRoles)) {
      return true;
    }
    if (disable) {
      const value = get(item, disable.selector, '');
      return value !== disable.value;
    }
    return true;
  };

  const isSelected = (itemKey: string, selectKey: string) =>
    every([isEqual(itemKey, selectKey), primaryFocus]);

  useEffect(() => {
    const selectedDataValues = map(selectedData, (selitem) => {
      return get(selitem, selector);
    });
    const tempData = [...data];
    remove(tempData, (item: unknown) => {
      const testItem = get(item, selector);
      return includes(selectedDataValues, testItem);
    });
    const sorted = sortList(tempData);
    setAllItems(sorted);
    /* setSelectedItem(sorted[0]); */
  }, [selector, sortList, selectedData, data]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div className="list-selector">
      <div className="list-selector__table">
        <FlexGroup>
          <p className="form__label">{dataLabel}</p>
          {search && (
            <React.Fragment>
              <Input
                name="searchTerm"
                noLabel={true}
                value={searchTerm}
                onChange={(item: any) => {
                  setSearchTerm(item.searchTerm);
                }}
              />
              <div
                style={{
                  marginBottom: '1rem',
                }}
              />
            </React.Fragment>
          )}

          {headers && headers()}
          <div className="list-selector__list" style={applyHeight()}>
            {map(allItems, (item: unknown, index: number) => {
              const itemKey = get(item, selector, '');
              const selectKey = get(selectedItem, selector, '');
              const canSelect = getCanSelect(item);

              const displayData: string = isFunction(displayName)
                ? displayName(item)
                : get(item, displayName as string, '');

              const showIt = !search
                ? true
                : some([
                    isEmpty(searchTerm),
                    displayData
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()),
                  ]);

              return (
                showIt && (
                  <div
                    tabIndex={isSelected(itemKey, selectKey) ? 0 : -1}
                    aria-disabled={canSelect}
                    onFocus={() => {
                      if (canSelect) {
                        setPrimaryFocus(true);
                      }
                    }}
                    key={index}
                    onClick={() => {
                      if (canSelect) {
                        setPrimaryFocus(true);
                        setSelectedItem(item);
                        handleDoubleclick(item);
                      }
                    }}
                    style={{ userSelect: 'none' }}
                    className={
                      isSelected(itemKey, selectKey)
                        ? 'list-selector__item list-selector__item--selected'
                        : 'list-selector__item'
                    }
                  >
                    {displayData}
                  </div>
                )
              );
            })}
          </div>
        </FlexGroup>
      </div>
      <div className="list-selector__buttons">
        <FlexGroup>
          <div
            onClick={addItem}
            className="list-selector__button list-selector__button--add"
          >
            <div className="list-selector__button-content">
              <p className="list-selector__button-text">Add</p>
              <Icon
                name="chevronsLeft"
                className="list-selector__icon list-selector__icon--add u-flip"
              />
            </div>
          </div>
          <div
            onClick={removeItem}
            className="list-selector__button list-selector__button--remove"
          >
            <div className="list-selector__button-content">
              <Icon
                name="chevronsLeft"
                className="list-selector__icon list-selector__icon--remove"
              />
              <p className="list-selector__button-text">Remove</p>
            </div>
          </div>
        </FlexGroup>
      </div>
      <div className="list-selector__table">
        <FlexGroup>
          <p className="form__label">{selectedDataLabel}</p>
          {headers && headers()}
          <div className="list-selector__list" style={applyHeight()}>
            {selectedData &&
              map(selectedData, (item: unknown, index: number) => {
                const itemKey = get(item, selector, '');
                const selectKey = get(selectedItem, selector, '');
                const canSelect = getCanSelect(item);
                return (
                  <div
                    aria-disabled={canSelect}
                    onFocus={() => {
                      if (canSelect) {
                        setPrimaryFocus(false);
                      }
                    }}
                    key={index}
                    onClick={() => {
                      if (canSelect) {
                        setPrimaryFocus(false);
                        setSelectedItem(item);
                        handleDoubleclick(item);
                      }
                    }}
                    style={{ userSelect: 'none' }}
                    className={
                      itemKey === selectKey && !primaryFocus
                        ? 'list-selector__item list-selector__item--selected'
                        : 'list-selector__item'
                    }
                  >
                    {isFunction(displayName)
                      ? displayName(item)
                      : get(item, displayName as string, '')}
                  </div>
                );
              })}
          </div>
        </FlexGroup>
      </div>
    </div>
  );
};
