import { connect } from 'react-redux';
import {
  getIsDataFetching_Consortia,
  getConsortiumByFiscalYear,
} from 'redux/grants/_selectors';
import { ConsortiumViewComponent } from './ConsortiumView.component';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { getLoggedinUser } from 'redux/user/selectors';

export const mapSTateToProps = (state: any, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);
  const consortiumId = Number(ownProps.match.params.consortiumId);
  const fiscalYear = Number(ownProps.match.params.fiscalYear);
  const consortium = getConsortiumByFiscalYear(state, consortiumId, fiscalYear);
  const isFetching = getIsDataFetching_Consortia(state);
  const ficalAgentID =
    consortium.fiscalAgent && consortium.fiscalAgent.primaryContactId
      ? consortium.fiscalAgent.primaryContactId
      : 0;
  const fiscalAgentUser = getContact(state, ficalAgentID);

  return {
    consortium,
    fiscalAgentUser,
    isFetching,
    loggedInUser,
  };
};

const dispatchToProps = {};

export default connect(
  mapSTateToProps,
  dispatchToProps
)(ConsortiumViewComponent);
