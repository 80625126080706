import { connect } from 'react-redux';
import {
  getIsDataFetching_ProgramOfStudy,
  getProgramOfStudyById,
} from 'redux/programs/_selectors';
import { loadProgramOfStudyData } from 'redux/programs/programofstudy';
import { ProgramOfStudyOverviewComponent } from './ProgramOfStudyOverview.component';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { getRecords_CtsoCodes } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const posId = ownProps.match.params.programOfStudyId;
  const ctsoCodes = getRecords_CtsoCodes(state);
  const programOfStudy = getProgramOfStudyById(state, posId);
  const isFetching = getIsDataFetching_ProgramOfStudy(state);
  const secondaryUser = getContact(
    state,
    programOfStudy.secondaryProgram.requestorContactId
  );
  const postsecondaryUser = getContact(
    state,
    programOfStudy.postSecondaryProgram.requestorContactId
  );

  return {
    programOfStudy,
    isFetching,
    secondaryUser,
    postsecondaryUser,
    ctsoCodes,
  };
};

const dispatchToProps = {
  loadProgramOfStudyData,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramOfStudyOverviewComponent);
