import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  putWithAuthOptions,
  catchError,
} from 'services';
import { PermissionTopic } from 'types';

const PERMISSION_TOPIC_ENDPOINT = 'api/PermissionTopics/';
const URL = `${ENVIRONMENT.API_URL}${PERMISSION_TOPIC_ENDPOINT}`;

export const readPermissionTopics = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTopics', err);
    });
}; // end

export const createPermissionTopic = async (topic: PermissionTopic) => {
  let value: Omit<PermissionTopic, 'permissionTopicId'> = {
    permissionTopicName: topic.permissionTopicName,
  };
  return fetch(URL, {
    ...postWithAuthOptions(),
    body: JSON.stringify(value),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTopics', err);
    });
}; // end

export const updatePermissionTopic = async (topic: PermissionTopic) => {
  return fetch(`${URL}${topic.permissionTopicId}`, {
    ...putWithAuthOptions(),
    body: JSON.stringify(topic),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTopics', err);
    });
}; // end
