import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';

/*
  Budget Hydration
  This component is used to refresh / hydrate data for grant data types in the background
*/

type CTAHydratorProps = {
  shouldRefreshCTAFiscalYears: boolean;
  loadCTAFiscalYears: Function;

  shouldRefreshCTASchoolTerms: boolean;
  loadCTASchoolTerms: Function;

  loadCTASupportProgramAffiliation: Function;
};

export const CTAHydrator: React.FC<CTAHydratorProps> = (props) => {
  // -- dependencies --
  const { ctaId } = useParams<any>();

  // -- CTAFiscalYears ------------------------------------------------------------
  const { shouldRefreshCTAFiscalYears, loadCTAFiscalYears } = props;

  useEffect(() => {
    shouldRefreshCTAFiscalYears && loadCTAFiscalYears();
  }, [shouldRefreshCTAFiscalYears, loadCTAFiscalYears]);

  // -- CTASchoolTermss ------------------------------------------------------------
  const { shouldRefreshCTASchoolTerms, loadCTASchoolTerms } = props;

  useEffect(() => {
    shouldRefreshCTASchoolTerms && loadCTASchoolTerms();
  }, [shouldRefreshCTASchoolTerms, loadCTASchoolTerms]);

  // -- CTASupportProgramAffiliation ------------------------------------------------------------
  const { loadCTASupportProgramAffiliation } = props;

  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      loadCTASupportProgramAffiliation(Number(ctaId));
    }
  }, [loadCTASupportProgramAffiliation, ctaId]);

  return <div className="hydration" />;
};
