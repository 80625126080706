import { connect } from 'react-redux';
import { getNavTrayClosed } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { GrantSchedule, emptyGrantSchedule } from 'types';
import { FiscalYearLayout } from 'layouts/FiscalYearLayout/FiscalYear.layout';

export const mapStateToProps = (state: any, ownProps: any) => {
  const navTrayClosed = getNavTrayClosed(state);
  const grantSchedule: GrantSchedule = emptyGrantSchedule();

  return {
    grantSchedule,
    navTrayClosed,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(FiscalYearLayout);
