import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import {
  loadProgramAwards,
  saveProgramAwardCache,
} from 'redux/caches/programaward';
import {
  getProgramAwards,
  getAreProgramAwardsFetching,
} from 'redux/caches/_selectors';
import {
  setProgramAwardCache,
  resetProgramAwardCache,
} from 'redux/caches/programaward';
import {
  getRecords_AwardTypes,
  getIsProgramReadOnly,
} from 'redux/programs/_selectors';
import { getNextStep } from 'redux/common/_selectors';
import { ProgramAwardEditComponent } from './AwardsEdit.component';
import { ProgramAwardSummaryComponent } from './AwardsSummary.component';
import { ProgramAwardsSuperSummaryComponent } from './AwardsSuperSummary.component';

const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const programAwards = getProgramAwards(state, programInstanceId);
  const awardTypes = getRecords_AwardTypes(state);
  const isFetching = getAreProgramAwardsFetching(state);
  const nextProgramStep = getNextStep(state, 'awards');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    programInstanceId,
    programAwards,
    awardTypes,
    isFetching,
    nextProgramStep,
    readOnly,
  };
};

const dispatchToProps = {
  setCurrentNavAnchor,
  loadProgramAwards,
  setProgramAwardCache,
  saveProgramAwardCache,
  resetProgramAwardCache,
};

export const ProgramAwardEdit = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramAwardEditComponent);

export const ProgramAwardSummary = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramAwardSummaryComponent);

export const ProgramAwardsSuperSummary = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramAwardsSuperSummaryComponent);
