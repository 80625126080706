import React from 'react';
import { setCurrentNavAnchor } from 'redux/application';
import { connect } from 'react-redux';
import { getRecords_GrantOverview } from 'redux/nav/_selectors';
import { isStatusLocked } from 'util/navHelpers';
import {
  getGeneralWorkflow,
  getIsDataFetching_GeneralWorkflow,
} from 'redux/generalworkflow/workflow.selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { saveWorkFlowUpdate } from 'redux/generalworkflow/workflow.actions';
import { includes, some } from 'lodash';
import { LOCAL_PLAN_WORKFLOW } from 'constants/grantsWorkFlowStatus.enum';
import { canEditGrantsWorkflow } from 'util/workflowUtility';
import {
  getRecords_FourYearPlan,
  getIsDataFetching_FourYearPlan,
} from 'redux/grants/_selectors';
import { loadFourYearQuestions } from 'redux/grants/grantformquestions/fouryearquestions/fouryear.actions';
import { FourYearWorkflowComponent } from './FourYearWorkflow.component';
import OpenWorkflowForRevision from 'components/Common/GeneralWorkflow/OpenWorkflowForRevision';

const ENTITY = 'four-year-plan';

const mapStateToProps = (state: any, ownProps: any) => {
  const { grantFiscalYearId: applicationId } = ownProps;
  const steps = getRecords_GrantOverview(state).steps;
  const isReadOnly = isStatusLocked(ENTITY, steps);
  const workflow = getGeneralWorkflow(ENTITY, state);
  const user = getLoggedinUser(state);

  // Question Data
  const questions = {
    needsAssessmentQuestions: getRecords_FourYearPlan(
      state,
      'needs-assessment'
    ),
    careerInfoQuestions: getRecords_FourYearPlan(state, 'career-info'),
    qualityQuestions: getRecords_FourYearPlan(state, 'quality'),
    instructorsQuestions: getRecords_FourYearPlan(state, 'instructors'),
    partnershipsQuestions: getRecords_FourYearPlan(state, 'partnerships'),
    workBasedLearningQuestions: getRecords_FourYearPlan(
      state,
      'work-based-learning'
    ),
    equityQuestions: getRecords_FourYearPlan(state, 'equity'),
  };

  // Fetching Data
  const isFetching = some([
    getIsDataFetching_GeneralWorkflow(ENTITY, state),
    getIsDataFetching_FourYearPlan(state, 'needs-assessment'),
    getIsDataFetching_FourYearPlan(state, 'career-info'),
    getIsDataFetching_FourYearPlan(state, 'quality'),
    getIsDataFetching_FourYearPlan(state, 'instructors'),
    getIsDataFetching_FourYearPlan(state, 'partnerships'),
    getIsDataFetching_FourYearPlan(state, 'work-based-learning'),
    getIsDataFetching_FourYearPlan(state, 'equity'),
  ]);

  // Load all questions
  const loadQuestions = (dispatch: Function, entityId: number) => {
    dispatch(loadFourYearQuestions('needs-assessment', entityId));
    dispatch(loadFourYearQuestions('career-info', entityId));
    dispatch(loadFourYearQuestions('quality', entityId));
    dispatch(loadFourYearQuestions('instructors', entityId));
    dispatch(loadFourYearQuestions('partnerships', entityId));
    dispatch(loadFourYearQuestions('work-based-learning', entityId));
    dispatch(loadFourYearQuestions('equity', entityId));
  };

  const disableSubmit = includes(
    [LOCAL_PLAN_WORKFLOW.APPROVED, LOCAL_PLAN_WORKFLOW.CONDITIONALLY_APROVED],
    workflow.workflowStatusId
  );

  const optionalAction = () => {
    if (workflow.workflowSteps.length < 1) {
      return null;
    }
    const currentStep = workflow.workflowSteps[0];

    if (!canEditGrantsWorkflow(currentStep, user)) {
      return null;
    }

    return (
      <React.Fragment>
        {disableSubmit && (
          <div className="u-flex">
            <div className="u-flex-grow-1" />
            <div>
              <OpenWorkflowForRevision
                workflowStep={currentStep}
                applicationId={applicationId}
                submitApplicationUpdate={submitApplicationUpdate}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return {
    applicationId,
    disableSubmit,
    isFetching,
    isReadOnly,
    loadQuestions,
    optionalAction,
    questions,
    workflow,
    user,
  };
};

const submitApplicationUpdate = saveWorkFlowUpdate(ENTITY);

const dispatchProps = {
  setCurrentNavAnchor,
  submitApplicationUpdate,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(FourYearWorkflowComponent);
