import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { isEmpty, every } from 'lodash';
import { UniversalContact } from 'types';
import { UniversalContactValidation } from 'validations/UniversalContact.validations';
import { isNotCurrentUser } from 'util/universalcontact.utility';
import { useParams } from 'react-router';
import Input from 'components/Common/FormElements/Input';
import Toggle from 'components/Common/FormElements/Toggle';

type LoginInformationProps = {
  checkIfEmailExists: Function;
  contact: UniversalContact;
  emailExists: UniversalContact | undefined;
  onChange: Function;
};

export const LoginInformation: React.FC<LoginInformationProps> = (props) => {
  const { checkIfEmailExists, contact, emailExists, onChange } = props;
  const {
    getError,
    getFieldValid,
    validate,
    validateIfTrue,
  } = UniversalContactValidation();

  const { userId }: any = useParams();
  const id = Number(userId);

  const validateOnBlur = () => {
    checkIfEmailExists(contact.loginEmail);
    validate('loginEmail', contact.loginEmail, contact);
  };

  const handleChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, contact);
    onChange(data, key, value);
  };

  const handleLoginUserToggle = (data: any, key: any, value: any) => {
    const updatedContact = {
      ...contact,
      isAadB2c: value,
    };
    if (!value) {
      validate('loginEmail', '', updatedContact);
    }
    onChange(data, key, value);
  };

  const isLoginReadOnly = every([
    !isEmpty(contact.aadB2cId),
    !isEmpty(contact.loginEmail),
  ]);

  return (
    <React.Fragment>
      <FlexGroup>
        <FlexRow>
          <Toggle
            description="This user requires a login."
            name="isAadB2c"
            value={contact.isAadB2c}
            onChange={handleLoginUserToggle}
            noLabel={true}
            disabled={isLoginReadOnly}
          />
        </FlexRow>
        {contact.isAadB2c && (
          <FlexRow>
            <Input
              disabled={isLoginReadOnly}
              errorMessage={getError('loginEmail')}
              name="loginEmail"
              onBlur={() => validateOnBlur()}
              onChange={handleChange}
              trim
              valid={
                getFieldValid('loginEmail') &&
                !isNotCurrentUser(emailExists, id)
              }
              value={contact.loginEmail}
            />
          </FlexRow>
        )}
      </FlexGroup>
    </React.Fragment>
  );
};
