import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/_exports';
import { ProgramState } from 'types';
import { readProgramStates } from 'services/common/programstates.services';

const initialState = baseInitialState;

export const loadProgramStates = createActionThunk('loadProgramStates', () => {
  return readProgramStates();
}); // end

export default handleActions<BaseStateObject, ProgramState[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramState[]>(loadProgramStates),
  },
  initialState
);
