import { connect } from 'react-redux';
import { ProgramStepsCareerPathway } from 'pages/ProgramSteps/CareerPathway/CareerPathway.page';
import { getCourses } from 'redux/common/courses.selectors';
import { getRecords_CareerPathways } from 'redux/common/_selectors';
import { loadCourses } from 'redux/common/courses';

const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const pathways = getRecords_CareerPathways(state);
  const courses = getCourses(state);
  return {
    programInstanceId,
    courses,
    pathways,
  };
};

const dispatchToProps = {
  loadCourses,
  /* saveProgramCareerPathway */
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramStepsCareerPathway);
