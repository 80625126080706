import React, { useEffect } from 'react';
import { AwardAmounts, ActualReimbursementAmounts } from 'types';
import { sum } from 'lodash';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { subtract } from 'util/mathHelpers';
import { compose, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Table from 'components/Common/Table';

type ActualReimbursementProps = {
  reimbursementSummary: ActualReimbursementAmounts;
  loadActualReimbursementRequestData: Function;
  grantFiscalYearId: number;
};

export const TotalActualReimbursementTables: React.FC<
  ActualReimbursementProps
> = (props) => {
  const {
    reimbursementSummary,
    loadActualReimbursementRequestData,
    grantFiscalYearId,
  } = props;

  useEffect(() => {
    loadActualReimbursementRequestData(grantFiscalYearId);
  }, [loadActualReimbursementRequestData, grantFiscalYearId]);

  const getTotals = (value: AwardAmounts): number => {
    return sum([value.equipment, value.other, value.personnel, value.travel]);
  };

  const personnelVariance = subtract(
    reimbursementSummary.budgetedCosts.personnel,
    reimbursementSummary.fiscalYearCosts.personnel
  );

  const travelVariance = subtract(
    reimbursementSummary.budgetedCosts.travel,
    reimbursementSummary.fiscalYearCosts.travel
  );

  const equipmentVariance = subtract(
    reimbursementSummary.budgetedCosts.equipment,
    reimbursementSummary.fiscalYearCosts.equipment
  );

  const otherVariance = subtract(
    reimbursementSummary.budgetedCosts.other,
    reimbursementSummary.fiscalYearCosts.other
  );

  const totalVariance = sum([
    personnelVariance,
    travelVariance,
    equipmentVariance,
    otherVariance,
  ]);

  const Personnel = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('personnel')
  );
  const Travel = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('travel')
  );
  const Equipment = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('equipment')
  );
  const Other = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('other')
  );
  const RowName = compose(
    renderData,
    prop('rowName')
  );
  const Total = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('total')
  );
  const conditionalRowStyles = [
    {
      when: (row: any) => row.rowName === 'Variance',
      style: {
        backgroundColor: 'transparent',
        borderBottom: '1px solid #179ba7',
      },
    },
    {
      when: (row: any) => !!row,
      style: {
        backgroundColor: 'transparent',
        borderTop: '1px solid #179ba7',
        borderLeft: '1px solid #179ba7',
        borderRight: '1px solid #179ba7',
        padding: '0 1rem',
      },
    },
  ];
  const renderRowName = (row: any) => {
    return <p className="u-bold">{RowName(row)}</p>;
  };
  const data = [
    {
      rowName: 'Budgeted Costs',
      total: getTotals(reimbursementSummary.budgetedCosts),
      ...reimbursementSummary.budgetedCosts,
    },
    {
      rowName: 'Actual Costs for Fiscal Year',
      total: getTotals(reimbursementSummary.fiscalYearCosts),
      ...reimbursementSummary.fiscalYearCosts,
    },
    {
      rowName: 'Variance',
      equipment: equipmentVariance,
      other: otherVariance,
      personnel: personnelVariance,
      travel: travelVariance,
      total: totalVariance,
    },
  ];

  const BUDGETED_COLUMNS: TableColumnProps[] = [
    {
      name: '',
      cell: renderRowName,
      style: {
        width: '25rem',
      },
    },
    {
      name: 'Personnel',
      cell: Personnel,
      style: {
        width: '15%',
      },
    },
    {
      name: 'Travel',
      cell: Travel,
      style: {
        width: '15%',
      },
    },
    {
      name: 'Equipment',
      cell: Equipment,
      style: {
        width: '15%',
      },
    },
    {
      name: 'Other',
      cell: Other,
      style: {
        width: '15%',
      },
    },
    {
      name: 'Total',
      cell: Total,
      style: {
        width: '15%',
      },
    },
  ];

  return (
    <div className="u-margin-bottom-large">
      <h2 className="u-section-title-text u-margin-bottom-medium">
        Total Actual Reimbursement Requested
      </h2>
      <Table
        columns={BUDGETED_COLUMNS}
        conditionalRowStyles={conditionalRowStyles}
        data={data}
        cellGrid
      />
    </div>
  );
};
