import {
  saveCTASchool,
  loadCTASchools,
  saveCTAGeneralAccuracy,
  loadCTAGeneralAccuracy,
} from 'redux/cta/ctageneral';
import { loadCTASingleDistrictRecord } from 'redux/cta/ctadashboard';
import { loadCTASignatures, saveCTASignatures } from 'redux/cta/ctasignature';

export const ctaGeneralMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveCTAGeneralAccuracy.SUCCEEDED:
    case saveCTASchool.SUCCEEDED: {
      dispatch(loadCTASchools(action.payload));
      dispatch(loadCTASingleDistrictRecord(action.payload));
      dispatch(loadCTAGeneralAccuracy(action.payload));
      break;
    }
    case saveCTASignatures.SUCCEEDED: {
      dispatch(loadCTASignatures(action.payload));
      break;
    }
  }
};
