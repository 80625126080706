import {
  WORKFLOW_KEY,
  WORKFLOW_FOUR_YEAR_KEY,
  WORKFLOW_TWO_YEAR_KEY,
  WORKFLOW_ANNUAL_SPENDING_PLAN_KEY,
  WORKFLOW_ASURANCE_SIGNATURE_KEY,
  WORKFLOW_ANNUAL_REFLECTION_KEY,
  WORKFLOW_AWARD_PACKET_KEY,
  WORKFLOW_VOUCHER_KEY,
  WORKFLOW_IMPROVEMENT_PLAN_KEY,
  WORKFLOW_PROGRAMS_KEY,
  WORKFLOW_CTA_KEY,
} from 'redux/keys';
import { getRecords, getIsDataFetching } from 'redux/utility/_exports';
import { Workflow, emptyWorkflowObject } from 'types';
import { curry } from 'lodash';
import { defaultTo } from 'ramda';

export const FOUR_YEAR_WORKFLOW_PATH = [WORKFLOW_KEY, WORKFLOW_FOUR_YEAR_KEY];
export const TWO_YEAR_WORKFLOW_PATH = [WORKFLOW_KEY, WORKFLOW_TWO_YEAR_KEY];
export const ANNUAL_SPENDING_PLAN_WORKFLOW_PATH = [
  WORKFLOW_KEY,
  WORKFLOW_ANNUAL_SPENDING_PLAN_KEY,
];
export const ASSURANCE_SIGNATURE_WORKFLOW_PATH = [
  WORKFLOW_KEY,
  WORKFLOW_ASURANCE_SIGNATURE_KEY,
];
export const ANNUAL_REFLECTION_WORKFLOW_PATH = [
  WORKFLOW_KEY,
  WORKFLOW_ANNUAL_REFLECTION_KEY,
];
export const AWARD_PACKET_WORFKLOW_PATH = [
  WORKFLOW_KEY,
  WORKFLOW_AWARD_PACKET_KEY,
];
export const VOUCHER_WORFKLOW_PATH = [WORKFLOW_KEY, WORKFLOW_VOUCHER_KEY];
export const IMPROVEMENT_PLAN_WORFKLOW_PATH = [
  WORKFLOW_KEY,
  WORKFLOW_IMPROVEMENT_PLAN_KEY,
];
export const PROGRAMS_WORKFLOW_PATH = [WORKFLOW_KEY, WORKFLOW_PROGRAMS_KEY];
export const CTA_WORKFLOW_PATH = [WORKFLOW_KEY, WORKFLOW_CTA_KEY];

const options: any = {
  programs: PROGRAMS_WORKFLOW_PATH,
  'four-year-plan': FOUR_YEAR_WORKFLOW_PATH,
  'two-year-plan': TWO_YEAR_WORKFLOW_PATH,
  'spending-plans': ANNUAL_SPENDING_PLAN_WORKFLOW_PATH,
  'assurance-signature': ASSURANCE_SIGNATURE_WORKFLOW_PATH,
  'annual-reflection': ANNUAL_REFLECTION_WORKFLOW_PATH,
  'award-packet': AWARD_PACKET_WORFKLOW_PATH,
  'voucher-one': VOUCHER_WORFKLOW_PATH,
  'voucher-two': VOUCHER_WORFKLOW_PATH,
  'voucher-three': VOUCHER_WORFKLOW_PATH,
  'final-voucher': VOUCHER_WORFKLOW_PATH,
  'improvement-plan': IMPROVEMENT_PLAN_WORFKLOW_PATH,
  cta: CTA_WORKFLOW_PATH,
};

export const getGeneralWorkflow = curry(
  (entityType: string, state: any): Workflow => {
    const records = getRecords<Workflow>(state, options[entityType]);
    const workflow = records[0];
    return workflow ? workflow : emptyWorkflowObject();
  }
);

export const getIsDataFetching_GeneralWorkflow = curry(
  (entityType: string, state: any) => {
    const isFetching = getIsDataFetching(state, options[entityType]);
    return defaultTo(false, isFetching);
  }
);
