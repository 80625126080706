import React from 'react';
import { useHistory } from 'react-router';
import { FiscalYear } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Button from 'components/Common/FormElements/Button';
import LandingPageCard from 'components/Common/LandingPageCard';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type GrantPortalProps = {
  currentFiscalYear: FiscalYear;
};

export const GrantsPortal: React.FC<GrantPortalProps> = (props) => {
  const { currentFiscalYear } = props;
  const history = useHistory();
  const navigate = (url: string) => {
    history.push(url);
  };
  return (
    <BaseLayout>
      <ScrollToTop />
      <div className="landing-page">
        <h1 className="landing-page__title">Grant Applications Portal</h1>
        <div className="landing-page__cards">
          <SecureWrap component="grantsfiscalyear" allowReadOnly={true}>
            <LandingPageCard
              title="Fiscal Year"
              iconType="calendar"
              buttons={[
                <Button
                  aria-label={`View Fiscal Year Management ${currentFiscalYear.year}`}
                  className="landing-page-card__btn"
                  asLink={true}
                  to={`/grants/fiscal-year-management/${currentFiscalYear.year}`}
                  text={`${currentFiscalYear.year}`}
                />,
                <Button
                  aria-label={`View Fiscal Year Management ${
                    currentFiscalYear.year + 1
                  }`}
                  className="landing-page-card__btn"
                  onClick={() =>
                    navigate(
                      `/grants/fiscal-year-management/${
                        currentFiscalYear.year + 1
                      }`
                    )
                  }
                  text={`${currentFiscalYear.year + 1}`}
                />,
              ]}
            />
          </SecureWrap>

          <SecureWrap
            component={['grantsparticipants', 'grantsconsortia']}
            allowReadOnly={true}
          >
            <LandingPageCard
              title="Manage"
              iconType="bus"
              buttons={[
                <SecureWrap component="grantsparticipants" allowReadOnly={true}>
                  <Button
                    aria-label="Participant Management"
                    className="landing-page-card__btn"
                    asLink={true}
                    to={`/grants/participation/${currentFiscalYear.year}`}
                    text="Participants"
                  />
                </SecureWrap>,
                <SecureWrap
                  allowReadOnly={true}
                  component={['grantsconsortia']}
                >
                  <Button
                    aria-label="Consortium Management"
                    className="landing-page-card__btn"
                    asLink={true}
                    to={'/grants/consortium'}
                    text="Consortia"
                  />
                </SecureWrap>,
              ]}
            />
          </SecureWrap>

          <SecureWrap component={'grants'}>
            <LandingPageCard
              title="Applications"
              iconType="mrMustache"
              buttons={[
                <div />,
                <SecureWrap component="grantsbasic" allowReadOnly={true}>
                  <Button
                    aria-label="View Basic Applications"
                    className="landing-page-card__btn"
                    asLink={true}
                    to={'/grants/applications'}
                    text="Basic"
                  />
                </SecureWrap>,
              ]}
            />
          </SecureWrap>

          <SecureWrap component={'GrantsEmailManagement'} allowReadOnly={true}>
            <LandingPageCard
              title="Emails"
              iconType="envelope"
              buttons={[
                <div />,
                <Button
                  aria-label="Email Management"
                  className="landing-page-card__btn"
                  asLink={true}
                  to={`/grants/email-management/${currentFiscalYear.year}`}
                  text="Management"
                />,
              ]}
            />
          </SecureWrap>
        </div>
      </div>
    </BaseLayout>
  );
};
