import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import React from 'react';
import { FlexRow } from 'layouts';
import { AcademicInformationM } from './AcademicInformationM';
import { AcademicInformationS } from './AcademicInformationS';
import { AcademicInformationPS } from './AcademicInformationPS';
import { prop } from 'util/objectUtility';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';

const AcademicInformation: React.FC<StudentRecordComponentProps> = (props) => {
  const { student } = props;
  const programLevel = prop('programLevelId', student);
  const isMiddleSchool = programLevel === PROGRAM_LEVEL_ENUM['Middle School'];
  const isSecondary = programLevel === PROGRAM_LEVEL_ENUM.Secondary;
  const isPostsecondary = programLevel === PROGRAM_LEVEL_ENUM.Postsecondary;

  return (
    <React.Fragment>
      <FlexRow>
        <h2>CTE and Academic Information</h2>
      </FlexRow>
      {isMiddleSchool && <AcademicInformationM {...props} />}
      {isSecondary && <AcademicInformationS {...props} />}
      {isPostsecondary && <AcademicInformationPS {...props} />}
    </React.Fragment>
  );
};

export default AcademicInformation;
