import { connect } from 'react-redux';
import { PathwayCourseStep } from 'pages/ProgramSteps/PathwayCourses/PathwayCourseStep.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(ownProps.match.params.programId);

  return {
    programInstanceId,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(PathwayCourseStep);
