import React from 'react';

export const FlexRow: React.FC<any> = (props) => {
  return (
    <div {...props} className="form__row">
      {props.children}
    </div>
  );
};

export const FlexGroup: React.FC<any> = (props) => {
  return (
    <div
      {...props}
      className={props.auto ? 'form__group form__group--auto' : 'form__group'}
    >
      {props.children}
    </div>
  );
};

export const ButtonContainer: React.FC<any> = (props) => {
  return (
    <div {...props} className="form__btn-container no-print">
      {props.children}
    </div>
  );
};
