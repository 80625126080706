import { CTA_KEY, CTA_HOSTING_AVAILABLE_CONTRACTS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTAHostItem } from 'types';

export const CTA_HOSTING_AVAILABLE_CONTRACTS_PATH = [
  CTA_KEY,
  CTA_HOSTING_AVAILABLE_CONTRACTS_KEY,
];

export const getIsDataFetching_CTAHostingAvailableContracts = (
  state: any
): boolean => {
  return getIsDataFetching(state, CTA_HOSTING_AVAILABLE_CONTRACTS_PATH);
};

export const getRecords_CTAHostingAvailableContracts = (
  state: any
): CTAHostItem[] => {
  return getRecords(state, CTA_HOSTING_AVAILABLE_CONTRACTS_PATH);
};

export const getAdvancedFilteredRecords_CTAHostingAvailableContracts = (
  state: any
): CTAHostItem[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTAHostItem>(
    state,
    CTA_HOSTING_AVAILABLE_CONTRACTS_PATH,
    filters
  );
  return records;
};
