import React, { useState } from 'react';
import { find, filter, map } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { FlexRow, FlexGroup } from 'layouts';
import { Department, Institute, ItemFilter, UniversalContact } from 'types';
import { userHasPerkinsRole, isSuperAdmin } from 'util/permissionsUtility';
import { PerkinsContactToggle } from './PerkinsContactToggle';
import { compose, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import DepartmentSelectBox from 'components/Common/SelectBoxes/DepartmentSelectBox';
import IconButton from 'components/Common/IconButton';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Instruction from 'components/Common/Instruction';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';
import Toggle from 'components/Common/FormElements/Toggle';

type InstituteDepartmentInfoProps = {
  contactInstitutes: Institute[];
  contactDepartments: Department[];
  institutes: Institute[];
  departments: Department[];
  onChange: Function;
  loggedInUser: UniversalContact;
};

export const InstituteDepartmentInfo: React.FC<InstituteDepartmentInfoProps> = (
  props
) => {
  const {
    contactDepartments,
    contactInstitutes,
    departments,
    institutes,
    onChange,
    loggedInUser,
  } = props;

  const updateInstitutes = (institutes: Institute[]) => {
    onChange({ institutes });
  };

  const updateDepartments = (departments: Department[]) => {
    onChange({ departments });
  };

  const toggleInstituteIsPerkinsPrimary = (schoolId: number) => {
    const value = find(contactInstitutes, (item: Institute) => {
      return schoolId === item.schoolId;
    });
    if (value) {
      const updatedInstitutes = upsertInPlace(
        contactInstitutes,
        { ...value, isPerkinPrimary: !value.isPerkinPrimary },
        'schoolId'
      );
      updateInstitutes(updatedInstitutes);
    }
  };

  const toggleInstituteIsPrimary = (schoolId: number) => {
    const value = find(contactInstitutes, (item: Institute) => {
      return schoolId === item.schoolId;
    });
    if (value) {
      const updatedInstitutes = upsertInPlace(
        contactInstitutes,
        { ...value, isPrimary: !value.isPrimary },
        'schoolId'
      );
      updateInstitutes(updatedInstitutes);
    }
  };

  const toggleDepartmentIsPrimary = (schoolDepartmentId: number) => {
    const value = find(contactDepartments, (item: Department) => {
      return schoolDepartmentId === item.schoolDepartmentId;
    });
    if (value) {
      const updatedDepartments = upsertInPlace(
        contactDepartments,
        { ...value, isPrimary: !value.isPrimary },
        'schoolDepartmentId'
      );
      updateDepartments(updatedDepartments);
    }
  };

  // This will filter departments based on the selected institute
  const [departmentFilter, setDepartmentFilter] = useState<ItemFilter>({
    property: 'schoolId',
    values: -1,
  });

  // --- Start Institutes ---
  const [selectedInstitute, setSelectedInstitute] = useState<number>(-1);

  const generateInstitutesFilter = () => {
    if (isSuperAdmin(loggedInUser)) {
      return undefined;
    }
    return {
      property: 'schoolId',
      values: map(
        loggedInUser.institutes,
        (item): number => {
          return item.schoolId;
        }
      ),
    };
  };

  const deleteInstitute = (value: Institute) => {
    const updates = filter(contactInstitutes, (item) => {
      return item.schoolId !== value.schoolId;
    });
    updateInstitutes(updates);
  };

  const handleInstituteChange = (data: any, key: any, values: number) => {
    setSelectedInstitute(values);
    if (values > 0) {
      setDepartmentFilter({
        property: 'schoolId',
        values,
      });
    }
  };

  const addInstitute = () => {
    if (!selectedInstitute) return;
    const value = find(institutes, (item: Institute) => {
      return item.schoolId === selectedInstitute;
    });

    if (value) {
      const updates = upsertInPlace(contactInstitutes, value, 'schoolId');
      updateInstitutes(updates);
    }
  };
  // --- End Institutes ---

  // --- Start Departments ---
  const [selectedDepartment, setSelectedDepartment] = useState<number>(-1);

  const getDepartmentName = (value: Department) => {
    const institute = find(institutes, (item) => {
      return item.schoolId === value.schoolId;
    });

    if (institute) {
      return `${institute.schoolName}: ${value.departmentName}`;
    }
    return value.departmentName;
  };

  const deleteDepartment = (value: Department) => {
    const updates = filter(contactDepartments, (item) => {
      return item.schoolDepartmentId !== value.schoolDepartmentId;
    });
    updateDepartments(updates);
  };

  const handleDepartmentChange = (data: any, key: any, values: number) => {
    setSelectedDepartment(values);
  };

  const addDepartment = () => {
    if (!selectedDepartment) return;
    const value = find(departments, (item) => {
      return item.schoolDepartmentId === selectedDepartment;
    });

    if (value) {
      const updates = upsertInPlace(
        contactDepartments,
        value,
        'schoolDepartmentId'
      );
      updateDepartments(updates);
    }
  };
  // --- End Departments ---

  const InstituteName = compose(
    renderData,
    prop('schoolName')
  );
  const INSTITUTE_HEADERS: TableColumnProps[] = [
    {
      name: 'Institute Name',
      cell: InstituteName,
      sortable: true,
      selector: InstituteName,
      style: {
        flexGrow: '2',
      },
    },
    {
      name: 'Programs Primary',
      cell: (row: Institute) => (
        <Toggle
          name="isPrimary"
          value={row.isPrimary}
          onChange={() => {
            toggleInstituteIsPrimary(row.schoolId);
          }}
          noLabel={true}
        />
      ),
      width: '15rem',
    },
    {
      name: 'Perkins Primary',
      cell: (row: Institute) => (
        <SecureWrap
          component={'grants'}
          requireAdmin={true}
          owner={
            userHasPerkinsRole(loggedInUser)
              ? loggedInUser.universalContactId
              : -1
          }
          noAccessComponent={
            <Toggle
              name="isPerkinPrimary"
              value={row.isPerkinPrimary}
              onChange={() => {}}
              noLabel={true}
              disabled={true}
            />
          }
        >
          <React.Fragment>
            <PerkinsContactToggle
              onChange={toggleInstituteIsPerkinsPrimary}
              schoolOrDistrictId={row.schoolId}
              isPerkinPrimary={row.isPerkinPrimary}
            />
          </React.Fragment>
        </SecureWrap>
      ),
      width: '15rem',
    },
    {
      name: 'Delete',
      cell: (row: Institute) => {
        return (
          <IconButton
            tooltip="Delete"
            className="icon-button__icon--delete"
            name="crossCircle"
            onClick={() => {
              deleteInstitute(row);
            }}
          />
        );
      },
      center: true,
      width: '6rem',
    },
  ];

  const DEPARTMENT_HEADERS: TableColumnProps[] = [
    {
      name: 'Primary',
      sortable: false,
      cell: (row: Department) => (
        <Toggle
          name="isPrimary"
          value={row.isPrimary}
          onChange={() => {
            toggleDepartmentIsPrimary(row.schoolDepartmentId);
          }}
          noLabel={true}
        />
      ),
      width: '12.8rem',
    },
    {
      name: 'Department Name',
      cell: getDepartmentName,
      selector: getDepartmentName,
      sortable: true,
      style: {
        flexGrow: '2',
      },
    },
    {
      name: 'Delete',
      cell: (row: Department) => {
        return (
          <IconButton
            tooltip="Delete"
            className="icon-button__icon--delete"
            name="crossCircle"
            onClick={() => {
              deleteDepartment(row);
            }}
          />
        );
      },
      center: true,
      width: '6rem',
    },
  ];
  return (
    <div className="form__section">
      <Instruction title="Add Institutions and Departments">
        <p>
          Follow the steps to first add an institution and then a department.
          Note: the department dropdown is always filtered based on the selected
          institution.
        </p>
        <br />
        <p className="u-bold">To add an institution:</p>
        <ol>
          <li>Click the institution dropdown and select a institution.</li>
          <li>Click "Add Institution".</li>
          <li>You can repeat this as many times as necessary. </li>
        </ol>
        <br />
        <p className="u-bold">To add a department:</p>
        <ol>
          <li>
            Click the institution dropdown and select the institution that the
            department belongs to (do <span className="u-bold">not</span> click
            "Add Institution").
          </li>
          <li>Click the department dropdown and select the department.</li>
          <li>Click "Add Department".</li>
          <li>You can repeat this as many times as necessary. </li>
        </ol>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <FlexRow>
            <InstituteSelectBox
              filter={generateInstitutesFilter()}
              onChange={handleInstituteChange}
            />
            <Button
              className="button--dsid button--outline"
              text="Add Institute"
              onClick={addInstitute}
            />
          </FlexRow>
          <Table columns={INSTITUTE_HEADERS} data={contactInstitutes} />
        </FlexGroup>
        <FlexGroup>
          <FlexRow>
            <DepartmentSelectBox
              onChange={handleDepartmentChange}
              filter={departmentFilter}
            />
            <Button
              className="button--dsid button--outline"
              text="Add Department"
              onClick={addDepartment}
            />
          </FlexRow>
          <Table columns={DEPARTMENT_HEADERS} data={contactDepartments} />
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
