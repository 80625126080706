import { connect } from 'react-redux';
import { getUserPermission } from 'redux/permissions/userpermissions.selectors';
import { AuthSecureWrap } from './SecureWrap.component';
import { getLoggedinUser } from 'redux/user/selectors';
import { ReactNode } from 'react';
import { some } from 'lodash';
import { getIsDataFetching } from 'redux/utility/_exports';
import { PERMISSIONS_KEY, USER_KEY, USER_PERMISSIONS_KEY } from 'redux/keys';

type SecureWrapConnectProps = {
  component: string | string[];
  children: ReactNode | ReactNode[];
  isPage?: boolean;
  requireEdit?: boolean;
  requireAdmin?: boolean;
  owner?: number;
  noAccessComponent?: React.ReactNode;
  allowReadOnly?: boolean;
  isLocked?: boolean;
  override?: boolean;
};

export const mapStateToProps = (state: any, ownProps: SecureWrapConnectProps) => {
  const permission = ownProps.override
    ? 'addedit'
    : getUserPermission(state, ownProps.component);
  const user = getLoggedinUser(state);
  const isLoading = some([
    getIsDataFetching(state, [USER_KEY]),
    getIsDataFetching(state, [PERMISSIONS_KEY, USER_PERMISSIONS_KEY]),
  ]);
  return {
    isLoading,
    permission,
    user,
  };
};
const dispatchToProps = {};
export default connect(mapStateToProps, dispatchToProps)(AuthSecureWrap);
