import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { FlexRow } from 'layouts';
import { AnnualSpending, SpendingCategory } from 'types';
import { formatMoney } from 'util/moneyHelpers';
import { add } from 'util/mathHelpers';
import {
  getRemainingFunds,
  getSpendingCategory,
} from 'util/annualSpendingUtility';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { loadAnnualSpendingPlan } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { useParams } from 'react-router';
import Input from 'components/Common/FormElements/Input';
import Paper from 'components/Common/Paper';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SpendingCategoryComponent from './SpendingCategory';
import SummaryScroll from 'components/Common/SummaryScroll';

type AnnualSpendingPlanSummaryProps = {
  annualSpendingPlan: AnnualSpending;
  setCurrentNavAnchor: Function;
};

export const AnnualSpendingPlanSummaryComponent: React.FC<AnnualSpendingPlanSummaryProps> = (
  props
) => {
  const { annualSpendingPlan, setCurrentNavAnchor } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    dispatch(loadAnnualSpendingPlan(grantFiscalYearId));
  }, [grantFiscalYearId]); // eslint-disable-line

  // --[ display logic ]-------------------------------------------------------
  const { spendingCategories } = annualSpendingPlan;
  const { basicGrantFunds, reserveGrantFunds } = annualSpendingPlan;

  const careerInfoFunds = getSpendingCategory(1, spendingCategories);
  const partnershipsFunds = getSpendingCategory(2, spendingCategories);
  const qualityFunds = getSpendingCategory(3, spendingCategories);
  const equityFunds = getSpendingCategory(4, spendingCategories);
  const instructorsFunds = getSpendingCategory(5, spendingCategories);
  const workBasedLearningFunds = getSpendingCategory(6, spendingCategories);
  const improvementPlanFunds = getSpendingCategory(10, spendingCategories);

  return (
    <Paper>
      <SummaryScroll componentStep="spending-plans" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('spending-plans')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <SecureWrap component={['grantsannualspending']} allowReadOnly={true}>
        <FlexRow>
          <h2>Estimated Funds Summary</h2>
        </FlexRow>
        <FlexRow>
          <Input
            value={formatMoney(basicGrantFunds)}
            name="Basic Grant Funds"
            disabled={true}
            className="input-display"
          />
          <Input
            value={formatMoney(reserveGrantFunds)}
            name="Reserve Grant Funds"
            disabled={true}
            className="input-display"
          />
          <Input
            value={formatMoney(add(basicGrantFunds, reserveGrantFunds))}
            name="Total Grant Funds"
            disabled={true}
            className="input-display"
          />
          <Input
            value={formatMoney(getRemainingFunds(annualSpendingPlan))}
            name="Funds Remaining"
            disabled={true}
            className="input-display"
          />
        </FlexRow>
        {improvementPlanFunds.length > 0 && (
          <div className="form__section">
            <h2>Improvement Plan</h2>
            {map(
              improvementPlanFunds,
              (category: SpendingCategory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SpendingCategoryComponent
                      isLocked={false}
                      index={index}
                      disabled={true}
                      onChange={() => null}
                      showLinks={false}
                      spendingCategory={category}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        {careerInfoFunds.length > 0 && (
          <div className="form__section">
            <h2>Career Advisement & Development</h2>
            {map(
              careerInfoFunds,
              (category: SpendingCategory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SpendingCategoryComponent
                      isLocked={false}
                      index={index}
                      disabled={true}
                      onChange={() => null}
                      showLinks={false}
                      spendingCategory={category}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        {partnershipsFunds.length > 0 && (
          <div className="form__section">
            <h2>Local Workforce Alignment</h2>
            {map(
              partnershipsFunds,
              (category: SpendingCategory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SpendingCategoryComponent
                      isLocked={false}
                      index={index}
                      disabled={true}
                      onChange={() => null}
                      showLinks={false}
                      spendingCategory={category}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        {qualityFunds.length > 0 && (
          <div className="form__section">
            <h2>Quality & Student Performance</h2>
            {map(qualityFunds, (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isLocked={false}
                    disabled={true}
                    onChange={() => null}
                    showLinks={false}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
        {equityFunds.length > 0 && (
          <div className="form__section">
            <h2>Progress Toward Improving Equity</h2>
            {map(equityFunds, (category: SpendingCategory, index: number) => {
              return (
                <React.Fragment key={index}>
                  <SpendingCategoryComponent
                    isLocked={false}
                    index={index}
                    disabled={true}
                    onChange={() => null}
                    showLinks={false}
                    spendingCategory={category}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
        {instructorsFunds.length > 0 && (
          <div className="form__section">
            <h2>Recruitment & Retention of Faculty/Staff</h2>
            {map(
              instructorsFunds,
              (category: SpendingCategory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SpendingCategoryComponent
                      isLocked={false}
                      index={index}
                      disabled={true}
                      onChange={() => null}
                      showLinks={false}
                      spendingCategory={category}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        {workBasedLearningFunds.length > 0 && (
          <div className="form__section">
            <h2>Work-based Learning</h2>
            {map(
              workBasedLearningFunds,
              (category: SpendingCategory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SpendingCategoryComponent
                      isLocked={false}
                      index={index}
                      disabled={true}
                      onChange={() => null}
                      showLinks={false}
                      spendingCategory={category}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
      </SecureWrap>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('spending-plans')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
