import { connect } from 'react-redux';
import { getProgram } from 'redux/programs/_selectors';
import ProgramOfStudyStep from './ProgramOfStudyStep.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const program = getProgram(state, programInstanceId);

  return {
    programInstanceId,
    program,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramOfStudyStep);
