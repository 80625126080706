import React, { useEffect, useState } from 'react';
import Paper from 'components/Common/Paper';
import { FlexRow, FlexGroup } from 'layouts';
import Cop from 'assets/403.svg';

export const FourOhThree: React.FC = () => {
  const [className, setClassName] = useState<string>('u-fade');
  useEffect(() => {
    setClassName('u-fade u-fade-in');
  }, []);

  return (
    <div className={className}>
      <Paper>
        <FlexRow>
          <FlexGroup>
            <div style={{ textAlign: 'center' }}>
              <h2>STOP</h2>
              <img
                style={{
                  filter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',
                  width: 300,
                  alignSelf: 'center',
                }}
                src={Cop}
                alt="403"
              />
              <h2>You are not authorized to view this content!!</h2>
            </div>
          </FlexGroup>
        </FlexRow>
      </Paper>
    </div>
  );
};
