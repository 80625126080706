import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';

type FilterProps = {
  clearEmailRecipientFilters: Function;
  filterLengths: any;
  setEmailRecipientFilterValue: Function;
};

export const ParticipationDashboardFilters: React.FC<FilterProps> = (props) => {
  const {
    clearEmailRecipientFilters,
    filterLengths,
    setEmailRecipientFilterValue,
  } = props;

  const [activeFilter, setActiveFilter] = useState<string>('');

  const setFilterValue = (filterData: any) => {
    clearEmailRecipientFilters();
    const { filters, name } = filterData;
    if (activeFilter !== name) {
      setActiveFilter(name);
      setEmailRecipientFilterValue(filters);
    } else {
      setActiveFilter('');
    }
  };

  useEffect(() => {
    clearEmailRecipientFilters();
  }, [clearEmailRecipientFilters]);

  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'standaloneParticipant'}
          iconName="person"
          number={filterLengths.standaloneParticipant}
          onClick={() => {
            setFilterValue({
              name: 'standaloneParticipant',
              filters: {
                isConsortium: false,
              },
            });
          }}
          text="Standalone Participants"
        />
        <FilterHotButton
          active={activeFilter === 'consortiumParticipant'}
          iconName="group"
          number={filterLengths.consortiumParticipant}
          onClick={() =>
            setFilterValue({
              name: 'consortiumParticipant',
              filters: {
                isConsortium: true,
              },
            })
          }
          text="Consortium Participants"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'secondary'}
          iconName="localLibrary"
          number={filterLengths.secondary}
          onClick={() =>
            setFilterValue({
              name: 'secondary',
              filters: {
                instituteLevel: PROGRAM_LEVEL_ENUM.Secondary,
              },
            })
          }
          text="Secondary"
        />
        <FilterHotButton
          active={activeFilter === 'postsecondary'}
          iconName="accountBalance"
          number={filterLengths.postsecondary}
          onClick={() =>
            setFilterValue({
              name: 'postsecondary',
              filters: {
                instituteLevel: PROGRAM_LEVEL_ENUM.Postsecondary,
              },
            })
          }
          text="Postsecondary"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={activeFilter === 'emailSent'}
          iconName="outbox"
          number={filterLengths.emailSent}
          onClick={() => {
            setFilterValue({
              name: 'emailSent',
              filters: { emailWasSent: true },
            });
          }}
          text="Email Sent"
        />
        <FilterHotButton
          active={activeFilter === 'emailNotSent'}
          iconName="cross"
          number={filterLengths.emailNotSent}
          onClick={() =>
            setFilterValue({
              name: 'emailNotSent',
              filters: { emailWasSent: false },
            })
          }
          text="Email Not Sent"
        />
      </FlexGroup>
    </FlexRow>
  );
};
