import { ArticulationComponent } from './Articulation.component';
import { connect } from 'react-redux';
import {
  saveArticulation,
  loadProgramArticulations,
} from 'redux/programs/articulations';
import {
  getArticulationByArticulationId,
  getActiveProgramLevel,
} from 'redux/programs/_selectors';
import { saveBusiness } from 'redux/common/schools';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = ownProps.match.params.programInstanceId;
  const programArticulationId = ownProps.match.params.articulationId;
  const articulation = getArticulationByArticulationId(
    state,
    programArticulationId
  );
  const programLevel = getActiveProgramLevel(state);

  return {
    articulation,
    programInstanceId,
    programLevel,
  };
};

const dispatchToProps = {
  loadProgramArticulations,
  saveArticulation,
  saveBusiness,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ArticulationComponent);
