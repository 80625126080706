import React from 'react';
import { PostsecondaryInformation } from './PostsecondaryInformation';
import { PostsecondaryCourses } from './PostsecondaryCourses';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';
import { PosInfoSection } from '../PosInfoSection';
import { map } from 'lodash';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Table from 'components/Common/Table';

const PostsecondaryInformationComponent: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy } = props;
  const { postSecondaryProgram: program } = programOfStudy;
  const HEADERS: TableColumnProps[] = [
    {
      name: '',
      cell: (row: any) => {
        return row.value;
      },
    },
  ];
  return (
    <React.Fragment>
      <PostsecondaryInformation {...props} />

      <PosInfoSection
        title={
          'Industry Certifications A Student Can Earn Through This Program'
        }
        text={program.industryCertifications}
      />
      <PosInfoSection
        title={'Certificates Offered through this Program'}
        text={
          <Table
            columns={HEADERS}
            data={map(programOfStudy.certificates, (item) => {
              return { value: item };
            })}
          />
        }
      />
      <PosInfoSection
        title={'Associate Degree(s) Offered through this Program'}
        text={
          <Table
            columns={HEADERS}
            data={map(programOfStudy.associateDegrees, (item) => {
              return { value: item };
            })}
          />
        }
      />

      <PosInfoSection
        title={'Bachelor’s Degree(s) Aligned with this Program'}
        text={program.bachelorDegrees}
      />

      <PosInfoSection
        title={'Advanced Degree(s) Aligned with this Program'}
        text={program.advancedDegrees}
      />

      <PostsecondaryCourses {...props} />
    </React.Fragment>
  );
};
export default PostsecondaryInformationComponent;
