import { GRANTS_KEY, GRANTS_AGREEMENTS_SIGNATURES } from 'redux/keys';
import { DistrictSchoolSignature } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';

export const GRANT_AGREEMENTS_SIGNATURES_PATH = [
  GRANTS_KEY,
  GRANTS_AGREEMENTS_SIGNATURES,
];

export const getIsDataFetching_GrantAgreementSignatures = (
  state: unknown
): boolean => {
  return getIsDataFetching(state, GRANT_AGREEMENTS_SIGNATURES_PATH);
};

export const getRecords_GrantAgreementSignatures = (
  state: unknown
): DistrictSchoolSignature[] => {
  return getRecords(state, GRANT_AGREEMENTS_SIGNATURES_PATH);
};

export const shouldRefreshReduxState_GrantAgreementSignatures = (
  state: unknown
): boolean => {
  return shouldRefreshReduxState<DistrictSchoolSignature[]>(
    state,
    GRANT_AGREEMENTS_SIGNATURES_PATH
  );
};
