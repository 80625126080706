import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isApplicaionIdValid } from 'util/global';
import { useParams } from 'react-router';
import { loadWorkFlow } from 'redux/generalworkflow/workflow.actions';
import IconButton from 'components/Common/IconButton';
import Paper from 'components/Common/Paper';
import ReactToPrint from 'react-to-print';
import Skeleton from 'components/Common/Skeleton';
import WorkflowTray from 'components/Common/GeneralWorkflow/WorkflowTray';

interface ChangeLogLayoutProps {
  children: any;
  title: string | JSX.Element;
  subTitle: string | JSX.Element;
  setProgramNav: Function;
  loader: Function;
}

export const ChangeLogLayout: React.FC<ChangeLogLayoutProps> = (props) => {
  const { loader, children, title, subTitle } = props;

  const { entityType, entityId: eId }: any = useParams();
  const entityId = Number(eId);

  const printArea = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const getPrintAreaContent = () => {
    const parea = printArea as React.MutableRefObject<HTMLInputElement>;
    return parea.current;
  };

  useEffect(() => {
    if (isApplicaionIdValid(entityId)) {
      loader && dispatch(loader(entityId));
      dispatch(loadWorkFlow(entityType, entityId));
    }
  }, [isApplicaionIdValid, entityType, entityId]); // eslint-disable-line

  return (
    <div id="general" className="program">
      <WorkflowTray entityType={entityType} entityId={entityId} />
      <section className="program__body" ref={printArea}>
        <Paper>
          <div className="program__header u-flex">
            <div className="u-flex-grow-1">
              <h2 className="program__title">{title ? title : <Skeleton />}</h2>
              <h3 className="program__subtitle">
                {subTitle ? subTitle : <Skeleton />}
              </h3>
            </div>
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="no-print u-pseudo-link">
                    <IconButton
                      tooltip="Print"
                      name="print"
                      className="history__print__icon"
                    />
                  </div>
                );
              }}
              content={getPrintAreaContent}
            />
          </div>
        </Paper>
        <div className="program__content">{children}</div>
      </section>
    </div>
  );
};
