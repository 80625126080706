import {
  saveDataCollectionSubmission,
  loadDataCollectionSubmission,
} from 'redux/datacollection/datacollectionsubmission';

/* lets monitor this not 100% happy with using an async / await.
   Also maybe thing of a better way to type our thunk actions
*/
export const submissionManagementMiddleware = (store: any) => (
  next: Function
) => async (action: any) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveDataCollectionSubmission.SUCCEEDED: {
      dispatch(await loadDataCollectionSubmission());
      break;
    }
  }
};
