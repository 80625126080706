import React, { useState, useEffect } from 'react';
import { FlexRow } from 'layouts';
import Button from 'components/Common/FormElements/Button';
import Checkbox from 'components/Common/FormElements/Checkbox';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';

type ActiveTeacherStatusComponentProps = {
  insertActiveTeacherComplete: Function;
  complete: boolean;
};

export const ActiveTeacherStatusComponent: React.FC<ActiveTeacherStatusComponentProps> = ({
  complete,
  insertActiveTeacherComplete,
}) => {
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setIsComplete(complete);
  }, [complete]);

  return (
    <Paper>
      <Instruction title="Active Teacher Status (Final Step)">
        <p>
          As a final step, you are required to certify that the teacher records
          reported for your site are complete. Do not proceed with this step
          until you have finished your data entry and review process. When you
          are ready to proceed, click on the bubble indicating that your site
          enrollment is complete and the ‘Save’ button to finalize your
          submission.
        </p>
      </Instruction>

      <FlexRow>
        <Checkbox
          disabled={complete}
          name="complete"
          value={isComplete}
          onChange={() => setIsComplete(!isComplete)}
          label="The data submission for my associated district/college is complete."
        />
        <div className="u-flex-grow-1" />

        <ConfirmationDialog
          disabled={complete}
          tooltip="Close Active Teachers"
          header="Close Active Teachers?"
          onConfirm={insertActiveTeacherComplete}
          activateButton={
            <Button
              disabled={complete}
              text="Save"
              className="button--filled"
            />
          }
        >
          <div style={{ whiteSpace: 'normal' }}>
            <p>
              Are you sure you want to close all active teachers for your
              associated districts / institutions?
            </p>
            <p className="u-error">This action cannot be undone!</p>
          </div>
        </ConfirmationDialog>
      </FlexRow>
    </Paper>
  );
};
