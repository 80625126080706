import {
  removeProgramOfStudy,
  loadProgramOfStudyData,
  saveProgramOfStudyData,
  saveProgramOfStudyApproval,
  saveProgramOfStudyDenial,
} from 'redux/programs/programofstudy';

export const programOfStudyMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case saveProgramOfStudyApproval.SUCCEEDED:
    case saveProgramOfStudyDenial.SUCCEEDED:
    case saveProgramOfStudyData.SUCCEEDED:
    case removeProgramOfStudy.SUCCEEDED: {
      if (action.payload) {
        dispatch(loadProgramOfStudyData(action.payload));
      }
      break;
    }
  }
};
