import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { ProgramLength } from 'types';

const PROGRAM_LENGTHS_ENDPOINT = 'api/ProgramLengths/';

export const readProgramLengths = async () => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_LENGTHS_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return Promise.reject(err);
    });
}; // end

export const createProgramLengths = async (programLength: ProgramLength) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_LENGTHS_ENDPOINT}`;

  let value: Omit<ProgramLength, 'programLengthId'> = {
    programLengthName: programLength.programLengthName,
    createdDate: programLength.createdDate,
    modifiedDate: programLength.modifiedDate,
  };

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(value),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramLengths', err);
    });
}; // end function
