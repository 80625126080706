import SelectBox from 'components/Common/FormElements/SelectBox';
import { connect } from 'react-redux';
import { PERMISSION_COMPONENT_PATH } from 'redux/permissions/permissioncomponents.selectors';
import { getRecords } from 'redux/utility/_exports';
import { OptionMap, PermissionComponent, SelectWrapper } from 'types';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { onChange, selection } = ownProps;

  const records = getRecords(state, PERMISSION_COMPONENT_PATH);

  const optionMap: OptionMap<PermissionComponent> = {
    key: 'permissionComponentId',
    value: 'permissionComponentId',
    label: 'permissionComponentLabelName',
  };

  return {
    label: '',
    name: 'permissionComponentId',
    onChange,
    optionMap,
    records,
    selection,
    noLabel: true,
  };
};

export default connect(mapStateToProps)(SelectBox);
