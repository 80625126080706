import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { map } from 'lodash';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { AzureUploadParameters, QuestionGroup } from 'types';
import { QuestionsGroupLayout } from 'components/Common/Questions/QuestionsGroupLayout.component';
import DatatableLoading from 'components/Common/DatatableLoading';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';

type AssurancesFormProps = {
  isFetching: boolean;
  loadProgramAssuranceQuestions: Function;
  questions: QuestionGroup[];
  setCurrentNavAnchor: Function;
};

const AssurancesForm: React.FC<AssurancesFormProps> = (props) => {
  const {
    isFetching,
    loadProgramAssuranceQuestions,
    questions,
    setCurrentNavAnchor,
  } = props;

  const { programInstanceId }: any = useParams();

  useEffect(() => {
    loadProgramAssuranceQuestions(programInstanceId);
  }, [loadProgramAssuranceQuestions, programInstanceId]);

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|assurances`,
  };

  return (
    <Paper className="u-read-only">
      <SummaryScroll componentStep="assurances" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('assurances')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <div className="form__group">
        {!isFetching && questions && questions.length > 0 ? (
          map(questions, (group: QuestionGroup) => {
            return (
              <QuestionsGroupLayout
                uploadParams={uploadParams}
                onChange={() => {}}
                flat={true}
                key={`${group.questionGroupId}`}
                group={group}
              />
            );
          })
        ) : (
          <DatatableLoading
            loading={isFetching}
            messageBefore="Loading assurance data..."
          />
        )}
      </div>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('assurances')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};

export default AssurancesForm;
