import {
  saveCertificateOfExemptionSignatures,
  loadCertificateOfExemptionSignatures,
} from 'redux/grants/awardsignatures/certificateofexemptionsignatures';
import {
  saveCivilRightSignatures,
  loadCivilRightSignatures,
} from 'redux/grants/awardsignatures/civilrightsignatures';
import {
  saveGrantAgreementSignatures,
  loadGrantAgreementSignatures,
} from 'redux/grants/awardsignatures/grantagreementsignatures';

export const awardPacketMiddleware = ({ dispatch }: any) => (
  next: Function
) => (action: any) => {
  next(action);
  switch (action.type) {
    case saveCertificateOfExemptionSignatures.SUCCEEDED:
      dispatch(loadCertificateOfExemptionSignatures(action.payload));
      break;
    case saveGrantAgreementSignatures.SUCCEEDED:
      dispatch(loadGrantAgreementSignatures(action.payload));
      break;
    case saveCivilRightSignatures.SUCCEEDED:
      dispatch(loadCivilRightSignatures(action.payload));
      break;
  }
};
