import React from 'react';

type RenderHTMLProps = {
  htmlString?: string;
  label?: string;
};

export const RenderHTML: React.FC<RenderHTMLProps> = (props) => {
  const { htmlString = '', label } = props;

  return (
    <div className="render-html">
      {label && <p className="form__label">{label}</p>}
      <div
        className="render-html__display"
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    </div>
  );
};
