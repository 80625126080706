import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';

type BorderedTitleProps = {
  primaryText?: string | number | JSX.Element;
  secondaryText?: string | number | JSX.Element;
};

export const BorderedTitle: React.FC<BorderedTitleProps> = (props) => {
  const { primaryText, secondaryText } = props;
  return (
    <FlexRow>
      <div className={`bordered-title`}>
        <FlexGroup>
          {primaryText && <h2>{primaryText}</h2>}
          {secondaryText && (
            <label className="form__label">{secondaryText}</label>
          )}
        </FlexGroup>
      </div>
    </FlexRow>
  );
};
