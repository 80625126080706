import { connect } from 'react-redux';
import { ApplicationsDashboardComponent } from 'components/Grants/ApplicationsDashboard/Dashboard.component';
import {
  getIsDataFetching_GrantFiscalYear,
  getAdvancedFilteredRecords_GrantFiscalYear,
} from 'redux/grants/_selectors';
import { loadGrantFiscalYear } from 'redux/grants/grantfiscalyear';
import { getPerkinsGrantFilters } from 'redux/filters/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const grants = getAdvancedFilteredRecords_GrantFiscalYear(state);
  const isFetching = getIsDataFetching_GrantFiscalYear(state);
  const filters = getPerkinsGrantFilters(state).length;

  return {
    filters,
    grants,
    isFetching,
  };
};

const dispatchProps = {
  loadGrantFiscalYear,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ApplicationsDashboardComponent);
