/**
 * This file contains a function to help in calculating if a user can edit a grant Workflow.
 * @module Utilities > WorkflowUtility
 * @author AmplifyCP
 */
import { WorkflowStep, UniversalContact } from 'types';
import { map, some } from 'lodash';
import { contactHasRoles, isSuperAdmin } from './permissionsUtility';

/**
 * Determines if the user is allowed to edit the workflow.
 * @function
 * @param currentStep the current workflow step
 * @return { boolean } true if the user can edit the workflow else false
 */
export const canEditGrantsWorkflow = (
  currentStep: WorkflowStep,
  user: UniversalContact,
  override: boolean = false
): boolean => {
  const approvers = currentStep ? currentStep.approvers : [];
  const isApprover = some(map(approvers), (item) => {
    return item.universalContactId === user.universalContactId;
  });
  return some([
    isSuperAdmin(user),
    override &&
      contactHasRoles(user, [
        'perkinsplanmanager',
        'consortiumfiscalAgentcontact',
      ]),
    isApprover,
  ]);
};
