import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { readCtsoCodes } from 'services/common/ctso.services';
import { CTSO_Type } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadCtsoCodes = createActionThunk('loadCtsoCodes', () => {
  return readCtsoCodes();
});

export default handleActions<BaseStateObject, CTSO_Type[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<CTSO_Type[]>(loadCtsoCodes),
  },
  initialState
);
