import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { isApplicaionIdValid } from 'util/global';
import { Program, ProgramStep } from 'types';
import { useParams } from 'react-router';
import { ProgramGeneralValidations } from 'validations/ProgramGeneral.validations';
import { every } from 'lodash';
import { mergeObjects, display } from 'util/objectUtility';
import CareerClusterSelectBox from 'components/Common/SelectBoxes/CareerClusterSelectBox';
import CTSOMultiSelectBox from 'components/Common/SelectBoxes/CTSOMultiSelectBox';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import ONetAttribution from 'components/Program/ONetAttribution';
import Paper from 'components/Common/Paper';
import ProgramAreaSelectBox from 'components/Common/SelectBoxes/ProgramAreaSelectBox';
import ProgramLengthSelectBox from 'components/Common/SelectBoxes/ProgramLengthSelectBox';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import WarningAlert from 'components/Common/WarningAlert';
import EditProgramOwner from './EditProgramOwner/';
import Loading from 'components/Common/Loading';

type GeneralEditProps = {
  permission?: string;
  program: Program;
  readOnly: boolean;
  saveProgramInstanceAction: Function;
  setCurrentNavAnchor: Function;
  nextProgramStep: ProgramStep;
  isProgramDataFetching: boolean;
};

export const GeneralEditComponent: React.FC<GeneralEditProps> = (props) => {
  const {
    permission,
    program,
    readOnly,
    saveProgramInstanceAction,
    setCurrentNavAnchor,
    nextProgramStep,
    isProgramDataFetching,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const [programState, setProgramState] = useState<Program>({ ...program });

  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = ProgramGeneralValidations();

  const onProgramChange = (
    data: Partial<Program>,
    key: keyof Program,
    value: any
  ) => {
    const updated = mergeObjects<Program>(programState, data);
    const termsAndLengthGroup = ['terms', 'programLengthId'];
    if (termsAndLengthGroup.includes(key)) {
      validateIfTrue('terms', updated.terms, updated);
      validateIfTrue('programLengthId', updated.programLengthId, updated);
    } else {
      validateIfTrue(key, value, updated);
    }
    setProgramState(updated);
  };

  const onTextAreaChange = (
    data: Partial<Program>,
    key: keyof Program,
    value: string
  ) => {
    validateIfTrue(key, value, programState);
    setProgramState({
      ...programState,
      ...data,
      onetReviewed: false,
    });
    validate('onetReviewed', false, programState);
  };

  const onSaveProgram = (): Promise<any> => {
    if (validateAll(programState)) {
      return saveProgramInstanceAction(programState);
    }
    return Promise.reject(
      'Not all validations passed. Please check for errors and complete the form.'
    );
  };

  const render = display(programState);

  useEffect(() => {
    if (isApplicaionIdValid(program.programInstanceId)) {
      setProgramState({ ...program });
    }
    return () => {};
  }, [program]);

  useEffect(() => {
    setCurrentNavAnchor('general');
  }, [setCurrentNavAnchor]);

  return (
    <React.Fragment>
      <Loading isActive={isProgramDataFetching} />
      <ScrollToTop />
      <SecureWrap component="programs" isLocked={readOnly}>
        <Paper>
          <Instruction title="General Program Information">
            <p>
              Verify the Program Description and Occupational Information that
              are provided below. You may modify this information if desired.
              Enter the requested information using the drop down menus. You
              must verify you have reviewed the O*NET information to proceed.
            </p>
          </Instruction>
          <FlexRow>
            <FlexGroup>
              <p className="form__label">CIP ID</p>
              <p className="input-display">{render('cipId')}</p>
            </FlexGroup>
            <FlexGroup>
              <p className="form__label">Program ID</p>
              <p className="input-display">{render('programInstanceId')}</p>
            </FlexGroup>
            <div className="form__group">
              <EditProgramOwner program={programState} />
            </div>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <p className="form__label">Program Name</p>
              <p className="input-display">{render('programName')}</p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <Textarea
              name="programDescription"
              value={programState.programDescription}
              onChange={onTextAreaChange}
              errorMessage={getError('programDescription')}
              onBlur={() => {
                validate(
                  'programDescription',
                  programState.programDescription,
                  programState
                );
              }}
              valid={getFieldValid('programDescription')}
            />
          </FlexRow>
          <FlexRow>
            <Input
              type="number"
              min={0}
              max={500000000}
              step={1}
              name="terms"
              label="Program Length"
              value={programState.terms}
              onChange={onProgramChange}
              errorMessage={getError('terms')}
              onBlur={() => validate('terms', programState.terms, programState)}
              valid={getFieldValid('terms')}
            />
            <div
              onBlur={() =>
                validate(
                  'programLengthId',
                  programState.programLengthId,
                  programState
                )
              }
              className="form__group"
            >
              <ProgramLengthSelectBox
                selection={programState.programLengthId}
                onChange={onProgramChange}
                errorMessage={getError('programLengthId')}
                valid={every([
                  getFieldValid('programLengthId'),
                  getFieldValid('terms'),
                ])}
              />
            </div>
          </FlexRow>
          <FlexRow>
            <CareerClusterSelectBox
              selection={programState.careerClusterId}
              disabled={permission && permission !== 'full'}
              onChange={onProgramChange}
            />
            <ProgramAreaSelectBox
              selection={programState.careerSectorId}
              value={programState.careerSectorId}
              disabled={permission && permission !== 'full'}
              onChange={onProgramChange}
            />
          </FlexRow>
          {program.programLevelId !== 2 && (
            <FlexRow>
              <div
                className="form__group"
                onBlur={() => {
                  validate('ctsO_IDs', programState.ctsO_IDs, programState);
                }}
              >
                <CTSOMultiSelectBox
                  ctsO_IDs={programState.ctsO_IDs}
                  onChange={onProgramChange}
                  errorMessage={getError('ctsO_IDs')}
                  valid={getFieldValid('ctsO_IDs')}
                />
              </div>
            </FlexRow>
          )}
          {programState.programLevelId === 2 && (
            <React.Fragment>
              <FlexRow>
                <Textarea
                  name="programRequirement"
                  label="Prerequisites For Entry Into This Program"
                  value={programState.programRequirement}
                  onChange={onTextAreaChange}
                  errorMessage={getError('programRequirement')}
                  valid={getFieldValid('programRequirement')}
                  onBlur={() => {
                    validate(
                      'programRequirement',
                      programState.programRequirement,
                      programState
                    );
                  }}
                />
              </FlexRow>
              <FlexRow>
                <Textarea
                  name="industryCertifications"
                  label="The Industry Certifications A Student Can Earn Through This Program"
                  value={programState.industryCertifications}
                  onChange={onTextAreaChange}
                  errorMessage={getError('industryCertifications')}
                  valid={getFieldValid('industryCertifications')}
                  onBlur={() => {
                    validate(
                      'industryCertifications',
                      programState.industryCertifications,
                      programState
                    );
                  }}
                />
              </FlexRow>
              <FlexRow>
                <Textarea
                  name="bachelorDegrees"
                  label="Bachelor’s Degree(s) Program Aligns To"
                  value={programState.bachelorDegrees}
                  onChange={onTextAreaChange}
                  errorMessage={getError('bachelorDegrees')}
                  valid={getFieldValid('bachelorDegrees')}
                  onBlur={() => {
                    validate(
                      'bachelorDegrees',
                      programState.bachelorDegrees,
                      programState
                    );
                  }}
                />
              </FlexRow>
              <FlexRow>
                <Textarea
                  name="advancedDegrees"
                  label="Advanced Degree(s) Program Aligns To"
                  value={programState.advancedDegrees}
                  onChange={onTextAreaChange}
                  errorMessage={getError('advancedDegrees')}
                  valid={getFieldValid('advancedDegrees')}
                  onBlur={() => {
                    validate(
                      'advancedDegrees',
                      programState.advancedDegrees,
                      programState
                    );
                  }}
                />
              </FlexRow>
            </React.Fragment>
          )}
          {(programState.programLevelId === 1 ||
            programState.programLevelId === 2) && (
            <React.Fragment>
              <FlexRow>
                <Textarea
                  name="workBasedLearning"
                  label="Work-based learning options for students"
                  value={programState.workBasedLearning}
                  onChange={onTextAreaChange}
                  errorMessage={getError('workBasedLearning')}
                  onBlur={() => {
                    validate(
                      'workBasedLearning',
                      programState.workBasedLearning,
                      programState
                    );
                  }}
                  valid={getFieldValid('workBasedLearning')}
                  additionalInfo={
                    <span>
                      {
                        'Please identify opportunities for students to participate in work-based learning as part of your program (i.e., co-op, internship, externship, clinical-based, supervised experience). Enter this information below.'
                      }
                    </span>
                  }
                />
              </FlexRow>
              <FlexRow>
                <FlexGroup>
                  <h2 className="form__section-title">
                    Occupational Information
                  </h2>
                  <p>
                    CTE programs prepare learners for a range of occupations
                    that are high skill, high wage and in demand. Accordingly,
                    it’s important to review labor market information (alongside
                    other sources, such as business and community feedback) to
                    make sure you are on target to meet industry need.
                  </p>
                </FlexGroup>
              </FlexRow>
              <FlexRow>
                <Textarea
                  name="employmentDemandInformation"
                  value={programState.employmentDemandInformation}
                  onChange={onTextAreaChange}
                  errorMessage={getError('employmentDemandInformation')}
                  onBlur={() => {
                    validate(
                      'employmentDemandInformation',
                      programState.employmentDemandInformation,
                      programState
                    );
                  }}
                  valid={getFieldValid('employmentDemandInformation')}
                  additionalInfo={
                    <span>
                      {'Please visit the '}
                      <a
                        href="https://www.colmigateway.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Colorado LMI Gateway
                      </a>
                      {
                        ' to identify the long-term occupational projections and current annual wages associated with this program and enter this information below. Visit the '
                      }
                      <a
                        href="http://coloradostateplan.com/educator/cte-standards-program-approval/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Colorado CTE Program Approval page
                      </a>
                      {
                        ' if you need additional tips on how to structure your response.'
                      }
                    </span>
                  }
                />
              </FlexRow>
              <FlexRow>
                <Textarea
                  name="occupationInfoProgramCompletion"
                  label="Occupations Students Will Be Prepared For Upon Program Completion"
                  value={programState.occupationInfoProgramCompletion}
                  onChange={onTextAreaChange}
                  errorMessage={getError('occupationInfoProgramCompletion')}
                  onBlur={() => {
                    validate(
                      'occupationInfoProgramCompletion',
                      programState.occupationInfoProgramCompletion,
                      programState
                    );
                  }}
                  valid={getFieldValid('occupationInfoProgramCompletion')}
                  additionalInfo={
                    <span>
                      {
                        'Occupational information will populate for some programs. If no information appears below, please visit O*NET Online and input the program CIP code or the CIP code family (first two digits of CIP code) in the '
                      }
                      <a
                        href="https://www.onetonline.org/crosswalk/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Education Crosswalk Search
                      </a>
                      {
                        ' to identify occupations associated with your program or career pathway. Enter this information below.'
                      }
                    </span>
                  }
                />
              </FlexRow>
            </React.Fragment>
          )}
          {programState.programLevelId !== 5 && (
            <React.Fragment>
              <FlexRow>
                <FlexGroup>
                  <FlexRow>
                    <Toggle
                      name="onetReviewed"
                      label="O*NET reviewed"
                      value={programState.onetReviewed}
                      onChange={onProgramChange}
                      description="I have reviewed/entered the occupational information from O*NET."
                    />
                  </FlexRow>
                </FlexGroup>
                <ONetAttribution />
              </FlexRow>
              <FlexRow>
                {!getFieldValid('onetReviewed') && (
                  <FlexRow>
                    <WarningAlert iconPosition="left">
                      {getError('onetReviewed')}
                    </WarningAlert>
                  </FlexRow>
                )}
              </FlexRow>
            </React.Fragment>
          )}
        </Paper>
        <Paper>
          <SaveButtons
            cancelUrl={`/programs/program-steps/${programInstanceId}`}
            saveData={onSaveProgram}
            saveUrl={nextProgramStep.nav.url}
            saveText="Save and Continue"
            disabled={!isValid}
            validationErrors={validationErrors}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
