import { connect } from 'react-redux';
import {
  getIsDataFetching_Consortia,
  getConsortiumByFiscalYear,
} from 'redux/grants/_selectors';
import { ConsortiumAddEditComponent } from './Consortium.component';
import { saveConsortia } from 'redux/grants/consortium';
import { loadFiscalAgents } from 'redux/grants/fiscalagents';
import {
  getRecords_FiscalAgents,
  getIsDataFetching_FiscalAgents,
} from 'redux/grants/fiscalagents.selectors';
import { some } from 'lodash';
import { getRecords_UniversalContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { getLoggedinUser } from 'redux/user/selectors';
import { getRecords_FiscalYear } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const consortiumId = Number(ownProps.match.params.consortiumId);
  const fiscalYear = Number(ownProps.match.params.fiscalYear);
  const consortium = getConsortiumByFiscalYear(state, consortiumId, fiscalYear);
  const fiscalAgents = getRecords_FiscalAgents(state);
  const contacts = getRecords_UniversalContact(state);
  const years = getRecords_FiscalYear(state);
  const loggedInUser = getLoggedinUser(state);

  const isFetching = some([
    getIsDataFetching_Consortia(state),
    getIsDataFetching_FiscalAgents(state),
  ]);

  return {
    years,
    consortium,
    contacts,
    fiscalAgents,
    isFetching,
    loggedInUser,
  };
};

const dispatchToProps = {
  loadFiscalAgents,
  saveConsortia,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ConsortiumAddEditComponent);
