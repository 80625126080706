import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useRef, useState, useEffect } from 'react';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import TableComponent from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import { CTABooksSchool, CTAProgram, emptyCTAProgram } from 'types';
import { CTAProgramValidation } from 'validations/CTA/CTAProgram.validations';
import { FlexRow, FlexGroup } from 'layouts';
import { Link } from 'react-router-dom';
import { Modal, useModal } from 'react-morphing-modal';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { defaultTo, gt as lt, all } from 'ramda';
import { fmap } from 'util/arrayUtility';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { isEqual } from 'util/formHelpers';
import { map, some } from 'lodash';
import {
  renderData,
  prop,
  compose,
  mergeObjects,
  safeGet,
  isTruthy,
} from 'util/objectUtility';
import { saveCTABooksSchool } from 'redux/cta/ctabooksschool';
import { sumNumbers } from 'util/mathHelpers';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';

type BooksSuppliesModalProps = {
  ctaBooksSchool: CTABooksSchool;
  year: number;
  district: string;
};

export const BooksSuppliesModal: React.FC<BooksSuppliesModalProps> = (
  props
) => {
  const { ctaBooksSchool, district, year } = props;

  // -- dependencies --
  const dispatch = useDispatch();
  const { modalProps, open, close } = useModal();
  const { validateAll } = CTAProgramValidation();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local states --
  const modalRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [booksSchools, setBooksSchools] = useState<CTABooksSchool>(
    ctaBooksSchool
  );

  // -- component logic --
  const validateAllPrograms = compose(all(isTruthy), fmap(validateAll));
  const handleChange = (program: CTAProgram) => (data: Partial<CTAProgram>) => {
    const updated = mergeObjects<CTAProgram>(program, data);
    const programs = map(booksSchools.programs, (p: CTAProgram) => {
      return isEqual(program.ctaProgramId, p.ctaProgramId) ? updated : p;
    });
    setBooksSchools(mergeObjects(booksSchools, { programs }));
  };

  const handleFileUpload = (document: string = '') => {
    setBooksSchools(mergeObjects(booksSchools, { document }));
  };

  const handleSave = (payload: CTABooksSchool) => {
    const canSave = validateAllPrograms(prop('programs', booksSchools));
    if (canSave) {
      close();
      dispatch(saveCTABooksSchool(payload)).then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      });
    } else {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  const costs = compose(
    sumNumbers,
    fmap(prop('costs')),
    defaultTo([emptyCTAProgram()]),
    prop('programs')
  )(booksSchools);

  // -- lifecyle --
  useEffect(() => {
    setBooksSchools(ctaBooksSchool);
  }, [ctaBooksSchool]);

  // -- display logic --
  const get = safeGet(booksSchools);
  const getCosts = compose(defaultTo(0), prop('costs'));
  const displayTotal = compose(insertDollarSign, formatMoney, getCosts);
  const hide = compose(lt(0), prop('ctaProgramId'));
  const renderBooksAndSuppliesAmount = (row: CTAProgram) => {
    const { getError, getFieldValid, validate } = CTAProgramValidation();
    return hide(row) ? (
      displayTotal(row)
    ) : (
      <Input
        className="form__input u-align-right"
        errorMessage={getError('costs')}
        key={prop('ctaBookSupplyId', row)}
        min={0}
        name="costs"
        noLabel={true}
        onBlur={() => validate('costs', getCosts(row))}
        onChange={handleChange(row)}
        type="number"
        valid={getFieldValid('costs')}
        value={getCosts(row)}
      />
    );
  };

  const PROGRAMS_COLUMNS: TableColumnProps[] = [
    {
      cell: compose(renderData, prop('programName')),
      name: 'Program Name',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: renderBooksAndSuppliesAmount,
      name: 'Total Cost',
      style: {
        textAlign: 'right',
        width: '20rem',
      },
    },
  ];

  const conditionalStyles = [
    {
      when: compose(lt(0), prop('ctaProgramId')),
      style: {
        backgroundColor: 'transparent',
      },
    },
  ];

  const totalRow: CTAProgram = {
    ctaProgramId: -1,
    costs,
    programName: 'Total',
  };

  const tableData = [...defaultTo([], get('programs')), totalRow];

  const getConfirmation = () => {
    const zero = map(booksSchools.programs, (item) => {
      return item.costs === 0;
    });
    return some(zero)
      ? {
          tooltip: 'Confirm Zero Costs Entered',
          header: `No Costs entered.`,
          text: `Costs have not been entered for one or more of the programs above.  Reminder that if any costs are reported for a program, all costs should be.  By clicking confirm you are asserting that the district has no costs for books and supplies.`,
        }
      : undefined;
  };

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <IconButton
          name="pencil"
          tooltip="Edit"
          onClick={() => open(modalRef)}
        />
      </div>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <Modal {...modalProps}>
          <SecureWrap component={['ctageneral']} isLocked={readOnly}>
            <Paper className="t-books-supplies-costs">
              <FlexRow>
                <Instruction
                  title={`Books and Supplies Costs for ${renderData(
                    get('schoolName')
                  )}`}
                >
                  <p>
                    For each program, enter information for all books and
                    supplies costs being reported. If a sum of $0 is entered,
                    please note that you are attesting that there were not
                    documented general fund expenditures for this program. Refer
                    to the Administrators’ Handbook for guidance on what is
                    allowable to be reported in this section. Include any
                    stipends paid to appropriately credentialed employees who
                    were not reported in Instructional or Support Personnel
                    Costs (e.g. if an English teacher with a Business and
                    Marketing credential was paid a stipend to be the FBLA
                    advisor). Equipment purchases for units of equipment costing
                    more than $5,000 per unit will be reported in the next
                    section and should not be included in totals reported here.
                    Double check your documentation as reporting equipment twice
                    is a common error. Expenses must be based on general ledger
                    or other documentation, which should be retained in case of
                    an audit. When you have finished entering cost data please
                    combine your documentation for CTE programs at this school
                    into a single electronic file (PDF or XLS) and upload it
                    using the ‘Upload Document’ field. Click on the ‘Save’
                    button to return to the ‘Books and Supplies Costs’ section.
                  </p>
                  <p>
                    <span className="u-color-red">Note</span>: If a program
                    needs to be added, have the person with the student record
                    permission enter student enrollment information in the
                    <Link
                      rel="noreferrer"
                      target="_blank"
                      to="/data-collection"
                    >
                      Data Collection
                    </Link>{' '}
                    module. You will not be able to enter program costs until
                    the day after the program was reported on in Data
                    Collections, allowing time for the database to update.
                  </p>
                  <p>
                    <span className="u-color-red">Note</span>: If you upload a
                    second document, it will replace the previous one.
                  </p>
                </Instruction>
              </FlexRow>
              <FlexRow>
                <FlexGroup>
                  <TableComponent
                    columns={PROGRAMS_COLUMNS}
                    conditionalRowStyles={conditionalStyles}
                    data={tableData}
                    tableRowKey="ctaProgramId"
                  />
                </FlexGroup>
              </FlexRow>
              <FlexRow>
                <FlexGroup>
                  <p className="form__label">Upload Document</p>
                  {get('document') ? (
                    <AzureDownloader
                      onClose={() => handleFileUpload('')}
                      uploadParams={{
                        container: 'cta',
                        folder: `${year}|${district}|books-supplies|${get(
                          'schoolName'
                        )}`,
                      }}
                      filepath={get('document')}
                    />
                  ) : (
                    <Button
                      text="+ Upload File"
                      className="form__btn--add u-flex-self-start grant-schedule__upload-btn"
                      onClick={() => setOpen(true)}
                    />
                  )}
                  <AzureUploader
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    uploadParams={{
                      container: 'cta',
                      folder: `${year}|${district}|books-supplies|${get(
                        'schoolName'
                      )}`,
                    }}
                    onConfirm={handleFileUpload}
                  />
                </FlexGroup>
              </FlexRow>
              <SaveButtons
                confirmation={getConfirmation()}
                onCancel={close}
                alternateAction={() => handleSave(booksSchools)}
              />
            </Paper>
          </SecureWrap>
        </Modal>
      </div>
    </React.Fragment>
  );
};
