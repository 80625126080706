import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { ImprovementDeficiencies } from 'components/Grants/Applications/ImprovementPlan/ImprovementPlanConnect';

export const ImprovementPlanPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <ImprovementDeficiencies />
      </GrantsLayout>
    </BaseLayout>
  );
};
