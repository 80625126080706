import { GRANTS_KEY, GRANTS_VOUCHERS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';
import {
  Voucher,
  emptyVoucher,
  VoucherSpendingCategory,
  emptyVoucherSpendingCategory,
} from 'types';
import { find } from 'lodash';
import { getSpendingCategory } from 'util/annualSpendingUtility';
import { addTotals, sumCollection } from 'util/mathHelpers';
import { getVoucherTotalAllocated } from 'util/voucherUtility';

export const VOUCHERS_PATH = [GRANTS_KEY, GRANTS_VOUCHERS_KEY];

export const getIsDataFetching_Vouchers = (state: any): boolean => {
  return getIsDataFetching(state, VOUCHERS_PATH);
};

export const getRecords_Vouchers = (state: unknown): Voucher[] => {
  return getRecords(state, VOUCHERS_PATH);
};

export const shouldRefreshReduxState_Vouchers = (state: any): boolean => {
  return shouldRefreshReduxState<Voucher>(state, VOUCHERS_PATH);
};

export const getSingleVoucherByType = (
  state: any,
  voucherType: string
): Voucher => {
  const vouchers = getRecords_Vouchers(state);
  let result;
  switch (voucherType) {
    case 'voucher-one':
      result = find(vouchers, { voucherName: 'Voucher 1' });
      return result ? result : emptyVoucher();

    case 'voucher-two':
      result = find(vouchers, { voucherName: 'Voucher 2' });
      return result ? result : emptyVoucher();

    case 'voucher-three':
      result = find(vouchers, { voucherName: 'Voucher 3 (Optional)' });
      return result ? result : emptyVoucher();

    case 'final-voucher':
      result = find(vouchers, { voucherName: 'Final Voucher' });
      return result ? result : emptyVoucher();

    default:
      return emptyVoucher();
  }
};

export const getVoucherSpendingCategories = (
  state: any,
  voucherType: string
) => {
  const voucher = getSingleVoucherByType(state, voucherType);
  const { categories } = voucher;

  // For readability, 'getSpendingCategory' is renamed 'extract' locally
  const extract = getSpendingCategory;
  const improvementPlan = extract(10, categories) as VoucherSpendingCategory[];
  const careerInfo = extract(1, categories) as VoucherSpendingCategory[];
  const partnerships = extract(2, categories) as VoucherSpendingCategory[];
  const quality = extract(3, categories) as VoucherSpendingCategory[];
  const equity = extract(4, categories) as VoucherSpendingCategory[];
  const instructors = extract(5, categories) as VoucherSpendingCategory[];
  const workBasedLearning = extract(6, categories) as VoucherSpendingCategory[];
  const needsAssessment = extract(9, categories) as VoucherSpendingCategory[];
  const directAdmin = extract(8, categories) as VoucherSpendingCategory[];
  const indirectAdmin = extract(7, categories) as VoucherSpendingCategory[];

  const spendingCategories = {
    improvementPlan,
    careerInfo,
    partnerships,
    quality,
    equity,
    instructors,
    workBasedLearning,
    needsAssessment,
    directAdmin,
    indirectAdmin,
  };
  const addRequested = addTotals('reimbursementRequest');
  const addTotalsForCurrentRequest = addTotals('totalAmountForCurrentRequest');

  const totalReimbursementRequests = categories
    ? sumCollection(addRequested, categories)
    : 0;

  const totalCurrentRequest = categories
    ? sumCollection(addTotalsForCurrentRequest, categories)
    : 0;

  const getAllocatedFunds = () => {
    return getVoucherTotalAllocated(voucher);
  };

  const totalRowData: VoucherSpendingCategory[] = [
    {
      ...emptyVoucherSpendingCategory(),
      strategy: 'Total',
      allocatedFunds: getAllocatedFunds(),
      reimbursementRequest: totalReimbursementRequests,
      totalAmountForCurrentRequest: totalCurrentRequest,
    },
  ];

  const projectPlans = [
    ...spendingCategories.improvementPlan,
    ...spendingCategories.careerInfo,
    ...spendingCategories.partnerships,
    ...spendingCategories.quality,
    ...spendingCategories.equity,
    ...spendingCategories.instructors,
    ...spendingCategories.workBasedLearning,
  ];

  const adminCosts = [
    ...spendingCategories.needsAssessment,
    ...spendingCategories.directAdmin,
    ...spendingCategories.indirectAdmin,
  ];

  return {
    adminCosts,
    projectPlans,
    totalRowData,
  };
};
