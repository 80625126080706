import {
  ReimbursementRequestTotal,
  emptyReimbursementRequestTotal,
} from 'types';

export interface ReimbursementSummary {
  fiscalYear: number;
  institutionName: string;
  institutionCode: string;
  vendorCode: string;
  dunsNumber: string;
  cfda: string;
  voucherOneCannotExceed: number;
  basicGrant: ReimbursementRequestTotal;
  reserveGrant: ReimbursementRequestTotal;
  submission?: Date;
  approval?: Date;
}

export const emptyReimbursementSummary = (): ReimbursementSummary => {
  return {
    fiscalYear: 0,
    institutionName: '',
    institutionCode: '',
    vendorCode: '',
    dunsNumber: '',
    cfda: '',
    voucherOneCannotExceed: 0,
    basicGrant: emptyReimbursementRequestTotal(),
    reserveGrant: emptyReimbursementRequestTotal(),
  };
};
