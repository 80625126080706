import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { AwardPacketEdit } from 'components/Grants/Applications/AwardPacket/AwardPacketConnect';

export const GrantAwardPacketPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AwardPacketEdit />
      </GrantsLayout>
    </BaseLayout>
  );
};
