import React, { useState, useRef } from 'react';
import { first } from 'lodash';
import Chip from 'components/Common/FormElements/Chip';
import { formatFile } from 'util/formHelpers';
import { FileDrop } from 'react-file-drop';

type FileDropZoneProps = {
  onUpload: Function;
};

const FileDropZone: React.FC<FileDropZoneProps> = (props) => {
  const { onUpload } = props;
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileState, setFile] = useState<File | null>();

  const handleDrop = (files: FileList | null) => {
    files && setFile(first(files));
  };

  const handleClickChange = () => {
    if (inputFile.current != null) {
      setFile(first(inputFile.current.files));
    }
  };

  const handleFileUpload = () => {
    onUpload(fileState);
    inputFile.current && (inputFile.current.value = '');
    setFile(null);
  };

  const handleFileSelect = (event: any) => {
    if (inputFile.current != null) {
      inputFile.current.click();
    }
  };

  const styles = { border: '2px dashed #13838e', height: '200px' };

  return (
    <React.Fragment>
      <input
        aria-label="Upload file"
        type="file"
        ref={inputFile}
        onChange={handleClickChange}
        style={{ display: 'none' }}
      />
      <div style={styles}>
        <FileDrop onDrop={handleDrop}>
          <div className="u-flex-vertical">
            <h3>Drag and Drop Files Here</h3>

            <div className="input-field-group">Or if you prefer…</div>
            <button className="button--filled" onClick={handleFileSelect}>
              Select file to upload
            </button>
          </div>
        </FileDrop>
      </div>
      <div className="u-flex" style={{ height: '5.0rem', paddingTop: '1rem' }}>
        <div className="u-flex-grow-1">
          {fileState && (
            <Chip
              style={{ height: '5rem', padding: '0 1rem' }}
              name={<p>{formatFile(fileState.name)}</p>}
              id={fileState.name}
              onClose={(id: any) => {
                inputFile.current && (inputFile.current.value = '');
                setFile(null);
              }}
            />
          )}
        </div>
        <button
          onClick={handleFileUpload}
          style={{ marginLeft: '1rem' }}
          className="button--outline"
        >
          Upload
        </button>
      </div>
    </React.Fragment>
  );
};

export default FileDropZone;
