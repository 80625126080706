import React from 'react';
import { SideNavData } from 'types';
import CollapseButton from 'components/Common/CollapseButton';
import { NavList } from './NavList.component';
import NavigationTitle from './NavigationTitle';
import { insertClass } from 'util/formHelpers';

type SideNavProps = {
  actionButton?: JSX.Element;
  iconName: string;
  navagationParameters: number[] | string[];
  navTrayClosed: boolean;
  setNavTrayClosed: Function;
  navData: SideNavData;
  titleBarColor: string;
};

export const SideNav: React.FC<SideNavProps> = (props) => {
  const {
    actionButton,
    iconName,
    navData,
    navTrayClosed,
    setNavTrayClosed,
    titleBarColor,
  } = props;

  const renderActionButton = () => {
    return actionButton && !navTrayClosed;
  };

  return (
    <div
      className={`side-nav-container ${navTrayClosed &&
        insertClass('side-nav-container--collapsed')}`}
    >
      <div
        className={navTrayClosed ? 'side-nav side-nav--collapsed' : 'side-nav'}
      >
        <NavigationTitle
          color={titleBarColor}
          iconName={iconName}
          title={navData.title}
        />
        <NavList {...props} />
        {renderActionButton() && actionButton}
        <CollapseButton
          trayClosed={navTrayClosed}
          setClosed={setNavTrayClosed}
          origin="right"
        />
      </div>
    </div>
  );
};
