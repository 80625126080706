import React from 'react';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { FlexRow, FlexGroup } from 'layouts';
import { ProgramFilterState } from 'types';
import Button from 'components/Common/FormElements/Button';
import CareerClusterSelectBox from 'components/Common/SelectBoxes/CareerClusterSelectBox';
import DistrictSelectBox from 'components/Common/SelectBoxes/DistrictSelectBox';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Paper from 'components/Common/Paper';
import ProgramAreaSelectBox from 'components/Common/SelectBoxes/ProgramAreaSelectBox';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import ToggleButton from 'components/Common/FormElements/ToggleButton';
import ProgramStateMultiSelectBox from 'components/Common/SelectBoxes/ProgramStateMultiSelectBox';

type ProgramFiltersProps = {
  filter: ProgramFilterState;
  setProgramFilterValue: Function;
  resetProgramFilterValue: Function;
  clearProgramFilters: Function;
};

export const ProgramFilterComponent: React.FC<ProgramFiltersProps> = (
  props
) => {
  const {
    filter,
    setProgramFilterValue,
    resetProgramFilterValue,
    clearProgramFilters,
  } = props;

  const setProgramLevelId = (level: number) => {
    if (level === filter.programLevelId) {
      setProgramFilterValue({ programLevelId: -1 });
    } else {
      setProgramFilterValue({ programLevelId: level });
    }
  };
  const updateFilter = (
    data: { [key: string]: unknown },
    name: string,
    value: number
  ) => {
    if (value) {
      setProgramFilterValue(data);
    } else {
      resetProgramFilterValue(name);
    }
  };

  const updateAndResetFilter = (
    data: { [key: string]: unknown },
    name: string,
    value: number,
    filterToReset: string
  ) => {
    updateFilter(data, name, value);
    resetProgramFilterValue(filterToReset);
  };

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Secondary"
          value={filter.programLevelId === PROGRAM_LEVEL_ENUM.Secondary}
          onChange={() => {
            setProgramLevelId(PROGRAM_LEVEL_ENUM.Secondary);
          }}
        />
        <ToggleButton
          text="Postsecondary"
          value={filter.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary}
          onChange={() => {
            setProgramLevelId(PROGRAM_LEVEL_ENUM.Postsecondary);
          }}
        />
        <ToggleButton
          text="Middle School"
          value={filter.programLevelId === PROGRAM_LEVEL_ENUM['Middle School']}
          onChange={() => {
            setProgramLevelId(PROGRAM_LEVEL_ENUM['Middle School']);
          }}
        />
        <ToggleButton
          text="Exclude Archived"
          value={filter.excludeArchived === true}
          onChange={() => {
            setProgramFilterValue({
              excludeArchived: filter.excludeArchived ? undefined : true,
            });
          }}
        />
        <ToggleButton
          text="Exclude Inactive"
          value={filter.excludeInactive === true}
          onChange={() => {
            setProgramFilterValue({
              excludeInactive: filter.excludeInactive ? undefined : true,
            });
          }}
        />
      </div>
      <FlexRow>
        <DistrictSelectBox
          clearable={true}
          shouldReset={true}
          onChange={updateFilter}
          selection={filter.districtId > 0 ? filter.districtId : undefined}
        />
      </FlexRow>
      <FlexRow>
        <InstituteSelectBox
          clearable={true}
          shouldReset={true}
          selection={filter.instituteId > 0 ? filter.instituteId : undefined}
          onChange={(data, name, value) => {
            updateAndResetFilter(
              { instituteId: value },
              'instituteId',
              value,
              'schoolId'
            );
          }}
        />
      </FlexRow>
      <FlexRow>
        <SchoolSelectBox
          filter={[]}
          clearable={true}
          shouldReset={true}
          onChange={(data: any, name: any, value: any) => {
            updateAndResetFilter(data, name, value, 'instituteId');
          }}
          selection={filter.schoolId > 0 ? filter.schoolId : undefined}
        />
      </FlexRow>

      <FlexRow>
        <CareerClusterSelectBox
          clearable={true}
          shouldReset={true}
          onChange={updateFilter}
          selection={
            filter.careerClusterId > 0 ? filter.careerClusterId : undefined
          }
        />
      </FlexRow>

      <FlexRow>
        <ProgramAreaSelectBox
          clearable={true}
          shouldReset={true}
          onChange={updateFilter}
          selection={
            filter.careerSectorId > 0 ? filter.careerSectorId : undefined
          }
        />
      </FlexRow>

      <FlexRow>
        <ProgramStateMultiSelectBox
          clearable={true}
          shouldReset={true}
          onChange={updateFilter}
        />
      </FlexRow>

      <FlexGroup>
        <Button
          onClick={clearProgramFilters}
          className="button--outline"
          text="Reset Filters"
        />
      </FlexGroup>
    </Paper>
  );
};
