import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  updateNonCTECourses,
  readNonCTECourses,
} from 'services/cta/ctainstructionalcosts.services';
import { CtaInstructorCourse } from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';

const initialState = baseInitialState;

export const loadNonCTECourses = createActionThunk(
  'loadNonCTECourses',
  (ctaInstructorId: number) => {
    return readNonCTECourses(ctaInstructorId);
  }
);

export const saveNonCTECourses = createActionThunk(
  'saveNonCTECourses',
  (ctaId: number, ctaInstructorId: number, records: CtaInstructorCourse[]) => {
    return updateNonCTECourses(ctaId, ctaInstructorId, records);
  }
);

export default handleActions<BaseStateObject, CtaInstructorCourse[]>(
  {
    ...baseLoadActionBuilder<CtaInstructorCourse[]>(loadNonCTECourses),
    ...baseSaveActionBuilder(saveNonCTECourses),
  },
  initialState
);
