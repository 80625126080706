import { FundsRemaining } from './FundsRemaining.component';
import { connect } from 'react-redux';
import { getAllocatedFundsRemaining } from 'util/annualSpendingUtility';
import { add } from 'util/mathHelpers';

const mapStateToProps = (state: any, ownProps: any) => {
  const label = 'Allocated Funds Remaining';
  const { offset, spendingCategory } = ownProps;
  const total = getAllocatedFundsRemaining(spendingCategory);
  const funds = offset ? add(total, offset) : total;

  return {
    label,
    funds,
  };
};

export default connect(mapStateToProps)(FundsRemaining);
