import { connect } from 'react-redux';
import { getRecords_ExpenseCategory } from 'redux/common/_selectors';
import { RecordActualAmountsComponent } from './RecordActualAmounts.component';
import { loadExpenseCategories } from 'redux/common/expensecategory';

const mapStateToProps = (state: any) => {
  const expenseCategories = getRecords_ExpenseCategory(state);

  return {
    expenseCategories,
  };
};

const dispatchProps = {
  loadExpenseCategories,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(RecordActualAmountsComponent);
