import { map, defaultsDeep } from 'lodash';
import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  putWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { Course, emptyCourse } from 'types';

const COURSES_ENDPOINT = 'api/courses/';

export const createCourse = async (course: Course) => {
  const url = `${ENVIRONMENT.API_URL}${COURSES_ENDPOINT}`;
  course.createdDate = new Date(Date.now());
  course.modifiedDate = new Date(Date.now());
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(course),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('courses', err);
    });
};

export const readCourses = async () => {
  const url = `${ENVIRONMENT.API_URL}${COURSES_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((courses: Course[]) => {
      return map(courses, (course) => {
        let output: Course = defaultsDeep({}, course, emptyCourse());
        return output;
      });
    })
    .catch((err) => {
      return catchError('courses', err);
    });
};

export const readCourse = async (id: number) => {
  const url = `${ENVIRONMENT.API_URL}${COURSES_ENDPOINT}${id}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((course: Course) => {
      let output: Course = defaultsDeep({}, course, emptyCourse());
      return output;
    })
    .catch((err) => {
      return catchError('courses', err);
    });
};

export const updateCourse = async (course: Course) => {
  const url = `${ENVIRONMENT.API_URL}${COURSES_ENDPOINT}${course.courseId}`;
  course.modifiedDate = new Date(Date.now());
  return fetch(url, {
    ...putWithAuthOptions(),
    body: JSON.stringify(course),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('courses', err);
    });
};
