import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { WINDOW_BOTTOM_OFFSET } from 'constants/application.constants';
import { useParams, useHistory } from 'react-router';
import { WorkflowStatus } from 'types/grant/WorkflowStatus.types';
import { loadWorkFlow } from 'redux/generalworkflow/workflow.actions';
import { useDispatch } from 'react-redux';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose, getDate, prop, renderData, either } from 'util/objectUtility';
import { concat } from 'util/global';
import Paper from 'components/Common/Paper';
import Table from 'components/Common/Table';

type CurrentStatusProps = {
  loadGrantVoucherOverviewStatus: Function;
  setCurrentNavAnchor: Function;
  currentStatus: WorkflowStatus[];
};

export const CurrentStatus: React.FC<CurrentStatusProps> = (props) => {
  const {
    currentStatus,
    setCurrentNavAnchor,
    loadGrantVoucherOverviewStatus,
  } = props;

  const dispath = useDispatch();
  const history = useHistory();
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);
  const baseRoute = '/grants/applications/';
  const grantLinks: any = {
    'four-year-plan': '/needs-assessment/',
    'two-year-plan': '/',
    'spending-plans': '/',
    'assurance-signature': '/assurances/',
    'annual-reflection': '/',
    'award-packet': '/',
    'improvement-plan': '/',
    vouchers: '/',
  };
  const navigateOnClick = (step: WorkflowStatus) => {
    //  entityType :: WorkflowStatus -> string
    const entityType = either(prop('entityType'), () => '');
    const entityRoute = concat(baseRoute, entityType(step));
    const link = concat(entityRoute, grantLinks[step.entityType as any]);
    history.push(`${link}${grantFiscalYearId}`);
  };

  const ProgressStep = compose(
    renderData,
    prop('progressStep')
  );

  const Updated = compose(
    renderData,
    getDate,
    prop('updated')
  );

  const LastModifiedBy = compose(
    renderData,
    prop('lastModifiedBy')
  );

  const Status = compose(
    renderData,
    prop('status')
  );

  const STATUS_COLUMNS: TableColumnProps[] = [
    {
      name: 'Progress Step',
      cell: ProgressStep,
    },
    {
      name: 'Last Updated',
      cell: Updated,
    },
    {
      name: 'Last Modified By',
      cell: LastModifiedBy,
    },
    {
      name: 'Status',
      cell: Status,
    },
  ];

  useEffect(() => {
    dispath(loadWorkFlow('two-year-plan', grantFiscalYearId));
    dispath(loadWorkFlow('four-year-plan', grantFiscalYearId));
    dispath(loadWorkFlow('spending-plans', grantFiscalYearId));
    dispath(loadWorkFlow('assurance-signature', grantFiscalYearId));
    dispath(loadWorkFlow('annual-reflection', grantFiscalYearId));
    dispath(loadWorkFlow('award-packet', grantFiscalYearId));
    dispath(loadWorkFlow('improvement-plan', grantFiscalYearId));
  }, [loadWorkFlow, grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    loadGrantVoucherOverviewStatus(grantFiscalYearId);
  }, [loadGrantVoucherOverviewStatus, grantFiscalYearId]);

  return (
    <Paper>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('current-status')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <Table
        columns={STATUS_COLUMNS}
        data={currentStatus}
        onRowClick={navigateOnClick}
      />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('current-status')}
        topOffset="0px"
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
