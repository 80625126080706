import {
  SpendingCategory,
  emptySpendingCategory,
} from './SpendingCategory.types';

export interface AnnualSpending {
  annualSpendingPlanId: number;
  basicGrantFunds: number;
  grantFiscalYearId: number;
  reserveGrantFunds: number;
  spendingCategories: SpendingCategory[];
  indirectRate: number;
}

export const emptyAnnualSpending = (): AnnualSpending => {
  return {
    annualSpendingPlanId: -1,
    indirectRate: 0,
    grantFiscalYearId: -1,
    basicGrantFunds: 0,
    reserveGrantFunds: 0,
    spendingCategories: defaultAdminSpendingCategories(),
  };
};

export const defaultIndirectAdministrativeCosts = (
  id: number = -1
): SpendingCategory => {
  return {
    ...emptySpendingCategory(),
    annualSpendingPlanId: id,
    spendingCategoryId: -1,
    categoryId: 7,
    categoryName: 'Indirect Administrative Costs',
    strategy: 'Indirect Administrative Costs',
    strategyId: -7,
  };
};
export const defaultDirectAdministrativeCosts = (
  id: number = -1
): SpendingCategory => {
  return {
    ...emptySpendingCategory(),
    annualSpendingPlanId: id,
    categoryId: 8,
    spendingCategoryId: -2,
    categoryName: 'Direct Administrative Costs',
    strategy: 'Direct Administrative Costs',
    strategyId: -8,
  };
};
export const defaultNeedsAssessmentCosts = (
  id: number = -1
): SpendingCategory => {
  return {
    ...emptySpendingCategory(),
    annualSpendingPlanId: id,
    categoryId: 9,
    spendingCategoryId: -3,
    categoryName: 'Needs Assessment Costs',
    strategy: 'Needs Assessment Costs',
    strategyId: -9,
  };
};

export const defaultAdminSpendingCategories = (): SpendingCategory[] => {
  return [
    {
      ...emptySpendingCategory(),
      annualSpendingPlanId: -1,
      spendingCategoryId: -1,
      categoryId: 7,
      categoryName: 'Indirect Administrative Costs',
      strategy: 'Indirect Administrative Costs',
      strategyId: -7,
    },
    {
      ...emptySpendingCategory(),
      annualSpendingPlanId: -1,
      categoryId: 8,
      spendingCategoryId: -2,
      categoryName: 'Direct Administrative Costs',
      strategy: 'Direct Administrative Costs',
      strategyId: -8,
    },
    {
      ...emptySpendingCategory(),
      annualSpendingPlanId: -1,
      categoryId: 9,
      spendingCategoryId: -3,
      categoryName: 'Needs Assessment Costs',
      strategy: 'Needs Assessment Costs',
      strategyId: -9,
    },
  ];
};
