import Button from 'components/Common/FormElements/Button';
import CTEInstructorCourseSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTEInstructorCourseSelectBox';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect, useRef } from 'react';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTACourse, CTACourseTerm } from 'types';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import { CTA_CTE_COURSE_PATH } from 'redux/cta/_paths';
import { EditCTACourseForCteSchedule } from '../../Common/EditCTACourseForCteSchedule';
import { FlexRow } from 'layouts';
import { all } from 'ramda';
import { compose, mergeObjects, isTruthy } from 'util/objectUtility';
import { createTempId } from 'util/formHelpers';
import { getRecords } from 'redux/utility/_exports';
import { isApplicaionIdValid } from 'util/global';
import { toast } from 'react-toastify';
import { upsertInPlace, fmap } from 'util/arrayUtility';
import { useParams, Link } from 'react-router-dom';
import { useSelector, DefaultRootState } from 'react-redux';

export type CteScheduleProps = {
  forceSave: boolean;
  loadCTECourses: Function;
  saveCTECourses: Function;
  setDirtyData: Function;
};

export const CteScheduleComponent: React.FC<CteScheduleProps> = (props) => {
  const { forceSave, loadCTECourses, saveCTECourses, setDirtyData } = props;

  // -- dependencies --
  const { ctaInstructorId: ciId, ctaId: ctId } = useParams<any>();
  const ctaInstructorId = Number(ciId);
  const ctaId = Number(ctId);
  const { validateAll } = CTACourseValidation();

  // -- local state --
  const mountedRef = useRef(true);
  const [courseState, setCourseState] = useState<CTACourse[]>([]);
  const [dirtyCourses, setDirtyCourses] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const instructorCourses = useSelector((state: DefaultRootState) =>
    getRecords<CTACourse>(state, CTA_CTE_COURSE_PATH)
  );
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);

  // -- component logic --
  const validateAllCourses = compose(
    all(isTruthy),
    fmap(
      compose(validateAll, (course: any) => ({
        ...course,
        isCte: true,
        isInstructional: true,
      }))
    )
  );
  const handleTermsChange = (course: CTACourse) => (terms: CTACourseTerm[]) => {
    !dirtyCourses && setDirtyData(true);
    !dirtyCourses && setDirtyCourses(true);
    const updatedCourse = mergeObjects(course, { terms });
    const upserted = upsertInPlace(
      courseState,
      updatedCourse,
      'ctaInstructorProgramCourseId'
    );
    setCourseState(upserted);
  };
  const addCourse = (course: CTACourse) => {
    !dirtyCourses && setDirtyData(true);
    !dirtyCourses && setDirtyCourses(true);
    const newCourses = upsertInPlace(
      courseState,
      {
        ...course,
        ctaInstructorProgramCourseId: createTempId(
          courseState,
          'ctaInstructorProgramCourseId'
        ),
      },
      'ctaInstructorProgramCourseId'
    );
    setCourseState(newCourses);
  };
  const handleCourseDelete = (ctaInstructorProgramCourseId: number) => {
    !dirtyCourses && setDirtyData(true);
    !dirtyCourses && setDirtyCourses(true);
    const updatedCourses = courseState.filter((course: CTACourse) => {
      return (
        ctaInstructorProgramCourseId !== course.ctaInstructorProgramCourseId
      );
    });
    setCourseState(updatedCourses);
  };
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const saveCourses = () => {
    toggleHasRequested();
    const canSave = validateAllCourses(courseState);
    if (canSave) {
      saveCTECourses(ctaId, ctaInstructorId, courseState)
        .then(() => setDirtyData(false))
        .then(() => setDirtyCourses(false))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  // -- lifecycle --
  useEffect(() => {
    if (isApplicaionIdValid(ctaInstructorId)) {
      loadCTECourses(ctaInstructorId).catch((err: any) => console.error(err));
    }
  }, [ctaInstructorId, loadCTECourses]);

  useEffect(() => {
    setCourseState(instructorCourses);
  }, [instructorCourses]);

  useEffect(() => {
    if (forceSave && dirtyCourses) {
      saveCourses();
    }
  }, [forceSave]); // eslint-disable-line

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Paper>
      <FlexRow>
        <Instruction title="CTE Schedule">
          <p>
            Enter each state-approved CTE course taught by this instructor and{' '}
            <b>
              enter the student enrollment for each term (as of the last day of
              class for the term) in the ‘Count’ box.
            </b>{' '}
            To begin, click in the ‘Add New Course’ dropdown menu and select the
            appropriate course, school, and program combination to add it to the
            schedule. Click the ‘Save’ button when you have completed this
            section. If the number of terms for a school is incorrect, fix this
            in the ‘General Information’ section in the CTA Report for your
            district (the number of terms will apply to every course for the
            school). Click on ‘Delete this Course’ text if the course was added
            in error. Independent study students and work-based learning courses
            should be reported with accurate enrollments.
          </p>
          <p>
            <span className="u-color-red">Note</span>: If the dropdown menu does
            not include courses that should be associated with this instructor,
            you will need open{' '}
            <Link rel="noreferrer" target="_blank" to="/data-collections">
              Data Collections
            </Link>{' '}
            to associate the teacher with the CTE program in the Active Teacher
            file and enter the program's student enrollment information. Only
            courses in approved programs will populate.
          </p>
        </Instruction>
      </FlexRow>
      {courseState.length > 0 ? (
        courseState.map((course: CTACourse, index: number) => {
          return (
            <EditCTACourseForCteSchedule
              key={index}
              ctaCourse={course}
              deleteCourse={handleCourseDelete}
              isInstructional={true}
              onChange={handleTermsChange(course)}
              submissionFailed={submitFailed}
            />
          );
        })
      ) : (
        <FlexRow>
          <p>No courses have been added.</p>
        </FlexRow>
      )}
      <FlexRow>
        <CTEInstructorCourseSelectBox
          label="Add New Course"
          onChange={addCourse}
        />
      </FlexRow>
      <FlexRow>
        <div className="u-flex-grow-1" />
        <Button
          className={dirtyCourses ? 'button--filled' : 'button--outline'}
          disabled={hasRequested}
          onClick={saveCourses}
          style={{
            alignSelf: 'flex-end',
            height: '5.6rem',
            width: '28rem',
          }}
          text="Save CTE Schedule"
        />
      </FlexRow>
    </Paper>
  );
};
