import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlexRow } from 'layouts';
import { SpendingCategory } from 'types';
import { renderData, safeGet } from 'util/objectUtility';
import { buildOnChange } from 'util/formHelpers';
import { updateSpendingCategories } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { SpendingCategoryValidations } from 'validations/AnnualSpendingPlan/SpendingCategory.validations';
import { readImprovementPlanQuestion } from 'services/grants/improvementPlan.services';
import { useParams } from 'react-router-dom';
import { isImprovementPlan } from 'util/annualSpendingUtility';
import Textarea from 'components/Common/FormElements/Textarea';

type ProjectSummaryProps = {
  setCurrentNavAnchor: Function;
  spendingCategory: SpendingCategory;
};

export const ProjectSummary: React.FC<ProjectSummaryProps> = (props) => {
  const { setCurrentNavAnchor, spendingCategory } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);
  const {
    getError,
    getFieldValid,
    validate,
    validateIfTrue,
  } = SpendingCategoryValidations();

  // --[ local state ]---------------------------------------------------------
  const [questionThree, setQuestionThree] = useState<string | undefined>(
    undefined
  );

  // --[ component logic ]-----------------------------------------------------
  // get :: spendingCategory -> string -> spendingCategory[string]
  const get = safeGet(spendingCategory);
  const onCategoryChange = buildOnChange(updateSpendingCategories, dispatch);
  const onChange = onCategoryChange(spendingCategory);

  const handleDescriptionChange = (
    data: Partial<SpendingCategory>,
    _: keyof SpendingCategory,
    value: any
  ) => {
    validateIfTrue('description', value, spendingCategory);
    onChange(data);
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setCurrentNavAnchor('spending-plans');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    if (isImprovementPlan(spendingCategory)) {
      readImprovementPlanQuestion(grantFiscalYearId).then((res) => {
        setQuestionThree(res.questionValue.value);
      });
    }
  }, [spendingCategory, grantFiscalYearId]);

  // --[ display logic ]-------------------------------------------------------
  return (
    <React.Fragment>
      <FlexRow>
        <p className="input-display">{renderData(get('categoryName'))}</p>
      </FlexRow>
      <FlexRow>
        <div className="form__group u-width-75">
          <Textarea
            name=""
            label="Project Plan Strategy"
            value={renderData(get('strategy'))}
            className="input-display"
          />
        </div>
        <div className="form__group u-width-25">
          <p className="form__label Level">Tier Level</p>
          <p className="input-display">{renderData(get('tier'))}</p>
        </div>
      </FlexRow>
      {questionThree && (
        <FlexRow>
          <Textarea
            name={`Your answer to the question Specify the actions that will be
              taken, along with timelines to address gaps in performance. You
              can add further detail in the description below.`}
            disabled={true}
            value={questionThree}
          />
        </FlexRow>
      )}
      <FlexRow>
        <Textarea
          errorMessage={getError('description')}
          name="description"
          onChange={handleDescriptionChange}
          onBlur={() => validate('description', get('description'))}
          value={get('description')}
          valid={getFieldValid('description')}
        />
      </FlexRow>
    </React.Fragment>
  );
};
