import { connect } from 'react-redux';
import { FerpaAgreementComponent } from './FerpaAgreement.component';
import {
  loadFerpaAgreementData,
  saveFerpaAgreementData,
} from 'redux/common/ferpaagreement';
import {
  getFirstRecord_FerpaAgreement,
  getIsDataFetching_FerpaAgreement,
} from 'redux/common/ferpaagreement.selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const agreement = getFirstRecord_FerpaAgreement(state);
  const isFetching = getIsDataFetching_FerpaAgreement(state);
  return {
    agreement,
    isFetching,
  };
};

const dispatchProps = {
  saveFerpaAgreementData,
  loadFerpaAgreementData,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(FerpaAgreementComponent);
