import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { TwoYearEditComponent } from 'components/Grants/Applications/TwoYear/TwoYearEditComponent.component';
import { TwoYearSummaryComponent } from 'components/Grants/Applications/TwoYear/TwoYearSummaryComponent.component';
import { getRecords_TwoYear } from 'redux/grants/twoyear.selectors';
import { loadTwoYear } from 'redux/grants/twoyear';
import { getLockedStatus } from 'util/applicationStateUtility';

export const mapStateToProps = (state: any, ownProps: any) => {
  const twoYearQuestions = getRecords_TwoYear(state);

  const isLocked = getLockedStatus(state, 'two-year-plan');

  return {
    isLocked,
    twoYearQuestions,
  };
};

const dispatchToProps = {
  loadTwoYear,
  setCurrentNavAnchor,
};

export const GrantTwoYearEdit = connect(
  mapStateToProps,
  dispatchToProps
)(TwoYearEditComponent);

export const GrantTwoYearSummary = connect(
  mapStateToProps,
  dispatchToProps
)(TwoYearSummaryComponent);
