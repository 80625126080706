import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, GrantParticipantFilterState } from 'types';

const initialState: GrantParticipantFilterState = {
  isStandalone: undefined,
  notified: undefined,
  noResponse: undefined,
  consortium: undefined,
  responded: undefined,
  notParticipating: undefined,
};

export const setGrantParticipantFilterValue = createAction(
  'setGrantParticipantFilterValue'
);
export const resetGrantParticipantFilterValue = createAction(
  'resetGrantParticipantFilterValue'
);
export const clearGrantParticipantFilters = createAction(
  'clearGrantParticipantFilters'
);

export default handleActions<
  GrantParticipantFilterState,
  KeyValueType<number> | string
>(
  {
    setGrantParticipantFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetGrantParticipantFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearGrantParticipantFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
