import '@material/dialog/dist/mdc.dialog.css';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Input from 'components/Common/FormElements/Input';
import React, { useState, useEffect } from 'react';
import ToastNotification from 'components/Common/Toast';
import { DialogActions, Dialog } from '@rmwc/dialog';
import { DynamicPhoneForm } from 'components/UniversalContacts/ContactForms/DynamicPhone.component';
import { Email, UniversalContact, emptyUniversalContact } from 'types';
import { FlexRow } from 'layouts';
import { TeacherInformation } from 'components/UniversalContacts/ContactForms/TeacherInformation.component';
import { UniversalContactValidation } from 'validations/UniversalContact.validations';
import { createUser } from 'services/universalcontacts';
import { getContactByEmail } from 'services/universalcontacts/universalcontact.services';
import { getContactFullName } from 'util/universalcontact.utility';
import { isEmpty, every } from 'lodash';
import { toast } from 'react-toastify';
import { validateEmail } from 'util/formHelpers';

type MiniContactFormProps = {
  miniContactData?: Partial<UniversalContact>;
  loadSingleUniversalContact: Function;
  contactSaved: Function;
};

export const MiniContactForm: React.FC<MiniContactFormProps> = (props) => {
  const validations = UniversalContactValidation();
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateIfTrue,
    validateAll,
  } = validations;

  const { loadSingleUniversalContact, contactSaved, miniContactData } = props;
  const [open, setOpen] = useState(false);
  const newContact = (): UniversalContact => {
    return {
      ...emptyUniversalContact(),
      isAadB2c: false,
    };
  };

  const [contactState, setContactState] = useState<UniversalContact>(
    newContact()
  );
  const [emailExists, setEmailExists] = useState<UniversalContact>();
  const [duplicateEmail, setDuplicateEmail] = useState<string>('');
  const [credentialExists, setCredentialExists] = useState<UniversalContact>();
  const [duplicateCredential, setDuplicateCredential] = useState<string>('');

  const checkIfEmailExists = async (email: string) => {
    if (validateEmail(email)) {
      const user = await getContactByEmail(email);
      setEmailExists(user);
      if (!isEmpty(user)) {
        setDuplicateEmail(email);
      } else {
        setDuplicateEmail('');
      }
    }
  };

  const handleFormChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, contactState);
    setContactState({
      ...contactState,
      ...data,
    });
  };

  const handleEmailChange = (email: Email) => {
    setContactState({
      ...contactState,
      universalContactEmail: [email],
    });
  };

  const saveContact = async () => {
    return createUser(contactState)
      .then((userId: number) => {
        loadSingleUniversalContact(userId).then((user: UniversalContact) => {
          contactSaved(user);
          setContactState(newContact());
        });
      })
      .then(() => setOpen(false))
      .catch((err: any) => {
        err && toast(<ToastNotification status="error" message={err} />);
      });
  };

  const confirmContactValidations = () => {
    return validateAll(contactState) ? saveContact() : null;
  };

  const emailOnBlur = () => {
    validate(
      'universalContactEmail',
      contactState.universalContactEmail,
      contactState
    );
    checkIfEmailExists(contactState.universalContactEmail[0].email);
  };

  const emailIsValid = () => {
    return every([
      getFieldValid('universalContactEmail'),
      duplicateEmail.length === 0,
    ]);
  };

  const getEmailError = () => {
    return !isEmpty(emailExists)
      ? `${duplicateEmail} is already in use`
      : getError('universalContactEmail');
  };

  const disableButton = () => {
    if (!isValid) {
      return true;
    }
    if (!isEmpty(emailExists)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setContactState({ ...contactState, ...miniContactData });
  }, [miniContactData]); //eslint-disable-line

  return (
    <React.Fragment>
      <Dialog open={open} onClose={(evt) => setOpen(false)}>
        <div className="mini-contact-body">
          <FlexRow>
            <h3 className="u-section-title-text">Add New Contact</h3>
          </FlexRow>
          <FlexRow>
            <TeacherInformation
              onChange={handleFormChange}
              contact={contactState}
              credentialExists={credentialExists}
              duplicateCredential={duplicateCredential}
              setDuplicateCredential={setDuplicateCredential}
              setCredentialExists={setCredentialExists}
            />
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={getError('firstName')}
              name="firstName"
              onChange={handleFormChange}
              onBlur={() =>
                validate('firstName', contactState.firstName, contactState)
              }
              valid={getFieldValid('firstName')}
              value={contactState.firstName}
            />
            <Input
              errorMessage={getError('lastName')}
              name="lastName"
              value={contactState.lastName}
              onChange={handleFormChange}
              onBlur={() =>
                validate('lastName', contactState.lastName, contactState)
              }
              valid={getFieldValid('lastName')}
            />
          </FlexRow>
          <FlexRow>
            <Input
              name="email"
              value={contactState.universalContactEmail[0].email}
              onChange={handleEmailChange}
              onBlur={emailOnBlur}
              valid={emailIsValid()}
              errorMessage={getEmailError()}
            />
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={getError('title')}
              name="title"
              value={contactState.title}
              onChange={handleFormChange}
              onBlur={() => validate('title', contactState.title, contactState)}
              valid={getFieldValid('title')}
            />
            <DynamicPhoneForm
              phones={contactState.universalContactPhone}
              onChange={handleFormChange}
              dynamic={false}
              validations={validations}
            />
          </FlexRow>
        </div>
        <DialogActions>
          <FlexRow>
            <Button
              onClick={async () => {
                setOpen(false);
                setContactState(newContact());
              }}
              className="form__btn--cancel"
              text="Cancel"
            />
            <ConfirmationDialog
              tooltip=""
              header={'Save Contact'}
              onConfirm={confirmContactValidations}
              disabled={disableButton()}
              activateButton={
                <Button
                  disabled={disableButton()}
                  text="Save"
                  className="form__btn--submit"
                />
              }
            >
              <h3>
                {`Please confirm you have verified that '${getContactFullName(
                  contactState
                )}' does not already exist in the system and that you wish to add them.`}
              </h3>
            </ConfirmationDialog>
          </FlexRow>
        </DialogActions>
      </Dialog>
      <button onClick={() => setOpen(true)} className="button--outline">
        New Contact
      </button>
    </React.Fragment>
  );
};
