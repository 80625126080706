import { ManageEmailRecipients } from './ManageRecipients.component';
import { connect } from 'react-redux';
import {
  getEmailTemplate,
  getIsDataFetching_EmailTemplate,
  getAdvancedFilteredRecords_EmailRecipients,
} from 'redux/grants/_selectors';
import {
  loadSingleEmailTemplate,
  upsertEmailTemplate,
  saveEmailRecipients,
} from 'redux/grants/emailtemplates';

const mapStateToProps = (state: any) => {
  const emailTemplate = getEmailTemplate(state);
  const recipients = getAdvancedFilteredRecords_EmailRecipients(state);
  const isLoading = getIsDataFetching_EmailTemplate(state);

  return {
    emailTemplate,
    recipients,
    isLoading,
  };
};

const dispatchProps = {
  loadSingleEmailTemplate,
  saveEmailRecipients,
  upsertEmailTemplate,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ManageEmailRecipients);
