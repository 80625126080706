export const QUALITY = '[QUALITY]';

export const SET_QUALITY = `${QUALITY} SET`;
export const SET_QUALITY_LOADING = `${QUALITY} LOADING`;

export const setQuality = (payload: any) => {
  return {
    type: SET_QUALITY,
    payload,
  };
};

export const setIsLoading_Quality = (payload: boolean) => {
  return {
    type: SET_QUALITY_LOADING,
    payload,
  };
};
