export const placementvalues = [
  {
    key: 1,
    label:
      'Employed full or part time in an occupation related to the CTE program completed last year (1)',
    value: 1,
  },
  {
    key: 2,
    label:
      'Employed full or part time in an occupation not related to the CTE program completed last year (2)',
    value: 2,
  },
  {
    key: 3,
    label: 'Unemployed and actively seeking employment (3)',
    value: 3,
  },
  {
    key: 4,
    label: 'Unemployed and not actively seeking employment (4)',
    value: 4,
  },
  {
    key: 5,
    label: 'Unknown (5)',
    value: 5,
  },
  {
    key: 7,
    label: 'Student is deceased  (7)',
    value: 7,
  },
];

export const contEducationvalues = [
  {
    key: 0,
    label: 'Not continuing education (0)',
    value: 0,
  },
  {
    key: 1,
    label: 'Continuing at high school level (secondary) (1)',
    value: 1,
  },
  {
    key: 3,
    label: 'Continuing at community or technical college or similar (3)',
    value: 3,
  },
  {
    key: 4,
    label:
      'Continuing at four-year college or university (i.e. bachelor’s degree) (4)',
    value: 4,
  },
  {
    key: 5,
    label: 'Unknown (5)',
    value: 5,
  },
];

export const militaryServicevalues = [
  {
    key: 0,
    label: 'Not in the military (0)',
    value: 0,
  },
  {
    key: 1,
    label: 'In the Military Full Time (≥35 hours/week) (1)',
    value: 1,
  },
  {
    key: 2,
    label: 'In the Military Part Time (< 35 hours/week) (2)',
    value: 2,
  },
  {
    key: 3,
    label: 'Unknown (3)',
    value: 3,
  },
];
export const apprenticeshipvalues = [
  {
    key: 0,
    label: 'Not in an apprenticeship (0)',
    value: 0,
  },
  {
    key: 1,
    label:
      'Participating in an apprenticeship related to the CTE program completed last year (1)',
    value: 1,
  },
  {
    key: 2,
    label:
      'Participating in an apprenticeship not related to the CTE program completed last year (2)',
    value: 2,
  },
  {
    key: 3,
    label: 'Unknown (3)',
    value: 3,
  },
];
export const serviceProgramvalues = [
  {
    key: 0,
    label: 'Not in a service program (0)',
    value: 0,
  },
  {
    key: 1,
    label: 'In a service program (1)',
    value: 1,
  },
  {
    key: 2,
    label: 'Unknown (2)',
    value: 2,
  },
];
export const certExamCompletedvalues = [
  {
    key: 0,
    label: 'Unknown (0)',
    value: '0',
  },
  {
    key: 1,
    label: 'Yes (Y)',
    value: 'Y',
  },
  {
    key: 2,
    label: 'No (N)',
    value: 'N',
  },
];
export const certExamPassedvalues = [
  {
    key: 0,
    label: 'Unknown (0)',
    value: '0',
  },
  {
    key: 1,
    label: 'Yes (Y)',
    value: 'Y',
  },
  {
    key: 2,
    label: 'No (N)',
    value: 'N',
  },
];
