import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import {
  SET_INSTRUCTORS,
  SET_INSTRUCTORS_LOADING,
} from './instructors.actions';

const initState = baseInitialState;

export const instructorsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_INSTRUCTORS:
      return actionUpdateRecords(state, action.payload);

    case SET_INSTRUCTORS_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
