import { COMMON_KEY, COMMON_PROGRAM_LENGTHS_KEY } from 'redux/keys';
import { ProgramLength } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';

export const PROGRAM_LENGTHS_PATH = [COMMON_KEY, COMMON_PROGRAM_LENGTHS_KEY];

export const getIsDataFetching_ProgramLengths = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_LENGTHS_PATH);
};

export const getRecords_ProgramLengths = (state: any): ProgramLength[] => {
  return getRecords<ProgramLength>(state, PROGRAM_LENGTHS_PATH);
};

export const shouldRefreshReduxState_ProgramLengths = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramLength>(state, PROGRAM_LENGTHS_PATH);
};

export const getLastUpdated_ProgramLengths = (state: any): number => {
  return getLastUpdated(state, PROGRAM_LENGTHS_PATH);
};
