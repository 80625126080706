import {
  getWithAuthOptions,
  toJSON,
  catchError,
  getFormattedUrl,
} from 'services';
import { FiscalYear } from 'types';

const FISCAL_YEAR_URL = getFormattedUrl([
  'api',
  'FiscalYearManagement',
  'fiscalyears',
]);

export const readFiscalYears = async (): Promise<FiscalYear[]> => {
  return fetch(FISCAL_YEAR_URL, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('fiscalyears', err, 'readFiscalYears');
    });
};
