import React from 'react';
import Icon from '../Icons';

type WarningAlertProps = {
  iconPosition?: 'left' | 'right';
};

export const WarningAlert: React.FC<WarningAlertProps> = (props) => {
  const { children, iconPosition = 'right' } = props;
  return iconPosition === 'right' ? (
    <div className="warning-alert">
      <p className="u-error">{children}</p>
      <Icon title="Warning" name="warningAlert" className="u-icon-base-size" />
    </div>
  ) : (
    <div className="warning-alert">
      <Icon title="Warning" name="warningAlert" className="u-icon-base-size" />
      <p className="u-error">{children}</p>
    </div>
  );
};
