import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import VoucherDetailComponent from 'components/Grants/Applications/Vouchers/VoucherDetail';
import { useParams } from 'react-router';

export const GrantVoucherDetailPage: React.FC = (props) => {
  const { voucherId: vid, voucherType }: any = useParams();
  const voucherId = Number(vid);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <VoucherDetailComponent
          voucherId={voucherId}
          voucherType={voucherType}
          {...props}
        />
      </GrantsLayout>
    </BaseLayout>
  );
};
