import { connect } from 'react-redux';
import {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
} from 'redux/programs/programinstance';
import { getIsDataFetching_ProgramInstance } from 'redux/programs/_selectors';
import { GeneralComponent } from './General.page';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const isProgramDataFetching = getIsDataFetching_ProgramInstance(state);

  return {
    programInstanceId,
    isProgramDataFetching,
  };
};

const dispatchToProps = {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(GeneralComponent);
