import { UniversalContact, emptyUniversalContact } from 'types/universalcontact';

export interface CTAAdministrator {
  actualSalaryEarned: number;
  administratorId: number;
  annualContractBaseSalary: number;
  affiliation: string;
  contactId: number;
  contractExtraPay: number;
  ctaId: number;
  ctePercent: number;
  contact: UniversalContact;
  employeeType: string;
  isDirector: boolean;
  memo: string;
  salary: number;
  ctaSchoolId?: number;
}

export const emptyCTAAdministrator = (): CTAAdministrator => {
  return {
    administratorId: -1,
    annualContractBaseSalary: 0,
    actualSalaryEarned: 0,
    affiliation: '',
    contactId: -1,
    contractExtraPay: 0,
    contact: emptyUniversalContact(),
    ctaId: -1,
    ctePercent: 0,
    employeeType: '',
    isDirector: false,
    memo: '',
    salary: 0,
  };
};
