import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import {
  getRecords_ProgramAssuranceGroups,
  getIsDataFetching_ProgramAssuranceGroups,
} from 'redux/programs/assurancequestions.selectors';
import {
  loadProgramAssuranceQuestions,
  saveProgramAssuranceQuestions,
} from 'redux/programs/assurancequestions';
import { setCurrentNavAnchor } from 'redux/application';
import { QuestionGroup } from 'types';
import AssurancesEditComponent from './AssurancesEdit.component';
import AssurancesSummaryComponent from './AssurancesSummary.component';

export const mapStateToProps = (state: ApplicationState) => {
  const questions: QuestionGroup[] = getRecords_ProgramAssuranceGroups(state);
  const isFetching = getIsDataFetching_ProgramAssuranceGroups(state);

  return {
    questions,
    isFetching,
  };
};

const dispatchToProps = {
  loadProgramAssuranceQuestions,
  saveProgramAssuranceQuestions,
  setCurrentNavAnchor,
};

export const AssurancesEdit = connect(
  mapStateToProps,
  dispatchToProps
)(AssurancesEditComponent);

export const AssurancesSummary = connect(
  mapStateToProps,
  dispatchToProps
)(AssurancesSummaryComponent);
