import React, { useState, useEffect } from 'react';
import { deburr, escapeRegExp, isNil, map, filter, find } from 'lodash';
import { CipCode, ItemFilter } from 'types';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Icon from 'components/Common/Icons';
import Tooltip from 'components/Common/Tooltip';

type CipCodeSelectBoxComponentProps = {
  cipId?: string;
  cipCodes: CipCode[];
  disabled?: boolean;
  filter?: ItemFilter;
  label?: string;
  onChange: Function;
  readOnly?: boolean;
};

export const CipCodeSelectBoxComponent: React.FC<
  CipCodeSelectBoxComponentProps
> = (props) => {
  const {
    cipId,
    cipCodes,
    disabled,
    label,
    onChange,
    readOnly = false,
  } = props;
  const [cipResults, setCipResults] = useState<CipCode[]>([]);
  const [result, setResult] = useState('');

  // if a cipId is passed in on props, set it as the result
  useEffect(() => {
    if (cipId) {
      const selectedCip = find(cipCodes, (code) => {
        return code.cipId === cipId;
      });
      setResult(selectedCip ? selectedCip.cipId : '');
    }
  }, [setResult, cipCodes, cipId]);

  const getSuggestedValues = (value: string) => {
    const cleanValue = escapeRegExp(deburr(value).toLowerCase());
    if (cleanValue) {
      const regex = new RegExp(`^${cleanValue}`, 'i');

      return filter(cipCodes, (code) => {
        const cipCodeName = code.cipCodeColoradoName
          ? code.cipCodeColoradoName
          : code.cipCodeName;
        return (
          regex.test(code.cipId.toLowerCase().trim()) ||
          regex.test(cipCodeName.toLowerCase())
        );
      });
    }
    return [];
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    if (request.value === result) {
      setCipResults(getSuggestedValues(request.value));
    }
  };

  const renderSuggestion = (suggestion: CipCode, { query }: any) => {
    const cipCodeName = suggestion.cipCodeColoradoName
      ? suggestion.cipCodeColoradoName
      : suggestion.cipCodeName;

    const cipmatches = AutosuggestHighlightMatch(suggestion.cipId, query);
    const cipparts = AutosuggestHighlightParse(suggestion.cipId, cipmatches);

    const matches = AutosuggestHighlightMatch(cipCodeName, query);
    const parts = AutosuggestHighlightParse(cipCodeName, matches);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div>
            {map(cipparts, (part, index) => {
              const className = part.highlight
                ? 'react-autosuggest__suggestion--match'
                : undefined;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
            {`:  `}
          </div>
          <div className="u-flex-grow-1">
            {map(parts, (part, index) => {
              const className = part.highlight
                ? 'react-autosuggest__suggestion--match'
                : undefined;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </div>
          <React.Fragment>
            {suggestion.cipCodeDescription && (
              <Tooltip
                tooltip={suggestion.cipCodeDescription}
                indicator={<Icon name="questionMark" className="u-menu-icon" />}
              />
            )}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (event: any, { newValue }: any) => {
    if (readOnly) {
      return;
    }
    setResult(newValue.trim());
    setCipResults(getSuggestedValues(newValue));
  };

  return (
    <div className="form__group">
      <label htmlFor="cipId" className="form__label">
        {label ? label : 'Add a CIP Code'}
      </label>
      <Autosuggest
        getSuggestionValue={(suggestion: CipCode) => {
          if (isNil(suggestion)) return '';
          onChange && onChange(suggestion);
          return suggestion.cipId;
        }}
        suggestions={cipResults}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {
          setCipResults([]);
        }}
        renderSuggestion={renderSuggestion}
        inputProps={{
          value: result,
          onChange: handleChange,
          id: 'cipId',
          disabled: disabled,
          className: `form__input`,
        }}
      />
    </div>
  );
};
