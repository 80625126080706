import React, { useState } from 'react';
import { VoucherExpense } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { useParams } from 'react-router';
import { VoucherExpenseValidations } from 'validations/Vouchers/VoucherExpense.validations';
import { safeGet } from 'util/objectUtility';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Textarea from 'components/Common/FormElements/Textarea';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type EquipmentDetailsProps = {
  onChange: Function;
  row: VoucherExpense;
  disabled: boolean;
};

export const EquipmentDetailsComponent: React.FC<EquipmentDetailsProps> = (
  props
) => {
  const { onChange, row, disabled } = props;

  // --[ dependencies ]--------------------------------------------------------
  const [isOpen, setOpen] = useState<boolean>(false);
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);
  const {
    getError,
    getFieldValid,
    validate,
    validateIfTrue,
  } = VoucherExpenseValidations();

  // --[ component logic ]-----------------------------------------------------
  // get :: VoucherExpense -> string -> VoucherExpense[string]
  const get = safeGet(row);
  const toggleModal = () => setOpen(!isOpen);
  const uploadParams = (filePath: string) => ({
    container: 'grants',
    folder: `${grantFiscalYearId}|voucher|${get('expenseId')}|${filePath}`,
  });

  const validateOnChange = (
    data: Partial<VoucherExpense>,
    key: any,
    value: any
  ) => {
    validateIfTrue(key, value, row);
    onChange(data);
  };

  const handleFileUpload = (equipmentReceiptFilePath: string = '') => {
    validate('equipmentReceiptFilePath', equipmentReceiptFilePath, row);
    onChange({ equipmentReceiptFilePath });
  };

  // --[ display logic ]-------------------------------------------------------
  return (
    <React.Fragment>
      <FlexRow>
        <Textarea
          errorMessage={getError('specificLocation')}
          name="specificLocation"
          onBlur={() =>
            validate('specificLocation', get('specificLocation'), row)
          }
          onChange={validateOnChange}
          value={get('specificLocation')}
          valid={getFieldValid('specificLocation')}
          maxLength={2000}
          disabled={disabled}
        />
        <Textarea
          errorMessage={getError('serialNumber')}
          name="serialNumber"
          onBlur={() => validate('serialNumber', get('serialNumber'), row)}
          onChange={validateOnChange}
          value={get('serialNumber')}
          valid={getFieldValid('serialNumber')}
          maxLength={2000}
          disabled={disabled}
        />
        <Textarea
          errorMessage={getError('invoiceNumber')}
          name="invoiceNumber"
          onBlur={() => validate('invoiceNumber', get('invoiceNumber'), row)}
          onChange={validateOnChange}
          value={get('invoiceNumber')}
          valid={getFieldValid('invoiceNumber')}
          maxLength={2000}
          disabled={disabled}
        />
        <DatePicker
          errorMessage={getError('invoiceDate')}
          name="invoiceDate"
          onBlur={() => validate('invoiceDate', get('invoiceDate'), row)}
          onChange={validateOnChange}
          value={get('invoiceDate')}
          valid={getFieldValid('invoiceDate')}
          disabled={disabled}
        />
      </FlexRow>
      <FlexRow>
        <FlexGroup
          onBlur={() => {
            validate(
              'equipmentReceiptFilePath',
              get('equipmentReceiptFilePath'),
              row
            );
          }}
          style={{ maxWidth: '40rem' }}
        >
          <p className="form__label">Equipment Supporting Documents</p>
          {get('equipmentReceiptFilePath') ? (
            <AzureDownloader
              editable={!disabled}
              customTruncate={40}
              onClose={() => !disabled && handleFileUpload()}
              uploadParams={uploadParams('equipmentReceiptFilePath')}
              filepath={get('equipmentReceiptFilePath')}
            />
          ) : (
            <Button
              className="form__btn--add grant-schedule__upload-btn"
              onClick={toggleModal}
              style={{ textAlign: 'left' }}
              text="+ Upload Document"
              disabled={disabled}
            />
          )}
          <SecureWrap
            isLocked={disabled}
            component={['GrantsVouchers', 'GrantsReimbursementOverride']}
          >
            <AzureUploader
              onClose={toggleModal}
              open={isOpen}
              uploadParams={uploadParams('equipmentReceiptFilePath')}
              onConfirm={handleFileUpload}
            />
          </SecureWrap>
          {!getFieldValid('equipmentReceiptFilePath') && (
            <p className="u-color-red">
              {getError('equipmentReceiptFilePath')}
            </p>
          )}
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  );
};
