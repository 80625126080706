import { isEmpty } from 'lodash';
import { createActionThunk } from 'redux-thunk-actions';
import { createAction, handleActions } from 'redux-actions';
import { ProgramAward } from 'types/program';
import {
  readProgramAwards,
  createProgramAwards,
} from 'services/programs/awards.services';
import { baseInitialState } from 'redux/utility/baseInitialState';
import { upsertInPlace } from 'util/arrayUtility';
import { actionFetching } from 'redux/utility/_exports';

const initialState = baseInitialState;

export const loadProgramAwards = createActionThunk(
  'loadProgramAwards',
  (programInstanceId: number) => {
    return readProgramAwards(programInstanceId);
  }
);

export const saveProgramAwardCache = createActionThunk(
  'saveProgramAwardCache',
  (payload: ProgramAward[], programInstanceId: number) => {
    return createProgramAwards(payload, programInstanceId);
  }
);

export const setProgramAwardCache = createAction('setProgramAwardCache');
export const addProgramAwardToCache = createAction('addProgramAwardToCache');
export const updateProgramAwardCache = createAction('updateProgramAwardCache');
export const cleanProgramAwardCache = createAction('cleanProgramAwardCache');
export const resetProgramAwardCache = createAction('resetProgramAwardCache');

export default handleActions(
  {
    [loadProgramAwards.START]: (state) => {
      return actionFetching(state, true);
    },

    [loadProgramAwards.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    },

    setProgramAwardCache: (state: any, { payload }: any) => {
      const { programInstanceId, records: rec } = payload;
      const records = isEmpty(rec) ? [] : rec;
      return {
        ...state,
        [programInstanceId]: {
          ...state[programInstanceId],
          records,
        },
      };
    },

    updateProgramAwardCache: (state: any, { payload }: any) => {
      const { programInstanceId, record } = payload;
      const cache = state[programInstanceId];
      return {
        ...state,
        [programInstanceId]: {
          records: upsertInPlace(cache.records, record, 'programAwardId'),
          isDirty: true,
        },
      };
    },

    cleanProgramAwardCache: (state: any, { payload }: any) => {
      const { programInstanceId } = payload;
      const cache = state[programInstanceId];
      return {
        ...state,
        [programInstanceId]: {
          records: [...cache.records],
          isDirty: false,
        },
      };
    },

    resetProgramAwardCache: (state: any, { payload }: any) => {
      delete state[payload];
      return { ...state };
    },
  },
  initialState
);
