import { connect } from 'react-redux';
import { CTADashboardComponent } from './CTADashboard.component';
import {
  getIsDataFetching_CTADistrictRecord,
  getAdvancedFilteredRecords_CTADistrictRecord,
} from 'redux/cta/ctadashboard.selectors';
import { loadCTADistrictRecords } from 'redux/cta/ctadashboard';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { getCurrentCTAFiscalYear } from 'redux/common/_selectors';

const mapStateToProps = (state: any) => {
  const isFetching = getIsDataFetching_CTADistrictRecord(state);
  const ctaRecords = getAdvancedFilteredRecords_CTADistrictRecord(state);
  const filters = getCTADashboardFilters(state);
  const currentCTAYear = getCurrentCTAFiscalYear(state);

  return {
    filters,
    ctaRecords,
    isFetching,
    currentCTAYear,
  };
};

const dispatchProps = {
  loadCTADistrictRecords,
};

export default connect(mapStateToProps, dispatchProps)(CTADashboardComponent);
