import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_PROGRAMS_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_PROGRAMS = `${CHANGELOG_PROGRAMS_KEY} RESET UPDATE`;
export const SET_CHANGELOG_PROGRAMS = `${CHANGELOG_PROGRAMS_KEY} SET`;
export const SET_CHANGELOG_PROGRAMS_LOADING = `${CHANGELOG_PROGRAMS_KEY} LOADING`;

const initState = baseInitialState;

export const changelogProgramsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_PROGRAMS:
      return setShouldUpdate(state);

    case SET_CHANGELOG_PROGRAMS:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_PROGRAMS_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogPrograms = (payload: any) => {
  return {
    type: SET_CHANGELOG_PROGRAMS,
    payload,
  };
};

export const setIsLoading_ChangelogPrograms = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_PROGRAMS_LOADING,
    payload,
  };
};
