import { FILTERS_KEY, FILTERS_KEY_CONSORTIA } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ConsortiumFilterState, ReduxFilter } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const CONSORTIA_FILTER_PATH = [FILTERS_KEY, FILTERS_KEY_CONSORTIA];

export const getRecords_ConsortiumFilterState = (
  state: any
): ConsortiumFilterState => {
  return get(state, CONSORTIA_FILTER_PATH);
};

export const getConsortiumFilters = (
  state: any
): ReduxFilter<ConsortiumFilterState>[] => {
  const filterValues: KeyValueType<unknown> = (getRecords_ConsortiumFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (item): ReduxFilter<ConsortiumFilterState> => {
      return {
        filter: {
          property: item,
          values: filterValues[item] !== undefined ? filterValues[item] : '',
        },
        operator: 'and',
      };
    }
  );
  return filters;
};
