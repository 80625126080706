import React, { ReactElement } from 'react';
import IconButton from 'components/Common/IconButton';
import { ComponentStep } from 'types/ui/ComponentStep.types';
import { useParams } from 'react-router';

type OverviewStepsProps = {
  stepInfo: ComponentStep;
  component?: ReactElement;
};

export const OverviewSteps: React.FC<OverviewStepsProps> = (props) => {
  const { stepInfo, component } = props;

  const { grantFiscalYearId: gId }: any = useParams();
  const grantFiscalYearId = Number(gId);

  const generateIcon = () => {
    switch (stepInfo.nav.anchor) {
      case 'current-status':
      case 'general':
        return null;
    }

    switch (stepInfo.nav.status) {
      case 'locked':
        return (
          <IconButton
            className="lock"
            name="lock"
            tooltip="Edit"
            asLink={true}
            to={`${stepInfo.nav.url}${grantFiscalYearId}`}
          />
        );

      default:
        return (
          <IconButton
            className="pencil"
            name="pencil"
            tooltip="Edit"
            asLink={true}
            to={`${stepInfo.nav.url}${grantFiscalYearId}`}
          />
        );
    }
  };

  return (
    <div id={stepInfo.nav.anchor} className="program-step">
      <div className="program-step__header">
        <h2 className="program-step__title">{stepInfo.stepName}</h2>
        <div>{generateIcon()}</div>
      </div>
      <div className="program-step__component">{component}</div>
    </div>
  );
};
