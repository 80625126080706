import { GRANTS_KEY, GRANTS_IMPROVEMENT_PLAN_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getFirstRecord,
} from 'redux/utility/baseSelectors';
import { ImprovementPlan, emptyImprovementPlan } from 'types';

export const IMPROVEMENT_PLAN_PATH = [GRANTS_KEY, GRANTS_IMPROVEMENT_PLAN_KEY];
const plan = emptyImprovementPlan();

export const getIsDataFetching_ImprovementPlan = (state: any): boolean => {
  return getIsDataFetching(state, IMPROVEMENT_PLAN_PATH);
};

export const getRecords_ImprovementPlan = (
  state: unknown
): ImprovementPlan[] => {
  return getRecords(state, IMPROVEMENT_PLAN_PATH);
};

export const getFirstRecord_ImprovementPlan = (
  state: unknown
): ImprovementPlan => {
  return getFirstRecord(state, IMPROVEMENT_PLAN_PATH, plan);
};

export const shouldRefreshReduxState_ImprovementPlan = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ImprovementPlan>(state, IMPROVEMENT_PLAN_PATH);
};
