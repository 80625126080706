import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  putWithAuthOptions,
  postWithAuthOptions,
  catchError,
  getFormattedUrl,
} from 'services';
import { Program, emptyProgram } from 'types';
import { defaultsDeep, uniq } from 'lodash';

const PROGRAMS_ENDPOINT = 'api/ProgramInstances/';
const SCHOOL_ENDPOINT = `${PROGRAMS_ENDPOINT}bySchoolId/`;
const DEPARTMENT_ENDPOINT = `${PROGRAMS_ENDPOINT}BySchoolDepartmentId/`;
const DISTRICT_ENDPOINT = `${PROGRAMS_ENDPOINT}ByDistrictId/`;

/**
 * Makes a "POST" request to 'api/ProgramInstances' to create a new Program.
 * @param program a Program object
 * @returns the new ProgramInstanceId
 */
export const createProgram = async (program: Program) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAMS_ENDPOINT}`;
  const ctsO_IDs = uniq(program.ctsO_IDs); // Want to make sure we never send duplicates
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({ ...program, ctsO_IDs }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramInstances'
 * @returns an array of all programs.
 */
export const readPrograms = async () => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAMS_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramInstances/{programInstanceId}'
 * @param programInstanceId the programInstanceId
 * @returns the specific program instance
 */
export const readProgram = async (programInstanceId: number) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAMS_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((program: Program) => {
      let output: Program = defaultsDeep({}, program, emptyProgram);
      return output;
    })
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramInstances/BySchoolDepartmentId/{schoolDepartmentId}'
 * @param schoolId the schoolId
 * @returns an array of all programs associated with the schoolId.
 */
export const readProgramsBySchoolDepartmentId = async (
  schoolDepartmentId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${DEPARTMENT_ENDPOINT}${schoolDepartmentId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramInstances/ByDistrictId/{districtId}'
 * @param districtId the district id
 * @returns an array of all programs associated with the schoolId.
 */
export const readProgramsByDistrictId = async (districtId: number) => {
  const url = `${ENVIRONMENT.API_URL}${DISTRICT_ENDPOINT}${districtId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramInstances/BySchoolID/{schoolId}'
 * @param schoolId the schoolId
 * @returns an array of all programs associated with the schoolId.
 */
export const readProgramsBySchoolId = async (schoolId: number) => {
  const url = `${ENVIRONMENT.API_URL}${SCHOOL_ENDPOINT}${schoolId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

/**
 * Makes a "PUT" request to 'api/ProgramInstances' to update an existing
 * Program record
 * @param program a Program object
 * @returns no content
 */
export const updateProgram = async (program: Program) => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAMS_ENDPOINT}${
    program.programInstanceId
  }`;
  const ctsO_IDs = uniq(program.ctsO_IDs); //What a hack, we seem to have duplicate data coming back from the DB, causing saves to fail
  return fetch(url, {
    ...putWithAuthOptions(),
    body: JSON.stringify({ ...program, ctsO_IDs }),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};

export const readPotentialProgramOwners = async (programInstanceId: number) => {
  const url = getFormattedUrl([
    'api',
    'ProgramInstances',
    'potentialProgramOwners',
    programInstanceId,
  ]);
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramInstances', err);
    });
};
