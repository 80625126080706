import { ProgramFollowup } from './ProgramFollowup';
import { connect } from 'react-redux';
import {
  getIsDataFetching_StudentEnrollment,
  getFirstRecord_StudentEnrollment,
} from 'redux/datacollection/_selectors';
import { loadStudentFollowUpData } from 'redux/datacollection/studentenrollment';
import { firstOrDefault } from 'util/arrayUtility';
import { filter, some } from 'lodash';
import { emptyStudentEnrollment } from 'types';
import {
  getRecords_ProgramSnapshotStudent,
  getIsDataFetching_ProgramSnapshotStudent,
} from 'redux/datacollection/programsnapshotstudent.selectors';
import { loadProgramFollowupSnapshotStudent } from 'redux/datacollection/programsnapshotstudent';

const mapStateToProps = (state: any, ownProps: any) => {
  const programId = Number(ownProps.match.params.programId);
  const enrollmentdata = getFirstRecord_StudentEnrollment(state);
  const enrollmentDataFetching = getIsDataFetching_StudentEnrollment(state);
  const schoolYear = enrollmentdata.schoolYear;

  const followup = firstOrDefault(
    filter(enrollmentdata.enrollments, (item) => {
      return item.programBaseId === programId;
    }),
    emptyStudentEnrollment()
  );
  const students = getRecords_ProgramSnapshotStudent(state);
  const studentsFetching = getIsDataFetching_ProgramSnapshotStudent(state);

  const isFetching = some([enrollmentDataFetching, studentsFetching]);

  return {
    isFetching,
    followup,
    schoolYear,
    students,
  };
};

const dispatchProps = {
  loadProgramFollowupSnapshotStudent,
  loadStudentFollowUpData,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProgramFollowup);
