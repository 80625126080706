import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAHostItem } from 'types';
import { readCTAHostingAvailableContracts } from 'services/cta/ctahosting.services';

const initialState = baseInitialState;

export const loadCTAHostingAvailableContracts = createActionThunk(
  'loadCTAHostingAvailableContracts',
  (ctaId: number) => {
    return readCTAHostingAvailableContracts(ctaId);
  }
);

export default handleActions<BaseStateObject, CTAHostItem[]>(
  {
    ...baseLoadActionBuilder<CTAHostItem[]>(loadCTAHostingAvailableContracts),
  },
  initialState
);
