import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const PROGRAM_CONTACTS_TYPES_ENDPOINT = 'api/ProgramContactTypes/';

export const readProgramContactTypes = async () => {
  const url = `${ENVIRONMENT.API_URL}${PROGRAM_CONTACTS_TYPES_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramContactTypes', err);
    });
}; // end
