import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_VOUCHER_THREE_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_THREE = `${CHANGELOG_VOUCHER_THREE_KEY} RESET UPDATE`;
export const SET_CHANGELOG_VOUCHER_THREE = `${CHANGELOG_VOUCHER_THREE_KEY} SET`;
export const SET_CHANGELOG_VOUCHER_THREE_LOADING = `${CHANGELOG_VOUCHER_THREE_KEY} LOADING`;

const initState = baseInitialState;

export const changelogVoucherThreeReducer = (
  state = initState,
  action: any
) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_VOUCHER_THREE:
      return setShouldUpdate(state);

    case SET_CHANGELOG_VOUCHER_THREE:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_VOUCHER_THREE_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogVoucherThree = (payload: any) => {
  return {
    type: SET_CHANGELOG_VOUCHER_THREE,
    payload,
  };
};

export const setIsLoading_ChangelogVoucherThree = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_VOUCHER_THREE_LOADING,
    payload,
  };
};
