import CtaSchoolSelectBox from 'components/Common/SelectBoxes/CtaSchoolSelectBox';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Textarea from 'components/Common/FormElements/Textarea';
import Tooltip from 'components/Common/Tooltip';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';
import { CTAAdministratorValidations } from 'validations/CTA/Administrator.validations';
import { CTASchool, emptyCTASchool } from 'types';
import { FlexRow } from 'layouts';
import { getContactFullName } from 'util/universalcontact.utility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import {
  renderData,
  getDate,
  safeGet,
  compose,
  prop,
} from 'util/objectUtility';
import { useSelector } from 'react-redux';

type AdminCostComponentProps = {
  admin: CTAAdministrator;
  districtId: number;
  close: Function;
  onSave: Function;
  ctaSchools: CTASchool[];
  adminSchools: CTASchool[];
};

export const AdminCostComponent: React.FC<AdminCostComponentProps> = (
  props
) => {
  const { admin, close, onSave, ctaSchools, adminSchools } = props;
  // -- dependencies --
  const {
    getError,
    getFieldValid,
    isValid,
    resetValidationState,
    validate,
    validateAll,
    validateIfTrue,
    validationErrors,
  } = CTAAdministratorValidations();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local state --
  const [adminState, setAdminState] = useState<CTAAdministrator>(admin);
  const minval = 0;
  const maxval = 1000000;
  const maxbonus = 100000;

  const adminrecords = [
    {
      ...emptyCTASchool(),
      schoolName: 'District Administrator',
    },
    ...adminSchools,
  ];

  // -- component logic --
  const updateAdmin = (
    item: Partial<CTAAdministrator>,
    key: any,
    data: any
  ) => {
    validateIfTrue(key, data);
    setAdminState({ ...adminState, ...item });
  };

  const updateAdminAssociation = (item: Partial<CTASchool>) => {
    setAdminState({
      ...adminState,
      ctaSchoolId: item.ctaSchoolId,
    });
  };

  const getSelectedOption = () => {
    if (get('isDirector')) {
      return get('ctaSchoolId');
    }
    return get('ctaSchoolId');
  };

  const saveAdmin = () => {
    if (validateAll(adminState)) {
      onSave(adminState);
      close();
    }
  };

  // -- lifecycle --
  useEffect(() => {
    resetValidationState();
    setAdminState(admin);
  }, [admin]); //eslint-disable-line

  // -- display logic --
  const get = safeGet(adminState);

  return (
    <React.Fragment>
      <SecureWrap component={['ctageneral']} isLocked={readOnly}>
        <Paper className="t-ac-personnel">
          <FlexRow>
            <h2 style={{ fontSize: '4rem' }}>
              CTE{' '}
              {get('isDirector') ? (
                <span>Director</span>
              ) : (
                <span>Specialist</span>
              )}{' '}
              costs for {getContactFullName(get('contact'))}
            </h2>
          </FlexRow>
          <FlexRow>
            {get('isDirector') ? (
              <Instruction title="Personnel Details">
                <p>
                  Enter the salary and administrative information requested for
                  the CTE Director you would like to add. In the dropdown,
                  indicate if the CTE Director was a district-wide administrator
                  or for a specific DCTS. Then provide information on the
                  percentage of time they spent on CTE specific activities and
                  use the ‘CTE Memo’ field to enter any comments. Click on the
                  ‘Save’ button to return to the ‘Administrative Costs’ section.
                </p>
              </Instruction>
            ) : (
              <Instruction title="Personnel Details">
                <p>
                  Enter the salary and administrative information requested for
                  the CTE Specialist you would like to add. Use the drop down
                  menu to provide their school affiliation. Provide information
                  on the percentage of time they spent providing CTE counseling
                  or job development activities and use the ‘CTE Memo’ field to
                  enter any comments. Click on the ‘Save’ button to return to
                  the ‘Administrative Costs’ section.
                </p>
              </Instruction>
            )}
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={getError('annualContractBaseSalary')}
              max={maxval}
              min={minval}
              name="annualContractBaseSalary"
              onBlur={() => {
                validate(
                  'annualContractBaseSalary',
                  get('annualContractBaseSalary')
                );
              }}
              onChange={updateAdmin}
              step={1}
              tooltip={
                <Tooltip
                  indicator={
                    <Icon name="info" className="spending-category__tooltip" />
                  }
                  tooltip="Enter the instructor's annual base salary (regular salary) for the reporting period (7/1 to 6/30). Do not include benefits, stipends, or extra duty pay. If district pay is based on an hourly rate, convert that rate to an annual basis according to your district's internal policies."
                />
              }
              type="number"
              valid={getFieldValid('annualContractBaseSalary')}
              value={get('annualContractBaseSalary')}
            />
            <Input
              errorMessage={getError('actualSalaryEarned')}
              label="Actual Salary Earned"
              name="actualSalaryEarned"
              max={maxval}
              min={minval}
              onBlur={() => {
                validate('actualSalaryEarned', get('annualContractBaseSalary'));
              }}
              onChange={updateAdmin}
              step={1}
              tooltip={
                <Tooltip
                  indicator={
                    <Icon name="info" className="spending-category__tooltip" />
                  }
                  tooltip="Enter the instructor’s actual salary earned during the reporting period (7/1-6/30). Note that this should include the total amount paid during the period regardless of when it is disbursed. Do not include benefits, stipends, or extra duty pay."
                />
              }
              type="number"
              value={get('actualSalaryEarned')}
              valid={getFieldValid('actualSalaryEarned')}
            />
            <Input
              max={maxbonus}
              min={0}
              step={1}
              type="number"
              value={get('contractExtraPay')}
              valid={getFieldValid('contractExtraPay')}
              errorMessage={getError('contractExtraPay')}
              onBlur={() => {
                validate('contractExtraPay', get('contractExtraPay'));
              }}
              name="contractExtraPay"
              onChange={updateAdmin}
              tooltip={
                <Tooltip
                  indicator={
                    <Icon name="info" className="spending-category__tooltip" />
                  }
                  tooltip="This box is to report costs in instances where a CTE Administrator is not also a teacher already reported in instructional costs AND is given a separate stipend/CTE specific supplement to be paid for work that (1) is outside their regularly assigned duties during the academic year and/or in the summer.  This work must be supported by an Extra Duty Pay contract which notes the scope of the work, the amount the individual should be paid for the additional CTE-related duties, and the timing of when the tasks should be completed in order to be allowable."
                />
              }
            />
          </FlexRow>
          <FlexRow>
            <Input
              disabled={true}
              name="credentialId"
              value={compose(
                renderData,
                prop('credentialId'),
                prop('contact')
              )(adminState)}
            />
            <Input
              disabled={true}
              name="credentialExpirationDate"
              value={compose(
                renderData,
                getDate,
                prop('credentialExpirationDate'),
                prop('contact')
              )(adminState)}
            />
            <Input
              disabled={true}
              name="credentialArea"
              value={compose(
                renderData,
                prop('credentialArea'),
                prop('contact')
              )(adminState)}
            />
          </FlexRow>
          <FlexRow>
            <CtaSchoolSelectBox
              selection={getSelectedOption()}
              label={
                get('isDirector')
                  ? 'Administrative Affiliation'
                  : 'School Affiliation'
              }
              onChange={updateAdminAssociation}
              records={get('isDirector') ? adminrecords : ctaSchools}
              shouldReset
            />
            <Input
              errorMessage={getError('ctePercent')}
              label="CTE Percent"
              max={100}
              min={0}
              name="ctePercent"
              onBlur={() => validate('ctePercent', get('ctePercent'))}
              onChange={updateAdmin}
              step={1}
              type="number"
              value={get('ctePercent')}
              valid={getFieldValid('ctePercent')}
            />
          </FlexRow>
          <FlexRow>
            <Textarea
              maxLength={500}
              label="Explain CTE Percentage (character limit 500)"
              name="memo"
              value={get('memo')}
              onChange={updateAdmin}
            />
          </FlexRow>
          <SaveButtons
            alternateAction={saveAdmin}
            disabled={!isValid}
            onCancel={close}
            validationErrors={validationErrors}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
