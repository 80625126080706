import { combineReducers } from 'redux';
import {
  CTA_ADMIN_KEY,
  CTA_DASHBOARD_KEY,
  CTA_GENERAL_KEY,
  CTA_SUPPORT_PERSONNEL_KEY,
  CTA_SPECIALIST_KEY,
  CTA_DIRECTOR_KEY,
  CTA_BOOKS_SCHOOL_KEY,
  CTA_SUPPORT_PROGRAM_AFFILIATION_KEY,
  CTA_ADMIN_BOOK_SUPPLY_KEY,
  CTA_DISTRICT_SALARY_KEY,
  CTA_EQUIPMENT_KEY,
  CTA_INSTRUCTOR_KEY,
  CTA_INSTRUCTOR_NON_CTE_COURSE_KEY,
  CTA_INSTRUCTOR_CTE_COURSE_KEY,
  CTA_COURSE_CONTRACTING_KEY,
  CTA_CONTRACTING_KEY,
  CTA_HOSTING_KEY,
  CTA_INSTRUCTOR_SALARY_KEY,
  CTA_COURSE_HOSTING_KEY,
  CTA_CONTRACTING_AVAILABLE_HOSTS_KEY,
  CTA_CONTRACTING_HOSTS_KEY,
  CTA_HOSTING_CONTRACTS_KEY,
  CTA_HOSTING_AVAILABLE_CONTRACTS_KEY,
  CTA_COURSE_INSTRUCTIONAL_KEY,
  CTA_SIGNATURE_KEY,
  CTA_REVIEW_OPTIMIZE_KEY,
} from 'redux/keys';

import ctaadmin from './ctaadmin';
import ctasupportpersonnel from './ctasupportpersonnel';
import ctageneral from './ctageneral';
import ctadashboard from './ctadashboard';
import ctadirector from './ctadirector';
import ctaspecialist from './ctaspecialist';
import ctabooksschool from './ctabooksschool';
import ctasupportprogramaffiliation from './ctasupportprogramaffiliation';
import ctaadminbooksupply from './ctaadminbooksupply';
import ctadistrictsalary from './ctadistrictsalary';
import ctaequipment from 'redux/cta/ctaequipment';
import cta_instructors from 'redux/cta/InstructionalCosts/ctainstructors';
import ctacontractingcourse from './contracting/ctacontractingcourse';
import ctacontracting from './contracting/ctacontracting';
import ctahosting from './hosting/ctahosting';
import ctainstructorsalary from './InstructionalCosts/ctainstructorsalary';
import ctahostingcourse from './hosting/ctahostingcourse';
import cta_nonctecourses from './InstructionalCosts/ctanonctecourses';
import cta_ctecourses from './InstructionalCosts/ctactecourses';
import ctacontractingavailablehosts from './contracting/ctacontractingavailablehosts';
import ctacontractinghost from './contracting/ctacontractinghost';
import ctahostingcontract from './hosting/ctahostingcontract';
import ctahostingavailablecontracts from './hosting/ctahostingavailablecontracts';
import ctainstructionalcourse from './InstructionalCosts/ctainstructionalcourse';
import ctasignature from './ctasignature';
import ctareviewoptimize from './ctareviewoptimize';

export default combineReducers({
  [CTA_ADMIN_KEY]: ctaadmin,
  [CTA_ADMIN_BOOK_SUPPLY_KEY]: ctaadminbooksupply,
  [CTA_BOOKS_SCHOOL_KEY]: ctabooksschool,

  [CTA_CONTRACTING_KEY]: ctacontracting,
  [CTA_CONTRACTING_AVAILABLE_HOSTS_KEY]: ctacontractingavailablehosts,
  [CTA_CONTRACTING_HOSTS_KEY]: ctacontractinghost,

  [CTA_COURSE_CONTRACTING_KEY]: ctacontractingcourse,
  [CTA_COURSE_HOSTING_KEY]: ctahostingcourse,
  [CTA_COURSE_INSTRUCTIONAL_KEY]: ctainstructionalcourse,

  [CTA_DASHBOARD_KEY]: ctadashboard,
  [CTA_DIRECTOR_KEY]: ctadirector,
  [CTA_DISTRICT_SALARY_KEY]: ctadistrictsalary,
  [CTA_EQUIPMENT_KEY]: ctaequipment,
  [CTA_GENERAL_KEY]: ctageneral,

  [CTA_HOSTING_KEY]: ctahosting,
  [CTA_HOSTING_AVAILABLE_CONTRACTS_KEY]: ctahostingavailablecontracts,
  [CTA_HOSTING_CONTRACTS_KEY]: ctahostingcontract,

  [CTA_INSTRUCTOR_KEY]: cta_instructors,
  [CTA_INSTRUCTOR_CTE_COURSE_KEY]: cta_ctecourses,
  [CTA_INSTRUCTOR_NON_CTE_COURSE_KEY]: cta_nonctecourses,
  [CTA_INSTRUCTOR_SALARY_KEY]: ctainstructorsalary,

  [CTA_REVIEW_OPTIMIZE_KEY]: ctareviewoptimize,
  [CTA_SIGNATURE_KEY]: ctasignature,

  [CTA_SPECIALIST_KEY]: ctaspecialist,

  [CTA_SUPPORT_PERSONNEL_KEY]: ctasupportpersonnel,
  [CTA_SUPPORT_PROGRAM_AFFILIATION_KEY]: ctasupportprogramaffiliation,
});
