import { ctaGeneralMiddleware } from './ctageneral.middleware';
import { ctaBooksSchoolMiddleware } from './ctabooksschool.middleware';
import { ctaAdminCostsMiddleware } from './ctaadmincosts.middleware';
import { ctaSupportingPersonnelMiddleware } from './ctasupportingpersonnel.middleware';
import { ctaContractingMiddleware } from './ctacontracting.middleware';
import { ctaHostingMiddleware } from './ctahosting.middleware';
import { ctaEquipmentMiddleware } from './ctaequipment.middleware';
import { ctaInstructionalCostsMiddleware } from './ctainstructionalcosts.middleware';
import { ctaDashboardMiddleware } from './ctadashboard.middleware';

const ctaMiddleware = [
  ctaAdminCostsMiddleware,
  ctaBooksSchoolMiddleware,
  ctaContractingMiddleware,
  ctaGeneralMiddleware,
  ctaHostingMiddleware,
  ctaSupportingPersonnelMiddleware,
  ctaInstructionalCostsMiddleware,
  ctaEquipmentMiddleware,
  ctaDashboardMiddleware,
];

export default ctaMiddleware;
