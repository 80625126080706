import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import {
  DistrictSchoolSignature,
  CertificationOfExemptionSignature,
} from 'types';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';
import { CivilRightsAssertionsListValidations } from 'validations/AwardPacket/ListValidations/CivilRightsAssertionsList.validations';
import { CertificationOfExemptionValidationsList } from 'validations/AwardPacket/ListValidations/CertificationOfExemptionList.validations';
import { GrantRecipientSignaturesValidations } from 'validations/AwardPacket/ListValidations/GrantRecipientSignatures.validations';

type Props = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadAwardPacket: Function;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
  civilRightsSignatures: DistrictSchoolSignature[];
  certificationOfExemptionSignatures: CertificationOfExemptionSignature[];
  grantAgreementSignatures: DistrictSchoolSignature[];
  loadGrantAgreementSignatures: Function;
  loadCivilRightSignatures: Function;
  loadCertificateOfExemptionSignatures: Function;
};

export const AwardPacketWorkflowComponent: React.FC<Props> = (props) => {
  const {
    civilRightsSignatures,
    certificationOfExemptionSignatures,
    grantAgreementSignatures,
    loadGrantAgreementSignatures,
    loadCivilRightSignatures,
    loadCertificateOfExemptionSignatures,
  } = props;

  const {
    validate: validateCivilRights,
    validationErrors: CivilRightsValidationErrors,
  } = CivilRightsAssertionsListValidations();

  const {
    validate: validateCertificationOfExemption,
    validationErrors: CertificationOfExemptionValidationErrors,
  } = CertificationOfExemptionValidationsList();
  const {
    validate: validateGrantRecipient,
    validationErrors: GrantRecipientValidationErrors,
  } = GrantRecipientSignaturesValidations();

  const validationErrors = [
    ...CivilRightsValidationErrors,
    ...CertificationOfExemptionValidationErrors,
    ...GrantRecipientValidationErrors,
  ];

  const wrap = {
    ...props,
    validationErrors,
  };

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadGrantAgreementSignatures(grantFiscalYearId);
      loadCivilRightSignatures(grantFiscalYearId);
      loadCertificateOfExemptionSignatures(grantFiscalYearId);
    }
  }, [
    loadGrantAgreementSignatures,
    loadCivilRightSignatures,
    loadCertificateOfExemptionSignatures,
    grantFiscalYearId,
  ]); // eslint-disable-line

  /* eslint-disable */
  useEffect(() => {
    validateGrantRecipient('canSubmit', grantAgreementSignatures);
    validateCivilRights('canSubmit', civilRightsSignatures);
    validateCertificationOfExemption(
      'canSubmit',
      certificationOfExemptionSignatures
    );
  }, [
    civilRightsSignatures,
    certificationOfExemptionSignatures,
    grantAgreementSignatures,
    grantFiscalYearId,
  ]);
  /* eslint-enable */

  return <WorkflowSummaryComponent {...wrap} />;
};
