import { connect } from 'react-redux';
import { Consortium, OptionMap, SelectWrapper } from 'types';
import {
  getRecords_Consortia,
  CONSORTIUM_PATH,
  getIsDataFetching_Consortia,
} from 'redux/grants/_selectors';
import { getRecordsUtility } from 'util/formHelpers';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    onChange,
    records: baseRecords,
    name,
  } = ownProps;

  const isLoading = getIsDataFetching_Consortia(state);
  const records = getRecordsUtility<Consortium>(
    state,
    CONSORTIUM_PATH,
    getRecords_Consortia,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<Consortium> = {
    key: 'consortiumId',
    value: 'consortiumId',
    label: 'consortiumName',
  };

  return {
    isLoading,
    label: label ? label : 'Consortium',
    name: name ? name : 'consortiumId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
