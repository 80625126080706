import React from 'react';
import Paper from 'components/Common/Paper';
import { FlexRow } from 'layouts';
import { BorderedTitle } from 'components/Common/BorderedTitle';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';

export const PostsecondaryInformation: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy } = props;
  const { postSecondaryProgram: program } = programOfStudy;

  return (
    <Paper>
      <FlexRow>
        <h2 className="program__title">Postsecondary Information</h2>
      </FlexRow>
      <BorderedTitle
        primaryText={program.programName}
        secondaryText={
          programOfStudy.postsecondarySchool &&
          programOfStudy.postsecondarySchool.schoolName
        }
      />

      <FlexRow>
        <div style={{ width: '15.0rem' }}>
          <label className="form__label">Program Id</label>
          <p>{program.programInstanceId}</p>
        </div>
        <div style={{ width: '15.0rem' }}>
          <label className="form__label">CIP Code</label>
          <p>{program.cipId}</p>
        </div>
      </FlexRow>

      <FlexRow>
        <div className="form__group u-flex-grow-1">
          <h3 className="form__label">Program Description</h3>
          <p>{program.programDescription}</p>
        </div>
      </FlexRow>

      <FlexRow>
        <div className="form__group u-flex-grow-1">
          <h3 className="form__label">Requirements for Entry</h3>
          <p>{program.programRequirement}</p>
        </div>
      </FlexRow>
    </Paper>
  );
};
