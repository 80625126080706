import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/_exports';
import { DataCollectionSubmission } from 'types';
import {
  readDataCollectionSubmission,
  updateDataCollectionSubmission,
} from 'services/datacollection/datacollectionsubmission.services';

const initialState = baseInitialState;

export const loadDataCollectionSubmission = createActionThunk(
  'loadDataCollectionSubmission',
  () => {
    return readDataCollectionSubmission();
  }
);

export const saveDataCollectionSubmission = createActionThunk(
  'saveDataCollectionSubmission',
  (submissions: DataCollectionSubmission[]) => {
    return updateDataCollectionSubmission(submissions);
  }
);

export default handleActions<BaseStateObject, DataCollectionSubmission[]>(
  {
    ...baseSaveActionBuilder<DataCollectionSubmission[]>(
      saveDataCollectionSubmission
    ),
    ...baseLoadActionBuilder<DataCollectionSubmission[]>(
      loadDataCollectionSubmission
    ),
  },
  initialState
);
