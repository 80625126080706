import { ENVIRONMENT } from 'constants/application.config';
import {
  postWithAuthOptions,
  getWithAuthOptions,
  catchError,
  toJSON,
} from 'services';
import { ImprovementPlan, Question } from 'types';

export const IMPROVEMENTS_ENDPOINT = 'api/ImprovementPlan/';

export const readImprovementPlan = async (
  grantFiscalYearId: number
): Promise<ImprovementPlan> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${IMPROVEMENTS_ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ImprovementPlan', err, 'readImprovementPlan');
    });
};

export const updateImprovementPlan = async (
  grantFiscalYearId: number,
  plan: ImprovementPlan
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${IMPROVEMENTS_ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(plan),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ImprovementPlan', err, 'updateImprovementPlan');
    });
};

export const readImprovementPlanQuestion = async (
  grantFiscalYearId: number
): Promise<Question> => {
  const url = `${
    ENVIRONMENT.API_URL
  }${IMPROVEMENTS_ENDPOINT}question/${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ImprovementPlan', err, 'readImprovementPlanQuestion');
    });
};
