import {
  CHANGELOG_KEY,
  CHANGELOG_PROGRAMS_KEY,
  CHANGELOG_FOUR_YEAR_KEY,
  CHANGELOG_ANNUAL_REFLECTION_KEY,
  CHANGELOG_IMPROVEMENT_PLAN_KEY,
  CHANGELOG_ANNUAL_SPENDING_KEY,
  CHANGELOG_VOUCHER_ONE_KEY,
  CHANGELOG_VOUCHER_TWO_KEY,
  CHANGELOG_VOUCHER_THREE_KEY,
  CHANGELOG_FINAL_VOUCHER_KEY,
} from 'redux/keys';
import { getRecords, getIsDataFetching } from 'redux/utility/_exports';
import { HistoryData, emptyHistoryData, WorkflowReduxEntities } from 'types';
import { curry } from 'lodash';

export const CHANGELOG_PROGRAMS_PATH = [CHANGELOG_KEY, CHANGELOG_PROGRAMS_KEY];
export const CHANGELOG_FOUR_YEAR_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_FOUR_YEAR_KEY,
];
export const CHANGELOG_ANNUAL_REFLECTION_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_ANNUAL_REFLECTION_KEY,
];
export const CHANGELOG_IMPROVEMENT_PLAN_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_IMPROVEMENT_PLAN_KEY,
];
export const CHANGELOG_ANNUAL_SPENDING_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_ANNUAL_SPENDING_KEY,
];
export const CHANGELOG_VOUCHER_ONE_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_VOUCHER_ONE_KEY,
];
export const CHANGELOG_VOUCHER_TWO_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_VOUCHER_TWO_KEY,
];
export const CHANGELOG_VOUCHER_THREE_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_VOUCHER_THREE_KEY,
];
export const CHANGELOG_FINAL_VOUCHER_PATH = [
  CHANGELOG_KEY,
  CHANGELOG_FINAL_VOUCHER_KEY,
];

const options: Partial<WorkflowReduxEntities> = {
  programs: CHANGELOG_PROGRAMS_PATH,
  'four-year-plan': CHANGELOG_FOUR_YEAR_PATH,
  'annual-reflection': CHANGELOG_ANNUAL_REFLECTION_PATH,
  'improvement-plan': CHANGELOG_IMPROVEMENT_PLAN_PATH,
  'spending-plans': CHANGELOG_ANNUAL_SPENDING_PATH,
  'voucher-one': CHANGELOG_VOUCHER_ONE_PATH,
  'voucher-two': CHANGELOG_VOUCHER_TWO_PATH,
  'voucher-three': CHANGELOG_VOUCHER_THREE_PATH,
  'final-voucher': CHANGELOG_FINAL_VOUCHER_PATH,
};

export const getRecords_Changelog = curry(
  (entityType: keyof WorkflowReduxEntities, state: any) => {
    return options[entityType]
      ? getRecords<HistoryData>(state, options[entityType])
      : [emptyHistoryData()];
  }
);

export const getIsDataFetching_Changelog = curry(
  (entityType: keyof WorkflowReduxEntities, state: any) => {
    return getIsDataFetching(state, options[entityType]);
  }
);
