import React from 'react';
import { Course, CourseCreditRequired, CourseCreditAward } from 'types';
import { FlexGroup } from 'layouts';
import { map, find } from 'lodash';
import { randomString } from 'util/global';
import Button from 'components/Common/FormElements/Button';
import CourseTypeSelectBox from 'components/Common/SelectBoxes/CourseTypeSelectBox';
import CourseSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CourseSelectBox';
import CreditInput from 'components/Common/FormElements/CreditInput';
import Input from 'components/Common/FormElements/Input';
import Label from 'components/Common/FormElements/Label';

type RequiredCourseCreditsInputRowProps = {
  index: number;
  requiredCourse: CourseCreditRequired;
  courses: Course[];
  onContactHoursChange: Function;
  onCreditChange: Function;
  onCourseSelect: Function;
  onCourseTypeSelect: Function;
  deleteRequiredCourse: Function;
};

export const RequiredCourseCreditsInputRow: React.FC<RequiredCourseCreditsInputRowProps> = (
  props
) => {
  const {
    courses,
    deleteRequiredCourse,
    index,
    onContactHoursChange,
    onCreditChange,
    onCourseSelect,
    onCourseTypeSelect,
    requiredCourse,
  } = props;

  const {
    awards,
    contactHours,
    courseId,
    courseTypeId,
    defaultCredits,
  } = requiredCourse;

  const getCourseName = () => {
    const courseInfo = find(courses, (course: Course) => {
      return course.courseId === courseId;
    });
    if (courseInfo) {
      return (
        <label className="course-credits__course-name">
          {courseInfo.courseName}
        </label>
      );
    } else {
      return (
        <label className="course-credits__course-name u-color-red">
          Please choose a course.
        </label>
      );
    }
  };

  const updateCredits = (data: CourseCreditAward) => {
    onCreditChange(data, index);
  };

  const courseTypeInputId = randomString();

  return (
    <div className="course-credits__input-row">
      {getCourseName()}
      <div className="course-credits__info">
        <div className="course-credits__input-container">
          <div className="course-credits__input-container__course-container">
            <FlexGroup>
              <Label htmlFor={courseId.toString()} name="Course" />
              <CourseSelectBox
                id={courseId.toString()}
                courseId={courseId}
                filter={{
                  property: 'programLevelId',
                  values: 2,
                }}
                onChange={(data: Course) => onCourseSelect(data, index)}
              />
            </FlexGroup>
          </div>
          <div className="course-credits__input-container__type-container">
            <CourseTypeSelectBox
              selection={courseTypeId}
              onChange={(data: any, key: any, id: number) => {
                onCourseTypeSelect(id, index);
              }}
            />
          </div>
          <div className="course-credits__input-container__contact-credits-container">
            <div className="course-credits__input-container__contact-container">
              <Input
                label="Contact"
                name="contactHours"
                value={contactHours}
                onChange={(data: any, key: any, value: any) => {
                  const newNumber = `${value}0`;
                  if (isNaN(Number(newNumber))) return;
                  onContactHoursChange({ contactHours: value }, index);
                }}
                className="course-credits__input"
              />
            </div>
            <div className="course-credits__input-container__credits-container">
              <FlexGroup>
                <Label htmlFor={courseTypeInputId} name="Credits" />
                <CreditInput
                  value={defaultCredits}
                  disabled={true}
                  className="course-credits__input course-credits__input--disabled"
                />
              </FlexGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="course-credits__credits">
        <FlexGroup>
          <div className="course-credits__input-row">
            <FlexGroup>
              <p className="form__label course-credits__input-container-label">
                Apply Credits
              </p>
              <div className="u-flex">
                {map(awards, (award: CourseCreditAward, index: number) => {
                  return (
                    <CreditInput
                      key={index}
                      name="credits"
                      programAwardId={award.programAwardId}
                      value={award.credits}
                      onChange={updateCredits}
                      className="course-credits__input"
                    />
                  );
                })}
              </div>
            </FlexGroup>
          </div>
          <Button
            style={{ width: '10.5rem', height: '2.5rem' }}
            text="Delete course"
            className="course-credits__delete no-print"
            onClick={() => deleteRequiredCourse(courseId)}
          />
        </FlexGroup>
      </div>
    </div>
  );
};
