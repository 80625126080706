import CTAAvailableDistrictsSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTAAvailableDistrictsSelectBox';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import Tooltip from 'components/Common/Tooltip';
import ToastNotification from 'components/Common/Toast';
import { COLOR_RED } from 'constants/css.const';
import { CTADistrictRecord, CTAHosting, CTACourse } from 'types';
import { FlexRow } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { all, defaultTo, length, lt as gt, converge } from 'ramda';
import { fmap } from 'util/arrayUtility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { getRecords_CTAHostingAvailableContracts } from 'redux/cta/_selectors';
import { loadCTAHostingAvailableContracts } from 'redux/cta/hosting/ctahostingavailablecontracts';
import { oneCTATermHasData } from 'validations/CTA/CTACourse.validations';
import {
  prop,
  compose,
  renderData,
  concatLengthWithItems,
  either,
  isTruthy,
  not,
} from 'util/objectUtility';
import { round } from 'util/mathHelpers';
import { some, identity } from 'lodash';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';

type HostingProps = {
  ctaHosting: CTAHosting[];
  ctaRecord: CTADistrictRecord;
  loadCTAHosting: Function;
  locked: boolean;
  removeCTAHostingContract: Function;
};

export const HostingComponent: React.FC<HostingProps> = (props) => {
  const {
    ctaHosting,
    loadCTAHosting,
    locked,
    removeCTAHostingContract,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const history = useHistory();
  const readOnly = useSelector(getIsCTAReadOnly);

  // --[ local state ]---------------------------------------------------------
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  // invalidCourses :: CTAHosting -> bool
  const invalidCourses = compose(
    not,
    all(isTruthy),
    fmap(
      compose(
        // @ts-ignore
        converge(oneCTATermHasData, [prop('terms'), identity]),
        (data: CTACourse) => ({
          ...data,
          isHosting: true,
        })
      )
    ),
    defaultTo([]),
    prop('courses')
  );

  // anyCoursesInvalid :: CTAHosting[] -> bool
  const anyCoursesInvalid = compose(some, fmap(invalidCourses));

  const handleDelete = async (row: CTAHosting) => {
    return removeCTAHostingContract(row)
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.Deleted}
          />
        );
      })
      .catch((err: any) => console.error(err));
  };

  // handleAddDistrict :: number -> void
  const handleAddDistrict = (id: number) => {
    return history.push(`/cta/district-reporting/hosting/${ctaId}/${id}`);
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTAHosting(ctaId).catch((err: any) => console.error(err));
    }
  }, [wasOpened, loadCTAHosting, ctaId]);

  // --[ display logic ]-------------------------------------------------------
  const renderEditIcon = (row: CTAHosting) => {
    return (
      <IconButton
        tooltip="Edit"
        name="pencil"
        asLink={true}
        to={`/cta/district-reporting/hosting/${ctaId}/${prop(
          'contractingId',
          row
        )}`}
      />
    );
  };
  const renderDeleteIcon = (row: CTAHosting) => {
    return (
      <ConfirmationDialog
        tooltip="Delete Contracting Entry"
        header="Confirm Delete?"
        onConfirm={async () => {
          await handleDelete(row);
        }}
        activateButton={<IconButton tooltip="Delete" name="trashCan" />}
      >
        <p>
          Are you sure you want to delete this Contract? Once deleted, data on
          this contact will need to be re-added using the “Add New Contracting
          District" field.
        </p>
      </ConfirmationDialog>
    );
  };

  const CONTRACTING_COLUMNS: TableColumnProps[] = [
    {
      cell: compose(renderData, prop('contractingDistrictInstitution')),
      name: 'Contracting District',
    },
    {
      cell: (row: CTAHosting) => {
        return prop('hostingSchools', row)
          ? concatLengthWithItems('hostingSchools', 'schoolName')(row)
          : null;
      },
      name: 'Hosting Schools',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: (row: CTAHosting) => {
        return prop('programs', row)
          ? concatLengthWithItems('programs', 'programName')(row)
          : null;
      },
      name: 'Programs',
      style: {
        flexGrow: 1,
      },
    },
    {
      cell: either(compose(round(2), prop('fte')), () => renderData('')),
      label: () => {
        return (
          <div className="u-flex">
            <span style={{ marginRight: '1rem' }}>FTE</span>
            <Tooltip
              place="right"
              position="below"
              tooltip={
                'The FTE numbers shown below are rounded to 2 decimal places for display purposes and are not the complete FTE values used for calculations. Use the reports module to see full FTE by CIP.'
              }
              indicator={<Icon name="questionMark" className="u-menu-icon" />}
            />
          </div>
        );
      },
      name: 'FTE',
      width: '7rem',
    },
    {
      cell: either(prop('students'), () => renderData('')),
      name: 'Students',
      width: '7rem',
    },
    {
      cell: either(prop('revenue'), () => renderData('')),
      name: 'Revenue',
    },
    {
      cell: renderDeleteIcon,
      name: 'Delete',
      center: true,
      width: '7rem',
    },
    {
      cell: renderEditIcon,
      name: 'Edit',
      center: true,
      width: '7rem',
    },
  ];

  // hasCourses :: CTAHosting -> bool
  const hasCourses = compose(gt(0), length, prop('courses'));

  const conditionalRowStyles = [
    {
      when: (row: CTAHosting) => {
        return hasCourses(row) ? invalidCourses(row) : true;
      },
      style: {
        borderColor: COLOR_RED,
      },
    },
  ];

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-hosting"
        locked={locked}
        header="Hosting Revenue"
        onChange={updateOpenedState}
      >
        {wasOpened && (
          <React.Fragment>
            <Paper className="t-hosting">
              <Instruction title="" hideTitle>
                <p>
                  Click in the ‘Add New Contracting District’ box and select the
                  site that sent students to your district. Click the Edit icon
                  next to the district name and follow the prompts in the pop-up
                  window. You may delete the record by clicking on the trash
                  icon. Enter additional contracting districts as needed.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: Entries with an
                  ellipses (…) indicates that items were cutoff and the number
                  in parenthesis (#) indicates the total number of items.
                </p>
              </Instruction>
              <SecureWrap
                component={['ctageneral']}
                requireEdit={true}
                isLocked={readOnly}
              >
                <FlexRow>
                  <CTAAvailableDistrictsSelectBox
                    label="Add New Contracting District"
                    onChange={handleAddDistrict}
                    selector={getRecords_CTAHostingAvailableContracts}
                    dispatcher={loadCTAHostingAvailableContracts}
                  />
                </FlexRow>
              </SecureWrap>
              <Table
                columns={CONTRACTING_COLUMNS}
                conditionalRowStyles={conditionalRowStyles}
                data={ctaHosting}
              />
              {anyCoursesInvalid(ctaHosting) && (
                <p className="u-color-red">
                  Not all required course data has been filled out. Edit each
                  District/Institution bordered in red to fill out required
                  data.
                </p>
              )}
            </Paper>
          </React.Fragment>
        )}
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
