import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  createAwardType,
  readAwardTypes,
  updateAwardType,
  deleteAwardType,
} from 'services/programs/awardtypes.services';
import { AwardType } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadAwardTypes = createActionThunk('loadAwardTypes', () => {
  return readAwardTypes();
}); // end loadAwardTypes

export const saveAwardType = createActionThunk(
  'saveAwardType',
  (payload: AwardType, id: number) => {
    if (payload.awardTypeId === -1) {
      return createAwardType({
        ...payload,
        awardTypeId: id,
      });
    }
    return updateAwardType(payload);
  }
); // end saveProgramCollaboration

export const removeAwardType = createActionThunk(
  'removeAwardType',
  (id: number) => {
    return deleteAwardType(id);
  }
); // end getProgramData

export default handleActions<BaseStateObject, AwardType[]>(
  {
    ...baseLoadActionBuilder<AwardType[]>(loadAwardTypes),
  },
  initialState
);
