/**
 *   -- Base information --
 *   signatureId: internal signature id
 *   signatureTemplateId: the template this signaure applies to.
 *   signatory: this is the display name of the person we want a signature from (e.g.: Perkins Contact, Advocate for Special Populations, etc....)
 *   signatureTypeId: this is the signatureTypeId (e.g. GrantAwardSignature, GrantVoucherSignature, etc....)
 *   required: indicates if this is a required signature;
 *
 *   -- User Entered Information --
 *   signedName: the name the user typed into the signature field
 *   signedTitle: the title the user typed into the title field
 *   signedDate: the date the user selected
 *
 *   -- Information the API collects from the post --
 *   universalContactId: the universalContactId of the user that signed.
 *   aadB2cId: the users aadb2cId;
 *   ipAddress: the ipAddress of the user who signed;
 */
export interface Signature extends SignatureInput {
  signatureId: number;
  signatureTemplateId: number;
  signatory?: string;
  signatureTypeId: number;
  required: boolean;
  universalContactId: number;
  aadB2cId: string;
  ipAddress: string;
  info?: string;
  categoryName: string;
}

export interface SignatureInput {
  isDirty: boolean;
  signedName: string;
  signedTitle: string;
  signedDate?: Date;
}

export const emptySignature = (): Signature => {
  return {
    signatureId: -1,
    signatureTemplateId: -1,
    signatory: '',
    signatureTypeId: -1,
    required: false,
    universalContactId: -1,
    aadB2cId: '',
    ipAddress: '',
    categoryName: '',
    isDirty: false,
    signedName: '',
    signedTitle: '',
  };
};
