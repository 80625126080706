import { useValidation } from 'de-formed-validations';
import { CtaInstructorSalary } from 'types';
import { gt as lt, all } from 'ramda';
import { InstructorProgramValidation } from './InstructorProgram.validations';
import { compose, isTruthy } from 'util/objectUtility';
import { fmap } from 'util/arrayUtility';
import { actualSalaryEarned, annualContractBaseSalary } from 'util/validation.utility';
import { isMoney, removeCommas } from 'util/moneyHelpers';

export const InstructorSalaryValidation = () => {
  const { validateAll: validateProgram } = InstructorProgramValidation();
  return useValidation<CtaInstructorSalary>({
    annualContractBaseSalary,
    actualSalaryEarned,
    programs: [
      {
        errorMessage: 'A program has invalid data.',
        validation: compose(all(isTruthy), fmap(validateProgram)),
      },
    ],
    extraAdminStipend: [
      {
        errorMessage: 'Must be a valid money representation.',
        validation: isMoney,
      },
      {
        errorMessage: 'Must be less than $100,000.',
        validation: compose(lt(100000), removeCommas),
      },
    ],
    numberOfSickDays: [
      {
        errorMessage: 'Cannot exceed 89 days.',
        validation: lt(90),
      },
    ],
  });
};
