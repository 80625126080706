import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
} from 'services';
import { SupportingSalaryDocuments } from 'types/cta/InstructionalCosts/SupportingSalaryDocuments.types';
import { CtaInstructorSalary } from 'types/cta/InstructionalCosts/CtaInstructorSalary.types';
import { CtaInstructor } from 'types/cta/InstructionalCosts/CtaInstructor.types';
import { CtaInstructorCourse } from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';

const BASE_URL = ['api', 'CtaInstructionalCosts'];

export const readInstructorName = async (ctaInstructorId: number) => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'instructorName', ctaInstructorId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'readInstructorName');
    });
};

/* Instructors Overview */
export const readInstructors = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'InstructorsByDistrict', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'cta instructional costs',
        err,
        'readInstructionalCosts'
      );
    });
};

export const readSingleInstructor = async (
  ctaInstructorId: number
): Promise<CtaInstructor> => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'InstructorReview', ctaInstructorId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'readSingleInstructor');
    });
};

export const updateInstructor = async (
  ctaId: number,
  instructor: CtaInstructor
) => {
  return fetch(getFormattedUrl([...BASE_URL, 'Complete', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(instructor),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'updateInstructor');
    });
};

/* InstructorCTECourses */
export const readCTECourses = async (
  ctaInstructorId: number
): Promise<CtaInstructorCourse[]> => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'InstructorCTECourses', ctaInstructorId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'readCTECourses');
    });
};

export const updateCTECourses = async (
  ctaId: number,
  ctaInstructorId: number,
  courses: CtaInstructorCourse[]
) => {
  return fetch(
    getFormattedUrl([
      ...BASE_URL,
      'InstructorCTECourses',
      ctaInstructorId,
      ctaId,
    ]),
    {
      ...postWithAuthOptions(),
      body: JSON.stringify(courses),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'updateCTECourses');
    });
};

/* InstructorNonCTECourses */
export const readNonCTECourses = async (
  ctaInstructorId: number
): Promise<CtaInstructorCourse[]> => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'InstructorNonCTECourses', ctaInstructorId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'readNonCTECourses');
    });
};

export const updateNonCTECourses = async (
  ctaId: number,
  ctaInstructorId: number,
  courses: CtaInstructorCourse[]
) => {
  return fetch(
    getFormattedUrl([
      ...BASE_URL,
      'InstructorNonCTECourses',
      ctaInstructorId,
      ctaId,
    ]),
    {
      ...postWithAuthOptions(),
      body: JSON.stringify(courses),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('cta instructional costs', err, 'updateInstructor');
    });
};

/* supporting salary documents */
export const updateSupportingSalary = async (
  ctaId: number,
  documents: SupportingSalaryDocuments
): Promise<number> => {
  return fetch(getFormattedUrl([...BASE_URL, 'supportingSalary', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(documents),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'cta instructional costs',
        err,
        'updateSupportingSalary'
      );
    });
};

/* Instructor Salary */
export const readInstructorSalary = async (
  ctaInstructorId: number
): Promise<CtaInstructorSalary> => {
  return fetch(getFormattedUrl([...BASE_URL, 'Instructor', ctaInstructorId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'cta instructional costs',
        err,
        'readInstructionalCosts'
      );
    });
};

export const updateInstructorSalary = async (
  ctaId: number,
  instructorSalary: CtaInstructorSalary
) => {
  return fetch(getFormattedUrl([...BASE_URL, 'Instructor', ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(instructorSalary),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'cta instructional costs',
        err,
        'readInstructionalCosts'
      );
    });
};
