import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import { CTAReviewOptimizeOverview, CTAReviewOptimize } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { Link } from 'react-router-dom';
import { formatMoney } from 'util/moneyHelpers';
import { isNumber } from 'lodash';
import { safeGet, prop } from 'util/objectUtility';
import { useParams } from 'react-router';

type ReviewAndOptimizeProps = {
  loadCTAReviewOptimize: Function;
  locked: boolean;
  reviewOptimize: CTAReviewOptimizeOverview;
};

export const ReviewAndOptimizeComponent: React.FC<ReviewAndOptimizeProps> = ({
  loadCTAReviewOptimize,
  locked,
  reviewOptimize,
}) => {
  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);

  // --[ local state ]---------------------------------------------------------
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTAReviewOptimize(ctaId).catch((err: any) => console.error(err));
    }
  }, [wasOpened, loadCTAReviewOptimize, ctaId]);

  const getValueForDisplay = (value: string) => {
    const item = prop(value, reviewOptimize);
    const istruthy = isNumber(item);
    if (istruthy) {
      return `$${formatMoney(item)}`;
    }
    return '';
  };

  const getValueForPercent = (value: string) => {
    const item = prop(value, reviewOptimize);
    const istruthy = isNumber(item);
    if (istruthy) {
      return `${item}%`;
    }
    return '';
  };

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-review"
        locked={locked}
        header="Review & Optimize"
        onChange={updateOpenedState}
      >
        <SecureWrap component={['ctageneral']}>
          <Paper>
            <Instruction title="Data Review">
              <p>
                Prior to running the optimization calculation and submitting the
                CTA report, please click on the ‘Review Reports’ button to
                review that all data entered is correct. When in the{' '}
                <Link to="/cccs-reports">Reporting Dashboard</Link>, search
                ‘CTA’ using the search bar to see all available reports to
                validate the districts inputs. To validate the reimbursement
                amounts use the search bar to search the word ‘reimbursement.’
                The <b>Reimbursement Report</b> will show the optimized vs.
                unoptimized numbers, and the <b>Reimbursement Report (Admin)</b>{' '}
                will show the district’s reimbursement estimate that will be
                calculated into the state total to determine proration, the
                Reimbursement Report (Admin) report is dependent on the
                submission amount that is selected in the Submission / Approval
                page.
                <br />
                <br />
                The CTA formula requires consideration of each district’s Per
                Pupil Revenue (PPR) at the state specified rate. Until this rate
                has provided by the Colorado Department of Education the
                district data displayed will not be accurate, and the district
                may not submit its CTA report until CCCS has received this
                information. Districts will be notified by email from the
                Colorado CTE Gateway once this figure is available, usually in
                early August.
                <br />
                <br />
              </p>
              <p>
                <span className="u-color-red">Note</span>: This data table
                updates hourly. Data you have entered may not immediately
                appear.
              </p>
            </Instruction>
            <FlexRow>
              <Button
                asLink
                className="button--outline"
                to="/cccs-reports"
                style={{ width: '28rem' }}
                text="Review Reports"
              />
            </FlexRow>
          </Paper>
          <Paper>
            <Instruction title="Optimizer">
              <p>
                The optimizer function helps districts identify their maximum
                possible reimbursement amount. In some instances, it is helpful
                to remove programs that cost and student impact do not meet 70%
                of PPR, as it may adversely affect the district’s reimbursement
                eligibility. Displayed below are the district’s non-optimized
                and optimized reimbursement estimates prior to any proration
                being applied. If the district’s optimized reimbursement
                estimate is greater, you should select optimized reimbursement
                on the Submission/Approval page. Note that if no values are
                showing in the boxes below, it is because CCCS does not yet have
                PPR figures from CDE. Once this information is available,
                generally in early August, the fields will be updated.
                <br />
                <br />
              </p>
              <p>
                <span className="u-color-red">Disclaimer</span>: Final CTA
                Reimbursement amount will be a prorated amount less than the
                amount shown above. You will receive an email by approximately
                December 15th with your final reimbursement amount. These
                numbers are only being provided as a budgeting tool.
              </p>
            </Instruction>
            <FlexRow>
              <Input
                className="input-display"
                disabled
                label="Non-Optimized Reimbursement Estimate (100% Prorated)"
                name=""
                value={getValueForDisplay('nonOptimizedReimbursementEstimate')}
              />
              <Input
                className="input-display"
                disabled
                label="Optimized Reimbursement Estimate (100% Prorated)"
                name=""
                value={getValueForDisplay('optimizedReimbursementEstimate')}
              />
            </FlexRow>
          </Paper>
          <Paper>
            <Instruction title="Reimbursement & Proration">
              <p>
                The reimbursement amount for each district is based on the costs
                and number of full-time equivalent CTE students reported, and
                the statewide disbursement amount available for distribution.
                Since the amount of statewide funds available for disbursement
                is always less than the amount districts are eligible to
                receive, reimbursements are prorated. We are providing you with
                the 3-year average proration percentage should you wish to use
                it to roughly estimate your district’s potential reimbursement
                for the current year.
              </p>
            </Instruction>
            <FlexRow>
              <Input
                className="input-display"
                disabled
                label="Statewide Disbursement Amount"
                name=""
                value={getValueForDisplay('statewideDisbursementAmount')}
              />
              <Input
                className="input-display"
                disabled
                label="Average Prorated Reimbursement (past 3 yrs)"
                name=""
                value={`${prop(
                  'proratedReimbursementAverage',
                  reviewOptimize
                )}%`}
              />
            </FlexRow>
          </Paper>
          <Paper>
            <Instruction title="Final CTA Reimbursement">
              <p>
                The district’s Final CTA Reimbursement figure for the reporting
                year will be populated here once the statewide calculation has
                been run, usually by mid-December. Districts will receive an
                email from this site as soon as Final CTA Reimbursements are
                posted here. School districts are paid quarterly in the current
                fiscal year with the payment for the first two quarters
                occurring by approximately December 31. If your district owes
                money to CCCS as the result of a CTA audit, current year CTA
                funds will not be released until outstanding audit invoices are
                paid.
              </p>
            </Instruction>
            <FlexRow>
              <Input
                className="input-display"
                disabled
                label="Reimbursement"
                name=""
                value={getValueForDisplay('reimbursement')}
              />
              <Input
                className="input-display"
                disabled
                label="State Proration"
                name=""
                value={getValueForPercent('stateProration')}
              />
            </FlexRow>
          </Paper>
        </SecureWrap>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
