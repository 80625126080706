import { CTADistrictSalary } from 'types';
import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
} from 'services';

const BASE_URL = ['api', 'ctasupportpersonnel', 'district-salary'];

export const readCTADistrictSalary = async (
  ctaId: number
): Promise<CTADistrictSalary[]> => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA District Salary', err, 'readCTADistrictSalary');
    });
};

export const updateCTADistrictSalary = async (
  ctaId: number,
  districtSalary: CTADistrictSalary
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(districtSalary),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA District Salary', err, 'updateCTADistrictSalary');
    });
};
