import React from 'react';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { PersonalInformationPS } from './PersonalInformationPS';
import { FlexRow } from 'layouts';
import { PersonalInformationBase } from './PersonalInformationBase';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { prop } from 'util/objectUtility';

const PersonalInformation: React.FC<StudentRecordComponentProps> = (props) => {
  const { student } = props;
  const programLevel = prop('programLevelId', student);
  const isPostsecondary = programLevel === PROGRAM_LEVEL_ENUM.Postsecondary;
  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">Personal Information</h2>
      </FlexRow>
      <PersonalInformationBase {...props} />
      {isPostsecondary && <PersonalInformationPS {...props} />}
    </React.Fragment>
  );
};

export default PersonalInformation;
