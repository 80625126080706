import { useValidation } from 'de-formed-validations';
import { SnapshotTeacher } from 'types';
import { every, isNil, some } from 'lodash';
import { isEqual } from 'util/formHelpers';

const validDoB = (date: Date) => {
  if (!date) return false;
  const testDate = new Date(date);
  const currentYear = new Date().getFullYear();
  const testYear = testDate.getFullYear();
  const diff = currentYear - testYear;
  return every([diff <= 80, diff >= 20]);
};

export const SnapshotTeacherValidations = () => {
  return useValidation<SnapshotTeacher>({
    credentialType: [
      {
        errorMessage: 'Credential Type is required.',
        validation: (val: number | null, state: SnapshotTeacher) => {
          return state.isTeachingPostsecondary ? !isNil(val) : true;
        },
      },
    ],
    dob: [
      {
        errorMessage: 'Must be between 20 and 80 years old.',
        validation: (val: Date, _) => {
          return validDoB(val);
        },
      },
    ],
    renewalReq: [
      {
        errorMessage: 'Must be 1-150 characters.',
        validation: (val: string, state: SnapshotTeacher) => {
          const value = val.trim();
          return state.isTeachingPostsecondary
            ? every([value.length > 0, value.length <= 150])
            : true;
        },
      },
    ],
    s_number: [
      {
        errorMessage: 'Must be 1-9 characters.',
        validation: (val: string, state: SnapshotTeacher) => {
          const value = val.trim();
          return state.isTeachingPostsecondary
            ? every([value.length > 0, value.length <= 9])
            : true;
        },
      },
    ],
    programInstanceId: [
      {
        errorMessage: 'Program is required.',
        validation: (val: number, _) => {
          return val > 0;
        },
      },
    ],
    schoolId: [
      {
        errorMessage: 'School is required.',
        validation: (val: number, _) => {
          return val > 0;
        },
      },
    ],
    timeId: [
      {
        errorMessage: 'Instructional Time is required.',
        validation: (val: string, _) => {
          const test = isEqual(val);
          return some([test('1'), test('2')]);
        },
      },
    ],
  });
};
