import Button from 'components/Common/FormElements/Button';
import Input from 'components/Common/FormElements/Input';
import Textarea from 'components/Common/FormElements/Textarea';
import Paper from 'components/Common/Paper';
import ToastNotification from 'components/Common/Toast';
import { FlexRow } from 'layouts';
import { get, isEmpty, some } from 'lodash';
import React, { useRef, useState } from 'react';
import { Modal, useModal } from 'react-morphing-modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { savePermissionRole } from 'redux/permissions/permissionroles';
import StringResources from 'StringResources';
import { emptyPermissionRole, PermissionRole } from 'types';

export const NewRoleModal: React.FC = () => {
  // -- dependencies --
  const { modalProps, open, close } = useModal();

  // -- local state --
  const modalRef = useRef<HTMLDivElement>(null);

  const [currentRole, setCurrentRole] = useState<PermissionRole>(
    emptyPermissionRole()
  );

  const handleChange = (item: Partial<PermissionRole>) => {
    setCurrentRole({ ...currentRole, ...item });
  };
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(savePermissionRole(currentRole))
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .finally(() => close());
  };
  const getDisabled = () => {
    return some([
      isEmpty(currentRole.permissionRoleName),
      isEmpty(currentRole.permissionRoleLabelName),
    ]);
  };
  return (
    <React.Fragment>
      <div ref={modalRef}>
        <Button
          text="Create a New Role"
          className="button--filled"
          onClick={() => open(modalRef)}
        />
      </div>
      <Modal {...modalProps}>
        <Paper>
          <FlexRow>
            <h3>Create new role</h3>
          </FlexRow>
          <FlexRow>
            <Input
              name="permissionRoleName"
              value={get(currentRole, 'permissionRoleName', '')}
              onChange={handleChange}
            />

            <Input
              label="Friendly Name"
              name="permissionRoleLabelName"
              value={get(currentRole, 'permissionRoleLabelName', '')}
              onChange={handleChange}
            />
          </FlexRow>
          <FlexRow>
            <Textarea
              maxLength={2000}
              name="permissionRoleDescription"
              value={get(currentRole, 'permissionRoleDescription', '')}
              onChange={handleChange}
            />
          </FlexRow>
          <FlexRow>
            <div className="u-flex-grow-1" />
            <Button
              asLink={false}
              text={'Cancel'}
              onClick={close}
              className={`form__btn--cancel`}
            />
            <Button
              disabled={getDisabled()}
              asLink={false}
              text={'Save Role Details'}
              onClick={handleSave}
              className={`form__btn--submit`}
            />
          </FlexRow>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
