import { SummaryScroll } from './SummaryScroll.component';
import { getCurrentNavAnchor } from 'redux/application/selectors';
import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';

const mapStateToProps = (state: any, ownProps: any) => {
  const currentNavAnchor = getCurrentNavAnchor(state);

  return {
    currentNavAnchor,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SummaryScroll);
