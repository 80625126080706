export const EQUITY_ADDRESSABLE_ELEMENTS = '[EQUITY_ADDRESSABLE_ELEMENTS]';

export const SET_EQUITY_ADDRESSABLE_ELEMENTS = `${EQUITY_ADDRESSABLE_ELEMENTS} SET`;
export const SET_EQUITY_ADDRESSABLE_ELEMENTS_LOADING = `${EQUITY_ADDRESSABLE_ELEMENTS} LOADING`;

export const setEquityAddressableElements = (payload: any) => {
  return {
    type: SET_EQUITY_ADDRESSABLE_ELEMENTS,
    payload,
  };
};

export const setIsLoading_EquityAddressableElements = (payload: boolean) => {
  return {
    type: SET_EQUITY_ADDRESSABLE_ELEMENTS_LOADING,
    payload,
  };
};
