import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_FINAL_VOUCHER_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_FINAL_VOUCHER = `${CHANGELOG_FINAL_VOUCHER_KEY} RESET UPDATE`;
export const SET_CHANGELOG_FINAL_VOUCHER = `${CHANGELOG_FINAL_VOUCHER_KEY} SET`;
export const SET_CHANGELOG_FINAL_VOUCHER_LOADING = `${CHANGELOG_FINAL_VOUCHER_KEY} LOADING`;

const initState = baseInitialState;

export const changelogFinalVoucherReducer = (
  state = initState,
  action: any
) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_FINAL_VOUCHER:
      return setShouldUpdate(state);

    case SET_CHANGELOG_FINAL_VOUCHER:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_FINAL_VOUCHER_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogFinalVoucher = (payload: any) => {
  return {
    type: SET_CHANGELOG_FINAL_VOUCHER,
    payload,
  };
};

export const setIsLoading_ChangelogFinalVoucher = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_FINAL_VOUCHER_LOADING,
    payload,
  };
};
