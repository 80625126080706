import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import { WORKFLOW_FOUR_YEAR_KEY } from 'redux/keys';

export const SET_FOUR_YEAR_WORKFLOW = `${WORKFLOW_FOUR_YEAR_KEY} SET`;
export const SET_FOUR_YEAR_WORKFLOW_LOADING = `${WORKFLOW_FOUR_YEAR_KEY} LOADING`;

// -- actions ------------------------------------------------------------------
export const setFourYearWorkflow = (payload: any) => {
  return {
    type: SET_FOUR_YEAR_WORKFLOW,
    payload,
  };
};

export const setFourYearWorkflowLoading = (payload: boolean) => {
  return {
    type: SET_FOUR_YEAR_WORKFLOW_LOADING,
    payload,
  };
};

// -- reducer ------------------------------------------------------------------
const initState = baseInitialState;

export const fourYearWorkflowReducer = (workflow = initState, action: any) => {
  switch (action.type) {
    case SET_FOUR_YEAR_WORKFLOW:
      return actionUpdateRecords(workflow, action.payload);

    case SET_FOUR_YEAR_WORKFLOW_LOADING:
      return actionFetching(workflow, action.payload);

    default:
      return workflow;
  }
};
