import { CommonSignatureComponent } from './Signature.component';
import { connect } from 'react-redux';
import { loadSingleUniversalContact } from 'redux/universalcontacts/universalcontacts';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const { signature } = ownProps;
  const user = getContact(state, signature.universalContactId);

  return {
    signature,
    user,
  };
};

const dispatchToProps = {
  loadSingleUniversalContact,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(CommonSignatureComponent);
