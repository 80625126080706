import React, { useState, useEffect } from 'react';
import { deburr, escapeRegExp, map, filter, find } from 'lodash';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import { Program, emptyProgram } from 'types';
import { FlexGroup } from 'layouts';
import MaxResults from '../MaxResults';
import { renderData } from 'util/objectUtility';
import { getParts, renderPart } from 'util/autoSuggest.utility';

type ProgramInstanceSelectBoxProps = {
  programs: Program[];
  programInstanceId?: number;
  onChange: Function;
  maxResults?: number;
};

export const ProgramInstanceSelectBox: React.FC<ProgramInstanceSelectBoxProps> = ({
  programs,
  programInstanceId,
  onChange,
  maxResults = 6,
}) => {
  const [programSuggestions, setProgramSuggestions] = useState<Program[]>([]);
  const [result, setResult] = useState('');

  const boxStyle = {
    cip: {
      width: '20%',
    },
    name: {
      width: '60%',
    },
  };

  /* set the selected program  */
  useEffect(() => {
    if (programInstanceId) {
      const selectedProgram = find(programs, (item: Program) => {
        return item.programInstanceId === programInstanceId;
      });
      setResult(selectedProgram ? selectedProgram.programName : '');
    }
  }, [programInstanceId, programs]); // eslint-disable-line

  const executeSearch = (value: string): Program[] => {
    const cleanValue = escapeRegExp(deburr(value.trim()).toLowerCase());

    if (cleanValue) {
      const regex = new RegExp(`^${cleanValue}`, 'i');
      const results = filter(programs, (program: Program) => {
        return (
          regex.test(program.programName) || regex.test(String(program.cipId))
        );
      });
      return results.length === 0
        ? [{ ...emptyProgram(), programName: 'No Results', cipId: '00.0000' }]
        : results.slice(0, maxResults);
    }

    return programs.slice(0, maxResults);
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    if (request.value === result) {
      setProgramSuggestions(executeSearch(request.value));
    }
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle.cip}>
            <strong>Cip Code</strong>
          </span>
          <span style={boxStyle.name}>
            <strong>Program Name</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={programs.length}
          resultCount={programSuggestions.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (suggestion: Program, { query }: any) => {
    const nameParts = getParts(renderData(suggestion.programName), query);
    const idParts = getParts(renderData(suggestion.cipId), query);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div style={boxStyle.cip}>{map(idParts, renderPart)}</div>
          <div style={boxStyle.name}>{map(nameParts, renderPart)}</div>
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'down':
      case 'up':
        break;
      default:
        setResult(newValue);
        const suggestions = executeSearch(newValue);
        const hasSuggestions = suggestions.length === 0;
        setProgramSuggestions(hasSuggestions ? [emptyProgram()] : suggestions);
        break;
    }
  };

  return (
    <FlexGroup>
      <p className="form__label">Search for a Program</p>
      <Autosuggest
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={true}
        onSuggestionSelected={(event, { suggestion }) => {
          if (suggestion.programBaseId > 0) {
            onChange && onChange(suggestion);
          }
          setProgramSuggestions([]);
        }}
        getSuggestionValue={(suggestion: Program) => {
          return suggestion.programBaseId > 0 ? suggestion.programName : '';
        }}
        suggestions={programSuggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {
          setProgramSuggestions([]);
        }}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={{
          value: result,
          onChange: handleChange,
        }}
      />
    </FlexGroup>
  );
};
