import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionTopic } from 'types';
import {
  createPermissionTopic,
  readPermissionTopics,
  updatePermissionTopic,
} from 'services/permissions/permissiontopic.services';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadPermissionTopics = createActionThunk(
  'loadPermissionTopics',
  () => {
    return readPermissionTopics();
  }
); // end

export const savePermissionTopic = createActionThunk(
  'savePermissionTopic',
  async (payload: PermissionTopic) => {
    let creating =
      !payload.permissionTopicId || payload.permissionTopicId === -1;
    if (creating) {
      return createPermissionTopic(payload);
    } else {
      return updatePermissionTopic(payload).then(() => {
        return payload.permissionTopicId;
      });
    }
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, PermissionTopic[]>(
  {
    ...baseLoadActionBuilder<PermissionTopic[]>(loadPermissionTopics),

    [savePermissionTopic.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [savePermissionTopic.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
