import { connect } from 'react-redux';
import { VoucherListComponent } from './VoucherList.component';
import { Voucher } from 'types';
import { getRecords_Vouchers } from 'redux/grants/_selectors';
import { loadVouchers } from 'redux/grants/vouchers';

const mapStateToProps = (state: any) => {
  const vouchers: Voucher[] = getRecords_Vouchers(state);

  return {
    vouchers,
  };
};

const dispatchProps = {
  loadVouchers,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(VoucherListComponent);
