import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readGrantVoucherWorkflowStatus } from 'services/nav/grantvoucher.services';
import { WorkflowStatus } from 'types/grant/WorkflowStatus.types';

const initialState = baseInitialState;

export const loadGrantVoucherOverviewStatus = createActionThunk(
  'loadGrantVoucherOverviewStatus',
  (GrantFiscalYearId: number) => {
    const voucherStatus = readGrantVoucherWorkflowStatus(GrantFiscalYearId);
    return voucherStatus;
  }
);

export default handleActions<BaseStateObject, WorkflowStatus>(
  {
    ...baseLoadActionBuilder<WorkflowStatus>(loadGrantVoucherOverviewStatus),
  },
  initialState
);
