export interface CtaInstructor {
  ctaInstructorId: number;
  instructorName: string;
  credentialNumber: string;
  credentialArea: string;
  credentialExpiration: Date;
  cteCount: number;
  ctePercent: number;
  classCount: number;
  salary: number;
  status: boolean;
  schools: string[];
  programs: string[];
}

export const emptyCtaInstructor = () => {
  return {
    ctaInstructorId: -1,
    instructorName: '',
    credentialNumber: '',
    credentialArea: '',
    credentialExpiration: new Date('1/1/1970'),
    cteCount: 0,
    ctePercent: 0,
    classCount: 0,
    salary: 0,
    status: false,
    schools: [],
    programs: [],
  };
};
