import { ManageTemplate } from './ManageTemplate.component';
import { connect } from 'react-redux';
import {
  getEmailTemplate,
  getIsDataFetching_EmailTemplate,
} from 'redux/grants/_selectors';
import { loadSingleEmailTemplate } from 'redux/grants/emailtemplates';
import { getAdminComment } from 'redux/common/_selectors';

const mapStateToProps = (state: any) => {
  const emailTemplate = getEmailTemplate(state);
  const isLoading = getIsDataFetching_EmailTemplate(state);
  const adminComments = getAdminComment(state, emailTemplate.templateId);

  return {
    adminComments,
    emailTemplate,
    isLoading,
  };
};

const dispatchProps = {
  loadSingleEmailTemplate,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ManageTemplate);
