import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from '..';
import { ProgramPathway } from 'types';

const ENDPOINT = 'api/ProgramPathways/byprograminstanceid/';

/**
 * Makes a "POST" request to 'api/ProgramPathways/ByProgramInstanceId/{programInstanceId}' to create new ProgramPathway associations.
 * @param  programPathways an array of ProgramPathway objects
 * @returns the new ProgramInstanceId
 */
export const createProgramCareerPathways = async (
  programPathways: ProgramPathway[],
  programInstanceId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(programPathways),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramCareerPathways', err);
    });
};

/**
 * Makes a "GET" request to 'api/ProgramPathways/ByProgramInstanceId/{programInstanceId}' to get all ProgramPathways associated with programInstanceId.
 * @returns an array of all program pathways associated with a programInstanceId.
 */
export const readProgramCareerPathways = async (programInstanceId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramCareerPathways', err);
    });
};
