import { ParticipationDashboardFilters } from './Filters.component';
import { connect } from 'react-redux';
import {
  clearGrantParticipantFilters,
  setGrantParticipantFilterValue,
} from 'redux/filters/grantParticipantfilter';
import { every, filter } from 'lodash';
import { len } from 'util/objectUtility';
import { getRecords_GrantParticipationMember } from 'redux/grants/grantparticipant.selectors';

const mapStateProps = (state: any, ownProps: any) => {
  const allParticipants = getRecords_GrantParticipationMember(state);

  const notified = len(
    filter(allParticipants, (item) => {
      return item.notified === true;
    })
  );

  const responses = len(
    filter(allParticipants, (item) => {
      return item.responded === true;
    })
  );

  const noResponses = len(
    filter(allParticipants, (item) => {
      return item.responded === false;
    })
  );

  const consortiumParticipant = len(
    filter(allParticipants, (item) => {
      return every([
        item.isStandalone === false,
        item.isParticipating === true,
      ]);
    })
  );

  const standaloneParticipant = len(
    filter(allParticipants, (item) => {
      return every([item.isStandalone === true, item.isParticipating === true]);
    })
  );

  const notParticipating = len(
    filter(allParticipants, (item) => {
      return item.isParticipating === false;
    })
  );

  const filterLengths = {
    notified,
    responses,
    noResponses,
    consortiumParticipant,
    standaloneParticipant,
    notParticipating,
  };

  return {
    filterLengths,
  };
};

const dispatchProps = {
  clearGrantParticipantFilters,
  setGrantParticipantFilterValue,
};

export default connect(
  mapStateProps,
  dispatchProps
)(ParticipationDashboardFilters);
