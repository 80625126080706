import { connect } from 'react-redux';
import { TeacherUploadComponent } from './TeacherUpload.component';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

export default connect(
  mapStateToProps,
  null
)(TeacherUploadComponent);
