import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import AdminPortalPage from 'pages/Admin/AdminPortalPage';
import PermissionRolesPage from 'pages/Admin/PermissionRolesPage';
import RoleDetailsPage from 'pages/Admin/RoleDetailsPage';

const adminRoutes = [
  <Route
    exact
    path="/admin"
    component={authentication.required(AdminPortalPage)}
  />,
  <Route
    exact
    path="/admin/permission-roles"
    component={authentication.required(PermissionRolesPage)}
  />,
  <Route
    exact
    path="/admin/permission-roles/:roleId"
    component={authentication.required(RoleDetailsPage)}
  />,
];

export default adminRoutes;
