import React, { useEffect, useState } from 'react';
import { Dialog } from '@rmwc/dialog';
import { toast } from 'react-toastify';
import { uploadFileToAzure } from 'services/azure/azure.services';
import { AzureUploadParameters } from 'types';
import DatatableLoading from 'components/Common/DatatableLoading';
import FileDropZone from 'components/Common/FormElements/FileDropZone/FileDropZone';
import IconButton from 'components/Common/IconButton';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';

type AzureUploaderCallback = (path: string) => void;

type AzureUploaderProps = {
  onClose: Function;
  onConfirm: AzureUploaderCallback;
  open: boolean;
  uploadFileToDB?: Function;
  uploadParams: AzureUploadParameters;
};

export const AzureUploader: React.FC<AzureUploaderProps> = (props) => {
  const { onClose, onConfirm, open, uploadFileToDB, uploadParams } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const uploadFile = async (file: File) => {
    if (!file) return;
    const path = `${uploadParams.folder}|${new Date(
      Date.now()
    ).getFullYear()}|${uploadParams.version ? `${uploadParams.version}_` : ''}${
      file.name
    }`;

    uploadFileToDB && uploadFileToDB(file);

    setUploading(true);
    await uploadFileToAzure(uploadParams.container, path, file)
      .then(() => {
        onConfirm(path);
      })
      .catch((err) => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileSaveFailed}
          />
        );
      })
      .finally(() => {
        setUploading(false);
        setOpen(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
      >
        <div className="azure-dialog">
          <div className="azure-dialog__title form__section-title u-flex u-flex-full-center">
            <div className="u-flex-grow-1">Upload files</div>
            <IconButton
              name="cross"
              tooltip="Exit"
              onClick={() => setOpen(false)}
            />
          </div>
          <div style={{ minWidth: '64rem' }}>
            {uploading ? (
              <DatatableLoading
                loading={uploading}
                messageBefore="Uploading File..."
              />
            ) : (
              <FileDropZone
                onUpload={(incomingFile: File) => {
                  uploadFile(incomingFile);
                }}
              />
            )}
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
