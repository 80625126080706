import React from 'react';
import { ProgramOfStudyCourseLevel } from 'types';
import { map } from 'lodash';
import { FlexRow } from 'layouts';

export const SecondaryCareerPathwayCourses: React.FC<{
  level: ProgramOfStudyCourseLevel;
}> = (props) => {
  const { level } = props;
  return (
    <FlexRow>
      <div className="secondary-pos-courses">
        <h4>{`Courses Level ${level.courseLevel}`}</h4>
        {map(level.courses, (course, index) => {
          return (
            <p key={index}>{`${course.course}${
              course.advancedCreditOption
                ? ` - ${course.advancedCreditOption}`
                : ''
            }`}</p>
          );
        })}
      </div>
    </FlexRow>
  );
};
