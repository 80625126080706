import { connect } from 'react-redux';
import {
  getRecords_ProgramLevels,
  PROGRAM_LEVELS_PATH,
} from 'redux/common/_selectors';
import { ProgramLevel, SelectWrapper, OptionMap } from 'types';
import SelectBox from 'components/Common/FormElements/SelectBox';
import { getRecordsUtility } from 'util/formHelpers';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const { selection, filter, label, onChange, records: baseRecords } = ownProps;

  const records = getRecordsUtility<ProgramLevel>(
    state,
    PROGRAM_LEVELS_PATH,
    getRecords_ProgramLevels,
    filter,
    baseRecords
  );

  const optionMap: OptionMap<ProgramLevel> = {
    key: 'programLevelId',
    label: 'programLevelName',
    value: 'programLevelId',
  };

  return {
    label: label ? label : 'Program Level',
    name: 'programLevelId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
