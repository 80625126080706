import { useValidation } from 'de-formed-validations';
import { VoucherExpense } from 'types';
import { compose, prop } from 'util/objectUtility';
import { isEqual } from 'util/formHelpers';
import { isStringValid } from 'util/validation.utility';
import { isNil } from 'lodash';

export const VoucherExpenseValidations = () => {
  const isExpense = compose(
    isEqual(4),
    prop('financialCategoryId')
  );
  const hasValue = compose(
    (num: number) => num > 0,
    prop('amountForCurrentVoucher')
  );
  const checkExpense = (expense: VoucherExpense) => {
    return isExpense(expense) && hasValue(expense);
  };

  return useValidation<VoucherExpense>({
    amountForCurrentVoucher: [
      {
        errorMessage: 'Expenses cannot have negative amounts.',
        validation: (value: number, _) => {
          return value >= 0;
        },
      },
    ],
    specificLocation: [
      {
        errorMessage: 'Specific Location is required.',
        validation: (value: string, expense: VoucherExpense) => {
          return checkExpense(expense) ? isStringValid(value) : true;
        },
      },
    ],
    serialNumber: [
      {
        errorMessage: 'Serial Number is required.',
        validation: (value: string, expense: VoucherExpense) => {
          return checkExpense(expense) ? isStringValid(value) : true;
        },
      },
    ],
    invoiceNumber: [
      {
        errorMessage: 'Invoice Number is required.',
        validation: (value: string, expense: VoucherExpense) => {
          return checkExpense(expense) ? isStringValid(value) : true;
        },
      },
    ],
    equipmentReceiptFilePath: [
      {
        errorMessage: 'Equipment Receipt is required.',
        validation: (value: string, expense: VoucherExpense) => {
          return checkExpense(expense) ? isStringValid(value) : true;
        },
      },
    ],
    invoiceDate: [
      {
        errorMessage: 'Invoice Date is required.',
        validation: (value: Date, expense: VoucherExpense) => {
          return checkExpense(expense) ? !isNil(value) : true;
        },
      },
    ],
  });
};
