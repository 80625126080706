import React from 'react';
import { useParams } from 'react-router';
import { FlexRow } from 'layouts';
import { ProgramPathway, PathwayCourse } from 'types';
import { map } from 'lodash';
import { compose, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import CourseSummaryTotal from '../CourseSummaryTotals';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';
import IconButton from 'components/Common/IconButton';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';
import Table from 'components/Common/Table';

type PathwayComponentProps = {
  programPathway: ProgramPathway;
  onChange: Function;
};

export const PathwayComponent: React.FC<PathwayComponentProps> = (props) => {
  const { programPathway, onChange } = props;
  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);
  const { programCareerPathwayId } = programPathway;

  const handleToggle = (data: any, courseId: number) => {
    const courses = map(programPathway.courses, (course: PathwayCourse) => {
      return course.courseId === courseId ? { ...course, ...data } : course;
    });
    const updatedPathway = {
      ...programPathway,
      courses,
    };
    onChange(updatedPathway);
  };

  const onFormChange = (data: any) => {
    const updatedPathway = {
      ...programPathway,
      ...data,
    };
    onChange(updatedPathway);
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderAdvancedCredit = (row: PathwayCourse) => {
    return renderCircle(row.isAdvancedCreditOption);
  };
  const renderIsAcademicCredit = (row: PathwayCourse) => {
    return renderCircle(row.isAcademicCredit);
  };

  const renderEditIcon = (row: PathwayCourse) => {
    return (
      <IconButton
        name="pencil"
        tooltip="Edit Course"
        asLink={true}
        to={`/programs/courses/${programInstanceId}/${programCareerPathwayId}/${
          row.courseId
        }`}
      />
    );
  };

  const renderCourseName = (row: PathwayCourse) => {
    return (
      <React.Fragment>
        {row.preferredTitle ? (
          <div className="u-flex-vertical">
            <div>{row.preferredTitle}</div>
            {row.preferredTitle && <div>{`State Name: ${row.courseName}`}</div>}
          </div>
        ) : (
          row.courseName
        )}
      </React.Fragment>
    );
  };

  const renderCustomCourse = (row: PathwayCourse) => {
    return (
      <Icon
        name={row.isCustomCourse ? 'circleFilled' : 'circleOpen'}
        className="consortium-view__status-icon u-icon-green"
      />
    );
  };

  const renderToggle = (row: PathwayCourse) => {
    return (
      <Toggle
        value={row.optIn}
        name="optIn"
        onChange={(data: any) => handleToggle(data, row.courseId)}
        noLabel={true}
      />
    );
  };

  const CourseLevel = (row: PathwayCourse) => {
    return row.courseLevel === 255
      ? renderData(undefined)
      : renderData(row.courseLevel);
  };

  const ContactHours = compose(
    renderData,
    prop('contactHours')
  );

  const generateHeaders = (): TableColumnProps[] => {
    const baseHeaders: TableColumnProps[] = [
      {
        name: 'Selected',
        cell: renderToggle,
        sortable: true,
        selector: 'optIn',
        width: '10rem',
      },
      {
        name: 'Course Name',
        cell: renderCourseName,
        sortable: true,
        selector: 'courseName',
        style: {
          minWidth: '40%',
        },
      },
      {
        name: 'Custom Course',
        cell: renderCustomCourse,
        sortable: true,
        selector: prop('isCustomCourse'),
        center: true,
        width: '10%',
      },
      {
        name: 'Contact Hours',
        cell: ContactHours,
        sortable: true,
        selector: 'contactHours',
        center: true,
        width: '10%',
      },
    ];

    const highSchoolHeaders: TableColumnProps[] = [
      {
        name: 'Course Level',
        cell: CourseLevel,
        selector: CourseLevel,
        sortable: true,
        center: true,
        width: '10%',
      },
      {
        name: 'Advanced Credit',
        cell: renderAdvancedCredit,
        center: true,
        width: '10%',
      },
      {
        name: 'Academic Credit',
        cell: renderIsAcademicCredit,
        center: true,
        width: '10%',
      },
    ];

    const edit: TableColumnProps = {
      name: 'Edit',
      cell: renderEditIcon,
      center: true,
      width: '10%',
    };

    return programPathway.isMiddleSchool
      ? [...baseHeaders, edit]
      : [...baseHeaders, ...highSchoolHeaders, edit];
  };

  return (
    <React.Fragment>
      <div className="program-step__header">
        <h2 className="program-step__title">
          {programPathway.careerPathwayName}
        </h2>
      </div>
      <Paper>
        {!programPathway.isMiddleSchool && (
          <CourseSummaryTotal programPathway={programPathway} />
        )}
        <Table
          columns={generateHeaders()}
          data={programPathway && programPathway.courses}
        />
        <Button
          text="+ Add new course"
          asLink={true}
          to={`/programs/courses/${programInstanceId}/${
            programPathway.programCareerPathwayId
          }/0`}
          className="form__btn--add u-reduce-margin-top u-z-plus"
          onClick={() => null}
        />
        {false && (
          <FlexRow>
            <Textarea
              name="definitionCompleter"
              label="Definition of Completer"
              value={programPathway.definitionCompleter}
              onChange={onFormChange}
            />
          </FlexRow>
        )}
      </Paper>
    </React.Fragment>
  );
};
