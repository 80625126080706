import { ExpenseProgram } from '../ExpenseProgram.types';

export interface Expense {
  // PK
  expenseId: number;

  // base
  budgetedAmount: number;
  description: string;
  expenseName: string;
  expensePrograms: ExpenseProgram[];
  financialCategoryId: number;
  isBackupExpense: boolean;
  isNewProgram: boolean;
  spendingCategoryId: number;
  strategyId: number;

  // equipment related
  units: number;
  unitCost: number;
  actualAmount: number;
  totalEquipmentCost: number;

  // travel related
  rate: number;
  eventName: string;
  travelIsOutOfState: boolean;
  travelerTypeId: number;
  travelStartDate: Date;
  travelEndDate: Date;
  travelerName: string;
  travelerNoValidation: boolean;
  travelerDescription: string;
  travelerDestination: string;
  travelerImproveProgramDesc: string;
}

export const emptyExpense = (): Expense => {
  return {
    expenseId: -1,
    spendingCategoryId: -1,
    expenseName: '',
    financialCategoryId: -1,
    units: 0,
    unitCost: 0,
    budgetedAmount: 0,
    actualAmount: 0,
    totalEquipmentCost: 0,
    rate: 0,
    description: '',
    eventName: '',
    travelIsOutOfState: false,
    travelerTypeId: 0,
    travelStartDate: new Date(),
    travelEndDate: new Date(),
    travelerName: '',
    travelerNoValidation: false,
    travelerDescription: '',
    travelerDestination: '',
    travelerImproveProgramDesc: '',
    isBackupExpense: false,
    isNewProgram: false,
    expensePrograms: [],
    strategyId: -1,
  };
};
