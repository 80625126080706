import { UniversalContact, Email, PhoneNumber } from 'types';
import { isStringValid } from 'util/validation.utility';
import { some } from 'lodash';
import { useValidation } from 'de-formed-validations';
import { validateEmail, validatePhoneNumber } from 'util/formHelpers';

export const UniversalContactValidation = () => {
  return useValidation<UniversalContact>({
    loginEmail: [
      {
        errorMessage: 'Login email must be a unique, valid email.',
        validation: (val: string, contact: UniversalContact) => {
          if (contact.isAadB2c) {
            return validateEmail(val);
          }
          return true;
        },
      },
    ],
    firstName: [
      {
        errorMessage: 'First Name is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    lastName: [
      {
        errorMessage: 'Last Name is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    title: [
      {
        errorMessage: 'Title is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    universalContactEmail: [
      {
        errorMessage: 'Contact Email must be a unique, valid email.',
        validation: (emails: Email[], contact: UniversalContact) => {
          let pass = true;
          if (contact.loginEmail.length > 0) {
            emails.forEach((email: Email) => {
              pass =
                validateEmail(email.email) || email.email === '' ? true : false;
            });
            return pass;
          }
          emails.forEach((email: Email) => {
            pass = validateEmail(email.email);
          });
          return pass;
        },
      },
    ],
    universalContactPhone: [
      {
        errorMessage: 'Phone must be a 10-digit number.',
        validation: (phones: PhoneNumber[], _) => {
          let pass = true;
          phones.forEach((phone: PhoneNumber) => {
            pass = validatePhoneNumber(phone.number);
          });
          return pass;
        },
      },
    ],
    fax: [
      {
        errorMessage: 'Fax Numbers must be 10 digits.',
        validation: (fax: string, _) => {
          return fax === '' || validatePhoneNumber(fax);
        },
      },
    ],
    credentialId: [
      {
        errorMessage: 'Credential ID cannot contain spaces.',
        validation: (val: string, _) => {
          return /\s/g.test(val) === false;
        },
      },
      {
        errorMessage: 'Credential ID is required.',
        validation: (val: string, contact: UniversalContact) => {
          const needsCredentials = some([
            contact.isTeacher,
            contact.isCteDirector,
            contact.isCteSpecialist,
            contact.isWorkBasedLearning,
          ]);
          if (needsCredentials) {
            return isStringValid(val) && !val.includes(' ');
          }
          return true;
        },
      },
    ],
    credentialExpirationDate: [
      {
        errorMessage: 'Expiration Date must be a valid future date.',
        validation: (val: Date, contact: UniversalContact) => {
          if (contact.isTeacher) {
            if (!val) return false; // fail safe to make sure undefined never passes
            const res = new Date(Date.now()) < new Date(val);
            return res;
          }
          return true;
        },
      },
    ],
    credentialArea: [
      {
        errorMessage: 'Credential Area must have a value.',
        validation: (val: string, contact: UniversalContact) => {
          const needsCredentials = some([
            contact.isTeacher,
            contact.isCteDirector,
            contact.isCteSpecialist,
            contact.isWorkBasedLearning,
          ]);
          if (needsCredentials) {
            return isStringValid(val);
          }
          return true;
        },
      },
    ],
  });
};
