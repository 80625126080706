import React from 'react';
import { startCase } from 'lodash';
import { NavAnchor } from 'types';
import Icon from 'components/Common/Icons';

type SideNavItemProps = {
  currentNavAnchor: string;
  onClick: Function;
  nav: NavAnchor;
  navTrayClosed: boolean;
  parent?: boolean;
  subNav?: boolean;
  hasLockedIcon?: boolean;
};

export const SideNavItem: React.FC<SideNavItemProps> = (props) => {
  const {
    currentNavAnchor,
    nav,
    navTrayClosed,
    onClick,
    parent,
    subNav,
    hasLockedIcon,
  } = props;

  const highlightAnchor = (anchor: string) => {
    const className = navTrayClosed
      ? 'side-nav__list-item u-flex-center'
      : 'side-nav__list-item';
    return matchCurrentStep(anchor)
      ? `${className} ${className}--highlight`
      : className;
  };

  const matchCurrentStep = (anchor: string) => {
    return anchor === currentNavAnchor;
  };

  const isCurrentStep = () => {
    if (nav && nav.anchor) {
      return matchCurrentStep(nav.anchor);
    }
  };

  const getIconClassName = () => {
    if (parent) {
      return 'side-nav__list-icon side-nav__list-icon--parent';
    }
    return nav && nav.status === 'warning'
      ? 'side-nav__list-icon u-icon-orange'
      : 'side-nav__list-icon';
  };

  const getIconName = () => {
    if (parent) {
      return 'chevronRight';
    }
    if (hasLockedIcon && hasLockedIcon) {
      return 'lock';
    }
    if (isCurrentStep()) {
      return 'rocket';
    }
    if (nav && nav.status === 'warning') {
      return 'crossCircle';
    }
    if (nav && nav.status === 'locked') {
      return 'lock';
    }
    return 'circleOpen';
  };

  const getListItemClass = () => {
    const baseClass = highlightAnchor(nav && nav.anchor);
    if (parent) {
      return `${baseClass} side-nav__list-item--parent`;
    }
    return subNav ? `${baseClass} side-nav__list-item--secondary` : baseClass;
  };

  const getTitle = () => {
    return nav ? startCase(nav && nav.displayName) : 'List item';
  };

  return (
    <li
      tabIndex={0}
      title={getTitle()}
      onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 && onClick()}
      onClick={() => onClick()}
      className={getListItemClass()}
    >
      <Icon name={getIconName()} className={getIconClassName()} />
      {!navTrayClosed && (
        <p className="side-nav__list-text">{nav && nav.displayName}</p>
      )}
    </li>
  );
};
