import React from 'react';
import Paper from 'components/Common/Paper';
import { FlexGroup, FlexRow } from 'layouts';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';
import { map } from 'lodash';

export const PostsecondaryCourses: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy } = props;

  return (
    <div className="u-flex">
      <FlexGroup>
        <Paper>
          <FlexRow>
            <FlexGroup>
              <h2>CTE Courses</h2>
              <h4 style={{ fontSize: '1.4rem' }}>
                *Not all courses are required for every Certificate
              </h4>
            </FlexGroup>
          </FlexRow>
          {map(programOfStudy.postsecondaryCourses, (item: string, index) => {
            return (
              <FlexGroup key={index}>
                <div style={{ lineHeight: '3.2rem' }} key={index}>
                  {item}
                </div>
              </FlexGroup>
            );
          })}
        </Paper>
      </FlexGroup>
      <div />
      <FlexGroup>
        <Paper>
          <FlexRow>
            <FlexGroup>
              <h2>General Education Courses</h2>
              <h4 style={{ fontSize: '1.4rem' }}>
                *Additional electives may be required for some Degrees
              </h4>
            </FlexGroup>
          </FlexRow>
          {map(
            programOfStudy.generalEducationCourses,
            (item: string, index: number) => {
              return (
                <FlexGroup key={index}>
                  <div
                    className="pos-course-item"
                    style={{ lineHeight: '3.2rem' }}
                    key={index}
                  >
                    {item}
                  </div>
                </FlexGroup>
              );
            }
          )}
        </Paper>
      </FlexGroup>
    </div>
  );
};
