import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, PerkinsGrantFilterState } from 'types';

const initialState: PerkinsGrantFilterState = {
  programLevelId: undefined,
  needsReview: undefined,
  status: undefined,
  substantiallySubmitted: undefined,
  needsFinanceReview: undefined,
};

export const setPerkinsGrantFilterValue = createAction(
  'setPerkinsGrantFilterValue'
);
export const resetPerkinsGrantFilterValue = createAction(
  'resetPerkinsGrantFilterValue'
);
export const clearPerkinsGrantFilters = createAction(
  'clearPerkinsGrantFilters'
);

export default handleActions<
  PerkinsGrantFilterState,
  KeyValueType<unknown> | string
>(
  {
    setPerkinsGrantFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetPerkinsGrantFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearPerkinsGrantFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
