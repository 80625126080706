import { FiscalYearStepSelection } from 'components/Grants/FiscalYearManagement/Dashboard/FiscalYearDashboard.component';
import { connect } from 'react-redux';
import { getRecords_GrantFiscalYearNav } from 'redux/nav/_selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { getCurrentFiscalYear } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const steps = getRecords_GrantFiscalYearNav(state).steps;
  const currentFiscalYear = getCurrentFiscalYear(state);
  return {
    currentFiscalYear,
    steps,
  };
};

const dispathProps = {
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispathProps
)(FiscalYearStepSelection);
