import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { AzureUploadParameters, Consortium, SpendingCategory } from 'types';
import { buildOnChange } from 'util/formHelpers';
import { useDispatch } from 'react-redux';
import { updateSpendingCategories } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { SpendingCategoryValidations } from 'validations/AnnualSpendingPlan/SpendingCategory.validations';
import { readConsortiumFundPoolEligibility } from 'services/grants/annualSpendingPlan.services';
import { useParams } from 'react-router';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { readConsortiaByFiscalYearId } from 'services/grants/consortium.services';
import { toast } from 'react-toastify';
import { compose, prop, safeGet } from 'util/objectUtility';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import ConsortiumSelectBox from 'components/Common/SelectBoxes/ConsortiumSelectBox';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import Tooltip from 'components/Common/Tooltip';
import WarningAlert from 'components/Common/WarningAlert';

type FundPoolingEditProps = {
  spendingCategory: SpendingCategory;
};

export const FundPoolingEditComponent: React.FC<FundPoolingEditProps> = (
  props
) => {
  const { spendingCategory } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);
  const { getError, getFieldValid, validate } = SpendingCategoryValidations();

  // --[ local state ]---------------------------------------------------------
  const [isFileOpen, setFileOpen] = useState<boolean>(false);
  const [loadingConsortia, setLoadingConsortia] = useState<boolean>(false);
  const [consortia, setConsortia] = useState<Consortium[]>([]);
  const [validConsortium, setValidConsortium] = useState<boolean | null>(null);
  const toggleModal = () => setFileOpen(!isFileOpen);

  // --[ component logic ]-----------------------------------------------------
  // get :: spendingCategory -> string -> spendingCategory[string]
  const get = safeGet(spendingCategory);

  const onChange = buildOnChange(
    updateSpendingCategories,
    dispatch,
    spendingCategory
  );

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|annual-spending-plans|${get(
      'annualSpendingPlanId'
    )}|${get('categoryId')}|fund-pool-agreement`,
  };

  const handleConsortiumSelect = (data: Partial<SpendingCategory>) => {
    const { strategyId } = spendingCategory;
    const { partnerConsortiumId } = data;
    setLoadingConsortia(true);
    readConsortiumFundPoolEligibility(
      grantFiscalYearId,
      partnerConsortiumId!,
      strategyId
    )
      .then((valid: boolean) => {
        if (!valid) {
          onChange({ partnerConsortiumId: -1 });
        } else {
          onChange(data);
        }
        setValidConsortium(valid);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Unable to load eligibility."
          />
        );
      })
      .finally(() => setLoadingConsortia(false));
  };

  const handlePathChange = (partnershipAgreementFilePath: string) => {
    if (!get('isPartnershipLead')) return;
    validate(
      'partnershipAgreementFilePath',
      partnershipAgreementFilePath,
      spendingCategory
    );
    return onChange({ partnershipAgreementFilePath });
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setLoadingConsortia(true);
    readConsortiaByFiscalYearId(grantFiscalYearId)
      .then((data: any) =>
        data.filter(
          (c: Consortium) => c.grantFiscalYearId !== grantFiscalYearId
        )
      )
      .then((data: any) => setConsortia(data))
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Unable to load Consortia."
          />
        );
      })
      .finally(() => setLoadingConsortia(false));
  }, [grantFiscalYearId]);

  // --[ display logic ]-------------------------------------------------------
  // getPartnershipContribution :: string -> string
  const getDollarAmount = (property: string) =>
    compose(insertDollarSign, formatMoney, prop(property))(spendingCategory);

  return (
    <React.Fragment>
      <Loading isActive={loadingConsortia} messageBefore="Loading Consortia" />
      <FlexRow>
        <FlexGroup>
          <Instruction title="Fund Pooling">
            <p>
              Secondary and Postsecondary Institutions are able to pool funds to
              expand CTE opportunities for learners. Institutions involved must
              have a formal agreement in place that is signed by both parties
              and details which Institution is acting as the Fiscal Agent for
              the pooled funds. You can only use Basic Funds to Fund Pool. Fund
              Pools are created by the institution that is the financial lead.
              If you are the lead, select the institution you are partnering
              with. Once selected, "Partnership Eligibility" will provide a
              validation that the institution selected is available to fund pool
              on the chosen strategy. Once created, the project will appear on
              the partnership's annual spending plan dashboard and they will be
              able to allocate funds directly.
            </p>
          </Instruction>
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <Toggle
          disabled={!get('isPartnershipLead')}
          name="isFundPool"
          noLabel={true}
          value={get('isFundPool')}
          onChange={onChange}
          description="Fund Pool Status"
        />
        {get('isFundPool') && (
          <Toggle
            disabled={true}
            name="isPartnershipLead"
            noLabel={true}
            value={get('isPartnershipLead')}
            onChange={onChange}
            description="Our Institution is the Financial Lead"
          />
        )}
      </FlexRow>
      {get('isFundPool') && (
        <React.Fragment>
          <FlexRow>
            <FlexGroup
              onBlur={() =>
                validate(
                  'partnerConsortiumId',
                  get('partnerConsortiumId'),
                  spendingCategory
                )
              }
            >
              <ConsortiumSelectBox
                disabled={!get('isPartnershipLead')}
                errorMessage={getError('partnerConsortiumId')}
                label="Partnership Institution"
                name="partnerConsortiumId"
                onChange={handleConsortiumSelect}
                placeholder="Select..."
                records={consortia}
                selection={get('partnerConsortiumId')}
                valid={getFieldValid('partnerConsortiumId')}
              />
            </FlexGroup>
            {get('isPartnershipLead') && (
              <FlexGroup>
                <div className="u-flex">
                  <p className="form__label">Partnership Eligibility</p>
                  <Tooltip
                    tooltip="If your selected partner is ineligible then they have already created a project plan with allocated funds that matches yours.  In order to fundpool, they will need to delete that plan from their project plans."
                    indicator={
                      <Icon
                        name="info"
                        className="spending-category__tooltip"
                      />
                    }
                  />
                </div>
                {validConsortium && (
                  <div className="u-flex input-display">
                    <Icon
                      name="circleChecked"
                      className="u-icon-base-size u-margin-right u-fill-green"
                    />
                    <p style={{ fontSize: '1.8rem' }}>
                      This Institution can fund pool on this project.
                    </p>
                  </div>
                )}
                {validConsortium === false && (
                  <div className="u-flex input-display">
                    <WarningAlert iconPosition="left">
                      This Institution cannot fund pool on this project
                    </WarningAlert>
                  </div>
                )}
                {validConsortium === null && (
                  <div className="u-flex input-display">
                    <p>{EMPTY_PLACEHOLDER}</p>
                  </div>
                )}
              </FlexGroup>
            )}
          </FlexRow>
          <FlexRow>
            <Input
              disabled={true}
              name="allocatedFunds"
              label="Our Institution's Budgeted Contribution"
              value={getDollarAmount('allocatedFunds')}
            />
            <Input
              disabled={true}
              name="partnershipInstituteContribution"
              label="Partnership Institution's Budgeted Contribution"
              value={getDollarAmount('partnershipInstituteContribution')}
            />
          </FlexRow>
          <div
            className={!get('isPartnershipLead') ? 'override-read-only' : ''}
          >
            <FlexRow>
              <FlexGroup
                onBlur={() => {
                  validate(
                    'partnershipAgreementFilePath',
                    get('partnershipAgreementFilePath'),
                    spendingCategory
                  );
                }}
              >
                <p className="form__label">Add the partnership agreement</p>
                {get('partnershipAgreementFilePath') ? (
                  <AzureDownloader
                    onClose={() => handlePathChange('')}
                    uploadParams={uploadParams}
                    filepath={get('partnershipAgreementFilePath')}
                  />
                ) : (
                  <Button
                    text="+ Upload File"
                    className="form__btn--add u-flex-self-start"
                    onClick={toggleModal}
                  />
                )}
                <AzureUploader
                  onClose={toggleModal}
                  open={isFileOpen}
                  uploadParams={uploadParams}
                  onConfirm={handlePathChange}
                />
                {!getFieldValid('partnershipAgreementFilePath') && (
                  <p className="u-color-red">
                    {getError('partnershipAgreementFilePath')}
                  </p>
                )}
              </FlexGroup>
            </FlexRow>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
