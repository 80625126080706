import { get } from 'lodash';
import { APPLICATION_KEY } from 'redux/keys';

export const getLoadingState = (state: any) => {
  return get(state, [APPLICATION_KEY, 'loadingState'], false);
};

export const getShowDisabledUsers = (state: any) => {
  return get(state, [APPLICATION_KEY, 'showDisabledUsers'], false);
};

export const getActiveProgramId = (state: any) => {
  return get(state, [APPLICATION_KEY, 'programInstanceId'], -1);
};

export const getProgramNav = (state: any) => {
  return get(state, [APPLICATION_KEY, 'programNav'], 'general');
};

export const getNavTrayClosed = (state: any) => {
  return get(state, [APPLICATION_KEY, 'navTrayClosed'], false);
};

export const getWorkflowTrayClosed = (state: any) => {
  return get(state, [APPLICATION_KEY, 'workflowTrayClosed'], false);
};

export const getCurrentNavAnchor = (state: any) => {
  return get(state, [APPLICATION_KEY, 'currentNavAnchor'], '');
};
