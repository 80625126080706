export const PROGRAM_LEVELS: any = {
  SECONDARY: {
    id: 1,
    name: 'Secondary',
  },
  POSTSECONDARY: {
    id: 2,
    name: 'Postsecondary',
  },
  MIDDLE_SCHOOL: {
    id: 5,
    name: 'Middle School',
  },
};

export enum PROGRAM_LEVEL_ENUM {
  'Secondary' = 1,
  'Postsecondary' = 2,
  'Middle School' = 5,
}
