import { useValidation } from 'de-formed-validations';
import { every } from 'lodash';
import { PathwayCourse } from 'types';
import { isStringValid } from 'util/validation.utility';

export const PathwayCourseValidation = () => {
  return useValidation<PathwayCourse>({
    courseName: [
      {
        errorMessage:
          'Course title is required, and has a max of 100 characters',
        validation: (val: string, _) => {
          return every([isStringValid(val), val.length <= 100]);
        },
      },
    ],
    courseLevel: [
      {
        errorMessage: 'Course level is required.',
        validation: (val: number, course: PathwayCourse) => {
          return course.isMiddleSchool || !!(val && val > 0 && val !== 255);
        },
      },
    ],
    contactHours: [
      {
        errorMessage: 'Contact hours is required and must be greater than 0.',
        validation: (val: number, _) => {
          return val > 0;
        },
      },
    ],
    courseDescription: [
      {
        errorMessage: 'Course description is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    academicCredit: [
      {
        errorMessage: 'Academic Credit description is required.',
        validation: (academicCredit: string, course: PathwayCourse) => {
          if (course.isAcademicCredit) {
            return isStringValid(academicCredit);
          }
          return true;
        },
      },
    ],
    articulationSchoolId: [
      {
        errorMessage: 'Institution is required.',
        validation: (schoolId: number, course: PathwayCourse) => {
          if (course.isApprovedForArticulationAgreement) {
            return schoolId > 0;
          }
          return true;
        },
      },
    ],
    articulationContactId: [
      {
        errorMessage: 'Articulation contact is required.',
        validation: (contactId: number, course: PathwayCourse) => {
          if (course.isApprovedForArticulationAgreement) {
            return contactId > 0;
          }
          return true;
        },
      },
    ],
    articulationFilePath: [
      {
        errorMessage: 'Documentation is required.',
        validation: (articulationFilePath: string, course: PathwayCourse) => {
          if (course.isApprovedForArticulationAgreement) {
            return isStringValid(articulationFilePath);
          }
          return true;
        },
      },
    ],
    concurrentCourse: [
      {
        errorMessage: 'Concurrent Course is required.',
        validation: (concurrentCourse: string, course: PathwayCourse) => {
          if (course.isConcurrentEnrollment) {
            return isStringValid(concurrentCourse);
          }
          return true;
        },
      },
    ],
    concurrentSchoolId: [
      {
        errorMessage: 'Institution is required.',
        validation: (concurrentSchoolId: number, course: PathwayCourse) => {
          if (course.isConcurrentEnrollment) {
            return concurrentSchoolId > 0;
          }
          return true;
        },
      },
    ],
  });
};
