import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { UniversalContactFilterState } from 'types';
import Button from 'components/Common/FormElements/Button';
import DistrictSelectBox from 'components/Common/SelectBoxes/DistrictSelectBox';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Paper from 'components/Common/Paper';
import ToggleButton from 'components/Common/FormElements/ToggleButton';

type UniversalContactFilterComponentProps = {
  filter: UniversalContactFilterState;
  setUniversalContactFilterValue: Function;
  resetUniversalContactFilterValue: Function;
  clearUniversalContactFilters: Function;
};

export const UniversalContactFilterComponent: React.FC<UniversalContactFilterComponentProps> = (
  props
) => {
  const {
    filter,
    setUniversalContactFilterValue,
    clearUniversalContactFilters,
    resetUniversalContactFilterValue,
  } = props;

  const updateFilter = (data: { [key: string]: unknown }, name: string, value: number) => {
    if (value) {
      setUniversalContactFilterValue(data);
    } else {
      resetUniversalContactFilterValue(name);
    }
  };

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Teachers"
          value={filter.isTeacher === true}
          onChange={() => {
            setUniversalContactFilterValue({
              isTeacher: filter.isTeacher ? undefined : true,
            });
          }}
        />
        <ToggleButton
          text="Active"
          value={filter.isActive === true}
          onChange={() => {
            setUniversalContactFilterValue({
              isActive: filter.isActive ? undefined : true,
            });
          }}
        />
      </div>
      <FlexRow>
        <DistrictSelectBox
          clearable={true}
          shouldReset={true}
          onChange={updateFilter}
          selection={filter.districtId > 0 ? filter.districtId : undefined}
        />
      </FlexRow>
      <FlexRow>
        <InstituteSelectBox
          clearable={true}
          shouldReset={true}
          selection={filter.instituteId > 0 ? filter.instituteId : undefined}
          onChange={(data, name, value) => {
            updateFilter({ instituteId: value }, 'instituteId', value);
          }}
        />
      </FlexRow>
      <FlexGroup>
        <Button
          onClick={clearUniversalContactFilters}
          className="button--outline"
          text="Reset Filters"
        />
      </FlexGroup>
    </Paper>
  );
};
