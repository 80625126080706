import React from 'react';
import { downloadFileFromAzure } from 'services/azure/azure.services';
import { AzureUploadParameters } from 'types';
import { last } from 'lodash';
import { formatFile } from 'util/formHelpers';
import { truncateString } from 'util/global';
import Button from 'components/Common/FormElements/Button';
import Chip from 'components/Common/FormElements/Chip';
import Truncate from 'react-truncate';

type AzureDownloaderProps = {
  customTruncate?: number;
  editable?: boolean;
  filepath: string;
  onClose: Function;
  uploadParams: AzureUploadParameters;
  allowReadOnlyDownloads?: boolean;
};

export const AzureDownloader: React.FC<AzureDownloaderProps> = (props) => {
  const {
    customTruncate,
    editable,
    filepath,
    onClose,
    uploadParams,
    allowReadOnlyDownloads = true,
  } = props;

  const getFilePathName = () => {
    const path = formatFile(last(filepath.split('|')));
    return customTruncate ? truncateString(path, customTruncate) : path;
  };

  const getClassName = () => {
    return allowReadOnlyDownloads
      ? 'override-read-only u-sec-ro--override'
      : 'override-read-only';
  };

  return (
    <div className={getClassName()}>
      <Chip
        editable={editable}
        name={
          <Button
            style={{ height: '100%' }}
            className="form__btn--add--chip"
            onClick={() => downloadFileFromAzure(uploadParams, filepath)}
            text={last(filepath.split('|'))}
            element={
              customTruncate ? (
                <span className="form__btn--truncate">{getFilePathName()}</span>
              ) : (
                <Truncate lines={1} trimWhitespace={true}>
                  &nbsp;{getFilePathName()}
                </Truncate>
              )
            }
          />
        }
        id={filepath}
        onClose={onClose}
      />
    </div>
  );
};
