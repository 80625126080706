import { FILTERS_KEY, FILTERS_KEY_EMAIL_RECIPIENT } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ReduxFilter, EmailRecipientFilterState } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const EMAIL_RECIPIENT_FILTER_PATH = [
  FILTERS_KEY,
  FILTERS_KEY_EMAIL_RECIPIENT,
];

export const getRecords_EmailRecipientFilterState = (
  state: any
): EmailRecipientFilterState => {
  return get(state, EMAIL_RECIPIENT_FILTER_PATH);
};

export const getEmailRecipientFilters = (state: any) => {
  const filterValues: KeyValueType<
    unknown
  > = (getRecords_EmailRecipientFilterState(state) as unknown) as KeyValueType<
    unknown
  >;

  const activeFilters: any = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (filterName): ReduxFilter<EmailRecipientFilterState> => {
      if (filterName === 'isConsortium' && filterValues[filterName] === false) {
        return {
          filter: {
            property: 'consortiumName',
            values: [null],
          },
          operator: 'and',
        };
      } else if (
        filterName === 'isConsortium' &&
        filterValues[filterName] === true
      ) {
        return {
          filter: {
            property: 'consortiumName',
            values:
              filterValues[filterName] !== undefined
                ? filterValues[filterName]
                : '',
            comparator: (data: string) => {
              return data !== null;
            },
          },
          operator: 'and',
        };
      }
      return {
        filter: {
          property: filterName,
          values:
            filterValues[filterName] !== undefined
              ? filterValues[filterName]
              : '',
        },
        operator: 'and',
      };
    }
  );

  return filters;
};
