import React from 'react';
import { map } from 'lodash';
import { AzureUploadParameters, QuestionGroup } from 'types';
import { QuestionsGroupLayout } from 'components/Common/Questions/QuestionsGroupLayout.component';
import { FlexRow } from 'layouts';

type ParentQuestionProps = {
  questionGroup: QuestionGroup[];
  title: string;
  flat?: boolean;
  onChange: Function;
  uploadParams: AzureUploadParameters;
};

export const ParentQuestionGroup: React.FC<ParentQuestionProps> = (props) => {
  const { flat, onChange, questionGroup, title, uploadParams } = props;

  const generateData = () => {
    if (questionGroup && questionGroup.length > 0) {
      return map(questionGroup, (group: QuestionGroup, index: number) => {
        return (
          <QuestionsGroupLayout
            key={index}
            group={group}
            onChange={onChange}
            flat={flat}
            uploadParams={uploadParams}
          />
        );
      });
    } else {
      return (
        <FlexRow>
          <p>There was no data was returned, please try again.</p>
        </FlexRow>
      );
    }
  };
  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">{title}</h2>
      </FlexRow>
      {generateData()}
    </React.Fragment>
  );
};
