import React, { useState } from 'react';
import { deburr, escapeRegExp, isNil, map } from 'lodash';
import { DropdownItem, ItemFilter } from 'types';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { equals, pipe } from 'lodash/fp';
import { firstMatch, prop } from 'util/objectUtility';

type ReportParametersSelectBoxComponentProps = {
  dropdownItems: DropdownItem[];
  disabled?: boolean;
  filter?: ItemFilter;
  label?: string;
  onChange: Function;
  readOnly?: boolean;
};

export const ReportParametersSelectBoxComponent: React.FC<ReportParametersSelectBoxComponentProps> = (
  props
) => {
  const { dropdownItems, disabled, label, onChange, readOnly = false } = props;
  const [suggestions, setSuggestions] = useState<DropdownItem[]>([]);
  const [result, setResult] = useState('');

  // getSuggestedValues :: string -> DropdownItem[]
  const getSuggestedValues = (value: string) => {
    const cleanValue = escapeRegExp(deburr(value).toLowerCase());
    if (cleanValue) {
      const regex = new RegExp(`${cleanValue}`, 'i');
      return dropdownItems.filter((item) => regex.test(item.label));
    }
    return [];
  };

  // handleSuggestionsFetchRequested :: SuggestionsFetchRequestedParams -> void
  const handleSuggestionsFetchRequested = firstMatch([
    [
      pipe(prop('value'), equals(result)),
      pipe(prop('value'), getSuggestedValues, setSuggestions),
    ],
    [() => true, () => null],
  ]);

  // handleChange :: (event, object) -> void
  const handleChange = (_: any, { newValue }: any) => {
    if (!readOnly) {
      setResult(newValue);
      setSuggestions(getSuggestedValues(newValue));
    }
  };

  // renderSuggestion :: (DropdownItem, object) -> JSX
  const renderSuggestion = (suggestion: DropdownItem, { query }: any) => {
    const matches = AutosuggestHighlightMatch(suggestion.label, query);
    const parts = AutosuggestHighlightParse(suggestion.label, matches);

    return (
      <React.Fragment>
        <div className="u-flex">
          <div className="u-flex-grow-1">
            {map(parts, (part, index) => {
              const className = part.highlight
                ? 'react-autosuggest__suggestion--match'
                : undefined;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="form__group">
      <label htmlFor="programId" className="form__label">
        {label ? label : 'Search for School / Program'}
      </label>
      <Autosuggest
        getSuggestionValue={(suggestion: DropdownItem) => {
          if (isNil(suggestion)) return '';
          onChange && onChange(suggestion);
          return suggestion.label;
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        renderSuggestion={renderSuggestion}
        inputProps={{
          value: result,
          onChange: handleChange,
          id: 'programId',
          disabled: disabled,
          className: `form__input`,
        }}
      />
    </div>
  );
};
