import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { SideNavData } from 'types/ui/SideNavData.types';
import { readGrantAnnualSpendingPlanNav } from 'services/nav/grantannualspendingplan.services';

const initialState = baseInitialState;

export const loadGrantSpendingPlanNav = createActionThunk(
  'loadGrantSpendingPlanNav',
  (GrantFiscalYearId: number) => {
    return readGrantAnnualSpendingPlanNav(GrantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, SideNavData>(
  {
    ...baseLoadActionBuilder<SideNavData>(loadGrantSpendingPlanNav),
  },
  initialState
);
