import {
  getWithAuthOptions,
  toJSON,
  catchError,
  getFormattedUrl,
  postWithAuthOptions,
} from 'services';
import { StudentEnrollmentData } from 'types';

const STUDENT_ENROLLMENT_ENDPOINT = getFormattedUrl([
  'api',
  'StudentEnrollment',
]);
const FOLLOWUP_ENROLLMENT_ENDPOINT = getFormattedUrl([
  'api',
  'StudentEnrollment',
  'Followup',
]);

export const readStudentEnrollmentData = async (): Promise<
  StudentEnrollmentData
> => {
  return fetch(STUDENT_ENROLLMENT_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('StudentEnrollment', err, 'readStudentEnrollmentData');
    });
};

export const readStudentFollowUpData = async (): Promise<
  StudentEnrollmentData
> => {
  return fetch(FOLLOWUP_ENROLLMENT_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('StudentEnrollment', err, 'readStudentFollowUpData');
    });
};

export const setEnrollmentComplete = async () => {
  return fetch(STUDENT_ENROLLMENT_ENDPOINT, { ...postWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('StudentEnrollment', err, 'setEnrollmentComplete');
    });
};

export const setFollowupComplete = async () => {
  return fetch(
    getFormattedUrl(['api', 'StudentEnrollment', 'CompleteFollowup']),
    { ...postWithAuthOptions() }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('StudentEnrollment', err, 'setFollowupComplete');
    });
};
