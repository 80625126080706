import { PERMISSIONS_KEY, USER_PERMISSIONS_KEY } from 'redux/keys';
import { filter, map, includes, flatMap, find, some } from 'lodash';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { UserPermission } from 'types';
import { isArray } from 'util';

const path: [string, string] = [PERMISSIONS_KEY, USER_PERMISSIONS_KEY];

/* Default Selectors */
export const getIsDataFetching_UserPermissions = (state: any): boolean => {
  return getIsDataFetching(state, path);
};

export const getLastUpdated_UserPermissions = (state: any): number => {
  return getLastUpdated(state, path);
};

export const getRecords_UserPermissions = (state: any): UserPermission[] => {
  return getRecords<UserPermission>(state, path);
};

export const shouldRefreshReduxState_UserPermissions = (
  state: any
): boolean => {
  return shouldRefreshReduxState(state, path);
};

/* End Default Selectors */

export const getUserPermission = (
  state: any,
  components: string | string[]
): string => {
  if (getIsDataFetching_UserPermissions(state)) {
    return 'loading';
  }

  const comps = isArray(components) ? components : [components];

  if (includes(comps, 'any')) {
    return 'addedit';
  }

  const userPermissions = getRecords_UserPermissions(state);

  if (userPermissions.length <= 0) {
    return 'none';
  }

  const applicablePerms: UserPermission[] = filter(userPermissions, (perm) => {
    return includes(
      map(comps, (item) => {
        return item.toLowerCase();
      }),
      perm.permissionComponentName.toLowerCase()
    );
  });

  const isAdmin = () => {
    return some(
      map(userPermissions, (permission: UserPermission) => {
        return (
          permission.permissionComponentName.toLowerCase() === 'allaccess' &&
          permission.permissionTypeName.toLowerCase() === 'full'
        );
      })
    );
  };

  if (isAdmin()) {
    return 'full';
  }

  const permissionWeights: { [key: string]: number } = {
    full: 100,
    addedit: 50,
    readonly: 10,
  };

  const shortList = flatMap(applicablePerms, (perm) => {
    return {
      permissionType: perm.permissionTypeName,
      value: permissionWeights[perm.permissionTypeName],
    };
  });

  const res = Math.max.apply(
    Math,
    map(shortList, (item) => {
      return item.value;
    })
  );

  const output = find(shortList, (item) => {
    return item.value === res;
  });

  return output ? output.permissionType : 'none';
};
