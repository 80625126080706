import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ConsortiumManagementDashboard from 'components/Grants/ConsortiumManagement/Dashboard';

type ConsortiumViewPageProps = {
  consortiumId: number;
};

export const ConsortiumDashboardPage: React.FC<ConsortiumViewPageProps> = (
  props
) => {
  return (
    <BaseLayout>
      <ConsortiumManagementDashboard {...props} />
    </BaseLayout>
  );
};
