import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { AddressableElement } from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { prop } from 'util/objectUtility';
import Table from 'components/Common/Table';

type AddressableElementsProps = {
  addressableElements: AddressableElement[];
};

export const AddressableElements: React.FC<AddressableElementsProps> = (
  props
) => {
  const { addressableElements } = props;

  const dataTableHeaders: TableColumnProps[] = [
    {
      name: 'Addressable Elements',
      cell: prop('addressableElement'),
      selector: 'addressableElement',
      sortable: false,
      style: {
        width: '35rem',
      },
    },
    {
      name: 'Region(s)',
      selector: 'regions',
      sortable: false,
      cell: (row: AddressableElement) => {
        return `${row.regions} `;
      },
      style: {
        width: '12rem',
      },
    },
    {
      name: 'Regional Level Strategies (Listed In Priority Order)',
      selector: 'strategies',
      sortable: false,
      cell: (row: AddressableElement) => {
        return <p style={{ padding: '1.5rem 0' }}>{row.strategies.strategy}</p>;
      },
    },
  ];

  return (
    <FlexRow>
      <FlexGroup>
        <Table data={addressableElements} columns={dataTableHeaders} />
      </FlexGroup>
    </FlexRow>
  );
};
