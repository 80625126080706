import { CTA_KEY, CTA_DISTRICT_SALARY_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTADistrictSalary, emptyCTADistrictSalary } from 'types';
import { defaultTo } from 'ramda';

export const CTA_DISTRICT_SALARY_PATH = [CTA_KEY, CTA_DISTRICT_SALARY_KEY];

export const getIsDataFetching_CTADistrictSalary = (state: any): boolean => {
  return getIsDataFetching(state, CTA_DISTRICT_SALARY_PATH);
};

export const getRecords_CTADistrictSalary = (
  state: unknown
): CTADistrictSalary => {
  const data: CTADistrictSalary[] = getRecords(state, CTA_DISTRICT_SALARY_PATH);
  return defaultTo(emptyCTADistrictSalary(), data[0]);
};
