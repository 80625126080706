import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { ConsortiumFilterState } from 'types';
import Button from 'components/Common/FormElements/Button';
import Paper from 'components/Common/Paper';
import ToggleButton from 'components/Common/FormElements/ToggleButton';

type ConsortiaFilterComponentProps = {
  filter: ConsortiumFilterState;
  setConsortiaFilterValue: Function;
  resetConsortiaFilterValue: Function;
  clearConsortiaFilters: Function;
};

export const ConsortiaFilterComponent: React.FC<
  ConsortiaFilterComponentProps
> = (props) => {
  const { filter, setConsortiaFilterValue, clearConsortiaFilters } = props;

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <div className="u-flex-wrap u-flex-between">
        <ToggleButton
          text="Active"
          value={filter.status === true}
          onChange={() => {
            setConsortiaFilterValue({
              status: filter.status ? undefined : true,
            });
          }}
        />
        <ToggleButton
          text="Inactive"
          value={filter.status === false}
          onChange={() => {
            setConsortiaFilterValue({
              status: filter.status === false ? undefined : false,
            });
          }}
        />
      </div>
      <FlexGroup>
        <Button
          onClick={clearConsortiaFilters}
          className="button--outline"
          text="Reset Filters"
        />
      </FlexGroup>
    </Paper>
  );
};
