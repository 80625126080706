import { createAction, handleActions } from 'redux-actions';
import {
  readUser,
  updateUserPassword,
  readDBUser,
} from 'services/universalcontacts/universalcontact.services';
import { createActionThunk } from 'redux-thunk-actions';
import {
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/baseActionHandler';
import { logoutUser } from 'util/authorization';
import { UniversalContact } from 'types';

const initialState = {
  isUserAuthenticated: false,
  isFetching: false,
  lastUpdate: 0,
  records: [],
};

export const updateLoggedinUser = createActionThunk(
  'updateLoggedinUser',
  (universalContactId: number) => {
    return readDBUser(universalContactId);
  }
);

export const loadUser = createActionThunk('loadUser', (aadB2cId: string) => {
  return readUser(aadB2cId);
});

export const logUserOut = createAction('logUserOut', () => {
  logoutUser();
});

export const updateContactPassword = createActionThunk(
  'updateContactPassword',
  async (user: UniversalContact, password: string) => {
    if (password && user) {
      return updateUserPassword(user, password);
    }
  }
); // end updateContactPassword

export default handleActions(
  {
    [updateLoggedinUser.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [updateLoggedinUser.SUCCEEDED]: (state, { payload }) => {
      return {
        ...actionUpdateRecords(state, payload),
      };
    } /* End action */,
    [updateLoggedinUser.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,

    [loadUser.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [loadUser.SUCCEEDED]: (state, { payload }) => {
      return {
        ...actionUpdateRecords(state, payload),
        isUserAuthenticated: true,
      };
    } /* End action */,
    [loadUser.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,

    logUserOut: () => {
      return initialState;
    } /* End action */,

    [updateContactPassword.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [updateContactPassword.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
