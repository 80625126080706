import { GRANTS_KEY, GRANTS_PARTICIPANT_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import {
  GrantParticipationMember,
  emptyGrantParticipationMember,
} from 'types/grant/GrantParticipationMember';
import { getGrantParticipantFilters } from 'redux/filters/_selectors';
import { findObject } from 'util/arrayUtility';
import { defaultTo } from 'lodash';

export const GRANTS_PARTICIPANT_PATH: string[] = [
  GRANTS_KEY,
  GRANTS_PARTICIPANT_KEY,
];

export const getIsFetching_GrantParticipationMember = (state: any): boolean => {
  return getIsDataFetching(state, GRANTS_PARTICIPANT_PATH);
};

export const shouldRefreshReduxState_GrantParticipationMember = (
  state: any
): boolean => {
  return shouldRefreshReduxState<GrantParticipationMember>(
    state,
    GRANTS_PARTICIPANT_PATH
  );
};

export const getRecords_GrantParticipationMember = (
  state: unknown
): GrantParticipationMember[] => {
  return getRecords(state, GRANTS_PARTICIPANT_PATH);
};

export const getAdvancedFilteredRecords_GrantParticipationMember = (
  state: any
): GrantParticipationMember[] => {
  const filters = getGrantParticipantFilters(state);
  const records = getAdvancedFilteredRecords<GrantParticipationMember>(
    state,
    GRANTS_PARTICIPANT_PATH,
    filters
  );
  return records;
};

export const getParticipantByProp = (state: any, prop: object) => {
  const allParticipants = getRecords_GrantParticipationMember(state);
  return defaultTo(
    findObject(prop, allParticipants),
    emptyGrantParticipationMember()
  );
};
