import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import SubmissionSuccess from 'components/Grants/IntentToParticipate/SubmissionSuccess';

export const IntentSuccess: React.FC = (props) => {
  return (
    <BaseLayout>
      <SubmissionSuccess />
    </BaseLayout>
  );
};
