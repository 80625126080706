import React, { useEffect } from 'react';
import { CTACourse, CTACourseTerm } from 'types';
import { safeGet, mergeObjects } from 'util/objectUtility';
import { FlexGroup, FlexRow } from 'layouts';
import { upsertInPlace } from 'util/arrayUtility';
import { CTACourseTermForm } from './CTACourseTerm.form';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import Button from 'components/Common/FormElements/Button';

interface EditCTACourseProps {
  ctaCourse: CTACourse;
  deleteCourse: Function;
  isHosting?: boolean;
  isInstructional?: boolean;
  onChange: Function;
  submissionFailed: boolean;
}
export const EditCTACourse: React.FC<EditCTACourseProps> = ({
  ctaCourse,
  deleteCourse,
  isHosting = false,
  isInstructional = false,
  onChange,
  submissionFailed,
}) => {
  // -- dependencies --
  const {
    getError,
    isValid,
    validateAll,
    validateIfTrue,
  } = CTACourseValidation();

  // -- component logic --
  const get = safeGet(ctaCourse);
  const handleChange = (term: CTACourseTerm) => (
    data: Partial<CTACourseTerm>
  ) => {
    const updated = mergeObjects(term, data);
    const upserted = upsertInPlace(get('terms'), updated, 'ctaCourseTermId');
    validateIfTrue('terms', upserted, {
      ...ctaCourse,
      isCte: isInstructional,
      isHosting,
      isInstructional,
    });
    onChange(upserted);
  };

  // -- lifecyle --
  useEffect(() => {
    submissionFailed &&
      validateAll({
        ...ctaCourse,
        isCte: isInstructional,
        isHosting,
        isInstructional,
      });
  }, [submissionFailed, ctaCourse]); // eslint-disable-line

  // -- display logic --
  const getBoxClassName = () => {
    return get('partnerUpload')
      ? 'cta-course__box cta-course__box--tan'
      : 'cta-course__box cta-course__box--blue';
  };

  const rowStyle = {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
  };

  const getTermsClassName = () => {
    return isInstructional
      ? 'cta-course__terms cta-course__terms--instructional'
      : 'cta-course__terms';
  };

  return (
    <>
      <div style={rowStyle}>
        <div className="cta-course__box__container">
          <p className="form__label">&nbsp;</p>
          <p className="form__label">School and Program</p>
          <div className={getBoxClassName()}>
            <p>{get('schoolName')}</p>
            <p>{get('programName')}</p>
          </div>
        </div>
        <div className="cta-course__box__container">
          <p className="form__label">&nbsp;</p>
          <p className="form__label">Course</p>
          <div className={getBoxClassName()}>
            <p>{get('cteCourseName')}</p>
            <p>CO: {get('coCourseName')}</p>
          </div>
        </div>
        <div style={rowStyle}>
          <FlexGroup>
            <div className={getTermsClassName()}>
              {get('terms').map((term: CTACourseTerm, index: number) => {
                return (
                  <CTACourseTermForm
                    key={index}
                    index={index}
                    isHosting={isHosting}
                    isInstructional={isInstructional}
                    onChange={handleChange}
                    term={term}
                    valid={isValid}
                  />
                );
              })}
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <Button
                className="form__btn--add"
                text="Delete Course"
                onClick={() => deleteCourse(get('ctaCourseId'))}
              />
            </div>
          </FlexGroup>
        </div>
      </div>
      <FlexRow>
        <div className="cta-course__box__container" />
        <div className="cta-course__box__container" />
        {getError('terms') && <p className="u-error">{getError('terms')}</p>}
      </FlexRow>
    </>
  );
};
