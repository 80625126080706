import { combineReducers } from 'redux';
import {
  FILTERS_KEY_PROGRAMS,
  FILTERS_KEY_UNIVERSALCONTACTS,
  FILTERS_KEY_CONSORTIA,
  FILTERS_KEY_GRANT_PARTICIPANT,
  FILTERS_KEY_PERKINS_GRANT,
  FILTERS_KEY_EMAIL_RECIPIENT,
  FILTERS_KEY_SUBMISSION_MANAGEMENT,
  FILTERS_KEY_SNAPSHOT_STUDENT,
  FILTERS_KEY_CTA,
  FILTERS_KEY_REPORTING,
} from 'redux/keys';
import programfilter from 'redux/filters/programfilter';
import universalContactFilter from 'redux/filters/universalcontactfilter';
import grantParticipantfilter from './grantParticipantfilter';
import consortiafilter from './consortiafilter';
import perkinsgranstfilter from './perkinsgrantsfilter';
import emailrecipientfilter from './emailrecipientfilter';
import submissionmanagementfilters from './submissionmanagementfilters';
import snapshotstudentfilter from './snapshotstudentfilter';
import ctadashboardfilter from './ctadashboardfilter';
import reportingfilter from './reportingfilter';

export default combineReducers({
  [FILTERS_KEY_PROGRAMS]: programfilter,
  [FILTERS_KEY_UNIVERSALCONTACTS]: universalContactFilter,
  [FILTERS_KEY_CONSORTIA]: consortiafilter,
  [FILTERS_KEY_GRANT_PARTICIPANT]: grantParticipantfilter,
  [FILTERS_KEY_PERKINS_GRANT]: perkinsgranstfilter,
  [FILTERS_KEY_EMAIL_RECIPIENT]: emailrecipientfilter,
  [FILTERS_KEY_SUBMISSION_MANAGEMENT]: submissionmanagementfilters,
  [FILTERS_KEY_SNAPSHOT_STUDENT]: snapshotstudentfilter,
  [FILTERS_KEY_CTA]: ctadashboardfilter,
  [FILTERS_KEY_REPORTING]: reportingfilter,
});
