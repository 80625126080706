import { COMMON_KEY, COMMON_CTA_FISCAL_YEAR_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { FiscalYear, emptyFiscalYear } from 'types';
import { find, defaultTo } from 'lodash';

export const CTA_FISCAL_YEAR_PATH = [COMMON_KEY, COMMON_CTA_FISCAL_YEAR_KEY];

export const getIsDataFetching_CTAFiscalYear = (state: any): boolean => {
  return getIsDataFetching(state, CTA_FISCAL_YEAR_PATH);
};

export const getLastUpdated_CTAFiscalYear = (state: any): number => {
  return getLastUpdated(state, CTA_FISCAL_YEAR_PATH);
};

export const getRecords_CTAFiscalYear = (state: any): FiscalYear[] => {
  return getRecords<FiscalYear>(state, CTA_FISCAL_YEAR_PATH);
};

export const getCurrentCTAFiscalYear = (state: any): FiscalYear => {
  const allYears = getRecords<FiscalYear>(state, CTA_FISCAL_YEAR_PATH);
  const currentYear = find(allYears, (item) => {
    return item.isCurrent;
  });
  return defaultTo(currentYear, emptyFiscalYear());
};

export const shouldRefreshReduxState_CTAFiscalYear = (state: any): boolean => {
  return shouldRefreshReduxState<FiscalYear>(state, CTA_FISCAL_YEAR_PATH);
};
