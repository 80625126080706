import { connect } from 'react-redux';
import ApplicationState from 'types/applicationstate.types';
import { ReportPage } from './ReportPage.page';
import { loadUniversalContacts } from 'redux/universalcontacts/universalcontacts';
import { getLoggedinUser } from 'redux/user/selectors';

export const mapStateToProps = (state: ApplicationState) => {
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
  };
};

const dispatchProps = { loadUniversalContacts };

export default connect(
  mapStateToProps,
  dispatchProps
)(ReportPage);
