import { connect } from 'react-redux';
import { find } from 'lodash';
import {
  getRecords_CareerPathways,
  CAREER_PATHWAYS_PATH,
  getIsDataFetching_CareerPathways,
} from 'redux/common/_selectors';
import { CareerPathway, OptionMap, SelectWrapper, OnChangeData } from 'types';
import { getRecordsUtility } from 'util/formHelpers';
import SelectBox from 'components/Common/FormElements/SelectBox';

const mapStateToProps = (state: any, ownProps: SelectWrapper) => {
  const {
    selection,
    filter,
    label,
    getFullObject,
    onChange: handleSelect,
    records: baseRecords,
  } = ownProps;

  const isLoading = getIsDataFetching_CareerPathways(state);
  const records = getRecordsUtility<CareerPathway>(
    state,
    CAREER_PATHWAYS_PATH,
    getRecords_CareerPathways,
    filter,
    baseRecords
  );

  const onChange = (
    data: OnChangeData<number>,
    key: keyof CareerPathway,
    value: number
  ) => {
    const fullObject = find(records, data);
    return fullObject && getFullObject
      ? getFullObject(fullObject)
      : handleSelect
      ? handleSelect(data, key, value)
      : undefined;
  };

  const optionMap: OptionMap<CareerPathway> = {
    key: 'careerPathwayId',
    label: 'careerPathwayName',
    value: 'careerPathwayId',
  };

  return {
    isLoading,
    label: label ? label : 'Career Pathway',
    name: 'careerPathwayId',
    onChange,
    optionMap,
    records,
    selection,
  };
};

export default connect(mapStateToProps)(SelectBox);
