import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import { AnnualSpendingPlanEdit } from 'components/Grants/Applications/AnnualSpendingPlan/AnnualSpendingPlanConnect';

export const GrantAnnualSpendingPlanPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <AnnualSpendingPlanEdit />
      </GrantsLayout>
    </BaseLayout>
  );
};
