import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { FlexRow, FlexGroup } from 'layouts';
import { CollapsibleListItem } from 'types/common/CollapsibleListItems';
import { SignatureWithAssertionComponent } from 'components/Common/SignatureWithAssertion/SignatureWithAssertion.component';
import { DistrictSchoolSignature } from 'types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isApplicaionIdValid } from 'util/global';
import { loadCivilRightSignatures } from 'redux/grants/awardsignatures/civilrightsignatures';
import { upsertInPlace } from 'util/arrayUtility';
import CollapsibleListContainer from 'components/Common/CollapsibleListContainer';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';

type Props = {
  onChange: Function;
  signatures: DistrictSchoolSignature[];
};

export const ReadAndSignCivilRightsAssuranceComponent: React.FC<Props> = (
  props
) => {
  const { onChange, signatures } = props;

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const [signaturesState, setSignaturesState] = useState<
    DistrictSchoolSignature[]
  >([]);

  const dispatch = useDispatch();

  const handleChange = (item: DistrictSchoolSignature) => {
    const newSignatures = upsertInPlace(signaturesState, item, 'signatureId');
    setSignaturesState(newSignatures);
    onChange(newSignatures);
  };

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadCivilRightSignatures(grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    setSignaturesState([...signatures]);
  }, [signatures]);

  const items = map(
    signaturesState,
    (signature: DistrictSchoolSignature): CollapsibleListItem => {
      return {
        item: (
          <SignatureWithAssertionComponent
            signature={signature}
            onChange={handleChange}
            assertionString="I certify that I have read and agree to the Civil Rights Assurance"
          />
        ),
        itemTitle: signature.categoryName,
      };
    }
  );

  return (
    <Paper>
      <Instruction title="Read and Sign Civil Rights Assurance">
        <p>
          Sign below for your institution. Set up the appropriate individual at
          your institution with a permission level of "Signee" in order to sign
          for the Civil Rights Assurance. Once the signature has been saved, a
          confirmation of the signatory's name and title from Universal Contacts
          will display below the signature line.{' '}
        </p>
      </Instruction>

      <FlexRow>
        <FlexGroup>
          <div className="u-sec-ro--override">
            <h4 className="u-margin-bottom-medium">Civil Rights Assurance</h4>
            <a
              href="/grants/applications/award-packet/documents/civil-rights-assurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div style={{ display: 'inline-block' }}>
                <span className="u-flex">
                  <Icon
                    name="documents"
                    className="u-icon-base-size u-icon-blue u-cursor-help"
                  />
                  <span style={{ marginLeft: '.5rem' }}>
                    View Civil Rights Assurance
                  </span>
                </span>
              </div>
            </a>
          </div>
        </FlexGroup>
      </FlexRow>

      <CollapsibleListContainer header="Institution" items={items} />
    </Paper>
  );
};
