import React from 'react';
import { FlexRow } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { display, compose, renderData, prop } from 'util/objectUtility';
import { SnapshotStudent } from 'types';
import { getProgramLevelNameById } from 'util/programInstance.utility';
import Input from 'components/Common/FormElements/Input';

export const ProgramInformationBase: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { enrollment, student } = props;
  const render = display<SnapshotStudent>(student);

  const ProgramLevel = compose(
    renderData,
    getProgramLevelNameById,
    prop('programLevelId')
  );

  return (
    <React.Fragment>
      <FlexRow>
        <Input
          disabled={true}
          className="input-display"
          name=""
          label="Program Name"
          value={render('programName')}
        />
        <Input
          disabled={true}
          className="input-display"
          name=""
          label="School Name"
          value={renderData(enrollment.schoolName)}
        />
      </FlexRow>
      <FlexRow>
        <Input
          disabled={true}
          className="input-display"
          name=""
          label="Program Level"
          value={ProgramLevel(student)}
        />
        <Input
          disabled={true}
          className="input-display"
          name=""
          label="Program Id"
          value={render('programId')}
        />
        <Input
          disabled={true}
          className="input-display"
          name=""
          label="School Year"
          value={render('schoolYear')}
        />
      </FlexRow>
    </React.Fragment>
  );
};
