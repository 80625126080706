import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';

/*
  Budget Hydration
  This component is used to refresh / hydrate data for grant data types in the background
*/

type DataHydratorProps = {
  shouldRefreshAwardPacketNav: boolean;
  loadGrantAwardPacketNav: Function;

  shouldRefreshAnnualReflectionNav: boolean;
  loadGrantAnnualReflectionNav: Function;

  shouldRefreshAssuranceSignatureNav: boolean;
  loadGrantAssuranceSignatureNav: Function;

  shouldRefreshSpendingPlanNav: boolean;
  loadGrantSpendingPlanNav: Function;

  shouldRefreshFourYearNav: boolean;
  loadGrantFourYearNav: Function;

  shouldRefreshTwoYearNav: boolean;
  loadGrantTwoYearNav: Function;

  shouldRefreshFiscalYearNav: boolean;
  loadGrantFiscalYearNav: Function;

  shouldRefreshGrantOverview: boolean;
  loadGrantOverviewNav: Function;

  shouldRefreshConsortia: boolean;
  loadConsortia: Function;

  shouldRefreshVoucherOverviewNav: boolean;
  loadGrantVoucherOverviewNav: Function;

  shouldRefreshVoucherDetailNav: boolean;
  loadGrantVoucherDetailNav: Function;

  shouldRefreshImprovementPlanNav: boolean;
  loadGrantImprovementPlanNav: Function;
};

export const GrantsDataHydrator: React.FC<DataHydratorProps> = (props: any) => {
  const {
    grantFiscalYearId,
    fiscalYear,
    voucherType,
    secureGUID,
  }: any = useParams();

  // -- Voucher Detail Nav ----------------------------------------------------
  const { shouldRefreshVoucherDetailNav, loadGrantVoucherDetailNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId) && voucherType) {
      shouldRefreshVoucherDetailNav &&
        loadGrantVoucherDetailNav(grantFiscalYearId, voucherType);
    }
  }, [
    shouldRefreshVoucherDetailNav,
    loadGrantVoucherDetailNav,
    voucherType,
    grantFiscalYearId,
  ]);

  // -- Voucher Overview Nav --------------------------------------------------
  const {
    shouldRefreshVoucherOverviewNav,
    loadGrantVoucherOverviewNav,
  } = props;

  useEffect(() => {
    shouldRefreshVoucherOverviewNav && loadGrantVoucherOverviewNav();
  }, [shouldRefreshVoucherOverviewNav, loadGrantVoucherOverviewNav]);

  // -- Award Packet Nav ------------------------------------------------------
  const { shouldRefreshAwardPacketNav, loadGrantAwardPacketNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshAwardPacketNav && loadGrantAwardPacketNav(grantFiscalYearId);
    }
  }, [shouldRefreshAwardPacketNav, loadGrantAwardPacketNav, grantFiscalYearId]);

  // -- Annual Reflection Nav -------------------------------------------------
  const {
    shouldRefreshAnnualReflectionNav,
    loadGrantAnnualReflectionNav,
  } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshAnnualReflectionNav &&
        loadGrantAnnualReflectionNav(grantFiscalYearId);
    }
  }, [
    shouldRefreshAnnualReflectionNav,
    loadGrantAnnualReflectionNav,
    grantFiscalYearId,
  ]);

  // -- Assurance Signature Nav -----------------------------------------------
  const {
    shouldRefreshAssuranceSignatureNav,
    loadGrantAssuranceSignatureNav,
  } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshAssuranceSignatureNav &&
        loadGrantAssuranceSignatureNav(grantFiscalYearId);
    }
  }, [
    shouldRefreshAssuranceSignatureNav,
    loadGrantAssuranceSignatureNav,
    grantFiscalYearId,
  ]);

  // -- Grant Spending Plan Nav -----------------------------------------------
  const { shouldRefreshSpendingPlanNav, loadGrantSpendingPlanNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshSpendingPlanNav &&
        loadGrantSpendingPlanNav(grantFiscalYearId);
    }
  }, [
    shouldRefreshSpendingPlanNav,
    loadGrantSpendingPlanNav,
    grantFiscalYearId,
  ]);

  // -- Grant Improvement Plan Nav -----------------------------------------------
  const {
    shouldRefreshImprovementPlanNav,
    loadGrantImprovementPlanNav,
  } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshImprovementPlanNav &&
        loadGrantImprovementPlanNav(grantFiscalYearId);
    }
  }, [
    shouldRefreshImprovementPlanNav,
    loadGrantImprovementPlanNav,
    grantFiscalYearId,
  ]);

  // -- Four Year Nav ---------------------------------------------------------
  const { shouldRefreshFourYearNav, loadGrantFourYearNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshFourYearNav && loadGrantFourYearNav(grantFiscalYearId);
    }
  }, [shouldRefreshFourYearNav, loadGrantFourYearNav, grantFiscalYearId]);

  // -- Two Year Nav ----------------------------------------------------------
  const { shouldRefreshTwoYearNav, loadGrantTwoYearNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshTwoYearNav && loadGrantTwoYearNav(grantFiscalYearId);
    }
  }, [shouldRefreshTwoYearNav, loadGrantTwoYearNav, grantFiscalYearId]);

  // -- Fiscal Year Nav -------------------------------------------------------
  const { shouldRefreshFiscalYearNav, loadGrantFiscalYearNav } = props;
  useEffect(() => {
    if (fiscalYear && !isNaN(Number(fiscalYear))) {
      shouldRefreshFiscalYearNav && loadGrantFiscalYearNav(Number(fiscalYear));
    }
  }, [shouldRefreshFiscalYearNav, loadGrantFiscalYearNav, fiscalYear]);

  // -- Grant Overview Nav ----------------------------------------------------
  const { shouldRefreshGrantOverview, loadGrantOverviewNav } = props;
  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      shouldRefreshGrantOverview && loadGrantOverviewNav(grantFiscalYearId);
    }
  }, [shouldRefreshGrantOverview, loadGrantOverviewNav]); // eslint-disable-line

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadGrantOverviewNav(grantFiscalYearId);
    }
  }, [loadGrantOverviewNav, grantFiscalYearId]);

  // -- Consortium ------------------------------------------------------------
  const { shouldRefreshConsortia, loadConsortia } = props;
  useEffect(() => {
    if (!secureGUID) {
      shouldRefreshConsortia && loadConsortia();
    }
  }, [shouldRefreshConsortia, loadConsortia, secureGUID]);

  return <div className="hydration" />;
};
