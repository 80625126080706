/**
 * This file contains functions to assist in scrolling the active browser window.
 * @module Utilities > WindowScroll
 * @author AmplifyCP
 */
import { headerHeight } from 'constants/application.constants';

/**
 * Scrolls the window to an element
 * @function
 * @param element the element to scroll to
 * @param behavior how you want it to scroll
 */
export const scrollToElement = (
  element: any,
  behavior: 'smooth' | 'auto' | undefined
) => {
  const { top } = element.getBoundingClientRect();
  const diff = top + window.pageYOffset;
  window.scroll({
    top: diff - headerHeight,
    behavior: behavior,
  });
};

/**
 * Scrolls the window to the specified Anchors
 * @function
 * @param anchor the anchor to scroll to
 * @param behavior how you want it to scroll
 */
export const scrollToAnchor = (
  anchor: string = '',
  behavior: 'smooth' | 'auto' | undefined = 'smooth'
) => {
  if (!anchor) {
    window.scroll({ top: 0, behavior: 'smooth' });
  } else {
    const divToFocus = document.getElementById(anchor);
    if (divToFocus) {
      scrollToElement(divToFocus, behavior);
    }
  }
};
