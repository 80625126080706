export interface AdminOption {
  comment: string;
  programState: string;
  programStateId: number;
  seq: number;
  stepId: number;
  stepName: string;
  stepOptionId: number;
  workflowStatus: string;
  workflowStatusId: number;
}

export const emptyAdminOption = () => {
  return {
    comment: '',
    programState: '',
    programStateId: -1,
    seq: -1,
    stepId: -1,
    stepName: '',
    stepOptionId: -1,
    workflowStatus: '',
    workflowStatusId: -1,
  };
};
