import React, { useEffect } from 'react';
import { ReimbursementSummary, ReimbursementRequestTotal } from 'types';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { add, subtract, sumNumbers } from 'util/mathHelpers';
import { renderData, compose, propObject, getDate } from 'util/objectUtility';
import Tooltip from 'components/Common/Tooltip';
import Icon from 'components/Common/Icons';

type ReimbursementRequestSummaryProps = {
  reimbursementSummary: ReimbursementSummary;
  loadReimbursementRequestSummaryData: Function;
  grantFiscalYearId: number;
};

export const ReimbursementRequestSummaryComponent: React.FC<
  ReimbursementRequestSummaryProps
> = (props) => {
  const {
    reimbursementSummary,
    loadReimbursementRequestSummaryData,
    grantFiscalYearId,
  } = props;
  const { basicGrant, reserveGrant } = reimbursementSummary;

  const getTotalAllocation = (value: ReimbursementRequestTotal) => {
    return value ? add(value.finalAwardAmount, value.fundPooledAmount) : 0;
  };

  const getRemaingBalance = (value: ReimbursementRequestTotal) => {
    const totalAllocation = getTotalAllocation(value);
    const voucherTotals = sumNumbers([
      value ? value.voucher1 : 0,
      value ? value.voucher2 : 0,
      value ? value.voucher3 : 0,
      value ? value.finalVoucher : 0,
    ]);
    return subtract(totalAllocation, voucherTotals);
  };

  const getTotals = (): ReimbursementRequestTotal => {
    const finalAwardAmount = add(
      basicGrant ? basicGrant.finalAwardAmount : 0,
      reserveGrant ? reserveGrant.finalAwardAmount : 0
    );
    const fundPooledAmount = add(
      basicGrant ? basicGrant.fundPooledAmount : 0,
      reserveGrant ? reserveGrant.fundPooledAmount : 0
    );
    const voucher1 = add(
      basicGrant ? basicGrant.voucher1 : 0,
      reserveGrant ? reserveGrant.voucher1 : 0
    );
    const voucher2 = add(
      basicGrant ? basicGrant.voucher2 : 0,
      reserveGrant ? reserveGrant.voucher2 : 0
    );
    const voucher3 = add(
      basicGrant ? basicGrant.voucher3 : 0,
      reserveGrant ? reserveGrant.voucher3 : 0
    );
    const finalVoucher = add(
      basicGrant ? basicGrant.finalVoucher : 0,
      reserveGrant ? reserveGrant.finalVoucher : 0
    );

    return {
      finalAwardAmount,
      fundPooledAmount,
      voucher1,
      voucher2,
      voucher3,
      finalVoucher,
    };
  };

  const renderField = compose(
    renderData,
    propObject(reimbursementSummary)
  );
  const renderMoney = compose(
    renderData,
    insertDollarSign,
    formatMoney
  );
  const getMoney = (object: any) => {
    return compose(
      renderMoney,
      propObject(object)
    );
  };
  const TotalAllocation = compose(
    renderMoney,
    getTotalAllocation
  );
  const RemainingBalance = compose(
    renderMoney,
    getRemaingBalance
  );
  const CannotExceed = getMoney(reimbursementSummary);
  const BasicGrant = getMoney(basicGrant);
  const ReserveGrant = getMoney(reserveGrant);
  const Totals = getMoney(getTotals());

  useEffect(() => {
    loadReimbursementRequestSummaryData(grantFiscalYearId);
  }, [loadReimbursementRequestSummaryData, grantFiscalYearId]);

  return (
    <div className="u-margin-bottom-large">
      <h2 className="u-section-title-text u-margin-bottom-medium">
        Reimbursement Approval Summary
      </h2>
      <table className="table-summary u-full-width">
        <tbody>
          <tr>
            <th scope="rowgroup" colSpan={9}>
              Colorado Community College System
            </th>
          </tr>
          <tr>
            <th scope="rowgroup" colSpan={9}>
              Request for FY{' '}
              {`${
                reimbursementSummary.fiscalYear
              } - ${reimbursementSummary.fiscalYear + 1}`}{' '}
              Perkins Federal Funds Reimbursement Voucher
            </th>
          </tr>
          <tr>
            <th scope="row">Institution | District Name</th>
            <td colSpan={8}>{renderField('institutionName')}</td>
          </tr>
          <tr>
            <th scope="row">Institution Code</th>
            <td colSpan={8}>{renderField('institutionCode')}</td>
          </tr>
          <tr>
            <th scope="row">Vendor Code</th>
            <td colSpan={8}>{renderField('vendorCode')}</td>
          </tr>
          <tr>
            <th scope="row">DUNS Number</th>
            <td colSpan={8}>{renderField('dunsNumber')}</td>
          </tr>
          <tr>
            <th scope="row">CFDA #</th>
            <td colSpan={8}>{renderField('cfda')}</td>
          </tr>
          <tr>
            <th scope="row">
              <div className="u-flex">
                Application Submitted &nbsp;
                <Tooltip
                  tooltip={
                    'This date indicates when a Grant Applicant can begin spending money on Travel, Other, and Personnel expenses'
                  }
                  indicator={
                    <Icon name="questionMark" className="u-menu-icon" />
                  }
                />
              </div>
            </th>
            <td colSpan={8}>
              {getDate(renderData(reimbursementSummary.submission))}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="u-flex">
                Application Approved &nbsp;
                <Tooltip
                  tooltip={
                    'This date indicates when a Grant Applicant can begin spending money on Equipment expenses'
                  }
                  indicator={
                    <Icon name="questionMark" className="u-menu-icon" />
                  }
                />
              </div>
            </th>
            <td colSpan={8}>
              {getDate(renderData(reimbursementSummary.approval))}
            </td>
          </tr>
          <tr>
            <th scope="row">Voucher 1 Can't Exceed</th>
            <td colSpan={8}>{CannotExceed('voucherOneCannotExceed')}</td>
          </tr>
          <tr>
            <td colSpan={9}>&nbsp;</td>
          </tr>
          <tr>
            <td />
            <th scope="col">Final Award</th>
            <th scope="col">Fund Pooled</th>
            <th scope="col">
              <div className="u-flex">
                Total Allocation &nbsp;
                <Tooltip
                  tooltip={'Final Award +/- Fund Pooled Amount'}
                  indicator={
                    <Icon name="questionMark" className="u-menu-icon" />
                  }
                />
              </div>
            </th>
            <th scope="col">Voucher 1</th>
            <th scope="col">Voucher 2</th>
            <th scope="col">Voucher 3</th>
            <th scope="col">Final Voucher</th>
            <th scope="col">Remaining Balance</th>
          </tr>
          <tr>
            <th scope="row">Basic</th>
            <td>{BasicGrant('finalAwardAmount')}</td>
            <td>{BasicGrant('fundPooledAmount')}</td>
            <td>{TotalAllocation(basicGrant)}</td>
            <td>{BasicGrant('voucher1')}</td>
            <td>{BasicGrant('voucher2')}</td>
            <td>{BasicGrant('voucher3')}</td>
            <td>{BasicGrant('finalVoucher')}</td>
            <td>{RemainingBalance(basicGrant)}</td>
          </tr>
          <tr>
            <th scope="row">Reserve</th>
            <td>{ReserveGrant('finalAwardAmount')}</td>
            <td>{ReserveGrant('fundPooledAmount')}</td>
            <td>{TotalAllocation(reserveGrant)}</td>
            <td>{ReserveGrant('voucher1')}</td>
            <td>{ReserveGrant('voucher2')}</td>
            <td>{ReserveGrant('voucher3')}</td>
            <td>{ReserveGrant('finalVoucher')}</td>
            <td>{RemainingBalance(reserveGrant)}</td>
          </tr>
          <tr>
            <th scope="row">Totals</th>
            <td>{Totals('finalAwardAmount')}</td>
            <td>{Totals('fundPooledAmount')}</td>
            <td>{TotalAllocation(getTotals())}</td>
            <td>{Totals('voucher1')}</td>
            <td>{Totals('voucher2')}</td>
            <td>{Totals('voucher3')}</td>
            <td>{Totals('finalVoucher')}</td>
            <td>{RemainingBalance(getTotals())}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
