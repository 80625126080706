import React, { ReactElement } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

type DatatableLoadingProps = {
  messageBefore?: string | ReactElement;
  messageAfter?: string | ReactElement;
  loading: boolean;
};

export const DatatableLoading: React.FC<DatatableLoadingProps> = (props) => {
  const { messageBefore, messageAfter, loading } = props;
  return (
    <React.Fragment>
      {loading ? (
        <div className="loading-inline u-flex-vertical u-flex-full-center">
          {messageBefore && <h3>{messageBefore}</h3>}
          <CircularProgress size={48} />
          {messageAfter && <h3>{messageAfter}</h3>}
        </div>
      ) : (
        <div>There are no records to display</div>
      )}
    </React.Fragment>
  );
};
