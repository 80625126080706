import { combineReducers } from 'redux';
import {
  COMMON_ADMIN_COMMENTS_KEY,
  COMMON_CAREER_CLUSTERS_KEY,
  COMMON_CAREER_PATHWAYS_KEY,
  COMMON_CIPCODE_KEY,
  COMMON_COURSES_KEY,
  COMMON_CTSO_KEY,
  COMMON_DEPARTMENTS_KEY,
  COMMON_DISTRICTS_KEY,
  COMMON_EXPENSE_CATEGORY_KEY,
  COMMON_FERPA_KEY,
  COMMON_FISCAL_YEAR_KEY,
  COMMON_INSTITUTES_KEY,
  COMMON_PROGRAM_AREA_KEY,
  COMMON_PROGRAM_CONTACT_TYPES_KEY,
  COMMON_PROGRAM_LENGTHS_KEY,
  COMMON_PROGRAM_LEVELS_KEY,
  COMMON_PROGRAM_STATES_KEY,
  COMMON_PROGRAM_STEPS_KEY,
  COMMON_SCHOOLS_KEY,
  COMMON_TRAVELER_TYPE_KEY,
  COMMON_CTA_FISCAL_YEAR_KEY,
  CTA_SCHOOL_TERMS_KEY,
} from 'redux/keys';
import { adminCommentsReducer } from './adminComments/adminComments.reducer';
import careerclusters from 'redux/common/careerclusters';
import careerpathways from 'redux/common/careerpathways';
import cipcodes from 'redux/common/cipcodes';
import courses from 'redux/common/courses';
import ctso from 'redux/common/ctsos';
import department from 'redux/common/departments';
import districts from 'redux/common/districts';
import expensecategory from './expensecategory';
import institutes from 'redux/common/intitutes';
import programareas from 'redux/common/programareas';
import programcontacttypes from './programcontacttypes';
import programlengths from 'redux/common/programlengths';
import programlevels from 'redux/common/programlevels';
import programstates from './programstates';
import programsteps from 'redux/common/programsteps';
import schools from 'redux/common/schools';
import travelertype from './travelertype';
import ferpaagreement from './ferpaagreement';
import fiscalyear from './fiscalyear';
import ctafiscalyear from './ctafiscalyear';
import ctaschoolterms from './ctaschoolterms';

export default combineReducers({
  [COMMON_ADMIN_COMMENTS_KEY]: adminCommentsReducer,
  [COMMON_CAREER_CLUSTERS_KEY]: careerclusters,
  [COMMON_CAREER_PATHWAYS_KEY]: careerpathways,
  [COMMON_CIPCODE_KEY]: cipcodes,
  [COMMON_COURSES_KEY]: courses,
  [COMMON_CTSO_KEY]: ctso,
  [COMMON_DEPARTMENTS_KEY]: department,
  [COMMON_DISTRICTS_KEY]: districts,
  [COMMON_EXPENSE_CATEGORY_KEY]: expensecategory,
  [COMMON_FERPA_KEY]: ferpaagreement,
  [COMMON_INSTITUTES_KEY]: institutes,
  [COMMON_PROGRAM_AREA_KEY]: programareas,
  [COMMON_PROGRAM_CONTACT_TYPES_KEY]: programcontacttypes,
  [COMMON_PROGRAM_LENGTHS_KEY]: programlengths,
  [COMMON_PROGRAM_STATES_KEY]: programstates,
  [COMMON_PROGRAM_STEPS_KEY]: programsteps,
  [COMMON_PROGRAM_LEVELS_KEY]: programlevels,
  [COMMON_SCHOOLS_KEY]: schools,
  [COMMON_TRAVELER_TYPE_KEY]: travelertype,
  [COMMON_FISCAL_YEAR_KEY]: fiscalyear,
  [COMMON_CTA_FISCAL_YEAR_KEY]: ctafiscalyear,
  [CTA_SCHOOL_TERMS_KEY]: ctaschoolterms,
});
