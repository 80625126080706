import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  putWithAuthOptions,
  catchError,
} from 'services';
import { PermissionType } from 'types';

const PERMISSION_TYPE_ENDPOINT = 'api/PermissionTypes/';
const URL = `${ENVIRONMENT.API_URL}${PERMISSION_TYPE_ENDPOINT}`;

export const readPermissionTypes = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTypes', err);
    });
}; // end

export const createPermissionType = async (ptype: PermissionType) => {
  let value: Omit<PermissionType, 'permissionTypeId'> = {
    permissionTypeName: ptype.permissionTypeName,
  };
  return fetch(URL, {
    ...postWithAuthOptions(),
    body: JSON.stringify(value),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTypes', err);
    });
}; // end

export const updatePermissionType = async (ptype: PermissionType) => {
  return fetch(`${URL}${ptype.permissionTypeId}`, {
    ...putWithAuthOptions(),
    body: JSON.stringify(ptype),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionTypes', err);
    });
}; // end
