import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { isApplicaionIdValid } from 'util/global';
import { FlexRow, FlexGroup } from 'layouts';
import { CertificationOfExemptionItem } from './CertificationOfExemptionItem';
import { loadCertificateOfExemptionSignatures } from 'redux/grants/awardsignatures/certificateofexemptionsignatures';
import { CertificationOfExemptionSignature } from 'types/grant/CertificationOfExemptionSignature.types';
import CollapsibleListContainer from 'components/Common/CollapsibleListContainer';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';

type CertificationOfExemptionProps = {
  onChange: Function;
  signatures: CertificationOfExemptionSignature[];
};

export const CertificationOfExemptionComponent: React.FC<
  CertificationOfExemptionProps
> = (props) => {
  const { onChange, signatures } = props;
  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const [signaturesState, setSignaturesState] = useState<
    CertificationOfExemptionSignature[]
  >(signatures);

  useEffect(() => {
    if (signaturesState.length <= 0) {
      setSignaturesState(signatures);
    }
  }, [signatures]); // eslint-disable-line

  const handleChange = (item: CertificationOfExemptionSignature) => {
    const newSignatures = upsertInPlace(signaturesState, item, 'categoryName');
    setSignaturesState(newSignatures);
    onChange(newSignatures);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadCertificateOfExemptionSignatures(grantFiscalYearId));
    }
  }, [dispatch, grantFiscalYearId]);

  return (
    <Paper>
      <Instruction title="Upload Single Audit Report or Read and Sign Certification of Exemption">
        Upload a Single Audit Report or sign the Certification of Exemption for
        your institution. If you are a System College or Department of
        Corrections you do not need to complete this step.
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <div className="u-sec-ro--override">
            <h4 className="u-margin-bottom-medium">
              Certification of Exemption
            </h4>
            <a
              href="/grants/applications/award-packet/documents/certification-of-exemption"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div style={{ display: 'inline-block' }}>
                <span className="u-flex">
                  <Icon
                    name="documents"
                    className="u-icon-base-size u-icon-blue u-cursor-help"
                  />
                  <span style={{ marginLeft: '.5rem' }}>
                    View Certification of Exemption
                  </span>
                </span>
              </div>
            </a>
          </div>
        </FlexGroup>
      </FlexRow>
      {signaturesState.length > 0 ? (
        <CollapsibleListContainer
          header={'Institution'}
          mainToggleText={{
            open: 'Open All',
            close: 'Close All',
          }}
          items={map(signaturesState, (item, index) => {
            return {
              item: (
                <CertificationOfExemptionItem
                  key={index}
                  signature={item}
                  onChange={handleChange}
                />
              ),
              itemTitle: item.categoryName,
            };
          })}
        />
      ) : (
        <div>As a system college, you do not need to sign this agreement.</div>
      )}
    </Paper>
  );
};
