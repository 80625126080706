import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/_exports';
import {
  readSnapshotStudents,
  createSnapshotStudent,
  deleteSnapshotStudent,
} from 'services/datacollection';
import { APIErrorEntity, SnapshotStudent } from 'types';

const initialState = baseInitialState;

export const loadSnapshotStudent = createActionThunk(
  'loadSnapshotStudent',
  () => {
    return readSnapshotStudents();
  }
);

export const saveSnapshotStudent = createActionThunk(
  'saveSnapshotStudent',
  (student: SnapshotStudent) => {
    return createSnapshotStudent(student);
  }
);

export const deactivateSnapshotStudent = createActionThunk(
  'deactivateSnapshotStudent',
  (student: SnapshotStudent) => {
    return deleteSnapshotStudent(student);
  }
);

export default handleActions<
  BaseStateObject,
  SnapshotStudent | SnapshotStudent[]
>(
  {
    ...baseLoadActionBuilder<SnapshotStudent | SnapshotStudent[]>(
      loadSnapshotStudent
    ),
    ...baseSaveActionBuilder<APIErrorEntity>(saveSnapshotStudent),
  },
  initialState
);
