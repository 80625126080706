import { NAV_KEY, NAV_GRANT_OVERVIEW_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { SideNavData, emptySideNavData } from 'types/ui/SideNavData.types';
import { defaultTo } from 'lodash';

export const NAV_GRANT_OVERVIEW_PATH: string[] = [
  NAV_KEY,
  NAV_GRANT_OVERVIEW_KEY,
];

export const getIsDataFetching_GrantOverview = (state: any): boolean => {
  return getIsDataFetching(state, NAV_GRANT_OVERVIEW_PATH);
};

export const getLastUpdated_GrantOverview = (state: any): number => {
  return getLastUpdated(state, NAV_GRANT_OVERVIEW_PATH);
};

export const getRecords_GrantOverview = (state: any) => {
  const records = getRecords<SideNavData>(state, NAV_GRANT_OVERVIEW_PATH);
  const [data] = records;
  return defaultTo(data, emptySideNavData());
};

export const shouldRefreshReduxState_GrantOverview = (state: any): boolean => {
  return shouldRefreshReduxState<SideNavData>(state, NAV_GRANT_OVERVIEW_PATH);
};
