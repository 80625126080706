import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTAEquipment } from 'types/cta/CTAEquipment.types';
import {
  readCTAEquipment,
  updateCTAEquipment,
  deleteCTAEquipment,
} from 'services/cta/ctaEquipment.services';

const initialState = baseInitialState;

export const loadCTAEquipment = createActionThunk(
  'loadCTAEquipment',
  (ctaId: number) => {
    return readCTAEquipment(ctaId);
  }
);

export const saveCTAEquipment = createActionThunk(
  'saveCTAEquipment',
  (ctaId: number, equipment: CTAEquipment) => {
    return updateCTAEquipment(ctaId, equipment);
  }
);

export const removeCTAEquipment = createActionThunk(
  'removeCTAEquipment',
  (ctaId: number, equipment: CTAEquipment) => {
    return deleteCTAEquipment(ctaId, equipment);
  }
);

export default handleActions<BaseStateObject, CTAEquipment[]>(
  {
    ...baseLoadActionBuilder<CTAEquipment[]>(loadCTAEquipment),
    ...baseSaveActionBuilder(saveCTAEquipment),
    ...baseSaveActionBuilder(removeCTAEquipment),
  },
  initialState
);
