export interface SnapshotTeacher {
  cdeId: string;
  cipCode: string;
  credentialArea: string;
  credentialExp: Date;
  credentialNumber: string;
  credentialType?: number | null;
  dob: Date;
  email: string;
  firstName: string;
  isComplete: boolean;
  isOpen: boolean;
  isTeachingPostsecondary: boolean;
  lastName: string;
  middleName: string;
  phone: string;
  programBaseId: number;
  programInstanceId: number;
  programLevelId: number;
  programName: string;
  programSchoolName: string;
  programState: string;
  renewalReq: string;
  s_number: string; // snuffeluffegus
  schoolId: number;
  snapshotTeacherId: number;
  snapshotId: number;
  teacherContactId: number;
  timeId: string;
}
export const emptySnapshotTeacher = (): SnapshotTeacher => {
  return {
    cdeId: '',
    cipCode: '',
    credentialArea: '',
    credentialExp: new Date(),
    credentialNumber: '',
    dob: new Date(),
    email: '',
    firstName: '',
    isComplete: false,
    isOpen: true, // need to be true by default
    isTeachingPostsecondary: false,
    lastName: '',
    middleName: '',
    phone: '',
    programBaseId: -1,
    programInstanceId: -1,
    programLevelId: -1,
    programName: '',
    programSchoolName: '',
    programState: '',
    renewalReq: '',
    s_number: '',
    schoolId: -1,
    snapshotTeacherId: -1,
    snapshotId: -1,
    teacherContactId: -1,
    timeId: '',
  };
};
