import { NAV_KEY, NAV_GRANT_VOUCHER_STATUS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  shouldRefreshReduxState,
  getLastUpdated,
  getFirstRecord,
} from 'redux/utility/baseSelectors';
import { WorkflowStatus } from 'types/grant/WorkflowStatus.types';

export const NAV_GRANT_VOUCHER_OVERVIEW_STATUS_PATH: string[] = [
  NAV_KEY,
  NAV_GRANT_VOUCHER_STATUS_KEY,
];

export const getIsDataFetching_GrantVoucherOverviewStatus = (
  state: any
): boolean => {
  return getIsDataFetching(state, NAV_GRANT_VOUCHER_OVERVIEW_STATUS_PATH);
};

export const getLastUpdated_GrantVoucherOverviewStatus = (
  state: any
): number => {
  return getLastUpdated(state, NAV_GRANT_VOUCHER_OVERVIEW_STATUS_PATH);
};

export const shouldRefreshReduxState_GrantVoucherOverviewStatus = (
  state: any
): boolean => {
  return shouldRefreshReduxState<WorkflowStatus>(
    state,
    NAV_GRANT_VOUCHER_OVERVIEW_STATUS_PATH,
    300
  );
};

export const getRecords_GrantVoucherOverviewStatus = (state: any) => {
  const records = getFirstRecord<WorkflowStatus>(
    state,
    NAV_GRANT_VOUCHER_OVERVIEW_STATUS_PATH,
    {
      updated: null,
      lastModifiedBy: null,
      progressStep: 'Vouchers',
      status: 'Open',
    }
  );
  const voucherStatus = {
    ...records,
  };
  return voucherStatus;
};
