import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const ENDPOINT = 'api/Expenses/Programs/';

//TODO: add JSDoc
export const readExpenseProgram = async (grantFiscalYearId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ExpenseProgram', err);
    });
};
