import { useValidation } from 'de-formed-validations';
import { Expense, ExpenseProgram } from 'types';
import { isAdminExpense } from 'util/annualSpendingUtility';
import { isStringValid } from 'util/validation.utility';

export const BaseExpenseValidations = () => {
  return useValidation<Expense>({
    expenseName: [
      {
        errorMessage: 'Expense Name is required.',
        validation: (value: string, _) => {
          return isStringValid(value);
        },
      },
    ],

    financialCategoryId: [
      {
        errorMessage: 'Please select a valid Financial Category.',
        validation: (value: number, _) => {
          return value > 0;
        },
      },
    ],

    budgetedAmount: [
      {
        errorMessage: 'Budgeted Amount cannot be negative.',
        validation: (value: number, _) => {
          return value >= 0;
        },
      },
    ],

    description: [
      {
        errorMessage: 'Expense Description is required.',
        validation: (value: string, _) => {
          return isStringValid(value);
        },
      },
    ],

    expensePrograms: [
      {
        errorMessage:
          'You must select one or more Programs. If this is expense is for a new program, please indicate it with the toggle.',
        validation: (value: ExpenseProgram[], state: Expense) => {
          if (isAdminExpense(state.strategyId)) return true;
          if (state.isNewProgram) return true;
          return !!value && value.length > 0;
        },
      },
    ],
  });
};
