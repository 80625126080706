// This function is for some special cases that need to ignore startCase effect

import { startCase } from 'lodash';

export const customStartCase = (value?: string) => {
  switch (value) {
    case 'work-based-learning':
      return 'Work-Based Learning';
    default:
      return startCase(value);
  }
};
