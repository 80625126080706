import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ManageEmailRecipients from 'components/Grants/EmailManagement/ManageRecipients';

export const EmailRecipientsPage: React.FC = () => {
  return (
    <BaseLayout>
      <ManageEmailRecipients />
    </BaseLayout>
  );
};
