import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import CTADashboardComponent from 'components/CTA/CTADashboard';

export const CTADashboardPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <CTADashboardComponent {...props} />
    </BaseLayout>
  );
};
