import { connect } from 'react-redux';
import { ConsortiaFilterComponent } from './ConsortiaFilterComponent';
import {
  setConsortiaFilterValue,
  resetConsortiaFilterValue,
  clearConsortiaFilters,
} from 'redux/filters/consortiafilter';
import { getRecords_ConsortiumFilterState } from 'redux/filters/consortiafilter.selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const filter = getRecords_ConsortiumFilterState(state);
  return { filter };
};

const dispatchToProps = {
  setConsortiaFilterValue,
  resetConsortiaFilterValue,
  clearConsortiaFilters,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ConsortiaFilterComponent);
