import { basetype } from 'types/base.types';

export interface ProgramAward extends basetype {
  programAwardId: number;
  awardTypeId: number;
  certOrDegreeTitle: string;
  credits: number;
  totalWeeks: number;
  contactHours: number;
  courses: string;
  applicationName: string;
  transcriptName: string;
  bannerCode: string;
  sequenceId: string;
  financialAidApproved: boolean;
  financialAidDistributionDate: Date | undefined;
  firstDate: Date | undefined;
}

export const emptyProgramAward = (): ProgramAward => {
  return {
    programAwardId: -1,
    awardTypeId: -1,
    certOrDegreeTitle: '',
    credits: 0,
    totalWeeks: 0,
    contactHours: 0,
    courses: '',
    applicationName: '',
    transcriptName: '',
    bannerCode: '',
    sequenceId: '',
    financialAidApproved: false,
    financialAidDistributionDate: new Date(Date.now()),
    firstDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  };
};
