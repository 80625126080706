import React, { useRef, useState, useEffect } from 'react';
import { map, indexOf, find } from 'lodash';
import { isDisabled } from 'util/authorization';
import { isEqual } from 'util/formHelpers';
import { FlexGroup } from 'layouts';
import Icon from 'components/Common/Icons';

export interface RadioOption {
  id: number;
  label: string;
}

export type RadiosProps = {
  border?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  horizontal?: boolean;
  label?: string;
  name: string;
  noLabel?: boolean;
  onBlur?: Function;
  onChange: Function;
  options: RadioOption[];
  valid?: boolean;
  value: number | null;
};

export const Radio: React.FC<RadiosProps> = (props) => {
  const {
    border = false,
    disabled,
    errorMessage,
    horizontal = true,
    label,
    name,
    noLabel,
    onBlur,
    onChange,
    options,
    valid = true,
    value,
  } = props;

  const [computedDisable, setComputedDisable] = useState<boolean>(false);
  const ref = useRef(null);

  const handleChange = (id: number) => {
    if (computedDisable) return;
    const value = id;
    const data: any = {};
    data[name] = value;
    onChange(data, name, id);
  };

  const handleKeyboard = (e: React.KeyboardEvent) => {
    if (computedDisable) return;
    let elementToSelect;
    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      e.preventDefault();
      const item = find(options, (option: RadioOption) => {
        return isEqual(value, option.id);
      });
      const index = indexOf(options, item);
      elementToSelect = options[index + 1] ? options[index + 1] : options[0];
    }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      e.preventDefault();
      const item = find(options, (option: RadioOption) => {
        return isEqual(value, option.id);
      });
      const index = indexOf(options, item);
      elementToSelect = options[index - 1]
        ? options[index - 1]
        : options[options.length - 1];
    }
    if (elementToSelect) {
      handleChange(elementToSelect.id);
      const newOption = document.getElementById(elementToSelect.id.toString());
      if (newOption) {
        newOption.focus();
      }
    }
  };

  const getTabIndex = (option: RadioOption) => {
    if (computedDisable) return -1;
    if (!value) return 0; // provide a tabstop if no value is selected in radio
    const isSelected = isEqual(value, option.id);
    return isSelected ? 0 : -1;
  };

  const getRadioClassName = () => {
    const direction = horizontal
      ? 'form__radio-group__container u-flex'
      : 'form__radio-group__container form__radio-group__container--vertical';
    return border ? `${direction} u-border` : direction;
  };

  const getItemClassName = () => {
    return horizontal
      ? 'form__radio-group'
      : 'form__radio-group form__radio-group--vertical';
  };

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, ref);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  return (
    <FlexGroup role="radiogroup">
      {!noLabel && <p className="form__label">{label}</p>}
      <div className={getRadioClassName()}>
        {map(options, (option: RadioOption) => {
          const isSelected = isEqual(value, option.id);
          return (
            <div
              aria-label={name}
              aria-checked={isSelected}
              className={getItemClassName()}
              id={option.id.toString()}
              key={option.id}
              onBlur={() => onBlur && onBlur()}
              onClick={() => handleChange(option.id)}
              onKeyDown={handleKeyboard}
              ref={ref}
              role="radio"
              tabIndex={getTabIndex(option)}
            >
              {isSelected ? (
                <Icon name="circleChecked" className="form__radio-btn" />
              ) : (
                <Icon name="circleOpen" className="form__radio-btn" />
              )}
              <p className="form__radio-text">{option.label}</p>
            </div>
          );
        })}
      </div>
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </FlexGroup>
  );
};
