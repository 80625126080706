import React, { useEffect } from 'react';
import { ButtonContainer, FlexGroup, FlexRow } from 'layouts';
import { useHistory } from 'react-router-dom';
import { FerpaAgreement } from 'types';
import { mergeObjects, renderData } from 'util/objectUtility';
import Button from 'components/Common/FormElements/Button';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';

export type FerpaAgreementProps = {
  agreement: FerpaAgreement;
  isFetching: boolean;
  saveFerpaAgreementData: Function;
  loadFerpaAgreementData: Function;
};

export const FerpaAgreementComponent: React.FC<FerpaAgreementProps> = (
  props
) => {
  const {
    agreement,
    isFetching,
    saveFerpaAgreementData,
    loadFerpaAgreementData,
  } = props;

  useEffect(() => {
    loadFerpaAgreementData();
  }, [loadFerpaAgreementData]);

  const history = useHistory();
  const navigateOnCancel = () => {
    history.goBack();
  };

  const handleSave = async (): Promise<any> => {
    const agreedTo = mergeObjects(agreement, { agreed: true });
    saveFerpaAgreementData(agreedTo);
  };

  const handleCancel = async (): Promise<any> => {
    const agreedTo = mergeObjects(agreement, { agreed: false });
    saveFerpaAgreementData(agreedTo);
    navigateOnCancel();
  };

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading..." />
      <ScrollToTop />
      <Paper>
        <PageHeading
          title={`Family Educational Rights and Privacy Act: ${renderData(
            agreement.schoolYear
          )}`}
          subTitle={'Data Collections'}
        />
      </Paper>
      <Paper>
        <FlexRow>
          <h2>By clicking Agree and Continue, you agree to the following: </h2>
        </FlexRow>
        <FlexRow>
          <p>
            1. It is expected that reporting institutions will make reasonable
            efforts to ensure that{' '}
            <strong>
              data reported on this site are complete, accurate and on time,
            </strong>{' '}
            and that they will actively pursue opportunities to review data
            submissions and make corrections before data reporting deadlines.
          </p>
        </FlexRow>
        <FlexRow>
          <p>
            2.{' '}
            <strong>
              Student-level data made available from this website are intended
              for internal audiences only.
            </strong>{' '}
            These data should not be provided to external audiences without the
            prior approval of the institution's official Data Contact. Such
            reports do not constitute official institution data and should not
            be represented as such or distributed outside the institution.
          </p>
        </FlexRow>
        <FlexRow>
          <p>
            3. You are{' '}
            <strong>
              required to comply with the{' '}
              <a
                href={
                  'https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Family Educational Rights and Privacy Act (FERPA)
              </a>{' '}
            </strong>{' '}
            when accessing student information. You are acknowledging your
            acceptance and recognition of this federal law and agree to not
            release student information to a third party without following
            proper administrative procedure.
          </p>
        </FlexRow>
      </Paper>
      <Paper>
        <ButtonContainer>
          <FlexRow>
            <FlexGroup>
              <Button
                asLink={false}
                text="Decline"
                onClick={handleCancel}
                className="form__btn--cancel u-sec-ro--override"
              />
            </FlexGroup>
            <FlexGroup>
              <Button
                asLink={false}
                text="Agree and Continue"
                onClick={handleSave}
                className="form__btn--cancel"
              />
            </FlexGroup>
          </FlexRow>
        </ButtonContainer>
      </Paper>
    </React.Fragment>
  );
};
