import { COMMON_KEY, COMMON_ADMIN_COMMENTS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { find, defaultTo } from 'lodash';
import { AdminComments, emptyAdminComment } from 'types';

export const ADMIN_COMMENTS_PATH = [COMMON_KEY, COMMON_ADMIN_COMMENTS_KEY];

export const getIsDataFetching_AdminComments = (state: any): boolean => {
  return getIsDataFetching(state, ADMIN_COMMENTS_PATH);
};

export const getRecords_AdminComments = (state: unknown): AdminComments[] => {
  return getRecords(state, ADMIN_COMMENTS_PATH);
};

export const getAdminComment = (state: any, templateId: number) => {
  const records = getRecords_AdminComments(state);
  return defaultTo(find(records, { templateId }), emptyAdminComment());
};
