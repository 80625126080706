import Button from 'components/Common/FormElements/Button';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect, useRef } from 'react';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import { CTASchool } from 'types';
import {
  CtaInstructorCourse,
  emptyCtaInstructorCourse,
} from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';
import { FlexRow } from 'layouts';
import { NonCteCtaCourseComponent } from './NonCteCtaCourse.component';
import { all } from 'ramda';
import { compose, isTruthy } from 'util/objectUtility';
import { createTempId } from 'util/formHelpers';
import { isApplicaionIdValid } from 'util/global';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import { upsertInPlace, fmap } from 'util/arrayUtility';
import { useParams } from 'react-router-dom';

type NonCteScheduleProps = {
  courses: CtaInstructorCourse[];
  forceSave: boolean;
  loadCTASchools: Function;
  loadNonCTECourses: Function;
  saveNonCTECourses: Function;
  schools: CTASchool[];
  setDirtyData: Function;
};

export const NonCteScheduleComponent: React.FC<NonCteScheduleProps> = ({
  courses,
  forceSave,
  loadNonCTECourses,
  loadCTASchools,
  saveNonCTECourses,
  schools,
  setDirtyData,
}) => {
  // -- dependencies --
  const { ctaId: cId, ctaInstructorId: ciId } = useParams<any>();
  const ctaId = Number(cId);
  const ctaInstructorId = Number(ciId);
  const { validateAll } = CTACourseValidation();

  // -- local state --
  const mountedRef = useRef(true);
  const [courseState, setCourseState] = useState<CtaInstructorCourse[]>([]);
  const [dirtySchedule, setDirtySchedule] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);

  // -- component logic --
  const validateAllCourses = compose(
    all(isTruthy),
    fmap(
      compose(validateAll, (course: any) => ({
        ...course,
        isCte: false,
        isInstructional: true,
      }))
    )
  );
  const deleteItem = (id: number) => {
    !dirtySchedule && setDirtyData(true);
    !dirtySchedule && setDirtySchedule(true);
    const newCourses = courseState.filter((item: CtaInstructorCourse) => {
      return item.ctaCourseId !== id;
    });
    setCourseState(newCourses);
  };
  const addCourse = () => {
    !dirtySchedule && setDirtyData(true);
    !dirtySchedule && setDirtySchedule(true);
    const ctaCourseId = createTempId(courseState, 'ctaCourseId');
    const newCourses = [
      ...courseState,
      {
        ...emptyCtaInstructorCourse(),
        ctaCourseId,
        isCte: false,
        ctaInstructorId,
      },
    ];
    setCourseState(newCourses);
  };
  const handleCourseChange = (edited: CtaInstructorCourse) => {
    !dirtySchedule && setDirtyData(true);
    !dirtySchedule && setDirtySchedule(true);
    const newCourses = upsertInPlace(courseState, edited, 'ctaCourseId');
    setCourseState(newCourses);
  };
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const saveCourses = () => {
    toggleHasRequested();
    const canSave = validateAllCourses(courseState);
    if (canSave) {
      saveNonCTECourses(ctaId, ctaInstructorId, courseState)
        .then(() => setDirtyData(false))
        .then(() => setDirtySchedule(false))
        .then(() => setSubmitFailed(false))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  // -- lifecycle --
  useEffect(() => {
    if (isApplicaionIdValid(ctaInstructorId)) {
      loadNonCTECourses(ctaInstructorId).catch((err: any) =>
        console.error(err)
      );
    }
  }, [ctaInstructorId, loadNonCTECourses]);

  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      loadCTASchools(ctaId).catch((err: any) => console.error(err));
    }
  }, [loadCTASchools, ctaId]);

  useEffect(() => {
    setCourseState(courses);
  }, [courses]);

  useEffect(() => {
    if (forceSave && dirtySchedule) {
      saveCourses();
    }
  }, [forceSave]); // eslint-disable-line

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Paper>
      <FlexRow>
        <Instruction title="Non-CTE Schedule">
          <p>
            All courses taught by this instructor must be entered to accurately
            calculate the reimbursement for your district. Enter each
            state-approved non-CTE course taught by this instructor and enter
            the number of credits offered in the ‘Term Credits’ box. To begin,
            click in the ‘Add New Course’ text to add a new row to the table.
            Type in the local course name and select the appropriate school
            using the dropdown box. Enter the number of credits offered in the
            ‘Term Credits’ box. Click on ‘Delete this Course’ if this course is
            no longer applicable or the selected school is incorrect. Do not
            report Teacher Aide on this schedule or courses for which no credit
            is transcripted. Click the ‘Save’ button when you have completed
            this section.
          </p>
          <p>
            <span className="u-color-red">Note</span>: If the correct number of
            terms do not appear for the school you select, fix this in the
            General Information section in the CTA Report for your district. A
            change in the General Information will apply to all courses
            associated with that school.
          </p>
        </Instruction>
      </FlexRow>
      {map(courseState, (course, index) => {
        return (
          <FlexRow key={`non-cte-course__${index}`}>
            <NonCteCtaCourseComponent
              course={course}
              onChange={handleCourseChange}
              onDelete={deleteItem}
              schools={schools}
              submitFailed={submitFailed}
            />
          </FlexRow>
        );
      })}
      <FlexRow>
        <Button
          text="+ Add New Course"
          asLink={false}
          className="button--filled"
          onClick={addCourse}
        />
      </FlexRow>
      <FlexRow>
        <div className="u-flex-grow-1" />
        <Button
          className={dirtySchedule ? 'button--filled' : 'button--outline'}
          disabled={hasRequested}
          onClick={saveCourses}
          style={{
            alignSelf: 'flex-end',
            height: '5.6rem',
            width: '28rem',
          }}
          text="Save Non-CTE Schedule"
        />
      </FlexRow>
    </Paper>
  );
};
