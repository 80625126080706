import React, { CSSProperties } from 'react';
import { COLOR_BLUE } from 'constants/css.const';
import Tooltip from 'components/Common/Tooltip';
import Icon from 'components/Common/Icons';

interface InputToolTipProps {
  iconClassName?: string;
  place?: 'right' | 'left' | null;
  position?: 'above' | 'below' | null;
  style?: CSSProperties;
  tooltip: string;
}
export const InputToolTip: React.FC<InputToolTipProps> = (props) => {
  const { iconClassName = '', place, position, style, tooltip } = props;

  const defaultStyle = {
    width: '2.2rem',
    fill: COLOR_BLUE,
    alignSelf: 'flex-start',
    marginLeft: '1rem',
  };

  return (
    <Tooltip
      indicator={
        <Icon
          name={'info'}
          className={iconClassName}
          style={{ ...defaultStyle, ...style }}
        />
      }
      place={place}
      position={position}
      tooltip={tooltip}
    />
  );
};
