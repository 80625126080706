import React from 'react';
import { toast } from 'react-toastify';
import { startCase } from 'lodash';
import { FlexRow } from 'layouts';
import ToastNotification from 'components/Common/Toast';

export const errorMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  next(action);

  if (action.error || action.type.includes('FAILED')) {
    if (action.type === 'loadUser_FAILED') {
      toast(
        <ToastNotification status="error">
          <FlexRow>
            <p>There was a problem loading your credentials.</p>
          </FlexRow>
          <FlexRow>
            <p>If the problem persists, contact a system administrator.</p>
          </FlexRow>
        </ToastNotification>
      );
    } else {
      const err = action.type;
      const component = err.split('_')[0];
      const msg = startCase(component);
      toast(
        <ToastNotification status="error">
          <FlexRow>
            <p>{msg} encountered a problem.</p>
          </FlexRow>
          <FlexRow>
            <p>
              If the problem persists, please contact a system administrator.
            </p>
          </FlexRow>
        </ToastNotification>
      );
    }
  }
};
