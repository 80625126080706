import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';
import { ReportingFilterState } from 'types';
import { isTruthy } from 'util/objectUtility';

type FilterProps = {
  clearFilters: Function;
  filterLengths: any;
  filterState: Partial<ReportingFilterState>;
  setFilterValue: Function;
};

export const ReportsDashboardFilters: React.FC<FilterProps> = (props) => {
  const { clearFilters, filterState, setFilterValue, filterLengths } = props;

  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          number={filterLengths.mostUsedCount}
          active={isTruthy(filterState.mostUsed)}
          iconName="starEmpty"
          onClick={() => {
            clearFilters();
            setFilterValue({
              mostUsed: filterState.mostUsed ? undefined : true,
            });
          }}
          text="Most Used"
        />
        <FilterHotButton
          number={filterLengths.dcReviewCount}
          active={isTruthy(filterState.dcReview)}
          iconName="clipboard"
          onClick={() => {
            clearFilters();
            setFilterValue({
              dcReview: filterState.dcReview ? undefined : true,
            });
          }}
          text="DC Review"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          number={filterLengths.cccsFavoriteCount}
          active={isTruthy(filterState.cccsFavorite)}
          iconName="starEmpty"
          onClick={() => {
            clearFilters();
            setFilterValue({
              cccsFavorite: filterState.cccsFavorite ? undefined : true,
            });
          }}
          text="CCCS Favorites"
        />
        <FilterHotButton
          number={filterLengths.ctaReviewCount}
          active={isTruthy(filterState.ctaReview)}
          iconName="clipboard"
          onClick={() => {
            clearFilters();
            setFilterValue({
              ctaReview: filterState.ctaReview ? undefined : true,
            });
          }}
          text="CTA Review"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          number={filterLengths.financeFavoriteCount}
          active={isTruthy(filterState.financeFavorite)}
          iconName="starEmpty"
          onClick={() => {
            clearFilters();
            setFilterValue({
              financeFavorite: filterState.financeFavorite ? undefined : true,
            });
          }}
          text="Finance Favorites"
        />
      </FlexGroup>
    </FlexRow>
  );
};
