import { CTA_KEY, CTA_CONTRACTING_AVAILABLE_HOSTS_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  getAdvancedFilteredRecords,
  getRecords,
} from 'redux/utility/baseSelectors';
import { getCTADashboardFilters } from 'redux/filters/_selectors';
import { CTAHostItem } from 'types';

export const CTA_CONTRACTING_AVAILABLE_HOSTS_PATH = [
  CTA_KEY,
  CTA_CONTRACTING_AVAILABLE_HOSTS_KEY,
];

export const getIsDataFetching_CTAContractingAvailableHosts = (
  state: any
): boolean => {
  return getIsDataFetching(state, CTA_CONTRACTING_AVAILABLE_HOSTS_PATH);
};

export const getRecords_CTAContractingAvailableHosts = (
  state: any
): CTAHostItem[] => {
  return getRecords(state, CTA_CONTRACTING_AVAILABLE_HOSTS_PATH);
};

export const getAdvancedFilteredRecords_CTAContractingAvailableHosts = (
  state: any
): CTAHostItem[] => {
  const filters = getCTADashboardFilters(state);
  const records = getAdvancedFilteredRecords<CTAHostItem>(
    state,
    CTA_CONTRACTING_AVAILABLE_HOSTS_PATH,
    filters
  );
  return records;
};
