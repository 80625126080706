import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { EmailTemplate, EmailTemplateBase } from 'types';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readEmailTemplates } from 'services/grants/emailManagement.services';

const initialState = baseInitialState;

export const loadEmailTemplateSummaries = createActionThunk(
  'loadEmailTemplateSummaries',
  (FiscalYear: number) => {
    return readEmailTemplates(FiscalYear);
  }
);

export default handleActions<BaseStateObject, EmailTemplate | EmailTemplate[]>(
  {
    ...baseLoadActionBuilder<EmailTemplateBase | EmailTemplateBase[]>(
      loadEmailTemplateSummaries
    ),
  },
  initialState
);
