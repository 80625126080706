import { GrantAssurances } from './Assurances.component';
import { setCurrentNavAnchor } from 'redux/application';
import { connect } from 'react-redux';
import {
  getIsDataFetching_GrantAssurances,
  getRecords_GrantAssurances,
} from 'redux/grants/_selectors';
import {
  saveBasicGrantAssurances,
  loadBasicGrantAssurances,
} from 'redux/grants/assurances';
import { getLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any) => {
  const isLoading = getIsDataFetching_GrantAssurances(state);

  const questions = getRecords_GrantAssurances(state);

  const isLocked = getLockedStatus(state, 'assurance-signature');

  return {
    isLoading,
    isLocked,
    questions,
  };
};

const dispatchProps = {
  loadBasicGrantAssurances,
  saveBasicGrantAssurances,
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(GrantAssurances);
