import React from 'react';
import { Link } from 'react-router-dom';
import AccountMenu from 'components/Common/Site/AccountMenu';
import CTELogo from 'assets/cte_logo.png';
import HamburgerMenuComponent from 'components/Common/Site/HamburgerMenu';
import RefreshData from 'components/Common/Site/RefreshData';

export const Header: React.FC = () => {
  return (
    <div role="banner" className="main-header">
      <h1 className="main-header__logo">
        <Link to="/">
          <img className="main-header__img" src={CTELogo} alt="CTE" />
        </Link>
      </h1>
      <div className="main-header__menu-container">
        <HamburgerMenuComponent />
        <RefreshData />
        <AccountMenu />
      </div>
    </div>
  );
};
