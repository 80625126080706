import { ProgramInformationComponent } from './ProgramInformation.component';
import { connect } from 'react-redux';
import { getFilteredRecords_Programs } from 'redux/programs/_selectors';
import { insertPrograms } from 'redux/programs/programinstance';
import { getLoggedinUser } from 'redux/user/selectors';
import { PROGRAM_STATE } from 'constants/programState.enum';

const mapStateToProps = (state: any, ownProps: any) => {
  const programs = getFilteredRecords_Programs(state, {
    property: 'programStateId',
    values: [
      PROGRAM_STATE.Active,
      PROGRAM_STATE.ConditionallyActive,
      PROGRAM_STATE.OpenForRenewal,
      PROGRAM_STATE.OpenForRevision,
    ],
  });
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
    programs,
  };
};

const dispatchProps = {
  insertPrograms,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProgramInformationComponent);
