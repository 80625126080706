import React from 'react';
import { SpendingCategory } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { FundsRemaining } from 'components/Grants/Applications/AnnualSpendingPlan/FundsRemaining/FundsRemaining.component';

type FundsRemainingProps = {
  otherSourcesAvailable: number;
  fundsRemaining: number;
  fundsOverage: number;
  title: string;
  description: string;
  offset?: number;
  spendingCategory: SpendingCategory;
};

export const VoucherFundsAvailable: React.FC<FundsRemainingProps> = (props) => {
  const {
    fundsRemaining,
    otherSourcesAvailable,
    spendingCategory,
    title,
  } = props;

  return (
    <React.Fragment>
      <h2 className="u-section-title-text u-margin-bottom-medium">{title}</h2>
      <FlexRow>
        <p>
          The remaining funds for each category will decrease as you create new
          requests. “Remaining Funds from Other Sources” are unclaimed funds
          from other plans and administrative costs. This number excludes any
          funds from Fund Pooled projects. Remaining Funds for Reimbursement”
          are remaining funds for the current plan/category. If you are editing
          Indirect Administrative Costs, "Remaining Funds from Other Sources"
          will not appear and remaining funds represent all remaining funds.
        </p>
      </FlexRow>
      <FlexRow>
        <FlexGroup />
        <FlexGroup />
        {spendingCategory.categoryId !== 7 && (
          <FlexGroup>
            <FundsRemaining
              label="Remaining Funds from Other Sources"
              funds={otherSourcesAvailable >= 0 ? otherSourcesAvailable : 0}
            />
          </FlexGroup>
        )}
        <FlexGroup>
          <FundsRemaining
            label="Remaining Project Budget"
            funds={fundsRemaining >= 0 ? fundsRemaining : 0}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  );
};
