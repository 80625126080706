import React, { useState, useEffect } from 'react';
import { SnapshotTeacher, UniversalContact, School, Program } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { readProgramsBySchoolId } from 'services/programs/program.services';
import { toast } from 'react-toastify';
import {
  createRadioOptionsForBool,
  convertBoolToRadioValue,
} from 'util/formHelpers';
import { isApplicaionIdValid } from 'util/global';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import { find, some, filter } from 'lodash';
import { readActiveTeacherSchools } from 'services/datacollection';
import { fmap, ffilter } from 'util/arrayUtility';
import { compose } from 'redux';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { prop, isFalse } from 'util/objectUtility';
import { isEqual } from 'util/formHelpers';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import ProgramInstanceSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/ProgramInstanceSelectBox/';
import Radio from 'components/Common/FormElements/Radio';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type ProgramInformationComponentProps = {
  teacher: SnapshotTeacher;
  loggedInUser: UniversalContact;
  insertPrograms: Function;
  onChange: Function;
  v: ValidationObject<SnapshotTeacher>;
  programs: Program[];
};

export const ProgramInformationComponent: React.FC<
  ProgramInformationComponentProps
> = (props) => {
  const { teacher, insertPrograms, onChange, v, programs } = props;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [schools, setSchools] = useState<School[]>([]);

  const [filteredPrograms, setFilteredPrograms] = useState<Program[]>(programs);

  // convert "1" or "2" to part time, full time
  const getInstructionalTime = (number: string | null) => {
    if (!number) return null;
    const id = Number(number) - 1;
    const bool = !!id;
    return convertBoolToRadioValue(bool);
  };
  const handleTimeChange = (
    data: Partial<SnapshotTeacher>,
    name: 'timeId',
    val: number
  ) => {
    const timeId = (val + 1).toString(); // convert back to "1" or "2"
    onChange({ timeId }, name, timeId);
  };
  const handleProgramChange = (data: Program) => {
    onChange(
      {
        ...teacher,
        programLevel: data.programLevelId,
        programBaseId: data.programBaseId,
        programInstanceId: data.programInstanceId,
        programLevelId: data.programLevelId,
        programName: data.programName,
        programState: data.programStateName,
        cipCode: data.cipId,
      },
      'programInstanceId',
      data.programInstanceId
    );
  };
  const handleSchoolChange = async (data: Partial<School>) => {
    loadData(teacher.schoolId);
    const school = find(schools, data);
    if (!school) return;
    onChange(
      {
        ...teacher,
        schoolId: data.schoolId,
        cdeId: school.cdeId,
        programSchoolName: school.schoolName,
        programInstanceId: -1,
      },
      'schoolId',
      data.schoolId
    );
  };

  const loadData = (id: number) => {
    if (!isApplicaionIdValid(id)) return;
    setIsFetching(true);
    return readProgramsBySchoolId(id)
      .then((response: any) => {
        insertPrograms(response);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.LoadDataFailed}
          />
        );
      })
      .finally(() => setIsFetching(false));
  };

  const isNotPostsecondary = compose(
    isFalse,
    isEqual(PROGRAM_LEVEL_ENUM.Postsecondary),
    prop('programLevelId')
  );

  const schoolDoesNotHavePostsecondary = compose(
    some,
    compose(
      fmap(isNotPostsecondary),
      prop('programLevels')
    )
  );

  const notPostsecondarySchools = ffilter(schoolDoesNotHavePostsecondary);

  const filterSchools = (schools: School[]) => {
    return teacher.isTeachingPostsecondary
      ? schools
      : notPostsecondarySchools(schools);
  };

  useEffect(() => {
    setIsFetching(true);
    readActiveTeacherSchools()
      .then((value: School[]) => {
        setSchools(value);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.InvalidResponse}
          />
        );
      })
      .finally(() => setIsFetching(false));
  }, [readActiveTeacherSchools]); // eslint-disable-line

  useEffect(() => {
    if (isApplicaionIdValid(teacher.schoolId)) {
      loadData(teacher.schoolId);
    }
  }, [teacher.schoolId]); // eslint-disable-line

  useEffect(() => {
    setFilteredPrograms(
      filter(programs, (item) => {
        return item.schoolId === teacher.schoolId;
      })
    );
  }, [teacher.schoolId, programs]); // eslint-disable-line

  return teacher ? (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading Data..." />
      <Instruction title="Program Information">
        <p>
          If your teacher is teaching multiple programs you will have to add
          them per program.
        </p>
      </Instruction>
      <FlexRow>
        <FlexGroup
          onBlur={() => {
            v.validate('schoolId', teacher.schoolId, teacher);
          }}
        >
          <SchoolSelectBox
            shouldReset={true}
            errorMessage={v.getError('schoolId')}
            label="Select a School"
            onChange={handleSchoolChange}
            records={filterSchools(schools)}
            selection={teacher.schoolId < 0 ? undefined : teacher.schoolId}
            valid={v.getFieldValid('schoolId')}
          />
        </FlexGroup>
        <FlexGroup
          onBlur={() => {
            v.validate('schoolId', teacher.schoolId, teacher);
          }}
        >
          <ProgramInstanceSelectBox
            records={filteredPrograms}
            onChange={handleProgramChange}
            programInstanceId={teacher.programInstanceId}
          />
          {!v.getFieldValid('programInstanceId') && (
            <p className="u-error">{v.getError('programInstanceId')}</p>
          )}
        </FlexGroup>
      </FlexRow>
      <FlexRow>
        <Radio
          border={true}
          errorMessage={v.getError('timeId')}
          label="Instructional Time"
          name="timeId"
          onBlur={() => v.validate('timeId', teacher.timeId, teacher)}
          onChange={handleTimeChange}
          options={createRadioOptionsForBool({
            optionTrue: 'Part Time',
            optionFalse: 'Full Time',
          })}
          valid={v.getFieldValid('timeId')}
          value={getInstructionalTime(teacher.timeId)}
        />
        <FlexGroup />
      </FlexRow>
    </React.Fragment>
  ) : null;
};
