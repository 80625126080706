import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { display, renderData } from 'util/objectUtility';
import { SnapshotStudent } from 'types';
import Input from 'components/Common/FormElements/Input';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';

export const ProgramInformationPS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { enrollment, student } = props;
  const render = display<SnapshotStudent>(student);

  return enrollment && student ? (
    <React.Fragment>
      <FlexRow>
        <Input
          className="input-display"
          disabled={true}
          name=""
          label="Institution Code"
          value={renderData(enrollment.perkinsInstitutionCode)}
        />
        <FlexGroup>
          <LabelWithIcon
            htmlFor="cipAcademic"
            iconName="info"
            label="Academic Cip Code"
            place="right"
            tooltip='The academic 6-digit “Classification of Instructional Program" code. This would be CIP codes that are not for CCCS, CTE approved programs.'
          />
          <Input
            className="input-display"
            disabled={true}
            name=""
            noLabel={true}
            value={render('cipAcademic')}
          />
        </FlexGroup>
        <FlexGroup>
          <LabelWithIcon
            htmlFor="cipAcademic"
            iconName="info"
            label="CTE Cip Code"
            place="right"
            tooltip="The CTE 6-digit “Classification of Instructional Program” code for the current program."
          />
          <Input
            className="input-display"
            disabled={true}
            name=""
            noLabel={true}
            value={render('cipCode')}
          />
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
