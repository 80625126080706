import { connect } from 'react-redux';
import { GrantApplicationFilterComponent } from './GrantApplicationFilterComponent';
import {
  setPerkinsGrantFilterValue,
  resetPerkinsGrantFilterValue,
  clearPerkinsGrantFilters,
} from 'redux/filters/perkinsgrantsfilter';
import { getRecords_PerkinsGrantFilterState } from 'redux/filters/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const filter = getRecords_PerkinsGrantFilterState(state);
  return { filter };
};

const dispatchToProps = {
  setPerkinsGrantFilterValue,
  resetPerkinsGrantFilterValue,
  clearPerkinsGrantFilters,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(GrantApplicationFilterComponent);
