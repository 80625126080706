import { connect } from 'react-redux';
import { NonCteScheduleComponent } from './NonCteSchedule.component';
import {
  loadNonCTECourses,
  saveNonCTECourses,
} from 'redux/cta/InstructionalCosts/ctanonctecourses';
import { getRecords } from 'redux/utility/_exports';
import { CTA_NON_CTE_COURSE_PATH } from 'redux/cta/_paths';
import { CtaInstructorCourse } from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';
import { getRecords_CTASchool } from 'redux/cta/_selectors';
import { loadCTASchools } from 'redux/cta/ctageneral';

const mapStateToProps = (state: any) => {
  const courses = getRecords<CtaInstructorCourse>(
    state,
    CTA_NON_CTE_COURSE_PATH
  );
  const schools = getRecords_CTASchool(state);
  return {
    schools,
    courses,
  };
};

const dispatchProps = {
  loadCTASchools,
  loadNonCTECourses,
  saveNonCTECourses,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(NonCteScheduleComponent);
