import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ReportDetail from 'components/Reports/ReportDetail';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export const ReportPage = (props: any) => {
  return (
    <BaseLayout>
      <ReportDetail {...props} />
    </BaseLayout>
  );
};
