import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import {
  updateCTECourses,
  readCTECourses,
} from 'services/cta/ctainstructionalcosts.services';
import { CtaInstructorCourse } from 'types/cta/InstructionalCosts/CtaInstructorCourse.types';

const initialState = baseInitialState;

export const loadCTECourses = createActionThunk(
  'loadCTECourses',
  (ctaInstructorId: number) => {
    return readCTECourses(ctaInstructorId);
  }
);

export const saveCTECourses = createActionThunk(
  'saveCTECourses',
  (ctaId: number, ctaInstructorId: number, records: CtaInstructorCourse[]) => {
    return updateCTECourses(ctaId, ctaInstructorId, records);
  }
);

export default handleActions<BaseStateObject, CtaInstructorCourse[]>(
  {
    ...baseLoadActionBuilder<CtaInstructorCourse[]>(loadCTECourses),
    ...baseSaveActionBuilder(saveCTECourses),
  },
  initialState
);
