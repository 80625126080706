import React, { useState, useEffect } from 'react';
import { District, ItemFilter, School, UniversalContact } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { find, filter, map, get } from 'lodash';
import { upsertInPlace } from 'util/arrayUtility';
import { getReduxFilterFromItemFilterArray } from 'util/filterUtility';
import { userHasPerkinsRole, isSuperAdmin } from 'util/permissionsUtility';
import { PerkinsContactToggle } from './PerkinsContactToggle';
import { compose, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import DistrictSelectBox from 'components/Common/SelectBoxes/DistrictSelectBox';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import SchoolSelectBox from 'components/Common/SelectBoxes/SchoolSelectBox';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';
import Toggle from 'components/Common/FormElements/Toggle';
import { CTASupervisorToggle } from './CTASupervisorToggle';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';

type DistrictSchoolInfoProps = {
  contactDistricts: District[];
  contactSchools: School[];
  districts: District[];
  onChange: Function;
  schools: School[];
  loggedInUser: UniversalContact;
};

export const DistrictSchoolInfo: React.FC<DistrictSchoolInfoProps> = (
  props
) => {
  const {
    contactDistricts,
    contactSchools,
    districts,
    onChange,
    schools,
    loggedInUser,
  } = props;

  const updateDistricts = (districts: District[]) => {
    onChange({ districts });
  };

  const updateSchools = (schools: School[]) => {
    onChange({ schools });
  };

  const toggleDistrictIsCTASupervisor = (districtId: number) => {
    const value = find(contactDistricts, (item: District) => {
      return districtId === item.districtId;
    });
    if (value) {
      const updates = upsertInPlace(
        contactDistricts,
        { ...value, isCTASupervisor: !value.isCTASupervisor },
        'districtId'
      );
      updateDistricts(updates);
    }
  };

  const toggleDistrictIsPrimary = (districtId: number) => {
    const value = find(contactDistricts, (item: District) => {
      return districtId === item.districtId;
    });
    if (value) {
      const updates = upsertInPlace(
        contactDistricts,
        { ...value, isPrimary: !value.isPrimary },
        'districtId'
      );
      updateDistricts(updates);
    }
  };

  const toggleDistrictIsPerkinsPrimary = (districtId: number) => {
    const value = find(contactDistricts, (item: District) => {
      return districtId === item.districtId;
    });
    if (value) {
      const updates = upsertInPlace(
        contactDistricts,
        { ...value, isPerkinPrimary: !value.isPerkinPrimary },
        'districtId'
      );
      updateDistricts(updates);
    }
  };

  const toggleSchoolIsPrimary = (schoolId: number) => {
    const value = find(contactSchools, (item: School) => {
      return schoolId === item.schoolId;
    });
    if (value) {
      const updates = upsertInPlace(
        contactSchools,
        { ...value, isPrimary: !value.isPrimary },
        'schoolId'
      );
      updateSchools(updates);
    }
  };

  const toggleSchoolIsCTASupervisor = (schoolId: number) => {
    const value = find(contactSchools, (item: School) => {
      return schoolId === item.schoolId;
    });
    if (value) {
      const updates = upsertInPlace(
        contactSchools,
        { ...value, isCTASupervisor: !value.isCTASupervisor },
        'schoolId'
      );
      updateSchools(updates);
    }
  };

  // This will filter schools based on the selected district
  const [schoolFilter, setSchoolFilter] = useState<ItemFilter>({
    property: 'districtId',
    values: -2,
  });

  useEffect(() => {
    const generateSchoolFilter = () => {
      if (isSuperAdmin(loggedInUser)) {
        return undefined;
      }
      return {
        property: 'schoolId',
        values: map(loggedInUser.schools, (item): number => {
          return item.schoolId;
        }),
      };
    };
    if (
      loggedInUser.universalContactId > 0 &&
      loggedInUser.districts.length <= 0
    ) {
      const filter = generateSchoolFilter();
      setSchoolFilter(filter ? filter : { property: 'districtId', values: -2 });
    }
  }, [setSchoolFilter, loggedInUser]);

  // --- Start Districts ---
  const [selectedDistrict, setSelectedDistrict] = useState<number>();

  const deleteDistrict = (value: District) => {
    const updates = filter(contactDistricts, (item) => {
      return item.districtId !== value.districtId;
    });
    updateDistricts(updates);
  };

  const handleDistrictChange = (data: any, key: any, value: number) => {
    setSelectedDistrict(value);
    if (value > 0) {
      setSchoolFilter({
        property: key,
        values: value,
      });
    }
  };

  const addDistrict = () => {
    if (!selectedDistrict) return;
    const district = find(districts, (item) => {
      return item.districtId === selectedDistrict;
    });

    if (district) {
      const updates = upsertInPlace(contactDistricts, district, 'districtId');
      updateDistricts(updates);
    }
  };

  const generateDistrictFilter = () => {
    if (isSuperAdmin(loggedInUser)) {
      return undefined;
    }
    return {
      property: 'districtId',
      values: map(loggedInUser.districts, (item): number => {
        return item.districtId;
      }),
    };
  };
  // --- End Districts ---

  // --- Start Schools ---
  const [selectedSchool, setSelectedSchool] = useState<number>(-1);

  const deleteSchool = (value: School) => {
    const updates = filter(contactSchools, (item) => {
      return item.schoolId !== value.schoolId;
    });
    updateSchools(updates);
  };

  const handleSchoolChange = (data: any, key: any, values: number) => {
    setSelectedSchool(values);
  };

  const addSchool = () => {
    if (!selectedSchool) return;
    const school = find(schools, (item: School) => {
      return item.schoolId === selectedSchool;
    });

    if (school) {
      const updates = upsertInPlace(contactSchools, school, 'schoolId');
      updateSchools(updates);
    }
  };
  // --- End Schools ---

  const SchoolName = compose(renderData, prop('schoolName'));

  const SCHOOL_HEADERS: TableColumnProps[] = [
    {
      name: 'Primary',
      sortable: false,
      cell: (row: School) => (
        <Toggle
          name="isPrimary"
          value={row.isPrimary}
          onChange={() => {
            toggleSchoolIsPrimary(row.schoolId);
          }}
          noLabel={true}
        />
      ),
      width: '12.8rem',
    },
    {
      name: 'CTA Supervisor',
      cell: (row: School) => {
        return get(row, 'isCharterSchool', false) === true ? (
          <SecureWrap
            component={'CTAAdmin'}
            owner={
              userHasPerkinsRole(loggedInUser)
                ? loggedInUser.universalContactId
                : -1
            }
            noAccessComponent={
              <Toggle
                name="isCTASupervisor"
                value={row.isCTASupervisor}
                onChange={() => {}}
                noLabel={true}
                disabled={true}
              />
            }
          >
            <React.Fragment>
              <CTASupervisorToggle
                onChange={toggleSchoolIsCTASupervisor}
                schoolOrDistrictId={row.schoolId}
                isCTASupervisor={row.isCTASupervisor}
              />
            </React.Fragment>
          </SecureWrap>
        ) : (
          <span>{EMPTY_PLACEHOLDER}</span>
        );
      },
      width: '15rem',
    },
    {
      name: 'School Name',
      cell: SchoolName,
      sortable: true,
      selector: SchoolName,
      style: {
        flexGrow: '2',
      },
    },
    {
      width: '6rem',
      ignoreRowClick: true,
      sortable: false,
      name: '',
      cell: (row: School) => {
        return (
          <IconButton
            tooltip="Delete"
            className="icon-button__icon--delete"
            name="crossCircle"
            onClick={() => {
              deleteSchool(row);
            }}
          />
        );
      },
    },
  ];

  const DistrictName = compose(renderData, prop('districtName'));
  const DISTRICT_HEADERS: TableColumnProps[] = [
    {
      name: 'District Name',
      cell: DistrictName,
      sortable: true,
      selector: DistrictName,
      style: {
        flexGrow: '2',
      },
    },
    {
      name: 'Programs Primary',
      cell: (row: District) => (
        <Toggle
          name="isPrimary"
          value={row.isPrimary}
          onChange={() => {
            toggleDistrictIsPrimary(row.districtId);
          }}
          noLabel={true}
        />
      ),
      width: '15rem',
    },
    {
      name: 'Perkins Primary',
      cell: (row: District) => (
        <SecureWrap
          component={'grants'}
          requireAdmin={true}
          owner={
            userHasPerkinsRole(loggedInUser)
              ? loggedInUser.universalContactId
              : -1
          }
          noAccessComponent={
            <Toggle
              name="isPerkinPrimary"
              value={row.isPerkinPrimary}
              onChange={() => {}}
              noLabel={true}
              disabled={true}
            />
          }
        >
          <React.Fragment>
            <PerkinsContactToggle
              onChange={toggleDistrictIsPerkinsPrimary}
              schoolOrDistrictId={row.districtId}
              isPerkinPrimary={row.isPerkinPrimary}
            />
          </React.Fragment>
        </SecureWrap>
      ),
      width: '15rem',
    },
    {
      name: 'CTA Supervisor',
      cell: (row: District) => (
        <SecureWrap
          component={['CTAAdmin', 'universalcontacts']}
          owner={
            userHasPerkinsRole(loggedInUser)
              ? loggedInUser.universalContactId
              : -1
          }
          noAccessComponent={
            <Toggle
              name="isCTASupervisor"
              value={row.isCTASupervisor}
              onChange={() => {}}
              noLabel={true}
              disabled={true}
            />
          }
        >
          <React.Fragment>
            <CTASupervisorToggle
              onChange={toggleDistrictIsCTASupervisor}
              schoolOrDistrictId={row.districtId}
              isCTASupervisor={row.isCTASupervisor}
            />
          </React.Fragment>
        </SecureWrap>
      ),
      width: '15rem',
    },
    {
      name: 'Delete',
      cell: (row: District) => {
        return (
          <IconButton
            tooltip="Delete"
            className="icon-button__icon--delete"
            name="crossCircle"
            onClick={() => {
              deleteDistrict(row);
            }}
          />
        );
      },
      width: '6rem',
    },
  ];
  return (
    <div className="form__section">
      <Instruction title="Add Districts and Schools">
        <p>
          Follow the steps below to first add a district and then a school.
          Note: the school dropdown is always filtered based on the selected
          district.
        </p>
        <br />
        <p className="u-bold">To add a district:</p>
        <ol>
          <li>Click the district dropdown and select a district.</li>
          <li>Click "Add District".</li>
          <li>You can repeat this as many times as necessary. </li>
        </ol>
        <br />
        <p className="u-bold">To add a school:</p>
        <ol>
          <li>
            Click the district dropdown and select the district that the school
            belongs to (do <span className="u-bold">not</span> click "Add
            District").
          </li>
          <li>Click the school dropdown and select the school.</li>
          <li>Click "Add School".</li>
          <li>You can repeat this as many times as necessary. </li>
        </ol>
      </Instruction>
      <FlexRow>
        <FlexGroup>
          <FlexRow>
            <DistrictSelectBox
              filter={generateDistrictFilter()}
              onChange={handleDistrictChange}
            />
            <Button
              className="button--dsid button--outline"
              text="Add district"
              onClick={addDistrict}
            />
          </FlexRow>
          <Table columns={DISTRICT_HEADERS} data={contactDistricts} />
        </FlexGroup>
        <FlexGroup>
          <FlexRow>
            <SchoolSelectBox
              filter={getReduxFilterFromItemFilterArray([schoolFilter])}
              onChange={handleSchoolChange}
            />
            <Button
              className="button--dsid button--outline"
              text="Add school"
              onClick={addSchool}
            />
          </FlexRow>
          <Table columns={SCHOOL_HEADERS} data={contactSchools} />
        </FlexGroup>
      </FlexRow>
    </div>
  );
};
