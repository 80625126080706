import React from 'react';
import Icon from 'components/Common/Icons';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { FlexGroup } from 'layouts';
import { defaultTo, every, first, get, map, some } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { WorkflowStep, WorkflowUser } from 'types/workflow';
import { isEqual } from 'util/formHelpers';
import { compose, prop, renderData } from 'util/objectUtility';

export type WorkflowCardProps = {
  applicationId?: number;
  workflowItem: WorkflowStep;
  isSummary: boolean;
  isOdd?: boolean;
  isFirst?: boolean;
  flat?: boolean;
};

export const WorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  const {
    applicationId,
    workflowItem,
    isSummary,
    isFirst,
    flat = false,
  } = props;

  // this component is not in use outside of programs
  const { entityType }: any = useParams();

  const flatStyle = `${flat ? 'workflow-card--flat' : ''}`;
  const getCardStyle = () => {
    if (isFirst) {
      return flat
        ? `workflow-card workflow-card--blue ${flatStyle}`
        : `workflow-card workflow-card--blue`;
    }
    return flat ? `workflow-card ${flatStyle}` : 'workflow-card';
  };

  const actionId = compose(
    isEqual(0),
    prop('stepActionId'),
    first,
    prop('workflowActions')
  );

  const commentIsBad = () => {
    const acomment = defaultTo(get(workflowItem, 'adminComment'), '');
    return some([
      acomment.trim() === 'auto',
      acomment.trim() === 'admin: auto',
      acomment.trim() === 'admin:',
    ]);
  };

  return (
    <React.Fragment>
      {workflowItem && (
        <div className={getCardStyle()}>
          {isSummary && (
            <Link
              to={`/this-is-a-TODO/${entityType}/${applicationId}`}
              className="workflow-card__cta"
            >
              <p className="workflow-card__view-history">
                View Workflow History
              </p>
              <Icon name="clockHistory" className="workflow-card__icon" />
            </Link>
          )}
          <h2 className="workflow-card__title">{workflowItem.title}</h2>
          <div className="workflow-card__info">
            <div className="workflow-card__label-group workflow-card__label-group--date">
              <label className="workflow-card__label">Date</label>
              {actionId(workflowItem) ? (
                <p className="workflow-card__value">{EMPTY_PLACEHOLDER}</p>
              ) : (
                <p className="workflow-card__value">
                  {new Date(
                    Date.parse(workflowItem.date.toString())
                  ).toLocaleDateString()}
                </p>
              )}
            </div>

            {isFirst ? (
              <div className="workflow-card__label-group">
                <label className="workflow-card__label">Assigned To</label>
                <div className="workflow-card__value--blue">
                  <FlexGroup>
                    {map(
                      workflowItem.approvers,
                      (user: WorkflowUser, index: number) => {
                        return user.name.trim().length > 0 ? (
                          <p key={index}>{renderData(user.name)}</p>
                        ) : (
                          <a key={index} href={`mailto:${user.email}`}>
                            {renderData(user.name)}
                          </a>
                        );
                      }
                    )}
                  </FlexGroup>
                </div>
              </div>
            ) : (
              <div className="workflow-card__label-group">
                <label className="workflow-card__label">Modified By</label>
                <p className="workflow-card__value">
                  {workflowItem.modifiedBy.name.trim().length > 0 ? (
                    <a href={`mailto:${workflowItem.modifiedBy.email}`}>
                      {renderData(workflowItem.modifiedBy.name)}
                    </a>
                  ) : (
                    <React.Fragment>
                      {renderData(workflowItem.modifiedBy.name)}
                    </React.Fragment>
                  )}
                </p>
              </div>
            )}
          </div>
          {workflowItem.comment && (
            <div className="workflow-card__label-group--comments">
              <label className="workflow-card__label">
                {`${workflowItem.modifiedBy.name} `}Comments
              </label>
              <p className="workflow-card__comments">{workflowItem.comment}</p>
            </div>
          )}
          {every([workflowItem.adminComment, !commentIsBad()]) && (
            <div className="workflow-card__label-group--comments">
              <label className="workflow-card__label">
                {`${workflowItem.modifiedBy.name} `}Admin Comments
              </label>
              <p className="workflow-card__comments">
                {workflowItem.adminComment}
              </p>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
