import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import CTACourseSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTACourseSelectBox';
import DirtyDataWarning from 'components/Common/DirtyDataWarning';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect, useRef } from 'react';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTACourse, CTACourseTerm, CTASupportDoc } from 'types';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import { EditCTACourse } from 'components/CTA/DistrictReporting/Common/EditCTACourse';
import { FlexGroup, FlexRow } from 'layouts';
import { all } from 'ramda';
import { find, some } from 'lodash';
import {
  getCTADistrictRecord,
  getRecords_CTACourseContracting,
  getRecords_CTAContractingHost,
  getIsDataFetching_CTAContractingHost,
  getIsDataFetching_CTADistrictRecord,
  getIsDataFetching_CTACourseContracting,
} from 'redux/cta/_selectors';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { isEqual } from 'util/formHelpers';
import { loadCTAContractingCourse } from 'redux/cta/contracting/ctacontractingcourse';
import { loadCTASingleDistrictRecord } from 'redux/cta/ctadashboard';
import {
  mergeObjects,
  safeGet,
  renderData,
  compose,
  not,
  prop,
  isTruthy,
} from 'util/objectUtility';
import { readCTADistrictName } from 'services/cta/ctageneral.services';
import {
  saveCTAContractingHost,
  loadCTAContractingHost,
} from 'redux/cta/contracting/ctacontractinghost';
import { toast } from 'react-toastify';
import {
  updateCTASupportDoc,
  readCTASupportDoc,
} from 'services/cta/ctacontracting.services';
import { upsertInPlace, fmap } from 'util/arrayUtility';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

export const EditCTAContract: React.FC = () => {
  // -- dependencies --
  const { ctaId: cId, ctaHostingId: chId }: any = useParams();
  const ctaId = Number(cId);
  const ctaHostingId = Number(chId);
  const history = useHistory();
  const dispatch = useDispatch();
  const { validateAll } = CTACourseValidation();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local states --
  const mountedRef = useRef(true);
  const [courseState, setCourseState] = useState<CTACourse[]>([]);
  const [courseWarning, setCourseWarning] = useState<boolean>(false);
  const [dirtyCourses, setDirtyCourses] = useState<boolean>(false);
  const [dirtyFilePath, setDirtyFilePath] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string>('');
  const [filePathWarning, setFilePathWarning] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [hostName, setHostName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [submissionFailed, setSubmitFailed] = useState<boolean>(false);
  const ctaRecord = useSelector(getCTADistrictRecord(ctaId));
  const contractingCourses = useSelector(getRecords_CTACourseContracting);
  const hostCourses = useSelector(getRecords_CTAContractingHost);
  const isLoading = some([
    useSelector(getIsDataFetching_CTAContractingHost),
    useSelector(getIsDataFetching_CTADistrictRecord),
    useSelector(getIsDataFetching_CTACourseContracting),
  ]);

  // -- component logic --
  const validateAllCourses = compose(all(isTruthy), fmap(validateAll));
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const handleTermsChange = (course: CTACourse) => (terms: CTACourseTerm[]) => {
    const updatedCourse = mergeObjects(course, { terms });
    const upserted = upsertInPlace(courseState, updatedCourse, 'ctaCourseId');
    setCourseState(upserted);
    setDirtyCourses(true);
  };
  const handleFileUpload = (file: string = '') => {
    setDirtyFilePath(true);
    setFilePath(file);
  };
  const addCTACourse = ({ ctaCourseId }: Partial<CTACourse>) => {
    setDirtyCourses(true);
    const exists = find(courseState, { ctaCourseId });
    if (!exists) {
      const course = find(contractingCourses, { ctaCourseId });
      if (course) {
        const upserted = upsertInPlace(courseState, course, 'ctaCourseId');
        setCourseState(upserted);
      }
    }
  };
  const deleteCourse = (ctaCourseId: number) => {
    const updated = courseState.filter(
      compose(not, isEqual(ctaCourseId), prop('ctaCourseId'))
    );
    setCourseState(updated);
    setDirtyCourses(true);
  };
  const handleCourseSave = () => {
    toggleHasRequested();
    const canSave = validateAllCourses(courseState);
    if (canSave) {
      dispatch(saveCTAContractingHost(ctaId, ctaHostingId, courseState))
        .then(() => {
          setDirtyCourses(false);
          setSubmitFailed(false);
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  const navigateBack = () => {
    history.push(`/cta/district-reporting/${ctaId}`);
  };

  const handleGoBack = () => {
    if (dirtyCourses) {
      setCourseWarning(true);
    } else if (dirtyFilePath) {
      setFilePathWarning(true);
    } else {
      navigateBack();
    }
  };

  const handleFileSave = () => {
    toggleHasRequested();
    updateCTASupportDoc({
      ctaId,
      ctaHostingId,
      filePath,
    })
      .then(() => {
        setDirtyFilePath(false);
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch((err: any) => console.error(err));
  };

  // -- lifecyle --
  useEffect(() => {
    dispatch(loadCTASingleDistrictRecord(ctaId));
    dispatch(loadCTAContractingHost(ctaId, ctaHostingId));
  }, [dispatch, ctaHostingId, ctaId]);

  useEffect(() => {
    setCourseState(hostCourses);
  }, [hostCourses]);

  useEffect(() => {
    readCTASupportDoc(ctaId, ctaHostingId)
      .then((supportDoc: CTASupportDoc) => {
        mountedRef.current && setFilePath(supportDoc.filePath);
      })
      .catch((err: any) => console.error(err));
  }, [ctaId, ctaHostingId]);

  useEffect(() => {
    readCTADistrictName(ctaHostingId)
      .then((res: string) => mountedRef.current && setHostName(res))
      .catch((err: any) => console.error(err));
  }, [ctaHostingId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // -- display logic --
  const getCTARecord = safeGet(ctaRecord);

  return (
    <SecureWrap component={['ctageneral']} isLocked={readOnly}>
      <ScrollToTop />
      <Loading messageBefore="Loading" isActive={isLoading} />
      <DirtyDataWarning
        open={courseWarning}
        onClose={() => setCourseWarning(false)}
        onCancel={navigateBack}
        onConfirm={() => {
          handleCourseSave();
          setCourseWarning(false);
        }}
      />
      <DirtyDataWarning
        open={filePathWarning}
        onClose={() => setFilePathWarning(false)}
        onCancel={navigateBack}
        onConfirm={() => {
          handleFileSave();
          setFilePathWarning(false);
        }}
      />
      <Paper className="t-contracted-courses">
        <FlexRow>
          <Instruction
            title={`Contracted Courses with ${renderData(hostName)}`}
          >
            <p>
              Click in the ‘Add New Course’ box and select the school, program,
              and course that your student(s) attended. Enter as many courses as
              needed. First enter the credits awarded for each term—the credits
              entered should match the credits that your district gave the
              student on their transcript. Next enter the number of students who
              attended this course and the <b>total cost</b> incurred for all of
              those students. The Administrators’ Handbook includes additional
              detail about allowable costs. The number of terms is populated
              based on the highest number of terms for a school as reported in
              your district’s ‘General Settings’ section not the terms offered
              by the host entity. Use only the terms you need and leave the rest
              blank.
            </p>
            <p>
              <span className="u-color-red">Note</span>: The first line in the
              Course column corresponds to the local course name; the second
              line, beginning with ‘CO’, is the state assigned name. Courses
              highlighted in <span className="u-color-tan">tan</span> indicate
              that the record was added by the host.
            </p>
          </Instruction>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            {courseState.length > 0 ? (
              courseState.map((course: CTACourse, index: number) => {
                return (
                  <EditCTACourse
                    key={index}
                    ctaCourse={course}
                    deleteCourse={deleteCourse}
                    onChange={handleTermsChange(course)}
                    submissionFailed={submissionFailed}
                  />
                );
              })
            ) : (
              <p>No courses have been added.</p>
            )}
          </FlexGroup>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <CTACourseSelectBox
              label="Add New Course"
              loadCourses={loadCTAContractingCourse}
              onChange={addCTACourse}
              selector={getRecords_CTACourseContracting}
            />
          </FlexGroup>
        </FlexRow>
        <FlexRow>
          <div style={{ flexGrow: 1 }} />
          <Button
            className={dirtyCourses ? 'button--filled' : 'button--outline'}
            disabled={hasRequested}
            onClick={handleCourseSave}
            style={{ alignSelf: 'flex-end', height: '5.6rem' }}
            text="Save Contracted Courses"
          />
        </FlexRow>
      </Paper>
      <Paper className="t-support-info">
        <FlexRow>
          <Instruction
            title={`Support Information for ${renderData(hostName)}`}
          >
            <p>
              When you have finished adding contracted course information,
              combine all supporting documentation into a single electronic file
              (PDF or XLS) and upload it using the ‘Upload Document’ field.
              Click on the ‘Save and Return to Overview’ button to return to the
              ‘Contracting Costs’ section where you may report additional
              contracts as needed.
            </p>
            <p>
              <span className="u-color-red">Note</span>: If you upload a second
              document, it will replace the previous one.
            </p>
          </Instruction>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <p className="form__label">Upload Document</p>
            {filePath ? (
              <AzureDownloader
                onClose={handleFileUpload}
                uploadParams={{
                  container: 'cta',
                  folder: `${getCTARecord('year')}|${getCTARecord(
                    'schoolDistrictName'
                  )}|supporting-information`,
                }}
                filepath={filePath}
              />
            ) : (
              <Button
                text="+ Upload File"
                className="form__btn--add u-flex-self-start grant-schedule__upload-btn"
                onClick={() => setOpen(true)}
              />
            )}
            <AzureUploader
              onClose={() => setOpen(false)}
              open={open}
              uploadParams={{
                container: 'cta',
                folder: `${getCTARecord('year')}|${getCTARecord(
                  'schoolDistrictName'
                )}|supporting-information`,
              }}
              onConfirm={handleFileUpload}
            />
          </FlexGroup>
          <div style={{ flexGrow: 1 }} />
          <Button
            className={dirtyFilePath ? 'button--filled' : 'button--outline'}
            disabled={hasRequested}
            onClick={handleFileSave}
            style={{
              alignSelf: 'flex-end',
              height: '5.6rem',
              width: '28rem',
            }}
            text="Save Support Information"
          />
        </FlexRow>
      </Paper>
      <Paper className="t-save-contracting-costs">
        <SaveButtons
          alternateAction={handleGoBack}
          hideCancel
          saveText="Return to CTA Overview"
        />
      </Paper>
    </SecureWrap>
  );
};
