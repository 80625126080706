import { FILTERS_KEY, FILTERS_KEY_UNIVERSALCONTACTS } from 'redux/keys';
import { get, map } from 'lodash';
import { KeyValueType, ReduxFilter, UniversalContact, UniversalContactFilterState } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const UNIVERSAL_CONTACTS_FILTER_PATH = [FILTERS_KEY, FILTERS_KEY_UNIVERSALCONTACTS];

export const getRecords_UniversalContactFilterState = (state: any): UniversalContactFilterState => {
  return get(state, UNIVERSAL_CONTACTS_FILTER_PATH);
};

export const getUniversalContactFilters = (state: any) => {
  const filterValues: KeyValueType<unknown> = (getRecords_UniversalContactFilterState(
    state
  ) as unknown) as KeyValueType<unknown>;

  const activeFilters = getFiltersWithValues(filterValues);

  const noFilter = {
    filter: {
      exclude: true,
      property: 'no-filter',
      values: 'nothing',
    },
    operator: 'and',
  };

  const filters = map(
    activeFilters,
    (item): ReduxFilter<UniversalContact> => {
      switch (item) {
        case 'districtId':
          if (Number(filterValues[item]) > 0) {
            return {
              filter: {
                arrayCompare: true,
                property: 'districtIds',
                values: filterValues[item] !== undefined ? filterValues[item] : '',
              },
              operator: 'and',
            };
          }
          return noFilter;
        case 'instituteId':
          if (Number(filterValues[item]) > 0) {
            return {
              filter: {
                arrayCompare: true,
                property: 'instituteIds',
                values: filterValues[item] !== undefined ? filterValues[item] : '',
              },
              operator: 'and',
            };
          }
          return noFilter;
        case 'isTeacher':
          return {
            filter: {
              property: 'isTeacher',
              values: filterValues[item] !== undefined ? filterValues[item] : '',
            },
            operator: 'and',
          };
        case 'isActive':
          return {
            filter: {
              property: 'isActive',
              values: filterValues[item] !== undefined ? filterValues[item] : '',
            },
            operator: 'and',
          };
        default:
          return noFilter;
      }
    }
  );
  return filters;
};
