import React, { useState, useEffect } from 'react';
import { map, some } from 'lodash';
import { CollapsibleListItem } from 'types/common/CollapsibleListItems';
import { CollapsibleList } from '@rmwc/list';
import IconButton from 'components/Common/IconButton';
import Skeleton from '../Skeleton';

type CollapsibleListContainerProps = {
  header: string;
  indicesToModify?: number[];
  tooltip?: string;
  allOpen?: boolean;
  mainToggleText?: {
    open: string;
    close: string;
  };
  items: CollapsibleListItem[];
};

export const CollapsibleListContainer: React.FC<
  CollapsibleListContainerProps
> = (props) => {
  const {
    header,
    indicesToModify = [],
    allOpen,
    mainToggleText = {
      open: 'Open All',
      close: 'Close All',
    },
    items,
  } = props;

  const createState = (bool: boolean = false): boolean[] => {
    const newState: boolean[] = map(items, (child: any, index: number) => {
      if (indicesToModify.length > 0) {
        if (
          some(indicesToModify, (testIndex: number) => {
            return testIndex === index;
          })
        ) {
          return bool;
        } else {
          return false;
        }
      }
      return bool;
    });
    return newState;
  };
  const [state, setState] = useState<boolean[]>(
    allOpen ? createState(allOpen) : createState()
  );

  useEffect(() => {
    if (indicesToModify.length > 0) {
      setState(createState(true));
    }
  }, [indicesToModify]); // eslint-disable-line

  const ToggleAll = (bool: boolean) => {
    const newState = map(items, () => {
      return bool;
    });
    setState(newState);
  };

  const generateIcon = (index: number, bool: boolean) => {
    return (
      <IconButton
        tooltip="Expand"
        className="u-icon-blue"
        name={bool ? 'expandLess' : 'expandMore'}
        onClick={() => handleToggle(index, !bool)}
      />
    );
  };

  const generateToggleAllIcon = () => {
    const anyOpen = some(state);
    return (
      <IconButton
        tooltip="Expand"
        className="u-icon-blue"
        textClassName="u-bold"
        name={anyOpen ? 'expandAllLess' : 'expandAllMore'}
        onClick={() => ToggleAll(!anyOpen)}
        text={anyOpen ? mainToggleText.close : mainToggleText.open}
      />
    );
  };

  const handleToggle = (index: number, bool: boolean) => {
    const newState = [...state];
    newState[index] = bool;
    setState(newState);
  };

  const mapChildren = (item: any) => {
    React.Children.map(item, (child: any) => {
      const localProps = { tabIndex: -1 };
      const clone = React.isValidElement(child)
        ? React.cloneElement(child, localProps)
        : child.item;
      return clone;
    });
  };

  const getChildren = (isOpen: boolean, item: any) => {
    return isOpen ? item : mapChildren(item);
  };

  return items.length < 1 ? (
    <Skeleton />
  ) : (
    <div className="collapsible-list-container">
      <div className="u-flex-between collapsible-list-container__header ">
        {header}
        <div className="u-sec-ro--override">{generateToggleAllIcon()}</div>
      </div>
      {map(items, (child, index) => {
        const isOpen = state[index];
        return (
          <CollapsibleList
            key={index}
            open={isOpen}
            handle={
              <div
                className="collapsible-list-container__item u-sec-ro--override"
                onClick={() => handleToggle(index, !state[index])}
              >
                <h2
                  className="collapsible-list-container__item--title"
                  style={{ opacity: 1 }}
                >
                  {child.itemTitle}
                </h2>
                {generateIcon(index, state[index])}
              </div>
            }
          >
            <div className="collapsible-list-container__body form__row-no-margin u-color-light-blue">
              {getChildren(isOpen, child.item)}
            </div>
          </CollapsibleList>
        );
      })}
    </div>
  );
};
