import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollTotop';
import { Consortium, FiscalYear } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { compose } from 'redux';
import { prop, getDate, renderData } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import ConsortiaFilter from '../ConsortiaFilter';
import FilterIconButton from 'components/Common/Filters/FilterIconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';

type ConsortiumManagementDashboardProps = {
  consortia: Consortium[];
  filters: number;
  isFetching: boolean;
  currentFiscalYear: FiscalYear;
};

export const ConsortiumManagementDashboard: React.FC<
  ConsortiumManagementDashboardProps
> = (props) => {
  const { filters, consortia, isFetching, currentFiscalYear } = props;
  const [filteredData, setFilteredData] = useState<Consortium[]>([
    ...consortia,
  ]);
  const [filterOpen, setFiltersOpen] = useState<boolean>(false);
  const history = useHistory();

  const InstitutionCode = compose(
    renderData,
    prop('institutionCode'),
    prop('fiscalAgent')
  );

  const VendorCode = compose(
    renderData,
    prop('vendorCode'),
    prop('fiscalAgent')
  );

  const Status = (row: Consortium) => (row.status ? 'Active' : 'Inactive');

  const FiscalYear = compose(
    renderData,
    prop('year'),
    prop('fiscalYear')
  );

  const LastModified = compose(
    renderData,
    getDate,
    prop('modifiedDate')
  );

  const ConsortiumName = compose(
    renderData,
    prop('consortiumName')
  );

  const handleClick = (row: Consortium) => {
    history.push(
      `/grants/consortium/${row.consortiumId}/${row.fiscalYear.year}`
    );
  };

  const CONSORTIUM_DASHBOARD_COLUMNS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: ConsortiumName,
      sortable: true,
      selector: 'consortiumName',
    },
    {
      name: 'Institution Code',
      cell: InstitutionCode,
      sortable: true,
      selector: InstitutionCode,
    },
    {
      name: 'Vendor Code',
      cell: VendorCode,
      sortable: true,
      selector: VendorCode,
    },
    {
      name: 'Status',
      cell: Status,
      sortable: true,
      selector: Status,
    },
    {
      name: 'Fiscal Year',
      cell: FiscalYear,
      sortable: true,
      selector: FiscalYear,
    },
    {
      name: 'Last Modified',
      cell: LastModified,
      sortable: true,
      selector: LastModified,
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} messageBefore="Loading Consortia..." />
      <Paper>
        <Instruction
          actionButton={
            <SecureWrap component="grantsconsortia" requireEdit={true}>
              <Button
                onClick={() =>
                  history.push(
                    `consortium/add-edit/-1/${currentFiscalYear.year}`
                  )
                }
                text="Create New Consortium"
                className="button--filled"
              />
            </SecureWrap>
          }
          title="Consortium Management"
        >
          <p>
            This page enables you to view, edit, and create consortia. Select a
            row to view or edit a consortium. Use "Search for Consortia" to find
            a consortium you want to view or edit. The table is searchable with
            the start of the text in any column. Columns with a down or up-arrow
            button can sort the table in ascending or descending order by that
            column. Users with enabled permission can create a new consortium by
            using "Create New Consortium". Districts and institutions not in a
            consortium will be classified as ‘Standalone’ in the system.
          </p>
          <p className="u-error">
            Please check that a consortium does not exist before creating a new
            one.
          </p>
        </Instruction>
      </Paper>
      <FlexRow>
        <FlexGroup>
          <Paper>
            <FlexRow>
              <SearchBarFilter
                data={consortia}
                headers={CONSORTIUM_DASHBOARD_COLUMNS}
                isFetching={isFetching}
                label="Search for Consortia"
                setFilteredData={setFilteredData}
              />
              <FilterIconButton
                count={filters}
                onClick={() => {
                  setFiltersOpen(!filterOpen);
                }}
              />
            </FlexRow>
            <Table
              columns={CONSORTIUM_DASHBOARD_COLUMNS}
              data={filteredData}
              onRowClick={handleClick}
            />
          </Paper>
        </FlexGroup>
        {filterOpen && (
          <div className="u-filters">
            <ConsortiaFilter />
          </div>
        )}
      </FlexRow>
    </React.Fragment>
  );
};
