import { removeCTAHostingContract } from 'redux/cta/hosting/ctahostingcontract';
import { loadCTAHosting } from 'redux/cta/hosting/ctahosting';

export const ctaHostingMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case removeCTAHostingContract.SUCCEEDED: {
      dispatch(loadCTAHosting(action.payload));
      break;
    }
  }
};
