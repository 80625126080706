import React from 'react';
import StickyBox from 'react-sticky-box';
import { LoadingState } from 'types/applicationstate.types';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import CommonDataHydrator from 'components/DataHydrators/CommonDataHydrator';
import GrantsDataHydrator from 'components/DataHydrators/GrantsDataHydrator';
import ProgramDataHydrator from 'components/DataHydrators/ProgramDataHydrator';
import DataCollectionHydrator from 'components/DataHydrators/DataCollectionHydrator';
import Header from 'components/Common/Site/Header';
import Footer from 'components/Common/Site/Footer';
import CTAHydrator from 'components/DataHydrators/CTAHydrator';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

export type BaseLayoutProps = {
  loadingState: LoadingState;
};
export const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const { pathname } = useLocation();

  const renderHydrators = () => {
    if (pathname.includes('cta/')) {
      return (
        <React.Fragment>
          <CommonDataHydrator />
          <CTAHydrator />
        </React.Fragment>
      );
    }
    if (pathname.includes('data-collection')) {
      return (
        <React.Fragment>
          <CommonDataHydrator />
          <DataCollectionHydrator />
        </React.Fragment>
      );
    }
    if (pathname.includes('grants')) {
      return (
        <React.Fragment>
          <CommonDataHydrator />
          <GrantsDataHydrator />
        </React.Fragment>
      );
    }
    if (pathname.includes('programs')) {
      return (
        <React.Fragment>
          <CommonDataHydrator />
          <ProgramDataHydrator />
        </React.Fragment>
      );
    }
    return <CommonDataHydrator />;
  };

  return (
    <div>
      <ToastContainer
        pauseOnFocusLoss={false}
        style={{ zIndex: 10001 }}
        position="bottom-right"
        autoClose={15000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
      />
      <StickyBox offsetBottom={168} style={{ zIndex: 7 }}>
        <Header />
      </StickyBox>
      {renderHydrators()}
      <div className="one-page__content">
        <SecureWrap component="override" override={true}>
          <main className="one-page__main">{props.children}</main>
        </SecureWrap>
        <Footer />
      </div>
    </div>
  );
};
