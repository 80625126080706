import {
  baseInitialState,
  actionUpdateRecords,
  actionFetching,
} from 'redux/utility/_exports';
import { WORKFLOW_CTA_KEY, WORKFLOW_KEY } from 'redux/keys';
import { compose, prop } from 'util/objectUtility';
import { first } from 'lodash';
import { isEqual, trace } from 'util/formHelpers';

export const SET_CTA_WORKFLOW = `${WORKFLOW_CTA_KEY} SET`;
export const SET_CTA_WORKFLOW_LOADING = `${WORKFLOW_CTA_KEY} SET LOADING`;

// -- actions ------------------------------------------------------------------
export const setctaWorkflow = (payload: any) => {
  return {
    type: SET_CTA_WORKFLOW,
    payload,
  };
};

export const setctaWorkflowLoading = (payload: boolean) => {
  return {
    type: SET_CTA_WORKFLOW_LOADING,
    payload,
  };
};

// -- reducer ------------------------------------------------------------------
const initState = baseInitialState;

export const ctaWorkflowReducer = (workflow = initState, action: any) => {
  switch (action.type) {
    case SET_CTA_WORKFLOW:
      return actionUpdateRecords(workflow, action.payload);

    case SET_CTA_WORKFLOW_LOADING:
      return actionFetching(workflow, action.payload);

    default:
      return workflow;
  }
};

/**
 * More Info Needed: 66
 * Open For Revision: 69
 * Open: 70
 */
export const EDITABLE_CTA_STATES = [66, 69, 70];

export const getIsCTAReadOnly = (state: any) => {
  // includes :: list -> value -> boolean
  const includes = (list: any[]) => (val: any) => list.includes(val);

  // isEditable :: state -> boolean
  const isNotEditable = compose(
    isEqual(false),
    includes(EDITABLE_CTA_STATES),
    prop('workflowStatusId'),
    first,
    prop('records'),
    prop(WORKFLOW_CTA_KEY),
    prop(WORKFLOW_KEY)
  );
  // console.log(isNotEditable(state));
  return isNotEditable(state);
};
