import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
  toFileBlob,
} from 'services';
import {
  CTAAdminInformation,
  CTAAllocation,
} from 'types/cta/CTAAdminInformation.types';

const ADMIN_BASE = ['api', 'ctaadmin'];

export const readCTAAdminInformation = async (): Promise<
  CTAAdminInformation[]
> => {
  return fetch(getFormattedUrl([...ADMIN_BASE]), { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'readAdminInformation');
    });
};

export const updateCTAAllocation = async (allocation: CTAAllocation) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'CTAAllocation']), {
    ...postWithAuthOptions(),
    body: JSON.stringify(allocation),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'updateCTAAllocation');
    });
};

export const readCTAAllocation = async (year: number) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'CTAAllocation', year]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'readCTAAllocation');
    });
};

export const createSubmissionOpenEmail = async (year: number) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'sendSubmissionEmail', year]), {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'createSubmissionOpenEmail');
    });
};

export const createCalculations = async (year: number) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'runCalculations', year]), {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'createCalculations');
    });
};

export const readGeneralReport = async (year: number) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'downloadReport', year]), {
    ...postWithAuthOptions(),
  })
    .then(toFileBlob)
    .catch((err) => {
      return catchError('CTA Admin', err, 'readGeneralReport');
    });
};

export const createApprovalEmail = async (year: number) => {
  return fetch(getFormattedUrl([...ADMIN_BASE, 'sendApprovalsEmail', year]), {
    ...postWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Admin', err, 'createApprovalEmail');
    });
};
