import { combineReducers } from 'redux';
import {
  PROGRAM_GENERAL_KEY,
  PROGRAM_ASSURANCE_QUESTIONS_KEY,
  PROGRAM_ARTICULATIONS_KEY,
  PROGRAM_AWARD_TYPES_KEY,
  PROGRAM_CONTACTS_KEY,
  PROGRAM_TEMPLATE_KEY,
  PROGRAM_COURSE_TYPES_KEY,
  PROGRAM_POS_KEY,
  PROGRAM_CAREER_PATHWAYS_KEY,
} from 'redux/keys';
import articulations from 'redux/programs/articulations';
import assuranceQuestions from 'redux/programs/assurancequestions';
import awardtypes from 'redux/programs/awardtypes';
import coursetypes from 'redux/programs/coursetypes';
import programBases from 'redux/programs/programbases';
import programcontacts from 'redux/programs/contacts';
import programInstance from 'redux/programs/programinstance';
import programOfStudy from 'redux/programs/programofstudy';
import programcareerpathway from 'redux/programs/programcareerpathway';

export default combineReducers({
  [PROGRAM_TEMPLATE_KEY]: programBases,
  [PROGRAM_GENERAL_KEY]: programInstance,
  [PROGRAM_CONTACTS_KEY]: programcontacts,
  [PROGRAM_AWARD_TYPES_KEY]: awardtypes,
  [PROGRAM_COURSE_TYPES_KEY]: coursetypes,
  [PROGRAM_ASSURANCE_QUESTIONS_KEY]: assuranceQuestions,
  [PROGRAM_ARTICULATIONS_KEY]: articulations,
  [PROGRAM_POS_KEY]: programOfStudy,
  [PROGRAM_CAREER_PATHWAYS_KEY]: programcareerpathway,
});
