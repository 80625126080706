import Button from 'components/Common/FormElements/Button';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState, useRef } from 'react';
import StringResources from 'StringResources';
import Textarea from 'components/Common/FormElements/Textarea';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import { CTA_INSTRUCTORS_PATH } from 'redux/cta/_paths';
import { CtaInstructor, emptyCtaInstructor } from 'types';
import { FlexRow } from 'layouts';
import { find } from 'lodash';
import { fmap } from 'util/arrayUtility';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { getRecords } from 'redux/utility/_exports';
import { isApplicaionIdValid, join, toString } from 'util/global';
import {
  prop,
  getDate,
  renderData,
  compose,
  safeGet,
  concatEnd,
} from 'util/objectUtility';
import { round } from 'util/mathHelpers';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export type CteInstructorReviewProps = {
  forceSave: boolean;
  loadSingleInstructor: Function;
  saveInstructor: Function;
  setDirtyData: Function;
};

export const CteInstructorReview: React.FC<CteInstructorReviewProps> = ({
  forceSave,
  loadSingleInstructor,
  saveInstructor,
  setDirtyData,
}) => {
  // -- dependencies --
  const { ctaInstructorId: ciId, ctaId: ctId } = useParams<any>();
  const ctaInstructorId = Number(ciId);
  const ctaId = Number(ctId);

  // -- local state --
  const mountedRef = useRef(true);
  const [dirtyReview, setDirtyReview] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const instructors = useSelector((state) =>
    getRecords<CtaInstructor>(state, CTA_INSTRUCTORS_PATH)
  );
  const [instructorState, setInstructorState] = useState<CtaInstructor>(
    emptyCtaInstructor()
  );

  // -- component logic --
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const saveChanges = () => {
    toggleHasRequested();
    saveInstructor(ctaId, instructorState)
      .then(() => setDirtyData(false))
      .then(() => setDirtyReview(false))
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch((err: any) => console.error(err));
  };

  // -- lifecycle --
  useEffect(() => {
    if (isApplicaionIdValid(ctaInstructorId)) {
      loadSingleInstructor(ctaInstructorId).catch((err: any) =>
        console.error(err)
      );
    }
  }, [ctaInstructorId, loadSingleInstructor]);

  useEffect(() => {
    if (instructors.length > 0) {
      const instructor = find(instructors, (item) => {
        return item.ctaInstructorId === ctaInstructorId;
      });
      if (instructor) {
        setInstructorState(instructor);
      }
    }
  }, [instructors, ctaInstructorId]);

  useEffect(() => {
    if (forceSave && dirtyReview) {
      saveChanges();
    }
  }, [forceSave]); // eslint-disable-line

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // -- display logic --
  // getTheDate :: instructor data -> date string | empty placeholder
  const getTheDate = compose(renderData, getDate, prop('credentialExpiration'));

  // getProp :: propertyName -> instructor datum
  const get = safeGet(instructorState);

  // displaySchoolNames :: instructorState -> string
  const displaySchoolNames = compose(
    renderData,
    join(', '),
    fmap(prop('schoolName')),
    prop('schools')
  );

  // displayProgramNames :: instructorState -> string
  const displayProgramNames = compose(
    renderData,
    join(', '),
    fmap(prop('programName')),
    prop('programs')
  );
  return (
    <Paper>
      <FlexRow>
        <Instruction title="Instructor Review">
          <p>
            Review the instructor's information and after verifying its
            accuracy, toggle the indicator that the instructor’s schedule and
            salary information is complete. Click the ‘Save’ button when you
            have completed this section.
          </p>
          <br />
          <p>
            If incorrect information is displayed in the ‘School Affiliations,’
            ‘Program Affiliations,’ or ‘Credential Areas’ boxes go to the{' '}
            <Link rel="noreferrer" target="_blank" to="/data-collections">
              Data Collections
            </Link>{' '}
            module and add the missing information to the database. When you are
            finished with Instructional Costs click the ‘Return to Overview’
            button to return to the ‘CTA Reporting’ main page.
          </p>
          <br />
          <p>
            <span className="u-color-red">Note</span>: You will need to toggle
            the information is complete even if you are not reporting data for
            the instructor.
          </p>
        </Instruction>
      </FlexRow>
      <FlexRow>
        <Textarea
          value={displaySchoolNames(instructorState)}
          name="School Affiliations"
          disabled={true}
        />
      </FlexRow>
      <FlexRow>
        <Textarea
          value={displayProgramNames(instructorState)}
          name="Program Affiliations"
          disabled={true}
        />
      </FlexRow>
      <FlexRow>
        <Input
          value={renderData(prop('credentialArea', instructorState))}
          name="Credential Areas"
          disabled={true}
        />
      </FlexRow>

      <FlexRow>
        <Input
          value={compose(
            concatEnd('%'),
            toString,
            round(2),
            prop('ctePercent')
          )(instructorState)}
          name="ctePercent"
          disabled={true}
        />
        <Input value={get('classCount')} name="classCount" disabled={true} />
        <Input value={get('cteCount')} name="cteCount" disabled={true} />
        <Input
          disabled={true}
          name="credentialExpiration"
          value={getTheDate(instructorState)}
        />
        <Input
          disabled={true}
          name="credentialNumber"
          value={get('credentialNumber')}
        />
        <Input
          disabled={true}
          name="salary"
          value={compose(
            insertDollarSign,
            formatMoney,
            prop('salary')
          )(instructorState)}
        />
      </FlexRow>
      <FlexRow>
        <Toggle
          description="The Schedule and Salary Information for this Instructor is Complete"
          name="state"
          onChange={() => {
            setDirtyData(true);
            setDirtyReview(true);
            setInstructorState({
              ...instructorState,
              status: !instructorState.status,
            });
          }}
          value={get('status')}
        />
        <Button
          className={dirtyReview ? 'button--filled' : 'button--outline'}
          disabled={hasRequested}
          onClick={saveChanges}
          style={{
            alignSelf: 'flex-end',
            height: '5.6rem',
            width: '28rem',
          }}
          text="Save Instructor Review"
        />
      </FlexRow>
    </Paper>
  );
};
