import { CTA_KEY, CTA_SUPPORT_PERSONNEL_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTASupportPersonnel, emptyCTASupportPersonnel } from 'types';
import { defaultTo, find } from 'lodash';

export const CTA_SUPPORT_PERSONNEL_PATH = [CTA_KEY, CTA_SUPPORT_PERSONNEL_KEY];

export const getIsDataFetching_CTASupportPersonnel = (state: any): boolean => {
  return getIsDataFetching(state, CTA_SUPPORT_PERSONNEL_PATH);
};

export const getRecords_CTASupportPersonnel = (
  state: unknown
): CTASupportPersonnel[] => {
  return getRecords(state, CTA_SUPPORT_PERSONNEL_PATH);
};

export const getSingleRecord_CTASupportPersonnel = (
  state: unknown,
  ctaSupportPersonnelId: number
): CTASupportPersonnel => {
  const records = getRecords_CTASupportPersonnel(state);
  return defaultTo(
    find(records, (item: CTASupportPersonnel) => {
      return item.ctaSupportPersonnelId === ctaSupportPersonnelId;
    }),
    emptyCTASupportPersonnel()
  );
};
