import React, { useState, useEffect, useRef } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { map, some } from 'lodash';
import { Dialog } from '@rmwc/dialog';
import {
  createFollowUpRecords,
  readFollowupTemplate,
} from 'services/datacollection/excelmanagement.services';
import { useModal, Modal } from 'react-morphing-modal';
import { downloadFileFromAzure } from 'services/azure/azure.services';
import { AzureUploadParameters, StudentEnrollmentData } from 'types';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import DatatableLoading from 'components/Common/DatatableLoading';
import download from 'js-file-download';
import FileDropZone from 'components/Common/FormElements/FileDropZone/FileDropZone';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';

type FollowupUploadProps = {
  programLevels: number[];
  followUpData: StudentEnrollmentData;
};

export const FollowupUploadComponent: React.FC<FollowupUploadProps> = ({
  followUpData,
  programLevels,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { modalProps, open, close } = useModal();
  const modalRef = useRef(null);

  const { followUpComplete, followUpLocked } = followUpData;
  const isLocked = some([followUpComplete, followUpLocked]);

  const uploadFile = async (file: File) => {
    setErrors([]);
    setUploading(true);
    createFollowUpRecords(file)
      .then((response: string[]) => {
        setErrors(response);
        if (response.length === 0) {
          toast(
            <ToastNotification
              status="success"
              message="Student Follow-up uploaded successfully."
            />
          );
        }
      })
      .catch(() => {
        setErrors([StringResources.Errors.InvalidResponse]);
      })
      .finally(() => {
        setUploading(false);
        setOpen(false);
      });
  };

  const downloadTemplate = (programLevelId: number, filePrefix: string) => {
    readFollowupTemplate(programLevelId, -1)
      .then((blob: unknown) => {
        download(blob as Blob, `${filePrefix}_enrollment_followup.xlsx`);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'datacollection',
    folder: '',
  };

  useEffect(() => {
    if (errors.length > 0) {
      open(modalRef);
    }
  }, [errors]); //eslint-disable-line

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <Instruction title="Upload Student Follow-up">
          <p className="u-margin-bottom">
            Use this section to enter follow-up data for identified students.
            Click the ‘Download Student List’ button to download an Excel file
            containing the names of students for whom you are required to
            report. Note that students in multiple programs will have a record
            listed for each program in which they met the follow-up requirement.
          </p>
          <p className="u-margin-bottom">
            Enter data relating to a student’s placement into continuing
            education, military service, apprenticeship, a national service
            program, and/or whether a student completed and passed a
            certification exam. All other data is prepopulated and should not be
            edited. Click on the ‘Get More Guidance’ button to access detailed
            instructions on how to enter your follow-up data.
          </p>
          <p className="u-margin-bottom">
            When you have completed entering your student follow-up data save
            the file to your computer. Click on the ‘Upload Student Follow-up
            File’ button and follow the instructions provided. Note that all
            uploads are validated to ensure that data are correctly formatted
            and include all required fields.
          </p>
          <ul className="u-margin-bottom">
            <li>
              If the validation process is successful, you will receive onscreen
              and an email notification that your upload is complete.
            </li>
            <li>
              If the validation process uncovers errors, you will be provided a
              detailed list of errors onscreen and in an email. You must fix all
              identified errors and resubmit your file.
            </li>
          </ul>
          <p className="u-error">
            Note: Due to a high volume of data, your upload will appear the
            following day.
          </p>
        </Instruction>

        <FlexRow>
          <FlexRow>
            <FlexGroup>
              <h3>Guidance</h3>
              <Button
                disabled={isLocked}
                className="form__btn--cancel u-margin-bottom"
                onClick={() => {
                  downloadFileFromAzure(
                    uploadParams,
                    'guidance|followup_guidance.docx'
                  ).catch(() => {
                    toast(
                      <ToastNotification
                        status="error"
                        message={StringResources.Errors.FileDownloadFailed}
                      />
                    );
                  });
                }}
                text="Get More Guidance"
              />
            </FlexGroup>

            <FlexGroup>
              <h3>Followup Data</h3>
              {map(programLevels, (level: number) => {
                switch (level) {
                  case PROGRAM_LEVEL_ENUM.Secondary:
                    return (
                      <Button
                        disabled={isLocked}
                        key={level}
                        className="form__btn--cancel u-margin-bottom"
                        onClick={() => {
                          downloadTemplate(level, 'secondary');
                        }}
                        text="Download List of Secondary Follow-up Students"
                      />
                    );
                  case PROGRAM_LEVEL_ENUM.Postsecondary:
                    return (
                      <Button
                        disabled={isLocked}
                        key={level}
                        className="form__btn--cancel u-margin-bottom"
                        onClick={() => {
                          downloadTemplate(level, 'postsecondary');
                        }}
                        text="Download List of Postsecondary Follow-up Students"
                      />
                    );
                  default:
                    return null;
                }
              })}
            </FlexGroup>
          </FlexRow>
          <div style={{ marginLeft: '1.8rem' }} />

          <FlexGroup>
            <h3>Upload</h3>
            <Button
              style={{ height: '6rem' }}
              className="form__btn--add grant-schedule__upload-btn"
              onClick={() => {
                !isLocked && setOpen(!isOpen);
              }}
              text="+ Upload Student Follow-up File"
            />
          </FlexGroup>
        </FlexRow>

        <Dialog
          preventOutsideDismiss
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="azure-dialog">
            <div className="azure-dialog__title form__section-title u-flex u-flex-full-center">
              <div className="u-flex-grow-1">Upload files</div>
              {!uploading && (
                <IconButton
                  name="cross"
                  tooltip="Exit"
                  onClick={() => setOpen(false)}
                />
              )}
            </div>
            <div style={{ minWidth: '64rem' }}>
              {uploading ? (
                <DatatableLoading
                  loading={uploading}
                  messageBefore="Uploading File..."
                />
              ) : (
                <FileDropZone
                  onUpload={(incomingFile: File) => {
                    uploadFile(incomingFile);
                  }}
                />
              )}
            </div>
          </div>
        </Dialog>
      </div>
      <Modal {...modalProps}>
        <Paper>
          <h2>File Upload Encountered Errors</h2>
          <div>
            <p>The following errors were encountered during file upload:</p>
            {map(errors, (error: string, index: number) => {
              return (
                <p key={index} className="u-error">
                  {error}
                </p>
              );
            })}
          </div>
          <div className="form__btn-container">
            <Button
              onClick={close}
              className="form__btn--cancel"
              text="Close Error Window"
            />
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
