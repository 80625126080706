import React, { ReactElement } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';
import ScrollLock from 'react-scrolllock';
import { WINDOW_TOP_OFFSET } from 'constants/application.constants';

type LoadingProps = {
  isActive: boolean;
  messageBefore?: string | ReactElement;
  messageAfter?: string | ReactElement;
  size?: number;
  opaque?: boolean;
};

export const Loading: React.FC<LoadingProps> = (props) => {
  const {
    messageBefore,
    messageAfter,
    size = 64,
    opaque = false,
    isActive,
  } = props;
  return (
    <React.Fragment>
      <ScrollLock isActive={isActive} />
      {isActive && (
        <div className={`loading-container${opaque ? '--opaque' : ''}`}>
          <div
            className="loading-container__thing"
            style={{
              top: WINDOW_TOP_OFFSET,
            }}
          >
            <span className="loading-container__spinner">
              {messageBefore && <h3>{messageBefore}</h3>}
              <CircularProgress size={size} />
              {messageAfter && <h3>{messageAfter}</h3>}
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
