import React from 'react';
import { GeneralSummary } from '../ProgramGeneral/ProgramGeneralConnect';
import { ContactsSummary } from '../Contacts/ContactsConnect';
import { ProgramCareerPathwaySummary } from '../CareerPathway/CareerPathwayConnect';
import { CourseCreditsSuperSummary } from '../CourseCredits/CourseCreditsConnect';
import { ProgramAwardsSuperSummary } from '../Awards/AwardsConnect';
import { ArticulationSummary } from '../Articulations/ArticulationsConnect';
import { PathwayCourseSuperSummary } from 'components/Program/PathwayCourses/PathwayCourseConnect';
import { ProgramsOfStudySummary } from '../ProgramsOfStudy/ProgramsOfStudyConnect';
import { AssurancesSummary } from '../Assurances/ProgramAssurancesConnect';
import { WorkflowSummary } from '../ProgramWorkflow/WorkflowConnect';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { Program } from 'types';

export const SuperSummaryComponent: React.FC<{ program: Program }> = (
  props
) => {
  const { program } = props;

  const isMiddleSchool =
    program.programLevelId === PROGRAM_LEVEL_ENUM['Middle School'];
  //const isSecondary = program.programLevelId === PROGRAM_LEVEL_ENUM.Secondary;
  const isPostSecondary =
    program.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary;

  return (
    <React.Fragment>
      <div className="program-step__header">
        <h2 className="program-step__title">General</h2>
      </div>
      <GeneralSummary />

      <div className="program-step__header">
        <h2 className="step__title-step">Contact Information</h2>
      </div>
      <ContactsSummary />

      {isPostSecondary && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Award Information</h2>{' '}
          </div>
          <ProgramAwardsSuperSummary />
        </React.Fragment>
      )}

      {!isPostSecondary && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Pathways</h2>
          </div>
          <ProgramCareerPathwaySummary />
        </React.Fragment>
      )}

      {isPostSecondary && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Courses</h2>
          </div>
          <CourseCreditsSuperSummary />
        </React.Fragment>
      )}

      {!isPostSecondary && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Courses</h2>
          </div>
          <PathwayCourseSuperSummary />
        </React.Fragment>
      )}

      {isPostSecondary && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Articulations</h2>
          </div>
          <ArticulationSummary />
        </React.Fragment>
      )}

      {!isMiddleSchool && (
        <React.Fragment>
          <div className="program-step__header">
            <h2 className="step__title-step">Program Of Study</h2>
          </div>
          <ProgramsOfStudySummary
            programInstanceId={program.programInstanceId}
          />
        </React.Fragment>
      )}

      <div className="program-step__header">
        <h2 className="step__title-step">Assurances</h2>
      </div>
      <AssurancesSummary />

      <div className="program-step__header">
        <h2 className="step__title-step">Approval / Workflow</h2>
      </div>
      <WorkflowSummary />
    </React.Fragment>
  );
};
