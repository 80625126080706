import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ScrollToTop from 'components/Common/ScrollToTop';
import Paper from 'components/Common/Paper';
import { Link } from 'react-router-dom';
import { FlexGroup, FlexRow } from 'layouts';

export const AccessibilityStatementPage: React.FC = () => {
  return (
    <BaseLayout>
      <ScrollToTop />

      <Paper>
        <div style={{ padding: '2rem' }}>
          <FlexRow>
            <FlexGroup>
              <h2>Accessibility Statement for Colorado Technical Education</h2>
              <p>
                Colorado Community College System (CCCS) is committed to ensuring digital
                accessibility for people with disabilities. We are continually improving the
                user experience for everyone, and applying the relevant accessibility
                standards.
              </p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Measures to support accessibility</h3>
              <p>
                Colorado Community College System (CCCS) takes the following measures to ensure
                accessibility of the Colorado Technical Education website:
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>Include people with disabilities in our design process</li>
                <li>
                  Appointed a Developer and UX Designer to lead the accessibility of the
                  website
                </li>
                <li>
                  Used multiple tools and screen readers to facilitate accessibility testing
                </li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Conformance status</h3>
              <p>
                <Link to="https://www.w3.org/WAI/standards-guidelines/wcag/">
                  The Web Content Accessibility Guidelines (WCAG)
                </Link>{' '}
                defines requirements for designers and developers to improve accessibility for
                people with disabilities. It defines three levels of conformance: Level A,
                Level AA, and Level AAA. The Colorado Technical Education website is partially
                conformant with WCAG 2.1 level AA. Partially conformant means that some parts
                of the content do not fully conform to the accessibility standard.
              </p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3 style={{ fontStyle: 'italic' }}>Additional accessibility considerations</h3>

              <p>
                Although our goal is WCAG 2.1 Level AA conformance, we have also applied some
                Level AAA Success Criteria such as:
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>Re-authentication after a session expires does not cause loss of data.</li>
                <li>No page content flashes more than 3 times per second.</li>
                <li>
                  If the user can submit information, the submission is reversible, verified,
                  or confirmed.
                </li>
                <li>
                  ARIA is used, where appropriate, to enhance HTML semantics to better identify
                  the purpose of interface components.
                </li>
                <li>HTML5 regions or ARIA landmarks are used to identify page regions.</li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Feedback</h3>
              <p>
                We welcome your feedback on the accessibility of Colorado Technical Education.
                Please let us know if you encounter accessibility barriers on the Colorado
                Technical Education website:
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>
                  E-mail: <a href="mailto:cte@cccs.edu">cte@cccs.edu</a>
                </li>
                <li>Visitor address: 9101 East Lowry Boulevard Denver, CO 80230</li>
              </ul>
              <br />
              <p>We try to respond to feedback within 2 business days.</p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Assistive Technology Compatibility </h3>
              <p>
                Colorado Technical Education is designed to be compatible with the following
                assistive technologies:
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>NVDA on Windows 10</li>
                <li>VoiceOver on Mac</li>
                <li>Chrome with ChromeVox assistive technology</li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Browser Compatibility</h3>
              <p>Colorado Technical Education is fully compatible with:</p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>Chrome</li>
                <li>Firefox</li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <p>Colorado Technical Education has not had compatibility tested with:</p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>Safari</li>
                <li>Edge / IE</li>
                <li>Samsung Internet</li>
                <li>Opera</li>
                <li>Mobile interfaces</li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Limitations and alternatives</h3>
              <p>
                Despite our best efforts to ensure accessibility of Colorado Technical
                Education, there may be some limitations. Below is a description of known
                limitations, and potential solutions. Please contact us at{' '}
                <a href="mailto:cte@cccs.edu">cte@cccs.edu</a> if you observe an issue not
                listed below.
              </p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Known limitations for Colorado Technical Education:</h3>
              <ol style={{ marginLeft: '3rem' }}>
                <li>
                  Multi-select fields in Universal Contacts, Grants, and Reports: The
                  multi-select fields are not keyboard functional. We have documented the issue
                  and have it in the backlog to address.
                </li>
                <li>
                  Row Highlights in Data Collections, Grants, and CTA: As WCAG 2.1 Level A
                  dictates, color should not be the only visual means of conveying information.
                  To mitigate this use of color, we have included instructions to explain the
                  meaning of the row highlights and only used row highlighting to indicate
                  peripheral information that is not required to do one’s job.
                </li>
                <li>
                  Improvement Plan Table in Grants: The Improvement Plan table is not read
                  properly by multiple screen readers. As a work-around, you can access the
                  same information in the Public Perkins Metrics by Institution Report{' '}
                  <Link to="/public-reporting">Found Here</Link>. We have documented the issue
                  and have it in the backlog to address. After this table was built, future
                  tables were built using custom code and which are properly read by multiple
                  screen readers.
                </li>
                <li>
                  Screen Size: Content has been designed to be viewed with a 1080p monitor with
                  1800+ available pixel-width. If your monitor is older and does not meet this
                  resolution, or you are using a laptop with a smaller screen, some content
                  will potentially be distorted. Some workarounds available are to collapse any
                  side-trays as well as to decrease the font-size of the browser by using the
                  built-in zoom functionality for your specific browser/system.
                </li>
                <li>
                  Multiple Form Labels: Throughout the site, there are fields that have
                  multiple form labels. While the accessibility testing tools indicate that
                  this could be confusing to those using screen readers, both ChromeVox and
                  VoiceOver do not read multiple form labels when translating the site. The
                  issue has been documented and is in the backlog to address.
                </li>
                <li>
                  Line Graph Color Contrast: For each report webpage, there is a table and a
                  line graph. For the line graphs, most colors are accessible according to WCAG
                  2.1 AA text contrast standards, however some are not. The 11 colors were
                  chosen because of their contrast with each other, making lines easier to
                  distinguish from one another. We apologize that this may be an issue,
                  however, to mitigate this, we increased the weight and size of the font in
                  the color key. Additionally, the line graphs act as a visual supplement to
                  the tables. If the line graphs are difficult to read, please rely on using a
                  screen reader to read the tables.
                </li>
              </ol>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Assessment approach</h3>
              <p>
                Colorado Community College System (CCCS) assessed the accessibility of the
                Colorado Technical Education website by the following approaches:
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>
                  A formal quality assurance process throughout the design and development
                  process utilizing Wave, Axe, Lighthouse, ChromeVox, NVDA and VoiceOver
                </li>
              </ul>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <h3>Formal approval of this accessibility statement</h3>
              <p>This Accessibility Statement is approved by:</p>
              <p>
                Director of CTE Accountability and Perkins Plan Manager at the Colorado
                Community College System
              </p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <p>
                Formal complaints
                <br />
                Contact us at <a href="mailto:cte@cccs.edu">cte@cccs.edu</a> or 303-620-4000.
              </p>

              <p>
                This statement was created on 14 December 2020 using the{' '}
                <Link to={'https://www.w3.org/WAI/planning/statements/'}>
                  W3C Accessibility Statement Generator Tool.
                </Link>
              </p>
            </FlexGroup>
          </FlexRow>
        </div>
      </Paper>
    </BaseLayout>
  );
};
