export interface CtaInstructorTerm {
  ctaCourseTermInstructionalId: number;
  ctaCourseTermId: number;
  credits: number;
  count: number;
  sequence: number;
}

export const emptyCtaInstructorTerm = (): CtaInstructorTerm => {
  return {
    ctaCourseTermInstructionalId: -1,
    ctaCourseTermId: -1,
    credits: 0,
    count: 0,
    sequence: 0,
  };
};
