import {
  saveCTAAssurances,
  loadCTASingleDistrictRecord,
} from 'redux/cta/ctadashboard';

export const ctaDashboardMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveCTAAssurances.SUCCEEDED: {
      dispatch(loadCTASingleDistrictRecord(action.payload));
      break;
    }
  }
};
