import Button from 'components/Common/FormElements/Button';
import Icon from 'components/Common/Icons';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState, useRef } from 'react';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import Tooltip from 'components/Common/Tooltip';
import { CtaInstructorProgram } from 'types/cta/InstructionalCosts/CtaInstructorProgram.types';
import { CtaInstructorSalary } from 'types/cta/InstructionalCosts/CtaInstructorSalary.types';
import { CteProgramAffiliation } from './CteProgramAffiliation.component';
import { FlexRow } from 'layouts';
import { InstructorSalaryValidation } from 'validations/CTA/InstructorSalary.validations';
import { isApplicaionIdValid } from 'util/global';
import { map } from 'lodash';
import { mergeObjects, safeGet } from 'util/objectUtility';
import { toast } from 'react-toastify';
import { upsertInPlace } from 'util/arrayUtility';
import { useParams } from 'react-router-dom';

export type CteSalaryInformationProps = {
  forceSave: boolean;
  loadInstructorSalary: Function;
  saveInstructorSalary: Function;
  salaryInfo: CtaInstructorSalary;
  setDirtyData: Function;
};

export const CteSalaryInformationComponent: React.FC<CteSalaryInformationProps> = ({
  forceSave,
  loadInstructorSalary,
  saveInstructorSalary,
  salaryInfo,
  setDirtyData,
}) => {
  // -- dependencies --
  const { ctaInstructorId: ciId, ctaId: ctId } = useParams<any>();
  const ctaInstructorId = Number(ciId);
  const ctaId = Number(ctId);
  const {
    getError,
    getFieldValid,
    validate,
    validateAll,
    validateIfTrue,
  } = InstructorSalaryValidation();

  // -- local state --
  const mountedRef = useRef(true);
  const [dirtySalary, setDirtySalary] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [salaryState, setSalaryState] = useState<CtaInstructorSalary>(
    salaryInfo
  );
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);

  // -- component logic --
  const updateSalary = (
    salary: Partial<CtaInstructorSalary>,
    key: keyof CtaInstructorSalary,
    value: any
  ) => {
    setDirtyData(true);
    setDirtySalary(true);
    const updated = mergeObjects(salaryState, salary);
    validateIfTrue(key, value, updated);
    setSalaryState(updated);
  };

  const updateProgram = (program: CtaInstructorProgram) => (
    value: Partial<CtaInstructorProgram>
  ) => {
    setDirtyData(true);
    setDirtySalary(true);
    const updatedProgram = mergeObjects(program, value);
    const programs = upsertInPlace(
      salaryState.programs,
      updatedProgram,
      'ctaProgramId'
    );
    const valid = validate('programs', programs, salaryState);
    if (!valid) {
      setSubmitFailed(true);
    }
    setSalaryState(mergeObjects(salaryState, { programs }));
  };
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const saveSalaryInfo = () => {
    toggleHasRequested();
    const canSave = validateAll(salaryState);
    if (canSave) {
      saveInstructorSalary(ctaId, salaryState)
        .then(() => setDirtyData(false))
        .then(() => setDirtySalary(false))
        .then(() => setSubmitFailed(false))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification
          status="error"
          message="Not all validations succeeded."
        />
      );
    }
  };

  // -- lifecycle --
  useEffect(() => {
    setSalaryState(salaryInfo);
  }, [salaryInfo]);

  useEffect(() => {
    if (isApplicaionIdValid(ctaInstructorId)) {
      loadInstructorSalary(ctaInstructorId).catch((err: any) =>
        console.error(err)
      );
    }
  }, [loadInstructorSalary, ctaInstructorId]);

  useEffect(() => {
    if (forceSave && dirtySalary) {
      saveSalaryInfo();
    }
  }, [forceSave]); // eslint-disable-line

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // -- display logic --
  const getSalary = safeGet(salaryState);

  return (
    <Paper>
      <FlexRow>
        <Instruction title="Salary Information">
          <p>
            Review your District’s contracted instructor start and end dates.{' '}
            <b>
              In CTA, you are reporting on costs incurred from 7/1 to 6/30;
              accordingly, you may need to modify your instructors’ salary
              information based on your district’s contracted start and end
              dates.
            </b>
          </p>
          <br />
          <p>
            Enter the costs associated with this instructor. This information is
            not specific to a particular school or program, except for the
            ‘Number of CTE sub days PAID’ and ‘Actual Pay for Extra CTE Duty’
            fields. If either of these conditions apply, enter the amount in the
            field labeled with the appropriate program and school. Extra
            stipends for Non-CTE instructors should be reported as part of the
            Books and Supplies section. Click the ‘Save’ button when you have
            completed this section.
          </p>
          <br />
          <p>
            <span className="u-color-red">Note</span>: An additional 30% for
            fringe benefits will be automatically added to the annual contracted
            base salary. No fringe benefits should be added to amounts below.
            For substitute days entered, a standard compensation rate will be
            automatically calculated.
          </p>
        </Instruction>
      </FlexRow>

      <fieldset>
        <legend>Salary Information</legend>
        <FlexRow>
          <Input
            errorMessage={getError('annualContractBaseSalary')}
            name="annualContractBaseSalary"
            onBlur={() =>
              validate(
                'annualContractBaseSalary',
                getSalary('annualContractBaseSalary')
              )
            }
            onChange={updateSalary}
            tooltip={
              <Tooltip
                indicator={
                  <Icon name="info" className="spending-category__tooltip" />
                }
                tooltip="Enter the instructor's annual base salary (regular contract salary) for the reporting period (7/1 to 6/30). Do not include benefits, stipends, or extra duty pay."
              />
            }
            type="number"
            valid={getFieldValid('annualContractBaseSalary')}
            value={getSalary('annualContractBaseSalary')}
          />
          <Input
            errorMessage={getError('actualSalaryEarned')}
            name="actualSalaryEarned"
            onBlur={() =>
              validate('actualSalaryEarned', getSalary('actualSalaryEarned'))
            }
            onChange={updateSalary}
            tooltip={
              <Tooltip
                indicator={
                  <Icon name="info" className="spending-category__tooltip" />
                }
                tooltip="Enter the instructor’s actual base salary earned during the reporting period (7/1-6/30). Note that this should include the total amount earned during the period regardless of when it is paid. Do not include benefits, stipends, or extra duty pay."
              />
            }
            type="number"
            valid={getFieldValid('actualSalaryEarned')}
            value={getSalary('actualSalaryEarned')}
          />
          <Input
            errorMessage={getError('numberOfSickDays')}
            label="Number of Sick / Personal Sub Days"
            name="numberOfSickDays"
            onBlur={() =>
              validate('numberOfSickDays', getSalary('numberOfSickDays'))
            }
            onChange={updateSalary}
            tooltip={
              <Tooltip
                place="right"
                indicator={
                  <Icon name="info" className="spending-category__tooltip" />
                }
                tooltip="Enter the number of whole or partial days of a non-CTE related absence for which a substitute was compensated. Note that CTE-related absences will be reported below."
              />
            }
            type="number"
            valid={getFieldValid('numberOfSickDays')}
            value={getSalary('numberOfSickDays')}
          />
        </FlexRow>
      </fieldset>
      {map(getSalary('programs'), (program, index: number) => {
        return (
          <React.Fragment key={index}>
            <CteProgramAffiliation
              index={index}
              onChange={updateProgram}
              program={program}
              submitFailed={submitFailed}
            />
          </React.Fragment>
        );
      })}
      <fieldset>
        <legend>Extra Administrative Stipend</legend>
        <FlexRow>
          <Input
            errorMessage={getError('extraAdminStipend')}
            label="Administrative"
            name="extraAdminStipend"
            onBlur={() =>
              validate('extraAdminStipend', getSalary('extraAdminStipend'))
            }
            onChange={updateSalary}
            tooltip={
              <Tooltip
                indicator={
                  <Icon name="info" className="spending-category__tooltip" />
                }
                tooltip="Enter the amount of any stipends or extra duty pay for CTE general work (e.g. part time CTE Director role) performed outside of the instructor's regular duties during the reporting period (7/1-6/30)."
              />
            }
            type="number"
            valid={getFieldValid('extraAdminStipend')}
            value={getSalary('extraAdminStipend')}
          />
          <div style={{ width: '100%' }} />
          <div style={{ width: '100%' }} />
        </FlexRow>
      </fieldset>
      <FlexRow>
        <div className="u-flex-grow-1" />
        <Button
          className={dirtySalary ? 'button--filled' : 'button--outline'}
          disabled={hasRequested}
          onClick={saveSalaryInfo}
          style={{
            alignSelf: 'flex-end',
            height: '5.6rem',
            width: '28rem',
          }}
          text="Save Salary Information"
        />
      </FlexRow>
    </Paper>
  );
};
