import { connect } from 'react-redux';
import ProgramCreateStep from './ProgramCreateStep.page';
import { getLoggedinUser } from 'redux/user/selectors';
import { getRecords_Institutes } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const method = String(ownProps.match.params.method);
  const user = getLoggedinUser(state);
  const institutes = getRecords_Institutes(state);
  return {
    institutes,
    method,
    user,
  };
};

export default connect(
  mapStateToProps,
  null
)(ProgramCreateStep);
