import {
  saveInstructor,
  loadSingleInstructor,
} from 'redux/cta/InstructionalCosts/ctainstructors';
import {
  loadInstructorSalary,
  saveInstructorSalary,
} from 'redux/cta/InstructionalCosts/ctainstructorsalary';
import {
  saveNonCTECourses,
  loadNonCTECourses,
} from 'redux/cta/InstructionalCosts/ctanonctecourses';
import { loadCTECourses, saveCTECourses } from 'redux/cta/InstructionalCosts/ctactecourses';

export const ctaInstructionalCostsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveInstructor.SUCCEEDED:
    case saveInstructorSalary.SUCCEEDED:
    case saveCTECourses.SUCCEEDED:
    case saveNonCTECourses.SUCCEEDED: {
      dispatch(loadCTECourses(action.payload));
      dispatch(loadNonCTECourses(action.payload));
      dispatch(loadInstructorSalary(action.payload));
      dispatch(loadSingleInstructor(action.payload));
      break;
    }
  }
};
