import React from 'react';
import { GrantSchedule } from 'types';
import ScrollToTop from 'components/Common/ScrollToTop';
import FiscalYearSideNav from 'components/Grants/FiscalYearManagement/FiscalYearSideNav';

type FiscalYearLayoutProps = {
  children: any;
  grantSchedule: GrantSchedule;
};

export const FiscalYearLayout: React.FC<FiscalYearLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div className="program">
      <ScrollToTop />
      <div className="program__body">
        <section
          aria-label="grant fiscal year content"
          className="program__content"
        >
          {children}
        </section>
      </div>
      <FiscalYearSideNav />
    </div>
  );
};
