import { useValidation } from 'de-formed-validations';
import { SignatureComponentValidations } from './SignatureComponent.validations';
import { Signature } from 'types';
import { isEmptySignature } from 'util/formHelpers';

type ValidationState = {
  hasAllRequiredSignatures: Signature[];
  hasAllRequiredFields: Signature[];
};

export const BasicGrantSignatureValidations = () => {
  const { validateAll } = SignatureComponentValidations();

  return useValidation<ValidationState>({
    hasAllRequiredSignatures: [
      {
        errorMessage:
          'Not all required signatures have been provided. Please provide all before submitting for approval.',
        validation: (signatures: Signature[], _) => {
          return signatures.reduce((prev: boolean, curr: Signature) => {
            if (!prev) return prev;
            return curr.required ? !isEmptySignature(curr) : true;
          }, true);
        },
      },
    ],
    hasAllRequiredFields: [
      {
        errorMessage:
          'A signature has been provided without all required fields.',
        validation: (signatures: Signature[], _) => {
          return signatures.reduce((prev: boolean, curr: Signature) => {
            if (!prev) return prev;
            return curr.isDirty ? validateAll(curr) : true;
          }, true);
        },
      },
    ],
  });
};
