import { WORKFLOW_KEY, WORKFLOW_ASSURANCES_KEY } from 'redux/keys';
import { getRecords } from 'redux/utility/baseSelectors';
import { QuestionGroup } from 'types';

export const getWorkflowAssurances = (state: any): QuestionGroup => {
  const bob = getRecords<QuestionGroup>(state, [
    WORKFLOW_KEY,
    WORKFLOW_ASSURANCES_KEY,
  ]);
  return bob && bob.length > 0
    ? bob[0]
    : {
        questionGroupId: 0,
        title: '',
        questions: [],
      };
};
