import { connect } from 'react-redux';
import { ProgramSummaryPage } from './ProgramSummary.page';
import { getProgramSteps } from 'redux/common/_selectors';
import { setProgramNav } from 'redux/application/index';
import { getGeneralWorkflow } from 'redux/generalworkflow/workflow.selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = Number(
    ownProps.match.params.programInstanceId
  );
  const workflow = getGeneralWorkflow('programs', state);
  const programSteps = getProgramSteps(state);

  return {
    programInstanceId,
    programSteps,
    workflow,
  };
};

const dispatchToProps = {
  setProgramNav,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramSummaryPage);
