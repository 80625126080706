export interface CTAProgram {
  costs: number;
  ctaProgramId: number;
  programName: string;
}

export const emptyCTAProgram = (): CTAProgram => {
  return {
    costs: 0,
    ctaProgramId: -1,
    programName: '',
  };
};
