import { connect } from 'react-redux';
import ProgramContactsStep from './ProgramContactsStep.page';
export const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(ProgramContactsStep);
