import { FILTERS_KEY, FILTERS_KEY_PERKINS_GRANT } from 'redux/keys';
import { get, isNil, map } from 'lodash';
import { KeyValueType, PerkinsGrantFilterState, ReduxFilter } from 'types';
import { getFiltersWithValues } from 'util/filterUtility';

export const PERKINS_GRANT_FILTER_PATH = [
  FILTERS_KEY,
  FILTERS_KEY_PERKINS_GRANT,
];

export const getRecords_PerkinsGrantFilterState = (
  state: any
): PerkinsGrantFilterState => {
  return get(state, PERKINS_GRANT_FILTER_PATH);
};

export const getPerkinsGrantFilters = (
  state: any
): ReduxFilter<PerkinsGrantFilterState>[] => {
  const filterValues: KeyValueType<
    unknown
  > = (getRecords_PerkinsGrantFilterState(state) as unknown) as KeyValueType<
    unknown
  >;

  const activeFilters = getFiltersWithValues(filterValues);

  const filters = map(
    activeFilters,
    (filterName): ReduxFilter<PerkinsGrantFilterState> => {
      if (filterName === 'substantiallySubmitted') {
        return {
          filter: {
            property: filterName,
            values:
              filterValues[filterName] !== undefined
                ? filterValues[filterName]
                : '',
            comparator: (data: Date) => {
              return !isNil(data);
            },
          },
          operator: 'and',
        };
      }
      return {
        filter: {
          property: filterName,
          values:
            filterValues[filterName] !== undefined
              ? filterValues[filterName]
              : '',
        },
        operator: 'and',
      };
    }
  );

  return filters;
};
