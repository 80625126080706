import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import GrantAssurances from 'components/Grants/Applications/AssuranceSignature/Assurances';

export const GrantAssurancesPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <GrantAssurances />
      </GrantsLayout>
    </BaseLayout>
  );
};
