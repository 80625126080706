import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { insertClass } from 'util/formHelpers';
import { isDisabled } from 'util/authorization';

export type ButtonProps = {
  asLink?: boolean;
  className?: string;
  disabled?: boolean;
  element?: JSX.Element;
  modalClick?: Function;
  onClick?: Function;
  style?: any;
  text?: string;
  to?: string;
};

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    asLink,
    className,
    disabled,
    element,
    modalClick,
    onClick,
    style,
    text,
    to,
  } = props;

  const generateClass = () => {
    if (isDisabled(disabled, btnRef)) {
      return `${insertClass(className)} u-disabled`;
    }
    return className;
  };
  const btnRef = useRef(null);

  const handleClick = () => {
    modalClick && modalClick(btnRef);
    onClick && onClick();
  };
  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };
  const butt = () => (
    <React.Fragment>{element ? element : text}</React.Fragment>
  );

  return (
    <React.Fragment>
      {asLink && to ? (
        <Link className={`${disabled ? 'u-disabled' : ''}`} to={to}>
          <div
            className={generateClass()}
            onClick={handleClick}
            onKeyDown={handleEnter}
            style={style}
          >
            {butt()}
          </div>
        </Link>
      ) : (
        <button
          className={generateClass()}
          disabled={isDisabled(disabled, btnRef)}
          onClick={handleClick}
          onKeyDown={handleEnter}
          ref={btnRef}
          style={style}
        >
          {butt()}
        </button>
      )}
    </React.Fragment>
  );
};
