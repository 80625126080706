import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  actionUpdateRecords,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { TravelerType } from 'types';
import { readTravelerTypes } from 'services/common/travelertype.services';

const initialState = baseInitialState;

export const loadTravelerTypes = createActionThunk('loadTravelerTypes', () => {
  return readTravelerTypes();
}); // end loadCareerClusters

export default handleActions<BaseStateObject, TravelerType | TravelerType[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    [loadTravelerTypes.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [loadTravelerTypes.SUCCEEDED]: (state, { payload }) => {
      return actionUpdateRecords(state, payload);
    } /* End action */,
    [loadTravelerTypes.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
