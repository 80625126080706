import { CTA_KEY, CTA_ADMIN_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTAAdminInformation } from 'types/cta/CTAAdminInformation.types';

export const CTA_ADMIN_PATH = [CTA_KEY, CTA_ADMIN_KEY];

export const getIsDataFetching_CTAAdminInformation = (state: any): boolean => {
  return getIsDataFetching(state, CTA_ADMIN_PATH);
};

export const getRecords_CTAAdminInformation = (
  state: unknown
): CTAAdminInformation[] => {
  return getRecords(state, CTA_ADMIN_PATH);
};
