import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { QuestionGroup } from 'types';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { readTwoYearGrantFormQuestions } from 'services/grants/grantformquestions.services';

const initialState = baseInitialState;

export const loadTwoYear = createActionThunk(
  'loadTwoYear',
  (grantFiscalYearId: number) => {
    return readTwoYearGrantFormQuestions(grantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, QuestionGroup | QuestionGroup[]>(
  {
    ...baseLoadActionBuilder<QuestionGroup | QuestionGroup[]>(loadTwoYear),
  },
  initialState
);
