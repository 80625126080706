export interface CTASchool {
  ctaSchoolId: number;
  cdeId: string;
  schoolName: string;
  terms: number;
  credits: number;
}

export const emptyCTASchool = (): CTASchool => {
  return {
    ctaSchoolId: -1,
    cdeId: '',
    schoolName: '',
    terms: -1,
    credits: -1,
  };
};
