import React, { useState, useEffect } from 'react';
import {
  ImprovementPlan,
  AzureUploadParameters,
  QuestionGroup,
  Signature,
} from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { mergeObjects, renderData } from 'util/objectUtility';
import { QuestionsGroupLayout } from 'components/Common/Questions/QuestionsGroupLayout.component';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { ImprovementPlanProps } from './ImprovementPlanConnect';
import { toast } from 'react-toastify';
import { formatMoney } from 'util/moneyHelpers';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import PerformanceData from './PerformanceData';
import SignatureComponent from 'components/Common/Signature';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

export const MultiyearDeficiencyComponent: React.FC<ImprovementPlanProps> = (
  props
) => {
  const {
    isLoading,
    improvementPlan,
    loadImprovementPlan,
    saveImprovementPlan,
    setCurrentNavAnchor,
    isLocked,
  } = props;

  const [
    improvementPlanState,
    setImprovementPlanState,
  ] = useState<ImprovementPlan>(improvementPlan);

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadImprovementPlan(grantFiscalYearId);
    }
  }, [loadImprovementPlan, grantFiscalYearId]);

  const handleSignatureChange = (multiYearSignature: Signature) => {
    setImprovementPlanState({ ...improvementPlanState, multiYearSignature });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: 'not needed',
  };

  const handleSave = () => {
    return saveImprovementPlan(grantFiscalYearId, improvementPlanState).then(
      () => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      }
    );
  };

  const handleQuestionChange = (
    data: QuestionGroup,
    key: keyof QuestionGroup,
    value: any
  ) => {
    const { multiYearQuestions: oldState } = improvementPlanState;
    const multiYearQuestions = mergeObjects<QuestionGroup>(oldState, data);
    const updated = mergeObjects<ImprovementPlan>(improvementPlanState, {
      multiYearQuestions,
    });
    setImprovementPlanState(updated);
  };

  useEffect(() => {
    if (improvementPlan) {
      setImprovementPlanState(improvementPlan);
    }
  }, [improvementPlan]);

  useEffect(() => {
    setCurrentNavAnchor('multiyear');
  }, [setCurrentNavAnchor]);

  return (
    <React.Fragment>
      <Loading isActive={isLoading} />
      <SecureWrap isLocked={isLocked} component="GrantsImprovementPlan">
        <ScrollToTop />
        <Paper>
          <Instruction title="Year-Over-Year Deficiencies">
            <p>
              Sites with performance levels that fall below 90 percent of the
              state target for two or more consecutive years are required to
              develop an improvement plan for each affected indicator.
              Additionally, sites must earmark 20 percent of their grant award
              to address these underperforming metrics. The Performance Data
              listed below detail the indicators where an improvement plan is
              necessary. Indicators requiring your attention are highlighted in
              red. Click on the “Show All Data” icon to see detailed information
              on the full dataset of Perkins V indicators.
            </p>
          </Instruction>
        </Paper>
        <Paper>
          <FlexRow>
            <h2 className="u-section-title-text">Program Data</h2>
          </FlexRow>
          <FlexRow>
            <PerformanceData improvementPlan={improvementPlanState} />
          </FlexRow>
        </Paper>
        <Paper>
          <Instruction title="Questions About Gaps/Deficiencies that Have Persisted for Two or More Years">
            <p>
              Describe the action plan you will implement to address the
              identified deficiencies—overall and for affected student
              subpopulations. Note that 20 percent of your Perkins V grant award
              must be spent on approved activities specified within your action
              plan. The following questions must be addressed to receive
              approval.
            </p>
          </Instruction>
          <QuestionsGroupLayout
            group={improvementPlanState.multiYearQuestions}
            onChange={handleQuestionChange}
            uploadParams={uploadParams}
          />
        </Paper>
      </SecureWrap>
      <SecureWrap
        isLocked={isLocked}
        component={['GrantsImprovementPlan', 'GrantsVouchersSignee']}
      >
        <Paper>
          <Instruction title="Signature">
            <p>
              Please have your Chief Academic Officer or equivalent provide
              their signature below.
            </p>
          </Instruction>
          <SignatureComponent
            onChange={handleSignatureChange}
            signature={improvementPlanState.multiYearSignature}
          />
        </Paper>
      </SecureWrap>
      <Paper>
        <FlexGroup>
          <h2>Improvement Plan Spending Information</h2>
          <FlexRow>
            <p>
              Please reference these boxes for question #3. You are required to
              spend at least 20% of your Grant Award on improvements. The
              minimum amount for Improvement Plan spending rounds up to the next
              dollar versus normal rounding rules.
            </p>
          </FlexRow>

          <FlexRow>
            <Input
              disabled={true}
              className="input-display"
              name="minimumSpending"
              label="Minimum Improvement Plan Spending"
              value={`$${formatMoney(
                renderData(Math.ceil(improvementPlanState.minimumSpending))
              )}`}
            />
            <Input
              disabled={true}
              className="input-display"
              name="initialGrantEstimate"
              label="Initial Grant Estimate"
              value={`$${formatMoney(
                renderData(improvementPlanState.initialGrantFund)
              )}`}
            />
          </FlexRow>
        </FlexGroup>
      </Paper>
      <SecureWrap
        isLocked={isLocked}
        component={['GrantsImprovementPlan', 'GrantsVouchersSignee']}
      >
        <Paper>
          <SaveButtons
            hideCancel
            saveData={handleSave}
            saveText="Save Year over Year"
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
