import { connect } from 'react-redux';
import {
  PermissionComponent,
  PermissionRole,
  PermissionRoleComponent,
  PermissionType,
} from 'types';
import ApplicationState from 'types/applicationstate.types';
import {
  getRecords_PermissionRoles,
  PERMISSION_ROLE_PATH,
} from 'redux/permissions/permissionroles.selectors';
import { RoleDetailsComponent } from './RoleDetails.component';
import {
  getRecords_PermissionRoleComponent,
  ROLE_COMPONENT_PATH,
} from 'redux/permissions/permissionrolecomponent.selector';
import { loadPermissionRoleComponents } from 'redux/permissions/permissionrolecomponent';
import {
  getRecords_PermissionComponent,
  PERMISSION_COMPONENT_PATH,
} from 'redux/permissions/permissioncomponents.selectors';
import { loadPermissionComponents } from 'redux/permissions/permissioncomponents';
import { loadPermissionRoles } from 'redux/permissions/permissionroles';
import {
  getRecords_PermissionTypes,
  PERMISSION_TYPES_PATH,
} from 'redux/permissions/permissiontype.selectors';
import { loadPermissionTypes } from 'redux/permissions/permissiontypes';
import { some } from 'lodash';
import { getIsDataFetching } from 'redux/utility/_exports';

export const mapStateToProps = (state: ApplicationState) => {
  const roles: PermissionRole[] = getRecords_PermissionRoles(state);
  const ptypes: PermissionType[] = getRecords_PermissionTypes(state);
  const pcomps: PermissionComponent[] = getRecords_PermissionComponent(state);
  const rolecomponents: PermissionRoleComponent[] = getRecords_PermissionRoleComponent(
    state
  );

  const isFetching: boolean = some([
    getIsDataFetching(state, PERMISSION_ROLE_PATH),
    getIsDataFetching(state, ROLE_COMPONENT_PATH),
    getIsDataFetching(state, PERMISSION_COMPONENT_PATH),
    getIsDataFetching(state, PERMISSION_TYPES_PATH),
  ]);

  return {
    roles,
    pcomps,
    ptypes,
    rolecomponents,
    isFetching,
  };
};

const dispatchProps = {
  loadPermissionRoleComponents,
  loadPermissionComponents,
  loadPermissionRoles,
  loadPermissionTypes,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(RoleDetailsComponent);
