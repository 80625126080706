import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
} from 'services';
import { CTADistrictRecord } from 'types';

const BASE = ['api', 'ctadashboard'];

export const readCTADistrictRecords = async (year: number): Promise<CTADistrictRecord[]> => {
  const theUrl = getFormattedUrl([...BASE, 'byYear', year]);
  return fetch(theUrl, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Dashboard', err, 'readCTADistrictRecords');
    });
};

export const readCTASingleDistrictRecord = async (id: number): Promise<CTADistrictRecord> => {
  return fetch(getFormattedUrl([...BASE, id]), { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Dashboard', err, 'readCTASingleDistrictRecord');
    });
};

export const updateCTADistrictRecord = async (record: CTADistrictRecord) => {
  return fetch(getFormattedUrl([...BASE, 'assurances']), {
    ...postWithAuthOptions(),
    body: JSON.stringify(record),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Dashboard', err, 'readCTASingleDistrictRecord');
    });
};
