import React, { useEffect } from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import {
  FormType,
  CTAFiscalYearProgramSupport,
  CTASupportPersonnel,
} from 'types';
import { safeGet, mergeObjects } from 'util/objectUtility';
import { CTAFiscalYearProgramSupportValidation } from 'validations/CTA/CTAFiscalYearProgramSupport.validations';
import CTASchoolAndProgramSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTASchoolAndProgramSelectBox';
import Input from 'components/Common/FormElements/Input';
import Textarea from 'components/Common/FormElements/Textarea';
import { round } from 'util/mathHelpers';

export const SchoolProgramAffiliationForm: React.FC<
  FormType<CTAFiscalYearProgramSupport>
> = ({ data, onChange, submitFailed }) => {
  // -- dependencies --
  const {
    getError,
    getFieldValid,
    validate,
    validateAll,
  } = CTAFiscalYearProgramSupportValidation();

  // -- component logic --
  const handleChange = (item: Partial<CTASupportPersonnel>) => {
    const updated = mergeObjects(data, item);
    onChange(updated);
    validateAll(updated);
  };

  // -- lifecycle --
  useEffect(() => {
    submitFailed && validateAll(data);
  }, [submitFailed, data]); // eslint-disable-line

  // -- display logic --
  const get = safeGet(data);

  return (
    <>
      <FlexGroup>
        <FlexRow>
          <FlexGroup
            onBlur={() => validate('ctaProgramId', get('ctaProgramId'))}
          >
            <CTASchoolAndProgramSelectBox
              label="School / Program"
              onChange={handleChange}
              ctaProgramId={get('ctaProgramId')}
            />
            {getError('ctaProgramId') && (
              <p className="u-color-red">{getError('ctaProgramId')}</p>
            )}
          </FlexGroup>
          <Input
            errorMessage={getError('ctePercent')}
            label="CTE%"
            name="ctePercent"
            onBlur={() => validate('ctePercent', get('ctePercent'))}
            onChange={handleChange}
            type="number"
            valid={getFieldValid('ctePercent')}
            value={round(2, get('ctePercent'))}
          />
          <div style={{ width: '3rem' }} />
        </FlexRow>
        <FlexRow>
          <Textarea
            maxLength={500}
            label="Explain CTE Percentage (character limit 500)"
            onChange={handleChange}
            name="memo"
            value={get('memo')}
          />
        </FlexRow>
      </FlexGroup>
    </>
  );
};
