import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import FiscalAgentManagementComponent from 'components/Grants/IntentToParticipate/FiscalAgentManagement';

export const FiscalAgentResponsePage: React.FC = (props) => {
  return (
    <BaseLayout>
      <FiscalAgentManagementComponent {...props} />
    </BaseLayout>
  );
};
