import { SubmissionManagementDataCollection } from './SubmissionManagement.component';
import { connect } from 'react-redux';
import {
  getIsDataFetching_DataCollectionSubmission,
  getAdvancedFilteredRecords_DataCollectionSubmission,
} from 'redux/datacollection/_selectors';
import {
  loadDataCollectionSubmission,
  saveDataCollectionSubmission,
} from 'redux/datacollection/datacollectionsubmission';

const mapStateToProps = (state: any) => {
  const submissions = getAdvancedFilteredRecords_DataCollectionSubmission(
    state
  );
  const isFetching = getIsDataFetching_DataCollectionSubmission(state);

  return {
    isFetching,
    submissions,
  };
};

const dispatchProps = {
  loadDataCollectionSubmission,
  saveDataCollectionSubmission,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SubmissionManagementDataCollection);
