import { connect } from 'react-redux';
import { EnrollmentStatusComponent } from './EnrollmentStatus.component';
import { insertEnrollmentComplete } from 'redux/datacollection/studentenrollment';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {
  insertEnrollmentComplete,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(EnrollmentStatusComponent);
