import React from 'react';
import { map } from 'lodash';
import { Question } from 'types';
import Radio from 'components/Common/FormElements/Radio';

type QuestionRadioProps = {
  question: Question;
  onChange: Function;
};

export const QuestionRadio: React.FC<QuestionRadioProps> = (props) => {
  const { question, onChange } = props;

  const createOptions = () => {
    return map(question.childQuestions, (item: Question) => {
      return {
        id: item.questionId,
        value: item.questionValue.valueId,
        label: item.text,
      };
    });
  };

  const handleChange = (data: any, selected: number) => {
    const questionValue = {
      ...question.questionValue,
      value: String(selected),
    };
    const newState = {
      ...question,
      questionValue,
    };
    onChange(newState);
  };

  return (
    <React.Fragment>
      <Radio
        name="questionValue.value"
        value={Number(question.questionValue.value)}
        options={createOptions()}
        onChange={handleChange}
      />
    </React.Fragment>
  );
};
