import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { ProjectSpendingPlan } from './ProjectPlan.component';
import { getIsDataFetching_AnnualSpendingPlan } from 'redux/grants/_selectors';
import { getLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any) => {
  const isLoading = getIsDataFetching_AnnualSpendingPlan(state);
  const isLocked = getLockedStatus(state, 'spending-plans');

  return {
    isLoading,
    isLocked,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
};

export default connect(mapStateToProps, dispatchProps)(ProjectSpendingPlan);
