import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { Consortium } from 'types';
import { readAllConsortia } from 'services/grants/consortium.services';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadAllConsortia = createActionThunk('loadAllConsortia', () => {
  return readAllConsortia();
});

export default handleActions<BaseStateObject, Consortium | Consortium[]>(
  {
    ...baseLoadActionBuilder<Consortium | Consortium[]>(loadAllConsortia),
  },
  initialState
);
