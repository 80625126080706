export const CAREER_INFO = '[CAREER_INFO]';

export const SET_CAREER_INFO = `${CAREER_INFO} SET`;
export const SET_CAREER_INFO_LOADING = `${CAREER_INFO} LOADING`;

export const setCareerInfo = (payload: any) => {
  return {
    type: SET_CAREER_INFO,
    payload,
  };
};

export const setIsLoading_CareerInfo = (payload: boolean) => {
  return {
    type: SET_CAREER_INFO_LOADING,
    payload,
  };
};
