import { CTAReviewOptimize } from 'types';

export interface CTAReviewOptimizeOverview {
  ctaId: number;
  districtPpor: number;
  dcts: CTAReviewOptimize[];
  nonDcts: CTAReviewOptimize[];
  nonOptimizedReimbursementEstimate: number;
  optimizedReimbursementEstimate: number;
  proratedReimbursementAverage: number;
  proratedReimbursementEstimate: number;
  reimbursement: number;
  StateProration: number;
  statewideDisbursementAmount: number;
}

export const emptyCTAReviewOptimizeOverview = (): CTAReviewOptimizeOverview => {
  return {
    ctaId: -1,
    districtPpor: 0,
    dcts: [],
    nonDcts: [],
    nonOptimizedReimbursementEstimate: 0,
    optimizedReimbursementEstimate: 0,
    proratedReimbursementAverage: 0,
    proratedReimbursementEstimate: 0,
    reimbursement: 0,
    StateProration: 0,
    statewideDisbursementAmount: 0,
  };
};
