import React, { useState, useEffect } from 'react';
import { clone } from 'lodash';
import { createTempId } from 'util/formHelpers';
import { ProgramAward, emptyProgramAward } from 'types';
import { useParams, useHistory } from 'react-router';
import { FlexRow } from 'layouts';
import { useValidation } from 'hooks';
import { getIsProgramReadOnly } from 'redux/programs/_selectors';
import { useSelector } from 'react-redux';
import AwardTypeSelectBox from 'components/Common/SelectBoxes/AwardTypeSelectBox';
import DatePicker from 'components/Common/FormElements/DatePicker';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SaveButtons from 'components/Common/SaveButtons';
import Toggle from 'components/Common/FormElements/Toggle';

type ProgramAwardAddEditProps = {
  award: ProgramAward;
  programAwards: ProgramAward[];
  programInstanceId: number;
  isFetching: boolean;
  loadProgramAwards: Function;
  updateProgramAwardCache: Function;
};

export const AwardAddEditComponent: React.FC<ProgramAwardAddEditProps> = (
  props
) => {
  const {
    award,
    programAwards,
    programInstanceId,
    loadProgramAwards,
    updateProgramAwardCache,
  } = props;

  const history = useHistory();
  const { programAwardId: paId }: any = useParams();
  const programAwardId = Number(paId);

  const [awardState, setAwardState] = useState<ProgramAward>({
    ...emptyProgramAward(),
    financialAidDistributionDate: undefined,
    firstDate: undefined,
  });

  useEffect(() => {
    setAwardState(clone(award));
  }, [award]);

  useEffect(() => {
    loadProgramAwards(programInstanceId);
  }, [loadProgramAwards, programInstanceId]);

  // define validation schema for validation hook
  type ValidationState = {
    applicationName: boolean;
    awardTypeId: boolean;
    certOrDegreeTitle: boolean;
    contactHours: boolean;
    credits: boolean;
    totalWeeks: boolean;
    transcriptName: boolean;
    sequenceId: boolean;
  };

  // set validations and extract functions/state from hook
  const {
    isValid,
    validationState,
    validate,
    validateIfTrue,
    validateAll,
    errorMessages,
  } = useValidation<ValidationState, ProgramAward>({
    applicationName: {
      errorMessage: 'Application Name must be between 0 and 60 characters.',
      validation: (val: string) => {
        return val && val.length > 0 && val.length < 60;
      },
    },
    awardTypeId: {
      errorMessage: 'Award Type must be selected.',
      validation: (val: number) => {
        return val && val > 0;
      },
    },
    certOrDegreeTitle: {
      errorMessage:
        'Cert or Degree title must be between 1 and 100 characters.',
      validation: (val: string) => {
        return val && val.length > 0 && val.length <= 100;
      },
    },
    contactHours: {
      errorMessage: 'Contact Hours must be a number greater than 0.',
      validation: (val: number) => {
        return val && val > 0;
      },
    },
    credits: {
      errorMessage: 'Credits must be a number greater than 0.',
      validation: (val: number) => {
        return val && val > 0;
      },
    },
    totalWeeks: {
      errorMessage: 'Total Weeks must be a number greater than 0.',
      validation: (val: number) => {
        return val && val > 0;
      },
    },
    transcriptName: {
      errorMessage: 'Transcript Name must be between 0 and 30 characters.',
      validation: (val: string) => {
        return val && val.length > 0 && val.length <= 30;
      },
    },
    sequenceId: {
      errorMessage: 'Sequence Id cannot be greater than 5 characters.',
      validation: (val: string) => {
        return val.length <= 5;
      },
    },
  });

  const onFormChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value);
    setAwardState({
      ...awardState,
      ...data,
    });
  };

  const validateAndSave = () => {
    return validateAll(awardState) ? saveAward() : null;
  };

  const saveAward = () => {
    let award;
    if (programAwardId === 0) {
      award = {
        ...awardState,
        programAwardId: createTempId(programAwards, 'programAwardId'),
        programInstanceId: programInstanceId,
      };
    } else {
      award = awardState;
    }
    updateProgramAwardCache({
      programInstanceId,
      record: award,
    });
    history.push(`/programs/awards/${programInstanceId}`);
  };
  const readOnly = useSelector((state) => {
    return getIsProgramReadOnly(state, programInstanceId);
  });
  return (
    <React.Fragment>
      <ScrollToTop />
      <SecureWrap isLocked={readOnly} component={'programs'}>
        <Paper>
          {awardState.programAwardId < 1 ? (
            <h1 className="form__title">Create New Program Award</h1>
          ) : (
            <h1 className="form__title">
              Edit Award: {award.certOrDegreeTitle}
            </h1>
          )}
          <FlexRow>
            <Input
              errorMessage={errorMessages.certOrDegreeTitle}
              name="certOrDegreeTitle"
              onBlur={() => {
                validate('certOrDegreeTitle', awardState.certOrDegreeTitle);
              }}
              onChange={onFormChange}
              valid={validationState.certOrDegreeTitle}
              value={awardState.certOrDegreeTitle}
            />
            <div
              className="form__group"
              onBlur={() => {
                validate('awardTypeId', awardState.awardTypeId);
              }}
            >
              <AwardTypeSelectBox
                selection={awardState.awardTypeId}
                errorMessage={errorMessages.awardTypeId}
                onChange={onFormChange}
                valid={validationState.awardTypeId}
              />
            </div>
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={errorMessages.applicationName}
              name="applicationName"
              onBlur={() => {
                validate('applicationName', awardState.applicationName);
              }}
              onChange={onFormChange}
              valid={validationState.applicationName}
              value={awardState.applicationName}
            />
            <Input
              errorMessage={errorMessages.transcriptName}
              name="transcriptName"
              onBlur={() => {
                validate('transcriptName', awardState.transcriptName);
              }}
              onChange={onFormChange}
              valid={validationState.transcriptName}
              value={awardState.transcriptName}
            />
          </FlexRow>
          <FlexRow>
            <Input
              errorMessage={errorMessages.contactHours}
              name="contactHours"
              onBlur={() => {
                validate('contactHours', awardState.contactHours);
              }}
              onChange={onFormChange}
              valid={validationState.contactHours}
              value={awardState.contactHours}
            />
            <Input
              errorMessage={errorMessages.credits}
              name="credits"
              onBlur={() => {
                validate('credits', awardState.credits);
              }}
              onChange={onFormChange}
              valid={validationState.credits}
              value={awardState.credits}
            />
            <Input
              errorMessage={errorMessages.totalWeeks}
              name="totalWeeks"
              onBlur={() => {
                validate('totalWeeks', awardState.totalWeeks);
              }}
              onChange={onFormChange}
              valid={validationState.totalWeeks}
              value={awardState.totalWeeks}
            />
          </FlexRow>
        </Paper>
      </SecureWrap>
      <SecureWrap component="programfinance">
        <Paper>
          <Instruction title="Financial Aid">
            <p>
              This section is for CCCS Financial Aid Admin only. If you are not
              a Financial Aid Administrator, please leave this blank.
            </p>
          </Instruction>
          <FlexRow>
            <Toggle
              description="This award is approved for financial aid."
              name="financialAidApproved"
              onChange={onFormChange}
              value={awardState.financialAidApproved}
            />
            <DatePicker
              id="financialAidDistributionDate"
              name="financialAidDistributionDate"
              label="Financial aid disbursement"
              value={awardState.financialAidDistributionDate}
              onChange={onFormChange}
            />
            <DatePicker
              id="firstDate"
              label="First day program offered"
              name="firstDate"
              onChange={onFormChange}
              value={awardState.firstDate}
            />
          </FlexRow>
        </Paper>
      </SecureWrap>
      <SecureWrap component="programbanner">
        <Paper>
          <Instruction title="Banner Code">
            <p>
              This section is for CCCS Banner Admin only. If you are not a
              Banner Admin please leave this blank.
            </p>
          </Instruction>
          <FlexRow>
            <Input
              name="bannerCode"
              onChange={onFormChange}
              value={awardState.bannerCode}
            />
            <Input
              errorMessage={errorMessages.sequenceId}
              name="sequenceId"
              label="Sequence ID"
              onBlur={() => {
                validate('sequenceId', awardState.sequenceId);
              }}
              onChange={onFormChange}
              valid={validationState.sequenceId}
              value={awardState.sequenceId}
            />
          </FlexRow>
        </Paper>
      </SecureWrap>
      <Paper>
        <SaveButtons
          cancelUrl={`/programs/awards/${programInstanceId}`}
          cancelText="return to program awards"
          disabled={!isValid}
          errorMessage="Please complete the form and check for potential validation errors."
          alternateAction={validateAndSave}
          saveText="Save Award"
        />
      </Paper>
    </React.Fragment>
  );
};
