import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ProgramLayout from 'layouts/ProgramLayout';
import { ProgramCareerPathwayEdit } from 'components/Program/CareerPathway/CareerPathwayConnect';

export const ProgramStepsCareerPathway: React.FC = (props) => {
  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramCareerPathwayEdit {...props} />
      </ProgramLayout>
    </BaseLayout>
  );
};
