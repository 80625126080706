import { combineReducers } from 'redux';
import {
  CHANGELOG_PROGRAMS_KEY,
  CHANGELOG_FOUR_YEAR_KEY,
  CHANGELOG_ANNUAL_SPENDING_KEY,
  CHANGELOG_ANNUAL_REFLECTION_KEY,
  CHANGELOG_IMPROVEMENT_PLAN_KEY,
  CHANGELOG_VOUCHER_ONE_KEY,
  CHANGELOG_VOUCHER_THREE_KEY,
  CHANGELOG_VOUCHER_TWO_KEY,
  CHANGELOG_FINAL_VOUCHER_KEY,
} from 'redux/keys';
import { changelogProgramsReducer } from './subtypes/programs.reducer';
import { CHANGELOG_KEY } from 'redux/keys';
import { curry } from 'ramda';
import { changelogFourYearReducer } from './subtypes/fouryear.reducer';
import { changelogAnnualSpendingReducer } from './subtypes/annualspending.reducer';
import { changelogAnnualReflectionReducer } from './subtypes/annualreflection.reducer';
import { changelogImprovementPlanReducer } from './subtypes/improvementplan.reducer';
import { WorkflowReduxEntities } from 'types';
import { changelogVoucherOneReducer } from './subtypes/voucherOne.reducer';
import { changelogFinalVoucherReducer } from './subtypes/finalVoucher.reducer';
import { changelogVoucherThreeReducer } from './subtypes/voucherThree.reducer';
import { changelogVoucherTwoReducer } from './subtypes/voucherTwo.reducer';

export const FETCH_CHANGELOG = `${CHANGELOG_KEY} FETCH`;
export const RESET_LAST_MODIFIED_CHANGELOG = `${CHANGELOG_KEY} RESET LAST MODIFIED`;
export const SET_CHANGELOG = `${CHANGELOG_KEY} SET`;
export const SET_CHANGELOG_LOADING = `${CHANGELOG_KEY} LOADING`;

export const loadChangelog = curry(
  (entityType: keyof WorkflowReduxEntities, entityId: number) => {
    return {
      type: FETCH_CHANGELOG,
      payload: {
        entityType,
        entityId,
      },
    };
  }
);

export const resetShouldUpdate = (entityType: keyof WorkflowReduxEntities) => {
  return {
    type: RESET_LAST_MODIFIED_CHANGELOG,
    payload: {
      entityType,
    },
  };
};

export default combineReducers({
  [CHANGELOG_PROGRAMS_KEY]: changelogProgramsReducer,
  [CHANGELOG_FOUR_YEAR_KEY]: changelogFourYearReducer,
  [CHANGELOG_ANNUAL_SPENDING_KEY]: changelogAnnualSpendingReducer,
  [CHANGELOG_ANNUAL_REFLECTION_KEY]: changelogAnnualReflectionReducer,
  [CHANGELOG_IMPROVEMENT_PLAN_KEY]: changelogImprovementPlanReducer,
  [CHANGELOG_VOUCHER_ONE_KEY]: changelogVoucherOneReducer,
  [CHANGELOG_VOUCHER_TWO_KEY]: changelogVoucherTwoReducer,
  [CHANGELOG_VOUCHER_THREE_KEY]: changelogVoucherThreeReducer,
  [CHANGELOG_FINAL_VOUCHER_KEY]: changelogFinalVoucherReducer,
});
