import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ConsortiumAddEdit from 'components/Grants/ConsortiumManagement/AddEdit';

type ConsortiumAddEditPageProps = {
  consortiumId: number;
};

export const ConsortiumAddEditPage: React.FC<ConsortiumAddEditPageProps> = (
  props
) => {
  return (
    <BaseLayout>
      <ConsortiumAddEdit {...props} />
    </BaseLayout>
  );
};
