import React, { useState } from 'react';
import { useOnChange } from 'hooks';
import { FlexRow, FlexGroup } from 'layouts';
import { ExpenseCategory } from 'types';
import { mergeObjects } from 'util/objectUtility';
import { RadioOption } from 'components/Common/FormElements/Radio/Radio.component';
import BaseLayout from 'layouts/BaseLayout';
import CheckboxGroupComponent from 'components/Common/FormElements/CheckboxGroup';
import Chip from 'components/Common/FormElements/Chip';
import DatePicker from 'components/Common/FormElements/DatePicker';
import DualListBox from 'components/Common/FormElements/DualListBox';
import Input from 'components/Common/FormElements/Input';
import Paper from 'components/Common/Paper';
import Radio from 'components/Common/FormElements/Radio';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Textarea from 'components/Common/FormElements/Textarea';
import ToggleButton from 'components/Common/FormElements/ToggleButton';

export const DevFormElements: React.FC<any> = () => {
  const listOptions: ExpenseCategory[] = [
    { expenseCategoryId: 1, expenseCategoryName: 'Shoes' },
    { expenseCategoryId: 2, expenseCategoryName: 'Pants' },
    { expenseCategoryId: 3, expenseCategoryName: 'Shirts' },
    { expenseCategoryId: 4, expenseCategoryName: 'Hoodies' },
    { expenseCategoryId: 5, expenseCategoryName: 'Hats' },
  ];

  const radioOptions: RadioOption[] = [
    { id: 1, label: 'Disagree' },
    { id: 2, label: 'Somewhat Disagree' },
    { id: 3, label: 'Somewhat Agree' },
    { id: 4, label: 'Agree' },
  ];

  const [secro, setSecro] = useState<boolean>(false);
  const [override, setOverride] = useState<boolean>(false);
  const [values, setValues, onChange] = useOnChange<any>({
    textInput: '',
    numInput: 0,
    passwordInput: '',
    creditInput: 0,
    textArea: '',
    checked: false,
    datePicker: undefined,
    radio: 1,
    lettuce: true,
    tomato: false,
    mustard: true,
    sprouts: false,
    selectedListData: [],
  });

  const toggleSecro = () => setSecro(!secro);
  const toggleOverride = () => setOverride(!override);

  const handleListSelect = (selectedListData: any[]) => {
    setValues(mergeObjects(values, { selectedListData }));
  };

  return (
    <BaseLayout>
      <Paper>
        <h1>Form Elements</h1>
        <FlexRow>
          <p>This page is for testing form element components.</p>
        </FlexRow>
        <FlexGroup>
          <ToggleButton
            onChange={toggleSecro}
            value={secro}
            text="Secure Wraps"
          />
          <ToggleButton
            onChange={toggleOverride}
            value={override}
            text="Override Secure"
          />
        </FlexGroup>
      </Paper>
      <SecureWrap component={secro ? ['programs'] : ['programfinance']}>
        <div className={override ? 'u-sec-ro--override' : ''}>
          <Paper>
            <FlexRow>
              <div style={{ width: '20%' }}>
                <Input
                  name="textInput"
                  value={values.textInput}
                  onChange={onChange}
                />
              </div>
            </FlexRow>
          </Paper>
          <Paper>
            <FlexRow>
              <div style={{ width: '20%' }}>
                <Input
                  name="numInput"
                  type="number"
                  value={values.numInput}
                  onChange={onChange}
                />
              </div>
            </FlexRow>
          </Paper>
          <Paper>
            <FlexRow>
              <div style={{ width: '20%' }}>
                <Input
                  name="passwordInput"
                  type="password"
                  value={values.passwordInput}
                  onChange={onChange}
                />
              </div>
            </FlexRow>
          </Paper>
          <Paper>
            <FlexRow>
              <Textarea
                name="textArea"
                value={values.textArea}
                onChange={onChange}
              />
            </FlexRow>
          </Paper>
          <Paper>
            <p className="form__label">Checkbox Group</p>
            <FlexRow>
              <CheckboxGroupComponent
                groupTitle="Sandwich Ingredients"
                onChange={onChange}
                options={{
                  lettuce: values.lettuce,
                  tomato: values.tomato,
                  mustard: values.mustard,
                  sprouts: values.sprouts,
                }}
              />
            </FlexRow>
          </Paper>
          <Paper>
            <FlexRow>
              <Radio
                label="Radio Input"
                name="radio"
                onChange={onChange}
                options={radioOptions}
                value={values.radio}
              />
            </FlexRow>
          </Paper>
          <Paper>
            <FlexRow>
              <div style={{ width: '20%' }}>
                <DatePicker
                  name="datePicker"
                  label="Date Picker Input"
                  onChange={onChange}
                  value={values.datePicker}
                />
              </div>
            </FlexRow>
          </Paper>
          <Paper>
            <p className="form__label">Chip Component</p>
            <FlexRow>
              <Chip id="dingo" name="Chip Name" onClose={() => null} />
            </FlexRow>
          </Paper>
          <Paper>
            <p className="form__label">Dual List Box</p>
            <FlexRow>
              <FlexGroup>
                <DualListBox
                  data={listOptions}
                  dataLabel="dataLabel"
                  displayName="expenseCategoryName"
                  height={200}
                  onChange={handleListSelect}
                  selectedData={values.selectedListData}
                  selectedDataLabel="selectedDataLabel"
                  selector="expenseCategoryId"
                />
              </FlexGroup>
            </FlexRow>
          </Paper>
        </div>
      </SecureWrap>
    </BaseLayout>
  );
};
