import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { QuestionGroup } from 'types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { FourYearPlanValidations } from 'validations/FourYearPlan.validations';

type Props = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadQuestions: Function;
  optionalAction: any;
  questions: { [key: string]: QuestionGroup[] };
  setCurrentNavAnchor: Function;
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
};

export const FourYearWorkflowComponent: React.FC<Props> = (props) => {
  const { loadQuestions, questions } = props;

  const {
    careerInfoQuestions,
    equityQuestions,
    instructorsQuestions,
    needsAssessmentQuestions,
    partnershipsQuestions,
    qualityQuestions,
    workBasedLearningQuestions,
  } = questions;

  const dispatch = useDispatch();

  const { grantFiscalYearId: cId }: any = useParams();
  const grantFiscalYearId = Number(cId);

  const { validateCustom, validationErrors } = FourYearPlanValidations();

  const wrap = {
    ...props,
    validationErrors,
  };

  useEffect(() => {
    loadQuestions(dispatch, grantFiscalYearId);
  }, [grantFiscalYearId]); // eslint-disable-line

  /* eslint-disable */
  useEffect(() => {
    validateCustom([
      { key: 'needsAssessment', value: needsAssessmentQuestions },
      { key: 'careerInfo', value: careerInfoQuestions },
      { key: 'quality', value: qualityQuestions },
      { key: 'instructors', value: instructorsQuestions },
      { key: 'partnerships', value: partnershipsQuestions },
      { key: 'workBasedLearning', value: workBasedLearningQuestions },
      { key: 'equity', value: equityQuestions },
    ]);
  }, [
    grantFiscalYearId,
    needsAssessmentQuestions,
    careerInfoQuestions,
    qualityQuestions,
    instructorsQuestions,
    partnershipsQuestions,
    workBasedLearningQuestions,
    equityQuestions,
  ]);
  /* eslint-enable */

  return <WorkflowSummaryComponent {...wrap} />;
};
