import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useOnChange } from 'hooks';
import { toast } from 'react-toastify';
import { useModal, Modal } from 'react-morphing-modal';
import { fmap } from 'util/arrayUtility';
import { includes, find, every, add } from 'lodash';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { compose, prop, renderData } from 'util/objectUtility';
import { createVoucherCategoryUpdate } from 'services/grants/vouchers.services';
import { getCategoryFundsRemaining } from 'util/voucherUtility';
import { loadActualReimbursementRequestData } from 'redux/grants/actualreimbursementrequest';
import { loadReimbursementRequestSummaryData } from 'redux/grants/reimbursementrequestsummary';
import { VoucherExpenseValidations } from 'validations/Vouchers/VoucherExpense.validations';
import {
  Voucher,
  VoucherSpendingCategory,
  emptyVoucherSpendingCategory,
} from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { COLOR_LIGHT_GREEN } from 'constants/css.const';
import EditCategoryExpenses from './EditCategoryExpenses';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';

type VoucherSpendingCategoryEditProps = {
  categories: {
    adminCosts: VoucherSpendingCategory[];
    projectPlans: VoucherSpendingCategory[];
    totalRowData: VoucherSpendingCategory[];
  };
  isLocked: boolean;
  loadSingleVoucherByType: Function;
  voucher: Voucher;
};

export const VoucherSpendingCategoryEdit: React.FC<
  VoucherSpendingCategoryEditProps
> = (props) => {
  const { categories, isLocked, loadSingleVoucherByType, voucher } = props;
  const { adminCosts, projectPlans, totalRowData } = categories;

  const dispatch = useDispatch();
  const { grantFiscalYearId: gfy, voucherType }: any = useParams();
  const grantFiscalYearId = Number(gfy);
  const voucherId = Number(voucher.voucherId);

  const { modalProps, open, close } = useModal();
  const [spinner, setSpinner] = useState<boolean>(false);
  const [spendingCategory, setSpendingCategory, onChange] = useOnChange(
    emptyVoucherSpendingCategory()
  );
  const getSpendingCategory = (spendingCategoryId: number) => {
    const category = find(voucher.categories, { spendingCategoryId });
    return category ? category : emptyVoucherSpendingCategory();
  };
  const reloadActualReimbursement = compose(
    dispatch,
    loadActualReimbursementRequestData
  );
  const reloadReimbursementRequestSummary = compose(
    dispatch,
    loadReimbursementRequestSummaryData
  );

  const updateCategoryToEdit = compose(
    setSpendingCategory,
    getSpendingCategory
  );

  const { validateAll: validateExpense } = VoucherExpenseValidations();
  const validateCategoryState = compose(
    every, // true = all valid, false = one or more false
    fmap(validateExpense), // execute validate all on all expense objects
    prop('expenses') // pull expenses off object
  );

  const handleSave = async () => {
    if (validateCategoryState(spendingCategory)) {
      setSpinner(true);
      return createVoucherCategoryUpdate(
        grantFiscalYearId,
        voucherId,
        spendingCategory
      )
        .then(() => loadSingleVoucherByType(grantFiscalYearId, voucherType))
        .then(() => reloadReimbursementRequestSummary(grantFiscalYearId))
        .then(() => reloadActualReimbursement(grantFiscalYearId))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .then(() => setSpinner(false))
        .catch(() => {
          setSpinner(false);
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        })
        .finally(() => close());
    }
    return toast(
      <ToastNotification
        status="error"
        message={StringResources.Errors.ValidationFailed}
      />
    );
  };

  const isAdminCostCategory = (categoryId: number) => {
    switch (true) {
      case includes([7, 8, 9], categoryId):
        return 'Administrative Costs';
      case includes([1, 2, 3, 4, 5, 6], categoryId):
        return 'Project Plan';
      default:
        return '';
    }
  };

  const getAllocatedFunds = (row: VoucherSpendingCategory) => {
    return row.isFundPool && row.isPartnershipLead
      ? add(
          prop('allocatedFunds', row),
          prop('partnershipInstituteContribution', row)
        )
      : prop('allocatedFunds', row);
  };

  const Strategy = compose(
    renderData,
    prop('strategy')
  );
  const ReimbursementRequest = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('reimbursementRequest')
  );
  const AllocatedFunds = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    getAllocatedFunds
  );
  const RemainingFunds = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    getCategoryFundsRemaining
  );
  const ActualAmounts = compose(
    renderData,
    insertDollarSign,
    formatMoney,
    prop('totalAmountForCurrentRequest')
  );
  const CategoryName = compose(
    isAdminCostCategory,
    prop('categoryId')
  );

  const renderActualCell = (row: VoucherSpendingCategory) => {
    return row.categoryId > 0 ? (
      <React.Fragment>{ActualAmounts(row)}</React.Fragment>
    ) : (
      <span className="custom-table__cell--green">{ActualAmounts(row)}</span>
    );
  };

  const renderEditIcon = (row: VoucherSpendingCategory) => {
    return row.categoryId > 0 ? (
      <div className="u-flex u-flex-full-center u-sec-ro--override">
        <IconButton
          name="pencil"
          tooltip="Edit"
          asLink={false}
          modalClick={open}
          onClick={() => updateCategoryToEdit(row.spendingCategoryId)}
        />
      </div>
    ) : null;
  };

  const renderRemainingCell = (row: VoucherSpendingCategory) => {
    const renderTotal = compose(
      renderData,
      insertDollarSign,
      formatMoney
    );
    const totalRemaining = getCategoryFundsRemaining(row);
    const className =
      totalRemaining >= 0
        ? 'custom-table__cell--green'
        : 'custom-table__cell--red';
    const total = <p className={className}>{renderTotal(totalRemaining)}</p>;
    return row.spendingCategoryId < 0 ? total : <p>{RemainingFunds(row)}</p>;
  };

  const getFirstCategory = () => {
    return projectPlans.length ? [1, 2, 3, 4, 5, 6, 10] : [7, 8, 9];
  };

  const renderHeader = (label: any) => (category: VoucherSpendingCategory) => {
    return includes(getFirstCategory(), prop('categoryId', category))
      ? label
      : '';
  };

  const conditionalRowStyles = [
    {
      when: (row: VoucherSpendingCategory) =>
        every([row.isPartnershipLead, row.isFundPool]),
      style: {
        backgroundColor: COLOR_LIGHT_GREEN,
      },
    },
    {
      when: (row: VoucherSpendingCategory) => row.categoryId < 0,
      style: {
        backgroundColor: 'transparent',
        border: '1px solid #6a7677',
      },
    },
  ];

  const CATEGORY_COLUMNS = (
    category: VoucherSpendingCategory
  ): TableColumnProps[] => [
    {
      cell: Strategy,
      label: CategoryName(category),
      name: 'strategy',
      width: '56%',
    },
    {
      cell: AllocatedFunds,
      label: renderHeader('Allocated Funds')(category),
      name: 'Allocated Funds',
      style: {
        textAlign: 'right',
        width: '12%',
      },
    },
    {
      cell: ReimbursementRequest,
      label: renderHeader('Reimbursement Requested To Date')(category),
      name: 'Reimbursement Requested To Date',
      style: {
        textAlign: 'right',
        width: '12%',
      },
    },
    {
      cell: renderActualCell,
      label: renderHeader('Total Amount for Current Request')(category),
      name: 'Total Amount for Current Request',
      style: {
        textAlign: 'right',
        width: '12%',
      },
    },
    {
      cell: renderRemainingCell,
      label: renderHeader('Remaining Funds')(category),
      name: 'Remaining Funds',
      style: {
        textAlign: 'right',
        width: '12%',
      },
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      label: renderHeader('Edit')(category),
      center: true,
      style: {
        width: '5%',
      },
    },
  ];

  return (
    <React.Fragment>
      <Instruction title="Step 1: Edit Each Spending Plan to Record Actual Amounts">
        <p>
          Use 'Edit' to record actual amounts spent for each project plan
          category during this voucher period in order to request reimbursement
          for allowable expenses. Rows highlighted in green indicate fund pooled
          projects.
        </p>
      </Instruction>
      {projectPlans.length > 0 && (
        <Table
          columns={CATEGORY_COLUMNS(projectPlans[0])}
          conditionalRowStyles={conditionalRowStyles}
          data={projectPlans}
        />
      )}
      {adminCosts.length > 0 && (
        <Table
          columns={CATEGORY_COLUMNS(adminCosts[0])}
          conditionalRowStyles={conditionalRowStyles}
          data={adminCosts}
          tableRowKey="categoryId"
        />
      )}
      {totalRowData.length > 0 && (
        <Table
          columns={CATEGORY_COLUMNS(totalRowData[0])}
          conditionalRowStyles={conditionalRowStyles}
          data={totalRowData}
          displayColumnLabels={false}
        />
      )}
      <Modal {...modalProps}>
        <EditCategoryExpenses
          close={close}
          handleSave={handleSave}
          onChange={onChange}
          spendingCategory={spendingCategory}
          spinner={spinner}
          isLocked={isLocked}
        />
      </Modal>
    </React.Fragment>
  );
};
