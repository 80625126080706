/**
 * This file contains functions to help in processing vouchers.
 * @module Utilities > VoucherUtility
 * @author AmplifyCP
 */
import { VoucherSpendingCategory, Voucher, VoucherExpense } from 'types';
import {
  add,
  subtract,
  addTotals,
  sumCollection,
  sumNumbers,
} from './mathHelpers';
import { reduce, find, every, map, some } from 'lodash';
import { prop, converge, compose } from 'util/objectUtility';
import { isEqual } from './formHelpers';
import { fmap } from './arrayUtility';
import { rEither } from './global';

/**
 * local helper for utilities to run sum collection on a supplied property name
 * @function
 * @param property keyof VoucherExpense
 * @return (p: keyof VoucherExpense) => (c: VoucherSpendingCategory) => number;
 */
const getExpenseSum = (property: keyof VoucherExpense) => {
  return compose(sumCollection(addTotals(property)), prop('expenses'));
};

// ------------------------------------
//      VoucherCategory Helpers
// ------------------------------------

/**
 * get total actual voucher requests from a spending category.
 * @function
 * @param spendingCategory VoucherSpendingCategory
 * @returns the sum total of current actual voucher requests
 */
export const getCategoryAmountForCurrentVoucher = (
  cat: VoucherSpendingCategory
) => {
  const isIndirect = compose(isEqual(7), prop('categoryId'));
  const func = isIndirect(cat)
    ? prop('totalAmountForCurrentRequest')
    : getExpenseSum('amountForCurrentVoucher');
  return func(cat);
};

/**
 * get reimbursement requested to date for a category
 * @function
 * @param spendingCategory VoucherSpendingCategory
 * @returns the sum total of current actual voucher requests
 */
export const getCategoryReimbursementRequestsToDate = prop(
  'reimbursementRequest'
);

/**
 * Get the available funds for a Voucher Project Plan
 * (allocatedFunds + partnershipInstituteContribution) if is fund pool lead
 * @function
 * @param spendingCategory VoucherSpendingCategory
 * @returns the sum total of available amount
 */
export const getCategoryFundsAllocated = (
  spendingCategory: VoucherSpendingCategory
) => {
  if (!spendingCategory) return 0;
  const {
    allocatedFunds,
    isFundPool,
    isPartnershipLead,
    partnershipInstituteContribution,
  } = spendingCategory;

  const availableFunds = every([isFundPool, isPartnershipLead])
    ? add(allocatedFunds, partnershipInstituteContribution)
    : allocatedFunds;

  return availableFunds;
};

/**
 * Get the amount remaining between allocated, requestedToDate, and amount for
 * a voucher spending category.
 * (A - B) - C
 * A: category funds allocated
 * B: reimbursement requested to date
 * C: amount for current voucher (summing from expenses)
 * @function
 * @param voucherSpendingCategory VoucherSpendingCategory
 * @return number
 */
export const getCategoryFundsRemainingFromExpenses = converge(subtract, [
  converge(subtract, [
    getCategoryFundsAllocated,
    getCategoryReimbursementRequestsToDate,
  ]),
  getCategoryAmountForCurrentVoucher,
]);

/**
 * Get the amount allocated plus partnershipInstituteContribution for
 * current category.
 * @function
 * @param voucherSpendingCategory VoucherSpendingCategory
 * @return number
 */
export const getAllocatedCategoryFunds = converge(add, [
  prop('allocatedFunds'),
  rEither(prop('partnershipInstituteContribution'), () => 0),
]);

/**
 * Get the amount remaining between allocated, requestedToDate, and amount for
 * current voucher.
 * (A - B) - C
 * A: category funds allocated
 * B: reimbursement requested to date
 * C: amount for current voucher (summing from values in DB)
 * @function
 * @param voucherSpendingCategory VoucherSpendingCategory
 * @return number
 */
export const getCategoryFundsRemaining = converge(subtract, [
  converge(subtract, [getAllocatedCategoryFunds, prop('reimbursementRequest')]),
  prop('totalAmountForCurrentRequest'),
]);

// ------------------------------------
//      Voucher Helpers
// ------------------------------------

/**
 * Get the total award amount for the voucher.
 * @function
 * @param voucher
 * @return number
 */
export const getTotalAwardAmount = (voucher: Voucher) => {
  return add(voucher.basicGrant, voucher.reserveGrant);
};

/**
 * Get the total allocations + fund pool contributions
 * @function
 * @param voucher
 * @return number
 */
export const getVoucherTotalAllocated = compose(
  sumNumbers,
  fmap(getAllocatedCategoryFunds),
  prop('categories')
);

/**
 * Get the total of all requests for the voucher.
 * @function
 * @param voucher Voucher
 * @return number
 */
export const getVoucherCurrentRequests = compose(
  sumNumbers,
  fmap(prop('totalAmountForCurrentRequest')),
  prop('categories')
);

/**
 *  Get the total of all requests to date for the voucher.
 * @function
 * @param voucher Voucher
 * @return number
 */
export const getVoucherRequestsToDate = compose(
  sumNumbers,
  fmap(prop('reimbursementRequest')),
  prop('categories')
);

/**
 * Get the total of all requests for a categoryId in a voucher.
 * @function
 * @param voucher Voucher
 * @param categoryId the categoryId
 * @return number
 */
export const getVoucherRequestsByCategoryID = (
  voucher: Voucher,
  categoryId: number
) => {
  const category = find(voucher.categories, { categoryId });
  return category ? getCategoryAmountForCurrentVoucher(category) : 0;
};

/**
 * Get the total amount remaining for all requests for a voucher.
 * @function
 * @param voucher Voucher
 * @return number
 */
export const getTotalVoucherRemaining = (voucher: Voucher) => {
  const allAvailable = map(voucher.categories, getCategoryFundsRemaining);
  const total = sumNumbers(allAvailable);
  return total;
};

/**
 * get the value of all remaining available funds from other categories
 * @function
 * @param voucher Voucher
 * @param spendingCategory VoucherSpendingCategory
 * @return number
 */
export const getOtherSourcesAvailable = (
  voucher: Voucher,
  spendingCategory: VoucherSpendingCategory
) => {
  const { categories } = voucher;
  return reduce(
    categories,
    (prev: number, curr: VoucherSpendingCategory) => {
      const isCurrent = isEqual(curr.categoryId, spendingCategory.categoryId);
      return some([isCurrent, curr.isFundPool])
        ? prev
        : add(prev, getCategoryFundsRemaining(curr));
    },
    0
  );
};

/**
 * get the voucher entity type based on the url parameter
 * @function
 * @param voucherName string
 * @return string
 */
export const getVoucherEntityType = (voucherName: string): string => {
  switch (voucherName) {
    case 'Voucher 1':
      return 'voucher-one';
    case 'Voucher 2':
      return 'voucher-two';
    case 'Voucher 3 (Optional)':
      return 'voucher-three';
    case 'Final Voucher':
      return 'final-voucher';
    default:
      return 'voucher-one';
  }
};
