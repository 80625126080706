import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { QuestionGroup } from 'types';
import { useParams } from 'react-router';
import { QuestionValidation } from 'validations/Question.validations';

type Props = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  loadAnnualReflection: Function;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  questions: QuestionGroup[];
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
};

export const AnnualReflectionWorkflowComponent: React.FC<Props> = (props) => {
  const { loadAnnualReflection, questions } = props;

  const { validate, validationErrors } = QuestionValidation();

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const wrap = {
    ...props,
    validationErrors,
  };

  useEffect(() => {
    loadAnnualReflection(grantFiscalYearId);
  }, [loadAnnualReflection, grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    validate('allQuestionsAnswered', questions);
  }, [questions, grantFiscalYearId]); //eslint-disable-line

  return <WorkflowSummaryComponent {...wrap} />;
};
