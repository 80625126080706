import { updateContactPassword, loadUser, logUserOut } from 'redux/user';
import { getLoggedinUser } from 'redux/user/selectors';

export const userMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState, dispatch } = store;

  next(action);

  switch (action.type) {
    case updateContactPassword.ENDED: {
      const currentUserId = getLoggedinUser(getState()).aadB2cId;
      if (currentUserId) {
        dispatch(loadUser(currentUserId));
      } else {
        logUserOut();
      }
      break;
    }
  }
};
