import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readCareerClusters } from 'services/common';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { CareerCluster } from 'types';

const initialState = baseInitialState;

export const loadCareerClusters = createActionThunk(
  'loadCareerClusters',
  () => {
    return readCareerClusters();
  }
); // end loadCareerClusters

export default handleActions<BaseStateObject, CareerCluster | CareerCluster[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<CareerCluster | CareerCluster[]>(
      loadCareerClusters
    ),
  },
  initialState
);
