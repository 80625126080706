import { District, Institute } from 'types';
import { ConsortiumMember } from './ConsortiumMember.types';
import { UniversalContact } from 'types/universalcontact';

export interface GrantParticipationMember extends ConsortiumMember {
  contacts: UniversalContact[];
  grantFiscalYearId: number;
  level: string;
  isStandalone: boolean;
  consortiumName: string;
  district?: District;
  institute?: Institute;
  key?: string;
  participationChange: boolean;
  wasSubmitted: boolean;
  grantCreated: boolean;
  exemptionReason: number;
  exemptionFilePath: string;
  changeExplanation: string;
  responded: boolean;
  notified: boolean;
  guid?: string;
}

export const emptyGrantParticipationMember = (): GrantParticipationMember => {
  return {
    grantCreated: false,
    id: -1,
    contacts: [],
    consortiumId: -1,
    grantFiscalYearId: -1,
    level: '',
    isStandalone: true,
    consortiumName: '',
    name: '',
    type: '',
    participationChange: false,
    wasSubmitted: false,
    exemptionReason: 0,
    exemptionFilePath: '',
    changeExplanation: '',
    responded: false,
    notified: false,
    guid: undefined,
  };
};
