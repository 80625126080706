import { CTACourseTerm } from '.';

export interface CTACourse {
  ctaCourseId: number;
  ctaId: number;
  ctaHostingContractingId: number;
  ctaSchoolId?: number;
  terms: CTACourseTerm[];
  coCourseName: string;
  cteCourseName: string;
  schoolName: string;
  partnerUpload: boolean;
  programName: string;
  ctaInstructorProgramCourseId: number;
}

export const emptyCTACourse = (): CTACourse => {
  return {
    ctaCourseId: 0,
    ctaId: 0,
    ctaHostingContractingId: 0,
    terms: [],
    coCourseName: '',
    cteCourseName: '',
    schoolName: '',
    partnerUpload: false,
    programName: '',
    ctaInstructorProgramCourseId: -1,
  };
};
