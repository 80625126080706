import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { Signature } from 'types';
import {
  readGrantAgreementSignatures,
  updateGrantAgreementSignatures,
} from 'services/grants/awardsignature.services';

const initialState = baseInitialState;

export const loadGrantAgreementSignatures = createActionThunk(
  'loadGrantAgreementSignatures',
  (grantFiscalYearId: number) => {
    return readGrantAgreementSignatures(grantFiscalYearId);
  }
);

export const saveGrantAgreementSignatures = createActionThunk(
  'saveGrantAgreementSignatures',
  async (grantFiscalYearId: number, payload: Signature[]) => {
    return updateGrantAgreementSignatures(grantFiscalYearId, payload);
  }
);

export default handleActions<BaseStateObject, Signature | Signature[]>(
  {
    ...baseLoadActionBuilder<Signature | Signature[]>(
      loadGrantAgreementSignatures
    ),
    ...baseSaveActionBuilder<Signature | Signature[]>(
      saveGrantAgreementSignatures
    ),
  },
  initialState
);
