import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readProgramLengths } from 'services/common';
import { createProgramLengths } from 'services/common/programlengths.services';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { ProgramLength } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadProgramLengths = createActionThunk(
  'loadProgramLengths',
  () => {
    return readProgramLengths();
  }
); // end

export const removeProgramLength = createActionThunk(
  'removeProgramLength',
  () => {
    return readProgramLengths();
  }
); // end

export const saveProgramLength = createActionThunk(
  'saveProgramLength',
  (lengthtext: string) => {
    return createProgramLengths({
      programLengthId: -1,
      programLengthName: lengthtext,
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    });
  }
); // end

export default handleActions<BaseStateObject, ProgramLength[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramLength[]>(loadProgramLengths),
  },
  initialState
);
