import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  createContactType,
  readContactTypes,
  updateContactType,
} from 'services/universalcontacts/contactType.services';
import {
  actionFetching,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { ContactType } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadContactTypes = createActionThunk('loadContactTypes', () => {
  return readContactTypes();
}); // end

export const saveContactType = createActionThunk(
  'saveContactType',
  (payload: ContactType) => {
    if (!payload.contactTypeId || payload.contactTypeId === -1) {
      return createContactType(payload);
    } else {
      return updateContactType(payload);
    }
  }
); // end updateContactType

export default handleActions<BaseStateObject, ContactType[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ContactType[]>(loadContactTypes),

    [saveContactType.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [saveContactType.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
