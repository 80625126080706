import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { isDisabled } from 'util/authorization';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IconButton from 'components/Common/IconButton';

type ChipProps = {
  editable?: boolean;
  id: string;
  name: string | JSX.Element;
  onClose: Function;
  style?: CSSProperties;
};

export const Chip: React.FC<ChipProps> = (props) => {
  const { editable = true, onClose, name, id, style } = props;
  const disabled = !editable; // oops...

  const handleOnClose = () => onClose(id);
  const [computedDisable, setComputedDisable] = useState<boolean>(false);
  const ref = useRef(null);

  // get the file name for the confirmation dialog
  const fileName = () => {
    if (typeof name === 'string') {
      return name;
    }
    if (typeof name === 'object') {
      return name.props ? name.props.text : '';
    }
    return '';
  };

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, ref);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  return (
    <div className="amplify-chip u-flex" style={style}>
      <div
        className="amplify-chip--content u-flex u-flex-grow-1"
        style={{ height: '100%', alignItems: 'center' }}
      >
        {name}
      </div>
      {editable && (
        <div className="no-show">
          <ConfirmationDialog
            tooltip="Delete"
            header="Confirm Delete"
            disabled={computedDisable}
            activateButton={
              <IconButton
                className="u-menu-icon"
                disabled={computedDisable}
                name="crossCircle"
                tooltip="Delete"
              />
            }
            onConfirm={handleOnClose}
          >
            <h3>Are you sure you want to delete the file {`${fileName()}`}?</h3>
          </ConfirmationDialog>
        </div>
      )}
    </div>
  );
};
