import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  catchError,
} from 'services';
import { School } from 'types';

const BASE_ENDPOINT = `${ENVIRONMENT.API_URL}api/DistrictInstituteSchool/`;
const SCHOOLS_ENDPOINT = `${BASE_ENDPOINT}schools/`;
const INSTITUTES_ENDPOINT = `${BASE_ENDPOINT}institutes/`;
const DISTRICTS_ENDPOINT = `${BASE_ENDPOINT}districts/`;
const DEPARTMENTS_ENDPOINT = `${BASE_ENDPOINT}departments/`;
const BUSINESS_ENDPOINT = `${ENVIRONMENT.API_URL}api/schools/business`;

export const readSchools = async () => {
  return fetch(SCHOOLS_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('DistrictInstituteSchool', err);
    });
};

export const readInstitutes = async () => {
  return fetch(INSTITUTES_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('DistrictInstituteSchool', err);
    });
};

export const readDistricts = async () => {
  return fetch(DISTRICTS_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('DistrictInstituteSchool', err);
    });
};

export const readDepartments = async () => {
  return fetch(DEPARTMENTS_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('DistrictInstituteSchool', err);
    });
};

export const createBusiness = async (school: School) => {
  return fetch(BUSINESS_ENDPOINT, {
    ...postWithAuthOptions(),
    body: JSON.stringify(school),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('DistrictInstituteSchool', err);
    });
};
