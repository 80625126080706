import { useState, Dispatch, SetStateAction } from 'react';
import { cMergeObjects, compose } from 'util/objectUtility';
import { HandleChangeFunction } from 'types';

// this has a return type of any because Typescript doesn't really
// know how to infer the typing on "setState" with array destructuring
export function useOnChange<T>(
  value: T
): [T, Dispatch<SetStateAction<T>>, HandleChangeFunction] {
  const [state, setState] = useState<T>(value);
  const onChange = compose(
    setState,
    cMergeObjects(state)
  );
  return [state, setState, onChange];
}
