import React, { CSSProperties } from 'react';
import { COLOR_BLUE } from 'constants/css.const';
import Tooltip from 'components/Common/Tooltip';
import Icon from 'components/Common/Icons';

interface LabelWithIconProps {
  htmlFor: string;
  label: string;
  iconName: string;
  iconClassName?: string;
  place?: 'right' | 'left' | null;
  position?: 'above' | 'below' | null;
  style?: CSSProperties;
  tooltip?: string;
}
export const LabelWithIcon: React.FC<LabelWithIconProps> = (props) => {
  const {
    htmlFor,
    label,
    iconName,
    iconClassName = '',
    place,
    position,
    style,
    tooltip,
  } = props;

  const defaultStyle = {
    width: '2.2rem',
    fill: COLOR_BLUE,
    alignSelf: 'flex-start',
    marginLeft: '1rem',
  };

  return (
    <div className="u-flex">
      <label htmlFor={htmlFor} className="form__label">
        {label}
      </label>
      {tooltip ? (
        <Tooltip
          indicator={
            <Icon
              name={iconName}
              className={iconClassName}
              style={{ ...defaultStyle, ...style }}
            />
          }
          place={place}
          position={position}
          tooltip={tooltip}
        />
      ) : (
        <Icon
          name={iconName}
          className={iconClassName}
          style={{ ...defaultStyle, ...style }}
        />
      )}
    </div>
  );
};
