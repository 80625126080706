import {
  baseInitialState,
  actionUpdateRecords,
  actionFetching,
} from 'redux/utility/_exports';
import { WORKFLOW_TWO_YEAR_KEY } from 'redux/keys';

export const SET_TWO_YEAR_WORKFLOW = `${WORKFLOW_TWO_YEAR_KEY} SET`;
export const SET_TWO_YEAR_WORKFLOW_LOADING = `${WORKFLOW_TWO_YEAR_KEY} SET LOADING`;

// -- actions ------------------------------------------------------------------
export const setTwoYearWorkflow = (payload: any) => {
  return {
    type: SET_TWO_YEAR_WORKFLOW,
    payload,
  };
};

export const setTwoYearWorkflowLoading = (payload: boolean) => {
  return {
    type: SET_TWO_YEAR_WORKFLOW_LOADING,
    payload,
  };
};

// -- reducer ------------------------------------------------------------------
const initState = baseInitialState;

export const twoYearWorkflowReducer = (workflow = initState, action: any) => {
  switch (action.type) {
    case SET_TWO_YEAR_WORKFLOW:
      return actionUpdateRecords(workflow, action.payload);

    case SET_TWO_YEAR_WORKFLOW_LOADING:
      return actionFetching(workflow, action.payload);

    default:
      return workflow;
  }
};
