import React from 'react';
import CreditInput from 'components/Common/FormElements/CreditInput';
import { CourseCreditAward, CourseCreditGeneral } from 'types';
import Textarea from 'components/Common/FormElements/Textarea';
import { map } from 'lodash';
import { randomString } from 'util/global';

type GeneralCourseCreditsInputRowProps = {
  generalCourse: CourseCreditGeneral;
  onChange: Function;
};

export const GeneralCourseCreditsInputRow: React.FC<
  GeneralCourseCreditsInputRowProps
> = (props) => {
  const { generalCourse, onChange } = props;
  const { title, courses, awards } = generalCourse;

  const updateCredits = (data: CourseCreditAward) => {
    const updatedAwardCredits = map(
      generalCourse.awards,
      (award: CourseCreditAward) => {
        return data.programAwardId === award.programAwardId ? data : award;
      }
    );
    onChange({
      ...generalCourse,
      awards: [...updatedAwardCredits],
    });
  };

  const updateCourses = (data: any) => {
    onChange({
      ...generalCourse,
      ...data,
    });
  };

  return (
    <React.Fragment>
      <div className="course-credits__education-row">
        <div className="course-credits__info">
          <Textarea
            id={randomString()}
            name="courses"
            label={title}
            value={courses}
            onChange={updateCourses}
          />
        </div>
        <div className="course-credits__input-row">
          {map(awards, (award: CourseCreditAward, index: number) => {
            return (
              <div key={index}>
                <CreditInput
                  programAwardId={award.programAwardId}
                  value={award.credits}
                  onChange={updateCredits}
                  className="course-credits__input"
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};
