import React from 'react';
import ReactSelect from 'react-select';
import { OptionMap, DropdownItem, HandleSelect, ProgramState } from 'types';
import { dropdownMapper } from 'util/formHelpers';
import { SelectBoxProps } from 'components/Common/FormElements/SelectBox/SelectBox.component';
import { map, filter, includes } from 'lodash';

interface ProgramStateMultiSelectBoxProps extends SelectBoxProps {
  selectedStates: number[];
  states: ProgramState[];
}

export const ProgramStateMultiSelectBoxComponent: React.FC<ProgramStateMultiSelectBoxProps> = (
  props
) => {
  const {
    errorMessage,
    states,
    selectedStates,
    onBlur,
    onChange,
    disabled,
    valid = true,
  } = props;

  const optionMap: OptionMap<ProgramState> = {
    key: 'programStateId',
    label: 'programStateName',
    value: 'programStateId',
  };

  const options = dropdownMapper(states, optionMap);

  const selectOrgs = () => {
    return filter(options, (item: DropdownItem) => {
      return includes(selectedStates, item.value);
    });
  };

  const handleSelection: HandleSelect = (items: any) => {
    if (disabled || !onChange) {
      return;
    }
    const data: { [key: string]: unknown } = {};

    if (!items) {
      data['programStateId'] = [];
      onChange(data, 'programStateId', []);
      return;
    }

    const value = map(items as DropdownItem[], (item: DropdownItem) => {
      return item.value;
    });
    data['programStateId'] = value;
    onChange(data, 'programStateId', value);
  };

  return (
    <div className="form__group">
      <label htmlFor="programStateId" className="form__label">
        Program State
      </label>
      <ReactSelect
        id="programStateId"
        className={`react-select-container t-programstate ${
          valid ? 'react-select__valid' : 'react-select__invalid'
        }`}
        classNamePrefix="react-select"
        fluid
        isMulti={true}
        isDisabled={disabled}
        name="programStateId"
        onBlur={onBlur && onBlur()}
        onChange={handleSelection}
        options={options}
        search
        valid={valid}
        value={selectOrgs()}
        placeholder=""
      />
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
