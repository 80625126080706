import { useValidation } from 'de-formed-validations';
import { QuestionGroup, Question } from 'types';
import { allQuestionsAnswered } from 'util/formHelpers';
import { filter, map, uniq } from 'lodash';

type ValidationState = {
  elements: QuestionGroup;
  needsAssessment: QuestionGroup[];
  careerInfo: QuestionGroup[];
  quality: QuestionGroup[];
  instructors: QuestionGroup[];
  partnerships: QuestionGroup[];
  workBasedLearning: QuestionGroup[];
  equity: QuestionGroup[];
};

export const FourYearPlanValidations = () => {
  return useValidation<ValidationState>({
    elements: [
      {
        errorMessage:
          'Must select 2 different elements from regional needs assessment.',
        validation: (data: QuestionGroup) => {
          const vals = filter(data.questions, (question: Question) => {
            return question.dataType === 'dropdown';
          });
          const values = map(vals, (question: Question) => {
            return question.questionValue.value.toString();
          });
          if (vals.length > 1) {
            const checkUnique = uniq(values).length;
            return checkUnique > 1;
          }
          return true;
        },
      },
    ],
    needsAssessment: [
      {
        errorMessage:
          'Not all Needs Assessment questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    careerInfo: [
      {
        errorMessage:
          'Not all Career Info questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    quality: [
      {
        errorMessage:
          'Not all Quality questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    instructors: [
      {
        errorMessage:
          'Not all Instructors questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    partnerships: [
      {
        errorMessage:
          'Not all Partnerships questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    workBasedLearning: [
      {
        errorMessage:
          'Not all Work-based Learning questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
    equity: [
      {
        errorMessage:
          'Not all Equity questions are answered. Please check all before submitting for approval.',
        validation: (questions: QuestionGroup[], _) => {
          return allQuestionsAnswered(questions);
        },
      },
    ],
  });
};
