import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import SubmissionManagementDataCollection from 'components/DataCollection/SubmissionManagement';

export const DataCollectionSubmissionManagementPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <SubmissionManagementDataCollection />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
