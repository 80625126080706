import { ENVIRONMENT } from 'constants/application.config';
import { catchError, postWithAuthOptions } from 'services';

export const CREATE_GRANTS_ENDPOINT = 'api/ParticipationManagements/';

export const createGrants = async (fiscalYear: number) => {
  const url = `${ENVIRONMENT.API_URL}${CREATE_GRANTS_ENDPOINT}${fiscalYear}`;
  try {
    const response = await fetch(url, {
      ...postWithAuthOptions(),
    });
    //Check if the response is not OK(status code other than 2XX)
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    return response.json();
  } catch (error: any) {
    // Handle network or other errors
    return catchError('CreateGrants', error);
  }
};
