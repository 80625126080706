import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import {
  SET_PARTNERSHIPS,
  SET_PARTNERSHIPS_LOADING,
} from './partnerships.actions';

const initState = baseInitialState;

export const partnershipsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_PARTNERSHIPS:
      return actionUpdateRecords(state, action.payload);

    case SET_PARTNERSHIPS_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
