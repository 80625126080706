import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
  deleteWithAuthOptions,
} from 'services';
import { CTASupportDoc, CTACourse, CTAContracting } from 'types';

const BASE_URL = ['api', 'ctacontracting'];

export const readCTAContractingAvailableHosts = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'availableHosts', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Contracting Contracting',
        err,
        'readCTAContractingAvailableHosts'
      );
    });
};

// Overview Page Data
export const readCTAContractingHosts = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'hosts', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Contracting Contracting',
        err,
        'readCTAContractingContracting'
      );
    });
};

export const readCTAContractingHost = async (
  ctaId: number,
  ctaHostingId: number
): Promise<CTAContracting[]> => {
  return fetch(getFormattedUrl([...BASE_URL, 'host', ctaId, ctaHostingId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Contracting Host', err, 'readCTAContractingHost');
    });
};

export const updateCTAContractingHost = async (
  ctaId: number,
  ctaHostingId: number,
  ctaCourses: CTACourse[]
) => {
  return fetch(getFormattedUrl([...BASE_URL, 'host', ctaId, ctaHostingId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(ctaCourses),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contracting',
        err,
        'readCTAHostingContracting'
      );
    });
};

export const readCTASupportDoc = async (
  ctaId: number,
  ctaHostingId: number
) => {
  return fetch(
    getFormattedUrl([...BASE_URL, 'supportDoc', ctaId, ctaHostingId]),
    {
      ...getWithAuthOptions(),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Support Doc', err, 'readCTASupportDoc');
    });
};

export const updateCTASupportDoc = async (supportDoc: CTASupportDoc) => {
  return fetch(getFormattedUrl([...BASE_URL, 'supportDoc']), {
    ...postWithAuthOptions(),
    body: JSON.stringify(supportDoc),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Support Doc', err, 'updateCTASupportDoc');
    });
};

export const deleteCTAContractingHost = async (
  ctaId: number,
  ctaHostingId: number
) => {
  return fetch(getFormattedUrl([...BASE_URL, 'hosts', ctaId, ctaHostingId]), {
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Support Doc', err, 'updateCTASupportDoc');
    });
};
