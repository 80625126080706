import { VoucherAuthSignatureComponent } from './VoucherAuthSignature.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {};
};
//Fix the type issue on 'onChange' when compiling
const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(VoucherAuthSignatureComponent);
