import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import {
  SET_WORK_BASED_LEARNING,
  SET_WORK_BASED_LEARNING_LOADING,
} from './workbasedlearning.actions';

const initState = baseInitialState;

export const workBasedLearningReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SET_WORK_BASED_LEARNING:
      return actionUpdateRecords(state, action.payload);

    case SET_WORK_BASED_LEARNING_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
