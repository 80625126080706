import React, { useEffect, useState } from 'react';
import {
  AzureUploadParameters,
  QuestionGroup,
  emptyQuestionGroup,
} from 'types';
import { useParams } from 'react-router';
import { upsertInPlace } from 'util/arrayUtility';
import { toast } from 'react-toastify';
import { updateGrantFormQuestions } from 'services/grants/grantformquestions.services';
import { QuestionValidation } from 'validations/Question.validations';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ParentQuestionGroup from 'components/Common/Questions';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import StateStrategicGoals from './StateStrategicGoals';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';
import { Link } from 'react-router-dom';

type Props = {
  annualReflection: QuestionGroup[];
  isLoading: boolean;
  isLocked: boolean;
  loadAnnualReflection: Function;
  setCurrentNavAnchor: Function;
};

export const AnnualReflectionEditComponent: React.FC<Props> = (props) => {
  const {
    annualReflection,
    isLoading,
    isLocked,
    loadAnnualReflection,
    setCurrentNavAnchor,
  } = props;

  const { validate, validationErrors } = QuestionValidation();

  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  const [questionState, setQuestionState] = useState<QuestionGroup[]>([
    emptyQuestionGroup(),
  ]);

  const handleChange = (data: QuestionGroup) => {
    const updated = upsertInPlace<QuestionGroup>(
      questionState,
      data,
      'questionGroupId'
    );
    setQuestionState(updated);
  };

  const handleSave = async (): Promise<any> => {
    validate('allQuestionsAnswered', questionState);
    return saveQuestions();
  };

  const saveQuestions = async (): Promise<any> => {
    return updateGrantFormQuestions(questionState)
      .then(() =>
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        )
      )
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      });
  };

  useEffect(() => {
    setQuestionState(annualReflection);
  }, [annualReflection]);

  useEffect(() => {
    setCurrentNavAnchor('annual-reflection');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    loadAnnualReflection(grantFiscalYearId);
  }, [loadAnnualReflection, grantFiscalYearId]);

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|annual-reflection`,
  };

  return (
    <SecureWrap isLocked={isLocked} component="GrantsAnnualReflection">
      <Paper>
        <Loading
          isActive={isLoading}
          messageBefore="Loading Annual Reflection..."
        />
        <ScrollToTop />
        <Instruction title="State Strategic Goals">
          <p>
            Colorado’s CTE Strategic Plan identifies five strategic goals and
            two foundational elements to guide statewide efforts to strengthen
            educational programming. Please review these goals and, in writing
            your reflections, consider how your site’s work throughout the year
            has contributed to achieving them.
          </p>
          <br />
          <p>
            Access the <Link to="/cccs-reports">Reporting Dashboard</Link> and
            filter by 'Grants' to see a list of helpful reports. From there, you
            can set unique filter parameters to run your preferred report.
          </p>
        </Instruction>
        <StateStrategicGoals />
      </Paper>
      <Paper>
        <ParentQuestionGroup
          uploadParams={uploadParams}
          onChange={handleChange}
          questionGroup={questionState}
          title="Questions About Progress Toward Two and Four-Year Strategic Goals"
        />
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
          saveData={handleSave}
          saveText="Save Annual Reflection"
          validationErrors={validationErrors}
        />
      </Paper>
    </SecureWrap>
  );
};
