import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_BOTTOM_OFFSET,
  WINDOW_TOP_OFFSET,
} from 'constants/application.constants';
import { FlexRow, FlexGroup } from 'layouts';
import { useParams } from 'react-router';
import { QuestionGroup } from 'types';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import { QuestionValidation } from 'validations/Question.validations';

type TwoYearSummaryProps = {
  isFetching: boolean;
  setCurrentNavAnchor: Function;
  loadTwoYear: Function;
  twoYearQuestions: QuestionGroup[];
};

export const TwoYearSummaryComponent: React.FC<TwoYearSummaryProps> = (
  props
) => {
  const { loadTwoYear, setCurrentNavAnchor, twoYearQuestions } = props;
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const { isValid, validate } = QuestionValidation();

  useEffect(() => {
    loadTwoYear(grantFiscalYearId);
  }, [loadTwoYear, grantFiscalYearId]);

  useEffect(() => {
    validate('allQuestionsAnswered', twoYearQuestions);
  }, [twoYearQuestions, grantFiscalYearId]); // eslint-disable-line

  return (
    <Paper className="u-read-only">
      <SummaryScroll componentStep="two-year-plan" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('two-year-plan')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      <FlexRow>
        <FlexGroup>
          <p className="form__label">Two-Year Strategic Plan Questions</p>
          <p>
            Collection Status:{' '}
            {isValid ? (
              <span className="u-color-green">Complete</span>
            ) : (
              <span className="u-color-orange-dark">In Progress</span>
            )}
          </p>
        </FlexGroup>
      </FlexRow>
      <Waypoint
        onEnter={() => setCurrentNavAnchor('two-year-plan')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
