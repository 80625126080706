import { useValidation } from 'de-formed-validations';
import { SnapshotStudent } from 'types';
import { isNull } from 'util/objectUtility';

export const StudentFollowupValidations = () => {
  return useValidation<SnapshotStudent>({
    placement: [
      {
        errorMessage: 'Placement Status is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    contEducation: [
      {
        errorMessage: 'Continuing Education is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    militaryServiceId: [
      {
        errorMessage: 'Military Service is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    apprenticeship: [
      {
        errorMessage: 'Apprenticeship is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    serviceProgram: [
      {
        errorMessage: 'Service Program is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    certExamCompleted: [
      {
        errorMessage: 'Certification Exam Complete is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
    certExamPassed: [
      {
        errorMessage: 'Certification Exam Passed is required.',
        validation: (val: number | null, _) => !isNull(val),
      },
    ],
  });
};
