import { FiscalAgentManagementComponent } from './FiscalAgentManagement.component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(FiscalAgentManagementComponent);
