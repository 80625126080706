import { useValidation } from 'de-formed-validations';
import { Consortium, FiscalYear, FiscalAgent } from 'types';
import { isStringValid } from 'util/validation.utility';

const FiscalAgentValidation = () => {
  return useValidation<FiscalAgent>({
    fiscalAgentId: [
      {
        errorMessage: 'A Fiscal Agent is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
  });
};

export const ConsortiumValidations = () => {
  const fiscalAgent = FiscalAgentValidation();
  return useValidation<Consortium>({
    consortiumName: [
      {
        errorMessage: 'Consortium Name is required.',
        validation: (val: string, _) => {
          return isStringValid(val);
        },
      },
    ],
    comment: [
      {
        errorMessage: 'Comment is required.',
        validation: (val: string, state: Consortium) => {
          return isStringValid(val) || state.consortiumId <= 0;
        },
      },
    ],
    fiscalAgent: [
      {
        errorMessage: 'A Fiscal Agent is required.',
        validation: (val: FiscalAgent, _) => {
          return fiscalAgent.validateAll(val);
        },
      },
    ],
    fiscalYear: [
      {
        errorMessage: 'Fiscal Year is required.',
        validation: (val: FiscalYear, _) => {
          const output = !!val;
          return output;
        },
      },
    ],
  });
};
