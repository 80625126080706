import {
  UniversalContact,
  emptyUniversalContact,
} from 'types/universalcontact';

export interface GrantFiscalYear {
  consortiumId: number;
  contact: UniversalContact;
  financeReviewers: UniversalContact[];
  generalStatus: string;
  grantFiscalYearId: number;
  isConsortiumActive: boolean;
  name: string;
  needsFinanceReview?: boolean;
  needsReview: boolean;
  programLevelId: number;
  status: string;
  submitVoucherThree: boolean | null;
  substantiallySubmitted: Date | null;
  voucherTwoApproved: boolean;
  year: number;
}

export const emptyGrantFiscalYear = (): GrantFiscalYear => {
  return {
    consortiumId: -1,
    contact: emptyUniversalContact(),
    financeReviewers: [],
    generalStatus: '',
    grantFiscalYearId: -1,
    isConsortiumActive: false,
    name: '',
    needsReview: false,
    programLevelId: -1,
    status: 'Inactive',
    submitVoucherThree: null,
    substantiallySubmitted: new Date(),
    voucherTwoApproved: false,
    year: 0,
  };
};
