import { connect } from 'react-redux';
import { getLoggedinUser } from 'redux/user/selectors';
import { ReportsDashboardComponent } from './ReportsDashboard.component';
import { ReportItem } from 'types/reporting/ReportItem.types';
import ApplicationState from 'types/applicationstate.types';
import {
  getAdvancedFilteredRecords,
  getIsDataFetching,
} from 'redux/utility/_exports';
import { REPORTING_REPORTS_PATH } from 'redux/reporting/_paths';
import { loadReportList } from 'redux/reporting/reports';
import {
  getRecords_ReportingFilterState,
  getReportingFilters,
} from 'redux/filters/reportingfilter.selectors';
import { isApplicaionIdValid } from 'util/global';
import { ReportingFilterState } from 'types';

export const mapStateToProps = (state: ApplicationState) => {
  const loggedInUser = getLoggedinUser(state);
  const isLoggedin = isApplicaionIdValid(loggedInUser.universalContactId);
  const isFetching = getIsDataFetching(state, REPORTING_REPORTS_PATH);

  const filters = getReportingFilters(state);

  const filterState: ReportingFilterState = getRecords_ReportingFilterState(
    state
  );

  const reports = getAdvancedFilteredRecords<ReportItem>(
    state,
    REPORTING_REPORTS_PATH,
    filters
  );

  return {
    filters,
    isFetching,
    isLoggedin,
    reports,
    filterState,
  };
};

const dispatchProps = {
  loadReportList,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ReportsDashboardComponent);
