import React from 'react';
import Paper from 'components/Common/Paper';
import { FlexRow } from 'layouts';

type PosInfoSectionProps = {
  title: string;
  subTitle?: string;
  text: string | JSX.Element;
};

export const PosInfoSection: React.FC<PosInfoSectionProps> = (props) => {
  const { title, subTitle, text } = props;

  return (
    <Paper>
      <FlexRow>
        <h2>{title}</h2>
      </FlexRow>

      <FlexRow>
        <div className="form__group u-flex-grow-1">
          {subTitle && <p className="form__label">{subTitle}</p>}
          <div>{text}</div>
        </div>
      </FlexRow>
    </Paper>
  );
};
