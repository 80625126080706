import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import ParticipationDashboard from 'components/Grants/ParticipantManagement';

export const ParticipationDashboardPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <ParticipationDashboard {...props} />
    </BaseLayout>
  );
};
