import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readPermissions } from 'services/permissions/permission.services';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { UserPermission } from 'types';

const initialState = baseInitialState;

export const loadUserPermissions = createActionThunk(
  'loadUserPermissions',
  () => {
    return readPermissions();
  }
); // end

export default handleActions<BaseStateObject, UserPermission[]>(
  {
    ...baseLoadActionBuilder<UserPermission[]>(loadUserPermissions),
  },
  initialState
);
