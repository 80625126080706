import {
  Program,
  School,
  Institute,
  Department,
  emptyProgram,
  emptySchool,
} from 'types';
import { emptyDepartment } from 'constants/department.const';

export interface ProgramOfStudy {
  programOfStudyId: number;
  secondaryProgramId: number;
  postSecondaryProgramId: number;
  ownerContactId: number;
  approverContactId: number;
  programOfStudyStatusId: number;

  secondarySchoolId: number;
  postsecondarySchoolId: number;
  secondarySchool: School;
  postsecondarySchool: Institute;
  postsecondaryDepartment: Department;
  secondaryProgram: Program;
  postSecondaryProgram: Program;
  associateDegrees: string[];
  certificates: string[];
  postsecondaryCourses: string[];
  generalEducationCourses: string[];
  pathways?: ProgramOfStudyPathway[];
}

export const emptyProgramOfStudy = (): ProgramOfStudy => {
  return {
    programOfStudyId: -1,
    secondaryProgramId: -1,
    postSecondaryProgramId: -1,
    secondarySchoolId: -1,
    postsecondarySchoolId: -1,
    secondarySchool: emptySchool(),
    postsecondarySchool: emptySchool(),
    secondaryProgram: emptyProgram(),
    postSecondaryProgram: emptyProgram(),
    postsecondaryDepartment: emptyDepartment,
    associateDegrees: [],
    certificates: [],
    postsecondaryCourses: [],
    generalEducationCourses: [],
    pathways: undefined,
    ownerContactId: -1,
    approverContactId: -1,
    programOfStudyStatusId: 3,
  };
};

export interface ProgramOfStudyPathway {
  pathway: string;
  courseLevels: ProgramOfStudyCourseLevel[];
}

export interface ProgramOfStudyCourseLevel {
  courseLevel: number;
  courses: ProgramOfStudyPathwayCourse[];
}

export interface ProgramOfStudyPathwayCourse {
  course: string;
  advancedCreditOption: string;
}
