import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  baseSaveActionBuilder,
  actionResetFetching,
} from 'redux/utility/_exports';
import {
  readFerpaAgreement,
  updateFerpaAgreement,
} from 'services/datacollection/ferpaagreement.services';
import { FerpaAgreement } from 'types';

const initialState = baseInitialState;

export const loadFerpaAgreementData = createActionThunk(
  'loadFerpaAgreementData',
  () => {
    return readFerpaAgreement();
  }
);

export const saveFerpaAgreementData = createActionThunk(
  'saveFerpaAgreementData',
  (agreement: FerpaAgreement) => {
    return updateFerpaAgreement(agreement);
  }
);

export default handleActions<BaseStateObject, FerpaAgreement>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseSaveActionBuilder<FerpaAgreement>(saveFerpaAgreementData),
    ...baseLoadActionBuilder<FerpaAgreement>(loadFerpaAgreementData),
  },
  initialState
);
