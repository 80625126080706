import { connect } from 'react-redux';
import {
  CteSalaryInformationComponent,
  CteSalaryInformationProps,
} from './CteSalaryInformation.component';
import {
  loadInstructorSalary,
  saveInstructorSalary,
} from 'redux/cta/InstructionalCosts/ctainstructorsalary';
import { getFirstRecord } from 'redux/utility/_exports';
import { CTA_INSTRUCTOR_SALARY_PATH } from 'redux/cta/_paths';
import { emptyCtaInstructorSalary } from 'types/cta/InstructionalCosts/CtaInstructorSalary.types';

const mapStateToProps = (
  state: any,
  ownProps: Partial<CteSalaryInformationProps>
) => {
  const salaryInfo = getFirstRecord(
    state,
    CTA_INSTRUCTOR_SALARY_PATH,
    emptyCtaInstructorSalary()
  );
  return {
    salaryInfo,
  };
};

const dispatchProps = {
  loadInstructorSalary,
  saveInstructorSalary,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CteSalaryInformationComponent);
