import { connect } from 'react-redux';
import { getLoggedinUser } from 'redux/user/selectors';
import { FollowupUploadComponent } from './FollowupUpload.component';
import { isSuperAdmin } from 'util/permissionsUtility';

const mapStateToProps = (state: any, ownProps: any) => {
  const user = getLoggedinUser(state);
  const programLevels = isSuperAdmin(user) ? [1, 2, 5] : user.programLevelIds;
  return {
    programLevels,
  };
};

export default connect(
  mapStateToProps,
  null
)(FollowupUploadComponent);
