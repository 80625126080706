export interface CTADistrictRecord {
  ctaId: number;
  cdeId: number;
  schoolDistrictName: string;
  districtId: number;
  schoolId: number;
  dcts: boolean;
  year: number;
  contactId: number;
  contactName: string;
  contactPhoneNumber: string;
  statusId: number;
  status: string;
  reportedCosts: number;
  firstSubmitted: Date;
  needsReview: boolean;
  booksAndSuppliesTotalCost: number;
  dctsTotalCostnumber: number;
  supportAdminCostFilepath: string;
  districtSalaryInfoFilePath: string;
  districtScheduleInfoFilePath: string;
  districtMailingAddress: string;
  assuranceProgramRepresentsDescription: boolean;
  assuranceCostsMeetRequirements: boolean;
  assuranceAllProgramActive: boolean;
  assuranceInstructorsCredentialed: boolean;
  accuracyCheck: boolean;
  ctaStarted: boolean
}

export const emptyCTADistrictRecord = (): CTADistrictRecord => {
  return {
    ctaId: 0,
    cdeId: -1,
    schoolDistrictName: '',
    districtId: -1,
    schoolId: -1,
    dcts: false,
    year: 1970,
    contactId: -1,
    contactName: '',
    contactPhoneNumber: '',
    statusId: -1,
    status: '',
    reportedCosts: -1,
    firstSubmitted: new Date(Date.now()),
    needsReview: false,
    booksAndSuppliesTotalCost: 0,
    dctsTotalCostnumber: 0,
    supportAdminCostFilepath: '',
    districtSalaryInfoFilePath: '',
    districtScheduleInfoFilePath: '',
    districtMailingAddress: '',
    assuranceProgramRepresentsDescription: false,
    assuranceCostsMeetRequirements: false,
    assuranceAllProgramActive: false,
    assuranceInstructorsCredentialed: false,
    accuracyCheck: false,
    ctaStarted: false
  };
};
