import React from 'react';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import IconButton from 'components/Common/IconButton';
import { PROGRAM_OF_STUDY_STATUS_ID } from 'constants/programOfStudy.const';
import { every } from 'lodash';

type ApprovePOSProps = {
  statusId: number;
  isApprover: boolean;
  onApprove: Function;
};

export const ApprovePOS: React.FC<ApprovePOSProps> = (props) => {
  const { statusId, isApprover, onApprove } = props;

  return every([
    isApprover,
    statusId === PROGRAM_OF_STUDY_STATUS_ID.Pending,
  ]) ? (
    <span className="pos__approve">
      <IconButton
        name="checkMark"
        tooltip="Approve Program of Study"
        onClick={onApprove}
      />
    </span>
  ) : (
    <span className="pos__approve">{EMPTY_PLACEHOLDER}</span>
  );
};
