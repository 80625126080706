import { CTA_KEY, CTA_CONTRACTING_HOSTS_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTACourse } from 'types';

export const CTA_CONTRACTING_HOST_PATH = [CTA_KEY, CTA_CONTRACTING_HOSTS_KEY];

export const getIsDataFetching_CTAContractingHost = (state: any): boolean => {
  return getIsDataFetching(state, CTA_CONTRACTING_HOST_PATH);
};

export const getRecords_CTAContractingHost = (state: any): CTACourse[] => {
  return getRecords(state, CTA_CONTRACTING_HOST_PATH);
};
