import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Icon from 'components/Common/Icons';

export const CertificationOfExemptionDocumentComponent = () => {
  const printArea = useRef<HTMLDivElement>(null);
  return (
    <div className="printable-padding" ref={printArea}>
      <div className="u-flex">
        <span>
          <ReactToPrint
            trigger={() => {
              return (
                <div className="no-print u-pseudo-link">
                  <Icon
                    title="Print"
                    name="print"
                    className="history__print__icon"
                  />
                </div>
              );
            }}
            content={() => {
              const parea = printArea as React.MutableRefObject<
                HTMLInputElement
              >;
              return parea.current;
            }}
          />
        </span>
        <span className="u-flex-grow-1" />
      </div>
      <div
        className="printable u-flex-vertical"
        style={{ alignItems: 'center' }}
      >
        <p>
          <strong>Certification of Exemption From Single Audit</strong>
        </p>
      </div>
      <div className="printable u-flex-vertical">
        <p>
          The Grant Recipient Agreement entered into between the State of
          Colorado by the State Board for Community Colleges and Occupational
          Education ("State Board") and the Recipient will only be considered as
          complete upon receipt of the Recipient's latest completed, within the
          last two years, Single Audit Report.
        </p>
        <p>
          Any College or District that receives and spends $750,000 or more in
          total federal funding [not just Perkins - all federal funding] is
          required by 2 C.F.R. Part 200 to have a Single Audit.
        </p>
        <p>
          If the Recipient is not required to arrange for an annual financial
          and compliance audit in accordance with 2 C.F.R. Part 200 this
          certification form must be signed in the Grant Award Packet.
        </p>
        <p>
          As the individual who has fiscal signing authority for my
          district/institution, I hereby certify that our district/institution
          is not required to arrange for an annual financial and compliance
          audit in accordance with the 2 C.F.R. Part 200.
        </p>
      </div>
    </div>
  );
};
