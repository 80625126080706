import { connect } from 'react-redux';
import { UniversalContactFilterComponent } from './UniversalContactFilterComponent';
import {
  setUniversalContactFilterValue,
  resetUniversalContactFilterValue,
  clearUniversalContactFilters,
} from 'redux/filters/universalcontactfilter';
import { getRecords_UniversalContactFilterState } from 'redux/filters/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const filter = getRecords_UniversalContactFilterState(state); // filter object
  return { filter };
};

const dispatchToProps = {
  setUniversalContactFilterValue,
  resetUniversalContactFilterValue,
  clearUniversalContactFilters,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(UniversalContactFilterComponent);
