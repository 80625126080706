import React from 'react';
import { ProgramAward, CourseCreditProgram } from 'types';
import { Link, useParams } from 'react-router-dom';
import { map } from 'lodash';

type AwardLegendProps = {
  courseCredits: CourseCreditProgram;
};

export const AwardLegend: React.FC<AwardLegendProps> = (props) => {
  const { courseCredits } = props;
  const { programAwards } = courseCredits;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const awardLink = `/programs/awards/${programInstanceId}`;

  return (
    <div className="award-legend">
      <h2 className="award-legend__title">Legend</h2>
      <div className="award-legend__items">
        {map(programAwards, (award: ProgramAward, index: number) => {
          return (
            <div key={index} className="award-legend__item">
              <p className="award-legend__number">{index + 1}</p>
              <Link to={`${awardLink}/${award.programAwardId}`}>
                {award.certOrDegreeTitle}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
