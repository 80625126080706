import '@rmwc/list/collapsible-list.css';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { FlexRow, FlexGroup } from 'layouts';
import {
  AzureUploadParameters,
  UniversalContact,
  PathwayCourse,
  HandleChangeFunction,
} from 'types';
import { CollapsibleList } from '@rmwc/list';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import ContactSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/ContactSelectBox';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import InstituteSelectBox from 'components/Common/SelectBoxes/InstituteSelectBox';
import Paper from 'components/Common/Paper';
import Toggle from 'components/Common/FormElements/Toggle';

type ProgramsAdvancedCreditOptionsProps = {
  course: PathwayCourse;
  programLevel: number;
  onChange: HandleChangeFunction;
  validations: ValidationObject<PathwayCourse>;
};

export const ProgramsAdvancedCreditOptions: React.FC<
  ProgramsAdvancedCreditOptionsProps
> = (props) => {
  const { course, onChange, validations } = props;

  const { getFieldValid, getError, validate, validateAll } = validations;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleAzureModal = () => {
    setOpen(!isOpen);
  };

  const setContact = ({ universalContactId }: UniversalContact) => {
    onChange(
      {
        ...course,
        articulationContactId: universalContactId,
      },
      'universalContactId',
      universalContactId
    );
  };

  const uploadParams: AzureUploadParameters = {
    container: 'programs',
    folder: `${programInstanceId}|course-articulations|${course.courseId}`,
  };

  const handleFileUpload = (filePath: string) => {
    onChange(
      { articulationFilePath: filePath },
      'articulationFilePath',
      filePath
    );
  };

  const handleFileClose = () => {
    onChange({ articulationFilePath: '' }, 'articulationFilePath', '');
  };

  const handleIsConcurrentEnrollment = (data: any, key: any, value: any) => {
    onChange(data, key, value);
    const updatedState = {
      ...course,
      isConcurrentEnrollment: value,
    };
    if (!value) {
      validateAll(updatedState, ['concurrentCourse', 'concurrentSchoolId']);
    }
  };

  const handleIsApprovedForArticulationAgreement = (
    data: any,
    key: any,
    value: any
  ) => {
    onChange(data, key, value);
    const updatedState = {
      ...course,
      isApprovedForArticulationAgreement: value,
    };
    if (!value) {
      validateAll(updatedState, [
        'articulationContactId',
        'articulationSchoolId',
        'articulationFilePath',
      ]);
    }
  };

  return (
    <React.Fragment>
      <Paper>
        <FlexRow>
          <h2>Advanced Credit Options</h2>
        </FlexRow>
        <FlexRow>
          <Toggle
            name="isAdvancedCreditOption"
            noLabel={true}
            value={course.isAdvancedCreditOption}
            description="Students completing this course earn Advanced Credit (optional)"
            onChange={onChange}
          />
        </FlexRow>
        <CollapsibleList handle={<div />} open={course.isAdvancedCreditOption}>
          <FlexRow>
            <h3>Choose how this Advanced Credit Option is provided</h3>
          </FlexRow>
          <FlexRow>
            <Toggle
              name="isApprovedForArticulationAgreement"
              label="Articulation Agreement"
              value={course.isApprovedForArticulationAgreement}
              description="This Course is approved for Articulation Agreement"
              onChange={handleIsApprovedForArticulationAgreement}
            />
          </FlexRow>

          <FlexRow>
            <Toggle
              name="isConcurrentEnrollment"
              label="Concurrent or Dual Enrollment"
              value={course.isConcurrentEnrollment}
              description="This Course is offered as Concurrent Enrollment"
              onChange={handleIsConcurrentEnrollment}
            />
          </FlexRow>
        </CollapsibleList>
      </Paper>
      <CollapsibleList
        handle={<div />}
        open={course.isApprovedForArticulationAgreement}
      >
        <Paper>
          <Instruction title="Articulation Agreement">
            <p>
              Please include an Articulation Agreement for any course that
              includes this type of Advanced Credit.
            </p>
          </Instruction>
          <div className="articulation-component">
            <FlexRow>
              <FlexGroup
                onBlur={() => {
                  validate(
                    'articulationSchoolId',
                    course.articulationSchoolId,
                    course
                  );
                }}
              >
                <InstituteSelectBox
                  name="articulationSchoolId"
                  label="Select an Institution"
                  selection={course.articulationSchoolId}
                  onChange={onChange}
                />
                <div className="u-color-red">
                  {getError('articulationSchoolId') &&
                    getError('articulationSchoolId')}
                </div>
              </FlexGroup>
            </FlexRow>

            <FlexRow>
              <div
                className="form__group"
                onBlur={() => {
                  validate(
                    'articulationContactId',
                    course.articulationContactId,
                    course
                  );
                }}
              >
                <ContactSelectBox
                  selectedContact={course.articulationContactId}
                  onChange={setContact}
                />
                <div className="u-color-red">
                  {getError('articulationContactId') &&
                    getError('articulationContactId')}
                </div>
              </div>
            </FlexRow>

            <FlexRow>
              <FlexGroup>
                <label className="form__label">Articulation agreement</label>
                {course.articulationFilePath ? (
                  <AzureDownloader
                    onClose={handleFileClose}
                    uploadParams={uploadParams}
                    filepath={course.articulationFilePath}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      text="+ Upload an articulation agreement"
                      className="form__btn--add u-flex-self-start"
                      onClick={toggleAzureModal}
                    />
                    <div className="u-color-red">
                      {getError('articulationFilePath') &&
                        getError('articulationFilePath')}
                    </div>
                  </React.Fragment>
                )}
              </FlexGroup>
            </FlexRow>

            <AzureUploader
              onClose={toggleAzureModal}
              open={isOpen}
              uploadParams={uploadParams}
              onConfirm={handleFileUpload}
            />
          </div>
        </Paper>
      </CollapsibleList>

      <CollapsibleList handle={<div />} open={course.isConcurrentEnrollment}>
        <Paper>
          <Instruction title="Concurrent or Dual Enrollment">
            <p>
              Please include a Concurrent or Dual Enrollment Agreement for any
              course that Includes this type of Advanced Credit.
            </p>
          </Instruction>
          <FlexRow>
            <div
              className="form__group"
              onBlur={() => {
                validate(
                  'concurrentSchoolId',
                  course.concurrentSchoolId,
                  course
                );
              }}
            >
              <InstituteSelectBox
                name="concurrentSchoolId"
                label="Select an Institution"
                selection={course.concurrentSchoolId}
                onChange={onChange}
              />
              <div className="u-color-red">
                {getError('concurrentSchoolId') &&
                  getError('concurrentSchoolId')}
              </div>
            </div>
          </FlexRow>
          <FlexRow>
            <Input
              name="concurrentCourse"
              value={course.concurrentCourse}
              onChange={onChange}
              errorMessage={getError('concurrentCourse')}
              onBlur={() => {
                validate('concurrentCourse', course.concurrentCourse, course);
              }}
              valid={getFieldValid('concurrentCourse')}
            />
          </FlexRow>
        </Paper>
      </CollapsibleList>
    </React.Fragment>
  );
};
