import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getWithAuthOptions, catchError } from 'services';

const CIPCODE_ENDPOINT = 'api/cipcodes/';

/**
 * Makes a "GET" request to 'api/cipcodes/'
 * @returns an array of all cipcodes.
 */
export const readCIPCodes = async () => {
  const url = `${ENVIRONMENT.API_URL}${CIPCODE_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('cipcodes', err);
    });
}; // end fetchCIPCodes
