import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { GrantFiscalYear } from 'types';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionFetching,
  actionUpdateRecords,
  actionRecordFailure,
} from 'redux/utility/_exports';
import {
  readGrantFiscalYear,
  readSingleGrantFiscalYear,
} from 'services/grants/grantfiscalyear.services';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadGrantFiscalYear = createActionThunk(
  'loadGrantFiscalYear',
  () => {
    return readGrantFiscalYear();
  }
);

export const loadSingleGrantFiscalYear = createActionThunk(
  'loadSingleGrantFiscalYear',
  (grantFiscalYearId: number) => {
    return readSingleGrantFiscalYear(grantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, GrantFiscalYear[]>(
  {
    [loadSingleGrantFiscalYear.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadSingleGrantFiscalYear.SUCCEEDED]: (state, action) => {
      const records = upsert<GrantFiscalYear>(
        state.records,
        action.payload,
        'grantFiscalYearId'
      );
      return actionUpdateRecords(state, records);
    },
    [loadSingleGrantFiscalYear.FAILED]: (state) => {
      return actionRecordFailure(state);
    } /* End action */,
    [loadSingleGrantFiscalYear.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    ...baseLoadActionBuilder<GrantFiscalYear[]>(loadGrantFiscalYear),
  },
  initialState
);
