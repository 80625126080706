import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, emptyFiscalYear, ConsortiumFilterState } from 'types';

const initialState: ConsortiumFilterState = {
  fiscalYear: emptyFiscalYear(),
  status: undefined,
  standalone: false, // Secret unshown filter
};

export const setConsortiaFilterValue = createAction('setConsortiaFilterValue');
export const resetConsortiaFilterValue = createAction(
  'resetConsortiaFilterValue'
);
export const clearConsortiaFilters = createAction('clearConsortiaFilters');

export default handleActions<
  ConsortiumFilterState,
  KeyValueType<number> | string
>(
  {
    setConsortiaFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetConsortiaFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearConsortiaFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
