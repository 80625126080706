import { connect } from 'react-redux';
import { DataCollectionLayout } from './DataCollection.layout';

export const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(DataCollectionLayout);
