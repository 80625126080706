import { VoucherSpendingCategory } from 'types';
import { Signature } from 'types/common';

// let voucher: Voucher;
// voucher.basicGrant             --> basicGrant amount
// voucher.reserveGrant           --> reserveGrant amount
// voucher.categories             --> voucher spending categories
// voucher.reimbursementRequested --> reimbursementRequestedToDate
// voucher.indirectRequested      --> requested indirect (always the total)

export interface Voucher {
  voucherId: number;
  voucherName: string;
  voucherTypeId: number;
  submissionDate: Date | null;
  dueDate: Date;
  reimbursementRequested: number;
  certifyAllExpenditures: boolean;
  granteeIndemnify: boolean;
  granteeMetCriteria: boolean;
  isOpen: boolean;
  categories: VoucherSpendingCategory[];
  authSignatures: Signature[];
  generalLedgerFilePath: VoucherFile[];
  personnelPayrollFilePath: VoucherFile[];
  additionalDocsFilePath: VoucherFile[];
  indirectRate: number;
  indirectRequested: number;
  adminCostLimit: number;
  cannotExceed: number;
  basicGrant: number;
  reserveGrant: number;
  voucherClose: Date;
}

export interface VoucherFile {
  filePath: string;
  grantVoucherFilePathId: number;
}

export const emptyVoucher = (): Voucher => {
  return {
    voucherId: -1,
    voucherName: '',
    voucherTypeId: -1,
    submissionDate: new Date(),
    dueDate: new Date(),
    reimbursementRequested: 0,
    certifyAllExpenditures: false,
    granteeIndemnify: false,
    granteeMetCriteria: false,
    isOpen: false,
    categories: [],
    authSignatures: [],
    generalLedgerFilePath: [],
    personnelPayrollFilePath: [],
    additionalDocsFilePath: [],
    indirectRate: 1,
    indirectRequested: 0,
    adminCostLimit: 0,
    cannotExceed: 0,
    basicGrant: 0,
    reserveGrant: 0,
    voucherClose: new Date(),
  };
};
