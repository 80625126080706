import React from 'react';
import { EditCTAContract } from 'components/CTA/DistrictReporting/Contracting/EditCTAContract.component';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getCTADistrictRecord } from 'redux/cta/_selectors';
import { safeGet } from 'util/objectUtility';
import BaseLayout from 'layouts/BaseLayout';
import Paper from 'components/Common/Paper';

export const CTAContractPage: React.FC = () => {
  // -- dependencies --
  const { ctaId: cId }: any = useParams();
  const ctaId = Number(cId);
  const ctaRecord = useSelector(getCTADistrictRecord(ctaId));

  // -- display logic --
  const get = safeGet(ctaRecord);

  return (
    <BaseLayout>
      <section className="program__body">
        <Paper>
          <div className="program__header u-flex">
            <div className="u-flex-grow-1">
              <h2 className="program__title">
                CTA Contracting Costs: {get('schoolDistrictName')}
              </h2>
              <p className="program__subtitle">
                Costs Incurred 7/1/{get('year') - 1} - 6/30/{get('year')}
              </p>
            </div>
          </div>
        </Paper>
        <div className="program__content">
          <EditCTAContract />
        </div>
      </section>
    </BaseLayout>
  );
};
