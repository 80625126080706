import React, { useEffect } from 'react';
import { map, find } from 'lodash';
import { defaultTo } from 'ramda';
import { upsertInPlace } from 'util/arrayUtility';
import { safeGet, mergeObjects } from 'util/objectUtility';
import { FlexRow, FlexGroup } from 'layouts';
import {
  CtaInstructorCourse,
  CtaInstructorTerm,
  emptyCtaInstructorTerm,
  CTASchool,
} from 'types';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import Button from 'components/Common/FormElements/Button';
import CtaSchoolSelectBox from 'components/Common/SelectBoxes/CtaSchoolSelectBox';
import Label from 'components/Common/FormElements/Label';
import Input from 'components/Common/FormElements/Input';
import SquareBoxInput from 'components/Common/FormElements/SquareBoxInput';

interface NonCteCtaCourseComponentProps {
  course: CtaInstructorCourse;
  onChange: Function;
  onDelete: Function;
  schools: CTASchool[];
  submitFailed: boolean;
}
export const NonCteCtaCourseComponent: React.FC<NonCteCtaCourseComponentProps> = ({
  schools,
  course,
  onDelete,
  onChange,
  submitFailed,
}) => {
  // -- dependencies --
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateAll,
    validateIfTrue,
  } = CTACourseValidation();

  // -- component logic --
  const get = safeGet(course);
  const addNewCourse = ({ ctaSchoolId }: Partial<CTASchool>) => {
    const school = find(schools, { ctaSchoolId });
    if (school) {
      const terms = map(new Array(school.terms), (_, idx: number) => {
        return {
          ...emptyCtaInstructorTerm(),
          ctaCourseTermInstructionalId: (idx + 1) * -1,
          ctaCourseTermId: (idx + 1) * -1,
        };
      });
      validateIfTrue('ctaSchoolId', ctaSchoolId, {
        ...course,
        isCte: false,
        isInstructional: true,
      } as any);
      onChange(mergeObjects(course, { ...school, terms }));
    }
  };
  const handleNameChange = ({ coCourseName }: Partial<CtaInstructorCourse>) => {
    validateIfTrue('coCourseName', coCourseName, {
      ...course,
      isCte: false,
      isInstructional: true,
    } as any);
    onChange(mergeObjects(course, { coCourseName }));
  };
  const handleTermChange = (term: CtaInstructorTerm) => (
    data: Partial<CtaInstructorTerm>
  ) => {
    const updated = mergeObjects(term, data);
    const terms = upsertInPlace(get('terms'), updated, 'ctaCourseTermId');
    validateIfTrue('terms', terms, {
      ...course,
      isCte: false,
      isInstructional: true,
    } as any);
    onChange(mergeObjects(course, { terms }));
  };

  // -- lifecycle --
  useEffect(() => {
    submitFailed &&
      validateAll({
        ...course,
        isCte: false,
        isInstructional: true,
      } as any);
  }, [submitFailed, course]); // eslint-disable-line

  // -- display logic --

  return (
    <React.Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          width: '100%',
          gridColumnGap: '1.8rem',
        }}
      >
        <FlexGroup>
          <label className="form__label ">&nbsp;</label>
          <FlexRow style={{ minWidth: '65rem' }}>
            <FlexGroup
              onBlur={() => validate('ctaSchoolId', get('ctaSchoolId'))}
            >
              <CtaSchoolSelectBox
                onChange={addNewCourse}
                selection={get('ctaSchoolId') ? get('ctaSchoolId') : undefined}
              />
              {getError('ctaSchoolId') && (
                <p className="u-color-red">{getError('ctaSchoolId')}</p>
              )}
            </FlexGroup>
            <Input
              errorMessage={getError('coCourseName')}
              label="Course Name"
              name="coCourseName"
              onBlur={() => validate('coCourseName', get('coCourseName'))}
              onChange={handleNameChange}
              value={get('coCourseName')}
              valid={getFieldValid('coCourseName')}
            />
          </FlexRow>
        </FlexGroup>
        <div>
          <div className="u-flex" style={{ flexWrap: 'wrap' }}>
            {defaultTo([], get('terms')).map(
              (term: CtaInstructorTerm, index: number) => {
                const getTerm = safeGet(term);
                return (
                  <div key={`cte-cta-course__${index}`}>
                    <div
                      className="u-flex-vertical"
                      style={{ marginRight: '1rem' }}
                    >
                      <fieldset>
                        <legend
                          style={{ fontSize: '1.6rem' }}
                          className="form__label"
                        >
                          Term {index + 1}
                        </legend>
                        <div className="u-flex-vertical">
                          <Label name="credits" htmlFor={`credits__${index}`} />
                          <SquareBoxInput
                            className="course-credits__input"
                            id={`credits__${index}`}
                            name="credits"
                            onChange={handleTermChange(term)}
                            value={getTerm('credits')}
                            valid={isValid}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                );
              }
            )}
            {get('terms').length === 0 && (
              <div style={{ height: '11.6rem', marginLeft: '1.8rem' }}>
                &nbsp;
              </div>
            )}
          </div>
          <Button
            className="form__btn--add"
            text="Delete Course"
            onClick={() => onDelete(get('ctaCourseId'))}
          />
          <FlexRow>
            {getError('terms') && (
              <p className="u-error">{getError('terms')}</p>
            )}
          </FlexRow>
        </div>
      </div>
    </React.Fragment>
  );
};
