import { connect } from 'react-redux';
import { loadPermissionComponents } from 'redux/permissions/permissioncomponents';
import { getRecords_PermissionComponent } from 'redux/permissions/permissioncomponents.selectors';
import {
  loadPermissionRoleComponents,
  savePermissionRoleComponent,
} from 'redux/permissions/permissionrolecomponent';
import { getRecords_PermissionRoleComponent } from 'redux/permissions/permissionrolecomponent.selector';
import { loadPermissionTypes } from 'redux/permissions/permissiontypes';
import { PermissionComponent, PermissionRoleComponent } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { EditRoleComponentsComponent } from './EditRoleComponents.component';

export const mapStateToProps = (state: ApplicationState) => {
  const roleComponents: PermissionRoleComponent[] = getRecords_PermissionRoleComponent(
    state
  );
  const components: PermissionComponent[] = getRecords_PermissionComponent(
    state
  );
  return {
    roleComponents,
    components,
  };
};

const dispatchProps = {
  loadPermissionRoleComponents,
  loadPermissionComponents,
  loadPermissionTypes,
  savePermissionRoleComponent,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(EditRoleComponentsComponent);
