import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import CheckboxGroupComponent from 'components/Common/FormElements/CheckboxGroup';
import CreateWorkflowAction from 'components/Common/GeneralWorkflow/WorkflowAction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useEffect, useState } from 'react';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import WorkflowCard from 'components/Common/GeneralWorkflow/WorkflowCard';
import Instruction from 'components/Common/Instruction';
import { CTAAssuranceValidations } from 'validations/CTA/CTAAssurance.validations';
import {
  CTAContracting,
  CTADistrictRecord,
  CTAHosting,
  CTAReviewOptimizeOverview,
  SignatureWithAssertion,
  UniversalContact,
  Workflow,
  WorkflowStep,
  WorkflowSubmission,
} from 'types';
import { CTAOptimizedSubmission } from 'types/cta/CTAOptimizedSubmission.types';
import { CTAWorkflowSubmissionValidations } from 'validations/CTA/CTAWorkflowSubmission.validations';
import { CTA_WORKFLOW } from 'enums/ctaWorkflowStatus.enum';
import { FlexGroup, FlexRow } from 'layouts';
import { SignatureWithAssertionValidations } from 'validations/AwardPacket/SignatureWithAssertions.validations';
import { catchError } from 'services';
import { contactHasRoles } from 'util/permissionsUtility';
import {
  createCtaOptimizedSubmission,
  readCtaOptimizedSubmission,
} from 'services/cta/ctaApprovals.services';
import { every, get, includes, isEmpty, map, some } from 'lodash';
import { formatMoney, insertDollarSign } from 'util/moneyHelpers';
import { isApplicaionIdValid, doNothing } from 'util/global';
import { prop, compose, concatFront } from 'util/objectUtility';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type WorkflowProps = {
  locked: boolean;
  reviewOptimize: CTAReviewOptimizeOverview;
  loadCTAReviewOptimize: Function;
  workflow: Workflow;
  isFetching: boolean;
  loggedInUser: UniversalContact;
  loadWorkFlow: Function;
  submitCtaForUpdate: Function;
  loadCTASignatures: Function;
  loadCTAContracting: Function;
  loadCTAHosting: Function;
  signatures: SignatureWithAssertion[];
  ctaRecord: CTADistrictRecord;
  ctaContracting: CTAContracting[];
  ctaHosting: CTAHosting[];
};

export const CTAWorkflowComponent: React.FC<WorkflowProps> = (props) => {
  const {
    locked,
    reviewOptimize,
    loadCTAReviewOptimize,
    loadCTAContracting,
    loadCTAHosting,
    workflow,
    loadWorkFlow,
    submitCtaForUpdate,
    loggedInUser,
    loadCTASignatures,
    signatures,
    ctaRecord,
    ctaContracting,
    ctaHosting,
  } = props;
  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);

  // --[ local state ]---------------------------------------------------------
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    submissionState,
    setSubmissionState,
  ] = useState<CTAOptimizedSubmission>({
    optimized: undefined,
  });
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  const { validate, validationErrors } = SignatureWithAssertionValidations();
  const {
    validate: assuranceValidate,
    validationErrors: assuranceErrors,
  } = CTAAssuranceValidations();
  const {
    validate: validateWorkflow,
    validationErrors: workflowErrors,
  } = CTAWorkflowSubmissionValidations();

  // --[ component logic ]-----------------------------------------------------

  const updateState = (item: Partial<CTAOptimizedSubmission>) => {
    if (item.optimized) {
      setSubmissionState({
        optimized: true,
      });
    } else if (!item.optimized) {
      setSubmissionState({
        optimized: false,
      });
    }
  };

  const canEditAmount = () => {
    return includes(
      [
        CTA_WORKFLOW.NEW,
        CTA_WORKFLOW.INFORMATION_NEEDED,
        CTA_WORKFLOW.OPEN,
        CTA_WORKFLOW.OPEN_FOR_REVISION,
      ],
      workflow.workflowStatusId
    );
  };

  // TODO: missing requirement logic for this
  const canEditWorkflow = (step: WorkflowStep) => {
    return some([
      contactHasRoles(loggedInUser, ['admin', 'ctaoverride']),
      includes(
        map(step.approvers, (item) => {
          return item.universalContactId;
        }),
        loggedInUser.universalContactId
      ),
    ]);
  };

  const onSubmitWorkflow = (id: number, item: WorkflowSubmission) => {
    setIsLoading(true);
    createCtaOptimizedSubmission(ctaId, submissionState)
      .then(() => {
        Promise.resolve(submitCtaForUpdate(id, item)).catch((err: any) => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
          catchError('createCtaOptimizedSubmission', err);
        });
        readCtaOptimizedSubmission(ctaId).then(
          (result: CTAOptimizedSubmission) => {
            setSubmissionState(result);
          }
        );
      })
      .catch((err: any) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      loadCTAReviewOptimize(ctaId).catch(doNothing);
      loadCTASignatures(ctaId).catch(doNothing);
      loadCTAContracting(ctaId).catch(doNothing);
      loadCTAHosting(ctaId).catch(doNothing);
    }
  }, [
    wasOpened,
    loadCTAReviewOptimize,
    ctaId,
    loadCTASignatures,
    loadCTAHosting,
    loadCTAContracting,
  ]);

  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  useEffect(() => {
    if (isApplicaionIdValid(ctaId)) {
      readCtaOptimizedSubmission(ctaId).then(
        (result: CTAOptimizedSubmission) => {
          setSubmissionState(result);
        }
      );
    }
  }, [ctaId, readCtaOptimizedSubmission]); // eslint-disable-line

  useEffect(() => {
    loadWorkFlow('cta', ctaId);
  }, [ctaId, loadWorkFlow]);

  useEffect(() => {
    map(signatures, (item) => {
      const valid = validate('canSubmit', item);
      return valid;
    });
    assuranceValidate('canSubmit', ctaRecord);
    validateWorkflow('selectedAmount', submissionState);
  }, [signatures, ctaRecord, ctaContracting, ctaHosting, submissionState]); // eslint-disable-line

  // makeOptimizedLabel :: reviewOptimize -> string
  const makeOptimizedLabel = compose(
    concatFront('Optimized '),
    insertDollarSign,
    formatMoney,
    prop('optimizedReimbursementEstimate')
  );
  // makeNonOptimizedLabel :: reviewOptimize -> string
  const makeNonOptimizedLabel = compose(
    concatFront('Non-Optimized '),
    insertDollarSign,
    formatMoney,
    prop('nonOptimizedReimbursementEstimate')
  );

  /* 
    for some reason the validator only show a single error at a time 
    doing this to save time.
  */
  const getHostingErrors = () => {
    if (isEmpty(ctaHosting)) {
      return [];
    }
    const hasData = some(
      map(ctaHosting, (item) => {
        return every([item.students > 0, item.revenue > 0]);
      })
    );
    return hasData
      ? []
      : ['Every hosting course must have data in at least one term.'];
  };

  const getContractingErrors = () => {
    if (isEmpty(ctaContracting)) {
      return [];
    }
    const hasData = some(
      map(ctaContracting, (item) => {
        return every([item.fte > 0, item.count > 0, item.cost]);
      })
    );
    return hasData
      ? []
      : ['Every contracting course must have data in at least one term.'];
  };

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-workflow"
        locked={locked}
        header="Submission / Approval"
        onChange={updateOpenedState}
      >
        <Paper>
          <Loading isActive={isLoading} />
          <Instruction title="" hideTitle>
            <p>
              In order to fully submit your CTA report you must complete the
              following <span className="u-color-red">three</span> steps.{' '}
              <span className="u-color-red">First</span>, select the higher of
              the two Submission Amounts, if they are the same, select the
              optimized option for the best outcome.{' '}
              <span className="u-color-red">Next</span>, select submit from the
              select an option dropdown menu, your report will not submit unless
              you select an option from this dropdown. If you have additional
              information to help with the review of your submission you may add
              this to the comment section, but adding a comment is not required
              for submission. <span className="u-color-red">Finally</span>, hit
              the submit button to save the submission. You can confirm that the
              report was submitted by checking who the report is assigned to in
              the Workflow History, if it is assigned to the CTA Manager, your
              report has been fully submitted.
            </p>
          </Instruction>
          <FlexRow>
            <CheckboxGroupComponent
              disabled={!canEditAmount()}
              border={true}
              groupTitle="Select Submission Amount"
              onChange={updateState}
              options={{
                nonOptimized: {
                  value: get(submissionState, 'optimized') === false,
                  label: makeNonOptimizedLabel(reviewOptimize),
                },
                optimized: {
                  value: get(submissionState, 'optimized') === true,
                  label: makeOptimizedLabel(reviewOptimize),
                },
              }}
            />
          </FlexRow>
          <FlexRow>
            {!locked && (
              <FlexGroup>
                {map(workflow.workflowSteps, (item, index) => {
                  if (!item.isCurrent) {
                    return null;
                  }
                  return canEditWorkflow(item) ? (
                    <CreateWorkflowAction
                      key={index}
                      applicationId={ctaId}
                      submitApplicationUpdate={onSubmitWorkflow}
                      workflowStep={item}
                      validationErrors={[
                        ...validationErrors,
                        ...assuranceErrors,
                        ...workflowErrors,
                        ...getHostingErrors(),
                        ...getContractingErrors(),
                      ]}
                    />
                  ) : (
                    <WorkflowCard
                      key={index}
                      flat={true}
                      isFirst={true}
                      isOdd={false}
                      workflowItem={item}
                      isSummary={false}
                    />
                  );
                })}
              </FlexGroup>
            )}
          </FlexRow>
        </Paper>
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
