import {
  loadProgramCourseCredits,
  saveProgramCourseCreditCache,
  resetProgramCourseCreditsCache,
} from 'redux/caches/coursecredit';
import { handleProgramCourseCreditCache } from 'redux/utility/_exports';

export const courseCreditsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { getState, dispatch } = store;

  next(action);

  switch (action.type) {
    case loadProgramCourseCredits.SUCCEEDED: {
      handleProgramCourseCreditCache(dispatch, getState(), action.payload);
      break;
    }
    case saveProgramCourseCreditCache.SUCCEEDED: {
      const { programInstanceId } = action.payload;
      dispatch(resetProgramCourseCreditsCache(programInstanceId));
      break;
    }
  }
};
