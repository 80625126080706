import { DATA_COLLECTION_KEY, DATA_COLLECTION_STUDENT_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { SnapshotStudent } from 'types';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';

export const DATA_COLLECTION_STUDENT_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_STUDENT_KEY,
];

export const getIsDataFetching_SnapshotStudent = (
  state: ApplicationState
): boolean => {
  return getIsDataFetching(state, DATA_COLLECTION_STUDENT_PATH);
}; // end

export const getRecords_SnapshotStudent = (
  state: ApplicationState
): SnapshotStudent[] => {
  return getRecords(state, DATA_COLLECTION_STUDENT_PATH);
};
