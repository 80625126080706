import { combineReducers } from 'redux';
import {
  UNIVERSAL_CONTACTS_CONTACTS,
  UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY,
} from 'redux/keys';
import contacts from './universalcontacts';
import contacttypes from './contacttypes';

export default combineReducers({
  [UNIVERSAL_CONTACTS_CONTACTS]: contacts,
  [UNIVERSAL_CONTACTS_CONTACTS_TYPE_KEY]: contacttypes,
});
