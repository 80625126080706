import { WorkflowSteps } from './WorkflowSteps.component';
import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { getWorkflowTrayClosed } from 'redux/application/selectors';
import { setWorkflowTrayClosed, setCurrentNavAnchor } from 'redux/application';
import { getGeneralWorkflow } from 'redux/generalworkflow/workflow.selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = getActiveProgramId(state);
  const workflowTrayClosed = getWorkflowTrayClosed(state);
  const workflow = getGeneralWorkflow('programs', state);

  return {
    programInstanceId,
    workflowTrayClosed,
    workflow,
  };
};

const dispatchProps = {
  setCurrentNavAnchor,
  setWorkflowTrayClosed,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(WorkflowSteps);
