import React from 'react';
import { FlexRow } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { formatSSN } from 'util/universalcontact.utility';
import { getNonNullValue } from 'util/formHelpers';
import { SnapshotStudent } from 'types';
import { every } from 'lodash';
import Input from 'components/Common/FormElements/Input';

export const PersonalInformationPS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { ssnLocked, alternateIdLocked, student, onChange, v } = props;
  const notNew = student.studentId !== '' && !student.newRecord;
  const handleSsnChange = (
    data: Partial<SnapshotStudent>,
    name: 'ssn',
    val: string
  ) => {
    const ssn = formatSSN(String(val));
    onChange({ ssn }, name, val);
  };

  return student ? (
    <React.Fragment>
      <FlexRow>
        <Input
          disabled={every([ssnLocked, notNew])}
          errorMessage={v.getError('ssn')}
          label="Social Security Number (Optional)"
          name="ssn"
          onBlur={() => v.validate('ssn', student.ssn, student)}
          onChange={handleSsnChange}
          valid={v.getFieldValid('ssn')}
          value={formatSSN(student.ssn)}
        />
        <Input
          disabled={every([alternateIdLocked, notNew])}
          errorMessage={v.getError('alternateId')}
          label="Student ID Number"
          name="alternateId"
          onBlur={() => v.validate('alternateId', student.alternateId, student)}
          onChange={onChange}
          valid={v.getFieldValid('alternateId')}
          value={getNonNullValue(student.alternateId)}
        />
      </FlexRow>
    </React.Fragment>
  ) : null;
};
