import { ENVIRONMENT } from 'constants/application.config';
import {
  getWithAuthOptions,
  toJSON,
  postWithAuthOptions,
  catchError,
} from 'services';
import { QuestionGroup } from 'types';

const ASSURANCE_ENDPOINT = 'api/ProgramAssuranceQuestions/';
const ASSURANCE_QUESTIONS_ENDPOINT = `${ASSURANCE_ENDPOINT}programAssuranceValues/`;
const ASSURANCE_WORKFLOW_ENDPOINT = `${ASSURANCE_ENDPOINT}WorkflowAssuranceValues/`;

export const readWorkflowAssuranceQuestions = async (
  programInstanceId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ASSURANCE_WORKFLOW_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAssuranceQuestions', err);
    });
}; // end

export const readProgramAssuranceQuestions = async (
  programInstanceId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ASSURANCE_QUESTIONS_ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAssuranceQuestions', err);
    });
}; // end

export const updateProgramAssurance = async (
  questionGroups: QuestionGroup[],
  programInstanceId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${ASSURANCE_QUESTIONS_ENDPOINT}${programInstanceId}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(questionGroups),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('ProgramAssuranceQuestions', err);
    });
}; // end
