export const NEEDS_ASSESSMENT = '[NEEDS_ASSESSMENT]';

export const SET_NEEDS_ASSESSMENT = `${NEEDS_ASSESSMENT} SET`;
export const SET_NEEDS_ASSESSMENT_LOADING = `${NEEDS_ASSESSMENT} LOADING`;

export const setNeedsAssessment = (payload: any) => {
  return {
    type: SET_NEEDS_ASSESSMENT,
    payload,
  };
};

export const setIsLoading_NeedsAssessment = (payload: boolean) => {
  return {
    type: SET_NEEDS_ASSESSMENT_LOADING,
    payload,
  };
};
