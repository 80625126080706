import React, { useRef, useEffect, useState } from 'react';
import { every } from 'lodash';
import { randomString } from 'util/global';
import { isDisabled } from 'util/authorization';
import Label from 'components/Common/FormElements/Label';

type InputProps = {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  focusOnLoad?: boolean;
  id?: string;
  label?: string;
  min?: number;
  max?: number;
  name: string;
  noLabel?: boolean;
  onBlur?: Function;
  onChange?: Function;
  onFocus?: Function;
  placeholder?: string;
  step?: number;
  trim?: boolean;
  type?: string;
  valid?: boolean;
  value: string | number;
  tooltip?: JSX.Element;
};

export const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    disabled,
    errorMessage,
    id,
    label,
    min,
    max,
    name,
    noLabel = false,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    step,
    trim,
    type,
    valid = true,
    value,
    tooltip,
  } = props;

  const [computedDisable, setComputedDisable] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (computedDisable) return;
    let value;
    let data: {
      [key: string]: unknown;
    } = {};

    if (type === 'number') {
      value = Number(event.target.value);
    } else {
      value = event.target.value;
    }

    if (trim && typeof value === 'string') {
      value = value.trim();
    }

    data[name] = value;
    onChange && onChange(data, name, value);
  };

  const getClassName = () => {
    const bob = className ? className : 'form__input';
    return valid ? bob : `${bob} form__input--error`;
  };

  const randomId = randomString();
  const inputRef = useRef(null);

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, inputRef);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  return (
    <div className="form__group">
      {!noLabel && (
        <div className="u-flex">
          <Label name={name} label={label} htmlFor={id ? id : randomId} />
          {tooltip && tooltip}
        </div>
      )}
      <input
        min={min}
        max={max}
        className={getClassName()}
        disabled={computedDisable}
        id={id ? id : randomId}
        ref={inputRef}
        name={name}
        onBlur={() => onBlur && onBlur()}
        onChange={handleChange}
        onFocusCapture={() => onFocus && onFocus()}
        placeholder={placeholder}
        type={type}
        value={value}
        step={step ? step : 0.01}
        onFocus={(event: any) => {
          if (every([type === 'number', value === 0])) event.target.select();
        }}
      />
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </div>
  );
};
