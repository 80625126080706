import { MemberIntentComponent } from './MemberIntent.component';
import { connect } from 'react-redux';
import {
  getIsDataFetching_ITPConsortia,
  getRecords_ITPConsortia,
} from 'redux/grants/_selectors';
import { loadAllConsortia } from 'redux/grants/itpconsortium';
import { getCurrentFiscalYear } from 'redux/common/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any) => {
  const isLoading = getIsDataFetching_ITPConsortia(state);
  const consoria = getRecords_ITPConsortia(state);
  const fiscalYear = getCurrentFiscalYear(state).year;
  const loggedInUser = getLoggedinUser(state);

  return {
    isLoading,
    consoria,
    fiscalYear,
    loggedInUser,
  };
};

const dispatchProps = {
  loadAllConsortia,
};

export default connect(mapStateToProps, dispatchProps)(MemberIntentComponent);
