import { connect } from 'react-redux';
import {
  getRecords_ProgramOfStudy,
  getIsDataFetching_ProgramOfStudy,
  getProgram,
  getIsProgramReadOnly,
} from 'redux/programs/_selectors';
import {
  loadProgramOfStudyData,
  removeProgramOfStudy,
  saveProgramOfStudyApproval,
  saveProgramOfStudyDenial,
} from 'redux/programs/programofstudy';
import { ProgramOfStudyEditComponent } from 'components/Program/ProgramsOfStudy/ProgramsOfStudyEdit.component';
import { ProgramOfStudySummaryComponent } from 'components/Program/ProgramsOfStudy/ProgramsOfStudySummary.component';
import { getLoggedinUser } from 'redux/user/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import { getNextStep } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programsOfStudy = getRecords_ProgramOfStudy(state);
  const isFetching = getIsDataFetching_ProgramOfStudy(state);
  const user = getLoggedinUser(state);
  const nextProgramStep = getNextStep(state, 'programofstudy');

  const programId = ownProps.programInstanceId
    ? ownProps.programInstanceId
    : ownProps.match.params.programInstanceId;
  const program = getProgram(state, Number(programId));
  const readOnly = getIsProgramReadOnly(state, program.programInstanceId);

  return {
    user,
    nextProgramStep,
    programsOfStudy,
    isFetching,
    program,
    readOnly,
  };
};

const dispatchToProps = {
  loadProgramOfStudyData,
  removeProgramOfStudy,
  saveProgramOfStudyApproval,
  saveProgramOfStudyDenial,
  setCurrentNavAnchor,
};

export const ProgramsOfStudyEdit = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramOfStudyEditComponent);

export const ProgramsOfStudySummary = connect(
  mapStateToProps,
  dispatchToProps
)(ProgramOfStudySummaryComponent);
