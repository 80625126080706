import React from 'react';
import { upsertInPlace } from 'util/arrayUtility';
import { FlexRow, FlexGroup } from 'layouts';
import { map } from 'lodash';
import { AzureUploadParameters, Question } from 'types';
import { QuestionBool } from './QuestionBool.component';
import { QuestionDropdown } from './QuestionDropdown.component';
import { QuestionFiles } from './QuestionFiles.component';
import { QuestionLink } from './QuestionLink.component';
import { QuestionRadio } from './QuestionRadio.component';
import { QuestionText } from './QuestionText.component';

type QuestionProps = {
  question: Question;
  onChange: Function;
  uploadParams: AzureUploadParameters;
};

export const QuestionComponent: React.FC<QuestionProps> = (props) => {
  const { onChange, question, uploadParams } = props;

  const valueChange = (data: Question) => {
    onChange(data);
  };

  const subChange = async (subquest: Question) => {
    const childQuestions = upsertInPlace<Question>(
      question.childQuestions,
      subquest,
      'questionId'
    );
    props.onChange({ ...question, childQuestions });
  };

  const generateQuestion = (
    question: Question,
    onChange: Function = valueChange
  ) => {
    switch (question.dataType) {
      case 'question-list':
        return map(question.childQuestions, (child, index) => {
          return (
            <QuestionComponent
              key={index}
              question={child}
              onChange={subChange}
              uploadParams={uploadParams}
            />
          );
        });
      case 'bool':
        return <QuestionBool question={question} onChange={onChange} />;

      case 'radio':
        return <QuestionRadio question={question} onChange={onChange} />;

      case 'dropdown':
        return <QuestionDropdown question={question} onChange={onChange} />;

      case 'text':
        return <QuestionText question={question} onChange={onChange} />;

      default:
        return (
          <React.Fragment>
            <div className="u-color-red">{question.text}</div>
          </React.Fragment>
        );
    }
  };

  const subQuestions = () => {
    return question.dataType === 'radio' ||
      question.dataType === 'dropdown' ||
      question.dataType === 'question-list'
      ? []
      : question.childQuestions;
  };

  return (
    <React.Fragment>
      {question ? (
        <FlexRow>
          <FlexGroup>
            {generateQuestion(question)}
            <div className="no-show">
              <QuestionLink question={question} />
            </div>
            {question.fileUploadRequired && (
              <div className="assurance-question__child">
                <QuestionFiles
                  uploadParams={uploadParams}
                  question={question}
                  onChange={valueChange}
                />
              </div>
            )}

            {subQuestions() &&
              subQuestions().length > 0 &&
              map(
                question.childQuestions,
                (question: Question, index: number) => {
                  return (
                    <div className="assurance-question__child" key={index}>
                      {generateQuestion(question, subChange)}
                    </div>
                  );
                }
              )}
          </FlexGroup>
        </FlexRow>
      ) : null}
    </React.Fragment>
  );
};
