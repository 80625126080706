import React from 'react';
import { Program, ProgramStep } from 'types';
import { Workflow } from 'types/workflow';
import BaseLayout from 'layouts/BaseLayout';
import ProgramStepsSelections from 'components/Program/ProgramStepsSelections';
import ProgramLayout from 'layouts/ProgramLayout';

type ProgramSummaryPageProps = {
  programInstanceId: number;
  program: Program;
  programSteps: ProgramStep[];
  isProgramDataFetching: boolean;
  workflow: Workflow;
};

export const ProgramSummaryPage: React.FC<ProgramSummaryPageProps> = (
  props
) => {
  const { programInstanceId, programSteps, workflow } = props;

  return (
    <BaseLayout>
      <ProgramLayout {...props}>
        <ProgramStepsSelections
          workflow={workflow}
          programInstanceId={programInstanceId}
          steps={programSteps}
        />
      </ProgramLayout>
    </BaseLayout>
  );
};
