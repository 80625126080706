import { COMMON_KEY, COMMON_COURSES_KEY } from 'redux/keys';
import { Course } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
} from 'redux/utility/baseSelectors';

export const COURSES_PATH = [COMMON_KEY, COMMON_COURSES_KEY];

export const shouldRefreshReduxState_Courses = (state: any): boolean => {
  return shouldRefreshReduxState<Course>(state, COURSES_PATH);
};

export const getIsDataFetching_Courses = (state: any): boolean => {
  return getIsDataFetching(state, COURSES_PATH);
};

export const getCourses = (state: any): Course[] => {
  return getRecords<Course>(state, COURSES_PATH);
};
