import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import ContactSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/ContactSelectBox';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Input from 'components/Common/FormElements/Input';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useRef, useEffect, useState } from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Tooltip from 'components/Common/Tooltip';
import { AdminBooksSupplyValidations } from 'validations/CTA/AdminBooksSupply.validations';
import { AdminCostComponent } from './AdminCostComponent';
import { AdminCostModal } from './AdminCostModal';
import {
  CTAAdministrator,
  emptyCTAAdministrator,
} from 'types/cta/AdministrativeCosts/CTAAdministrator.types';
import { CTADistrictRecord } from 'types/cta/CTADistrictRecord.types';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { UniversalContact, AdminBooksAndSupplies, CTASchool } from 'types';
import { getContactFullName } from 'util/universalcontact.utility';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import { isApplicaionIdValid } from 'util/global';
import { map, some } from 'lodash';
import { readCTASchools } from 'services/cta/ctageneral.services';
import {
  renderData,
  prop,
  safeGet,
  compose,
  getDate,
} from 'util/objectUtility';
import { toast } from 'react-toastify';
import { useModal, Modal } from 'react-morphing-modal';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

type AdministrativeProps = {
  locked: boolean;
  ctaSchools: CTASchool[];
  ctaRecord: CTADistrictRecord;
  specialists: CTAAdministrator[];
  directors: CTAAdministrator[];
  loadCTASpecialists: Function;
  loadCTADirectors: Function;
  getContact: Function;
  saveCTASpecialists: Function;
  saveCTADirectors: Function;
  removeCTASpecialists: Function;
  removeCTADirectors: Function;
  loadCTASupplies: Function;
  saveCTASupplies: Function;
  loadCTASchools: Function;
  booksSupplies: AdminBooksAndSupplies;
};

export const AdministrativeComponent: React.FC<AdministrativeProps> = (
  props
) => {
  const {
    ctaSchools,
    loadCTASpecialists,
    loadCTADirectors,
    specialists,
    directors,
    ctaRecord,
    saveCTASpecialists,
    saveCTADirectors,
    removeCTASpecialists,
    removeCTADirectors,
    loadCTASupplies,
    saveCTASupplies,
    loadCTASchools,
    booksSupplies,
    locked,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const triggerRef = useRef<HTMLButtonElement>(null);
  const newModalRef = useRef<HTMLDivElement>(null);
  const { modalProps, open, close } = useModal();
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);
  const readOnly = useSelector(getIsCTAReadOnly);

  const {
    getError,
    getFieldValid,
    validate,
    validateAll,
    validateIfTrue,
  } = AdminBooksSupplyValidations();

  // --[ local state ]---------------------------------------------------------
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dirtyData, setDirtyData] = useState<boolean>(false);
  const [newAdminRecord, setNewAdminRecord] = useState<CTAAdministrator>(
    emptyCTAAdministrator()
  );
  const [specialistState, setSpecialistState] = useState<CTAAdministrator[]>(
    specialists
  );
  const [directorState, setDirectorState] = useState<CTAAdministrator[]>(
    directors
  );
  const [
    booksSuppliesState,
    setBooksSuppliesState,
  ] = useState<AdminBooksAndSupplies>(booksSupplies);
  const [wasOpened, setWasOpened] = useState<boolean>(false);
  const [adminSchools, setAdminSchools] = useState<CTASchool[]>([]);

  // --[ component logic ]-----------------------------------------------------
  // updateOpenedState :: bool -> void
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };
  const uploadParams = () => ({
    container: 'cta',
    folder: `${ctaRecord.year}|${ctaRecord.schoolDistrictName}|AdminSupportingDoc`,
  });

  const updateBookSupplies = (
    item: Partial<AdminBooksAndSupplies>,
    key: any,
    data: any
  ) => {
    setDirtyData(true);
    validateIfTrue(key, data);
    setBooksSuppliesState({ ...booksSuppliesState, ...item });
  };

  const uploadFile = (supportingDoc: string) => {
    setDirtyData(true);
    setBooksSuppliesState({ ...booksSuppliesState, supportingDoc });
  };

  const updateSpecialists = (contact: UniversalContact) => {
    const exists = some(
      map(specialistState, (item: CTAAdministrator) => {
        return item.contactId === contact.universalContactId;
      })
    );
    if (exists) {
      return;
    }
    setNewAdminRecord({
      ...emptyCTAAdministrator(),
      isDirector: false,
      contactId: contact.universalContactId,
      ctaId,
      contact,
      employeeType: '',
      memo: '',
    });
  };

  const updateDirectors = (contact: UniversalContact) => {
    const exists = some(
      map(directorState, (item: CTAAdministrator) => {
        return item.contactId === contact.universalContactId;
      })
    );
    if (exists) {
      return;
    }
    setNewAdminRecord({
      ...emptyCTAAdministrator(),
      isDirector: true,
      contactId: contact.universalContactId,
      ctaId,
      contact,
      employeeType: '',
      memo: '',
    });
  };

  const onClose = () => {
    setNewAdminRecord(emptyCTAAdministrator());
    close();
  };

  const saveBookSupplies = () => {
    if (validateAll(booksSuppliesState)) {
      saveCTASupplies(ctaId, booksSuppliesState)
        .then(() => setDirtyData(false))
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  const handleSave = (item: CTAAdministrator) => {
    if (item.isDirector) {
      saveCTADirectors(item)
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      saveCTASpecialists(item)
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    }
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (isApplicaionIdValid(newAdminRecord.contactId)) {
      open(newModalRef);
    }
  }, [newAdminRecord]); // eslint-disable-line

  /* Update State */
  useEffect(() => {
    setBooksSuppliesState(booksSupplies);
  }, [booksSupplies]);

  useEffect(() => {
    setDirectorState(directors);
  }, [directors]);

  useEffect(() => {
    setSpecialistState(specialists);
  }, [specialists]);

  /* Load state */
  useEffect(() => {
    if (wasOpened) {
      loadCTASupplies(ctaId);
      loadCTADirectors(ctaId);
      loadCTASpecialists(ctaId);
      loadCTASchools(ctaId);
      readCTASchools(ctaId, true).then((result) => {
        setAdminSchools(result);
      });
    }
  }, [
    ctaId,
    wasOpened,
    loadCTASupplies,
    loadCTADirectors,
    loadCTASpecialists,
    loadCTASchools,
    setAdminSchools,
  ]);

  // --[ display logic ]-------------------------------------------------------
  // getDisplayData :: string -> CTAAdministrator -> string
  const getDisplayData = (name: keyof CTAAdministrator) => (
    row: CTAAdministrator
  ) => {
    return renderData(prop(name, row));
  };

  // getName :: CTAAdministrator -> string
  const getName = (row: CTAAdministrator) => {
    return getContactFullName(row.contact);
  };

  // getCredientialId :: CTAAdministrator -> string
  const getCredientialId = compose(
    renderData,
    prop('credentialId'),
    prop('contact')
  );

  // getCredientialExpiration :: CTAAdministrator -> string
  const getCredientialExpiration = compose(
    renderData,
    getDate,
    prop('credentialExpirationDate'),
    prop('contact')
  );

  const renderEditIcon = (row: CTAAdministrator) => {
    return true ? (
      <AdminCostModal
        districtId={ctaRecord.districtId}
        admin={row}
        saveCTAAdministrator={handleSave}
        ctaSchools={ctaSchools}
        adminSchools={adminSchools}
      />
    ) : (
      renderData(null)
    );
  };

  const renderDeleteIcon = (item: CTAAdministrator) => {
    return (
      <ConfirmationDialog
        tooltip="Delete Contact"
        header="Confirm Delete?"
        onConfirm={() => {
          if (item.isDirector) {
            removeCTADirectors(item)
              .then(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Success.Deleted}
                  />
                );
              })
              .catch(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Errors.DataSaveFailed}
                  />
                );
              });
          } else {
            removeCTASpecialists(item)
              .then(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Success.Deleted}
                  />
                );
              })
              .catch(() => {
                toast(
                  <ToastNotification
                    status="success"
                    message={StringResources.Errors.DataSaveFailed}
                  />
                );
              });
          }
        }}
        activateButton={<IconButton tooltip="Delete" name="trashCan" />}
      >
        <p>
          Are you sure you want to delete this Administrator? Once deleted, data
          on this administrator will need to be re-added via the "Name of
          Contact" dropdown menu.
        </p>
      </ConfirmationDialog>
    );
  };

  // getval :: booksSuppliesState -> string -> booksSuppliesState[string]
  const getval = safeGet(booksSuppliesState);

  const BASE_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: getName,
      selector: getName,
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Credential Id',
      cell: getCredientialId,
      selector: getCredientialId,
      sortable: true,
    },
    {
      name: 'Credential Exp.',
      cell: getCredientialExpiration,
      selector: getCredientialExpiration,
      sortable: true,
    },
    {
      name: 'Affiliation',
      cell: getDisplayData('affiliation'),
      selector: getDisplayData('affiliation'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Salary',
      cell: getDisplayData('salary'),
      selector: getDisplayData('salary'),
      sortable: true,
    },
    {
      name: 'CTE %',
      cell: getDisplayData('ctePercent'),
      selector: prop('ctePercent'),
      sortable: true,
      width: '8rem',
    },
    {
      name: 'Delete',
      cell: renderDeleteIcon,
      width: '5%',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  return (
    <React.Fragment>
      <Modal {...modalProps}>
        <AdminCostComponent
          close={onClose}
          admin={newAdminRecord}
          districtId={ctaRecord.districtId}
          onSave={handleSave}
          ctaSchools={ctaSchools}
          adminSchools={adminSchools}
        />
      </Modal>

      <CTACollapsableLayout
        name="cta-administrative"
        locked={locked}
        header="Administrative Costs"
        onChange={updateOpenedState}
      >
        {wasOpened && (
          <React.Fragment>
            <Paper className="t-ac-cte-director">
              <Instruction title="CTE Director">
                <p ref={newModalRef}>
                  Enter information for all CTE Directors in your district. If
                  your Director is also a teacher and was included in
                  Instructional Costs, do not include them here. Click in the
                  ‘Add a CTE Director’ box and select the individual you would
                  like to add from the list provided. If the CTE Director’s name
                  does not appear, have the person with the CTA Supervisor
                  permission click the ‘Add New Director’ button to go to the{' '}
                  <Link rel="noreferrer" target="_blank" to="/contacts">
                    Universal Contacts
                  </Link>{' '}
                  module to add them. Once the Director’s information is listed,
                  click the Edit icon next to their name and follow the prompts
                  in the pop-up window. You may delete the record by clicking on
                  the trash icon.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: You may add as many
                  CTE Directors as is applicable; the site will automatically
                  select the eligible Directors with the highest salaries to
                  calculate the district’s reimbursement.
                </p>
              </Instruction>

              <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                <FlexRow>
                  <ContactSelectBox
                    onChange={updateDirectors}
                    filter={{ property: 'isCteDirector', values: true }}
                  />
                </FlexRow>
              </SecureWrap>
              <SecureWrap
                component="UniversalContacts"
                requireEdit={true}
                isLocked={readOnly}
              >
                <FlexRow>
                  <div className="u-flex-grow-1" />
                  <button
                    ref={triggerRef}
                    onClick={() =>
                      window.open(`/contacts/create-new-user`, '_blank')
                    }
                    className="button--outline"
                  >
                    New Contact
                  </button>
                </FlexRow>
              </SecureWrap>
              <FlexRow>
                <FlexGroup>
                  <Table columns={BASE_HEADERS} data={directorState} />
                </FlexGroup>
              </FlexRow>
            </Paper>

            <Paper className="t-ac-cte-specialist">
              <Instruction title="CTE Specialist">
                <p>
                  Enter information for all CTE Specialists in your district.
                  Click in the ‘Add a CTE Specialist’ box and select the
                  individual you would like to add from the list provided. If
                  the CTE Specialist’s name does not appear, have the person
                  with the CTA Supervisor permission click the ‘Add New
                  Specialist’ button to go to the{' '}
                  <Link rel="noreferrer" target="_blank" to="/contacts">
                    Universal Contacts
                  </Link>{' '}
                  module to add them. Once the Specialist’s information is
                  listed, click the Edit icon next to their name and follow the
                  prompts in the pop-up window to enter salary, school
                  affiliation, and CTE percentage time information. You may
                  delete the record by clicking on the trash icon.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: You may add as many
                  CTE Specialists as is applicable; the site will automatically
                  select the Specialists with the highest salaries at each
                  school to calculate the district’s reimbursement.
                </p>
              </Instruction>

              <SecureWrap component={['ctageneral']} isLocked={readOnly}>
                <FlexRow>
                  <ContactSelectBox
                    onChange={updateSpecialists}
                    filter={{ property: 'isCteSpecialist', values: true }}
                  />
                </FlexRow>
              </SecureWrap>
              <SecureWrap
                component="UniversalContacts"
                requireEdit={true}
                isLocked={readOnly}
              >
                <FlexRow>
                  <div className="u-flex-grow-1" />
                  <button
                    ref={triggerRef}
                    onClick={() =>
                      window.open(`/contacts/create-new-user`, '_blank')
                    }
                    className="button--outline"
                  >
                    New Contact
                  </button>
                </FlexRow>
              </SecureWrap>
              <FlexRow>
                <FlexGroup>
                  <Table columns={BASE_HEADERS} data={specialistState} />
                </FlexGroup>
              </FlexRow>
            </Paper>

            <Paper className="t-ac-books-supplies">
              <Instruction title="Books and Supplies for Administrative Costs">
                <p>
                  Report all CTE book and supplies administrative costs that are
                  not CTE program specific. When you have finished entering
                  administrative cost data, please combine supporting
                  documentation for personnel and book and supplies costs into a
                  single electronic file (PDF or XLS) and upload it using the
                  ‘Upload Document’ field.
                </p>
                <br />
                <p>
                  When you have finished adding Administrative Cost data click
                  on the ‘Save’ button to continue to the next section.
                </p>
                <p>
                  <span className="u-color-red">Note</span>: If you upload a
                  second document, it will replace the previous one.
                </p>
              </Instruction>

              <FlexRow>
                <Input
                  valid={getFieldValid('booksAndSuppliesTotalCost')}
                  errorMessage={getError('booksAndSuppliesTotalCost')}
                  value={getval('booksAndSuppliesTotalCost')}
                  onBlur={() => {
                    validate(
                      'booksAndSuppliesTotalCost',
                      getval('booksAndSuppliesTotalCost')
                    );
                  }}
                  name="booksAndSuppliesTotalCost"
                  type="number"
                  min={0}
                  max={100000}
                  onChange={updateBookSupplies}
                />
                {booksSuppliesState.dcts ? (
                  <Input
                    errorMessage={getError('dctsTotalCost')}
                    label="DCTS Total Cost"
                    max={100000}
                    min={0}
                    name="dctsTotalCost"
                    onBlur={() => {
                      validate('dctsTotalCost', getval('dctsTotalCost'));
                    }}
                    onChange={updateBookSupplies}
                    step={1}
                    tooltip={
                      <Tooltip
                        indicator={
                          <Icon
                            name="info"
                            className="spending-category__tooltip"
                          />
                        }
                        tooltip="Districts with both DCTS (Designated Career and Technical School) and non-DCTS schools may have administrative books and supplies costs associated with each type of school.  In that case, enter the reported costs for each school type and make sure it is supported by the district’s documentation. If administrative costs cannot be directly linked to the DCTS, report them under Books and Supplies Total Cost."
                      />
                    }
                    type="number"
                    valid={getFieldValid('dctsTotalCost')}
                    value={getval('dctsTotalCost')}
                  />
                ) : (
                  <div />
                )}
                <FlexGroup style={{ alignSelf: 'flex-end' }}>
                  {booksSuppliesState.supportingDoc ? (
                    <AzureDownloader
                      onClose={() => {
                        uploadFile('');
                      }}
                      uploadParams={uploadParams()}
                      filepath={booksSuppliesState.supportingDoc}
                    />
                  ) : (
                    <Button
                      text="+ Upload Documentation"
                      className="form__btn--add u-border"
                      style={{ height: '5.4rem' }}
                      onClick={() => setIsOpen(true)}
                    />
                  )}
                  <AzureUploader
                    onClose={() => setIsOpen(false)}
                    open={isOpen}
                    uploadParams={uploadParams()}
                    onConfirm={uploadFile}
                  />
                </FlexGroup>
              </FlexRow>
              <FlexRow>
                <div className="u-flex-grow-1" />
                <Button
                  className={dirtyData ? 'button--filled' : 'button--outline'}
                  onClick={saveBookSupplies}
                  style={{
                    alignSelf: 'flex-end',
                    height: '5.6rem',
                    width: '28rem',
                  }}
                  text="Save Books and Supplies"
                />
              </FlexRow>
            </Paper>
          </React.Fragment>
        )}
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
