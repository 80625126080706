import { connect } from 'react-redux';
import { ProgramInstanceSelectBox } from './ProgramInstanceSelectBox.component';
import {
  getFilteredRecords_Programs,
  getRecords_ProgramInstance,
} from 'redux/programs/_selectors';
import { loadProgramInstanceBySchoolId } from 'redux/programs/programinstance';
import { Program } from 'types';

export const mapStateToProps = (state: unknown, ownProps: any) => {
  const { filter, records } = ownProps;
  const programs: Program[] = records
    ? records
    : filter
    ? getFilteredRecords_Programs(state, filter)
    : getRecords_ProgramInstance(state);

  return {
    programs,
  };
};

const dispatchProps = {
  loadProgramInstanceBySchoolId,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ProgramInstanceSelectBox);
