import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  actionUpdateRecords,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { ExpenseCategory } from 'types/grant/ExpenseCategory.types';
import { readExpenseCategories } from 'services/common/expensecategory.services';

const initialState = baseInitialState;

export const loadExpenseCategories = createActionThunk(
  'loadExpenseCategories',
  () => {
    return readExpenseCategories();
  }
);

export default handleActions<
  BaseStateObject,
  ExpenseCategory | ExpenseCategory[]
>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    [loadExpenseCategories.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadExpenseCategories.SUCCEEDED]: (state, { payload }) => {
      return actionUpdateRecords(state, payload);
    },
    [loadExpenseCategories.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
