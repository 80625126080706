import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { VoucherSpendingCategory } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { renderData, compose, prop } from 'util/objectUtility';
import { every } from 'lodash';
import { VoucherExpenseValidations } from 'validations/Vouchers/VoucherExpense.validations';
import { VoucherSpendingCategoryValidations } from 'validations/Vouchers/VoucherCategory.validations';
import { fmap } from 'util/arrayUtility';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import RecordActualAmounts from './RecordActualAmounts';
import RecordActualIndirect from './RecordActualIndirect';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Textarea from 'components/Common/FormElements/Textarea';
import VoucherFundsAvailable from './VoucherFundsAvailable';

type EditVoucherExpensesProps = {
  close: Function;
  handleSave: Function;
  onChange: Function;
  spendingCategory: VoucherSpendingCategory;
  spinner: boolean;
  isLocked: boolean;
};

export const EditCategoryExpensesComponent: React.FC<
  EditVoucherExpensesProps
> = (props) => {
  const {
    close,
    handleSave,
    onChange,
    spendingCategory,
    spinner,
    isLocked,
  } = props;

  const [canSave, setCanSave] = useState<boolean>(true);

  const { voucherType }: any = useParams();

  const {
    validateAll: validateAllExpense,
    validationErrors: expenseValidationErrors,
  } = VoucherExpenseValidations();

  const {
    validate: validateIndirect,
    validationErrors: indirectValidationErrors,
  } = VoucherSpendingCategoryValidations();

  const validateCategoryState = compose(
    setCanSave, // set state
    every, // true = all valid, false = one or more false
    fmap(validateAllExpense), // execute validate all on all expense objects
    prop('expenses') // pull expenses off object
  );

  useEffect(() => {
    if (spendingCategory) {
      validateCategoryState(spendingCategory);
      spendingCategory.categoryId === 7 &&
        validateIndirect(
          'totalAmountForCurrentRequest',
          spendingCategory.totalAmountForCurrentRequest,
          spendingCategory
        );
    }
  }, [spendingCategory]); // eslint-disable-line

  return (
    <React.Fragment>
      <Loading isActive={spinner} messageBefore="Saving Expenses..." />
      <Paper>
        <Instruction title="Edit Voucher Project Plan">
          <p className="u-margin-bottom-medium">
            In this section, you’ll record actual amounts that need to be
            reimbursed during the current voucher reimbursement period. If funds
            become available, you are encouraged to record actual amounts for
            backup expenses
          </p>
          {spendingCategory.categoryId !== 7 && (
            <React.Fragment>
              <p className="u-margin-bottom-medium">
                Please note: equipment and travel expenses cannot be submitted
                for reimbursement over multiple vouchers, while personnel and
                other expenses can be.
              </p>
              <p className="u-margin-bottom-medium">
                Equipment included on this voucher requires information about
                where it was purchased, the serial number, date of purchase and
                an attached invoice.
              </p>
            </React.Fragment>
          )}
          <p className="u-error">
            Remember to use ‘Save and Close’ to save your changes.
          </p>
        </Instruction>
      </Paper>
      {spendingCategory.categoryId !== 7 && (
        <Paper>
          <SecureWrap
            isLocked={isLocked}
            component={['GrantsVouchers', 'GrantsReimbursementOverride']}
          >
            <h2 className="u-section-title-text u-margin-bottom-medium">
              Plan Summary
            </h2>
            <FlexRow>
              <FlexGroup>
                <p className="form__label">Addressable Element</p>
                <p className="input-display">
                  {renderData(spendingCategory.categoryName)}
                </p>
              </FlexGroup>
            </FlexRow>
            <FlexRow>
              <div className="form__group u-width-75">
                <Textarea
                  className="input-display"
                  disabled={true}
                  label="Project Plan Strategy"
                  name="strategy"
                  value={renderData(spendingCategory.strategy)}
                />
              </div>
              <div className="form__group u-width-25">
                <p className="form__label">Tier Level</p>
                <p className="input-display">
                  {renderData(spendingCategory.tier)}
                </p>
              </div>
            </FlexRow>
            <FlexRow>
              <Textarea
                className="input-display"
                disabled={true}
                name="description"
                value={renderData(spendingCategory.description)}
              />
            </FlexRow>
          </SecureWrap>
        </Paper>
      )}
      <Paper>
        <VoucherFundsAvailable
          spendingCategory={spendingCategory}
          voucherType={voucherType}
        />
      </Paper>
      <Paper>
        {spendingCategory.categoryId === 7 ? (
          <RecordActualIndirect
            isLocked={isLocked}
            onChange={onChange}
            spendingCategory={spendingCategory}
          />
        ) : (
          <RecordActualAmounts
            isLocked={isLocked}
            onChange={onChange}
            spendingCategory={spendingCategory}
          />
        )}
      </Paper>
      <Paper>
        <SecureWrap
          isLocked={isLocked}
          component={['GrantsVouchers', 'GrantsReimbursementOverride']}
        >
          <SaveButtons
            alternateAction={handleSave}
            disabled={!canSave}
            onCancel={close}
            saveText="Save and close"
            validationErrors={[
              ...expenseValidationErrors,
              ...indirectValidationErrors,
            ]}
          />
        </SecureWrap>
      </Paper>
      <div style={{ height: '16rem' }} />
    </React.Fragment>
  );
};
