import React, { useEffect } from 'react';
import { FlexRow } from 'layouts';
import { ReportingFilterState } from 'types';
import Paper from 'components/Common/Paper';
import ToggleButton from 'components/Common/FormElements/ToggleButton';
import { filter, includes } from 'lodash';

type ReportRailFiltersProps = {
  filterState: Partial<ReportingFilterState>;
  setFilterValue: Function;
};

export const ReportRailFiltersComponent: React.FC<ReportRailFiltersProps> = (
  props
) => {
  const { filterState, setFilterValue } = props;

  const updateFilterState = (module: string) => {
    if (includes(filterState.module, module)) {
      const values = filter(filterState.module, (item) => {
        return item !== module;
      });
      setFilterValue({ module: values });
      return;
    } else setFilterValue({ module: [...filterState.module!, module] });
  };

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <div
        style={{
          width: '36rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(17rem, 1fr))',
          gridGap: '1.6rem',
        }}
      >
        <ToggleButton
          text="Programs"
          value={includes(filterState.module, 'program')}
          onChange={() => {
            updateFilterState('program');
          }}
        />
        <ToggleButton
          text="Grants"
          value={includes(filterState.module, 'grant')}
          onChange={() => {
            updateFilterState('grant');
          }}
        />
        <ToggleButton
          text="Data Collections"
          value={includes(filterState.module, 'datacollection')}
          onChange={() => {
            updateFilterState('datacollection');
          }}
        />
        <ToggleButton
          text="CTA"
          value={includes(filterState.module, 'cta')}
          onChange={() => {
            updateFilterState('cta');
          }}
        />
        <ToggleButton
          text="Universal Contacts"
          value={includes(filterState.module, 'universalcontact')}
          onChange={() => {
            updateFilterState('universalcontact');
          }}
        />
      </div>
    </Paper>
  );
};
///programs, grants, universalcontacts, datacollection, cta
