import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FlexRow, FlexGroup } from 'layouts';
import { SignatureWithAssertion } from 'types';
import {
  AzureUploadParameters,
  CertificationOfExemptionSignature,
} from 'types';
import { CertificationOfExemptionItemValidations } from 'validations/AwardPacket/CertificationOfExemptionItem.validations';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import AzureUploader from 'components/Common/AzureFileStorage/AzureUploader';
import Button from 'components/Common/FormElements/Button';
import SignatureWithAssertionComponent from 'components/Common/SignatureWithAssertion';
import Toggle from 'components/Common/FormElements/Toggle';

type CertificationOfExemptionItemProps = {
  signature: CertificationOfExemptionSignature;
  onChange: Function;
};

export const CertificationOfExemptionItem: React.FC<
  CertificationOfExemptionItemProps
> = (props) => {
  const { onChange, signature } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { grantFiscalYearId: gId }: any = useParams();
  const grantFiscalYearId = Number(gId);

  const { getFieldValid, validate } = CertificationOfExemptionItemValidations();

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: `${grantFiscalYearId}|audit-report`,
  };

  const onToggle = () => {
    onChange({
      ...signature,
      institutionDoesNotMeetThreshold: !signature.institutionDoesNotMeetThreshold,
    });
  };

  const handleAssertionChange = (item: SignatureWithAssertion) => {
    onChange({ ...signature, ...item });
  };

  const handleFileUpload = (item: string) => {
    onChange({ ...signature, auditReportFilePath: item });
  };

  useEffect(() => {
    if (signature) {
      validate('canSave', signature);
    }
  }, [signature]); // eslint-disable-line

  return (
    <div>
      <Toggle
        name="institutionDoesNotMeetThreshold"
        value={signature.institutionDoesNotMeetThreshold}
        onChange={onToggle}
        className="signature"
        description={
          'My Institution receives less than $750,000 annually in Federal funding from any source'
        }
      />
      {signature.institutionDoesNotMeetThreshold ? (
        <SignatureWithAssertionComponent
          onChange={handleAssertionChange}
          signature={signature}
          assertionString={
            'I certify that I have read the Certification of Exemption and agree to the terms.'
          }
          validState={getFieldValid('canSave')}
        />
      ) : (
        <FlexRow>
          <FlexGroup>
            <FlexRow>
              <h4>Upload Single Audit Report</h4>
            </FlexRow>
            {isEmpty(signature.auditReportFilePath) ? (
              <React.Fragment>
                <Button
                  text="+ Upload Document"
                  className="form__btn--add u-flex-self-start"
                  onClick={() => setOpen(true)}
                />
                <AzureUploader
                  onClose={() => setOpen(false)}
                  open={open}
                  uploadParams={uploadParams}
                  onConfirm={handleFileUpload}
                />
              </React.Fragment>
            ) : (
              <AzureDownloader
                onClose={() => {
                  handleFileUpload('');
                }}
                uploadParams={uploadParams}
                filepath={
                  signature.auditReportFilePath
                    ? signature.auditReportFilePath
                    : ''
                }
              />
            )}
          </FlexGroup>
        </FlexRow>
      )}
    </div>
  );
};
