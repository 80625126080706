import { connect } from 'react-redux';
import { ParticipationDashboardComponent } from 'components/Grants/ParticipantManagement/Dashboard.component';
import { loadGrantParticipants } from 'redux/grants/grantparticipant';
import {
  getIsFetching_GrantParticipationMember,
  getAdvancedFilteredRecords_GrantParticipationMember,
  getRecords_GrantParticipationMember,
} from 'redux/grants/grantparticipant.selectors';
import {
  setGrantParticipantFilterValue,
  resetGrantParticipantFilterValue,
  clearGrantParticipantFilters,
} from 'redux/filters/grantParticipantfilter';
import { first, defaultTo } from 'lodash';
import { prop } from 'util/objectUtility';
import { getCurrentFiscalYear } from 'redux/common/_selectors';
import { getLoggedinUser } from 'redux/user/selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const participants = getAdvancedFilteredRecords_GrantParticipationMember(
    state
  );
  const allParticipants = getRecords_GrantParticipationMember(state);
  const grantStatus = defaultTo(
    prop('grantCreated', first(allParticipants)),
    0
  );
  const currentStatus = grantStatus ? true : false;
  const isFetching = getIsFetching_GrantParticipationMember(state);
  const currentFiscalYear = getCurrentFiscalYear(state);
  const loggedInUser = getLoggedinUser(state);

  return {
    currentFiscalYear,
    isFetching,
    participants,
    currentStatus,
    loggedInUser,
  };
};

const dispatchProps = {
  clearGrantParticipantFilters,
  loadGrantParticipants,
  resetGrantParticipantFilterValue,
  setGrantParticipantFilterValue,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ParticipationDashboardComponent);
