import { reMap } from 'util/arrayUtility';
import {
  ProgramPathway,
  CourseCreditProgram,
  CourseCreditElective,
  ProgramAward,
  CourseCreditBase,
} from 'types';
import { map } from 'lodash';

/**
 * Update ProgramPathway records after HTTP request for currently saved ProgramPathways
 *@param cachedRecords the ProgramPathway records currently in cache
 *@param payload an array of ProgramPathway records from the HTTP request payload
 *@return array of ProgramPathway records to set in cache
 */
export const buildProgramPathwaysCache = (
  cachedRecords: ProgramPathway[] | null,
  payload: ProgramPathway[]
) => {
  if (!cachedRecords) {
    return payload;
  }
  return reMap<ProgramPathway>(
    cachedRecords,
    payload,
    'programCareerPathwayId'
  );
};

/**
 * Update CourseCreditRequired or CourseCreditGeneral with updated ProgramAward data.
 *@param cachedRecords an array of either required courses or general education courses currently in cache
 *@param payload the CourseCreditProgram object returned from HTTP request
 *@return remapped course credits with added or removed ProgramAwards
 */
const mapAwardCourses = (
  cachedRecords: CourseCreditBase[],
  payload: CourseCreditProgram
) => {
  const { programAwards } = payload;
  const courses = map(cachedRecords, (course: any) => {
    const awards = reMap<ProgramAward>(
      course.awards,
      programAwards,
      'programAwardId',
      { credits: 0 }
    );
    return { ...course, awards };
  });
  return courses;
};

/**
 * Update CourseCreditElective with updated ProgramAward data.
 *@param cachedRecords the CourseCreditProgram object in cache
 *@param payload the CourseCreditProgram object returned from HTTP request
 *@return remapped course credits with added or removed ProgramAwards
 */
const mapElectives = (
  cachedRecords: CourseCreditElective[],
  payload: CourseCreditProgram
): CourseCreditElective[] => {
  const { programAwards } = payload;
  const electiveCourses = reMap<CourseCreditElective>(
    cachedRecords,
    programAwards,
    'programAwardId',
    { courses: '', credits: 0 }
  );
  return electiveCourses;
};

/**
 * Rebuild CourseCreditProgram in cache with updated data from HTTP request.
 *@param cachedRecords the CourseCreditProgram object in cache
 *@param payload the CourseCreditProgram object returned from HTTP request
 *@return remapped CourseCreditProgram object to set in cache with updated information
 */
export const buildCourseCreditCache = (
  cachedRecords: CourseCreditProgram | null,
  payload: CourseCreditProgram
) => {
  if (!cachedRecords) {
    return payload;
  }
  const {
    requiredCourses,
    generalEducationCourses,
    electiveCourses,
  } = cachedRecords;
  let newRecords: CourseCreditProgram = {
    ...payload,
    programAwards: payload.programAwards,
    requiredCourses: mapAwardCourses(requiredCourses, payload),
    generalEducationCourses: mapAwardCourses(generalEducationCourses, payload),
    electiveCourses: mapElectives(electiveCourses, payload),
  };
  return newRecords;
};
