import { BaseStateObject } from './_exports';
import { Action, createAction } from 'redux-actions';

export const resetIsLoading = createAction('resetIsLoading');

export const setShouldUpdate = (state: any) => {
  return {
    ...state,
    lastUpdate: 0,
  };
};

export const actionFetching = (state: any, isFetching: boolean) => {
  return {
    ...state,
    lastUpdate: Date.now(),
    isFetching,
  };
};

export const actionResetFetching = (state: any, isFetching: boolean) => {
  return {
    ...state,
    isFetching,
  };
};

export const actionUpdateRecords = <T>(state: any, payload: T) => {
  const records = Array.isArray(payload) ? payload : [payload];
  return {
    ...state,
    records,
    lastUpdate: Date.now(),
    lastFailure: 0,
  };
};

export const actionRecordFailure = (state: any) => {
  return {
    ...state,
    lastFailure: Date.now(),
  };
};

/**
 * Creates all the base load action handlers.
 * @param thunk the thunk handler
 */
export const baseLoadActionBuilder = <T>(thunk: any) => {
  return {
    [thunk.START]: (state: BaseStateObject) => {
      return actionFetching(state, true);
    },
    [thunk.SUCCEEDED]: (state: BaseStateObject, action: Action<T>) => {
      return actionUpdateRecords(state, action.payload);
    },
    [thunk.FAILED]: (state: BaseStateObject) => {
      return actionRecordFailure(state);
    },
    [thunk.ENDED]: (state: BaseStateObject) => {
      return actionFetching(state, false);
    },
  };
};

/**
 * Creates all the base save action handlers.
 * @param thunk the thunk handler
 */
// eslint-disable-next-line
export const baseSaveActionBuilder = <T>(thunk: any) => {
  return {
    [thunk.START]: (state: BaseStateObject) => {
      return actionFetching(state, true);
    },
    [thunk.ENDED]: (state: BaseStateObject) => {
      return actionFetching(state, false);
    },
  };
};
