import { ItemFilter, KeyValueType, ReduxFilter } from 'types';
import {
  map,
  filter,
  isNumber,
  isBoolean,
  escapeRegExp,
  deburr,
  some,
  isArray,
} from 'lodash';
import { always, compose, firstMatch, prop, propObject } from './objectUtility';
import { isEqual } from './formHelpers';
import * as R from 'ramda';

export const getFiltersWithValues = <T>(filterValues: KeyValueType<T>) => {
  return filter(Object.keys(filterValues), (item): boolean => {
    const value = filterValues[item];
    if (isNumber(value)) {
      const num: number = value ? value : 0;
      return num !== 0 && num !== -1;
    } else if (isBoolean(value)) {
      return true;
    } else if (isArray(value) && value.length > 0) {
      return true;
    }
    return false;
  });
};

export const getFilterValue = (val: any) => {
  return val !== undefined ? val : '';
};

export const showEverythingFilter = () => {
  return {
    exclude: true,
    property: 'show',
    values: 'everything',
  };
};

export const getReduxFilterFromItemFilterArray = <T>(
  filters: ItemFilter[]
): ReduxFilter<T>[] => {
  return map(filters, (item) => {
    return {
      filter: item,
      operator: 'and',
    };
  });
};

export const getReduxFilter = <T>(
  property: string,
  values: any[],
  operator: 'and' | 'or'
): ReduxFilter<T>[] => {
  return [
    {
      filter: {
        property,
        values,
      },
      operator,
    },
  ];
};

// Returns the data that matches at least one of the columns and the search
export const getFilteredData = (data: any[], headers: any[], value: string) => {
  const cleanValue = escapeRegExp(deburr(value).toLowerCase().trim());
  if (!cleanValue) {
    return data ? data : [];
  }
  return filter(data, (dataItem) => {
    return some(headers, (header) => {
      const selectorIsString = compose(
        isEqual('String'),
        R.type,
        prop('selector')
      );
      const selectorIsFunction = compose(
        isEqual('Function'),
        R.type,
        prop('selector')
      );
      const cellReturnsString = compose(
        isEqual('String'),
        R.type,
        R.applyTo(dataItem),
        prop('cell')
      );
      const getPropValue = compose(propObject(dataItem), prop('selector'));
      const selectorFunction = compose(R.applyTo(dataItem), prop('selector'));
      const cellFunction = compose(R.applyTo(dataItem), prop('cell'));

      const valToTest = firstMatch([
        [selectorIsString, getPropValue],
        [selectorIsFunction, selectorFunction],
        [cellReturnsString, cellFunction],
        [always(true), always('')],
      ])(header);

      const regex = RegExp(`${cleanValue}`, 'i');
      return regex.test(String(valToTest).toLowerCase().trim());
    });
  });
};
