import { connect } from 'react-redux';
import { FollowupDetailsComponent } from './FollowupDetails.component';
import { saveSnapshotStudent } from 'redux/datacollection/studentdata';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};
const dispatchProps = {
  saveSnapshotStudent,
};
export default connect(
  mapStateToProps,
  dispatchProps
)(FollowupDetailsComponent);
