import { COMMON_KEY, COMMON_CIPCODE_KEY } from 'redux/keys';
import { CipCode, ItemFilter } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';

export const CIP_CODES_PATH = [COMMON_KEY, COMMON_CIPCODE_KEY];

export const getIsDataFetching_CipCode = (state: any): boolean => {
  return getIsDataFetching(state, CIP_CODES_PATH);
};

export const getRecords_CipCode = (state: any): CipCode[] => {
  return getRecords<CipCode>(state, CIP_CODES_PATH);
};

export const shouldRefreshReduxState_CipCode = (state: any): boolean => {
  return shouldRefreshReduxState<CipCode>(state, CIP_CODES_PATH);
};

export const getLastUpdated_CipCode = (state: any): number => {
  return getLastUpdated(state, CIP_CODES_PATH);
};

export const getFilteredRecords_CipCode = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<CipCode>(state, CIP_CODES_PATH, itemFilter);
};
