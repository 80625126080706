// Code from https://github.com/JamesRandall/react-azure-adb2c/blob/master/src/react-azure-adb2c.js
// becuse the npm package was outdated.

// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
/* istanbul ignore file */
import * as Msal from 'msal';
import React from 'react';
import { defaultTo } from 'lodash';
import decodeJWT from 'jwt-decode';

const logger = new Msal.Logger(loggerCallback, {
  level: Msal.LogLevel.Warning,
});
const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: [],
};
var appConfig = {
  instance: null,
  tenant: null,
  signInPolicy: null,
  resetPolicy: null,
  applicationId: null,
  cacheLocation: null,
  redirectUri: null,
  postLogoutRedirectUri: null,
};

function loggerCallback(logLevel, message, piiLoggingEnabled) {}

function authCallback(errorDesc, token, error, tokenType) {
  if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
    redirect();
  } else if (errorDesc) {
    state.stopLoopingRedirect = true;
  } else {
    acquireToken();
  }
}

function redirect() {
  const localMsalApp = window.msal;
  localMsalApp.authority = `${appConfig.instance}${appConfig.tenant}/${
    appConfig.resetPolicy
  }`;
  acquireToken();
}

// TODO potential bug here, we might need to add a check for a valid token before
// calling acquireToken based on this: https://stackoverflow.com/questions/46237776/azure-ad-b2c-token-issue
// it might be trying to aquire with an invalid state
function getValidToken() {
  try {
    const decoded = decodeJWT(state.accessToken);
    const expires = Number(decoded.exp);
    if (expires && expires - 300 <= now()) {
      acquireToken();
    }
  } catch (error) {
    return defaultTo(state.accessToken, '');
  }
  return defaultTo(state.accessToken, '');
}

async function acquireToken(successCallback) {
  const localMsalApp = window.msal;
  const user = localMsalApp.getUser(state.scopes);
  if (!user) {
    localMsalApp.loginRedirect(state.scopes);
  } else {
    return await Promise.resolve(
      localMsalApp.acquireTokenSilent(state.scopes).then(
        (accessToken) => {
          if (state.accessToken !== accessToken) {
            //token updated
            const timeDiff = getTimeDiff(accessToken);
            if (timeDiff > 0) {
              setTimeout(acquireToken, timeDiff);
            }
          }
          state.accessToken = accessToken;
          if (state.launchApp) {
            state.launchApp();
          }
          if (successCallback) {
            successCallback();
          }
          return accessToken;
        },
        (error) => {
          if (error) {
            localMsalApp.acquireTokenRedirect(state.scopes);
          }
        }
      )
    );
  }
}

const authentication = {
  initialize: (config) => {
    appConfig = config;
    const instance = config.instance
      ? config.instance
      : 'https://login.microsoftonline.com/tfp/';
    const authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    let scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      state.stopLoopingRedirect = true;
    }
    state.scopes = scopes;

    new Msal.UserAgentApplication(
      config.applicationId,
      authority,
      authCallback,
      {
        logger: logger,
        cacheLocation: config.cacheLocation,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        redirectUri: config.redirectUri,
        validateAuthority: instance.includes('b2clogin.com') ? false : true,
      }
    );
  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    if (
      !window.msal.isCallback(window.location.hash) &&
      window.parent === window &&
      !window.opener
    ) {
      if (!state.stopLoopingRedirect) {
        acquireToken();
      }
    }
  },
  required: (WrappedComponent, renderLoading) => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      componentDidMount() {
        acquireToken(() => {
          this.setState({
            signedIn: true,
          });
        });
      }

      render() {
        if (this.state.signedIn) {
          return <WrappedComponent {...this.props} />;
        }
        return typeof renderLoading === 'function' ? renderLoading() : null;
      }
    };
  },
  signOut: () => {
    window.msal.logout();
  },
  getAccessToken: () => {
    return getValidToken();
  },
};

// helper function to get time in milliseconds
function getTimeDiff(token) {
  try {
    const decoded = decodeJWT(token);
    const expires = Number(decoded.exp);
    return expires ? (expires - now()) * 1000 : -1;
  } catch (error) {
    return -1;
  }
}

// util helper function
function now() {
  return Math.round(new Date().getTime() / 1000.0);
}

export default authentication;
