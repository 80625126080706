import { useValidation } from 'de-formed-validations';
import { CTAEquipment } from 'types';
import { isUniqueObjectList } from 'util/arrayUtility';
import { isMoney } from 'util/moneyHelpers';
import { lt as gt } from 'ramda';

export const CTAEquipmentValidation = () => {
  return useValidation<CTAEquipment>({
    ctaEquipmentCTEPrograms: [
      {
        errorMessage: 'School / Program cannot contain duplicates.',
        validation: isUniqueObjectList('ctaProgramId'),
      },
    ],
    totalCost: [
      {
        errorMessage: 'Must be a valid money representation.',
        validation: isMoney,
      },
      {
        errorMessage: 'The cost per unit must be greater than or equal to $5,000.',
        validation: (val: number, { units }: CTAEquipment) => {
          return val / units >= 5000;
        },
      },
    ],
    units: [
      {
        errorMessage: 'You must have at least 1 unit.',
        validation: gt(0),
      },
    ],
  });
};
