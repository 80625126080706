import React from 'react';
import ReportsDashboard from 'components/Reports/ReportsDashboard';
import { PublicLayout } from 'layouts/PublicLayout';

export const PublicReportsDashboard = () => {
  return (
    <PublicLayout>
      <ReportsDashboard />
    </PublicLayout>
  );
};
