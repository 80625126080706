import { useValidation } from 'de-formed-validations';
import { every, isEmpty, map } from 'lodash';
import { CourseCreditCustom } from 'types/program/CourseCreditCustom.types';

type ValidationState = {
  allCoursesVaslid: CourseCreditCustom[];
};

export const CustomCourseValidations = () => {
  return useValidation<ValidationState>({
    allCoursesValid: [
      {
        errorMessage:
          'Ensure all courses have a course name, and that it is 200 characters or less',
        validation: (courses: CourseCreditCustom[], _) => {
          const isNameValid = map(courses, (item) => {
            return every([
              !isEmpty(item.courseName),
              item.courseName.length <= 200,
            ]);
          });
          return every(isNameValid);
        },
      },
      {
        errorMessage:
          'Ensure all courses have a course prefix, and that it is 5 characters or less',
        validation: (courses: CourseCreditCustom[], _) => {
          const isNameValid = map(courses, (item) => {
            return every([
              !isEmpty(item.coursePrefix),
              item.coursePrefix.length <= 5,
            ]);
          });
          return every(isNameValid);
        },
      },
      {
        errorMessage:
          'Ensure all courses have a course Number, and that it is 5 characters or less',
        validation: (courses: CourseCreditCustom[], _) => {
          const isNameValid = map(courses, (item) => {
            return every([
              !isEmpty(item.courseNumber),
              item.courseNumber.length <= 5,
            ]);
          });
          return every(isNameValid);
        },
      },
    ],
  });
};
