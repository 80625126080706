import {
  toJSON,
  catchError,
  getFormattedUrl,
  getWithAuthOptions,
  postWithAuthOptions,
} from 'services';
import { CTABooksSchool } from 'types';

const BASE_URL = ['api', 'CtaBooksSupply', 'schoolSupply'];

export const readCTABooksSchool = async (id: number) => {
  return fetch(getFormattedUrl([...BASE_URL, id]), { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Books School', err, 'readCTABooksSchool');
    });
};

export const updateCTABooksSchool = async (payload: CTABooksSchool) => {
  return fetch(getFormattedUrl([...BASE_URL]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(payload),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Books School', err, 'updateCTABooksSchool');
    });
};
