import React, { ReactNode } from 'react';
import { FourOhThree } from 'components/Common/Authorization/FourOhThree';
import Loading from 'components/Common/Loading';
import { UniversalContact } from 'types';
import { every, get, isEmpty } from 'lodash';

/**
 *   Secure wrap uses the roles / permissions to determine how users can interact with components.
 *
 *   @typedef {string}                    permission          represents the permission the user has, this is returned from redux via the API.
 *   @typedef {ReactNode | ReactNode[]}   children            the child object | objects that are being wrapped.
 *   @typedef {boolean}                   isPage?             specifying true will cause the 403 access denied page to display.
 *   @typedef {boolean}                   requireEdit         when set to true, the use must have at 'add/edit' access to the component in order to view it.
 *   @typedef {boolean}                   requireAdmin        when set to true, the use must have at 'full' access to the component in order to view it.
 *   @typedef {number}                    owner               indicated the userId of the owner of the component, this will override other permissions adn grant the user access to a normally locked component..
 *   @typedef {UniversalContact}          user                the currently logged in user, used to compare the owner agains the logged in user.
 *   @typedef {JSX.Element}               noAccessComponent   the component to show if the user does not have access, if you don't set this nothing will display.
 *   @typedef {boolean}                   allowReadOnly       set this if you want read only users to have be able to interact with components the normally would only have read only access to. (Navigation buttons, etc...)
 *   @typedef {boolean}                   isLocked            set this to lock the componenet regardless of the users permissions.
 */
export type AuthSecureWrapProps = {
  permission: string;
  children: ReactNode | ReactNode[];
  isPage?: boolean;
  requireEdit?: boolean;
  requireAdmin?: boolean;
  owner?: number;
  user: UniversalContact;
  noAccessComponent?: JSX.Element;
  allowReadOnly?: boolean;
  isLocked?: boolean;
  override?: boolean;
  isLoading: boolean;
};

export const AuthSecureWrap: React.FC<AuthSecureWrapProps> = (props) => {
  const {
    permission,
    children,
    isPage = false,
    requireEdit = false,
    requireAdmin = false,
    owner,
    user,
    noAccessComponent,
    allowReadOnly = false,
    isLocked = false,
    override = false,
    isLoading,
  } = props;

  const theChildren = React.Children.map(children, (child: any) => {
    if (child) {
      return React.cloneElement(child, { permission, owner, user });
    }
    return null;
  });

  const noAccess = noAccessComponent ? noAccessComponent : null;

  const wrapComponent = () => {
    if (every([override, isEmpty(get(user, 'permissionRoles', []))])) {
      return <FourOhThree />;
    }
    if (requireAdmin) {
      return permission === 'full' ? <React.Fragment>{theChildren}</React.Fragment> : noAccess;
    }

    if (owner && Number(owner) === Number(user.universalContactId)) {
      return <React.Fragment>{theChildren}</React.Fragment>;
    }
    if (allowReadOnly && permission !== 'none') {
      return <React.Fragment>{theChildren}</React.Fragment>;
    }

    switch (permission) {
      case 'full':
        return <React.Fragment>{theChildren}</React.Fragment>;

      case 'addedit':
        if (isLocked) {
          return requireEdit ? noAccess : <div className="u-sec-ro">{theChildren}</div>;
        }
        return <React.Fragment>{theChildren}</React.Fragment>;

      case 'readonly':
        return requireEdit ? noAccess : <div className="u-sec-ro">{theChildren}</div>;

      case 'none':
        return isPage ? <FourOhThree /> : noAccess;

      default:
        return <Loading isActive={true} messageBefore="Loading..." />;
    }
  };

  return (
    <React.Fragment>
      {isLoading ? <Loading isActive={true} /> : wrapComponent()}
    </React.Fragment>
  );
};
