import React, { useRef, useState, useEffect } from 'react';
import { startCase } from 'lodash';
import { FlexGroup } from 'layouts';
import { insertClass } from 'util/formHelpers';
import { randomString } from 'util/global';
import { isDisabled } from 'util/authorization';
import Icon from 'components/Common/Icons';
import Label from '../Label';

type DatePickerProps = {
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  label?: string;
  name: string;
  onBlur?: Function;
  onChange?: Function;
  valid?: boolean;
  value?: Date;
};

const formatDate = (date: Date | undefined) => {
  if (!date) return '00/00/0000';
  const newDate = new Date(date);
  let shortDate = newDate.toISOString().split('T')[0];
  if (shortDate.length > 10) {
    shortDate = shortDate.slice(shortDate.length - 10);
  }
  return shortDate;
};

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    disabled,
    errorMessage,
    id,
    name,
    label = props.label ? props.label : startCase(name),
    onBlur,
    onChange,
    valid = true,
    value,
  } = props;

  const [computedDisable, setComputedDisable] = useState<boolean>(false);
  const calendarRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const shortDate = e.target.valueAsDate;
    if (computedDisable || !shortDate) return;
    const data: any = {};
    data[name] = shortDate;
    onChange && onChange(data, name, shortDate);
  };

  const handleBlur = () => {
    if (value) {
      const shortISO = new Date(value).toISOString().split('T')[0];
      const date = new Date(shortISO);
      const data: any = {};
      data[name] = date;
      onChange && onChange(data, name, date);
    }
    onBlur && onBlur();
  };

  const focusCalendar = () => {
    calendarRef.current && calendarRef.current.focus();
  };

  const randomId = randomString();

  useEffect(() => {
    const shouldDisabled = isDisabled(disabled, calendarRef);
    setComputedDisable(shouldDisabled);
  }, [disabled]);

  return (
    <FlexGroup>
      <Label name={name} label={label} htmlFor={id ? id : randomId} />
      <div className={`${insertClass(id)}form__icon-container`}>
        <input
          id={id ? id : randomId}
          ref={calendarRef}
          aria-label={label}
          type="date"
          value={formatDate(value)}
          className={
            valid ? 'form__date-picker' : 'form__date-picker form__input--error'
          }
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={computedDisable}
        />
        <div
          aria-hidden
          className={
            computedDisable
              ? 'form__date-calendar form__date-calendar--disabled'
              : 'form__date-calendar'
          }
          onClick={focusCalendar}
        >
          <Icon name="calendar" className="form__date-icon" />
        </div>
      </div>
      {!valid && <p className="u-color-red">{errorMessage}</p>}
    </FlexGroup>
  );
};
