import { RadioOptionSchema } from 'types';

export const SECONDARY_GRADE_OPTIONS: RadioOptionSchema = {
  '07': {
    id: 7,
    label: '07 - Seventh',
  },
  '08': {
    id: 8,
    label: '08 - Eighth',
  },
  '09': {
    id: 9,
    label: '09 - Ninth',
  },
  '10': {
    id: 10,
    label: '10 - Tenth',
  },
  '11': {
    id: 11,
    label: '11 - Eleventh',
  },
  '12': {
    id: 12,
    label: '12 - Twelth',
  },
  SPU: {
    id: 1,
    label: 'SPU - Special or Ungraded',
  },
};

export const MIDDLE_SCHOOL_GRADE_OPTIONS: RadioOptionSchema = {
  '06': {
    id: 6,
    label: '06 - Sixth',
  },
  '07': {
    id: 7,
    label: '07 - Seventh',
  },
  '08': {
    id: 8,
    label: '08 - Eighth',
  },
};

export const POSTSECONDARY_GRADE_OPTIONS: RadioOptionSchema = {
  '13': {
    id: 13,
    label: '13 - Thirteenth',
  },
  '14': {
    id: 14,
    label: '14 - Fourteenth',
  },
};

export const POSTSECONDARY_REGISTERED_APPRENTICESHIP_OPTIONS = [
  {
    id: 0,
    label: 'Yes',
  },
  {
    id: 1,
    label: 'No',
  },
  {
    id: 255, //255 will be converted to NULL value in API to match null value from user upload
    label: 'Unselect',
  },
];

export const SECONDARY_CERT_OPTIONS = [
  {
    id: 0,
    label:
      'Student did not receive an industry certificate/certification as a part of this program',
  },
  {
    id: 1,
    label:
      'Student received industry certificate(s)/certification(s) that is/are on the CDIP list',
  },
  {
    id: 2,
    label:
      'Student received industry certificate(s)/certification(s) that is/are not on the CDIP list',
  },
  {
    id: 3,
    label:
      'Student received industry certificates/certifications that are both on and not on the CDIP list',
  },
  {
    id: 255, //255 will be converted to NULL value in API to match null value from user upload
    label: 'Unselect any of above',
  },
];

export const SECONDARY_WBL_TYPE_OPTIONS = [
  {
    id: 0,
    label:
      '0 - Student did not experience any of these as part of this program',
  },
  {
    id: 1,
    label: '1 - Student did an industry-sponsored project',
  },
  {
    id: 2,
    label: '2 - Student did a supervised entrepreneurship experience',
  },
  {
    id: 3,
    label: '3 - Student did a credit-for-work experience',
  },
  {
    id: 4,
    label: '4 - Student did a clinical experience',
  },
  {
    id: 5,
    label: '5 - Student did an internship',
  },
  {
    id: 6,
    label: '6 - Student did a pre-apprenticeship',
  },
  {
    id: 7,
    label: '7 - Student did an apprenticeship',
  },
  {
    id: 255, //255 will be converted to NULL value in API to match null value from user upload
    label: 'Unselect any of above',
  },
];

export const SECONDARY_REGISTERED_APPRENTICESHIP_OPTIONS = [
  {
    id: 0,
    label: 'Yes',
  },
  {
    id: 1,
    label: 'No',
  },
  {
    id: 255, //255 will be converted to NULL value in API to match null value from user upload
    label: 'Unselect',
  },
];

export const SECONDARY_GRADUATE_OPTIONS = [
  {
    id: 0,
    label: 'Student did not graduate this year',
  },
  {
    id: 1,
    label: 'High school senior did receive a diploma this year',
  },
  {
    id: 2,
    label:
      'High school senior did NOT receive a diploma this year; will be continuing a 5th year',
  },
  {
    id: 3,
    label:
      'High school senior did NOT receive a diploma this year; will be continuing a 6th year',
  },
];

export const COLORADO_RESIDENT_OPTIONS = [
  {
    key: 1,
    value: 1,
    label: 'Colorado resident',
  },
  {
    key: 2,
    value: 2,
    label: 'Not a Colorado resident',
  },
];

export const MILITARY_STATUS_OPTIONS = [
  {
    key: 1,
    value: 1,
    label:
      "Student is a veteran, receives veteran's benefits, but is not on active duty",
  },
  {
    key: 2,
    value: 2,
    label: 'Student is on active duty in the military',
  },
  {
    key: 3,
    value: 3,
    label: "Student is a veteran but does not receive veteran's benefits",
  },
];

export const EDUCATION_PLAN_OPTIONS = [
  {
    key: 1,
    value: 1,
    label: 'Student is pursuing a certificate',
  },
  {
    key: 2,
    value: 2,
    label: 'Student is pursuing an associate degree',
  },
  {
    key: 3,
    value: 3,
    label: 'Student is upgrading skills',
  },
];
