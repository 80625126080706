import {
  map,
  get,
  find,
  defaultTo,
  curry,
  flow,
  isEmpty,
  every,
  isArray,
} from 'lodash';
import {
  DropdownItem,
  ItemFilter,
  ProgramLevel,
  QuestionGroup,
  OptionMap,
  Question,
  School,
  SignatureInput,
  RadioOptionSchema,
} from 'types';
import { getFilteredRecords } from 'redux/utility/_exports';
import { cMergeObjects, isNull, prop, compose, isFalse } from './objectUtility';
import { formatSSN } from './universalcontact.utility';
import { equals } from 'ramda';
import { fmap } from './arrayUtility';

export const insertClass = (name: string | undefined) => {
  return name ? ` ${name} ` : '';
};

/**
 * @param state Application State (redux)
 * @param path Path in redux to select from
 * @param filter ItemFiter(s) to selct data with
 * @param baseRecords the records to filter
 * @param selector the redux selector to call for records
 *
 */
export const getRecordsUtility = <T>(
  state: unknown,
  path: string[],
  selector: Function,
  filter?: ItemFilter,
  baseRecords?: T[]
): T[] => {
  if (filter) {
    return getFilteredRecords<T>(state, path, filter, baseRecords);
  }
  return baseRecords ? baseRecords : selector(state);
};

/**
 * @param collection the collection we are getting a new temporary id for.
 * @param selector the existing id selector
 * @returns a new unique id
 */
export const createTempId = <T>(collection: T[], selector: string): number => {
  let min = -1;
  let max = 0;
  collection.forEach((item: any) => {
    const id = item[selector];
    if (id < min) {
      min = id;
    }
    if (id > max) {
      max = id;
    }
  });
  return min <= -1 ? min - 1 : (max + 1) * -1;
};

/**
 * @param array the array we are adding to
 * @param crosswalk the crosswalk to use to map the items
 * @returns an array of DropdownItem to use in a ddrodown selector
 */
export const dropdownMapper = <T>(
  array: T[],
  crosswalk: OptionMap<T>
): DropdownItem[] => {
  const output = map(array, (item) => {
    return {
      key: get(item, crosswalk.key, ''),
      label: get(item, crosswalk.label, ''),
      value: get(item, crosswalk.value, ''),
    };
  });
  return output;
};

/**
 * @param schools the array of schools to iterate through
 * @param schoolId the schoolId we are looking for
 * @returns an list of school program levels
 */
export const getSchoolProgramLevels = (
  schools: School[],
  schoolId: number
): ProgramLevel[] => {
  const theSchool = find(schools, (item) => {
    return item.schoolId === schoolId;
  });

  const levels = defaultTo(theSchool && theSchool.programLevels, []);
  return levels;
};

/**
 * @param email the string of the email to test
 * @returns true/false if the email is valid
 */
export const validateEmail = (email: string): boolean => {
  // @ts-ignore
  const validEmailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegex.test(String(email).toLowerCase());
};

/**
 * @param phone phone number as a string to test
 * @returns true/false if the phone number is valid
 */
export const validatePhoneNumber = (phone: string) => {
  const validPhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return validPhoneRegex.test(String(phone).toLowerCase());
};

/**
 * @param ssn Social Security number as a string to test
 * @returns true/false if the Social Security number is valid
 */
export const validateSocialSecurityNumber = (ssn: string) => {
  const testString = formatSSN(ssn).split(' ').join('');
  const validSocialSecurityRegex = /^((?!000)(?!666)(?:[0-6]\d{2}|7[0-2][0-9]|73[0-3]|7[5-6][0-9]|77[0-2]))-((?!00)\d{2})-((?!0000)\d{4})$/;
  return validSocialSecurityRegex.test(String(testString));
};
/**
 * @param name a string
 * @returns the same string with all "_" and "-" replaced with spaces
 */
export const formatFile = (name: string | undefined) => {
  if (!name) return '';
  return name.replace(/[_-]/g, ' ');
};

/**
 * A helper function to debug compose and pipe declarations
 * @param {string} trace any
 * @param {any} x any
 * @returns x
 */
export const trace = curry((trace: string, x: any) => {
  console.log(trace, x);
  return x;
});

/**
 * A helper function that returns an onChange event to dispatch directly to redux
 * Because this function is curried, you can pass params at your convenience from
 * parent to child.
 *
 * NOTE: this function was an attempt to simplify the use of entities editing
 * directly in redux without holding data in a local state, however the pattern
 * was not widely used and so this function has very few current uses outside ASP.
 *
 * @param actionCreator a redux action that takes an array of items
 * @param dispatcher a dispatch hook (e.g. useDispatch())
 * @param item the item being edited from the array
 * @param data returned from an input change event
 * @returns a curried function
 */
export const buildOnChange = curry(
  <T>(
    actionCreator: (data: T[]) => any,
    dispatcher: (action: any) => void,
    item: T,
    data: Partial<T>
  ) => {
    return flow(cMergeObjects(item), actionCreator, dispatcher)(data);
  }
);

/**
 * A helper function for validation an array of Question Groups.
 * @param questions an array of Question Groups
 * @returns boolean
 */
export const allQuestionsAnswered = (questions: QuestionGroup[]): boolean => {
  const _questions = questions && !isArray(questions) ? [questions] : questions;
  const values = map(_questions, (group) => {
    const { questions } = group;

    // If there are no questions return immediatly, prevent saving so they do not save an empty question array to the DB
    if (!questions || questions.length === 0) {
      return false;
    }

    const answers = map(questions, (question: Question) => {
      return wasQuestionAnswered(question);
    });
    return every(answers);
  });
  return every(values);
};

// wasQuestionAnswered helper functions
const questionWasAnswered = compose(
  isFalse,
  isEmpty,
  prop('value'),
  prop('questionValue')
);
const questionIsOptional = prop('isQuestionOptional');
const childQuestionsEmpty = compose(isEmpty, prop('childQuestions'));
const isQuestionList = compose(equals('question-list'), prop('dataType'));
const isFileUploadRequired = prop('fileUploadRequired');
const getQuestionFilePath = compose(
  prop('questionFilePath'),
  prop('questionValue')
);
const checkUploads = compose(every, fmap(compose(isFalse, isEmpty)));
const documentsHaveBeenUploaded = (question: Question) => {
  const filepath = getQuestionFilePath(question);
  return isEmpty(filepath) ? false : checkUploads(getQuestionFilePath);
};

/**
 *  A helper function to determine if a question was answered
 *  @param question Question
 *  @returns boolean
 */
export const wasQuestionAnswered = (question: Question) => {
  // If the question is optional, then mark it as answered
  if (questionIsOptional(question)) {
    return true;
  }

  // Return true if any questions of type 'question-list' have no childQuestions
  if (every([childQuestionsEmpty(question), isQuestionList(question)])) {
    return true;
  }

  // return true if documents have been uploaded and all questions answered
  if (isFileUploadRequired(question)) {
    return documentsHaveBeenUploaded(question);
  }
  return questionWasAnswered(question);
};

/**
 * A helper function for taking HTML from SendGrid and displaying it
 * SendGrid sends <html> and <body> tags which are invalid children, this
 * function cleanses the html as well as removes any URLs that are not prefaced
 * with http or https.
 *
 * @param htmlString string html code
 * @returns string
 */
export const formatHTML = (htmlString: string) => {
  const bodyTags = /<\/?body.*>/g;
  const body = /<body>(.|\s)*?<\/body>/g;
  const anchors = /href="www\.\S+\.com"/g;
  if (!!htmlString.length) {
    const newHtml = body
      .exec(htmlString)![0]
      .replace(bodyTags, '')
      .replace(anchors, 'href=""');
    return newHtml;
  }
  return '';
};

/**
 *  Returns true if signature has empty strings for signedName and signedTitle
 *  @param data SignatureInput
 *  @returns boolean
 */
export const isEmptySignature = (data: SignatureInput) => {
  return every([data.signedName.length === 0, data.signedTitle.length === 0]);
};

/**
 *  Returns true if values are equal, false if they're not. Returns a partially
 *  applied function if only 1 argument is provided.
 *  @param val1 any
 *  @param val2 any
 *  @returns boolean
 */
export const isEqual = curry((val1: any, val2: any) => {
  return JSON.stringify(val1) === JSON.stringify(val2);
});

/**
 * takes data that will be displayed for a user and if the data
 * is falsey it will return an empty placeholder instead
 * @param data any any value
 * @returns presentation data or an EMPTY_PLACEHOLDER
 */
export const renderBool = compose(String, isEqual(true));

/**
 *  Returns an object with data, name and value converted from a number to bool.
 *  @param data Partial type
 *  @returns an object with data, name and val
 */
export const convertRadioToBoolValue = <S>(data: Partial<S>) => {
  const val = Object.values(data)[0];
  const name = Object.keys(data)[0];
  const updated = { [name]: !!val };
  return { data: updated, name, val: !!val };
};

/**
 *  Returns a 1 if true, 0 if false, and null if null
 *  @param bool boolean | null
 *  @returns number | null
 */
export const convertBoolToRadioValue = (bool: boolean | null) => {
  return bool === null ? null : bool ? 1 : 0;
};

/**
 *  Returns a radio option list with 2 values based on a boolean value.
 *  @param object optional optionTrue/optionFalse for label names
 *  @returns list of radio options
 */
type CreateRadioOptionsForBoolType = {
  optionTrue?: string;
  optionFalse?: string;
};
export const createRadioOptionsForBool = ({
  optionTrue,
  optionFalse,
}: CreateRadioOptionsForBoolType = {}) => {
  return [
    { id: 1, label: optionTrue ? optionTrue : 'Yes' },
    { id: 0, label: optionFalse ? optionFalse : 'No' },
  ];
};

/**
 *  Takes a radio option and converts it to a string based on the option shema.
 *  It then takes that result and executes an onChange hanlder provided with it.
 *  @param onChange function to call with transformed payload
 *  @param optionsSchema radio options schema defining the values
 *  @returns the data that was sent to the onChange handler
 */
export const createRadioToStringOnChange = (
  onChange: Function,
  optionsSchema: RadioOptionSchema
) => (data: any, name: string, id: any) => {
  const inputName = Object.keys(data)[0];
  const val = Object.values(data)[0];
  const value = Object.keys(optionsSchema).reduce(
    (prev: string, key: string) => (optionsSchema[key].id === val ? key : prev),
    ''
  );
  const updated = { [inputName]: value };
  onChange(updated, name, id);
  return { data: updated, name, val: id };
};

/**
 *  Takes an optionSchema and returns a function that returns the id for a given
 *  string value to generate the proper radio value equivalent.
 *  @param optionSchema RadioOptionSchema
 *  @returns function (value: string | null) => value ? number : value
 */
export const convertStringToRadioValue = (optionSchema: RadioOptionSchema) => (
  value: string | null
) => {
  return value ? prop('id', optionSchema[value]) : value;
};

/**
 *  Takes an value and an optional default. If the value is null, it will return
 *  the default value, if the value is undefined it will return undefined. This
 *  is to trap undefined data errors.
 *  @param val string | number | null
 *  @param def string | number
 *  @returns string | number
 */
export const getNonNullValue = (
  val: string | number | null,
  def: string | number = ''
): string | number => {
  return isNull(val) ? def : val!;
};
