import { useValidation } from 'de-formed-validations';
import { ImprovementPlan } from 'types';
import { every } from 'lodash';
import { wasQuestionAnswered } from 'util/formHelpers';
import { compose, prop } from 'util/objectUtility';
import { fmap } from 'util/arrayUtility';

// define validation schema for validation hook
type ValidationState = {
  areQuestionsAnswered: boolean;
};

const deficienciesAnswered = compose(
  every,
  fmap(
    compose(
      wasQuestionAnswered,
      prop('question')
    )
  ),
  prop('deficiencies')
);

export const SingleDeficiencySubmitValidations = () => {
  return useValidation<ValidationState>({
    areQuestionsAnswered: [
      {
        errorMessage:
          "Not all questions have been answered in this year's performance deficiencies.",
        validation: (improvementPlan: ImprovementPlan) => {
          return deficienciesAnswered(improvementPlan);
        },
      },
    ],
  });
};
