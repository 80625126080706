import React from 'react';
import { CTACourseTerm } from 'types';
import { FlexGroup } from 'layouts';
import { safeGet } from 'util/objectUtility';
import { randomString } from 'util/global';
import { round } from 'util/mathHelpers';
import { some } from 'lodash';
import Label from 'components/Common/FormElements/Label';
import SquareBoxInput from 'components/Common/FormElements/SquareBoxInput';

interface CTACourseTermFormProps {
  index: number;
  isHosting?: boolean;
  isInstructional?: boolean;
  onChange: Function;
  term: CTACourseTerm;
  valid: boolean;
}
export const CTACourseTermForm: React.FC<CTACourseTermFormProps> = ({
  index,
  isHosting = false,
  isInstructional = false,
  onChange,
  term,
  valid,
}) => {
  // -- display logic --
  const getTerm = safeGet(term);
  const uniqueHash = randomString();

  const getCreditsValue = () => {
    return some([isHosting, isInstructional])
      ? round(2, getTerm('credits'))
      : getTerm('credits');
  };

  return (
    <React.Fragment>
      <div className="cta-course__term__container">
        <p className="form__label">Term {index + 1}</p>
        <div className="cta-course__term__group">
          <div className="cta-course__term__box">
            <FlexGroup>
              <Label name="credits" htmlFor={`credits__${uniqueHash}`} />
              <SquareBoxInput
                className="course-credits__input"
                id={`credits__${uniqueHash}`}
                name="credits"
                onChange={onChange(term)}
                valid={valid}
                value={getCreditsValue()}
              />
            </FlexGroup>
          </div>
          <div className="cta-course__term__box">
            <FlexGroup>
              <Label name="count" htmlFor={`count__${uniqueHash}`} />
              <SquareBoxInput
                className="course-credits__input"
                id={`count__${uniqueHash}`}
                name="count"
                onChange={onChange(term)}
                valid={valid}
                value={getTerm('count')}
              />
            </FlexGroup>
          </div>
          {!isInstructional && (
            <div className="cta-course__term__box">
              <FlexGroup>
                <Label name="cost" htmlFor={`cost__${uniqueHash}`} />
                <SquareBoxInput
                  className="course-credits__input"
                  id={`cost__${uniqueHash}`}
                  name="cost"
                  onChange={onChange(term)}
                  valid={valid}
                  value={getTerm('cost')}
                />
              </FlexGroup>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
