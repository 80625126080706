import { PROGRAMS_KEY, PROGRAM_COURSE_TYPES_KEY } from 'redux/keys';
import {
  shouldRefreshReduxState,
  getRecords,
} from 'redux/utility/baseSelectors';
import { CourseType } from 'types';

export const COURSE_TYPES_PATHS = [PROGRAMS_KEY, PROGRAM_COURSE_TYPES_KEY];

export const getRecords_CourseTypes = (state: any): CourseType[] => {
  return getRecords(state, COURSE_TYPES_PATHS);
};

export const shouldRefreshReduxState_CourseTypes = (state: any): boolean => {
  return shouldRefreshReduxState<CourseType>(state, COURSE_TYPES_PATHS, 300);
};
