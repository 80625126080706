import React from 'react';
import { CTAFilterState, FiscalYear } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import Paper from 'components/Common/Paper';
import ToggleButton from 'components/Common/FormElements/ToggleButton';
import SelectBox from 'components/Common/FormElements/SelectBox';

type CtaFiltersComponentProps = {
  year: FiscalYear;
  filterState: CTAFilterState;
  setCTAFilterValue: Function;
  resetCTAFilterValue: Function;
  clearCTAFilters: Function;
};

export const CtaFiltersComponent: React.FC<CtaFiltersComponentProps> = (props) => {
  const { filterState, setCTAFilterValue } = props;

  const hasSubmissionDateOptions = () => {
    return [
      {
        key: 1,
        label: 'Yes',
        value: 1,
      },
      {
        key: 2,
        label: 'No',
        value: 2,
      },
    ];
  };

  const statusOptions = () => {
    return [
      {
        key: -2,
        label: 'Pending',
        value: -2,
      },
      {
        key: 70,
        label: 'Open',
        value: 70,
      },
      {
        key: 69,
        label: 'Open For Revision',
        value: 69,
      },
      {
        key: 65,
        label: 'Submitted',
        value: 65,
      },
      {
        key: 66,
        label: 'Information Needed',
        value: 66,
      },
      {
        key: 67,
        label: 'Approved',
        value: 67,
      },
      {
        key: 75,
        label: 'No Report',
        value: 75,
      },
    ];
  };

  return (
    <Paper>
      <FlexRow>
        <h2>Filters</h2>
      </FlexRow>
      <FlexGroup>
        <ToggleButton
          text="Needs Review"
          value={filterState.needsReview === true}
          onChange={(item: any) => {
            setCTAFilterValue({
              needsReview: filterState.needsReview ? undefined : true,
            });
          }}
        />
        <SelectBox
          options={hasSubmissionDateOptions()}
          label="First Submitted"
          name="firstSubmission"
          clearable={true}
          shouldReset={true}
          onChange={(item: any) => {
            setCTAFilterValue(item);
          }}
          selection={filterState.firstSubmission}
        />

        <FlexRow>
          <SelectBox
            options={statusOptions()}
            label="Status"
            name="status"
            clearable={true}
            shouldReset={true}
            onChange={(item: any) => {
              setCTAFilterValue({
                status: item.status ? item.status : undefined,
              });
            }}
            selection={filterState.status}
          />
        </FlexRow>
      </FlexGroup>
    </Paper>
  );
};
