import React from 'react';
import { FlexGroup, FlexRow } from 'layouts';
import {
  createRadioOptionsForBool,
  createRadioToStringOnChange,
  convertStringToRadioValue,
  convertBoolToRadioValue,
  convertRadioToBoolValue,
  getNonNullValue,
} from 'util/formHelpers';
import { RadioOptionSchema, SnapshotStudent } from 'types';
import {
  POSTSECONDARY_GRADE_OPTIONS as gradeOptions,
  COLORADO_RESIDENT_OPTIONS as residentOptions,
  MILITARY_STATUS_OPTIONS,
  EDUCATION_PLAN_OPTIONS,
  POSTSECONDARY_REGISTERED_APPRENTICESHIP_OPTIONS as registeredApprenticeshipOptions,
} from 'constants/radioOptionSchema.const';
import { sortBy } from 'lodash';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import Input from 'components/Common/FormElements/Input';
import LabelWithIcon from 'components/Common/FormElements/LabelWithIcon';
import Radio from 'components/Common/FormElements/Radio';
import SelectBox from 'components/Common/FormElements/SelectBox';

export const AcademicInformationPS: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student, onChange, v } = props;

  const handleBooleanChange = (data: Partial<SnapshotStudent>) => {
    const updated = convertRadioToBoolValue<SnapshotStudent>(data);
    onChange(updated.data, updated.name, updated.val);
  };

  const convertGradeToRadioValue = convertStringToRadioValue(gradeOptions);
  const handleGradesChange = createRadioToStringOnChange(
    onChange,
    gradeOptions
  );

  const mapRadioOptions = (data: RadioOptionSchema) => {
    return sortBy(Object.values(data), 'label');
  };

  return student ? (
    <React.Fragment>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexGroup style={{ width: '25%' }}>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="participant"
                iconName="info"
                label="Participant"
                tooltip="This field is used to identify all students who have completed at least one (1) CTE course within any CTE program area (defined by CIP code) within the reporting year."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('participant')}
                name="participant"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('participant')}
                value={convertBoolToRadioValue(student.participant)}
                onBlur={() =>
                  v.validate('participant', student.participant, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="concentrator"
                iconName="info"
                label="Concentrator"
                tooltip="This field is used to identify those students who are Full Time CTE and meet the CTE Concentrator definition. This field is required under Perkins V in order to calculate the Perkins performance metrics."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('concentrator')}
                name="concentrator"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('concentrator')}
                value={convertBoolToRadioValue(student.concentrator)}
                onBlur={() =>
                  v.validate('concentrator', student.concentrator, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="completer"
                iconName="info"
                label="Completer"
                tooltip="This field is used to identify those students who have completed the minimum CTE credit hours required within a single CTE program area (defined by CIP code) for a CTE certificate or degree. This field is needed under Perkins V in order to calculate the Perkins performance metrics."
                place="left"
              />
              <Radio
                border={true}
                errorMessage={v.getError('completer')}
                name="completer"
                noLabel
                onChange={handleBooleanChange}
                options={createRadioOptionsForBool()}
                valid={v.getFieldValid('completer')}
                value={convertBoolToRadioValue(student.completer)}
                onBlur={() =>
                  v.validate('completer', student.completer, student)
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="registeredApprenticeship"
                iconName="info"
                label="Registered Apprenticeship"
                tooltip="Only apprenticeships that are registered through the Department of Labor should be reported as a 'Yes'."
                place="left"
              />
              <Radio //implement "unselect" option for registeredApprenticeship
                border={true}
                horizontal={false}
                name="registeredApprenticeship"
                onChange={onChange}
                options={registeredApprenticeshipOptions}
                value={student.registeredApprenticeship}
                noLabel
                errorMessage={v.getError('registeredApprenticeship')}
                //valid={v.getFieldValid('registeredApprenticeship')}   --optional for 23-24 fiscal year
                onBlur={() =>
                  v.validate(
                    'registeredApprenticeship',
                    student.registeredApprenticeship,
                    student
                  )
                }
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <Radio
              border={true}
              errorMessage={v.getError('fullTimeCte')}
              label="Full Time CTE"
              name="fullTimeCte"
              onChange={handleBooleanChange}
              options={createRadioOptionsForBool()}
              valid={v.getFieldValid('fullTimeCte')}
              value={convertBoolToRadioValue(student.fullTimeCte)}
              onBlur={() =>
                v.validate('fullTimeCte', student.fullTimeCte, student)
              }
            />
          </FlexRow>
        </FlexGroup>

        <FlexGroup style={{ width: '50%' }}>
          <FlexRow>
            <Radio
              border={true}
              errorMessage={v.getError('grade')}
              label="CDE Grade"
              name="grade"
              onChange={handleGradesChange}
              options={mapRadioOptions(gradeOptions)}
              valid={v.getFieldValid('grade')}
              value={convertGradeToRadioValue(student.grade)}
              onBlur={() => v.validate('grade', student.grade, student)}
            />
          </FlexRow>
          <FlexGroup>
            <FlexRow>
              <FlexGroup>
                <LabelWithIcon
                  htmlFor="oneYearCteCertificate"
                  iconName="info"
                  label="One-Year CTE Certificates"
                  tooltip="This field identifies the number of one-year CTE certificates awarded to this student this academic year in just this program CIP.  Must be verifiable in the institution’s IPEDS submission for the same academic year."
                  place="left"
                />
                <Input
                  name="oneYearCteCertificate"
                  noLabel
                  type="number"
                  step={1}
                  onChange={onChange}
                  value={getNonNullValue(student.oneYearCteCertificate)}
                  min={0}
                />
              </FlexGroup>
              <FlexGroup>
                <LabelWithIcon
                  htmlFor="aas"
                  iconName="info"
                  label="AAS Degrees"
                  place="right"
                  tooltip="This field identifies the number of AAS degree awards to this student this academic year in just this program CIP.  Must be verifiable in the institution’s IPEDS submission for the same academic year."
                />
                <Input
                  name="aas"
                  noLabel
                  type="number"
                  step={1}
                  onChange={onChange}
                  value={getNonNullValue(student.aas)}
                  min={0}
                />
              </FlexGroup>
            </FlexRow>
            <FlexRow>
              <FlexGroup>
                <LabelWithIcon
                  htmlFor="twoYearCteCertificate"
                  iconName="info"
                  label="Two-Year CTE Certificates"
                  place="left"
                  tooltip="This field identifies the number of two-year CTE certificates awarded to this student this academic year in just this program CIP.  Must be verifiable in the institution’s IPEDS submission for the same academic year."
                />
                <Input
                  name="twoYearCteCertificate"
                  noLabel
                  type="number"
                  step={1}
                  onChange={onChange}
                  value={getNonNullValue(student.twoYearCteCertificate)}
                  min={0}
                />
              </FlexGroup>
              <FlexGroup>
                <LabelWithIcon
                  htmlFor="agsAcademic"
                  iconName="info"
                  label="AGS/Academic Degrees"
                  place="right"
                  tooltip="This field identifies the number of AGS degree awards to this student this academic year in just this AGS academic CIP.  Must be verifiable in the institution’s IPEDS submission for the same academic year."
                />
                <Input
                  name="agsAcademic"
                  noLabel
                  type="number"
                  step={1}
                  onChange={onChange}
                  value={getNonNullValue(student.agsAcademic)}
                  min={0}
                />
              </FlexGroup>
            </FlexRow>
          </FlexGroup>
        </FlexGroup>

        <FlexGroup style={{ width: '25%' }}>
          <FlexRow>
            <SelectBox
              label="Colorado Resident (Optional)"
              name="coloradoResident"
              onChange={onChange}
              options={residentOptions}
              selection={student.coloradoResident}
              clearable={true}
            />
          </FlexRow>
          <FlexRow>
            <SelectBox
              label="Military Status (Optional)"
              name="militaryStatus"
              onChange={onChange}
              options={MILITARY_STATUS_OPTIONS}
              selection={student.militaryStatus}
              clearable={true}
            />
          </FlexRow>
          <FlexRow>
            <SelectBox
              label="Educational Plan (Optional)"
              name="educationPlan"
              onChange={onChange}
              options={EDUCATION_PLAN_OPTIONS}
              selection={student.educationPlan}
              clearable={true}
            />
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <LabelWithIcon
                htmlFor="agsCte"
                iconName="info"
                label="AGS/CTE Degrees"
                place="right"
                tooltip="This field identifies the number of AGS/CTE degree awards to this student this academic year.  Must be verifiable in the institution’s IPEDS submission for the same academic year.  Can be identified by a combination of major and minor codes."
              />
              <Input
                name="agsCte"
                noLabel
                type="number"
                step={1}
                onChange={onChange}
                value={getNonNullValue(student.agsCte)}
                min={0}
              />
            </FlexGroup>
          </FlexRow>
        </FlexGroup>
      </FlexRow>
    </React.Fragment>
  ) : null;
};
