import { ENVIRONMENT } from 'constants/application.config';
import { toJSON, getOptions, catchError } from 'services';

export const readOccupations = async (keyword: string) => {
  return fetch(`${ENVIRONMENT.ONET.API_URL}${keyword}`, {
    ...getOptions,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('Onet', err);
    });
}; // End
