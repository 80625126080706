import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionUpdateRecords,
  actionFetching,
} from 'redux/utility/_exports';
import { StudentEnrollment, StudentEnrollmentData } from 'types';
import {
  readStudentEnrollmentData,
  setEnrollmentComplete,
  setFollowupComplete,
  readStudentFollowUpData,
} from 'services/datacollection/studentenrollment.services';
import { firstOrDefault } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadStudentEnrollmentData = createActionThunk(
  'loadStudentEnrollmentData',
  () => {
    return readStudentEnrollmentData();
  }
);

export const loadStudentFollowUpData = createActionThunk(
  'loadStudentFollowUpData',
  () => {
    return readStudentFollowUpData();
  }
);

export const insertFollowupComplete = createActionThunk(
  'insertFollowupComplete',
  () => {
    return setFollowupComplete();
  }
);

export const insertEnrollmentComplete = createActionThunk(
  'insertEnrollmentComplete',
  () => {
    return setEnrollmentComplete();
  }
);

export default handleActions<
  BaseStateObject,
  StudentEnrollment | StudentEnrollment[]
>(
  {
    ...baseLoadActionBuilder<StudentEnrollmentData | StudentEnrollmentData[]>(
      loadStudentEnrollmentData
    ),
    ...baseLoadActionBuilder<StudentEnrollmentData | StudentEnrollmentData[]>(
      loadStudentFollowUpData
    ),

    [insertFollowupComplete.START]: (state: BaseStateObject) => {
      return actionFetching(state, true);
    },
    [insertFollowupComplete.SUCCEEDED]: (state: BaseStateObject) => {
      const currentrecord: StudentEnrollmentData = firstOrDefault(
        state.records,
        undefined
      );
      if (currentrecord) {
        return actionUpdateRecords(state, [
          { ...currentrecord, followupComplete: true },
        ]);
      }
      return actionUpdateRecords(state, state.records);
    },
    [insertFollowupComplete.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    [insertEnrollmentComplete.START]: (state: BaseStateObject) => {
      return actionFetching(state, true);
    },
    [insertEnrollmentComplete.SUCCEEDED]: (state: BaseStateObject) => {
      const currentrecord: StudentEnrollmentData = firstOrDefault(
        state.records,
        undefined
      );
      if (currentrecord) {
        return actionUpdateRecords(state, [
          { ...currentrecord, complete: true },
        ]);
      }
      return actionUpdateRecords(state, state.records);
    },

    [insertEnrollmentComplete.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
