import { CTA_KEY, CTA_GENERAL_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTASchool } from 'types';

export const CTA_GENERAL_PATH = [CTA_KEY, CTA_GENERAL_KEY];

export const getIsDataFetching_CTAGeneral = (state: any): boolean => {
  return getIsDataFetching(state, CTA_GENERAL_PATH);
};

export const getRecords_CTASchool = (state: unknown): CTASchool[] => {
  return getRecords(state, CTA_GENERAL_PATH);
};
