import React, { useState, useEffect } from 'react';
import {
  WorkflowSubmission,
  WorkflowAction,
  WorkflowStep,
} from 'types/workflow';
import { map } from 'lodash';
import { FlexRow } from 'layouts';
import { OptionMap } from 'types';
import { catchError } from 'services';
import Icon from 'components/Common/Icons';
import SaveButtons from 'components/Common/SaveButtons';
import Select from 'components/Common/FormElements/SelectBox';
import Textarea from 'components/Common/FormElements/Textarea';
import { toast } from 'react-toastify';
import ToastNotification from 'components/Common/Toast';
import StringResources from 'StringResources';
import { isApplicaionIdValid } from 'util/global';

export type WorkflowActionProps = {
  applicationId: number;
  submitApplicationUpdate: Function;
  workflowStep: WorkflowStep;
  validationErrors?: string[];
};

export const CreateWorkflowAction: React.FC<WorkflowActionProps> = (props) => {
  const {
    applicationId,
    submitApplicationUpdate,
    validationErrors,
    workflowStep,
  } = props;

  const [submission, setSubmission] = useState<WorkflowSubmission>({
    optionId: -1,
    comment: '',
  });

  const onSubmit = async () => {
    return Promise.resolve(
      submitApplicationUpdate(applicationId, submission)
    ).catch((err: any) => {
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.DataSaveFailed}
        />
      );
      catchError('submitApplicationUpdate', err);
    });
  };

  const onFormChange = (data: any) => {
    setSubmission({
      ...submission,
      ...data,
    });
  };

  const optionMap: OptionMap<WorkflowAction> = {
    key: 'stepActionId',
    label: 'label',
    value: 'stepActionId',
  };

  const getDisabled = () => {
    const ignoreValidations = [188, 196, 235, 243, 246, 255];
    return ignoreValidations.includes(submission.optionId)
      ? false
      : validationErrors
      ? validationErrors.length > 0
      : false;
  };

  useEffect(() => {
    setSubmission({ optionId: -1, comment: '' });
  }, [workflowStep]);

  return (
    <React.Fragment>
      <FlexRow>
        <span>Currently assigned To: &nbsp;</span>
        {map(workflowStep.approvers, (item, index) => {
          return (
            <React.Fragment key={index}>
              {isApplicaionIdValid(item.universalContactId) && (
                <React.Fragment>
                  <a key={index} href={`mailto:${item.email}`}>
                    {item.name}
                  </a>
                  {index < workflowStep.approvers.length - 1 && (
                    <span>&nbsp;|&nbsp;</span>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </FlexRow>
      <FlexRow>
        <Select
          records={workflowStep.workflowActions}
          optionMap={optionMap}
          label="Select an option"
          name="optionId"
          onChange={onFormChange}
          selection={submission.optionId}
          shouldReset={true}
        />
      </FlexRow>
      <FlexRow>
        <Textarea
          maxLength={8000}
          label="Add a comment (optional)"
          name="comment"
          onChange={onFormChange}
          value={submission.comment}
        />
      </FlexRow>
      <SaveButtons
        disabled={getDisabled()}
        alternateAction={onSubmit}
        hideCancel={true}
        saveText="Submit"
        validationErrors={validationErrors && validationErrors}
      />
      {validationErrors && validationErrors.length > 0 && (
        <FlexRow>
          <span className="expense-warning">
            <Icon name="megaphone" className="expense-warning__icon" />
            <div className="expense-warning__text">
              <p>
                Your application did not pass all requirements for submission.
              </p>
              <p>
                Please navigate back to your application and fix all failing
                validations.
              </p>
            </div>
            <Icon
              name="megaphone"
              className="expense-warning__icon expense-warning__icon--flip"
            />
          </span>
        </FlexRow>
      )}
    </React.Fragment>
  );
};
