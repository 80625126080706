import { saveBusiness } from 'redux/common/schools';
import { loadInstitutes } from 'redux/common/intitutes';

export const schoolMiddleWare = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveBusiness.SUCCEEDED: {
      dispatch(loadInstitutes());
      break;
    }
  }
};
