import React, { useEffect } from 'react';
import { map, filter, every } from 'lodash';
import { ProgramContactDeep, ProgramContactType } from 'types';
import {
  getUserEmail,
  getContactFullName,
  getUserPhone,
  getCredentialString,
  extractTeacherInfo,
} from 'util/universalcontact.utility';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { useParams } from 'react-router-dom';
import { isApplicaionIdValid } from 'util/global';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { compose, renderData, prop } from 'util/objectUtility';
import DatatableLoading from 'components/Common/DatatableLoading';
import IconButton from 'components/Common/IconButton';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import TeacherCredentials from 'components/UniversalContacts/TeacherCredentials';
import Table from 'components/Common/Table';

type ProgramContactsProps = {
  isFetching: boolean;
  loadProgramContacts: Function;
  programContactCache: ProgramContactDeep[];
  programContactTypes: ProgramContactType[];
  programLevel: number;
  setCurrentNavAnchor: Function;
};

export const ContactsSummaryComponent: React.FC<ProgramContactsProps> = (
  props
) => {
  const {
    isFetching,
    loadProgramContacts,
    programContactCache,
    programContactTypes,
    programLevel,
    setCurrentNavAnchor,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const getTableData = (typeName: string, typeId: number) => {
    return filter(programContactCache, (contact: ProgramContactDeep) => {
      const isOther = every([
        typeName.toLowerCase() === 'other',
        contact.programContactTypeId === 255,
      ]);
      return contact.programContactTypeId === typeId || isOther;
    });
  };

  const FullName = compose(renderData, getContactFullName, prop('contact'));
  const Title = compose(renderData, prop('title'), prop('contact'));
  const Email = compose(renderData, getUserEmail, prop('contact'));
  const Phone = compose(renderData, getUserPhone, prop('contact'));
  const Credentials = compose(
    renderData,
    prop('credentialId'),
    prop('contact')
  );
  const getAdvisorFullName = (row: ProgramContactDeep) => {
    return `${row.advisorFirstName} ${row.advisorLastName}`;
  };
  const AdvisorFullName = compose(renderData, getAdvisorFullName);
  const AdvisorTitle = compose(renderData, prop('advisorTitle'));
  const AdvisorOrganization = compose(renderData, prop('advisorOrganization'));
  const renderFullName = (row: ProgramContactDeep) => {
    return (
      <span
        className="u-flex u-color-blue u-bold"
        style={{ alignItems: 'center' }}
      >
        {row.contact.isTeacher && (
          <TeacherCredentials teacherInfo={extractTeacherInfo(row.contact)} />
        )}
        {FullName(row)}
      </span>
    );
  };
  const renderAdvisorFullName = (row: ProgramContactDeep) => {
    return <span className="u-bold u-color-blue">{AdvisorFullName(row)}</span>;
  };

  const renderEmail = (row: ProgramContactDeep) => {
    const email = Email(row);
    return email === EMPTY_PLACEHOLDER ? (
      <React.Fragment>{email}</React.Fragment>
    ) : (
      <a href={`mailto:${email}`}>{email}</a>
    );
  };

  const renderAdvisoryEmail = (row: ProgramContactDeep) => {
    const email = row.advisorEmail.trim()
      ? row.advisorEmail.trim()
      : EMPTY_PLACEHOLDER;
    return email === EMPTY_PLACEHOLDER ? (
      <React.Fragment>{email}</React.Fragment>
    ) : (
      <a href={`mailto:${email}`}>{email}</a>
    );
  };

  const renderCredentials = (row: ProgramContactDeep) => {
    return (
      <React.Fragment>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cool.randasolutions.com/Public/Search"
        >
          {getCredentialString(extractTeacherInfo(row.contact))}
        </a>
      </React.Fragment>
    );
  };

  const renderViewLink = (row: ProgramContactDeep) => (
    <IconButton
      target="_blank"
      name="launchOpen"
      tooltip="View contact in new browser tab"
      asLink={true}
      to={`/contacts/${row.contactId}`}
    />
  );

  const CONTACT_COLUMNS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: renderFullName,
      sortable: true,
      selector: FullName,
      width: '20%',
    },
    {
      name: 'Title',
      cell: Title,
      sortable: true,
      selector: Title,
      width: '25%',
    },
    {
      name: 'Email',
      cell: renderEmail,
      sortable: true,
      selector: Email,
      style: {
        flexGrow: '1',
      },
    },
    {
      name: 'Phone',
      cell: Phone,
      sortable: true,
      selector: Phone,
      width: '15rem',
    },
    {
      name: 'Credentials',
      cell: renderCredentials,
      sortable: true,
      selector: Credentials,
    },
    {
      name: 'View',
      cell: renderViewLink,
      center: true,
      width: '5rem',
    },
  ];

  const ADVISORY_CHAIR_HEADER: TableColumnProps[] = [
    {
      name: 'Name',
      cell: renderAdvisorFullName,
      sortable: true,
      selector: AdvisorFullName,
      width: '20%',
    },
    {
      name: 'Title',
      cell: AdvisorTitle,
      sortable: true,
      selector: AdvisorTitle,
      width: '25%',
    },
    {
      name: 'Email',
      sortable: true,
      cell: renderAdvisoryEmail,
      style: {
        flexGrow: '1',
      },
    },
    {
      name: 'Organization',
      cell: AdvisorOrganization,
      style: {
        flexGrow: '1',
      },
    },
  ];

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramContacts(programInstanceId);
    }
  }, [loadProgramContacts, programInstanceId]);

  return (
    <Paper>
      <SummaryScroll componentStep="contacts" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('contacts')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      {isFetching ? (
        <DatatableLoading
          messageBefore={'Loading Contacts'}
          loading={isFetching}
        />
      ) : (
        <React.Fragment>
          {map(programContactTypes, (contactType: ProgramContactType) => {
            const typeID = contactType.programContactTypeId;
            const typeName = contactType.programContactTypeName;
            const isPostSecondary = programLevel === 2;
            // if it is postsecondary program dont render the District
            // and if its not postsecondary then dont render Department
            if (
              (isPostSecondary && typeName.toLowerCase() === 'district') ||
              (!isPostSecondary && typeName.toLowerCase() === 'department')
            ) {
              return;
            }
            // modifies the title based on the type
            let sectionTitle = '';
            switch (typeName.toLowerCase()) {
              case 'school':
                if (isPostSecondary) {
                  sectionTitle = 'Institution Contacts';
                } else {
                  sectionTitle = `${typeName} Contacts`;
                }
                break;
              case 'advisory chair':
                sectionTitle = 'Program Advisory Chair';
                break;
              case 'other':
                sectionTitle = 'Additional Contacts';
                break;
              default:
                sectionTitle = `${typeName} Contacts`;
                break;
            }
            return (
              <Paper flat={true} key={typeName}>
                <h3>{sectionTitle}</h3>
                <Table
                  columns={
                    typeName.toLowerCase() === 'advisory chair'
                      ? ADVISORY_CHAIR_HEADER
                      : CONTACT_COLUMNS
                  }
                  data={getTableData(typeName, typeID)}
                />
              </Paper>
            );
          })}
        </React.Fragment>
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('contacts')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
