import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { SideNavData } from 'types/ui/SideNavData.types';
import { readGrantAwardPacketNav } from 'services/nav/grantawardpacket.services';

const initialState = baseInitialState;

export const loadGrantAwardPacketNav = createActionThunk(
  'loadGrantAwardPacketNav',
  (GrantFiscalYearId: number) => {
    return readGrantAwardPacketNav(GrantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, SideNavData>(
  {
    ...baseLoadActionBuilder<SideNavData>(loadGrantAwardPacketNav),
  },
  initialState
);
