import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readProgramAreas } from 'services/common/programareas.services';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { ProgramArea } from 'types/common/ProgramArea.types';

const initialState = baseInitialState;

export const loadProgramAreas = createActionThunk('loadProgramAreas', () => {
  return readProgramAreas();
});

export default handleActions<BaseStateObject, ProgramArea[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramArea[]>(loadProgramAreas),
  },
  initialState
);
