import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  putWithAuthOptions,
  catchError,
} from '..';
import { ContactType } from 'types';

const CONTACT_TYPE_ENDPOINT = 'api/ContactTypes/';

export const createContactType = async (payload: ContactType) => {
  let value: ContactType = {
    contactTypeId: payload.contactTypeId,
    contactTypeName: payload.contactTypeName,
    isAadB2c: payload.isAadB2c,
    isProgramContact: payload.isProgramContact,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  };
  return fetch(`${ENVIRONMENT.API_URL}${CONTACT_TYPE_ENDPOINT}`, {
    ...postWithAuthOptions(),
    body: JSON.stringify(value),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<unknown>('ContactType', err);
    });
}; // end

export const readContactTypes = async (): Promise<ContactType[]> => {
  return fetch(`${ENVIRONMENT.API_URL}${CONTACT_TYPE_ENDPOINT}`, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError<ContactType>('ContactType', err);
    });
}; // end

export const updateContactType = async (payload: ContactType) => {
  return fetch(
    `${ENVIRONMENT.API_URL}${CONTACT_TYPE_ENDPOINT}${payload.contactTypeId}`,
    {
      ...putWithAuthOptions(),
      body: JSON.stringify(payload),
    }
  )
    .then(toJSON)
    .catch((err) => {
      return catchError<unknown>('ContactType', err);
    });
}; // end
