import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionComponent } from 'types';
import {
  createPermissionComponent,
  readPermissionComponents,
  updatePermissionComponent,
} from 'services/permissions/permissioncomponent.services';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadPermissionComponents = createActionThunk(
  'loadPermissionComponents',
  () => {
    return readPermissionComponents();
  }
); // end

export const savePermissionComponent = createActionThunk(
  'savePermissionComponent',
  async (payload: PermissionComponent) => {
    let creating =
      !payload.permissionComponentId || payload.permissionComponentId === -1;
    if (creating) {
      return createPermissionComponent(payload);
    } else {
      return updatePermissionComponent(payload).then(() => {
        return payload.permissionComponentId;
      });
    }
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, PermissionComponent[]>(
  {
    ...baseLoadActionBuilder<PermissionComponent[]>(loadPermissionComponents),

    [savePermissionComponent.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [savePermissionComponent.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
