import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { SideNavData } from 'types/ui/SideNavData.types';
import { readGrantFourYearNav } from 'services/nav/grantfouryear.services';

const initialState = baseInitialState;

export const loadGrantFourYearNav = createActionThunk(
  'loadGrantFourYearNav',
  (GrantFiscalYearId: number) => {
    return readGrantFourYearNav(GrantFiscalYearId);
  }
);

export default handleActions<BaseStateObject, SideNavData>(
  {
    ...baseLoadActionBuilder<SideNavData>(loadGrantFourYearNav),
  },
  initialState
);
