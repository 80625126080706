import React, { useEffect, useState } from 'react';
import { filter, find, every } from 'lodash';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateSpendingCategories } from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { FlexGroup, FlexRow } from 'layouts';
import { formatMoney } from 'util/moneyHelpers';
import { Modal, useModal } from 'react-morphing-modal';
import { prop, renderData } from 'util/objectUtility';
import { buildOnChange } from 'util/formHelpers';
import { isApplicaionIdValid } from 'util/global';
import { isAdminCategory } from 'util/annualSpendingUtility';
import {
  Expense,
  emptyExpense,
  ExpenseCategory,
  SpendingCategory,
  UniversalContact,
} from 'types';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { contactHasRoles } from 'util/permissionsUtility';
import AllocatedFundsRemaing from '../../FundsRemaining/AllocatedFundsRemaing';
import Button from 'components/Common/FormElements/Button';
import ExpenseEdit from '../ExpenseEdit';
import IconButton from 'components/Common/IconButton';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';

type CreateProjectExpensesProps = {
  expenseCategories: ExpenseCategory[];
  loadAnnualSpendingPlan: Function;
  setCurrentNavAnchor: Function;
  spendingCategory: SpendingCategory;
  isLocked: boolean;
  loggedInUser: UniversalContact;
};

export const CreateProjectExpenses: React.FC<CreateProjectExpensesProps> = (
  props
) => {
  const {
    expenseCategories,
    spendingCategory,
    setCurrentNavAnchor,
    loadAnnualSpendingPlan,
    isLocked,
    loggedInUser,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const dispatch = useDispatch();
  const { modalProps, open, close } = useModal();
  const { grantFiscalYearId: fId, strategyId: sId }: any = useParams();
  const grantFiscalYearId = Number(fId);
  const strategyId = Number(sId);

  // --[ local state ]---------------------------------------------------------
  const [expense, setExpense] = useState<Expense>({
    ...emptyExpense(),
    strategyId,
  });

  // --[ component logic ]-----------------------------------------------------
  const onChange = buildOnChange(
    updateSpendingCategories,
    dispatch,
    spendingCategory
  );

  const getExpenses = ({ backup }: { backup: boolean }) => {
    return filter(spendingCategory.expenses, (expense: Expense) => {
      return expense.isBackupExpense === backup;
    });
  };

  const handleClick = (row: any) => {
    setExpense(row);
  };

  const handleDelete = (expenseId: number) => {
    const expenses = filter(spendingCategory.expenses, (item: Expense) => {
      return item.expenseId !== expenseId;
    });
    onChange({ expenses });
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setCurrentNavAnchor('annual-spending-plan');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      loadAnnualSpendingPlan(grantFiscalYearId);
    }
  }, [loadAnnualSpendingPlan, grantFiscalYearId]); //eslint-disable-line

  // --[ display logic ]-------------------------------------------------------
  const getFinancialCategoryName = (id: number) => {
    return find(expenseCategories, (cat: ExpenseCategory) => {
      return cat.expenseCategoryId === id;
    });
  };

  const renderId = compose(renderData, prop('expenseId'));

  const renderName = compose(renderData, prop('expenseName'));

  const renderBudgetedAmount = compose(
    renderData,
    formatMoney,
    prop('budgetedAmount')
  );

  const renderFinancialCategory = compose(
    renderData,
    prop('expenseCategoryName'),
    getFinancialCategoryName,
    prop('financialCategoryId')
  );

  const renderEditIcon = (row: Expense) => {
    return (
      <IconButton
        name="pencil"
        tooltip="View Details & Edit"
        asLink={false}
        modalClick={open}
        onClick={() => handleClick(row)}
      />
    );
  };

  const renderTrashCan = (row: Expense) => {
    return (
      <React.Fragment>
        {!isLocked && (
          <IconButton
            name="trashCan"
            tooltip="Delete expense"
            asLink={false}
            onClick={() => handleDelete(row.expenseId)}
          />
        )}
      </React.Fragment>
    );
  };

  const isReallyLocked = () => {
    return every([
      isLocked,
      !contactHasRoles(loggedInUser, ['financereviewer', 'financeapprover']),
    ]);
  };

  const EXPENSE_HEADERS: TableColumnProps[] = [
    {
      name: 'ID#',
      cell: renderId,
      sortable: true,
      selector: renderId,
      width: '80px',
    },
    {
      name: 'Name',
      cell: renderName,
      sortable: true,
      selector: renderName,
    },
    {
      name: 'Category',
      cell: renderFinancialCategory,
      sortable: true,
      selector: renderFinancialCategory,
    },
    {
      name: 'Budgeted Amount',
      cell: renderBudgetedAmount,
      sortable: true,
      selector: prop('budgetedAmount'),
    },
    {
      name: 'Delete',
      cell: renderTrashCan,
      center: true,
      width: '80px',
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      center: true,
      width: '80px',
    },
  ];

  return (
    <React.Fragment>
      <p>
        Add expenses to your available allocated funds. Please note that all new
        expenses will have a negative number as their ID until they are saved by
        clicking "Save Expenses".
      </p>
      <div className="form__row u-add-margin-top-medium">
        {!isLocked && (
          <FlexGroup>
            <div className="u-flex-between">
              <Button
                text="+ Add an Expense"
                asLink={false}
                className="button--filled"
                modalClick={open}
                onClick={() => {
                  handleClick(emptyExpense());
                }}
              />
            </div>
          </FlexGroup>
        )}
        <FlexGroup>
          <AllocatedFundsRemaing spendingCategory={spendingCategory} />
        </FlexGroup>
      </div>
      <FlexRow>
        <FlexGroup>
          <h2>Primary Expenses</h2>
          <Table
            columns={EXPENSE_HEADERS}
            data={getExpenses({ backup: false })}
          />
        </FlexGroup>
      </FlexRow>
      {!isAdminCategory(spendingCategory) && (
        <FlexRow>
          <FlexGroup>
            <h2>Backup Expenses</h2>
            <Table
              columns={EXPENSE_HEADERS}
              data={getExpenses({ backup: true })}
            />
          </FlexGroup>
        </FlexRow>
      )}
      <Modal {...modalProps}>
        <SecureWrap
          isLocked={isReallyLocked()}
          component={['grantsannualspending']}
        >
          <ExpenseEdit
            isLocked={isLocked}
            close={close}
            expense={expense}
            spendingCategory={spendingCategory}
          />
        </SecureWrap>
      </Modal>
    </React.Fragment>
  );
};
