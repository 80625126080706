import React from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import FilterHotButton from 'components/Common/Filters/FilterHotButton';
import { PerkinsGrantFilterState } from 'types';

type FilterProps = {
  clearPerkinsGrantFilters: Function;
  filterLengths: any;
  filterState: PerkinsGrantFilterState;
  setPerkinsGrantFilterValue: Function;
};

export const ParticipationDashboardFilters: React.FC<FilterProps> = (props) => {
  const {
    clearPerkinsGrantFilters,
    filterLengths,
    filterState,
    setPerkinsGrantFilterValue,
  } = props;

  return (
    <FlexRow>
      <FlexGroup>
        <FilterHotButton
          active={filterState.needsReview === true}
          iconName="clipboard"
          number={filterLengths.needsReview}
          onClick={() => {
            clearPerkinsGrantFilters();
            setPerkinsGrantFilterValue({
              needsReview: filterState.needsReview ? undefined : true,
            });
          }}
          text="Needs Review"
        />
        <FilterHotButton
          active={filterState.needsFinanceReview === true}
          iconName="clipboard"
          number={filterLengths.needsFinanceReview}
          onClick={() => {
            clearPerkinsGrantFilters();
            setPerkinsGrantFilterValue({
              needsFinanceReview: filterState.needsFinanceReview
                ? undefined
                : true,
            });
          }}
          text="Needs Finance Review"
        />
      </FlexGroup>
      <FlexGroup>
        <FilterHotButton
          active={filterState.programLevelId === PROGRAM_LEVEL_ENUM.Secondary}
          iconName="localLibrary"
          number={filterLengths.secondary}
          onClick={() => {
            clearPerkinsGrantFilters();
            setPerkinsGrantFilterValue({
              programLevelId:
                filterState.programLevelId === PROGRAM_LEVEL_ENUM.Secondary
                  ? undefined
                  : PROGRAM_LEVEL_ENUM.Secondary,
            });
          }}
          text="Secondary"
        />
        <FilterHotButton
          active={
            filterState.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary
          }
          iconName="accountBalance"
          number={filterLengths.postsecondary}
          onClick={() => {
            clearPerkinsGrantFilters();
            setPerkinsGrantFilterValue({
              programLevelId:
                filterState.programLevelId === PROGRAM_LEVEL_ENUM.Postsecondary
                  ? undefined
                  : PROGRAM_LEVEL_ENUM.Postsecondary,
            });
          }}
          text="Postsecondary"
        />
      </FlexGroup>
    </FlexRow>
  );
};
