import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PosReview } from './PosReview';
import { isApplicaionIdValid } from 'util/global';
import { FlexRow, FlexGroup } from 'layouts';
import { BorderedTitle } from 'components/Common/BorderedTitle';
import { ProgramOfStudy } from 'types';
import { UniversalContact } from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { CTSO_Type } from 'types';
import Icon from 'components/Common/Icons';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import PostsecondaryInformation from './PostsecondaryInformation';
import ReactToPrint from 'react-to-print';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecondaryInformation from './SecondaryInformation';

export type ProgramOfStudyOverviewProps = {
  programOfStudy: ProgramOfStudy;
  loadProgramOfStudyData: Function;
  secondaryUser: UniversalContact;
  postsecondaryUser: UniversalContact;
  ctsoCodes: CTSO_Type[];
  isFetching: boolean;
};
export type ProgramOfStudySubProps = {
  programOfStudy: ProgramOfStudy;
  ctsoCodes: CTSO_Type[];
};

export const ProgramOfStudyOverviewComponent: React.FC<
  ProgramOfStudyOverviewProps
> = (props) => {
  const {
    programOfStudy,
    loadProgramOfStudyData,
    ctsoCodes,
    isFetching = true,
  } = props;
  const { programInstanceId }: any = useParams();

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramOfStudyData(Number(programInstanceId));
    }
  }, [loadProgramOfStudyData, programInstanceId]);
  const printArea = useRef<HTMLDivElement>(null);
  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading Program of study" />
      <ScrollToTop />
      <div ref={printArea}>
        <Paper>
          <FlexRow>
            <FlexGroup>
              <h2 className="program__title">Program of Study</h2>
              <h2>Secondary and Postsecondary Partnership</h2>
            </FlexGroup>
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="no-print u-pseudo-link">
                    <Icon
                      title="Print"
                      name="print"
                      className="history__print__icon"
                    />
                  </div>
                );
              }}
              content={() => {
                const parea = printArea as React.MutableRefObject<
                  HTMLInputElement
                >;
                return parea.current;
              }}
            />
          </FlexRow>

          <FlexRow>
            <BorderedTitle
              primaryText={programOfStudy.secondaryProgram.programName}
              secondaryText={
                programOfStudy.secondarySchool
                  ? programOfStudy.secondarySchool.schoolName
                  : EMPTY_PLACEHOLDER
              }
            />
            <div style={{ padding: '0 .8rem' }} />
            <BorderedTitle
              primaryText={programOfStudy.postSecondaryProgram.programName}
              secondaryText={
                programOfStudy.postsecondarySchool
                  ? programOfStudy.postsecondarySchool.schoolName
                  : EMPTY_PLACEHOLDER
              }
            />
          </FlexRow>
          <FlexRow>
            <h2>Possible Careers Related to this Pathway</h2>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <label className="form__label">
                Occupations Students Will Be Prepared For Upon Program
                Completion
              </label>
              <FlexRow>
                {programOfStudy.secondaryProgram
                  .occupationInfoProgramCompletion && (
                  <React.Fragment>
                    <p>
                      {
                        programOfStudy.secondaryProgram
                          .occupationInfoProgramCompletion
                      }
                    </p>
                  </React.Fragment>
                )}
              </FlexRow>

              <FlexRow>
                <p>
                  {
                    programOfStudy.postSecondaryProgram
                      .occupationInfoProgramCompletion
                  }
                </p>
              </FlexRow>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <label className="form__label">
                Employment Demand Information
              </label>
              {programOfStudy.secondaryProgram.employmentDemandInformation && (
                <React.Fragment>
                  <p>
                    {
                      programOfStudy.secondaryProgram
                        .employmentDemandInformation
                    }
                  </p>
                  <br />
                </React.Fragment>
              )}

              <p>
                {
                  programOfStudy.postSecondaryProgram
                    .employmentDemandInformation
                }
              </p>
            </FlexGroup>
          </FlexRow>
        </Paper>

        <SecondaryInformation
          programOfStudy={programOfStudy}
          ctsoCodes={ctsoCodes}
        />
        <PostsecondaryInformation
          programOfStudy={programOfStudy}
          ctsoCodes={ctsoCodes}
        />
        <PosReview {...props} />
      </div>
    </React.Fragment>
  );
};
