import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import OverviewStepsSelections from 'components/Grants/Applications/OverviewStepsSelections';
import GrantsLayout from 'layouts/GrantsLayout';

export const GrantApplicationOverviewPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <OverviewStepsSelections />
      </GrantsLayout>
    </BaseLayout>
  );
};
