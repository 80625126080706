import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { compose, prop, renderData } from 'util/objectUtility';
import { FlexRow, FlexGroup } from 'layouts';
import { EmailTemplateBase, FiscalYear } from 'types';
import { startCase } from 'lodash';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import HotButton from 'components/Common/HotButton';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Table from 'components/Common/Table';

type EmailsDashboardProps = {
  filters: number;
  emailTemplates: EmailTemplateBase[];
  isFetching: boolean;
  loadEmailTemplateSummaries: Function;
  currentFiscalYear: FiscalYear;
};

export const EmailsDashboardComponent: React.FC<EmailsDashboardProps> = (
  props
) => {
  const {
    emailTemplates,
    isFetching,
    loadEmailTemplateSummaries,
    currentFiscalYear,
  } = props;
  const { year: currentFy } = currentFiscalYear;

  // -- dependencies --
  const history = useHistory();
  const { grantFiscalYear } = useParams<any>();
  const fiscalYear = Number(grantFiscalYear);

  // -- local state --
  const [filteredData, setFilteredData] = useState<EmailTemplateBase[]>([
    ...emailTemplates,
  ]);

  // -- component logic --
  /*
   *   handleClick :: EmailTemplateBase -> void
   */
  const handleClick = (row: EmailTemplateBase) => {
    history.push(
      `/grants/email-management/email-template/${row.fiscalYear}/${
        row.templateId
      }`
    );
  };

  /*
   *   isActive :: number -> bool
   */
  const isActive = (year: number) => fiscalYear === year;

  /*
   *   navigate :: number -> void
   */
  const navigate = (year: number) => {
    history.push(`/grants/email-management/${year}`);
  };

  // -- lifecycle --
  useEffect(() => {
    setFilteredData([...emailTemplates]);
  }, [emailTemplates]);

  useEffect(() => {
    loadEmailTemplateSummaries(fiscalYear);
  }, [loadEmailTemplateSummaries, fiscalYear]);

  // -- display logic --
  const renderEditIcon = (row: EmailTemplateBase) => {
    return (
      <React.Fragment>
        <IconButton
          name="pencil"
          tooltip="Edit Email Template Details"
          asLink={false}
          onClick={() => handleClick(row)}
        />
      </React.Fragment>
    );
  };

  const renderCircle = (bool: boolean) => {
    return (
      <React.Fragment>
        {bool ? (
          <Icon
            name="circleFilled"
            className={'consortium-view__status-icon u-icon-green'}
          />
        ) : (
          <Icon
            name="circleOpen"
            className={'consortium-view__status-icon u-icon-green'}
          />
        )}
      </React.Fragment>
    );
  };

  /*
   *   Name :: EmailTemplateBase -> string
   */
  const Name = compose(
    renderData,
    startCase,
    prop('templateName')
  );
  /*
   *   Year :: EmailTemplateBase -> string
   */
  const Year = compose(
    renderData,
    prop('fiscalYear')
  );
  /*
   *   Sent :: EmailTemplateBase -> string
   */
  const Sent = compose(
    renderCircle,
    prop('emailWasSent')
  );

  const EMAIL_HEADERS: TableColumnProps[] = [
    {
      name: 'Name of Email Template',
      cell: Name,
      selector: Name,
      sortable: true,
    },
    {
      name: 'Fiscal Year',
      cell: Year,
      selector: prop('fiscalYear'),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Sent',
      cell: Sent,
      selector: prop('emailWasSent'),
      sortable: true,
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      center: true,
      ignoreRowClick: true,
      sortable: false,
      width: '8%',
    },
  ];

  return (
    <React.Fragment>
      <Loading isActive={isFetching} messageBefore="Loading Email Templates" />
      <ScrollToTop />
      <SecureWrap allowReadOnly={true} component="GrantsEmailManagement">
        <Paper>
          <FlexRow>
            <h2 className="program__title">Manage Email Templates</h2>
          </FlexRow>
          <Instruction title="" hideTitle>
            <p>
              This page contains email templates used to communicate with site
              administrators about the status of their Grant Applications. Begin
              by selecting the Fiscal Year you wish to manage. You may check the
              status of the communication using the “Sent” indicator. Click on
              the edit icon to view the template, manage recipients, and send an
              email.
            </p>
            <br />
            <p>
              <span className="u-color-red">Note</span>: You may resend an email
              even if the dashboard shows that the communication has been sent.
              Vouchers are labeled as “(auto sent)” because the email is
              generated once an award packet has been approved. You may enter
              the edit mode to resend this email to other people, as needed.
            </p>
          </Instruction>
        </Paper>
        <Paper>
          <FlexRow>
            <h2 className="u-section-title-text">Select Fiscal Year</h2>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <HotButton
                iconName="envelope"
                text={`${currentFy - 2} - ${currentFy - 1}`}
                active={isActive(currentFy - 1)}
                onClick={() => navigate(currentFy - 1)}
              />
            </FlexGroup>
            <FlexGroup>
              <HotButton
                iconName="envelope"
                text={`${currentFy - 1} - ${currentFy}`}
                active={isActive(currentFy)}
                onClick={() => navigate(currentFy)}
              />
            </FlexGroup>
            <FlexGroup>
              <HotButton
                iconName="envelope"
                text={`${currentFy} - ${currentFy + 1}`}
                active={isActive(currentFy + 1)}
                onClick={() => navigate(currentFy + 1)}
              />
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <Table columns={EMAIL_HEADERS} data={filteredData} />
            </FlexGroup>
          </FlexRow>
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
