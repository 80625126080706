import React from 'react';
import Icon from 'components/Common/Icons';
import { map } from 'lodash';

export type LandingPageCardProps = {
  title: string;
  iconType: string;
  buttons: JSX.Element[];
};

export const LandingPageCard: React.FC<LandingPageCardProps> = (props) => {
  const { title, iconType, buttons } = props;
  return (
    <div className="landing-page-card">
      <Icon name={iconType} className="landing-page-card__icon" />
      <h2 className="landing-page-card__title">{title}</h2>
      {map(buttons, (element: JSX.Element, index: number) => {
        return (
          <div className="landing-page-card__item" key={index}>
            {element}
          </div>
        );
      })}
    </div>
  );
};
