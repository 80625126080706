import React, { useEffect } from 'react';
import { ProgramAward, AwardType } from 'types';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { compose, renderData, prop, isTruthy } from 'util/objectUtility';
import { isApplicaionIdValid } from 'util/global';
import { find } from 'lodash';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import DatatableLoading from 'components/Common/DatatableLoading';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import Table from 'components/Common/Table';

type ProgramAwardSummaryProps = {
  programInstanceId: number;
  programAwards: ProgramAward[];
  awardTypes: AwardType[];
  isFetching: boolean;
  loadProgramAwards: Function;
  setCurrentNavAnchor: Function;
};

export const ProgramAwardSummaryComponent: React.FC<
  ProgramAwardSummaryProps
> = (props) => {
  const {
    programInstanceId,
    programAwards,
    awardTypes,
    isFetching,
    loadProgramAwards,
    setCurrentNavAnchor,
  } = props;

  useEffect(() => {
    if (!isApplicaionIdValid(programInstanceId)) return;
    loadProgramAwards(programInstanceId);
  }, [loadProgramAwards, programInstanceId]);

  const getAwardType = (awardTypeId: number): string => {
    const awardType = find(awardTypes, (type: AwardType) => {
      return type.awardTypeId === awardTypeId;
    });
    if (awardType) {
      return awardType.awardName;
    }
    return '';
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const CertOrDegreeTitle = compose(
    renderData,
    prop('certOrDegreeTitle')
  );
  const AwardType = compose(
    renderData,
    getAwardType,
    prop('awardTypeId')
  );
  const ContactHours = compose(
    renderData,
    prop('contactHours')
  );
  const TotalCredits = compose(
    renderData,
    prop('credits')
  );
  const TotalWeeks = compose(
    renderData,
    prop('totalWeeks')
  );
  const BannerCode = compose(
    renderCircle,
    isTruthy,
    prop('bannerCode')
  );

  const FinancialAidApproved = compose(
    renderCircle,
    isTruthy,
    prop('financialAidApproved')
  );

  const awardHeaders: TableColumnProps[] = [
    {
      name: 'Award Titles',
      cell: CertOrDegreeTitle,
      sortable: true,
      selector: CertOrDegreeTitle,
      width: '30%',
    },
    {
      name: 'Award Type',
      cell: AwardType,
      width: '10%',
    },
    {
      name: 'Contact Hours',
      cell: ContactHours,
      sortable: true,
      selector: ContactHours,
      center: true,
    },
    {
      name: 'Total Credits',
      cell: TotalCredits,
      sortable: true,
      selector: TotalCredits,
      center: true,
    },
    {
      name: 'Total Weeks',
      cell: TotalWeeks,
      sortable: true,
      selector: TotalWeeks,
      center: true,
    },
    {
      name: 'Banner Code',
      cell: BannerCode,
      center: true,
    },
    {
      name: 'Financial Aid',
      cell: FinancialAidApproved,
      center: true,
    },
  ];

  return (
    <Paper>
      <SummaryScroll componentStep="awards" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('awards')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      {isFetching ? (
        <DatatableLoading loading={true} />
      ) : (
        <Table data={programAwards} columns={awardHeaders} />
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('awards')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </Paper>
  );
};
