import React, { useEffect } from 'react';
import { WorkflowSummaryComponent } from 'components/Common/GeneralWorkflow/WorkflowSummary.component';
import { AnnualSpendingPlanValidations } from 'validations/AnnualSpendingPlan/AnnualSpendingPlan.validations';
import { AnnualSpending } from 'types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { loadAnnualSpendingPlan } from 'redux/grants/annualspendingplan/annualspendingplan.actions';

type ASPWorkflowProps = {
  applicationId: number;
  disableSubmit: boolean;
  isFetching: boolean;
  isReadOnly: boolean;
  optionalAction: any;
  setCurrentNavAnchor: Function;
  spendingPlan: AnnualSpending;
  submitApplicationUpdate: Function;
  user: any;
  workflow: any;
};

export const ASPWorkflowComponent: React.FC<ASPWorkflowProps> = (props) => {
  const { spendingPlan } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { validateCustom, validationErrors } = AnnualSpendingPlanValidations();
  const dispatch = useDispatch();
  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    dispatch(loadAnnualSpendingPlan(grantFiscalYearId));
  }, [grantFiscalYearId]); // eslint-disable-line

  useEffect(() => {
    validateCustom([
      { key: 'indirectAdminCosts', value: spendingPlan },
      { key: 'directAdminCosts', value: spendingPlan },
      { key: 'fundsRemaining', value: spendingPlan },
      { key: 'allocatedRemaining', value: spendingPlan },
      { key: 'improvementPlanFunds', value: spendingPlan },
    ]);
  }, [spendingPlan, grantFiscalYearId]); //eslint-disable-line

  // --[ display logic ]-------------------------------------------------------
  const wrap = {
    ...props,
    validationErrors,
  };
  return <WorkflowSummaryComponent {...wrap} />;
};
