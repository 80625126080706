import { connect } from 'react-redux';
import { saveCTAAssurances } from 'redux/cta/ctadashboard';
import { AssurancesComponent } from './Assurances.component';

const dispatchProps = {
  saveCTAAssurances,
};

export default connect(
  null,
  dispatchProps
)(AssurancesComponent);
