import { connect } from 'react-redux';
import {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
} from 'redux/programs/programinstance';
import {
  getProgram,
  getIsDataFetching_ProgramInstance,
  getIsProgramReadOnly,
} from 'redux/programs/_selectors';
import { GeneralEditComponent } from 'components/Program/ProgramGeneral/GeneralEdit.component';
import { GeneralSummaryComponent } from 'components/Program/ProgramGeneral/GeneralSummary.component';
import {
  getActiveProgramId,
  getCurrentNavAnchor,
} from 'redux/application/selectors';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getRecords_CareerCluster,
  getRecords_ProgramLengths,
  getRecords_ProgramArea,
  getNextStep,
  CIP_CODES_PATH,
} from 'redux/common/_selectors';
import { getAdvancedFilteredRecords } from 'redux/utility/_exports';
import { getReduxFilter } from 'util/filterUtility';
import { firstOrDefault } from 'util/arrayUtility';
import { CipCode, emptyCipCode } from 'types';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId: number = getActiveProgramId(state);
  const program = getProgram(state, programInstanceId);
  const isProgramDataFetching = getIsDataFetching_ProgramInstance(state);
  const careerClusters = getRecords_CareerCluster(state);
  const programLengths = getRecords_ProgramLengths(state);
  const programAreas = getRecords_ProgramArea(state);
  const currentNavAnchor = getCurrentNavAnchor(state);
  const nextProgramStep = getNextStep(state, currentNavAnchor);
  const readOnly = getIsProgramReadOnly(state, programInstanceId);
  const filter = getReduxFilter('cipId', [program.cipId, '8675309'], 'and');
  const cipCode = firstOrDefault(
    getAdvancedFilteredRecords<CipCode>(state, CIP_CODES_PATH, filter),
    emptyCipCode()
  );

  return {
    currentNavAnchor,
    careerClusters,
    isProgramDataFetching,
    nextProgramStep,
    program,
    programAreas,
    programInstanceId,
    programLengths,
    readOnly,
    cipCode,
  };
};

const dispatchToProps = {
  saveProgramInstanceAction,
  loadSingleProgramInstance,
  setCurrentNavAnchor,
};

export const GeneralEdit = connect(
  mapStateToProps,
  dispatchToProps
)(GeneralEditComponent);

export const GeneralSummary = connect(
  mapStateToProps,
  dispatchToProps
)(GeneralSummaryComponent);
