import { ComponentStep } from 'types/ui/ComponentStep.types';
import { filter } from 'lodash';

/**
 * Check if an application is closed and unable to be edited yet
 * @param stepName string name of the anchor in the nav object
 * @param steps ComponentStep[] list of ComponentSteps from API
 */
export const isStatusLocked = (stepName: string, steps: ComponentStep[]) => {
  const [currStep] = filter(steps, (step: ComponentStep) => {
    return step.nav.anchor === stepName;
  });
  if (currStep) {
    return currStep.nav.status !== 'open';
  }
  return false;
};
