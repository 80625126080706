import React, { useEffect } from 'react';
import ScrollToTop from 'components/Common/ScrollToTop';
import WorkflowSteps from './WorkflowSteps';

export type WorkflowPageProps = {
  programInstanceId: number;
  loadWorkFlow: Function;
};

export const WorkflowEditComponent: React.FC<WorkflowPageProps> = (props) => {
  const { programInstanceId, loadWorkFlow } = props;

  useEffect(() => {
    loadWorkFlow('programs', programInstanceId);
  }, [programInstanceId, loadWorkFlow]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <WorkflowSteps />
    </React.Fragment>
  );
};
