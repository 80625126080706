import React, { useState } from 'react';
import { Email, UniversalContact } from 'types';
import { filter, map } from 'lodash';
import { FlexGroup, FlexRow } from 'layouts';
import { validateEmail } from 'util/formHelpers';
import Button from 'components/Common/FormElements/Button';
import Input from 'components/Common/FormElements/Input';
import Icon from 'components/Common/Icons';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';

type EmailFormProps = {
  checkIfEmailExists: Function;
  emailExists: UniversalContact | undefined;
  emails: Email[];
  duplicateEmail: string;
  onChange: Function;
  validations: ValidationObject<UniversalContact>;
};

export const DynamicEmailForm: React.FC<EmailFormProps> = (props) => {
  const {
    checkIfEmailExists,
    emails,
    duplicateEmail,
    onChange,
    validations,
  } = props;

  const { getError } = validations;

  const updateEmail = (email: Email, index: number) => {
    validateEmailIfTrue(email, index);
    const universalContactEmail = map(emails, (storedEmail, idx) => {
      return index === idx ? email : storedEmail;
    });
    onChange(
      { universalContactEmail },
      'universalContactEmail',
      universalContactEmail
    );
  };

  const deleteEmail = (index: number) => {
    const universalContactEmail = filter(emails, (stored, idx) => {
      return index !== idx;
    });
    onChange(
      { universalContactEmail },
      'universalContactEmail',
      universalContactEmail
    );
  };

  const validateAllEmails = () => {
    return map(emails, (email: Email) => {
      if (email.email.length === 0) return true;
      return validateEmail(email.email);
    });
  };

  const validateEmailIfTrue = (email: Email, index: number) => {
    if (validateEmail(email.email)) {
      const update = map(emailValid, (val, idx) => {
        return index === idx ? true : val;
      });
      setEmailValid([...update]);
    }
  };

  const validateSingleEmail = (email: Email, index: number) => {
    return emailValid[index] && isNotDuplicate(email.email, index);
  };

  const isNotDuplicate = (email: string, index: number) => {
    if (email.length > 0) {
      return emails[index].email !== duplicateEmail;
    }
    return true;
  };

  const [emailValid, setEmailValid] = useState<boolean[]>(validateAllEmails());

  return (
    <FlexGroup>
      <div>
        {map(emails, (email: Email, index: number) => {
          return (
            <FlexRow key={index}>
              <FlexGroup>
                <Input
                  errorMessage={getError('universalContactEmail')}
                  id={`Email ${index > 0 ? index + 1 : ''}`}
                  label={`Email ${index > 0 ? index + 1 : ''}`}
                  name="email"
                  onBlur={() => {
                    setEmailValid(validateAllEmails());
                    if (validateEmail(email.email)) {
                      checkIfEmailExists(email.email);
                    }
                  }}
                  onChange={(email: Email) => {
                    updateEmail(email, index);
                  }}
                  trim
                  value={email.email}
                  valid={validateSingleEmail(email, index)}
                />
                {index !== 0 && (
                  <div
                    className="form__delete-btn"
                    onClick={() => deleteEmail(index)}
                  >
                    <Icon
                      name="crossCircle"
                      title={'close'}
                      className={'form__delete-btn--svg'}
                    />
                  </div>
                )}
              </FlexGroup>
            </FlexRow>
          );
        })}
        <FlexRow>
          <Button
            text="+ Add another email"
            className="form__btn--add u-reduce-margin-top"
            onClick={() => updateEmail({ email: '' }, emails.length++)}
          />
        </FlexRow>
      </div>
    </FlexGroup>
  );
};
