import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { FerpaAgreement } from 'types';

export const FERPA_AGREEMENT_ENDPOINT = 'api/ferpa/';

export const readFerpaAgreement = async (): Promise<FerpaAgreement> => {
  const url = `${ENVIRONMENT.API_URL}${FERPA_AGREEMENT_ENDPOINT}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('FERPA-Agreement', err);
    });
};

export const updateFerpaAgreement = async (agreement: FerpaAgreement) => {
  const url = `${ENVIRONMENT.API_URL}${FERPA_AGREEMENT_ENDPOINT}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(agreement),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('FERPA-Agreement', err);
    });
};
