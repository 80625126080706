import React from 'react';
import { ItemFilter } from 'types';

type DataFilterProps = {
  path: string[];
  itemFilter: ItemFilter;
  data: any[];
};

/// TODO: Rip this out
export const DataFilter: React.FC<DataFilterProps> = (props) => {
  const { children } = props;
  const theChildren = React.Children.map(children, (child: any) => {
    return React.cloneElement(child, props);
  });
  return <React.Fragment>{theChildren}</React.Fragment>;
};
