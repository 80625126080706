import React from 'react';
import Badge from 'components/Common/Badge';
import IconButton from 'components/Common/IconButton';

type FilterIconButtonProps = {
  tooltip?: string;
  onClick?: Function;
  className?: string;
  count: number;
};

export const FilterIconButton: React.FC<FilterIconButtonProps> = (props) => {
  const { className, onClick, tooltip, count } = props;

  return (
    <div className={className ? className : 'search-bar-filter__button'}>
      <Badge label={count} show={!!count}>
        <IconButton
          name="tune"
          tooltip={tooltip ? tooltip : 'Show Filters'}
          onClick={onClick && onClick}
        />
      </Badge>
    </div>
  );
};
