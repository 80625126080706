import { some } from 'lodash';
import { connect } from 'react-redux';
import { PERMISSION_COMPONENT_PATH } from 'redux/permissions/permissioncomponents.selectors';
import { ROLE_COMPONENT_PATH } from 'redux/permissions/permissionrolecomponent.selector';
import { loadPermissionRoles } from 'redux/permissions/permissionroles';
import {
  getRecords_PermissionRoles,
  PERMISSION_ROLE_PATH,
} from 'redux/permissions/permissionroles.selectors';
import { PERMISSION_TYPES_PATH } from 'redux/permissions/permissiontype.selectors';
import { getIsDataFetching } from 'redux/utility/_exports';
import { PermissionRole } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { PermissionRoleManagement } from './PermissionRoleManagement.component';
import { removePermissionRole } from 'redux/permissions/permissionroles';

export const mapStateToProps = (state: ApplicationState) => {
  const roles: PermissionRole[] = getRecords_PermissionRoles(state);
  const isFetching: boolean = some([
    getIsDataFetching(state, PERMISSION_ROLE_PATH),
    getIsDataFetching(state, ROLE_COMPONENT_PATH),
    getIsDataFetching(state, PERMISSION_COMPONENT_PATH),
    getIsDataFetching(state, PERMISSION_TYPES_PATH),
  ]);

  return {
    roles,
    isFetching,
  };
};

const dispatchProps = { loadPermissionRoles, removePermissionRole };

export default connect(
  mapStateToProps,
  dispatchProps
)(PermissionRoleManagement);
