import { connect } from 'react-redux';
import { BooksAndSuppliesComponent } from './BooksAndSupplies.component';
import { loadCTABooksSchool } from 'redux/cta/ctabooksschool';
import { getRecords_CTABooksSchool } from 'redux/cta/ctabooksschool.selectors';

const mapStateToProps = (state: any) => {
  const booksSchools = getRecords_CTABooksSchool(state);
  return {
    booksSchools,
  };
};

const dispatchProps = {
  loadCTABooksSchool,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(BooksAndSuppliesComponent);
