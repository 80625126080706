import React, { useState, useEffect, useRef } from 'react';
import { FlexRow, FlexGroup } from 'layouts';
import { map } from 'lodash';
import { Dialog } from '@rmwc/dialog';
import { useModal, Modal } from 'react-morphing-modal';
import { downloadFileFromAzure } from 'services/azure/azure.services';
import { AzureUploadParameters } from 'types';
import {
  readTeacherTemplate,
  createTeacherRecords,
} from 'services/datacollection/excelmanagement.services';
import { toast } from 'react-toastify';
import Button from 'components/Common/FormElements/Button';
import DatatableLoading from 'components/Common/DatatableLoading';
import download from 'js-file-download';
import FileDropZone from 'components/Common/FormElements/FileDropZone/FileDropZone';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

export const TeacherUploadComponent: React.FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { modalProps, open, close } = useModal();
  const modalRef = useRef(null);

  useEffect(() => {
    if (errors.length > 0) {
      open(modalRef);
    }
  }, [errors]); //eslint-disable-line

  const uploadFile = async (file: File) => {
    setErrors([]);
    setUploading(true);
    createTeacherRecords(file)
      .then((response: string[]) => {
        setErrors(response);
        if (response.length === 0) {
          toast(
            <ToastNotification
              status="success"
              message="Teacher record uploaded successfully."
            />
          );
        }
      })
      .catch(() => setErrors([StringResources.Errors.FileDownloadFailed]))
      .finally(() => {
        setUploading(false);
        setOpen(false);
      });
  };

  const uploadParams: AzureUploadParameters = {
    container: 'datacollection',
    folder: '',
  };

  const downloadTemplate = () => {
    readTeacherTemplate(true)
      .then((blob: unknown) => {
        download(blob as Blob, `teacher_enrollment_template.xlsx`);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={StringResources.Errors.FileDownloadFailed}
          />
        );
      });
  };

  return (
    <React.Fragment>
      <div ref={modalRef}>
        <Paper>
          <Instruction title="Uploading Active Teachers">
            <p className="u-margin-bottom">
              Use this section to enter records for active teachers. Click the
              ‘Download the Template’ button to download an Excel file
              containing the names of teachers for whom you are required to
              report. Click on the ‘Get More Guidance’ button to access detailed
              instructions on how to enter your data.
            </p>
            <p className="u-margin-bottom">
              When you have completed entering your teacher record data save the
              file to your computer. Click on the ‘Upload Active Teacher File’
              button and follow the instructions provided. Note that information
              on teachers new to the system will be automatically added to
              Universal Contacts.
            </p>
            <p className="u-error">
              Note: Due to a high volume of data, your upload will appear the
              following day.
            </p>
          </Instruction>

          <FlexRow>
            <FlexRow>
              <FlexGroup>
                <Button
                  className="form__btn--cancel"
                  onClick={() => {
                    downloadFileFromAzure(
                      uploadParams,
                      'guidance|teacher_guidance.docx'
                    ).catch(() => {
                      toast(
                        <ToastNotification
                          status="error"
                          message={StringResources.Errors.FileDownloadFailed}
                        />
                      );
                    });
                  }}
                  text={'Get More Guidance'}
                />
              </FlexGroup>
              <FlexGroup>
                <Button
                  className="form__btn--cancel"
                  onClick={() => {
                    downloadTemplate();
                  }}
                  text={'Download the Template'}
                />
              </FlexGroup>
            </FlexRow>

            <FlexGroup>
              <Button
                style={{ marginLeft: '1.8rem', height: '6rem' }}
                className="form__btn--add grant-schedule__upload-btn"
                onClick={() => {
                  setOpen(!isOpen);
                }}
                text="+ Upload Active Teachers File"
              />
            </FlexGroup>
          </FlexRow>

          <Dialog
            preventOutsideDismiss
            open={isOpen}
            onClose={() => {
              setOpen(false);
            }}
          >
            <div className="azure-dialog">
              <div className="azure-dialog__title form__section-title u-flex u-flex-full-center">
                <div className="u-flex-grow-1">Upload files</div>
                {!uploading && (
                  <IconButton
                    name="cross"
                    tooltip="Exit"
                    onClick={() => setOpen(false)}
                  />
                )}
              </div>
              <div style={{ minWidth: '64rem' }}>
                {uploading ? (
                  <DatatableLoading
                    loading={uploading}
                    messageBefore="Uploading File..."
                  />
                ) : (
                  <FileDropZone
                    onUpload={(incomingFile: File) => {
                      uploadFile(incomingFile);
                    }}
                  />
                )}
              </div>
            </div>
          </Dialog>
        </Paper>
      </div>
      <Modal {...modalProps}>
        <Paper>
          <h2>File Upload Encountered Errors</h2>
          <div>
            <p>The following errors were encountered during file upload:</p>
            {map(errors, (error: string) => {
              return <p className="u-error">{error}</p>;
            })}
          </div>
          <div className="form__btn-container">
            <Button
              onClick={close}
              className="form__btn--cancel"
              text="Close Error Window"
            />
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
