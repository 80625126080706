import { ENVIRONMENT } from 'constants/application.config';
import { getWithAuthOptions, toJSON, postWithAuthOptions, catchError } from 'services';
import { ProgramAward } from 'types';

const ENDPOINT = 'api/ProgramAwards/byProgramInstanceId/';

// TODO: add JSDoc
export const createProgramAwards = async (
  awards: ProgramAward[],
  programInstanceId: number
) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;

  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(awards),
  })
    .then(toJSON)
    .then((data: any) => {
      return {
        ...data,
        programInstanceId,
      };
    })
    .catch((err) => {
      return catchError('ProgramAwards', err);
    });
};

// TODO: add JSDoc
export const readProgramAwards = async (programInstanceId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${programInstanceId}`;
  return fetch(url, { ...getWithAuthOptions() })
    .then(toJSON)
    .then((records: ProgramAward[]) => {
      return {
        records,
        programInstanceId,
      };
    })
    .catch((err) => {
      return catchError('ProgramAwards', err);
    });
};
