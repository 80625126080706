import React from 'react';
import Icon from '../Icons';
import { insertClass } from 'util/formHelpers';

type HotButtonProps = {
  text: string;
  iconName: string;
  active?: boolean;
  onClick?: Function;
  className?: string;
};

export const HotButton: React.FC<HotButtonProps> = (props) => {
  const { active = false, iconName = 'tune', onClick, text, className } = props;

  const containerClass = active
    ? 'hot-button hot-button--active'
    : 'hot-button';

  return (
    <button
      className={`${containerClass} ${insertClass(className)}`}
      onClick={() => onClick && onClick()}
    >
      <div className="hot-button__row">
        <div className="hot-button__icon-parent">
          <Icon className="hot-button__icon" name={iconName} />
        </div>
      </div>
      <p className="hot-button__text">{text}</p>
    </button>
  );
};
