import React from 'react';
import { BorderedTitle } from 'components/Common/BorderedTitle';
import { IntentToParticipateMember, Consortium } from 'types';
import {
  PARTICIPATION_TYPE,
  PARTICIPATION_TYPE_ENUM,
} from 'constants/participation.enum';
import { find } from 'lodash';

type MemberParticipationSummaryProps = {
  member: IntentToParticipateMember;
  consoria: Consortium[];
};

export const MemberParticipationSummary: React.FC<
  MemberParticipationSummaryProps
> = (props) => {
  const { consoria, member } = props;

  const getSecondaryText = (
    participationStatus: number,
    consoriaId: number
  ) => {
    if (participationStatus === PARTICIPATION_TYPE_ENUM.CONSORTIUM) {
      const consorium = find(consoria, (item) => {
        return item.consortiumId === consoriaId;
      });
      return `${PARTICIPATION_TYPE[String(participationStatus)]}: ${
        consorium ? consorium.consortiumName : ''
      }`;
    }
    return PARTICIPATION_TYPE[String(participationStatus)];
  };

  return (
    <div className="u-flex">
      <BorderedTitle
        primaryText={'Next Fiscal Year Participation Status'}
        secondaryText={getSecondaryText(
          member.currentParticipationStatus,
          member.consortiumId
        )}
      />
      <div style={{ padding: '0 .8rem' }} />
      <BorderedTitle
        primaryText={'Current Fiscal Year Participation Status'}
        secondaryText={getSecondaryText(
          member.previousParticipationStatus,
          member.previousConsortiumId
        )}
      />
    </div>
  );
};
