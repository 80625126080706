import { useValidation } from 'de-formed-validations';
import { DistrictSchoolSignature } from 'types';
import { SignatureComponentValidations } from 'validations/SignatureComponent.validations';
import { isEmptySignature } from 'util/formHelpers';

// type alias
type S = DistrictSchoolSignature;

// define validation schema for validation hook
type ValidationState = {
  canSave: S[];
  canSubmit: S[];
};

export const GrantRecipientSignaturesValidations = () => {
  const { validateAll } = SignatureComponentValidations();

  return useValidation<ValidationState>({
    canSave: [
      {
        errorMessage: "'Typed Signature' and 'Title' are required.",
        validation: (signatures: S[], _) => {
          return signatures.reduce((prev: boolean, curr: S) => {
            if (!prev) return prev;
            return curr.isDirty ? validateAll(curr) : true;
          }, true);
        },
      },
    ],
    canSubmit: [
      {
        errorMessage:
          'Not all required Grant Recipient Agreement signatures have been provided.',
        validation: (signatures: S[], _) => {
          return signatures.reduce((prev: boolean, curr: S) => {
            if (!prev) return prev;
            return curr.required ? !isEmptySignature(curr) : true;
          }, true);
        },
      },
    ],
  });
};
