import { PERMISSIONS_KEY, PERMISSIONS_ROLES_KEY } from 'redux/keys';
import { ItemFilter, PermissionRole } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
  getFilteredRecords,
} from 'redux/utility/baseSelectors';

export const PERMISSION_ROLE_PATH = [PERMISSIONS_KEY, PERMISSIONS_ROLES_KEY];

export const getIsDataFetching_PermissionRoles = (state: any): boolean => {
  return getIsDataFetching(state, PERMISSION_ROLE_PATH);
};

export const getLastUpdated_PermissionRoles = (state: any): number => {
  return getLastUpdated(state, PERMISSION_ROLE_PATH);
};

export const getRecords_PermissionRoles = (state: any): PermissionRole[] => {
  return getRecords<PermissionRole>(state, PERMISSION_ROLE_PATH);
};

export const shouldRefreshReduxState_PermissionRoles = (
  state: any
): boolean => {
  return shouldRefreshReduxState<PermissionRole>(state, PERMISSION_ROLE_PATH);
};

export const getFilteredRecords_PermissionRoles = (
  state: any,
  itemFilter: ItemFilter
) => {
  return getFilteredRecords<PermissionRole>(
    state,
    PERMISSION_ROLE_PATH,
    itemFilter
  );
};
