import { CurrentStatus } from 'components/Grants/Applications/CurrentStatus/CurrentStatus.component';
import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import {
  getRecords_GrantOverview,
  getRecords_GrantVoucherOverviewStatus,
} from 'redux/nav/_selectors';
import { WorkflowStatus } from 'types/grant/WorkflowStatus.types';
import { getDate, compose, prop, either } from 'util/objectUtility';
import { loadGrantVoucherOverviewStatus } from 'redux/nav/voucheroverviewstatus';
import {
  getCurrentWorkflowStep,
  isVoucherStep,
  updateLockedStatus,
} from 'util/grantCurrentStatus.utility';
import { ComponentStep } from 'types';

export const mapStateToProps = (state: any) => {
  //  getAnchor :: State -> ComponentStep[]
  const getProgressSteps = either(
    compose(
      prop('steps'),
      getRecords_GrantOverview
    ),
    () => []
  );
  //  getAnchor :: ComponentStep -> string
  const getAnchor = compose(
    prop('anchor'),
    prop('nav')
  );

  const steps = getProgressSteps(state);
  const voucherStatus = getRecords_GrantVoucherOverviewStatus(state);

  // getCurrentStatus :: ComponentStep[] ComponentStep -> WorkflowStatus
  const getCurrentStatus = (prev: ComponentStep[], step: ComponentStep) => {
    // getLastModifiedBy :: State -> string
    const getLastModifiedBy = compose(
      either(
        compose(
          prop('name'),
          prop('modifiedBy')
        ),
        () => null
      ),
      getCurrentWorkflowStep(getAnchor(step))
    );

    // getUpdated :: State -> string
    const getUpdated = compose(
      getDate,
      prop('date'),
      getCurrentWorkflowStep(getAnchor(step))
    );

    // getStatusTitle :: State -> string
    const getStatusTitle = compose(
      prop('title'),
      getCurrentWorkflowStep(getAnchor(step))
    );

    const currentStatus: WorkflowStatus = {
      entityType: getAnchor(step),
      progressStep: prop('stepName', step),
      updated: getUpdated(state),
      lastModifiedBy: getLastModifiedBy(state),
      status: getStatusTitle(state),
    };

    const status = isVoucherStep(step)
      ? { ...currentStatus, ...voucherStatus }
      : currentStatus;

    const updated = updateLockedStatus(status, step);
    return [...prev, updated];
  };

  // map the status data
  const currentStatus: WorkflowStatus[] = steps.reduce(getCurrentStatus, []);

  return {
    currentStatus: currentStatus.slice(2),
  };
};

const dispatchToProps = {
  loadGrantVoucherOverviewStatus,
  setCurrentNavAnchor,
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(CurrentStatus);
