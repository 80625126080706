import React from 'react';
import { FlexRow } from 'layouts';
import { ProgramOfStudyPathway } from 'types';
import { BorderedTitle } from 'components/Common/BorderedTitle';
import { map } from 'lodash';
import { SecondaryCareerPathwayCourses } from './SecondaryCareerPathwayCourses';

export const SecondaryCareerPathway: React.FC<{
  pathway: ProgramOfStudyPathway;
}> = (props) => {
  const { pathway } = props;

  return (
    <FlexRow>
      <div className="secondary-pos-pathway" style={{ width: '100%' }}>
        <BorderedTitle secondaryText={pathway.pathway} />
        <div className="u-flex">
          {map(pathway.courseLevels, (level, index) => {
            return <SecondaryCareerPathwayCourses key={index} level={level} />;
          })}
        </div>
      </div>
    </FlexRow>
  );
};
