import {
  baseInitialState,
  actionUpdateRecords,
  actionFetching,
} from 'redux/utility/_exports';
import { WORKFLOW_AWARD_PACKET_KEY } from 'redux/keys';

export const SET_AWARD_PACKET_WORKFLOW = `${WORKFLOW_AWARD_PACKET_KEY} SET`;
export const SET_AWARD_PACKET_WORKFLOW_LOADING = `${WORKFLOW_AWARD_PACKET_KEY} SET LOADING`;

// -- actions ------------------------------------------------------------------
export const setAwardPacketWorkflow = (payload: any) => {
  return {
    type: SET_AWARD_PACKET_WORKFLOW,
    payload,
  };
};

export const setAwardPacketWorkflowLoading = (payload: boolean) => {
  return {
    type: SET_AWARD_PACKET_WORKFLOW_LOADING,
    payload,
  };
};

// -- reducer ------------------------------------------------------------------
const initState = baseInitialState;

export const awardPacketReducer = (workflow = initState, action: any) => {
  switch (action.type) {
    case SET_AWARD_PACKET_WORKFLOW:
      return actionUpdateRecords(workflow, action.payload);

    case SET_AWARD_PACKET_WORKFLOW_LOADING:
      return actionFetching(workflow, action.payload);

    default:
      return workflow;
  }
};
