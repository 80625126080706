import { AdminStateControlComponent } from './AdminStateControl.component';
import { connect } from 'react-redux';
import { getLoggedinUser } from 'redux/user/selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const loggedInUser = getLoggedinUser(state);

  return {
    loggedInUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(AdminStateControlComponent);
