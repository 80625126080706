import {
  removeCTAContracting,
  loadCTAContracting,
} from 'redux/cta/contracting/ctacontracting';

export const ctaContractingMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case removeCTAContracting.SUCCEEDED: {
      dispatch(loadCTAContracting(action.payload));
      break;
    }
  }
};
