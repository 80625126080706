import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  BaseStateObject,
  baseInitialState,
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/_exports';
import { School } from 'types';
import {
  readSchools,
  createBusiness,
} from 'services/common/districtinstituteschool.services';

const initialState = baseInitialState;

export const saveBusiness = createActionThunk(
  'saveBusiness',
  (school: School) => {
    return createBusiness(school);
  }
);

export const loadSchools = createActionThunk('loadSchools', () => {
  const value = readSchools();
  return value;
});

export default handleActions<BaseStateObject, School[]>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<School[]>(loadSchools),

    [saveBusiness.START]: (state) => {
      return actionFetching(state, false);
    },
    [saveBusiness.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
