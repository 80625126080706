import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
  setShouldUpdate,
} from 'redux/utility/_exports';
import { CHANGELOG_ANNUAL_REFLECTION_KEY } from 'redux/keys';

export const RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_REFLECTION = `${CHANGELOG_ANNUAL_REFLECTION_KEY} RESET UPDATE`;
export const SET_CHANGELOG_ANNUAL_REFLECTION = `${CHANGELOG_ANNUAL_REFLECTION_KEY} SET`;
export const SET_CHANGELOG_ANNUAL_REFLECTION_LOADING = `${CHANGELOG_ANNUAL_REFLECTION_KEY} LOADING`;

const initState = baseInitialState;

export const changelogAnnualReflectionReducer = (
  state = initState,
  action: any
) => {
  switch (action.type) {
    case RESET_LAST_MODIFIED_CHANGELOG_ANNUAL_REFLECTION:
      return setShouldUpdate(state);

    case SET_CHANGELOG_ANNUAL_REFLECTION:
      return actionUpdateRecords(state, action.payload);

    case SET_CHANGELOG_ANNUAL_REFLECTION_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};

export const setChangelogAnnualReflection = (payload: any) => {
  return {
    type: SET_CHANGELOG_ANNUAL_REFLECTION,
    payload,
  };
};

export const setIsLoading_ChangelogAnnualReflection = (payload: boolean) => {
  return {
    type: SET_CHANGELOG_ANNUAL_REFLECTION_LOADING,
    payload,
  };
};
