import {
  toJSON,
  getWithAuthOptions,
  catchError,
  getFormattedUrl,
} from 'services';

const CTA_SCHOOL_TERMS = ['api', 'CtaGeneralSettings', 'terms'];

export const reacCTASchoolTerms = async () => {
  return fetch(getFormattedUrl(CTA_SCHOOL_TERMS), { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTASchoolTerms Services', err, 'reacCTASchoolTerms');
    });
};
