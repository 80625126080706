import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import { AnnualReflectionEditComponent } from './AnnualReflectionEdit.component';
import { AnnualReflectionSummaryComponent } from './AnnualReflectionSummary.component';
import {
  getRecords_AnnualReflection,
  getIsDataFetching_AnnualReflection,
} from 'redux/grants/_selectors';
import { loadAnnualReflection } from 'redux/grants/annualreflection';
import { getLockedStatus } from 'util/applicationStateUtility';

const mapStateToProps = (state: any) => {
  const annualReflection = getRecords_AnnualReflection(state);
  const isLoading = getIsDataFetching_AnnualReflection(state);

  const isLocked = getLockedStatus(state, 'annual-reflection');

  return {
    annualReflection,
    isLoading,
    isLocked,
  };
};

const dispatchProps = {
  loadAnnualReflection,
  setCurrentNavAnchor,
};

export const AnnualReflectionEdit = connect(
  mapStateToProps,
  dispatchProps
)(AnnualReflectionEditComponent);

export const AnnualReflectionSummary = connect(
  mapStateToProps,
  dispatchProps
)(AnnualReflectionSummaryComponent);
