import {
  saveCTADirectors,
  loadCTADirectors,
  removeCTADirectors,
} from 'redux/cta/ctadirector';
import {
  saveCTASpecialists,
  loadCTASpecialists,
  removeCTASpecialists,
} from 'redux/cta/ctaspecialist';

import { loadCTASupplies, saveCTASupplies } from 'redux/cta/ctaadminbooksupply';

export const ctaAdminCostsMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case removeCTADirectors.SUCCEEDED:
    case saveCTADirectors.SUCCEEDED: {
      dispatch(loadCTADirectors(action.payload));
      break;
    }

    case removeCTASpecialists.SUCCEEDED:
    case saveCTASpecialists.SUCCEEDED: {
      dispatch(loadCTASpecialists(action.payload));
      break;
    }

    case saveCTASupplies.SUCCEEDED: {
      dispatch(loadCTASupplies(action.payload));
      break;
    }
  }
};
