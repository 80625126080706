export interface CTAFiscalYearProgramSupport {
  ctaSupportPersonnelId: number; // PK of parent
  ctaProgramId: number; // uniqueIdentifier
  ctePercent: number;
  memo: string;
  isActive?: boolean;
}

export const emptyCTAFiscalYearProgramSupport = (): CTAFiscalYearProgramSupport => {
  return {
    ctaProgramId: -1,
    ctaSupportPersonnelId: -1,
    ctePercent: 0,
    memo: '',
    isActive: true,
  };
};
