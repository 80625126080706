import {
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_SUBMISSION_KEY,
} from 'redux/keys';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import {
  DataCollectionSubmission,
  // emptyDataCollectionSubmission,
} from 'types/datacollection/DataCollectionSubmission.types';
import { getSubmissionManagementFilters } from 'redux/filters/_selectors';

export const DATA_COLLECTION_SUBMISSION_PATH: string[] = [
  DATA_COLLECTION_KEY,
  DATA_COLLECTION_SUBMISSION_KEY,
];

export const getIsDataFetching_DataCollectionSubmission = (
  state: any
): boolean => {
  return getIsDataFetching(state, DATA_COLLECTION_SUBMISSION_PATH);
};

export const shouldRefreshReduxState_DataCollectionSubmission = (
  state: any
): boolean => {
  return shouldRefreshReduxState<DataCollectionSubmission>(
    state,
    DATA_COLLECTION_SUBMISSION_PATH
  );
};

export const getRecords_DataCollectionSubmission = (
  state: unknown
): DataCollectionSubmission[] => {
  return getRecords(state, DATA_COLLECTION_SUBMISSION_PATH);
};

export const getAdvancedFilteredRecords_DataCollectionSubmission = (
  state: any
): DataCollectionSubmission[] => {
  const filters = getSubmissionManagementFilters(state);
  const records = getAdvancedFilteredRecords<DataCollectionSubmission>(
    state,
    DATA_COLLECTION_SUBMISSION_PATH,
    filters
  );
  return records;
};
