import { useValidation } from 'de-formed-validations';
import { Expense } from 'types';
import { isStringValid } from 'util/validation.utility';

export const TravelExpenseValidations = () => {
  return useValidation<Expense>({
    travelStartDate: [
      {
        errorMessage: 'The Start Date must come before the End Date.',
        validation: (value: Date, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : new Date(value) <= new Date(state.travelEndDate);
        },
      },
    ],

    travelEndDate: [
      {
        errorMessage: 'The End Date must come after the Start Date.',
        validation: (value: Date, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : new Date(state.travelStartDate) <= new Date(value);
        },
      },
    ],

    travelerDestination: [
      {
        errorMessage: 'Please specify a destination.',
        validation: (value: string, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : isStringValid(value);
        },
      },
    ],

    travelerName: [
      {
        errorMessage: 'Please provide the travelers name.',
        validation: (value: string, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : isStringValid(value);
        },
      },
    ],

    travelerTypeId: [
      {
        errorMessage: 'Please select a valid traveler type',
        validation: (value: number, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : value > 0;
        },
      },
    ],

    eventName: [
      {
        errorMessage:
          'Please enter the name, description, or url of the event that the travel was for.',
        validation: (value: string, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : isStringValid(value);
        },
      },
    ],

    travelerImproveProgramDesc: [
      {
        errorMessage:
          'Please provide a description of how this travel will positively impact the programs selected.',
        validation: (value: string, state: Expense) => {
          if (state.financialCategoryId !== 3) return true;
          return state.travelerNoValidation || !state.travelIsOutOfState
            ? true
            : isStringValid(value);
        },
      },
    ],
  });
};
