import React, { CSSProperties } from 'react';
import { map, startCase } from 'lodash';
import { IconTable } from 'constants/icons.const';

type IconComponentProps = {
  name: string;
  title?: string;
  className: string;
  style?: CSSProperties;
  tooltip?: string;
};

export const Icon: React.FC<IconComponentProps> = (props) => {
  const { className, name, title, style = {}, tooltip } = props;
  const icon = IconTable[name] ? IconTable[name] : IconTable['goat'];

  const labelName = title ? title : startCase(name);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={icon.viewBox}
      role="img"
      aria-label={labelName}
      className={className}
      style={style}
    >
      {map(icon.paths, (d: string, index: number) => (
        <path d={d} key={index} />
      ))}
      {title && <title>{tooltip}</title>}
    </svg>
  );
};
