import { connect } from 'react-redux';
import { PathwayComponent } from './Pathway.component';

const mapStateToProps = (state: any, ownProps: any) => {
  return {};
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(PathwayComponent);
