import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import {
  IntentToParticipateMember,
  IntentToParticipateFiscalAgent,
} from 'types';

const PARTICIPATION_ENDPOINT = `${ENVIRONMENT.API_URL}api/IntentToParticipate/`;

export const readMemberParticipation = async (
  guid: string
): Promise<IntentToParticipateMember> => {
  const url = `${PARTICIPATION_ENDPOINT}member/${guid}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('intentToParticipate', err, 'readMemberParticipation');
    });
};

export const readFiscalAgentParticipation = async (
  guid: string
): Promise<IntentToParticipateFiscalAgent> => {
  const url = `${PARTICIPATION_ENDPOINT}fiscalagent/${guid}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'intentToParticipate',
        err,
        'readFiscalAgentParticipation'
      );
    });
};

export const updateMemberParticipation = async (
  guid: string,
  member: IntentToParticipateMember
): Promise<IntentToParticipateMember> => {
  const url = `${PARTICIPATION_ENDPOINT}member/${guid}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(member),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('intentToParticipate', err, 'readMemberParticipation');
    });
};

export const updateFiscalAgentParticipation = async (
  guid: string
): Promise<IntentToParticipateFiscalAgent> => {
  const url = `${PARTICIPATION_ENDPOINT}fiscalagent/${guid}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify({}),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'intentToParticipate',
        err,
        'readFiscalAgentParticipation'
      );
    });
};
