import { connect } from 'react-redux';
import { FundPoolingEditComponent } from './FundPoolingEdit.component';
import { getAnnualSpendingPlan } from 'redux/grants/_selectors';
import { loadConsortia } from 'redux/grants/consortium';

const mapStateToProps = (state: any) => {
  const spendingPlan = getAnnualSpendingPlan(state);

  return {
    spendingPlan,
  };
};

const dispatchProps = {
  loadConsortia,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(FundPoolingEditComponent);
