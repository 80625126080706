import React, { useState, useEffect } from 'react';
import { FlexRow } from 'layouts';
import { StudentEnrollmentData } from 'types';
import { some } from 'lodash';
import Button from 'components/Common/FormElements/Button';
import Checkbox from 'components/Common/FormElements/Checkbox';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Instruction from 'components/Common/Instruction';

type EnrollmentStatusComponentProps = {
  insertEnrollmentComplete: Function;
  enrollment: StudentEnrollmentData;
};

export const EnrollmentStatusComponent: React.FC<EnrollmentStatusComponentProps> = ({
  enrollment,
  insertEnrollmentComplete,
}) => {
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const { complete, locked } = enrollment;
  const isLocked = some([complete, locked]);

  useEffect(() => {
    setIsComplete(complete);
  }, [complete]);

  return (
    <React.Fragment>
      <Instruction title="Enrollment Status (Final Step)">
        As your final step, the person who has the “Local - DC Submission
        Approver” permission in Universal Contacts should access the Reporting
        Dashboard and filter by 'DC - Review' to double check all data that has
        been entered. Once the data has been confirmed as correct, this person
        will need to certify below that all student records are complete for
        their district or institution, then press save. This action cannot be
        undone.
      </Instruction>

      <FlexRow>
        <Checkbox
          disabled={isLocked}
          name="complete"
          value={isComplete}
          onChange={() => setIsComplete(!isComplete)}
          label="The data submission for my associated district/college is complete."
        />
        <div className="u-flex-grow-1" />

        <ConfirmationDialog
          disabled={isLocked}
          tooltip="Close Enrollments"
          header="Close Enrollments?"
          onConfirm={insertEnrollmentComplete}
          activateButton={
            <Button
              disabled={isLocked}
              text="Save"
              className="button--filled"
            />
          }
        >
          <div style={{ whiteSpace: 'normal' }}>
            <p>
              Are you sure you want to close all enrollments for your associated
              districts / institutions?
            </p>
            <p className="u-error">This action cannot be undone!</p>
          </div>
        </ConfirmationDialog>
      </FlexRow>
    </React.Fragment>
  );
};
