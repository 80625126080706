import React from 'react';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <div
      style={{
        borderTop: '.1rem solid #007d89',
        boxShadow: '0px 0px 18px 4px rgb(0 0 0 / 10%)',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '2.2rem',
        backgroundColor: '#FFF',
      }}
    >
      <div
        className="footer u-flex"
        style={{
          justifyContent: 'center',
          fontSize: '1.6rem',
        }}
      >
        <div
          className="u-flex"
          style={{ justifyContent: 'space-evenly', width: '60%' }}
        >
          <a
            href={
              'http://coloradostateplan.com/administrator/coloradocte-com-faq/'
            }
            // Launching new tab
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
          <span>||</span>
          <Link to={'/accessibility'}>Accessibility Statement</Link>
          <span>||</span>
          <a
            href={
              'https://internal.cccs.edu/human-resources/nondiscrimination-statement/'
            }
            // Launching new tab
            target="_blank"
            rel="noreferrer"
          >
            Nondiscrimination Statement
          </a>
          <span>||</span>
          <a
            href={
              'https://forms.office.com/Pages/ResponsePage.aspx?id=6rEiKgp4PUqxt85fmsRTaBbq_NoW3_JNlasFwHD33LNURUcxT1pORlBSNFQ2RkE1REdBU0ZESVpKNi4u'
            }
            // Launching new tab
            target="_blank"
            rel="noreferrer"
          >
            Submit Issue
          </a>
        </div>
      </div>
    </div>
  );
};
