import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import { PermissionType } from 'types';
import {
  createPermissionType,
  readPermissionTypes,
  updatePermissionType,
} from 'services/permissions/permissiontype.services';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadPermissionTypes = createActionThunk(
  'loadPermissionTypes',
  () => {
    return readPermissionTypes();
  }
); // end

export const savePermissionType = createActionThunk(
  'savePermissionType',
  async (payload: PermissionType) => {
    let creating = !payload.permissionTypeId || payload.permissionTypeId === -1;
    if (creating) {
      return createPermissionType(payload);
    } else {
      return updatePermissionType(payload).then(() => {
        return payload.permissionTypeId;
      });
    }
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, PermissionType[]>(
  {
    ...baseLoadActionBuilder<PermissionType[]>(loadPermissionTypes),

    [savePermissionType.START]: (state) => {
      return actionFetching(state, true);
    } /* End action */,
    [savePermissionType.ENDED]: (state) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
