import {
  getFormattedUrl,
  getWithAuthOptions,
  toJSON,
  catchError,
  postWithAuthOptions,
  deleteWithAuthOptions,
} from 'services';
import { CTAContracting, CTASupportDoc, CTACourse } from 'types';

const BASE_URL = ['api', 'ctahosting'];

export const readCTAHostingAvailableContracts = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, 'availableContracts', ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contracting',
        err,
        'readCTAContractingAvailableHosts'
      );
    });
};

// Overview Page Data
export const readCTAHostingContracts = async (ctaId: number) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contracts',
        err,
        'readCTAHostingContracts'
      );
    });
};

export const readCTAHostingContract = async (
  ctaId: number,
  ctaContractingId: number
) => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId, ctaContractingId]), {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('CTA Hosting Contract', err, 'readCTAHostingContract');
    });
};

export const updateCTAHostingContract = async (
  ctaId: number,
  contractingId: number,
  ctaCourses: CTACourse[]
): Promise<CTAContracting[]> => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId, contractingId]), {
    ...postWithAuthOptions(),
    body: JSON.stringify(ctaCourses),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contract',
        err,
        'updateCTAHostingContract'
      );
    });
};

export const deleteCTAHostingContract = async (
  ctaId: number,
  contractingId: number
): Promise<CTASupportDoc> => {
  return fetch(getFormattedUrl([...BASE_URL, ctaId, contractingId]), {
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError(
        'CTA Hosting Contract',
        err,
        'deleteCTAHostingContract'
      );
    });
};
