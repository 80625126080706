import React, { useEffect } from 'react';
import { compose, renderData, prop, isTruthy } from 'util/objectUtility';
import { ProgramAward, AwardType, ProgramStep } from 'types';
import { isApplicaionIdValid } from 'util/global';
import { filter, find } from 'lodash';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import Button from 'components/Common/FormElements/Button';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Icon from 'components/Common/Icons';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SaveButtons from 'components/Common/SaveButtons';
import Table from 'components/Common/Table';

type AwardsEditComponentProps = {
  awardTypes: AwardType[];
  loadProgramAwards: Function;
  nextProgramStep: ProgramStep;
  programInstanceId: number;
  programAwards: ProgramAward[];
  readOnly: boolean;
  resetProgramAwardCache: Function;
  setProgramAwardCache: Function;
  setCurrentNavAnchor: Function;
  saveProgramAwardCache: Function;
};

export const ProgramAwardEditComponent: React.FC<AwardsEditComponentProps> = (
  props
) => {
  const {
    awardTypes,
    loadProgramAwards,
    nextProgramStep,
    programInstanceId,
    programAwards,
    readOnly,
    resetProgramAwardCache,
    saveProgramAwardCache,
    setCurrentNavAnchor,
    setProgramAwardCache,
  } = props;

  useEffect(() => {
    if (!isApplicaionIdValid(programInstanceId)) return;
    loadProgramAwards(programInstanceId);
  }, [loadProgramAwards, programInstanceId]);

  useEffect(() => {
    setCurrentNavAnchor('awards');
  }, [setCurrentNavAnchor]);

  const deleteAward = (id: number | string) => {
    const records = filter(programAwards, (award: ProgramAward) => {
      return award.programAwardId !== id;
    });
    setProgramAwardCache({
      programInstanceId,
      records,
    });
  };

  const saveNewChronicle = () => {
    return saveProgramAwardCache(programAwards, programInstanceId);
  };

  const getAwardType = (awardTypeId: number): string => {
    const awardType = find(awardTypes, (type: AwardType) => {
      return type.awardTypeId === awardTypeId;
    });
    if (awardType) {
      return awardType.awardName;
    }
    return '';
  };

  const renderCircle = (bool: boolean): JSX.Element => {
    return (
      <React.Fragment>
        <Icon
          name={bool ? 'circleFilled' : 'circleOpen'}
          className="consortium-view__status-icon u-icon-green"
        />
      </React.Fragment>
    );
  };

  const renderIconButton = (row: ProgramAward) => {
    return (
      <IconButton
        name="pencil"
        tooltip="Edit"
        asLink={true}
        to={`/programs/awards/${programInstanceId}/${row.programAwardId}`}
      />
    );
  };

  const renderTrashCan = (row: ProgramAward) => {
    return (
      <SecureWrap
        component={['programs']}
        requireEdit={true}
        isLocked={readOnly}
      >
        <ConfirmationDialog
          tooltip="Delete"
          header={'Confirm Delete'}
          onConfirm={() => {
            deleteAward(row.programAwardId);
          }}
        >
          <h3>Are you sure you wish to delete {row.certOrDegreeTitle}</h3>
        </ConfirmationDialog>
      </SecureWrap>
    );
  };

  const CertOrDegreeTitle = compose(
    renderData,
    prop('certOrDegreeTitle')
  );
  const AwardType = compose(
    renderData,
    getAwardType,
    prop('awardTypeId')
  );
  const ContactHours = compose(
    renderData,
    prop('contactHours')
  );
  const TotalCredits = compose(
    renderData,
    prop('credits')
  );
  const TotalWeeks = compose(
    renderData,
    prop('totalWeeks')
  );
  const BannerCode = compose(
    renderCircle,
    isTruthy,
    prop('bannerCode')
  );

  const FinancialAidApproved = compose(
    renderCircle,
    isTruthy,
    prop('financialAidApproved')
  );

  const awardHeaders: TableColumnProps[] = [
    {
      name: 'Award Titles',
      cell: CertOrDegreeTitle,
      sortable: true,
      selector: CertOrDegreeTitle,
      width: '30%',
    },
    {
      name: 'Award Type',
      cell: AwardType,
      width: '10%',
    },
    {
      name: 'Contact Hours',
      cell: ContactHours,
      sortable: true,
      selector: ContactHours,
      center: true,
    },
    {
      name: 'Total Credits',
      cell: TotalCredits,
      sortable: true,
      selector: TotalCredits,
      center: true,
    },
    {
      name: 'Total Weeks',
      cell: TotalWeeks,
      sortable: true,
      selector: TotalWeeks,
      center: true,
    },
    {
      name: 'Banner Code',
      cell: BannerCode,
      center: true,
    },
    {
      name: 'Financial Aid',
      cell: FinancialAidApproved,
      center: true,
    },
    {
      name: 'Edit',
      cell: renderIconButton,
      center: true,
      width: '5%',
    },
    {
      name: 'Delete',
      cell: renderTrashCan,
      center: true,
      width: '5%',
    },
  ];

  return (
    <React.Fragment>
      <ScrollToTop />
      <Paper>
        <Instruction title="Program Awards">
          <p>
            To add a new program award, use "Add New Award" and fill out all
            required fields. Use the 'Edit' column to modify an existing program
            award. To remove a program award, use the 'Delete' column and
            confirm you wish to delete the award.
          </p>
        </Instruction>
        <SecureWrap
          component={['programs']}
          requireEdit={true}
          isLocked={readOnly}
        >
          <div className="form__row">
            <Button
              text="+ Add New Award"
              asLink={true}
              to={`/programs/awards/${programInstanceId}/0`}
              className="form__btn--add"
            />
          </div>
        </SecureWrap>
      </Paper>
      <Paper>
        <Table data={programAwards} columns={awardHeaders} />
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl={`/programs/program-steps/${programInstanceId}`}
          onCancel={() => resetProgramAwardCache(programInstanceId)}
          saveData={saveNewChronicle}
          saveText="Save and Continue"
          saveUrl={nextProgramStep.nav.url}
        />
      </Paper>
    </React.Fragment>
  );
};
