import { useValidation } from 'de-formed-validations';
import { AnnualSpending, SpendingCategory } from 'types';
import {
  getSpendingCategory,
  calculateAvailableIndirectFunds,
  calculateAvailableDirectFunds,
  getRemainingFunds,
  getAllocatedFundsRemaining,
  getMinImprovementAllocation,
} from 'util/annualSpendingUtility';
import { map, every } from 'lodash';
import { sumCollection, addTotals } from 'util/mathHelpers';
import { isEqual } from 'util/formHelpers';

//define validation schema for validation hook
type ValidationState = {
  indirectAdminCosts: boolean;
  directAdminCosts: boolean;
  fundsRemaining: boolean;
  allocatedRemaining: boolean;
  improvementPlanFunds: boolean;
};

const sumExpenses = ({ expenses }: SpendingCategory) => {
  return sumCollection(addTotals('budgetedAmount'), expenses);
};

export const AnnualSpendingPlanValidations = () => {
  return useValidation<ValidationState>({
    // If Indirect Admin Costs Allocated > Available Indirect Funds
    indirectAdminCosts: [
      {
        errorMessage:
          'Indirect Administrative Costs cannot exceed available funds.',
        validation: (spendingPlan: AnnualSpending, _) => {
          const { spendingCategories } = spendingPlan;
          const [indirectAdminCosts] = getSpendingCategory(
            7,
            spendingCategories
          );
          const indirectAvailable = calculateAvailableIndirectFunds(
            spendingPlan
          );
          return indirectAdminCosts.allocatedFunds <= indirectAvailable;
        },
      },
    ],
    // If Direct Admin Costs Allocated > Available Indirect Funds
    directAdminCosts: [
      {
        errorMessage: 'Direct Admin Costs cannot exceed available funds.',
        validation: (spendingPlan: AnnualSpending, _) => {
          const { spendingCategories } = spendingPlan;
          const [directAdminCosts] = getSpendingCategory(8, spendingCategories);
          const directAvailable = calculateAvailableDirectFunds(spendingPlan);
          return directAdminCosts.allocatedFunds <= directAvailable;
        },
      },
    ],
    fundsRemaining: [
      {
        errorMessage:
          'Total Funds Remaining must be $0.00. Allocate more funds or create additional projects.',
        validation: (spendingPlan: AnnualSpending, _) => {
          return isEqual(0, getRemainingFunds(spendingPlan));
        },
      },
    ],
    allocatedRemaining: [
      {
        errorMessage:
          'Allocated Funds Remaining must be $0.00. Please add more expenses.',
        validation: (spendingPlan: AnnualSpending, _) => {
          const allocatedRemaining = map(
            spendingPlan.spendingCategories,
            (category: SpendingCategory) => {
              if (category.isFundPool && !category.isPartnershipLead) {
                return true;
              }
              if (category.categoryId === 7) {
                return true;
              }
              return getAllocatedFundsRemaining(category) === 0;
            }
          );
          return every(allocatedRemaining);
        },
      },
    ],
    improvementPlanFunds: [
      {
        errorMessage:
          'Improvement Plans must use at least 20% of your Total Grant Funds.',
        validation: (spendingPlan: AnnualSpending, _) => {
          const [improvementPlan] = getSpendingCategory(
            10,
            spendingPlan.spendingCategories
          );
          if (improvementPlan) {
            const totalExpenses = sumExpenses(improvementPlan);
            const min = getMinImprovementAllocation(spendingPlan);
            const valid = totalExpenses >= min;
            return valid;
          }
          return true;
        },
      },
    ],
  });
};
