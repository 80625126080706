import React, { useState, useEffect, CSSProperties } from 'react';
import { deburr, escapeRegExp, map, filter, find, orderBy } from 'lodash';
import { Course, ItemFilter } from 'types';
import Autosuggest, {
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Icon from 'components/Common/Icons';
import Tooltip from 'components/Common/Tooltip';
import MaxResults from '../MaxResults';

type CourseSelectBoxComponentProps = {
  id?: string;
  courseId?: number;
  courses: Course[];
  filter: ItemFilter;
  onChange: Function;
  readOnly?: boolean;
};

export const CourseSelectBoxComponent: React.FC<CourseSelectBoxComponentProps> = (
  props
) => {
  const { courseId, courses, id, onChange, readOnly = false } = props;
  const [courseResults, setCourseResults] = useState<Course[]>([]);
  const [result, setResult] = useState('');

  /* if a course is passed in on props, we'll set it here */
  useEffect(() => {
    if (courseId) {
      const selectedCourse = find(courses, (item) => {
        return item.courseId === courseId;
      });
      setResult(selectedCourse ? getFullCoursePrefix(selectedCourse) : '');
    }
  }, [courses, courseId]);

  /* used to get the course prefix and number combined */
  const getFullCoursePrefix = (course: Course): string => {
    return `${course.prefix} ${course.courseNumber}`;
  };

  /* used to get the suggested values */
  const getSuggestions = (value: string) => {
    let results = 0;
    const cleanValue = escapeRegExp(deburr(value).toLowerCase());

    if (cleanValue) {
      const regex = new RegExp(`^${cleanValue}`, 'i');

      const suggestions = filter(courses, (item: Course) => {
        const courseNumber = getFullCoursePrefix(item);
        const included =
          regex.test(courseNumber.toLowerCase()) ||
          regex.test(item.prefix.toLowerCase()) ||
          regex.test(String(item.courseNumber)) ||
          regex.test(item.courseName.toLowerCase());
        if (included) {
          results++;
        }
        return results <= 8 && included;
      });
      return orderBy(suggestions, ['prefix', 'courseNumber']);
    }
    return [];
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    if (request.value === result) {
      setCourseResults(getSuggestions(request.value));
    }
  };

  const renderSuggestion = (suggestion: Course, { query }: any) => {
    const courseMatch = AutosuggestHighlightMatch(
      String(suggestion.courseNumber),
      query
    );
    const courseParts = AutosuggestHighlightParse(
      String(suggestion.courseNumber),
      courseMatch
    );

    const prefixmatches = AutosuggestHighlightMatch(suggestion.prefix, query);
    const prefixparts = AutosuggestHighlightParse(
      suggestion.prefix,
      prefixmatches
    );

    const matches = AutosuggestHighlightMatch(suggestion.courseName, query);
    const parts = AutosuggestHighlightParse(suggestion.courseName, matches);

    return (
      <div className="u-flex" style={{ width: '45rem' }}>
        <div>
          {map(prefixparts, (part, index) => {
            const className = part.highlight
              ? 'react-autosuggest__suggestion--match'
              : undefined;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}{' '}
          {map(courseParts, (part, index) => {
            const className = part.highlight
              ? 'react-autosuggest__suggestion--match'
              : undefined;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
          {': '}
        </div>
        <div className="u-flex-grow-1">
          {map(parts, (part, index) => {
            const className = part.highlight
              ? 'react-autosuggest__suggestion--match'
              : undefined;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
        <React.Fragment>
          {suggestion.courseDescription && (
            <Tooltip
              tooltip={suggestion.courseDescription}
              indicator={<Icon name="questionMark" className="u-menu-icon" />}
            />
          )}
        </React.Fragment>
      </div>
    );
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    if (readOnly) {
      return;
    }
    switch (method) {
      case 'left':
      case 'right':
      case 'down':
      case 'up':
        break;
      default:
        setResult(newValue);
        setCourseResults(getSuggestions(newValue));
        break;
    }
  };

  const boxStyle: CSSProperties = {
    width: '30%',
  };

  // Displays the results summary at the bottom of the dropdown
  const renderSuggestionsContainer = (
    suggestionParams: RenderSuggestionsContainerParams
  ) => {
    const { containerProps, children } = suggestionParams;
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>Course</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={courses.length}
          resultCount={courseResults.length}
          maxResults={8}
        />
      </div>
    );
  };

  return (
    <div className="course-auto-suggest">
      <Autosuggest
        getSuggestionValue={(suggestion: Course) => {
          onChange && onChange(suggestion);
          return getFullCoursePrefix(suggestion);
        }}
        suggestions={courseResults}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {
          setCourseResults([]);
        }}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={{
          id,
          value: result,
          onChange: handleChange,
        }}
      />
    </div>
  );
};
