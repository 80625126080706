import React from 'react';
import { PROGRAM_LEVEL_ENUM } from 'constants/programLevels.const';
import { FlexRow } from 'layouts';
import { StudentRecordComponentProps } from '../EditSnapshotStudent';
import { DemographicInformationBase } from './DemographicInformationBase';
import { DemographicInformationPS } from './DemographicInformationPS';
import { DemographicInformationS } from './DemographicInformationS';
import { prop } from 'util/objectUtility';
import { DemographicInformationSPS } from './DemographicInformationSPS';

const DemographicInformation: React.FC<StudentRecordComponentProps> = (
  props
) => {
  const { student } = props;
  const programLevel = prop('programLevelId', student);
  const isSecondary = programLevel === PROGRAM_LEVEL_ENUM.Secondary;
  const isPostsecondary = programLevel === PROGRAM_LEVEL_ENUM.Postsecondary;

  return (
    <React.Fragment>
      <FlexRow>
        <h2 className="u-section-title-text">Demographic Information</h2>
      </FlexRow>
      <DemographicInformationBase {...props} />
      {(isSecondary || isPostsecondary) && (
        <DemographicInformationSPS {...props} />
      )}
      {isSecondary && <DemographicInformationS {...props} />}
      {isPostsecondary && <DemographicInformationPS {...props} />}
    </React.Fragment>
  );
};

export default DemographicInformation;
