import { COMMON_KEY, COMMON_PROGRAM_STEPS_KEY } from 'redux/keys';
import { ProgramStep, ProgramStepCollection, ProgramLevel } from 'types';
import { getProgram } from 'redux/programs/_selectors';
import { getRecords_ProgramLevels } from './programlevels.selectors';
import { getActiveProgramId } from 'redux/application/selectors';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { defaultTo, find } from 'lodash';

export const PROGRAM_STEP_PATH = [COMMON_KEY, COMMON_PROGRAM_STEPS_KEY];

export const getIsDataFetching_ProgramSteps = (state: any): boolean => {
  return getIsDataFetching(state, PROGRAM_STEP_PATH);
};

export const getRecords_ProgramSteps = (
  state: any
): ProgramStepCollection[] => {
  return getRecords<ProgramStepCollection>(state, PROGRAM_STEP_PATH);
};

export const shouldRefreshReduxState_ProgramSteps = (state: any): boolean => {
  return shouldRefreshReduxState<ProgramStepCollection>(
    state,
    PROGRAM_STEP_PATH
  );
};

export const getLastUpdated_ProgramSteps = (state: any): number => {
  return getLastUpdated(state, PROGRAM_STEP_PATH);
};

export const getProgramSteps = (state: any): ProgramStep[] => {
  const plevelid = getProgram(state, getActiveProgramId(state)).programLevelId;
  const plevel = find(
    getRecords_ProgramLevels(state),
    (level: ProgramLevel) => {
      return level.programLevelId === plevelid;
    }
  );
  const steps = find(getRecords_ProgramSteps(state), (step) => {
    return step.level === (plevel && plevel.programLevelName);
  });
  return steps ? steps.steps : [];
};

export const getSummaryStep = (state: any): ProgramStep => {
  return {
    stepID: 0,
    stepName: 'Program Summary',
    nav: {
      anchor: 'summary',
      displayName: 'Program Summary',
      url: `/programs/program-steps/${getActiveProgramId(state)}`,
    },
  };
};

export const getCurrentProgramStep = (
  state: any,
  anchor: string
): ProgramStep => {
  const steps = getProgramSteps(state).filter((item) => {
    return item.nav.anchor !== 'approval';
  });

  return defaultTo(
    find(steps, (item) => {
      return item.nav.anchor === anchor;
    }),
    getSummaryStep(state)
  );
};

export const getNextStep = (state: any, component: string): ProgramStep => {
  const steps = getProgramSteps(state);
  const current = getCurrentProgramStep(state, component);
  const nextId = defaultTo(current && current.stepID + 1, -1);

  if (nextId >= steps.length || nextId === -1) {
    return getSummaryStep(state);
  }

  const nextStep = defaultTo(
    find(steps, (item) => {
      return item.stepID === nextId;
    }),
    getSummaryStep(state)
  );

  return {
    ...nextStep,
    nav: {
      ...nextStep.nav,
      url: `/programs/${nextStep.nav.anchor}/${getActiveProgramId(state)}`,
    },
  };
};
