import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  loadAnnualSpendingPlan,
  saveAnnualSpendingPlan,
} from 'redux/grants/annualspendingplan/annualspendingplan.actions';
import { AnnualSpending } from 'types';
import { isApplicaionIdValid } from 'util/global';
import { FlexRow } from 'layouts';
import { formatMoney } from 'util/moneyHelpers';
import { add } from 'util/mathHelpers';
import { AnnualSpendingPlanValidations } from 'validations/AnnualSpendingPlan/AnnualSpendingPlan.validations';
import AdministrativeCosts from './AdministrativeCosts';
import Input from 'components/Common/FormElements/Input';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ProjectPlanAllocation from './ProjectPlanAllocation';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import TotalFundsRemaining from './FundsRemaining/TotalFundsRemaining';

type AnnualSpendingPlanProps = {
  annualSpendingPlan: AnnualSpending;
  isLoading: boolean;
  isLocked: boolean;
  loadStrategySelectItem: Function;
  setCurrentNavAnchor: Function;
};

export const AnnualSpendingPlanEditComponent: React.FC<AnnualSpendingPlanProps> = (
  props
) => {
  const {
    annualSpendingPlan,
    isLoading,
    isLocked,
    loadStrategySelectItem,
    setCurrentNavAnchor,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const v = AnnualSpendingPlanValidations();
  const dispatch = useDispatch();
  const { grantFiscalYearId: fId }: any = useParams();
  const grantFiscalYearId = Number(fId);

  // --[ component logic ]-----------------------------------------------------
  const { basicGrantFunds, reserveGrantFunds } = annualSpendingPlan;
  const handleSave = () => {
    dispatch(saveAnnualSpendingPlan(annualSpendingPlan));
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    setCurrentNavAnchor('spending-plans');
  }, [setCurrentNavAnchor]);

  useEffect(() => {
    if (isApplicaionIdValid(grantFiscalYearId)) {
      dispatch(loadAnnualSpendingPlan(grantFiscalYearId));
      loadStrategySelectItem(grantFiscalYearId);
    }
  }, [loadStrategySelectItem, grantFiscalYearId]); //eslint-disable-line

  useEffect(() => {
    if (isApplicaionIdValid(annualSpendingPlan.annualSpendingPlanId)) {
      v.validateCustom([
        { key: 'indirectAdminCosts', value: annualSpendingPlan },
        { key: 'directAdminCosts', value: annualSpendingPlan },
        { key: 'fundsRemaining', value: annualSpendingPlan },
        { key: 'allocatedRemaining', value: annualSpendingPlan },
        { key: 'improvementPlanFunds', value: annualSpendingPlan },
      ]);
    }
  }, [annualSpendingPlan]); //eslint-disable-line

  // --[ render logic ]--------------------------------------------------------
  const total: number = add(basicGrantFunds, reserveGrantFunds);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading
        isActive={isLoading}
        messageBefore="Loading Annual Spending Plan Data..."
      />
      <SecureWrap isLocked={isLocked} component={['grantsannualspending']}>
        <Paper>
          <FlexRow>
            <h2>Instructions</h2>
          </FlexRow>
          <p>
            Welcome to your Institution's Annual Spending Plan. From here,
            create Projects and Allocate Funds to Projects, Improvement Plans
            (if applicable), and Administrative Costs. In order to submit your
            Annual Spending Plan, you will need to allocate all of your
            Institution's Estimated Funds (down to the penny), record expenses
            for all Projects, and fill in all Stakeholder Signature fields. We
            recommed that you budget to the nearest dollar.
          </p>
        </Paper>

        <Paper>
          <FlexRow>
            <h2>Estimated Funds Summary</h2>
          </FlexRow>
          <FlexRow>
            <Input
              name="basicGrantFunds"
              value={`$${formatMoney(basicGrantFunds, false)}`}
              disabled={true}
              className="input-display"
            />
            <Input
              name="reserveGrantFunds"
              value={`$${formatMoney(reserveGrantFunds, false)}`}
              disabled={true}
              className="input-display"
            />
            <Input
              name="totalGrantFunds"
              value={`$${formatMoney(total, false)}`}
              disabled={true}
              className="input-display"
            />
          </FlexRow>
        </Paper>

        <Paper>
          <FlexRow>
            <h2>Estimated Funds Remaining</h2>
          </FlexRow>
          <p>
            Your Institution's Estimated Funds are a combination of Basic and
            Reserve Grant Funds and is estimated based upon last year's Grant
            Award. The Estimated Funds Remaining to your institution will
            decrease as you allocate funds to Projects, Improvement Plans, and
            Administrative Costs.
          </p>
          <TotalFundsRemaining />
        </Paper>
      </SecureWrap>
      <Paper>
        <ProjectPlanAllocation
          isLocked={isLocked}
          annualSpendingPlan={annualSpendingPlan}
        />
      </Paper>

      <Paper>
        <AdministrativeCosts
          isLocked={isLocked}
          spendingPlan={annualSpendingPlan}
          validations={v}
        />
      </Paper>

      <SecureWrap isLocked={isLocked} component={['grantsannualspending']}>
        <Paper>
          <SaveButtons
            cancelText="return to grant overview"
            cancelUrl={`/grants/applications/grant-steps/${grantFiscalYearId}`}
            disabled={annualSpendingPlan.annualSpendingPlanId < 0}
            saveText="Save Annual Spending Plan"
            alternateAction={handleSave}
            validationErrors={v.validationErrors}
          />
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
