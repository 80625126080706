import { ENVIRONMENT } from 'constants/application.config';
import { getWithAuthOptions, toJSON, catchError } from 'services';

const CTSO_ENDPOINT = 'api/CtsoCodes';
const URL = `${ENVIRONMENT.API_URL}${CTSO_ENDPOINT}`;

export const readCtsoCodes = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CtsoCodes', err);
    });
};
