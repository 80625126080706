import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import StudentEnrollment from 'components/DataCollection/StudentEnrollment';

export const StudentEnrollmentPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <StudentEnrollment />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
