import { ENVIRONMENT } from 'constants/application.config';
import { getWithAuthOptions, toJSON, catchError } from 'services';
import { ProgramArea } from 'types';

const PROGRAM_AREAS_ENDPOINT = `${ENVIRONMENT.API_URL}api/CareerSectors/`;

export const readProgramAreas = async (): Promise<ProgramArea[]> => {
  return fetch(PROGRAM_AREAS_ENDPOINT, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('CareerSectors', err);
    });
};
