import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import DataCollectionLayout from 'layouts/DataCollectionLayout';
import ActiveTeachersComponent from 'components/DataCollection/ActiveTeachers';

export const ActiveTeachersPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <DataCollectionLayout {...props}>
        <ActiveTeachersComponent />
      </DataCollectionLayout>
    </BaseLayout>
  );
};
