import React from 'react';
import { useParams } from 'react-router';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';
import TwoYearWorkflow from 'components/Grants/Applications/TwoYear/Workflow';

export const GrantTwoYearApprovalPage: React.FC = (props) => {
  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <TwoYearWorkflow grantFiscalYearId={grantFiscalYearId} />
      </GrantsLayout>
    </BaseLayout>
  );
};
