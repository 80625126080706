import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { ProgramBase } from 'types';
import {
  createProgramBase,
  readProgramBases,
} from 'services/programs/programbases.services';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadProgramBases = createActionThunk('loadProgramBases', () => {
  return readProgramBases();
}); // end

export const saveProgramBase = createActionThunk(
  'saveProgramBase',
  (payload: ProgramBase) => {
    return createProgramBase(payload);
  }
); // end updateProgramAction

export default handleActions<BaseStateObject, ProgramBase[]>(
  {
    ...baseLoadActionBuilder<ProgramBase[]>(loadProgramBases),

    [saveProgramBase.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [saveProgramBase.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
