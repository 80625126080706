import React, { useState, useEffect, CSSProperties } from 'react';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
  RenderSuggestionsContainerParams,
  RenderSuggestionParams,
} from 'react-autosuggest';
import { useDispatch } from 'react-redux';
import { map, find, every } from 'lodash';
import { FlexGroup } from 'layouts';
import { renderPart, getParts, getSuggestions } from 'util/autoSuggest.utility';
import { useParams } from 'react-router';
import { prop } from 'util/objectUtility';
import { emptyCtaInstructorCourse, CtaInstructorCourse } from 'types';
import { isApplicaionIdValid } from 'util/global';
import { loadCTAInstructionalCourse } from 'redux/cta/InstructionalCosts/ctainstructionalcourse';
import MaxResults from '../MaxResults';

type CTEInstructorCourseSelectBoxProps = {
  ctaCourseId: number;
  courses: CtaInstructorCourse[];
  noLabel?: boolean;
  onChange: Function;
  label?: string;
  maxResults?: number;
};

export const CTEInstructorCourseSelectBox: React.FC<CTEInstructorCourseSelectBoxProps> = (
  props
) => {
  const {
    ctaCourseId,
    courses,
    noLabel,
    onChange,
    label,
    maxResults = 20,
  } = props;

  // -- dependencies --
  const { ctaId: cId, ctaInstructorId: ciId }: any = useParams();
  const ctaId = Number(cId);
  const ctaInstructorId = Number(ciId);
  const dispatch = useDispatch();

  // -- local states --
  const [resultText, setResultText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<CtaInstructorCourse[]>([]);

  // -- component logic --
  const defaultResult: CtaInstructorCourse = {
    ...emptyCtaInstructorCourse(),
    schoolName: 'No results',
  };

  const executeSearch = (value: string): CtaInstructorCourse[] => {
    return getSuggestions(
      courses,
      value,
      [
        prop('schoolName'),
        prop('programName'),
        prop('cteCourseName'),
        prop('coCourseName'),
      ],
      maxResults,
      defaultResult
    );
  };

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    const { value } = request;
    if (value === resultText) {
      setSearchResults(executeSearch(value));
    }
  };

  const handleChange = (event: any, { newValue, method }: any) => {
    switch (method) {
      case 'left':
      case 'right':
      case 'down':
      case 'up':
        break;
      default:
        setResultText(newValue);
        setSearchResults(executeSearch(newValue));
        break;
    }
  };

  const getSuggestionValue = (suggestion: CtaInstructorCourse) => {
    return `${prop('schoolName', suggestion)} / ${prop(
      'programName',
      suggestion
    )}`;
  };

  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<CtaInstructorCourse>
  ) => {
    const { suggestion } = data;
    onSuggestionsClearRequested();
    setResultText('');
    onChange && onChange(suggestion);
  };

  // -- lifecyle --
  useEffect(() => {
    const selected = find(courses, (item: CtaInstructorCourse) => {
      return item.ctaCourseId === ctaCourseId;
    });
    if (selected) {
      setResultText(getSuggestionValue(selected));
    } else {
      setResultText('');
    }
  }, [ctaCourseId, courses]);

  useEffect(() => {
    if (
      every([isApplicaionIdValid(ctaId), isApplicaionIdValid(ctaInstructorId)])
    ) {
      dispatch(loadCTAInstructionalCourse(ctaId, ctaInstructorId));
    }
  }, [ctaId, ctaInstructorId]); // eslint-disable-line

  // -- display logic --
  const inputProps = {
    value: resultText,
    onChange: handleChange,
    id: 'ctaCourseId',
  };

  const boxStyle: CSSProperties = {
    width: '50%',
  };

  const renderSuggestionsContainer = (
    suggestionParams: RenderSuggestionsContainerParams
  ) => {
    const { containerProps, children } = suggestionParams;
    return (
      <div {...containerProps}>
        <div className="u-flex react-autosuggest__suggestion">
          <span style={boxStyle}>
            <strong>School Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>Program Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>CTE Course Name</strong>
          </span>
          <span style={boxStyle}>
            <strong>CO Course Name</strong>
          </span>
        </div>
        {children}
        <MaxResults
          totalCount={courses.length}
          resultCount={searchResults.length}
          maxResults={maxResults}
        />
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: CtaInstructorCourse,
    { query }: RenderSuggestionParams
  ) => {
    const schoolParts = getParts(prop('schoolName', suggestion), query);
    const programParts = getParts(prop('programName', suggestion), query);
    const courseParts = getParts(prop('cteCourseName', suggestion), query);
    const cocourseParts = getParts(prop('coCourseName', suggestion), query);

    return (
      <>
        <div className="u-flex">
          <div style={boxStyle}>{map(schoolParts, renderPart)}</div>
          <div style={boxStyle}>{map(programParts, renderPart)}</div>
          <div style={boxStyle}>{map(courseParts, renderPart)}</div>
          <div style={boxStyle}>{map(cocourseParts, renderPart)}</div>
        </div>
      </>
    );
  };

  return (
    <FlexGroup>
      {!noLabel && (
        <label htmlFor="courseselectBox" className="form__label">
          {label ? label : 'CTA Course'}
        </label>
      )}
      <Autosuggest
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={true}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={searchResults}
      />
    </FlexGroup>
  );
};
