import { Expense } from './Expense.types';

export interface SpendingCategory {
  spendingCategoryId: number;
  allocatedFunds: number;
  annualSpendingPlanId: number;
  categoryId: number;
  categoryName: string;
  description: string;
  expenses: Expense[];
  isFundPool: boolean;
  isPartnershipLead: boolean;
  partnerConsortiumId: number;
  partnershipAgreementFilePath: string;
  partnershipInstituteContribution: number;
  strategy: string;
  strategyId: number;
  tier: number;
}

export const emptySpendingCategory = (): SpendingCategory => {
  return {
    spendingCategoryId: -1,
    annualSpendingPlanId: -1,
    categoryId: -1,
    categoryName: '',
    allocatedFunds: 0,
    strategy: '',
    strategyId: -1,
    tier: 0,
    description: '',
    partnerConsortiumId: -1,
    isFundPool: false,
    isPartnershipLead: true,
    partnershipAgreementFilePath: '',
    partnershipInstituteContribution: 0,
    expenses: [],
  };
};
