import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import { readCourseTypes } from 'services/programs/coursetypes.services';
import { CourseType } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadCourseTypes = createActionThunk('loadCourseTypes', () => {
  return readCourseTypes();
});

export default handleActions<BaseStateObject, CourseType[]>(
  {
    ...baseLoadActionBuilder<CourseType[]>(loadCourseTypes),
  },
  initialState
);
