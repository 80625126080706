import { connect } from 'react-redux';
import { getActiveProgramId } from 'redux/application/selectors';
import { InstructorAdditionalContacts } from './InstructorAdditionalContacts.component';
import { getProgram } from 'redux/programs/_selectors';
import {
  getRecords_Schools,
  getRecords_Institutes,
} from 'redux/common/_selectors';
import { filter } from 'lodash';
import { School, Institute } from 'types';

export const mapStateToProps = (state: any) => {
  const programInstanceId = getActiveProgramId(state);
  const program = getProgram(state, programInstanceId);
  const allSchools = getRecords_Schools(state);
  const allInstitutes = getRecords_Institutes(state);

  const schools: School[] = filter(allSchools, (item) => {
    return item.schoolId === program.schoolId;
  });

  const institutes: Institute[] = filter(allInstitutes, (item) => {
    return item.schoolId === program.schoolId;
  });

  return {
    schools,
    institutes,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(InstructorAdditionalContacts);
