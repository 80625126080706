import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { isApplicaionIdValid } from 'util/global';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { ProgramPathway } from 'types';
import { compose, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import DatatableLoading from 'components/Common/DatatableLoading';
import Paper from 'components/Common/Paper';
import SummaryScroll from 'components/Common/SummaryScroll';
import Table from 'components/Common/Table';

type CareerPathwaySummaryProps = {
  isFetching: boolean;
  loadProgramCareerPathways: Function;
  programCareerPathways: ProgramPathway[];
  setCurrentNavAnchor: Function;
};

export const CareerPathwaySummaryComponent: React.FC<
  CareerPathwaySummaryProps
> = (props) => {
  const {
    setCurrentNavAnchor,
    programCareerPathways,
    loadProgramCareerPathways,
    isFetching,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const CareerPathwayName = compose(
    renderData,
    prop('careerPathwayName')
  );

  const headers: TableColumnProps[] = [
    {
      name: 'Career Pathway',
      cell: CareerPathwayName,
      sortable: true,
      selector: CareerPathwayName,
    },
  ];

  useEffect(() => {
    if (isApplicaionIdValid(programInstanceId)) {
      loadProgramCareerPathways(programInstanceId);
    }
  }, [loadProgramCareerPathways, programInstanceId]);

  return (
    <div className="career-pathway-summary">
      <SummaryScroll componentStep="pathways" />
      <Waypoint
        onEnter={() => setCurrentNavAnchor('pathways')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
      {isFetching ? (
        <DatatableLoading loading={true} />
      ) : (
        <Paper>
          <Table columns={headers} data={programCareerPathways} />
        </Paper>
      )}
      <Waypoint
        onEnter={() => setCurrentNavAnchor('pathways')}
        topOffset={WINDOW_TOP_OFFSET}
        bottomOffset={WINDOW_BOTTOM_OFFSET}
      />
    </div>
  );
};
