import {
  saveFerpaAgreementData,
  loadFerpaAgreementData,
} from 'redux/common/ferpaagreement';

export const ferpaAgreementMiddleware = (store: any) => (
  next: Function
) => async (action: any) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case saveFerpaAgreementData.SUCCEEDED: {
      dispatch(await loadFerpaAgreementData());
      break;
    }
  }
};
