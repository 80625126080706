import Footer from 'components/Common/Site/Footer';
import React from 'react';
import StickyBox from 'react-sticky-box';
import CTELogo from 'assets/cte_logo.png';

export const PublicLayout: React.FC = (props) => {
  return (
    <div>
      <StickyBox offsetBottom={168} style={{ zIndex: 7 }}>
        <div role="banner" className="main-header">
          <h1 className="main-header__logo">
            <img className="main-header__img" src={CTELogo} alt="CTE" />
          </h1>
        </div>
      </StickyBox>
      <div className="one-page__content">
        <main className="one-page__main">{props.children}</main>
        <Footer />
      </div>
    </div>
  );
};
