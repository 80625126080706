import { GRANTS_KEY, GRANTS_ACTUAL_REIMBURSEMENT_KEY } from 'redux/keys';
import {
  getIsDataFetching,
  shouldRefreshReduxState,
  getFirstRecord,
} from 'redux/utility/baseSelectors';
import {
  ActualReimbursementAmounts,
  emptyActualReimbursementAmounts,
} from 'types';

export const ACTUAL_REIMBURSEMENT_PATH = [
  GRANTS_KEY,
  GRANTS_ACTUAL_REIMBURSEMENT_KEY,
];

export const getIsDataFetching_ActualReimbursement = (state: any): boolean => {
  return getIsDataFetching(state, ACTUAL_REIMBURSEMENT_PATH);
};

export const getFirstRecord_ActualReimbursement = (
  state: unknown
): ActualReimbursementAmounts => {
  return getFirstRecord(
    state,
    ACTUAL_REIMBURSEMENT_PATH,
    emptyActualReimbursementAmounts()
  );
};

export const shouldRefreshReduxState_ActualReimbursement = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ActualReimbursementAmounts>(
    state,
    ACTUAL_REIMBURSEMENT_PATH
  );
};
