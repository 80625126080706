import React from 'react';
import { Expense } from 'types';
import { FlexRow, FlexGroup } from 'layouts';
import { mergeObjects, safeGet } from 'util/objectUtility';
import { formatMoney } from 'util/moneyHelpers';
import { ValidationObject } from 'de-formed-validations/dist/validations/types';
import Input from 'components/Common/FormElements/Input';

type EquipmentExpenseProps = {
  onChange: Function;
  expense: Expense;
  validations: ValidationObject<any>;
};

export const EquipmentExpense: React.FC<EquipmentExpenseProps> = (props) => {
  const { expense, onChange, validations } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { getError, getFieldValid, validate, validateIfTrue } = validations;

  // --[ component logic ]-----------------------------------------------------
  // get :: Expense -> string -> Expense[string]
  const get = safeGet(expense);

  const updateEquipmentCosts = (data: Partial<Expense>, _: any, value: any) => {
    const output = mergeObjects<Expense>(expense, data);
    validateIfTrue('unitCost', value, output);
    const totalEquipmentCost = output.unitCost * output.units;
    onChange(
      mergeObjects<Expense>(output, { totalEquipmentCost })
    );
  };

  return (
    <FlexGroup>
      <FlexRow>
        <Input
          errorMessage={getError('units')}
          label="# of Units"
          name="units"
          onBlur={() => validate('units', get('units'), expense)}
          onChange={updateEquipmentCosts}
          type="number"
          min={0}
          max={500000000}
          step={1}
          valid={getFieldValid('units')}
          value={Math.floor(get('units'))}
        />
        <Input
          errorMessage={getError('unitCost')}
          label="Total Unit Cost"
          name="unitCost"
          onBlur={() => validate('unitCost', get('unitCost'), expense)}
          onChange={updateEquipmentCosts}
          type="number"
          min={0}
          max={500000000}
          valid={getFieldValid('unitCost')}
          value={get('unitCost')}
        />
        <Input
          className="input-display"
          disabled={true}
          name="totalEquipmentCost"
          label="Total Cost of Equipment"
          value={formatMoney(get('totalEquipmentCost'))}
        />
      </FlexRow>
    </FlexGroup>
  );
};
