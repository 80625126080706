import { get, find } from 'lodash';
import { CACHE_KEY, PROGRAM_AWARD_CACHE_KEY } from 'redux/keys';
import { ProgramAward, emptyProgramAward } from 'types';
import { getIsDataFetching } from 'redux/utility/_exports';

const paths: string[] = [CACHE_KEY, PROGRAM_AWARD_CACHE_KEY];

export const getAreProgramAwardsFetching = (state: any) => {
  return getIsDataFetching(state, paths);
};

export const getProgramAwardCache = (state: any) => {
  return get(state, paths, null);
};

export const getProgramAwards = (state: any, programInstanceId: number) => {
  const cache = getProgramAwardCache(state);
  if (!cache) return null;
  const records = cache[programInstanceId];
  return records ? records.records : null;
};

export const getProgramAward = (
  state: any,
  programInstanceId: number,
  awardId: number
): ProgramAward => {
  let award;
  const awards = getProgramAwards(state, programInstanceId);
  if (awards) {
    award = find(awards, (award: ProgramAward) => {
      return award.programAwardId === Number(awardId);
    });
  }
  return award
    ? award
    : {
        ...emptyProgramAward(),
        financialAidDistributionDate: undefined,
        firstDate: undefined,
      };
};

export const isProgramAwardCacheDirty = (
  state: any,
  programInstanceId: number
) => {
  const cache = getProgramAwardCache(state);
  if (!cache) return false;
  const records = cache[programInstanceId];
  return records ? records.isDirty : false;
};
