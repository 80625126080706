import { EditParticipantComponent } from './Edit.component';
import { connect } from 'react-redux';
import { getConsortium } from 'redux/grants/_selectors';
import { getParticipantByProp } from 'redux/grants/grantparticipant.selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const { participantKey } = ownProps;
  const participant = getParticipantByProp(state, { key: participantKey });
  const consortiumInfo = getConsortium(state, participant.consortiumId);

  return {
    consortiumInfo,
    participant,
  };
};

const dispatchProps = {};

export default connect(
  mapStateToProps,
  dispatchProps
)(EditParticipantComponent);
