import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { baseLoadActionBuilder } from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { readFiscalYears } from 'services/common/fiscalyear.services';
import { FiscalYear } from 'types';

const initialState = baseInitialState;

export const loadFiscalYears = createActionThunk('loadFiscalYears', () => {
  return readFiscalYears();
});

export default handleActions<BaseStateObject, FiscalYear[]>(
  {
    ...baseLoadActionBuilder<FiscalYear[]>(loadFiscalYears),
  },
  initialState
);
