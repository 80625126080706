import {
  loadPermissionRoles,
  removePermissionRole,
  savePermissionRole,
} from 'redux/permissions/permissionroles';

export const adminMiddleware = (store: any) => (next: Function) => async (
  action: any
) => {
  const { dispatch } = store;

  next(action);

  switch (action.type) {
    case savePermissionRole.SUCCEEDED: {
      dispatch(await loadPermissionRoles());
      break;
    }
    case removePermissionRole.SUCCEEDED: {
      dispatch(await loadPermissionRoles());
      break;
    }
  }
};
