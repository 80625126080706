import DatePicker from 'components/Common/FormElements/DatePicker';
import Input from 'components/Common/FormElements/Input';
import React from 'react';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import Toggle from 'components/Common/FormElements/Toggle';
import { FlexRow, FlexGroup } from 'layouts';
import { UniversalContact } from 'types';
import { UniversalContactValidation } from 'validations/UniversalContact.validations';
import { every } from 'lodash';
import { getContactByCredential } from 'services/universalcontacts/universalcontact.services';
import { isNotCurrentUser } from 'util/universalcontact.utility';
import { useParams } from 'react-router-dom';
import { isSuperAdmin } from 'util/permissionsUtility';

type TeacherInformationProps = {
  contact: UniversalContact;
  onChange: Function;
  credentialExists?: UniversalContact | undefined;
  duplicateCredential?: string;
  setDuplicateCredential: Function;
  setCredentialExists: Function;
};

export const TeacherInformation: React.FC<TeacherInformationProps> = (
  props
) => {
  const {
    contact,
    credentialExists,
    duplicateCredential,
    onChange,
    setCredentialExists,
    setDuplicateCredential,
  } = props;
  const {
    getError,
    getFieldValid,
    validate,
    validateAll,
    validateIfTrue,
  } = UniversalContactValidation();

  const { userId }: any = useParams();
  const id = Number(userId);

  const validateOnBlur = (key: any) => {
    validate(key, contact[key as keyof UniversalContact], contact);
  };

  const handleChange = (data: any, key: any, value: any) => {
    validateIfTrue(key, value, contact);
    onChange(data, key, value);
  };

  const checkIfCredentialExists = async (credential: string) => {
    if (credential.length === 0) {
      setDuplicateCredential(null);
      setCredentialExists(null);
    } else {
      const user = await getContactByCredential(credential.trim());
      if (isNotCurrentUser(user, id)) {
        setCredentialExists(user);
        setDuplicateCredential(credential.trim());
      } else {
        setDuplicateCredential(null);
        setCredentialExists(null);
      }
    }
  };

  const credentialOnBlur = () => {
    validateOnBlur('credentialId');
    checkIfCredentialExists(contact.credentialId);
  };

  const credentialIsValid = () => {
    return every([
      !duplicateCredential || duplicateCredential.length === 0,
      getFieldValid('credentialId'),
    ]);
  };

  const getCredentialError = () => {
    if (contact.credentialOverride) {
      return `Number is being shared by Contact ID ${credentialExists?.universalContactId}.`;
    }
    const error = credentialExists
      ? `Number is already in use by Contact ID ${credentialExists.universalContactId}. Please contact a site administrator for assistance.`
      : getError('credentialId');
    return error;
  };

  const handleToggle = (
    data: Partial<UniversalContact>,
    key: any,
    value: any
  ) => {
    onChange(data);
    const updatedState = {
      ...contact,
      ...data,
    };
    if (!value) {
      validateAll(updatedState, [
        'credentialId',
        'credentialExpirationDate',
        'credentialArea',
      ]);
    }
  };

  const tstyle = {
    height: '5.4rem',
    alignItems: 'center',
  };

  return (
    <FlexGroup>
      <FlexRow>
        <div style={{ width: '20%', marginRight: '1.8rem' }}>
          <div className="u-flex-center" style={tstyle}>
            <Toggle
              name="isTeacher"
              noLabel
              value={contact.isTeacher}
              onChange={handleToggle}
              description="Teacher"
            />
          </div>
          <div className="u-flex-center" style={tstyle}>
            <Toggle
              name="isCteDirector"
              noLabel
              value={contact.isCteDirector}
              onChange={handleToggle}
              description="CTE Director"
            />
          </div>
          <div className="u-flex-center" style={tstyle}>
            <Toggle
              name="isCteSpecialist"
              noLabel
              value={contact.isCteSpecialist}
              onChange={handleToggle}
              description="CTE Specialist"
            />
          </div>
          <div className="u-flex-center" style={tstyle}>
            <Toggle
              name="isWorkBasedLearning"
              noLabel
              value={contact.isWorkBasedLearning}
              onChange={handleToggle}
              description="Work Based Learning"
            />
          </div>
        </div>
        <FlexGroup>
          <FlexRow>
            <div style={{ minWidth: '45%', marginRight: '2rem' }}>
              <SecureWrap
                component={['universalcontacts']}
                isLocked={contact.credentialOverride && !isSuperAdmin(contact)}
              >
                <Input
                  errorMessage={getCredentialError()}
                  name="credentialId"
                  label="Credential Number"
                  onBlur={credentialOnBlur}
                  onChange={handleChange}
                  valid={credentialIsValid()}
                  value={contact.credentialId}
                />
              </SecureWrap>
            </div>
            <SecureWrap component={['admin']}>
              <FlexGroup
                style={{
                  width: '20rem',
                  alignItems: 'center',
                }}
              >
                <Toggle
                  value={contact.credentialOverride}
                  onChange={handleToggle}
                  label="Override"
                  name="credentialOverride"
                />
              </FlexGroup>
            </SecureWrap>
            <DatePicker
              errorMessage={getError('credentialExpirationDate')}
              name="credentialExpirationDate"
              onBlur={() => validateOnBlur('credentialExpirationDate')}
              onChange={handleChange}
              valid={getFieldValid('credentialExpirationDate')}
              value={
                contact.credentialExpirationDate
                  ? contact.credentialExpirationDate
                  : undefined
              }
            />
          </FlexRow>
          <Input
            errorMessage={getError('credentialArea')}
            name="credentialArea"
            label="Credential Area"
            onBlur={() => validateOnBlur('credentialArea')}
            onChange={handleChange}
            valid={getFieldValid('credentialArea')}
            value={contact.credentialArea}
          />
        </FlexGroup>
      </FlexRow>
    </FlexGroup>
  );
};
