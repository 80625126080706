import React from 'react';
import { VouchersEdit } from 'components/Grants/Applications/Vouchers/Vouchers.connect';
import BaseLayout from 'layouts/BaseLayout';
import GrantsLayout from 'layouts/GrantsLayout';

export const GrantVoucherOverviewPage: React.FC = (props) => {
  return (
    <BaseLayout>
      <GrantsLayout {...props}>
        <VouchersEdit />
      </GrantsLayout>
    </BaseLayout>
  );
};
