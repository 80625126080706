import { connect } from 'react-redux';
import { CTADistrictLayout } from './CTADistrict.layout';
import { loadCTASingleDistrictRecord } from 'redux/cta/ctadashboard';
import { getSingleRecord_CTADistrictRecord } from 'redux/cta/ctadashboard.selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const ctaId = ownProps.match.params.ctaId;
  const ctaRecord = getSingleRecord_CTADistrictRecord(state, ctaId);

  return {
    ctaRecord,
  };
};

const dispatchProps = {
  loadCTASingleDistrictRecord,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CTADistrictLayout);
