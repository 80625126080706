import { loadWorkFlow } from 'redux/generalworkflow/workflow.actions';
import {
  readFourYearGrantFormQuestions,
  updateGrantFormQuestions,
} from 'services/grants/grantformquestions.services';
import {
  setNeedsAssessment,
  setIsLoading_NeedsAssessment,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/needsassessment.actions';
import {
  FETCH_FOUR_YEAR,
  SAVE_FOUR_YEAR,
} from 'redux/grants/grantformquestions/fouryearquestions/fouryear.actions';
import {
  setIsLoading_CareerInfo,
  setCareerInfo,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/careerinfo.actions';
import {
  setIsLoading_Quality,
  setQuality,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/quality.actions';
import {
  setIsLoading_Instructors,
  setInstructors,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/instructors.actions';
import {
  setIsLoading_Partnerships,
  setPartnerships,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/partnerships.actions';
import {
  setIsLoading_WorkBasedLearning,
  setWorkBasedLearning,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/workbasedlearning.actions';
import {
  setIsLoading_Equity,
  setEquity,
} from 'redux/grants/grantformquestions/fouryearquestions/subtypes/equity.actions';

export const grantFourYearQuestionsMiddleware = ({ dispatch }: any) => (
  next: Function
) => (action: any) => {
  next(action);

  if (action.type === FETCH_FOUR_YEAR) {
    const { entityType, entityId } = action.payload;

    switch (entityType) {
      case 'needs-assessment':
        dispatch(setIsLoading_NeedsAssessment(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setNeedsAssessment(res)))
          .finally(() => dispatch(setIsLoading_NeedsAssessment(false)));

      case 'career-info':
        dispatch(setIsLoading_CareerInfo(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setCareerInfo(res)))
          .finally(() => dispatch(setIsLoading_CareerInfo(false)));

      case 'quality':
        dispatch(setIsLoading_Quality(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setQuality(res)))
          .finally(() => dispatch(setIsLoading_Quality(false)));

      case 'instructors':
        dispatch(setIsLoading_Instructors(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setInstructors(res)))
          .finally(() => dispatch(setIsLoading_Instructors(false)));

      case 'partnerships':
        dispatch(setIsLoading_Partnerships(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setPartnerships(res)))
          .finally(() => dispatch(setIsLoading_Partnerships(false)));

      case 'work-based-learning':
        dispatch(setIsLoading_WorkBasedLearning(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setWorkBasedLearning(res)))
          .finally(() => dispatch(setIsLoading_WorkBasedLearning(false)));

      case 'equity':
        dispatch(setIsLoading_Equity(true));
        return readFourYearGrantFormQuestions(entityType, entityId)
          .then((res: any) => dispatch(setEquity(res)))
          .finally(() => dispatch(setIsLoading_Equity(false)));
    }
  }

  if (action.type === SAVE_FOUR_YEAR) {
    const { entityType, entityId, questions } = action.payload;
    updateGrantFormQuestions(questions);
    dispatch(loadWorkFlow(entityType, entityId));
  }
};
