import React from 'react';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import Paper from '../Paper';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import Icon from '../Icons';

export const BreadcrumbComponent: React.FC = () => {
  const routes = [
    {
      path: '/data-collection/student-enrollment/:programId',
      breadcrumb: ({ match }: any) => {
        return <span>{`Program ID: ${match.params.programId}`}</span>;
      },
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <Paper>
      <div className="u-flex" style={{ alignItems: 'center' }}>
        {map(breadcrumbs, (crumb: BreadcrumbData, index: number) => {
          const last = index === breadcrumbs.length - 1;
          return (
            <div
              key={index}
              className="u-flex"
              style={{ alignItems: 'center' }}
            >
              {last ? (
                <span style={{ textTransform: 'uppercase' }}>
                  {crumb.breadcrumb}
                </span>
              ) : (
                <Link style={{ textTransform: 'uppercase' }} to={crumb.key}>
                  {crumb.breadcrumb}
                </Link>
              )}

              {!last && (
                <Icon className="breadcrumb--seperator" name="chevronsRight" />
              )}
            </div>
          );
        })}
      </div>
    </Paper>
  );
};
