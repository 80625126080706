import React, { useEffect } from 'react';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { Workflow, WorkflowStep } from 'types/workflow';
import { Waypoint } from 'react-waypoint';
import { QuestionGroup, UniversalContact } from 'types';
import { useParams } from 'react-router-dom';
import { some, map, includes, filter } from 'lodash';
import { Approval } from './Approval.component';
import { SubmissionComponent } from './Submission.component';
import { WorkflowCard } from './WorkflowCard.component';
import { VALID_PROGRAM_SUBMISSION_STATUS_IDS } from 'constants/workflow.const';
import { isSuperAdmin } from 'util/permissionsUtility';
import DatatableLoading from 'components/Common/DatatableLoading';
import Paper from 'components/Common/Paper';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import SummaryScroll from 'components/Common/SummaryScroll';
import AdminStateControl from 'components/Common/GeneralWorkflow/AdminStateControl';
import { readProgramApprovalSteps } from 'services/workflow/programworkflow.service';
import Loading from 'components/Common/Loading';

export type WorkflowApprovalProps = {
  workflow: Workflow;
  submitProgramForApproval: Function;
  submitProgramForUpdate: Function;
  setCurrentNavAnchor: Function;
  user: UniversalContact;
  isFetching: boolean;
  assurances: QuestionGroup;
  loadWorkflowAssurances: Function;
};

export const WorkflowSummaryComponent: React.FC<WorkflowApprovalProps> = (
  props
) => {
  const {
    setCurrentNavAnchor,
    workflow,
    submitProgramForApproval,
    submitProgramForUpdate,
    user,
    isFetching,
    assurances,
    loadWorkflowAssurances,
  } = props;

  const { programInstanceId }: any = useParams();

  useEffect(() => {
    loadWorkflowAssurances(Number(programInstanceId));
  }, [loadWorkflowAssurances, programInstanceId]);

  const canEditWorkflow = (currentStep: WorkflowStep): boolean => {
    const approvers = currentStep ? currentStep.approvers : [];
    const isApprover = some(map(approvers), (item) => {
      return item.universalContactId === user.universalContactId;
    });
    return isSuperAdmin(user) ? isSuperAdmin(user) : isApprover;
  };

  const currentWorkflowSteps = (): WorkflowStep[] => {
    return filter(workflow.workflowSteps, (item) => {
      return item.isCurrent;
    });
  };

  return (
    <React.Fragment>
      <Paper>
        <Loading isActive={isFetching} />
        <SummaryScroll componentStep="approval" />
        <Waypoint
          onEnter={() => setCurrentNavAnchor('approval')}
          topOffset={WINDOW_TOP_OFFSET}
          bottomOffset={WINDOW_BOTTOM_OFFSET}
        />
        {isFetching ? (
          <DatatableLoading
            loading={true}
            messageBefore="Loading Workflow...."
          />
        ) : (
          <React.Fragment>
            {includes(
              VALID_PROGRAM_SUBMISSION_STATUS_IDS,
              workflow.workflowStatusId
            ) ? (
              <SecureWrap
                component="ProgramWorkflow"
                noAccessComponent={
                  <h4>Only authorized users can submit for approval</h4>
                }
              >
                <SubmissionComponent
                  assurances={assurances}
                  programInstanceId={Number(programInstanceId)}
                  submitProgramForApproval={submitProgramForApproval}
                />
              </SecureWrap>
            ) : (
              <React.Fragment>
                {map(currentWorkflowSteps(), (item, index) => {
                  return canEditWorkflow(item) ? (
                    <Approval
                      key={index}
                      workflowStep={item}
                      programInstanceId={Number(programInstanceId)}
                      submitProgramForUpdate={submitProgramForUpdate}
                    />
                  ) : (
                    <WorkflowCard
                      key={index}
                      flat={true}
                      isFirst={true}
                      isOdd={false}
                      workflowItem={item}
                      isSummary={false}
                    />
                  );
                })}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {includes([0, 3], workflow.workflowStatusId) ? null : (
          <AdminStateControl
            validRoles={[
              'admin',
              'cccsprogramdirector',
              'banneradmin',
              'financialaidadmin',
              'perkinsplanmanager',
              'financeapprover',
              'financereviewer',
            ]}
            optionsEndpoint={readProgramApprovalSteps}
            submitProgramForUpdate={submitProgramForUpdate}
          />
        )}
      </Paper>
    </React.Fragment>
  );
};
