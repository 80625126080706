import { connect } from 'react-redux';
import { setCurrentNavAnchor } from 'redux/application';
import PathwayCourseEditComponent from 'components/Program/PathwayCourses/PathwayCourseEdit';
import PathwayCourseSummaryComponent from 'components/Program/PathwayCourses/PathwayCourseSummary';
import PathwayCourseSuperSummaryComponent from 'components/Program/PathwayCourses/PathwayCourseSuperSummary';
import {
  getProgramPathways,
  getAreProgramPathwaysFetching,
} from 'redux/caches/_selectors';
import {
  loadProgramPathway,
  saveProgramPathway,
  updateProgramPathwayCache,
  cleanProgramPathwayCache,
} from 'redux/caches/programpathway';
import { getActiveProgramId } from 'redux/application/selectors';
import { getProgram, getIsProgramReadOnly } from 'redux/programs/_selectors';
import { getNextStep } from 'redux/common/_selectors';

export const mapStateToProps = (state: any, ownProps: any) => {
  const programInstanceId = getActiveProgramId(state);
  const programPathways = getProgramPathways(state, programInstanceId);
  const isFetching = getAreProgramPathwaysFetching(state);
  const program = getProgram(state, programInstanceId);
  const programLevelId = program.programLevelId;
  const nextProgramStep = getNextStep(state, 'courses');
  const readOnly = getIsProgramReadOnly(state, programInstanceId);

  return {
    isFetching,
    nextProgramStep,
    programLevelId,
    programPathways,
    readOnly,
  };
};

const dispatchToProps = {
  cleanProgramPathwayCache,
  loadProgramPathway,
  saveProgramPathway,
  setCurrentNavAnchor,
  updateProgramPathwayCache,
};

export const PathwayCourseEdit = connect(
  mapStateToProps,
  dispatchToProps
)(PathwayCourseEditComponent);

export const PathwayCourseSummary = connect(
  mapStateToProps,
  dispatchToProps
)(PathwayCourseSummaryComponent);

export const PathwayCourseSuperSummary = connect(
  mapStateToProps,
  dispatchToProps
)(PathwayCourseSuperSummaryComponent);
