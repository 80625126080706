import { PROGRAMS_KEY, PROGRAM_ARTICULATIONS_KEY } from 'redux/keys';
import { ProgramArticulation, ProgramArticulationDeep } from 'types';
import ApplicationState from 'types/applicationstate.types';
import { getContact } from 'redux/universalcontacts/universalcontacts.selectors';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getLastUpdated,
} from 'redux/utility/baseSelectors';
import { getSchoolById, getInstituteById } from 'redux/common/_selectors';
import { find, map, filter } from 'lodash';

export const ARTICULATIONS_PATH: string[] = [
  PROGRAMS_KEY,
  PROGRAM_ARTICULATIONS_KEY,
];

/* Base selectors */

export const getIsDataFetching_ProgramArticulations = (state: any): boolean => {
  return getIsDataFetching(state, ARTICULATIONS_PATH);
};

export const getLastUpdated_ProgramArticulations = (state: any): number => {
  return getLastUpdated(state, ARTICULATIONS_PATH);
};

export const getRecords_ProgramArticulations = (
  state: any
): ProgramArticulation[] => {
  return getRecords<ProgramArticulation>(state, ARTICULATIONS_PATH);
};

export const shouldRefreshReduxState_ProgramArticulations = (
  state: any
): boolean => {
  return shouldRefreshReduxState<ProgramArticulation>(
    state,
    ARTICULATIONS_PATH
  );
};

export const getArticulationsByProgramId = (
  state: any,
  programInstanceId: number
): ProgramArticulation[] => {
  return filter(
    getRecords_ProgramArticulations(state),
    (value: ProgramArticulation) => {
      return value.programInstanceId === programInstanceId;
    }
  );
};

export const getArticulationByArticulationId = (
  state: any,
  programArticulationId: number
): ProgramArticulation | undefined => {
  const articulations = getRecords_ProgramArticulations(state);
  return find(articulations, (art) => {
    return Number(art.programArticulationId) === Number(programArticulationId);
  });
};

export const getProgramArticulationsDeep = (
  state: ApplicationState,
  programInstanceId: number
): ProgramArticulationDeep[] => {
  return map(
    getArticulationsByProgramId(state, programInstanceId),
    (value: ProgramArticulation): ProgramArticulationDeep => {
      const contact = getContact(state, value.contactId);
      const institute = getInstituteById(state, value.schoolId);
      const school = getSchoolById(state, value.schoolId);
      return {
        ...value,
        contact,
        school: institute ? institute : school,
      };
    }
  );
};
