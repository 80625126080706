import Button from 'components/Common/FormElements/Button';
import Input from 'components/Common/FormElements/Input';
import Textarea from 'components/Common/FormElements/Textarea';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import ToastNotification from 'components/Common/Toast';
import { FlexRow } from 'layouts';
import { defaultTo, find, get, isEmpty, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StringResources from 'StringResources';
import { emptyPermissionRole, PermissionRole } from 'types';

type EditRoleDetailsProps = {
  roles: PermissionRole[];
  loadPermissionRoles: Function;
  savePermissionRole: Function;
};

export const EditRoleDetailsComponent: React.FC<EditRoleDetailsProps> = (
  props
) => {
  const { roles, loadPermissionRoles, savePermissionRole } = props;

  const [currentRole, setCurrentRole] = useState<PermissionRole>(
    emptyPermissionRole()
  );

  const { roleId }: any = useParams();

  useEffect(() => {
    loadPermissionRoles();
  }, [loadPermissionRoles]);

  useEffect(() => {
    const selectedRole = defaultTo(
      find(roles, (item) => {
        return Number(item.permissionRoleId) === Number(roleId);
      }),
      emptyPermissionRole()
    );
    setCurrentRole(selectedRole);
  }, [roles, roleId]);

  const handleChange = (item: Partial<PermissionRole>) => {
    setCurrentRole({ ...currentRole, ...item });
  };

  const handleSave = () => {
    savePermissionRole(currentRole).then(() => {
      toast(
        <ToastNotification
          status="success"
          message={StringResources.Success.DataSaved}
        />
      );
    });
  };
  const disabled = Number(roleId) === 1;

  const getDisabled = () => {
    return some([
      isEmpty(currentRole.permissionRoleName),
      isEmpty(currentRole.permissionRoleLabelName),
    ]);
  };

  return (
    <Paper>
      <FlexRow>
        <Instruction title="Permission Role Details">
          <p>Use the following to update you permission role details.</p>
          <br />
          <p>
            WARNING: Changing any of these values can have far reaching effects,
            be 1000% sure you know what you are doing.
          </p>
        </Instruction>
      </FlexRow>

      <FlexRow>
        <Input
          name="permissionRoleName"
          value={get(currentRole, 'permissionRoleName', '')}
          onChange={handleChange}
          disabled={disabled}
        />

        <Input
          label="Friendly Name"
          name="permissionRoleLabelName"
          value={get(currentRole, 'permissionRoleLabelName', '')}
          onChange={handleChange}
        />
        {/* <Toggle
          label="Assign Roles"
          name="canAssignRoles"
          description="Users with this role can assign roles to other users"
          value={get(currentRole, 'canAssignRoles', false)}
          onChange={handleChange}
          disabled={disabled}
        /> */}
      </FlexRow>
      <FlexRow>
        <Textarea
          name="permissionRoleDescription"
          value={get(currentRole, 'permissionRoleDescription', '')}
          onChange={handleChange}
        />
      </FlexRow>
      <FlexRow>
        <div className="u-flex-grow-1" />
        <Button
          disabled={getDisabled()}
          asLink={false}
          text={'Save Role Details'}
          onClick={handleSave}
          className={`form__btn--submit`}
        />
      </FlexRow>
    </Paper>
  );
};
