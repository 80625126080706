import { connect } from 'react-redux';
import { GrantSideNav } from './GrantsSideNav.component';
import {
  getCurrentNavAnchor,
  getNavTrayClosed,
} from 'redux/application/selectors';
import { startCase, some } from 'lodash';
import { setNavTrayClosed } from 'redux/application';
import { SideNavData } from 'types/ui/SideNavData.types';
import {
  getRecords_GrantOverview,
  getRecords_GrantFourYearNav,
  getRecords_GrantFiscalYearNav,
  getRecords_GrantSpendingPlanNav,
  getRecords_GrantVoucherDetailNav,
  getRecords_GrantImprovementPlanNav,
  getRecords_GrantAwardPacketNav,
} from 'redux/nav/_selectors';
import { getRecords_GrantTwoYearNav } from 'redux/nav/twoyearnav.selectors';
import { getRecords_GrantAssuranceSignatureNav } from 'redux/nav/assurancesignaturenav.selectors';
import { getRecords_GrantAnnualReflectionNav } from 'redux/nav/annualreflectionnav.selectors';
import { getRecords_GrantVoucherOverviewNav } from 'redux/nav/voucheroverview.selectors';
import { getLockedIcon } from 'util/applicationStateUtility';
import { mergeObjects } from 'util/objectUtility';

const getVoucherTypeNav = (anchor: string) => {
  return {
    title: startCase(anchor),
    steps: [
      {
        stepID: 1,
        stepName: startCase(anchor),
        nav: {
          anchor: anchor,
          displayName: startCase(anchor),
          url: `/grants/applications/${anchor}/voucher-detail/`,
        },
      },
      {
        stepID: 2,
        stepName: 'Submission / Approval',
        nav: {
          anchor: 'workflow-approval',
          displayName: 'Submission / Approval',
          url: `/grants/applications/${anchor}/workflow-approval/`,
        },
      },
    ],
    scrollNav: false,
  };
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { pathname } = ownProps;
  const currentNavAchor = getCurrentNavAnchor(state);
  const navTrayClosed = getNavTrayClosed(state);
  const anchor = pathname.split('/')[3];

  const getNavData = () => {
    if (pathname.includes('grant-steps')) {
      return getRecords_GrantOverview(state);
    }
    if (pathname.includes('four-year-plan')) {
      return getRecords_GrantFourYearNav(state);
    }
    if (pathname.includes('fiscal-year-management')) {
      return getRecords_GrantFiscalYearNav(state);
    }
    if (pathname.includes('two-year-plan')) {
      return getRecords_GrantTwoYearNav(state);
    }
    if (pathname.includes('spending-plans')) {
      return getRecords_GrantSpendingPlanNav(state);
    }
    if (pathname.includes('assurance-signature')) {
      return getRecords_GrantAssuranceSignatureNav(state);
    }
    if (pathname.includes('award-packet')) {
      return getRecords_GrantAwardPacketNav(state);
    }
    if (pathname.includes('annual-reflection')) {
      return getRecords_GrantAnnualReflectionNav(state);
    }
    if (pathname.includes('improvement-plan')) {
      return getRecords_GrantImprovementPlanNav(state);
    }
    if (
      some([
        pathname.includes('voucher-detail'),
        pathname.includes('vouchers/workflow-approval'),
      ])
    ) {
      return getRecords_GrantVoucherDetailNav(state);
    }
    if (pathname.includes('vouchers')) {
      return getRecords_GrantVoucherOverviewNav(state);
    }
    if (
      some([
        pathname.includes('voucher-one'),
        pathname.includes('voucher-two'),
        pathname.includes('voucher-three'),
        pathname.includes('final-voucher'),
      ])
    ) {
      return getVoucherTypeNav(anchor);
    }

    return {
      title: startCase(anchor),
      steps: [
        {
          stepID: 1,
          stepName: startCase(anchor),
          nav: {
            anchor: anchor,
            displayName: startCase(anchor),
            url: `/grants/applications/${anchor}/`,
          },
        },
        {
          stepID: 2,
          stepName: 'Submission / Approval',
          nav: {
            anchor: 'workflow-approval',
            displayName: 'Submission / Approval',
            url: `/grants/applications/${anchor}/workflow-approval/`,
          },
        },
      ],
      scrollNav: false,
    };
  };

  const hasLockedIcon = getLockedIcon(state, anchor);
  const navData: SideNavData = mergeObjects(getNavData(), { hasLockedIcon });

  return {
    currentNavAchor,
    navTrayClosed,
    navData,
  };
};

const dispathProps = {
  setNavTrayClosed,
};

export default connect(
  mapStateToProps,
  dispathProps
)(GrantSideNav);
