import {
  saveProgramLength,
  loadProgramLengths,
} from 'redux/common/programlengths';

export const programLengthMiddleware = (store: any) => (next: Function) => (
  action: any
) => {
  const { dispatch } = store;
  next(action);

  switch (action.type) {
    case saveProgramLength.SUCCEEDED: {
      dispatch(loadProgramLengths());
      break;
    }
  }
};
