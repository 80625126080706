export enum LOCAL_PLAN_WORKFLOW {
  NEW = 3,
  REVIEW_IN_PROGRESS = 65,
  INFORMATION_NEEDED = 66,
  APPROVED = 67,
  CONDITIONALLY_APROVED = 68,
  OPEN_FOR_REVISION = 69,
  RECOMMENDED_FOR_APPROVAL = 73,
  FISCAL_INFORMATION_NEEDED = 74,
}
