import { connect } from 'react-redux';
import { CTAInstructorPage } from './CTAInstructorPage.page';
import { some } from 'lodash';
import { getIsDataFetching } from 'redux/utility/_exports';
import {
  CTA_NON_CTE_COURSE_PATH,
  CTA_INSTRUCTOR_SALARY_PATH,
  CTA_INSTRUCTORS_PATH,
  CTA_CTE_COURSE_PATH,
} from 'redux/cta/_paths';

const mapStateToProps = (state: any, ownProps: any) => {
  const isLoading = some([
    getIsDataFetching(state, CTA_NON_CTE_COURSE_PATH),
    getIsDataFetching(state, CTA_INSTRUCTOR_SALARY_PATH),
    getIsDataFetching(state, CTA_INSTRUCTORS_PATH),
    getIsDataFetching(state, CTA_CTE_COURSE_PATH),
  ]);
  return {
    isLoading,
  };
};

export default connect(
  mapStateToProps,
  null
)(CTAInstructorPage);
