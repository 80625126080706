import {
  baseInitialState,
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/_exports';
import {
  SET_EQUITY_ADDRESSABLE_ELEMENTS,
  SET_EQUITY_ADDRESSABLE_ELEMENTS_LOADING,
} from './equity.actions';

const initState = baseInitialState;

export const equityAddressableElementsReducer = (
  state = initState,
  action: any
) => {
  switch (action.type) {
    case SET_EQUITY_ADDRESSABLE_ELEMENTS:
      return actionUpdateRecords(state, action.payload);

    case SET_EQUITY_ADDRESSABLE_ELEMENTS_LOADING:
      return actionFetching(state, action.payload);

    default:
      return state;
  }
};
