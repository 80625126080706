import React, { useState, useEffect, useRef, useCallback } from 'react';
import { UniversalContact, Program } from 'types';
import { contactHasRoles } from 'util/permissionsUtility';
import { readPotentialProgramOwners } from 'services/programs/program.services';
import { toast } from 'react-toastify';
import { map } from 'lodash';
import { getContactFullName } from 'util/universalcontact.utility';
import { isApplicaionIdValid } from 'util/global';
import Button from 'components/Common/FormElements/Button';
import SelectBox from 'components/Common/FormElements/SelectBox';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';

type EditProgramOwnerProps = {
  loggedInUser: UniversalContact;
  loadSingleProgramInstance: Function;
  saveProgramInstanceAction: Function;
  program: Program;
};

export const EditProgramOwnerComponent: React.FC<EditProgramOwnerProps> = (
  props
) => {
  const {
    loggedInUser,
    loadSingleProgramInstance,
    saveProgramInstanceAction,
    program,
  } = props;
  const [display, setDisplay] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [contacts, setContacts] = useState<UniversalContact[]>([]);
  const [owner, setOwner] = useState<Partial<UniversalContact>>({});
  const mountedRef = useRef(true);

  const loadData = useCallback(() => {
    setLoading(true);
    setOwner({ universalContactId: program.requestorContactId });
    readPotentialProgramOwners(program.programInstanceId)
      .then((data: UniversalContact[]) => {
        mountedRef.current && setContacts(data);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message={'Unable to load program owner data'}
          />
        );
      })
      .finally(() => mountedRef.current && setLoading(false));
  }, [program.programInstanceId]); // eslint-disable-line

  const getOptions = () => {
    return map(contacts, (item) => {
      return {
        key: item.universalContactId,
        label: getContactFullName(item),
        value: item.universalContactId,
      };
    });
  };

  const updateOwner = () => {
    if (isApplicaionIdValid(owner.universalContactId)) {
      saveProgramInstanceAction({
        ...program,
        requestorContactId: owner.universalContactId,
      })
        .then(() => {
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
          loadSingleProgramInstance(program.programInstanceId);
        })
        .catch(() => {
          toast(
            <ToastNotification
              status="error"
              message={StringResources.Errors.DataSaveFailed}
            />
          );
        });
    }
  };

  useEffect(() => {
    setDisplay(
      contactHasRoles(loggedInUser, [
        'admin',
        'primaryprogrammanager',
        'cccsprogramdirector',
      ])
    );
  }, [loggedInUser]);

  useEffect(() => {
    if (display && isApplicaionIdValid(program.programInstanceId)) {
      loadData();
    }
  }, [display, program.programInstanceId, loadData]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <React.Fragment>
      {display && (
        <div className="u-flex" style={{ alignItems: 'flex-end' }}>
          <div className="u-flex-grow-1" style={{ marginRight: '1.8rem' }}>
            <SelectBox
              isLoading={loading}
              label="Update Program Owner"
              name="universalContactId"
              options={getOptions()}
              clearable={true}
              onChange={setOwner}
              selection={owner.universalContactId}
            />
          </div>
          <Button
            style={{ height: '5.4rem' }}
            className="dialog__btn--cancel"
            text="Update Owner"
            onClick={updateOwner}
          />
        </div>
      )}
    </React.Fragment>
  );
};
