import React from 'react';
import Paper from 'components/Common/Paper';
import { ProgramOfStudySubProps } from '../ProgramOfStudyOverview.component';
import { map } from 'lodash';
import { FlexRow } from 'layouts';
import { SecondaryCareerPathway } from './SecondaryCareerPathway';

export const SecondaryCareerPathways: React.FC<ProgramOfStudySubProps> = (
  props
) => {
  const { programOfStudy } = props;

  return (
    <Paper>
      <FlexRow>
        <h2>Secondary Career and Technical Courses</h2>
      </FlexRow>
      <div>
        {programOfStudy.pathways
          ? map(programOfStudy.pathways, (pathway, index) => {
              return <SecondaryCareerPathway key={index} pathway={pathway} />;
            })
          : 'No pathway information was found'}
      </div>
      <hr />
      <div>
        <label className="form__label">Advanced Credit Options Key:</label>
        <p>AA – Course is approved for Articulation Agreement </p>
        <p>CE – Course is offered as Concurrent Enrollment</p>
      </div>
    </Paper>
  );
};
