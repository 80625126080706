import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  readProgramContacts,
  updateProgramContacts,
} from 'services/programs/contacts.services';
import {
  actionFetching,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { ProgramContact } from 'types';

export const loadProgramContacts = createActionThunk(
  'loadProgramContacts',
  (programInstanceId: number) => {
    return readProgramContacts(programInstanceId);
  }
); // end

export const saveProgramContacts = createActionThunk(
  'saveProgramContacts',
  (programInstanceId: number, payload: ProgramContact[]) => {
    return updateProgramContacts(programInstanceId, payload).then(() => {
      return programInstanceId;
    });
  }
); // end

export default handleActions<BaseStateObject, ProgramContact[]>(
  {
    ...baseLoadActionBuilder<ProgramContact[]>(loadProgramContacts),

    [saveProgramContacts.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [saveProgramContacts.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  baseInitialState
);
