import { CtaInstructorTerm } from 'types';

export interface CtaInstructorCourse {
  ctaInstructorCourseProgramId: number;
  ctaInstructorId: number;
  ctaCourseId: number;
  schoolId: number;
  courseId: number;
  coCourseName: string;
  cteCourseName: string;
  ctaSchoolId: number | null;
  schoolName: string;
  ctaProgramId: number;
  programName: string;
  isCte: boolean;
  terms: CtaInstructorTerm[];
}

export const emptyCtaInstructorCourse = (): CtaInstructorCourse => {
  return {
    ctaInstructorCourseProgramId: -1,
    ctaInstructorId: -1,
    ctaCourseId: 0,
    schoolId: -1,
    courseId: -1,
    coCourseName: '',
    cteCourseName: '',
    ctaSchoolId: -1,
    schoolName: '',
    ctaProgramId: -1,
    programName: '',
    isCte: false,
    terms: [],
  };
};
