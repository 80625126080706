import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import CivilRightsAssuranceDocument from 'components/Grants/Applications/AwardPacket/Documents/CivilRightsAssuranceDocument';
import Paper from 'components/Common/Paper';

const CivilRightsAssurancePage: React.FC = (props) => {
  return (
    <BaseLayout>
      <Paper>
        <CivilRightsAssuranceDocument />
      </Paper>
    </BaseLayout>
  );
};

export default CivilRightsAssurancePage;
