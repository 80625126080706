import Button from 'components/Common/FormElements/Button';
import CTACourseSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/CTACourseSelectBox';
import DirtyDataWarning from 'components/Common/DirtyDataWarning';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect, useRef } from 'react';
import SaveButtons from 'components/Common/SaveButtons';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import ToastNotification from 'components/Common/Toast';
import { CTACourse, CTACourseTerm } from 'types';
import { CTACourseValidation } from 'validations/CTA/CTACourse.validations';
import { EditCTACourse } from '../Common/EditCTACourse';
import { FlexGroup, FlexRow } from 'layouts';
import { Link } from 'react-router-dom';
import { all } from 'ramda';
import { find, some } from 'lodash';
import { getIsCTAReadOnly } from 'redux/generalworkflow/ctaworkflow.reducer';
import {
  getRecords_CTACourseHosting,
  getRecords_CTAHostingContract,
  getIsDataFetching_CTAHostingContract,
  getIsDataFetching_CTACourseHosting,
} from 'redux/cta/_selectors';
import { isEqual } from 'util/formHelpers';
import {
  loadCTAHostingContract,
  saveCTAHostingContract,
} from 'redux/cta/hosting/ctahostingcontract';
import { loadCTAHostingCourse } from 'redux/cta/hosting/ctahostingcourse';
import { loadCTASingleDistrictRecord } from 'redux/cta/ctadashboard';
import { mergeObjects, compose, prop, not, isTruthy } from 'util/objectUtility';
import { readCTADistrictName } from 'services/cta/ctageneral.services';
import { toast } from 'react-toastify';
import { upsertInPlace, fmap } from 'util/arrayUtility';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

export const EditCTAHosting: React.FC = () => {
  // -- dependencies --
  const { ctaId: cId, ctaContractingId: ccId } = useParams<any>();
  const ctaId = Number(cId);
  const ctaContractingId = Number(ccId);
  const history = useHistory();
  const dispatch = useDispatch();
  const { validateAll } = CTACourseValidation();
  const readOnly = useSelector(getIsCTAReadOnly);

  // -- local states --
  const mountedRef = useRef(true);
  const [contractorName, setContractorName] = useState<string>('');
  const [courseState, setCourseState] = useState<CTACourse[]>([]);
  const [courseWarning, setCourseWarning] = useState<boolean>(false);
  const [dirtyCourses, setDirtyCourses] = useState<boolean>(false);
  const [dirtyFilePath, setDirtyFilePath] = useState<boolean>(false);
  const [filePathWarning, setFilePathWarning] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [submissionFailed, setSubmitFailed] = useState<boolean>(false);
  const hostingCourses = useSelector(getRecords_CTACourseHosting);
  const contractCourses = useSelector(getRecords_CTAHostingContract);
  const isLoading = some([
    useSelector(getIsDataFetching_CTAHostingContract),
    useSelector(getIsDataFetching_CTACourseHosting),
  ]);

  // -- component logic --
  const validateAllCourses = compose(
    all(isTruthy),
    fmap(
      compose(validateAll, (course: CTACourse) => ({
        ...course,
        isHosting: true,
      }))
    )
  );
  const toggleHasRequested = () => {
    setHasRequested(true);
    setTimeout(() => {
      mountedRef.current && setHasRequested(false);
    }, 4000);
  };
  const handleTermsChange = (course: CTACourse) => (terms: CTACourseTerm[]) => {
    const updatedCourse = mergeObjects(course, { terms });
    const upserted = upsertInPlace(courseState, updatedCourse, 'ctaCourseId');
    setCourseState(upserted);
    setDirtyCourses(true);
  };
  const addCTACourse = ({ ctaCourseId }: Partial<CTACourse>) => {
    const exists = find(courseState, { ctaCourseId });
    if (!exists) {
      const course = find(hostingCourses, { ctaCourseId });
      if (course) {
        const upserted = upsertInPlace(courseState, course, 'ctaCourseId');
        setCourseState(upserted);
      }
    }
  };
  const deleteCourse = (ctaCourseId: number) => {
    const updated = courseState.filter(
      compose(not, isEqual(ctaCourseId), prop('ctaCourseId'))
    );
    setCourseState(updated);
    setDirtyCourses(true);
  };
  const handleCourseSave = () => {
    toggleHasRequested();
    const canSave = validateAllCourses(courseState);
    if (canSave) {
      dispatch(saveCTAHostingContract(ctaId, ctaContractingId, courseState))
        .then(() => {
          setDirtyCourses(false);
          setSubmitFailed(false);
          toast(
            <ToastNotification
              status="success"
              message={StringResources.Success.DataSaved}
            />
          );
        })
        .catch((err: any) => console.error(err));
    } else {
      setSubmitFailed(true);
      toast(
        <ToastNotification
          status="error"
          message={StringResources.Errors.ValidationFailed}
        />
      );
    }
  };

  const navigateBack = () => {
    history.push(`/cta/district-reporting/${ctaId}`);
  };

  const handleGoBack = () => {
    if (dirtyCourses) {
      setCourseWarning(true);
    } else if (dirtyFilePath) {
      setFilePathWarning(true);
    } else {
      navigateBack();
    }
  };

  const handleFileSave = () => {
    setDirtyFilePath(false);
    toast(
      <ToastNotification
        status="error"
        message={StringResources.Success.DataSaved}
      />
    );
  };

  // -- lifecyle --
  useEffect(() => {
    dispatch(loadCTASingleDistrictRecord(ctaId));
    dispatch(loadCTAHostingContract(ctaId, ctaContractingId));
  }, [dispatch, ctaContractingId, ctaId]);

  useEffect(() => {
    setCourseState(contractCourses);
  }, [contractCourses]);

  useEffect(() => {
    readCTADistrictName(ctaContractingId)
      .then((res: string) => mountedRef.current && setContractorName(res))
      .catch((err: any) => console.error(err));
  }, [ctaContractingId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // -- display logic --

  return (
    <SecureWrap component={['ctageneral']} isLocked={readOnly}>
      <Loading messageBefore="Loading" isActive={isLoading} />
      <DirtyDataWarning
        open={courseWarning}
        onClose={() => setCourseWarning(false)}
        onCancel={navigateBack}
        onConfirm={() => {
          handleCourseSave();
          setCourseWarning(false);
        }}
      />
      <DirtyDataWarning
        open={filePathWarning}
        onClose={() => setFilePathWarning(false)}
        onCancel={navigateBack}
        onConfirm={() => {
          handleFileSave();
          setFilePathWarning(false);
        }}
      />
      <Paper className="t-hosted-courses">
        <FlexRow>
          <Instruction title={`Hosted Courses for ${contractorName}`}>
            <p>
              Click in the ‘Add New Course’ box and select the school, program,
              and course that the hosted student(s) attended. Enter as many
              courses as needed. Enter the number of students who attended this
              course and the <b>total revenue</b> generated by the students
              enrolled. If the number of terms are displaying incorrectly for
              the given school, revisit the ‘General Settings’ of your report.
              Click on ‘Delete this course’ text if this course was added in
              error.
            </p>
            <br />
            <p>
              If the correct courses are not displaying in the ‘Add New Course’
              dropdown, revisit ‘Instructional Costs’ to report on the course
              for the appropriate teacher and also ensure that student
              enrollment for the program has been reported in{' '}
              <Link rel="noreferrer" target="_blank" to="/data-collection">
                Data Collections
              </Link>
              .
            </p>
            <p>
              <span className="u-color-red">Note</span>: The Course name
              beginning with ‘CO’ is the state assigned name; the other Course
              name is the locally assigned name. Courses highlighted in{' '}
              <span className="u-color-tan">tan</span> indicate that the record
              was added by the contracting District.
            </p>
          </Instruction>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            {courseState.length > 0 ? (
              courseState.map((course: CTACourse, index: number) => {
                return (
                  <EditCTACourse
                    key={index}
                    ctaCourse={course}
                    deleteCourse={deleteCourse}
                    isHosting={true}
                    onChange={handleTermsChange(course)}
                    submissionFailed={submissionFailed}
                  />
                );
              })
            ) : (
              <p>No courses have been added.</p>
            )}
          </FlexGroup>
        </FlexRow>
        <FlexRow>
          <FlexGroup>
            <CTACourseSelectBox
              label="Add New Course"
              loadCourses={loadCTAHostingCourse}
              onChange={addCTACourse}
              selector={getRecords_CTACourseHosting}
            />
          </FlexGroup>
        </FlexRow>
        <FlexRow>
          <div style={{ flexGrow: 1 }} />
          <Button
            className={dirtyCourses ? 'button--filled' : 'button--outline'}
            disabled={hasRequested}
            onClick={handleCourseSave}
            style={{ alignSelf: 'flex-end', height: '5.6rem' }}
            text="Save Hosted Courses"
          />
        </FlexRow>
      </Paper>
      <Paper className="t-save-hosting">
        <SaveButtons
          alternateAction={handleGoBack}
          hideCancel
          saveText="Return to CTA Overview"
        />
      </Paper>
    </SecureWrap>
  );
};
