export const WORK_BASED_LEARNING = '[WORK_BASED_LEARNING]';

export const SET_WORK_BASED_LEARNING = `${WORK_BASED_LEARNING} SET`;
export const SET_WORK_BASED_LEARNING_LOADING = `${WORK_BASED_LEARNING} LOADING`;

export const setWorkBasedLearning = (payload: any) => {
  return {
    type: SET_WORK_BASED_LEARNING,
    payload,
  };
};

export const setIsLoading_WorkBasedLearning = (payload: boolean) => {
  return {
    type: SET_WORK_BASED_LEARNING_LOADING,
    payload,
  };
};
