import React, { useEffect } from 'react';
import { SaveButtons } from 'components/Common/SaveButtons/SaveButtons.component';
import { useParams, useHistory } from 'react-router';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import ReimbursementRequestSummary from './ReimbursementRequestSummary';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import ScrollToTop from 'components/Common/ScrollToTop';
import TotalActualReimbursementTables from './TotalActualReimbursementTables';
import VoucherList from './VoucherList';

type VoucherEditProps = {
  isLoading: boolean;
  loadVouchers: Function;
  setCurrentNavAnchor: Function;
};

export const VouchersEditComponent: React.FC<VoucherEditProps> = (props) => {
  const { isLoading, loadVouchers, setCurrentNavAnchor } = props;

  const { grantFiscalYearId: id }: any = useParams();
  const grantFiscalYearId = Number(id);

  const history = useHistory();
  const navigateTo = (url: string) => history.push(url);

  useEffect(() => {
    setCurrentNavAnchor('vouchers');
    loadVouchers(grantFiscalYearId);
  }, [setCurrentNavAnchor, loadVouchers, grantFiscalYearId]);

  return (
    <SecureWrap allowReadOnly={true} component={['GrantsVouchers']}>
      <ScrollToTop />
      <Loading isActive={isLoading} messageBefore="Loading Vouchers..." />
      <Paper>
        <Instruction title="Overview">
          <p>
            On this page, you’ll be able to access reimbursement vouchers that
            you’ll use to record expenses you incurred over the course of the
            year. You must submit details and receipts, in some cases, by the
            due date listed next to each voucher and if you don’t complete this
            by the due date, funds may be deobligated.
          </p>
          <br />
          <p>
            All grantees are required to submit reimbursement expenses for
            vouchers 1, 2, and the final. If you have no expenses to submit for
            voucher 1, you still need to submit a voucher for $0 by the due
            date. If you submit an optional voucher, it should be completed
            between the submission of voucher 2 and the final voucher.
          </p>
        </Instruction>
      </Paper>
      <Paper>
        <VoucherList />
      </Paper>
      <Paper>
        <ReimbursementRequestSummary grantFiscalYearId={grantFiscalYearId} />
      </Paper>
      <Paper>
        <TotalActualReimbursementTables grantFiscalYearId={grantFiscalYearId} />
      </Paper>
      <Paper>
        <SaveButtons
          hideCancel={true}
          saveText="return to grant overview"
          alternateAction={() =>
            navigateTo(`/grants/applications/grant-steps/${grantFiscalYearId}`)
          }
        />
      </Paper>
    </SecureWrap>
  );
};
