import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readWorkflowAssuranceQuestions } from 'services/programs/programassurance.service';
import {
  actionFetching,
  actionUpdateRecords,
} from 'redux/utility/baseActionHandler';
import { QuestionGroup } from 'types';
import {
  baseInitialState,
  BaseStateObject,
} from 'redux/utility/baseInitialState';
import { map } from 'lodash';

const initialState = baseInitialState;

export const loadWorkflowAssurances = createActionThunk(
  'loadWorkflowAssurances',
  (programInstanceId: number) => {
    return readWorkflowAssuranceQuestions(programInstanceId);
  }
); // end

export default handleActions<BaseStateObject, QuestionGroup[] | QuestionGroup>(
  {
    [loadWorkflowAssurances.START]: (state, { payload }) => {
      return actionFetching(state, true);
    } /* End action */,
    [loadWorkflowAssurances.SUCCEEDED]: (state, { payload }) => {
      const reset_values = map(payload, (item: QuestionGroup) => {
        return {
          ...item,
          questions: map(item.questions, (quest) => {
            return {
              ...quest,
              programAssuranceValue: {
                ...quest.questionValue,
                value: '',
              },
            };
          }),
        };
      });
      return actionUpdateRecords(state, reset_values);
    } /* End action */,
    [loadWorkflowAssurances.ENDED]: (state, { payload }) => {
      return actionFetching(state, false);
    } /* End action */,
  },
  initialState
);
