import { CTA_KEY, CTA_SPECIALIST_KEY } from 'redux/keys';
import { getIsDataFetching, getRecords } from 'redux/utility/baseSelectors';
import { CTAAdministrator } from 'types/cta/AdministrativeCosts/CTAAdministrator.types';

export const CTA_SPECIALIST_PATH = [CTA_KEY, CTA_SPECIALIST_KEY];

export const getIsDataFetching_CTASpecialist = (state: any): boolean => {
  return getIsDataFetching(state, CTA_SPECIALIST_PATH);
};

export const getRecords_CTASpecialist = (
  state: unknown
): CTAAdministrator[] => {
  return getRecords(state, CTA_SPECIALIST_PATH);
};
