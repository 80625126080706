import { connect } from 'react-redux';
import {
  loadImprovementPlan,
  saveImprovementPlan,
} from 'redux/grants/improvementplan';
import {
  getFirstRecord_ImprovementPlan,
  getIsDataFetching_ImprovementPlan,
} from 'redux/grants/improvementplan.selectors';
import { MultiyearDeficiencyComponent } from './MultiyearDeficiency.component';
import { ImprovementDeficienciesComponent } from './ImprovementDeficiencies.component';
import { ImprovementPlan } from 'types';
import { setCurrentNavAnchor } from 'redux/application';
import { getLockedStatus } from 'util/applicationStateUtility';

export type ImprovementPlanProps = {
  isLoading: boolean;
  improvementPlan: ImprovementPlan;
  loadImprovementPlan: Function;
  saveImprovementPlan: Function;
  setCurrentNavAnchor: Function;
  isLocked: boolean;
};

export const mapStateToProps = (state: any, ownProps: any) => {
  const improvementPlan = getFirstRecord_ImprovementPlan(state);
  const isLoading = getIsDataFetching_ImprovementPlan(state);
  const isLocked = getLockedStatus(state, 'improvement-plan');

  return {
    isLocked,
    isLoading,
    improvementPlan,
  };
};

const dispatchToProps = {
  loadImprovementPlan,
  saveImprovementPlan,
  setCurrentNavAnchor,
};

export const MultiyearDeficiency = connect(
  mapStateToProps,
  dispatchToProps
)(MultiyearDeficiencyComponent);

export const ImprovementDeficiencies = connect(
  mapStateToProps,
  dispatchToProps
)(ImprovementDeficienciesComponent);
