import React, { ReactElement } from 'react';
import { ProgramStep } from 'types';
import IconButton from 'components/Common/IconButton';

type ProgramStepsProps = {
  stepInfo: ProgramStep;
  component?: ReactElement;
  programInstanceId: number;
  readOnly: boolean;
};

export const ProgramSteps: React.FC<ProgramStepsProps> = (props) => {
  const { stepInfo, programInstanceId, component, readOnly } = props;

  const generateIcon = () => {
    switch (stepInfo.stepName) {
      case 'Approval / Workflow':
        return (
          <IconButton
            className="program-step__icon"
            name="launchOpen"
            tooltip="View"
            asLink={true}
            to={`/programs/${stepInfo.nav.anchor}/${programInstanceId}`}
          />
        );
      case 'Performance Reports':
        return (
          <IconButton
            className="program-step__icon"
            name="launchOpen"
            tooltip="View"
            asLink={true}
            to={`/programs/${stepInfo.nav.anchor}/${programInstanceId}`}
          />
        );
      case 'Award Information':
        return (
          <IconButton
            className="program-step__icon"
            name={readOnly ? 'launchOpen' : 'pencil'}
            tooltip="Edit"
            asLink={true}
            to={`/programs/${stepInfo.nav.anchor}/${programInstanceId}`}
          />
        );
      default:
        return (
          <IconButton
            className="program-step__icon"
            name={readOnly ? 'launchOpen' : 'pencil'}
            tooltip="Edit"
            asLink={true}
            to={`/programs/${stepInfo.nav.anchor}/${programInstanceId}`}
          />
        );
    }
  };

  const getScrollNavID = () => {
    return stepInfo.nav.anchor === 'general' ? '' : stepInfo.nav.anchor;
  };

  return (
    <div id={getScrollNavID()} className="program-step">
      <div className="program-step__header">
        <h2 className="program-step__title">{stepInfo.stepName}</h2>
        {generateIcon()}
      </div>
      <div className="program-step__component">{component}</div>
    </div>
  );
};
