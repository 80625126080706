import React from 'react';
import { FlexRow } from 'layouts';
import Instruction from 'components/Common/Instruction';
import ContactSelectBox from 'components/Common/SelectBoxes/AutoSuggestBoxes/ContactSelectBox';
import MiniContactForm from 'components/UniversalContacts/MiniContactForm';
import { UniversalContact, School, Institute } from 'types';

type InstructorAdditionalContactsProps = {
  excludedContactList: Function;
  addToContacts: Function;
  schools: School[];
  institutes: Institute[];
};

export const InstructorAdditionalContacts: React.FC<
  InstructorAdditionalContactsProps
> = (props) => {
  const { addToContacts, excludedContactList, schools, institutes } = props;

  return (
    <React.Fragment>
      <FlexRow>
        <Instruction title="Instructor | Additional Contacts">
          <p>
            Type the first few letters of the name of the person you want to add
            as an Instructor or Additional Contact. If their name doesn't appear
            in the search, use the "New Contact" button and enter their
            information. Use the link in the 'View' column to see the universal
            contact in more detail. Use the button in the 'Remove' column to
            remove contacts from the program.
          </p>
        </Instruction>
      </FlexRow>
      <FlexRow>
        <ContactSelectBox
          label="Search for Universal Contact"
          selectedContact={undefined}
          excludeList={excludedContactList}
          onChange={(contact: UniversalContact) => {
            addToContacts(contact);
          }}
        />
      </FlexRow>
      <FlexRow>
        <div className="u-flex-grow-1" />
        <MiniContactForm
          miniContactData={{ schools, institutes }}
          contactSaved={addToContacts}
        />
      </FlexRow>
    </React.Fragment>
  );
};
