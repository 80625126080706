import { RequiredCourseCreditsInputRow } from './RequiredInputRow.component';
import { connect } from 'react-redux';
import { getCourses } from 'redux/common/_selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const courses = getCourses(state);

  return {
    courses,
  };
};

const dispatchToProps = {};

export default connect(
  mapStateToProps,
  dispatchToProps
)(RequiredCourseCreditsInputRow);
