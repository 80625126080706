import Button from 'components/Common/FormElements/Button';
import CTACollapsableLayout from 'layouts/CTACollapsableLayout';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import React, { useState, useEffect } from 'react';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StringResources from 'StringResources';
import Table from 'components/Common/Table';
import ToastNotification from 'components/Common/Toast';
import Toggle from 'components/Common/FormElements/Toggle';
import { CTADistrictRecord } from 'types';
import { CTASchool } from 'types/cta/CTASchool.types';
import { CTASchoolTerm } from 'types/cta/CTASchoolTerm.types';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { TermsAndCreditsModal } from './TermsAndCreditsModal';
import { find, defaultTo } from 'lodash';
import { isApplicaionIdValid } from 'util/global';
import { renderData, prop } from 'util/objectUtility';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type GeneralProps = {
  ctaRecord: CTADistrictRecord;
  ctaSchoolTerms: CTASchoolTerm[];
  ctaSchools: CTASchool[];
  enabled: boolean;
  isFetching: boolean;
  loadCTASchools: Function;
  readOnly: boolean;
  saveCTAGeneralAccuracy: Function;
  saveCTASchool: Function;
};

export const GeneralComponent: React.FC<GeneralProps> = (props) => {
  const {
    ctaRecord,
    ctaSchoolTerms,
    ctaSchools,
    enabled,
    isFetching,
    loadCTASchools,
    readOnly,
    saveCTAGeneralAccuracy,
    saveCTASchool,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { ctaId: cId } = useParams<any>();
  const ctaId = Number(cId);

  // --[ local state ]---------------------------------------------------------
  const [filteredData, setFilteredData] = useState<CTASchool[]>([]);
  const [termsCorrect, setTermsCorrect] = useState<boolean>(enabled);
  const [wasOpened, setWasOpened] = useState<boolean>(false);

  // --[ component logic ]-----------------------------------------------------
  const updateOpenedState = (value: boolean) => {
    if (value === true) {
      setWasOpened(true);
    }
  };

  const saveTermsValue = () => {
    saveCTAGeneralAccuracy(ctaId, { enabled: termsCorrect })
      .then(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Success.DataSaved}
          />
        );
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="success"
            message={StringResources.Errors.DataSaveFailed}
          />
        );
      });
  };

  // --[ lifecycle ]-----------------------------------------------------------
  useEffect(() => {
    if (wasOpened) {
      if (isApplicaionIdValid(ctaId)) {
        loadCTASchools(ctaId);
      }
    }
  }, [wasOpened, ctaId, loadCTASchools]);

  useEffect(() => {
    setTermsCorrect(enabled);
  }, [enabled]);

  // --[ display logic ]-------------------------------------------------------
  const getDisplayData = (name: keyof CTASchool) => (row: CTASchool) => {
    return renderData(prop(name, row));
  };

  const getTermData = (row: CTASchool) => {
    const term = defaultTo(
      find(ctaSchoolTerms, (item) => {
        return item.termId === row.terms;
      }),
      { termId: row.terms, termName: '' }
    );
    return renderData(term!.termName);
  };

  const renderEditIcon = (row: CTASchool) => {
    return (
      <TermsAndCreditsModal ctaSchool={row} saveCTASchool={saveCTASchool} />
    );
  };

  const CTA_GENERAL_HEADERS: TableColumnProps[] = [
    {
      name: 'CDE ID',
      cell: getDisplayData('cdeId'),
      selector: prop('cdeId'),
      sortable: true,
      width: '20rem',
    },
    {
      name: 'School Name',
      cell: getDisplayData('schoolName'),
      selector: prop('schoolName'),
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'Terms',
      cell: getTermData,
      selector: getTermData,
      sortable: true,
    },
    {
      name: 'Credits Per Year',
      cell: getDisplayData('credits'),
      selector: prop('credits'),
      sortable: true,
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  // this occurs when there is no CTA Fiscal Year Approval record for the district
  const hasNoApprovalData = ctaRecord.statusId === -2;

  return (
    <React.Fragment>
      <CTACollapsableLayout
        name="cta-general"
        header="General Settings"
        onChange={updateOpenedState}
      >
        {wasOpened && (
          <React.Fragment>
            <SecureWrap
              component={['ctageneral']}
              isLocked={readOnly || hasNoApprovalData}
            >
              <Paper>
                <Instruction title="general" hideTitle={true}>
                  <p>
                    Enter text in the Search bar to find a specific school. Use
                    this page to review and edit CTA report data for each school
                    in your district. Begin by verifying the ‘Terms per Year’ or
                    ‘Credits per Year’ offered for each school. Click the edit
                    icon to make changes to the data. Once you have confirmed
                    the data are correct for all schools click on the ‘Accuracy
                    Check’ toggle to confirm your data and then the ‘Save’
                    button to unlock remaining sections.
                  </p>
                  <br />
                  <p>
                    You may navigate to different sections of the CTA report by
                    clicking on the menu items listed in the right-hand side of
                    the page. However, all sections of the CTA report are locked
                    and cannot be edited until the General Settings are
                    confirmed.
                  </p>
                  <br />
                  <p>
                    <span className="u-color-red">Note</span>: If you don't see
                    a school listed that you were expecting to, it is because
                    there were no active CTE programs associated with that
                    school for the reporting year.
                  </p>
                </Instruction>
              </Paper>
              <Paper>
                <FlexRow>
                  <Toggle
                    name="termsCreditsCorrect"
                    label="Final Step: Accuracy Check"
                    description="The terms and credits are correct for each school"
                    value={termsCorrect}
                    onChange={() => {
                      setTermsCorrect(!termsCorrect);
                    }}
                  />
                  <Button
                    text="Save"
                    className="button--filled"
                    onClick={saveTermsValue}
                  />
                </FlexRow>
              </Paper>
            </SecureWrap>
            <Paper>
              <FlexRow>
                <SearchBarFilter
                  shouldReset={true}
                  data={ctaSchools}
                  isFetching={isFetching}
                  label="Search Schools"
                  setFilteredData={setFilteredData}
                  headers={CTA_GENERAL_HEADERS}
                />
              </FlexRow>
              <FlexGroup>
                <Table columns={CTA_GENERAL_HEADERS} data={filteredData} />
              </FlexGroup>
            </Paper>
          </React.Fragment>
        )}
      </CTACollapsableLayout>
    </React.Fragment>
  );
};
