import { SnapshotTeacher } from './SnapshotTeacher.types';

export interface ActiveTeachersData {
  complete: boolean;
  locked: boolean;
  schoolYear: number;
  teachers: SnapshotTeacher[];
}

export const emptyActiveTeacher = (): ActiveTeachersData => {
  return {
    complete: false,
    locked: true,
    schoolYear: 1970,
    teachers: [],
  };
};
