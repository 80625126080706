import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import { readProgramSteps } from 'services/programs/programsteps.services';
import {
  baseLoadActionBuilder,
  actionResetFetching,
} from 'redux/utility/baseActionHandler';
import { ProgramStepCollection } from 'types';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';

const initialState = baseInitialState;

export const loadProgramSteps = createActionThunk('loadProgramSteps', () => {
  return readProgramSteps();
}); // end

export default handleActions<BaseStateObject, ProgramStepCollection>(
  {
    resetIsLoading: (state: BaseStateObject) => {
      return actionResetFetching(state, false);
    },
    ...baseLoadActionBuilder<ProgramStepCollection>(loadProgramSteps),
  },
  initialState
);
