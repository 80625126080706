import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  catchError,
  postWithAuthOptions,
} from 'services';
import { AnnualSpending } from 'types';

const ENDPOINT = 'api/Expenses/spending/';
const FUND_POOL = 'api/Expenses/validconsortium/';

export const readConsortiumFundPoolEligibility = async (
  grantFiscalYearId: number,
  partnerConsortiumId: number,
  strategyId: number
) => {
  const url = `${
    ENVIRONMENT.API_URL
  }${FUND_POOL}${grantFiscalYearId}/${partnerConsortiumId}/${strategyId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('FundPoolEligibility', err);
    });
};

//TODO: add JSDoc
export const readAnnualSpendingPlan = async (grantFiscalYearId: number) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}${grantFiscalYearId}`;
  return fetch(url, {
    ...getWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('AnnualSpendingPlan', err);
    });
};

//TODO: add JSDOC
export const createAnnualSpendingPlan = async (data: AnnualSpending) => {
  const url = `${ENVIRONMENT.API_URL}${ENDPOINT}`;
  return fetch(url, {
    ...postWithAuthOptions(),
    body: JSON.stringify(data),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('AnnualSpendingPlan', err);
    });
};
