import { GRANTS_EMAIL_TEMPLATE_KEY, GRANTS_KEY } from 'redux/keys';
import ApplicationState from 'types/applicationstate.types';
import { defaultTo, first } from 'lodash';
import { EmailTemplate, emptyEmailTemplate, EmailRecipient } from 'types';
import {
  getIsDataFetching,
  getRecords,
  shouldRefreshReduxState,
  getAdvancedFilteredRecords,
} from 'redux/utility/baseSelectors';
import { getEmailRecipientFilters } from 'redux/filters/_selectors';

export const EMAIL_TEMPLATE_PATH = [GRANTS_KEY, GRANTS_EMAIL_TEMPLATE_KEY];

export const getIsDataFetching_EmailTemplate = (state: any): boolean => {
  return getIsDataFetching(state, EMAIL_TEMPLATE_PATH);
};

export const getRecords_EmailTemplate = (state: unknown): EmailTemplate[] => {
  return getRecords(state, EMAIL_TEMPLATE_PATH);
};

export const shouldRefreshReduxState_EmailTemplate = (state: any): boolean => {
  return shouldRefreshReduxState<EmailTemplate>(state, EMAIL_TEMPLATE_PATH);
};

export const getEmailTemplate = (state: ApplicationState): EmailTemplate => {
  const emailTemplates = getRecords_EmailTemplate(state);
  const EmailTemplate = defaultTo(first(emailTemplates), emptyEmailTemplate());
  return EmailTemplate;
};

export const getAdvancedFilteredRecords_EmailRecipients = (
  state: any
): EmailRecipient[] => {
  const filters = getEmailRecipientFilters(state);
  const { recipients } = getEmailTemplate(state);
  const records = getAdvancedFilteredRecords<EmailRecipient>(
    state,
    EMAIL_TEMPLATE_PATH,
    filters,
    recipients
  );
  return records;
};
