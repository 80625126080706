import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  actionFetching,
  actionRecordFailure,
  actionUpdateRecords,
  baseLoadActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { ReportItem, SelectedData } from 'types';
import {
  readPublicReportList,
  readPublicWebReport,
  readReportList,
  readWebReport,
} from 'services/reporting/report.service';
import { upsert } from 'util/arrayUtility';

const initialState = baseInitialState;

export const loadReportList = createActionThunk('loadReportList', () => {
  return readReportList();
});

export const loadSingleWebReport = createActionThunk(
  'loadSingleWebReport',
  (name: string, report: ReportItem) => {
    return readWebReport(name, report);
  }
);

export const loadPublicReportList = createActionThunk(
  'loadPublicReportList',
  () => {
    return readPublicReportList();
  }
);

export const loadPublicWebReport = createActionThunk(
  'loadPublicWebReport',
  (name: string, params: SelectedData[]) => {
    return readPublicWebReport(name, params);
  }
);

export default handleActions<BaseStateObject, ReportItem[]>(
  {
    ...baseLoadActionBuilder<ReportItem[]>(loadReportList),
    ...baseLoadActionBuilder<ReportItem[]>(loadPublicReportList),

    [loadSingleWebReport.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadSingleWebReport.SUCCEEDED]: (state, action) => {
      const records = upsert<ReportItem>(state.records, action.payload, 'name');
      return actionUpdateRecords(state, records);
    },
    [loadSingleWebReport.FAILED]: (state) => {
      return actionRecordFailure(state);
    },
    [loadSingleWebReport.ENDED]: (state) => {
      return actionFetching(state, false);
    },

    [loadPublicWebReport.START]: (state) => {
      return actionFetching(state, true);
    },
    [loadPublicWebReport.SUCCEEDED]: (state, action) => {
      const records = upsert<ReportItem>(state.records, action.payload, 'name');
      return actionUpdateRecords(state, records);
    },
    [loadPublicWebReport.FAILED]: (state) => {
      return actionRecordFailure(state);
    },
    [loadPublicWebReport.ENDED]: (state) => {
      return actionFetching(state, false);
    },
  },
  initialState
);
