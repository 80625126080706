import { Route } from 'react-router-dom';
import authentication from 'components/Common/Authorization/AzureB2C';
import WorkflowDiffPage from 'pages/WorkflowDiff';

const changelogRoutes = [
  <Route
    exact
    path="/change-history/:entityType/:entityId"
    component={authentication.required(WorkflowDiffPage)}
  />,
];

export default changelogRoutes;
