import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FlexRow, FlexGroup } from 'layouts';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { getFullName } from 'util/datacollection.utility';
import { SnapshotStudent, StudentEnrollment, emptySnapshotStudent } from 'types';
import { compose, renderData, prop } from 'util/objectUtility';
import { useModal, Modal } from 'react-morphing-modal';
import Button from 'components/Common/FormElements/Button';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import FollowupDetails from './FollowupDetails';
import Loading from 'components/Common/Loading';
import PageHeading from 'components/Common/PageHeading';
import Paper from 'components/Common/Paper';
import ScrollToTop from 'components/Common/ScrollToTop';
import SearchBarFilter from 'components/Common/SearchBarFilter';
import Table from 'components/Common/Table';

type ProgramFollowupProps = {
  isFetching: boolean;
  followup: StudentEnrollment;
  loadProgramFollowupSnapshotStudent: Function;
  loadStudentFollowUpData: Function;
  schoolYear: number;
  students: SnapshotStudent[];
};

export const ProgramFollowup: React.FC<ProgramFollowupProps> = (props) => {
  const {
    followup,
    isFetching,
    loadProgramFollowupSnapshotStudent,
    loadStudentFollowUpData,
    students,
  } = props;

  const [filteredData, setFilteredData] = useState<SnapshotStudent[]>([]);
  const { modalProps, open, close } = useModal({
    onOpen: () => {
      const modalbody = document.getElementsByClassName('RMM__body')[0];
      modalbody.scrollTo(0, 0);
    },
  });
  const [selectedStudent, setSelectedStudent] = useState<SnapshotStudent>(
    emptySnapshotStudent()
  );

  const isLocked = !followup.isFollowUpOpen || followup.isFollowUpComplete;

  const { programId: pId }: any = useParams();
  const programId = Number(pId);

  const renderEditIcon = (row: SnapshotStudent) => {
    return !isLocked ? (
      <IconButton
        modalClick={open}
        name="pencil"
        onClick={() => setSelectedStudent(row)}
        tooltip="Edit"
      />
    ) : (
      renderData(null)
    );
  };

  const getDisplayData = (name: keyof SnapshotStudent) => {
    return compose(renderData, prop(name));
  };

  const STUDENT_FOLLOWUP_HEADERS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: getFullName,
      selector: getFullName,
      sortable: true,
      style: {
        flexGrow: 1,
      },
    },
    {
      name: 'SASID',
      cell: getDisplayData('sasId'),
      sortable: true,
      selector: prop('sasId'),
    },
    {
      name: 'Placement',
      cell: getDisplayData('placement'),
      sortable: true,
      selector: prop('placement'),
    },
    {
      name: 'Continuing Ed.',
      cell: getDisplayData('contEducation'),
      sortable: true,
      selector: prop('contEducation'),
    },
    {
      name: 'Military',
      cell: getDisplayData('militaryServiceId'),
      sortable: true,
      selector: prop('militaryServiceId'),
    },
    {
      name: 'Apprenticeship',
      cell: getDisplayData('apprenticeship'),
      sortable: true,
      selector: prop('apprenticeship'),
    },
    {
      name: 'Service Program',
      cell: getDisplayData('serviceProgram'),
      sortable: true,
      selector: prop('serviceProgram'),
    },
    {
      name: 'Cert. Exam Completed',
      cell: getDisplayData('certExamCompleted'),
      sortable: true,
      selector: prop('certExamCompleted'),
    },
    {
      name: 'Cert. Exam Passed',
      cell: getDisplayData('certExamPassed'),
      sortable: true,
      selector: prop('certExamPassed'),
    },
    {
      name: 'Edit',
      cell: renderEditIcon,
      width: '5%',
    },
  ];

  useEffect(() => {
    setFilteredData(students);
  }, [students]);

  useEffect(() => {
    loadStudentFollowUpData();
  }, [loadStudentFollowUpData]);

  useEffect(() => {
    loadProgramFollowupSnapshotStudent(programId);
  }, [loadProgramFollowupSnapshotStudent, programId]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Loading isActive={isFetching} />
      <Paper>
        <PageHeading
          title={
            <Instruction
              title={`Student Follow-up : ${followup.programName}`}
              isPageHeader={true}
            >
              <p>
                Use this page to view program follow-up and trend data for your site and review
                and make edits to the student follow-up data entered in the system.
              </p>
            </Instruction>
          }
        />
      </Paper>
      <Paper>
        <Instruction title="Viewing Program Follow-up and Trends">
          <p>
            This section allows you to view summary a summary report for this program. These
            data will open in a new tab on this page. Click on the “View Follow-up Summary
            Report” button to obtain a copy of student enrollment data previously uploaded
            relating to this program.
          </p>
        </Instruction>
        <FlexRow>
          <FlexGroup />
          <Button
            className="button--outline"
            disabled
            onClick={() => {
              if (isLocked) {
                return;
              }
            }}
            style={{ minWidth: '30rem' }}
            text="View Follow-up Summary Report"
          />
        </FlexRow>
      </Paper>
      <Paper>
        <Instruction title="Student Records">
          <p>
            Enter a student’s name or SASID in the search bar to review follow-up data uploaded
            in your follow-up data file. Click on the edit icon to update the information
            listed.
          </p>
        </Instruction>
        <FlexRow>
          <SearchBarFilter
            data={students}
            isFetching={isFetching}
            label="Search Student Follow-up"
            setFilteredData={setFilteredData}
            headers={STUDENT_FOLLOWUP_HEADERS}
          />
        </FlexRow>
        <Table columns={STUDENT_FOLLOWUP_HEADERS} data={filteredData} />
      </Paper>
      <Modal {...modalProps}>
        <FollowupDetails close={close} student={selectedStudent} />
      </Modal>
    </React.Fragment>
  );
};
