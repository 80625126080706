import { createActionThunk } from 'redux-thunk-actions';
import { handleActions } from 'redux-actions';
import {
  baseLoadActionBuilder,
  baseSaveActionBuilder,
} from 'redux/utility/baseActionHandler';
import {
  BaseStateObject,
  baseInitialState,
} from 'redux/utility/baseInitialState';
import { CTADistrictSalary } from 'types';
import {
  readCTADistrictSalary,
  updateCTADistrictSalary,
} from 'services/cta/ctadistrictsalary.services';

const initialState = baseInitialState;

export const loadCTADistrictSalary = createActionThunk(
  'loadCTADistrictSalary',
  (ctaId: number) => {
    return readCTADistrictSalary(ctaId);
  }
);

export const saveCTADistrictSalary = createActionThunk(
  'saveCTADistrictSalary',
  (ctaId: number, supportPersonnel: CTADistrictSalary) => {
    return updateCTADistrictSalary(ctaId, supportPersonnel);
  }
);

export default handleActions<BaseStateObject, CTADistrictSalary[]>(
  {
    ...baseLoadActionBuilder<CTADistrictSalary[]>(loadCTADistrictSalary),
    ...baseSaveActionBuilder<CTADistrictSalary>(saveCTADistrictSalary),
  },
  initialState
);
