import React from 'react';
import Checkbox from 'components/Common/FormElements/Checkbox';
import { FlexGroup } from 'layouts';
import { Question } from 'types';
import { useParams } from 'react-router-dom';

type QuestionBoolProps = {
  question: Question;
  onChange: Function;
};

export const QuestionBool: React.FC<QuestionBoolProps> = (props) => {
  const { question, onChange } = props;

  const updateCheckedState = async (
    data: Partial<any>,
    name: string,
    checked: boolean
  ) => {
    const questionValue = {
      ...question.questionValue,
      value: checked ? 'checked' : '',
    };
    const newState = {
      ...question,
      questionValue,
    };
    onChange(newState);
  };
  const { programInstanceId }: any = useParams();
  const label = question.text.replace(
    '[programid]',
    programInstanceId ? programInstanceId : ''
  );

  return (
    <React.Fragment>
      <FlexGroup>
        <div className="u-flex">
          <Checkbox
            name="value"
            label={label}
            value={question.questionValue.value === 'checked'}
            onChange={updateCheckedState}
          />
        </div>
      </FlexGroup>
    </React.Fragment>
  );
};
