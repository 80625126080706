import { ENVIRONMENT } from 'constants/application.config';
import {
  toJSON,
  getWithAuthOptions,
  postWithAuthOptions,
  putWithAuthOptions,
  catchError,
  deleteWithAuthOptions,
} from 'services';
import { PermissionRole } from 'types';

const PERMISSION_ROLES_ENDPOINT = 'api/PermissionRoles/';
const URL = `${ENVIRONMENT.API_URL}${PERMISSION_ROLES_ENDPOINT}`;

export const readPermissionRoles = async () => {
  return fetch(URL, { ...getWithAuthOptions() })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};

export const createPermissionRole = async (role: PermissionRole) => {
  let newRole: Omit<PermissionRole, 'permissionRoleId'> = {
    permissionRoleDescription: role.permissionRoleDescription,
    permissionRoleName: role.permissionRoleName,
    permissionRoleLabelName: role.permissionRoleLabelName,
    canAssignRoles: role.canAssignRoles,
  };
  return fetch(URL, {
    ...postWithAuthOptions(),
    body: JSON.stringify(newRole),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};

export const updatePermissionRole = async (role: PermissionRole) => {
  return fetch(`${URL}${role.permissionRoleId}`, {
    ...putWithAuthOptions(),
    body: JSON.stringify(role),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};

export const deletePermissionRole = async (role: PermissionRole) => {
  return fetch(`${URL}${role.permissionRoleId}`, {
    ...deleteWithAuthOptions(),
  })
    .then(toJSON)
    .catch((err) => {
      return catchError('PermissionRoles', err);
    });
};
