import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import {
  WINDOW_TOP_OFFSET,
  WINDOW_BOTTOM_OFFSET,
} from 'constants/application.constants';
import { CourseCreditProgram, CourseCreditRequired, ProgramStep } from 'types';
import AwardLegend from 'components/Program/CourseCredits/AwardLegend';
import CreditsBar from 'components/Program/CourseCredits/CreditsBar';
import ElectiveCourses from 'components/Program/CourseCredits/ElectiveCourses';
import GeneralEducationCourses from 'components/Program/CourseCredits/GeneralEducationCourses';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import RequiredCourses from 'components/Program/CourseCredits/RequiredCourses';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import SecureWrap from 'components/Common/Authorization/SecureWrap';
import StickyCreditsBar from './StickyCreditsBar';
import CustomCourse from './CustomCourse';

type CourseCreditsEditComponentProps = {
  courseCredits: CourseCreditProgram;
  loadProgramCourseCredits: Function;
  nextProgramStep: ProgramStep;
  saveProgramCourseCreditCache: Function;
  setCurrentNavAnchor: Function;
  readOnly: boolean;
  resetProgramCourseCreditsCache: Function;
  totalCredits: number[];
  isCccsCollege: boolean;
};

export const CourseCreditsEditComponent: React.FC<CourseCreditsEditComponentProps> = (
  props
) => {
  const {
    courseCredits,
    loadProgramCourseCredits,
    nextProgramStep,
    saveProgramCourseCreditCache,
    setCurrentNavAnchor,
    readOnly,
    resetProgramCourseCreditsCache,
    totalCredits,
    isCccsCollege,
  } = props;

  const { programInstanceId: pId }: any = useParams();
  const programInstanceId = Number(pId);

  const [showStickyHeader, setStickyHeader] = useState<boolean>(false);
  const [isNotValid, setIsNotValid] = useState<boolean>(true);

  useEffect(() => {
    if (courseCredits) {
      const { requiredCourses = [] } = courseCredits;
      const invalid = requiredCourses.some((course: CourseCreditRequired) => {
        return course.courseId < 0;
      });
      setIsNotValid(invalid);
    }
  }, [courseCredits]);

  useEffect(() => {
    if (!programInstanceId || programInstanceId === -1) return;
    loadProgramCourseCredits(programInstanceId);
  }, [loadProgramCourseCredits, programInstanceId]);

  useEffect(() => {
    setCurrentNavAnchor('coursecredits');
  }, [setCurrentNavAnchor]);

  const saveCredits = () => {
    return saveProgramCourseCreditCache(courseCredits, programInstanceId);
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <SecureWrap
        component={['programs', 'programbanner', 'programfinance']}
        isLocked={readOnly}
      >
        {courseCredits && (
          <React.Fragment>
            <Paper>
              <Instruction title="Course Credits">
                <p>
                  Please indicate all credits and courses that are associated
                  with each award. As you fill in credits, the "Total Credits"
                  bar will calculate how many credits have been applied so far.
                  Courses and their credits should add up to the number of
                  credits defined by the award information.
                </p>
              </Instruction>
            </Paper>
            <Paper>
              <AwardLegend courseCredits={courseCredits} />
            </Paper>
            <Waypoint
              onEnter={() => setStickyHeader(false)}
              topOffset={WINDOW_TOP_OFFSET}
              bottomOffset={WINDOW_BOTTOM_OFFSET}
            />
            <Paper>
              <CreditsBar
                creditTotals={totalCredits}
                courseCredits={courseCredits}
              />
              <StickyCreditsBar
                courseCredits={courseCredits}
                creditTotals={totalCredits}
                trigger={showStickyHeader}
              />
            </Paper>
            <Waypoint
              onEnter={() => setStickyHeader(true)}
              topOffset={WINDOW_TOP_OFFSET}
              bottomOffset={WINDOW_BOTTOM_OFFSET}
            />
            <Paper>
              <RequiredCourses />
            </Paper>
            {!isCccsCollege && (
              <Paper>
                <CustomCourse />
              </Paper>
            )}
            <Paper>
              <GeneralEducationCourses />
            </Paper>
            <Paper>
              <ElectiveCourses />
            </Paper>
            <Paper>
              <SaveButtons
                cancelUrl={`/programs/program-steps/${programInstanceId}`}
                disabled={isNotValid}
                onCancel={() =>
                  resetProgramCourseCreditsCache(programInstanceId)
                }
                saveData={saveCredits}
                saveText="Save and Continue"
                saveUrl={nextProgramStep.nav.url}
              />
            </Paper>
          </React.Fragment>
        )}
      </SecureWrap>
    </React.Fragment>
  );
};
